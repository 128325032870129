import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import useLocalStorage from "../../../hooks/useLocalStorage";

export default function BannerAlerts() {
    const { enabled2fa } = useContext(AuthContext);

    const [ignored, setIgnored] = useLocalStorage("ignored-alerts", {});
    const [alerts, setAlerts] = useState([]);

    useEffect(() => {
        if (!enabled2fa) {
            setAlerts((alerts) => [
                ...alerts.filter((a) => a.id !== "2fa-alert"),
                {
                    id: "2fa-alert",
                    message: (
                        <span className="font-bold">
                            Enable Two-Factor Authentication to increase the
                            security of your account.
                        </span>
                    ),
                    icon: faExclamationTriangle,
                    ignorable: false,
                    href: "/account/two-factor-auth",
                },
            ]);
        } else {
            setAlerts((alerts) => [
                ...alerts.filter((a) => a.id !== "2fa-alert"),
            ]);
        }
    }, [enabled2fa]);

    return (
        <div>
            {alerts.map((alert) => {
                if (ignored[alert.id] && alert.ignorable) {
                    return <div key={alert.id} className="hidden"></div>;
                }
                return (
                    <div
                        className="flex justify-between items-center px-4 h-10 bg-red-500 text-white"
                        key={alert.id}
                    >
                        <a href={alert.href}>
                            {alert.icon && (
                                <FontAwesomeIcon
                                    icon={alert.icon}
                                    className="mr-2"
                                />
                            )}{" "}
                            {alert.message}
                        </a>
                        {alert.ignorable && (
                            <div
                                className="cursor-pointer"
                                onClick={() =>
                                    setIgnored((ignored) => ({
                                        ...ignored,
                                        [alert.id]: true,
                                    }))
                                }
                            >
                                Ignore
                            </div>
                        )}
                    </div>
                );
            })}
        </div>
    );
}
