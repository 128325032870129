import mime from "mime-types";

const downloadFile = async (
    response,
    defaultName = "",
    { open, print, addExtension } = {
        open: true,
        print: false,
        addExtension: true,
    }
) => {
    let fileName = defaultName;
    if (response.headers["content-disposition"]) {
        try {
            const disposition = response.headers["content-disposition"];
            var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
            var matches = filenameRegex.exec(disposition);
            if (matches != null && matches[1]) {
                fileName = matches[1].replace(/['"]/g, "");
            }
        } catch (e) {
            console.error(e);
        }
    }
    let extension = mime.extension(response.headers["content-type"]);

    let file = fileName;
    if (addExtension) {
        file += `.${extension}`;
    }

    const url = window.URL.createObjectURL(
        new Blob(
            [response.data],
            { type: response.headers["content-type"] },
            `${file}`
        )
    );
    const link = document.createElement("a");
    link.href = url;
    if (open) {
        link.setAttribute("target", `_blank`);
        if (print) {
            link.onclick = function (e) {
                e.preventDefault();
                window.open(this.href).print();
                return false;
            };
        }
    } else {
        link.setAttribute("download", `${file}`);
    }
    document.body.appendChild(link);
    link.click();
    link.remove();
};

function downloadFileAsLink(url, fileName) {
    const link = document.createElement("a");
    link.href =
        window.location.protocol +
        "//" +
        window.location.hostname +
        "/api/" +
        url;
    link.setAttribute("download", `${fileName}`);
    link.setAttribute("target", "_blank");
    document.body.appendChild(link);
    link.click();
    link.remove();
}

export { downloadFile, downloadFileAsLink };
