import React from "react";
import Badge from "../../components/Badge";
import TextInput from "../../components/TextInput";
import { formatDate } from "../../util/TextUtil";
import { titleCase } from "../../util/TextUtil";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";
import Money from "../../components/Money";
import useFormatCurrency from "../../hooks/useFormatCurrency";
import SaveButton from "components/SaveButton";
import { usePostMortemContext } from "views/post_mortem/PostMortemContext";
import usePostMortemState from "hooks/usePostMortemState";
import { isBool } from "index";
import useForm from "hooks/useForm";

export default function UpdateDodValues({ asset, onSave, errors }) {
    const { person } = usePostMortemState();

    const { input, updateValue, clearUnsavedChanges, hasUnsavedChanges } =
        useForm({
            dod_value: asset.dod_value_original || "",
            alt_dod_value: asset.alt_dod_value_original || "",
            dod_value_spouse: asset.dod_value_spouse_original || "",
            alt_dod_value_spouse: asset.alt_dod_value_spouse_original || "",
            dod_balance: asset.dod_balance_original || "",
            alt_dod_balance: asset.alt_dod_balance_original || "",
            dod_balance_spouse: asset.dod_balance_spouse_original || "",
            alt_dod_balance_spouse: asset.alt_dod_balance_spouse_original || "",
        });

    const { data: postMortemData } = usePostMortemContext();

    let disabled =
        asset.funding_entity ||
        asset.dod_value_locked ||
        isBool(postMortemData.is_client_deceased) === false ||
        isBool(postMortemData.is_client_dod_locked);

    if (person == "spouse") {
        disabled =
            asset.funding_entity ||
            asset.dod_value_spouse_locked ||
            isBool(postMortemData.is_spouse_deceased) === false ||
            isBool(postMortemData.is_spouse_dod_locked);
    }

    async function save(e) {
        await onSave(e, {
            ...input,
            person,
            asset_type: asset.type,
            asset_id: asset.id,
        });
        clearUnsavedChanges();
    }

    return (
        <form
            onSubmit={(e) => {
                e.preventDefault();
                save(e);
            }}
        >
            <div className="flex">
                <div className="flex mr-auto">
                    <div className="flex-none">
                        {asset.funding_entity === true && (
                            <div className="mb-2">
                                <Badge color="green">Funding Entity</Badge>
                            </div>
                        )}
                        <div className={`text-xs text-gray-500`}>
                            {titleCase(asset.type, {
                                pascal: true,
                            })}
                        </div>
                        <h2 className="text-sm">
                            <div className="flex items-start">
                                <span className="font-bold text-base">
                                    {asset.name}
                                </span>
                                {!disabled && (
                                    <a
                                        href={asset.resource_path}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="hover:text-brand-500 text-gray-600"
                                        tabIndex={-1}
                                    >
                                        &nbsp;
                                        <FontAwesomeIcon
                                            icon={faExternalLinkAlt}
                                            className="pb-1"
                                        />
                                    </a>
                                )}
                            </div>
                            {asset.account_number && (
                                <span className="text-xs text-gray-500 block mt-1">
                                    {asset.account_number}
                                </span>
                            )}
                        </h2>
                    </div>
                </div>
                <div className="flex space-x-8 items-center ml-auto flex-0">
                    <div>
                        <div className={`text-xs text-gray-500`}>
                            <ValueLabel asset={asset} />
                        </div>
                        {asset.is_liability ? (
                            <span className="text-sm text-red-500">
                                <Money
                                    value={
                                        asset.death_benefit
                                            ? asset.death_benefit
                                            : asset.gross_value
                                    }
                                />
                            </span>
                        ) : (
                            <div className="text-sm">
                                <Money
                                    value={
                                        asset.death_benefit
                                            ? asset.death_benefit
                                            : asset.net_value
                                    }
                                />
                            </div>
                        )}
                    </div>
                    <div>
                        <div className={`text-xs text-gray-500`}>
                            As Of Date
                        </div>
                        <div className="text-sm">
                            {formatDate(asset.as_of_date)}
                        </div>
                    </div>
                </div>
            </div>
            {parseInt(asset.liability_amount) > 0 && asset.has_lien && (
                <div className="border-t border-b py-3 mt-2 border-gray-200 text-gray-600">
                    <div className="flex items-center space-x-1 text-sm">
                        <span>Current Net Equity:</span>
                        <span>{<Money value={asset.gross_value} />}</span>
                        <span>-</span>
                        <span className="text-red-500">
                            {<Money value={asset.balance} />}
                        </span>
                        <span>=</span>
                        <span>
                            <Money value={asset.net_value} />
                        </span>
                    </div>
                </div>
            )}
            <div className="flex items-end space-x-2 mt-3">
                <div className="flex-1 flex items-end space-x-2">
                    <div className="flex-1">
                        {person === "client" && (
                            <ClientInputs
                                asset={asset}
                                input={input}
                                updateValue={updateValue}
                                errors={errors}
                            />
                        )}
                        {person === "spouse" && (
                            <SpouseInputs
                                asset={asset}
                                input={input}
                                updateValue={updateValue}
                                errors={errors}
                            />
                        )}
                    </div>
                    {asset.funding_entity === false && (
                        <div className="flex-none w-32">
                            <SaveButton
                                type="button"
                                tabIndex={1}
                                disabled={!hasUnsavedChanges}
                                onClick={save}
                            />
                        </div>
                    )}
                </div>
            </div>
        </form>
    );
}

export function ClientInputs({ asset, input, updateValue, errors }) {
    const { data: postMortemData } = usePostMortemContext();
    const showAlt = !!postMortemData.alternate_client_valuation_date;
    const formatCurrency = useFormatCurrency();

    const disabled =
        asset.funding_entity ||
        asset.dod_value_locked ||
        parseInt(postMortemData.is_client_deceased) === 0 ||
        parseInt(postMortemData.is_client_dod_locked) === 1;

    let placeholder = "Leave blank to use current value";
    if (asset.funding_entity && !asset.has_dod_value) {
        placeholder = "Not All Assets have DOD Values";
    }

    return (
        <div className="flex flex-col space-y-6">
            <div className="grid grid-cols-1 gap-4">
                <TextInput
                    label={`${
                        asset.type === "NonMortgageLiability"
                            ? "D.O.D Balance"
                            : "D.O.D Net Value"
                    }`}
                    id={`dod_value_${asset.type}_${asset.id}`}
                    type="text"
                    name="dod_value"
                    value={
                        asset.funding_entity
                            ? asset.dod_value_original
                            : input.dod_value
                    }
                    onChange={updateValue}
                    onBlur={(e) => {
                        if (e.target.value.length > 0) {
                            e.target.value = formatCurrency(e.target.value);
                            updateValue(e);
                        }
                    }}
                    placeholder={placeholder}
                    error={errors.dod_value}
                    disabled={disabled}
                    tabIndex={1}
                    tooltip="DOD value of asset should be entered as 100%, regardless of percentage included in estate. If the DOD Value is left blank, the as of date will not be changed when verifying."
                />
                {asset.has_lien && (
                    <LienInput
                        name="dod_balance"
                        label="D.O.D Lien Balance"
                        asset={asset}
                        input={input}
                        updateValue={updateValue}
                        errors={errors}
                        disabled={disabled}
                    />
                )}
            </div>
            {showAlt === true && (
                <div className="grid grid-cols-1 gap-4">
                    <TextInput
                        label={`${
                            asset.type === "NonMortgageLiability"
                                ? "Alternate Date Balance"
                                : "Alternate Date Value"
                        }`}
                        id={`dod_value_${asset.type}_${asset.id}`}
                        type="text"
                        name="alt_dod_value"
                        value={
                            asset.funding_entity
                                ? asset.alt_dod_value_original
                                : input.alt_dod_value
                        }
                        onChange={updateValue}
                        onBlur={(e) => {
                            if (e.target.value.length > 0) {
                                e.target.value = formatCurrency(e.target.value);
                                updateValue(e);
                            }
                        }}
                        placeholder="Leave blank to use current value"
                        error={errors.alt_dod_value}
                        disabled={
                            asset.funding_entity ||
                            asset.alt_dod_value_locked ||
                            parseInt(postMortemData.is_client_deceased) === 0 ||
                            parseInt(
                                postMortemData.is_client_dod_valuation_locked
                            ) === 1
                        }
                        tabIndex={1}
                    />
                    {asset.has_lien && (
                        <LienInput
                            name="alt_dod_balance"
                            label="Alternate Lien D.O.D Value"
                            asset={asset}
                            input={input}
                            updateValue={updateValue}
                            errors={errors}
                            disabled={disabled}
                        />
                    )}
                </div>
            )}
        </div>
    );
}

export function SpouseInputs({ asset, input, updateValue, errors }) {
    const { data: postMortemData } = usePostMortemContext();
    const showAlt = !!postMortemData.alternate_spouse_valuation_date;
    const formatCurrency = useFormatCurrency();

    const disabled =
        asset.funding_entity ||
        asset.dod_value_spouse_locked ||
        postMortemData.is_spouse_deceased === 0 ||
        postMortemData.is_spouse_dod_locked;

    let placeholder = "Leave blank to use current value";
    if (asset.funding_entity && !asset.has_dod_value_spouse) {
        placeholder = "Not All Assets have DOD Values";
    }

    return (
        <div className="flex flex-col space-y-6">
            <div className="grid grid-cols-1 gap-4">
                <TextInput
                    label={`${
                        asset.type === "NonMortgageLiability"
                            ? "Spouse D.O.D Balance"
                            : "Spouse D.O.D Value"
                    }`}
                    id={`dod_value_spouse_${asset.type}_${asset.id}`}
                    type="text"
                    name="dod_value_spouse"
                    value={
                        asset.funding_entity
                            ? asset.dod_value_spouse_original
                            : input.dod_value_spouse
                    }
                    onChange={updateValue}
                    onBlur={(e) => {
                        if (e.target.value.length > 0) {
                            e.target.value = formatCurrency(e.target.value);
                            updateValue(e);
                        }
                    }}
                    placeholder={placeholder}
                    error={errors.dod_value_spouse}
                    disabled={disabled}
                    tabIndex={1}
                />
                {asset.has_lien && (
                    <LienInput
                        name="dod_balance_spouse"
                        label="Spouse Lien D.O.D Value"
                        asset={asset}
                        input={input}
                        updateValue={updateValue}
                        errors={errors}
                        disabled={disabled}
                    />
                )}
            </div>
            {showAlt === true && (
                <div className="grid grid-cols-1 gap-4">
                    <TextInput
                        label={`${
                            asset.type === "NonMortgageLiability"
                                ? "Spouse Alternate Date Balance"
                                : "Spouse Alternate Date Value"
                        }`}
                        id={`alt_dod_value_spouse_${asset.type}_${asset.id}`}
                        type="text"
                        name="alt_dod_value_spouse"
                        value={
                            asset.funding_entity
                                ? asset.alt_dod_value_spouse_original
                                : input.alt_dod_value_spouse
                        }
                        onChange={updateValue}
                        onBlur={(e) => {
                            if (e.target.value.length > 0) {
                                e.target.value = formatCurrency(e.target.value);
                                updateValue(e);
                            }
                        }}
                        placeholder="Leave blank to use current value"
                        error={errors.alt_dod_value_spouse}
                        disabled={
                            asset.funding_entity ||
                            asset.alt_dod_value_locked ||
                            postMortemData.is_spouse_deceased === 0 ||
                            postMortemData.is_spouse_dod_valuation_locked
                        }
                        tabIndex={1}
                    />
                    {asset.has_lien && (
                        <LienInput
                            name="alt_dod_balance_spouse"
                            label="Spouse Alternate Lien D.O.D Value"
                            asset={asset}
                            input={input}
                            updateValue={updateValue}
                            errors={errors}
                            disabled={disabled}
                        />
                    )}
                </div>
            )}
        </div>
    );
}

function LienInput({
    name,
    label,
    asset,
    input,
    updateValue,
    errors,
    disabled,
}) {
    const formatCurrency = useFormatCurrency();
    return (
        <TextInput
            label={label}
            id={`${name}_${asset.type}_${asset.id}`}
            type="text"
            name={name}
            value={input[name]}
            onChange={updateValue}
            onBlur={(e) => {
                if (e.target.value.length > 0) {
                    e.target.value = formatCurrency(e.target.value);
                    updateValue(e);
                }
            }}
            placeholder="Leave blank to use current value"
            error={errors.dod_balance}
            disabled={disabled}
            tabIndex={1}
        />
    );
}

function ValueLabel({ asset }) {
    if (asset.death_benefit) {
        return <>Death Benefit</>;
    }

    return <>Current {asset.is_liability ? "Balance" : "Net Value"}</>;
}
