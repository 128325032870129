import { useState } from "react";

export default function useQuickAdd() {
    const quickAddAssets = [
        { type: "Annuity", label: "Annuity" },
        { type: "BusinessInterest", label: "Business Interest" },
        { type: "FinancialAccount", label: "Financial Account" },
        { type: "RetirementAccount", label: "Retirement Account" },
        { type: "InsurancePolicy", label: "Insurance Policy" },
        { type: "Lease", label: "Lease" },
        { type: "OtherInsurance", label: "Other Insurance" },
        { type: "PersonalProperty", label: "Personal Property" },
        { type: "RealEstate", label: "Real Estate" },
        { type: "Relationship", label: "Relationship" },
        { type: "Trust", label: "Trust" },
        { type: "Advisor", label: "Advisor" },
    ];

    const [quickAddType, setQuickAddType] = useState("");
    const [quickAddOpen, setQuickAddOpen] = useState(false);
    const [quickAddTitle, setQuickAddTitle] = useState("");

    return {
        quickAddAssets,
        quickAddType,
        quickAddOpen,
        quickAddTitle,
        setQuickAddType,
        setQuickAddOpen,
        setQuickAddTitle,
    };
}
