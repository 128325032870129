import axios from "axios";
import { createBrowserHistory } from "history";
import { showLoader, hideLoader } from "../components/Loading";

import * as Sentry from "@sentry/browser";

class Api {
    constructor() {
        this.appHistory = createBrowserHistory();

        const {
            origin,
            protocol,
            host: baseUrl,
        } = new URL(process.env.REACT_APP_BASE_URL || window.location.href);
        axios.defaults.withCredentials = true;
        if (window.location.hostname === baseUrl) {
            axios.defaults.baseURL = origin;
        } else {
            const [subdomain] = window.location.hostname.split(".");
            axios.defaults.baseURL =
                protocol + "//" + subdomain + "." + baseUrl;
        }
    }

    baseUrl() {
        const {
            origin,
            protocol,
            host: baseUrl,
        } = new URL(process.env.REACT_APP_BASE_URL || window.location.href);
        if (window.location.hostname === baseUrl) {
            return origin + "/api/";
        } else {
            const [subdomain] = window.location.hostname.split(".");
            return protocol + "//" + subdomain + "." + baseUrl + "/api/";
        }
    }

    async get(endPoint, params, config) {
        return this.makeRequest("get", endPoint, { params }, config);
    }

    async post(endPoint, data, config) {
        return this.makeRequest("post", endPoint, { data }, config);
    }

    async put(endPoint, data, config) {
        return this.makeRequest("put", endPoint, { data }, config);
    }

    async patch(endPoint, data, config) {
        return this.makeRequest("patch", endPoint, { data }, config);
    }

    async delete(endPoint, data, config) {
        return this.makeRequest("delete", endPoint, { data }, config);
    }

    async makeRequest(method, endPoint, params, config) {
        showLoader();
        const url =
            endPoint.indexOf("://") > -1
                ? endPoint
                : `${this.baseUrl()}${endPoint}`;
        try {
            hideLoader();

            const response = await axios({
                method,
                url,
                ...params,
                ...config,
            });

            // Display DD output.
            if (
                response.headers["content-type"] === "text/html; charset=UTF-8"
            ) {
                document.body.innerHTML = response.data;
                return false;
            }

            try {
                if (response.headers["x-session-expiration"]) {
                    window.localStorage.setItem(
                        "session-expiration",
                        response.headers["x-session-expiration"]
                    );
                }
            } catch (error) {
                console.error(error);
            }

            return response;
        } catch (error) {
            hideLoader();

            if (!error.response) {
                error.errorMessage = `Please check your network connection. (Code: 0)`;
                throw error;
            }

            if (error.response.status === 422) {
                setTimeout(function () {
                    let elements = document.getElementsByClassName("errorList");
                    if (elements && elements.length > 0) {
                        elements[0].scrollIntoView({
                            behavior: "smooth",
                            block: "center",
                            inline: "nearest",
                        });
                    }
                }, 10);
            }

            let href = window.location.href.toLowerCase();

            if (error.response.status === 401) {
                if (
                    href.indexOf("/login") === -1 &&
                    href.indexOf("/login/forgot") === -1 &&
                    href.indexOf("/ice") === -1 &&
                    href.indexOf("/accept-invitation") === -1 &&
                    href.indexOf("/register") === -1 &&
                    href.indexOf("/complete-registration") === -1 &&
                    href.indexOf("/complete-client-registration") === -1
                ) {
                    window.location.href = "/login";
                }
            }

            if (error.response.status === 403) {
                error.errorMessage = "403 Not Authorized";
                throw error;
            }

            // TODO: Check sentry object
            if (
                error.response.status > 499 &&
                error.response.status < 600 &&
                error.response.data.sentry
            ) {
                if (error.response.data.sentry.config.dsn) {
                    Sentry.showReportDialog({
                        ...error.response.data.sentry.config,
                        ...{ eventId: error.response.data.sentry.eventId },
                    });
                }
            }

            if (error.response.status === 404) {
                error.errorMessage = "404 Resource Not Found";
            }

            if (error.response.data && error.response.data.message) {
                error.errorMessage = error.response.data.message;
            } else if (error.response.status) {
                error.errorMessage = `An Unknown Error Occurred (Code: ${error.response.status})`;
            } else {
                error.errorMessage = `Please check your network connection. (Code: 0)`;
            }
            throw error;
        }
    }

    makeUrl(endPoint) {
        return endPoint.indexOf("://") > -1
            ? endPoint
            : `${this.baseUrl()}${endPoint}`;
    }
}

export default new Api();
