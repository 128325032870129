import React, { useState, useEffect } from "react";
import Dropdown, { DropdownItem } from "../../../components/Dropdown";
import {
    faBan,
    faEllipsisV,
    faEnvelope,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Api from "../../../services/Api";
import { useToasts } from "react-toast-notifications";
import { Link } from "react-router-dom";
import Spinner from "../../../components/Spinner";
import { formatDate } from "../../../util/TextUtil";

function PendingRelationshipsTable({ client }) {
    const { addToast } = useToasts();
    const [relationships, setRelationships] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        document.title = "Pending Relationships";
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            let response = await Api.get("invitations", {
                "filter[client_id]": client.id,
                "filter[type]": "client",
                "filter[role]": ["client_guest", "client_successor"],
                "filter[trashed]": "with",
            });
            setRelationships(response.data.data);
            setLoading(false);
        };
        fetchData();
    }, [loading, client]);

    async function resendInvitation(invitationEmail, invitationId) {
        try {
            const response = await Api.post("invitations", {
                invitees: [
                    { email: invitationEmail, invitation_id: invitationId },
                ],
            });
            addToast(response.data.message);
        } catch (error) {
            addToast(error.response.data.message, { type: "error" });
        }
    }

    async function revokeInvitation(invitationId) {
        const response = await Api.delete("invitations/" + invitationId);
        addToast(response.data.message);
    }

    if (loading) {
        return <Spinner />;
    }

    return (
        <>
            {relationships.length ? (
                <table className="w-full text-left">
                    <thead>
                        <tr>
                            <th>Email</th>
                            <th>Invited On</th>
                            <th>Expires On</th>
                            <th>Role</th>
                            <th>Status</th>
                            <th className="text-right"></th>
                        </tr>
                    </thead>
                    <tbody>
                        {relationships.map((invitation, idx) => (
                            <tr
                                key={idx}
                                className={invitation.deleted_at ? "" : ""}
                            >
                                <td>{invitation.email}</td>
                                <td>{formatDate(invitation.created_at)}</td>
                                <td>{formatDate(invitation.expires_at)}</td>
                                <td>{invitation.role_display}</td>
                                <td className="w-12">
                                    {invitation.deleted_at ? (
                                        <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium leading-4 bg-red-100 text-red-800">
                                            Revoked
                                        </span>
                                    ) : (
                                        <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium leading-4 bg-green-100 text-green-800">
                                            Pending
                                        </span>
                                    )}
                                </td>
                                <td className="text-right">
                                    <Dropdown
                                        position="right"
                                        toggleClass="px-3 py-1"
                                        toggleContent={
                                            <FontAwesomeIcon
                                                icon={faEllipsisV}
                                            />
                                        }
                                    >
                                        <DropdownItem
                                            label="Resend Invitation"
                                            icon={faEnvelope}
                                            handleClick={() =>
                                                resendInvitation(
                                                    invitation.email,
                                                    invitation.id
                                                )
                                            }
                                        />
                                        {!invitation.deleted_at && (
                                            <DropdownItem
                                                label="Revoke"
                                                icon={faBan}
                                                handleClick={() =>
                                                    revokeInvitation(
                                                        invitation.id
                                                    )
                                                }
                                            />
                                        )}
                                    </Dropdown>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            ) : (
                <div className="text-gray-400">
                    The are no relationships for this client.{" "}
                    <Link
                        to={`/clients/${client.id}/relationships/add`}
                        className="underline text-gray-600"
                    >
                        Add Now.
                    </Link>
                </div>
            )}
        </>
    );
}

export default PendingRelationshipsTable;
