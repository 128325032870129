import useClient from "hooks/useClient";
import React, { useEffect, useState } from "react";
import Api from "../services/Api";

export const RelationshipContext = React.createContext();

export default function RelationshipContextProvider({
    relationshipId = null,
    children,
}) {
    const { client } = useClient();
    const [relationshipModifiers, setRelationshipModifiers] = useState([]);
    const [directRelationships, setDirectRelationships] = useState([]);
    const [spouseModifiers, setSpouseModifiers] = useState([]);
    const [relationshipTypes, setRelationshipTypes] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const fetchDirectRelationships = async () => {
        const response = await Api.get(
            "clients/" + client.id + "/relationships"
        );
        setDirectRelationships(
            response.data.data
                .filter((relationship) => {
                    return relationship.id !== relationshipId;
                })
                .map((relationship) => {
                    return {
                        value: relationship.id,
                        label: relationship.full_name,
                    };
                })
        );
    };

    const fetchRelationshipModifiers = async () => {
        setIsLoading(true);
        const response = await Api.get("settings");
        let relationshipTypes = Object.keys(response.data.relationship_types)
            .map((key) => {
                return {
                    value: key,
                    label: response.data.relationship_types[key],
                };
            })
            .filter(
                (option) =>
                    ["Organization", "Friend", "Spouse"].indexOf(
                        option.label
                    ) === -1
            );
        setRelationshipTypes(relationshipTypes);

        let modifiers = Object.keys(response.data.relationship_modifiers).map(
            (key) => {
                return {
                    value: key,
                    label: response.data.relationship_modifiers[key],
                };
            }
        );
        setRelationshipModifiers(modifiers);
        setSpouseModifiers(
            modifiers
                .filter((mod) => {
                    return mod.label === "Ex" || mod.label === "None";
                })
                .map((mod) => {
                    if (mod.label === "None") mod.label = "Current";
                    return mod;
                })
        );
        setIsLoading(false);
    };

    useEffect(() => {
        fetchDirectRelationships();
    }, [client.id, relationshipId]);

    useEffect(() => {
        fetchRelationshipModifiers();
    }, []);

    return (
        <RelationshipContext.Provider
            value={{
                relationshipModifiers,
                spouseModifiers,
                isLoading,
                fetchRelationshipModifiers,
                directRelationships,
                relationshipTypes,
            }}
        >
            {children}
        </RelationshipContext.Provider>
    );
}
