import useFetch from "./useFetch";
import Api from "services/Api";

const resourceNameDictionary = {
    Client: "Client",
    ClientSpouse: "Client & Spouse",
    Joint: "Client & Spouse",
    Relationship: "Relationships",
    FinancialAccount: "Financial Accounts",
    RetirementAccount: "Retirement Accounts",
    BusinessInterest: "Business Interests",
    FundingEntity: "Business Interests",
    Trust: "Trusts",
    Annuity: "Annuities",
};

const fetchResourceTypes = ({ queryKey }) => {
    const [
        ,
        {
            resourceTypes,
            client,
            emptyStateLabel,
            emptyStateValue,
            emptyState,
            excludedOptions,
        },
    ] = queryKey;

    if (resourceTypes.length === 0) {
        return [];
    }

    return Promise.all(
        resourceTypes.map(async (resourceType) => {
            return Api.get(`resources/types/${client.id}/${resourceType}`).then(
                (res) => {
                    const options = [];
                    const optionsForResource = res.data.data;

                    options.push({
                        label:
                            resourceNameDictionary[resourceType] ||
                            resourceType,
                        options: optionsForResource.filter(
                            (option) =>
                                excludedOptions.indexOf(option.value) === -1
                        ),
                        disabled: false,
                        value: null,
                    });

                    if (optionsForResource.length === 0) {
                        return [
                            {
                                label: "No options available",
                                value: "",
                                disabled: true,
                            },
                        ];
                    }
                    return options;
                }
            );
        })
    ).then((results) => {
        if (!emptyStateLabel) {
            return results.flat();
        }
        return [
            {
                label: emptyStateLabel,
                value: emptyStateValue || "",
                disabled: !emptyState,
            },
            ...results.flat(),
        ];
    });
};

export default function useResourceTypeFetch(
    {
        resourceTypes,
        client,
        emptyStateLabel,
        emptyStateValue,
        emptyState,
        excludedOptions,
    },
    config = {
        initialData: [],
        keepPreviousData: true,
        refetchOnWindowFocus: false,
    }
) {
    return useFetch(
        [
            "fetchResourceType",
            {
                resourceTypes,
                client,
                emptyStateLabel,
                emptyStateValue,
                emptyState,
                excludedOptions,
            },
        ],
        fetchResourceTypes,
        config
    );
}
