import React from "react";
import { Route, Switch } from "react-router-dom";
import AddDisabilityPolicy from "./AddDisabilityPolicy";
import EditDisabilityPolicy from "./EditDisabilityPolicy";
import DisabilityPoliciesIndex from "./DisabilityPolicyIndex";

export default function DisabilityPolicies(parentProps) {
    return (
        <Switch>
            <Route
                exact
                path={[
                    "/clients/:clientId/disability-policies",
                    "/client/disability-policies",
                ]}
                render={(props) => (
                    <DisabilityPoliciesIndex {...parentProps} {...props} />
                )}
            />
            <Route
                exact
                path={[
                    "/clients/:clientId/disability-policies/add",
                    "/client/disability-policies/add",
                ]}
                render={(props) => (
                    <AddDisabilityPolicy {...parentProps} {...props} />
                )}
            />
            <Route
                path={[
                    "/clients/:clientId/disability-policies/:disabilityPolicyId/edit",
                    "/client/disability-policies/:disabilityPolicyId/edit",
                ]}
                render={(props) => (
                    <EditDisabilityPolicy {...parentProps} {...props} />
                )}
            />
            <Route
                path={[
                    "/clients/:clientId/disability-policies/:disabilityPolicyId/view",
                    "/client/disability-policies/:disabilityPolicyId/view",
                ]}
                render={(props) => (
                    <EditDisabilityPolicy
                        readOnly={true}
                        {...parentProps}
                        {...props}
                    />
                )}
            />
        </Switch>
    );
}
