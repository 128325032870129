import React from "react";
import SelectInput from "../../../components/SelectInput";
import TextInput from "../../../components/TextInput";
import FrequencySelect from "../../../components/FrequencySelect";
import Checkbox from "../../../components/Checkbox";
import ManagedByAdvisor from "../../../components/ManagedByAdvisor";
import useFormatCurrency from "../../../hooks/useFormatCurrency";

const policyTypes = [
    { value: "Disability", label: "Disability" },
    {
        value: "Long Term Care",
        label: "Long Term Care",
    },
];

const getPolicyTypeLabel = (type) => {
    const policyType = policyTypes.filter((t) => type === t.value);
    if (policyType.length === 0) return "Unknown";
    return policyType[0].label;
};

export { getPolicyTypeLabel };

export default function DisabilityPoliciesForm({
    client,
    input = {},
    errors = {},
    readOnly = false,
    onSubmit,
    updateValue,
}) {
    const formatCurrency = useFormatCurrency();
    function InsuredOptions() {
        if (client.spouse) {
            return [
                { value: "", label: "Choose an option...", disabled: true },
                { value: "Client:" + client.id, label: client.full_name },
                {
                    value: "Spouse:" + client.spouse.id,
                    label: client.spouse.full_name + " (Spouse)",
                },
            ];
        }

        return [
            { value: "", label: "Choose an option...", disabled: true },
            { value: "Client:" + client.id, label: client.full_name },
        ];
    }

    function updateInsuredField(event) {
        let value = event.target.value;
        let parts = value.split(":");
        updateValue(event);
        if (parts.length === 2) {
            updateValue({ target: { name: "insured_type", value: parts[0] } });
            updateValue({ target: { name: "insured_id", value: parts[1] } });
        }
    }

    return (
        <form onSubmit={onSubmit}>
            <div>
                <div className="mb-6">
                    <SelectInput
                        type="type"
                        name="type"
                        label="Policy Type"
                        id="type"
                        disabled={readOnly}
                        value={input.type}
                        error={errors.type}
                        onChange={updateValue}
                        autoFocus={true}
                        options={policyTypes}
                    />
                </div>

                <div className="mb-6">
                    <TextInput
                        name="number"
                        label="Policy Number"
                        id="number"
                        readOnly={readOnly}
                        disabled={readOnly}
                        value={input.number}
                        error={errors.number}
                        onChange={updateValue}
                    />
                </div>

                <div className="mb-6">
                    <TextInput
                        name="provider_name"
                        label="Company Name"
                        id="provider_name"
                        readOnly={readOnly}
                        disabled={readOnly}
                        value={input.provider_name}
                        error={errors.provider_name}
                        onChange={updateValue}
                        required={true}
                    />
                </div>

                <div className="mb-6">
                    <SelectInput
                        name="insured_key"
                        label="Insured"
                        id="insured_key"
                        value={input.insured_key}
                        disabled={readOnly}
                        error={errors.insured_key}
                        onChange={updateInsuredField}
                        options={InsuredOptions()}
                    />
                </div>

                <fieldset className="mt-12">
                    <legend className="font-bold txt-md text-gray-500 my-6 pb-1 border-b block w-full">
                        Policy Amounts
                    </legend>

                    <div className="flex space-x-4 mb-6">
                        <div className="flex-1">
                            <TextInput
                                name="premium_amount"
                                label="Premium Amount"
                                id="premium_amount"
                                readOnly={readOnly}
                                disabled={readOnly}
                                value={input.premium_amount}
                                error={errors.premium_amount}
                                onChange={updateValue}
                                onBlur={(e) => {
                                    e.target.value = formatCurrency(
                                        e.target.value
                                    );
                                    updateValue(e);
                                }}
                                placeholder="0.00"
                            />
                        </div>
                        <div className="flex-1">
                            <FrequencySelect
                                name="frequency_id"
                                label="Payment Frequency"
                                id="frequency_id"
                                disabled={readOnly}
                                value={input.frequency_id}
                                error={errors.frequency_id}
                                onChange={updateValue}
                            />
                        </div>
                    </div>

                    <div className="mb-6">
                        <div className="flex items-center space-x-3">
                            <div className="flex-1">
                                <TextInput
                                    label="Benefit Amount"
                                    name="benefit_amount"
                                    id="benefit_amount"
                                    readOnly={readOnly}
                                    disabled={readOnly}
                                    value={input.benefit_amount}
                                    error={errors.benefit_amount}
                                    onChange={updateValue}
                                    onBlur={(e) => {
                                        e.target.value = formatCurrency(
                                            e.target.value
                                        );
                                        updateValue(e);
                                    }}
                                    placeholder="0.00"
                                />
                            </div>
                            <div className="flex-1">
                                <FrequencySelect
                                    name="benefit_frequency_id"
                                    label="Benefit Frequency"
                                    id="benefit_frequency_id"
                                    disabled={readOnly}
                                    value={input.benefit_frequency_id}
                                    error={errors.benefit_frequency_id}
                                    onChange={updateValue}
                                    defaultOption={"N/A"}
                                    defaultOptionValue={0}
                                />
                            </div>
                        </div>
                    </div>
                </fieldset>

                <fieldset className="mt-12">
                    <legend className="font-bold txt-md text-gray-500 my-6 pb-1 border-b block w-full">
                        Terms
                    </legend>

                    <div className="mb-6">
                        <TextInput
                            type="number"
                            name="elimination_days"
                            label="Elimination Days"
                            readOnly={readOnly}
                            disabled={readOnly}
                            id="elimination_days"
                            value={input.elimination_days}
                            error={errors.elimination_days}
                            onChange={updateValue}
                        />
                    </div>

                    <Checkbox
                        id="lifetime_benefits"
                        name="lifetime_benefits"
                        disabled={readOnly}
                        defaultChecked={input.lifetime_benefits}
                        onChange={updateValue}
                        label="Lifetime Benefits"
                    />

                    {parseInt(input.lifetime_benefits) === 0 && (
                        <div className="mb-6 mt-6">
                            <TextInput
                                type="number"
                                name="benefit_years"
                                label="Number of Months"
                                readOnly={
                                    readOnly ||
                                    parseInt(input.lifetime_benefits) === 1
                                }
                                disabled={readOnly}
                                id="benefit_years"
                                value={
                                    parseInt(input.lifetime_benefits) === 1
                                        ? ""
                                        : input.benefit_years
                                }
                                error={errors.benefit_years}
                                onChange={updateValue}
                            />
                        </div>
                    )}
                </fieldset>

                <fieldset className="mt-12">
                    <legend className="font-bold txt-md text-gray-500 my-6 pb-1 border-b block w-full">
                        Managed By
                    </legend>
                    <ManagedByAdvisor
                        client={client}
                        input={input}
                        errors={errors}
                        disabled={readOnly}
                        updateValue={updateValue}
                    />
                </fieldset>
                <input type="submit" hidden />
            </div>
        </form>
    );
}
