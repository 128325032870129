import React from "react";
import ClientAdvisorsSelect from "./ClientAdvisorSelect";
import QuickAddProvider, { QuickAddButton } from "./QuickAddButton";
import TextInput from "./TextInput";
import ErrorBoundary from "util/ErrorBoundary";

export default function ManagedByAdvisor({
    client,
    input,
    disabled,
    errors,
    updateValue,
}) {
    return (
        <>
            <ErrorBoundary>
                <div className="flex space-x-3 items-end">
                    <div className="flex-1">
                        <ClientAdvisorsSelect
                            client={client}
                            name="managed_by_id"
                            label="Managed By"
                            id="managed_by_id"
                            disabled={disabled}
                            value={input.managed_by_id}
                            error={errors.managed_by_id}
                            onChange={updateValue}
                            prepend={{ value: 0, label: "Other" }}
                        />
                    </div>
                    <QuickAddProvider
                        client={client}
                        onSave={(data) =>
                            updateValue({
                                target: {
                                    name: "managed_by_id",
                                    value: "Advisor:" + data.id,
                                },
                            })
                        }
                    >
                        {(props) => (
                            <QuickAddButton
                                {...props}
                                singleAction="Advisor"
                                options={["Advisor"]}
                            />
                        )}
                    </QuickAddProvider>
                </div>
            </ErrorBoundary>
            {parseInt(input.managed_by_id) === 0 && (
                <div className="mt-6">
                    <div className="mb-6">
                        <TextInput
                            name="managed_by_name"
                            label="Name"
                            id="managed_by_name"
                            disabled={disabled}
                            readOnly={disabled}
                            value={input.managed_by_name}
                            error={errors.managed_by_name}
                            onChange={updateValue}
                        />
                    </div>
                    <div className="mb-6">
                        <TextInput
                            name="managed_by_phone"
                            label="Phone"
                            id="managed_by_phone"
                            disabled={disabled}
                            readOnly={disabled}
                            value={input.managed_by_phone}
                            error={errors.managed_by_phone}
                            onChange={updateValue}
                        />
                    </div>
                    <div className="mb-6">
                        <TextInput
                            type="email"
                            name="managed_by_email"
                            label="Email"
                            id="managed_by_email"
                            disabled={disabled}
                            readOnly={disabled}
                            value={input.managed_by_email}
                            error={errors.managed_by_email}
                            onChange={updateValue}
                        />
                    </div>
                    <div className="mb-6">
                        <TextInput
                            name="managed_by_address"
                            label="Address"
                            id="managed_by_address"
                            disabled={disabled}
                            readOnly={disabled}
                            value={input.managed_by_address}
                            error={errors.managed_by_address}
                            onChange={updateValue}
                        />
                    </div>
                    <div className="flex space-x-4">
                        <div className="flex-1">
                            <TextInput
                                name="managed_by_city"
                                label="City"
                                id="managed_by_city"
                                disabled={disabled}
                                readOnly={disabled}
                                value={input.managed_by_city}
                                error={errors.managed_by_city}
                                onChange={updateValue}
                            />
                        </div>
                        <div className="flex-1">
                            <TextInput
                                name="managed_by_state"
                                label="State"
                                id="managed_by_state"
                                disabled={disabled}
                                readOnly={disabled}
                                value={input.managed_by_state}
                                error={errors.managed_by_state}
                                onChange={updateValue}
                            />
                        </div>
                        <div className="flex-1">
                            <TextInput
                                name="managed_by_postal_code"
                                label="Zip"
                                id="managed_by_postal_code"
                                disabled={disabled}
                                readOnly={disabled}
                                value={input.managed_by_postal_code}
                                error={errors.managed_by_postal_code}
                                onChange={updateValue}
                            />
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}
