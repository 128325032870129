import React, { useContext } from "react";
import { BudgetContext } from "../BudgetContext";

export default function InlineHeaderItem({ itemLabel }) {
    const { clientName, hasSpouse, spouseName } = useContext(BudgetContext);

    return (
        <div className="flex space-x-3 text-brand font-bold border-b border-gray-200 mb-6 pb-2">
            <div className="w-2/5">{itemLabel}</div>
            <div className="w-1/5">Frequency</div>
            <div className="w-1/5">{clientName}</div>
            {hasSpouse && (
                <>
                    <div className="w-1/5">{spouseName}</div>
                    <div className="w-1/5">Joint</div>
                </>
            )}
        </div>
    );
}
