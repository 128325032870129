import React from "react";
import { Route, Switch } from "react-router-dom";
import Spinner from "../../components/Spinner";
import AdvisorProfile from "./AdvisorProfile";
import AdvisorPermissions from "./AdvisorPermissions";
import ViewAdvisor from "./ViewAdvisor";
import Forbidden from "../../components/Forbidden";
import useAdvisor from "hooks/useAdvisor";
import AdvisorNotes from "./AdvisorNotes";
import AdvisorRelatedFiles from "./AdvisorRelatedFiles";

export default function ManageAdvisor({ client, ...props }) {
    const { data, isLoading, unauthorized, refetch } = useAdvisor({
        clientId: client.id,
        advisorId: props.match.params.advisorId,
    });

    if (unauthorized) {
        return <Forbidden />;
    }

    if (isLoading) {
        return (
            <div className="absolute top-0 left-0 w-full h-screen flex items-center justify-center">
                <Spinner />
            </div>
        );
    }

    return (
        <>
            <Switch>
                <Route
                    exact
                    path={[
                        "/clients/:clientId/advisors/:advisorId/edit",
                        "/client/advisors/:advisorId/edit",
                    ]}
                    render={(newProps) => (
                        <AdvisorProfile
                            client={client}
                            advisor={data}
                            refetch={refetch}
                            {...props}
                            {...newProps}
                        />
                    )}
                />
                <Route
                    exact
                    path={[
                        "/clients/:clientId/advisors/:advisorId/view",
                        "/client/advisors/:advisorId/view",
                    ]}
                    render={(newProps) => (
                        <ViewAdvisor
                            client={client}
                            advisor={data}
                            {...props}
                            {...newProps}
                        />
                    )}
                />
                <Route
                    exact
                    path={[
                        "/clients/:clientId/advisors/:advisorId/notes",
                        "/client/advisors/:advisorId/notes",
                    ]}
                    render={(newProps) => (
                        <AdvisorNotes
                            client={client}
                            advisor={data}
                            {...props}
                            {...newProps}
                        />
                    )}
                />
                <Route
                    exact
                    path={[
                        "/clients/:clientId/advisors/:advisorId/related-files",
                        "/client/advisors/:advisorId/related-files",
                    ]}
                    render={(newProps) => (
                        <AdvisorRelatedFiles
                            client={client}
                            advisor={data}
                            {...props}
                            {...newProps}
                        />
                    )}
                />
                <Route
                    exact
                    path={[
                        "/clients/:clientId/advisors/:advisorId/edit-permissions",
                        "/client/advisors/:advisorId/edit-permissions",
                    ]}
                    render={(newProps) => (
                        <AdvisorPermissions
                            client={client}
                            advisor={data}
                            {...props}
                            {...newProps}
                        />
                    )}
                />
            </Switch>
        </>
    );
}
