import React from "react";
import SelectInput from "./SelectInput";
import CountryRegionData from "country-region-data/data.json";

const CountrySelector = ({ ...props }) => {
    const countries = () => {
        return [
            { value: "", label: "None" },
            { value: "US", label: "United States" },
            ...CountryRegionData.map((country) => {
                return {
                    value: country.countryShortCode,
                    label: country.countryName,
                };
            }).filter((country) => {
                return country.value !== "US";
            }),
        ];
    };

    return <SelectInput options={countries()} {...props} />;
};

export default CountrySelector;
