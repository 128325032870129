import React from "react";
import {
    faChevronLeft,
    faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export function PaginationLinks({ pagination, setCurrentPage, isSuccess }) {
    if (!isSuccess || !pagination || !pagination.total_pages) {
        return <></>;
    }
    const onEachSide = 3;

    let prevLinks = [];
    let nextLinks = [];
    let currentPage = pagination.current_page;
    let lastPage = pagination.last_page;
    if (currentPage > 1) {
        let startingPage = currentPage;
        let stopPage = startingPage - onEachSide;
        if (stopPage < 1) {
            stopPage = 1;
        }

        while (startingPage !== stopPage) {
            prevLinks.push(startingPage - 1);
            startingPage = startingPage - 1;
        }

        prevLinks.reverse();
    }

    if (currentPage < lastPage) {
        let startingPage = currentPage;
        let stopPage = startingPage + onEachSide;
        if (stopPage > lastPage) {
            stopPage = lastPage;
        }

        while (startingPage !== stopPage) {
            nextLinks.push(startingPage + 1);
            startingPage = startingPage + 1;
        }
    }

    return (
        <>
            {pagination.last_page > 1 && (
                <div className="ml-auto text-right">
                    <div className="mb-1 text-sm text-gray-900">
                        Showing {pagination.from} to {pagination.to} of{" "}
                        {pagination.total} results
                    </div>
                    <div>
                        <nav
                            className="relative z-0 inline-flex h-12 rounded-md shadow-sm -space-x-px rounded-l-md overflow-hidden border border-gray-300 divide-x"
                            aria-label="Pagination"
                        >
                            {pagination.current_page !== 1 && (
                                <button
                                    className="relative inline-flex items-center px-2 py-2 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                                    aria-label="Previous Page"
                                    onClick={() =>
                                        setCurrentPage(currentPage - 1)
                                    }
                                >
                                    <span className="sr-only">Previous</span>
                                    <div className="w-5 text-center">
                                        <FontAwesomeIcon icon={faChevronLeft} />
                                    </div>
                                </button>
                            )}
                            {pagination.current_page - onEachSide > 1 && (
                                <>
                                    <button
                                        className="relative inline-flex items-center px-4 py-2 bg-white text-sm font-medium hover:bg-gray-50 text-gray-700"
                                        onClick={() => setCurrentPage(1)}
                                    >
                                        1
                                    </button>
                                    <span className="relative inline-flex items-center px-4 py-2 bg-white text-sm font-medium text-gray-700">
                                        ...
                                    </span>
                                </>
                            )}
                            {prevLinks.map((page) => (
                                <button
                                    key={page}
                                    className="relative inline-flex items-center px-4 py-2 bg-white text-sm font-medium hover:bg-gray-50 text-gray-700"
                                    onClick={() => setCurrentPage(page)}
                                >
                                    {page}
                                </button>
                            ))}
                            <span className="relative inline-flex items-center px-4 py-2 bg-white text-sm font-bold bg-gray-50 text-gray-700">
                                {pagination.current_page}
                            </span>
                            {nextLinks.map((page) => (
                                <button
                                    key={page}
                                    className="relative inline-flex items-center px-4 py-2 bg-white text-sm font-medium hover:bg-gray-50 text-gray-700"
                                    onClick={() => setCurrentPage(page)}
                                >
                                    {page}
                                </button>
                            ))}
                            {pagination.current_page + onEachSide <
                                pagination.last_page && (
                                <>
                                    <span className="relative inline-flex items-center px-4 py-2 bg-white text-sm font-medium text-gray-700">
                                        ...
                                    </span>
                                    <button
                                        className="relative inline-flex items-center px-4 py-2 bg-white text-sm font-medium hover:bg-gray-50 text-gray-700"
                                        onClick={() =>
                                            setCurrentPage(pagination.last_page)
                                        }
                                    >
                                        {pagination.last_page}
                                    </button>
                                </>
                            )}
                            {pagination.current_page !==
                                pagination.last_page && (
                                <button
                                    className="relative inline-flex items-center px-2 py-2 rounded-r-md bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                                    aria-label="Next Page"
                                    onClick={() =>
                                        setCurrentPage(currentPage + 1)
                                    }
                                >
                                    <span className="sr-only">Next</span>
                                    <div className="w-5 text-center">
                                        <FontAwesomeIcon
                                            icon={faChevronRight}
                                        />
                                    </div>
                                </button>
                            )}
                        </nav>
                    </div>
                </div>
            )}
        </>
    );
}
