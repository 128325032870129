import React from "react";
import { Route, Switch } from "react-router-dom";
import AddAdvisor from "./AddAdvisor";
import ManageAdvisor from "./ManageAdvisors";
import AdvisorsIndex from "./AdvisorsIndex";

export default function Advisors(parentProps) {
    return (
        <Switch>
            <Route
                exact
                path={[
                    "/clients/:clientId/advisors",
                    "/clients/:clientId/advisors/disabled",
                    "/clients/:clientId/advisors/pending",
                    "/client/advisors",
                    "/client/advisors/disabled",
                    "/client/advisors/pending",
                ]}
                render={(props) => (
                    <AdvisorsIndex {...parentProps} {...props} />
                )}
            />
            <Route
                path={[
                    "/clients/:clientId/advisors/add",
                    "/client/advisors/add",
                ]}
                exact
                render={(props) => <AddAdvisor {...parentProps} {...props} />}
            />
            <Route
                path={[
                    "/clients/:clientId/advisors/:advisorId",
                    "/client/advisors/:advisorId",
                ]}
                render={(props) => (
                    <ManageAdvisor {...parentProps} {...props} />
                )}
            />
        </Switch>
    );
}
