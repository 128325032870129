import React, { useState, useEffect } from "react";
import Api from "../../services/Api";
import Modal from "../../components/Modal";
import FilePicker from "../clients/Vault/_modals/FilePicker";
import Tabs from "../../components/Tabs";
import { useToasts } from "react-toast-notifications";
import InputErrors from "../../components/InputErrors";
import { processFormErrors } from "../../util/FormsHelper";
import UploadFile from "../clients/Vault/_modals/UploadFile";

export default function ModalVaultUpload({
    client,
    fileType,
    defaultFolderName = null,
    mimeTypeFilter,
    restrictToUpload = false,
    isOpen,
    onResult,
    resourceType,
    resourceId,
}) {
    const [isLoading, setIsLoading] = useState(false);
    const [errors, setErrors] = useState([]);
    const { addToast } = useToasts();

    const [showPickerTab, setShowPickerTab] = useState(true);

    useEffect(() => {
        setShowPickerTab(restrictToUpload ? false : true);
    }, [restrictToUpload, isOpen]);

    const linkFile = async ({ id }) => {
        setErrors([]);
        try {
            setIsLoading(true);
            const response = await Api.post(`resources/relations`, {
                type: resourceType,
                resource_id: resourceId,
                vault_id: id,
            });
            addToast(`${response.data.message}`, { type: "success" });
            setIsLoading(false);
            reset();
            onResult(true);
            return true;
        } catch (e) {
            setIsLoading(false);
            console.error(e);
            setErrors(processFormErrors(e));
        }
    };

    const reset = () => {
        setIsLoading(false);
        setErrors([]);
        setShowPickerTab(true);
    };

    return (
        <Modal
            isOpen={isOpen}
            title={`Link or Upload ${fileType}`}
            showButtons={false}
            size={"lg"}
            onResult={() => {
                reset();
                onResult(false);
            }}
        >
            {!restrictToUpload && (
                <div className={"mt-4"}>
                    <Tabs
                        selectedIndex={showPickerTab ? 0 : 1}
                        items={[
                            {
                                label: "Choose from Vault",
                                onClick: () => {
                                    setShowPickerTab(true);
                                    setErrors([]);
                                },
                            },
                            {
                                label: `Upload ${fileType}`,
                                onClick: () => {
                                    setShowPickerTab(false);
                                    setErrors([]);
                                },
                            },
                        ]}
                    />
                </div>
            )}

            {errors && <InputErrors errors={errors} />}

            {!showPickerTab && (
                <UploadFile
                    client={client}
                    parentId={null}
                    defaultFolderName={defaultFolderName}
                    onResult={async (result) => {
                        if (result && result.result) {
                            await linkFile(result.result);
                        }
                    }}
                />
            )}

            {showPickerTab && (
                <FilePicker
                    client={client}
                    fileType={fileType}
                    mimeTypeFilter={mimeTypeFilter}
                    isLoading={isLoading}
                    onResult={async (result) => {
                        if (typeof result.result !== "string") {
                            onResult(false);
                            return;
                        }
                        setIsLoading(true);
                        if (!(await linkFile(result.parent))) {
                            onResult(false);
                        }
                    }}
                />
            )}
        </Modal>
    );
}
