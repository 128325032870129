import React, { useEffect, useReducer, useCallback } from "react";
import { useHistory } from "react-router-dom";
import useApi from "../hooks/useApi";
import useAuth from "../hooks/useAuth";
import {
    isRootDomain,
    redirectToRootDomain,
    redirectToSubdomain,
} from "../Utility";
import { slugify } from "../Utility";

export const RegistrationContext = React.createContext();

export default function RegistrationContextProvider({ children }) {
    const { isLoggedIn } = useAuth();
    const history = useHistory();
    const { api, errors, saving: loading } = useApi();

    const [userInput, setUserInput] = useReducer(
        (state, newState) => ({ ...state, ...newState }),
        JSON.parse(sessionStorage.getItem("userData")) || {
            email: "",
            password: "",
            password_confirmation: "",
        }
    );

    const [organizationInput, setOrganizationInput] = useReducer(
        (state, newState) => ({ ...state, ...newState }),
        JSON.parse(sessionStorage.getItem("organizationData")) || {}
    );

    function updateUserInput(e) {
        setUserInput({ [e.target.name]: e.target.value });
    }

    function updateOrganizationInput(e) {
        if (e.target.name === "subdomain") {
            return setOrganizationInput({
                subdomain: slugify(e.target.value),
            });
        }
        if (e.target.name === "name") {
            setOrganizationInput({ subdomain: slugify(e.target.value) });
        }
        setOrganizationInput({ [e.target.name]: e.target.value });
    }

    async function submitEmailPasswordForm(event) {
        event.preventDefault();
        saveUserDataToSessionStorage();
        try {
            await api("post", "register/create-member", userInput);
            history.push("/register/organization-step-1");
        } catch (error) {
            console.log(error);
        }
    }

    async function submitUpdateProfileForm(event) {
        event.preventDefault();
        saveUserDataToSessionStorage();
        try {
            await api("post", "register/update-member-profile", userInput);
            history.push("/register/organization-step-2");
        } catch (error) {
            console.log(error);
        }
    }

    async function submitOrganizationForm(event) {
        event.preventDefault();
        saveOrganizationDataToSessionStorage();
        if (loading) {
            return;
        }
        try {
            const response = await api("post", "register/create-organization", {
                ...organizationInput,
                user: userInput,
            });
            if (response.data.subdomain) {
                return redirectToSubdomain(
                    response.data.subdomain,
                    "/choose-subscription"
                );
            }
        } catch (error) {
            if (error.response && error.response.data.userInputValidation) {
                console.log(error.response.data.userInputValidation);
                history.push("/register/email-password");
            }
        }
    }

    function saveUserDataToSessionStorage() {
        sessionStorage.setItem(
            "userData",
            JSON.stringify({
                ...userInput,
                password: "",
                password_confirmation: "",
            })
        );
    }

    function saveOrganizationDataToSessionStorage() {
        sessionStorage.setItem(
            "organizationData",
            JSON.stringify({
                ...organizationInput,
            })
        );
    }

    useEffect(() => {
        if (!isRootDomain()) {
            redirectToRootDomain("register/email-password");
        }
    }, []);

    const emailOrPasswordMissing = useCallback(() => {
        return (
            isLoggedIn === false &&
            (userInput.email.length === 0 || userInput.password.length === 0)
        );
    }, [isLoggedIn, userInput.email, userInput.password]);

    return (
        <RegistrationContext.Provider
            value={{
                errors,
                loading,
                userInput,
                updateUserInput,
                organizationInput,
                emailOrPasswordMissing,
                updateOrganizationInput,
                submitEmailPasswordForm,
                submitUpdateProfileForm,
                submitOrganizationForm,
            }}
        >
            {children}
        </RegistrationContext.Provider>
    );
}
