import React, { useContext, useState, useEffect } from "react";
import Panel, { PanelHeader } from "../../../components/Panel";
import ChangeLogTable from "../../changelog/_partials/ChangeLogTable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { formatDate, formatDateTime } from "../../../util/TextUtil";
import {
    faClock,
    faEnvelopeOpenText,
    faLightbulb,
    faUsers,
} from "@fortawesome/free-solid-svg-icons";
import { AuthContext } from "../../../contexts/AuthContext";
import { TaskList } from "../../onboarding/TaskList";
import useApi from "../../../hooks/useApi";
import { UpcomingAppointmentsWidget } from "../Clients/ClientDashboard";

export default function MemberDashboard() {
    const auth = useContext(AuthContext);
    return (
        <>
            <div className="flex justify-between items-center w-full p-6">
                <h1 className="text-2xl font-bold text-gray-700">
                    Welcome, {auth.user.full_name}!
                </h1>
                <div className="pr-4">
                    <TaskList />
                </div>
            </div>
            <div className="p-6">
                <div className="lg:flex flex-row items-stretch lg:space-x-4 space-y-4 lg:space-y-0 mb-4">
                    <div className="lg:w-5/12 w-full">
                        <Panel className="h-full">
                            <PanelHeader
                                title={
                                    <div className="flex justify-between items-center w-full">
                                        <div className="flex items-center space-x-2">
                                            <FontAwesomeIcon
                                                icon={faEnvelopeOpenText}
                                                className="text-brand-green"
                                            />
                                            <div>Recent Messages</div>
                                        </div>
                                    </div>
                                }
                            ></PanelHeader>
                            <RecentMessages />
                        </Panel>
                    </div>

                    <div className="lg:w-7/12 w-full">
                        <Panel className="min-h-72 h-full">
                            <PanelHeader
                                title={
                                    <div className="flex justify-between items-center w-full">
                                        <div className="flex items-center space-x-2">
                                            <FontAwesomeIcon
                                                icon={faClock}
                                                className="text-brand-green"
                                            />
                                            <div>Recent Changes</div>
                                        </div>
                                    </div>
                                }
                            ></PanelHeader>
                            <ChangeLogTable limit={5} showFilters={false} />
                        </Panel>
                    </div>
                </div>

                <div className="lg:flex flex-row items-stretch lg:space-x-4 space-y-4 lg:space-y-0 mb-4">
                    <div className="lg:w-7/12 w-full">
                        <UpcomingAppointmentsWidget />
                    </div>

                    <div className="lg:w-5/12 w-full">
                        <Panel className="min-h-72 h-full">
                            <PanelHeader
                                title={
                                    <div className="flex justify-between items-center w-full">
                                        <div className="flex items-center space-x-2">
                                            <FontAwesomeIcon
                                                icon={faUsers}
                                                className="text-brand-green"
                                            />
                                            <div>Recent Clients</div>
                                        </div>
                                    </div>
                                }
                            ></PanelHeader>
                            <RecentClientsWidget />
                        </Panel>
                    </div>
                </div>

                <Panel>
                    <PanelHeader
                        title={
                            <div className="flex justify-between items-center w-full">
                                <div className="flex items-center space-x-2">
                                    <FontAwesomeIcon
                                        icon={faLightbulb}
                                        className="text-brand-green"
                                    />
                                    <div>Tips & Announcements</div>
                                </div>
                            </div>
                        }
                    ></PanelHeader>
                    <AnnouncementsWidget />
                </Panel>
            </div>
        </>
    );
}

export function RecentMessages() {
    const { api } = useApi();
    const [messages, setMessages] = useState([]);

    useEffect(() => {
        const fetchMessages = async () => {
            const response = await api("get", `messages/recent`, {
                limit: 4,
            });

            setMessages(response.data.data);
        };
        fetchMessages();
    }, [api]);

    if (messages.length === 0) {
        return <p>No recent messages found.</p>;
    }

    return (
        <>
            <table className="w-full text-left text-black">
                <tbody>
                    {messages.map((message, idx) => (
                        <tr key={idx}>
                            <td>
                                <div className="text-sm text-gray-500">
                                    {message.from_name}
                                </div>
                                <div className="truncate w-60">
                                    <Link
                                        to={message.link}
                                        className="text-base text-gray-700 hover:text-brand-500 font-bold"
                                    >
                                        {message.subject}
                                    </Link>
                                </div>
                            </td>
                            <td>
                                <div className="text-sm text-gray-700 text-right">
                                    {formatDate(message.sent_at)}
                                </div>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </>
    );
}

export function AnnouncementsWidget() {
    const { api } = useApi();
    const [announcements, setAnnouncements] = useState([]);

    useEffect(() => {
        const fetchAnnouncements = async () => {
            const response = await api("get", `announcements`, {
                limit: 4,
            });

            setAnnouncements(response.data.data);
        };
        fetchAnnouncements();
    }, [api]);

    if (announcements.length === 0) {
        return <p>No announcements at this time.</p>;
    }

    return (
        <>
            <div className="w-full text-let space-y-6">
                {announcements.map((announcement, idx) => (
                    <div key={`announcement_${idx}`}>
                        <h2 className="text-gray-900 text-lg font-bold">
                            {announcement.subject}
                        </h2>
                        <p
                            key={idx}
                            className="text-gray-700 text-base whitespace-pre-line"
                        >
                            {announcement.content}
                        </p>
                    </div>
                ))}
            </div>
        </>
    );
}

export function RecentClientsWidget() {
    const { api } = useApi();
    const [clients, setClients] = useState([]);

    useEffect(() => {
        const fetchClients = async () => {
            const response = await api("get", `access-logs`, {
                "filter[resource_type]": "Client",
                // "filter[unique]": true,
                sort: "-created_at",
                limit: 5,
            });

            setClients(response.data.data);
        };
        fetchClients();
    }, [api]);

    if (clients.length === 0) {
        return <p>No clients accessed recently.</p>;
    }

    return (
        <>
            <table className="w-full text-left text-black">
                <tbody>
                    {clients.map((client, idx) => (
                        <tr key={idx}>
                            <td>
                                <div className="text-sm text-gray-500">
                                    <Link
                                        to={client.resource_url}
                                        className="text-base text-gray-700 hover:text-brand-500 font-bold"
                                    >
                                        {client.resource_name}
                                    </Link>
                                </div>
                            </td>
                            <td>
                                <div className="text-sm text-gray-700 text-right">
                                    {formatDateTime(client.created_at)}
                                </div>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </>
    );
}
