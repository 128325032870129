import React from "react";

export default function Pagination({
    current_page,
    from,
    last_page,
    to,
    total,
    nextPage,
}) {
    if (total === undefined) {
        return <></>;
    }
    return (
        <nav className="flex items-center justify-center space-x-4 text-sm py-6">
            {current_page > 1 && (
                <a
                    href="#next"
                    onClick={(event) => {
                        event.preventDefault();
                        nextPage(Math.max(current_page - 1, 0));
                    }}
                    className="text-brand-400 underline"
                >
                    Prev
                </a>
            )}
            <div>
                {from} - {to} of {total}
            </div>
            {last_page > 1 && last_page !== current_page && (
                <a
                    onClick={(event) => {
                        event.preventDefault();
                        nextPage(Math.min(current_page + 1, last_page));
                    }}
                    href="#next"
                    className="text-brand-400 underline"
                >
                    Next
                </a>
            )}
        </nav>
    );
}
