import React, { useState, useEffect } from "react";

import TextInput from "components/TextInput";
import Button from "components/Button";
import useForm from "hooks/useForm";
import Api from "services/Api";

export default function ChangePasswordForm({ onVerify }) {
    let message = "A password change is required.";
    let errorMessage = "";
    const [isLoading, setIsLoading] = useState(false);
    const [errors, setErrors] = useState({});
    const { input, updateValue, clearUnsavedChanges } = useForm({
        password: "",
        password_confirmation: "",
    });

    useEffect(() => {
        clearUnsavedChanges();
    }, [input]);

    const handleSubmit = async (e) => {
        try {
            e.preventDefault();
            setErrors({});
            setIsLoading(true);
            await Api.post("login/password-update", input);
            onVerify();
        } catch (e) {
            setIsLoading(false);
            if (e?.response?.data?.errors) {
                setErrors(e.response.data.errors);
            }
        }
    };

    return (
        <>
            <div className="flex flex-col">
                <h1 className="text-center text-3xl mb-4">Change Password</h1>
                {message && (
                    <div className="max-w-xs mx-auto mb-8 p-3 text-center">
                        {message}
                    </div>
                )}
                {errorMessage && (
                    <div className="max-w-xs mx-auto mb-3 p-3 text-center rounded-md bg-gray-50 text-red-600">
                        {errorMessage}
                    </div>
                )}
                <form
                    onSubmit={handleSubmit}
                    className="max-w-xs mx-auto w-full"
                >
                    <div className="mb-6 relative flex space-x-2 items-center">
                        <span className="text-gray-500">
                            <svg
                                className="h-6 w-6"
                                fill="none"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                            >
                                <path d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z"></path>
                            </svg>
                        </span>
                        <div className="flex-1">
                            <TextInput
                                className="border-b border-gray-400 outline-none h-10 w-full px-1"
                                styleType={false}
                                name="password"
                                id="password"
                                type="password"
                                value={input.password}
                                onChange={updateValue}
                                error={errors.password}
                                placeholder="New Password"
                                autoComplete="new-password"
                            />
                        </div>
                    </div>

                    <div className="mb-6 relative flex space-x-2 items-center">
                        <span className="text-gray-500">
                            <svg
                                className="h-6 w-6"
                                fill="none"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                            >
                                <path d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z"></path>
                            </svg>
                        </span>
                        <div className="flex-1">
                            <TextInput
                                className="border-b border-gray-400 outline-none h-10 w-full px-1"
                                styleType={false}
                                name="password_confirmation"
                                id="password_confirmation"
                                type="password"
                                value={input.password_confirmation}
                                onChange={updateValue}
                                error={errors.password_confirmation}
                                placeholder="Confirm Password"
                                autoComplete="confirm-password"
                            />
                        </div>
                    </div>

                    <div className="w-64 mx-auto">
                        <Button
                            type="submit"
                            text="Change Password"
                            isLoading={isLoading}
                        />
                    </div>
                </form>
            </div>
        </>
    );
}
