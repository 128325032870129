import React from "react";
import SelectInput from "./SelectInput";
import Api from "../services/Api";

export default function InsuredSelect(props) {
    const [loading, setLoading] = React.useState(true);
    const [options, setOptions] = React.useState([]);

    function getOptions() {
        return options
            .filter((option) => {
                if (option.value === "spouse" && !props.client.spouse) {
                    return false;
                }
                return true;
            })
            .map((option) => {
                let label = option.label;

                if (option.value === "client") {
                    label = props.client.full_name || option.label;
                }

                if (option.value === "spouse" && props.client.spouse) {
                    label =
                        props.client.spouse.full_name + " (Spouse)" ||
                        option.label;
                }

                return { value: option.value, label: label };
            });
    }

    React.useEffect(() => {
        const fetchData = async () => {
            try {
                let response = await Api.get("enums/insured");
                setOptions(response.data);
                setLoading(false);
            } catch (error) {
                console.log(error);
            }
        };
        fetchData();
    }, []);

    return <SelectInput options={getOptions()} loading={loading} {...props} />;
}
