import Api from "./Api";

export const saveBeneficiaryTransfer = async function (params, formData) {
    return await Api.post(
        "clients/" + params.clientId + "/beneficiaries/transfer",
        formData
    ).then((res) => res.data);
};

export default {
    saveBeneficiaryTransfer,
};
