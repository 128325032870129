import React from "react";
import AccountNav from "./_partials/AccountNav";
import PageHeader from "../../components/PageHeader";
import PageContainer from "../../components/PageContainer";
import useAuth from "hooks/useAuth";
import useSwitchAccount from "hooks/useSwitchAccount";
import { titleCase } from "util/TextUtil";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";

export default function SwitchAccount({ ...props }) {
    document.title = "Switch Accounts";
    const { accounts, user } = useAuth();

    const { onSubmit } = useSwitchAccount();

    const handleClick = (event, account) => {
        onSubmit(event, {
            org_user_id: account.id,
        });
    };

    return (
        <>
            <PageHeader text="Switch Accounts" />
            <PageContainer>
                <div className="flex space-x-10">
                    <div className="flex-initial">
                        <AccountNav active={props.history.location.pathname} />
                    </div>
                    <div className="flex-1">
                        <p>
                            Below is a list of all accounts linked to this email
                            on the Inheralink Platform. You can log into your
                            other roles through the list below.
                        </p>
                        <table className="w-full text-left">
                            <thead>
                                <tr>
                                    <th>Organization</th>
                                    <th>Role</th>
                                    <th>Client</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {accounts.map((account) => (
                                    <tr key={account.id}>
                                        <td>
                                            <button
                                                type="button"
                                                onClick={(e) =>
                                                    handleClick(e, account)
                                                }
                                            >
                                                {account.organization_name}
                                            </button>
                                        </td>
                                        <td>
                                            {titleCase(
                                                account.role.replaceAll(
                                                    "_",
                                                    " "
                                                )
                                            )}
                                        </td>
                                        <td>
                                            {titleCase(
                                                account.type === "member"
                                                    ? "All"
                                                    : account.role ===
                                                      "client_owner"
                                                    ? account.user_full_name
                                                    : account.target_full_name
                                            )}
                                        </td>
                                        <td>
                                            <div className={"flex"}>
                                                <div className={"flex-0"}>
                                                    {user.logged_in_id ===
                                                    account.id ? (
                                                        <button
                                                            type="button"
                                                            className="border-0 border-transparent shadow-none text-green-400 hover:text-brand-700 rounded-sm px-0 font-semibold"
                                                            onClick={(e) =>
                                                                handleClick(
                                                                    e,
                                                                    account
                                                                )
                                                            }
                                                        >
                                                            Active{" "}
                                                            <FontAwesomeIcon
                                                                icon={
                                                                    faCheckCircle
                                                                }
                                                            />
                                                        </button>
                                                    ) : (
                                                        <button
                                                            type="button"
                                                            className="bg-transparent border-0 border-transparent shadow-none text-brand hover:text-brand-700 rounded-sm px-0"
                                                            onClick={(e) =>
                                                                handleClick(
                                                                    e,
                                                                    account
                                                                )
                                                            }
                                                        >
                                                            Switch
                                                        </button>
                                                    )}
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </PageContainer>
        </>
    );
}
