import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Badge from "components/Badge";
import Button from "components/Button";
import BasicModal from "components/ModalBasic";
import usePrices from "hooks/usePrices";
import React, { useState } from "react";
import Money from "../../components/Money";

export default function Plans({
    selectedPrice,
    primarySubscriptionPrice = null,
    onChange,
}) {
    const [selectedInterval, setSelectedInterval] = useState("month_6");
    const [showDetailsModal, setShowDetailsModal] = useState(0);

    const { data } = usePrices();

    const intervals = data?.intervals || [];

    const toggleInterval = (interval) => {
        setSelectedInterval(interval);
        onChange(null);
    };

    const filteredPrices = () => {
        return (
            data?.prices?.filter(
                (price) => price.interval_id === selectedInterval
            ) || []
        );
    };

    const activePrice = data?.prices?.find(
        (price) => price.id === primarySubscriptionPrice
    );

    if (data?.prices?.length === 0) {
        return <div>Loading subscription options...</div>;
    }

    if (filteredPrices()?.length === 0) {
        return <div>No pricing options were found...</div>;
    }

    return (
        <div>
            {!!activePrice && (
                <div className="mb-12">
                    <CurrentPlan
                        plan={activePrice}
                        setShowDetailsModal={setShowDetailsModal}
                    />
                    <BasicModal
                        isOpen={activePrice.id === showDetailsModal}
                        onClose={() => setShowDetailsModal(0)}
                        title="Included With Plan:"
                        header={null}
                        modalBodyClassName="px-6 pb-6 pt-0 -mt-6"
                    >
                        <h2 className="font-bold text-lg mb-2">
                            {activePrice.name}
                        </h2>
                        {activePrice.metadata.length > 0 ? (
                            <ul className="space-y-1 list-disc pl-4">
                                {activePrice.metadata.map((data) => (
                                    <li key={data}>{data}</li>
                                ))}
                            </ul>
                        ) : (
                            <p>
                                Please visit https://inheralink.com for plan
                                details.
                            </p>
                        )}
                    </BasicModal>
                </div>
            )}
            <div>
                <div className="flex space-x-2 mb-4">
                    {intervals.map((interval) => (
                        <Button
                            key={interval.id}
                            type="button"
                            appearance={
                                selectedInterval === interval.id
                                    ? "default"
                                    : "light"
                            }
                            onClick={() => toggleInterval(interval.id)}
                        >
                            {interval.label}
                        </Button>
                    ))}
                </div>
                <ul className="space-y-3">
                    {filteredPrices().map((plan) => (
                        <li key={`plan_${plan.id}`} className="relative">
                            <Price
                                plan={plan}
                                selectedPrice={selectedPrice}
                                onChange={onChange}
                                setShowDetailsModal={setShowDetailsModal}
                                disabled={plan.id === primarySubscriptionPrice}
                                active={plan.id === primarySubscriptionPrice}
                            />
                            {plan.id !== primarySubscriptionPrice && (
                                <BasicModal
                                    isOpen={plan.id === showDetailsModal}
                                    onClose={() => setShowDetailsModal(0)}
                                    title="Included With Plan:"
                                    header={null}
                                    modalBodyClassName="px-6 pb-6 pt-0 -mt-6"
                                >
                                    <h2 className="font-bold text-lg mb-2">
                                        {plan.name}
                                    </h2>
                                    {plan.metadata.length > 0 ? (
                                        <ul className="space-y-1 list-disc pl-4">
                                            {plan.metadata.map((data) => (
                                                <li key={data}>{data}</li>
                                            ))}
                                        </ul>
                                    ) : (
                                        <p>
                                            Please visit https://inheralink.com
                                            for plan details.
                                        </p>
                                    )}
                                </BasicModal>
                            )}
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
}

const Price = ({
    plan,
    selectedPrice,
    onChange,
    setShowDetailsModal,
    disabled = false,
    active = false,
}) => {
    return (
        <label
            htmlFor={`plan_${plan.id}`}
            className={`flex items-center justify-between bg-white cursor-pointer rounded-md py-6 px-6 border ${
                selectedPrice === plan.id
                    ? "border-brand bg-brand-50 text-brand-800"
                    : "bg-white border-gray-300 text-gray-700"
            } ${disabled ? "pointer-events-none opacity-75" : ""}`}
        >
            <div className="flex items-center space-x-3">
                <input
                    type="radio"
                    name={`selected_plan`}
                    id={`plan_${plan.id}`}
                    value={plan.id}
                    checked={selectedPrice === plan.id}
                    onChange={(e) => onChange(e.target.value)}
                    className="text-brand-500 h-4 w-4"
                />
                <div>
                    <div className="flex space-x-2 items-end">
                        <div>
                            {!!active && (
                                <div>
                                    <Badge color="brand">Current Plan</Badge>
                                </div>
                            )}
                            <span className="font-bold">{plan.name}</span>
                        </div>
                        <button
                            type="button"
                            className="text-gray-500 hover:text-brand-700"
                            onClick={() => setShowDetailsModal(plan.id)}
                        >
                            <FontAwesomeIcon icon={faInfoCircle} />
                        </button>
                    </div>
                    <div className="text-sm">{plan.description}</div>
                </div>
            </div>
            <span className="text-right">
                <Money value={plan.monthly_price} />{" "}
                <span className="text-sm text-gray-500">/ month</span>
                {plan.price_description && (
                    <div className="text-xs text-gray-500">
                        {plan.price_description}
                    </div>
                )}
            </span>
        </label>
    );
};

const CurrentPlan = ({ plan, setShowDetailsModal }) => {
    return (
        <label
            htmlFor={`plan_${plan.id}`}
            className={`flex items-center justify-between cursor-pointer rounded-md py-3 px-6 border-2 border-brand bg-brand-50 text-brand-800 min-h-28`}
        >
            <div className="flex items-center space-x-3">
                <div>
                    <div className="font-bold text-brand">Current Plan</div>
                    <div className="flex space-x-2">
                        <div className="font-bold">{plan.name}</div>
                        <button
                            type="button"
                            className="text-gray-500 hover:text-brand-700"
                            onClick={() => setShowDetailsModal(plan.id)}
                        >
                            <FontAwesomeIcon icon={faInfoCircle} />
                        </button>
                    </div>
                    <div className="text-sm">{plan.description}</div>
                </div>
            </div>
            <span className="text-right">
                <Money value={plan.monthly_price} />{" "}
                <span className="text-sm text-gray-500">/ month</span>
                {plan.price_description && (
                    <div className="text-xs text-gray-500">
                        {plan.price_description}
                    </div>
                )}
            </span>
        </label>
    );
};
