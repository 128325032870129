import React, { Component } from "react";
import Button from "./Button";
import { HashLink } from "react-router-hash-link";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretRight } from "@fortawesome/free-solid-svg-icons";
import { Transition } from "@headlessui/react";
import { classNames } from "Utility";

export default class Dropdown extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
        };

        this.setWrapperRef = this.setWrapperRef.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }

    setWrapperRef(node) {
        this.wrapperRef = node;
    }

    handleClickOutside(event) {
        if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
            this.closeMenu();
        }
    }

    toggleMenu = (e) => {
        e.stopPropagation();
        e.preventDefault();
        this.state.isOpen ? this.closeMenu() : this.openMenu();
    };

    openMenu = () => {
        this.setState({ isOpen: true });
        document.addEventListener("mouseup", this.handleClickOutside);
    };

    closeMenu = () => {
        this.setState({ isOpen: false });
        document.removeEventListener("mouseup", this.handleClickOutside);
    };

    componentWillUnmount = () => {
        document.removeEventListener("mouseup", this.handleClickOutside);
    };

    handleOnClick = (e) => {
        if (this.props.stopPropagation) {
            e.stopPropagation();
        }
        this.closeMenu();
    };

    getPosition = () => {
        if (!this.props.position || this.props.position === "left") {
            return "left-0";
        }

        if (this.props.position === "left-side") {
            return "left-10 bottom-10";
        }

        if (this.props.position === "left-full") {
            return "left-full";
        }

        return "right-0";
    };

    render() {
        return (
            <div
                className={`relative ${this.props.containerClass}`}
                ref={this.setWrapperRef}
            >
                {this.props.toggleButton ? (
                    <Button
                        onClick={this.toggleMenu}
                        isLoading={this.props.isLoading}
                        appearance={
                            this.props.toggleButtonAppearance || "default"
                        }
                    >
                        {this.props.toggleButton}
                    </Button>
                ) : (
                    <button
                        type="button"
                        className={
                            this.props.toggleClass
                                ? this.props.toggleClass.concat(
                                      " focus:outline-none"
                                  )
                                : "focus:outline-none"
                        }
                        onClick={this.toggleMenu}
                    >
                        {this.props.toggleContent}
                    </button>
                )}
                <Transition
                    show={this.state.isOpen}
                    enter="relative z-50 transition duration-100 ease-out"
                    enterFrom="relative z-50 transform scale-95 opacity-0"
                    enterTo="relative z-50 transform scale-100 opacity-100"
                    leave="relative z-50 transition duration-75 ease-out"
                    leaveFrom="relative z-50 transform scale-100 opacity-100"
                    leaveTo="relative z-50 transform scale-95 opacity-0"
                >
                    <ul
                        onClick={(e) => this.handleOnClick(e)}
                        className={`z-50 absolute ${this.getPosition()} mt-2 max-w-sm min-w-56 py-1 rounded-md bg-white shadow-md`}
                    >
                        {this.props.children}
                    </ul>
                </Transition>
            </div>
        );
    }
}

const menuStyle =
    "flex items-center w-full text-left appearance-none px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900";

const MenuIcon = ({ icon }) => {
    return (
        <FontAwesomeIcon
            icon={icon}
            className="mr-2 text-gray-400"
            fixedWidth
        />
    );
};
const CustomLink = ({ icon, label, handleClick, disabled = false }) => (
    <button
        type="button"
        className={menuStyle}
        onClick={handleClick}
        disabled={disabled}
    >
        {!!icon && <MenuIcon icon={icon} />} {label}
    </button>
);

export const DropdownItem = ({
    label = "",
    link = "",
    icon = null,
    disabled = false,
    ...props
}) => {
    const handleClick = React.useCallback((e) => {
        e.preventDefault();
        props.handleClick(e);
    }, []);

    return (
        <li
            className={classNames(
                "drowdown-menu-item whitespace-no-wrap",
                disabled && "opacity-50 cursor-not-allowed"
            )}
        >
            {link ? (
                <HashLink to={disabled ? "#" : link} className={menuStyle}>
                    {icon ? <MenuIcon icon={icon} /> : ""} {label}
                </HashLink>
            ) : (
                <CustomLink
                    icon={icon}
                    label={label}
                    handleClick={handleClick}
                    disabled={disabled}
                />
            )}
        </li>
    );
};

export function DropdownSubmenu({ label, children }) {
    const menuStyle =
        "relative flex justify-between flex-1 items-center w-full text-left appearance-none px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900";
    return (
        <Dropdown
            containerClass="w-full flex"
            toggleClass="w-full flex-1"
            position="left-full"
            toggleContent={
                <span className={menuStyle}>
                    {label}
                    <FontAwesomeIcon
                        icon={faCaretRight}
                        className="text-gray-400"
                    />
                </span>
            }
        >
            {children}
        </Dropdown>
    );
}

export class DropdownDivider extends Component {
    render() {
        return <div className="border-t border-gray-100"></div>;
    }
}
