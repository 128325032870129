import React, { useState, useEffect } from "react";

import Api from "../../services/Api";

import { faUserCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useToasts } from "react-toast-notifications";

import Spinner from "../../components/Spinner";
import ModalVaultUploadPhoto from "./ModalVaultUploadPhoto";

export default function VaultProfilePhoto({
    client,
    resourceId,
    resourceType,
    photoType = "Profile",
    rounded = true,
    justifyCenter = true,
    readOnly = false,
}) {
    const { addToast } = useToasts();
    const [hover, setHover] = useState(false);
    const [showPicker, setShowPicker] = useState(false);
    const [primaryPhoto, setPrimaryPhoto] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [forceUpdate, setForceUpdate] = useState(0);

    useEffect(() => {
        const fetch = async () => {
            setIsLoading(true);
            const response = await Api.get(
                `resources/relations/files/${resourceType}/${resourceId}`
            );
            const primary = response.data.files.filter(
                (file) => file.is_primary_photo
            );
            setPrimaryPhoto(primary.length > 0 ? primary[0] : false);
            setIsLoading(false);
        };
        fetch();
    }, [resourceType, resourceId, client, forceUpdate]);

    const clearPhoto = async () => {
        // TODO: check this
        const vaultId = primaryPhoto.id;
        // setErrors([]);
        try {
            setIsLoading(true);
            await Api.post(`resources/relations`, {
                type: resourceType,
                resource_id: resourceId,
                is_primary_photo: false,
                vault_id: vaultId,
            });
            addToast(`${photoType} photo has been unset`, { type: "success" });
            setIsLoading(false);
            setShowPicker(false);
            setForceUpdate(forceUpdate + 1);
            return true;
        } catch (e) {
            setIsLoading(false);
            console.error(e);
            // setErrors(processFormErrors(e))
        }
    };

    const photoUrl = primaryPhoto
        ? Api.baseUrl() +
          `clients/${primaryPhoto.client.id}/vault/${primaryPhoto.id}/download?preview=1`
        : null;

    return (
        <>
            <div
                className={`flex items-center ${
                    justifyCenter ? "justify-center" : ""
                } relative z-auto ${readOnly ? "" : "cursor-pointer"}`}
                onMouseOver={() => setHover(true)}
                onMouseLeave={() => setHover(false)}
            >
                {isLoading ? (
                    <Spinner />
                ) : (
                    <div
                        className="text-center"
                        onClick={() => setShowPicker(true)}
                    >
                        {primaryPhoto ? (
                            <img
                                src={photoUrl}
                                className={`object-cover h-48 w-48 ${
                                    rounded ? "rounded-full" : ""
                                } shadow-inner`}
                                alt="Member"
                            />
                        ) : (
                            <div
                                className={`flex items-center justify-center text-center bg-gray-100 ${
                                    readOnly === false
                                        ? "hover:bg-gray-200 hover:border-gray-400"
                                        : ""
                                }  border-2 border-dashed h-56 w-56 p-2`}
                            >
                                <div>
                                    <FontAwesomeIcon
                                        icon={faUserCircle}
                                        size="8x"
                                        className="text-gray-400"
                                    />
                                    <p className="mt-2 text-gray-400 text-sm">
                                        {readOnly === false
                                            ? `+ Add ${photoType} photo`
                                            : `No ${photoType} Photo`}
                                    </p>
                                </div>
                            </div>
                        )}

                        {primaryPhoto && hover && readOnly === false && (
                            <>
                                <div className="absolute top-0 right-0 bottom-0 left-0 flex flex-col space-y-4 items-center justify-center">
                                    <button
                                        type="button"
                                        className="text-center w-32 flex-0 py-1 px-2 shadow-sm bg-brand-600 text-white hover:bg-brand-700 bg-opacity-75 rounded-sm text-sm"
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            setShowPicker(true);
                                        }}
                                    >
                                        Edit
                                    </button>

                                    <button
                                        type="button"
                                        className="text-center w-32 flex-0 py-1 px-2 shadow-sm bg-brand text-white hover:bg-brand-700 bg-opacity-75 rounded-sm text-sm"
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            clearPhoto();
                                        }}
                                    >
                                        Remove
                                    </button>
                                </div>
                            </>
                        )}
                    </div>
                )}

                {readOnly === false && (
                    <ModalVaultUploadPhoto
                        client={client}
                        isOpen={showPicker}
                        primaryPhoto={primaryPhoto}
                        photoType={photoType}
                        photoUrl={photoUrl}
                        resourceType={resourceType}
                        resourceId={resourceId}
                        onResult={(result) => {
                            setShowPicker(false);
                            if (result) {
                                setForceUpdate(forceUpdate + 1);
                            }
                        }}
                    />
                )}
            </div>
        </>
    );
}
