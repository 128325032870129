import React from "react";
import Forbidden from "./Forbidden";
import Spinner from "./Spinner";

export default function PageContainer({
    children,
    isLoading = false,
    loadingMessage = "Loading...",
    forbidden = false,
    forbiddenText = null,
}) {
    if (forbidden) {
        return (
            <div className="px-8 py-8 mb-12">
                <Forbidden text={forbiddenText} />
            </div>
        );
    }

    if (isLoading) {
        return (
            <div className="h-auto flex items-center justify-center mt-20">
                <Spinner message={loadingMessage} />
            </div>
        );
    }

    return <div className="px-8 py-8 mb-12">{children}</div>;
}
