import React, { useState } from "react";
import useHasAnyPermissions from "hooks/useHasAnyPermissions";
import ClientPageHeader, { Heading } from "components/ClientPageHeader";
import PageContainer from "components/PageContainer";
import Button from "components/Button";
import Api from "services/Api";
import { downloadFile } from "util/Downloader";
import { useToasts } from "react-toast-notifications";
import useClient from "hooks/useClient";

export default function PersonalInfoReport({ client, user }) {
    const forbidden = !useHasAnyPermissions([
        "report_global_access",
        "report_personal_info_report",
    ]);
    const { clientPath } = useClient();
    const { addToast } = useToasts();
    const [isBlankExporting, setIsBlankExporting] = useState(false);
    const [isExporting, setIsExporting] = useState(false);

    document.title = "Personal Info Report";

    async function exportPersonalInfoReport(e) {
        e.preventDefault();
        try {
            setIsExporting(true);
            let response = await Api.get(
                "clients/" + client.id + "/reports/personal-info",
                {
                    export: "pdf",
                    printBlankReport: 0,
                },
                { responseType: "arraybuffer" }
            );
            await downloadFile(response, "personal-info");
            setIsExporting(false);
            addToast("Export downloaded");
        } catch (e) {
            addToast(e.response.data.message, { type: "error" });
            setIsExporting(false);
        }
    }

    async function exportBlankReport(e) {
        e.preventDefault();
        try {
            setIsBlankExporting(true);
            let response = await Api.get(
                "clients/" + client.id + "/reports/personal-info",
                {
                    export: "pdf",
                    printBlankReport: 1,
                },
                { responseType: "arraybuffer" }
            );
            await downloadFile(response, "blank-personal-info");
            setIsBlankExporting(false);
            addToast("Export downloaded");
        } catch (e) {
            addToast(e.response.data.message, { type: "error" });
            setIsBlankExporting(false);
        }
    }

    return (
        <>
            <ClientPageHeader client={client}>
                <Heading
                    backTo={`${clientPath}/reports`}
                    backText="All Reports"
                >
                    Personal Info Report
                </Heading>
            </ClientPageHeader>
            <PageContainer forbidden={forbidden}>
                <div className="space-x-2">
                    {user.type !== "client" && (
                        <Button
                            text="Export Blank Report"
                            width="w-auto"
                            isLoading={isBlankExporting}
                            onClick={exportBlankReport}
                        />
                    )}
                    <Button
                        text="Export Personal Info Report"
                        width="w-auto"
                        isLoading={isExporting}
                        onClick={exportPersonalInfoReport}
                    />
                </div>
            </PageContainer>
        </>
    );
}
