// https://goshakkk.name/array-form-inputs/
import React, { useState, useEffect, useReducer } from "react";
import TextInput from "../../components/TextInput";
import Api from "../../services/Api";
import { useToasts } from "react-toast-notifications";
import Button from "../../components/Button";
import { faUserPlus, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { Redirect } from "react-router-dom";
import Select from "react-select/async";
import debounce from "../../util/debounce-promise";
import InputErrors from "../../components/InputErrors";
import GenderSelect from "../../components/GenderSelect";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Toggle from "../../components/Toggle";
import { fetchAttorneys } from "../../services/MemberService";
import { classNames } from "Utility";
import SelectInput from "components/SelectInput";
import useQuestionnaires from "hooks/useQuestionnaires";

function AddClient(props) {
    const { addToast } = useToasts();
    const [redirectToClientIndex, setRedirectToClientIndex] = useState(false);
    const [errors, setErrors] = useState([]);
    const [isSaving, setIsSaving] = useState(false);

    const [clientInput, setClientInput] = useReducer(
        (state, newState) => ({ ...state, ...newState }),
        {
            primary_attorney_id: null,
            first_name: "",
            last_name: "",
            middle_name: "",
            email: "",
            phone: "",
            address: "",
            city: "",
            state: "",
            postal_code: "",
            role: "client_owner",
            welcome_email: true,
            questionnaire_id: null,
        }
    );

    const { data: questionnaires } = useQuestionnaires({
        memberId: null,
    });

    useEffect(() => {
        document.title = "Add Client";
    }, []);

    function updateValue(event) {
        setClientInput({ [event.target.name]: event.target.value });
    }

    function updatePrimaryAccount(value) {
        setClientInput({ primary_attorney_id: value });
    }

    const getAttorneyOptions = debounce((e) => fetchAttorneys(e), 250);

    async function createClient(e) {
        e.preventDefault();
        if (isSaving) {
            return true;
        }
        try {
            setIsSaving(true);
            let response = await Api.post("clients", clientInput);
            addToast(response.data.message);
            setErrors([]);
            props.onCancel();
            setRedirectToClientIndex(true);
            setIsSaving(false);
            props.onCancel();
            setTimeout(() => {
                window.location.reload();
            }, 250);
        } catch (e) {
            setIsSaving(false);
            setErrors([]);
            if (e.response.data.errors) {
                setErrors(e.response.data.errors);
            } else {
                addToast(e.response.data.message, { type: "error" });
            }
        }
    }

    if (redirectToClientIndex) {
        return <Redirect to="/clients" />;
    }

    return (
        <>
            <form onSubmit={createClient} autoComplete="off">
                <div className="flex items-center justify-between mb-6">
                    <div className="text-xl">Add Client Manually</div>
                    <button type="button" onClick={props.onCancel}>
                        <FontAwesomeIcon
                            icon={faTimesCircle}
                            className="text-gray-400 text-lg"
                        />
                    </button>
                </div>
                <div className="h-96 overflow-y-auto px-2">
                    <fieldset>
                        <div className="mb-6">
                            <InputErrors errors={errors.primary_attorney_id} />
                            <label
                                htmlFor="primary_attorney_id"
                                className="mb-1 block font-light text-gray-900"
                            >
                                Attorney{" "}
                                <span className="text-base text-gray-500">
                                    *
                                </span>
                            </label>
                            <Select
                                id="primary_attorney_id"
                                placeholder="Attorney"
                                onChange={(selection) =>
                                    updatePrimaryAccount(selection.value)
                                }
                                cacheOptions
                                loadOptions={getAttorneyOptions}
                                defaultOptions
                                onInputChange={getAttorneyOptions}
                            />
                        </div>
                        {questionnaires && questionnaires.data.length > 0 && (
                            <div className="mb-6">
                                <SelectInput
                                    label="Questionnaire"
                                    id="questionnaire_id"
                                    name="questionnaire_id"
                                    value={clientInput.questionnaire_id}
                                    defaultOption="None"
                                    defaultOptionDisabled={false}
                                    defaultOptionValue={null}
                                    options={questionnaires.data.map(
                                        (questionnaire) => {
                                            return {
                                                value: questionnaire.id.toString(),
                                                label: questionnaire.title,
                                            };
                                        }
                                    )}
                                    placeholder="Questionnaire"
                                    onChange={updateValue}
                                />
                            </div>
                        )}
                        <div className="flex items-end mb-6">
                            <div className="w-1/3 mr-4">
                                <TextInput
                                    name="first_name"
                                    label="First Name"
                                    id="first_name"
                                    value={clientInput.first_name}
                                    error={errors.first_name}
                                    autoFocus={true}
                                    onChange={updateValue}
                                    required={true}
                                />
                            </div>
                            <div className="w-1/3 mr-4">
                                <TextInput
                                    name="middle_name"
                                    label="Middle Name"
                                    id="middle_name"
                                    value={clientInput.middle_name}
                                    error={errors.middle_name}
                                    onChange={updateValue}
                                />
                            </div>
                            <div className="w-1/3">
                                <TextInput
                                    name="last_name"
                                    label="Last Name"
                                    id="last_name"
                                    value={clientInput.last_name}
                                    error={errors.last_name}
                                    onChange={updateValue}
                                    required={true}
                                />
                            </div>
                        </div>

                        <div className="mb-6">
                            <GenderSelect
                                label="Gender"
                                id="gender"
                                name="gender"
                                value={clientInput.gender}
                                error={errors.gender}
                                onChange={updateValue}
                            />
                        </div>

                        <div className="mb-6">
                            <TextInput
                                type="email"
                                name="email"
                                label="Email"
                                id="email"
                                value={clientInput.email}
                                error={errors.email}
                                onChange={updateValue}
                                required={true}
                            />
                        </div>

                        <div className="mb-6">
                            <TextInput
                                type="tel"
                                name="phone"
                                label="Phone"
                                id="phone"
                                value={clientInput.phone}
                                error={errors.phone}
                                onChange={updateValue}
                            />
                        </div>

                        <div className="mb-6">
                            <TextInput
                                name="address"
                                label="Address"
                                id="address"
                                value={clientInput.address}
                                error={errors.address}
                                onChange={updateValue}
                            />
                        </div>

                        <div className="flex mb-6">
                            <div className="w-1/3 mr-4">
                                <TextInput
                                    name="city"
                                    label="City"
                                    id="city"
                                    value={clientInput.city}
                                    error={errors.city}
                                    onChange={updateValue}
                                />
                            </div>
                            <div className="w-1/3 mr-4">
                                <TextInput
                                    name="state"
                                    label="State"
                                    id="state"
                                    value={clientInput.state}
                                    error={errors.state}
                                    onChange={updateValue}
                                />
                            </div>
                            <div className="w-1/3">
                                <TextInput
                                    name="postal_code"
                                    label="Postal Code"
                                    id="postal_code"
                                    value={clientInput.postal_code}
                                    error={errors.postal_code}
                                    onChange={updateValue}
                                />
                            </div>
                        </div>
                    </fieldset>
                    <hr />
                    <fieldset className="space-y-6 mt-6 mb-8">
                        <legend className="text-lg font-bold">Spouse</legend>
                        <div className="flex space-x-4">
                            <TextInput
                                name="spouse_first_name"
                                label="First Name"
                                id="spouse_first_name"
                                value={clientInput.spouse_first_name}
                                error={errors.spouse_first_name}
                                onChange={updateValue}
                            />
                            <TextInput
                                name="spouse_middle_name"
                                label="Middle Name"
                                id="spouse_middle_name"
                                value={clientInput.spouse_middle_name}
                                error={errors.spouse_middle_name}
                                onChange={updateValue}
                            />
                            <TextInput
                                name="spouse_last_name"
                                label="Last Name"
                                id="spouse_last_name"
                                value={clientInput.spouse_last_name}
                                error={errors.spouse_last_name}
                                onChange={updateValue}
                            />
                        </div>
                        <GenderSelect
                            label="Gender"
                            value={clientInput.spouse_gender}
                            name="spouse_gender"
                            error={errors.spouse_gender}
                            onChange={updateValue}
                            defaultOption={""}
                        />
                        <div className="flex items-end space-x-4">
                            <div className="flex-1">
                                <TextInput
                                    type="email"
                                    name="spouse_email"
                                    label="Email"
                                    autoComplete="off"
                                    id="email"
                                    value={clientInput.spouse_email}
                                    error={errors.spouse_email}
                                    onChange={updateValue}
                                />
                            </div>

                            <div
                                className={classNames(
                                    "flex flex-col",
                                    !clientInput.spouse_email &&
                                        "pointer-events-none opacity-25"
                                )}
                            >
                                <div className="mb-1 block font-light text-gray-900 mr-2">
                                    Make Spouse Authorized Party
                                </div>

                                <Toggle
                                    onToggle={(e, toggled) =>
                                        setClientInput({
                                            invite_spouse_to_collaborate:
                                                toggled,
                                        })
                                    }
                                    isToggled={
                                        clientInput.invite_spouse_to_collaborate
                                    }
                                />
                            </div>
                        </div>
                    </fieldset>
                </div>
                <input type="submit" hidden />
                <div className="flex justify-between items-center">
                    <div className="flex">
                        <div className="mb-1 block font-light text-gray-900 mr-2">
                            Send Welcome Email
                        </div>

                        <Toggle
                            onToggle={(e, toggled) =>
                                setClientInput({ welcome_email: toggled })
                            }
                            isToggled={clientInput.welcome_email}
                        />
                    </div>
                    <div className="w-40">
                        <Button
                            type="submit"
                            onClick={createClient}
                            isLoading={isSaving}
                            disabled={isSaving}
                            icon={faUserPlus}
                            text="Add Client"
                        />
                    </div>
                </div>
            </form>
        </>
    );
}

export default AddClient;
