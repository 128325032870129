import { useState } from "react";
import { useMutation } from "react-query";
import { useToasts } from "react-toast-notifications";

export default function usePost(
    callback,
    config = {
        showToastOnSuccess: false,
        showToastOnError: false,
    }
) {
    const { addToast } = useToasts();
    const [validationErrors, setValidationErrors] = useState([]);

    const mutation = useMutation(callback, {
        useErrorBoundary: (error) => {
            if (!error.response) {
                return false;
            }

            if (
                error.response.status >= 500 ||
                error.response.status === 401 ||
                error.response.status === 403
            ) {
                return true;
            }
        },
        ...config,
        onSuccess: (data, variables) => {
            if (config.showToastOnSuccess && data.message) {
                addToast(data.message);
            }
            typeof config.onSuccess === "function"
                ? config.onSuccess(data, variables)
                : null;
        },
        onError: (error) => {
            if (error.response && error.response.data) {
                if (error.response.status === 422) {
                    setValidationErrors(error.response.data.errors);
                }

                if (config.showToastOnError && error.response.data.message) {
                    addToast(error.response.data.message, { type: "error" });
                }
            }
            typeof config.onError === "function" ? config.onError() : null;
        },
    });

    return { validationErrors, mutation };
}
