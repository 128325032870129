import React, { useState } from "react";
import Auth from "services/Auth";
import Button from "./Button";
import BasicModal from "./ModalBasic";
import TextInput from "./TextInput";

export default function ConfirmImpersonationModal({ client, isOpen, onClose }) {
    const [approvalPassword, setApprovalPassword] = useState("");
    const [loading, setLoading] = useState(false);

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);
        await Auth.impersonateClient(client.id, { approvalPassword });
        setLoading(false);
    };

    return (
        <BasicModal
            isOpen={isOpen}
            title="Impersonate Client"
            onClose={onClose}
            size="lg"
        >
            <form onSubmit={handleSubmit} className="space-y-6 -mt-10">
                <div>
                    Please enter{" "}
                    <span className="font-semibold underline">your</span>{" "}
                    current password to proceed. You will be logged out of your
                    account and logged in as {client.full_name}.
                </div>
                <div className="flex items-end space-x-2">
                    <div className="flex-1">
                        <TextInput
                            label={"Your Password"}
                            type="password"
                            name="approval_password"
                            id="approval_password"
                            value={approvalPassword}
                            onChange={(e) =>
                                setApprovalPassword(e.target.value)
                            }
                            required
                        />
                    </div>
                    <div className="text-right">
                        <Button
                            isLoading={loading}
                            text="Submit"
                            type="submit"
                            width="w-32"
                        />
                    </div>
                </div>
            </form>
        </BasicModal>
    );
}
