import React from "react";

function Fieldset(props) {
    return (
        <fieldset className="mb-10">
            {props.legend && (
                <legend className="mb-10 uppercase font-bold text-gray-500 tracking-wide border-b border-gray-200 w-full pb-2">
                    {props.legend}
                    {props.description && (
                        <p className="max-w-lg mt-1 text-xs text-gray-400 normal-case font-normal">
                            {props.description}
                        </p>
                    )}
                </legend>
            )}
            {props.children}
        </fieldset>
    );
}

export default Fieldset;
