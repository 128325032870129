import React, { useState, useEffect, useContext } from "react";
import Dropdown, { DropdownItem } from "../../../components/Dropdown";
import {
    faBan,
    faCheck,
    faEdit,
    faEllipsisH,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Api from "../../../services/Api";
import { AuthContext } from "../../../contexts/AuthContext";
import EmptyState from "../../../components/EmptyState";
import { Link } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import useDebounce from "../../../hooks/useDebounce";
import SearchInput from "../../../components/SearchInput";
import Pagination from "../../../components/Pagination";
import TableSortHeader from "../../../components/TableSortHeader";
import { formatDate } from "../../../util/TextUtil";
import Badge from "../../../components/Badge";
import useHasPermission from "../../../hooks/usePermissions";

const MembersTable = ({ showDisabled, setShowInviteMemberModal }) => {
    const { addToast } = useToasts();
    const auth = useContext(AuthContext);
    const [members, setMembers] = useState([]);
    const [meta, setMeta] = useState([]);
    const [needsRefreshed, setNeedsRefreshed] = useState(false);
    const [search, setSearch] = useState("");
    const debouncedSearch = useDebounce(search, 250);
    const [page, setPage] = useState(1);
    const [orderBy, setOrderBy] = useState("first_name");
    const [sortDir, setSortDir] = useState("asc");
    const canDisableMember = useHasPermission(
        "firm_member_account_disablement"
    );

    useEffect(() => {
        const fetchData = async () => {
            let response = await Api.get("members", {
                page: page,
                limit: 20,
                sort: sortDir === "desc" ? "-" + orderBy : orderBy,
                show_trashed: showDisabled ? "with" : false,
                "filter[search]": debouncedSearch,
            });
            setMeta(response.data.meta);
            setMembers(response.data.data);
            setNeedsRefreshed(false);
        };
        fetchData();
    }, [showDisabled, debouncedSearch, needsRefreshed, page, sortDir, orderBy]);

    const removeMember = (memberId) => async () => {
        try {
            let response = await Api.delete("members/" + memberId);
            addToast(response.data.message);
        } catch (e) {
            if (e.response.data && e.response.data.message) {
                addToast(e.response.data.message, { type: "error" });
            } else if (e.response.status === 403) {
                addToast("You do not have permission to disable members.", {
                    type: "error",
                });
            }
        }
        setNeedsRefreshed(true);
    };

    const restoreMember = (memberId) => async () => {
        try {
            let response = await Api.put("members/" + memberId, {
                restore: true,
            });
            setNeedsRefreshed(true);
            addToast(response.data.message);
        } catch (error) {
            if (error.response.data && error.response.data.message) {
                addToast(error.response.data.message, { type: "error" });
            }
        }
    };

    const renderDisableButton = (member) => {
        if (member.id === auth.user.id || canDisableMember === false) {
            return "";
        }

        if (member.disabled_at) {
            return (
                <DropdownItem
                    label="Enable"
                    icon={faCheck}
                    handleClick={restoreMember(member.id)}
                />
            );
        }

        return (
            <DropdownItem
                label="Disable"
                icon={faBan}
                handleClick={removeMember(member.id)}
            />
        );
    };

    return (
        <>
            <div className="mb-4">
                <SearchInput
                    label={false}
                    searchValue={search}
                    onSearchChange={(e) => setSearch(e.target.value)}
                />
            </div>
            {members.length < 1 ? (
                <EmptyState>
                    No members have been added yet.{" "}
                    <Link
                        onClick={() => setShowInviteMemberModal(true)}
                        to="#"
                        className="underline"
                    >
                        Add Now.
                    </Link>
                </EmptyState>
            ) : (
                <>
                    <table className="w-full text-left">
                        <thead>
                            <tr>
                                <TableSortHeader
                                    field="first_name"
                                    sortField={orderBy}
                                    sortDir={sortDir}
                                    changeSort={(column) => {
                                        setSortDir(
                                            sortDir === "asc" ? "desc" : "asc"
                                        );
                                        setOrderBy(column);
                                    }}
                                >
                                    First Name
                                </TableSortHeader>
                                <TableSortHeader
                                    field="last_name"
                                    sortField={orderBy}
                                    sortDir={sortDir}
                                    changeSort={(column) => {
                                        setSortDir(
                                            sortDir === "asc" ? "desc" : "asc"
                                        );
                                        setOrderBy(column);
                                    }}
                                >
                                    Last Name
                                </TableSortHeader>
                                <TableSortHeader
                                    field="email"
                                    sortField={orderBy}
                                    sortDir={sortDir}
                                    changeSort={(column) => {
                                        setSortDir(
                                            sortDir === "asc" ? "desc" : "asc"
                                        );
                                        setOrderBy(column);
                                    }}
                                >
                                    Email
                                </TableSortHeader>
                                <TableSortHeader
                                    field="org_user.created_at"
                                    sortField={orderBy}
                                    sortDir={sortDir}
                                    changeSort={(column) => {
                                        setSortDir(
                                            sortDir === "asc" ? "desc" : "asc"
                                        );
                                        setOrderBy(column);
                                    }}
                                >
                                    Joined
                                </TableSortHeader>
                                <TableSortHeader
                                    field="role"
                                    sortField={orderBy}
                                    sortDir={sortDir}
                                    changeSort={(column) => {
                                        setSortDir(
                                            sortDir === "asc" ? "desc" : "asc"
                                        );
                                        setOrderBy(column);
                                    }}
                                >
                                    Role
                                </TableSortHeader>
                                <th className="text-right">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {members.map((member) => (
                                <tr key={`member_${member.id}`}>
                                    <td className="flex items-center space-x-2">
                                        <Link to={`/members/${member.id}/edit`}>
                                            {member.first_name}
                                        </Link>
                                        {member.id === auth.user.id && (
                                            <Badge color="gray">Me</Badge>
                                        )}
                                    </td>
                                    <td>{member.last_name}</td>
                                    <td>{member.email}</td>
                                    <td>{formatDate(member.created_at)}</td>
                                    <td>{member.role_display}</td>
                                    <td className="text-right">
                                        <span>
                                            <Dropdown
                                                position="right"
                                                toggleClass="px-3 py-1"
                                                toggleContent={
                                                    <FontAwesomeIcon
                                                        icon={faEllipsisH}
                                                    />
                                                }
                                            >
                                                <DropdownItem
                                                    label="Edit"
                                                    icon={faEdit}
                                                    link={`/members/${member.id}/edit`}
                                                />
                                                {renderDisableButton(member)}
                                            </Dropdown>
                                        </span>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <Pagination {...meta} nextPage={setPage} />
                </>
            )}
        </>
    );
};

export default MembersTable;
