import React, { useCallback } from "react";
import Button from "../../components/Button";
import useAuth from "../../hooks/useAuth";
import LoginSplash from "./LoginSplash";

export default function Logout(props) {
    const { logoutUser } = useAuth();
    const logout = useCallback(
        async (e) => {
            e.preventDefault();
            try {
                await logoutUser();
                props.history.replace("/login");
            } catch (e) {
                console.error(e);
            }
        },
        [logoutUser, props.history]
    );

    return (
        <div className="h-screen flex">
            <div className="bg-white text-gray-700 lg:max-w-2xl flex-auto flex items-center justify-center">
                <div>
                    <h1 className="text-3xl mb-8 text-center">
                        Logout of Inheralink
                    </h1>
                    <form onSubmit={logout}>
                        <Button
                            type="submit"
                            text="Logout Now"
                            className="w-64 mx-auto block bg-gray-800 hover:bg-gray-700 text-white border border-gray-800"
                        />
                    </form>
                </div>
            </div>
            <LoginSplash />
        </div>
    );
}
