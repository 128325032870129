import React, { useEffect, useState, useContext } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import Api from "../../services/Api";
import Spinner from "../../components/Spinner";
import { AuthContext } from "../../contexts/AuthContext";
import { formatDate } from "../../util/TextUtil";
import MemberProfile from "./MemberProfile";
import MemberPermissions from "./MemberPermissions";
import MemberAdvisors from "./advisors/MemberAdvisors";
import Questionnaires from "../questionnaires/Questionnaires";
import BudgetItems from "../budget-estimates/BudgetItems";
import useHasPermission from "../../hooks/usePermissions";
import Forbidden from "components/Forbidden";

export default function ManageMember(props) {
    const [member, setMember] = useState({});
    const [forceReload, setForceReload] = useState(0);
    const [loading, setLoading] = useState(true);
    const context = useContext(AuthContext);
    const unauthorized = !useHasPermission(
        "firm_member_accounts_administration"
    );

    useEffect(() => {
        async function fetchMember() {
            try {
                setLoading(true);
                let response = await Api.get(
                    "members/" + props.match.params.memberId,
                    {
                        "filter[trashed]": "with",
                    }
                );
                setMember(response.data.data);
                setLoading(false);
            } catch (e) {
                if (e.response.status === 401) {
                    alert(e.response.data.message);
                }
            }
        }
        fetchMember();
    }, [props.match.params.memberId, forceReload]);

    const reloadMember = () => {
        setForceReload(forceReload + 1);
    };

    if (loading || !member) {
        return (
            <div className="absolute top-0 left-0 w-full h-screen flex items-center justify-center">
                <Spinner />
            </div>
        );
    }

    if (unauthorized) {
        return <Forbidden />;
    }

    return (
        <>
            {member.disabled_at && (
                <div className="bg-red-500 text-white p-6 py-3">
                    This member was disabled on {formatDate(member.disabled_at)}
                </div>
            )}
            <Switch>
                <Route
                    exact
                    path="/members/:memberId/edit"
                    render={() => (
                        <Redirect
                            to={`/members/${props.match.params.memberId}/edit/profile`}
                            reloadMember={reloadMember}
                        />
                    )}
                />
                <Route
                    path="/members/:memberId/edit/profile"
                    render={() => (
                        <MemberProfile
                            member={member}
                            reloadMember={reloadMember}
                            auth={context}
                            {...props}
                        />
                    )}
                />
                <Route
                    path="/members/:memberId/edit/permissions"
                    render={() => (
                        <MemberPermissions
                            member={member}
                            reloadMember={reloadMember}
                            auth={context}
                            {...props}
                        />
                    )}
                />
                <Route
                    path="/members/:memberId/edit/advisors"
                    render={() => (
                        <MemberAdvisors
                            member={member}
                            reloadMember={reloadMember}
                            auth={context}
                            {...props}
                        />
                    )}
                />
                <Route
                    path="/members/:memberId/questionnaires"
                    render={() => (
                        <Questionnaires
                            member={member}
                            reloadMember={reloadMember}
                            auth={context}
                            {...props}
                        />
                    )}
                />
                <Route
                    path="/members/:memberId/budget-items"
                    render={() => (
                        <BudgetItems
                            member={member}
                            auth={context}
                            {...props}
                        />
                    )}
                />
            </Switch>
        </>
    );
}
