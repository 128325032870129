import React, { useState, useEffect, useContext } from "react";
import Api from "../../../services/Api";
import Dropdown, { DropdownItem } from "../../../components/Dropdown";
import { Link } from "react-router-dom";
import Spinner from "../../../components/Spinner";
import TableSortHeader from "../../../components/TableSortHeader";
import SearchInput from "../../../components/SearchInput";
import { AuthContext } from "../../../contexts/AuthContext";
import useDebounce from "../../../hooks/useDebounce";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faTrash,
    faEdit,
    faEllipsisH,
} from "@fortawesome/free-solid-svg-icons";
import { useToasts } from "react-toast-notifications";
import Pagination from "../../../components/Pagination";
import usePagination from "../../../hooks/usePagination";
import AddTrusteeModal from "../AddTrusteeModal";
import Forbidden from "../../../components/Forbidden";

export default function TrusteesTable({
    client,
    user,
    clientPath,
    showDisabled,
    showAddTrusteesModal,
    setShowAddTrusteesModal,
}) {
    const auth = useContext(AuthContext);
    const userType = auth.user.type;
    const { addToast } = useToasts();
    const [loading, setLoading] = useState(true);
    const [trustees, setTrustees] = useState([]);
    const [orderBy, setOrderBy] = useState("trustees.created_at");
    const [sortDir, setSortDir] = useState("desc");
    const [search, setSearch] = useState("");
    const debouncedSearch = useDebounce(search, 250);
    const [notAuthorized, setNotAuthorized] = useState(false);
    const [needsUpdated, setNeedsUpdated] = useState(0);
    const [page, setPage, meta, setMeta] = usePagination();

    useEffect(() => {
        document.title = "Trustees";
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            try {
                let response = await Api.get(
                    "clients/" + client.id + "/trustees",
                    {
                        limit: 20,
                        page: page,
                        sort: sortDir === "desc" ? "-" + orderBy : orderBy,
                        "filter[search]": debouncedSearch,
                    }
                );
                setMeta(response.data.meta);
                setTrustees(response.data.data);
                setLoading(false);
            } catch (error) {
                if (error.response.status === 403) {
                    setNotAuthorized(true);
                }
            }
        };
        fetchData();
    }, [
        client,
        showDisabled,
        debouncedSearch,
        orderBy,
        sortDir,
        needsUpdated,
        page,
        setMeta,
    ]);

    async function deleteTrustee(trustee) {
        try {
            const response = await Api.delete(
                "clients/" + client.id + "/trustees/" + trustee.id
            );
            addToast(response.data.message);
            setNeedsUpdated(needsUpdated + 1);
        } catch (e) {
            addToast(e.errorMessage, { type: "error" });
        }
    }

    if (notAuthorized) {
        return <Forbidden />;
    }

    if (loading) {
        return <Spinner />;
    }

    return (
        <>
            <div className="mb-4">
                <SearchInput
                    label={false}
                    searchValue={search}
                    onSearchChange={(e) => setSearch(e.target.value)}
                    placeholder="Search by trust name"
                />
            </div>
            {trustees.length ? (
                <>
                    <table className="w-full text-left">
                        <thead>
                            <tr>
                                <TableSortHeader
                                    field="owner_id"
                                    sortField={orderBy}
                                    sortDir={sortDir}
                                    changeSort={(column) => {
                                        setSortDir(
                                            sortDir === "asc" ? "desc" : "asc"
                                        );
                                        setOrderBy(column);
                                    }}
                                >
                                    Trustee Name
                                </TableSortHeader>
                                <TableSortHeader
                                    field="trust_name"
                                    sortField={orderBy}
                                    sortDir={sortDir}
                                    changeSort={(column) => {
                                        setSortDir(
                                            sortDir === "asc" ? "desc" : "asc"
                                        );
                                        setOrderBy(column);
                                    }}
                                >
                                    Trust Name
                                </TableSortHeader>
                                <TableSortHeader
                                    field="successor_level"
                                    sortField={orderBy}
                                    sortDir={sortDir}
                                    changeSort={(column) => {
                                        setSortDir(
                                            sortDir === "asc" ? "desc" : "asc"
                                        );
                                        setOrderBy(column);
                                    }}
                                >
                                    Level
                                </TableSortHeader>
                                <th className="text-right">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {trustees.map((trustee, idx) => (
                                <tr
                                    key={idx}
                                    className={trustee.deleted_at ? "" : ""}
                                >
                                    <td>
                                        <Link
                                            to={`${clientPath}/trustees/${trustee.id}/view`}
                                        >{`${trustee.trustee_name}`}</Link>
                                    </td>
                                    <td>
                                        <Link
                                            to={`${clientPath}/trusts/${trustee.trust_id}/view`}
                                        >{`${trustee.trust_name}`}</Link>
                                    </td>
                                    <td>{trustee.successor_level_display}</td>
                                    <td className="text-right">
                                        <span>
                                            <Dropdown
                                                position="right"
                                                toggleClass="px-3 py-1"
                                                toggleContent={
                                                    <FontAwesomeIcon
                                                        icon={faEllipsisH}
                                                    />
                                                }
                                            >
                                                <DropdownItem
                                                    label="Edit"
                                                    icon={faEdit}
                                                    link={`${clientPath}/trustees/${trustee.id}/edit`}
                                                />
                                                {(userType === "member" ||
                                                    userType === "client") && (
                                                    <DropdownItem
                                                        label="Delete"
                                                        icon={faTrash}
                                                        handleClick={() =>
                                                            deleteTrustee(
                                                                trustee
                                                            )
                                                        }
                                                    />
                                                )}
                                            </Dropdown>
                                        </span>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <div className="my-10">
                        <Pagination {...meta} nextPage={setPage} />
                    </div>
                </>
            ) : (
                <div className="text-gray-400">
                    The are no trustees.{" "}
                    <Link
                        to={`${clientPath}/trustees/add`}
                        className="underline text-gray-600"
                    >
                        Add Now.
                    </Link>
                </div>
            )}
            <AddTrusteeModal
                isOpen={showAddTrusteesModal}
                client={client}
                user={user}
                clientPath={clientPath}
                onClose={() => setShowAddTrusteesModal(false)}
                onSuccess={() => {
                    setNeedsUpdated(needsUpdated + 1);
                    setShowAddTrusteesModal(false);
                }}
            />
        </>
    );
}
