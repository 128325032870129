import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBan } from "@fortawesome/free-solid-svg-icons";

export default function Forbidden({ ...props }) {
    return (
        <div className="flex items-center justify-center overflow-hidden w-full h-full p-8">
            <div className="rounded-lg text-center p-8 bg-white">
                <span className="text-4xl mb-6 text-red-300">
                    <FontAwesomeIcon icon={faBan} />
                </span>
                <p className="text-gray-800">
                    {props.text
                        ? props.text
                        : "You do not have sufficient permissions to access this area."}
                </p>
            </div>
        </div>
    );
}
