import usePost from "./usePost";
import { updateClientAdvisor } from "services/AdvisorService";

export default function useUpdateAdvisor(clientId, advisorId, formData) {
    const { mutation, validationErrors } = usePost(
        (formData) => updateClientAdvisor(clientId, advisorId, formData),
        { showToastOnError: true, showToastOnSuccess: true }
    );
    const onSubmit = (event) => {
        event.preventDefault();
        return mutation.mutate(formData);
    };

    return { onSubmit, ...mutation, validationErrors };
}
