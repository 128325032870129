import React from "react";
import PageHeader from "../../components/PageHeader";
import DashboardLayout from "../../views/layouts/DashboardLayout";
import ShowChange from "./ShowChange";

export default function ShowMemberChange({ client = null, ...props }) {
    return (
        <DashboardLayout>
            <PageHeader
                text="View Change"
                backText={"View All Changes"}
                backTo={"/changes"}
            />
            <ShowChange client={client} {...props} />
        </DashboardLayout>
    );
}
