import React, { useState } from "react";
import QuickAddProvider, { QuickAddButton } from "../QuickAddButton";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Beneficiary from "./Beneficiary";
import ResourceContextProvider from "../../contexts/ResourceContext";
import useAuth from "hooks/useAuth";

export default function InitialBeneficiary({
    asset,
    client,
    disabled = false,
    hideQuickAdd = false,
    hideProposed = false,
}) {
    const [refresh, setRefresh] = useState(0);
    const { user } = useAuth();
    if (!asset.resource_type || !asset.id) {
        throw Error("Resource type and ID must be provided.");
    }

    return (
        <div>
            {hideQuickAdd === false && (
                <div className="text-right">
                    <QuickAddProvider
                        client={client}
                        onSave={() => setRefresh(refresh + 1)}
                    >
                        {(props) => (
                            <QuickAddButton {...props}>
                                <span className="flex items-center space-x-1 p-1">
                                    <span>Quick Add</span>
                                    <FontAwesomeIcon icon={faCaretDown} />
                                </span>
                            </QuickAddButton>
                        )}
                    </QuickAddProvider>
                </div>
            )}

            <ResourceContextProvider
                refresh={refresh}
                resourceTypes={[
                    "Client",
                    "Relationship",
                    "Trust",
                    "BusinessInterest",
                ]}
            >
                <div className="space-y-8">
                    <div>
                        <h3 className="mb-2 ml-1 font-bold text-md">
                            Current Beneficiaries
                        </h3>
                        <Beneficiary
                            resourceType={asset.resource_type}
                            resourceId={asset.id}
                            client={client}
                            disabled={disabled}
                        />
                    </div>

                    {user.type !== "client" && !hideProposed && (
                        <div>
                            <h3 className="mb-2 ml-1 font-bold text-md">
                                Proposed Beneficiaries
                            </h3>
                            <Beneficiary
                                showProposed={true}
                                resourceType={asset.resource_type}
                                resourceId={asset.id}
                                client={client}
                                disabled={disabled}
                            />
                        </div>
                    )}
                </div>
            </ResourceContextProvider>
        </div>
    );
}
