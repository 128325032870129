import React from "react";
import VerticalTabs from "../../../components/VerticalTabs";

export default function NotesReceivableTabs({ basePath, ...props }) {
    let items = [
        {
            label: "Info",
            to: basePath + "",
        },
        {
            label: "Owners",
            to: basePath + "/owners",
        },
        {
            label: "Notes",
            to: basePath + "/notes",
        },
        {
            label: "Related Files",
            to: basePath + "/related-files",
        },
        {
            label: "Related Photos",
            to: basePath + "/related-photos",
        },
        {
            label: "Access",
            to: basePath + "/access",
        },
        {
            label: "Transfers",
            to: basePath + "/transfers",
        },
    ];
    return <VerticalTabs {...props} items={items} />;
}
