import React, { useState } from "react";
import useClient from "hooks/useClient";
import Api from "services/Api";
import { useToasts } from "react-toast-notifications";
import Button from "components/Button";
import useForm from "hooks/useForm";
import ClientProfileForm from "views/clients/_partials/ClientProfileForm";
import debounce from "util/debounce-promise";
import ProfilePhoto from "views/members/_partials/ProfilePhoto";
import PageContainer from "components/PageContainer";
import ClientPageHeader, { Heading } from "components/ClientPageHeader";

export default function Client() {
    const { client, refreshClient, clientPath } = useClient();
    const { addToast } = useToasts();
    const [errors, setErrors] = useState([]);

    const { input, updateValue, setInput, clearUnsavedChanges } =
        useForm(client);

    async function updateClient(e) {
        e.preventDefault();
        clearUnsavedChanges();
        try {
            let response = await Api.put("clients/" + client.id, input);
            addToast(response.data.message);
            setErrors([]);
            setInput(response.data.data);
        } catch (e) {
            setErrors([]);
            if (e.response.data.errors) {
                setErrors(e.response.data.errors);
            } else {
                addToast(e.response.data.message, { type: "error" });
            }
        }
    }

    async function fetchAttorneyOptions(query) {
        let response = await Api.get("members", {
            "filter[search]": query,
            "filter[role]": ["member_owner", "member_partner"],
            limit: 100,
        });

        return response.data.data.map((attorney) => {
            return {
                value: attorney.id.toString(),
                label: attorney.full_name,
            };
        });
    }

    const getAttorneyOptions = debounce(fetchAttorneyOptions, 250);

    return (
        <>
            <ClientPageHeader client={client}>
                <Heading
                    backTo={`${clientPath}/relationships`}
                    backText="All Relationships"
                >
                    <div className="space-x-3">{client.full_name}</div>
                </Heading>
                <div className="flex-0">
                    <Button type="button" text="Save" onClick={updateClient} />
                </div>
            </ClientPageHeader>
            <PageContainer>
                <div className="flex items-start space-x-10 mx-auto max-w-4xl flex-shrink-0">
                    <form onSubmit={updateClient} className="flex-1">
                        <ClientProfileForm
                            client={input}
                            errors={errors}
                            updateValue={updateValue}
                            getAttorneyOptions={getAttorneyOptions}
                            readOnly={false}
                        />
                        <input type="submit" hidden />
                    </form>
                    <div className="flex-none">
                        <ProfilePhoto
                            memberId={client.id}
                            photo={client.profile_photo}
                            upload={refreshClient}
                        />
                    </div>
                </div>
            </PageContainer>
        </>
    );
}
