import React from "react";
import InsurancePoliciesLayout from "./InsurancePoliciesLayout";
import InitialBeneficiary from "components/Beneficiary/InitialBeneficiary";

export default function EditInsurancePolicyBeneficiaries({
    client,
    input,
    currentBasePath,
    title,
}) {
    return (
        <InsurancePoliciesLayout
            currentBasePath={currentBasePath}
            title={title}
            // buttons={
            //     <QuickAddProvider client={client} onSave={() => refreshData()}>
            //         {(props) => <QuickAddButton {...props} />}
            //     </QuickAddProvider>
            // }
        >
            <InitialBeneficiary
                client={client}
                asset={input}
                hideQuickAdd={false}
            />
        </InsurancePoliciesLayout>
    );
}
