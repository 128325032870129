import React from "react";
import { Link } from "react-router-dom";

class NoMatch extends React.Component {
    render() {
        return (
            <div className="h-screen flex items-center justify-center">
                <div className="text-center">
                    <h1 className="block text-2xl">404</h1>
                    <p>The page you are looking for could not be found.</p>
                    <Link to="/" className="block mt-3 text-blue-800 underline">
                        Back to dashboard
                    </Link>
                </div>
            </div>
        );
    }
}
export default NoMatch;
