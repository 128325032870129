import React, { useState, useEffect, useReducer } from "react";
import Api from "../../services/Api";
import { Redirect, Route, Switch } from "react-router-dom";
import Button from "../../components/Button";
import SaveButton from "../../components/SaveButton";
import Spinner from "../../components/Spinner";
import { useToasts } from "react-toast-notifications";
import PageContainer from "../../components/PageContainer";
import Panel, { PanelHeader } from "../../components/Panel";
import VaultResourceFileList from "../resources/VaultResourceFileList";
import TrustsForm from "./_partials/TrustsForm";
import ClientPageHeader, { Heading } from "../../components/ClientPageHeader";
import ResourceAccessUserList from "../resources/ResourceAccessUserList";
import Dropdown, { DropdownItem } from "../../components/Dropdown";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faEllipsisH,
    faEdit,
    faTrash,
    faPlus,
    faDownload,
} from "@fortawesome/free-solid-svg-icons";
import AddTrusteeModal from "../trustees/AddTrusteeModal";
import InitialBeneficiary from "../../components/Beneficiary/InitialBeneficiary";
import ClientNotes from "../clients/Notes/ClientNotes";
import UnsavedChanges from "../../components/UnsavedChanges";
import TrustTabs from "./_partials/TrustsTabs";
import { downloadFile } from "util/Downloader";
import { useAsyncError } from "hooks/useAsyncError";

export function TrustTrustees({ client, trustId, userType, clientPath }) {
    const { addToast } = useToasts();
    const [loading, setLoading] = useState(true);
    const [trustees, setTrustees] = useState([]);
    const [needsUpdated, setNeedsUpdated] = useState(0);
    const [showAddTrusteesModal, setShowAddTrusteesModal] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            try {
                let response = await Api.get(
                    "clients/" + client.id + "/trusts/" + trustId + "/trustees"
                );
                setTrustees(response.data.data);
                setLoading(false);
            } catch (error) {
                console.log(error);
            }
        };
        fetchData();
    }, [client.id, trustId, needsUpdated]);

    async function deleteTrustee(trustee) {
        try {
            const response = await Api.delete(
                "clients/" + client.id + "/trustees/" + trustee.id
            );
            addToast(response.data.message);
            setNeedsUpdated(needsUpdated + 1);
        } catch (e) {
            addToast(e.errorMessage, { type: "error" });
        }
    }

    if (loading) {
        return <p>Loading trustees...</p>;
    }

    return (
        <>
            <Panel allowOverflow={true}>
                <PanelHeader title="Trustees">
                    <Button
                        type="button"
                        appearance="none"
                        onClick={() => setShowAddTrusteesModal(true)}
                        className="text-sm text-brand block flex-1 flex items-center space-x-1"
                    >
                        <FontAwesomeIcon icon={faPlus} />
                        <span>Add Trustee</span>
                    </Button>
                </PanelHeader>
                {trustees.length > 0 && (
                    <table className="w-full">
                        <thead className="text-left">
                            <tr>
                                <th>Name</th>
                                <th>Level</th>
                                <th className="text-right">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {trustees.map((trustee) => {
                                return (
                                    <tr key={`trustee_${trustee.id}`}>
                                        <td>{trustee.trustee_name}</td>
                                        <td>
                                            {trustee.successor_level_display}
                                        </td>
                                        <td className="text-right">
                                            <span>
                                                <Dropdown
                                                    position="right"
                                                    toggleClass="px-3 py-1"
                                                    toggleContent={
                                                        <FontAwesomeIcon
                                                            icon={faEllipsisH}
                                                        />
                                                    }
                                                >
                                                    <DropdownItem
                                                        label="Edit"
                                                        icon={faEdit}
                                                        link={`${clientPath}/trustees/${trustee.id}/edit`}
                                                    />
                                                    {(userType === "member" ||
                                                        userType ===
                                                            "client") && (
                                                        <DropdownItem
                                                            label="Delete"
                                                            icon={faTrash}
                                                            handleClick={() =>
                                                                deleteTrustee(
                                                                    trustee
                                                                )
                                                            }
                                                        />
                                                    )}
                                                </Dropdown>
                                            </span>
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                )}
                {trustees.length === 0 && <>No trustees have been added yet.</>}
            </Panel>

            <AddTrusteeModal
                isOpen={showAddTrusteesModal}
                client={client}
                trustId={trustId}
                onClose={() => setShowAddTrusteesModal(false)}
                onSuccess={() => {
                    setNeedsUpdated(needsUpdated + 1);
                    setShowAddTrusteesModal(false);
                }}
            />
        </>
    );
}

export default function EditTrust({
    client,
    user,
    readOnly = false,
    clientPath,
    ...props
}) {
    const [isExporting, setIsExporting] = useState(false);
    const [loading, setLoading] = useState(true);
    const [title, setTitle] = useState();
    const [redirectToIndex, setRedirectToIndex] = useState(false);
    const throwError = useAsyncError();
    const { addToast } = useToasts();
    const [errors, setErrors] = useState([]);
    const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);

    const [input, setInput] = useReducer(
        (state, newState) => ({ ...state, ...newState }),
        {
            owner: "Client:" + client.id,
            owner_type: "Client",
            type_id: 1,
        }
    );

    useEffect(() => {
        const fetchData = async () => {
            try {
                let response = await Api.get(
                    "clients/" +
                        client.id +
                        "/trusts/" +
                        props.match.params.trustId
                );
                setInput(response.data.data);
                setTitle(response.data.data.name);
                setHasUnsavedChanges(false);
                setLoading(false);
                document.title = `${readOnly ? "View" : "Edit"} Trust: ${
                    response.data.data.name
                }`;
            } catch (error) {
                if (error.response.status === 403) {
                    throwError(error);
                }
            }
        };
        fetchData();
    }, [client, props.match.params.trustId, readOnly]);

    function updateValue(event) {
        setInput({ [event.target.name]: event.target.value });
        setHasUnsavedChanges(true);
    }

    const printCard = async (e) => {
        e.preventDefault();
        try {
            setIsExporting(true);
            let response = await Api.get(
                "clients/" + client.id + "/trusts/" + input.id + "/id-card",
                {
                    export: true,
                },
                { responseType: "arraybuffer" }
            );
            await downloadFile(response, "trust-id-card", {
                open: true,
                print: true,
            });
            setIsExporting(false);
            addToast("Trust ID Card Ready");
        } catch (e) {
            setIsExporting(false);
            addToast(e.response.data.message, { type: "error" });
        }
    };

    async function update(e) {
        e.preventDefault();
        try {
            const body = { ...input };
            let response = await Api.put(
                "clients/" + client.id + "/trusts/" + input.id,
                body
            );
            addToast(response.data.message);
            setErrors([]);
            setHasUnsavedChanges(false);
            setRedirectToIndex(true);
        } catch (e) {
            setErrors([]);
            if (e.response.data.errors) {
                setErrors(e.response.data.errors);
            } else {
                addToast(e.response.data.message, { type: "error" });
            }
        }
    }

    if (redirectToIndex) {
        return <Redirect to={`/clients/${client.id}/trusts`} />;
    }

    if (!input || loading) {
        return (
            <div className="h-screen w-full flex items-center justify-center">
                <Spinner />
            </div>
        );
    }

    const resourceType = "Trust";
    const basePath = `${clientPath}/trusts/${input.id}/`;
    const currentBasePath = basePath + (readOnly ? "view" : "edit");

    return (
        <>
            <ClientPageHeader client={client}>
                <Heading backTo={`${clientPath}/trusts`} backText="All Trusts">
                    {readOnly ? "View" : "Edit"} Trust - {title}
                </Heading>
                {readOnly !== true && (
                    <div className="flex-0">
                        <SaveButton onClick={update} />
                    </div>
                )}
                {readOnly !== false && (
                    <div className="space-x-4 flex flex-row">
                        <div className="flex-0">
                            <Button
                                type="submit"
                                appearance="outline"
                                disabled={isExporting}
                                isLoading={isExporting}
                                icon={faDownload}
                                text="Download Trust ID Card"
                                onClick={printCard}
                                width="w-auto"
                            />
                        </div>
                        <div className="flex-0">
                            <Button
                                type="link"
                                to={`${clientPath}/trusts/${input.id}/edit`}
                                text="Edit"
                            />
                        </div>
                    </div>
                )}
            </ClientPageHeader>
            <PageContainer>
                <UnsavedChanges when={hasUnsavedChanges} />
                <div className="flex space-x-10">
                    <div className="flex-initial">
                        <TrustTabs basePath={currentBasePath} {...props} />
                    </div>
                    <div className="flex-1">
                        <Switch>
                            <Route
                                exact={true}
                                path={[basePath + "edit", basePath + "view"]}
                                render={() => (
                                    <div className="max-w-3xl mx-auto">
                                        <TrustsForm
                                            client={client}
                                            input={input}
                                            readOnly={readOnly}
                                            errors={errors}
                                            updateValue={updateValue}
                                            onSubmit={update}
                                        />
                                    </div>
                                )}
                            />
                            <Route
                                exact={true}
                                path={[
                                    basePath + "edit/trustees",
                                    basePath + "view/trustees",
                                ]}
                                render={() => (
                                    <TrustTrustees
                                        input={input}
                                        client={client}
                                        trustId={input.id}
                                        clientPath={clientPath}
                                        userType={user.type}
                                    />
                                )}
                            />
                            <Route
                                exact={true}
                                path={[
                                    basePath + "edit/beneficiaries",
                                    basePath + "view/beneficiaries",
                                ]}
                                render={() => (
                                    <fieldset>
                                        <legend className="font-bold txt-md text-gray-500 my-6 pb-1 border-b block w-full">
                                            Beneficiaries
                                        </legend>
                                        <InitialBeneficiary
                                            hideProposed={true}
                                            client={client}
                                            asset={input}
                                        />
                                    </fieldset>
                                )}
                            />
                            <Route
                                exact={true}
                                path={[
                                    basePath + "edit/notes",
                                    basePath + "view/notes",
                                ]}
                                render={() => (
                                    <>
                                        <Heading>Notes</Heading>
                                        <ClientNotes
                                            client={client}
                                            resourceType={resourceType}
                                            resourceId={input.id}
                                            {...props}
                                        />
                                    </>
                                )}
                            />
                            <Route
                                exact={true}
                                path={[
                                    basePath + "edit/related-files",
                                    basePath + "view/related-files",
                                ]}
                                render={() => (
                                    <VaultResourceFileList
                                        client={client}
                                        resourceType={"Trust"}
                                        resourceId={input.id}
                                    />
                                )}
                            />
                            <Route
                                exact={true}
                                path={[
                                    basePath + "edit/access",
                                    basePath + "view/access",
                                ]}
                                render={() => (
                                    <ResourceAccessUserList
                                        resourceType="Trust"
                                        resourceId={input.id}
                                    />
                                )}
                            />
                        </Switch>
                    </div>
                </div>
            </PageContainer>
        </>
    );
}
