import React, { useContext, useState } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import PageHeader from "../../components/PageHeader";
import PageContainer from "../../components/PageContainer";
import Button from "../../components/Button";
import Tabs from "../../components/Tabs";
import PendingClientsTable from "./_partials/PendingClientsTable";
import ClientsTable from "./_partials/ClientsTable";
import { AuthContext } from "../../contexts/AuthContext";
import Modal from "../../components/Modal";
import InviteClients from "./InviteClients";
import AddClient from "./AddClient";
import useHasPermission from "hooks/usePermissions";

function AddClientModal(props) {
    const [showManualForm, setShowManualForm] = useState(false);
    return showManualForm ? (
        <AddClient {...props} />
    ) : (
        <InviteClients setShowManualForm={setShowManualForm} {...props} />
    );
}

export default function ClientsIndex(props) {
    const auth = useContext(AuthContext);
    const [showAddClientModal, setShowAddClientModal] = useState(false);

    if (auth.user.type === "client") {
        return <Redirect to="/" />;
    }

    return (
        <>
            <PageHeader text="Clients">
                {auth.user.type === "member" &&
                    useHasPermission(
                        "firm_client_related_accounts_administration"
                    ) && (
                        <div className="flex flex-row">
                            <Button
                                onClick={() => setShowAddClientModal(true)}
                                text="Add Clients"
                            />
                        </div>
                    )}
            </PageHeader>
            <PageContainer>
                <Tabs
                    {...props}
                    items={[
                        { label: "My Clients", to: "/clients" },
                        { label: "All Clients", to: "/clients/all" },
                        { label: "Deactivated", to: "/clients/disabled" },
                        { label: "Pending", to: "/clients/pending" },
                    ]}
                />
                <Switch>
                    <Route
                        exact
                        path="/clients/pending"
                        render={(props) => (
                            <PendingClientsTable
                                {...props}
                                title="Pending Clients"
                            />
                        )}
                    />
                    <Route
                        exact
                        path="/clients/disabled"
                        render={(props) => (
                            <ClientsTable
                                {...props}
                                showDisabled={true}
                                showAll={true}
                                title="Disabled Clients"
                            />
                        )}
                    />
                    <Route
                        exact
                        path="/clients/all"
                        render={(props) => (
                            <ClientsTable
                                {...props}
                                showDisabled={false}
                                showAll={true}
                                title="All Clients"
                            />
                        )}
                    />
                    <Route
                        exact
                        path="/clients"
                        render={(props) => (
                            <ClientsTable
                                {...props}
                                showDisabled={false}
                                showAll={false}
                                title="My Clients"
                            />
                        )}
                    />
                </Switch>

                <Modal
                    noWrapper={true}
                    backgroundDismiss={true}
                    title="Invite Clients"
                    isOpen={showAddClientModal}
                    size="2xl"
                    component={AddClientModal}
                    onCancel={() => setShowAddClientModal(false)}
                ></Modal>
            </PageContainer>
        </>
    );
}
