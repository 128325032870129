import Api from "./Api";

export const fetchQuestionnaires = async function ({ queryKey }) {
    const [, { ...params }] = queryKey;
    return await Api.get(
        "members/" + params.memberId + "/questionnaires",
        params
    ).then((res) => res.data);
};

export const fetchQuestionnairesForClient = async function ({ queryKey }) {
    const [, { ...params }] = queryKey;
    return await Api.get(
        "clients/" + params.clientId + "/questionnaires",
        params
    ).then((res) => res.data);
};

export const storeClientQuestionnaires = async function (params) {
    return await Api.post(
        "clients/" +
            params.clientId +
            "/questionnaires/" +
            params.questionnaireId,
        params
    ).then((res) => res.data);
};

export const deleteClientQuestionnaires = async function (params) {
    return await Api.delete(
        "clients/" +
            params.clientId +
            "/questionnaires/" +
            params.questionnaireId,
        params
    ).then((res) => res.data);
};

export default {
    storeClientQuestionnaires,
    fetchQuestionnaires,
};
