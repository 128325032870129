import React, { useState, useEffect, useReducer } from "react";
import Api from "../../services/Api";
import { useToasts } from "react-toast-notifications";
import SaveButton from "../../components/SaveButton";
import SelectInput from "../../components/SelectInput";
import FamilyForm from "./_partials/FamilyForm";
import FriendForm from "./_partials/FriendForm";
import OrganizationForm from "./_partials/OrganizationForm";
import { Redirect } from "react-router-dom";
import ClientPageHeader, { Heading } from "../../components/ClientPageHeader";
import PageContainer from "../../components/PageContainer";
import SpouseForm from "./_partials/SpouseForm";
import RelationshipContextProvider from "contexts/RelationshipContext";
import useClient from "hooks/useClient";

function AddRelationship({ reloadClient }) {
    const { client, clientPath } = useClient();
    const { addToast } = useToasts();
    const [errors, setErrors] = useState([]);
    const [redirectToRelationshipId, setRedirectToRelationshipId] =
        useState(false);

    const [relationshipInput, setRelationshipInput] = useReducer(
        (state, newState) => ({ ...state, ...newState }),
        {
            type: "",
            spousal_relation: "client",
            relationship: 1,
            modifier: 0,
            birth_date: "",
            date_of_death: "",
            first_name: "",
            last_name: "",
            middle_name: "",
            nick_name: "",
            maiden_name: "",
            signature_name: "",
            email: "",
            phone: "",
            work_phone: "",
            address: "",
            address_2: "",
            city: "",
            state: "",
            postal_code: "",
            country: "US",
            work_country: "US",
            organization_name: "",
            organization_website: "",
            ssn: "",
            spouse_name: "",
            gender: "",
            tax_dependent: 0,
            authorized_party: false,
            authorized_party_role: "client_successor",
        }
    );

    useEffect(() => {
        switch (relationshipInput.type) {
            case "friend":
                setRelationshipInput({
                    relationship: 16,
                    modifier: 0,
                    authorized_party_role: "client_successor",
                });
                break;
            case "organization":
                setRelationshipInput({
                    relationship: 17,
                    modifier: 0,
                    authorized_party_role: "client_successor",
                });
                break;
            case "spouse":
                setRelationshipInput({
                    relationship: 18,
                    modifier: 0,
                    spousal_relation: "client",
                    authorized_party_role: "client_spouse",
                });
                break;
            default:
                setRelationshipInput({
                    relationship: 1,
                    modifier: 0,
                    authorized_party_role: "client_successor",
                });
        }
    }, [relationshipInput.type]);

    useEffect(() => {
        document.title = "Add Relationship";
    }, []);

    function updateValue(event) {
        setErrors([]);
        let value = event.target.value;
        if (event.target.type && event.target.type === "checkbox") {
            value = event.target.checked;
        }
        setRelationshipInput({ [event.target.name]: value });
    }

    async function createRelationship(e) {
        e.preventDefault();
        try {
            const body = { ...relationshipInput };
            if (body.type !== "organization") {
                delete body["organization_website"];
            }

            if (body.type !== "spouse") {
                body.marriage_date = null;
                body.marriage_end_date = null;
            } else if (body.modifier > 1) {
                body.marriage_end_date = null;
            }

            if (body.type !== "family") {
                body.tax_dependent = 0;
            }

            if (body.type === "friend" || body.type === "organization") {
                [
                    "drivers_license_number",
                    "drivers_license_state",
                    "ssn",
                    "passport_number",
                ].forEach((key) => {
                    body[key] = null;
                });
            }

            let response = await Api.post(
                "clients/" + client.id + "/relationships",
                body
            );
            addToast(response.data.message);
            setErrors([]);
            setRedirectToRelationshipId(response.data.data.id);
            reloadClient();
        } catch (e) {
            setErrors([]);
            if (e.response.data.errors) {
                setErrors(e.response.data.errors);
            } else {
                addToast(e.response.data.message, { type: "error" });
            }
        }
    }

    const displayForm = () => {
        switch (relationshipInput.type) {
            case "friend":
                return (
                    <FriendForm
                        relationshipInput={relationshipInput}
                        errors={errors}
                        onChange={updateValue}
                        clientId={client.id}
                        allFields={false}
                    />
                );
            case "organization":
                return (
                    <OrganizationForm
                        relationshipInput={relationshipInput}
                        errors={errors}
                        onChange={updateValue}
                        clientId={client.id}
                        allFields={false}
                    />
                );
            case "spouse":
                return (
                    <SpouseForm
                        relationshipInput={relationshipInput}
                        errors={errors}
                        onChange={updateValue}
                        clientId={client.id}
                        allFields={false}
                    />
                );
            default:
                return (
                    <FamilyForm
                        relationshipInput={relationshipInput}
                        errors={errors}
                        onChange={updateValue}
                        clientId={client.id}
                        allFields={false}
                    />
                );
        }
    };

    if (redirectToRelationshipId) {
        return (
            <Redirect
                to={`/clients/${client.id}/relationships/${redirectToRelationshipId}/edit`}
            />
        );
    }

    return (
        <RelationshipContextProvider>
            <>
                <ClientPageHeader client={client}>
                    <Heading
                        backTo={`${clientPath}/relationships`}
                        backText="All Relationships"
                    >
                        Add Relationship
                    </Heading>
                    <div className="flex-0">
                        <SaveButton onClick={createRelationship} />
                    </div>
                </ClientPageHeader>
                <PageContainer>
                    <form
                        onSubmit={createRelationship}
                        className="max-w-3xl mx-auto"
                        autoComplete="off"
                    >
                        <div>
                            <div className="mb-6">
                                <SelectInput
                                    label="Relationship Type"
                                    options={[
                                        {
                                            value: "",
                                            label: "Choose an option",
                                            disabled: true,
                                        },
                                        { value: "spouse", label: "Spouse" },
                                        { value: "family", label: "Family" },
                                        { value: "friend", label: "Friend" },
                                        {
                                            value: "organization",
                                            label: "Organization",
                                        },
                                    ]}
                                    value={relationshipInput.type}
                                    name="type"
                                    error={errors.type}
                                    onChange={updateValue}
                                ></SelectInput>
                            </div>

                            {relationshipInput.type !== "spouse" && (
                                <div className="mb-6">
                                    <SelectInput
                                        label="Related To"
                                        options={
                                            client.spouse
                                                ? [
                                                      {
                                                          value: "client",
                                                          label: client.full_name,
                                                      },
                                                      {
                                                          value: "spouse",
                                                          label: client.spouse
                                                              .full_name,
                                                      },
                                                      {
                                                          value: "both",
                                                          label: "Both",
                                                      },
                                                  ]
                                                : [
                                                      {
                                                          value: "client",
                                                          label: client.full_name,
                                                      },
                                                  ]
                                        }
                                        value={
                                            relationshipInput.spousal_relation
                                        }
                                        name="spousal_relation"
                                        onChange={updateValue}
                                    ></SelectInput>
                                </div>
                            )}

                            {displayForm()}
                        </div>
                        <input type="submit" hidden />
                    </form>
                </PageContainer>
            </>
        </RelationshipContextProvider>
    );
}

export default AddRelationship;
