import useFetch from "./useFetch";
import { fetchQuestionnaires } from "../services/QuestionnaireService";

export default function useQuestionnaires(
    params,
    config = { keepPreviousData: true }
) {
    return useFetch(
        ["fetchQuestionnaires", params],
        fetchQuestionnaires,
        config
    );
}
