export default function useFormatPercentage() {
    const defaultOptions = {
        style: "decimal",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    };

    return function formatPercentage(value) {
        return (
            new Intl.NumberFormat("en-US", defaultOptions).format(value) + "%"
        );
    };
}
