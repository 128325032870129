import React, { useCallback } from "react";
import Api from "../../services/Api";
import { downloadFile } from "../../util/Downloader";
import { useToasts } from "react-toast-notifications";
import useSessionExpiration from "../../hooks/useSessionExpiration";
export const ICEGuestContext = React.createContext();

export default function ICEGuestContextProvider({ children }) {
    const [isLoggedIn, setIsLoggedIn] = React.useState(false);
    const [isLoggingIn, setIsLoggingIn] = React.useState(false);
    const [hasCheckedLogin, setHasCheckedLogin] = React.useState(false);
    const [memberId, setMemberId] = React.useState("");
    const [accessCode, setAccessCode] = React.useState("");
    const [errorMessage, setErrorMessage] = React.useState("");
    const [message] = React.useState("");
    const [errors, setErrors] = React.useState({});
    const [info, setInfo] = React.useState({});
    const [isDownloadingIds, setIsDownloadingIds] = React.useState([]);

    const { addToast } = useToasts();

    const fetchInfo = async () => {
        const { data } = await Api.get(
            "ice/info",
            {},
            {
                headers: {
                    "x-ice-id": memberId,
                    "x-ice-code": accessCode,
                },
            }
        );
        setInfo(data);
    };

    const download = async (file, e) => {
        const id = file.id;
        e.preventDefault();
        try {
            setIsDownloadingIds((ids) => [...ids, id]);
            const response = await Api.get(
                "ice/download/" + id,
                {},
                {
                    headers: {
                        "x-ice-id": memberId,
                        "x-ice-code": accessCode,
                    },
                    responseType: "arraybuffer",
                }
            );
            await downloadFile(response, `${file.name}.${file.extension}`, {
                addExtension: false,
            });

            addToast("File downloaded");
        } catch (e) {
            addToast(e.response.data.message, { type: "error" });
        }
        setIsDownloadingIds((ids) => [...ids.filter((i) => i !== id)]);
    };

    const checkLogin = useCallback(async () => {
        try {
            if (isLoggingIn) return;

            setErrors({});
            setErrorMessage("");

            setIsLoggingIn(true);
            const { data } = await Api.get(
                "ice/check",
                {},
                {
                    headers: {
                        "x-ice-id": memberId,
                        "x-ice-code": accessCode,
                    },
                }
            );
            if (data.redirect) {
                window.location.href = data.redirect;
                return;
            }
            await fetchInfo();
            setIsLoggedIn(true);
            setHasCheckedLogin(true);
        } catch (e) {
            setIsLoggedIn(false);
            setHasCheckedLogin(true);
            console.error(e);

            if (memberId || accessCode) {
                if (e.response && e.response.data.errors) {
                    setErrors(e.response.data.errors);
                } else {
                    setErrorMessage(e.response.data.message);
                    setErrors({});
                }
            }
        }
        setIsLoggingIn(false);
    }, [memberId, accessCode]);

    const iceLogin = async (e) => {
        e.preventDefault();
        await checkLogin();
    };

    React.useEffect(() => {
        checkLogin();
    }, []);

    useSessionExpiration({
        hasCheckedLogin,
        isLoggedIn,
        logoutUser: () => {
            window.location.reload();
        },
        extendSession: async () => {
            setHasCheckedLogin(false);
            await Api.get("ice/extend");
            setHasCheckedLogin(true);
        },
    });

    return (
        <ICEGuestContext.Provider
            value={{
                isLoggedIn,
                isLoggingIn,
                iceLogin,
                memberId,
                setMemberId,
                accessCode,
                setAccessCode,
                errors,
                errorMessage,
                message,
                info,
                download,
                isDownloadingIds,
            }}
        >
            {children}
        </ICEGuestContext.Provider>
    );
}
