import React, { useContext, useState, useEffect } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import { useToasts } from "react-toast-notifications";
import SearchInput from "../../../components/SearchInput";
import Spinner from "../../../components/Spinner";
import TableSortHeader from "../../../components/TableSortHeader";
import Api from "../../../services/Api";
import Dropdown, { DropdownItem } from "../../../components/Dropdown";
import useDebounce from "../../../hooks/useDebounce";
import { formatDate } from "../../../util/TextUtil";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Money from "../../../components/Money";
import {
    faTrash,
    faEdit,
    faEllipsisH,
} from "@fortawesome/free-solid-svg-icons";
import TableSummaryRow from "../../../components/TableSummaryRow";
import { parseMoney } from "../../../components/Money";
import Forbidden from "../../../components/Forbidden";
import { Link } from "react-router-dom";
import useFormatPercentage from "../../../hooks/useFormatPercentage";
import { useUserCan } from "hooks/useHasAnyPermissions";

function RealEstateTable({ client, clientPath }) {
    const auth = useContext(AuthContext);
    const userType = auth.user.type;
    const { addToast } = useToasts();
    const formatPercentage = useFormatPercentage();
    const canCreateWealthRecords = useUserCan("createWealthRecord");
    const [realEstate, setRealEstate] = useState([]);
    const [loading, setLoading] = useState(true);
    const [notAuthorized, setNotAuthorized] = useState(false);
    const [needsUpdated, setNeedsUpdated] = useState(0);
    const [search, setSearch] = useState("");
    const debouncedSearch = useDebounce(search, 250);
    const [orderBy, setOrderBy] = useState("as_of_date");
    const [sortDir, setSortDir] = useState("desc");

    // runs like component did mount
    useEffect(() => {
        document.title = "Real Estate";
    }, []);

    // runs when items in array are updated
    useEffect(() => {
        const fetchData = async () => {
            try {
                let response = await Api.get(
                    "clients/" +
                        (client.id ?? client.client.id) +
                        "/real-estate",
                    {
                        limit: 20,
                        sort: sortDir === "desc" ? "-" + orderBy : orderBy,
                        "filter[search]": debouncedSearch,
                    }
                );
                setRealEstate(response.data.data);
                setLoading(false);
            } catch (error) {
                if (error && error.response && error.response.status === 403) {
                    setNotAuthorized(true);
                }
                console.log(error);
                setLoading(false);
            }
        };
        fetchData();
    }, [client, needsUpdated, orderBy, sortDir, debouncedSearch]);

    async function deleteRealEstate(realEstate) {
        try {
            const response = await Api.delete(
                "clients/" + client.id + "/real-estate/" + realEstate.id
            );
            addToast(response.data.message);
            setNeedsUpdated(needsUpdated + 1);
        } catch (error) {
            addToast(error.errorMessage, { type: "error" });
        }
    }

    if (notAuthorized) {
        return <Forbidden />;
    }

    if (loading) {
        return <Spinner />;
    }

    return (
        <>
            <div className="mb-4">
                <SearchInput
                    label={false}
                    searchValue={search}
                    onSearchChange={(e) => setSearch(e.target.value)}
                />
            </div>
            {realEstate.length ? (
                <>
                    <table className="w-full text-left">
                        <thead>
                            <tr>
                                <TableSortHeader
                                    field="property_name"
                                    sortField={orderBy}
                                    sortDir={sortDir}
                                    changeSort={(column) => {
                                        setSortDir(
                                            sortDir === "asc" ? "desc" : "asc"
                                        );
                                        setOrderBy(column);
                                    }}
                                >
                                    Property Name
                                </TableSortHeader>
                                <TableSortHeader
                                    disabled={true}
                                    field="owner_name"
                                    sortField={orderBy}
                                    sortDir={sortDir}
                                    changeSort={(column) => {
                                        setSortDir(
                                            sortDir === "asc" ? "desc" : "asc"
                                        );
                                        setOrderBy(column);
                                    }}
                                >
                                    Owner
                                </TableSortHeader>
                                <TableSortHeader
                                    disabled={true}
                                    field="percentage_owned"
                                    sortField={orderBy}
                                    sortDir={sortDir}
                                    changeSort={(column) => {
                                        setSortDir(
                                            sortDir === "asc" ? "desc" : "asc"
                                        );
                                        setOrderBy(column);
                                    }}
                                >
                                    Percentage Owned
                                </TableSortHeader>
                                <TableSortHeader
                                    field="value"
                                    sortField={orderBy}
                                    sortDir={sortDir}
                                    changeSort={(column) => {
                                        setSortDir(
                                            sortDir === "asc" ? "desc" : "asc"
                                        );
                                        setOrderBy(column);
                                    }}
                                >
                                    Value
                                </TableSortHeader>
                                <TableSortHeader
                                    field="value"
                                    sortField={orderBy}
                                    sortDir={sortDir}
                                    changeSort={(column) => {
                                        setSortDir(
                                            sortDir === "asc" ? "desc" : "asc"
                                        );
                                        setOrderBy(column);
                                    }}
                                >
                                    Mortgage
                                </TableSortHeader>
                                <TableSortHeader
                                    field="value"
                                    sortField={orderBy}
                                    sortDir={sortDir}
                                    changeSort={(column) => {
                                        setSortDir(
                                            sortDir === "asc" ? "desc" : "asc"
                                        );
                                        setOrderBy(column);
                                    }}
                                >
                                    Net Value
                                </TableSortHeader>
                                <TableSortHeader
                                    field="as_of_date"
                                    sortField={orderBy}
                                    sortDir={sortDir}
                                    changeSort={(column) => {
                                        setSortDir(
                                            sortDir === "asc" ? "desc" : "asc"
                                        );
                                        setOrderBy(column);
                                    }}
                                >
                                    As of Date
                                </TableSortHeader>
                                <th className="text-right">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {realEstate.map((realEstate, idx) => (
                                <tr key={idx}>
                                    <td>
                                        <Link
                                            to={`${clientPath}/real-estate/${realEstate.id}/view`}
                                        >{`${realEstate.property_name}`}</Link>
                                    </td>
                                    <td>{realEstate.owner_name}</td>
                                    <td>
                                        {formatPercentage(
                                            realEstate.percentage_owned
                                        )}
                                    </td>
                                    <td>${realEstate.value}</td>
                                    <td>
                                        {parseInt(realEstate.has_lien) ===
                                            1 && (
                                            <span>${realEstate.balance}</span>
                                        )}
                                        {parseInt(realEstate.has_lien) !==
                                            1 && <Money value={0.0} />}
                                    </td>
                                    <td>${realEstate.net_value}</td>
                                    <td>{formatDate(realEstate.as_of_date)}</td>
                                    <td className="text-right">
                                        <span>
                                            <Dropdown
                                                position="right"
                                                toggleClass="px-3 py-1"
                                                toggleContent={
                                                    <FontAwesomeIcon
                                                        icon={faEllipsisH}
                                                    />
                                                }
                                            >
                                                <DropdownItem
                                                    label="Edit"
                                                    icon={faEdit}
                                                    link={`${clientPath}/real-estate/${realEstate.id}/edit`}
                                                />
                                                {(userType === "member" ||
                                                    userType === "client") && (
                                                    <DropdownItem
                                                        label="Delete"
                                                        icon={faTrash}
                                                        handleClick={() =>
                                                            deleteRealEstate(
                                                                realEstate
                                                            )
                                                        }
                                                    />
                                                )}
                                            </Dropdown>
                                        </span>
                                    </td>
                                </tr>
                            ))}
                            <TableSummaryRow
                                columns={[
                                    "",
                                    "",
                                    "",
                                    realEstate
                                        .map((p) => parseMoney(p.value))
                                        .reduce((a, c) => a + c),
                                    realEstate
                                        .filter((p) => p.has_lien)
                                        .map((p) => parseMoney(p.balance))
                                        .reduce((a, c) => a + c, 0),
                                    realEstate
                                        .map((p) => parseMoney(p.net_value))
                                        .reduce((a, c) => a + c),
                                    "",
                                    "",
                                ]}
                            />
                        </tbody>
                    </table>
                </>
            ) : (
                <div className="text-gray-400">
                    There are no real estate entities for this client.{" "}
                    {canCreateWealthRecords && (
                        <Link
                            to={`${clientPath}/real-estate/add`}
                            className="underline text-gray-600"
                        >
                            Add Now.
                        </Link>
                    )}
                </div>
            )}
        </>
    );
}

export default RealEstateTable;
