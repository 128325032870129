import React, { useEffect, useState } from "react";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import Api from "../../services/Api";
import { Link } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import Button from "components/Button";
import Player from "@vimeo/player";

const TaskUrl = ({ task }) => {
    const { user } = useAuth();
    const taskUrl = task.action
        .replace("{memberId}", user.id)
        .replace("{clientId}", user.id);
    return (
        <Link to={taskUrl} className="hover:to-brand-500">
            {task.name}
        </Link>
    );
};

export function TaskList() {
    const { user } = useAuth();
    const introVideo = React.useRef();
    const [tasks, setTasks] = useState(null);
    const [updatingTasks, setUpdatingTasks] = useState(false);
    const [taskPanelOpen, setTaskPanelOpen] = useState(false);
    const [showVideo, setShowVideo] = useState(
        parseInt(user.intro_seconds_watched) > 0 ? false : true
    );

    useEffect(() => {
        async function fetchUserTasks() {
            try {
                const response = await Api.get(`clients/${user.id}/user-tasks`);
                setTasks(response.data);
            } catch (e) {
                console.warn(e);
            }
        }
        if (tasks === null) {
            fetchUserTasks();
        }
    }, [user.id, tasks, setTasks]);

    function openVideo() {
        setShowVideo(true);
    }

    async function closeVideo() {
        if (introVideo.current) {
            const player = new Player(introVideo.current);
            let time = Math.round(await player.getCurrentTime());
            if (!user.intro_seconds_watched) {
                user.intro_seconds_watched = 1;
                Api.put("members/" + user.id, user);
            }

            if (user.intro_seconds_watched < time) {
                user.intro_seconds_watched = time;
                Api.put("members/" + user.id, user);
            }
        }

        setShowVideo(false);

        if (tasks && tasks.data && tasks.data.length > 0) {
            setTaskPanelOpen(true);
        }
    }

    async function toggleTask(task, toggledIndex) {
        let tasksUpdated = tasks.data.map((task, idx) => {
            if (idx === toggledIndex) {
                task.completed_at = !task.completed_at;
            }
            return task;
        });

        setTasks({
            data: tasksUpdated,
            ...tasks,
        });

        if (!updatingTasks) {
            setUpdatingTasks(true);
            try {
                const response = await Api.post(
                    `clients/${user.id}/user-tasks/${task.id}`
                );
                console.log("toggle task", response.data);
                setTasks(response.data);
                setUpdatingTasks(false);
            } catch (e) {
                console.warn(e);
                setUpdatingTasks(false);
            }
        }
    }

    const videoSources = {
        super_member:
            "https://player.vimeo.com/video/656318708?h=ddced773ee&amp;",
        member: "https://player.vimeo.com/video/656318708?h=ddced773ee&amp;",
        client: "https://player.vimeo.com/video/656317433?h=d2de68135e&amp;",
        advisor: "https://player.vimeo.com/video/656318708?h=ddced773ee&amp;",
    };

    return (
        <>
            {tasks !== null && tasks.data && tasks.data.length > 0 && (
                <div
                    className={`fixed top-0 right-0 bottom-0 max-h-screen overflow-auto px-6 py-10 z-50 bg-white max-w-full shadow transform transition-transform duration-200 ${
                        taskPanelOpen ? "translate-x-0" : "translate-x-full"
                    }`}
                    style={{ width: "32.75rem" }}
                >
                    <div className="whitespace-no-wrap flex items-center justify-between pb-6 border-b">
                        <div className="flex items-center">
                            <div className="w-16 h-16">
                                <CircularProgressbar
                                    value={tasks.meta.percentage}
                                    text={`${tasks.meta.percentage}%`}
                                    counterClockwise={true}
                                    background={true}
                                    strokeWidth={2}
                                    styles={{
                                        path: {
                                            stroke: `rgba(0, 162, 228, ${
                                                tasks.meta.percentage / 100
                                            })`,
                                        },
                                        trail: {
                                            stroke: "#FFF",
                                        },
                                        text: {
                                            fill: "#00A2E4",
                                            fontSize: "22px",
                                            fontWeight: "600",
                                        },
                                        background: {
                                            fill: "#EDF4F7",
                                        },
                                    }}
                                />
                            </div>
                            <div className="pl-4">
                                <div className="font-medium">
                                    {tasks.meta.completed}/{tasks.meta.total}{" "}
                                    Complete
                                </div>
                            </div>
                        </div>
                        <button
                            className="text-brand text-sm"
                            onClick={() => {
                                setTaskPanelOpen(false);
                            }}
                        >
                            Hide Checklist
                        </button>
                    </div>
                    {/* Tasks */}
                    <div className="mt-6">
                        <ul className="space-y-8">
                            {tasks.data.map((task, idx) => (
                                <li key={task.id} className="flex items-center">
                                    <button
                                        className="mr-3 cursor-pointer"
                                        onClick={() => {
                                            toggleTask(task, idx);
                                        }}
                                    >
                                        {task.completed_at === null ? (
                                            <div className="w-5 h-5 flex-grow-0 flex-shrink-0 bg-gray-200 rounded-sm"></div>
                                        ) : (
                                            <div className="w-5 h-5 flex-grow-0 flex-shrink-0 bg-brand-500 rounded-sm text-white flex items-center justify-center">
                                                <svg
                                                    aria-hidden="true"
                                                    focusable="false"
                                                    data-prefix="fas"
                                                    data-icon="check"
                                                    className="w-auto h-3"
                                                    role="img"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    viewBox="0 0 512 512"
                                                >
                                                    <path
                                                        fill="currentColor"
                                                        d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"
                                                    ></path>
                                                </svg>
                                            </div>
                                        )}
                                    </button>
                                    {task.action ? (
                                        <TaskUrl task={task} />
                                    ) : (
                                        <>{task.name}</>
                                    )}
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            )}
            <div className="flex items-center">
                {tasks !== null && (
                    <>
                        {tasks.data && tasks.data.length > 0 && (
                            <>
                                <div className="w-16 h-16 ">
                                    <CircularProgressbar
                                        value={tasks.meta.percentage}
                                        text={`${tasks.meta.percentage}%`}
                                        counterClockwise={true}
                                        background={true}
                                        strokeWidth={2}
                                        styles={{
                                            path: {
                                                stroke: `rgba(0, 162, 228, ${
                                                    tasks.meta.percentage / 100
                                                })`,
                                            },
                                            trail: {
                                                stroke: "#FFF",
                                            },
                                            text: {
                                                fill: "#00A2E4",
                                                fontSize: "22px",
                                                fontWeight: "600",
                                            },
                                            background: {
                                                fill: "#EDF4F7",
                                            },
                                        }}
                                    />
                                </div>
                                <div className="pl-4 pr-7 border-r border-gray-300">
                                    <div className="font-medium">
                                        {tasks.meta.completed}/
                                        {tasks.meta.total} Complete
                                    </div>
                                    <button
                                        className="text-brand text-sm"
                                        onClick={() => {
                                            setTaskPanelOpen(true);
                                        }}
                                    >
                                        View Checklist
                                    </button>
                                </div>
                            </>
                        )}

                        <div className="pl-7">
                            <Button
                                text="Watch Intro Video"
                                type="button"
                                onClick={() => {
                                    openVideo();
                                }}
                            ></Button>
                        </div>
                    </>
                )}
            </div>
            {showVideo && (
                <div
                    onClick={() => {
                        closeVideo();
                    }}
                    className="inset-0 fixed z-50 bg-gray-900 bg-opacity-75 flex items-center justify-center"
                >
                    <iframe
                        ref={introVideo}
                        className="h-96"
                        onClick={(event) => event.stopPropagation()}
                        src={`${
                            videoSources[user.type]
                        }badge=0&amp;autopause=0&amp;autoplay=1&amp;player_id=0&amp;app_id=58479`}
                        width="640"
                        height="564"
                        frameBorder="0"
                        allow="autoplay; fullscreen"
                        allowFullScreen
                        title="INHERALINK CLIENT ONBOARDING"
                    ></iframe>
                </div>
            )}
        </>
    );
}
