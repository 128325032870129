import React, { useState } from "react";
import Dropdown, { DropdownItem } from "../../../components/Dropdown";
import {
    faBan,
    faCheck,
    faEdit,
    faUser,
    faKey,
    faEllipsisH,
    faEye,
    faEnvelope,
    faClipboard,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Api from "../../../services/Api";
import { useToasts } from "react-toast-notifications";
import { Link } from "react-router-dom";
import TableSortHeader from "../../../components/TableSortHeader";
import SearchInput from "../../../components/SearchInput";
import useDebounce from "../../../hooks/useDebounce";
import { formatDate } from "../../../util/TextUtil";
import Forbidden from "../../../components/Forbidden";
import usePagination from "hooks/usePagination";
import Pagination from "components/Pagination";
import useAdvisors from "hooks/useAdvisors";
import Badge from "components/Badge";
import { isBool } from "index";
import useHasAnyPermissions from "hooks/useHasAnyPermissions";
import useAuth from "hooks/useAuth";

function AdvisorsTable({ client, clientPath, isClient, showDisabled }) {
    const { user } = useAuth();
    const { addToast } = useToasts();
    const [orderBy, setOrderBy] = useState("first_name");
    const [sortDir, setSortDir] = useState("asc");
    const [search, setSearch] = useState("");
    const debouncedSearch = useDebounce(search, 250);
    const [page, setPage] = usePagination();

    document.title = "Advisors";

    const canManageCollaborators = useHasAnyPermissions([
        "client_related_accounts_administration",
        "firm_client_related_accounts_administration",
    ]);

    const { data, meta, unauthorized, refetch } = useAdvisors({
        clientId: client.id,
        "filter[trashed]": showDisabled ? "only" : false,
        page,
        limit: 25,
        sort: sortDir === "desc" ? "-" + orderBy : orderBy,
        "filter[search]": debouncedSearch,
    });

    async function removeAdvisor(advisorId) {
        try {
            const response = await Api.delete(
                "clients/" + client.id + "/advisors/" + advisorId
            );
            addToast(response.data.message);
            refetch();
        } catch (e) {
            addToast(e.errorMessage, { type: "error" });
        }
    }

    async function restoreAdvisor(advisorId) {
        try {
            const response = await Api.put(
                "clients/" + client.id + "/advisors/" + advisorId,
                {
                    restore: true,
                }
            );
            addToast(response.data.message);
            refetch();
        } catch (e) {
            addToast(e.errorMessage, { type: "error" });
        }
    }

    async function approveAdvisor(advisorId) {
        try {
            const response = await Api.put(
                "clients/" + client.id + "/advisors/" + advisorId,
                {
                    approve: true,
                }
            );
            addToast(response.data.message);
            refetch();
        } catch (e) {
            addToast(e.errorMessage, { type: "error" });
        }
    }

    async function makeCollaborator(advisor) {
        try {
            const response = await Api.post(
                "clients/" +
                    client.id +
                    "/advisors/" +
                    advisor.id +
                    "/collaborator"
            );
            addToast(response.data.message);
            refetch();
        } catch (error) {
            addToast(error?.response.data.message, { type: "error" });
        }
    }

    async function revokeCollaborator(advisor) {
        try {
            const response = await Api.delete(
                "clients/" +
                    client.id +
                    "/advisors/" +
                    advisor.id +
                    "/collaborator"
            );
            addToast(response.data.message);
            refetch();
        } catch (error) {
            addToast(error?.response.data.message, { type: "error" });
        }
    }

    async function resendInvitation(advisor) {
        try {
            const response = await Api.post(
                "clients/" +
                    client.id +
                    "/advisors/" +
                    advisor.id +
                    "/collaborator-resend-invitation"
            );
            addToast(response.data.message);
            refetch();
        } catch (error) {
            addToast(error?.response.data.message, { type: "error" });
        }
    }

    const renderDisableButton = (advisor) => {
        if (user.role === "client_successor") {
            return <></>;
        }

        if (
            !canManageCollaborators &&
            advisor.collaboration_status !== "none"
        ) {
            return <></>;
        }

        if (advisor.deleted_at) {
            return (
                <DropdownItem
                    label="Enable"
                    icon={faCheck}
                    handleClick={() => restoreAdvisor(advisor.id)}
                />
            );
        }

        return (
            <DropdownItem
                label="Disable"
                icon={faBan}
                handleClick={() => removeAdvisor(advisor.id)}
            />
        );
    };

    async function copyAdvisorLinkToClipboard(advisor) {
        try {
            const response = await Api.get(
                "clients/" +
                    client.id +
                    "/advisors/" +
                    advisor.id +
                    "/collaborator-fetch-invitation"
            );
            addToast(response.data.message);

            const url = response.data.link;
            if (window.location.protocol !== "https:") {
                addToast(
                    "HTTPS Required for automatic copy. Here's the link: " +
                        url,
                    {
                        type: "warning",
                    }
                );
                return;
            }
            await navigator.clipboard.writeText(url);
            addToast("Advisor Invite link is now on your clipboard.");
        } catch (e) {
            addToast(
                e.response?.data?.message ||
                    "An error occurred fetching the invitation.",
                {
                    type: "error",
                }
            );
        }
    }

    if (unauthorized) {
        return <Forbidden />;
    }

    return (
        <>
            <div className="mb-4">
                <SearchInput
                    label={""}
                    searchValue={search}
                    onSearchChange={(e) => setSearch(e.target.value)}
                />
            </div>
            {data && data.length > 0 && (
                <>
                    <table className="w-full text-left">
                        <thead>
                            <tr>
                                <TableSortHeader
                                    field="first_name"
                                    sortField={orderBy}
                                    sortDir={sortDir}
                                    changeSort={(column) => {
                                        setSortDir(
                                            sortDir === "asc" ? "desc" : "asc"
                                        );
                                        setOrderBy(column);
                                    }}
                                >
                                    First Name
                                </TableSortHeader>
                                <TableSortHeader
                                    field="last_name"
                                    sortField={orderBy}
                                    sortDir={sortDir}
                                    changeSort={(column) => {
                                        setSortDir(
                                            sortDir === "asc" ? "desc" : "asc"
                                        );
                                        setOrderBy(column);
                                    }}
                                >
                                    Last Name
                                </TableSortHeader>
                                <TableSortHeader
                                    field="email"
                                    sortField={orderBy}
                                    sortDir={sortDir}
                                    changeSort={(column) => {
                                        setSortDir(
                                            sortDir === "asc" ? "desc" : "asc"
                                        );
                                        setOrderBy(column);
                                    }}
                                >
                                    Email
                                </TableSortHeader>
                                <TableSortHeader
                                    field="created_at"
                                    sortField={orderBy}
                                    sortDir={sortDir}
                                    changeSort={(column) => {
                                        setSortDir(
                                            sortDir === "asc" ? "desc" : "asc"
                                        );
                                        setOrderBy(column);
                                    }}
                                >
                                    Added
                                </TableSortHeader>
                                {showDisabled && (
                                    <TableSortHeader
                                        field="deleted_at"
                                        sortField={orderBy}
                                        sortDir={sortDir}
                                        changeSort={(column) => {
                                            setSortDir(
                                                sortDir === "asc"
                                                    ? "desc"
                                                    : "asc"
                                            );
                                            setOrderBy(column);
                                        }}
                                    >
                                        Disabled On
                                    </TableSortHeader>
                                )}
                                <th>Status</th>
                                <th></th>
                            </tr>
                        </thead>

                        <tbody>
                            {data.map((advisor) => {
                                if (advisor.is_primary_attorney) {
                                    return showDisabled === false ? (
                                        <PrimaryAttorneyItem
                                            key="primary_attorney"
                                            primaryAttorney={advisor}
                                        />
                                    ) : (
                                        <></>
                                    );
                                } else {
                                    return (
                                        <tr
                                            key={advisor.id}
                                            className={
                                                advisor.deleted_at ? "" : ""
                                            }
                                        >
                                            <td>
                                                <Link
                                                    to={`${advisor.resource_path}`}
                                                >{`${advisor.first_name}`}</Link>
                                            </td>
                                            <td>
                                                <Link
                                                    to={`${advisor.resource_path}`}
                                                >{`${advisor.last_name}`}</Link>
                                            </td>
                                            <td>{advisor.email}</td>
                                            <td>
                                                {formatDate(advisor.created_at)}
                                            </td>
                                            {showDisabled && (
                                                <td>
                                                    {formatDate(
                                                        advisor.deleted_at
                                                    )}
                                                </td>
                                            )}
                                            <td>
                                                <CollaboratorBadge
                                                    advisor={advisor}
                                                />
                                            </td>
                                            <td className="text-right">
                                                <span>
                                                    <Dropdown
                                                        position="right"
                                                        toggleClass="px-3 py-1"
                                                        toggleContent={
                                                            <FontAwesomeIcon
                                                                icon={
                                                                    faEllipsisH
                                                                }
                                                            />
                                                        }
                                                    >
                                                        <DropdownItem
                                                            label={
                                                                user.role ===
                                                                "client_successor"
                                                                    ? "View"
                                                                    : "Edit"
                                                            }
                                                            icon={faEdit}
                                                            link={`${advisor.resource_path}`}
                                                        />

                                                        {canManageCollaborators &&
                                                            isClient &&
                                                            advisor.collaboration_status ===
                                                                "consent_pending" && (
                                                                <DropdownItem
                                                                    label="Approve"
                                                                    icon={
                                                                        faCheck
                                                                    }
                                                                    handleClick={() =>
                                                                        approveAdvisor(
                                                                            advisor.id
                                                                        )
                                                                    }
                                                                />
                                                            )}

                                                        {canManageCollaborators &&
                                                            advisor.collaboration_status ===
                                                                "invite_pending" && (
                                                                <DropdownItem
                                                                    label="Resend Invitation"
                                                                    icon={
                                                                        faEnvelope
                                                                    }
                                                                    handleClick={() =>
                                                                        resendInvitation(
                                                                            advisor
                                                                        )
                                                                    }
                                                                />
                                                            )}

                                                        {canManageCollaborators &&
                                                            advisor.collaboration_status ===
                                                                "invite_pending" && (
                                                                <DropdownItem
                                                                    label="Copy Invitation Link"
                                                                    icon={
                                                                        faClipboard
                                                                    }
                                                                    handleClick={() =>
                                                                        copyAdvisorLinkToClipboard(
                                                                            advisor
                                                                        )
                                                                    }
                                                                />
                                                            )}

                                                        {canManageCollaborators &&
                                                            isBool(
                                                                advisor.can_collaborate
                                                            ) &&
                                                            advisor.collaborator_id &&
                                                            advisor.consent_given_at && (
                                                                <>
                                                                    <DropdownItem
                                                                        label="Edit Permissions"
                                                                        icon={
                                                                            faKey
                                                                        }
                                                                        link={`${clientPath}/advisors/${advisor.id}/edit-permissions`}
                                                                    />
                                                                    <DropdownItem
                                                                        label="Revoke Collaborator Access"
                                                                        icon={
                                                                            faBan
                                                                        }
                                                                        handleClick={() =>
                                                                            revokeCollaborator(
                                                                                advisor
                                                                            )
                                                                        }
                                                                    />
                                                                </>
                                                            )}

                                                        {renderDisableButton(
                                                            advisor
                                                        )}
                                                        {canManageCollaborators &&
                                                            advisor.collaboration_status ===
                                                                "none" && (
                                                                <DropdownItem
                                                                    label="Make Collaborator"
                                                                    icon={
                                                                        faUser
                                                                    }
                                                                    handleClick={() =>
                                                                        makeCollaborator(
                                                                            advisor
                                                                        )
                                                                    }
                                                                />
                                                            )}
                                                        {canManageCollaborators &&
                                                            advisor.collaboration_status ===
                                                                "disabled" && (
                                                                <DropdownItem
                                                                    label="Restore Collaborator"
                                                                    icon={
                                                                        faUser
                                                                    }
                                                                    handleClick={() =>
                                                                        makeCollaborator(
                                                                            advisor
                                                                        )
                                                                    }
                                                                />
                                                            )}
                                                    </Dropdown>
                                                </span>
                                            </td>
                                        </tr>
                                    );
                                }
                            })}
                        </tbody>
                    </table>
                    <Pagination {...meta} nextPage={setPage} />
                </>
            )}
            {data && data.length === 0 && (
                <div className="text-gray-400">
                    The are no advisors for this client.{" "}
                    {canManageCollaborators && (
                        <Link
                            to={`${clientPath}/advisors/add`}
                            className="underline text-gray-600"
                        >
                            Add Now.
                        </Link>
                    )}
                </div>
            )}
        </>
    );
}

export default AdvisorsTable;

export function PrimaryAttorneyItem({ primaryAttorney }) {
    return (
        <tr className={`bg-gray-100 border-b`}>
            <td>
                <Link
                    to={{
                        pathname: `${primaryAttorney.resource_path}/profile`,
                        state: { go_back: window.location.pathname },
                    }}
                >{`${primaryAttorney.first_name}`}</Link>
            </td>
            <td>
                <Link
                    to={{
                        pathname: `${primaryAttorney.resource_path}/profile`,
                        state: { go_back: window.location.pathname },
                    }}
                >{`${primaryAttorney.last_name}`}</Link>
            </td>
            <td>{primaryAttorney.email}</td>
            <td>{formatDate(primaryAttorney.created_at)}</td>
            <td>
                <Badge color="brand">Primary Attorney</Badge>
            </td>
            <td className="text-right">
                <span>
                    <Dropdown
                        position="right"
                        toggleClass="px-3 py-1"
                        toggleContent={<FontAwesomeIcon icon={faEllipsisH} />}
                    >
                        <DropdownItem
                            label="View"
                            icon={faEye}
                            link={`${primaryAttorney.resource_path}`}
                        />
                    </Dropdown>
                </span>
            </td>
        </tr>
    );
}

export function CollaboratorBadge({ advisor }) {
    if (advisor.collaboration_status === "consent_pending") {
        return <Badge color="red">Consent Pending</Badge>;
    }

    if (advisor.collaboration_status === "invite_pending") {
        return <Badge color="orange">Invite Pending</Badge>;
    }

    if (advisor.collaboration_status === "collaborator") {
        return <Badge color="green">Collaborator</Badge>;
    }

    if (advisor.collaboration_status === "disabled") {
        return <Badge color="red">Disabled Collaborator</Badge>;
    }

    return <Badge color="gray">Non-Collaborator</Badge>;
}
