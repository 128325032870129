import React from "react";
import VerticalTabs from "../../../components/VerticalTabs";

export default function PersonalPropertyTabs({
    basePath,
    showBeneficiary = false,
    ...props
}) {
    let items = [
        {
            label: "Info",
            to: basePath + "",
        },
        {
            label: "Owners",
            to: basePath + "/owners",
        },
        {
            label: "Notes",
            to: basePath + "/notes",
        },
        {
            label: "Related Files",
            to: basePath + "/related-files",
        },
        {
            label: "Related Photos",
            to: basePath + "/related-photos",
        },
        {
            label: "Access",
            to: basePath + "/access",
        },
        {
            label: "Transfers",
            to: basePath + "/transfers",
        },
    ];
    if (parseInt(showBeneficiary) === 1) {
        items.splice(2, 0, {
            label: "Transfer on Death",
            to: basePath + "/beneficiaries",
        });
    }

    return <VerticalTabs {...props} items={items} />;
}
