import React, { useState, useEffect } from "react";
import PageContainer from "../../components/PageContainer";
import ClientPageHeader, {
    Heading,
    HeadingContainer,
} from "../../components/ClientPageHeader";
import Tabs from "../../components/Tabs";
import Api from "../../services/Api";
import Settlement from "./Settlement";
import Exclusion from "./Inclusion";
import DodValues from "./DodValues";
import { useToasts } from "react-toast-notifications";
import SaveButton from "../../components/SaveButton";
import { usePostMortemContext } from "./PostMortemContext";
import EstateInventoryTransfers from "./EstateInventoryTransfers";
import useAuth from "../../hooks/useAuth";
import { formatDate } from "../../util/TextUtil";
import EstateInventoryReport from "../reports/_partials/EstateInventoryReport";
import { isBool } from "index";
import BeneficiaryTransfers from "./BeneficiaryTransfers";
import TransfersTable from "views/funding_table/_partials/TransfersTable";
import useQuickAdd from "hooks/useQuickAdd";
import Dropdown, { DropdownItem } from "components/Dropdown";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faCaretDown,
    faCloudDownloadAlt,
} from "@fortawesome/free-solid-svg-icons";
import ModalModelFactory from "components/ModalModelFactory";
import usePostMortemState from "hooks/usePostMortemState";
import useLiveWealthState from "hooks/useLiveWealthState";
import usePostMortemDownloads from "./usePostMortemDownloads";
import useForm from "hooks/useForm";
import useHasPermission from "hooks/usePermissions";
import EstateAdminNotesReport from "views/reports/EstateAdminNotesReport";

const TAB = {
    WEALTH: "wealth",
    OWNERSHIP: "owners",
    BENEFICIARY_TRANSFERS: "beneficiaries",
    TRANSFERS: "transfers",
    TRANSFER_HISTORY: "history",
    NOTES: "notes",
    SETTLEMENT: "settlement",
    INCLUDE: "include",
    DOD_VALUES: "values",
};

export default function PostMortemIndex({
    client,
    currentTab,
    clientPath,
    ...props
}) {
    const { user } = useAuth();
    const quickAdd = useQuickAdd();
    const {
        data: postMortemData,
        reload: reloadPostMortem,
        notFound,
        isActive,
    } = usePostMortemContext();

    const { alternate_client_valuation_date, alternate_spouse_valuation_date } =
        postMortemData || {};

    const {
        isPostMortem,
        setIsPostMortem,
        person,
        jointSplit,
        setJointSplit,
        showAltValues,
        setShowAltValues,
    } = usePostMortemState();

    useEffect(() => {
        if (!isPostMortem) {
            setIsPostMortem(true);
        }
    }, [isPostMortem]);

    useEffect(() => {
        if (!jointSplit) {
            setJointSplit(true);
        }
    }, [jointSplit]);

    const { setCurrent, setShowDodValue, setOwner } = useLiveWealthState();

    const showDownload = currentTab !== TAB.SETTLEMENT;

    useEffect(() => {
        setShowAltValues(() => showAltValues);
        setShowDodValue(true);

        if (showAltValues) {
            if (person === "spouse") {
                if (!isBool(alternate_spouse_valuation_date)) {
                    setShowAltValues(() => false);
                }
            } else {
                if (!isBool(alternate_client_valuation_date)) {
                    setShowAltValues(() => false);
                }
            }
        }
    }, [showAltValues, person]);

    useEffect(() => {
        setCurrent(true);

        if (currentTab === TAB.OWNERSHIP) {
            setOwner("all");
        }
    }, [currentTab]);

    const { addToast } = useToasts();

    const [saving, setSaving] = useState(false);
    const [errors, setErrors] = useState([]);

    const { input, updateValue, clearUnsavedChanges } = useForm(
        postMortemData || {}
    );

    if (person === "spouse") {
        document.title = "Estate Administration for Spouse";
    } else {
        document.title = "Estate Administration for Client";
    }

    async function update(e) {
        e.preventDefault();
        setSaving(true);
        try {
            const body = { ...input };
            let response = await Api.post(
                "clients/" + client.id + "/post-mortem",
                body
            );
            addToast(response.data.message);
            setErrors([]);
            reloadPostMortem();
            clearUnsavedChanges();
        } catch (e) {
            setErrors([]);
            if (e.response.data.errors) {
                setErrors(e.response.data.errors);
            } else {
                addToast(e.response.data.message, { type: "error" });
            }
        }
        setSaving(false);
    }

    return (
        <>
            <ClientPageHeader client={client} />
            <div className="flex">
                <div className="flex-1">
                    <HeadingContainer>
                        <Heading>Estate Administration </Heading>
                        <div className="flex-0">
                            {currentTab === TAB.SETTLEMENT && (
                                <div className="flex-0">
                                    <SaveButton
                                        isLoading={saving}
                                        onClick={update}
                                    />
                                </div>
                            )}
                        </div>
                    </HeadingContainer>
                    <div className="flex h-auto post-mortem-container overflow-hidden">
                        <div className="flex-1 overflow-auto max-h-screen h-screen">
                            <PageContainer>
                                <div className="space-y-4 mb-10">
                                    {person !== "none" &&
                                        notFound === false &&
                                        isActive && (
                                            <div className="flex space-x-4 justify-between">
                                                <ClientSpouseSelect
                                                    client={client}
                                                    showValuations={true}
                                                    currentTab={currentTab}
                                                />
                                                {showDownload && (
                                                    <PostMortemDownloadsButton />
                                                )}
                                                <div className="flex-none w-36">
                                                    <Dropdown
                                                        position="right"
                                                        toggleButtonAppearance="outline"
                                                        toggleButton={
                                                            <span className="flex items-center space-x-1">
                                                                <span>
                                                                    Quick Add
                                                                </span>
                                                                <FontAwesomeIcon
                                                                    icon={
                                                                        faCaretDown
                                                                    }
                                                                />
                                                            </span>
                                                        }
                                                    >
                                                        {quickAdd.quickAddAssets.map(
                                                            (item) => (
                                                                <DropdownItem
                                                                    key={
                                                                        item.type
                                                                    }
                                                                    label={
                                                                        item.label
                                                                    }
                                                                    handleClick={() => {
                                                                        quickAdd.setQuickAddType(
                                                                            item.type
                                                                        );
                                                                        quickAdd.setQuickAddTitle(
                                                                            item.label
                                                                        );
                                                                        quickAdd.setQuickAddOpen(
                                                                            true
                                                                        );
                                                                    }}
                                                                />
                                                            )
                                                        )}
                                                    </Dropdown>
                                                </div>
                                            </div>
                                        )}
                                    <Tabs
                                        {...props}
                                        persistSearch={true}
                                        items={[
                                            {
                                                label: "Settlement",
                                                to: `${clientPath}/post-mortem`,
                                                active:
                                                    currentTab ===
                                                    TAB.SETTLEMENT,
                                            },
                                            {
                                                label: "Included Assets",
                                                to: `${clientPath}/post-mortem/include`,
                                                active:
                                                    currentTab === TAB.INCLUDE,
                                                disabled:
                                                    person === "" || !isActive,
                                            },
                                            {
                                                label: "D.O.D Values",
                                                to: `${clientPath}/post-mortem/values`,
                                                active:
                                                    currentTab ===
                                                    TAB.DOD_VALUES,
                                                disabled:
                                                    person === "" || !isActive,
                                            },
                                            {
                                                label: "Estate Inventory",
                                                to: `${clientPath}/post-mortem/wealth`,
                                                active:
                                                    currentTab === TAB.WEALTH,
                                                disabled:
                                                    person === "" || !isActive,
                                            },
                                            {
                                                label: "Owner Transfers",
                                                to: `${clientPath}/post-mortem/owners`,
                                                active:
                                                    currentTab ===
                                                    TAB.OWNERSHIP,
                                                disabled:
                                                    person === "" || !isActive,
                                            },
                                            {
                                                label: "Beneficiary Transfers",
                                                to: `${clientPath}/post-mortem/beneficiaries`,
                                                active:
                                                    currentTab ===
                                                    TAB.BENEFICIARY_TRANSFERS,
                                                disabled:
                                                    person === "" || !isActive,
                                            },
                                            {
                                                label: "Transfer History",
                                                to: `${clientPath}/post-mortem/history`,
                                                active:
                                                    currentTab ===
                                                    TAB.TRANSFER_HISTORY,
                                                disabled:
                                                    person === "" || !isActive,
                                            },
                                            {
                                                label: "Notes",
                                                to: `${clientPath}/post-mortem/notes`,
                                                active:
                                                    currentTab === TAB.NOTES,
                                                disabled:
                                                    person === "" || !isActive,
                                            },
                                        ]}
                                    />
                                </div>
                                {currentTab === TAB.SETTLEMENT && (
                                    <Settlement
                                        client={client}
                                        onSubmit={update}
                                        input={input}
                                        errors={errors}
                                        updateValue={updateValue}
                                        {...props}
                                    />
                                )}
                                {currentTab === TAB.INCLUDE && <Exclusion />}
                                {currentTab === TAB.DOD_VALUES && <DodValues />}
                                {currentTab === TAB.TRANSFER_HISTORY && (
                                    <TransfersTable />
                                )}
                                {currentTab === TAB.WEALTH && (
                                    <EstateInventoryReport />
                                )}
                                {currentTab === TAB.OWNERSHIP && (
                                    <EstateInventoryTransfers />
                                )}
                                {currentTab === TAB.BENEFICIARY_TRANSFERS && (
                                    <BeneficiaryTransfers />
                                )}
                                {currentTab === TAB.NOTES && (
                                    <EstateAdminNotesReport />
                                )}
                            </PageContainer>
                        </div>
                    </div>
                </div>
                <div id="ownershipPortal" className="flex-none"></div>
            </div>

            <ModalModelFactory
                modelType={quickAdd.quickAddType}
                client={client}
                user={user}
                clientPath={clientPath}
                isOpen={quickAdd.quickAddOpen}
                title={quickAdd.quickAddTitle}
                onClose={() => {
                    quickAdd.setQuickAddOpen(false);
                    quickAdd.setQuickAddTitle("");
                }}
                onSuccess={() => {
                    quickAdd.setQuickAddOpen(false);
                    quickAdd.setQuickAddTitle("");
                    window.location.reload();
                }}
            />
        </>
    );
}

export function ClientSpouseSelect({
    client,
    currentTab,
    showValuations = false,
}) {
    const {
        setPerson,
        data: postMortemData,
        ...postMortemContext
    } = usePostMortemContext();

    const { person, showAltValues, setShowAltValues } = usePostMortemState();

    const {
        alternate_client_valuation_date,
        alternate_spouse_valuation_date,
        client_valuation_date,
        spouse_valuation_date,
        spouse_date_of_death,
        client_date_of_death,
        is_client_deceased,
        is_spouse_deceased,
    } = postMortemData || {};

    const showSpouse = person === postMortemContext.POST_MORTEM_VIEWS.SPOUSE;

    const alternateValuationEnabled = showSpouse
        ? isBool(alternate_spouse_valuation_date)
        : isBool(alternate_client_valuation_date);

    const shouldShowAltPicker =
        [TAB.BENEFICIARY_TRANSFERS, TAB.OWNERSHIP, TAB.WEALTH].includes(
            currentTab
        ) && alternateValuationEnabled;

    const displayPicker =
        isBool(is_client_deceased) || isBool(is_spouse_deceased);

    if (!displayPicker) {
        return <div></div>;
    }

    const dateOfDeath = showSpouse
        ? spouse_date_of_death
        : client_date_of_death;

    const altValDate = showSpouse
        ? spouse_valuation_date
        : client_valuation_date;

    const showAltDate = alternateValuationEnabled && altValDate;

    return (
        <div className="flex space-x-4 w-full">
            <div className="flex flex-1 flex-wrap">
                <div className="mr-4 mb-4">
                    <select
                        onChange={(e) => {
                            setPerson(e.target.value);
                        }}
                        value={person || ""}
                        className="form-select focus:outline-none rounded border focus:border-gray-800 border-gray-200 h-12 px-3 pr-8 inline-block appearance-none leading-normal"
                    >
                        {postMortemData.is_client_deceased && (
                            <option
                                value={
                                    postMortemContext.POST_MORTEM_VIEWS.CLIENT
                                }
                            >
                                {client.full_name}
                            </option>
                        )}
                        {client.spouse && postMortemData.is_spouse_deceased && (
                            <option
                                value={
                                    postMortemContext.POST_MORTEM_VIEWS.SPOUSE
                                }
                            >
                                {client.spouse.full_name} (Spouse)
                            </option>
                        )}
                    </select>
                </div>
                {showValuations && (
                    <div className="flex space-x-10">
                        <div>
                            <div className="text-sm text-gray-500">
                                Date Of Death
                            </div>
                            <div className="font-bold">
                                {formatDate(dateOfDeath)}
                            </div>
                        </div>
                        {showAltDate && (
                            <div className="flex flex-1 flex-row space-x-4 justify-between">
                                <div>
                                    <div className="text-sm text-gray-500">
                                        Alternate Valuation Date
                                    </div>
                                    <div className="font-bold">
                                        {formatDate(altValDate)}
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                )}
            </div>
            {shouldShowAltPicker && (
                <div className="ml-auto">
                    <select
                        onChange={(e) =>
                            setShowAltValues(isBool(e.target.value))
                        }
                        value={showAltValues ? "1" : "0"}
                        className="form-select focus:outline-none rounded border focus:border-gray-800 border-gray-200 h-12 px-3 pr-8 inline-block appearance-none leading-normal"
                    >
                        <option value={"0"}>Show D.O.D. Values</option>
                        <option value={"1"}>Show Alternate Valuations</option>
                    </select>
                </div>
            )}
        </div>
    );
}

function PostMortemDownloadsButton() {
    const {
        isLoading,
        exportEstateInventory,
        exportBeneficiaryReport,
        exportEstateInventoryNotes,
        exportEstateTransfereesReport,
    } = usePostMortemDownloads();

    const canViewEstateInventoryReport = useHasPermission(
        "report_estate_valuation_report"
    );

    const canViewEstateTransfereesReport = useHasPermission(
        "report_estate_transferees"
    );

    const canViewBeneficiaryClaimsReport = useHasPermission(
        "report_beneficiary_claims_report"
    );

    return (
        <>
            <div className="flex-none w-36">
                <Dropdown
                    position="right"
                    toggleButtonAppearance="outline"
                    isLoading={isLoading}
                    toggleButton={
                        <span className="flex items-center space-x-1">
                            <span>Downloads</span>
                            <FontAwesomeIcon icon={faCaretDown} />
                        </span>
                    }
                >
                    <DropdownItem
                        label="Estate Inventory"
                        icon={faCloudDownloadAlt}
                        handleClick={exportEstateInventory}
                        disabled={!canViewEstateInventoryReport}
                    />
                    <DropdownItem
                        label="Estate Inventory Notes"
                        icon={faCloudDownloadAlt}
                        handleClick={exportEstateInventoryNotes}
                        disabled={!canViewEstateInventoryReport}
                    />
                    <DropdownItem
                        label="Estate Transferees"
                        icon={faCloudDownloadAlt}
                        handleClick={exportEstateTransfereesReport}
                        disabled={!canViewEstateTransfereesReport}
                    />
                    <DropdownItem
                        label="Beneficiary Claims Form"
                        icon={faCloudDownloadAlt}
                        handleClick={exportBeneficiaryReport}
                        disabled={!canViewBeneficiaryClaimsReport}
                    />
                </Dropdown>
            </div>
        </>
    );
}
