import React, { useState } from "react";
import { Route, Switch } from "react-router-dom";
import PageContainer from "../../components/PageContainer";
import Button from "../../components/Button";
import ClientPageHeader, { Heading } from "../../components/ClientPageHeader";
import TrusteesTable from "./_partials/TrusteesTable";

export default function TrusteesIndex({ client, user, clientPath, ...props }) {
    const [showAddTrusteesModal, setShowAddTrusteesModal] = useState(false);

    return (
        <>
            <ClientPageHeader client={client}>
                <Heading>Trustees</Heading>
                <div className="flex space-x-2 flex-0">
                    <Button
                        type="link"
                        appearance="light"
                        to={`${clientPath}/trusts`}
                        text="Back To Trusts"
                    />
                    <Button
                        type="button"
                        text="Add Trustee"
                        onClick={() => setShowAddTrusteesModal(true)}
                    />
                </div>
            </ClientPageHeader>
            <PageContainer>
                <div className="flex space-x-8">
                    <div className="flex-1">
                        <Switch>
                            <Route
                                path={clientPath + "/trustees"}
                                render={(newProps) => (
                                    <TrusteesTable
                                        client={client}
                                        user={user}
                                        clientPath={clientPath}
                                        showAddTrusteesModal={
                                            showAddTrusteesModal
                                        }
                                        setShowAddTrusteesModal={
                                            setShowAddTrusteesModal
                                        }
                                        {...props}
                                        {...newProps}
                                    />
                                )}
                            />
                        </Switch>
                    </div>
                </div>
            </PageContainer>
        </>
    );
}
