import React, { useState, useEffect } from "react";
import Api from "../../services/Api";
import Spinner from "../../components/Spinner";
import PageContainer from "../../components/PageContainer";
import ClientPageHeader, { Heading } from "../../components/ClientPageHeader";
import BooleanQuestion from "./_partials/BooleanQuestion";
import RangeQuestion from "./_partials/RangeQuestion";
import MultipleChoiceQuestion from "./_partials/MultipleChoiceQuestion";
import SaveButton from "../../components/SaveButton";
import { useToasts } from "react-toast-notifications";
import Forbidden from "../../components/Forbidden";
import useHasAnyPermissions from "hooks/useHasAnyPermissions";
import ClientNotes from "views/clients/Notes/ClientNotes";
import Button from "components/Button";
import { downloadFile } from "util/Downloader";

export default function EditClientQuestionnaire({
    client,
    clientPath,
    ...props
}) {
    const [loading, setLoading] = useState(true);
    const [isExporting, setIsExporting] = useState(false);
    const [notAuthorized, setNotAuthorized] = useState(false);
    const [questionnaire, setQuestionnaire] = useState(null);
    const { addToast } = useToasts();

    const canViewQuestions = useHasAnyPermissions([
        "member_question_viewing",
        "client_question_viewing",
        "advisor_answer_viewing",
    ]);

    const canAnswerQuestions = useHasAnyPermissions([
        "question_answering",
        "client_question_answering",
    ]);

    useEffect(() => {
        document.title = questionnaire ? questionnaire.title : "";
    }, [questionnaire]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                let response = await Api.get(
                    "clients/" +
                        client.id +
                        "/questionnaires/" +
                        props.match.params.questionnaireId
                );
                setQuestionnaire(response.data.data);
                setLoading(false);
            } catch (error) {
                if (error.response.status === 403) {
                    setNotAuthorized(true);
                }
            }
        };
        fetchData();
    }, [client, props.match.params.questionnaireId]);

    async function updateResponse(question, body) {
        try {
            await Api.put(
                "clients/" +
                    client.id +
                    "/questionnaires/" +
                    props.match.params.questionnaireId +
                    "/questions/" +
                    question.id +
                    "/responses",
                body
            );
            setLoading(false);
        } catch (error) {
            if (error.response.status === 403) {
                addToast(error.response.data.message, { type: "error" });
            }
        }
    }

    if (notAuthorized || !canViewQuestions) {
        return <Forbidden />;
    }

    if (loading) {
        return (
            <div className="h-screen w-full flex items-center justify-center">
                <Spinner />
            </div>
        );
    }

    function renderQuestion(question) {
        switch (question.type) {
            case "boolean":
                return (
                    <BooleanQuestion
                        disabled={canAnswerQuestions === false}
                        question={question}
                        onUpdate={(response) =>
                            updateResponse(question, response)
                        }
                    />
                );
            case "range":
                return (
                    <RangeQuestion
                        disabled={canAnswerQuestions === false}
                        question={question}
                        onUpdate={(response) =>
                            updateResponse(question, response)
                        }
                    />
                );
            case "multiple_choice":
                return (
                    <MultipleChoiceQuestion
                        disabled={canAnswerQuestions === false}
                        question={question}
                        onUpdate={(response) =>
                            updateResponse(question, response)
                        }
                    />
                );
            default:
                return "";
        }
    }

    async function handlePrint(event) {
        event.preventDefault();
        try {
            setIsExporting(true);
            let response = await Api.get(
                "clients/" +
                    client.id +
                    "/questionnaires/" +
                    props.match.params.questionnaireId,
                {
                    export: true,
                },
                { responseType: "arraybuffer" }
            );
            await downloadFile(response, "questions");
            setIsExporting(false);
            addToast("Questions downloaded");
        } catch (e) {
            setIsExporting(false);
            addToast(e.response.data.message, { type: "error" });
        }
    }

    return (
        <>
            <ClientPageHeader client={client}>
                <Heading
                    backTo={`${clientPath}/questionnaires`}
                    backText="All Questionnaires"
                >
                    {questionnaire.title}
                </Heading>
                <div className="flex space-x-2">
                    {canViewQuestions && (
                        <div className="inline-block">
                            <Button
                                type="button"
                                text="Download"
                                appearance="light"
                                width="w-auto"
                                isLoading={isExporting}
                                onClick={(event) => handlePrint(event)}
                            />
                        </div>
                    )}
                    {canAnswerQuestions && (
                        <div className="inline-block">
                            <SaveButton
                                onClick={() => addToast("Questions Saved.")}
                            />
                        </div>
                    )}
                </div>
            </ClientPageHeader>
            <PageContainer>
                <div className="spacey-6">
                    <div
                        className={`max-w-2xl mx-auto ${
                            canAnswerQuestions === false
                                ? "opacity-75"
                                : "opacity-100"
                        }`}
                    >
                        {questionnaire.questions.map((question) => (
                            <div
                                className="border-b border-gray-200 py-6"
                                key={`question_${question.id}`}
                            >
                                {renderQuestion(question)}
                            </div>
                        ))}

                        {questionnaire.questions.length === 0 && (
                            <div className="text-gray-400">
                                This questionnaire does not have any
                                questions...
                            </div>
                        )}
                    </div>
                    <div>
                        <Heading>Notes</Heading>
                        <ClientNotes
                            client={client}
                            resourceType={"Questionnaire"}
                            resourceId={questionnaire.id}
                            autoFocus={false}
                            {...props}
                        />
                    </div>
                </div>
            </PageContainer>
        </>
    );
}
