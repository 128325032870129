import React from "react";
import SelectInput from "../../../components/SelectInput";

export default function AdvisorTypeSelect(props) {
    const options = [
        { value: 1, label: "Accountant" },
        { value: 2, label: "Attorney" },
        { value: 3, label: "Financial Planner" },
        { value: 4, label: "Insurance Advisor" },
        { value: 5, label: "Primary Bank" },
        { value: 6, label: "Primary Physician" },
        { value: 7, label: "Multi-Disciplinary" },
        { value: 8, label: "Other" },
    ];

    return (
        <SelectInput
            {...props}
            label={props.label ? props.label : "Advisor Type"}
            options={options}
        ></SelectInput>
    );
}
