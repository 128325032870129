import React, { useEffect, useState } from "react";
import Spinner from "../../components/Spinner";
import useClient from "hooks/useClient";
import AssetDisplayForBeneficiaryTransfer from "../../components/Ownership/AssetDisplayForBeneficiaryTransfer";
import ManageBeneficiaryTransfer from "../../components/Transfer/ManageBeneficiaryTransfer";
import usePostMortemState from "hooks/usePostMortemState";
import usePostMortemBeneficiaries from "../../hooks/usePostMortemBeneficiaries";
import { usePostMortemContext } from "./PostMortemContext";
import { Redirect } from "react-router-dom";

export default function BeneficiaryTransfers() {
    const { client, clientPath } = useClient();

    const [manageTransfer, setManageTransfer] = useState(null);

    const isManageTransferOpen = manageTransfer?.asset?.id ? true : false;

    const { person } = usePostMortemState();
    const { isActive } = usePostMortemContext();

    const enabled = !!person;

    const {
        data: assets,
        isLoading: loading,
        remove,
        refetch,
    } = usePostMortemBeneficiaries(
        {
            clientId: client.id,
            includeTrusts: false,
            liabilities: true,
            person,
            is_post_mortem: true,
        },
        {
            keepPreviousData: false,
            enabled,
        }
    );

    const refreshData = () => {
        remove();
        refetch();
    };

    useEffect(() => {
        if (!isManageTransferOpen) {
            refreshData();
        }
    }, [isManageTransferOpen, manageTransfer]);

    if (!isActive) {
        return <Redirect to={`${clientPath}/post-mortem`} />;
    }

    if (loading || !enabled) {
        return <Spinner message="Loading Beneficiary Designations" />;
    }

    return (
        <>
            <div className="w-full space-y-6">
                {assets.map((asset) => {
                    return (
                        <AssetDisplayForBeneficiaryTransfer
                            key={`${asset.type}_${asset.id}`}
                            client={client}
                            asset={asset}
                            manageTransfer={(asset, beneficiaries) => {
                                setManageTransfer({ asset, beneficiaries });
                            }}
                        />
                    );
                })}
                {assets?.length === 0 && (
                    <div>
                        No Beneficiary Designations Exist. Please add
                        Beneficiary Designations, if applicable.
                    </div>
                )}
            </div>

            <ManageBeneficiaryTransfer
                client={client}
                asset={manageTransfer?.asset}
                beneficiaries={manageTransfer?.beneficiaries}
                isOpen={isManageTransferOpen}
                closeModal={() => setManageTransfer(null)}
                onClose={() => {
                    refreshData();
                    setManageTransfer(false);
                }}
            />
        </>
    );
}
