import React, { useState } from "react";
import ReactDOM from "react-dom";
import PageContainer from "../../components/PageContainer";
import ClientPageHeader, { Heading } from "../../components/ClientPageHeader";
import ModalModelFactory from "../../components/ModalModelFactory";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Dropdown, { DropdownItem } from "../../components/Dropdown";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons";
import Tabs from "../../components/Tabs";
import useQuickAdd from "../../hooks/useQuickAdd";
import { useQueryClient } from "react-query";
import { formatDate } from "../../util/TextUtil";
import Spinner from "../../components/Spinner";
import Transfer from "../../components/Transfer/Transfer";
import TransfersTable from "./_partials/TransfersTable";
import AssetDisplayForTransfer from "../../components/Ownership/AssetDisplayForTransfer";
import VerifyIndividualTransfer from "../../components/Transfer/VerifyIndividualTransfer";
import { useOwnershipContext } from "contexts/OwnershipContext";
import useClient from "hooks/useClient";
import useAuth from "../../hooks/useAuth";
import FundingTableOwners from "./_partials/FundingTableOwners";
import usePostMortemState from "../../hooks/usePostMortemState";
import useLiveWealthState from "hooks/useLiveWealthState";
import { PostMortemOwnershipSidebar } from "./_partials/PostMortemWealthOwners";

export default function FundingTableIndex({
    client,
    clientPath,
    currentTab,
    ...props
}) {
    const TAB = {
        OWNERSHIP: "ownership",
        TRANSFERS: "transfers",
        GIFTS: "gifts",
    };

    document.title = "Owner Changes and Transfers";

    return (
        <>
            <ClientPageHeader client={client}></ClientPageHeader>
            <div className="flex h-auto">
                <div className="flex-1 h-full">
                    <div className="flex">
                        <div className="flex-1">
                            <PageContainer>
                                <div className="mb-6">
                                    <Heading>
                                        Ownership Changes & Transfers
                                    </Heading>
                                </div>
                                <Tabs
                                    {...props}
                                    items={[
                                        {
                                            label: "Ownership",
                                            to: `${clientPath}/funding-table`,
                                            active:
                                                currentTab === TAB.OWNERSHIP,
                                        },
                                        {
                                            label: "Transfer History",
                                            to: `${clientPath}/funding-table/transfers`,
                                            active: currentTab === TAB.GIFTS,
                                        },
                                    ]}
                                />

                                {currentTab !== TAB.OWNERSHIP && (
                                    <TransfersTable />
                                )}
                                {currentTab === TAB.OWNERSHIP && (
                                    <FundingTableAssetOwnerships
                                        currentTab={currentTab}
                                        clientPath={clientPath}
                                    />
                                )}
                            </PageContainer>
                        </div>
                        <div id="ownershipPortal" className="flex-none"></div>
                    </div>
                </div>
            </div>
        </>
    );
}

export function FundingTableAssetOwnerships({ clientPath, currentTab }) {
    const {
        transferAsset,
        setTransferAsset,
        assets,
        verifyAsset,
        setVerifyAsset,
        verifyOwnership,
        setVerifyOwnership,
        verifyTransfer,
        setVerifyTransfer,
    } = useOwnershipContext();

    const { current, setCurrent } = useLiveWealthState();
    const { isPostMortem } = usePostMortemState();

    const quickAdd = useQuickAdd();
    const [ownersNeedsRefreshed, setOwnersNeedsRefreshed] = useState(0);

    const { client } = useClient();

    const { user } = useAuth();

    const {
        fetchOptions,
        isLoading,
        isFetching: isReadOnly,
        refreshData: refreshAssets,
    } = useOwnershipContext();

    const queryClient = useQueryClient();

    const refreshData = () => {
        queryClient.refetchQueries({ active: true });
        refreshAssets();
    };

    return (
        <div>
            <div className="mb-6">
                <OwnershipViewTabs
                    current={current}
                    setCurrent={setCurrent}
                    currentTab={currentTab}
                    quickAdd={quickAdd}
                    showQuickAdd={isPostMortem === false}
                />
            </div>
            {isLoading ? (
                <div className="h-full justify-center">
                    <Spinner message="Loading Ownerships" />
                </div>
            ) : (
                <div className="w-full space-y-6">
                    {assets?.length === 0 && (
                        <>
                            <p>
                                {isPostMortem
                                    ? "No Ownerships Eligible for Transfer. Beneficiary Transfers are handled separately."
                                    : "No Ownerships Exists. Use the Quick Add above to get started."}
                            </p>
                        </>
                    )}
                    {assets?.map((asset) => {
                        let date = formatDate(
                            current ? asset.verified_at : asset.proposed_at,
                            ""
                        );

                        return (
                            <AssetDisplayForTransfer
                                key={`${asset.type}_${asset.id}`}
                                client={client}
                                asset={asset}
                                ownerships={asset.current_owners}
                                setVerifyAsset={setVerifyAsset}
                                setVerifyOwnership={setVerifyOwnership}
                                setVerifyTransfer={setVerifyTransfer}
                                isReadOnly={isReadOnly}
                                date={date}
                                onSave={() => {
                                    refreshData();
                                }}
                                showProposed={!current}
                            />
                        );
                    })}
                </div>
            )}

            {!isPostMortem && <OwnershipSidebar />}
            {isPostMortem && <PostMortemOwnershipSidebar />}

            <ModalModelFactory
                modelType={quickAdd.quickAddType}
                client={client}
                user={user}
                clientPath={clientPath}
                isOpen={quickAdd.quickAddOpen}
                title={quickAdd.quickAddTitle}
                onClose={() => {
                    quickAdd.setQuickAddOpen(false);
                    quickAdd.setQuickAddTitle("");
                }}
                onSuccess={() => {
                    quickAdd.setQuickAddOpen(false);
                    quickAdd.setQuickAddTitle("");
                    setOwnersNeedsRefreshed(ownersNeedsRefreshed + 1);
                    refreshData();
                    fetchOptions();
                }}
            />

            <Transfer
                client={client}
                asset={transferAsset}
                isOpen={transferAsset.id ? true : false}
                closeModal={() => setTransferAsset(false)}
                canBeGift={true}
                onClose={() => {
                    refreshData();
                    setTransferAsset(false);
                }}
            />

            <VerifyIndividualTransfer
                client={client}
                asset={verifyAsset}
                transfer={verifyTransfer}
                source={verifyAsset.ownership}
                ownership={verifyOwnership}
                isOpen={verifyOwnership.id}
                closeModal={() => {
                    setVerifyOwnership(false);
                    setVerifyTransfer(false);
                    setVerifyAsset(false);
                }}
                onClose={() => {
                    refreshData();
                    setVerifyOwnership(false);
                    setVerifyTransfer(false);
                    setVerifyAsset(false);
                }}
            />
        </div>
    );
}

export function OwnershipViewTabs({
    quickAdd,
    current,
    setCurrent,
    currentTab,
    showQuickAdd = true,
}) {
    return (
        <div className="flex justify-between w-full h-12">
            <div className="flex border rounded-md w-80 overflow-hidden">
                <button
                    type="button"
                    onClick={() => setCurrent(true)}
                    className={`w-1/2 px-3 rounded-none focus:outline-none ${
                        current
                            ? "bg-white text-brand-500 focus:bg-white"
                            : "bg-gray-100 text-gray-400 focus:bg-gray-100"
                    }`}
                >
                    Current
                </button>
                <button
                    type="button"
                    onClick={() => setCurrent(false)}
                    className={`w-1/2 px-3 rounded-none focus:outline-none border-l ${
                        current === false
                            ? "bg-white text-brand-500 focus:bg-white"
                            : "bg-gray-100 text-gray-400 focus:bg-gray-100"
                    }`}
                >
                    Proposed
                </button>
            </div>
            {currentTab === "ownership" && showQuickAdd && (
                <div className="flex-none w-36">
                    <Dropdown
                        position="right"
                        toggleButtonAppearance="light"
                        toggleButton={
                            <span className="flex items-center space-x-1">
                                <span>Quick Add</span>
                                <FontAwesomeIcon icon={faCaretDown} />
                            </span>
                        }
                    >
                        {quickAdd.quickAddAssets.map((item) => (
                            <DropdownItem
                                key={item.type}
                                label={item.label}
                                handleClick={() => {
                                    quickAdd.setQuickAddType(item.type);
                                    quickAdd.setQuickAddTitle(item.label);
                                    quickAdd.setQuickAddOpen(true);
                                }}
                            />
                        ))}
                    </Dropdown>
                </div>
            )}
        </div>
    );
}

export function OwnershipSidebar() {
    return ReactDOM.createPortal(
        <div className="flex-none w-72 h-full max-h-screen overflow-auto border-l border-gray-200 overscroll-contain">
            <FundingTableOwners />
        </div>,
        document.getElementById("rightSideBar")
    );
}
