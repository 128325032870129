import { isBool } from "index";
import { useContext } from "react";
import { AuthContext } from "../contexts/AuthContext";

export default function useHasPermission(permission) {
    const authContext = useContext(AuthContext);

    return authContext.user.permissions[permission]
        ? isBool(authContext.user.permissions[permission].enabled)
        : false;
}

export function useHasRole(role) {
    const authContext = useContext(AuthContext);
    if (Array.isArray(role)) {
        return role.includes(authContext.user.role);
    }
    return authContext.user.role === role;
}

export function useHasUserType(type) {
    const authContext = useContext(AuthContext);
    if (Array.isArray(type)) {
        return type.includes(authContext.user.type);
    }
    return authContext.user.type === type;
}

export function useGetUserRole() {
    const authContext = useContext(AuthContext);
    return authContext.user.role;
}

export function useGetUserType() {
    const authContext = useContext(AuthContext);
    return authContext.user.type;
}
