import React, { useEffect, useState } from "react";
import Spinner from "components/Spinner";
import Panel from "components/Panel";
import Money, { parseMoney } from "components/Money";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faExternalLinkAlt,
    faPencilAlt,
} from "@fortawesome/free-solid-svg-icons";
import { formatDate } from "util/TextUtil";
import useFormatPercentage from "hooks/useFormatPercentage";
import ResourceSelect from "components/ResourceSelect";
import useClient from "hooks/useClient";
import usePostMortemState from "hooks/usePostMortemState";
import useLiveWealthState from "hooks/useLiveWealthState";
import usePostMortemWealthFetch from "hooks/usePostMortemWealthFetch";
import { usePostMortemContext } from "views/post_mortem/PostMortemContext";
import { isBool } from "index";
import useAssetOwnerValues from "views/funding_table/hooks/useAssetOwnerValues";
import { classNames } from "Utility";
import Modal from "components/Modal";
import ClientNotes from "views/clients/Notes/ClientNotes";

export default function EstateInventoryReport() {
    const { client } = useClient();

    const { current, owner, setOwner, showDodValue } = useLiveWealthState({
        ownerInitial: `Client:${client.id}`,
    });

    const { person, isPostMortem, showAltValues, jointSplit } =
        usePostMortemState({
            joint_split: true,
            is_post_mortem: true,
        });

    const enabled = !!person;

    const { isLoading: loadingAssets, data } = usePostMortemWealthFetch(
        {
            owner,
            current,
            client,
            isPostMortem,
            showAltValues,
            snapshot: true,
            person,
            jointSplit,
        },
        {
            keepPreviousData: false,
            enabled,
        }
    );

    const [ownerOptions] = useState(["Client", "Trust", "FundingEntity"]);
    const [excludedOwners, setExcludedOwners] = useState([]);

    useEffect(() => {
        let excluded = [];
        if (client.spouse) {
            excluded = [
                person === "client"
                    ? `Spouse:${client.spouse.id}`
                    : `Client:${client.id}`,
            ];
        }
        setExcludedOwners(excluded);

        let newOwner =
            person === "client"
                ? `Client:${client.id}`
                : `Spouse:${client.spouse.id}`;
        setOwner((owner) => (owner === "all" ? newOwner : owner));
    }, [person]);

    if (loadingAssets || !enabled) {
        return <Spinner />;
    }

    const { assets, assetKeys, sortKeys, totals, ownerTotals } = data;

    return (
        <>
            <div className="flex space-x-2">
                <div className="flex-1 justify-between flex">
                    <div className="max-w-xs w-full">
                        <ResourceSelect
                            resourceType={ownerOptions}
                            client={client}
                            excluded={excludedOwners}
                            name="resource"
                            value={owner}
                            onChange={(e) => {
                                setOwner(e.target.value);
                            }}
                        />
                    </div>
                    <div className={"text-right"}>
                        <div className="text-gray-600">Total Net Worth</div>
                        <span className="font-bold font-tabular">
                            <Money
                                value={
                                    showDodValue
                                        ? totals.estateNetWorth
                                        : totals.netWorth
                                }
                            />
                        </span>
                    </div>
                </div>
            </div>
            {sortKeys.map((key, ix) => {
                let curAssetKeys = assetKeys[key];
                return (
                    <Panel key={`ownerGroup_${ix}`} className="mt-6">
                        <div className="border-b border-gray-200 flex justify-between pb-4">
                            <h2 className="text-sm">
                                <span className="font-bold text-base">
                                    {key}
                                </span>
                            </h2>
                            <h2 className={"text-sm"}>
                                <span className="font-bold text-base">
                                    <Money
                                        value={
                                            showDodValue
                                                ? ownerTotals[key]
                                                      .dod_net_value_sum
                                                : ownerTotals[key].net_value_sum
                                        }
                                    />
                                </span>
                            </h2>
                        </div>
                        <div className="space-y-10">
                            {curAssetKeys.map((curKey, idx) => {
                                return (
                                    <table
                                        className="text-left w-full"
                                        key={`assetTable_${idx}`}
                                    >
                                        {idx === 0 && (
                                            <thead>
                                                <tr className="text-gray-500 uppercase">
                                                    <th className="text-xs">
                                                        Assets
                                                    </th>
                                                    <th className="text-xs">
                                                        Act. Number
                                                    </th>
                                                    <th className="text-xs">
                                                        As of Date
                                                    </th>
                                                    <th className="text-xs">
                                                        Ownership
                                                    </th>
                                                    <th
                                                        className={
                                                            "text-center text-xs"
                                                        }
                                                    >
                                                        Status
                                                    </th>
                                                    <th
                                                        className={
                                                            "text-right text-xs"
                                                        }
                                                    >
                                                        Value
                                                    </th>
                                                </tr>
                                            </thead>
                                        )}
                                        <tbody>
                                            <AssetType
                                                idx={idx}
                                                assets={assets[key][curKey]}
                                            />
                                        </tbody>
                                    </table>
                                );
                            })}
                        </div>
                    </Panel>
                );
            })}
        </>
    );
}

function AssetType({ assets, idx }) {
    const { person, showAltValues } = usePostMortemState();

    let ownerDodNetValueKey =
        person === "spouse"
            ? "owner_dod_net_value_spouse"
            : "owner_dod_net_value";

    if (showAltValues) {
        ownerDodNetValueKey =
            person === "spouse"
                ? "owner_alt_dod_net_value_spouse"
                : "owner_alt_dod_net_value";
    }

    let assetEstateTotal = assets.reduce(
        (a, b) => a + parseMoney(b[ownerDodNetValueKey]),
        0
    );

    const assetType = assets[0].type_formatted;
    return assets.map((asset, i) => {
        return (
            <AssetDetail
                index={i}
                totalAssets={assets.length}
                assetType={assetType}
                key={assetType + "_" + idx + "_" + i}
                asset={asset}
                assetEstateTotal={assetEstateTotal}
            />
        );
    });
}

function AssetDetail({
    index,
    assetType,
    asset,
    totalAssets,
    assetEstateTotal,
}) {
    const formatPercentage = useFormatPercentage();

    const { person } = usePostMortemState();

    const { client } = useClient();

    const { data: postMortemData } = usePostMortemContext();

    let disabled = isBool(postMortemData.is_client_dod_locked);

    if (person == "spouse") {
        disabled = isBool(postMortemData.is_spouse_dod_locked);
    }

    const showExternalLink = !disabled;

    const {
        asOfDate,
        gross_value,
        status,
        lienValue,
        lienStatus,
        lienAsOfDate,
    } = useAssetOwnerValues({ asset });

    const hasNotes =
        (person === "spouse"
            ? asset.spouse_notes_count
            : asset.client_notes_count) > 0;

    const [showNotesModal, setShowNotesModal] = useState(false);

    return (
        <>
            {index === 0 && (
                <tr className="font-bold">
                    <td colSpan={6} className="py-0 pb-2">
                        {assetType}
                    </td>
                </tr>
            )}
            <tr className="text-gray-600">
                <td className="pb-3 pt-0 w-72">
                    <div className="space-x-1">
                        <span>{asset.asset_name}</span>
                        {showExternalLink && (
                            <a
                                href={asset.resource_path}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                &nbsp;
                                <FontAwesomeIcon
                                    icon={faExternalLinkAlt}
                                    className="text-gray-500 hover:text-brand text-xs"
                                />
                            </a>
                        )}

                        <button onClick={() => setShowNotesModal(true)}>
                            &nbsp;
                            <FontAwesomeIcon
                                icon={faPencilAlt}
                                className={classNames(
                                    " hover:text-brand text-xs",
                                    hasNotes
                                        ? "text-yellow-400"
                                        : "text-gray-500"
                                )}
                            />
                        </button>

                        <Modal
                            isOpen={showNotesModal}
                            backgroundDismiss={true}
                            title={`${asset.asset_name} - Notes`}
                            size="2xl"
                            onCancel={() => {
                                setShowNotesModal(false);
                            }}
                            component={() => (
                                <>
                                    <ClientNotes
                                        client={client}
                                        onlyEstateAdmin={true}
                                        resourceType={
                                            asset.original_resource_type ||
                                            asset.resource_type
                                        }
                                        resourceId={asset.resource_id}
                                    />
                                </>
                            )}
                        />
                    </div>
                </td>
                <td className="pb-3 pt-0 w-32">{asset.account_number}</td>
                <td className="pb-3 pt-0 w-32">{formatDate(asOfDate)}</td>
                <td className="pb-3 pt-0 w-32">
                    ({formatPercentage(asset.percentage_owned)})
                </td>
                <td className={"text-center"}>
                    {status || (
                        <span className="text-red-500 text-lg">&times;</span>
                    )}
                </td>
                <td className={"text-right tabular-nums pb-3 pt-0 w-32"}>
                    {asset.is_liability ? (
                        <span className="text-red-500">
                            (<Money value={gross_value} />)
                        </span>
                    ) : (
                        <Money value={gross_value} />
                    )}
                </td>
            </tr>
            {asset.lien && (
                <tr className="text-gray-600">
                    <td className="pt-0 pb-2" colSpan={2}>
                        <div className="ml-1 flex items-center space-x-1 -mt-3">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="h-3 w-3 transform rotate-180 inline-block text-gray-500"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                            >
                                <path
                                    fillRule="evenodd"
                                    d="M7.707 3.293a1 1 0 010 1.414L5.414 7H11a7 7 0 017 7v2a1 1 0 11-2 0v-2a5 5 0 00-5-5H5.414l2.293 2.293a1 1 0 11-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                                    clipRule="evenodd"
                                />
                            </svg>
                            <div>
                                <span className="font-semibold">Lien: </span>
                                {asset.lien.name}
                            </div>
                        </div>
                    </td>
                    <td className="pt-0 pb-2">{formatDate(lienAsOfDate)}</td>
                    <td className="pb-3 pt-0 w-32">
                        ({formatPercentage(asset.percentage_owned)})
                    </td>
                    <td className={"text-center"}>
                        {lienStatus || (
                            <span className="text-red-500 text-lg">
                                &times;
                            </span>
                        )}
                    </td>
                    <td className="pt-0 pb-2 text-red-500 text-right">
                        (<Money value={lienValue} />)
                    </td>
                </tr>
            )}
            {index === totalAssets - 1 && (
                <AssetTotals
                    isLiability={asset.is_liability}
                    assetType={assetType}
                    assetEstateTotal={assetEstateTotal}
                />
            )}
        </>
    );
}

function AssetTotals({ assetType, assetEstateTotal, isLiability }) {
    return (
        <tr className="bg-gray-100">
            <td colSpan="6" className="text-right tabular-nums py-1 rounded-md">
                <span className="text-gray-500 mr-3 font-normal">
                    Total {assetType}:
                </span>

                <span className="text-black font-semibold">
                    {isLiability ? (
                        <span className="text-red-500">
                            (<Money value={assetEstateTotal} />)
                        </span>
                    ) : (
                        <Money value={assetEstateTotal} />
                    )}
                </span>
            </td>
        </tr>
    );
}
