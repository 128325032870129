import React from "react";
import Transferees from "./Transferees";
import useHasAnyPermissions from "hooks/useHasAnyPermissions";

export default function LifetimeTransferees({ client, ...props }) {
    const isForbidden = !useHasAnyPermissions([
        "report_global_access",
        "report_lifetime_transferees",
    ]);
    return (
        <Transferees
            client={client}
            title="Lifetime Transferees"
            pathname="lifetime-transferees"
            isPostMortem={false}
            forbidden={isForbidden}
            {...props}
        />
    );
}
