import React, { useState, useEffect } from "react";
import ClientPageHeader, { Heading } from "../../components/ClientPageHeader";
import PageContainer from "../../components/PageContainer";
import Api from "../../services/Api";
import { useToasts } from "react-toast-notifications";
import Spinner from "../../components/Spinner";
import { formatDate } from "../../util/TextUtil";
import Button from "../../components/Button";
import TextInput from "../../components/TextInput";
import SelectInput from "../../components/SelectInput";
import { downloadFile } from "../../util/Downloader";
import Panel, { PanelHeader } from "../../components/Panel";
import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";
import useDateRange from "hooks/useDateRange";
import useHasAnyPermissions from "hooks/useHasAnyPermissions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useClient from "hooks/useClient";

export const changedByOptions = [
    {
        value: "all",
        label: "All Changes",
    },
    {
        value: "all_internal",
        label: "All Internal",
    },
    {
        value: "client_spouse",
        label: "Client & Spouse",
    },
    {
        value: "external",
        label: "All External",
    },
    {
        value: "authorized_party",
        label: "Authorized Parties",
    },
    {
        value: "collaborating_advisor",
        label: "Collaborating Advisors",
    },
];

export default function ListOfChanges({ client }) {
    document.title = "List of Changes Report";

    const { clientPath } = useClient();
    const [loadingChanges, setLoadingChanges] = useState(false);
    const [needsUpdated, setNeedsUpdated] = useState(0);
    const [changes, setChanges] = useState([]);
    const [changedBy, setChangedBy] = useState("all_changes");
    const { dates, updateStartDate, updateEndDate } = useDateRange();

    const [isExporting, setIsExporting] = useState(false);

    const { addToast } = useToasts();

    useEffect(() => {
        async function fetchData() {
            try {
                setLoadingChanges(true);
                let response = await Api.get(
                    "clients/" + client.id + "/reports/list-of-changes",
                    {
                        startDate: dates.startDate,
                        endDate: dates.endDate,
                        allChanges: true,
                        changedBy,
                    }
                );
                setChanges(response.data);
                setLoadingChanges(false);
            } catch (error) {
                setLoadingChanges(false);
                console.log(error);
            }
        }
        fetchData();
    }, [needsUpdated, client.id]);

    async function exportRecords(e) {
        e.preventDefault();
        try {
            setIsExporting(true);
            let response = await Api.get(
                "clients/" + client.id + "/reports/list-of-changes",
                {
                    export: "pdf",
                    startDate: dates.startDate,
                    endDate: dates.endDate,
                    allChanges: true,
                    changedBy,
                },
                { responseType: "arraybuffer" }
            );
            await downloadFile(response, "list-of-changes");
            setIsExporting(false);
            addToast("Export downloaded");
        } catch (e) {
            setIsExporting(false);
            addToast(e.response.data.message, { type: "error" });
        }
    }

    const forbidden = !useHasAnyPermissions([
        "report_global_access",
        "report_list_of_changes",
    ]);

    return (
        <>
            <ClientPageHeader client={client}>
                <Heading
                    backTo={`${clientPath}/reports`}
                    backText="All Reports"
                >
                    List of Changes Report
                </Heading>
            </ClientPageHeader>
            <PageContainer forbidden={forbidden}>
                <>
                    <div className="flex justify-between items-end">
                        <div className="flex-0 flex items-end space-x-2">
                            <TextInput
                                label="Date Range"
                                name="start_date"
                                type="date"
                                value={dates.startDate}
                                onChange={updateStartDate}
                            />
                            <TextInput
                                name="end_date"
                                type="date"
                                value={dates.endDate}
                                onChange={updateEndDate}
                            />
                            <div className="flex-shrink-0">
                                <SelectInput
                                    name="changed_by"
                                    value={changedBy}
                                    options={changedByOptions}
                                    onChange={(e) =>
                                        setChangedBy(e.target.value)
                                    }
                                />
                            </div>
                            <Button
                                onClick={() =>
                                    setNeedsUpdated(needsUpdated + 1)
                                }
                                appearance="light"
                                text="Submit"
                            />
                        </div>
                        <div className="flex-0 flex space-x-4">
                            <Button
                                text="Download"
                                appearance="outline"
                                width="w-auto"
                                isLoading={isExporting}
                                onClick={exportRecords}
                            />
                        </div>
                    </div>

                    {loadingChanges ? (
                        <Spinner />
                    ) : (
                        <div className="space-y-6 mt-6">
                            {changes.map((ownership) => (
                                <Panel key={`ownership_${ownership.title}`}>
                                    <PanelHeader title={ownership.title} />
                                    <div className="space-y-6 divide-y divide-gray-100">
                                        {ownership.assets.map((changeGroup) => (
                                            <div
                                                className="pt-3 "
                                                key={`changeGroup_${changeGroup.title}`}
                                            >
                                                <div className="mb-2 font-bold text-gray-700">
                                                    {changeGroup.title}
                                                </div>
                                                <table className="w-full">
                                                    <ChangesTableHeader />
                                                    <tbody>
                                                        {changeGroup.changes.map(
                                                            (change, idx) => (
                                                                <IndividualChange
                                                                    key={`change_${change.id}_${idx}`}
                                                                    change={
                                                                        change
                                                                    }
                                                                    client={
                                                                        client
                                                                    }
                                                                />
                                                            )
                                                        )}
                                                    </tbody>
                                                </table>
                                            </div>
                                        ))}
                                    </div>
                                </Panel>
                            ))}
                        </div>
                    )}
                </>
                {changes.length === 0 && loadingChanges === false && (
                    <div>No changes were found for the given date range.</div>
                )}
            </PageContainer>
        </>
    );
}

export function ChangesTableHeader() {
    return (
        <thead>
            <tr className="font-semibold text-gray-700 text-left">
                <th className="p-1 pb-2">Asset Changed</th>
                <th className="p-1 pb-2">Description</th>
                <th className="p-1 pb-2">Date Changed</th>
                <th className="p-1 pb-2">Changed By</th>
            </tr>
        </thead>
    );
}

export function IndividualChange({ change, client }) {
    return (
        <tr className="text-sm text-gray-600">
            <td className="p-1 py-2 w-60">
                <a
                    href={`/clients/${client.id}/changes/${change.id}/show`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="space-x-1 text-gray-600 group"
                >
                    <span>{change.changed_resource_display_name}</span>
                    {change.account_number_last_four && (
                        <span className="group-hover:text-brand-default">
                            (xxx{change.account_number_last_four})
                        </span>
                    )}
                    <FontAwesomeIcon
                        icon={faExternalLinkAlt}
                        className="pb-1"
                    />
                </a>
            </td>
            <td className="p-1 py-2">{change.change_description}</td>
            <td className="p-1 py-2 w-36">
                {formatDate(change.created_at, "")}
            </td>
            <td className="p-1 py-2 w-36">{change.user?.full_name ?? "N/A"}</td>
        </tr>
    );
}
