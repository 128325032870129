import React from "react";
import { Route, Switch } from "react-router-dom";
import PageContainer from "../../components/PageContainer";
import ClientPageHeader, { Heading } from "../../components/ClientPageHeader";
import ClientFormsTable from "./_partials/ClientFormsTable";

export default function ClientFormsIndex({
    client,
    user,
    clientPath,
    ...props
}) {
    return (
        <>
            <ClientPageHeader client={client}>
                <Heading>Client Forms</Heading>
            </ClientPageHeader>
            <PageContainer>
                <Switch>
                    <Route
                        path={clientPath + "/forms"}
                        render={(newProps) => (
                            <ClientFormsTable
                                client={client}
                                user={user}
                                clientPath={clientPath}
                                {...props}
                                {...newProps}
                            />
                        )}
                    />
                </Switch>
            </PageContainer>
        </>
    );
}
