import React, { useEffect, useState } from "react";
import UpdateDodValues from "../update_values/UpdateDodValues";
import Spinner from "../../components/Spinner";
import Panel from "../../components/Panel";
import useApi from "../../hooks/useApi";

import usePostMortemAssetsForValues from "hooks/usePostMortemAssetsForValues";
import usePostMortemState from "hooks/usePostMortemState";
import useClient from "../../hooks/useClient";
import { useToasts } from "react-toast-notifications";

export default function DodValues() {
    const { addToast } = useToasts();
    const { client } = useClient();
    const { person } = usePostMortemState();

    const { api, loading: errors, unauthorized, errorMessage } = useApi();
    const [assetType, setAssetType] = useState();
    const [assetId, setAssetId] = useState();

    const enabled = !!person;

    const {
        data: assets,
        isLoading: loading,
        remove,
        refetch,
    } = usePostMortemAssetsForValues(
        {
            clientId: client.id,
            person,
        },
        {
            enabled,
            keepPreviousData: true,
        }
    );

    const saveChanges = async (e, data) => {
        setAssetType(data.asset_type);
        setAssetId(data.asset_id);
        e.preventDefault();
        try {
            await api(
                "put",
                "clients/" + client.id + "/post-mortem/values",
                data
            );
            refetch();
            remove();
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        if (unauthorized && errorMessage) {
            addToast(errorMessage, { type: "error" });
        }
    }, [unauthorized, errorMessage]);

    if (loading || !enabled) {
        return <Spinner message="Loading assets" />;
    }

    if (assets.length === 0) {
        return (
            <p>
                There are no assets currently included in the deceased&apos;s
                estate.
            </p>
        );
    }

    return (
        <div className="space-y-6">
            {assets.map((asset, idx) => (
                <Panel key={`parent_${idx}`}>
                    <UpdateDodValues
                        key={idx}
                        asset={asset}
                        onSave={(e, data) => saveChanges(e, data)}
                        errors={
                            assetType === asset.type && assetId === asset.id
                                ? errors
                                : []
                        }
                    />
                </Panel>
            ))}
        </div>
    );
}
