import useFetch from "./useFetch";
import { fetchClientUserPermissions } from "services/PermissionService";

export default function useClientUserPermissions(params, config) {
    return useFetch(
        ["client-user-permissions", params],
        fetchClientUserPermissions,
        config
    );
}
