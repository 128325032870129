import React from "react";
import VerticalTabs from "../../../components/VerticalTabs";

export default function LeasesTabs({ basePath, ...props }) {
    let items = [
        {
            label: "Info",
            to: basePath + "",
        },
        {
            label: "Owners/Lessor",
            to: basePath + "/owners",
        },
        {
            label: "Notes",
            to: basePath + "/notes",
        },
        {
            label: "Related Files",
            to: basePath + "/related-files",
        },
        {
            label: "Access",
            to: basePath + "/access",
        },
    ];
    return <VerticalTabs {...props} items={items} />;
}
