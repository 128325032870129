import React, { useEffect, useState } from "react";
import useFormatCurrency from "../../../hooks/useFormatCurrency";
import TextInput from "../../../components/TextInput";
import Checkbox from "../../../components/Checkbox";
import ManagedByAdvisor from "../../../components/ManagedByAdvisor";
import InsuranceSelect from "./InsuranceSelect";
import ModalModelFactory from "../../../components/ModalModelFactory";
import Button from "../../../components/Button";
import SelectInput from "../../../components/SelectInput";
import NonMortgageLiabilityForm from "../../non_mortgage_liabilities/_partials/NonMortgageLiabilityForm";
import BasicModal, { BasicModalHeader } from "../../../components/ModalBasic";
import useNonMortgageLiabilities from "hooks/useNonMortgageLiabilities";
import useUpdateNonMortgageLiability from "hooks/useUpdateNonMortgageLiability";
import useForm from "hooks/useForm";
import useDeleteNonMortgageLiability from "hooks/useDeleteNonMortgageLiability";
import useClient from "hooks/useClient";

const PersonalPropertyForm = ({
    client,
    input,
    errors,
    readOnly = false,
    updateValue,
    onSubmit,
    user,
    setNeedsUpdate,
    onAddPolicy,
}) => {
    const { clientPath } = useClient();
    const formatCurrency = useFormatCurrency();
    const [showAddPolicyModal, setShowAddPolicyModal] = useState(false);
    const [showAddLienModal, setShowAddLienModal] = useState(false);
    const [showEditLien, setShowEditLienModal] = useState(false);
    const [lienOptions, setLienOptions] = useState([]);
    const [confirmDeleteLien, setConfirmDeleteLien] = useState(false);

    const getAttachedLien = (liens) => {
        setLienInput(
            liens.find((lien) => lien.id === parseInt(input.lien_holder))
        );
    };

    const { data: liens, refetch: refetchLiens } = useNonMortgageLiabilities(
        {
            clientId: client.id,
        },
        {
            initialData: [],
            onSuccess: (data) => {
                setLienOptions([
                    { label: "Select an option...", value: "", disabled: true },
                    ...data.map((o) => {
                        return { label: o.name, value: o.id };
                    }),
                ]);
                getAttachedLien(data);
            },
        }
    );

    useEffect(() => {
        if (liens.length > 0) {
            getAttachedLien(liens);
        }
    }, [input.lien_holder]);

    const {
        input: lienInput,
        updateValue: updateLienValue,
        setInput: setLienInput,
        clearUnsavedChanges: clearUnsavedLienChanges,
    } = useForm([]);

    const { onSubmit: updateLien } = useUpdateNonMortgageLiability(
        {
            clientId: client.id,
            liabilityId: lienInput["id"],
            formData: lienInput,
        },
        {
            onSuccess: () => {
                clearUnsavedLienChanges();
                refetchLiens();
                setShowEditLienModal(false);
            },
        }
    );

    const { onSubmit: deleteLien } = useDeleteNonMortgageLiability(
        {
            clientId: client.id,
            liabilityId: lienInput["id"],
        },
        {
            onSuccess: () => {
                setNeedsUpdate((a) => a + 1);
            },
        }
    );

    return (
        <>
            <form onSubmit={onSubmit}>
                <div>
                    <div className="mb-6">
                        <TextInput
                            type="name"
                            name="name"
                            label="Item Name"
                            id="name"
                            readOnly={readOnly}
                            disabled={readOnly}
                            value={input.name}
                            error={errors.name}
                            onChange={updateValue}
                            required={true}
                            autoFocus={true}
                        />
                    </div>
                    <div className="mb-6">
                        <TextInput
                            name="description"
                            label="Description"
                            id="description"
                            readOnly={readOnly}
                            disabled={readOnly}
                            value={input.description}
                            error={errors.description}
                            onChange={updateValue}
                        />
                    </div>
                    <div className="mb-6">
                        <TextInput
                            name="value"
                            label="Value"
                            id="value"
                            readOnly={readOnly}
                            disabled={readOnly}
                            value={input.value}
                            error={errors.value}
                            onChange={updateValue}
                            onBlur={(e) => {
                                e.target.value = formatCurrency(e.target.value);
                                updateValue(e);
                            }}
                            placeholder="0.00"
                            required={true}
                        />
                    </div>
                    <div className="mb-6">
                        <TextInput
                            type="date"
                            name="as_of_date"
                            label="As of Date"
                            id="as_of_date"
                            readOnly={readOnly}
                            disabled={readOnly}
                            value={input.as_of_date}
                            error={errors.as_of_date}
                            onChange={updateValue}
                            required={true}
                        />
                    </div>
                    <div className="mb-6">
                        <TextInput
                            name="purchase_price"
                            label="Purchase Price"
                            id="purchase_price"
                            readOnly={readOnly}
                            disabled={readOnly}
                            value={input.purchase_price}
                            error={errors.purchase_price}
                            onChange={updateValue}
                            onBlur={(e) => {
                                e.target.value = formatCurrency(e.target.value);
                                updateValue(e);
                            }}
                            placeholder="0.00"
                        />
                    </div>
                    <div className="mb-6">
                        <TextInput
                            type="date"
                            name="purchase_date"
                            label="Purchase Date"
                            id="purchase_date"
                            readOnly={readOnly}
                            disabled={readOnly}
                            value={input.purchase_date}
                            error={errors.purchase_date}
                            onChange={updateValue}
                        />
                    </div>
                    <div className="flex items-end mb-6 space-x-2">
                        <div className="flex-1">
                            <InsuranceSelect
                                client={client}
                                value={input.insurance_policy_id}
                                disabled={readOnly}
                                name="insurance_policy_id"
                                label="Insurance Policy"
                                onChange={updateValue}
                            />
                        </div>
                        <div className="w-40">
                            <Button
                                onClick={() => setShowAddPolicyModal(true)}
                                text="Add New Policy"
                                appearance="light"
                            />
                        </div>
                    </div>

                    <div className="mb-6">
                        <Checkbox
                            name="show_beneficiary"
                            label="Add Transfer On Death Designation?"
                            id="show_beneficiary"
                            readOnly={readOnly}
                            disabled={readOnly}
                            defaultChecked={input.show_beneficiary}
                            value={input.show_beneficiary}
                            error={errors.show_beneficiary}
                            onChange={updateValue}
                        />
                    </div>

                    <fieldset className="mt-12">
                        <legend className="font-bold txt-md text-gray-500 my-6 pb-1 border-b block w-full">
                            Lien
                        </legend>

                        <div>
                            <Checkbox
                                id="has_lien"
                                name="has_lien"
                                readOnly={readOnly}
                                disabled={readOnly}
                                value={input.has_lien}
                                defaultChecked={input.has_lien}
                                onChange={updateValue}
                                label="Has Lien?"
                            />
                        </div>

                        {parseInt(input.has_lien) === 1 && (
                            <div className="flex items-end my-6 space-x-2">
                                <div className="flex-1">
                                    <SelectInput
                                        name="lien_holder"
                                        label="Lien Holder"
                                        id="lien_holder"
                                        options={lienOptions}
                                        disabled={readOnly}
                                        value={input.lien_holder}
                                        error={errors.lien_holder}
                                        onChange={updateValue}
                                    />
                                </div>
                                <div className="w-40">
                                    <Button
                                        onClick={() =>
                                            setShowAddLienModal(true)
                                        }
                                        text="Add New Lien"
                                        appearance="light"
                                    />
                                </div>
                            </div>
                        )}
                        {parseInt(input.has_lien) === 1 && (
                            <>
                                <div className="mb-6">
                                    <TextInput
                                        name="loan_balance"
                                        label="Loan Balance"
                                        id="loan_balance"
                                        readOnly={readOnly}
                                        disabled={
                                            readOnly || input.lien_holder !== 0
                                        }
                                        value={
                                            input.lien_holder === 0
                                                ? input.loan_balance
                                                : lienInput["balance"] || 0
                                        }
                                        error={errors.loan_balance}
                                        onChange={updateValue}
                                        onBlur={(e) => {
                                            e.target.value = formatCurrency(
                                                e.target.value
                                            );
                                            updateValue(e);
                                        }}
                                        placeholder="0.00"
                                    />
                                </div>
                                <div className="w-36">
                                    <Button
                                        appearance="light"
                                        onClick={() =>
                                            setShowEditLienModal(true)
                                        }
                                        disabled={readOnly}
                                        text="View/Edit Lien"
                                    />
                                </div>
                            </>
                        )}
                    </fieldset>

                    <fieldset className="mt-12">
                        <legend className="font-bold txt-md text-gray-500 my-6 pb-1 border-b block w-full">
                            Managed By
                        </legend>
                        <ManagedByAdvisor
                            client={client}
                            input={input}
                            errors={errors}
                            disabled={readOnly}
                            updateValue={updateValue}
                        />
                    </fieldset>
                    <input type="submit" hidden />
                </div>
            </form>
            <ModalModelFactory
                isOpen={showAddPolicyModal}
                client={client}
                clientPath={clientPath}
                user={user}
                title="Add Insurance Policy"
                onClose={() => setShowAddPolicyModal(false)}
                onSuccess={(data) => {
                    setShowAddPolicyModal(false);
                    onAddPolicy(data);
                }}
                modelType="OtherInsurance"
                parentResource={{
                    id: input.id,
                    type: "PersonalProperty",
                }}
            />
            <ModalModelFactory
                isOpen={showAddLienModal}
                client={client}
                clientPath={clientPath}
                user={user}
                title="Add Non Mortgage Liability"
                onClose={() => setShowAddLienModal(false)}
                onSuccess={({ data }) => {
                    setShowAddLienModal(false);
                    refetchLiens();
                    const e = {
                        target: {
                            value: data.id,
                            name: "lien_holder",
                        },
                    };
                    updateValue(e);
                }}
                modelType="NonMortgageLiability"
                parentResource={{
                    id: input.id,
                    type: "PersonalProperty",
                }}
            />
            <BasicModal
                isOpen={showEditLien}
                size="3xl"
                onClose={() => setShowEditLienModal(false)}
                header={
                    <BasicModalHeader
                        onClose={() => setShowEditLienModal(false)}
                        onSave={updateLien}
                        title="View/Edit Lien"
                    />
                }
            >
                <div className="space-y-12">
                    <NonMortgageLiabilityForm
                        client={client}
                        input={lienInput}
                        readOnly={false}
                        updateValue={updateLienValue}
                        onSubmit={updateLien}
                    />
                    <div>
                        {confirmDeleteLien === true && (
                            <>
                                <div className="flex space-x-2">
                                    <div className="w-48">
                                        <Button
                                            onClick={() =>
                                                setConfirmDeleteLien(false)
                                            }
                                            type="button"
                                            text="Cancel"
                                            appearance="light"
                                        ></Button>
                                    </div>
                                    <div className="w-48">
                                        <Button
                                            onClick={deleteLien}
                                            type="button"
                                            text="Confirm Delete"
                                            appearance="danger"
                                        ></Button>
                                    </div>
                                </div>
                                <p className="mt-2 text-gray-600">
                                    Are you sure you want to delete this lien?
                                </p>
                            </>
                        )}
                        {confirmDeleteLien === false && (
                            <div className="w-48">
                                <Button
                                    onClick={() => setConfirmDeleteLien(true)}
                                    type="button"
                                    text="Delete Lien"
                                    appearance="danger"
                                ></Button>
                            </div>
                        )}
                    </div>
                </div>
            </BasicModal>
        </>
    );
};

export default PersonalPropertyForm;
