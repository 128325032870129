import React from "react";
import PropTypes from "prop-types";
import InputErrors from "./InputErrors";
import { InputLabel } from "./TextInput";

const TextArea = ({
    type,
    id,
    name,
    label,
    onChange,
    onBlur,
    onFocus,
    placeholder,
    value,
    error,
    className = "",
    tabindex = 0,
    autoFocus = false,
    readOnly = false,
    disabled = false,
    required = false,
    rows = 10,
}) => {
    return (
        <div>
            {label && !error && <InputLabel text={label} htmlFor={name} required={required} />}
            <div className="field">
                <InputErrors errors={error} />
                <textarea
                    type={type ?? "text"}
                    name={name}
                    className={`bg-white focus:outline-none rounded border focus:border-gray-800 border-gray-200 px-3 py-3 block w-full appearance-none leading-normal ${
                        disabled || readOnly
                            ? "opacity-75 bg-gray-100 cursor-not-allowed"
                            : ""
                    } ${className}`}
                    placeholder={placeholder}
                    value={value}
                    id={name || id}
                    onChange={onChange}
                    onBlur={onBlur}
                    onFocus={onFocus}
                    tabIndex={tabindex}
                    autoFocus={autoFocus}
                    readOnly={readOnly}
                    disabled={disabled}
                    required={required}
                    rows={rows ? rows : 10}
                ></textarea>
            </div>
        </div>
    );
};

TextArea.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    placehoder: PropTypes.string,
    value: PropTypes.string,
    error: PropTypes.array,
};

export default TextArea;
