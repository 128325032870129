import React from "react";
import TextInput from "../../components/TextInput";
import Button from "../../components/Button";
import CountrySelector from "../../components/CountrySelector";
import Stepper from "./partials/Stepper";
import { StateSelect } from "../../components/SelectInput";
import useRegistrationContext from "../../hooks/useRegistrationContext";
import { Redirect } from "react-router-dom";

export default function OrganizationStep2() {
    const {
        errors,
        loading,
        organizationInput,
        emailOrPasswordMissing,
        updateOrganizationInput,
        submitOrganizationForm,
    } = useRegistrationContext();

    if (emailOrPasswordMissing()) {
        return <Redirect to={"/register/email-password"} />;
    }

    return (
        <div className="max-w-3xl mx-auto p-10">
            <Stepper step={3} />

            <h1 className="text-2xl mb-8  text-center">
                Tell us about your organization.
            </h1>

            <form onSubmit={submitOrganizationForm} className="space-y-6">
                <div>
                    <TextInput
                        name="name"
                        label="Organization Name"
                        id="name"
                        value={organizationInput.name}
                        onChange={updateOrganizationInput}
                        error={errors.name}
                        autoFocus={true}
                    />
                </div>

                <div>
                    <div className="flex items-end">
                        <div className="flex-1">
                            <TextInput
                                name="subdomain"
                                label="Your Organization's Inheralink URL"
                                id="subdomain"
                                value={organizationInput.subdomain}
                                onChange={updateOrganizationInput}
                                error={errors.subdomain}
                                className="rounded-r-none"
                                disabled={loading}
                            />
                        </div>
                        <div className="flex-none">
                            <div className="flex items-center bg-gray-100 text-gray-600 px-3 border border-gray-200 rounded-md h-12 border-l-0 rounded-l-none">
                                {`.${
                                    process.env.REACT_APP_URL ||
                                    "inheralink.com"
                                }`}
                            </div>
                        </div>
                    </div>
                </div>

                <fieldset className="space-y-6">
                    <div>
                        <TextInput
                            name="address"
                            label="Organization Address"
                            id="address"
                            value={organizationInput.address}
                            onChange={updateOrganizationInput}
                            error={errors.address}
                            disabled={loading}
                        />
                    </div>

                    <div className="flex space-x-3 items-end">
                        <div className="flex-1">
                            <TextInput
                                name="city"
                                label="City"
                                value={organizationInput.city}
                                id="city"
                                onChange={updateOrganizationInput}
                                error={errors.city}
                                disabled={loading}
                            />
                        </div>
                        <div className="flex-1">
                            <StateSelect
                                defaultOption=" - Select -"
                                name="state"
                                label="State"
                                value={organizationInput.state}
                                id="state"
                                onChange={updateOrganizationInput}
                                error={errors.state}
                                disabled={loading}
                            />
                        </div>
                        <div className="flex-1">
                            <TextInput
                                name="postal_code"
                                label="Zip"
                                id="postal_code"
                                value={organizationInput.postal_code}
                                onChange={updateOrganizationInput}
                                error={errors.postal_code}
                                disabled={loading}
                            />
                        </div>
                    </div>

                    <div>
                        <CountrySelector
                            label="Country"
                            value={organizationInput.country}
                            name="country"
                            error={errors.country}
                            onChange={updateOrganizationInput}
                            disabled={loading}
                        />
                    </div>
                </fieldset>

                <fieldset className="space-y-6">
                    <div>
                        <TextInput
                            name="phone_number"
                            type="tel"
                            label="Organization Phone"
                            id="phone_number"
                            value={organizationInput.phone_number}
                            onChange={updateOrganizationInput}
                            error={errors.phone_number}
                            disabled={loading}
                        />
                    </div>

                    <div>
                        <TextInput
                            name="website"
                            label="Organization Website"
                            id="website"
                            value={organizationInput.website}
                            placeholder="https://"
                            onChange={updateOrganizationInput}
                            error={errors.website}
                            disabled={loading}
                        />
                    </div>
                </fieldset>

                <div className="flex mt-8 justify-center">
                    {loading === false && (
                        <Button
                            text="Next"
                            type="submit"
                            className="w-64 bg-gray-800 hover:bg-gray-700 text-white border border-gray-800"
                            isLoading={loading}
                        />
                    )}
                    {loading === true && (
                        <Button
                            text="Please wait, setting up your account now..."
                            type="submit"
                            className="w-64 bg-gray-800 hover:bg-gray-700 text-white border border-gray-800 animate-pulse"
                            isLoading={false}
                        />
                    )}
                </div>
            </form>
        </div>
    );
}
