import React from "react";
import { Route, Switch } from "react-router-dom";
import { combineProviders } from "util/CombineProviders";
import PostMortemContextProvider from "./PostMortemContext";
import ResourceContextProvider from "../../contexts/ResourceContext";
import PostMortemIndex from "./PostMortemIndex";

const PostMortemProviders = combineProviders([
    PostMortemContextProvider,
    [
        ResourceContextProvider,
        {
            resourceTypes: [
                "Client",
                "Relationship",
                "Trust",
                "BusinessInterest",
            ],
        },
    ],
]);

export default function PostMortem(parentProps) {
    return (
        <PostMortemProviders>
            <Switch>
                <Route
                    exact
                    path={[
                        "/clients/:clientId/post-mortem",
                        "/client/post-mortem",
                    ]}
                    render={(props) => (
                        <PostMortemIndex
                            currentTab={"settlement"}
                            {...parentProps}
                            {...props}
                        />
                    )}
                />
                <Route
                    exact
                    path={[
                        "/clients/:clientId/post-mortem/include",
                        "/client/post-mortem/include",
                    ]}
                    render={(props) => (
                        <PostMortemIndex
                            currentTab={"include"}
                            {...parentProps}
                            {...props}
                        />
                    )}
                />
                <Route
                    exact
                    path={[
                        "/clients/:clientId/post-mortem/values",
                        "/client/post-mortem/values",
                    ]}
                    render={(props) => (
                        <PostMortemIndex
                            currentTab={"values"}
                            {...parentProps}
                            {...props}
                        />
                    )}
                />
                <Route
                    exact
                    path={[
                        "/clients/:clientId/post-mortem/wealth",
                        "/client/post-mortem/wealth",
                    ]}
                    render={(props) => (
                        <PostMortemIndex
                            currentTab={"wealth"}
                            {...parentProps}
                            {...props}
                        />
                    )}
                />
                <Route
                    exact
                    path={[
                        "/clients/:clientId/post-mortem/owners",
                        "/client/post-mortem/owners",
                    ]}
                    render={(props) => (
                        <PostMortemIndex
                            currentTab={"owners"}
                            {...parentProps}
                            {...props}
                        />
                    )}
                />
                <Route
                    exact
                    path={[
                        "/clients/:clientId/post-mortem/beneficiaries",
                        "/client/post-mortem/beneficiaries",
                    ]}
                    render={(props) => (
                        <PostMortemIndex
                            currentTab={"beneficiaries"}
                            {...parentProps}
                            {...props}
                        />
                    )}
                />
                <Route
                    exact
                    path={[
                        "/clients/:clientId/post-mortem/history",
                        "/client/post-mortem/history",
                    ]}
                    render={(props) => (
                        <PostMortemIndex
                            currentTab={"history"}
                            {...parentProps}
                            {...props}
                        />
                    )}
                />
                <Route
                    exact
                    path={[
                        "/clients/:clientId/post-mortem/notes",
                        "/client/post-mortem/notes",
                    ]}
                    render={(props) => (
                        <PostMortemIndex
                            currentTab={"notes"}
                            {...parentProps}
                            {...props}
                        />
                    )}
                />
            </Switch>
        </PostMortemProviders>
    );
}
