import React, { useState, useEffect, useReducer } from "react";
import Api from "../../services/Api";
import { Redirect } from "react-router-dom";
import SaveButton from "../../components/SaveButton";
import { useToasts } from "react-toast-notifications";
import PageContainer from "../../components/PageContainer";
import TrustsForm from "./_partials/TrustsForm";
import ClientPageHeader, { Heading } from "../../components/ClientPageHeader";
import { formatDateForInput } from "../../util/TextUtil";

export default function AddTrust({ client, clientPath }) {
    const { addToast } = useToasts();
    const [errors, setErrors] = useState([]);
    const [redirectToIndex, setRedirectToIndex] = useState(false);
    const [input, setInput] = useReducer(
        (state, newState) => ({ ...state, ...newState }),
        {
            owner: "Client:" + client.id,
            owner_type: "Client",
            type_id: "",
            as_of_date: formatDateForInput(new Date()),
            assets_owned: [],
            is_included_in_estate: 1,
            state_law: "None",
            state_situs: "None",
        }
    );

    useEffect(() => {
        document.title = "Add Trust";
    }, []);

    function updateValue(event) {
        setInput({ [event.target.name]: event.target.value });
    }

    async function store(e) {
        e.preventDefault();
        try {
            const body = { ...input };
            let response = await Api.post(
                "clients/" + client.id + "/trusts",
                body
            );
            addToast(response.data.message);
            setErrors([]);
            setRedirectToIndex(true);
        } catch (e) {
            setErrors([]);
            if (e.response.data.errors) {
                setErrors(e.response.data.errors);
            } else {
                addToast(e.response.data.message, { type: "error" });
            }
        }
    }

    if (redirectToIndex) {
        return <Redirect to={`${clientPath}/trusts`} />;
    }

    return (
        <>
            <ClientPageHeader client={client}>
                <Heading backTo={`${clientPath}/trusts`} backText="All Trusts">
                    Add Trust
                </Heading>
                <div className="flex-0">
                    <SaveButton onClick={store} />
                </div>
            </ClientPageHeader>
            <PageContainer>
                <div className="max-w-3xl mx-auto">
                    <TrustsForm
                        client={client}
                        input={input}
                        errors={errors}
                        updateValue={updateValue}
                        onSubmit={store}
                    />
                </div>
            </PageContainer>
        </>
    );
}
