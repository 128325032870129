import React from "react";
import { Route, Switch } from "react-router-dom";
import AddAppointment from "./AddAppointment";
import AppointmentIndex from "./AppointmentIndex";
import EditAppointment from "./EditAppointment";

export default function Appointments(parentProps) {
    return (
        <Switch>
            <Route
                exact
                path={[
                    "/clients/:clientId/appointments",
                    "/client/appointments",
                ]}
                render={(props) => (
                    <AppointmentIndex {...parentProps} {...props} />
                )}
            />
            <Route
                path={[
                    "/clients/:clientId/appointments/:appointmentId/view",
                    "/client/appointments/:appointmentId/view",
                ]}
                render={(props) => (
                    <EditAppointment {...parentProps} {...props} />
                )}
            />
            <Route
                path={["/clients/:clientId/appointments/add"]}
                render={(props) => (
                    <AddAppointment {...parentProps} {...props} />
                )}
            />
        </Switch>
    );
}
