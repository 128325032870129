import React from "react";
import TextInput from "../../../components/TextInput";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import Fieldset from "../../../components/Fieldset";
import PaymentDetails from "../_partials/PaymentDetails";

export default function Subscriptions({ subscriptions, setSubscriptions }) {
    const subscription = {
        name: "",
        contact: "",
        address: "",
        phone: "",
        email: "",
        website: "",
        payment_frequency: "Monthly",
    };

    function appendSubscription() {
        if (subscriptions.length < 10) {
            setSubscriptions((subscriptions) => [
                ...subscriptions,
                subscription,
            ]);
        }
    }

    function removeSubscription(idx) {
        setSubscriptions(
            subscriptions.filter((subscription, idxs) => idxs !== idx)
        );
    }

    const updateSubscription = (event, idx) => {
        if (
            event.target.name === "payment_frequency" &&
            event.target.value !== "Other"
        ) {
            subscriptions[idx].payment_frequency_other = "";
            setSubscriptions(subscriptions);
        }

        setSubscriptions(
            subscriptions.map((subscription, sidx) => {
                return idx !== sidx
                    ? subscription
                    : {
                          ...subscription,
                          [event.target.name]: event.target.value,
                      };
            })
        );
    };

    return (
        <Fieldset
            legend="Subscriptions"
            description="Magazines, Meal Delivery, Flower of the month, Etc."
        >
            <div className="mb-10 border-b border-gray-100 pb-6">
                {subscriptions.map((subscription, idx) => (
                    <div className="mb-10 border-b border-gray-100" key={idx}>
                        <div className="flex space-x-3 mb-6">
                            <div className="flex-1">
                                <TextInput
                                    label="Subscription"
                                    name="name"
                                    value={subscriptions[idx]["name"]}
                                    onChange={(e) => updateSubscription(e, idx)}
                                    autoFocus={subscriptions.length === idx + 1}
                                />
                            </div>
                            <div className="flex-1">
                                <TextInput
                                    label="Contact"
                                    name="contact"
                                    value={subscriptions[idx]["contact"]}
                                    onChange={(e) => updateSubscription(e, idx)}
                                />
                            </div>
                        </div>
                        <div className="mb-6">
                            <TextInput
                                label="Address"
                                name="address"
                                value={subscriptions[idx]["address"]}
                                onChange={(e) => updateSubscription(e, idx)}
                            />
                        </div>
                        <div className="flex space-x-3 mb-6">
                            <div className="flex-1">
                                <TextInput
                                    type="tel"
                                    label="Phone"
                                    name="phone"
                                    value={subscriptions[idx]["phone"]}
                                    onChange={(e) => updateSubscription(e, idx)}
                                />
                            </div>
                            <div className="flex-1">
                                <TextInput
                                    type="email"
                                    label="Email"
                                    name="email"
                                    value={subscriptions[idx]["email"]}
                                    onChange={(e) => updateSubscription(e, idx)}
                                />
                            </div>
                        </div>
                        <div className="mb-6">
                            <TextInput
                                label="Website"
                                name="website"
                                value={subscriptions[idx]["website"]}
                                onChange={(e) => updateSubscription(e, idx)}
                            />
                        </div>
                        <div className="flex items-start space-x-3 mb-6">
                            <PaymentDetails
                                data={subscriptions}
                                idx={idx}
                                onchange={updateSubscription}
                            />
                        </div>
                        <div className="flex justify-end mb-3">
                            <button
                                type="button"
                                onClick={() => removeSubscription(idx)}
                                className="text-gray-500 text-sm hover:text-red-500"
                            >
                                <FontAwesomeIcon
                                    icon={faTimesCircle}
                                    className="mr-1"
                                />{" "}
                                Remove Subscription
                            </button>
                        </div>
                    </div>
                ))}
                <div className="flex">
                    <button
                        type="button"
                        onClick={(e) => appendSubscription(e)}
                        className="text-gray-500 text-sm hover:text-brand"
                    >
                        <FontAwesomeIcon icon={faPlusCircle} className="mr-1" />{" "}
                        Add Subscription
                    </button>
                </div>
            </div>
        </Fieldset>
    );
}
