import React from "react";
import VaultResourceFileList from "./VaultResourceFileList";

export default function VaultPhotosList({ ...props }) {
    return (
        <VaultResourceFileList
            title="Related Photos"
            fileType="Photo"
            viewType="grid"
            mimeTypeFilter="image"
            {...props}
        />
    );
}
