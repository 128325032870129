import useFetch from "./useFetch";
import { fetchPostMortemAssetsForInclusion } from "services/PostMortemService";

export default function usePostMortemAssetsForInclusion(
    params,
    config = { keepPreviousData: false }
) {
    return useFetch(
        ["fetchPostMortemAssetsForInclusion", params],
        fetchPostMortemAssetsForInclusion,
        config
    );
}
