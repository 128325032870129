import useFetch from "./useFetch";
import { fetchNonMortgageLiabilities } from "services/NonMortgageLiabilityService";

export default function useNonMortgageLiabilities(params, config) {
    return useFetch(
        ["fetchNonMortgageLiabilities", params],
        fetchNonMortgageLiabilities,
        config
    );
}
