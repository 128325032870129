import React, { useState, useEffect } from "react";
import Api from "../../../services/Api";
import { useToasts } from "react-toast-notifications";
import ClientPageHeader, {
    Heading,
} from "../../../components/ClientPageHeader";
import Button from "../../../components/Button";
import SaveButton from "../../../components/SaveButton";
import PageContainer from "../../../components/PageContainer";
import Locations from "./_locations";
import Documents from "./_documents";
import Spinner from "../../../components/Spinner";
import Access from "./_access";
import MedicalHistory from "./_medical_history";
import SideNav, { SideNavLink } from "../../../components/SideNav";
import CompanyContacts from "./_company_contacts";
import Schools from "./_schools";
import MortgageCompanies from "./_mortgage";
import Memberships from "./_memberships";
import Subscriptions from "./_subscriptions";
import VolunteerLocations from "./_volunteer_locations";
import BoardMemberships from "./_board_memberships";
import RewardMemberships from "./_reward_memberships";
import PetCareInstructions from "./_pet_care_instructions";
import { useRef } from "react";

export default function LocationListForm({ client, ...props }) {
    const [errors, setErrors] = useState({ data: [] });
    const { addToast } = useToasts();
    const [loading, setLoading] = useState(true);

    const [locations, setLocations] = useState([]);
    const [documents, setDocuments] = useState([]);
    const [accessItems, setAccessItems] = useState([]);
    const [medicalHistory, setMedicalHistory] = useState([]);
    const [relationships, setRelationships] = useState([]);
    const [advisors, setAdvisors] = useState([]);
    const [companyContacts, setCompanyContacts] = useState([]);
    const [mortgageCompanies, setMortgageCompanies] = useState([]);
    const [schools, setSchools] = useState([]);
    const [memberships, setMemberships] = useState([]);
    const [subscriptions, setSubscriptions] = useState([]);
    const [volunteerLocations, setVolunteerLocations] = useState([]);
    const [boardMemberships, setBoardMemberships] = useState([]);
    const [rewardMemberships, setRewardMemberships] = useState([]);
    const [petCareInstructions, setPetCareInstructions] = useState([]);
    const [saving, setSaving] = useState(false);
    const [isForbidden, setIsForbidden] = useState(false);
    const formWindow = useRef();

    useEffect(() => {
        document.title = "Location List";
    }, []);

    useEffect(() => {
        const workArea = document.getElementById("workarea");
        if (workArea) {
            workArea.scrollTo(0, 0);
        }
        if (formWindow.current) {
            formWindow.current.scrollTo(0, 0);
        }
    }, [props.location.hash]);

    useEffect(() => {
        async function fetchForm() {
            try {
                setLoading(true);
                let response = await Api.get(
                    "clients/" + client.id + "/location-list-form"
                );
                if (response.data.data) {
                    setLocations(response.data.data.locations || []);
                    setDocuments(response.data.data.documents || []);
                    setAccessItems(response.data.data.accessItems || []);
                    setMedicalHistory(response.data.data.medicalHistory || []);
                    setRelationships(response.data.data.relationships || []);
                    setAdvisors(response.data.data.advisors || []);
                    setCompanyContacts(
                        response.data.data.companyContacts || []
                    );
                    setMortgageCompanies(
                        response.data.data.mortgageCompanies || []
                    );
                    setSchools(response.data.data.schools || []);
                    setMemberships(response.data.data.memberships || []);
                    setSubscriptions(response.data.data.subscriptions || []);
                    setVolunteerLocations(
                        response.data.data.volunteerLocations || []
                    );
                    setBoardMemberships(
                        response.data.data.boardMemberships || []
                    );
                    setRewardMemberships(
                        response.data.data.rewardMemberships || []
                    );
                    setPetCareInstructions(
                        response.data.data.petCareInstructions || []
                    );
                }
                setLoading(false);
            } catch (e) {
                console.log(e);
                if (e.response && e.response.status === 401) {
                    alert(e.response.data.message);
                }
                if (e.response && e.response.status === 403) {
                    setIsForbidden(true);
                }
            }
        }
        fetchForm();
    }, [client.id]);

    async function update(e) {
        e.preventDefault();
        setSaving(true);
        try {
            const body = {
                locations: locations.filter((location) => location.length),
                documents,
                accessItems,
                medicalHistory,
                relationships,
                advisors,
                companyContacts,
                mortgageCompanies,
                schools,
                memberships,
                subscriptions,
                volunteerLocations,
                boardMemberships,
                rewardMemberships,
                petCareInstructions,
            };
            let response = await Api.put(
                "clients/" + client.id + "/location-list-form",
                { data: body }
            );
            addToast(response.data.message);
            setErrors([]);
            setSaving(false);
        } catch (e) {
            setErrors([]);
            setSaving(false);
            if (e.response.data.errors) {
                setErrors(e.response.data.errors);
                if (e.response.data.errors["data.locations"]) {
                    props.history.push("#locations");
                }
                addToast(e.response.data.message, { type: "error" });
            } else {
                addToast(e.response.data.message, { type: "error" });
            }
        }
    }

    return (
        <>
            <ClientPageHeader client={client}>
                <Heading
                    backTo={`${props.clientPath}/forms`}
                    backText="All Forms"
                >
                    Location List
                </Heading>
                {isForbidden === false && (
                    <div className="flex-0 flex space-x-2 w-64">
                        <Button
                            onClick={() => window.print()}
                            text="Download"
                            appearance="light"
                        />
                        <SaveButton onClick={update} isLoading={saving} />
                    </div>
                )}
            </ClientPageHeader>
            <PageContainer forbidden={isForbidden}>
                <div className="flex space-x-6">
                    <div className="print:hidden flex-0">
                        <SideNav>
                            <SideNavLink
                                to={{ hash: "locations" }}
                                active={
                                    props.location.hash === "#locations" ||
                                    !props.location.hash
                                }
                            >
                                My Locations
                            </SideNavLink>
                            <SideNavLink
                                to={{ hash: "documents" }}
                                active={props.location.hash === "#documents"}
                            >
                                Document Locations
                            </SideNavLink>
                            <SideNavLink
                                to={{ hash: "access" }}
                                active={props.location.hash === "#access"}
                            >
                                Access To Home &amp; Documents
                            </SideNavLink>
                            <SideNavLink
                                to={{ hash: "medical" }}
                                active={props.location.hash === "#medical"}
                            >
                                Medical History
                            </SideNavLink>
                            <SideNavLink
                                to={{ hash: "company-contacts" }}
                                active={
                                    props.location.hash === "#company-contacts"
                                }
                            >
                                Company Contacts
                            </SideNavLink>
                            <SideNavLink
                                to={{ hash: "mortgage" }}
                                active={props.location.hash === "#mortgage"}
                            >
                                Mortgage / Apartment Leasing Office
                            </SideNavLink>
                            <SideNavLink
                                to={{ hash: "schools" }}
                                active={props.location.hash === "#schools"}
                            >
                                Children’s Schools
                            </SideNavLink>
                            <SideNavLink
                                to={{ hash: "memberships" }}
                                active={props.location.hash === "#memberships"}
                            >
                                Memberships
                            </SideNavLink>
                            <SideNavLink
                                to={{ hash: "subscriptions" }}
                                active={
                                    props.location.hash === "#subscriptions"
                                }
                            >
                                Subscriptions
                            </SideNavLink>
                            <SideNavLink
                                to={{ hash: "volunteer-locations" }}
                                active={
                                    props.location.hash ===
                                    "#volunteer-locations"
                                }
                            >
                                Volunteer Locations
                            </SideNavLink>
                            <SideNavLink
                                to={{ hash: "board-memberships" }}
                                active={
                                    props.location.hash === "#board-memberships"
                                }
                            >
                                Board Memberships
                            </SideNavLink>
                            <SideNavLink
                                to={{ hash: "reward-memberships" }}
                                active={
                                    props.location.hash ===
                                    "#reward-memberships"
                                }
                            >
                                Reward Memberships
                            </SideNavLink>
                            <SideNavLink
                                to={{ hash: "pet-care-instructions" }}
                                active={
                                    props.location.hash ===
                                    "#pet-care-instructions"
                                }
                            >
                                Pet Care Instructions
                            </SideNavLink>
                        </SideNav>
                    </div>
                    <div
                        ref={formWindow}
                        className="flex-1 max-h-screen overflow-auto print:max-h-auto print:overflow-visible"
                    >
                        {loading ? (
                            <Spinner />
                        ) : (
                            <form
                                onSubmit={update}
                                className="max-w-3xl mx-auto printableForm"
                            >
                                <div
                                    style={{
                                        pageBreakAfter: "always",
                                        display:
                                            props.location.hash ===
                                                "#locations" ||
                                            !props.location.hash
                                                ? "block"
                                                : "none",
                                    }}
                                >
                                    <Locations
                                        locations={locations}
                                        errors={errors}
                                        setLocations={setLocations}
                                    />
                                </div>
                                <div
                                    style={{
                                        pageBreakAfter: "always",
                                        display:
                                            props.location.hash === "#documents"
                                                ? "block"
                                                : "none",
                                    }}
                                >
                                    <Documents
                                        locations={locations}
                                        documents={documents}
                                        errors={errors}
                                        setDocuments={setDocuments}
                                    />
                                </div>
                                <div
                                    style={{
                                        pageBreakAfter: "always",
                                        display:
                                            props.location.hash === "#access"
                                                ? "block"
                                                : "none",
                                    }}
                                >
                                    <Access
                                        locations={locations}
                                        items={accessItems}
                                        errors={errors}
                                        setAccessItems={setAccessItems}
                                    />
                                </div>
                                <div
                                    style={{
                                        pageBreakAfter: "always",
                                        display:
                                            props.location.hash === "#medical"
                                                ? "block"
                                                : "none",
                                    }}
                                >
                                    <MedicalHistory
                                        items={medicalHistory}
                                        errors={errors}
                                        setMedicalHistory={setMedicalHistory}
                                        client={client}
                                    />
                                </div>
                                <div
                                    style={{
                                        pageBreakAfter: "always",
                                        display:
                                            props.location.hash ===
                                            "#company-contacts"
                                                ? "block"
                                                : "none",
                                    }}
                                >
                                    <CompanyContacts
                                        companyContacts={companyContacts}
                                        errors={errors}
                                        setCompanyContacts={setCompanyContacts}
                                    />
                                </div>
                                <div
                                    style={{
                                        pageBreakAfter: "always",
                                        display:
                                            props.location.hash === "#mortgage"
                                                ? "block"
                                                : "none",
                                    }}
                                >
                                    <MortgageCompanies
                                        mortgageCompanies={mortgageCompanies}
                                        errors={errors}
                                        setMortgageCompanies={
                                            setMortgageCompanies
                                        }
                                    />
                                </div>
                                <div
                                    style={{
                                        pageBreakAfter: "always",
                                        display:
                                            props.location.hash === "#schools"
                                                ? "block"
                                                : "none",
                                    }}
                                >
                                    <Schools
                                        schools={schools}
                                        errors={errors}
                                        setSchools={setSchools}
                                    />
                                </div>
                                <div
                                    style={{
                                        pageBreakAfter: "always",
                                        display:
                                            props.location.hash ===
                                            "#memberships"
                                                ? "block"
                                                : "none",
                                    }}
                                >
                                    <Memberships
                                        memberships={memberships}
                                        errors={errors}
                                        setMemberships={setMemberships}
                                    />
                                </div>
                                <div
                                    style={{
                                        pageBreakAfter: "always",
                                        display:
                                            props.location.hash ===
                                            "#subscriptions"
                                                ? "block"
                                                : "none",
                                    }}
                                >
                                    <Subscriptions
                                        subscriptions={subscriptions}
                                        errors={errors}
                                        setSubscriptions={setSubscriptions}
                                    />
                                </div>
                                <div
                                    style={{
                                        pageBreakAfter: "always",
                                        display:
                                            props.location.hash ===
                                            "#volunteer-locations"
                                                ? "block"
                                                : "none",
                                    }}
                                >
                                    <VolunteerLocations
                                        volunteerLocations={volunteerLocations}
                                        errors={errors}
                                        setVolunteerLocations={
                                            setVolunteerLocations
                                        }
                                    />
                                </div>
                                <div
                                    style={{
                                        pageBreakAfter: "always",
                                        display:
                                            props.location.hash ===
                                            "#board-memberships"
                                                ? "block"
                                                : "none",
                                    }}
                                >
                                    <BoardMemberships
                                        boardMemberships={boardMemberships}
                                        errors={errors}
                                        setBoardMemberships={
                                            setBoardMemberships
                                        }
                                    />
                                </div>
                                <div
                                    style={{
                                        pageBreakAfter: "always",
                                        display:
                                            props.location.hash ===
                                            "#reward-memberships"
                                                ? "block"
                                                : "none",
                                    }}
                                >
                                    <RewardMemberships
                                        rewardMemberships={rewardMemberships}
                                        errors={errors}
                                        setRewardMemberships={
                                            setRewardMemberships
                                        }
                                    />
                                </div>
                                <div
                                    style={{
                                        display:
                                            props.location.hash ===
                                            "#pet-care-instructions"
                                                ? "block"
                                                : "none",
                                    }}
                                >
                                    <PetCareInstructions
                                        petCareInstructions={
                                            petCareInstructions
                                        }
                                        errors={errors}
                                        setPetCareInstructions={
                                            setPetCareInstructions
                                        }
                                    />
                                </div>
                            </form>
                        )}
                    </div>
                </div>
            </PageContainer>
        </>
    );
}
