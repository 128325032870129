import React from "react";

import ClientRoutes from "./_partials/ClientRoutes";
import ClientContextProvider from "../../contexts/ClientContext";

const Clients = (props) => {
    return (
        <>
            <ClientContextProvider {...props}>
                <ClientRoutes {...props} />
            </ClientContextProvider>
        </>
    );
};

export default Clients;
