import React, { useState, useEffect, useContext } from "react";
import Api from "../../../services/Api";
import Dropdown, { DropdownItem } from "../../../components/Dropdown";
import { Link } from "react-router-dom";
import Spinner from "../../../components/Spinner";
import TableSortHeader from "../../../components/TableSortHeader";
import SearchInput from "../../../components/SearchInput";
import { AuthContext } from "../../../contexts/AuthContext";
import useDebounce from "../../../hooks/useDebounce";
import { formatDate } from "../../../util/TextUtil";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faTrash,
    faEdit,
    faEllipsisH,
} from "@fortawesome/free-solid-svg-icons";
import { useToasts } from "react-toast-notifications";
import Pagination from "../../../components/Pagination";
import usePagination from "../../../hooks/usePagination";
import Forbidden from "../../../components/Forbidden";
import Money from "components/Money";
import { useUserCan } from "hooks/useHasAnyPermissions";

export default function DisabilityPoliciesTable({
    client,
    clientPath,
    showDisabled,
    updateRecordCount,
}) {
    const auth = useContext(AuthContext);
    const userType = auth.user.type;
    const { addToast } = useToasts();
    const [loading, setLoading] = useState(true);
    const [disabilityPolicies, setDisabilityPolicies] = useState([]);
    const [orderBy, setOrderBy] = useState("created_at");
    const [sortDir, setSortDir] = useState("desc");
    const [search, setSearch] = useState("");
    const debouncedSearch = useDebounce(search, 250);
    const [notAuthorized, setNotAuthorized] = useState(false);
    const [needsUpdated, setNeedsUpdated] = useState(0);
    const canCreateWealthRecords = useUserCan("createWealthRecord");
    const [page, setPage, meta, setMeta] = usePagination();

    useEffect(() => {
        document.title = "Disability Policies";
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            try {
                let response = await Api.get(
                    "clients/" + client.id + "/disability-policies",
                    {
                        limit: 20,
                        page: page,
                        sort: sortDir === "desc" ? "-" + orderBy : orderBy,
                        "filter[search]": debouncedSearch,
                    }
                );
                setMeta(response.data.meta);
                setDisabilityPolicies(response.data.data);
                updateRecordCount(response.data.data.length);
                setLoading(false);
            } catch (error) {
                if (error.response.status === 403) {
                    setNotAuthorized(true);
                }
            }
        };
        fetchData();
    }, [
        client,
        showDisabled,
        updateRecordCount,
        debouncedSearch,
        orderBy,
        sortDir,
        needsUpdated,
        page,
        setMeta,
    ]);

    async function deletePolicy(policy) {
        try {
            const response = await Api.delete(
                "clients/" + client.id + "/disability-policies/" + policy.id
            );
            addToast(response.data.message);
            setNeedsUpdated(needsUpdated + 1);
        } catch (e) {
            addToast(e.errorMessage, { type: "error" });
        }
    }

    if (notAuthorized) {
        return <Forbidden />;
    }

    if (loading) {
        return <Spinner />;
    }

    return (
        <>
            <div className="mb-4">
                <SearchInput
                    label={false}
                    searchValue={search}
                    onSearchChange={(e) => setSearch(e.target.value)}
                />
            </div>
            {disabilityPolicies.length ? (
                <>
                    <table className="w-full text-left">
                        <thead>
                            <tr>
                                <TableSortHeader
                                    field="type"
                                    sortField={orderBy}
                                    sortDir={sortDir}
                                    changeSort={(column) => {
                                        setSortDir(
                                            sortDir === "asc" ? "desc" : "asc"
                                        );
                                        setOrderBy(column);
                                    }}
                                >
                                    Policy Type
                                </TableSortHeader>
                                <TableSortHeader
                                    field="number"
                                    sortField={orderBy}
                                    sortDir={sortDir}
                                    changeSort={(column) => {
                                        setSortDir(
                                            sortDir === "asc" ? "desc" : "asc"
                                        );
                                        setOrderBy(column);
                                    }}
                                >
                                    Policy #
                                </TableSortHeader>
                                <TableSortHeader
                                    field="benefit_amount"
                                    sortField={orderBy}
                                    sortDir={sortDir}
                                    changeSort={(column) => {
                                        setSortDir(
                                            sortDir === "asc" ? "desc" : "asc"
                                        );
                                        setOrderBy(column);
                                    }}
                                >
                                    Benefit
                                </TableSortHeader>
                                <TableSortHeader
                                    field="frequency"
                                    sortField={orderBy}
                                    sortDir={sortDir}
                                    changeSort={(column) => {
                                        setSortDir(
                                            sortDir === "asc" ? "desc" : "asc"
                                        );
                                        setOrderBy(column);
                                    }}
                                >
                                    Frequency
                                </TableSortHeader>
                                <TableSortHeader
                                    field="name"
                                    sortField={orderBy}
                                    sortDir={sortDir}
                                    changeSort={(column) => {
                                        setSortDir(
                                            sortDir === "asc" ? "desc" : "asc"
                                        );
                                        setOrderBy(column);
                                    }}
                                >
                                    Company Name
                                </TableSortHeader>
                                <TableSortHeader field="name" disabled={true}>
                                    Insured
                                </TableSortHeader>
                                <TableSortHeader
                                    field="created_at"
                                    sortField={orderBy}
                                    sortDir={sortDir}
                                    changeSort={(column) => {
                                        setSortDir(
                                            sortDir === "asc" ? "desc" : "asc"
                                        );
                                        setOrderBy(column);
                                    }}
                                >
                                    Date Added
                                </TableSortHeader>
                                <th className="text-right">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {disabilityPolicies.map((policy, idx) => (
                                <tr
                                    key={idx}
                                    className={policy.deleted_at ? "" : ""}
                                >
                                    <td>
                                        <Link
                                            to={`${clientPath}/disability-policies/${policy.id}/view`}
                                        >{`${policy.type}`}</Link>
                                    </td>
                                    <td>
                                        {policy.account_number_last_four && (
                                            <span className="text-gray-800">
                                                xxx
                                            </span>
                                        )}
                                        {policy.account_number_last_four}
                                    </td>
                                    <td>
                                        <Money value={policy.benefit_amount} />
                                    </td>
                                    <td>{policy.benefit_frequency}</td>
                                    <td>{policy.provider_name}</td>
                                    <td>
                                        {policy.insured?.full_name ?? "N/A"}
                                    </td>
                                    <td>{formatDate(policy.created_at)}</td>
                                    <td className="text-right">
                                        <span>
                                            <Dropdown
                                                position="right"
                                                toggleClass="px-3 py-1"
                                                toggleContent={
                                                    <FontAwesomeIcon
                                                        icon={faEllipsisH}
                                                    />
                                                }
                                            >
                                                <DropdownItem
                                                    label="Edit"
                                                    icon={faEdit}
                                                    link={`${clientPath}/disability-policies/${policy.id}/edit`}
                                                />
                                                {(userType === "member" ||
                                                    userType === "client") && (
                                                    <DropdownItem
                                                        label="Delete"
                                                        icon={faTrash}
                                                        handleClick={() =>
                                                            deletePolicy(policy)
                                                        }
                                                    />
                                                )}
                                            </Dropdown>
                                        </span>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <div className="my-10">
                        <Pagination {...meta} nextPage={setPage} />
                    </div>
                </>
            ) : (
                <div className="text-gray-400">
                    The are no Disability Policies for this client.{" "}
                    {canCreateWealthRecords && (
                        <Link
                            to={`${clientPath}/disability-policies/add`}
                            className="underline text-gray-600"
                        >
                            Add Now.
                        </Link>
                    )}
                </div>
            )}
        </>
    );
}
