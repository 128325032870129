import React, { useState } from "react";
import Api from "../../services/Api";
import Modal from "../../components/Modal";
import { processFormErrors } from "../../util/FormsHelper";
import { useToasts } from "react-toast-notifications";

export default function ModalRevokeAccess({ access, show, setShow, onResult }) {
    const [isLoading, setIsLoading] = useState(false);
    const [errors, setErrors] = useState([]);
    const { addToast } = useToasts();

    const revokeAccess = async (access) => {
        setErrors([]);
        try {
            setIsLoading(true);
            await Api.delete(`resources/access/${access.id}`);
            onResult();
            addToast(
                `Revoked access for ${access && access.user_full_name} to ${
                    access && access.resource_name
                }.`,
                { type: "success" }
            );
            setIsLoading(false);
            return true;
        } catch (e) {
            setIsLoading(false);
            console.log(e);
            setErrors(processFormErrors(e));
        }
    };

    return (
        <Modal
            isOpen={show}
            type="warning"
            title={`Revoke Access ${
                access && access.type === "directory" ? "Folder" : "File"
            }`}
            body={() => {
                return (
                    <p>
                        Are you sure you want to revoke access for{" "}
                        <strong>{access && access.user_full_name}</strong> to{" "}
                        <strong>{access && access.resource_name}</strong>. Type
                        in <strong>revoke</strong> below to confirm.
                    </p>
                );
            }}
            inputType="text"
            inputErrors={errors}
            doneButtonTitle="Revoke"
            isLoading={isLoading}
            onResult={async (result) => {
                if (result.result !== "revoke") {
                    if (typeof result.result === "string") {
                        setErrors([
                            'Please confirm deletion by typing in "revoke"',
                        ]);
                        return;
                    }
                    setErrors([]);
                    setShow(false);
                    return;
                }
                if (await revokeAccess(access)) {
                    setShow(false);
                    onResult();
                }
            }}
        />
    );
}
