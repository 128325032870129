import usePost from "./usePost";
import { resetAllMemberPermissions } from "../services/PermissionService";

export default function useResetAllMemberPermissions(clientId) {
    const { mutation, validationErrors } = usePost(
        () => resetAllMemberPermissions(clientId),
        {
            showToastOnError: true,
            showToastOnSuccess: true,
        }
    );
    const onSubmit = (event) => {
        event.preventDefault();
        return mutation.mutate();
    };

    return { onSubmit, ...mutation, validationErrors };
}
