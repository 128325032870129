import React, { useContext } from "react";
import { Switch } from "react-router-dom";
import DashboardLayout from "../../layouts/DashboardLayout";
import ResourceNotFound from "../../../components/ResourceNotFound";
import { AuthContext } from "../../../contexts/AuthContext";
import Relationships from "../../relationships/Relationships";
import ClientVault from "./../Vault/Vault";
import Advisors from "../../advisors/Advisors";
import InsurancePolicies from "../../insurance_policies/InsurancePolicies";
import PersonalProperties from "../../personal_property/PersonalProperties";
import IncomeSources from "../../income_sources/IncomeSources";
import DisabilityPolicies from "../../disability_policies/DisabilityPolicies";
import Annuities from "../../annuities/Annuities";
import OtherInsurancePolices from "../../other_insurance_policies/OtherInsurancePolicies";
import NonMortgageLiabilities from "../../non_mortgage_liabilities/NonMortgageLiabilities";
import PersonalPropertyMemorandums from "../../personal_property_memo/PersonalPropertyMemorandums";
import ClientForms from "../../client_forms/ClientForms";
import RealEstate from "../../real_estate/RealEstate";
import NotesReceivables from "../../notes_receivables/NotesReceivables";
import Leases from "../../leases/Leases";
import Trusts from "../../trusts/Trusts";
import BudgetEstimates from "../../budget-estimates/BudgetEstimates";
import Trustees from "../../trustees/Trustees";
import ClientQuestionnaires from "../../client_questionnaires/ClientQuestionnaires";
import FinancialAccounts from "../../financial_accounts/FinancialAccounts";
import BusinessInterest from "../../business_interests/BusinessInterest";
import EstatePlanningRoles from "../../estate_planning_roles/EstatePlanningRoles";
import BeneficiariesIndex from "../../Beneficiaries/BeneficiariesIndex";
import UpdateValues from "../../update_values/UpdateValues";
import ClientNotesPage from "./../Notes/ClientNotesPage";
import Messages from "../../messages/Messages";
import Appointments from "../../appointments/Appointments";
import ICE from "views/ice/ICE";
import AssetsAndLiabilitiesIndex from "../AssetsAndLiabilitiesIndex";
import { ClientRoute } from "../ManageClient";
import useClient from "hooks/useClient";
import Client from "views/relationships/Client";
import RetirementAccounts from "../../retirement_accounts/RetirementAccounts";

const Clients = (props) => {
    const context = useContext(AuthContext);
    const { client, refreshClient } = useClient();
    const clientPath = "/client";
    const isClient = true;

    if (client.type !== "client") {
        return <ResourceNotFound />;
    }

    const reloadClient = async () => {
        refreshClient();
    };

    return (
        <DashboardLayout>
            <Switch>
                <ClientRoute
                    path={["/client/advisors"]}
                    render={() => (
                        <Advisors
                            clientPath={clientPath}
                            isClient={isClient}
                            user={context.user}
                            client={client}
                            reloadClient={reloadClient}
                            {...props}
                        />
                    )}
                />
                <ClientRoute
                    path={["/client/annuities"]}
                    render={() => (
                        <Annuities
                            clientPath={clientPath}
                            isClient={isClient}
                            user={context.user}
                            client={client}
                            reloadClient={reloadClient}
                            {...props}
                        />
                    )}
                />
                <ClientRoute
                    path={["/client/appointments"]}
                    render={() => (
                        <Appointments
                            clientPath={clientPath}
                            isClient={isClient}
                            user={context.user}
                            client={client}
                            reloadClient={reloadClient}
                            {...props}
                        />
                    )}
                />
                <ClientRoute
                    path={["/client/beneficiaries"]}
                    render={() => (
                        <BeneficiariesIndex
                            clientPath={clientPath}
                            isClient={isClient}
                            user={context.user}
                            client={client}
                            reloadClient={reloadClient}
                            {...props}
                        />
                    )}
                />
                <ClientRoute
                    path={["/client/budget-estimates"]}
                    render={() => (
                        <BudgetEstimates
                            clientPath={clientPath}
                            isClient={isClient}
                            user={context.user}
                            client={client}
                            reloadClient={reloadClient}
                            {...props}
                        />
                    )}
                />
                <ClientRoute
                    path={["/client/business-interests"]}
                    render={() => (
                        <BusinessInterest
                            clientPath={clientPath}
                            isClient={isClient}
                            user={context.user}
                            client={client}
                            reloadClient={reloadClient}
                            {...props}
                        />
                    )}
                />
                <ClientRoute
                    path={["/client/disability-policies"]}
                    render={() => (
                        <DisabilityPolicies
                            clientPath={clientPath}
                            isClient={isClient}
                            user={context.user}
                            client={client}
                            reloadClient={reloadClient}
                            {...props}
                        />
                    )}
                />
                <ClientRoute
                    path={["/client/estate-planning-roles"]}
                    render={() => (
                        <EstatePlanningRoles
                            clientPath={clientPath}
                            isClient={isClient}
                            user={context.user}
                            client={client}
                            reloadClient={reloadClient}
                            {...props}
                        />
                    )}
                />
                <ClientRoute
                    path={["/client/financial-accounts"]}
                    render={() => (
                        <FinancialAccounts
                            clientPath={clientPath}
                            isClient={isClient}
                            user={context.user}
                            client={client}
                            reloadClient={reloadClient}
                            {...props}
                        />
                    )}
                />
                <ClientRoute
                    path={["/client/retirement-accounts"]}
                    render={() => (
                        <RetirementAccounts
                            clientPath={clientPath}
                            isClient={isClient}
                            user={context.user}
                            client={client}
                            reloadClient={reloadClient}
                            {...props}
                        />
                    )}
                />
                <ClientRoute
                    path={["/client/forms"]}
                    render={() => (
                        <ClientForms
                            clientPath={clientPath}
                            isClient={isClient}
                            user={context.user}
                            client={client}
                            reloadClient={reloadClient}
                            {...props}
                        />
                    )}
                />
                <ClientRoute
                    path={["/client/income-sources"]}
                    render={() => (
                        <IncomeSources
                            clientPath={clientPath}
                            isClient={isClient}
                            user={context.user}
                            client={client}
                            reloadClient={reloadClient}
                            {...props}
                        />
                    )}
                />
                <ClientRoute
                    path={["/client/insurance-policies"]}
                    render={() => (
                        <InsurancePolicies
                            clientPath={clientPath}
                            isClient={isClient}
                            user={context.user}
                            client={client}
                            reloadClient={reloadClient}
                            {...props}
                        />
                    )}
                />
                <ClientRoute
                    path={["/client/leases"]}
                    render={() => (
                        <Leases
                            clientPath={clientPath}
                            isClient={isClient}
                            user={context.user}
                            client={client}
                            reloadClient={reloadClient}
                            {...props}
                        />
                    )}
                />
                <ClientRoute
                    path={["/client/messages"]}
                    render={() => (
                        <Messages
                            clientPath={clientPath}
                            isClient={isClient}
                            user={context.user}
                            client={client}
                            reloadClient={reloadClient}
                            {...props}
                        />
                    )}
                />
                <ClientRoute
                    path={["/client/non-mortgage-liabilities"]}
                    render={() => (
                        <NonMortgageLiabilities
                            clientPath={clientPath}
                            isClient={isClient}
                            user={context.user}
                            client={client}
                            reloadClient={reloadClient}
                            {...props}
                        />
                    )}
                />
                <ClientRoute
                    path={["/client/other-insurance-policies"]}
                    render={() => (
                        <OtherInsurancePolices
                            clientPath={clientPath}
                            isClient={isClient}
                            user={context.user}
                            client={client}
                            reloadClient={reloadClient}
                            {...props}
                        />
                    )}
                />
                <ClientRoute
                    path={["/client/notes-receivables"]}
                    render={() => (
                        <NotesReceivables
                            clientPath={clientPath}
                            isClient={isClient}
                            user={context.user}
                            client={client}
                            reloadClient={reloadClient}
                            {...props}
                        />
                    )}
                />
                <ClientRoute
                    path={["/client/personal-properties"]}
                    render={() => (
                        <PersonalProperties
                            clientPath={clientPath}
                            isClient={isClient}
                            user={context.user}
                            client={client}
                            reloadClient={reloadClient}
                            {...props}
                        />
                    )}
                />
                <ClientRoute
                    path={["/client/personal-property-memos"]}
                    render={() => (
                        <PersonalPropertyMemorandums
                            clientPath={clientPath}
                            isClient={isClient}
                            user={context.user}
                            client={client}
                            reloadClient={reloadClient}
                            {...props}
                        />
                    )}
                />
                <ClientRoute
                    path={["/client/questionnaires"]}
                    render={() => (
                        <ClientQuestionnaires
                            clientPath={clientPath}
                            isClient={isClient}
                            user={context.user}
                            client={client}
                            reloadClient={reloadClient}
                            {...props}
                        />
                    )}
                />
                <ClientRoute
                    path={["/client/real-estate"]}
                    render={() => (
                        <RealEstate
                            clientPath={clientPath}
                            isClient={isClient}
                            user={context.user}
                            client={client}
                            reloadClient={reloadClient}
                            {...props}
                        />
                    )}
                />
                <ClientRoute
                    path={["/client/relationships"]}
                    render={() => (
                        <Relationships
                            clientPath={clientPath}
                            isClient={isClient}
                            user={context.user}
                            client={client}
                            reloadClient={reloadClient}
                            {...props}
                        />
                    )}
                />
                <ClientRoute
                    path={["/client/trustees"]}
                    render={() => (
                        <Trustees
                            clientPath={clientPath}
                            isClient={isClient}
                            user={context.user}
                            client={client}
                            reloadClient={reloadClient}
                            {...props}
                        />
                    )}
                />
                <ClientRoute
                    path={["/client/trusts"]}
                    render={() => (
                        <Trusts
                            clientPath={clientPath}
                            isClient={isClient}
                            user={context.user}
                            client={client}
                            reloadClient={reloadClient}
                            {...props}
                        />
                    )}
                />
                <ClientRoute
                    path={["/client/vault"]}
                    render={() => (
                        <ClientVault
                            clientPath={clientPath}
                            isClient={isClient}
                            user={context.user}
                            client={client}
                            reloadClient={reloadClient}
                            {...props}
                        />
                    )}
                />
                <ClientRoute
                    path={["/client/update-values"]}
                    render={() => (
                        <UpdateValues
                            clientPath={clientPath}
                            isClient={isClient}
                            user={context.user}
                            client={client}
                            reloadClient={reloadClient}
                            {...props}
                        />
                    )}
                />
                <ClientRoute
                    path={["/client/notes"]}
                    render={() => (
                        <ClientNotesPage
                            clientPath={clientPath}
                            isClient={isClient}
                            user={context.user}
                            client={client}
                            reloadClient={reloadClient}
                            {...props}
                        />
                    )}
                />
                <ClientRoute
                    path="/client/ice"
                    render={() => (
                        <ICE
                            user={context.user}
                            clientPath={clientPath}
                            isClient={isClient}
                            client={client}
                            reloadClient={reloadClient}
                            {...props}
                        />
                    )}
                />
                <ClientRoute
                    path={"/client/assets-liabilities"}
                    render={() => (
                        <AssetsAndLiabilitiesIndex
                            clientPath={clientPath}
                            client={client}
                        />
                    )}
                />
                <ClientRoute
                    path={["/client/profile/view", '"/client/profile/edit"']}
                    render={() => <Client {...props} />}
                />
            </Switch>
        </DashboardLayout>
    );
};

export default Clients;
