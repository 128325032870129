import React from "react";

export default function SectionHeader({ children, buttons, ...props }) {
    return (
        <div className="flex items-center justify-between mb-4 pb-3 border-b border-gray-200">
            <h2 className="text-xl text-gray-700 font-bold" {...props}>
                {children}
            </h2>
            {buttons && <div>{buttons}</div>}
        </div>
    );
}
