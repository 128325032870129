import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";

export const AddOwnershipButton = ({
    ownerships,
    appendOwnership,
    tabIndex,
    text = "Add an Owner",
}) => {
    let buttonLabel = text;
    if (ownerships && ownerships.length >= 1) {
        buttonLabel = "Add an Additional Owner";
    }
    return (
        <button
            onClick={appendOwnership}
            tabIndex={tabIndex}
            type="button"
            className="flex items-center space-x-1 text-gray-800 hover:text-brand p-1 mr-auto mt-4 text-xs"
        >
            <FontAwesomeIcon icon={faPlusCircle} className="text-gray-400" />{" "}
            <span>{buttonLabel}</span>
        </button>
    );
};
