import React, { useState } from "react";
import ClientPageHeader, {
    Heading,
    HeadingContainer,
} from "../../components/ClientPageHeader";
import PageContainer from "../../components/PageContainer";
import Api from "../../services/Api";
import useQuickAdd from "../../hooks/useQuickAdd";
import Panel from "../../components/Panel";
import Button from "../../components/Button";
import { formatDate, titleCase } from "../../util/TextUtil";
import Money from "../../components/Money";
import ModalModelFactory from "../../components/ModalModelFactory";
import { resourceTypes } from "../../components/Beneficiary/Beneficiary";
import AssetBeneficiary from "../../components/Beneficiary/AssetBeneficiary";
import { useToasts } from "react-toast-notifications";
import { downloadFile } from "../../util/Downloader";
import ResourceContextProvider from "../../contexts/ResourceContext";
import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";
import Dropdown, { DropdownItem } from "../../components/Dropdown";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons";
import useHasAnyPermissions from "hooks/useHasAnyPermissions";
import { OwnershipViewTabs } from "views/funding_table/FundingTableIndex";
import useAllBeneficiaries from "hooks/useAllBeneficiaries";
import { useQueryState } from "use-location-state";
import Badge from "components/Badge";
import EmptyState from "components/EmptyState";

export default function BeneficiariesIndex({ client, user, clientPath }) {
    document.title = "Beneficiaries";

    const quickAdd = useQuickAdd();

    const [current, setCurrent] = useQueryState("current", true);
    const showProposed = !current;

    const [assetsNeedsRefreshed, setAssetsNeedsRefreshed] = useState(0);
    const [isExporting, setIsExporting] = useState(false);
    const canDownloadReport = useHasAnyPermissions([
        "report_global_access",
        "report_beneficiary_report",
    ]);

    const { addToast } = useToasts();

    const {
        data: assets,
        refetch: refetchBeneficiaries,
        isLoading: loading,
        isFetching,
    } = useAllBeneficiaries(
        {
            clientId: client.id,
            showProposed,
        },
        {
            initialData: [],
        }
    );

    const isLoading = loading || isFetching;

    const didSave = () => {
        refetchBeneficiaries();
    };

    async function exportRecords(e) {
        e.preventDefault();
        try {
            setIsExporting(true);
            let response = await Api.get(
                "clients/" + client.id + "/reports/beneficiary",
                {
                    export: true,
                    showProposed,
                },
                { responseType: "arraybuffer" }
            );
            await downloadFile(response, "beneficiary-report");
            setIsExporting(false);
            addToast("Export downloaded");
        } catch (e) {
            setIsExporting(false);
            addToast(e.response.data.message, { type: "error" });
        }
    }

    return (
        <>
            <ClientPageHeader client={client}>
                <Heading>Beneficiaries</Heading>
            </ClientPageHeader>
            <HeadingContainer className="border-none pb-0">
                <div className="flex justify-between w-full">
                    <div className="mr-auto">
                        <OwnershipViewTabs
                            quickAdd={false}
                            current={current}
                            setCurrent={setCurrent}
                            currentTab={null}
                        />
                    </div>
                    <div className="flex space-x-2">
                        {canDownloadReport && assets.length > 0 && (
                            <div className="flex-none">
                                <Button
                                    text="Download"
                                    appearance="outline"
                                    width="w-auto"
                                    isLoading={isExporting}
                                    onClick={exportRecords}
                                />
                            </div>
                        )}
                        <div className="flex-none">
                            <Dropdown
                                position="right"
                                toggleButtonAppearance="outline"
                                toggleButton={
                                    <span className="flex items-center space-x-1">
                                        <span>Quick Add</span>
                                        <FontAwesomeIcon icon={faCaretDown} />
                                    </span>
                                }
                            >
                                {quickAdd.quickAddAssets.map((item) => (
                                    <DropdownItem
                                        key={item.type}
                                        label={item.label}
                                        handleClick={() => {
                                            quickAdd.setQuickAddType(item.type);
                                            quickAdd.setQuickAddTitle(
                                                item.label
                                            );
                                            quickAdd.setQuickAddOpen(true);
                                        }}
                                    />
                                ))}
                            </Dropdown>
                        </div>
                    </div>
                </div>
            </HeadingContainer>
            <PageContainer isLoading={isLoading}>
                <ResourceContextProvider
                    resourceTypes={resourceTypes}
                    refresh={assetsNeedsRefreshed}
                >
                    <div className="flex grid-cols-2">
                        <div className="w-full space-y-6">
                            {assets.map((asset) => {
                                return (
                                    <Panel
                                        key={`${asset.type}_${asset.id}`}
                                        appearance="wrap"
                                    >
                                        <div className="bg-white flex items-center justify-between mb-8 pb-3 border-b border-gray-200">
                                            <div className="flex-1">
                                                <div
                                                    className={`text-xs text-gray-500`}
                                                >
                                                    {titleCase(asset.type, {
                                                        pascal: true,
                                                    })}
                                                </div>
                                                <h2 className="text-sm">
                                                    <div className="flex items-start">
                                                        <a
                                                            href={
                                                                asset.resource_path
                                                            }
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                            className="hover:text-brand-500 text-gray-600"
                                                        >
                                                            <span className="font-bold text-xl">
                                                                {asset.name}
                                                            </span>
                                                            &nbsp;
                                                            <FontAwesomeIcon
                                                                icon={
                                                                    faExternalLinkAlt
                                                                }
                                                                className="pb-1"
                                                            />
                                                        </a>
                                                    </div>
                                                    {asset.account_number && (
                                                        <span className="text-xs text-gray-500 block mt-1">
                                                            {
                                                                asset.account_number
                                                            }
                                                        </span>
                                                    )}
                                                </h2>
                                            </div>
                                            <div className="flex-none flex items-center border-r pr-3 mr-3 border-gray-300">
                                                <DateBadge asset={asset} />
                                            </div>
                                            <div className="flex-none text-brand font-bold text-lg">
                                                {
                                                    <Money
                                                        value={
                                                            asset.death_benefit
                                                                ? asset.death_benefit
                                                                : asset.dod_net_value
                                                        }
                                                    />
                                                }
                                            </div>
                                        </div>
                                        {showProposed ? (
                                            <AssetBeneficiary
                                                proposed={true}
                                                key={`proposed_${asset.id}`}
                                                asset={asset}
                                                client={client}
                                                resourceType={asset.type}
                                                resourceId={asset.id}
                                                defaultBeneficiaries={
                                                    asset.proposedBeneficiaries
                                                }
                                                current={current}
                                                didSave={didSave}
                                                loading={isLoading}
                                            />
                                        ) : (
                                            <AssetBeneficiary
                                                proposed={false}
                                                key={`current_${asset.id}`}
                                                asset={asset}
                                                client={client}
                                                resourceType={asset.type}
                                                resourceId={asset.id}
                                                defaultBeneficiaries={
                                                    asset.currentBeneficiaries
                                                }
                                                current={current}
                                                didSave={didSave}
                                                loading={isLoading}
                                            />
                                        )}
                                    </Panel>
                                );
                            })}
                            {assets.length === 0 && (
                                <EmptyState>
                                    No asset and beneficiary data has been
                                    entered yet.
                                </EmptyState>
                            )}
                        </div>
                    </div>
                </ResourceContextProvider>
            </PageContainer>

            <ModalModelFactory
                modelType={quickAdd.quickAddType}
                client={client}
                user={user}
                clientPath={clientPath}
                isOpen={quickAdd.quickAddOpen}
                title={quickAdd.quickAddTitle}
                onClose={() => {
                    quickAdd.setQuickAddOpen(false);
                    quickAdd.setQuickAddTitle("");
                }}
                onSuccess={() => {
                    quickAdd.setQuickAddOpen(false);
                    quickAdd.setQuickAddTitle("");
                    setAssetsNeedsRefreshed(assetsNeedsRefreshed + 1);
                }}
            />
        </>
    );
}

export function DateBadge({ asset }) {
    if (!asset.beneficiaries_proposed_at && !asset.beneficiaries_verified_at) {
        return (
            <Badge color="gray">
                There are no beneficiaries for this asset
            </Badge>
        );
    }

    if (asset.beneficiaries_proposed_at && asset.beneficiaries_verified_at) {
        return (
            <div className="flex space-x-2">
                <Badge color="gray">
                    {"Last Verified: " +
                        formatDate(asset.beneficiaries_verified_at)}
                </Badge>
                <Badge color="gray">
                    {"Proposed: " + formatDate(asset.beneficiaries_proposed_at)}
                </Badge>
            </div>
        );
    }

    if (asset.beneficiaries_verified_at) {
        return (
            <Badge color="gray">
                {"Verified: " + formatDate(asset.beneficiaries_verified_at)}
            </Badge>
        );
    }

    return (
        <Badge color="gray">
            {"Date Proposed: " + formatDate(asset.beneficiaries_proposed_at)}
        </Badge>
    );
}
