import React, { useContext } from "react";
import { Route } from "react-router-dom";
import useLocalStorage from "../../hooks/useLocalStorage";
import MainNavigation from "./Members/MainNavigation";
import { AuthContext } from "../../contexts/AuthContext";
import ClientMainNavigation from "./Clients/ClientMainNavigation";
import AdvisorMainNavigation from "./Advisors/AdvisorMainNavigation";
import BannerAlerts from "./_partials/BannerAlerts";
import ErrorBoundary from "../../util/ErrorBoundary";
import { classNames } from "Utility";

export function FooterLinks({
    className = "flex flex-none items-center border-t border-gray-200 py-6 px-4 w-full bg-gray-50 print:hidden",
}) {
    return (
        <div className={classNames(className)}>
            <a
                className="px-5 inline-block text-brand-700 hover:underline weight-bold border-right border-gray-300"
                href={
                    process.env.REACT_APP_INHERALINK_WEBSITE + "/terms-of-use"
                }
                target="_blank"
                rel="noreferrer"
            >
                Terms of Use
            </a>
            <a
                className="px-5 inline-block text-brand-700 hover:underline weight-bold border-right border-gray-300"
                href={process.env.REACT_APP_INHERALINK_WEBSITE + "/security"}
                target="_blank"
                rel="noreferrer"
            >
                Security
            </a>
            <a
                className="px-5 inline-block text-brand-700 hover:underline weight-bold border-right border-gray-300"
                href={
                    process.env.REACT_APP_INHERALINK_WEBSITE + "/privacy-policy"
                }
                target="_blank"
                rel="noreferrer"
            >
                Privacy Policy
            </a>
        </div>
    );
}

const DashboardLayout = ({ children, ...rest }) => {
    const authContext = useContext(AuthContext);
    const [isCollapsed, setIsCollapsed] = useLocalStorage(
        "collapse_sidebar",
        false
    );
    const workareaStyle =
        "max-h-screen bg-gray-50 flex flex-col flex-1 relative overflow-auto print:max-h-auto print:overflow-visible";

    if (authContext.user.type === "advisor") {
        return (
            <Route
                {...rest}
                render={(matchProps) => (
                    <div className="h-auto min-h-screen flex flex-col">
                        <BannerAlerts />
                        <div className="h-full flex-1 flex">
                            <AdvisorMainNavigation
                                {...matchProps}
                                {...rest}
                                isCollapsed={isCollapsed}
                                canCollapse={true}
                                onCollapse={() => setIsCollapsed(!isCollapsed)}
                            />
                            <div id="workarea" className={workareaStyle}>
                                <div className="flex-1">
                                    <ErrorBoundary>{children}</ErrorBoundary>
                                </div>
                                <FooterLinks />
                            </div>

                            <div
                                id="rightSideBar"
                                className="h-auto overflow-auto flex-none"
                            ></div>
                        </div>
                    </div>
                )}
            />
        );
    } else if (authContext.user.type === "client") {
        return (
            <Route
                {...rest}
                render={(matchProps) => (
                    <div className="h-auto min-h-screen flex flex-col">
                        <BannerAlerts />
                        <div className="h-full flex-1 flex">
                            <ClientMainNavigation
                                {...matchProps}
                                {...rest}
                                isCollapsed={isCollapsed}
                                canCollapse={false}
                                onCollapse={() => setIsCollapsed(!isCollapsed)}
                            />
                            <div id="workarea" className={workareaStyle}>
                                <div className="flex-1">
                                    <ErrorBoundary>{children}</ErrorBoundary>
                                </div>
                                <FooterLinks />
                            </div>

                            <div
                                id="rightSideBar"
                                className="h-auto overflow-auto flex-none"
                            ></div>
                        </div>
                    </div>
                )}
            />
        );
    } else {
        return (
            <Route
                {...rest}
                render={(matchProps) => (
                    <div className="h-auto min-h-screen flex flex-col">
                        <BannerAlerts />
                        <div className="h-full flex-1 flex">
                            <MainNavigation
                                {...matchProps}
                                {...rest}
                                isCollapsed={isCollapsed}
                                canCollapse={true}
                                onCollapse={() => setIsCollapsed(!isCollapsed)}
                            />

                            <div id="workarea" className={workareaStyle}>
                                <div className="flex-1">
                                    <ErrorBoundary>{children}</ErrorBoundary>
                                </div>
                                <FooterLinks />
                            </div>

                            <div
                                id="rightSideBar"
                                className="h-auto overflow-auto flex-none"
                            ></div>
                        </div>
                    </div>
                )}
            />
        );
    }
};

export default DashboardLayout;
