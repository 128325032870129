export default function useFormatCurrency(options = null) {
    const defaultOptions = {
        currency: "USD",
        minimumFractionDigits: 2,
    };

    return function formatCurrency(value) {
        return new Intl.NumberFormat("en-US", options || defaultOptions).format(
            (value + "").replace(/[^0-9.]+/g, "")
        );
    };
}
