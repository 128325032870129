import React, { useState, useReducer } from "react";
import Api from "../../services/Api";
import { useToasts } from "react-toast-notifications";
import BusinessInterestForm from "./_partials/BusinessInterestForm";
import BasicModal, { BasicModalHeader } from "../../components/ModalBasic";
import { formatDateForInput } from "../../util/TextUtil";

export default function BusinessInterestModal({
    isOpen,
    title,
    client,
    onClose,
    onSuccess,
}) {
    const readOnly = false;
    const { addToast } = useToasts();
    const [errors, setErrors] = useState([]);
    const [input, setInput] = useReducer(
        (state, newState) => ({ ...state, ...newState }),
        {
            name: "",
            description: "",
            value: "",
            as_of_date: formatDateForInput(new Date()),
            purchase_price: "",
            purchase_date: "",
            insurance_policy_id: "",
            owner: "client:" + client.id,
            percentage_owned: "",
            has_lien: false,
            lien_holder: "",
            loan_balance: "",
            managed_by_id: "",
            type_id: 1,
        }
    );

    function updateValue(event) {
        setInput({ [event.target.name]: event.target.value });
    }

    async function store(e) {
        e.preventDefault();
        try {
            const body = { ...input };
            let response = await Api.post(
                "clients/" + client.id + "/business-interests",
                body
            );
            addToast(response.data.message);
            setErrors([]);
            onSuccess();
        } catch (error) {
            setErrors([]);
            console.log(error);
            if (error.response.data.errors) {
                setErrors(error.response.data.errors);
            } else {
                addToast(error.response.data.message, { type: "error" });
            }
        }
    }

    return (
        <>
            <BasicModal
                isOpen={isOpen}
                size="w-full"
                onClose={onClose}
                header={
                    <BasicModalHeader
                        onClose={onClose}
                        onSave={store}
                        title={title}
                    />
                }
            >
                <div className="max-w-3xl mx-auto">
                    <BusinessInterestForm
                        client={client}
                        input={input}
                        readOnly={readOnly}
                        errors={errors}
                        updateValue={updateValue}
                        onSubmit={store}
                    />
                </div>
            </BasicModal>
        </>
    );
}
