import React, { useState } from "react";
import TextInput from "../../../components/TextInput";
import Checkbox from "../../../components/Checkbox";
import SaveButton from "../../../components/SaveButton";

export default function AddNewBudgetItem({ budgetItem, saveValue }) {
    const [item, setItem] = useState(budgetItem);

    const AddBudgetItem = (e) => {
        e.preventDefault();
        saveValue(item);
    };

    return (
        <form onSubmit={AddBudgetItem} className="space-y-6">
            <div>
                <TextInput
                    name={`${item.id}_name`}
                    label="Item Name"
                    value={item.name}
                    onChange={(e) => {
                        setItem({ ...item, name: e.target.value });
                    }}
                />
            </div>
            <div>
                <Checkbox
                    name={`${item.id}_has_joint_values`}
                    id="has_joint_values"
                    value={1}
                    onChange={(e) => {
                        setItem({
                            ...item,
                            has_joint_values: parseInt(e.target.value),
                        });
                    }}
                    label="Has Joint Values?"
                    defaultChecked={item.has_joint_values}
                />
            </div>
            <div>
                <SaveButton text="Add Item" type="submit" />
            </div>
        </form>
    );
}
