import React from "react";
import VerticalTabs from "../../../components/VerticalTabs";

export default function IncomeSourceTabs({ basePath, ...props }) {
    return (
        <VerticalTabs
            {...props}
            items={[
                {
                    label: "Info",
                    to: basePath + "",
                },
                {
                    label: "Notes",
                    to: basePath + "/notes",
                },
                {
                    label: "Related Files",
                    to: basePath + "/related-files",
                },
                {
                    label: "Access",
                    to: basePath + "/access",
                },
            ]}
        />
    );
}
