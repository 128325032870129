import Api from "./Api";

export const fetchOtherInsurancePolices = async function ({ queryKey }) {
    const [, { ...params }] = queryKey;
    return await Api.get(
        "clients/" + params.clientId + "/other-insurance-policies",
        params
    ).then((res) => res.data);
};

export const fetchOtherInsurancePolicy = async function ({ queryKey }) {
    const [, { ...params }] = queryKey;
    return await Api.get(
        "clients/" +
            params.clientId +
            "/other-insurance-policies" +
            params.policyId,
        params
    ).then((res) => res.data);
};

export default {
    fetchOtherInsurancePolices,
    fetchOtherInsurancePolicy,
};
