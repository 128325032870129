import React, { useState, useEffect } from "react";
import MessageList from "./MessageList";
import { Route } from "react-router";
import MessageComposer, { MessageComposerForm } from "./MessageComposer";
import Button from "../../components/Button";
import ClientPageHeader from "../../components/ClientPageHeader";
import MessageViewer from "./MessageViewer";
import TextInput from "../../components/TextInput";
import useDebounce from "../../hooks/useDebounce";
import Api from "../../services/Api";
import SelectInput from "../../components/SelectInput";
import { faEdit, faSync } from "@fortawesome/free-solid-svg-icons";
import { useHistory } from "react-router-dom";

export default function Messages({ client, clientPath, ...props }) {
    const [showComposeModal, setShowComposeModal] = useState(false);
    const [recipients, setRecipients] = useState([]);
    const [subject, setSubject] = useState("");
    const [search, setSearch] = useState("");
    const debouncedSearch = useDebounce(search, 250);
    const [searchType, setSearchType] = useState("Subject");
    const [needsRefreshed, setNeedsRefreshed] = useState(0);
    const history = useHistory();

    useEffect(() => {
        document.title = "Messages";
    }, []);

    async function fetchRecipientOptions(query) {
        let response = await Api.get(`clients/${client.id}/recipients`, {
            "filter[search]": query,
            limit: 10,
        });

        return response.data.data.map((recipient) => {
            return {
                value: recipient.id.toString(),
                label: `${recipient.display_name} ${
                    recipient.type ? "(" + recipient.type + ")" : ""
                }`,
            };
        });
    }

    async function deleteMessage(messageId) {
        try {
            await Api.delete(`clients/${client.id}/messages/${messageId}`);
            setNeedsRefreshed((a) => a + 1);
            history.push(`${clientPath}/messages`);
        } catch (error) {
            console.log(error);
        }
    }

    async function reply(message) {
        try {
            setRecipients([
                { value: message.sender_id, label: message.sender },
            ]);
            setSubject(`Re: ${message.subject}`);
            setShowComposeModal(true);
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <>
            <div className="flex flex-col h-full overflow-hidden max-h-screen">
                <ClientPageHeader client={client} />

                <Route
                    exact
                    path={[
                        `/client/messages/`,
                        `/client/messages/:messageId/view`,
                        `/clients/:clientId/messages/`,
                        `/clients/:clientId/messages/:messageId/view`,
                    ]}
                    render={(props) => (
                        <>
                            <div className="flex-initial bg-white flex items-center border-b border-gray-200 p-3 space-x-3">
                                <div className="flex space-x-3 flex-initial ml-auto">
                                    <Button
                                        text="Refresh"
                                        type="button"
                                        title="Fetch New Messages"
                                        appearance="light"
                                        icon={faSync}
                                        onClick={() =>
                                            setNeedsRefreshed((a) => a + 1)
                                        }
                                    />
                                    <Button
                                        text="New Message"
                                        title="Compose New Message"
                                        type="button"
                                        appearance="light"
                                        icon={faEdit}
                                        onClick={() =>
                                            setShowComposeModal(true)
                                        }
                                    />
                                </div>
                                <div className="flex-1 flex items-center relative">
                                    <div className="absolute top-0 right-0 z-10">
                                        <SelectInput
                                            className="border-none bg-transparent text-gray-500"
                                            name="searchType"
                                            value={searchType}
                                            options={[
                                                {
                                                    value: "Subject",
                                                    label: "Subject",
                                                },
                                                {
                                                    value: "Content",
                                                    label: "Content",
                                                },
                                                {
                                                    value: "Person",
                                                    label: "Person",
                                                },
                                            ]}
                                            onChange={(e) =>
                                                setSearchType(e.target.value)
                                            }
                                        />
                                    </div>
                                    <div className="flex-1">
                                        <TextInput
                                            name="search"
                                            value={search}
                                            placeholder="Search messages"
                                            onChange={(e) =>
                                                setSearch(e.target.value)
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="flex flex-1 overflow-hidden">
                                <MessageList
                                    client={client}
                                    clientPath={clientPath}
                                    search={debouncedSearch}
                                    searchType={searchType}
                                    needsRefreshed={needsRefreshed}
                                    {...props}
                                />
                                <div className="flex-1 h-full max-h-screen overflow-auto bg-white border-l border-gray-200">
                                    <MessageViewer
                                        client={client}
                                        deleteMessage={deleteMessage}
                                        reply={reply}
                                        needsRefreshed={needsRefreshed}
                                        {...props}
                                    />
                                </div>
                            </div>
                        </>
                    )}
                />
            </div>

            <MessageComposer
                {...props}
                showComposeModal={showComposeModal}
                setShowComposeModal={setShowComposeModal}
                withModal={true}
                client={client}
                defaultRecipients={recipients}
                defaultSubject={subject}
                fetchRecipientOptions={fetchRecipientOptions}
                onSend={() => {
                    setShowComposeModal(false);
                    setNeedsRefreshed(needsRefreshed + 1);
                }}
                component={(renderProps) => (
                    <MessageComposerForm {...renderProps} />
                )}
            />
        </>
    );
}
