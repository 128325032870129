import { titleCase } from "util/TextUtil";
import Api from "./Api";

export const fetchMemberPermissions = async function ({ queryKey }) {
    const [, { ...params }] = queryKey;
    return await Api.get(
        "members/" + params.memberId + "/permissions/",
        params
    ).then((res) => res.data);
};

export const updateMemberPermission = async function (memberId, formData) {
    return await Api.post(
        "members/" + memberId + "/permissions/",
        formData
    ).then((res) => res.data);
};

export const updateMemberRole = async function (memberId, role) {
    return await Api.put("members/" + memberId + "/roles", {
        role,
    }).then((res) => res.data);
};

export const resetMemberPermissions = async function (memberId, permissionKey) {
    return await Api.delete(
        "members/" + memberId + "/permissions/" + permissionKey
    ).then((res) => res.data);
};

export const resetAllMemberPermissions = async function (memberId) {
    return await Api.delete("members/" + memberId + "/permissions").then(
        (res) => res.data
    );
};

export const fetchClientPermissions = async function ({ queryKey }) {
    const [, { ...params }] = queryKey;
    return await Api.get(
        "clients/" + params.clientId + "/permissions",
        params
    ).then((res) => res.data);
};

export const fetchClientUserPermissions = async function ({ queryKey }) {
    const [, { ...params }] = queryKey;
    return await Api.get(
        "clients/" +
            params.clientId +
            "/users/" +
            params.userId +
            "/permissions",
        params
    ).then((res) => res.data);
};

export const updateClientPermissions = async function (clientId, formData) {
    return await Api.post(
        "clients/" + clientId + "/permissions",
        formData
    ).then((res) => res.data);
};

export const updateClientUserPermissions = async function (
    clientId,
    userId,
    formData
) {
    return await Api.post(
        "clients/" + clientId + "/users/" + userId + "/permissions",
        formData
    ).then((res) => res.data);
};

export const updateClientRole = async function (clientId, role) {
    return await Api.put("clients/" + clientId + "/role", {
        role,
    }).then((res) => res.data);
};

export const resetAllClientPermissions = async function (clientId) {
    return await Api.delete("clients/" + clientId + "/permissions").then(
        (res) => res.data
    );
};

export const resetClientPermissions = async function (clientId, permissionKey) {
    return await Api.delete(
        "clients/" + clientId + "/permissions/" + permissionKey
    ).then((res) => res.data);
};

export const resetClientUserPermission = async function (
    clientId,
    userId,
    permissionKey
) {
    return await Api.delete(
        "clients/" +
            clientId +
            "/users/" +
            userId +
            "/permissions/" +
            permissionKey
    ).then((res) => res.data);
};

export const resetAllClientUserPermissions = async function (clientId, userId) {
    return await Api.delete(
        "clients/" + clientId + "/users/" + userId + "/permissions"
    ).then((res) => res.data);
};

export const fetchOrganizationPermissions = async function ({ queryKey }) {
    const [, { ...params }] = queryKey;
    return await Api.get(
        "organization/default-permissions?role=" + params.role,
        params
    ).then((res) => res.data);
};

export function getPermissionTypes(permissions) {
    if (Array.isArray(permissions)) {
        return [
            { value: "", label: "All" },
            ...Object.values(permissions)
                .map((permission) => {
                    return {
                        value: permission.type,
                        label: titleCase(permission.type),
                    };
                })
                .filter((value, index, self) => {
                    return (
                        self.findIndex(
                            (element) =>
                                JSON.stringify(element) ===
                                JSON.stringify(value)
                        ) === index
                    );
                }),
        ];
    } else {
        return [];
    }
}

export default {
    fetchMemberPermissions,
    updateMemberPermission,
};
