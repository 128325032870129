import React, { useState, useEffect, useReducer, useCallback } from "react";
import { useToasts } from "react-toast-notifications";
import Spinner from "../../components/Spinner";
import ClientPageHeader, { Heading } from "../../components/ClientPageHeader";
import Button from "../../components/Button";
import SaveButton, { showSave } from "../../components/SaveButton";
import { Route, Switch } from "react-router-dom";
import UnsavedChanges from "../../components/UnsavedChanges";
import PageContainer from "../../components/PageContainer";
import NotesReceivableForm from "./_partials/NotesReceivableForm";
import Api from "../../services/Api";
import VaultResourceFileList from "../resources/VaultResourceFileList";
import ResourceAccessUserList from "../resources/ResourceAccessUserList";
import InitialBeneficiary from "../../components/Beneficiary/InitialBeneficiary";
import VaultPhotosList from "../resources/VaultPhotosList";
import { AllOwnershipsForAsset } from "components/Ownership";
import NotesReceivableTabs from "./_partials/NotesReceivableTabs";
import ClientNotes from "../clients/Notes/ClientNotes";
import { useAsyncError } from "hooks/useAsyncError";
import TransfersTable from "views/funding_table/_partials/TransfersTable";

export default function EditNotesReceivable({
    client,
    readOnly = false,
    clientPath,
    ...props
}) {
    const throwError = useAsyncError();
    const { addToast } = useToasts();
    const [loading, setLoading] = useState(true);
    const [errors, setErrors] = useState([]);
    const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
    const [saving, setSaving] = useState(false);
    const [needsUpdate, setNeedsUpdate] = useState(0);

    const [notesReceivableId, setNotesReceivableId] = useState(
        props.match.params.notesReceivableId
    );

    const [input, setInput] = useReducer((state, newState) => ({
        ...state,
        ...newState,
    }));

    const title =
        `${readOnly ? "View" : "Edit"} Notes Receivable` +
        (input && !loading ? ` - ${input.debtor_name}` : "");

    useEffect(() => {
        let unlisten = props.history.listen(() => {
            if (hasUnsavedChanges) {
                setNeedsUpdate(needsUpdate + 1);
            }
        });
        return () => {
            unlisten();
        };
    }, [hasUnsavedChanges, needsUpdate, props.history]);

    useEffect(() => {
        document.title = title;
    }, [title]);

    useEffect(() => {
        setNotesReceivableId(props.match.params.notesReceivableId);
    }, [props.match.params.notesReceivableId]);

    // runs when items in array are updated
    useEffect(() => {
        const fetchData = async () => {
            try {
                // API Call
                setLoading(true);
                let response = await Api.get(
                    "clients/" +
                        client.id +
                        "/notes-receivables/" +
                        notesReceivableId
                );
                // Set Input with response
                setInput(response.data.data);
                setLoading(false);
                setHasUnsavedChanges(false);
            } catch (error) {
                if (error.response && error.response.status === 403) {
                    throwError(error);
                }
                setLoading(false);
                addToast(
                    error.response && error.response.data.errors
                        ? error.response.data.errors
                        : "Error fetching notes receivables",
                    { type: "error" }
                );
            }
        };
        fetchData();
    }, [client, notesReceivableId, addToast, needsUpdate]);

    const updateValue = useCallback((event) => {
        setInput({ [event.target.name]: event.target.value });
        setHasUnsavedChanges(true);
    }, []);

    async function update(e) {
        e.preventDefault();
        try {
            setErrors([]);
            setSaving(true);
            const body = { ...input };
            // get api response from update
            let response = await Api.put(
                "clients/" + client.id + "/notes-receivables/" + input.id,
                body
            );
            addToast(response.data.message);
            setErrors([]);
            setHasUnsavedChanges(false);
            setSaving(false);
        } catch (e) {
            setSaving(false);
            setErrors([]);
            if (e.response.data.errors) {
                setErrors(e.response.data.errors);
            } else {
                addToast(e.response.data.message, { type: "error" });
            }
        }
    }

    if (!input || loading) {
        return (
            <div className="h-screen w-full flex items-center justify-center">
                <Spinner />
            </div>
        );
    }

    const resourceType = "NotesReceivable";
    const basePath = `${clientPath}/notes-receivables/${input.id}/`;
    const currentBasePath = basePath + (readOnly ? "view" : "edit");
    const saveButtonVisible = showSave();

    const refreshData = () => {
        setNeedsUpdate((needsUpdate) => needsUpdate + 1);
    };

    return (
        <>
            <ClientPageHeader client={client}>
                <Heading
                    backTo={`${clientPath}/notes-receivables`}
                    backText="All Notes Receivables"
                >
                    {title}
                </Heading>
                {readOnly !== true && saveButtonVisible === true && (
                    <div className="flex-0">
                        <SaveButton isLoading={saving} onClick={update} />
                    </div>
                )}
                {readOnly !== false && saveButtonVisible === true && (
                    <div className="flex-0">
                        <Button
                            type="link"
                            to={window.location.pathname.replace(
                                "view",
                                "edit"
                            )}
                            text="Edit"
                        />
                    </div>
                )}
            </ClientPageHeader>
            <PageContainer>
                <UnsavedChanges when={hasUnsavedChanges} />
                <div className="flex space-x-10">
                    <div className="flex-initial">
                        <NotesReceivableTabs
                            basePath={currentBasePath}
                            {...props}
                        />
                    </div>
                    <div className="flex-1">
                        <Switch>
                            <Route
                                exact={true}
                                path={[basePath + "edit", basePath + "view"]}
                                render={() => (
                                    <div className="max-w-3xl mx-auto">
                                        <NotesReceivableForm
                                            client={client}
                                            input={input}
                                            readOnly={readOnly}
                                            errors={errors}
                                            updateValue={updateValue}
                                            onSubmit={update}
                                        />
                                    </div>
                                )}
                            />
                            <Route
                                exact={true}
                                path={[
                                    basePath + "edit/owners",
                                    basePath + "view/owners",
                                ]}
                                render={() => (
                                    <AllOwnershipsForAsset
                                        asset={input}
                                        client={client}
                                        refreshData={refreshData}
                                    />
                                )}
                            />
                            <Route
                                exact={true}
                                path={[
                                    basePath + "edit/beneficiaries",
                                    basePath + "view/beneficiaries",
                                ]}
                                render={() => (
                                    <InitialBeneficiary
                                        client={client}
                                        asset={input}
                                    />
                                )}
                            />
                            <Route
                                exact={true}
                                path={[
                                    basePath + "edit/notes",
                                    basePath + "view/notes",
                                ]}
                                render={() => (
                                    <>
                                        <Heading>Notes</Heading>
                                        <ClientNotes
                                            client={client}
                                            resourceType={resourceType}
                                            resourceId={input.id}
                                            {...props}
                                        />
                                    </>
                                )}
                            />
                            <Route
                                exact={true}
                                path={[
                                    basePath + "edit/transfers",
                                    basePath + "view/transfers",
                                ]}
                                render={() => (
                                    <TransfersTable
                                        assetId={input.id}
                                        assetType={`NotesReceivable`}
                                        client={client}
                                    />
                                )}
                            />
                            <Route
                                exact={true}
                                path={[
                                    basePath + "edit/related-files",
                                    basePath + "view/related-files",
                                ]}
                                render={() => (
                                    <VaultResourceFileList
                                        client={client}
                                        resourceType={resourceType}
                                        resourceId={input.id}
                                    />
                                )}
                            />
                            <Route
                                exact={true}
                                path={[
                                    basePath + "edit/related-photos",
                                    basePath + "view/related-photos",
                                ]}
                                render={() => (
                                    <VaultPhotosList
                                        client={client}
                                        resourceType={resourceType}
                                        resourceId={input.id}
                                    />
                                )}
                            />
                            <Route
                                exact={true}
                                path={[
                                    basePath + "edit/access",
                                    basePath + "view/access",
                                ]}
                                render={() => (
                                    <ResourceAccessUserList
                                        resourceType={resourceType}
                                        resourceId={input.id}
                                    />
                                )}
                            />
                        </Switch>
                    </div>
                </div>
            </PageContainer>
        </>
    );
}
