import React from "react";
import {
    faCheckCircle,
    faExclamationCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const CustomToast = ({
    type = "success",
    children,
    title,
    transitionState,
    action,
    actionTitle = "",
    transitionDuration,
    ...props
}) => {
    const transitions = {
        entering: `transform ease-out duration-${transitionDuration} transition translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2`,
        entered: `transform ease-out duration-${transitionDuration} transition translate-y-0 opacity-100 sm:translate-x-0`,
        exiting: `transition ease-in opacity-0 duration-${transitionDuration}`,
        exited: `transition ease-in opacity-0 duration-${transitionDuration}`,
    };

    const getIcon = () => {
        switch (type) {
            case "error":
                return (
                    <FontAwesomeIcon
                        icon={faExclamationCircle}
                        className="text-red-500 text-lg"
                    />
                );
            case "warning":
                return (
                    <FontAwesomeIcon
                        icon={faExclamationCircle}
                        className="text-orange-400 text-lg"
                    />
                );
            default:
                return (
                    <FontAwesomeIcon
                        icon={faCheckCircle}
                        className="text-green-400 text-lg"
                    />
                );
        }
    };

    const getTitle = () => {
        switch (type) {
            case "error":
                return title || "Error";
            case "warning":
                return title || "Warning";
            default:
                return title || "Success";
        }
    };

    const getAction = () => {
        if (action && actionTitle) {
            return (
                <div className="text-left">
                    <button
                        onClick={() => action()}
                        className="py-1 mt-1 text-sm underline hover:text-brand-500"
                    >
                        {actionTitle}
                    </button>
                </div>
            );
        }
        return <></>;
    };

    return (
        <div className="print:hidden fixed inset-0 flex items-end justify-center px-4 py-6 pointer-events-none sm:p-6 sm:items-start sm:justify-end">
            <div
                className={
                    transitions[transitionState] +
                    " max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto"
                }
            >
                <div className="rounded-lg shadow-xs overflow-hidden bg-white">
                    <div className="p-4">
                        <div className="flex items-start">
                            <div className="flex-shrink-0">{getIcon()}</div>
                            <div className="ml-3 w-0 flex-1 pt-0.5">
                                <p className="text-sm leading-5 font-medium text-gray-900">
                                    {getTitle()}
                                </p>
                                <p className="mt-1 text-sm leading-5 text-gray-500">
                                    {children}
                                </p>
                                {getAction()}
                            </div>
                            <div className="ml-4 flex-shrink-0 flex">
                                <button
                                    onClick={props.onDismiss}
                                    className="inline-flex text-gray-400 focus:outline-none focus:text-gray-500 transition ease-in-out duration-150"
                                >
                                    <svg
                                        className="h-5 w-5"
                                        viewBox="0 0 20 20"
                                        fill="currentColor"
                                    >
                                        <path
                                            fillRule="evenodd"
                                            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                            clipRule="evenodd"
                                        />
                                    </svg>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CustomToast;
