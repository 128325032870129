import { downloadFile } from "../util/Downloader";
import Api from "./Api";

export const fetchLiveWealth = async function ({ queryKey }) {
    const [, { ...params }] = queryKey;
    const { owner, current, client, include_transfer_owners } = params;
    return await Api.get("clients/" + client.id + "/reports/live-wealth", {
        owner,
        current,
        include_transfer_owners,
    }).then((res) => {
        const {
            assets,
            owner_totals,
            owner_summary,
            net_worth_total: netWorth,
            net_worth_with_death_benefit_total: netWorthWithDeathBenefit,
            asset_keys,
        } = res.data;

        res.data.assets = assets;
        res.data.ownerTotals = owner_totals;
        res.data.ownerSummary = owner_summary;
        res.data.assetKeys = asset_keys;
        res.data.totals = {
            netWorth,
            netWorthWithDeathBenefit,
        };
        res.data.sortKeys = Object.keys(assets);

        return res.data;
    });
};

export const fetchLiveWealthExport = async function (params) {
    const { owner, current, client, isPostMortem, showAltValues, person } =
        params;
    const response = await Api.get(
        "clients/" + client.id + "/reports/live-wealth",
        {
            owner,
            current,
            is_post_mortem: isPostMortem,
            show_alternate_valuation: showAltValues,
            person,
            export: true,
        },
        { responseType: "arraybuffer" }
    );
    await downloadFile(response, "live-wealth-export", { open: true });
    return {};
};
