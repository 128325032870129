import React, { useContext } from "react";
import ClientPageHeader, { Heading } from "../../components/ClientPageHeader";
import SaveButton from "../../components/SaveButton";
import PageContainer from "../../components/PageContainer";
import Modal from "../../components/Modal";
import AddNewRowItem from "./_partials/AddNewRowItem";
import UnsavedChanges from "../../components/UnsavedChanges";
import { BudgetContext } from "./BudgetContext";
import BudgetCategory from "./_partials/BudgetCategory";
import Spinner from "components/Spinner";
import useHasAnyPermissions from "hooks/useHasAnyPermissions";
import ClientNotes from "views/clients/Notes/ClientNotes";
import Button from "components/Button";
import { downloadFileAsLink } from "util/Downloader";

export default function BudgetEstimateForm({ client, ...props }) {
    document.title = "Budget Estimates";

    const canEnterBudgetValues = useHasAnyPermissions([
        "budget_value_entry",
        "client_budget_data_provision",
    ]);

    const canViewBudget = useHasAnyPermissions([
        "member_budget_viewing",
        "client_budget_viewing",
        "advisor_budget_item_viewing",
    ]);

    const {
        clientName,
        hasSpouse,
        spouseName,
        loading,
        budget,
        budgetClientItems,
        setBudgetClientItems,
        budgetCategories,
        options,
        loadingOptions,
        isSaving,
        hasUnsavedChanges,
        setHasUnsavedChanges,
        showModal,
        setShowModal,
        newItem,
        setNewItem,
        currentBudgetItem,
        setCurrentBudgetItem,
        update,
        isNegative,
    } = useContext(BudgetContext);

    if (!budgetClientItems) {
        return <></>;
    }

    return (
        <>
            <ClientPageHeader client={client}>
                <Heading>Budget Estimates</Heading>
                {canViewBudget && (
                    <div className="flex-initial flex space-x-3">
                        <Button
                            onClick={() =>
                                downloadFileAsLink(
                                    "clients/" +
                                        client.id +
                                        "/budgets?print=true"
                                )
                            }
                            text="Download"
                            appearance="light"
                        />
                        {canEnterBudgetValues && (
                            <SaveButton isLoading={isSaving} onClick={update} />
                        )}
                    </div>
                )}
            </ClientPageHeader>
            <PageContainer
                forbidden={!canViewBudget}
                isLoading={loading && loadingOptions}
            >
                <UnsavedChanges when={hasUnsavedChanges} />
                {loading && <Spinner />}

                {!loading && budgetCategories.length > 0 && (
                    <>
                        <div className="space-y-6">
                            <div>
                                {budgetCategories.map((budget) => (
                                    <BudgetCategory
                                        key={budget.name}
                                        budget={budget}
                                    />
                                ))}
                            </div>

                            {budget && (
                                <div>
                                    <Heading>Notes</Heading>
                                    <ClientNotes
                                        client={client}
                                        resourceType={"Budget"}
                                        resourceId={budget.id}
                                        autoFocus={false}
                                        {...props}
                                    />
                                </div>
                            )}
                        </div>

                        <Modal
                            isOpen={showModal}
                            backgroundDismiss={true}
                            title="Add Item"
                            size="2xl"
                            onCancel={() => {
                                setNewItem({});
                                setShowModal(false);
                            }}
                            component={() => (
                                <>
                                    <AddNewRowItem
                                        newItem={newItem}
                                        onSubmit={(addItem) => {
                                            setHasUnsavedChanges(true);
                                            currentBudgetItem.budget_category_items.push(
                                                {
                                                    id: addItem.id,
                                                    budget_category_id:
                                                        addItem.budget_category_id,
                                                    name: addItem.name,
                                                    frequency_id:
                                                        addItem.frequency_id,
                                                    client_value:
                                                        addItem.client_value,
                                                    spouse_value:
                                                        addItem.spouse_value,
                                                    joint_value:
                                                        addItem.joint_value,
                                                    sort_order:
                                                        addItem.sort_order,
                                                    has_other_value:
                                                        addItem.has_other_value,
                                                    has_joint_values:
                                                        addItem.has_joint_values,
                                                }
                                            );
                                            // update all the values:
                                            setBudgetClientItems({
                                                ...budgetClientItems,
                                                [addItem.id]: {
                                                    budget_category_item_id:
                                                        addItem.id,
                                                    frequency_id:
                                                        addItem.frequency_id,
                                                    client_value:
                                                        addItem.client_value,
                                                    spouse_value:
                                                        addItem.spouse_value,
                                                    joint_value:
                                                        addItem.joint_value,
                                                    other_value: "",
                                                    budget_category_id:
                                                        addItem.budget_category_id,
                                                    name: addItem.name,
                                                    sort_order:
                                                        addItem.sort_order,
                                                    has_other_value:
                                                        addItem.has_other_value,
                                                    has_joint_values:
                                                        addItem.has_joint_values,
                                                },
                                            });

                                            // close modal and reset values
                                            setCurrentBudgetItem(null);
                                            setShowModal(false);
                                        }}
                                        options={options}
                                        hasSpouse={hasSpouse}
                                        clientName={clientName}
                                        spouseName={spouseName}
                                        isNegative={isNegative}
                                    />
                                </>
                            )}
                        />
                    </>
                )}
            </PageContainer>
        </>
    );
}
