import React, { useState } from "react";
import TextInput from "../../../components/TextInput";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import Fieldset from "../../../components/Fieldset";
import PaymentDetails from "../_partials/PaymentDetails";

export default function CompanyContacts({
    companyContacts,
    setCompanyContacts,
}) {
    const company = {
        name: "",
        contact: "",
        address: "",
        service_address: "",
        phone: "",
        email: "",
        website: "",
        payment_frequency: "Monthly",
        payment_frequency_other: "",
    };

    const [companies, setCompanies] = useState(companyContacts || [company]);

    function appendCompany() {
        if (companies.length < 10) {
            if (companies.length) {
                company.service_address = companies[0].service_address;
            }
            setCompanies((companies) => [...companies, company]);
        }
    }

    function removeCompany(idx) {
        const newCompanies = companies.filter((_company, idxs) => idxs !== idx);
        setCompanies(() => newCompanies);
        setCompanyContacts({ ...companyContacts, ...newCompanies });
    }

    const updateCompany = (event, idx) => {
        if (
            event.target.name === "payment_frequency" &&
            event.target.value !== "Other"
        ) {
            companies[idx].payment_frequency_other = "";
            setCompanies(companies);
        }
        const newCompanies = companies.map((company, sidx) => {
            if (idx !== sidx) return company;
            return { ...company, [event.target.name]: event.target.value };
        });
        setCompanies(newCompanies);
        setCompanyContacts({ ...companyContacts, ...newCompanies });
    };

    return (
        <Fieldset
            legend="Other Important People/Companies To Be Contacted"
            description="Utility Companies &amp; Service Providers (Gas/Electric, Water/Sewer, Telephone, Cable, Lawn/Landscape, Housekeeper, Tutors, Etc.)"
        >
            <div className="mb-10 border-b border-gray-100 pb-6">
                <h2 className="mb-4 font-bold text-gray-500">Companies</h2>
                {companies.map((_company, idx) => (
                    <div className="mb-10 border-b border-gray-100" key={idx}>
                        <div className="flex space-x-3 mb-6">
                            <div className="flex-1">
                                <TextInput
                                    label="Name"
                                    name="name"
                                    value={companies[idx]["name"]}
                                    onChange={(e) => updateCompany(e, idx)}
                                    autoFocus={companies.length === idx + 1}
                                />
                            </div>
                            <div className="flex-1">
                                <TextInput
                                    label="Contact"
                                    name="contact"
                                    value={companies[idx]["contact"]}
                                    onChange={(e) => updateCompany(e, idx)}
                                />
                            </div>
                        </div>
                        <div className="mb-6">
                            <TextInput
                                label="Address"
                                name="address"
                                value={companies[idx]["address"]}
                                onChange={(e) => updateCompany(e, idx)}
                            />
                        </div>
                        <div className="mb-6">
                            <TextInput
                                id={`service_address_${idx}`}
                                label="Service Address"
                                name="service_address"
                                value={companies[idx]["service_address"]}
                                onChange={(e) => updateCompany(e, idx)}
                            />
                        </div>
                        <div className="flex space-x-3 mb-6">
                            <div className="flex-1">
                                <TextInput
                                    type="tel"
                                    label="Phone"
                                    name="phone"
                                    value={companies[idx]["phone"]}
                                    onChange={(e) => updateCompany(e, idx)}
                                />
                            </div>
                            <div className="flex-1">
                                <TextInput
                                    type="email"
                                    label="Email"
                                    name="email"
                                    value={companies[idx]["email"]}
                                    onChange={(e) => updateCompany(e, idx)}
                                />
                            </div>
                        </div>
                        <div className="mb-6">
                            <TextInput
                                label="Website"
                                name="website"
                                value={companies[idx]["website"]}
                                onChange={(e) => updateCompany(e, idx)}
                            />
                        </div>
                        <div className="flex space-x-3 items-start mb-6">
                            <PaymentDetails
                                data={companies}
                                idx={idx}
                                onchange={updateCompany}
                            />
                        </div>
                        <div className="flex justify-end mb-3">
                            <button
                                type="button"
                                onClick={() => removeCompany(idx)}
                                className="text-gray-500 text-sm hover:text-red-500"
                            >
                                <FontAwesomeIcon
                                    icon={faTimesCircle}
                                    className="mr-1"
                                />{" "}
                                Remove Company
                            </button>
                        </div>
                    </div>
                ))}
                <div className="flex">
                    <button
                        type="button"
                        onClick={(e) => appendCompany(e)}
                        className="text-gray-500 text-sm hover:text-brand"
                    >
                        <FontAwesomeIcon icon={faPlusCircle} className="mr-1" />{" "}
                        Add Company
                    </button>
                </div>
            </div>
        </Fieldset>
    );
}
