import React from "react";
import { Route, Switch } from "react-router-dom";
import TrusteesIndex from "./TrusteesIndex";
import AddTrustee from "./AddTrustee";
import EditTrustee from "./EditTrustee";

export default function Trustees(parentProps) {
    return (
        <Switch>
            <Route
                exact
                path={["/clients/:clientId/trustees", "/client/trustees"]}
                render={(props) => (
                    <TrusteesIndex {...parentProps} {...props} />
                )}
            />
            <Route
                exact
                path={[
                    "/clients/:clientId/trustees/add",
                    "/client/trustees/add",
                ]}
                render={(props) => <AddTrustee {...parentProps} {...props} />}
            />
            <Route
                path={[
                    "/clients/:clientId/trustees/:trusteeId/edit",
                    "/client/trustees/:trusteeId/edit",
                ]}
                render={(props) => <EditTrustee {...parentProps} {...props} />}
            />
            <Route
                path={[
                    "/clients/:clientId/trustees/:trusteeId/view",
                    "/client/trustees/:trusteeId/view",
                ]}
                render={(props) => (
                    <EditTrustee readOnly={true} {...parentProps} {...props} />
                )}
            />
        </Switch>
    );
}
