export const iconColorForVault = (vault) => {
    if (vault.type === "directory") {
        return "text-blue-300";
    } else if (["ppt", "pptx", "key"].indexOf(vault.extension) > -1) {
        return "text-orange-500";
    } else if (
        [
            "mov",
            "mp4",
            "m4v",
            "avi",
            "flv",
            "mpg",
            "mpeg",
            "wmv",
            "mkv",
            "heic",
        ].indexOf(vault.extension) > -1
    ) {
        return "text-purple-700";
    } else if (
        ["aaif", "aac", "mp3", "m4a", "m4b", "wav"].indexOf(vault.extension) >
        -1
    ) {
        return "text-blue-400";
    } else if (["zip", "tar.gz", "7z"].indexOf(vault.extension) > -1) {
        return "text-brown-500";
    } else if (["pdf", "rtf", "tex", "txt"].indexOf(vault.extension) > -1) {
        return "text-red-700";
    } else if (
        ["odt", "doc", "docx", "pages", "wpd"].indexOf(vault.extension) > -1
    ) {
        return "text-blue-700";
    } else if (
        [
            "xls",
            "xlsm",
            "xlsx",
            "numbers",
            "ods",
            "csv",
            "tsv",
            "sql",
            "tar",
            "xml",
        ].indexOf(vault.extension) > -1
    ) {
        return "text-green-600";
    }

    return "text-gray-300";
};
