import Api from "./Api";

export const fetchChanges = async function ({ queryKey }) {
    const [, { ...params }] = queryKey;
    return await Api.get("changes", params).then((res) => res.data);
};

export const fetchChange = async function ({ queryKey }) {
    const [, { ...params }] = queryKey;
    return await Api.get("changes/" + params.changeId).then((res) => res.data);
};

export default {
    fetchChanges,
    fetchChange,
};
