import React from "react";
import { Link, useLocation } from "react-router-dom";

export default function PageHeader({
    text,
    children = null,
    backTo = null,
    backText = null,
}) {
    return (
        <div className="h-20 w-full sticky top-0 z-50 flex justify-between items-center bg-white px-8 shadow-sm">
            <div>
                {backText && (
                    <Link
                        to={backTo}
                        className="text-gray-500 text-sm hover:text-gray-700"
                    >
                        {backText}
                    </Link>
                )}
                <h1 className="text-2xl text-gray-700 font-bold">{text}</h1>
            </div>
            {children && <div className="flex-initial">{children}</div>}
        </div>
    );
}

export function MemberHeader({ text, ...props }) {
    const location = useLocation();

    return (
        <div className="h-20 w-full sticky top-0 z-50 flex justify-between items-center bg-white px-8 shadow-sm">
            <div>
                {location?.state?.go_back ? (
                    <Link to={location?.state?.go_back}>Advisors</Link>
                ) : (
                    <Link
                        to="/members"
                        className="text-gray-500 text-sm hover:text-gray-700"
                    >
                        Members
                    </Link>
                )}
                <h1 className="text-2xl text-gray-700 font-bold">{text}</h1>
            </div>
            <div className="flex-initial">{props.children}</div>
        </div>
    );
}
