import React from "react";
import ClientPageHeader, { Heading } from "components/ClientPageHeader";
import PageContainer from "components/PageContainer";
import useClient from "hooks/useClient";
import InsurancePoliciesTabs from "./_partials/InsurancePoliciesTabs";

export default function InsurancePoliciesLayout({
    children,
    currentBasePath,
    buttons = null,
    title,
    ...props
}) {
    const { client } = useClient();

    return (
        <>
            <ClientPageHeader client={client}>
                <Heading
                    backTo={`/clients/${client.id}/insurance-policies`}
                    backText="All Policies"
                >
                    {title}
                </Heading>
                {buttons && <div className="flex-0">{buttons}</div>}
            </ClientPageHeader>
            <PageContainer>
                <div className="flex space-x-10">
                    <div className="flex-initial">
                        <InsurancePoliciesTabs
                            basePath={currentBasePath}
                            title={title}
                            {...props}
                        />
                    </div>
                    <div className="flex-1 flex-shrink-0 w-full">
                        {children}
                    </div>
                </div>
            </PageContainer>
        </>
    );
}
