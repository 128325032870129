import React from "react";

import VerifyTwoFactorForm from "views/_partials/VerifyTwoFactorForm";

export default function TwoFactorLoginForm({ authOptions, onVerify }) {
    return (
        <div className="flex flex-col justify-center items-center">
            <div className="w-3/5 flex flex-col justify-center items-center">
                <h1 className="text-center text-3xl mb-8">
                    Verify One-Time Code
                </h1>

                <div>
                    <VerifyTwoFactorForm
                        title=""
                        showCancel={false}
                        showRemember={true}
                        doneButtonTitle="Verify"
                        authOptions={authOptions}
                        onResult={onVerify}
                    />
                </div>
            </div>
        </div>
    );
}
