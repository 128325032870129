import React, { useEffect, useState } from "react";
import SelectInput from "../../../components/SelectInput";
import Api from "../../../services/Api";

export default function InsuranceSelect({ client, needsUpdated, ...props }) {
    const [loading, setLoading] = useState(true);
    const [insurancePolicies, setInsurancePolicies] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                let response = await Api.get(
                    "clients/" + client.id + "/other-insurance-policies"
                );
                let options = response.data.data.map((policy) => {
                    return {
                        value: policy.id,
                        label: policy.number
                            ? policy.type + ": " + policy.number
                            : policy.type,
                    };
                });
                options.splice(0, 0, { value: "", label: "None" });
                setInsurancePolicies(options);
                setLoading(false);
            } catch (error) {
                setLoading(false);
            }
        };
        fetchData();
    }, [client, needsUpdated]);

    return (
        <SelectInput options={insurancePolicies} loading={loading} {...props} />
    );
}
