import React, { useState, useEffect, useCallback } from "react";
import useClient from "../../../hooks/useClient";
import Api from "../../../services/Api";
import useIceContext from "../hooks/useIceContext";

import { useToasts } from "react-toast-notifications";
import {
    useFetchAvailableContacts,
    useFetchContacts,
} from "hooks/useFetchIceContacts";

export const IceContactsContext = React.createContext();

export default function IceContactsContextProvider({ children }) {
    const [availableContactsKeyed, setAvailableContactsKeyed] = useState({});

    const { isSpouse } = useIceContext();
    const { addToast } = useToasts();
    const { client } = useClient();

    const {
        data: contacts,
        isLoading: isLoadingContacts,
        remove: removeContacts,
        refetch: refetchContacts,
    } = useFetchContacts({
        clientId: client.id,
        isSpouse,
    });

    const {
        data: availableContacts,
        isLoading: isLoadingAvailable,
        remove: removeAvailable,
        refetch: refetchAvailable,
    } = useFetchAvailableContacts({
        clientId: client.id,
        isSpouse,
    });

    const refreshContacts = () => {
        removeContacts();
        refetchContacts();
    };

    const refreshAvailable = () => {
        removeAvailable();
        refetchAvailable();
    };

    useEffect(() => {
        const keyed = {};
        (availableContacts || []).forEach((c) => (keyed[c.key] = c));
        setAvailableContactsKeyed(keyed);
    }, [availableContacts]);

    useEffect(() => {
        refreshAvailable();
    }, [isSpouse]);

    const addIceContact = useCallback(
        async (contact) => {
            try {
                await Api.post("clients/" + client.id + "/ice/contacts", {
                    isSpouse,
                    contact_type: contact.type,
                    contact_id: contact.id,
                });
            } catch (err) {
                addToast(err.response.data.message, { type: "error" });
            }
            refreshContacts();
        },
        [client, isSpouse, addToast]
    );

    const removeIceContact = useCallback(
        async (iceContact) => {
            await Api.delete(
                "clients/" + client.id + "/ice/contacts/" + iceContact.id,
                {
                    isSpouse,
                }
            );
            refreshContacts();
        },
        [client, isSpouse]
    );

    const updateIceContact = useCallback(
        async ({ order, id }) => {
            await Api.put("clients/" + client.id + "/ice/contacts/" + id, {
                order,
            });
            refreshContacts();
        },
        [client]
    );

    return (
        <IceContactsContext.Provider
            value={{
                isLoading: isLoadingContacts || isLoadingAvailable,
                contacts,
                availableContacts,
                availableContactsKeyed,
                addIceContact,
                removeIceContact,
                updateIceContact,
            }}
        >
            {children}
        </IceContactsContext.Provider>
    );
}
