import "core-js";
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import "react-tippy/dist/tippy.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { ToastProvider } from "react-toast-notifications";
import Alert from "./components/Alert";
import { QueryClient, QueryClientProvider } from "react-query";
import * as Sentry from "@sentry/browser";
import ErrorBoundary from "./util/ErrorBoundary";
import AuthContextProvider from "./contexts/AuthContext";

const queryClient = new QueryClient();

// Sentry Setup
const sentryConfig = {
    dsn: "https://5b171395595d45a897c9467ab1de315e@errors.reusser.io/3",
    environment: process.env.REACT_APP_ENV,
    release: process.env.REACT_APP_SENTRY_RELEASE,
    beforeSend(event) {
        if (
            event.exception.values.length &&
            event.exception.values[0].value.indexOf(
                "Request failed with status code"
            ) > -1
        ) {
            return null;
        }
        console.log(event);
        return event;
    },
};

if (process.env.REACT_APP_ENV === "local" || !process.env.REACT_APP_ENV) {
    sentryConfig.dsn = null;
}

Sentry.init(sentryConfig);

// App
ReactDOM.render(
    <ToastProvider
        components={{ Toast: Alert }}
        autoDismiss
        autoDismissTimeout={6000}
        transitionDuration={100}
    >
        <ErrorBoundary>
            <QueryClientProvider client={queryClient}>
                <AuthContextProvider>
                    <App />
                </AuthContextProvider>
            </QueryClientProvider>
        </ErrorBoundary>
    </ToastProvider>,
    document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

// Helper functions
export function isBool(value) {
    switch (value) {
        case true:
        case "true":
        case 1:
        case "1":
        case "on":
        case "yes":
            return true;
        default:
            return false;
    }
}
