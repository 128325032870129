import Api from "./Api";
import { downloadFile } from "../util/Downloader";

export const fetchAllBeneficiaries = async function ({ queryKey }) {
    const [, { ...params }] = queryKey;
    return await Api.get(
        "clients/" + params.clientId + "/beneficiaries/all",
        params
    ).then((res) => res.data);
};

export const fetchBeneficiaryReportExport = async function (params) {
    const { client, showClaimsForms, is_post_mortem, person, level } = params;

    const response = await Api.get(
        "clients/" + client.id + "/reports/beneficiary-transfers",
        {
            export: true,
            showClaimsForms,
            person,
            is_post_mortem,
            level,
        },
        { responseType: "arraybuffer" }
    );
    await downloadFile(response, "beneficiary-report-claims", { open: true });
    return {};
};

export default {
    fetchAllBeneficiaries,
};
