import React from "react";
import PropTypes from "prop-types";

const InputErrors = ({ errors = [] }) => {
    if (errors && errors.length) {
        return (
            <ul className="errorList">
                {errors.map((value) => {
                    return (
                        <li
                            className="text-red-600 mb-1 block font-light italic text-sm"
                            key={value}
                        >
                            {value}
                        </li>
                    );
                })}
            </ul>
        );
    } else {
        return <></>;
    }
};

InputErrors.propTypes = {
    errors: PropTypes.array,
};

export default InputErrors;
