import React from "react";
import IceCardContextProvider from "./contexts/IceCardContext";

import IceContextProvider from "./contexts/IceContext";
import IceWrapper from "./ICEWrapper";

export default function ICE({ ...props }) {
    return (
        <IceContextProvider>
            <IceCardContextProvider>
                <IceWrapper {...props} />
            </IceCardContextProvider>
        </IceContextProvider>
    );
}
