import React from "react";
import PropTypes from "prop-types";
import Button from "./Button";

const SaveButton = ({ ...props }) => {
    if (props.readOnly) {
        return (
            <Button
                type="link"
                to={window.location.pathname.replace("view", "edit")}
                {...props}
                text="Edit"
            />
        );
    }
    return <Button {...props} text="Save" />;
};

SaveButton.propTypes = {
    text: PropTypes.string,
    type: PropTypes.string,
    disabled: PropTypes.bool,
    handleClick: PropTypes.func,
    appearance: PropTypes.string,
};

const defaultProps = {
    hideRoutes: [
        "/beneficiaries",
        "/owners",
        "/notes",
        "/related-files",
        "/related-photos",
        "/permissions",
        "/access",
    ],
};

const showSave = (
    { hideRoutes = defaultProps.hideRoutes } = { ...defaultProps }
) => {
    const route = window.location.pathname;
    let show = true;
    if (hideRoutes.length) {
        show = hideRoutes.filter((r) => route.endsWith(r)).length === 0;
    }
    return show;
};

export { showSave };

export default SaveButton;
