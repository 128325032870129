import React, { useReducer, useState } from "react";

import Button from "../../../components/Button";
import Dropdown, { DropdownItem } from "../../../components/Dropdown";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SaveButton from "../../../components/SaveButton";
import TextArea from "../../../components/TextArea";
import {
    faEllipsisH,
    faEdit,
    faTrash,
    faSitemap,
    faLock,
} from "@fortawesome/free-solid-svg-icons";
import { formatDate, titleCase } from "../../../util/TextUtil";
import Api from "../../../services/Api";
import { useToasts } from "react-toast-notifications";
import Checkbox from "../../../components/Checkbox";
import Spinner from "../../../components/Spinner";
import ResourceAccessUserList from "../../resources/ResourceAccessUserList";
import BasicModal from "../../../components/ModalBasic";

export default function Note({
    note,
    client,
    auth,
    showRelations = false,
    showRelatedResources = true,
    deleteNote,
    updateNote,
    refetch,
    processing,
}) {
    const { addToast } = useToasts();
    const [editNote, setEditNote] = useState(false);
    const [editResources, setEditResources] = useState(false);
    const [showAuthorizedList, setShowAuthorizedList] = useState(false);
    const [confirmDelete, setConfirmDelete] = useState(false);
    const [noteInput, setNoteInput] = useReducer(
        (state, newState) => ({ ...state, ...newState }),
        {
            content: note.content,
            for_office_use_only: note.for_office_use_only,
            is_post_mortem: note.is_post_mortem,
        }
    );
    const [clientResources, setClientResources] = useReducer(
        (state, newState) => ({ ...state, ...newState }),
        null
    );
    const [resourcesLoading, setResourcesLoading] = useState(false);
    const [resourcesSaving, setResourcesSaving] = useState(false);

    const canEditNote =
        auth.user.id === note.user_id || auth.user.type === "member";

    async function fetchResources() {
        setResourcesLoading(true);
        try {
            let response = await Api.get("clients/" + client.id + "/resources");
            let resources = response.data.data;
            setClientResources({ clients: [client] });
            if (client.spouse) {
                setClientResources({ spouses: [client.spouse] });
            }
            for (const [key, values] of Object.entries(resources)) {
                if (key === "relationships" && client.spouse) {
                    setClientResources({
                        [key]: values.filter(
                            (value) => value.id !== client.spouse.id
                        ),
                    });
                } else {
                    setClientResources({ [key]: values });
                }
            }
            setResourcesLoading(false);
        } catch (e) {
            addToast(e.response.data.message, { type: "error" });
            setResourcesLoading(false);
        }
    }

    async function updateNoteResources(model, id) {
        setResourcesSaving(true);
        try {
            let response = await Api.put(
                "clients/" + client.id + "/notes/" + note.id + "/resource",
                {
                    resourceType: model,
                    resourceId: id,
                }
            );
            addToast(response.data.message);
            setResourcesSaving(false);
            refetch();
        } catch (e) {
            addToast(e.response.data.message, { type: "error" });
            setResourcesSaving(false);
        }
    }

    function updateValue(event) {
        setNoteInput({ [event.target.name]: event.target.value });
    }

    const accordionGroup = (title, jsKey, model, titleKey) => {
        if (clientResources[jsKey] === undefined || note[jsKey] === undefined) {
            return;
        }

        let noteModels = note[jsKey] ?? null;
        let alreadyAssociatedIds = [];
        if (noteModels) {
            alreadyAssociatedIds = noteModels.map((model) => {
                return model.id;
            });
        }

        return (
            <div>
                {title && (
                    <h3 className="font-medium text-sm text-gray-800">
                        {title}
                    </h3>
                )}
                <div>
                    {clientResources[jsKey].map((item) => (
                        <div key={item.id}>
                            <Checkbox
                                type="checkbox"
                                name={model}
                                id={`${jsKey}_${item.id}`}
                                defaultChecked={alreadyAssociatedIds.includes(
                                    item.id
                                )}
                                onChange={(e) =>
                                    updateNoteResources(e.target.name, item.id)
                                }
                                label={item[titleKey]}
                                disabled={resourcesSaving}
                            />
                        </div>
                    ))}
                </div>
            </div>
        );
    };

    const avatar = (user) => {
        let initials = "";
        if (user) {
            initials =
                user.first_name.substring(0, 1) +
                user.last_name.substring(0, 1);
        }
        return (
            <>
                <div className="flex">
                    <div className="flex justify-center items-center h-10 w-10 -ml-1 bg-brand rounded-full flex-shrink-0 flex-grow-0">
                        <span className="text-lg text-white">{initials}</span>
                    </div>
                    <div className="ml-3">
                        <div>{user ? user.full_name : "Anonymous"}</div>
                        <div className="text-xs text-gray-800">
                            {user.formatted_role}
                        </div>
                    </div>
                </div>
            </>
        );
    };

    let noteClass = "bg-white shadow rounded";
    if (confirmDelete) {
        noteClass += " shadow-outline-red";
    }

    return (
        <>
            <div className={noteClass}>
                {confirmDelete && (
                    <div className="bg-gray-50 px-4 py-4">
                        <div className="flex items-center justify-between">
                            <div className="text-md leading-6 font-medium text-gray-900">
                                Delete this note?
                            </div>
                            <div className="flex items-center space-x-3">
                                <Button
                                    text="Delete"
                                    type="button"
                                    appearance="danger"
                                    height="h-10"
                                    isLoading={processing}
                                    onClick={() => deleteNote(note)}
                                />
                                <Button
                                    text="Cancel"
                                    type="button"
                                    appearance="light"
                                    height="h-10"
                                    disabled={processing}
                                    onClick={() => setConfirmDelete(false)}
                                />
                            </div>
                        </div>
                    </div>
                )}
                {editResources && (
                    <>
                        <div className="bg-gray-50 px-4 py-4">
                            <div className="flex items-center justify-between">
                                <div className="text-md leading-6 font-medium text-gray-900">
                                    Edit Links
                                </div>
                                <div className="flex items-center space-x-3">
                                    <Button
                                        text="Done"
                                        type="button"
                                        appearance="default"
                                        height="h-10"
                                        disabled={processing}
                                        onClick={() => setEditResources(false)}
                                    />
                                </div>
                            </div>
                        </div>
                        <div
                            className="pb-2 overflow-y-auto divide-y divide-gray-100 border-b border-gray-100"
                            style={{
                                maxHeight: "75vh",
                            }}
                        >
                            {resourcesLoading && <Spinner />}
                            {!resourcesLoading && (
                                <>
                                    <div className="px-4 py-2 space-y-4">
                                        <div>
                                            {clientResources &&
                                                clientResources.clients &&
                                                clientResources.clients.length >
                                                    0 &&
                                                accordionGroup(
                                                    "",
                                                    "clients",
                                                    "Client",
                                                    "full_name"
                                                )}
                                            {clientResources &&
                                                clientResources.spouses &&
                                                clientResources.spouses.length >
                                                    0 &&
                                                accordionGroup(
                                                    "",
                                                    "spouses",
                                                    "Relationship",
                                                    "full_name"
                                                )}
                                        </div>
                                        {clientResources &&
                                            clientResources.trusts &&
                                            clientResources.trusts.length > 0 &&
                                            accordionGroup(
                                                "Trusts",
                                                "trusts",
                                                "Trust",
                                                "name"
                                            )}
                                        {clientResources &&
                                            clientResources.relationships &&
                                            clientResources.relationships
                                                .length > 0 &&
                                            accordionGroup(
                                                "Relationships",
                                                "relationships",
                                                "Relationship",
                                                "full_name_with_relationship"
                                            )}
                                    </div>
                                    <div className="px-4 py-2 space-y-4">
                                        <h2 className="mb-2 font-medium text-md text-gray-800">
                                            Assets
                                        </h2>
                                        {clientResources &&
                                            clientResources.annuities &&
                                            clientResources.annuities.length >
                                                0 &&
                                            accordionGroup(
                                                "Annuities",
                                                "annuities",
                                                "Annuity",
                                                "name"
                                            )}
                                        {clientResources &&
                                            clientResources.business_interests &&
                                            clientResources.business_interests
                                                .length > 0 &&
                                            accordionGroup(
                                                "Business Interests",
                                                "business_interests",
                                                "BusinessInterest",
                                                "name"
                                            )}
                                        {clientResources &&
                                            clientResources.financial_accounts &&
                                            clientResources.financial_accounts
                                                .length > 0 &&
                                            accordionGroup(
                                                "Financial Accounts",
                                                "financial_accounts",
                                                "FinancialAccount",
                                                "name"
                                            )}
                                        {clientResources &&
                                            clientResources.retirement_accounts &&
                                            clientResources.retirement_accounts
                                                .length > 0 &&
                                            accordionGroup(
                                                "Retirement Accounts",
                                                "retirement_accounts",
                                                "RetirementAccount",
                                                "name"
                                            )}
                                        {clientResources &&
                                            clientResources.insurance_policies &&
                                            clientResources.insurance_policies
                                                .length > 0 &&
                                            accordionGroup(
                                                "Insurance Policies",
                                                "insurance_policies",
                                                "InsurancePolicy",
                                                "name"
                                            )}
                                        {clientResources &&
                                            clientResources.leases &&
                                            clientResources.leases.length > 0 &&
                                            accordionGroup(
                                                "Leases",
                                                "leases",
                                                "Lease",
                                                "description"
                                            )}
                                        {clientResources &&
                                            clientResources.personal_properties &&
                                            clientResources.personal_properties
                                                .length > 0 &&
                                            accordionGroup(
                                                "Personal Property",
                                                "personal_properties",
                                                "PersonalProperty",
                                                "name"
                                            )}
                                        {clientResources &&
                                            clientResources.memorandum_of_personal_properties &&
                                            clientResources
                                                .memorandum_of_personal_properties
                                                .length > 0 &&
                                            accordionGroup(
                                                "Memorandum of Personal Properties",
                                                "memorandum_of_personal_properties",
                                                "MemorandumOrPersonalProperty",
                                                "name"
                                            )}
                                        {clientResources &&
                                            clientResources.real_estate &&
                                            clientResources.real_estate.length >
                                                0 &&
                                            accordionGroup(
                                                "Real Estate",
                                                "real_estate",
                                                "RealEstate",
                                                "property_name"
                                            )}
                                    </div>

                                    <div className="px-4 py-2 space-y-4">
                                        <h2 className="mb-2 font-medium text-md text-gray-800">
                                            Financial
                                        </h2>
                                        {clientResources &&
                                            clientResources.income_sources &&
                                            clientResources.income_sources
                                                .length > 0 &&
                                            accordionGroup(
                                                "Income Sources",
                                                "income_sources",
                                                "Relationship",
                                                "type"
                                            )}
                                        {clientResources &&
                                            clientResources.other_insurance_policies &&
                                            clientResources
                                                .other_insurance_policies
                                                .length > 0 &&
                                            accordionGroup(
                                                "Other Insurance Policies",
                                                "other_insurance_policies",
                                                "OtherInsurancePolicy",
                                                "number"
                                            )}
                                        {clientResources &&
                                            clientResources.disability_policies &&
                                            clientResources.disability_policies
                                                .length > 0 &&
                                            accordionGroup(
                                                "Disability Policies",
                                                "disability_policies",
                                                "DisabilityPolicies",
                                                "type"
                                            )}
                                    </div>

                                    <div className="px-4 py-2 space-y-4">
                                        <h2 className="mb-2 font-medium text-md text-gray-800">
                                            Liabilities
                                        </h2>
                                        {clientResources &&
                                            clientResources.non_mortgage_liabilities &&
                                            clientResources
                                                .non_mortgage_liabilities
                                                .length > 0 &&
                                            accordionGroup(
                                                "Non-Mortgage Liabilities",
                                                "non_mortgage_liabilities",
                                                "NonMortgageLiability",
                                                "name"
                                            )}
                                        {clientResources &&
                                            clientResources.notes_receivables &&
                                            clientResources.notes_receivables
                                                .length > 0 &&
                                            accordionGroup(
                                                "Notes Receivables",
                                                "notes_receivables",
                                                "NotesReceivable",
                                                "debtor_name"
                                            )}
                                    </div>
                                </>
                            )}
                        </div>
                    </>
                )}
                {editNote && (
                    <>
                        <div className="bg-gray-50 px-4 py-4">
                            <div className="flex items-center justify-between">
                                <div className="text-md leading-6 font-medium text-gray-900">
                                    Edit Note
                                </div>
                                <div className="flex items-center space-x-3">
                                    <Button
                                        text="Cancel"
                                        type="button"
                                        appearance="light"
                                        height="h-10"
                                        disabled={processing}
                                        onClick={() => setEditNote(false)}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="bg-white shadow rounded">
                            <div className="p-4">
                                <TextArea
                                    rows="2"
                                    onChange={(e) => updateValue(e)}
                                    name="content"
                                    disabled={processing}
                                    value={noteInput.content}
                                />
                                <div className="mt-3 w-48">
                                    {auth.user.type === "member" && (
                                        <div className="my-2">
                                            <Checkbox
                                                id="for_office_use_only"
                                                name="for_office_use_only"
                                                onChange={(e) => updateValue(e)}
                                                label="For office use only"
                                                defaultChecked={
                                                    note.for_office_use_only
                                                }
                                            />
                                        </div>
                                    )}
                                    <SaveButton
                                        type="button"
                                        onClick={() => {
                                            updateNote(note, noteInput).then(
                                                () => {
                                                    setEditNote(false);
                                                }
                                            );
                                        }}
                                        isLoading={processing}
                                        disabled={
                                            noteInput.content.length < 1 ||
                                            (noteInput.content ===
                                                note.content &&
                                                noteInput.for_office_use_only ===
                                                    note.for_office_use_only &&
                                                note.is_post_mortem ===
                                                    noteInput.is_post_mortem)
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                    </>
                )}
                {!editNote && (
                    <>
                        <div className="p-4 flex justify-between items-center border-b border-gray-100">
                            <div className="text-gray-700">
                                {avatar(note.author)}
                                {note.for_office_use_only && (
                                    <div className="mt-2">
                                        <div className="inline-flex items-center justify-center px-2.5 py-0.5 rounded-full text-xs font-medium leading-4 bg-green-100 text-green-800">
                                            Office Use Only
                                        </div>
                                    </div>
                                )}
                                {note.is_post_mortem && (
                                    <div className="mt-2">
                                        <div className="inline-flex items-center justify-center px-2.5 py-0.5 rounded-full text-xs font-medium leading-4 bg-yellow-100 text-yellow-800">
                                            Estate Administration -{" "}
                                            {titleCase(note.person)}
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div className="flex text-sm items-center space-x-3 font-normal text-gray-600">
                                <div>{formatDate(note.created_at)}</div>
                                {(auth.user.type === "member" ||
                                    auth.user.id === note.client_id) && (
                                    <Dropdown
                                        position="right"
                                        toggleContent={
                                            <div className="px-3 rounded">
                                                <FontAwesomeIcon
                                                    icon={faEllipsisH}
                                                />
                                            </div>
                                        }
                                    >
                                        {canEditNote && (
                                            <DropdownItem
                                                label="Edit"
                                                icon={faEdit}
                                                handleClick={() => {
                                                    setEditNote(true);
                                                }}
                                            />
                                        )}
                                        {canEditNote && showRelations && (
                                            <DropdownItem
                                                label="Edit Links"
                                                icon={faSitemap}
                                                handleClick={() => {
                                                    setEditResources(true);
                                                    fetchResources();
                                                }}
                                            />
                                        )}
                                        {(auth.user.type === "member" ||
                                            auth.user.id ===
                                                note.client_id) && (
                                            <DropdownItem
                                                label="Access"
                                                icon={faLock}
                                                handleClick={() => {
                                                    setShowAuthorizedList(true);
                                                }}
                                            />
                                        )}
                                        {canEditNote && (
                                            <DropdownItem
                                                label="Delete"
                                                icon={faTrash}
                                                handleClick={() =>
                                                    setConfirmDelete(true)
                                                }
                                            />
                                        )}
                                    </Dropdown>
                                )}
                            </div>
                        </div>
                        <div className="p-4 text-md whitespace-pre-line">
                            <p className="break-words">{note.content}</p>
                        </div>
                    </>
                )}
                {showRelatedResources && (
                    <div className="bg-gray-50 px-4 py-4 text-xs text-gray-900">
                        <div className="font-medium">
                            Related Resource
                            {note.relatedResources.length >= 1 ? "s" : ""}:
                        </div>
                        <ul>
                            {note.relatedResources.map((resource) => (
                                <li key={`${resource.class}-{${resource.id}}`}>
                                    {resource.resource_name}
                                </li>
                            ))}
                        </ul>
                    </div>
                )}
                <BasicModal
                    isOpen={showAuthorizedList}
                    size="3xl"
                    onClose={() => setShowAuthorizedList(false)}
                    overFlow="overflow-normal"
                >
                    <ResourceAccessUserList
                        resourceType="ClientNote"
                        resourceId={note.id}
                        operations={["read"]}
                        viewOnly={
                            auth.user.type !== "member" &&
                            note.user_id !== auth.user.id
                        }
                    />
                </BasicModal>
            </div>
        </>
    );
}
