import useAuth from "hooks/useAuth";
import React from "react";
import VerticalTabs from "../../../components/VerticalTabs";

export default function ICETabs({ basePath, ...props }) {
    let items = [
        {
            label: "Cards",
            to: basePath + "",
        },
        {
            label: "Contacts",
            to: basePath + "/contacts",
        },
        {
            label: "Vault",
            to: basePath + "/vault",
        },
        {
            label: "Medical History",
            to: basePath + "/medical",
        },
    ];

    const { user } = useAuth();
    if (user.type !== "member") {
        delete items[0];
    }

    return <VerticalTabs {...props} items={items} />;
}
