import React from "react";
import { Route, Switch } from "react-router-dom";
import EditClientQuestionnaire from "./EditClientQuestionnaire";
import ClientQuestionnairesIndex from "./ClientQuestionnairesIndex";

export default function ClientQuestionnaires(parentProps) {
    return (
        <Switch>
            <Route
                exact
                path={[
                    "/clients/:clientId/questionnaires",
                    "/client/questionnaires",
                ]}
                render={(props) => (
                    <ClientQuestionnairesIndex {...parentProps} {...props} />
                )}
            />
            <Route
                exact
                path={[
                    "/clients/:clientId/questionnaires/:questionnaireId/edit",
                    "/clients/:clientId/questionnaires/:questionnaireId/view",
                    "/client/questionnaires/:questionnaireId/edit",
                    "/client/questionnaires/:questionnaireId/view",
                ]}
                render={(props) => (
                    <EditClientQuestionnaire {...parentProps} {...props} />
                )}
            />
        </Switch>
    );
}
