// Used for displaying a single asset on the Estate Inventory screens.

import React, { useState } from "react";
import Panel from "../Panel";
import { titleCase } from "../../util/TextUtil";
import Money from "../Money";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";
import Spinner from "../Spinner";
import {
    beneficiaryLevels,
    BeneficiaryLineItem,
} from "../Beneficiary/BeneficiaryList";
import useAssetValues from "views/funding_table/hooks/useAssetValues";
import { formatDate } from "util/TextUtil";

export default function AssetDisplayForBeneficiaryPreview({ client, asset }) {
    const [beneficiaryLevel] = useState(1);
    const beneficiaries = asset.currentBeneficiaries;

    const dateVerified = formatDate(asset.verified_at, false);

    const { asOfDate, asOfDateLabel, value, valueLabel } = useAssetValues({
        asset,
    });

    if (!asset) {
        return <Spinner message="Loading Beneficiaries..." />;
    }

    const filtered = beneficiaries.filter((b) => b.level == beneficiaryLevel);

    const levelName = beneficiaryLevels.filter(
        (l) => l.value === beneficiaryLevel
    )[0].label;

    return (
        <Panel key={`${asset.type}_${asset.id}`}>
            <div className="bg-white flex items-center">
                <div className="flex-1 flex flex-row space-x-6 mr-10">
                    <div className="flex flex-col">
                        <div className={`text-xs text-gray-500`}>
                            {titleCase(asset.type, {
                                pascal: true,
                            })}
                        </div>
                        <h2 className="text-sm">
                            <div className="flex flex-col items-start">
                                <a
                                    href={asset.resource_path}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="hover:text-brand-500 text-gray-600"
                                >
                                    <span className="font-bold text-base">
                                        {asset.resource_display_name}
                                    </span>
                                    &nbsp;
                                    <FontAwesomeIcon
                                        icon={faExternalLinkAlt}
                                        className="pb-1"
                                    />
                                </a>
                            </div>
                            {asset.account_number && (
                                <span className="text-xs text-gray-500 block mt-1">
                                    {asset.account_number}
                                </span>
                            )}
                        </h2>
                    </div>
                    <div className="flex-none text-left">
                        <div className={`text-xs text-gray-500`}>
                            {valueLabel}
                        </div>
                        <div className="text-sm tabular-nums">
                            {asset.is_liability ? (
                                <span className="text-red-500">
                                    <Money value={value} />
                                </span>
                            ) : (
                                <Money value={value} />
                            )}
                        </div>
                    </div>
                    <div className="flex-none ml-8">
                        <div className={`text-xs text-gray-500`}>
                            {asOfDateLabel}
                        </div>
                        <div className="text-sm">{asOfDate}</div>
                    </div>
                    <div className="ml-10 flex-none text-left">
                        {dateVerified && (
                            <>
                                <div className={`text-xs text-gray-500`}>
                                    Verified
                                </div>
                                <div className="text-sm">{dateVerified}</div>
                            </>
                        )}
                    </div>
                </div>
            </div>
            {asset.has_lien && parseInt(asset.liability_amount) > 0 && (
                <div className="border-t pt-3 mt-2 border-gray-200 text-gray-600">
                    <div className="flex items-center space-x-1 text-sm">
                        <span>Net Equity:</span>
                        <span>{<Money value={asset.gross_value} />}</span>
                        <span>-</span>
                        <span className="text-red-500">
                            {<Money value={asset.liability_amount} />}
                        </span>
                        <span>=</span>
                        <span>
                            <Money value={asset.net_value} />
                        </span>
                    </div>
                </div>
            )}
            {filtered.length === 0 && (
                <div className="mt-3 pt-3 border-t border-gray-200">
                    No {levelName} Beneficiary Designations
                </div>
            )}
            {filtered.length > 0 && (
                <div className="space-y-4 mt-3 pt-3 border-t border-gray-200">
                    {filtered.map((beneficiary, idx) => (
                        <div
                            className="flex space-x-3 items-end"
                            key={`beneficiary_${beneficiary.id}`}
                        >
                            <div className="flex-1">
                                <BeneficiaryLineItem
                                    client={client}
                                    beneficiary={beneficiary}
                                    showLevel={false}
                                    idx={idx}
                                    key={idx}
                                    showLabels={idx === 0}
                                    disabled={true}
                                    errors={[]}
                                    required={false}
                                />
                            </div>
                        </div>
                    ))}
                </div>
            )}
        </Panel>
    );
}
