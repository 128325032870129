import React, { useState, useEffect } from "react";
import Api from "../services/Api";
import SelectInput from "./SelectInput";

export default function PersonalPropertySelect({ client, ...props }) {
    const [loading, setLoading] = useState(true);
    const [personalProperties, setPersonalProperties] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                let response = await Api.get(
                    "clients/" + client.id + "/personal-properties"
                );
                let options = response.data.data.map((property) => {
                    return {
                        value: property.id,
                        label: property.name,
                    };
                });
                options.splice(0, 0, { value: "", label: "None" });
                setPersonalProperties(options);
                setLoading(false);
            } catch (error) {
                setLoading(false);
            }
        };
        fetchData();
    }, [client]);

    return (
        <SelectInput
            options={personalProperties}
            loading={loading}
            {...props}
        />
    );
}
