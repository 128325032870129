import React, { useContext, useState, useEffect } from "react";
import { useToasts } from "react-toast-notifications";
import { AuthContext } from "../../../contexts/AuthContext";
import Spinner from "../../../components/Spinner";
import TableSortHeader from "../../../components/TableSortHeader";
import Api from "../../../services/Api";
import SearchInput from "../../../components/SearchInput";
import useDebounce from "../../../hooks/useDebounce";
import Dropdown, { DropdownItem } from "../../../components/Dropdown";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faTrash,
    faEdit,
    faEllipsisH,
    faExternalLinkAlt,
} from "@fortawesome/free-solid-svg-icons";
import VaultResourceFileList from "../../resources/VaultResourceFileList";
import Forbidden from "../../../components/Forbidden";
import { Link } from "react-router-dom";
import Panel from "../../../components/Panel";
import { formatDate } from "util/TextUtil";

export default function PersonalPropertyMemoTable({
    client,
    clientPath,
    spouse = false,
    setIsPrintEnabled,
}) {
    const auth = useContext(AuthContext);
    const userType = auth.user.type;
    const { addToast } = useToasts();

    const [personalPropertyMemos, setPersonalPropertyMemos] = useState([]);
    const [loading, setLoading] = useState(true);
    const [notAuthorized, setNotAuthorized] = useState(false);
    const [needsUpdated, setNeedsUpdated] = useState(0);
    const [search, setSearch] = useState("");
    const debouncedSearch = useDebounce(search, 250);
    const [orderBy, setOrderBy] = useState("personal_properties.created_at");
    const [sortDir, setSortDir] = useState("desc");
    const upperClientName = !spouse
        ? client.full_name.toUpperCase()
        : client.spouse.full_name.toUpperCase();

    useEffect(() => {
        document.title = "Memorandum of Personal Property";
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            try {
                let response = await Api.get(
                    "clients/" + client.id + "/memorandum-of-personal-property",
                    {
                        limit: 20,
                        sort: sortDir === "desc" ? "-" + orderBy : orderBy,
                        "filter[search]": debouncedSearch,
                        isSpouse: spouse,
                    }
                );
                setPersonalPropertyMemos(response.data.data);
                setLoading(false);
                setIsPrintEnabled(true);
                console.log(response.data.data[0].getResourcePath());
            } catch (error) {
                if (error.response && error.response.status === 403) {
                    setNotAuthorized(true);
                }
                setLoading(false);
            }
        };
        fetchData();
    }, [
        client,
        needsUpdated,
        orderBy,
        sortDir,
        debouncedSearch,
        spouse,
        setIsPrintEnabled,
    ]);

    async function deletePersonalPropertyMemo(personalPropertyMemo) {
        try {
            const response = await Api.delete(
                "clients/" +
                    client.id +
                    "/memorandum-of-personal-property/" +
                    personalPropertyMemo.id
            );
            addToast(response.data.message);
            setNeedsUpdated(needsUpdated + 1);
        } catch (error) {
            addToast(error.errorMessage, { type: "error" });
        }
    }

    if (notAuthorized) {
        return <Forbidden />;
    }

    if (loading) {
        return <Spinner />;
    }

    return (
        <>
            <div className="mb-6 print:hidden">
                <SearchInput
                    label={false}
                    searchValue={search}
                    onSearchChange={(e) => setSearch(e.target.value)}
                />
            </div>
            {personalPropertyMemos.memorandum_items.length ? (
                <>
                    <table className="w-full text-left print:block hidden">
                        <thead>
                            <tr>
                                <td
                                    colSpan="2"
                                    className="text-center text-lg font-bold"
                                >
                                    <p className="m-4">
                                        Exhibit A<br />
                                        Personal Property Memorandum
                                        <br />
                                        {upperClientName}
                                    </p>
                                </td>
                            </tr>
                            <tr>
                                <td colSpan="2">
                                    {client.spouse && (
                                        <p>
                                            If an item is marked with an (*) it
                                            is to be distributed to the person
                                            designated to receive the item only
                                            if my Spouse predeceases me.
                                        </p>
                                    )}
                                </td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td className="w-1/2 font-bold">
                                    Description of Tangible Personal Property
                                </td>
                                <td className="w-1/2 font-bold">
                                    Recipient of Tangible Personal Property
                                </td>
                            </tr>
                            <tr>
                                <td className="w-full" colSpan="2">
                                    <hr
                                        style={{
                                            color: "black",
                                            width: "100%",
                                        }}
                                    />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <table className="w-full text-left">
                        <thead>
                            <tr className="print:hidden">
                                <TableSortHeader
                                    field="personal_properties.name"
                                    sortField={orderBy}
                                    sortDir={sortDir}
                                    changeSort={(column) => {
                                        setSortDir(
                                            sortDir === "asc" ? "desc" : "asc"
                                        );
                                        setOrderBy(column);
                                    }}
                                >
                                    Description
                                </TableSortHeader>
                                <TableSortHeader
                                    disabled={true}
                                    sortField={orderBy}
                                    sortDir={sortDir}
                                    changeSort={(column) => {
                                        setSortDir(
                                            sortDir === "asc" ? "desc" : "asc"
                                        );
                                        setOrderBy(column);
                                    }}
                                >
                                    Recipient
                                </TableSortHeader>
                                <TableSortHeader
                                    field="personal_properties.transfer_on"
                                    sortField={orderBy}
                                    sortDir={sortDir}
                                    changeSort={(column) => {
                                        setSortDir(
                                            sortDir === "asc" ? "desc" : "asc"
                                        );
                                        setOrderBy(column);
                                    }}
                                >
                                    Transfer On
                                </TableSortHeader>
                                <TableSortHeader
                                    field="personal_properties.created_at"
                                    sortField={orderBy}
                                    sortDir={sortDir}
                                    changeSort={(column) => {
                                        setSortDir(
                                            sortDir === "asc" ? "desc" : "asc"
                                        );
                                        setOrderBy(column);
                                    }}
                                >
                                    Created On
                                </TableSortHeader>
                                <th className="print:hidden text-right">
                                    Actions
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {personalPropertyMemos.memorandum_items.map(
                                (memoPropertyItem, idx) => (
                                    <tr key={idx}>
                                        <td className="text-left align-middle">
                                            <div className="flex items-center space-x-3">
                                                {memoPropertyItem?.property
                                                    ?.primary_photo
                                                    ?.preview_url && (
                                                    <PreviewImage
                                                        key={`preview_for_${memoPropertyItem.id}`}
                                                        src={
                                                            memoPropertyItem
                                                                ?.property
                                                                ?.primary_photo
                                                                ?.preview_url
                                                        }
                                                    />
                                                )}
                                                <div>
                                                    <div>
                                                        <a
                                                            href={
                                                                memoPropertyItem
                                                                    .property
                                                                    .resource_path
                                                            }
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                        >
                                                            <span className="print:hidden mb-1 block">
                                                                {
                                                                    memoPropertyItem
                                                                        .property
                                                                        .name
                                                                }
                                                                &nbsp;
                                                                <FontAwesomeIcon
                                                                    icon={
                                                                        faExternalLinkAlt
                                                                    }
                                                                    className="pb-1"
                                                                />
                                                            </span>
                                                        </a>
                                                    </div>
                                                    {memoPropertyItem.property
                                                        .description && (
                                                        <div className="mt-1 text-gray-700">
                                                            {
                                                                memoPropertyItem
                                                                    .property
                                                                    .description
                                                            }
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            {memoPropertyItem.recipient.title}
                                        </td>
                                        <td>
                                            {
                                                memoPropertyItem.transfer_on_display
                                            }
                                        </td>
                                        <td>
                                            {formatDate(
                                                memoPropertyItem.created_at
                                            )}
                                        </td>
                                        <td className="print:hidden text-right">
                                            <span>
                                                <Dropdown
                                                    position="right"
                                                    toggleClass="px-3 py-1"
                                                    toggleContent={
                                                        <FontAwesomeIcon
                                                            icon={faEllipsisH}
                                                        />
                                                    }
                                                >
                                                    <DropdownItem
                                                        label="Edit"
                                                        icon={faEdit}
                                                        link={`${clientPath}/personal-property-memos/${memoPropertyItem.id}/edit`}
                                                    />
                                                    {(userType === "member" ||
                                                        userType ===
                                                            "client") && (
                                                        <DropdownItem
                                                            label="Delete"
                                                            icon={faTrash}
                                                            handleClick={() =>
                                                                deletePersonalPropertyMemo(
                                                                    memoPropertyItem
                                                                )
                                                            }
                                                        />
                                                    )}
                                                </Dropdown>
                                            </span>
                                        </td>
                                    </tr>
                                )
                            )}
                        </tbody>
                    </table>
                    <table className="w-full text-left hidden print:block">
                        <thead>
                            <tr>
                                <td colSpan="2">
                                    <hr
                                        style={{
                                            color: "black",
                                            width: "100%",
                                        }}
                                    />
                                </td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td colSpan="2">
                                    <p>
                                        If a recipient of a particular item of
                                        nonbusiness personal property does not
                                        survive me, such item shall be disposed
                                        of as though it had not been listed in
                                        this memorandum. In the event there is a
                                        conflict between this memorandum and the{" "}
                                        {upperClientName} LIVING TRUST, the
                                        terms of last dated memorandum shall
                                        control. In the event that I
                                        subsequently amend my trust after the
                                        date of this memorandum, this memorandum
                                        shall survive such amendment of my trust
                                        and shall continue in full force and
                                        effect unless amended or revoked by me
                                        in a written memorandum or statement of
                                        revocation signed and dated by me after
                                        the date herein.
                                    </p>
                                </td>
                            </tr>
                            <tr>
                                <td className="w-1/2 mt-12">
                                    Dated:
                                    <hr style={{ color: "black" }} />
                                </td>
                                <td>&nbsp;</td>
                            </tr>
                            <tr>
                                <td>
                                    <hr
                                        className="mt-12"
                                        style={{
                                            color: "black",
                                            width: "100%",
                                        }}
                                    />
                                </td>
                                <td>
                                    <hr
                                        className="mt-12"
                                        style={{
                                            color: "black",
                                            width: "100%",
                                        }}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>WITNESS</td>
                            </tr>
                            <tr>
                                <td>
                                    <hr
                                        className="mt-12"
                                        style={{
                                            color: "black",
                                            width: "100%",
                                        }}
                                    />
                                </td>
                                <td>
                                    <hr
                                        className="mt-12"
                                        style={{
                                            color: "black",
                                            width: "100%",
                                        }}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>WITNESS</td>
                                <td>{upperClientName}</td>
                            </tr>
                        </tbody>
                    </table>
                </>
            ) : (
                <div className="text-gray-400">
                    There are no memorandums of personal property for this
                    client.{" "}
                    <Link
                        to={`${clientPath}/personal-property-memos/add?spouse=${spouse}`}
                    >
                        Add Now.
                    </Link>
                </div>
            )}

            {personalPropertyMemos.memorandum_items.length > 0 && (
                <div className="print:hidden mt-6">
                    <Panel>
                        <VaultResourceFileList
                            client={client}
                            resourceType="Form"
                            resourceId={personalPropertyMemos.memorandum.id}
                        />
                    </Panel>
                </div>
            )}
        </>
    );
}

function PreviewImage({ src }) {
    const [loaded, setLoaded] = useState(false);
    return (
        <div className="relative h-16 w-16">
            {loaded === false && (
                <div className="flex items-center justify-center h-16 w-16 bg-gray-100 absolute inset-0">
                    <Spinner height={36} width={36} />
                </div>
            )}
            <img
                onLoad={() => setLoaded(true)}
                src={src}
                className="`w-auto h-auto max-h-16 max-w-full`"
            />
        </div>
    );
}
