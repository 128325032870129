import useFetch from "./useFetch";
import { fetchMemberPermissions } from "services/PermissionService";

export default function useMemberPermissions(params, config) {
    return useFetch(
        ["member-permissions", params],
        fetchMemberPermissions,
        config
    );
}
