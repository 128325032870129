import React, { useState, useEffect, useContext } from "react";
import Api from "../../../services/Api";
import Dropdown, { DropdownItem } from "../../../components/Dropdown";
import { Link } from "react-router-dom";
import Spinner from "../../../components/Spinner";
import TableSortHeader from "../../../components/TableSortHeader";
import SearchInput from "../../../components/SearchInput";
import { AuthContext } from "../../../contexts/AuthContext";
import useDebounce from "../../../hooks/useDebounce";
import { formatDate } from "../../../util/TextUtil";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faTrash,
    faEdit,
    faEllipsisH,
} from "@fortawesome/free-solid-svg-icons";
import { useToasts } from "react-toast-notifications";
import { parseMoney } from "../../../components/Money";
import TableSummaryRow from "../../../components/TableSummaryRow";
import Forbidden from "../../../components/Forbidden";
import useFormatPercentage from "../../../hooks/useFormatPercentage";
import { useUserCan } from "hooks/useHasAnyPermissions";

function InsurancePoliciesTable({ client, clientPath, showDisabled }) {
    const auth = useContext(AuthContext);
    const userType = auth.user.type;
    const { addToast } = useToasts();
    const formatPercentage = useFormatPercentage();
    const [loading, setLoading] = useState(true);
    const [insurancePolicies, setInsurancePolicies] = useState([]);
    const [orderBy, setOrderBy] = useState("as_of_date");
    const [sortDir, setSortDir] = useState("desc");
    const [search, setSearch] = useState("");
    const debouncedSearch = useDebounce(search, 250);
    const [notAuthorized, setNotAuthorized] = useState(false);
    const [needsUpdated, setNeedsUpdated] = useState(0);
    const canCreateWealthRecords = useUserCan("createWealthRecord");

    useEffect(() => {
        document.title = "Insurance Policies";
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            try {
                let response = await Api.get(
                    "clients/" + client.id + "/insurance-policies",
                    {
                        limit: 20,
                        sort: sortDir === "desc" ? "-" + orderBy : orderBy,
                        "filter[search]": debouncedSearch,
                    }
                );
                setInsurancePolicies(response.data.data);
                setLoading(false);
            } catch (error) {
                if (error.response.status === 403) {
                    setNotAuthorized(true);
                }
            }
        };
        fetchData();
    }, [client, showDisabled, debouncedSearch, orderBy, sortDir, needsUpdated]);

    async function deletePolicy(policy) {
        try {
            const response = await Api.delete(
                "clients/" + client.id + "/insurance-policies/" + policy.id
            );
            addToast(response.data.message);
            setNeedsUpdated(needsUpdated + 1);
        } catch (e) {
            addToast(e.errorMessage, { type: "error" });
        }
    }

    if (notAuthorized) {
        return <Forbidden />;
    }

    if (loading) {
        return <Spinner />;
    }

    return (
        <>
            <div className="mb-4">
                <SearchInput
                    label={false}
                    searchValue={search}
                    onSearchChange={(e) => setSearch(e.target.value)}
                />
            </div>
            {insurancePolicies.length ? (
                <>
                    <table className="w-full text-left">
                        <thead>
                            <tr>
                                <TableSortHeader
                                    field="name"
                                    sortField={orderBy}
                                    sortDir={sortDir}
                                    changeSort={(column) => {
                                        setSortDir(
                                            sortDir === "asc" ? "desc" : "asc"
                                        );
                                        setOrderBy(column);
                                    }}
                                >
                                    Name
                                </TableSortHeader>
                                <TableSortHeader
                                    field="number"
                                    sortField={orderBy}
                                    sortDir={sortDir}
                                    changeSort={(column) => {
                                        setSortDir(
                                            sortDir === "asc" ? "desc" : "asc"
                                        );
                                        setOrderBy(column);
                                    }}
                                >
                                    Policy #
                                </TableSortHeader>
                                <TableSortHeader
                                    disabled={true}
                                    field="owner_name"
                                    sortField={orderBy}
                                    sortDir={sortDir}
                                    changeSort={(column) => {
                                        setSortDir(
                                            sortDir === "asc" ? "desc" : "asc"
                                        );
                                        setOrderBy(column);
                                    }}
                                >
                                    Owner
                                </TableSortHeader>
                                <TableSortHeader
                                    disabled={true}
                                    field="percentage_owned"
                                    sortField={orderBy}
                                    sortDir={sortDir}
                                    changeSort={(column) => {
                                        setSortDir(
                                            sortDir === "asc" ? "desc" : "asc"
                                        );
                                        setOrderBy(column);
                                    }}
                                >
                                    Percentage Owned
                                </TableSortHeader>
                                <TableSortHeader
                                    field="value"
                                    sortField={orderBy}
                                    sortDir={sortDir}
                                    changeSort={(column) => {
                                        setSortDir(
                                            sortDir === "asc" ? "desc" : "asc"
                                        );
                                        setOrderBy(column);
                                    }}
                                >
                                    Cash Value
                                </TableSortHeader>
                                <TableSortHeader
                                    field="death_benefit"
                                    sortField={orderBy}
                                    sortDir={sortDir}
                                    changeSort={(column) => {
                                        setSortDir(
                                            sortDir === "asc" ? "desc" : "asc"
                                        );
                                        setOrderBy(column);
                                    }}
                                >
                                    Death Benefit
                                </TableSortHeader>
                                <TableSortHeader
                                    field="as_of_date"
                                    sortField={orderBy}
                                    sortDir={sortDir}
                                    changeSort={(column) => {
                                        setSortDir(
                                            sortDir === "asc" ? "desc" : "asc"
                                        );
                                        setOrderBy(column);
                                    }}
                                >
                                    As Of Date
                                </TableSortHeader>
                                <th className="text-right">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {insurancePolicies.map((policy, idx) => (
                                <tr
                                    key={idx}
                                    className={policy.deleted_at ? "" : ""}
                                >
                                    <td>
                                        <Link
                                            to={`${clientPath}/insurance-policies/${policy.id}/view`}
                                        >{`${policy.name}`}</Link>
                                        <div className="text-xs text-gray-600">
                                            {policy.type}
                                        </div>
                                    </td>
                                    <td>
                                        {policy.account_number_last_four && (
                                            <span className="text-gray-800">
                                                xxx
                                            </span>
                                        )}
                                        {policy.account_number_last_four}
                                    </td>
                                    <td>{policy.owner_name}</td>
                                    <td>
                                        {formatPercentage(
                                            policy.percentage_owned
                                        )}
                                    </td>
                                    <td>${policy.value}</td>
                                    <td>${policy.death_benefit}</td>
                                    <td>{formatDate(policy.as_of_date)}</td>
                                    <td className="text-right">
                                        <span>
                                            <Dropdown
                                                position="right"
                                                toggleClass="px-3 py-1"
                                                toggleContent={
                                                    <FontAwesomeIcon
                                                        icon={faEllipsisH}
                                                    />
                                                }
                                            >
                                                <DropdownItem
                                                    label="Edit"
                                                    icon={faEdit}
                                                    link={`${clientPath}/insurance-policies/${policy.id}/edit`}
                                                />
                                                {(userType === "member" ||
                                                    userType === "client") && (
                                                    <DropdownItem
                                                        label="Delete"
                                                        icon={faTrash}
                                                        handleClick={() =>
                                                            deletePolicy(policy)
                                                        }
                                                    />
                                                )}
                                            </Dropdown>
                                        </span>
                                    </td>
                                </tr>
                            ))}

                            <TableSummaryRow
                                columns={[
                                    "",
                                    "",
                                    "",
                                    "",
                                    insurancePolicies
                                        .map((p) => parseMoney(p.value))
                                        .reduce((a, c) => a + c),
                                    insurancePolicies
                                        .map((p) => parseMoney(p.death_benefit))
                                        .reduce((a, c) => a + c),
                                    "",
                                    "",
                                ]}
                            />
                        </tbody>
                    </table>
                </>
            ) : (
                <div className="text-gray-400">
                    The are no insurance policies for this client.{" "}
                    {canCreateWealthRecords && (
                        <Link
                            to={`${clientPath}/insurance-policies/add`}
                            className="underline text-gray-600"
                        >
                            Add Now.
                        </Link>
                    )}
                </div>
            )}
        </>
    );
}

export default InsurancePoliciesTable;
