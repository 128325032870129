import Button from "components/Button";
import moment from "moment";
import React, { useEffect, useState } from "react";
import Api from "services/Api";
export default function ICESession() {
    const [timeRemaining, setTimeRemaining] = useState("");

    const logout = async () => {
        try {
            await Api.get("ice/logout");
        } catch (e) {
            ("");
        }
        window.location.reload();
    };

    useEffect(() => {
        const interval = setInterval(() => {
            const sessionExpiration = parseInt(
                window.localStorage.getItem("session-expiration")
            );

            const now = Date.now() / 1000;
            const timeRemaining = sessionExpiration - now;

            if (timeRemaining <= 0) {
                clearInterval(interval);
                return setTimeRemaining("Logging out...");
            }
            var diff = moment.duration(parseInt(timeRemaining + ""), "seconds");
            var interval = 1000;

            let formatted = "";
            let highestUnit = -1;

            ["years", "months", "days", "hours", "minutes", "seconds"].forEach(
                (unit, i) => {
                    let amount = diff[unit]();
                    if (amount === undefined) {
                        return;
                    }
                    if (amount <= 0 && highestUnit === -1) {
                        return;
                    }
                    highestUnit = i;
                    formatted += (amount + "").padStart(2, "0");
                    if (unit !== "seconds") {
                        formatted += ":";
                    }
                }
            );

            setTimeRemaining(formatted);
        }, 1000);

        return () => clearInterval(interval);
    }, []);

    return (
        <>
            <div className="flex justify-between items-center mb-6">
                <div className="text-lg text-brand font-bold">
                    {timeRemaining.indexOf("Logging") > -1
                        ? timeRemaining
                        : `Time Remaining: ${timeRemaining}`}
                </div>
                <div className="text-2xl font-bold text-brand-400">
                    ICE Access
                </div>
                <div>
                    <Button onClick={logout} text="Logout" />
                </div>
            </div>
        </>
    );
}
