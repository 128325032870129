import useClient from "hooks/useClient";
import React, { useState, useEffect, useCallback } from "react";
import Api from "../services/Api";

export const ResourceContext = React.createContext();

export default function ResourceContextProvider({
    children,
    resourceTypes,
    append = null,
    refresh = null,
}) {
    const [options, setOptions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [types] = useState(resourceTypes);

    const { client } = useClient();

    const fetchResource = useCallback(
        async (resourceType) => {
            let response = await Api.get(
                `resources/types/${client.id}/${resourceType}`
            );

            let options = response.data.data.map((o) => {
                return { label: o.label, value: o.value };
            });

            if (append) {
                options = options.concat(append);
            }

            return options;
        },
        [client.id, append]
    );

    useEffect(() => {
        const resources = Array.isArray(types) ? types : [types];
        const fetchData = async () => {
            try {
                setLoading(true);
                const resourceNameDictionary = {
                    Client: "Client",
                    Relationship: "Relationships",
                    FinancialAccount: "Financial Accounts",
                    RetirementAccount: "Retirement Accounts",
                    BusinessInterest: "Business Interests",
                    Trust: "Trusts",
                    Annuity: "Annuities",
                };

                if (resources.length === 0) {
                    return setOptions([]);
                }
                if (resources.length > 1) {
                    let options = [
                        {
                            label: "None Selected",
                            value: "",
                            disabled: true,
                        },
                    ];
                    for (let i = 0; i < resources.length; i++) {
                        let optionsForResource = await fetchResource(
                            resources[i]
                        );
                        options.push({
                            label:
                                resourceNameDictionary[resources[i]] ||
                                resources[i],
                            options: [...optionsForResource],
                        });
                        if (optionsForResource.length === 0) {
                            options.push({
                                label: "None Available",
                                value: "",
                                disabled: true,
                            });
                        }
                    }
                    setOptions(options);
                } else {
                    setOptions(await fetchResource(resources[0]));
                }
                setLoading(false);
            } catch (error) {
                setLoading(false);
                console.log(error);
            }
        };
        fetchData();
    }, [client, fetchResource, types, refresh]);

    return (
        <ResourceContext.Provider value={{ options, loading }}>
            {children}
        </ResourceContext.Provider>
    );
}
