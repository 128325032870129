import React, { useState } from "react";
import Api from "../../services/Api";
import Modal from "../../components/Modal";
import { processFormErrors } from "../../util/FormsHelper";
import { useToasts } from "react-toast-notifications";

export default function ModalUnlinkResource({
    relation,
    vaultEntry,
    show,
    setShow,
    onResult,
}) {
    const [isLoading, setIsLoading] = useState(false);
    const [errors, setErrors] = useState([]);
    const { addToast } = useToasts();

    const unlinkRelation = async ({ relation, vaultEntry }) => {
        setErrors([]);
        try {
            setIsLoading(true);
            const response = await Api.delete(`resources/relations`, {
                type: relation.resource_type,
                resource_id: relation.resource_id,
                vault_id: vaultEntry.id,
            });
            onResult();
            addToast(`${response.data.message}`, { type: "success" });
            setIsLoading(false);
            return true;
        } catch (e) {
            setIsLoading(false);
            console.log(e);
            setErrors(processFormErrors(e));
        }
    };

    return (
        <Modal
            isOpen={show}
            type="warning"
            title={`Unlink ${relation && relation.resource_name}`}
            body={() => {
                return (
                    <p>
                        Are you sure you want to unlink resource{" "}
                        <strong>{relation && relation.resource_name}</strong> to{" "}
                        <strong>{vaultEntry && vaultEntry.name}</strong>. Type
                        in <strong>unlink</strong> below to confirm.
                    </p>
                );
            }}
            inputType="text"
            inputErrors={errors}
            doneButtonTitle="Unlink"
            isLoading={isLoading}
            onResult={async (result) => {
                if (result.result !== "unlink") {
                    if (typeof result.result === "string") {
                        setErrors([
                            'Please confirm deletion by typing in "unlink"',
                        ]);
                        return;
                    }
                    setErrors([]);
                    setShow(false);
                    return;
                }
                if (await unlinkRelation({ relation, vaultEntry })) {
                    setShow(false);
                    onResult();
                }
            }}
        />
    );
}
