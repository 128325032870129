import React, { useEffect, useState } from "react";
import Spinner from "../../../components/Spinner";
import Money from "../../../components/Money";
import { formatDate, titleCase } from "../../../util/TextUtil";
import useFormatPercentage from "../../../hooks/useFormatPercentage";
import { isBool } from "index";
import useTransfers from "../../../hooks/useTransfers";
import useClient from "hooks/useClient";
import usePostMortemState from "hooks/usePostMortemState";
import EditTransferModal from "./EditTransferModal";

export default function TransfersTable({
    assetId = null,
    assetType = null,
    showPostMortem = null,
}) {
    const { client } = useClient();
    const [currentTab, setCurrentTab] = useState("gifts");
    const [totalClientExclusionUsed, setTotalClientExclusionUsed] = useState(0);
    const [totalSpouseExclusionUsed, setTotalSpouseExclusionUsed] = useState(0);
    const [editDestination, setEditDestination] = useState(null);
    const [editTransfer, setEditTransfer] = useState(null);

    const { person, isPostMortem } = usePostMortemState({
        is_post_mortem: showPostMortem ? true : false,
    });

    const {
        data: transfers,
        isLoading: loading,
        isSuccess,
    } = useTransfers({
        clientId: client.id,
        is_gift: currentTab === "gifts" && !isPostMortem,
        asset_id: assetId || null,
        asset_type: assetType || null,
        is_post_mortem: isPostMortem,
        person,
    });

    useEffect(() => {
        if (isSuccess) {
            setTotalClientExclusionUsed(
                transfers.reduce(
                    (a, b) => a + b.total_basic_exclusion_client,
                    0
                ) / 100
            );
            setTotalSpouseExclusionUsed(
                transfers.reduce(
                    (a, b) => a + b.total_basic_exclusion_spouse,
                    0
                ) / 100
            );
        }
    }, [isSuccess, transfers]);

    if (loading) {
        return (
            <div>
                {!isPostMortem && <TransferHistoryTabs />}
                <Spinner message="Loading transfer history" />
            </div>
        );
    }

    return (
        <div>
            <EditTransferModal
                client={client}
                transfer={editTransfer}
                destination={editDestination}
                isOpen={!!editDestination}
                onClose={() => setEditDestination(null)}
            />
            {!isPostMortem && (
                <div className="flex justify-between">
                    <TransferHistoryTabs
                        currentTab={currentTab}
                        setCurrentTab={setCurrentTab}
                    />
                    {currentTab === "gifts" && (
                        <BasicExclusionTotals
                            totalClientExclusionUsed={totalClientExclusionUsed}
                            totalSpouseExclusionUsed={totalSpouseExclusionUsed}
                        />
                    )}
                </div>
            )}
            {!loading && transfers?.length > 0 && (
                <div className="space-y-4">
                    {transfers.map((transfer) => {
                        const is_benefit =
                            transfer.destinations.length > 0
                                ? !!transfer.destinations[0].is_benefit
                                : false;
                        return (
                            <div key={`transfer_${transfer.batch_id}`}>
                                <div className="border border-gray-200 rounded-lg bg-white overflow-hidden">
                                    <TransferDisplayHeader
                                        transfer={transfer}
                                        isPostMortem={isPostMortem}
                                    />
                                    <div className="bg-gray-50 pb-2">
                                        {transfer.transfer_type ===
                                            "ownership" &&
                                            !is_benefit && (
                                                <OwnershipDestinationsTable
                                                    asset={transfer.asset}
                                                    destinations={
                                                        transfer.destinations
                                                    }
                                                    editDestination={(
                                                        destination
                                                    ) => {
                                                        setEditTransfer(
                                                            transfer
                                                        );
                                                        setEditDestination(
                                                            destination
                                                        );
                                                    }}
                                                />
                                            )}
                                        {transfer.transfer_type ===
                                            "ownership" &&
                                            is_benefit && (
                                                <BeneficiaryDestinationsTable
                                                    asset={transfer.asset}
                                                    destinations={
                                                        transfer.destinations
                                                    }
                                                    editDestination={(
                                                        destination
                                                    ) => {
                                                        setEditTransfer(
                                                            transfer
                                                        );
                                                        setEditDestination(
                                                            destination
                                                        );
                                                    }}
                                                />
                                            )}
                                        {transfer.transfer_type === "value" && (
                                            <ValueDestinationsTable
                                                asset={transfer.asset}
                                                destinations={
                                                    transfer.destinations
                                                }
                                                editDestination={(
                                                    destination
                                                ) => {
                                                    setEditTransfer(transfer);
                                                    setEditDestination(
                                                        destination
                                                    );
                                                }}
                                            />
                                        )}
                                    </div>
                                    {!isPostMortem && (
                                        <TransferGiftDescription
                                            transfer={transfer}
                                        />
                                    )}
                                </div>
                            </div>
                        );
                    })}
                </div>
            )}
            {transfers.length === 0 && (
                <p>There are currently no verified transfers.</p>
            )}
        </div>
    );
}

export function BasicExclusionTotals({
    totalClientExclusionUsed,
    totalSpouseExclusionUsed,
}) {
    const { client } = useClient();
    return (
        <div className="flex-none flex space-x-10">
            <div className="flex-none text-center">
                <div className="text-xs text-gray-800">
                    Client Basic Exclusion
                </div>
                <div className="text-lg font-bold text-gray-700">
                    <Money value={totalClientExclusionUsed} />
                </div>
            </div>
            {client.spouse && (
                <div className="flex-none text-center">
                    <div className="text-xs text-gray-800">
                        Spouse Basic Exclusion
                    </div>
                    <div className="text-lg font-bold text-gray-700">
                        <Money value={totalSpouseExclusionUsed} />
                    </div>
                </div>
            )}
        </div>
    );
}

export function TransferDetailsContainer({ children }) {
    return (
        <div className="grid grid-flow-col auto-cols-fr self-center items-center w-full md:divide-x divide-gray-100">
            {children}
        </div>
    );
}

export function TransferDisplayHeader({ transfer, isPostMortem }) {
    return (
        <div className="flex justify-between text-left px-4 py-3">
            <h2 className="text-sm">
                <div className={`text-xs text-gray-500 flex`}>
                    <span>
                        {titleCase(transfer.asset_type ?? "", {
                            pascal: true,
                        })}
                    </span>
                </div>
                <div className="flex items-end">
                    <span className="font-bold text-base text-gray-700">
                        {transfer.asset_name}
                    </span>
                </div>
                {transfer.asset_account_number && (
                    <span className="text-xs text-gray-500 block">
                        {transfer.asset_account_number}
                    </span>
                )}
            </h2>
            <div className="text-left">
                <div className={`text-xs text-gray-500`}>
                    <span>
                        {transfer.is_monetary_gift ? "Donor" : "Transferor"}
                    </span>
                </div>
                <div>
                    <span className="font-bold text-sm text-gray-600">
                        {transfer.donor_name ||
                            transfer.destinations[0].source_display_name}
                    </span>
                </div>
            </div>
            {isBool(transfer.is_monetary_gift) && (
                <div className="text-left">
                    <div className={`text-xs text-gray-500`}>
                        <span>Gift Tax Return Filed</span>
                    </div>
                    <div>
                        <span className="font-bold text-sm text-gray-600">
                            {transfer.file_gift_tax_return ? "Yes" : "No"}
                        </span>
                    </div>
                </div>
            )}
            {!isPostMortem && (
                <div className="text-left">
                    <div className={`text-xs text-gray-500`}>
                        <span>Basic Exclusion Used</span>
                    </div>
                    <div>
                        <span className="font-bold text-sm text-gray-600">
                            <Money
                                value={transfer.total_basic_exclusion / 100}
                            />
                        </span>
                    </div>
                </div>
            )}
        </div>
    );
}

export function TransferGiftDescription({ transfer }) {
    if (!transfer.description) {
        return <></>;
    }
    return (
        <div className="w-full px-4 py-2 bg-white rounded-b-lg border-t border-gray-200">
            <div className="text-xs text-gray-500">Description</div>
            <p className="text-sm text-gray-900">{transfer.description}</p>
        </div>
    );
}

function OwnershipDestinationsTable({ asset, destinations, editDestination }) {
    const formatPercentage = useFormatPercentage();
    return (
        <table className="w-full p-0 border-t border-gray-200 table-fixed bg-gray-50">
            <thead className="text-left text-gray-700 p-0 m-0">
                <tr>
                    <th className="pt-2 pb-0">Recipients</th>
                    <th className="pt-2 pb-0">Ownership Transferred</th>
                    <th className="pt-2 pb-0">Value Transferred</th>
                    <th className="pt-2 pb-0">Verified At</th>
                    <th className="pt-2 pb-0"></th>
                </tr>
            </thead>
            <tbody className="text-gray-700">
                {destinations.map((destination) => (
                    <tr
                        key={`destination_${destination.id}`}
                        className="hover:bg-brand-500 hover:text-white"
                    >
                        <td className="py-1">
                            {destination.destination_display_name}
                        </td>
                        <td className="py-1">
                            {formatPercentage(destination.transfer_percentage)}
                        </td>
                        <td className="py-1">
                            {asset.is_liability ? (
                                <span className="text-red-500">
                                    <Money
                                        value={destination.transfer_amount}
                                    />
                                </span>
                            ) : (
                                <Money value={destination.transfer_amount} />
                            )}
                        </td>
                        <td className="py-1">
                            {formatDate(destination.verified_at)}
                        </td>
                        <td
                            className="py-1 text-right cursor-pointer"
                            onClick={() => editDestination(destination)}
                        >
                            Edit
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
}

function BeneficiaryDestinationsTable({
    asset,
    destinations,
    editDestination,
}) {
    const formatPercentage = useFormatPercentage();
    return (
        <table className="w-full p-0 border-t border-gray-200 table-fixed bg-gray-50">
            <thead className="text-left text-gray-700 p-0 m-0">
                <tr>
                    <th className="pt-2 pb-0">Recipients</th>
                    <th className="pt-2 pb-0">Death Benefit Transferred</th>
                    <th className="pt-2 pb-0">Value Transferred</th>
                    <th className="pt-2 pb-0">Verified At</th>
                    <th className="pt-2 pb-0"></th>
                </tr>
            </thead>
            <tbody className="text-gray-700">
                {destinations.map((destination) => (
                    <tr
                        key={`destination_${destination.id}`}
                        className="hover:bg-brand-500 hover:text-white"
                    >
                        <td className="py-1">
                            {destination.destination_display_name}
                        </td>
                        <td className="py-1">
                            {formatPercentage(destination.transfer_percentage)}
                        </td>
                        <td className="py-1">
                            {asset.is_liability ? (
                                <span className="text-red-500">
                                    <Money
                                        value={destination.transfer_amount}
                                    />
                                </span>
                            ) : (
                                <Money value={destination.transfer_amount} />
                            )}
                        </td>
                        <td className="py-1">
                            {formatDate(destination.verified_at)}
                        </td>
                        <td
                            className="py-1 text-right cursor-pointer"
                            onClick={() => editDestination(destination)}
                        >
                            Edit
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
}

function ValueDestinationsTable({ asset, destinations, editDestination }) {
    return (
        <table className="w-full p-0 border-t border-gray-200 table-fixed bg-gray-50">
            <thead className="text-left text-gray-700 p-0 m-0">
                <tr>
                    <th className="pt-2 pb-0">Recipients</th>
                    <th className="pt-2 pb-0">Ownership Transferred</th>
                    <th className="pt-2 pb-0">Value Transferred</th>
                    <th className="pt-2 pb-0">Verified At</th>
                    <th className="pt-2 pb-0"></th>
                </tr>
            </thead>
            <tbody className="text-gray-700">
                {destinations.map((destination) => (
                    <tr
                        key={`destination_${destination.id}`}
                        className="hover:bg-brand-500 hover:text-white"
                    >
                        <td className="py-1">
                            {destination.destination_display_name}
                        </td>
                        <td className="py-1">Value Transfer Only</td>
                        <td className="py-1">
                            {asset.is_liability ? (
                                <span className="text-red-500">
                                    <Money
                                        value={destination.transfer_amount}
                                    />
                                </span>
                            ) : (
                                <Money value={destination.transfer_amount} />
                            )}
                        </td>
                        <td className="py-1">
                            {formatDate(destination.verified_at)}
                        </td>
                        <td
                            className="py-1 text-right cursor-pointer"
                            onClick={() => editDestination(destination)}
                        >
                            Edit
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
}

export function TransferHistoryTabs({ setCurrentTab, currentTab }) {
    return (
        <div className="flex justify-between mb-6 w-full h-12">
            <div className="flex border rounded-md w-80 overflow-hidden">
                <button
                    type="button"
                    onClick={() => setCurrentTab("gifts")}
                    className={`w-1/2 px-3 rounded-none focus:outline-none ${
                        currentTab === "gifts"
                            ? "bg-white text-brand-500 focus:bg-white"
                            : "bg-gray-100 text-gray-400 focus:bg-gray-100"
                    }`}
                >
                    Gifts
                </button>
                <button
                    type="button"
                    onClick={() => setCurrentTab("non_gifts")}
                    className={`w-1/2 px-3 rounded-none focus:outline-none border-l ${
                        currentTab === "non_gifts"
                            ? "bg-white text-brand-500 focus:bg-white"
                            : "bg-gray-100 text-gray-400 focus:bg-gray-100"
                    }`}
                >
                    Non-Gifts
                </button>
            </div>
        </div>
    );
}
