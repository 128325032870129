import React, { useReducer, useState, useCallback } from "react";
import Api from "../../services/Api";
import { Redirect } from "react-router-dom";
import SaveButton from "../../components/SaveButton";
import { useToasts } from "react-toast-notifications";
import PageContainer from "../../components/PageContainer";
import AnnuityForm from "./_partials/AnnuityForm";
import UnsavedChanges from "../../components/UnsavedChanges";
import ClientPageHeader, { Heading } from "../../components/ClientPageHeader";
import { formatDateForInput } from "../../util/TextUtil";

export default function AddAnnuity({ client, clientPath }) {
    const { addToast } = useToasts();
    const [errors, setErrors] = useState([]);
    const [isSaving, setIsSaving] = useState(false);
    const [redirectToId, setRedirectToId] = useState(false);
    const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);

    const [input, setInput] = useReducer(
        (state, newState) => ({ ...state, ...newState }),
        {
            owner_id: client.id,
            frequency_id: 1,
            as_of_date: formatDateForInput(new Date()),
            other_owner_type: "none",
            managed_by_id: "",
            type: "fixed_annuity",
            qualified: "nonqualified",
        }
    );

    document.title = "Add Annuity";

    const updateValue = useCallback((event) => {
        setInput({ [event.target.name]: event.target.value });
        setHasUnsavedChanges(true);
    }, []);

    async function store(e) {
        e.preventDefault();
        try {
            setIsSaving(true);
            const body = { ...input };
            let response = await Api.post(
                "clients/" + client.id + "/annuities",
                body
            );
            addToast(response.data.message);
            setErrors([]);
            setRedirectToId(response.data.data.id);
            setIsSaving(false);
            setHasUnsavedChanges(false);
        } catch (e) {
            setIsSaving(false);
            setErrors([]);
            if (e.response.data.errors) {
                setErrors(e.response.data.errors);
            } else {
                addToast(e.response.data.message, { type: "error" });
            }
        }
    }

    if (redirectToId) {
        return (
            <Redirect
                to={`${clientPath}/annuities/${redirectToId}/edit/owners`}
            />
        );
    }

    return (
        <>
            <ClientPageHeader client={client}>
                <Heading
                    backTo={`${clientPath}/annuities`}
                    backText="All Policies"
                >
                    Add Annuity
                </Heading>
                <div className="flex-0">
                    <SaveButton isLoading={isSaving} onClick={store} />
                </div>
            </ClientPageHeader>
            <PageContainer>
                <UnsavedChanges when={hasUnsavedChanges} />
                <div className="max-w-3xl mx-auto">
                    <AnnuityForm
                        client={client}
                        input={input}
                        errors={errors}
                        updateValue={updateValue}
                        onSubmit={store}
                    />
                </div>
            </PageContainer>
        </>
    );
}
