// https://goshakkk.name/array-form-inputs/
import React, { useState } from "react";
import TextInput, { InputLabel } from "../../components/TextInput";
import Api from "../../services/Api";
import { useToasts } from "react-toast-notifications";
import Button from "../../components/Button";
import {
    faEnvelopeOpen,
    faTimesCircle,
    faPlusCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Select from "react-select/async";
import debounce from "../../util/debounce-promise";
import InputErrors from "../../components/InputErrors";
import { Redirect } from "react-router-dom";
import useQuestionnaires from "hooks/useQuestionnaires";
import SelectInput from "components/SelectInput";

function InviteClients(props) {
    const { addToast } = useToasts();
    const [emails, setEmails] = useState([
        {
            email: "",
            type: "client",
            role: "client_owner",
            questionnaire_id: null,
        },
    ]);
    const [errors, setErrors] = useState([]);
    const [redirectToIndex, setRedirectToIndex] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const { data: questionnaires } = useQuestionnaires({ memberId: null });

    document.title = "Invite Clients";

    function updateEmail(idx, event) {
        const newEmails = emails.map((email, sidx) => {
            if (idx !== sidx) return email;
            return {
                ...email,
                email: event.target.value,
                type: "client",
                role: "client_owner",
            };
        });
        setEmails(newEmails);
    }

    function updatePrimaryAccount(idx, primary_attorney_id) {
        const newEmails = emails.map((email, sidx) => {
            if (idx !== sidx) return email;
            return {
                ...email,
                primary_attorney_id: primary_attorney_id,
                type: "client",
                role: "client_owner",
            };
        });
        setEmails(newEmails);
    }

    function updateQuestionnaireId(idx, questionnaire_id) {
        const newEmails = emails.map((email, sidx) => {
            if (idx !== sidx) return email;
            return {
                ...email,
                questionnaire_id: questionnaire_id,
            };
        });
        setEmails(newEmails);
    }

    function appendNewEmail() {
        if (emails.length < 10) {
            setEmails((emails) => [...emails, { email: "" }]);
        }
    }

    function removeEmail(idx) {
        if (emails.length > 1) {
            setEmails((emails) => emails.filter((s, sidx) => idx !== sidx));
        }
    }

    async function inviteClients(e) {
        e.preventDefault();
        if (isSaving) {
            return;
        }
        try {
            setIsSaving(true);
            let response = await Api.post("invitations", {
                invitees: emails,
            });
            setEmails([{ email: "" }]);
            setErrors([{ emails: null, invitees: null }]);
            addToast(response.data.message);
            props.onCancel();
            setRedirectToIndex(true);
            setIsSaving(false);
            setTimeout(function () {
                window.location.reload();
            }, 250);
        } catch (e) {
            setIsSaving(false);
            if (e.response.data.errors) {
                setErrors(e.response.data.errors);
            } else {
                addToast(e.response.data.message, { type: "error" });
            }
        }
    }

    async function fetchAttorneyOptions(query) {
        let response = await Api.get("members", {
            "filter[search]": query,
            "filter[role]": ["member_owner", "member_partner"],
            limit: 100,
        });

        return response.data.data.map((attorney) => {
            return {
                value: attorney.id.toString(),
                label: attorney.full_name,
            };
        });
    }

    const getAttorneyOptions = debounce(fetchAttorneyOptions, 250);

    function renderEmailInputs() {
        return emails.map((email, idx) => (
            <div className="mb-8 flex items-center space-x-2" key={idx}>
                <div className="flex-auto w-64">
                    <TextInput
                        name="emails[]"
                        value={email.email}
                        placeholder="Email"
                        type="email"
                        onChange={(event) => updateEmail(idx, event)}
                        error={errors["invitees." + idx + ".email"]}
                        autoFocus={idx === 0 ? true : false}
                    />
                </div>
                <div className="flex-auto w-64">
                    <InputErrors
                        errors={
                            errors["invitees." + idx + ".primary_attorney_id"]
                        }
                    />
                    <Select
                        placeholder="Attorney"
                        onChange={(event) =>
                            updatePrimaryAccount(idx, event.value)
                        }
                        cacheOptions
                        loadOptions={getAttorneyOptions}
                        defaultOptions
                        onInputChange={getAttorneyOptions}
                        menuPortalTarget={document.body}
                        styles={{
                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                            control: (base) => ({
                                ...base,
                                ...{
                                    height: 50,
                                    display: "flex",
                                    border: "solid rgb(229, 231, 235) 1px",
                                    borderRadius: 4,
                                },
                            }),
                        }}
                    />
                </div>
                {questionnaires && questionnaires.data.length > 0 && (
                    <div className="flex-auto w-64">
                        <SelectInput
                            label=""
                            id="questionnaire_id"
                            name="questionnaire_id"
                            value={email.questionnaire_id}
                            defaultOption="None"
                            defaultOptionDisabled={false}
                            defaultOptionValue={null}
                            options={questionnaires.data.map(
                                (questionnaire) => {
                                    return {
                                        value: questionnaire.id.toString(),
                                        label: questionnaire.title,
                                    };
                                }
                            )}
                            placeholder="Questionnaire"
                            onChange={(event) =>
                                updateQuestionnaireId(idx, event.target.value)
                            }
                        />
                    </div>
                )}
                {emails.length > 1 && (
                    <button
                        type="button"
                        onClick={() => removeEmail(idx)}
                        className="text-base flex items-center hover:text-gray-900 flex-initial pl-3"
                    >
                        <FontAwesomeIcon
                            icon={faTimesCircle}
                            className="text-gray-500 hover:text-red-500"
                        />
                    </button>
                )}
            </div>
        ));
    }

    if (redirectToIndex) {
        return <Redirect to={`/clients/pending`} />;
    }

    return (
        <>
            <form onSubmit={inviteClients}>
                <div className="flex items-center justify-between mb-6">
                    <div className="text-xl">Invite Clients</div>
                    <button type="button" onClick={props.onCancel}>
                        <FontAwesomeIcon
                            icon={faTimesCircle}
                            className="text-gray-400 text-lg"
                        />
                    </button>
                </div>
                <p className="my-8 text-gray-400">
                    Enter the email address of the clients you would like to
                    invite.
                </p>
                <div className="flex items-center space-x-2">
                    <div className="flex-auto w-64">
                        <InputLabel text="Email" htmlFor="emails" />
                    </div>
                    <div className="flex-auto w-64">
                        <InputLabel text="Attorney" />
                    </div>
                    <div className="flex-auto w-64">
                        <InputLabel text="Questionnaire" />
                    </div>
                </div>

                {renderEmailInputs()}

                <div className="mt-3">
                    <button
                        type="button"
                        onClick={(event) => appendNewEmail(event)}
                        className="text-base flex items-center text-gray-700 hover:text-gray-900"
                    >
                        <FontAwesomeIcon
                            icon={faPlusCircle}
                            className="text-gray-600 text-sm mr-2"
                        />{" "}
                        Add Another Client
                    </button>
                </div>

                <div className="flex items-center justify-between mt-8">
                    <div className="flex-0">
                        <Button
                            type="button"
                            appearance="link"
                            onClick={() => props.setShowManualForm(true)}
                        >
                            Add Client Manually
                        </Button>
                    </div>

                    <div className="flex-0 flex items-center space-x-2">
                        <Button
                            appearance="light"
                            onClick={props.onCancel}
                            text="Cancel"
                        />
                        <Button
                            onClick={inviteClients}
                            icon={faEnvelopeOpen}
                            isLoading={isSaving}
                            text="Send Invites"
                        />
                    </div>
                </div>
            </form>
        </>
    );
}

export default InviteClients;
