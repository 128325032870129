import { useQueryState } from "use-location-state";

export default function usePostMortemState({
    is_post_mortem = false,
    person: personInitial = "",
    joint_split = true,
} = {}) {
    const showAltValues = false;
    const setShowAltValues = () => {};
    const [person, setPerson] = useQueryState("person", personInitial);
    const [jointSplit, setJointSplit] = useQueryState(
        "joint_split",
        joint_split
    );
    const [isPostMortem, setIsPostMortem] = useQueryState(
        "is_post_mortem",
        is_post_mortem
    );

    return {
        showAltValues,
        setShowAltValues,
        person,
        setPerson,
        isPostMortem: isPostMortem || false,
        jointSplit,
        // These are only to be used by the initial values
        setIsPostMortem,
        setJointSplit,
    };
}
