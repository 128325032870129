import React from "react";
import Badge from "components/Badge";
import ClientPageHeader, { Heading } from "components/ClientPageHeader";
import PageContainer from "components/PageContainer";
import useClient from "hooks/useClient";
import RelationshipTabs from "./_partials/RelationshipTabs";

export default function RelationshipLayout({
    relationshipInput,
    readOnly = false,
    children,
    currentBasePath,
    buttons = null,
    ...props
}) {
    const { client, clientPath } = useClient();

    return (
        <>
            <ClientPageHeader client={client}>
                <Heading
                    backTo={`${clientPath}/relationships`}
                    backText="All Relationships"
                >
                    <div className="space-x-3">
                        <span>
                            {!readOnly && `Edit `}
                            {relationshipInput.full_name_with_relationship}
                        </span>
                        {relationshipInput.is_authorized_party && (
                            <Badge color="green">Authorized Party</Badge>
                        )}
                    </div>
                </Heading>
                {buttons && <div className="flex-0">{buttons}</div>}
            </ClientPageHeader>
            <PageContainer>
                <div className="flex space-x-10">
                    <div className="flex-initial">
                        <RelationshipTabs
                            basePath={currentBasePath}
                            isAuthorizedParty={
                                relationshipInput.is_authorized_party
                            }
                            {...props}
                        />
                        <div className="mt-6">
                            {relationshipInput.estate_planning_roles?.length >
                                0 && (
                                <div>
                                    <h2 className="border-b font-bold text-gray-700 text-sm">
                                        Estate Planning Roles
                                    </h2>
                                    <div className="flex flex-col space-y-3 divide-y divide-gray-200">
                                        {relationshipInput.estate_planning_roles.map(
                                            (item, idx) => (
                                                <dl
                                                    key={idx}
                                                    className="text-sm pt-3"
                                                >
                                                    <div className="flex space-x-1 items-center">
                                                        <dt className="text-gray-600">
                                                            Role:
                                                        </dt>
                                                        <dd>
                                                            {
                                                                item.role_description
                                                            }
                                                        </dd>
                                                    </div>
                                                    <div className="flex space-x-1 items-center">
                                                        <dt className="text-gray-600">
                                                            For Whom:
                                                        </dt>
                                                        <dd>
                                                            {
                                                                item.on_behalf_of_description
                                                            }
                                                        </dd>
                                                    </div>
                                                    <div className="flex space-x-1 items-center">
                                                        <dt className="text-gray-600">
                                                            Level:
                                                        </dt>
                                                        <dd>
                                                            {
                                                                item.role_level_description
                                                            }
                                                        </dd>
                                                    </div>
                                                </dl>
                                            )
                                        )}
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="flex-1 flex-shrink-0 w-full">
                        {children}
                    </div>
                </div>
            </PageContainer>
        </>
    );
}
