import React, { Component } from "react";
import TextInput from "../../components/TextInput";
import SaveButton from "../../components/SaveButton";
import InputErrors from "../../components/InputErrors";
import { input } from "../../Utility";
import Api from "../../services/Api";
import { Redirect } from "react-router-dom";
import { AuthContext } from "../../contexts/AuthContext";
import Panel, { PanelHeader } from "../../components/Panel";
import Spinner from "../../components/Spinner";

class RegisterMember extends Component {
    constructor() {
        super();
        this.state = {
            isLoading: true,
            redirectToDashboard: false,
            redirectToLogin: false,
            codeHasExpired: false,
            isSaving: false,
            first_name: "",
            last_name: "",
            title: "",
            phone: "",
            password: "",
            password_confirmation: "",
            code: "",
            email: "",
            invitation: {},
            user: null,
            errors: {
                emails: null,
                invitees: null,
            },
        };
    }

    componentDidMount() {
        let code = input("code");
        this.setState({ code: code });
        let email = input("email");
        this.setState({ email: email });
        this.fetchInvitation(code);
    }

    updateValues = (event) => {
        const { name, value } = event.target;
        this.setState({ [name]: value });
    };

    fetchInvitation = async (code) => {
        try {
            let invitation = await Api.get("invitations/" + code);
            this.setState({ invitation: invitation.data, isLoading: false });
        } catch (e) {
            this.setState({ isLoading: false });
            if (e.response.data.errors) {
                this.setState({ errors: e.response.data.errors });
            }

            if (e.response.status === 410) {
                this.setState({ codeHasExpired: true });
            }
        }
    };

    registerUser = async (e) => {
        e.preventDefault();
        try {
            await Api.post(
                "invitations/" + this.state.invitation.id + "/accept",
                {
                    code: this.state.code,
                    email: this.state.email,
                    password: this.state.password,
                    password_confirmation: this.state.password_confirmation,
                    first_name: this.state.first_name,
                    last_name: this.state.last_name,
                    title: this.state.title,
                    phone: this.state.phone,
                }
            );
            window.location = "/";
        } catch (e) {
            if (e.response.data.errors) {
                this.setState({ errors: e.response.data.errors });
            }

            if (e.response.data.redirect === "/login") {
                this.setState({ redirectToLogin: true });
            }
        }
    };

    render() {
        if (this.state.isLoading) {
            return <Spinner />;
        }

        if (this.state.redirectToLogin) {
            return (
                <Redirect
                    to={{
                        path: "/login",
                        state: {
                            message:
                                "You already have an account. Please login instead.",
                        },
                    }}
                />
            );
        }

        if (this.state.user) {
            return <Redirect to="/account/profile" />;
        }

        if (this.state.codeHasExpired) {
            return (
                <div className="h-screen flex items-center justify-center text-center">
                    <div className="mt-8 mx-auto max-w-2xl px-3">
                        <h1 className="text-xl font-bold text-gray-700 mb-1">
                            Your Invitation Has Expired
                        </h1>
                        <div className="text-gray-700 mx-auto px-6 py-4">
                            <p>
                                Please reach out to the person that invited you
                                to ask for a new invitation.
                            </p>
                        </div>
                    </div>
                </div>
            );
        }

        return (
            <div className="my-8">
                <div className="mx-auto max-w-2xl px-3">
                    <Panel>
                        <PanelHeader title="Complete Your Account" />
                        <InputErrors errors={this.state.errors.invitees} />
                        <form onSubmit={this.registerUser}>
                            <input
                                type="hidden"
                                name="code"
                                value={this.state.code}
                            />
                            <input
                                type="hidden"
                                name="email"
                                value={this.state.email}
                            />
                            <div className="mb-6">
                                <TextInput
                                    name="password"
                                    label="Password"
                                    id="password"
                                    type="password"
                                    value={this.state.password}
                                    onChange={this.updateValues}
                                    error={this.state.errors.password}
                                    autoFocus={true}
                                    autoComplete="new-password"
                                />
                            </div>

                            <div className="mb-6">
                                <TextInput
                                    name="password_confirmation"
                                    label="Confirm Password"
                                    id="password_confirmation"
                                    type="password"
                                    value={this.state.password_confirmation}
                                    onChange={this.updateValues}
                                    error={
                                        this.state.errors.password_confirmation
                                    }
                                    autoComplete="new-password"
                                />
                            </div>

                            <div className="flex items-end space-x-4 mb-6">
                                <div className="flex-1">
                                    <TextInput
                                        name="first_name"
                                        label="First Name"
                                        id="first_name"
                                        value={this.state.first_name}
                                        onChange={this.updateValues}
                                        error={this.state.errors.first_name}
                                    />
                                </div>

                                <div className="flex-1">
                                    <TextInput
                                        name="last_name"
                                        label="Last Name"
                                        id="last_name"
                                        value={this.state.last_name}
                                        onChange={this.updateValues}
                                        error={this.state.errors.last_name}
                                    />
                                </div>
                            </div>

                            <div className="mb-6">
                                <TextInput
                                    name="title"
                                    label="Title"
                                    id="title"
                                    value={this.state.title}
                                    onChange={this.updateValues}
                                    error={this.state.errors.title}
                                />
                            </div>

                            <div className="mb-6">
                                <TextInput
                                    name="phone"
                                    type="tel"
                                    label="Phone"
                                    id="phone"
                                    placeholder="xxx-xxx-xxxx"
                                    value={this.state.phone}
                                    onChange={this.updateValues}
                                    error={this.state.errors.phone}
                                />
                            </div>

                            <SaveButton
                                type="submit"
                                className="w-64 mx-auto block bg-gray-800 hover:bg-gray-700 text-white border border-gray-800"
                            />
                        </form>
                    </Panel>
                </div>
            </div>
        );
    }
}

RegisterMember.contextType = AuthContext;

export default RegisterMember;
