import React from "react";
import VerticalTabs from "../../../components/VerticalTabs";

export default function NonMortgageLiabilityTabs({
    basePath,
    hideOwnerships = false,
    ...props
}) {
    let items = [
        {
            label: "Info",
            to: basePath + "",
        },
        {
            label: "Owners",
            to: basePath + "/owners",
            hidden: hideOwnerships,
        },
        {
            label: "Notes",
            to: basePath + "/notes",
        },
        {
            label: "Related Files",
            to: basePath + "/related-files",
        },
        {
            label: "Related Photos",
            to: basePath + "/related-photos",
        },
        {
            label: "Access",
            to: basePath + "/access",
        },
    ];
    return <VerticalTabs {...props} items={items} />;
}
