import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShareAlt } from "@fortawesome/free-solid-svg-icons";
import { useOwnershipContext } from "contexts/OwnershipContext";

export default function ProposeTransferButton({
    asset,
    ownership,
    disabled = false,
}) {
    const { showTransferOwnershipModal } = useOwnershipContext();
    return (
        <button
            type="button"
            onClick={() => {
                showTransferOwnershipModal(asset, ownership);
            }}
            className="flex items-center space-x-1 text-gray-500 text-sm hover:text-brand p-2"
            title="Propose Transfer"
            disabled={disabled}
        >
            <FontAwesomeIcon icon={faShareAlt} />
            &nbsp; Transfer
        </button>
    );
}
