import React from "react";
import { Link } from "react-router-dom";

export default function BreadCrumbs({
    breadcrumbs,
    rootPath,
    defaultName = "",
    onClick = null,
}) {
    return (
        <div className={`flex flex-row`}>
            {breadcrumbs?.length > 0 && (
                <>
                    {onClick ? (
                        <span
                            className="text-blue-500 cursor-pointer"
                            onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                onClick(null);
                            }}
                        >
                            {defaultName}
                        </span>
                    ) : (
                        <Link to={`${rootPath}`} className="text-blue-500">
                            <span>{defaultName}</span>
                        </Link>
                    )}
                    {breadcrumbs.length === 0 ? "" : <>&nbsp; &gt; &nbsp;</>}
                </>
            )}

            {breadcrumbs?.map((crumb, idx) => {
                return (
                    <div key={idx}>
                        {breadcrumbs.length - 1 === idx ? (
                            <span className="font-bold">{crumb.name}</span>
                        ) : (
                            <>
                                {onClick ? (
                                    <span
                                        className="text-blue-500 cursor-pointer"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                            onClick(crumb);
                                        }}
                                    >
                                        {crumb.name}
                                    </span>
                                ) : (
                                    <Link
                                        to={`${rootPath}?id=${crumb.id}`}
                                        className="text-blue-500"
                                    >
                                        <span>{crumb.name}</span>
                                    </Link>
                                )}
                                &nbsp; &gt; &nbsp;
                            </>
                        )}
                    </div>
                );
            })}

            {breadcrumbs?.length === 0 && (
                <span className="font-bold">{defaultName}</span>
            )}
        </div>
    );
}
