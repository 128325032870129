import React, { useState } from "react";
import SelectInput from "../../components/SelectInput";
import PermissionList from "components/Permissions/PermissionList";
import useUpdateClientUserPermission from "hooks/useUpdateClientUserPermission";
import useResetClientUserPermission from "hooks/useResetClientUserPermission";
import useClientUserPermission from "hooks/useClientUserPermissions";
import ModalResetClientUserPermissions from "../clients/ModalResetClientUserPermissions";
import Button from "../../components/Button";
import { getPermissionTypes } from "services/PermissionService";
import useClient from "hooks/useClient";
import RelationshipLayout from "./RelationshipLayout";

export default function AuthorizedPartyPermissions({
    relationship,
    currentBasePath,
}) {
    const { client } = useClient();
    const [readOnly, setReadOnly] = useState(true);
    const { data, refetch } = useClientUserPermission({
        clientId: client.id,
        userId: relationship.authorized_party_id,
    });

    const permissionTypes = getPermissionTypes(data);

    const { onSubmit: updatePermission, isSuccess } =
        useUpdateClientUserPermission(
            client.id,
            relationship.authorized_party_id
        );
    const { onSubmit: resetPermission, isSuccess: isReset } =
        useResetClientUserPermission(
            client.id,
            relationship.authorized_party_id
        );

    const [permissionType, setPermissionType] = useState("");
    const [showReset, setShowReset] = useState(false);
    const filteredPermissions = () => {
        if (permissionType === "general" || permissionType === "report") {
            return data.filter(
                (permission) => permission.type === permissionType
            );
        }
        return data;
    };

    const handleResetPermission = async (event, permissionKey) => {
        event.preventDefault();
        resetPermission(event, permissionKey);
    };
    const handleUpdatePermission = (event, permission, toggled) => {
        event.preventDefault();
        updatePermission(event, {
            key: permission,
            enabled: toggled,
        });
    };

    React.useEffect(() => {
        if (isSuccess || isReset) {
            refetch();
        }
    }, [isSuccess, isReset]);

    return (
        <RelationshipLayout
            relationshipInput={relationship}
            readOnly={false}
            currentBasePath={currentBasePath}
            buttons={
                <div className="flex items-end space-x-3">
                    <div className="flex-1">
                        <Button
                            text="Reset All Permissions"
                            onClick={() => setShowReset(true)}
                        />
                    </div>
                    <div className="flex-1">
                        <Button
                            text={readOnly ? "Unlock" : "Lock"}
                            onClick={() => setReadOnly((readOnly) => !readOnly)}
                        />
                    </div>
                </div>
            }
        >
            {data && (
                <form className="max-w-3xl mx-auto">
                    <div className="flex justify-between items-center py-6 border-b">
                        <label>Permission Type</label>
                        <div className="w-64 max-w-full">
                            <SelectInput
                                options={permissionTypes}
                                value={permissionType}
                                name="type"
                                readOnly={readOnly}
                                onChange={(e) => {
                                    setPermissionType(e.target.value);
                                }}
                            />
                        </div>
                    </div>
                    <PermissionList
                        permissions={filteredPermissions()}
                        resetPermission={handleResetPermission}
                        updatePermission={handleUpdatePermission}
                        readOnly={readOnly}
                    />

                    <input type="submit" hidden />
                </form>
            )}

            <ModalResetClientUserPermissions
                client={client}
                user={{ ...relationship, id: relationship.authorized_party_id }}
                show={showReset}
                setShow={setShowReset}
                onResult={async () => {
                    refetch();
                }}
            />
        </RelationshipLayout>
    );
}
