import React from "react";
import AdvisorProfileForm from "./_partials/AdvisorProfileForm";
import useCreateAdvisor from "hooks/useCreateAdvisor";
import useForm from "hooks/useForm";
import BasicModal, { BasicModalHeader } from "components/ModalBasic";

export default function AdvisorModal({
    isOpen,
    onClose,
    onSuccess,
    title,
    client,
}) {
    const { input, updateValue, clearUnsavedChanges } = useForm({
        type: 1,
        first_name: "",
        last_name: "",
        middle_name: "",
        nick_name: "",
        email: "",
        phone: "",
        address: "",
        address_2: "",
        city: "",
        state: "",
        postal_code: "",
        country: "US",
        organization_name: "",
        organization_website: "",
        can_collaborate: 0,
    });

    document.title = "Add Advisor";

    const { onSubmit, validationErrors: errors } = useCreateAdvisor(
        client.id,
        input
    );

    const createAdvisor = async (event) => {
        clearUnsavedChanges();
        let response = await onSubmit(event);
        onSuccess(response.data);
    };

    return (
        <BasicModal
            isOpen={isOpen}
            size="w-full"
            onClose={onClose}
            header={
                <BasicModalHeader
                    onClose={onClose}
                    onSave={createAdvisor}
                    title={title}
                />
            }
        >
            <form
                onSubmit={createAdvisor}
                className="max-w-3xl mx-auto"
                autoComplete="off"
            >
                <AdvisorProfileForm
                    advisor={input}
                    updateValue={updateValue}
                    errors={errors}
                    view="create"
                />
            </form>
        </BasicModal>
    );
}
