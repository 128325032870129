import React from "react";
import { Link, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function Tabs({ items = [], selectedIndex = -1, persistSearch = false }) {
    const baseTabStyle =
        "mr-8 group inline-flex items-center py-4 pb-2 px-1 border-b-2 border-transparent font-medium text-sm leading-5 focus:outline-none";
    const inactiveTabStyle =
        "text-gray-500 hover:text-gray-700 hover:border-gray-300 focus:text-gray-700 focus:border-gray-300";
    const activeTabStyle =
        "text-brand focus:text-brand focus:border-brand border-brand";
    const baseIconStyle = "-ml-0.5 mr-2 h-5 w-5";
    const inactiveIconStyle =
        "text-gray-400 group-hover:text-gray-500 group-focus:text-gray-600";
    const activeIconStyle = "text-gray-500 group-focus:text-gray-700";
    const disabledTabStyle =
        "text-gray-400 group-focus:text-gray-700 pointer-events-none opacity-75";

    const location = useLocation();

    const isItemSelected = (item) =>
        item.active ||
        (item.onClick
            ? selectedIndex > -1 && selectedIndex === items.indexOf(item)
            : location.pathname === item.to);

    const activeLinkClass = (item) => {
        if (item.disabled) {
            return baseTabStyle.concat(" ").concat(disabledTabStyle);
        }

        if (isItemSelected(item)) {
            return baseTabStyle.concat(" ").concat(activeTabStyle);
        }

        return baseTabStyle.concat(" ").concat(inactiveTabStyle);
    };

    const activeIconClass = (item) => {
        if (isItemSelected(item)) {
            return baseIconStyle.concat(" ").concat(activeIconStyle);
        }

        return baseIconStyle.concat(" ").concat(inactiveIconStyle);
    };

    return (
        <div className="border-b border-gray-200 mb-8 -my-4">
            <nav className="flex -mb-px">
                {items.map((item, idx) => {
                    const options = {};
                    if (item.onClick) {
                        options.onClick = item.onClick;
                        options.to = "#";
                    } else {
                        options.to = { pathname: item.to };
                        if (persistSearch) {
                            options.to.search = window.location.search;
                            options.to.hash = window.location.hash;
                        }
                    }
                    return (
                        <Link
                            {...options}
                            className={activeLinkClass(item)}
                            key={idx}
                        >
                            {item.icon && (
                                <FontAwesomeIcon
                                    icon={item.icon}
                                    className={activeIconClass(item)}
                                />
                            )}
                            <span>{item.label}</span>
                        </Link>
                    );
                })}
            </nav>
        </div>
    );
}

export default Tabs;
