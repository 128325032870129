import React, { useState } from "react";
import { Link } from "react-router-dom";
import Spinner from "../../../components/Spinner";
import TableSortHeader from "../../../components/TableSortHeader";
import SearchInput from "../../../components/SearchInput";
import useDebounce from "../../../hooks/useDebounce";
import { formatDate } from "../../../util/TextUtil";
import Button from "../../../components/Button";
import Forbidden from "components/Forbidden";
import useQuestionnaires from "hooks/useQuestionnaires";
import {
    deleteClientQuestionnaires,
    storeClientQuestionnaires,
} from "services/QuestionnaireService";
import { useQueryClient } from "react-query";
import useClientQuestionnaires from "hooks/useClientQuestionnaires";
import SelectInput from "components/SelectInput";
import Dropdown, { DropdownItem } from "components/Dropdown";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faEdit,
    faEllipsisH,
    faTrash,
} from "@fortawesome/free-solid-svg-icons";
import useAuth from "hooks/useAuth";
import { useToasts } from "react-toast-notifications";
import BasicModal from "components/ModalBasic";

export default function ClientQuestionnaireTable({
    client,
    clientPath,
    showDisabled,
    showModal,
    setShowModal,
}) {
    const { user } = useAuth();
    const [orderBy, setOrderBy] = useState("updated_at");
    const [sortDir, setSortDir] = useState("desc");
    const [search, setSearch] = useState("");
    const debouncedSearch = useDebounce(search, 250);
    const { addToast } = useToasts();
    const queryClient = useQueryClient();
    const {
        data: questionnaires,
        isLoading,
        error,
    } = useClientQuestionnaires({
        clientId: client.id,
        limit: 20,
        sort: sortDir === "desc" ? "-" + orderBy : orderBy,
        "filter[search]": debouncedSearch,
        showDisabled,
    });

    const handleDelete = async (id) => {
        try {
            await deleteClientQuestionnaires({
                clientId: client.id,
                questionnaireId: id,
            });
            queryClient.invalidateQueries("fetchQuestionnairesForClient");
        } catch (error) {
            addToast(error?.response?.data?.message || "Something went wrong", {
                type: "error",
            });
        }
    };

    if (error?.status === 403) {
        return <Forbidden />;
    }

    if (isLoading) {
        return <Spinner />;
    }

    return (
        <>
            <div className="mb-4">
                <SearchInput
                    label={""}
                    searchValue={search}
                    onSearchChange={(e) => setSearch(e.target.value)}
                />
            </div>
            {questionnaires.length ? (
                <>
                    <table className="w-full text-left">
                        <thead>
                            <tr>
                                <TableSortHeader
                                    field="title"
                                    sortField={orderBy}
                                    sortDir={sortDir}
                                    changeSort={(column) => {
                                        setSortDir(
                                            sortDir === "asc" ? "desc" : "asc"
                                        );
                                        setOrderBy(column);
                                    }}
                                >
                                    Name
                                </TableSortHeader>
                                <TableSortHeader
                                    field="updated_at"
                                    sortField={orderBy}
                                    sortDir={sortDir}
                                    changeSort={(column) => {
                                        setSortDir(
                                            sortDir === "asc" ? "desc" : "asc"
                                        );
                                        setOrderBy(column);
                                    }}
                                >
                                    Last Updated
                                </TableSortHeader>
                                <th className="text-right">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {questionnaires.map((questionnnaire, idx) => (
                                <tr
                                    key={idx}
                                    className={
                                        questionnnaire.deleted_at ? "" : ""
                                    }
                                >
                                    <td>
                                        <Link
                                            to={`${clientPath}/questionnaires/${questionnnaire.id}/view`}
                                        >{`${questionnnaire.title}`}</Link>
                                    </td>
                                    <td>
                                        {formatDate(questionnnaire.updated_at)}
                                    </td>
                                    <td className="text-right">
                                        <Dropdown
                                            position="right"
                                            toggleClass="px-3 py-1"
                                            toggleContent={
                                                <FontAwesomeIcon
                                                    icon={faEllipsisH}
                                                />
                                            }
                                        >
                                            <DropdownItem
                                                label="View"
                                                icon={faEdit}
                                                link={`${clientPath}/questionnaires/${questionnnaire.id}/edit`}
                                            />
                                            {user.type === "member" &&
                                                questionnnaire.status ===
                                                    "private" && (
                                                    <DropdownItem
                                                        label="Hide From Client"
                                                        icon={faTrash}
                                                        handleClick={() =>
                                                            handleDelete(
                                                                questionnnaire.id
                                                            )
                                                        }
                                                    />
                                                )}
                                        </Dropdown>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </>
            ) : (
                <div className="text-gray-400">
                    The are no questionnaires available to you at this time.
                </div>
            )}
            {user.type === "member" && (
                <AssignQuestionnaireToClient
                    clientId={client.id}
                    excludeIds={questionnaires.map((q) => q.id)}
                    showModal={showModal}
                    setShowModal={setShowModal}
                />
            )}
        </>
    );
}

const AssignQuestionnaireToClient = ({
    clientId,
    excludeIds,
    showModal,
    setShowModal,
}) => {
    const [questionnaire, setQuestionnaire] = useState("");
    const queryClient = useQueryClient();
    const { data } = useQuestionnaires({
        "filter[status]": "private",
        "filter[excludeIds]": excludeIds,
    });

    const handleSubmit = async (event) => {
        event.preventDefault();
        await storeClientQuestionnaires({
            clientId,
            questionnaireId: questionnaire,
        });
        queryClient.invalidateQueries("fetchQuestionnairesForClient");
        setShowModal(false);
        setQuestionnaire("");
    };

    if (!data) {
        return <></>;
    }

    return (
        <BasicModal
            isOpen={showModal}
            onClose={() => setShowModal(false)}
            title="Assign Questionnaire To Client"
            size="xl"
        >
            <form
                onSubmit={handleSubmit}
                className="flex items-center space-x-2 pb-4"
            >
                <div className="flex-1">
                    <SelectInput
                        label=""
                        id="questionnaire"
                        name="questionnaire"
                        value={questionnaire || ""}
                        options={data.data.map((q) => ({
                            value: q.id,
                            label: q.title,
                        }))}
                        onChange={(e) => setQuestionnaire(e.target.value)}
                        defaultOption="Select Questionnaire"
                        defaultOptionDisabled={true}
                        defaultOptionValue={""}
                    />
                </div>
                <div className="w-32">
                    <Button type="submit" text="Assign" />
                </div>
            </form>
        </BasicModal>
    );
};
