import React from "react";
import TextInput from "../../components/TextInput";
import Button from "../../components/Button";
import Stepper from "./partials/Stepper";
import useRegistrationContext from "../../hooks/useRegistrationContext";
import { Redirect } from "react-router-dom";

export default function OrganizationStep1() {
    const {
        errors,
        userInput,
        updateUserInput,
        emailOrPasswordMissing,
        submitUpdateProfileForm,
    } = useRegistrationContext();

    if (emailOrPasswordMissing()) {
        return <Redirect to={"/register/email-password"} />;
    }

    return (
        <div className="max-w-3xl mx-auto p-10">
            <Stepper step={2} />

            <h1 className="text-2xl mb-8 text-center">
                Tell us about yourself.
            </h1>

            <form onSubmit={submitUpdateProfileForm}>
                <div className="md:flex md:flex-wrap mb-6">
                    <div className="flex-1 mb-6 md:mr-3 md:mb-0">
                        <TextInput
                            name="first_name"
                            label="First Name"
                            id="first_name"
                            value={userInput.first_name}
                            onChange={updateUserInput}
                            error={errors.first_name}
                            autoFocus={true}
                        />
                    </div>
                    <div className="flex-1 mb-6 md:ml-3 md:mb-0">
                        <TextInput
                            name="last_name"
                            label="Last Name"
                            id="last_name"
                            value={userInput.last_name}
                            onChange={updateUserInput}
                            error={errors.last_name}
                        />
                    </div>
                </div>

                <div className="md:flex md:flex-wrap">
                    <div className="flex-1 mb-6 md:mr-3 md:mb-0">
                        <TextInput
                            name="title"
                            label="Title"
                            id="title"
                            value={userInput.title}
                            onChange={updateUserInput}
                            error={errors.title}
                        />
                    </div>
                    <div className="flex-1 mb-6 md:ml-3 md:mb-0">
                        <TextInput
                            name="phone"
                            type="tel"
                            label="Phone"
                            id="phone"
                            placeholder="xxx-xxx-xxxx"
                            value={userInput.phone}
                            onChange={updateUserInput}
                            error={errors.phone}
                        />
                    </div>
                </div>
                <div className="flex mt-10 justify-center">
                    <Button
                        type="submit"
                        text="Next"
                        className="w-64 bg-gray-800 hover:bg-gray-700 text-white border border-gray-800"
                    />
                </div>
            </form>
        </div>
    );
}
