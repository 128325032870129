import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Spinner from "./Spinner";

export default function ButtonGroup({
    options = [],
    isLoading = -1,
    selected = -1,
    onClick = null,
}) {
    const baseStyle = `relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm leading-5 font-medium text-gray-700 focus:z-10 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-100 active:text-gray-700 transition ease-in-out duration-150 hover:text-gray-500`;
    const soloStyle = `${baseStyle} rounded-md`;
    const firstStyle = `${baseStyle} rounded-l-md`;
    const middleStyle = `${baseStyle} -ml-px`;
    const lastStyle = `${baseStyle} -ml-px rounded-r-md`;

    const selectedStyle = `bg-gray-100`;

    return (
        <span className="relative z-0 inline-flex shadow-sm rounded-md">
            {options.map((option, idx) => {
                let style = soloStyle;
                if (options.length > 1) {
                    style = middleStyle;
                    if (idx === 0) {
                        style = firstStyle;
                    } else if (idx === options.length - 1) {
                        style = lastStyle;
                    }
                }
                let text =
                    typeof option === "string" ? option : option.title || "";
                let icon = typeof option === "string" ? null : option.icon;

                let showLoading = isLoading === idx;

                return (
                    <button
                        key={idx}
                        type="button"
                        className={`${style} ${
                            selected === idx ? selectedStyle : ""
                        }`}
                        onClick={(e) => onClick(option, idx, e)}
                    >
                        {icon && (
                            <div
                                className={`${showLoading ? "invisible" : ""}`}
                            >
                                <FontAwesomeIcon
                                    icon={icon}
                                    className={text ? "mr-2" : "mr-0"}
                                />
                            </div>
                        )}
                        {showLoading && (
                            <div className="absolute left-0 right-0">
                                <Spinner color="#000" width="20" height="20" />
                            </div>
                        )}
                        <span className={`${showLoading ? "invisible" : ""}`}>
                            {text}
                        </span>
                    </button>
                );
            })}
        </span>
    );
}
