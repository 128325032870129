import { useCallback, useState, useEffect } from "react";
import { useToasts } from "react-toast-notifications";
import useClient from "../../../hooks/useClient";
import Api from "../../../services/Api";
import useIceContext from "./useIceContext";

export default function useIceVault() {
    const { client } = useClient();
    const { isSpouse } = useIceContext();
    const [isLoading, setIsLoading] = useState(false);
    const [isCreating, setIsCreating] = useState(false);
    const [clientIceVaultId, setClientIceVaultId] = useState(0);
    const [spouseIceVaultId, setSpouseIceVaultId] = useState(0);
    const [needsRefreshed, setNeedsRefreshed] = useState(0);

    const { addToast } = useToasts();

    const currentName = isSpouse ? client.spouse.full_name : client.full_name;

    const initVault = useCallback(async () => {
        try {
            if (isCreating) return;

            setIsCreating(true);
            const data = {
                name: "ICE - " + currentName,
                ice_for_id: isSpouse ? client.spouse.id : client.id,
                ice_for_type: isSpouse ? "Spouse" : "Client",
            };

            await Api.post("clients/" + client.id + "/ice/vault", data);
            setNeedsRefreshed((needsRefreshed) => needsRefreshed + 1);
        } catch (error) {
            console.error(error);
            if (error.response?.status === 422) {
                addToast(error.response.data.message, {
                    type: "error",
                });
                return;
            }
            const message =
                error.response?.data?.message ||
                "An error occurred creating the vault.";
            addToast(`${message} (${error.response?.status || -1})`, {
                type: "error",
            });
        }
        setIsCreating(false);
    }, [client.id, currentName, isCreating, isSpouse]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                setIsLoading(true);
                const { data } = await Api.get(
                    "clients/" + client.id + "/ice/vault"
                );

                data.data.forEach((vault) => {
                    if (
                        vault.ice_for_id === client.id &&
                        vault.ice_for_type === "Client"
                    ) {
                        setClientIceVaultId(vault.id);
                    }
                    // TODO: Grab relationships
                    if (
                        client.spouse &&
                        vault.ice_for_id === client.spouse.id &&
                        (vault.ice_for_type === "Relationship" ||
                            vault.ice_for_type === "Spouse")
                    ) {
                        setSpouseIceVaultId(vault.id);
                    }
                });

                console.log(data);
            } catch (error) {
                console.error(error);
            }
            setIsLoading(false);
        };
        fetchData();
    }, [needsRefreshed, client.spouse, client.id]);

    return {
        clientIceVaultId,
        spouseIceVaultId,
        initVault,
        isLoading,
        isCreating,
        currentName,
    };
}
