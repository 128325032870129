import React, { useEffect, useState } from "react";
import SelectInput from "../../../components/SelectInput";
import TextInput from "../../../components/TextInput";
import Checkbox from "../../../components/Checkbox";
import TextArea from "../../../components/TextArea";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import Fieldset from "../../../components/Fieldset";
import ResourceSelect from "components/ResourceSelect";
import useResourceTypeFetch from "hooks/useResourceTypeFetch";
import { useLocation } from "react-router";

export const allergies = [
    "penicillin",
    "sulfa",
    "nuts",
    "bee_stings",
    "latex",
    "shellfish",
];
export const conditions = [
    "alzheimer",
    "arthritis",
    "asthma",
    "diabetes",
    "stroke_history",
    "cancer",
    "cancer_type",
    "cancer_survivor",
    "hearing_loss",
    "heart_disease",
    "high_blood_pressure",
    "lung_disease",
    "low_vision",
];

export default function MedicalHistory({ items, setMedicalHistory, client }) {
    const location = useLocation();
    const isSpouse = new URLSearchParams(location.search).get("spouse") === "1";

    const member = {
        resourceUuid: "Client:" + client.id,
        doctors: [],
        pharmacies: [],
        medications: [],
    };

    const doctor = {
        name: "",
        specialty: "",
        address: "",
        phone: "",
        email: "",
        website: "",
    };

    const pharmacy = {
        name: "",
        address: "",
        phone: "",
        email: "",
        website: "",
    };

    const medication = {
        name: "",
        dosage: "",
        taken: 0,
        am: 0,
        pm: 0,
        refills: 0,
        refill_frequency: "None",
    };

    const [resourceTypes] = useState(["Client", "Relationship"]);

    const { data: relationships, isLoading: isLoadingRelationships } =
        useResourceTypeFetch(
            {
                resourceTypes,
                client,
                excludedOptions: [],
                emptyStateLabel: "",
                emptyStateValue: "",
                emptyState: false,
            },
            {
                initialData: [
                    {
                        value: "",
                        label: "Loading Options",
                    },
                ],
            }
        );

    const isLoading =
        isLoadingRelationships ||
        (relationships.length === 1 && relationships[0].value === "");

    const [selectedMemberName, setSelectedMemberName] = useState("");
    const [selectedMember, setSelectedMember] = useState(null);
    const [selectedMemberResourceId, setSelectedMemberResourceId] =
        useState("");

    function save(updatedMember) {
        let newMembers = items.map((member) => {
            if (member.resourceUuid === updatedMember.resourceUuid) {
                return updatedMember;
            }
            return member;
        });

        setMedicalHistory(newMembers);
    }

    const changeMember = (e) => {
        const resourceUuid = e.target.value;
        setSelectedMemberResourceId(resourceUuid);

        const selected = items.filter((i) => i.resourceUuid === resourceUuid);
        let selectedName = "N/A";
        const foundRelationships = relationships
            .flatMap((o) => o.options)
            .filter((r) => r)
            .filter(
                (r) => `${r.resource_type}:${r.resource_id}` === resourceUuid
            );
        if (foundRelationships.length > 0) {
            selectedName = foundRelationships[0].full_name;
        }

        if (selected.length) {
            setSelectedMember(selected[0]);
            setSelectedMemberName(selectedName);
        } else {
            let newMember = {
                ...member,
                resourceUuid,
            };
            setMedicalHistory([...items, newMember]);
            setSelectedMember(newMember);
            setSelectedMemberName(selectedName);
        }
    };

    // Set initial Value
    useEffect(() => {
        if (!selectedMember && !isLoading) {
            let targetUuid = "Client:" + client.id;

            if (client.spouse && isSpouse) {
                targetUuid = "Spouse:" + client.spouse.id;
            }
            changeMember({
                target: {
                    value: targetUuid,
                },
            });
        }
    }, [
        selectedMember,
        client.spouse,
        setMedicalHistory,
        items,
        member,
        relationships,
    ]);

    // Fix for older values
    useEffect(() => {
        let oldValues = items.filter((r) => !r.resourceUuid);
        if (oldValues.length) {
            const newValues = items.filter((r) => !!r.resourceUuid);
            oldValues = oldValues
                .slice(0, client.spouse ? 2 : 1)
                .map((values, i) => ({
                    ...values,
                    resourceUuid:
                        i === 0
                            ? "Client:" + client.id
                            : "Spouse:" + client.spouse.id,
                }));
            setMedicalHistory([...oldValues, ...newValues]);
        }
    }, [items, setMedicalHistory]);

    function appendDoctor() {
        if (selectedMember.doctors.length < 10) {
            let updatedMember = {
                ...selectedMember,
                doctors: [...selectedMember.doctors, doctor],
            };
            setSelectedMember(updatedMember);
            save(updatedMember);
        }
    }

    function removeDoctor(idx) {
        const updatedMember = {
            ...selectedMember,
            doctors: selectedMember.doctors.filter(
                (doctor, idxs) => idxs !== idx
            ),
        };
        setSelectedMember(updatedMember);
        save(updatedMember);
    }

    const updateDoctor = (event, idx) => {
        const updatedDoctors = selectedMember.doctors.map((doctor, sidx) => {
            if (idx !== sidx) return doctor;
            return { ...doctor, [event.target.name]: event.target.value };
        });
        let updatedMember = { ...selectedMember, doctors: updatedDoctors };
        setSelectedMember(updatedMember);
        save(updatedMember);
    };

    function appendPharmacy() {
        if (selectedMember.pharmacies.length < 10) {
            let updatedMember = {
                ...selectedMember,
                pharmacies: [...selectedMember.pharmacies, pharmacy],
            };
            setSelectedMember(updatedMember);
            save(updatedMember);
        }
    }

    function removePharmacy(idx) {
        const updatedMember = {
            ...selectedMember,
            pharmacies: selectedMember.pharmacies.filter(
                (pharmacy, idxs) => idxs !== idx
            ),
        };
        setSelectedMember(updatedMember);
        save(updatedMember);
    }

    const updatePharmacy = (event, idx) => {
        const updatedPharmacies = selectedMember.pharmacies.map(
            (pharmacy, sidx) => {
                if (idx !== sidx) return pharmacy;
                return { ...pharmacy, [event.target.name]: event.target.value };
            }
        );
        let updatedMember = {
            ...selectedMember,
            pharmacies: updatedPharmacies,
        };
        setSelectedMember(updatedMember);
        save(updatedMember);
    };

    function appendMedication() {
        if (selectedMember.medications.length < 10) {
            let updatedMember = {
                ...selectedMember,
                medications: [...selectedMember.medications, medication],
            };
            setSelectedMember(updatedMember);
            save(updatedMember);
        }
    }

    function removeMedication(idx) {
        const updatedMember = {
            ...selectedMember,
            medications: selectedMember.medications.filter(
                (medication, idxs) => idxs !== idx
            ),
        };
        setSelectedMember(updatedMember);
        save(updatedMember);
    }

    const updateMedication = (event, idx) => {
        const updatedMedications = selectedMember.medications.map(
            (medication, sidx) => {
                if (idx !== sidx) return medication;
                return {
                    ...medication,
                    [event.target.name]: event.target.value,
                };
            }
        );
        let updatedMember = {
            ...selectedMember,
            medications: updatedMedications,
        };
        setSelectedMember(updatedMember);
        save(updatedMember);
    };

    const updateValue = (e) => {
        let updatedMember = { ...selectedMember };
        updatedMember[e.target.name] = e.target.value;
        setSelectedMember((selectedMember) => ({
            ...selectedMember,
            ...updatedMember,
        }));
        save(updatedMember);
    };

    return (
        <Fieldset legend="Medical History">
            <div className="mb-10 flex items-center space-x-3">
                <div className="flex-1">
                    <ResourceSelect
                        name="resourceUuid"
                        resourceType={resourceTypes}
                        value={selectedMemberResourceId || ""}
                        label="Selected Person"
                        client={client}
                        onChange={changeMember}
                    />
                </div>
            </div>

            {selectedMember && (
                <div>
                    <div className="mb-10 border-b border-gray-100 pb-6">
                        <h2 className="mb-4 font-bold text-gray-500">
                            {selectedMemberName}&apos;s Doctors
                        </h2>
                        {Array.isArray(selectedMember.doctors) &&
                            selectedMember.doctors.map((_doctor, idx) => (
                                <div
                                    className="mb-10 border-b border-gray-100"
                                    key={idx}
                                >
                                    <div className="mb-6">
                                        <TextInput
                                            label="Name"
                                            name="name"
                                            value={
                                                selectedMember.doctors[idx][
                                                    "name"
                                                ]
                                            }
                                            onChange={(e) =>
                                                updateDoctor(e, idx)
                                            }
                                        />
                                    </div>
                                    <div className="flex space-x-3 mb-6">
                                        <div className="flex-1">
                                            <TextInput
                                                label="Specialty"
                                                name="specialty"
                                                value={
                                                    selectedMember.doctors[idx][
                                                        "specialty"
                                                    ]
                                                }
                                                onChange={(e) =>
                                                    updateDoctor(e, idx)
                                                }
                                            />
                                        </div>
                                        <div className="flex-1">
                                            <TextInput
                                                label="Phone"
                                                name="phone"
                                                value={
                                                    selectedMember.doctors[idx][
                                                        "phone"
                                                    ]
                                                }
                                                onChange={(e) =>
                                                    updateDoctor(e, idx)
                                                }
                                            />
                                        </div>
                                    </div>
                                    <div className="mb-6">
                                        <TextInput
                                            label="Address"
                                            name="address"
                                            value={
                                                selectedMember.doctors[idx][
                                                    "address"
                                                ]
                                            }
                                            onChange={(e) =>
                                                updateDoctor(e, idx)
                                            }
                                        />
                                    </div>
                                    <div className="flex space-x-3 mb-6">
                                        <div className="flex-1">
                                            <TextInput
                                                type="email"
                                                label="Email"
                                                name="email"
                                                value={
                                                    selectedMember.doctors[idx][
                                                        "email"
                                                    ]
                                                }
                                                onChange={(e) =>
                                                    updateDoctor(e, idx)
                                                }
                                            />
                                        </div>
                                        <div className="flex-1">
                                            <TextInput
                                                label="Website"
                                                name="website"
                                                value={
                                                    selectedMember.doctors[idx][
                                                        "website"
                                                    ]
                                                }
                                                onChange={(e) =>
                                                    updateDoctor(e, idx)
                                                }
                                            />
                                        </div>
                                    </div>
                                    <div className="flex justify-end mb-3">
                                        <button
                                            type="button"
                                            onClick={() => removeDoctor(idx)}
                                            className="text-gray-500 text-sm hover:text-red-500"
                                        >
                                            <FontAwesomeIcon
                                                icon={faTimesCircle}
                                                className="mr-1"
                                            />{" "}
                                            Remove Doctor
                                        </button>
                                    </div>
                                </div>
                            ))}
                        <div className="flex">
                            <button
                                type="button"
                                onClick={(e) => appendDoctor(e)}
                                className="text-gray-500 text-sm hover:text-brand"
                            >
                                <FontAwesomeIcon
                                    icon={faPlusCircle}
                                    className="mr-1"
                                />{" "}
                                Add Doctor
                            </button>
                        </div>
                    </div>

                    <div className="mb-10 border-b border-gray-100 pb-6">
                        <h2 className="mb-4 font-bold text-gray-500">
                            {selectedMemberName}&apos;s Medical Conditions
                        </h2>
                        <div className="grid grid-cols-3 gap-3">
                            <div>
                                <div className="mb-2">
                                    <Checkbox
                                        id="alzheimer"
                                        label="Alzheimer's"
                                        name="alzheimer"
                                        checked={
                                            parseInt(
                                                selectedMember["alzheimer"]
                                            ) || 0
                                        }
                                        value={1}
                                        onChange={updateValue}
                                    />
                                </div>
                                <div className="mb-2">
                                    <Checkbox
                                        id="arthritis"
                                        label="Arthritis"
                                        name="arthritis"
                                        checked={
                                            parseInt(
                                                selectedMember["arthritis"]
                                            ) || 0
                                        }
                                        value={1}
                                        onChange={updateValue}
                                    />
                                </div>

                                <div className="mb-2">
                                    <Checkbox
                                        id="asthma"
                                        label="Asthma"
                                        name="asthma"
                                        checked={
                                            parseInt(
                                                selectedMember["asthma"]
                                            ) || 0
                                        }
                                        value={1}
                                        onChange={updateValue}
                                    />
                                </div>

                                <div className="mb-2">
                                    <Checkbox
                                        id="diabetes"
                                        label="Diabetes"
                                        name="diabetes"
                                        checked={
                                            parseInt(
                                                selectedMember["diabetes"]
                                            ) || 0
                                        }
                                        value={1}
                                        onChange={updateValue}
                                    />
                                </div>

                                <div className="mb-2">
                                    <Checkbox
                                        id="stroke_history"
                                        label="Stroke History"
                                        name="stroke_history"
                                        checked={
                                            parseInt(
                                                selectedMember["stroke_history"]
                                            ) || 0
                                        }
                                        value={1}
                                        onChange={updateValue}
                                    />
                                </div>

                                <div className="mb-4">
                                    <Checkbox
                                        id="cancer"
                                        label="Cancer"
                                        name="cancer"
                                        checked={
                                            parseInt(
                                                selectedMember["cancer"]
                                            ) || 0
                                        }
                                        value={1}
                                        onChange={updateValue}
                                    />
                                </div>

                                {parseInt(selectedMember["cancer"]) === 1 && (
                                    <div className="w-48">
                                        <TextInput
                                            label="Cancer Type"
                                            name="cancer_type"
                                            value={
                                                selectedMember["cancer_type"]
                                            }
                                            onChange={updateValue}
                                        />
                                    </div>
                                )}
                            </div>

                            <div>
                                <div className="mb-2">
                                    <Checkbox
                                        id="cancer_survivor"
                                        label="Cancer Survivor"
                                        name="cancer_survivor"
                                        checked={
                                            parseInt(
                                                selectedMember[
                                                    "cancer_survivor"
                                                ]
                                            ) || 0
                                        }
                                        value={1}
                                        onChange={updateValue}
                                    />
                                </div>

                                <div className="mb-2">
                                    <Checkbox
                                        id="hearing_loss"
                                        label="Hearing Loss"
                                        name="hearing_loss"
                                        checked={
                                            parseInt(
                                                selectedMember["hearing_loss"]
                                            ) || 0
                                        }
                                        value={1}
                                        onChange={updateValue}
                                    />
                                </div>

                                <div className="mb-2">
                                    <Checkbox
                                        id="heart_disease"
                                        label="Heart disease"
                                        name="heart_disease"
                                        checked={
                                            parseInt(
                                                selectedMember["heart_disease"]
                                            ) || 0
                                        }
                                        value={1}
                                        onChange={updateValue}
                                    />
                                </div>

                                <div className="mb-2">
                                    <Checkbox
                                        id="high_blood_pressure"
                                        label="High blood pressure"
                                        name="high_blood_pressure"
                                        checked={
                                            parseInt(
                                                selectedMember[
                                                    "high_blood_pressure"
                                                ]
                                            ) || 0
                                        }
                                        value={1}
                                        onChange={updateValue}
                                    />
                                </div>

                                <div className="mb-2">
                                    <Checkbox
                                        id="lung_disease"
                                        label="Lung Disease"
                                        name="lung_disease"
                                        checked={
                                            parseInt(
                                                selectedMember["lung_disease"]
                                            ) || 0
                                        }
                                        value={1}
                                        onChange={updateValue}
                                    />
                                </div>

                                <div className="mb-2">
                                    <Checkbox
                                        id="Low vision"
                                        label="Low Vision"
                                        name="low_vision"
                                        checked={
                                            parseInt(
                                                selectedMember["low_vision"]
                                            ) || 0
                                        }
                                        value={1}
                                        onChange={updateValue}
                                    />
                                </div>
                            </div>

                            <div>
                                <TextArea
                                    id="custom"
                                    label="Other Medical History"
                                    name="custom"
                                    rows="3"
                                    value={selectedMember["custom"]}
                                    onChange={updateValue}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="mb-10 border-b border-gray-100 pb-6">
                        <h2 className="mt-4 mb-4 font-bold text-gray-500">
                            {selectedMemberName}&apos;s Allergies
                        </h2>
                        <div className="grid grid-cols-3 gap-3">
                            <div>
                                <div className="mb-2">
                                    <Checkbox
                                        id="penicillin"
                                        label="Penicillin"
                                        name="penicillin"
                                        checked={
                                            parseInt(
                                                selectedMember["penicillin"]
                                            ) || 0
                                        }
                                        value={1}
                                        onChange={updateValue}
                                    />
                                </div>
                                <div className="mb-2">
                                    <Checkbox
                                        id="sulfa"
                                        label="Sulfa"
                                        name="sulfa"
                                        checked={
                                            parseInt(selectedMember["sulfa"]) ||
                                            0
                                        }
                                        value={1}
                                        onChange={updateValue}
                                    />
                                </div>
                                <div className="mb-2">
                                    <Checkbox
                                        id="nuts"
                                        label="Nuts"
                                        name="nuts"
                                        checked={
                                            parseInt(selectedMember["nuts"]) ||
                                            0
                                        }
                                        value={1}
                                        onChange={updateValue}
                                    />
                                </div>
                            </div>

                            <div>
                                <div className="mb-2">
                                    <Checkbox
                                        id="bee_stings"
                                        label="Bee Stings"
                                        name="bee_stings"
                                        checked={
                                            parseInt(
                                                selectedMember["bee_stings"]
                                            ) || 0
                                        }
                                        value={1}
                                        onChange={updateValue}
                                    />
                                </div>
                                <div className="mb-2">
                                    <Checkbox
                                        id="latex"
                                        label="Latex"
                                        name="latex"
                                        checked={
                                            parseInt(selectedMember["latex"]) ||
                                            0
                                        }
                                        value={1}
                                        onChange={updateValue}
                                    />
                                </div>
                                <div className="mb-2">
                                    <Checkbox
                                        id="shellfish"
                                        label="Shellfish"
                                        name="shellfish"
                                        checked={
                                            parseInt(
                                                selectedMember["shellfish"]
                                            ) || 0
                                        }
                                        value={1}
                                        onChange={updateValue}
                                    />
                                </div>
                            </div>

                            <div>
                                <TextArea
                                    id="custom_allergies"
                                    label="Other Allergies"
                                    name="custom_allergies"
                                    rows="3"
                                    value={
                                        selectedMember["custom_allergies"] || ""
                                    }
                                    onChange={updateValue}
                                />
                            </div>
                        </div>

                        <div className="mt-6">
                            <TextArea
                                id="surgeries"
                                label="Past Surgeries/Dates of Surgeries"
                                name="surgeries"
                                rows="5"
                                value={selectedMember["surgeries"] || ""}
                                onChange={updateValue}
                            />
                        </div>
                    </div>

                    <div className="mb-10 border-b border-gray-100 pb-6">
                        <h2 className="mt-4 mb-4 font-bold text-gray-500">
                            {selectedMemberName}&apos;s Current Medications
                        </h2>
                        <div>
                            {selectedMember.medications.length > 0 && (
                                <table>
                                    <thead>
                                        <tr>
                                            <th className="text-left">Name</th>
                                            <th className="text-left">
                                                Dosage
                                            </th>
                                            <th>Taken</th>
                                            <th>AM/PM</th>
                                            <th>Refills?</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {selectedMember.medications.map(
                                            (medication, idx) => (
                                                <tr key={idx}>
                                                    <td>
                                                        <TextInput
                                                            name="name"
                                                            value={
                                                                selectedMember
                                                                    .medications[
                                                                    idx
                                                                ]["name"]
                                                            }
                                                            onChange={(e) =>
                                                                updateMedication(
                                                                    e,
                                                                    idx
                                                                )
                                                            }
                                                        />
                                                    </td>
                                                    <td>
                                                        <TextInput
                                                            name="dosage"
                                                            value={
                                                                selectedMember
                                                                    .medications[
                                                                    idx
                                                                ]["dosage"]
                                                            }
                                                            onChange={(e) =>
                                                                updateMedication(
                                                                    e,
                                                                    idx
                                                                )
                                                            }
                                                        />
                                                    </td>
                                                    <td className="text-center">
                                                        <div className="flex justify-center">
                                                            <Checkbox
                                                                name="taken"
                                                                checked={
                                                                    parseInt(
                                                                        selectedMember
                                                                            .medications[
                                                                            idx
                                                                        ][
                                                                            "taken"
                                                                        ]
                                                                    ) || 0
                                                                }
                                                                value={1}
                                                                onChange={(e) =>
                                                                    updateMedication(
                                                                        e,
                                                                        idx
                                                                    )
                                                                }
                                                            />
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="flex items-center justify-center space-x-1">
                                                            <Checkbox
                                                                name="am"
                                                                checked={
                                                                    parseInt(
                                                                        selectedMember
                                                                            .medications[
                                                                            idx
                                                                        ]["am"]
                                                                    ) || 0
                                                                }
                                                                value={1}
                                                                onChange={(e) =>
                                                                    updateMedication(
                                                                        e,
                                                                        idx
                                                                    )
                                                                }
                                                            />
                                                            <Checkbox
                                                                name="pm"
                                                                checked={
                                                                    parseInt(
                                                                        selectedMember
                                                                            .medications[
                                                                            idx
                                                                        ]["pm"]
                                                                    ) || 0
                                                                }
                                                                value={1}
                                                                onChange={(e) =>
                                                                    updateMedication(
                                                                        e,
                                                                        idx
                                                                    )
                                                                }
                                                            />
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="flex items-center space-x-2">
                                                            <div className="flex-1">
                                                                <SelectInput
                                                                    options={[
                                                                        "None",
                                                                        "Monthly",
                                                                        "Quarterly",
                                                                        "Semi-Annually",
                                                                        "Annually",
                                                                        "Other",
                                                                    ]}
                                                                    name="refill_frequency"
                                                                    value={
                                                                        selectedMember
                                                                            .medications[
                                                                            idx
                                                                        ][
                                                                            "refill_frequency"
                                                                        ]
                                                                    }
                                                                    onChange={(
                                                                        e
                                                                    ) =>
                                                                        updateMedication(
                                                                            e,
                                                                            idx
                                                                        )
                                                                    }
                                                                />
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <button
                                                            type="button"
                                                            onClick={() =>
                                                                removeMedication(
                                                                    idx
                                                                )
                                                            }
                                                            className="text-gray-500 text-sm hover:text-red-500"
                                                        >
                                                            <FontAwesomeIcon
                                                                icon={
                                                                    faTimesCircle
                                                                }
                                                                className="mr-1"
                                                            />
                                                        </button>
                                                    </td>
                                                </tr>
                                            )
                                        )}
                                    </tbody>
                                </table>
                            )}
                            <div className="flex mt-6">
                                <button
                                    type="button"
                                    onClick={(e) => appendMedication(e)}
                                    className="text-gray-500 text-sm hover:text-brand"
                                >
                                    <FontAwesomeIcon
                                        icon={faPlusCircle}
                                        className="mr-1"
                                    />{" "}
                                    Add Medication
                                </button>
                            </div>
                        </div>
                    </div>

                    <div>
                        <h2 className="mb-6 font-bold text-gray-500">
                            {selectedMemberName}&apos;s Pharmacies
                        </h2>
                        {selectedMember.pharmacies.map((pharmacy, idx) => (
                            <div
                                className="mb-10 border-b border-gray-200"
                                key={idx}
                            >
                                <div className="flex space-x-3 mb-6">
                                    <div className="flex-1">
                                        <TextInput
                                            id={`pharmacy_name_${idx}`}
                                            label="Pharmacy Name"
                                            name="name"
                                            value={
                                                selectedMember.pharmacies[idx][
                                                    "name"
                                                ]
                                            }
                                            onChange={(e) =>
                                                updatePharmacy(e, idx)
                                            }
                                        />
                                    </div>
                                    <div className="flex-1">
                                        <TextInput
                                            type="tel"
                                            label="Phone"
                                            name="phone"
                                            value={
                                                selectedMember.pharmacies[idx][
                                                    "phone"
                                                ]
                                            }
                                            onChange={(e) =>
                                                updatePharmacy(e, idx)
                                            }
                                        />
                                    </div>
                                </div>
                                <div className="mb-6">
                                    <TextInput
                                        label="Address"
                                        name="address"
                                        value={
                                            selectedMember.pharmacies[idx][
                                                "address"
                                            ]
                                        }
                                        onChange={(e) => updatePharmacy(e, idx)}
                                    />
                                </div>
                                <div className="flex space-x-3 mb-6">
                                    <div className="flex-1">
                                        <TextInput
                                            type="email"
                                            label="Email"
                                            name="email"
                                            value={
                                                selectedMember.pharmacies[idx][
                                                    "email"
                                                ]
                                            }
                                            onChange={(e) =>
                                                updatePharmacy(e, idx)
                                            }
                                        />
                                    </div>
                                    <div className="flex-1">
                                        <TextInput
                                            label="Website"
                                            name="website"
                                            value={
                                                selectedMember.pharmacies[idx][
                                                    "website"
                                                ]
                                            }
                                            onChange={(e) =>
                                                updatePharmacy(e, idx)
                                            }
                                        />
                                    </div>
                                </div>
                                <div className="flex justify-end mb-3">
                                    <button
                                        type="button"
                                        onClick={() => removePharmacy(idx)}
                                        className="text-gray-500 text-sm hover:text-red-500"
                                    >
                                        <FontAwesomeIcon
                                            icon={faTimesCircle}
                                            className="mr-1"
                                        />{" "}
                                        Remove Pharamacy
                                    </button>
                                </div>
                            </div>
                        ))}
                        <div className="flex">
                            <button
                                type="button"
                                onClick={(e) => appendPharmacy(e)}
                                className="text-gray-500 text-sm hover:text-brand"
                            >
                                <FontAwesomeIcon
                                    icon={faPlusCircle}
                                    className="mr-1"
                                />{" "}
                                Add Pharamacy
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </Fieldset>
    );
}
