import React, { useReducer, useState, useEffect } from "react";
import { useToasts } from "react-toast-notifications";
import Api from "../../services/Api";
import { Redirect } from "react-router-dom";
import Spinner from "../../components/Spinner";
import ClientPageHeader, { Heading } from "../../components/ClientPageHeader";
import PageContainer from "../../components/PageContainer";
import PersonalPropertyMemoForm from "./_partials/PersonalPropertyMemoForm";
import VaultResourceFileList from "../resources/VaultResourceFileList";
import SaveButton from "../../components/SaveButton";
import useClient from "hooks/useClient";
import { useAsyncError } from "hooks/useAsyncError";

export default function EditPersonalPropertyMemo({ ...props }) {
    const throwError = useAsyncError();
    const { client, clientPath } = useClient();
    const spouseSelected = props.match.params.spouse === "spouse";
    const { addToast } = useToasts();
    const [loading, setLoading] = useState(true);
    const [errors, setErrors] = useState([]);
    const [redirectToIndex, setRedirectToIndex] = useState(false);

    const defaultInput = {
        id: props.match.params.personalPropertyMemoId,
        transfer_on: "first_death",
    };

    const [input, setInput] = useReducer(
        (state, newState) => ({ ...state, ...newState }),
        defaultInput
    );

    useEffect(() => {
        if (input.name) {
            document.title =
                "Edit Personal Property Memorandum Item: " + input.name;
        }
    }, [input.name]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                let response = await Api.get(
                    "clients/" +
                        client.id +
                        "/memorandum-of-personal-property/" +
                        props.match.params.personalPropertyMemoId
                );
                setInput(response.data.data);
                setInput({ is_spouse: spouseSelected ? 1 : 0 });
                setLoading(false);
            } catch (error) {
                setLoading(false);
                if (error.response && error.response.status === 403) {
                    throwError(error);
                }
                addToast(
                    error.response && error.response.data.message
                        ? error.response.data.message
                        : "Error fetching personal property memorandum item",
                    { type: "error" }
                );
            }
        };
        fetchData();
    }, [
        client,
        props.match.params.personalPropertyMemoId,
        addToast,
        spouseSelected,
    ]);

    function updateValue(event) {
        setInput({ [event.target.name]: event.target.value });
    }

    async function update(e) {
        e.preventDefault();
        setErrors([]);
        try {
            const body = { ...input };
            let response = await Api.put(
                "clients/" +
                    client.id +
                    "/memorandum-of-personal-property/" +
                    input.id,
                body
            );
            addToast(response.data.message);
            setRedirectToIndex(true);
        } catch (error) {
            if (error.response.data.errors) {
                setErrors(error.response.data.errors);
            } else {
                addToast(error.response.data.message, { type: "error" });
            }
        }
    }

    if (redirectToIndex) {
        return (
            <Redirect
                to={`/clients/${client.id}/personal-property-memos?spouse=${spouseSelected}`}
            />
        );
    }

    if (!input || loading) {
        return (
            <div className="h-screen w-full flex items-center justify-center">
                <Spinner />
            </div>
        );
    }

    return (
        <>
            <ClientPageHeader client={client}>
                <Heading
                    backTo={`${clientPath}/personal-property-memos?spouse=${spouseSelected}`}
                    backText="All Personal Property Memorandum Items"
                >
                    Update Personal Property Memorandum Item
                </Heading>
                <div className="flex-0">
                    <SaveButton onClick={update} />
                </div>
            </ClientPageHeader>
            <PageContainer>
                <div className="max-w-3xl mx-auto">
                    <PersonalPropertyMemoForm
                        client={client}
                        input={input}
                        errors={errors}
                        updateValue={updateValue}
                        onSubmit={update}
                    />
                </div>

                <VaultResourceFileList
                    client={client}
                    resourceType={"MemorandumOfPersonalProperty"}
                    resourceId={input.id}
                />
            </PageContainer>
        </>
    );
}
