import React, { useEffect, useState } from "react";
import useClient from "../../../hooks/useClient";

import useIceContext from "../hooks/useIceContext";
import useIceContacts from "../hooks/useIceContacts";
import Panel, { PanelHeader } from "../../../components/Panel";
import Spinner from "../../../components/Spinner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faArrowDown,
    faArrowUp,
    faExternalLinkAlt,
} from "@fortawesome/free-solid-svg-icons";
import EmptyState from "../../../components/EmptyState";

export default function ICEContacts() {
    const {
        isLoading: isLoadingContacts,
        contacts,
        availableContacts,
        availableContactsKeyed,
    } = useIceContacts();

    const { client, isLoading: isLoadingClient, clientPath } = useClient();
    const { isSpouse } = useIceContext();

    useEffect(() => {
        document.title = "ICE Contacts | " + client.full_name;
    }, [client.full_name]);

    if (isLoadingClient || isLoadingContacts) {
        return <Spinner message="Loading ICE Contacts" />;
    }

    return (
        <div className="flex flex-col space-y-4">
            <Panel>
                <PanelHeader
                    title={
                        "ICE Contacts | " +
                        (isSpouse ? client.spouse.full_name : client.full_name)
                    }
                />
                <div className="flow-root mt-6">
                    <ul className="-my-5 divide-y divide-gray-200">
                        {contacts.map((contact) => {
                            return (
                                <IceContact
                                    key={contact.key}
                                    iceContact={contact}
                                    contact={
                                        availableContactsKeyed[contact.key]
                                    }
                                />
                            );
                        })}
                    </ul>
                    {contacts.length === 0 && (
                        <EmptyState> Add Contacts from Below.</EmptyState>
                    )}
                </div>
            </Panel>
            <Panel>
                <PanelHeader
                    title={
                        "Available Contacts | " +
                        (isSpouse ? client.spouse.full_name : client.full_name)
                    }
                />
                <div className="flow-root mt-6">
                    <ul className="-my-5 divide-y divide-gray-200">
                        {(availableContacts || []).map((contact) => (
                            <Contact
                                key={contact.key}
                                contact={contact}
                                clientPath={clientPath}
                            />
                        ))}
                    </ul>
                </div>
            </Panel>
        </div>
    );
}

function Contact({ contact, clientPath }) {
    const [isLoading, setIsLoading] = useState(false);
    const { client } = useClient();
    const { addIceContact, removeIceContact, contacts } = useIceContacts();
    const { isSpouse } = useIceContext();

    const filteredContacts = contacts.filter((c) => {
        let include = false;
        if (
            isSpouse &&
            c.ice_for_type === "Spouse" &&
            c.ice_for_id === client.spouse.id &&
            c.contact_id === contact.id &&
            c.contact_type === contact.type
        ) {
            include = true;
        }
        if (
            !isSpouse &&
            c.ice_for_type === "Client" &&
            c.ice_for_id === client.id &&
            c.contact_type === contact.type &&
            c.contact_id === contact.id
        ) {
            include = true;
        }
        return include;
    });

    const iceContact = filteredContacts.length > 0 ? filteredContacts[0] : null;
    const isIceContact = iceContact !== null;

    const remove = async () => {
        if (!isIceContact) return;
        setIsLoading(true);
        try {
            removeIceContact(iceContact);
        } catch (err) {
            console.log(err);
        }
        setIsLoading(false);
    };

    const add = async () => {
        if (isIceContact) return;
        setIsLoading(true);
        try {
            addIceContact(contact);
        } catch (err) {
            console.log(err);
        }
        setIsLoading(false);
    };

    return (
        <li key={contact.key} className="py-4">
            <div className="flex items-center space-x-4">
                <div className="flex-shrink-0">
                    <div className="w-10 h-10 bg-gray-500 rounded-full flex-shrink-0 inline-flex items-center justify-center">
                        <span className="text-xl font-medium leading-none text-white">
                            {(contact.first_name || " ").charAt("0")}
                            {(contact.last_name || " ").charAt("0")}
                        </span>
                    </div>
                </div>
                <div className="flex-1 min-w-0">
                    <p className="text-sm font-medium text-gray-900 truncate">
                        <a
                            href={clientPath + contact.resource_path}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="hover:text-brand-500 text-gray-600"
                        >
                            <span className="font-bold text-xl">
                                {contact.full_name_with_relationship}
                            </span>
                            &nbsp;
                            <FontAwesomeIcon
                                icon={faExternalLinkAlt}
                                className="pb-1"
                            />
                        </a>
                    </p>
                </div>
                <div>
                    {isIceContact ? (
                        <button
                            onClick={() => remove()}
                            className="inline-flex items-center shadow-sm px-2.5 py-0.5 border border-red-400 text-sm leading-5 font-medium rounded-full text-white bg-red-600 hover:bg-red-700"
                        >
                            {!isLoading ? "Remove" : "..."}
                        </button>
                    ) : (
                        <button
                            onClick={() => add()}
                            className="inline-flex items-center shadow-sm px-2.5 py-0.5 border border-green-400 text-sm leading-5 font-medium rounded-full text-white bg-green-500 hover:bg-green-600"
                        >
                            {!isLoading ? "Add" : "..."}
                        </button>
                    )}
                </div>
            </div>
        </li>
    );
}

function IceContact({ iceContact, contact = {} }) {
    const [isLoading, setIsLoading] = useState(false);
    const { updateIceContact, removeIceContact, contacts } = useIceContacts();
    const remove = async () => {
        setIsLoading(true);
        try {
            removeIceContact(iceContact);
        } catch (err) {
            console.log(err);
        }
        setIsLoading(false);
    };
    return (
        <li key={contact.key} className="py-4">
            <div className="flex items-center space-x-4">
                <div className="flex-shrink-0">
                    <div className="w-10 h-10 bg-gray-500 rounded-full flex-shrink-0 inline-flex items-center justify-center">
                        <span className="text-xl font-medium leading-none text-white">
                            {(contact.first_name || " ").charAt("0")}
                            {(contact.last_name || " ").charAt("0")}
                        </span>
                    </div>
                </div>
                <div className="flex-1 min-w-0">
                    <p className="text-sm font-medium text-gray-900 truncate">
                        {contact.full_name_with_relationship}
                    </p>
                </div>
                <div className="space-x-2">
                    {iceContact.order > 0 && (
                        <button
                            onClick={() => {
                                iceContact.order--;
                                updateIceContact(iceContact);
                            }}
                            className="inline-flex items-center shadow-sm px-2.5 py-0.5 border border-gray-300 text-sm leading-5 font-medium rounded-full text-gray-700 bg-white hover:bg-gray-50"
                        >
                            <div className="p2">
                                <FontAwesomeIcon icon={faArrowUp} />
                            </div>
                        </button>
                    )}
                    {iceContact.order < contacts.length - 1 && (
                        <button
                            onClick={() => {
                                iceContact.order++;
                                updateIceContact(iceContact);
                            }}
                            className="inline-flex items-center shadow-sm px-2.5 py-0.5 border border-gray-300 text-sm leading-5 font-medium rounded-full text-gray-700 bg-white hover:bg-gray-50"
                        >
                            <div className="p2">
                                <FontAwesomeIcon icon={faArrowDown} />
                            </div>
                        </button>
                    )}
                    <button
                        onClick={() => remove()}
                        className="inline-flex items-center shadow-sm px-2.5 py-0.5 border border-red-400 text-sm leading-5 font-medium rounded-full text-white bg-red-600 hover:bg-red-700"
                    >
                        {!isLoading ? "Remove" : "..."}
                    </button>
                </div>
            </div>
        </li>
    );
}
