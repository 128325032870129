import React from "react";
import { Switch, Route } from "react-router-dom";

import MessageTemplatesIndex from "./MessageTemplatesIndex";
import EditMessageTemplate from "./EditMessageTemplate";

export default function MessageTemplates(parentProps) {
    return (
        <Switch>
            <Route
                exact
                path={["/organization/message-templates"]}
                render={(props) => (
                    <MessageTemplatesIndex {...parentProps} {...props} />
                )}
            />
            <Route
                path={[
                    "/organization/message-templates/:messageTemplateId/edit",
                ]}
                render={(props) => (
                    <EditMessageTemplate {...parentProps} {...props} />
                )}
            />
        </Switch>
    );
}
