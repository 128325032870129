import React, { useState, useEffect } from "react";
import Api from "../../../services/Api";
import Panel from "../../../components/Panel";
import Button from "../../../components/Button";
import { Heading } from "../../../components/ClientPageHeader";
import Spinner from "../../../components/Spinner";
import { formatBytes, formatDate } from "../../../util/TextUtil";
import Breadcrumbs from "../../../components/Breadcrumbs";
import { iconColorForVault } from "./_partials/helpers";
import { getRootVaultPath, isFileImage } from "./helpers";
import { faFile, faDownload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useToasts } from "react-toast-notifications";
import FileDownload from "js-file-download";
import ResourceAccessUserList from "../../resources/ResourceAccessUserList";
import VaultResourcesList from "../../resources/VaultResourcesList";
import useAuth from "hooks/useAuth";
import ErrorBoundary from "util/ErrorBoundary";

export default function VaultFileView({ client, loading, breadcrumbs = [] }) {
    const { user } = useAuth();
    const loggedInAsClient = user.type === "client";
    const [vaultEntry] = useState(breadcrumbs[breadcrumbs.length - 1]);

    useEffect(() => {
        document.title = "Vault | " + (client.full_name || "Loading");
    }, [client]);

    const [isLoading, setIsLoading] = useState(false);
    const { addToast } = useToasts();

    const downloadVaultEntry = async (vault) => {
        try {
            if (isLoading !== false) return;
            let response = await Api.get(
                `clients/${client.id}/vault/${vault.id}/download`,
                {},
                {
                    responseType: "blob",
                    onDownloadProgress: function (progressEvent) {
                        var percentCompleted = Math.round(
                            (progressEvent.loaded * 100) / progressEvent.total
                        );
                        setIsLoading(percentCompleted);
                    },
                }
            );
            FileDownload(response.data, `${vault.name}.${vault.extension}`);
            setIsLoading(false);
            return true;
        } catch (e) {
            console.error(e);
            if (!e.response) {
                return;
            }
            if (e.response.data.message) {
                addToast(e.response.data.message, { type: "error" });
            }
        }
    };

    const isImage = isFileImage({ extension: vaultEntry.extension });

    let src = "";
    if (isImage) {
        src = Api.makeUrl(
            `clients/${client.id}/vault/${vaultEntry.id}/download?preview=1`
        );
    }

    return (
        <div>
            <div className="flex justify-between item-center">
                <Heading>{client.full_name}&apos;s Vault</Heading>
                <div className="flex flex-row">
                    <Button
                        icon={faDownload}
                        text={
                            isLoading === false
                                ? `Download File`
                                : `Downloading (${isLoading}%)`
                        }
                        onClick={() => {
                            downloadVaultEntry(vaultEntry);
                        }}
                    />
                </div>
            </div>
            <div className="my-4">
                <hr />
            </div>
            <div>
                {loading && (
                    <div className="absolute top-0 left-0 w-full h-screen flex items-center justify-center">
                        <Spinner />
                    </div>
                )}

                {!loading && (
                    <div className="space-y-10">
                        <Panel allowOverflow={true} header="File Details">
                            <div className={`mb-4`}>
                                <Breadcrumbs
                                    defaultName={"Vault"}
                                    breadcrumbs={breadcrumbs}
                                    rootPath={getRootVaultPath(client, user)}
                                />
                            </div>

                            <div className="flex flex-row">
                                {isImage && (
                                    <div>
                                        {src && (
                                            <img
                                                src={src}
                                                className="object-contain h-auto w-56 mx-auto mb-4 rounded border-2 border-gray-200"
                                                alt="Preview"
                                            />
                                        )}
                                    </div>
                                )}
                                {!!isImage !== true && (
                                    <div className="flex justify-center align-center mb-4 text-gray-400">
                                        <div className="relative">
                                            <FontAwesomeIcon
                                                className={`${iconColorForVault(
                                                    vaultEntry
                                                )}`}
                                                icon={faFile}
                                                size="8x"
                                            />
                                            <div className="absolute inset-0 flex items-center justify-center">
                                                <div className="text-md font-bold text-white">
                                                    {vaultEntry.extension.toUpperCase()}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                <div className="flew flex-column ml-8">
                                    <div>
                                        <span className={`font-bold`}>
                                            Name:&nbsp;
                                        </span>
                                        {vaultEntry.name}
                                    </div>
                                    <div>
                                        <span className={`font-bold`}>
                                            Size:&nbsp;
                                        </span>
                                        {formatBytes(vaultEntry.size)}
                                    </div>
                                    <div>
                                        <span className={`font-bold`}>
                                            Created:&nbsp;
                                        </span>
                                        {formatDate(vaultEntry.created_at)}
                                    </div>
                                    <div>
                                        <span className={`font-bold`}>
                                            Updated:&nbsp;
                                        </span>
                                        {formatDate(vaultEntry.updated_at)}
                                    </div>
                                    <div>
                                        <span className={`font-bold`}>
                                            Uploaded By:&nbsp;
                                        </span>
                                        {vaultEntry.uploaded_by.full_name}
                                    </div>
                                    {loggedInAsClient === false && (
                                        <div>
                                            <span className={`font-bold`}>
                                                Last Viewed By:&nbsp;
                                            </span>
                                            {vaultEntry.last_viewed_by ||
                                                "Never Been Viewed"}
                                        </div>
                                    )}
                                    {loggedInAsClient === false && (
                                        <div>
                                            <span className={`font-bold`}>
                                                Last Viewed At:&nbsp;
                                            </span>
                                            {formatDate(
                                                vaultEntry.last_viewed_at,
                                                "Never Been Viewed"
                                            )}
                                        </div>
                                    )}
                                    {vaultEntry.description && (
                                        <div>
                                            <span className={`font-bold`}>
                                                Description:&nbsp;
                                            </span>
                                            {vaultEntry.description}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </Panel>

                        <ErrorBoundary>
                            <ResourceAccessUserList
                                resourceType="Vault"
                                resourceId={vaultEntry.id}
                                appearance="default"
                            />
                        </ErrorBoundary>

                        <ErrorBoundary>
                            <VaultResourcesList
                                vaultEntry={vaultEntry}
                                client={client}
                                appearance="default"
                            />
                        </ErrorBoundary>
                    </div>
                )}
            </div>
        </div>
    );
}
