import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown, faCaretUp } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { Tooltip } from "react-tippy";

export default function SidebarDropdownList({
    title,
    icon,
    isOpen,
    children,
    ...props
}) {
    const activeClass = "font-normal bg-brand text-white";
    const inActiveClass = "font-normal text-brand hover:text-brand-700";

    const expandedStyles =
        "flex-0 flex justify-center relative mr-4 rounded-r-lg";
    const collapsedStyles = "flex-0 flex justify-center relative";

    return (
        <ul>
            <li className="relative">
                <div
                    className={`${
                        props.isCollapsed ? collapsedStyles : expandedStyles
                    } ${isOpen ? activeClass : inActiveClass}`}
                >
                    <button
                        type="button"
                        onClick={(e) => props.onClick(e)}
                        className={`focus:outline-none ${
                            props.isCollapsed
                                ? "flex items-center mx-auto my-1 py-3 text-lg"
                                : "flex items-center mx-auto my-1 py-3 text-lg space-x-3 w-48"
                        }`}
                    >
                        <Tooltip
                            disabled={!props.isCollapsed}
                            title={title}
                            position="right"
                            duration="150"
                            followCursor={false}
                            distance="-64"
                        >
                            <FontAwesomeIcon
                                icon={icon}
                                className="text-2xl"
                                fixedWidth
                            />
                        </Tooltip>

                        {!props.isCollapsed && (
                            <div className="flex items-center justify-between w-full">
                                <span className="mr-1 font-light text-left">
                                    {title}
                                </span>
                                <span className="text-sm text-gray-300">
                                    <FontAwesomeIcon
                                        icon={isOpen ? faCaretUp : faCaretDown}
                                        fixedWidth
                                    />
                                </span>
                            </div>
                        )}
                    </button>
                </div>
                {isOpen && (
                    <ul
                        className={`${
                            props.isCollapsed
                                ? "z-10 absolute min-w-64 left-20 top-0 bg-white shadow-md py-2 px-4"
                                : "pl-20 mb-4 "
                        }`}
                    >
                        {children}
                    </ul>
                )}
            </li>
        </ul>
    );
}

export function SidebarDropdownListItem({ label, to, ...props }) {
    return (
        <li className="py-2 text-brand-400 hover:text-brand-600 text-sm">
            <Link to={to} {...props}>
                {label}
            </Link>
        </li>
    );
}
