import React from "react";
import InsurancePoliciesLayout from "./InsurancePoliciesLayout";
import ResourceAccessUserList from "views/resources/ResourceAccessUserList";

export default function EditInsurancePolicyAccess({
    resourceId,
    resourceType,
    currentBasePath,
    title,
}) {
    return (
        <InsurancePoliciesLayout
            currentBasePath={currentBasePath}
            title={title}
        >
            <ResourceAccessUserList
                resourceType={resourceType}
                resourceId={resourceId}
            />
        </InsurancePoliciesLayout>
    );
}
