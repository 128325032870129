import React, { useState, useEffect, useContext } from "react";
import Api from "../../../services/Api";
import Dropdown, {
    DropdownDivider,
    DropdownItem,
} from "../../../components/Dropdown";
import { Link, Redirect } from "react-router-dom";
import Spinner from "../../../components/Spinner";
import TableSortHeader from "../../../components/TableSortHeader";
import SearchInput from "../../../components/SearchInput";
import { AuthContext } from "../../../contexts/AuthContext";
import useDebounce from "../../../hooks/useDebounce";
import { formatDate } from "../../../util/TextUtil";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faTrash,
    faEdit,
    faEllipsisH,
    faUserPlus,
    faUsers,
} from "@fortawesome/free-solid-svg-icons";
import { useToasts } from "react-toast-notifications";
import Pagination from "../../../components/Pagination";
import usePagination from "../../../hooks/usePagination";
import AddTrusteeModal from "../../trustees/AddTrusteeModal";
import Forbidden from "../../../components/Forbidden";
import { useUserCan } from "hooks/useHasAnyPermissions";

function TrustsTable({ client, clientPath, showDisabled }) {
    const auth = useContext(AuthContext);
    const userType = auth.user.type;
    const { addToast } = useToasts();
    const [loading, setLoading] = useState(true);
    const [Trusts, setTrusts] = useState([]);
    const [orderBy, setOrderBy] = useState("sign_date");
    const [sortDir, setSortDir] = useState("desc");
    const [search, setSearch] = useState("");
    const debouncedSearch = useDebounce(search, 250);
    const [notAuthorized, setNotAuthorized] = useState(false);
    const [needsUpdated, setNeedsUpdated] = useState(0);
    const [page, setPage, meta, setMeta] = usePagination();
    const [showAddTrusteesModal, setShowAddTrusteesModal] = useState(false);
    const [trustId, setTrustId] = useState("");
    const [redirectToTrustees, setRedirectToTrustees] = useState(false);
    const canCreateWealthRecords = useUserCan("createWealthRecord");

    useEffect(() => {
        document.title = "Trusts";
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            try {
                let response = await Api.get(
                    "clients/" + client.id + "/trusts",
                    {
                        limit: 20,
                        page: page,
                        sort: sortDir === "desc" ? "-" + orderBy : orderBy,
                        "filter[search]": debouncedSearch,
                    }
                );
                setMeta(response.data.meta);
                setTrusts(response.data.data);
                setLoading(false);
            } catch (error) {
                console.log(error);
                if (error.response.status === 403) {
                    setNotAuthorized(true);
                }
            }
        };
        fetchData();
    }, [
        client,
        showDisabled,
        debouncedSearch,
        orderBy,
        sortDir,
        needsUpdated,
        page,
        setMeta,
    ]);

    async function deletePolicy(policy) {
        try {
            const response = await Api.delete(
                "clients/" + client.id + "/trusts/" + policy.id
            );
            addToast(response.data.message);
            setNeedsUpdated(needsUpdated + 1);
        } catch (e) {
            addToast(e.errorMessage, { type: "error" });
        }
    }

    if (notAuthorized) {
        return <Forbidden to="/" />;
    }

    if (loading) {
        return <Spinner />;
    }

    if (redirectToTrustees) {
        return <Redirect to={`${clientPath}/trustees/`} />;
    }

    return (
        <>
            <div className="mb-4">
                <SearchInput
                    label={false}
                    searchValue={search}
                    onSearchChange={(e) => setSearch(e.target.value)}
                />
            </div>
            {Trusts.length ? (
                <>
                    <table className="w-full text-left">
                        <thead>
                            <tr>
                                <TableSortHeader
                                    field="name"
                                    sortField={orderBy}
                                    sortDir={sortDir}
                                    changeSort={(column) => {
                                        setSortDir(
                                            sortDir === "asc" ? "desc" : "asc"
                                        );
                                        setOrderBy(column);
                                    }}
                                >
                                    Name
                                </TableSortHeader>
                                <TableSortHeader
                                    field="sign_date"
                                    sortField={orderBy}
                                    sortDir={sortDir}
                                    changeSort={(column) => {
                                        setSortDir(
                                            sortDir === "asc" ? "desc" : "asc"
                                        );
                                        setOrderBy(column);
                                    }}
                                >
                                    Sign Date
                                </TableSortHeader>
                                <TableSortHeader
                                    field="type_id"
                                    sortField={orderBy}
                                    sortDir={sortDir}
                                    changeSort={(column) => {
                                        setSortDir(
                                            sortDir === "asc" ? "desc" : "asc"
                                        );
                                        setOrderBy(column);
                                    }}
                                >
                                    Type
                                </TableSortHeader>
                                <th className="text-right">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {Trusts.map((trust, idx) => (
                                <tr
                                    key={idx}
                                    className={trust.deleted_at ? "" : ""}
                                >
                                    <td>
                                        <Link
                                            to={`${clientPath}/trusts/${trust.id}/view`}
                                        >{`${
                                            trust.name || trust.description
                                        }`}</Link>
                                    </td>
                                    <td>{formatDate(trust.sign_date)}</td>
                                    <td>{trust.type_display_name}</td>
                                    <td className="text-right">
                                        <span>
                                            <Dropdown
                                                position="right"
                                                toggleClass="px-3 py-1"
                                                toggleContent={
                                                    <FontAwesomeIcon
                                                        icon={faEllipsisH}
                                                    />
                                                }
                                            >
                                                <DropdownItem
                                                    label="Edit"
                                                    icon={faEdit}
                                                    link={`${clientPath}/trusts/${trust.id}/edit`}
                                                />
                                                {(userType === "member" ||
                                                    userType === "client") && (
                                                    <>
                                                        <DropdownItem
                                                            label="Delete"
                                                            icon={faTrash}
                                                            handleClick={() =>
                                                                deletePolicy(
                                                                    trust
                                                                )
                                                            }
                                                        />
                                                        <DropdownDivider />
                                                        <DropdownItem
                                                            label="Add Trustee"
                                                            icon={faUserPlus}
                                                            handleClick={() => {
                                                                setShowAddTrusteesModal(
                                                                    true
                                                                );
                                                                setTrustId(
                                                                    trust.id
                                                                );
                                                            }}
                                                        />
                                                        <DropdownItem
                                                            label="Edit Trustees"
                                                            icon={faUsers}
                                                            link={`${clientPath}/trusts/${trust.id}/edit/trustees`}
                                                        />
                                                    </>
                                                )}
                                            </Dropdown>
                                        </span>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <div className="my-10">
                        <Pagination {...meta} nextPage={setPage} />
                    </div>
                </>
            ) : (
                <div className="text-gray-400">
                    The are no trusts for this client.{" "}
                    {canCreateWealthRecords && (
                        <Link
                            to={`${clientPath}/trusts/add`}
                            className="underline text-gray-600"
                        >
                            Add Now.
                        </Link>
                    )}
                </div>
            )}
            <AddTrusteeModal
                isOpen={showAddTrusteesModal}
                client={client}
                trustId={trustId}
                onClose={() => setShowAddTrusteesModal(false)}
                onSuccess={() => {
                    setRedirectToTrustees(true);
                    setShowAddTrusteesModal(false);
                }}
            />
        </>
    );
}

export default TrustsTable;
