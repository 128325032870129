// Used for managing assets from an individual asset.

import React, { useState } from "react";
import QuickAddProvider, { QuickAddButton } from "../QuickAddButton";
import OwnershipContextProvider, {
    useOwnershipContext,
} from "contexts/OwnershipContext";
import {
    faCaretDown,
    faExclamationTriangle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AssetDisplayForTransfer from "./AssetDisplayForTransfer";
import { formatDate } from "util/TextUtil";
import VerifyIndividualTransfer from "components/Transfer/VerifyIndividualTransfer";
import Transfer from "../Transfer/Transfer";
import useAssetSingle from "hooks/useAssetSingle";
import Spinner from "components/Spinner";

import { combineProviders } from "util/CombineProviders";
import useAuth from "hooks/useAuth";
import Panel from "components/Panel";

const AllOwnershipsProvider = combineProviders([OwnershipContextProvider]);

const OwnershipsForAsset = ({
    asset: originalAsset,
    client,
    refreshData: parentRefreshData,
}) => {
    const {
        data: asset,
        isLoading,
        remove,
        refetch,
    } = useAssetSingle({
        clientId: client.id,
        assetType: originalAsset.resource_type,
        assetId: originalAsset.id,
    });

    const { user } = useAuth();
    const { transferAsset, setTransferAsset } = useOwnershipContext();
    const [verifyAsset, setVerifyAsset] = useState(false);
    const [verifyOwnership, setVerifyOwnership] = useState(false);
    const [verifyTransfer, setVerifyTransfer] = useState(false);

    if (isLoading) {
        return <Spinner message="Loading Ownerships" />;
    }

    const refreshData = () => {
        parentRefreshData();
        remove();
        refetch();
    };

    let proposedDate = asset.proposed_at
        ? formatDate(asset.proposed_at, false)
        : "";
    let verifiedDate = asset.verified_at
        ? formatDate(asset.verified_at, false)
        : "";

    let asOfDate = formatDate(asset.as_of_date);
    let value = asset.gross_value;
    let valueLabel = asset.is_liability ? "Liability balance" : "Net value";

    return (
        <>
            <div className="space-y-8">
                {asset?.current_owners?.length === 0 && (
                    <Panel appearance="alert" className="mt-2">
                        <div className="flex items-center">
                            <FontAwesomeIcon
                                icon={faExclamationTriangle}
                                className="mr-2"
                            />
                            <span>
                                Please specify how this asset is currently
                                owned.
                            </span>
                        </div>
                    </Panel>
                )}
                <div>
                    <h3 className="mb-2 ml-1 font-bold text-md">
                        Current Ownership
                    </h3>
                    <AssetDisplayForTransfer
                        client={client}
                        asset={asset}
                        value={value}
                        valueLabel={valueLabel}
                        ownerships={asset.current_owners}
                        proposedOwnerships={asset.proposed_owners}
                        setVerifyAsset={setVerifyAsset}
                        setVerifyOwnership={setVerifyOwnership}
                        setVerifyTransfer={setVerifyTransfer}
                        date={verifiedDate}
                        asOfDate={asOfDate}
                        onSave={() => {
                            refreshData();
                        }}
                        allowChangingOwnershipMode={false}
                        showProposed={false}
                    />
                </div>

                {user.type !== "client" && asset.current_owners.length > 0 && (
                    <div>
                        <h3 className="mb-2 ml-1 font-bold text-md">
                            Proposed Ownership
                        </h3>
                        <AssetDisplayForTransfer
                            client={client}
                            asset={asset}
                            value={value}
                            valueLabel={valueLabel}
                            ownerships={asset.current_owners}
                            proposedOwnerships={asset.proposed_owners}
                            setVerifyAsset={setVerifyAsset}
                            setVerifyOwnership={setVerifyOwnership}
                            setVerifyTransfer={setVerifyTransfer}
                            date={proposedDate}
                            asOfDate={asOfDate}
                            onSave={() => {
                                refreshData();
                            }}
                            showProposed={true}
                        />
                    </div>
                )}
            </div>
            <Transfer
                client={client}
                asset={transferAsset}
                isOpen={transferAsset.id ? true : false}
                closeModal={() => setTransferAsset(false)}
                canBeGift={true}
                onClose={() => {
                    refreshData();
                    setTransferAsset(false);
                }}
            />

            <VerifyIndividualTransfer
                client={client}
                asset={verifyAsset}
                transfer={verifyTransfer}
                ownership={verifyOwnership}
                isOpen={verifyOwnership.id}
                closeModal={() => {
                    setVerifyOwnership(false);
                    setVerifyTransfer(false);
                    setVerifyAsset(false);
                }}
                onClose={() => {
                    refreshData();
                    setVerifyOwnership(false);
                    setVerifyTransfer(false);
                    setVerifyAsset(false);
                }}
            />
        </>
    );
};

export function AllOwnershipsForAsset({ asset, client, refreshData }) {
    return (
        <div>
            <div className="text-right">
                <QuickAddProvider client={client} onSave={() => refreshData()}>
                    {(props) => (
                        <QuickAddButton {...props}>
                            <span className="flex items-center space-x-1 p-1">
                                <span>Quick Add</span>
                                <FontAwesomeIcon icon={faCaretDown} />
                            </span>
                        </QuickAddButton>
                    )}
                </QuickAddProvider>
            </div>

            <AllOwnershipsProvider>
                <OwnershipsForAsset
                    asset={asset}
                    client={client}
                    refreshData={refreshData}
                />
            </AllOwnershipsProvider>
        </div>
    );
}
