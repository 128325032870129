import React from "react";
import "nprogress/nprogress.css";
import * as NProgress from "nprogress";

class Loading extends React.Component {
    componentDidMount() {
        NProgress.start();
    }

    componentWillUnmount() {
        NProgress.done();
    }

    render() {
        return <div></div>;
    }
}
export default Loading;

export function showLoader() {
    NProgress.start();
}

export function advanceLoader() {
    NProgress.inc();
}

export function hideLoader() {
    NProgress.done();
}
