import React from "react";

export default function EmptyTableRow({ children, colSpan }) {
    return (
        <tr>
            <td colSpan={colSpan} className="text-center">
                {children}
            </td>
        </tr>
    );
}
