import React, { useState } from "react";
import TextInput from "../../../components/TextInput";
import SelectInput from "../../../components/SelectInput";
import CountrySelector from "../../../components/CountrySelector";
import GenderSelect from "../../../components/GenderSelect";
import SearchableSelect from "../../../components/SearchableSelect";
import useAuth from "../../../hooks/useAuth";
import Button from "components/Button";
import Api from "services/Api";
import { useToasts } from "react-toast-notifications";
import BasicModal from "components/ModalBasic";

export default function ClientProfileForm({
    client,
    readOnly,
    errors,
    updateValue,
    getAttorneyOptions,
}) {
    const { addToast } = useToasts();
    const { user } = useAuth();
    const [showResetTasksModal, setShowResetTasksModal] = useState(false);
    const [processingTaskReset, setProcessingTaskReset] = useState(false);
    const [meetingPhaseForReset, setMeetingPhaseForReset] = useState(null);

    const meetingPhases = [
        { value: "", label: "Choose an option", disabled: true },
        { value: "pre-initial-meeting", label: "Pre-Initial Meeting" },
        { value: "annual-update", label: "Annual Update" },
    ];

    const relationshipStatusOptions = [
        { value: "single", label: "Single" },
        { value: "married", label: "Married" },
        { value: "divorced", label: "Divorced" },
        { value: "widowed", label: "Widowed" },
        { value: "partner", label: "Partner" },
    ];

    const handleResetTasks = async (meetingPhase) => {
        try {
            setProcessingTaskReset(true);
            let result = await Api.delete(
                "clients/" + client.user_id + "/user-tasks/reset",
                {
                    meeting_phase: meetingPhase,
                }
            );
            addToast(result.data.message);
            setProcessingTaskReset(false);
            setShowResetTasksModal(false);
        } catch (error) {
            console.log(error);
            addToast(error.response?.data?.message, { type: "error" });
            setProcessingTaskReset(false);
        }
    };

    return (
        <>
            <BasicModal
                isOpen={showResetTasksModal}
                onClose={() => setShowResetTasksModal(false)}
                size="xl"
            >
                <h2 className="text-lg font-semibold mb-3">{`Reset Tasks For Meeting Phase`}</h2>
                <div className="mb-4">
                    Select the meeting phase you would like to reset all
                    complete tasks for. This will reset any items the client has
                    checked off so they can start with a fresh list. This
                    operation can not be undone.
                </div>
                <SelectInput
                    label="Meeting Phase"
                    required={false}
                    id="current_phase_reset"
                    name="meeting_phase"
                    options={meetingPhases}
                    defaultValue={meetingPhases[0].value}
                    value={meetingPhaseForReset}
                    onChange={(event) =>
                        setMeetingPhaseForReset(event.target.value)
                    }
                />
                <div className="flex space-x-2 justify-between mt-8">
                    <Button
                        type="button"
                        appearance="light"
                        text="Cancel"
                        onClick={() => setShowResetTasksModal(false)}
                        disabled={processingTaskReset}
                    />
                    <Button
                        type="button"
                        appearance="default"
                        text="Reset"
                        onClick={() => handleResetTasks(meetingPhaseForReset)}
                        disabled={processingTaskReset || !meetingPhaseForReset}
                        isLoading={processingTaskReset}
                    />
                </div>
            </BasicModal>
            {user.type === "member" && (
                <div className="mb-6">
                    <SearchableSelect
                        label="Attorney"
                        required={true}
                        id="primary_attorney_id"
                        defaultValue={{
                            label: `${client.attorney_first_name} ${client.attorney_last_name}`,
                            value: client.attorney_id,
                        }}
                        placeholder="Attorney"
                        onChange={(selection) => {
                            updateValue({
                                target: {
                                    name: "primary_attorney_id",
                                    value: selection.value,
                                },
                            });
                        }}
                        cacheOptions
                        loadOptions={getAttorneyOptions}
                        defaultOptions
                    />
                </div>
            )}
            {user.type === "member" && (
                <div className="mb-6 relative">
                    {client.current_phase && (
                        <button
                            className="top-0 right-0 absolute text-sm text-gray-800 hover:text-brand px-1"
                            type="button"
                            onClick={() => setShowResetTasksModal(true)}
                        >
                            Reset Checklist
                        </button>
                    )}
                    <SelectInput
                        label="Meeting Phase"
                        required={false}
                        id="current_phase"
                        name="current_phase"
                        options={meetingPhases}
                        defaultValue={meetingPhases[0].value}
                        value={client.current_phase}
                        onChange={updateValue}
                    />
                </div>
            )}
            <div className="flex mb-6">
                <div className="w-1/3 mr-4">
                    <TextInput
                        name="first_name"
                        label="First Name"
                        id="first_name"
                        value={client.first_name}
                        error={errors.first_name}
                        autoFocus={true}
                        readOnly={readOnly}
                        onChange={updateValue}
                        required={true}
                    />
                </div>
                <div className="w-1/3 mr-4">
                    <TextInput
                        name="middle_name"
                        label="Middle Name"
                        id="middle_name"
                        value={client.middle_name}
                        error={errors.middle_name}
                        onChange={updateValue}
                        readOnly={readOnly}
                    />
                </div>
                <div className="w-1/3">
                    <TextInput
                        name="last_name"
                        label="Last Name"
                        id="last_name"
                        value={client.last_name}
                        error={errors.last_name}
                        onChange={updateValue}
                        readOnly={readOnly}
                        required={true}
                    />
                </div>
            </div>

            <div className="flex space-x-4 mb-6">
                <div className="flex-1">
                    <TextInput
                        name="prefix"
                        label="Prefix"
                        id="prefix"
                        value={client.prefix}
                        error={errors.prefix}
                        onChange={updateValue}
                        readOnly={readOnly}
                    />
                </div>
                <div className="flex-1">
                    <TextInput
                        name="suffix"
                        label="Suffix"
                        id="suffix"
                        value={client.suffix}
                        error={errors.suffix}
                        onChange={updateValue}
                        readOnly={readOnly}
                    />
                </div>
            </div>

            <div className="mb-6">
                <TextInput
                    type="signature_name"
                    name="signature_name"
                    label="Signature Name"
                    id="signature_name"
                    value={client.signature_name}
                    error={errors.signature_name}
                    readOnly={readOnly}
                    onChange={updateValue}
                />
            </div>

            <div className="mb-6">
                <TextInput
                    name="nick_name"
                    label="Nickname"
                    id="nick_name"
                    value={client.nick_name}
                    error={errors.nick_name}
                    onChange={updateValue}
                    readOnly={readOnly}
                />
            </div>

            <div className="mb-6">
                <GenderSelect
                    label="Gender"
                    id="gender"
                    name="gender"
                    value={client.gender}
                    disabled={readOnly}
                    error={errors.gender}
                    onChange={updateValue}
                />
            </div>

            <div className="mb-6">
                <TextInput
                    type="date"
                    name="birthday"
                    label="Birth Date"
                    id="birthday"
                    value={client.birthday}
                    error={errors.birthday}
                    readOnly={readOnly}
                    onChange={updateValue}
                />
            </div>

            <div className="mb-6">
                <TextInput
                    type="date"
                    name="date_of_death"
                    label="DOD"
                    id="date_of_death"
                    value={client.date_of_death}
                    error={errors.date_of_death}
                    readOnly={true}
                    disabled={true}
                    onChange={() => {}}
                />
            </div>

            <div className="mb-6">
                <TextInput
                    type="email"
                    name="email"
                    label="Email"
                    id="email"
                    value={client.email}
                    error={errors.email}
                    readOnly={readOnly}
                    onChange={updateValue}
                    required={true}
                />
            </div>

            <div className="mb-6">
                <TextInput
                    type="tel"
                    name="phone"
                    label="Home Phone"
                    id="phone"
                    value={client.phone}
                    error={errors.phone}
                    readOnly={readOnly}
                    onChange={updateValue}
                />
            </div>

            <div className="mb-6">
                <TextInput
                    type="tel"
                    name="mobile_phone"
                    label="Mobile Phone"
                    id="mobile_phone"
                    value={client.mobile_phone}
                    error={errors.mobile_phone}
                    readOnly={readOnly}
                    onChange={updateValue}
                />
            </div>

            <div className="mb-6">
                <TextInput
                    name="address"
                    label="Address"
                    id="address"
                    value={client.address}
                    error={errors.address}
                    onChange={updateValue}
                    readOnly={readOnly}
                />
            </div>

            <div className="flex mb-6">
                <div className="w-1/3 mr-4">
                    <TextInput
                        name="city"
                        label="City"
                        id="city"
                        value={client.city}
                        error={errors.city}
                        onChange={updateValue}
                        readOnly={readOnly}
                    />
                </div>
                <div className="w-1/3 mr-4">
                    <TextInput
                        name="state"
                        label="State"
                        id="state"
                        value={client.state}
                        error={errors.state}
                        onChange={updateValue}
                        readOnly={readOnly}
                    />
                </div>
                <div className="w-1/3">
                    <TextInput
                        name="postal_code"
                        label="Postal Code"
                        id="postal_code"
                        value={client.postal_code}
                        error={errors.postal_code}
                        onChange={updateValue}
                        readOnly={readOnly}
                    />
                </div>
            </div>

            <div className="mb-6">
                <TextInput
                    name="county"
                    label="County"
                    id="county"
                    value={client.county}
                    error={errors.county}
                    onChange={updateValue}
                    readOnly={readOnly}
                />
            </div>

            <div className="mb-6">
                <CountrySelector
                    label="Country"
                    value={client.country}
                    disabled={readOnly}
                    name="country"
                    onChange={updateValue}
                />
            </div>

            <div className="mb-6">
                <CountrySelector
                    label="Citizenship"
                    value={client.citizenship}
                    disabled={readOnly}
                    name="citizenship"
                    onChange={updateValue}
                />
            </div>

            <fieldset className="mt-12">
                <legend className="font-bold txt-md text-gray-500 my-6 pb-1 border-b block w-full">
                    Identifying Information
                </legend>
                <div className="mb-6">
                    <TextInput
                        type="text"
                        secret={true}
                        name="drivers_license_number"
                        label="Driver's License Number"
                        id="drivers_license_number"
                        value={client.drivers_license_number}
                        error={errors.drivers_license_number}
                        readOnly={readOnly}
                        onChange={updateValue}
                        autoComplete="off"
                    />
                </div>
                <div className="mb-6">
                    <TextInput
                        type="text"
                        name="drivers_license_state"
                        label="Driver's License State"
                        id="drivers_license_state"
                        value={client.drivers_license_state}
                        error={errors.drivers_license_state}
                        readOnly={readOnly}
                        onChange={updateValue}
                        autoComplete="off"
                    />
                </div>
                <div className="mb-6">
                    <TextInput
                        type="text"
                        secret={true}
                        name="passport_number"
                        label="Passport Number"
                        id="passport_number"
                        value={client.passport_number}
                        error={errors.passport_number}
                        readOnly={readOnly}
                        onChange={updateValue}
                        autoComplete="off"
                    />
                </div>

                <div className="mb-6">
                    <TextInput
                        type="text"
                        secret={true}
                        name="ssn"
                        label="Social Security Number"
                        id="ssn"
                        value={client.ssn}
                        error={errors.ssn}
                        readOnly={readOnly}
                        onChange={updateValue}
                        autoComplete="off"
                    />
                </div>
            </fieldset>

            <fieldset className="mt-12">
                <legend className="font-bold txt-md text-gray-500 my-6 pb-1 border-b block w-full">
                    Relationships
                </legend>
                <div className="mb-6">
                    <SelectInput
                        label="Relationship Status"
                        options={relationshipStatusOptions}
                        value={client.relationship_status}
                        disabled={readOnly}
                        name="relationship_status"
                        onChange={updateValue}
                    ></SelectInput>
                </div>

                {(client.relationship_status === "married" ||
                    client.relationship_status === "partner") && (
                    <>
                        <div className="mb-6">
                            <TextInput
                                type="date"
                                name="date_of_marriage"
                                label="Date of Marrige"
                                id="date_of_marriage"
                                value={client.date_of_marriage}
                                error={errors.date_of_marriage}
                                readOnly={readOnly}
                                onChange={updateValue}
                            />
                        </div>
                        <div className="mb-6">
                            <TextInput
                                name="maiden_name"
                                label="Maiden Name"
                                id="maiden_name"
                                value={client.maiden_name}
                                error={errors.maiden_name}
                                onChange={updateValue}
                                readOnly={readOnly}
                            />
                        </div>
                    </>
                )}
            </fieldset>

            <fieldset className="mt-12">
                <legend className="font-bold txt-md text-gray-500 my-6 pb-1 border-b block w-full">
                    Employment
                </legend>
                <div className="mb-6">
                    <TextInput
                        name="work_name"
                        label="Company"
                        id="work_name"
                        value={client.work_name}
                        error={errors.work_name}
                        onChange={updateValue}
                        readOnly={readOnly}
                    />
                </div>

                <div className="mb-6">
                    <TextInput
                        type="text"
                        name="job_title"
                        label="Job Title"
                        id="job_title"
                        value={client.job_title}
                        error={errors.job_title}
                        readOnly={readOnly}
                        onChange={updateValue}
                    />
                </div>

                <div className="mb-6">
                    <TextInput
                        type="tel"
                        name="work_phone"
                        label="Work Phone"
                        id="work_phone"
                        value={client.work_phone}
                        error={errors.work_phone}
                        readOnly={readOnly}
                        onChange={updateValue}
                    />
                </div>

                <div className="mb-6">
                    <TextInput
                        name="work_address"
                        label="Address"
                        id="work_address"
                        value={client.work_address}
                        error={errors.work_address}
                        onChange={updateValue}
                        readOnly={readOnly}
                    />
                </div>

                <div className="flex mb-6">
                    <div className="w-1/3 mr-4">
                        <TextInput
                            name="work_city"
                            label="City"
                            id="work_city"
                            value={client.work_city}
                            error={errors.work_city}
                            onChange={updateValue}
                            readOnly={readOnly}
                        />
                    </div>
                    <div className="w-1/3 mr-4">
                        <TextInput
                            name="work_state"
                            label="State"
                            id="work_state"
                            value={client.work_state}
                            error={errors.work_state}
                            onChange={updateValue}
                            readOnly={readOnly}
                        />
                    </div>
                    <div className="w-1/3">
                        <TextInput
                            name="work_postal_code"
                            label="Postal Code"
                            id="work_postal_code"
                            value={client.work_postal_code}
                            error={errors.work_postal_code}
                            onChange={updateValue}
                            readOnly={readOnly}
                        />
                    </div>
                </div>

                <div className="mb-6">
                    <CountrySelector
                        label="Country"
                        value={client.work_country}
                        disabled={readOnly}
                        name="work_country"
                        onChange={updateValue}
                    />
                </div>
            </fieldset>
            <input type="submit" hidden />
        </>
    );
}
