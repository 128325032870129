import React from "react";
import { Route, Switch } from "react-router-dom";
import NonMortgageLiabilitiesIndex from "./NonMortgageLiabilitiesIndex";
import AddNonMortgageLiability from "./AddNonMortgageLiability";
import EditNonMortgageLiability from "./EditNonMortgageLiability";

export default function NonMortgageLiabilities(parentProps) {
    return (
        <Switch>
            <Route
                exact
                path={[
                    "/clients/:clientId/non-mortgage-liabilities",
                    "/client/non-mortgage-liabilities",
                ]}
                render={(props) => (
                    <NonMortgageLiabilitiesIndex {...parentProps} {...props} />
                )}
            />
            <Route
                exact
                path={[
                    "/clients/:clientId/non-mortgage-liabilities/add",
                    "/client/non-mortgage-liabilities/add",
                ]}
                render={(props) => (
                    <AddNonMortgageLiability {...parentProps} {...props} />
                )}
            />
            <Route
                path={[
                    "/clients/:clientId/non-mortgage-liabilities/:nonMortgageLiabilityId/edit",
                    "/client/non-mortgage-liabilities/:nonMortgageLiabilityId/edit",
                ]}
                render={(props) => (
                    <EditNonMortgageLiability {...parentProps} {...props} />
                )}
            />
            <Route
                path={[
                    "/clients/:clientId/non-mortgage-liabilities/:nonMortgageLiabilityId/view",
                    "/client/non-mortgage-liabilities/:nonMortgageLiabilityId/view",
                ]}
                render={(props) => (
                    <EditNonMortgageLiability
                        readOnly={true}
                        {...parentProps}
                        {...props}
                    />
                )}
            />
        </Switch>
    );
}
