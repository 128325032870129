import React, { useState } from "react";

import useClient from "hooks/useClient";
import Money, { parseMoney } from "components/Money";
import { useOwnershipContext } from "contexts/OwnershipContext";
import { OwnershipOptions, ownershipTypes } from "components/Ownership";
import SelectInput from "components/SelectInput";
import { FakeInput } from "components/TextInput";
import ResourceSelect from "../../ResourceSelect";

export function PendingTransferLineItemReadOnly({ transfer }) {
    const { client } = useClient();
    const { assetOptions } = useOwnershipContext();
    const hideLabels = true;

    // const [excludedAsset] = useState([asset.type + ":" + asset.id]);

    const [resourceTypes] = useState([
        "Relationship",
        "Client",
        "FinancialAccount",
        "RetirementAccount",
        "Trust",
    ]);

    transfer.transfer_percentage = parseMoney(transfer.transfer_percentage);
    transfer.transfer_amount = parseMoney(transfer.transfer_amount);

    return (
        <>
            <div className="flex flex-1" key={`transfer_${transfer.id}`}>
                <div className={`flex flex-1 space-x-3`}>
                    {transfer.transfer_type === "value" && (
                        <>
                            <div className="flex flex-1">
                                <div className="flex-1 relative">
                                    <ResourceSelect
                                        client={client}
                                        resourceType={resourceTypes}
                                        label={!hideLabels ? `Recipient` : ""}
                                        name="recipient"
                                        disabled={true}
                                        readOnly={true}
                                        value={`${transfer.destination_type}:${transfer.destination_id}`}
                                    />
                                </div>
                            </div>
                            <div className="flex space-x-2 flex-0 w-32 mx-2">
                                <div className="flex-1 relative">
                                    <FakeInput
                                        label={!hideLabels ? `Amount` : ""}
                                        name="transfer_amount"
                                        id={`transfer_amount_${transfer.id}`}
                                        disabled={true}
                                        required={false}
                                    >
                                        <Money
                                            value={transfer.transfer_amount}
                                        />
                                    </FakeInput>
                                </div>
                            </div>
                        </>
                    )}
                    {transfer.transfer_type === "ownership" && (
                        <>
                            <div className="flex flex-1">
                                <div className="flex-1 relative">
                                    <SelectInput
                                        label={
                                            !hideLabels ? `Ownership Type` : ""
                                        }
                                        name="type"
                                        options={ownershipTypes()}
                                        loading={false}
                                        value={
                                            transfer.destination_ownership_type ||
                                            ""
                                        }
                                        disabled={true}
                                        required={false}
                                        autoFocus={false}
                                    />
                                </div>
                            </div>
                            {transfer.destination_ownership_type &&
                                assetOptions && (
                                    <div className="flex-1">
                                        <OwnershipOptions
                                            label={!hideLabels ? `Owner` : ""}
                                            assetOptions={assetOptions}
                                            client={client}
                                            type={
                                                transfer.destination_ownership_type
                                            }
                                            ownerType={
                                                transfer.destination_type
                                            }
                                            ownerId={transfer.destination_id}
                                            description={
                                                transfer.destination_description
                                            }
                                            disabled={true}
                                            updateOption={console.log()}
                                            updateDescription={console.log()}
                                        />
                                    </div>
                                )}
                            <div className="flex space-x-2 flex-0 w-32 mx-2">
                                <div className="flex-1 relative">
                                    <FakeInput
                                        label={!hideLabels ? `Percentage` : ""}
                                        name="percentage_owned"
                                        id={`percentage_owned_${transfer.id}`}
                                        value={
                                            transfer.transfer_percentage + "%"
                                        }
                                        disabled={true}
                                        required={false}
                                        placeholder="0 - 100"
                                        onChange={console.log()}
                                    />
                                </div>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </>
    );
}
