import Api from "./Api";

export const fetchContacts = async function ({ queryKey }) {
    const [, { ...params }] = queryKey;
    const { clientId, isSpouse, ...qs } = params;

    let response = await Api.get("clients/" + clientId + "/ice/contacts", {
        isSpouse,
        ...qs,
    });
    return response.data.data;
};

export const fetchAvailableContacts = async function ({ queryKey }) {
    const [, { ...params }] = queryKey;
    const { clientId, isSpouse, ...qs } = params;

    let response = await Api.get(
        "clients/" + clientId + "/ice/contacts/available",
        {
            isSpouse,
            ...qs,
        }
    );
    return response.data.data;
};

export default {
    fetchAvailableContacts,
    fetchContacts,
};
