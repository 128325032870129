import useFetch from "./useFetch";
import { fetchPostMortemAssets } from "services/PostMortemService";

export default function usePostMortemAssets(
    params,
    config = { keepPreviousData: false }
) {
    return useFetch(
        ["fetchPostMortemAssets", params],
        fetchPostMortemAssets,
        config
    );
}
