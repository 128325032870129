import React from "react";
import { Route, Switch } from "react-router-dom";
import PageContainer from "../../components/PageContainer";
import Button from "../../components/Button";
import ClientPageHeader, { Heading } from "../../components/ClientPageHeader";
import TrustsTable from "./_partials/TrustsTable";
import { useUserCan } from "hooks/useHasAnyPermissions";

export default function TrustsIndex({ client, user, clientPath, ...props }) {
    const canCreateWealthRecords = useUserCan("createWealthRecord");
    return (
        <>
            <ClientPageHeader client={client}>
                <Heading>Trusts</Heading>

                <div className="flex space-x-2 flex-0">
                    <Button
                        type="link"
                        appearance="light"
                        to={`${clientPath}/trustees`}
                        text="Go To Trustees"
                    />
                    {canCreateWealthRecords && (
                        <Button
                            type="link"
                            to={`${clientPath}/trusts/add`}
                            text="Add Trust"
                        />
                    )}
                </div>
            </ClientPageHeader>
            <PageContainer>
                <div className="flex space-x-8">
                    <div className="flex-1">
                        <Switch>
                            <Route
                                path={clientPath + "/trusts"}
                                render={(newProps) => (
                                    <TrustsTable
                                        client={client}
                                        user={user}
                                        clientPath={clientPath}
                                        {...props}
                                        {...newProps}
                                    />
                                )}
                            />
                        </Switch>
                    </div>
                </div>
            </PageContainer>
        </>
    );
}
