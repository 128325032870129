import React, { useEffect } from "react";
import { Route, Switch, useParams } from "react-router-dom";
import AuthorizedPartyPermissions from "./AuthorizedPartyPermissions";
import useClient from "hooks/useClient";
import Relationship from "./Relationship";
import RelationshipNotes from "./RelationshipNotes";
import RelationshipVault from "./RelationshipVault";
import RelationshipPhotos from "./RelationshipPhotos";
import RelationshipAccess from "./RelationshipAccess";
import useForm from "hooks/useForm";
import useRelationship from "hooks/useRelationship";
import RelationshipContextProvider from "contexts/RelationshipContext";

function ManageRelationship({ clientPath, readOnly }) {
    const { relationshipId } = useParams();
    const { client } = useClient();
    const basePath = `${clientPath}/relationships/${relationshipId}/`;
    const currentBasePath = basePath + (readOnly ? "view" : "edit");

    const {
        input: relationshipInput,
        setInput: setRelationshipInput,
        updateValue,
        clearUnsavedChanges,
    } = useForm({});

    useRelationship(
        {
            clientId: client.id,
            relationshipId: relationshipId,
            "filter[trashed]": "with",
        },
        {
            initialData: {},
            onSuccess: (data) => {
                setRelationshipInput(data);
            },
        }
    );

    useEffect(() => {
        if (relationshipInput.full_name_with_relationship) {
            document.title =
                "Edit Relationship: " +
                relationshipInput.full_name_with_relationship;
        }
    }, [relationshipInput.full_name_with_relationship]);

    return (
        <RelationshipContextProvider relationshipId={relationshipInput.id}>
            <Switch>
                <Route
                    exact={true}
                    path={[basePath + "edit", basePath + "view"]}
                    render={() => (
                        <Relationship
                            relationshipInput={relationshipInput}
                            setRelationshipInput={setRelationshipInput}
                            clearUnsavedChanges={clearUnsavedChanges}
                            updateValue={updateValue}
                            relationshipId={relationshipId}
                            currentBasePath={currentBasePath}
                            readOnly={readOnly}
                        />
                    )}
                />
                <Route
                    exact={true}
                    path={[basePath + "edit/notes", basePath + "view/notes"]}
                    render={() => (
                        <RelationshipNotes
                            relationshipInput={relationshipInput}
                            relationshipId={relationshipId}
                            currentBasePath={currentBasePath}
                            readOnly={readOnly}
                        />
                    )}
                />
                <Route
                    exact={true}
                    path={[
                        basePath + "edit/related-files",
                        basePath + "view/related-files",
                    ]}
                    render={() => (
                        <RelationshipVault
                            relationshipInput={relationshipInput}
                            relationshipId={relationshipId}
                            currentBasePath={currentBasePath}
                            readOnly={readOnly}
                        />
                    )}
                />
                <Route
                    exact={true}
                    path={[
                        basePath + "edit/related-photos",
                        basePath + "view/related-photos",
                    ]}
                    render={() => (
                        <RelationshipPhotos
                            relationshipInput={relationshipInput}
                            relationshipId={relationshipId}
                            currentBasePath={currentBasePath}
                            readOnly={readOnly}
                        />
                    )}
                />
                <Route
                    exact={true}
                    path={[basePath + "edit/access", basePath + "view/access"]}
                    render={() => (
                        <RelationshipAccess
                            relationshipInput={relationshipInput}
                            relationshipId={relationshipId}
                            currentBasePath={currentBasePath}
                            updateValue={updateValue}
                            readOnly={readOnly}
                        />
                    )}
                />
                <Route
                    exact={true}
                    path={[
                        basePath + "edit/permissions",
                        basePath + "view/permissions",
                        basePath + "edit-permissions",
                    ]}
                    render={() => (
                        <AuthorizedPartyPermissions
                            relationship={relationshipInput}
                            currentBasePath={currentBasePath}
                            readOnly={readOnly}
                        />
                    )}
                />
            </Switch>
        </RelationshipContextProvider>
    );
}

export default ManageRelationship;
