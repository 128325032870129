import React, { useState, useCallback } from "react";
import { Route, Switch } from "react-router-dom";
import PageContainer from "../../components/PageContainer";
import Button from "../../components/Button";
import ClientPageHeader, { Heading } from "../../components/ClientPageHeader";
import IncomeSourcesTable from "./_partials/IncomeSourcesTable";
import { useToasts } from "react-toast-notifications";
import Api from "../../services/Api";
import { downloadFile } from "../../util/Downloader";
import useHasAnyPermissions, { useUserCan } from "hooks/useHasAnyPermissions";

export default function IncomeSourcesIndex({
    client,
    user,
    clientPath,
    ...props
}) {
    const [isExporting, setIsExporting] = useState(false);
    const [recordCount, setRecordCount] = useState(0);
    const { addToast } = useToasts();
    const canCreateWealthRecords = useUserCan("createWealthRecord");
    const canDownloadReport = useHasAnyPermissions([
        "report_global_access",
        "report_income_sources_report",
    ]);

    async function exportRecords(e) {
        e.preventDefault();
        try {
            setIsExporting(true);
            let response = await Api.get(
                "clients/" + client.id + "/income-sources",
                {
                    export: true,
                },
                { responseType: "arraybuffer" }
            );
            await downloadFile(response, "income-sources");
            setIsExporting(false);
            addToast("Export downloaded");
        } catch (e) {
            setIsExporting(false);
            addToast(e.response.data.message, { type: "error" });
        }
    }

    const updateRecordCount = useCallback((count) => {
        setRecordCount(count);
    }, []);

    return (
        <>
            <ClientPageHeader client={client}>
                <Heading>Income Sources</Heading>
                <div className="flex-0 flex space-x-4">
                    {canCreateWealthRecords && (
                        <Button
                            type="link"
                            to={`${clientPath}/income-sources/add`}
                            text="Add Income Source"
                        />
                    )}
                    {recordCount > 0 && canDownloadReport && (
                        <Button
                            text="Download"
                            appearance="outline"
                            width="w-auto"
                            isLoading={isExporting}
                            onClick={exportRecords}
                        />
                    )}
                </div>
            </ClientPageHeader>
            <PageContainer>
                <div className="flex space-x-8">
                    <div className="flex-1">
                        <Switch>
                            <Route
                                path={clientPath + "/income-sources"}
                                render={(newProps) => (
                                    <IncomeSourcesTable
                                        client={client}
                                        user={user}
                                        clientPath={clientPath}
                                        updateRecordCount={updateRecordCount}
                                        {...props}
                                        {...newProps}
                                    />
                                )}
                            />
                        </Switch>
                    </div>
                </div>
            </PageContainer>
        </>
    );
}
