import { useState, useCallback } from "react";
import { useQueryClient } from "react-query";
import useApi from "./useApi";

export default function useBilling() {
    const { api, hasError, loading, errorMessage } = useApi();
    const [processing, setProcessing] = useState(false);
    const [clientSecret, setClientSecret] = useState();
    const queryClient = useQueryClient();

    const createSetupIntent = useCallback(async () => {
        const response = await api("get", "billing/create-setup-intent");
        if (!hasError && response?.data?.client_secret) {
            setClientSecret(response.data.client_secret);
        }
    }, [api, hasError]);

    const savePaymentMethod = useCallback(
        async (paymentId, priceId, promotion_code) => {
            setProcessing(true);
            const response = await api("post", "billing/save-payment-method", {
                payment_id: paymentId,
                price_id: priceId,
                promotion_code,
            });
            setProcessing(false);
            return response;
        },
        [api]
    );

    const updatePaymentMethod = useCallback(
        async (paymentId) => {
            const response = await api("put", "billing/update-payment-method", {
                payment_id: paymentId,
            });
            return response;
        },
        [api]
    );

    const updatePrimarySubscription = useCallback(
        async (priceId) => {
            setProcessing(true);
            const response = await api(
                "put",
                "billing/update-primary-subscription",
                {
                    price_id: priceId,
                }
            );
            setProcessing(false);
            queryClient.refetchQueries("fetchCurrentSubscription");
            return response;
        },
        [api]
    );

    const cancelPrimarySubscription = useCallback(async () => {
        setProcessing(true);
        const response = await api(
            "delete",
            "billing/cancel-primary-subscription"
        );
        setProcessing(false);
        return response;
    }, [api]);

    const getPrices = useCallback(async () => {
        return await api("get", "billing/get-prices");
    }, [api]);

    const getPaymentDetails = useCallback(async () => {
        return await api("get", "billing/get-payment-details");
    }, [api]);

    const getInvoices = useCallback(async () => {
        return await api("get", "billing/get-invoices");
    }, [api]);

    return {
        loading,
        hasError,
        getPrices,
        processing,
        getInvoices,
        clientSecret,
        errorMessage,
        getPaymentDetails,
        createSetupIntent,
        savePaymentMethod,
        updatePaymentMethod,
        updatePrimarySubscription,
        cancelPrimarySubscription,
    };
}
