import { useState, useEffect } from "react";

// Hook
export default function useSessionStorage(key, initialValue) {
    const [storedValue, setStoredValue] = useState(initialize);
    const [initializedKey, setInitializedKey] = useState(null);
    const isInitialized = initializedKey === key;
    const initialize = () => {
        setInitializedKey(key);
        try {
            // Get from local storage by key
            const item = window.sessionStorage.getItem(key);
            // Parse stored json or if none return initialValue
            return item ? JSON.parse(item) : initialValue;
        } catch (error) {
            // If error also return initialValue
            console.log(error);
            return initialValue;
        }
    };
    // State to store our value
    // Pass initial state function to useState so logic is only executed once

    useEffect(() => {
        setStoredValue(initialize);
    }, [key]);

    // Return a wrapped version of useState's setter function that ...
    // ... persists the new value to sessionStorage.
    const setValue = (value) => {
        try {
            // Allow value to be a function so we have same API as useState
            const valueToStore =
                value instanceof Function ? value(storedValue) : value;
            // Save state
            setStoredValue(valueToStore);
            // Save to local storage
            window.sessionStorage.setItem(key, JSON.stringify(valueToStore));
        } catch (error) {
            // A more advanced implementation would handle the error case
            console.log(error);
        }
    };

    const remove = () => {
        try {
            setStoredValue(initialValue);
            // Save to local storage
            window.sessionStorage.removeItem(key);
        } catch (error) {
            // A more advanced implementation would handle the error case
            console.log(error);
        }
    };

    return [storedValue, setValue, remove, isInitialized];
}
