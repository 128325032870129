import React from "react";
import { Switch, Route } from "react-router-dom";
import PersonalPropertyIndex from "./PersonalPropertyIndex";
import AddPersonalProperty from "./AddPersonalProperty";
import EditPersonalProperty from "./EditPersonalProperty";

export default function PersonalProperties(parentProps) {
    return (
        <Switch>
            <Route
                exact
                path={[
                    "/clients/:clientId/personal-properties",
                    "/client/personal-properties",
                ]}
                render={(props) => (
                    <PersonalPropertyIndex {...parentProps} {...props} />
                )}
            />
            <Route
                exact
                path={[
                    "/clients/:clientId/personal-properties/add",
                    "/client/personal-properties/add",
                ]}
                render={(props) => (
                    <AddPersonalProperty {...parentProps} {...props} />
                )}
            />
            <Route
                path={[
                    "/clients/:clientId/personal-properties/:personalPropertyId/edit",
                    "/client/personal-properties/:personalPropertyId/edit",
                ]}
                render={(props) => (
                    <EditPersonalProperty {...parentProps} {...props} />
                )}
            />
            <Route
                path={[
                    "/clients/:clientId/personal-properties/:personalPropertyId/view",
                    "/client/personal-properties/:personalPropertyId/view",
                ]}
                render={(props) => (
                    <EditPersonalProperty
                        readOnly={true}
                        {...parentProps}
                        {...props}
                    />
                )}
            />
        </Switch>
    );
}
