import React from "react";
import ClientPageHeader, { Heading } from "../../components/ClientPageHeader";
import Button from "../../components/Button";
import PageContainer from "../../components/PageContainer";
import { Switch, Route } from "react-router-dom";
import FinancialAccountTable from "./_partials/FinancialAccountTable";
import Tabs from "../../components/Tabs";
import { useUserCan } from "hooks/useHasAnyPermissions";

export default function FinancialAccountIndex({
    client,
    user,
    clientPath,
    ...props
}) {
    const canCreateWealthRecords = useUserCan("createWealthRecord");
    return (
        <>
            <ClientPageHeader client={client}>
                <Heading>Financial Accounts</Heading>
                {canCreateWealthRecords && (
                    <div className="flex-0">
                        <Button
                            type="link"
                            to={`${clientPath}/financial-accounts/add`}
                            text="Add Financial Account"
                        />
                    </div>
                )}
            </ClientPageHeader>
            <PageContainer>
                <div className="flex space-x-8">
                    <div className="flex-1">
                        <Tabs
                            {...props}
                            items={[
                                {
                                    label: "Active",
                                    to: `${clientPath}/financial-accounts`,
                                },
                                {
                                    label: "Deleted",
                                    to: `${clientPath}/financial-accounts/disabled`,
                                },
                            ]}
                        />
                        <Switch>
                            <Route
                                exact
                                path={`${clientPath}/financial-accounts`}
                                render={(newProps) => (
                                    <FinancialAccountTable
                                        client={client}
                                        clientPath={clientPath}
                                        withTrashed={false}
                                        {...props}
                                        {...newProps}
                                    />
                                )}
                            />
                            <Route
                                exact
                                path={`${clientPath}/financial-accounts/disabled`}
                                render={(newProps) => (
                                    <FinancialAccountTable
                                        client={client}
                                        user={user}
                                        clientPath={clientPath}
                                        withTrashed={true}
                                        {...props}
                                        {...newProps}
                                    />
                                )}
                            />
                        </Switch>
                    </div>
                </div>
            </PageContainer>
        </>
    );
}
