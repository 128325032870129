import React from "react";
import Button from "../../components/Button";
import { downloadFileAsLink } from "../../util/Downloader";

export default function FinancialAccountLetters({ clientId, accountId }) {
    document.title = "Forms - Letters of Instruction";

    const downloadCashOwnershipInstructions = () => {
        const url = `clients/${clientId}/financial-accounts/${accountId}/letters/cash-account-ownership-change`;
        downloadFileAsLink(url);
    };

    const downloadTodToIndividualInstructions = () => {
        const url = `clients/${clientId}/financial-accounts/${accountId}/letters/cash-account-tod-to-individual`;
        downloadFileAsLink(url);
    };

    const downloadTodToTrustInstructions = () => {
        const url = `clients/${clientId}/financial-accounts/${accountId}/letters/cash-account-tod-to-trusts`;
        downloadFileAsLink(url);
    };

    const downloadHsaInstructions = () => {
        const url = `clients/${clientId}/financial-accounts/${accountId}/letters/hsa`;
        downloadFileAsLink(url);
    };

    const download592Instructions = () => {
        const url = `clients/${clientId}/financial-accounts/${accountId}/letters/account-successor-529`;
        downloadFileAsLink(url);
    };

    const downloadBrokerageOwnershipInstructions = () => {
        const url = `clients/${clientId}/financial-accounts/${accountId}/letters/brokerage-account-ownership-change`;
        downloadFileAsLink(url);
    };

    const downloadBrokerageBeneficiaryInstructions = () => {
        const url = `clients/${clientId}/financial-accounts/${accountId}/letters/brokerage-account-beneficiary-change`;
        downloadFileAsLink(url);
    };

    const downloadCertificateOfDepositOwnershipInstructions = () => {
        const url = `clients/${clientId}/financial-accounts/${accountId}/letters/certificate-of-deposit-ownership-change`;
        downloadFileAsLink(url);
    };

    const downloadCertificateOfDepositBeneficiaryInstructions = () => {
        const url = `clients/${clientId}/financial-accounts/${accountId}/letters/certificate-of-deposit-beneficiary-change`;
        downloadFileAsLink(url);
    };

    return (
        <table className="w-full text-left">
            <thead>
                <tr>
                    <th>Name</th>
                    <th>Description</th>
                    <th className="text-right">Actions</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>Brokerage Account - Ownership Transfer</td>
                    <td>Instructions for ownership change.</td>
                    <td className="text-right">
                        <Button
                            type="button"
                            text="Download Document"
                            height="h-10"
                            appearance="outline"
                            width="w-auto"
                            onClick={downloadBrokerageOwnershipInstructions}
                        />
                    </td>
                </tr>
                <tr>
                    <td>Brokerage Account - Beneficiary Change</td>
                    <td>Instructions for beneficiary change.</td>
                    <td className="text-right">
                        <Button
                            type="button"
                            text="Download Document"
                            height="h-10"
                            appearance="outline"
                            width="w-auto"
                            onClick={downloadBrokerageBeneficiaryInstructions}
                        />
                    </td>
                </tr>
                <tr>
                    <td>Cash Account - Ownership Transfer</td>
                    <td>Instructions for ownership change.</td>
                    <td className="text-right">
                        <Button
                            type="button"
                            text="Download Document"
                            height="h-10"
                            appearance="outline"
                            width="w-auto"
                            onClick={downloadCashOwnershipInstructions}
                        />
                    </td>
                </tr>
                <tr>
                    <td>Cash Account - ToD to Individuals</td>
                    <td>
                        Instructions for payable on death beneficiary
                        designation for cash accounts
                    </td>
                    <td className="text-right">
                        <Button
                            type="button"
                            text="Download Document"
                            height="h-10"
                            appearance="outline"
                            width="w-auto"
                            onClick={downloadTodToIndividualInstructions}
                        />
                    </td>
                </tr>
                <tr>
                    <td>Cash Account - ToD to Trust</td>
                    <td>
                        Instructions for payable on death designation for cash
                        account
                    </td>
                    <td className="text-right">
                        <Button
                            type="button"
                            text="Download Document"
                            height="h-10"
                            appearance="outline"
                            width="w-auto"
                            onClick={downloadTodToTrustInstructions}
                        />
                    </td>
                </tr>
                <tr>
                    <td>HSA</td>
                    <td>Instructions for Health Savings Account</td>
                    <td className="text-right">
                        <Button
                            type="button"
                            text="Download Document"
                            height="h-10"
                            appearance="outline"
                            width="w-auto"
                            onClick={downloadHsaInstructions}
                        />
                    </td>
                </tr>
                <tr>
                    <td>529 Account - Successor Owner</td>
                    <td>Instructions for 529 Savings Plan</td>
                    <td className="text-right">
                        <Button
                            type="button"
                            text="Download Document"
                            height="h-10"
                            appearance="outline"
                            width="w-auto"
                            onClick={download592Instructions}
                        />
                    </td>
                </tr>
                <tr>
                    <td>Certificate of Deposit</td>
                    <td>Instructions for ownership transfer to trust.</td>
                    <td className="text-right">
                        <Button
                            type="button"
                            text="Download Document"
                            height="h-10"
                            appearance="outline"
                            width="w-auto"
                            onClick={
                                downloadCertificateOfDepositOwnershipInstructions
                            }
                        />
                    </td>
                </tr>
                <tr>
                    <td>Certificate of Deposit</td>
                    <td>
                        Instructions for payable on death beneficiary
                        designation.
                    </td>
                    <td className="text-right">
                        <Button
                            type="button"
                            text="Download Document"
                            height="h-10"
                            appearance="outline"
                            width="w-auto"
                            onClick={
                                downloadCertificateOfDepositBeneficiaryInstructions
                            }
                        />
                    </td>
                </tr>
            </tbody>
        </table>
    );
}
