import React, { useState, useEffect, useCallback } from "react";
import Api from "../../services/Api";
import { MemberHeader } from "../../components/PageHeader";
import PageContainer from "../../components/PageContainer";
import MemberNav from "../members/_partials/MemberNav";
import InlineBudgetItem from "./_partials/InlineBudgetItem";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import Modal from "../../components/Modal";
import AddNewBudgetItem from "./_partials/AddNewBudgetItem";
import SaveButton from "../../components/SaveButton";
import { useToasts } from "react-toast-notifications";
import SectionHeader from "../../components/SectionHeader";
import useHasPermission from "hooks/usePermissions";

export default function BudgetItems({ member, ...props }) {
    const [budgetItems, setBudgetItems] = useState([]);
    const [newBudgetItem, setNewBudgetItem] = useState({});
    const [nextId, setNextId] = useState(0);
    const [showModal, setShowModal] = useState(false);
    const { addToast } = useToasts();
    const canCustomizeBudgets = useHasPermission("budget_item_customization");

    const getBudgetItems = useCallback(async () => {
        const response = await Api.get(`members/${member.id}/budget-items`);
        setBudgetItems(response.data);
    }, [member.id]);

    useEffect(() => {
        getBudgetItems();
    }, [getBudgetItems]);

    const InlineHeaderItem = ({ itemLabel }) => {
        return (
            <div className="flex space-x-3 text-brand font-bold border-b border-gray-200 mb-6 pb-2">
                <div>{itemLabel}</div>
            </div>
        );
    };

    const updateValue = (id, categoryId, name, val) => {
        let updatedItems = budgetItems.map((b) => {
            if (b.id === id) {
                let items = b.budget_category_items;
                b.budget_category_items = items.map((i) => {
                    if (i.id === categoryId) {
                        i[name] = val;
                    }
                    return i;
                });
                return b;
            } else {
                return b;
            }
        });

        setBudgetItems(updatedItems);
    };

    const saveItems = async () => {
        const body = { ...budgetItems };

        try {
            let response = await Api.post(
                `members/${member.id}/budget-items`,
                body
            );
            addToast(response.data.message);
        } catch (e) {
            if (e.response && e.response.data) {
                addToast(e.response.data.message, { type: "error" });
            } else {
                addToast(e.message, { type: "error" });
            }
            console.log(e);
        }
    };

    return (
        <>
            <MemberHeader text={member.full_name}>
                {canCustomizeBudgets && (
                    <SaveButton onClick={() => saveItems()} />
                )}
            </MemberHeader>
            <PageContainer forbidden={!canCustomizeBudgets}>
                <div className="flex">
                    <div className="mr-10 flex-initial">
                        <MemberNav
                            active={props.history.location.pathname}
                            member={member}
                        />
                    </div>
                    <div className="flex-1 max-full">
                        <SectionHeader>Budget Items</SectionHeader>
                        <p className="pb-4">
                            Modifications to the Budget Items below apply to the
                            entire firm. Changing a budget item{"'"}s name will
                            affect previously entered budgets by clients.{" "}
                        </p>
                        {budgetItems.map((b) => (
                            <div key={b.id} className="mb-6">
                                <InlineHeaderItem itemLabel={b.name} />
                                <div className="space-y-2">
                                    {b.budget_category_items.map((i) => (
                                        <InlineBudgetItem
                                            key={i.id}
                                            budgetItem={i}
                                            updateValue={updateValue}
                                            readOnly={!canCustomizeBudgets}
                                        />
                                    ))}
                                    {canCustomizeBudgets && (
                                        <div>
                                            <button
                                                type="button"
                                                onClick={() => {
                                                    setNewBudgetItem(
                                                        {
                                                            id: `UDF${nextId}`,
                                                            budget_category_id:
                                                                b.id,
                                                            name: "",
                                                            sort_order:
                                                                Math.max.apply(
                                                                    Math,
                                                                    b.budget_category_items.map(
                                                                        function (
                                                                            o
                                                                        ) {
                                                                            return o.sort_order;
                                                                        }
                                                                    )
                                                                ) + 10,
                                                            has_other_value: 0,
                                                            has_joint_values:
                                                                "1",
                                                        },
                                                        setShowModal(true)
                                                    );
                                                    setNextId(nextId + 1);
                                                }}
                                                className="flex items-center space-x-1 text-gray-800 hover:text-brand p-1 mr-auto mt-4 text-xs"
                                            >
                                                <FontAwesomeIcon
                                                    icon={faPlusCircle}
                                                    className="text-gray-400"
                                                />{" "}
                                                <span>
                                                    Add new {b.name} item
                                                </span>
                                            </button>
                                        </div>
                                    )}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                <Modal
                    isOpen={showModal}
                    backgroundDismiss={true}
                    title="Add Item"
                    size="2xl"
                    onCancel={() => {
                        setNewBudgetItem({});
                        setShowModal(false);
                    }}
                    component={() => (
                        <AddNewBudgetItem
                            budgetItem={newBudgetItem}
                            saveValue={(item) => {
                                setShowModal(false);

                                let updatedItems = budgetItems.map((b) => {
                                    if (b.id === item.budget_category_id) {
                                        b.budget_category_items.push(item);
                                    }
                                    return b;
                                });

                                setBudgetItems(updatedItems);
                            }}
                        />
                    )}
                />
            </PageContainer>
        </>
    );
}
