import React from "react";
import TextInput from "../../../components/TextInput";
import CountrySelector from "../../../components/CountrySelector";

export default function OrganizationForm({
    relationshipInput,
    readOnly = false,
    allFields = true,
    errors,
    onChange,
}) {
    return (
        <>
            <div className="mb-6">
                <TextInput
                    name="organization_name"
                    label="Organization Name"
                    id="organization_name"
                    value={relationshipInput.organization_name}
                    error={errors.organization_name}
                    onChange={onChange}
                    autoFocus={true}
                    readOnly={readOnly}
                    required={true}
                />
            </div>

            <div className="mb-6">
                <TextInput
                    name="organization_website"
                    label="Organization Website"
                    id="organization_website"
                    value={relationshipInput.organization_website}
                    error={errors.organization_website}
                    onChange={onChange}
                    readOnly={readOnly}
                />
            </div>

            <div className="mb-6">
                <TextInput
                    type="text"
                    secret={true}
                    name="ssn"
                    label="Tax ID Number"
                    autoComplete="off"
                    id="ssn"
                    value={relationshipInput.ssn}
                    error={errors.ssn}
                    onChange={onChange}
                    readOnly={readOnly}
                />
            </div>
            {allFields && (
                <>
                    <fieldset className="mt-12">
                        <legend className="font-bold txt-md text-gray-500 my-6 pb-1 border-b block w-full">
                            Point Of Contact
                        </legend>
                        <div className="flex items-end space-x-3 mb-6">
                            <div className="w-20">
                                <TextInput
                                    name="prefix"
                                    label="Prefix"
                                    id="prefix"
                                    value={relationshipInput.prefix}
                                    error={errors.prefix}
                                    autoFocus={false}
                                    readOnly={readOnly}
                                    onChange={onChange}
                                />
                            </div>
                            <div className="flex-1">
                                <TextInput
                                    name="first_name"
                                    label="First Name"
                                    id="first_name"
                                    value={relationshipInput.first_name}
                                    error={errors.first_name}
                                    autoFocus={false}
                                    readOnly={readOnly}
                                    onChange={onChange}
                                />
                            </div>
                            <div className="flex-1">
                                <TextInput
                                    name="middle_name"
                                    label="Middle Name"
                                    id="middle_name"
                                    value={relationshipInput.middle_name}
                                    error={errors.middle_name}
                                    onChange={onChange}
                                    readOnly={readOnly}
                                />
                            </div>
                            <div className="flex-1">
                                <TextInput
                                    name="last_name"
                                    label="Last Name"
                                    id="last_name"
                                    value={relationshipInput.last_name}
                                    error={errors.last_name}
                                    onChange={onChange}
                                    readOnly={readOnly}
                                />
                            </div>
                            <div className="w-20">
                                <TextInput
                                    name="suffix"
                                    label="Suffix"
                                    id="suffix"
                                    value={relationshipInput.suffix}
                                    error={errors.suffix}
                                    autoFocus={false}
                                    readOnly={readOnly}
                                    onChange={onChange}
                                />
                            </div>
                        </div>

                        <div className="flex items-end space-x-3 mb-6">
                            <div className="flex-1">
                                <TextInput
                                    name="signature_name"
                                    label="Signature Name"
                                    id="signature_name"
                                    value={relationshipInput.signature_name}
                                    error={errors.signature_name}
                                    onChange={onChange}
                                    readOnly={readOnly}
                                />
                            </div>

                            <div className="flex-1">
                                <TextInput
                                    name="maiden_name"
                                    label="Maiden Name"
                                    id="maiden_name"
                                    value={relationshipInput.maiden_name}
                                    error={errors.maiden_name}
                                    onChange={onChange}
                                    readOnly={readOnly}
                                />
                            </div>

                            <div className="flex-1">
                                <TextInput
                                    name="nick_name"
                                    label="Nickname"
                                    id="nick_name"
                                    value={relationshipInput.nick_name}
                                    error={errors.nick_name}
                                    onChange={onChange}
                                    readOnly={readOnly}
                                />
                            </div>
                        </div>
                    </fieldset>

                    <div className="mb-6">
                        <TextInput
                            type="email"
                            name="email"
                            label="Email"
                            autoComplete="off"
                            id="email"
                            value={relationshipInput.email}
                            error={errors.email}
                            onChange={onChange}
                            readOnly={readOnly}
                        />
                    </div>

                    <div className="mb-6">
                        <TextInput
                            type="tel"
                            name="phone"
                            label="Main Phone"
                            id="phone"
                            value={relationshipInput.phone}
                            error={errors.phone}
                            onChange={onChange}
                            readOnly={readOnly}
                        />
                    </div>

                    <div className="mb-6">
                        <TextInput
                            type="tel"
                            name="mobile_phone"
                            label="Mobile Phone"
                            id="mobile_phone"
                            value={relationshipInput.mobile_phone}
                            error={errors.mobile_phone}
                            readOnly={readOnly}
                            onChange={onChange}
                        />
                    </div>

                    <div className="mb-6">
                        <TextInput
                            name="address"
                            label="Address"
                            id="address"
                            value={relationshipInput.address}
                            error={errors.address}
                            onChange={onChange}
                            readOnly={readOnly}
                        />
                    </div>

                    <div className="mb-6">
                        <TextInput
                            name="address_2"
                            label="Address 2"
                            id="address_2"
                            value={relationshipInput.address_2}
                            error={errors.address_2}
                            onChange={onChange}
                            readOnly={readOnly}
                        />
                    </div>

                    <div className="flex mb-6">
                        <div className="w-1/3 mr-4">
                            <TextInput
                                name="city"
                                label="City"
                                id="city"
                                value={relationshipInput.city}
                                error={errors.city}
                                onChange={onChange}
                                readOnly={readOnly}
                            />
                        </div>
                        <div className="w-1/3 mr-4">
                            <TextInput
                                name="state"
                                label="State"
                                id="state"
                                value={relationshipInput.state}
                                error={errors.state}
                                onChange={onChange}
                                readOnly={readOnly}
                            />
                        </div>
                        <div className="w-1/3">
                            <TextInput
                                name="postal_code"
                                label="Postal Code"
                                id="postal_code"
                                value={relationshipInput.postal_code}
                                error={errors.postal_code}
                                onChange={onChange}
                                readOnly={readOnly}
                            />
                        </div>
                    </div>

                    <div className="mb-6">
                        <TextInput
                            name="county"
                            label="County"
                            id="county"
                            value={relationshipInput.county}
                            error={errors.county}
                            onChange={onChange}
                            readOnly={readOnly}
                        />
                    </div>

                    <div className="mb-6">
                        <CountrySelector
                            label="Country"
                            value={relationshipInput.country}
                            name="country"
                            onChange={onChange}
                            disabled={readOnly}
                        />
                    </div>
                </>
            )}
        </>
    );
}
