import React, { useState } from "react";
import Modal from "../../components/Modal";
import useResetAllClientUserPermissions from "../../hooks/useResetAllClientUserPermissions";

export default function ModalResetClientUserPermissions({
    client,
    user,
    show,
    setShow,
    onResult,
}) {
    const [errors, setErrors] = useState([]);

    const {
        onSubmit: onResetAll,
        isLoading,
        isSuccess,
    } = useResetAllClientUserPermissions(client.id, user.id);

    const resetPermissions = async (event) => {
        await onResetAll(event);
    };

    React.useEffect(() => {
        if (isSuccess) {
            setShow(false);
            onResult({});
        }
    }, [isSuccess]);

    return (
        <Modal
            isOpen={show}
            type="warning"
            title={`Reset All Permissions for ${user.full_name} on behalf of ${client.full_name}`}
            body={() => {
                return (
                    <>
                        <p>
                            Are you sure you want to reset permissions for{" "}
                            <strong>{user.full_name}</strong> on behalf of{" "}
                            <strong>{client.full_name}</strong>? Type in{" "}
                            <strong>reset</strong> below to confirm.
                        </p>
                        <p>
                            <strong>Note:</strong> This resets permissions for
                            this user to the default organization permissions.
                            This also does not affect individual access to
                            resources given to this client.
                        </p>
                    </>
                );
            }}
            size="lg"
            inputType="text"
            inputErrors={errors}
            doneButtonTitle="Reset"
            isLoading={isLoading}
            onResult={async ({ event, result }) => {
                if (result !== "reset") {
                    if (typeof result === "string") {
                        setErrors([
                            'Please confirm reset by typing in "reset"',
                        ]);
                        return;
                    }
                    setErrors([]);
                    setShow(false);
                    return;
                }

                await resetPermissions(event);
            }}
        />
    );
}
