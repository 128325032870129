import React from "react";
import Button from "../../components/Button";
import { downloadFileAsLink } from "../../util/Downloader";

export default function EditRetirementAccountForms({ client, input }) {
    document.title = "Forms - Letters of Instruction";

    return (
        <table className="w-full text-left">
            <thead>
                <tr>
                    <th>Name</th>
                    <th>Description</th>
                    <th className="text-right">Actions</th>
                </tr>
            </thead>
            {
                <RetirementAccountForms
                    clientId={client.id}
                    accountId={input.id}
                />
            }
        </table>
    );
}

function RetirementAccountForms({ clientId, accountId }) {
    const downloadTodInstructions = () => {
        const url = `clients/${clientId}/retirement-accounts/${accountId}/instructions-for-retirement-account`;
        downloadFileAsLink(url);
    };

    return (
        <tbody>
            <tr>
                <td>Beneficiary/TOD Change Form</td>
                <td>
                    Merge proposed beneficiary/TOD information for the asset to
                    a document to provide to financial institutions.
                </td>
                <td className="text-right">
                    <Button
                        type="button"
                        text="Download Document"
                        height="h-10"
                        appearance="outline"
                        width="w-auto"
                        onClick={downloadTodInstructions}
                    />
                </td>
            </tr>
        </tbody>
    );
}
