import React from "react";
import Button from "../../../components/Button";
import Modal from "../../../components/Modal";
import Money from "../../../components/Money";
import useIceCard from "../hooks/useIceCard";
import TextInput from "../../../components/TextInput";
import CountrySelector from "../../../components/CountrySelector";

export default function ICECheckoutModal() {
    const {
        checkout,
        spouseCardQty,
        clientCardQty,
        shippingInfo: input,
        shippingInfoErrors: errors,
        updateShippingInfo: updateValue,
        cost,
        isCheckoutOpen,
        setIsCheckoutOpen,
    } = useIceCard();

    const qty = spouseCardQty + clientCardQty;

    return (
        <Modal
            onResult={() => setIsCheckoutOpen(false)}
            isOpen={isCheckoutOpen}
            title="ICE Card Checkout"
            showButtons={false}
        >
            <div className="mt-8">
                <div>Total Cards: {qty}</div>
                <div>
                    Total Cost:{" "}
                    {<Money value={cost * qty} formatNegative={true} />}
                </div>
            </div>
            <div className="my-6">
                <hr />
            </div>
            <div className="text-left">
                <div className="text-xl mb-4">Shipping Information</div>
                <form className="space-y-2" onSubmit={(e) => checkout(e)}>
                    <TextInput
                        name="shipping_name"
                        label="Name"
                        id="shipping_name"
                        value={input.shipping_name}
                        error={errors.shipping_name}
                        onChange={updateValue}
                        required={true}
                        autoFocus={true}
                    />
                    <TextInput
                        name="shipping_address"
                        label="Address"
                        id="shipping_address"
                        value={input.shipping_address}
                        error={errors.shipping_address}
                        onChange={updateValue}
                        required={true}
                    />
                    <TextInput
                        name="shipping_address_2"
                        label="Address 2"
                        id="shipping_address_2"
                        value={input.shipping_address_2}
                        error={errors.shipping_address_2}
                        onChange={updateValue}
                    />
                    <div className="flex mb-6">
                        <div className="w-1/3 mr-4">
                            <TextInput
                                name="shipping_city"
                                label="City"
                                id="shipping_city"
                                value={input.shipping_city}
                                error={errors.shipping_city}
                                onChange={updateValue}
                                required={true}
                            />
                        </div>
                        <div className="w-1/3 mr-4">
                            <TextInput
                                name="shipping_state"
                                label="State"
                                id="shipping_state"
                                value={input.shipping_state}
                                error={errors.shipping_state}
                                onChange={updateValue}
                                required={true}
                            />
                        </div>
                        <div className="w-1/3">
                            <TextInput
                                name="shipping_postal_code"
                                label="Postal Code"
                                id="shipping_postal_code"
                                value={input.shipping_postal_code}
                                error={errors.shipping_postal_code}
                                onChange={updateValue}
                                required={true}
                            />
                        </div>
                    </div>

                    <CountrySelector
                        label="Country"
                        value={input.shipping_country}
                        name="shipping_country"
                        onChange={updateValue}
                    />

                    <input type="submit" hidden />
                </form>
                <div className="w-48 mt-4 mx-auto">
                    <Button
                        onClick={checkout}
                        type="submit"
                        text="Complete Checkout"
                    />
                </div>
            </div>
        </Modal>
    );
}
