import React, { useState } from "react";
import FrequencySelect from "../../../components/FrequencySelect";
import TextInput from "../../../components/TextInput";
import useFormatCurrency from "../../../hooks/useFormatCurrency";
import SaveButton from "../../../components/SaveButton";

const formatCurrency = useFormatCurrency();

export default function AddNewRowItem({
    newItem,
    onSubmit,
    options,
    hasSpouse,
    clientName,
    spouseName,
    isNegative,
}) {
    const [name, setName] = useState(newItem.name);
    const [frequencyId, setFrequencyId] = useState(newItem.frequency_id);
    const [clientValue, setClientValue] = useState(newItem.client_value);
    const [spouseValue, setSpouseValue] = useState(newItem.spouse_value);
    const [jointValue, setJointValue] = useState(newItem.joint_value);

    const onLocalSubmit = (e) => {
        e.preventDefault();
        onSubmit({
            id: newItem.id,
            budget_category_id: newItem.budget_category_id,
            name: name,
            frequency_id: frequencyId,
            client_value: clientValue,
            spouse_value: spouseValue,
            joint_value: jointValue,
            sort_order: newItem.sort_order < 100 ? 100 : newItem.sort_order,
            has_other_value: newItem.has_other_value,
            has_joint_values: newItem.has_joint_values,
        });
    };

    return (
        <form onSubmit={onLocalSubmit}>
            <div className="mb-6">
                <TextInput
                    name="name"
                    label="Item Name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                />
            </div>
            <div className="flex space-x-3 items-center">
                <div className="flex-1 mb-6">
                    <FrequencySelect
                        name={"frequency_id"}
                        label="Frequency"
                        optionItems={options}
                        value={frequencyId}
                        onChange={(e) => setFrequencyId(e.target.value)}
                    />
                </div>
                <div className="flex-1 mb-6">
                    <TextInput
                        name="client_value"
                        label={`${clientName}`}
                        value={clientValue}
                        onChange={(e) => {
                            let newVal = e.target.value;
                            if (isNegative(newVal)) {
                                newVal = 0;
                            }
                            setClientValue(newVal);
                        }}
                        onBlur={(e) => {
                            setClientValue(formatCurrency(e.target.value));
                        }}
                        placeholder="0.00"
                    />
                </div>
                {hasSpouse && (
                    <>
                        <div className="flex-1 mb-6">
                            <TextInput
                                name="spouse_value"
                                label={`${spouseName}`}
                                value={spouseValue}
                                onChange={(e) => {
                                    let newVal = e.target.value;
                                    if (isNegative(newVal)) {
                                        newVal = 0;
                                    }
                                    setSpouseValue(newVal);
                                }}
                                onBlur={(e) => {
                                    setSpouseValue(
                                        formatCurrency(e.target.value)
                                    );
                                }}
                                placeholder="0.00"
                            />
                        </div>
                        <div className="flex-1 mb-6">
                            <TextInput
                                name="joint_value"
                                label="Joint"
                                value={jointValue}
                                onChange={(e) => {
                                    let newVal = e.target.value;
                                    if (isNegative(newVal)) {
                                        newVal = 0;
                                    }
                                    setJointValue(newVal);
                                }}
                                onBlur={(e) => {
                                    setJointValue(
                                        formatCurrency(e.target.value)
                                    );
                                }}
                                placeholder="0.00"
                            />
                        </div>
                    </>
                )}
            </div>
            <div className="w-48 ml-auto">
                <SaveButton type="submit" />
            </div>
        </form>
    );
}
