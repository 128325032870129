import React, { useState } from "react";
import ClientPageHeader, { Heading } from "../../components/ClientPageHeader";
import PageContainer from "../../components/PageContainer";
import SelectInput from "../../components/SelectInput";
import Button from "../../components/Button";
import ModalResetClientUserPermissions from "../clients/ModalResetClientUserPermissions";
import AdvisorNav from "./_partials/AdvisorNav";
import Badge from "components/Badge";
import { isBool } from "index";
import PermissionList from "components/Permissions/PermissionList";
import useClientUserPermissions from "../../hooks/useClientUserPermissions";
import useUpdateClientUserPermission from "hooks/useUpdateClientUserPermission";
import useResetClientUserPermission from "hooks/useResetClientUserPermission";
import Spinner from "../../components/Spinner";
import { getPermissionTypes } from "services/PermissionService";

export default function AdvisorPermissions({
    client,
    clientPath = null,
    advisor,
    ...props
}) {
    const [readOnly, setReadOnly] = useState(true);
    const [permissionType, setPermissionType] = useState("");
    const [showReset, setShowReset] = useState(false);

    const userId = advisor.collaborator_id;

    const { data, isLoading, refetch } = useClientUserPermissions({
        clientId: client.id,
        userId,
    });

    const permissionTypes = getPermissionTypes(data);

    const { onSubmit, isSuccess } = useUpdateClientUserPermission(
        client.id,
        userId
    );
    const { onSubmit: onReset, isSuccess: isReset } =
        useResetClientUserPermission(client.id, userId);

    const resetPermission = async (event, permission) => {
        onReset(event, permission);
    };

    const updatePermission = async (event, permission, toggled) => {
        onSubmit(event, {
            key: permission,
            enabled: toggled,
        });
    };

    const filteredPermissions = () => {
        if (permissionType === "general" || permissionType === "report") {
            return data.filter(
                (permission) => permission.type === permissionType
            );
        }
        return data;
    };

    React.useEffect(() => {
        if (isSuccess || isReset) {
            refetch();
        }
    }, [isSuccess, isReset]);

    if (!userId) {
        return (
            <div className="flex justify-center items-center w-full h-full">
                <div>Advisor is not a collaborator</div>
            </div>
        );
    }

    return (
        <>
            <ClientPageHeader client={client} text="Permissions">
                <Heading
                    backText="All Advisors"
                    backTo={`${clientPath}/advisors`}
                >
                    <div className="space-x-3">
                        <span>Permissions for {advisor.full_name}</span>
                        {isBool(advisor.can_collaborate) && (
                            <Badge color="green">Collaborator</Badge>
                        )}
                    </div>
                </Heading>
                <div className="flex items-end space-x-3">
                    <div className="flex-1">
                        <Button
                            text="Reset All Permissions"
                            onClick={() => setShowReset(true)}
                        />
                    </div>
                    <div className="flex-1">
                        <Button
                            text={readOnly ? "Unlock" : "Lock"}
                            onClick={() => setReadOnly((readOnly) => !readOnly)}
                        />
                    </div>
                </div>
            </ClientPageHeader>
            <PageContainer>
                <div className="flex space-x-10">
                    <div className="flex-initial">
                        <AdvisorNav
                            clientPath={clientPath}
                            active={props.history.location.pathname}
                            advisor={advisor}
                        />
                    </div>
                    <div className="flex-1">
                        {isLoading && <Spinner />}
                        {client && data && (
                            <form className="max-w-3xl mx-auto">
                                <div className="flex justify-between items-center py-6 border-b">
                                    <label>Permission Type</label>
                                    <div className="w-64 max-w-full">
                                        <SelectInput
                                            options={permissionTypes}
                                            value={permissionType}
                                            name="type"
                                            readOnly={readOnly}
                                            onChange={(e) => {
                                                setPermissionType(
                                                    e.target.value
                                                );
                                            }}
                                        />
                                    </div>
                                </div>
                                <PermissionList
                                    permissions={filteredPermissions()}
                                    resetPermission={resetPermission}
                                    updatePermission={updatePermission}
                                    readOnly={readOnly}
                                />

                                <input type="submit" hidden />
                            </form>
                        )}
                    </div>
                </div>
            </PageContainer>

            <ModalResetClientUserPermissions
                client={client}
                user={{ ...advisor, id: userId }}
                show={showReset}
                setShow={setShowReset}
                onResult={async () => {
                    refetch();
                }}
            />
        </>
    );
}
