import React, { useCallback } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { Link, useLocation } from "react-router-dom";
import ClientMenuForMembers from "./Navigation/ClientMenuForMembers";
import ClientMenuForAdvisors from "./Navigation/ClientMenuForAdvisors";
import useClient from "hooks/useClient";
import useAuth from "hooks/useAuth";

export const iconClass = "ml-1 mt-1 text-gray-400 text-sm";
export const activeClass =
    "flex items-center flex-no-wrap text-gray-900 bg-gray-100 px-3 py-2 rounded-md";
export const inActiveClass =
    "flex items-center flex-no-wrap text-gray-600 bg-transparent px-3 py-2 rounded-md hover:bg-gray-100";

export default function ClientPageHeader({ ...props }) {
    return (
        <>
            {props.children && (
                <HeadingContainer>{props.children}</HeadingContainer>
            )}
        </>
    );
}

export function ClientPageNavigation({ ...props }) {
    const { user } = useAuth();
    const { client } = useClient();

    if (user.type === "member") {
        return (
            <div className="flex-shrink-0 w-full h-16 flex justify-between bg-white border-b border-gray-100 print:hidden">
                <ClientMenuForMembers client={client} {...props} />
            </div>
        );
    }

    if (user.type === "advisor") {
        return (
            <div className="flex-shrink-0 w-full h-16 flex justify-between bg-white border-b border-gray-100 print:hidden">
                <ClientMenuForAdvisors client={client} {...props} />
            </div>
        );
    }

    return <></>;
}

export function ClientProfileMenuContainer({ children }) {
    return (
        <div className="relative h-full flex items-center justify-center flex-0 w-60 border-r border-gray-100">
            {children}
        </div>
    );
}

export function MenuBarContainer({ children }) {
    return (
        <div className="flex-1 flex items-center pl-6 pr-10">{children}</div>
    );
}

export function MenuItemsContainer({ children }) {
    return <div className="flex items-center space-x-6">{children}</div>;
}

export function IconTray({ children }) {
    return <div className="flex-none ml-auto">{children}</div>;
}

export function MenuLink({ to, paths, children }) {
    const { isActive } = useActiveMenuItem();
    return (
        <Link to={to} className={isActive(paths) ? activeClass : inActiveClass}>
            {children}
        </Link>
    );
}

export function HeadingContainer({ children, className = "" }) {
    return (
        <div className="print:hidden sticky top-0 z-40 bg-gray-50">
            <div
                className={`mx-8 mt-6 py-3 px-0 flex items-center border-b border-gray-200 ${className}`}
            >
                {children}
            </div>
        </div>
    );
}

export function Heading({ backTo = null, backText = null, ...props }) {
    if (backTo && backText) {
        return (
            <div className="w-full">
                <div className="inline-block h-12">
                    <Link
                        to={backTo}
                        className="flex items-center space-x-1 hover:text-gray-700"
                    >
                        <span className="text-xs text-gray-300">
                            <FontAwesomeIcon icon={faChevronLeft} />
                        </span>
                        <span className="text-gray-500 text-sm">
                            {backText}
                        </span>
                    </Link>
                    <h1 className="flex items-end flex-1 text-xl font-normal text-gray-600">
                        {props.children}
                    </h1>
                </div>
            </div>
        );
    }

    return (
        <h1 className="flex items-end flex-1 text-xl font-normal text-gray-600 h-12">
            {props.children}
        </h1>
    );
}

export function useActiveMenuItem() {
    const location = useLocation();

    const isActive = useCallback(
        (paths, segment) => {
            if (segment) {
                const segments = location.pathname.split("/");
                if (Array.isArray(paths)) {
                    return paths.includes(segments[segment]);
                }
                return segments[segment] === paths;
            }

            // If a string is passed with no segment specified then just search the location path for that string.
            if (Array.isArray(paths)) {
                let isInPath = false;
                paths.forEach((path) => {
                    if (location.pathname.indexOf(path) > -1) {
                        isInPath = true;
                    }
                });
                return isInPath;
            } else {
                return location.pathname.indexOf(paths) > -1 ? true : false;
            }
        },
        [location.pathname]
    );

    return { isActive };
}
