import React from "react";
import { Route, Switch } from "react-router-dom";
import IncomeSourcesIndex from "./IncomeSourcesIndex";
import AddIncomeSource from "./AddIncomeSource";
import EditIncomeSource from "./EditIncomeSource";

export default function IncomeSources(parentProps) {
    return (
        <Switch>
            <Route
                exact
                path={[
                    "/clients/:clientId/income-sources",
                    "/client/income-sources",
                ]}
                render={(props) => (
                    <IncomeSourcesIndex {...parentProps} {...props} />
                )}
            />
            <Route
                exact
                path={[
                    "/clients/:clientId/income-sources/add",
                    "/client/income-sources/add",
                ]}
                render={(props) => (
                    <AddIncomeSource {...parentProps} {...props} />
                )}
            />
            <Route
                path={[
                    "/clients/:clientId/income-sources/:incomeSourceId/edit",
                    "/client/income-sources/:incomeSourceId/edit",
                ]}
                render={(props) => (
                    <EditIncomeSource {...parentProps} {...props} />
                )}
            />
            <Route
                path={[
                    "/clients/:clientId/income-sources/:incomeSourceId/view",
                    "/client/income-sources/:incomeSourceId/view",
                ]}
                render={(props) => (
                    <EditIncomeSource
                        readOnly={true}
                        {...parentProps}
                        {...props}
                    />
                )}
            />
        </Switch>
    );
}
