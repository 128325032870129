import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faEllipsisV,
    faTrash,
    faPlus,
} from "@fortawesome/free-solid-svg-icons";

import Api from "../../services/Api";
import EmptyTableRow from "../../components/EmptyTableRow";
import Spinner from "../../components/Spinner";
import Panel, { PanelHeader } from "../../components/Panel";
import Dropdown, { DropdownItem } from "../../components/Dropdown";

import ModalUnlinkResource from "./ModalUnlinkResource";
import { Link } from "react-router-dom";
import { formatDate } from "../../util/TextUtil";
import Button from "../../components/Button";
import ModalLinkResource from "./ModalLinkResource";
import useHasAnyPermissions from "hooks/useHasAnyPermissions";

export default function VaultResourcesList({
    client,
    vaultEntry,
    appearance = "flat",
}) {
    const [relatedResources, setRelatedResources] = useState([]);
    const [showDelete, setShowDelete] = useState(false);
    const [showLinkResource, setShowLinkResource] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedRelation, setSelectedRelation] = useState(null);
    const [forceUpdate, setForceUpdate] = useState(0);

    const canGrantAccessToResources = useHasAnyPermissions([
        "member_resource_access_management",
        "client_resource_access_management",
        "advisor_resource_access_management",
    ]);

    const hasPermission = useHasAnyPermissions([
        "firm_client_wealth_records_global_access",
        "client_wealth_records_global_access",
        "client_wealth_records_editing",
    ]);

    useEffect(() => {
        const fetch = async () => {
            try {
                setIsLoading(true);
                const response = await Api.get(
                    `resources/relations/resources/${client.id}/${vaultEntry.id}`
                );
                setRelatedResources(response.data.resources);
                setIsLoading(false);
            } catch (e) {
                setIsLoading(false);
            }
        };
        if (canGrantAccessToResources) {
            fetch();
        }
    }, [client, vaultEntry, forceUpdate]);

    const openDelete = (access) => {
        setShowDelete(true);
        setSelectedRelation(access);
    };

    const OptionsDropdown = ({ relation }) => {
        return (
            <span className="block w-full">
                <Dropdown
                    position="right"
                    toggleContent={
                        <>
                            <div className="py-1 px-3 w-full block">
                                <FontAwesomeIcon icon={faEllipsisV} />
                            </div>
                        </>
                    }
                >
                    <DropdownItem
                        label="Unlink Resource"
                        icon={faTrash}
                        handleClick={() => openDelete(relation)}
                    />
                </Dropdown>
            </span>
        );
    };

    if (!hasPermission || !canGrantAccessToResources) {
        return <></>;
    }

    return (
        <>
            <Panel
                allowOverflow={true}
                appearance={appearance}
                header={
                    <PanelHeader title="Related Resources">
                        <Button
                            base={false}
                            height={null}
                            appearance={"link"}
                            onClick={() => setShowLinkResource(true)}
                        >
                            <FontAwesomeIcon icon={faPlus} /> Link Resource
                        </Button>
                    </PanelHeader>
                }
            >
                <table className="w-full text-left">
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Type</th>
                            <th>Date Linked</th>
                            <th>Linked By</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {relatedResources.map((relation, idx) => (
                            <tr key={idx}>
                                <td>
                                    <Link
                                        to={relation.resource_path}
                                        className="text-blue-500"
                                        title="Clients"
                                    >
                                        {relation.resource_name}
                                    </Link>
                                </td>
                                <td>{relation.resource_type}</td>
                                <td>{formatDate(relation.linked_at)}</td>
                                <td>{relation.creator_full_name}</td>
                                <td className="text-right">
                                    <OptionsDropdown relation={relation} />
                                </td>
                            </tr>
                        ))}
                        {!isLoading && relatedResources.length === 0 && (
                            <EmptyTableRow colSpan="5">
                                No Related Resources
                            </EmptyTableRow>
                        )}
                    </tbody>
                </table>
                {isLoading && relatedResources.length === 0 && (
                    <div className="w-full h-full flex items-center justify-center">
                        <Spinner />
                    </div>
                )}

                <ModalUnlinkResource
                    relation={selectedRelation}
                    vaultEntry={vaultEntry}
                    show={showDelete}
                    setShow={setShowDelete}
                    onResult={() => {
                        setForceUpdate(forceUpdate + 1);
                    }}
                />

                <ModalLinkResource
                    client={client}
                    relation={selectedRelation}
                    vaultEntry={vaultEntry}
                    show={showLinkResource}
                    setShow={setShowLinkResource}
                    onResult={() => {
                        setForceUpdate(forceUpdate + 1);
                    }}
                />
            </Panel>
        </>
    );
}
