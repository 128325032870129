import React, { useEffect } from "react";

// keyCode constants
const BACKSPACE = 8;
const LEFT_ARROW = 37;
const RIGHT_ARROW = 39;
const DELETE = 46;
const SPACEBAR = 32;

// Doesn't really check if it's a style Object
// Basic implementation to check if it's not a string
// of classNames and is an Object
// TODO: Better implementation
const isStyleObject = (obj) => typeof obj === "object";

const SingleOtpInput = ({ focus, ...props }) => {
    const input = React.useRef(null);

    useEffect(() => {
        if (input && focus) {
            input.current.focus();
            input.current.select();
        }
    }, [focus, input]);

    const getClasses = (...classes) =>
        classes.filter((c) => !isStyleObject(c) && c !== false).join(" ");

    const {
        separator,
        useBaseStyles = false,
        isLastChild,
        inputStyle,
        isDisabled,
        hasErrored,
        errorStyle,
        focusStyle,
        disabledStyle,
        inputContainerStyle,
        focusInputContainerStyle,
        disabledInputContainerStyle,
        errorInputContainerStyle,
        isInputNum,
        value,
        ...rest
    } = props;

    const numValueLimits = isInputNum ? { min: 0, max: 9 } : {};

    return (
        <div
            style={Object.assign(
                useBaseStyles ? { display: "flex", alignItems: "center" } : {},
                isStyleObject(inputContainerStyle) && inputContainerStyle,
                focus &&
                    isStyleObject(focusInputContainerStyle) &&
                    focusInputContainerStyle,
                isDisabled &&
                    isStyleObject(disabledInputContainerStyle) &&
                    disabledInputContainerStyle,
                hasErrored &&
                    isStyleObject(errorInputContainerStyle) &&
                    errorInputContainerStyle
            )}
            className={getClasses(
                inputContainerStyle,
                focus && focusInputContainerStyle,
                isDisabled && disabledInputContainerStyle,
                hasErrored && errorInputContainerStyle
            )}
        >
            <input
                style={Object.assign(
                    useBaseStyles ? { width: "1em", textAlign: "center" } : {},
                    isStyleObject(inputStyle) && inputStyle,
                    focus && isStyleObject(focusStyle) && focusStyle,
                    isDisabled && isStyleObject(disabledStyle) && disabledStyle,
                    hasErrored && isStyleObject(errorStyle) && errorStyle
                )}
                className={getClasses(
                    inputStyle,
                    focus && focusStyle,
                    isDisabled && disabledStyle,
                    hasErrored && errorStyle
                )}
                type={isInputNum ? "number" : "tel"}
                {...numValueLimits}
                maxLength={1}
                ref={input}
                disabled={isDisabled}
                value={value ? value : ""}
                {...rest}
            />
            {!isLastChild && separator}
        </div>
    );
};

export default function OtpInput({
    numInputs = 4,
    onChange = () => {},
    isDisabled = false,
    containerStyle = "",
    value = "",
    isInputNum = false,
    ...props
}) {
    const [activeInput, setActiveInput] = React.useState(0);

    const getOtpValue = () => (value ? ("" + value).split("") : []);

    // Helper to return OTP from input
    const handleOtpChange = (otp) => {
        const otpValue = otp.join("");
        onChange(isInputNum ? Number(otpValue) : otpValue);
    };

    // Focus on input by index
    const focusInput = (input) => {
        const activeInput = Math.max(Math.min(numInputs - 1, input), 0);
        setActiveInput(activeInput);
    };

    // Focus on next input
    const focusNextInput = () => {
        focusInput(activeInput + 1);
    };

    // Focus on previous input
    const focusPrevInput = () => {
        focusInput(activeInput - 1);
    };

    // Change OTP value at focused input
    const changeCodeAtFocus = (value) => {
        const otp = getOtpValue();
        otp[activeInput] = value[0];
        handleOtpChange(otp);
    };

    // Handle pasted OTP
    const handleOnPaste = (e) => {
        e.preventDefault();
        const otp = getOtpValue();

        // Get pastedData in an array of max size (num of inputs - current position)
        const pastedData = e.clipboardData
            .getData("text/plain")
            .slice(0, numInputs - activeInput)
            .split("");

        // Paste data from focused input onwards
        for (let pos = 0; pos < numInputs; ++pos) {
            if (pos >= activeInput && pastedData.length > 0) {
                otp[pos] = pastedData.shift();
            }
        }

        handleOtpChange(otp);
    };

    const handleOnChange = (e) => {
        changeCodeAtFocus(e.target.value);
        focusNextInput();
    };

    // Handle cases of backspace, delete, left arrow, right arrow, space
    const handleOnKeyDown = (e) => {
        if (e.keyCode === BACKSPACE || e.key === "Backspace") {
            e.preventDefault();
            changeCodeAtFocus("");
            focusPrevInput();
        } else if (e.keyCode === DELETE || e.key === "Delete") {
            e.preventDefault();
            changeCodeAtFocus("");
        } else if (e.keyCode === LEFT_ARROW || e.key === "ArrowLeft") {
            e.preventDefault();
            focusPrevInput();
        } else if (e.keyCode === RIGHT_ARROW || e.key === "ArrowRight") {
            e.preventDefault();
            focusNextInput();
        } else if (
            e.keyCode === SPACEBAR ||
            e.key === " " ||
            e.key === "Spacebar"
        ) {
            e.preventDefault();
        }
    };

    const checkLength = (e) => {
        if (e.target.value.length > 1) {
            e.preventDefault();
            focusNextInput();
        }
    };

    const renderInputs = () => {
        const {
            inputStyle,
            focusStyle,
            inputContainerStyle,
            focusInputContainerStyle,
            disabledInputContainerStyle,
            errorInputContainerStyle,
            separator,
            disabledStyle,
            hasErrored,
            errorStyle,
        } = props;
        const otp = getOtpValue();
        const inputs = [];

        for (let i = 0; i < numInputs; i++) {
            inputs.push(
                <SingleOtpInput
                    key={i}
                    focus={activeInput === i}
                    value={otp && otp[i]}
                    onChange={handleOnChange}
                    onKeyDown={handleOnKeyDown}
                    onInput={checkLength}
                    onPaste={handleOnPaste}
                    onFocus={(e) => {
                        setActiveInput(i);
                        e.target.select();
                    }}
                    onBlur={() => setActiveInput(-1)}
                    separator={separator}
                    inputStyle={inputStyle}
                    focusStyle={focusStyle}
                    isLastChild={i === numInputs - 1}
                    isDisabled={isDisabled}
                    disabledStyle={disabledStyle}
                    hasErrored={hasErrored}
                    errorStyle={errorStyle}
                    isInputNum={isInputNum}
                    inputContainerStyle={inputContainerStyle}
                    focusInputContainerStyle={focusInputContainerStyle}
                    disabledInputContainerStyle={disabledInputContainerStyle}
                    errorInputContainerStyle={errorInputContainerStyle}
                />
            );
        }

        return inputs;
    };

    if (activeInput > value.length) {
        focusInput(value.length);
    }

    return (
        <div
            style={Object.assign(
                { display: "flex" },
                isStyleObject(containerStyle) && containerStyle
            )}
            className={!isStyleObject(containerStyle) ? containerStyle : ""}
        >
            {renderInputs()}
        </div>
    );
}
