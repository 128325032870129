import React, { useEffect, useContext, useState } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import { useToasts } from "react-toast-notifications";
import useDebounce from "../../../hooks/useDebounce";
import Api from "../../../services/Api";
import SearchInput from "../../../components/SearchInput";
import { Link } from "react-router-dom";
import Spinner from "../../../components/Spinner";
import TableSortHeader from "../../../components/TableSortHeader";
import { formatDate } from "../../../util/TextUtil";
import Dropdown, { DropdownItem } from "../../../components/Dropdown";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faTrash,
    faEdit,
    faEllipsisH,
    faTrashRestore,
} from "@fortawesome/free-solid-svg-icons";
import TableSummaryRow from "../../../components/TableSummaryRow";
import { parseMoney } from "../../../components/Money";
import Forbidden from "../../../components/Forbidden";
import useFormatPercentage from "../../../hooks/useFormatPercentage";
import { useUserCan } from "hooks/useHasAnyPermissions";

export default function RetirementAccountTable({
    client,
    clientPath,
    withTrashed,
}) {
    const auth = useContext(AuthContext);
    const userType = auth.user.type;
    const { addToast } = useToasts();
    const formatPercentage = useFormatPercentage();
    const [loading, setLoading] = useState(true);
    const [retirementAccounts, setRetirementAccounts] = useState([]);
    const [orderBy, setOrderBy] = useState("created_at");
    const [sortDir, setSortDir] = useState("desc");
    const [search, setSearch] = useState("");
    const debouncedSearch = useDebounce(search, 250);
    const [notAuthorized, setNotAuthorized] = useState(false);
    const [needsUpdated, setNeedsUpdated] = useState(0);
    const canCreateWealthRecords = useUserCan("createWealthRecord");

    useEffect(() => {
        document.title = "Retirement Accounts";
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            try {
                let response = await Api.get(
                    "clients/" + client.id + "/retirement-accounts",
                    {
                        limit: 20,
                        sort: sortDir === "desc" ? "-" + orderBy : orderBy,
                        "filter[search]": debouncedSearch,
                        "filter[trashed]": withTrashed ? "only" : null,
                    }
                );
                setRetirementAccounts(response.data.data);
                setLoading(false);
            } catch (error) {
                if (error.response.status === 403) {
                    setNotAuthorized(true);
                }
            }
        };
        fetchData();
    }, [client, debouncedSearch, orderBy, sortDir, needsUpdated, withTrashed]);

    async function deleteRetirementAccount(retirementAccount) {
        try {
            const response = await Api.delete(
                "clients/" +
                    client.id +
                    "/retirement-accounts/" +
                    retirementAccount.id
            );
            addToast(response.data.message);
            setNeedsUpdated(needsUpdated + 1);
        } catch (e) {
            addToast(e.errorMessage, { type: "error" });
        }
    }

    async function restoreResource(retirementAccount) {
        try {
            await Api.put(
                `clients/${client.id}/retirement-accounts/${retirementAccount.id}/restore`,
                {
                    is_trashed: false,
                }
            );
            setNeedsUpdated(needsUpdated + 1);
        } catch (e) {
            addToast(e.errorMessage, { type: "error" });
        }
    }

    if (notAuthorized) {
        return <Forbidden />;
    }

    if (loading) {
        return <Spinner />;
    }

    return (
        <>
            <div className="mb-4">
                <SearchInput
                    label={false}
                    searchValue={search}
                    onSearchChange={(e) => setSearch(e.target.value)}
                />
            </div>
            {retirementAccounts.length ? (
                <>
                    <table className="w-full text-left">
                        <thead>
                            <tr>
                                <TableSortHeader
                                    field="name"
                                    sortField={orderBy}
                                    sortDir={sortDir}
                                    changeSort={(column) => {
                                        setSortDir(
                                            sortDir === "asc" ? "desc" : "asc"
                                        );
                                        setOrderBy(column);
                                    }}
                                >
                                    Account Name
                                </TableSortHeader>
                                <TableSortHeader
                                    field="account_number"
                                    sortField={orderBy}
                                    sortDir={sortDir}
                                    changeSort={(column) => {
                                        setSortDir(
                                            sortDir === "asc" ? "desc" : "asc"
                                        );
                                        setOrderBy(column);
                                    }}
                                >
                                    Account #
                                </TableSortHeader>
                                <TableSortHeader
                                    field="value"
                                    sortField={orderBy}
                                    sortDir={sortDir}
                                    changeSort={(column) => {
                                        setSortDir(
                                            sortDir === "asc" ? "desc" : "asc"
                                        );
                                        setOrderBy(column);
                                    }}
                                >
                                    Value
                                </TableSortHeader>
                                <TableSortHeader
                                    disabled={true}
                                    field="owner_name"
                                    sortField={orderBy}
                                    sortDir={sortDir}
                                    changeSort={(column) => {
                                        setSortDir(
                                            sortDir === "asc" ? "desc" : "asc"
                                        );
                                        setOrderBy(column);
                                    }}
                                >
                                    Owner
                                </TableSortHeader>
                                <TableSortHeader
                                    disabled={true}
                                    field="percentage_owned"
                                    sortField={orderBy}
                                    sortDir={sortDir}
                                    changeSort={(column) => {
                                        setSortDir(
                                            sortDir === "asc" ? "desc" : "asc"
                                        );
                                        setOrderBy(column);
                                    }}
                                >
                                    Percentage Owned
                                </TableSortHeader>
                                <TableSortHeader
                                    field="as_of_date"
                                    sortField={orderBy}
                                    sortDir={sortDir}
                                    changeSort={(column) => {
                                        setSortDir(
                                            sortDir === "asc" ? "desc" : "asc"
                                        );
                                        setOrderBy(column);
                                    }}
                                >
                                    As of Date
                                </TableSortHeader>
                                <th className="text-right">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {retirementAccounts.map((finAcct, idx) => (
                                <tr key={idx}>
                                    <td>
                                        <Link
                                            to={`${clientPath}/retirement-accounts/${finAcct.id}/view`}
                                        >{`${finAcct.name}`}</Link>
                                        <div className="text-xs text-gray-600">
                                            {
                                                finAcct.financial_account_type_name
                                            }
                                        </div>
                                    </td>
                                    <td>
                                        <span className="text-gray-800">
                                            xxx
                                        </span>
                                        {finAcct.account_number_last_four}
                                    </td>
                                    <td>${finAcct.value}</td>
                                    <td>{finAcct.owner_name}</td>
                                    <td>
                                        {formatPercentage(
                                            finAcct.percentage_owned
                                        )}
                                    </td>
                                    <td>{formatDate(finAcct.as_of_date)}</td>
                                    <td className="text-right">
                                        <span>
                                            <Dropdown
                                                position="right"
                                                toggleClass="px-3 py-1"
                                                toggleContent={
                                                    <FontAwesomeIcon
                                                        icon={faEllipsisH}
                                                    />
                                                }
                                            >
                                                {!withTrashed ? (
                                                    <>
                                                        <DropdownItem
                                                            label="Edit"
                                                            icon={faEdit}
                                                            link={`${clientPath}/retirement-accounts/${finAcct.id}/edit`}
                                                        />
                                                        {(userType ===
                                                            "member" ||
                                                            userType ===
                                                                "client") && (
                                                            <DropdownItem
                                                                label="Delete"
                                                                icon={faTrash}
                                                                handleClick={() =>
                                                                    deleteRetirementAccount(
                                                                        finAcct
                                                                    )
                                                                }
                                                            />
                                                        )}
                                                    </>
                                                ) : (
                                                    <DropdownItem
                                                        label="Restore"
                                                        icon={faTrashRestore}
                                                        handleClick={() =>
                                                            restoreResource(
                                                                finAcct
                                                            )
                                                        }
                                                    />
                                                )}
                                            </Dropdown>
                                        </span>
                                    </td>
                                </tr>
                            ))}
                            <TableSummaryRow
                                columns={[
                                    "",
                                    "",
                                    retirementAccounts
                                        .map((p) => parseMoney(p.value))
                                        .reduce((a, c) => a + c),
                                    "",
                                    "",
                                    "",
                                    "",
                                ]}
                            />
                        </tbody>
                    </table>
                </>
            ) : (
                <div className="text-gray-400">
                    {withTrashed ? (
                        <>
                            There are no deleted retirement accounts for this
                            client.
                        </>
                    ) : (
                        <>
                            The were no retirement accounts found for this
                            client.{" "}
                            {canCreateWealthRecords && (
                                <Link
                                    to={`${clientPath}/retirement-accounts/add`}
                                    className="underline text-gray-600"
                                >
                                    Add one now.
                                </Link>
                            )}
                        </>
                    )}
                </div>
            )}
        </>
    );
}
