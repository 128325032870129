import React, { useEffect } from "react";
import Button from "../../../components/Button";
import useClient from "../../../hooks/useClient";

import { faPrint, faShoppingCart } from "@fortawesome/free-solid-svg-icons";
import useIceContext from "../hooks/useIceContext";
import InputStepper from "../../../components/InputStepper";
import Money from "../../../components/Money";
import useIceCard from "../hooks/useIceCard";
import Badge from "../../../components/Badge";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ICECheckoutModal from "./ICECheckoutModal";
import { formatDate } from "../../../util/TextUtil";
import Spinner from "../../../components/Spinner";

export default function ICECards() {
    const {
        isExporting,
        printCard,
        spouseCardQty,
        setSpouseCardQty,
        clientCardQty,
        setClientCardQty,
        cost,
        setIsCheckoutOpen,
        loadedCost,
        loadingCost,
    } = useIceCard();

    const { client } = useClient();
    const { isSpouse } = useIceContext();

    useEffect(() => {
        document.title = "ICE Cards | " + client.full_name;
    }, [client.full_name]);

    return (
        <div className="flex flex-col">
            <div className="flex flex-1 justify-between items-center">
                <div className="font-bold text-xl">Cards</div>
                <Button
                    disabled={
                        !loadedCost || spouseCardQty + clientCardQty === 0
                    }
                    width="w-auto"
                    onClick={() => setIsCheckoutOpen(true)}
                >
                    <div className="flex flex-1 justify-center items-center">
                        <div>
                            <FontAwesomeIcon
                                icon={faShoppingCart}
                                className="mr-2"
                            />
                        </div>
                        <span>Checkout</span>
                        <div className="ml-2">
                            <Badge color="white">
                                {spouseCardQty + clientCardQty}
                            </Badge>
                        </div>
                    </div>
                </Button>
            </div>
            <div className="my-4">
                <hr />
            </div>
            <div className="flex flex-1 justify-between items-center">
                <div>
                    Buy &amp; Ship Card -{" "}
                    {isSpouse ? client.spouse.full_name : client.full_name}{" "}
                    <Badge color="green">
                        {loadingCost ? (
                            <span>Loading</span>
                        ) : loadedCost ? (
                            <>
                                <Money value={cost} formatNegative={true} />{" "}
                                <span>/card</span>
                            </>
                        ) : (
                            <span>N/A</span>
                        )}
                    </Badge>
                </div>
                <InputStepper
                    value={isSpouse ? spouseCardQty : clientCardQty}
                    onChange={(newValue) => {
                        if (newValue < 0) return;
                        isSpouse
                            ? setSpouseCardQty(newValue)
                            : setClientCardQty(newValue);
                    }}
                />
            </div>
            <div className="my-4">
                <hr />
            </div>
            <div className="flex flex-1 justify-between items-center">
                <div>
                    Print Card -{" "}
                    {isSpouse ? client.spouse.full_name : client.full_name}{" "}
                    <Badge color="green">FREE</Badge>
                </div>
                <Button
                    text="Print ICE Card"
                    appearance="outline"
                    width="w-auto"
                    isLoading={isExporting}
                    icon={faPrint}
                    onClick={(e) => printCard(isSpouse, e)}
                />
            </div>
            <ICECheckoutModal />
            <OrderStatus />
        </div>
    );
}

function OrderStatus() {
    const { orderStatus, isLoadingOrderStatus } = useIceCard();

    return (
        <div>
            <div className="mt-8 font-bold text-xl">Order Status</div>

            <div className="mt-4 shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                <table className="min-w-full divide-y divide-gray-200">
                    <thead className="bg-gray-50">
                        <tr>
                            <th
                                scope="col"
                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                            >
                                Card For
                            </th>
                            <th
                                scope="col"
                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                            >
                                Quantity
                            </th>
                            <th
                                scope="col"
                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                            >
                                Order Date
                            </th>
                            <th
                                scope="col"
                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                            >
                                Shipped To
                            </th>
                            <th
                                scope="col"
                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                            >
                                Tracking Number
                            </th>
                        </tr>
                    </thead>
                    {!isLoadingOrderStatus && (
                        <tbody>
                            {orderStatus.length === 0 && (
                                <tr>
                                    <td
                                        className="px-6 py-4 whitespace-nowrap text-sm text-gray-500"
                                        colSpan="5"
                                    >
                                        No ICE Card Orders
                                    </td>
                                </tr>
                            )}
                            {orderStatus.map((status, statusIdx) => (
                                <tr
                                    key={status.id}
                                    className={
                                        statusIdx % 2 === 0
                                            ? "bg-white"
                                            : "bg-gray-50"
                                    }
                                >
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                        {status.ice_for}
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                        {status.quantity}
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                        {status.shipping_name} (
                                        {
                                            (status.shipping_city,
                                            status.shipping_state)
                                        }
                                        )
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                        {formatDate(status.created_at)}
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                                        {!status.shipping_tracking_number && (
                                            <Badge color="gray">
                                                Not Yet Shipped
                                            </Badge>
                                        )}
                                        {!!status.shipping_tracking_number && (
                                            <Badge color="green">
                                                {
                                                    status.shipping_tracking_number
                                                }
                                            </Badge>
                                        )}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    )}
                </table>
                {isLoadingOrderStatus && (
                    <div className="p-8 bg-white">
                        <Spinner />
                    </div>
                )}
            </div>
        </div>
    );
}
