import React from "react";
import { Switch, Route } from "react-router-dom";
import FinancialAccountIndex from "./FinancialAccountIndex";
import AddFinancialAccount from "./AddFinancialAccount";
import EditFinancialAccount from "./EditFinancialAccount";

export default function FinancialAccounts(parentProps) {
    return (
        <Switch>
            <Route
                exact
                path={[
                    "/clients/:clientId/financial-accounts",
                    "/clients/:clientId/financial-accounts/disabled",
                    "/client/financial-accounts",
                    "/client/financial-accounts/disabled",
                ]}
                render={(props) => (
                    <FinancialAccountIndex {...parentProps} {...props} />
                )}
            />
            <Route
                exact
                path={[
                    "/clients/:clientId/financial-accounts/add",
                    "/client/financial-accounts/add",
                ]}
                render={(props) => (
                    <AddFinancialAccount {...parentProps} {...props} />
                )}
            />
            <Route
                path={[
                    "/clients/:clientId/financial-accounts/:financialAccountId/edit",
                    "/client/financial-accounts/:financialAccountId/edit",
                ]}
                render={(props) => (
                    <EditFinancialAccount {...parentProps} {...props} />
                )}
            />
            <Route
                path={[
                    "/clients/:clientId/financial-accounts/:financialAccountId/view",
                    "/client/financial-accounts/:financialAccountId/view",
                ]}
                render={(props) => (
                    <EditFinancialAccount
                        readOnly={true}
                        {...parentProps}
                        {...props}
                    />
                )}
            />
        </Switch>
    );
}
