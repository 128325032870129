import usePost from "./usePost";
import { linkResource } from "services/ResourceService";

export default function useLinkResource(config = {}) {
    const { mutation, validationErrors } = usePost(
        (formData) => linkResource(formData),
        { showToastOnError: true, showToastOnSuccess: true, ...config }
    );
    const onSubmit = (event, formData) => {
        event.preventDefault();
        return mutation.mutate(formData);
    };

    return { onSubmit, ...mutation, validationErrors };
}
