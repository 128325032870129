import { fetchOtherInsurancePolices } from "services/InsurancePolicyService";
import useFetch from "./useFetch";

export function useOtherInsurancePolicies(params, config) {
    return useFetch(
        ["fetchOtherInsurancePolicies", params],
        fetchOtherInsurancePolices,
        config
    );
}
