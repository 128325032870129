import React, { useEffect, useState } from "react";
import ClientPageHeader, { Heading } from "../../components/ClientPageHeader";
import Button, { ButtonStyles } from "../../components/Button";
import PageContainer from "../../components/PageContainer";
import { Switch, Route } from "react-router-dom";
import PersonalPropertyMemoTable from "./_partials/PersonalPropertyMemoTable";
import Tabs from "../../components/Tabs";
import QuickAddProvider, {
    QuickAddButton,
} from "../../components/QuickAddButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons";
import { downloadFileAsLink } from "util/Downloader";
import useHasAnyPermissions from "hooks/useHasAnyPermissions";
import useHasPermission from "hooks/usePermissions";

export default function PersonalPropertyMemoIndex({
    client,
    clientPath,
    ...props
}) {
    let canViewMemorandum = useHasAnyPermissions([
        "firm_client_wealth_records_global_access",
        "client_wealth_records_global_access",
        "client_wealth_records_editing",
        "report_memorandum_of_personal_property",
    ]);

    if (!useHasPermission("report_memorandum_of_personal_property")) {
        canViewMemorandum = false;
    }

    const [isPrintEnabled, setIsPrintEnabled] = useState(false);
    const spouseSelected = props.match.params.spouse === "spouse";

    useEffect(() => {
        if (spouseSelected) {
            document.title = "Memorandum of Spouses Personal Property";
        } else {
            document.title = "Memorandum of Client's Personal Property";
        }
    }, [spouseSelected]);

    const handlePrint = (event) => {
        event.preventDefault();
        downloadFileAsLink(
            `clients/${client.id}/memorandum-of-personal-property?print=1&isSpouse=${spouseSelected}`
        );
    };

    return (
        <>
            <ClientPageHeader client={client}>
                <Heading backTo={`${clientPath}/forms`} backText="All Forms">
                    Memorandum of Personal Property
                </Heading>
                {canViewMemorandum && (
                    <div className="flex space-x-2">
                        <div className="">
                            <QuickAddProvider client={client}>
                                {(props) => (
                                    <QuickAddButton
                                        {...props}
                                        options={["PersonalProperty"]}
                                    >
                                        <span
                                            className={`${ButtonStyles.outline} p-3 space-x-1`}
                                        >
                                            <span>Quick Add</span>
                                            <FontAwesomeIcon
                                                icon={faCaretDown}
                                            />
                                        </span>
                                    </QuickAddButton>
                                )}
                            </QuickAddProvider>
                        </div>
                        <div className="flex-0">
                            {!spouseSelected ? (
                                <Button
                                    type="link"
                                    to={`${clientPath}/personal-property-memos/add`}
                                    text="Add Property to Memorandum"
                                />
                            ) : (
                                <Button
                                    type="link"
                                    to={`${clientPath}/personal-property-memos/add/spouse`}
                                    text="Add Property to Memorandum"
                                />
                            )}
                        </div>
                        <div className="flex-0">
                            <Button
                                type="button"
                                disabled={!isPrintEnabled}
                                onClick={handlePrint}
                                text="Download"
                                appearance="light"
                            />
                        </div>
                    </div>
                )}
            </ClientPageHeader>
            <PageContainer>
                {client.spouse && canViewMemorandum && (
                    <div className="print:hidden">
                        <Tabs
                            {...props}
                            items={[
                                {
                                    label: `${client.full_name}'s Memorandum`,
                                    to: clientPath + "/personal-property-memos",
                                    active: !spouseSelected,
                                },
                                {
                                    label: `${client.spouse.full_name}'s Memorandum`,
                                    to:
                                        clientPath +
                                        "/personal-property-memos/spouse",
                                    active: spouseSelected,
                                },
                            ]}
                        />
                    </div>
                )}
                <div className="flex space-x-8">
                    <div className="flex-1">
                        <Switch>
                            <Route
                                path={clientPath + "/personal-property-memos"}
                                render={(newProps) => (
                                    <PersonalPropertyMemoTable
                                        setIsPrintEnabled={setIsPrintEnabled}
                                        client={client}
                                        clientPath={clientPath}
                                        spouse={spouseSelected || false}
                                        {...props}
                                        {...newProps}
                                    />
                                )}
                            />
                        </Switch>
                    </div>
                </div>
            </PageContainer>
        </>
    );
}
