import React, { useState } from 'react';
import { useEffect } from 'react';
import SelectInput from '../../../components/SelectInput';
import Api from '../../../services/Api';

export default function RealEstateLeaseSelect({client, needsUpdated, ...props}) {
    const [loading, setLoading] = useState(true);
    const [leases, setLeases] = useState([]);
    const realEstateId = props.realEstateId;


    useEffect(() => {
        const fetchLeases = async () => {
            try {
                let response = await Api.get("clients/" + client.id + "/leases", {
                    "filter[type]":"Real Estate"
                });
                let options = [];
                response.data.data.forEach((lease) => {
                    if(lease.lease_for_id === null || lease.lease_for_id === "" || lease.lease_for_id === realEstateId) {
                        options.push({
                            value: lease.id,
                            label: lease.description
                        });
                    }
                });
                options.splice(0, 0, { value: "", label: "None" });
                setLeases(options);
                setLoading(false);
            } catch (error) {
                setLoading(false);
            }
        };
        fetchLeases();
    },[client, needsUpdated, realEstateId]);

    return (
        <SelectInput options={leases} loading={loading} {...props} />
    );
}
