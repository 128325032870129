import { isBool } from "index";
import useAuth from "./useAuth";

const checkForEnabledPermission = (permissionKeys, permissions) => {
    const enabledPermissions = permissionKeys.filter((permissionKey) => {
        return permissions[permissionKey]
            ? isBool(permissions[permissionKey].enabled)
            : false;
    });

    return enabledPermissions.length > 0;
};

export default function useHasAnyPermissions(permissionKeys) {
    const { user } = useAuth();

    if (Array.isArray(permissionKeys) === false) {
        throw new Error("Permission keys must be an array");
    }

    return checkForEnabledPermission(permissionKeys, user.permissions);
}

export function useUserCan(permission) {
    const { user } = useAuth();
    const options = {
        createWealthRecord: [
            "firm_client_wealth_records_global_access",
            "client_wealth_records_global_access",
            "client_wealth_records_editing",
        ],
        deleteWealthRecord: [
            "firm_client_wealth_records_global_access",
            "client_wealth_records_global_access",
            "client_wealth_records_editing",
        ],
    };

    if (Array.isArray(options[permission])) {
        return checkForEnabledPermission(options[permission], user.permissions);
    }
}
