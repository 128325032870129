import React from "react";
import { Route, Switch } from "react-router-dom";
import InsurancePolicyIndex from "./InsurancePolicyIndex";
import AddInsurancePolicy from "./AddInsurancePolicy";
import EditInsurancePolicy from "./EditInsurancePolicy";

export default function InsurancePolicies(parentProps) {
    return (
        <Switch>
            <Route
                exact
                path={[
                    "/clients/:clientId/insurance-policies",
                    "/client/insurance-policies",
                ]}
                render={(props) => (
                    <InsurancePolicyIndex {...parentProps} {...props} />
                )}
            />
            <Route
                exact
                path={[
                    "/clients/:clientId/insurance-policies/add",
                    "/client/insurance-policies/add",
                ]}
                render={(props) => (
                    <AddInsurancePolicy {...parentProps} {...props} />
                )}
            />
            <Route
                path={[
                    "/clients/:clientId/insurance-policies/:insurancePolicyId/edit",
                    "/client/insurance-policies/:insurancePolicyId/edit",
                ]}
                render={(props) => (
                    <EditInsurancePolicy {...parentProps} {...props} />
                )}
            />
            <Route
                path={[
                    "/clients/:clientId/insurance-policies/:insurancePolicyId/view",
                    "/client/insurance-policies/:insurancePolicyId/view",
                ]}
                render={(props) => (
                    <EditInsurancePolicy
                        readOnly={true}
                        {...parentProps}
                        {...props}
                    />
                )}
            />
        </Switch>
    );
}
