import React, { useState } from "react";
import SelectInput from "../../../components/SelectInput";
import QuestionWrapper from "./QuestionWrapper";

export default function MultipleChoiceQuestion({
    question,
    onUpdate,
    disabled = false,
}) {
    const [response, setResponse] = useState(
        question.answer ? question.answer.response : ""
    );

    return (
        <>
            <QuestionWrapper
                question={question}
                onUpdate={onUpdate}
                response={response}
            >
                <SelectInput
                    options={question.options}
                    value={response}
                    disabled={disabled}
                    onChange={(e) => {
                        setResponse(e.target.value);
                        onUpdate({ response: e.target.value });
                    }}
                />
            </QuestionWrapper>
        </>
    );
}
