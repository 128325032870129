import React from "react";
import { snakeToTitle } from "util/TextUtil";
import {
    allergies,
    conditions,
} from "views/client_forms/LocationListForm/_medical_history";
import useIceGuest from "./useIceGuest";

const MedicalRow = ({ title, info }) => {
    info = info + "";
    console.log(title, info);
    return (
        <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500 flex flex-col">
                {title == "custom"
                    ? "Other Medical History"
                    : snakeToTitle(title)}
                <span className="text-xs text-gray-400">
                    {conditions.indexOf(title) > -1 && "Medical Condition"}
                    {allergies.indexOf(title) > -1 && "Allergy"}
                </span>
            </dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {info === "1" && "Yes"}
                {info === "0" && "No"}
                {info !== "0" && info !== "1" && info}
            </dd>
        </div>
    );
};

const MedicalObjects = ({ title, info }) => {
    return (
        <div>
            <hr />
            <div className="my-4">
                <div className="md:ml-6 font-semibold">{title}</div>
                {info.map((container, i) => (
                    <div key={i}>
                        {Object.keys(container).map((key) => (
                            <div key={key}>
                                {!!container[key] && (
                                    <MedicalRow
                                        title={key}
                                        info={container[key]}
                                    />
                                )}
                            </div>
                        ))}
                    </div>
                ))}
            </div>
            <hr />
        </div>
    );
};

export default function ICEMedicalHistory() {
    const { info } = useIceGuest();

    const { medical } = info;

    return (
        <div className="bg-white shadow overflow-hidden sm:rounded-lg">
            <div className="px-4 py-5 sm:px-6">
                <h3 className="text-lg leading-6 font-medium text-gray-900">
                    Medical Info
                </h3>
                <p className="mt-1 max-w-2xl text-sm text-gray-500">
                    Allergies, medications, etc.
                </p>
            </div>
            <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
                {Object.keys(medical).length === 0 && (
                    <div className="py-8 flex items-center justify-center">
                        No Medical Information Available
                    </div>
                )}
                <dl className="sm:divide-y sm:divide-gray-200">
                    {Object.keys(medical).map((key) => {
                        const info = medical[key];
                        if (
                            typeof info == "object" &&
                            Array.isArray(medical[key])
                        ) {
                            if (info.length === 0) {
                                return <div className="hidden" key={key}></div>;
                            }
                            return (
                                <MedicalObjects
                                    title={snakeToTitle(key)}
                                    info={info}
                                />
                            );
                        }
                        if (
                            key === "id" ||
                            key === "resourceUuid" ||
                            typeof info == "object"
                        ) {
                            return <div className="hidden" key={key}></div>;
                        }
                        return <MedicalRow key={key} title={key} info={info} />;
                    })}
                </dl>
            </div>
        </div>
    );
}
