import React from "react";
import { Route, Switch } from "react-router-dom";
import QuestionnaireIndex from "./QuestionnaireIndex";
import AddQuestionnaire from "./AddQuestionnaire";
import EditQuestionnaire from "./EditQuestionnaire";

export default function Questionnaires(parentProps) {
    return (
        <Switch>
            <Route
                exact
                path={[
                    "/members/:memberId/questionnaires",
                    "/members/questionnaires",
                ]}
                render={(props) => (
                    <QuestionnaireIndex {...parentProps} {...props} />
                )}
            />
            <Route
                exact
                path={[
                    "/members/:memberId/questionnaires/add",
                    "/members/questionnaires/add",
                ]}
                render={(props) => (
                    <AddQuestionnaire {...parentProps} {...props} />
                )}
            />
            <Route
                path={[
                    "/members/:memberId/questionnaires/:questionnaireId/edit",
                    "/members/questionnaires/:questionnaireId/edit",
                ]}
                render={(props) => (
                    <EditQuestionnaire {...parentProps} {...props} />
                )}
            />
            <Route
                path={[
                    "/members/:memberId/questionnaires/:questionnaireId/view",
                    "/members/questionnaires/:questionnaireId/view",
                ]}
                render={(props) => (
                    <EditQuestionnaire
                        readOnly={true}
                        {...parentProps}
                        {...props}
                    />
                )}
            />
        </Switch>
    );
}
