import React from "react";
import SelectInput from "../../../components/SelectInput";
import TextInput from "../../../components/TextInput";
import ResourceSelect from "../../../components/ResourceSelect";

export default function LeaseTypeSelect({
    client,
    resourceType = "",
    onChange,
    ...props
}) {
    const options = [
        { value: "Oil/Gas Lease", label: "Oil/Gas Lease" },
        { value: "Mineral Right Lease", label: "Mineral Right Lease" },
        { value: "Solar Panel Farm Lease", label: "Solar Panel Farm Lease" },
        { value: "Cellphone Tower Lease", label: "Cellphone Tower Lease" },
        { value: "Wind Farm Lease", label: "Wind Farm Lease" },
        { value: "Farm Lease", label: "Farm Lease" },
        { value: "Real Estate", label: "Real Estate" },
        { value: "Equipment", label: "Equipment" },
        { value: "Automobile", label: "Automobile" },
        { value: "Other", label: "Other" },
    ];

    function updateType(e) {
        const selectedValue = e.target.value;
        let optionText = options.filter((opt) => {
            return opt.value === selectedValue;
        });
        if (optionText.value === "other") {
            optionText = "";
        }

        onChange({
            target: { name: "lease_type_id", value: selectedValue },
        });
        onChange({
            target: { name: "lease_type_name", value: optionText.label },
        });
        onChange({
            target: { name: "lease_for_id", value: null },
        });
    }

    function updateLeaseForId(event) {
        let value = event.target.value;
        const lease_for_parts = value.split(":");
        if (lease_for_parts[1]) {
            onChange({
                target: { name: "lease_for_id", value: lease_for_parts[1] },
            });
        }
    }

    return (
        <div className="flex space-x-3">
            <div className="flex-1">
                <SelectInput
                    name="lease_type_id"
                    options={options}
                    loading={false}
                    onChange={updateType}
                    {...props}
                />
            </div>
            {props.value === "Other" && (
                <div className="flex-1">
                    <TextInput
                        id="lease_type_name"
                        label="Type Name"
                        name="lease_type_name"
                        value={props.lease_type_name}
                        onChange={onChange}
                        disabled={props.readOnly}
                        readOnly={props.readOnly}
                    />
                </div>
            )}
            {!!resourceType && (
                <div className="flex-1">
                    <ResourceSelect
                        client={client}
                        resourceType={resourceType}
                        id="lease_for_id"
                        label="Lease For"
                        name="lease_for_id"
                        value={
                            props.lease_for_id
                                ? `${resourceType}:${props.lease_for_id}`
                                : ""
                        }
                        onChange={updateLeaseForId}
                        disabled={props.readOnly}
                    />
                </div>
            )}
        </div>
    );
}
