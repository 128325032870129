import React from "react";
import TextInput from "../../../components/TextInput";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import Fieldset from "../../../components/Fieldset";

export default function Schools({ schools, setSchools }) {
    const school = {
        name: "",
        contact: "",
        address: "",
        phone: "",
        email: "",
        website: "",
        child: "",
    };

    function appendSchool() {
        if (schools.length < 10) {
            setSchools((schools) => [...schools, school]);
        }
    }

    function removeSchool(idx) {
        setSchools(schools.filter((school, idxs) => idxs !== idx));
    }

    const updateSchool = (event, idx) => {
        setSchools(
            schools.map((school, sidx) => {
                return idx !== sidx
                    ? school
                    : { ...school, [event.target.name]: event.target.value };
            })
        );
    };

    return (
        <Fieldset
            legend="Children’s Schools and After-School Activities"
            description="Schools, Sports, Music, Etc."
        >
            <div className="mb-10 border-b border-gray-100 pb-6">
                {schools.map((school, idx) => (
                    <div className="mb-10 border-b border-gray-100" key={idx}>
                        <div className="flex space-x-3 mb-6">
                            <div className="flex-1">
                                <TextInput
                                    label="School/Activities"
                                    name="name"
                                    value={schools[idx]["name"]}
                                    onChange={(e) => updateSchool(e, idx)}
                                    autoFocus={schools.length === idx + 1}
                                />
                            </div>
                            <div className="flex-1">
                                <TextInput
                                    label="Contact"
                                    name="contact"
                                    value={schools[idx]["contact"]}
                                    onChange={(e) => updateSchool(e, idx)}
                                />
                            </div>
                        </div>
                        <div className="mb-6">
                            <TextInput
                                label="Child's Name"
                                name="child"
                                value={schools[idx]["child"]}
                                onChange={(e) => updateSchool(e, idx)}
                            />
                        </div>
                        <div className="mb-6">
                            <TextInput
                                label="Address"
                                name="address"
                                value={schools[idx]["address"]}
                                onChange={(e) => updateSchool(e, idx)}
                            />
                        </div>
                        <div className="flex space-x-3 mb-6">
                            <div className="flex-1">
                                <TextInput
                                    type="tel"
                                    label="Phone"
                                    name="phone"
                                    value={schools[idx]["phone"]}
                                    onChange={(e) => updateSchool(e, idx)}
                                />
                            </div>
                            <div className="flex-1">
                                <TextInput
                                    type="email"
                                    label="Email"
                                    name="email"
                                    value={schools[idx]["email"]}
                                    onChange={(e) => updateSchool(e, idx)}
                                />
                            </div>
                        </div>
                        <div className="mb-6">
                            <TextInput
                                label="Website"
                                name="website"
                                value={schools[idx]["website"]}
                                onChange={(e) => updateSchool(e, idx)}
                            />
                        </div>
                        <div className="flex justify-end mb-3">
                            <button
                                type="button"
                                onClick={() => removeSchool(idx)}
                                className="text-gray-500 text-sm hover:text-red-500"
                            >
                                <FontAwesomeIcon
                                    icon={faTimesCircle}
                                    className="mr-1"
                                />{" "}
                                Remove School
                            </button>
                        </div>
                    </div>
                ))}
                <div className="flex">
                    <button
                        type="button"
                        onClick={(e) => appendSchool(e)}
                        className="text-gray-500 text-sm hover:text-brand"
                    >
                        <FontAwesomeIcon icon={faPlusCircle} className="mr-1" />{" "}
                        Add School
                    </button>
                </div>
            </div>
        </Fieldset>
    );
}
