import React from "react";
import { Link } from "react-router-dom";

export default function CollaboratingAdvisorNav({ active, advisor }) {
    const inactiveClass =
        "group flex items-center px-3 py-2 text-sm leading-5 font-medium text-gray-600 rounded-md hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:bg-gray-100 transition ease-in-out duration-150";
    const activeClass =
        "group flex items-center px-3 py-2 text-sm leading-5 font-medium text-gray-900 rounded-md bg-gray-200 hover:text-gray-900 hover:bg-gray-200 focus:outline-none focus:bg-gray-200 transition ease-in-out duration-150";

    return (
        <nav className="w-56">
            <Link
                to={`/collaborating-advisors/${advisor.id}/edit`}
                className={
                    active === `/collaborating-advisors/${advisor.id}/edit`
                        ? activeClass
                        : inactiveClass
                }
            >
                <span className="truncate">Edit Profile</span>
            </Link>
            <Link
                to={`/collaborating-advisors/${advisor.id}/client-list`}
                className={
                    active ===
                    `/collaborating-advisors/${advisor.id}/client-list`
                        ? activeClass
                        : inactiveClass
                }
            >
                <span className="truncate">Client List</span>
            </Link>
        </nav>
    );
}
