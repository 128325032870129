import { useEffect, useState } from "react";
import { useToasts } from "react-toast-notifications";

const WARNING_THRESHOLD = 60 * 2;

export default function useSessionExpiration({
    hasCheckedLogin,
    isLoggedIn,
    logoutUser,
    extendSession,
    key = "session-expiration",
}) {
    const [shownExpirationWarning, setShownExpirationWarning] = useState("");

    const { addToast, removeToast } = useToasts();

    const checkSession = async () => {
        try {
            if (!isLoggedIn) {
                return;
            }
            if (!hasCheckedLogin) {
                return;
            }

            const sessionExpiration = parseInt(
                window.localStorage.getItem(key)
            );

            if (!sessionExpiration) {
                return;
            }

            const now = Date.now() / 1000;

            const expired = now > sessionExpiration;
            const timeLeft = sessionExpiration - now;

            if (expired) {
                removeToast(shownExpirationWarning);
                setShownExpirationWarning("");
                await logoutUser();
                addToast("Session Expired", {
                    type: "error",
                    autoDismiss: false,
                });
            } else if (
                timeLeft < WARNING_THRESHOLD &&
                !shownExpirationWarning
            ) {
                let toastId = "";
                addToast(
                    "Your Session is expiring soon",
                    {
                        title: "Session Expiring",
                        type: "warning",
                        autoDismiss: false,
                        actionTitle: "Extend Session",
                        action: async () => {
                            try {
                                removeToast(toastId);
                                await extendSession();
                                setShownExpirationWarning("");
                            } catch (e) {
                                addToast("Session Extension Failed", {
                                    type: "error",
                                });
                            }
                        },
                    },
                    (id) => {
                        toastId = id;
                        setShownExpirationWarning(id);
                    }
                );
            } else {
                // console.log(timeLeft);
            }
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        if (!hasCheckedLogin) {
            return;
        }
        checkSession();
        const timer = setInterval(() => {
            checkSession();
        }, 2000);
        // clearing interval
        return () => clearInterval(timer);
    }, [hasCheckedLogin, shownExpirationWarning]);

    return { shownExpirationWarning };
}
