// https://goshakkk.name/array-form-inputs/
import React, { useState, useEffect, useReducer, useContext } from "react";
import TextArea from "../../../components/TextArea";
import Button from "../../../components/Button";
import { AuthContext } from "../../../contexts/AuthContext";
import Checkbox from "../../../components/Checkbox";
import SelectInput from "../../../components/SelectInput";
import Note from "./Note";
import SearchInput from "components/SearchInput";
import useDebounce from "hooks/useDebounce";
import useClientNotesFetch from "hooks/useClientNotesFetch";
import Spinner from "components/Spinner";
import { useClientNotes } from "./useClientNotes";
import usePostMortemState from "hooks/usePostMortemState";
import { PaginationLinks } from "./PaginationLinks";

export default function ClientNotes({
    client,
    resourceType = null,
    resourceId = null,
    autoFocus = true,
    onlyEstateAdmin = false,
}) {
    const auth = useContext(AuthContext);

    const { person } = usePostMortemState();

    const [sortDirection, setSortDirection] = useState("desc");
    const [authors, setAuthors] = useState([]);
    const [userFilter, setUserFilter] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [search, setSearch] = useState("");
    const debouncedSearch = useDebounce(search, 250);
    const [input, setInput] = useReducer(
        (state, newState) => ({ ...state, ...newState }),
        {
            content: "",
            for_office_use_only: false,
            is_post_mortem: !!onlyEstateAdmin,
            ...(onlyEstateAdmin
                ? {
                      person,
                  }
                : {}),
            resourceType,
            resourceId,
        }
    );

    const { data, isSuccess, isLoading, refetch } = useClientNotesFetch(
        {
            clientId: client.id,
            page: currentPage,
            resourceType: resourceType,
            resourceId: resourceId,
            sort: sortDirection === "desc" ? "-created_at" : "created_at",
            "filter[user_id]": userFilter,
            "filter[search]": debouncedSearch,
            ...(onlyEstateAdmin
                ? {
                      person,
                      is_post_mortem: true,
                  }
                : {}),
        }
    );

    const { createNote, processing, updateNote, deleteNote } = useClientNotes({
        input,
        setInput,
        refetch,
        currentPage,
        setCurrentPage,
        onlyEstateAdmin,
    });

    const notes = data ? data.data : [];
    const filters = data ? data.filters : {};
    const pagination = data ? data.meta : {};

    useEffect(() => {
        if (!isSuccess) {
            return;
        }
        let authorSelect = filters.authors.map((author) => {
            return {
                value: author.id,
                label: author.full_name,
            };
        });
        setAuthors(authorSelect);
    }, [filters]);

    function updateValue(event) {
        let value = event.target.value;
        if (event.target.type && event.target.type === "checkbox") {
            value = event.target.checked;
        }
        setInput({ [event.target.name]: value });
    }

    return (
        <>
            <div className="py-6 mb-6 border-b border-gray-200">
                <TextArea
                    rows="2"
                    onChange={updateValue}
                    placeholder="Add your note here"
                    name="content"
                    value={input.content}
                    autoFocus={autoFocus}
                />
                {input.content.length > 0 && (
                    <div>
                        <div className="mt-3 w-48">
                            <Button
                                text="Save Note"
                                type="button"
                                onClick={(e) => createNote(e)}
                                disabled={!input.content.length}
                                isLoading={processing}
                            />
                        </div>
                        {auth.user.type === "member" && (
                            <div className="mt-2 space-x-4">
                                <Checkbox
                                    id="for_office_use_only"
                                    name="for_office_use_only"
                                    checked={input.for_office_use_only}
                                    onChange={updateValue}
                                    label="For office use only"
                                />
                            </div>
                        )}
                    </div>
                )}
            </div>

            <div className="flex space-x-4">
                <div className="w-32 flex-shrink-0">
                    <SelectInput
                        label="Sort By"
                        options={[
                            { value: "desc", label: "Newest" },
                            { value: "asc", label: "Oldest" },
                        ]}
                        value={sortDirection}
                        name="created_at"
                        onChange={() => {
                            setSortDirection(
                                sortDirection === "asc" ? "desc" : "asc"
                            );
                        }}
                    />
                </div>
                {authors.length > 1 && (
                    <div className="w-48 flex-shrink-0">
                        <SelectInput
                            label="Author"
                            options={authors}
                            value={userFilter}
                            name="user_id"
                            onChange={(event) => {
                                setUserFilter(event.target.value);
                            }}
                            defaultOption="All"
                            defaultOptionValue=""
                        />
                    </div>
                )}
                <div className="w-full">
                    <SearchInput
                        label="Search"
                        searchValue={search}
                        onSearchChange={(event) => {
                            setSearch(event.target.value);
                        }}
                    />
                </div>
                <PaginationLinks
                    pagination={pagination}
                    setCurrentPage={setCurrentPage}
                    isSuccess={isSuccess}
                />
            </div>

            {isLoading && (
                <div className="h-full w-full flex items-center justify-center">
                    <Spinner message="Loading Notes" />
                </div>
            )}

            {notes.length > 0 && (
                <>
                    <div className="mt-10 space-y-5">
                        {notes.map((note) => (
                            <Note
                                key={note.id}
                                client={client}
                                note={note}
                                auth={auth}
                                showRelations={resourceId ? false : true}
                                refetch={refetch}
                                deleteNote={deleteNote}
                                updateNote={updateNote}
                                processing={processing}
                            />
                        ))}
                    </div>
                </>
            )}
        </>
    );
}
