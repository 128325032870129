import React, { useState } from "react";
import { Radio } from "../../../components/Checkbox";
import QuestionWrapper from "./QuestionWrapper";

export default function BooleanQuestion({
    question,
    onUpdate,
    disabled = false,
}) {
    const [response, setResponse] = useState(
        question.answer ? question.answer.response : ""
    );

    return (
        <>
            <QuestionWrapper
                question={question}
                onUpdate={onUpdate}
                response={response}
                disabled={disabled}
            >
                <div className="flex space-x-3">
                    <Radio
                        id={`question_yes_${question.id}`}
                        name={`question_yes_${question.id}`}
                        label="Yes"
                        value="1"
                        checked={parseInt(response) === 1}
                        disabled={disabled}
                        onChange={(e) => {
                            setResponse(e.target.value);
                            onUpdate({ response: parseInt(e.target.value) });
                        }}
                    />
                    <Radio
                        id={`question_no_${question.id}`}
                        name={`question_no_${question.id}`}
                        label="No"
                        value="0"
                        checked={parseInt(response) === 0}
                        disabled={disabled}
                        onChange={(e) => {
                            setResponse(e.target.value);
                            onUpdate({ response: parseInt(e.target.value) });
                        }}
                    />
                </div>
            </QuestionWrapper>
        </>
    );
}
