import React from "react";
import ClientPageHeader from "../../../components/ClientPageHeader";
import PageContainer from "../../../components/PageContainer";
import useClient from "../../../hooks/useClient";
import VaultWrapper from "./VaultWrapper";

export default function Vault({ ...props }) {
    const { client } = useClient();
    return (
        <div>
            <ClientPageHeader text="Vault" client={client}></ClientPageHeader>
            <PageContainer>
                <VaultWrapper {...props} />
            </PageContainer>
        </div>
    );
}
