import React from "react";
import useDeleteTransfer from "hooks/useDeleteTransfer";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { faShareAlt } from "@fortawesome/free-solid-svg-icons";
import Button from "components/Button";
import { useOwnershipContext } from "contexts/OwnershipContext";

export function TransferButtonToolbar({
    showProposed,
    onVerifyTransfer = () => {},
    transfer,
    disabled = false,
}) {
    if (!showProposed || !transfer.id) {
        return <></>;
    }

    const { refreshData } = useOwnershipContext();

    const {
        onSubmit: onDelete,
        isLoading: isDeleting,
        isSuccess: didDelete,
    } = useDeleteTransfer({ id: transfer.id, clientId: transfer.client_id });

    React.useEffect(() => {
        if (didDelete) {
            refreshData();
        }
    }, [didDelete]);

    const deleteTransfer = (event) => {
        if (
            !window.confirm(
                "This will delete the proposed transfer. Would you like to continue?"
            )
        ) {
            return false;
        }
        onDelete(event, {
            id: transfer.id,
        });
    };

    return (
        <div className="flex space-x-2 justify-center items-center">
            <div className="w-12">
                <Button
                    onClick={deleteTransfer}
                    icon={faTimes}
                    height="h-8"
                    appearance="danger"
                    text=""
                    title={"Delete Transfer"}
                    isLoading={isDeleting}
                    disabled={disabled}
                />
            </div>
            <div className="w-12">
                <Button
                    onClick={onVerifyTransfer}
                    icon={faShareAlt}
                    height="h-8"
                    appearance="default"
                    text=""
                    title={"View Transfer & Verify"}
                    disabled={disabled}
                />
            </div>
        </div>
    );
}
