import useHasAnyPermissions from "hooks/useHasAnyPermissions";
import { isBool } from "index";
import React from "react";
import { Link } from "react-router-dom";

export default function AdvisorNav({ clientPath, active, advisor }) {
    const canManageCollaborators = useHasAnyPermissions([
        "client_related_accounts_administration",
        "firm_client_related_accounts_administration",
    ]);

    const inactiveClass =
        "group flex items-center px-3 py-2 text-sm leading-5 font-medium text-gray-600 rounded-md hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:bg-gray-100 transition ease-in-out duration-150";
    const activeClass =
        "group flex items-center px-3 py-2 text-sm leading-5 font-medium text-gray-900 rounded-md bg-gray-200 hover:text-gray-900 hover:bg-gray-200 focus:outline-none focus:bg-gray-200 transition ease-in-out duration-150";

    return (
        <nav className="w-56">
            <Link
                to={`${clientPath}/advisors/${advisor.id}/edit`}
                className={
                    active === `${clientPath}/advisors/${advisor.id}/edit` ||
                    active === `${clientPath}/advisors/${advisor.id}/view`
                        ? activeClass
                        : inactiveClass
                }
            >
                <span className="truncate">Edit Profile</span>
            </Link>
            <Link
                to={`${clientPath}/advisors/${advisor.id}/notes`}
                className={
                    active === `${clientPath}/advisors/${advisor.id}/notes`
                        ? activeClass
                        : inactiveClass
                }
            >
                <span className="truncate">Notes</span>
            </Link>
            <Link
                to={`${clientPath}/advisors/${advisor.id}/related-files`}
                className={
                    active ===
                    `${clientPath}/advisors/${advisor.id}/related-files`
                        ? activeClass
                        : inactiveClass
                }
            >
                <span className="truncate">Related Files</span>
            </Link>
            {canManageCollaborators && isBool(advisor.can_collaborate) && (
                <Link
                    to={`${clientPath}/advisors/${advisor.id}/edit-permissions`}
                    className={
                        active ===
                        `${clientPath}/advisors/${advisor.id}/edit-permissions`
                            ? activeClass
                            : inactiveClass
                    }
                >
                    <span className="truncate">Permissions</span>
                </Link>
            )}
        </nav>
    );
}
