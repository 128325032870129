import React from "react";
import SelectInput from "../SelectInput";
import InputErrors from "../InputErrors";
import PercentInput from "../PercentInput";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ownershipTypes } from "../Ownership/OwnershipService";
import { OwnershipOptions } from "components/Ownership";
import { useOwnershipContext } from "contexts/OwnershipContext";

export default function OwnershipList({
    asset,
    client,
    errors,
    ownerships,
    setOwnerships,
    removeOwnership,
    autoFocus = false,
    ...props
}) {
    function updateType(idx, value) {
        let jointTypes = ["TBE", "JT", "CP"];
        let jointTypesOther = ["JTO", "TCO", "ADD_OWNER", "CJTO", "SJTO"];

        setOwnerships(
            ownerships.map((ownership, idxs) => {
                if (idxs === idx) {
                    if (jointTypes.includes(value)) {
                        ownership.owner_id = client.id;
                        ownership.owner_type = "Client";
                        ownership.type = value;
                        return ownership;
                    }

                    // Set default value.
                    if (value === "OWNER") {
                        ownership.owner_id = client.id;
                        ownership.owner_type = "Client";
                        ownership.type = value;
                        return ownership;
                    }

                    if (jointTypesOther.includes(value)) {
                        ownership.owner_id = 0;
                        ownership.owner_type = value;
                        ownership.type = value;
                        ownership.owner_id = null;
                        return ownership;
                    }

                    ownership.owner_id = 0;
                    ownership.owner_type = value;
                    ownership.type = value;
                }
                return ownership;
            })
        );
    }

    function updateOwnershipOption(idx, value) {
        let parts = value.split(":");
        let ownerType = parts[0];
        let ownerId = parts[1];
        setOwnerships(
            ownerships.map((ownership, idxs) => {
                if (idxs === idx) {
                    ownership.owner_type = ownerType;
                    if (ownerType === "Spouse") {
                        ownership.owner_id = client.spouse.id;
                    }
                    ownership.owner_id = ownerId;
                }
                return ownership;
            })
        );
    }

    function updatePercentageOwned(idx, value) {
        setOwnerships(
            ownerships.map((ownership, idxs) => {
                if (idxs === idx) {
                    ownership.percentage_owned = value;
                }
                return ownership;
            })
        );
    }

    function updateDescription(idx, value) {
        setOwnerships(
            ownerships.map((ownership, idxs) => {
                if (idxs === idx) {
                    ownership.owner_description = value;
                }
                return ownership;
            })
        );
    }

    return (
        <>
            {ownerships.length === 0 && (
                <p>There are currently no ownerships.</p>
            )}
            <div className="space-y-4">
                {errors["percentage_owned"] && (
                    <div className="text-right">
                        <InputErrors errors={errors["percentage_owned"]} />
                    </div>
                )}
                {ownerships.map((ownership, idx) => {
                    return (
                        <OwnershipLineItem
                            key={`ownership_${ownership.id}`}
                            idx={idx}
                            errors={errors}
                            asset={asset}
                            client={client}
                            ownership={ownership}
                            updateType={updateType}
                            disabled={props.disabled}
                            required={props.required}
                            autoFocus={autoFocus}
                            updateOwnershipOption={updateOwnershipOption}
                            updateDescription={updateDescription}
                            updatePercentageOwned={updatePercentageOwned}
                            removeOwnership={removeOwnership}
                            showRemoveButton={ownerships.length > 1}
                        />
                    );
                })}
            </div>
        </>
    );
}

export function OwnershipLineItem({
    idx,
    errors,
    ownership,
    disabled = false,
    required = false,
    autoFocus = false,
    asset,
    client,
    updateType,
    updateOwnershipOption,
    updateDescription,
    updatePercentageOwned,
    removeOwnership,
    showRemoveButton = true,
}) {
    const ownershipContext = useOwnershipContext();
    const assetOptions = ownershipContext.getAssetOptionsForResourceType(
        asset.type
    );

    return (
        <div key={ownership.id}>
            <InputErrors
                errors={errors["ownerships." + idx + ".percentage_owned"]}
            />
            <div className={`flex space-x-3 items-stretch`}>
                <div className="flex-shrink-0 w-56">
                    <div className="flex-1 relative">
                        <SelectInput
                            label="Ownership Type"
                            name="type"
                            options={ownershipTypes(asset.type)}
                            loading={false}
                            value={ownership.type || ""}
                            disabled={disabled}
                            required={required}
                            autoFocus={autoFocus}
                            onChange={(e) => updateType(idx, e.target.value)}
                            error={
                                errors &&
                                errors["ownerships." + idx + ".owner_type"]
                            }
                        />
                    </div>
                </div>
                {ownership.type && (
                    <div className="flex-1">
                        <OwnershipOptions
                            index={idx}
                            label="Owner"
                            assetOptions={assetOptions}
                            client={client}
                            type={ownership.type}
                            ownerType={ownership.owner_type}
                            ownerId={ownership.owner_id}
                            description={ownership.owner_description}
                            disabled={disabled}
                            errors={errors}
                            updateOption={(e) =>
                                updateOwnershipOption(idx, e.target.value)
                            }
                            updateDescription={(e) =>
                                updateDescription(idx, e.target.value)
                            }
                        />
                    </div>
                )}
                <div className="flex space-x-2 flex-0 w-32 mx-2">
                    {ownership.type && (
                        <div className="flex-1 relative">
                            <PercentInput
                                label="Percentage"
                                name="percentage_owned"
                                id={`percentage_owned_${ownership.id}`}
                                value={ownership.percentage_owned}
                                disabled={disabled}
                                required={required}
                                placeholder="0 - 100"
                                onChange={(e) =>
                                    updatePercentageOwned(idx, e.target.value)
                                }
                            />
                        </div>
                    )}
                    {!disabled && showRemoveButton && (
                        <div className="flex-0 flex items-end justify-center">
                            <button
                                tabIndex={-1}
                                type="button"
                                className="text-gray-500 text-sm hover:text-red-500 mb-4"
                                title="Remove Ownership"
                                onClick={() => removeOwnership(idx)}
                            >
                                <FontAwesomeIcon icon={faTimesCircle} />
                            </button>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}
