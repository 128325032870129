import useFetch from "./useFetch";
import { fetchClientPermissions } from "services/PermissionService";

export default function useClientPermissions(params, config) {
    return useFetch(
        ["fetchClientPermissions", params],
        fetchClientPermissions,
        config
    );
}
