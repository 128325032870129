import React, { useState } from "react";
import QuestionWrapper from "./QuestionWrapper";

export default function RangeQuestion({
    question,
    onUpdate,
    disabled = false,
}) {
    const [response, setResponse] = useState(
        question.answer
            ? parseInt(question.answer.response)
            : question.range_start
    );

    return (
        <>
            <QuestionWrapper
                question={question}
                onUpdate={onUpdate}
                response={response}
            >
                <div className="flex">
                    <div className="flex space-x-3 items-center bg-white border border-gray-200 px-3 w-full leading-normal h-12 rounded rounded-r-none">
                        <input
                            className="w-full"
                            type="range"
                            value={response}
                            min={question.range_start}
                            max={question.range_end}
                            disabled={disabled}
                            onChange={(e) => {
                                setResponse(e.target.value);
                            }}
                            onBlur={() => {
                                onUpdate({ response });
                            }}
                            step="1"
                        />
                    </div>
                    <input
                        type="text"
                        value={response}
                        className="px-3 leading-normal h-12 w-20 border-t border-r border-b border-gray-200 text-center rounded rounded-l-none"
                        disabled={disabled}
                        onChange={(e) => {
                            setResponse(e.target.value);
                        }}
                        onBlur={(e) => {
                            const value = parseInt(e.target.value);
                            if (
                                value > question.range_end ||
                                value < question.range_start
                            ) {
                                setResponse(
                                    value < question.range_start
                                        ? question.range_start
                                        : question.range_end
                                );
                                return alert(
                                    `Please enter a value between ${question.range_start} and ${question.range_end}`
                                );
                            }
                            onUpdate({ response });
                        }}
                    />
                </div>
            </QuestionWrapper>
        </>
    );
}
