import React from "react";
import useApi from "../../hooks/useApi";
import PageContainer from "../../components/PageContainer";
import ClientPageHeader, { Heading } from "../../components/ClientPageHeader";
import UnsavedChanges from "../../components/UnsavedChanges";
import { useToasts } from "react-toast-notifications";
import SaveButton from "../../components/SaveButton";
import useMountEffect from "../../hooks/useMountEffect";
import { useHistory } from "react-router";
import AppointmentForm from "./_partials/AppointmentForm";
import useAuth from "../../hooks/useAuth";
import { InputLabel } from "../../components/TextInput";
import Panel from "../../components/Panel";

export default function EditAppointment({ client, clientPath, ...props }) {
    const history = useHistory();
    const { user } = useAuth();
    const readOnly = user.type !== "member";
    const { api, saving, unauthorized } = useApi();
    const { addToast } = useToasts();
    const [errors, setErrors] = React.useState([]);
    const [hasUnsavedChanges, setHasUnsavedChanges] = React.useState(false);
    const appointmentId = props.match.params.appointmentId;
    const [input, setInput] = React.useReducer(
        (state, newState) => ({ ...state, ...newState }),
        {
            member_id: "",
            client_id: client.id,
            title: "",
            description: "",
            date: "",
            hour: 0,
            minute: 0,
            meridiem: "AM",
        }
    );

    const fetchAppointment = async () => {
        try {
            const response = await api("get", `appointments/${appointmentId}`);
            setInput(response.data.data);
        } catch (error) {
            console.log(error);
        }
    };

    useMountEffect(() => {
        fetchAppointment();
    });

    const updateValue = React.useCallback((event) => {
        setInput({ [event.target.name]: event.target.value });
        setHasUnsavedChanges(true);
    }, []);

    const updateAppointment = async (e) => {
        e.preventDefault();
        try {
            await api("put", `appointments/${appointmentId}`, input);
            setHasUnsavedChanges(false);
            history.push(`${clientPath}/appointments`);
        } catch (error) {
            console.log(error);
            if (error.response.data.errors) {
                setErrors(error.response.data.errors);
            } else {
                addToast(error.response.data.message, { type: "error" });
            }
        }
    };

    return (
        <>
            <ClientPageHeader client={client}>
                <Heading
                    backTo={`${clientPath}/appointments`}
                    backText="All Appointments"
                >
                    {readOnly ? <>View Appointment</> : <>Edit Appointment</>}
                </Heading>
                {readOnly === false && (
                    <div className="flex-0">
                        <SaveButton
                            isLoading={saving}
                            onClick={updateAppointment}
                        />
                    </div>
                )}
            </ClientPageHeader>
            <PageContainer forbidden={unauthorized}>
                <UnsavedChanges when={hasUnsavedChanges} />
                {readOnly === true && (
                    <Panel>
                        <div className="space-y-6">
                            <div>
                                <h2 className="text-lg font-bold">
                                    {input.title}
                                </h2>
                            </div>
                            <div>
                                <InputLabel text="Date & Time"></InputLabel>
                                {input.date_formatted} at {input.time_formatted}
                            </div>
                            {input.member && (
                                <div>
                                    <InputLabel text="With Attorney"></InputLabel>
                                    {input.member.full_name}
                                </div>
                            )}
                            {input.description && (
                                <div>
                                    <InputLabel text="Details"></InputLabel>
                                    <p className="whitespace-pre-line">
                                        {input.description}
                                    </p>
                                </div>
                            )}
                        </div>
                    </Panel>
                )}
                {readOnly === false && (
                    <AppointmentForm
                        input={input}
                        errors={errors}
                        updateValue={updateValue}
                        onSubmit={updateAppointment}
                        readOnly={readOnly}
                    />
                )}
            </PageContainer>
        </>
    );
}
