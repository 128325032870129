import React from "react";
import SelectInput from "./SelectInput";
import useFrequency from "../hooks/useFrequency";

export default function FrequencySelect({ optionItems = [], ...props }) {
    const [options, setOptions] = React.useState([]);

    const { data, isSuccess, isLoading: loading } = useFrequency();

    React.useEffect(() => {
        if (optionItems.length > 0) {
            setOptions(optionItems);
            return;
        }
        if (isSuccess) {
            if (optionItems.length > 0) {
                return;
            }

            let newOptions = data;
            if (props.only) {
                newOptions = data.filter((option) => {
                    if (props.only.includes(option.value)) {
                        return option;
                    } else {
                        return false;
                    }
                });
            } else if (props.except) {
                newOptions = data.filter((option) => {
                    if (props.except.includes(option.value)) {
                        return false;
                    } else {
                        return option;
                    }
                });
            }
            setOptions((old) =>
                JSON.stringify(old) !== JSON.stringify(newOptions)
                    ? newOptions
                    : old
            );
        }
    }, [props.only, props.except, options.length, optionItems]);

    return <SelectInput options={options} loading={loading} {...props} />;
}
