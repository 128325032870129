import React from "react";
import { Heading } from "components/ClientPageHeader";
import useClient from "hooks/useClient";
import RelationshipLayout from "./RelationshipLayout";
import ClientNotes from "views/clients/Notes/ClientNotes";

export default function RelationshipNotes({
    relationshipId,
    relationshipInput,
    readOnly = false,
    currentBasePath,
}) {
    const { client } = useClient();

    return (
        <RelationshipLayout
            relationshipInput={relationshipInput}
            readOnly={readOnly}
            currentBasePath={currentBasePath}
        >
            <Heading>Notes</Heading>
            <ClientNotes
                client={client}
                resourceType={"Relationship"}
                resourceId={relationshipId}
            />
        </RelationshipLayout>
    );
}
