import { InputLabel } from "components/TextInput";
import React from "react";
import Select from "react-select/async";
import { fetchUsers } from "services/UserService";

export const styles = {
    control: (provided) => ({
        ...provided,
        "--border-opacity": 1,
        height: "3rem",
        border: "solid 1px rgba(229, 231, 235, var(--border-opacity))",
        borderRadius: "0.25rem",
    }),
};

export default function UserSelect({
    id = null,
    name,
    placeholder = null,
    label = null,
    onChange,
    cacheOptions = false,
    params,
    initialData = [{ label: "Choose an option...", value: "" }],
    required = false,
}) {
    const loadOptions = async (search) => {
        const result = await fetchUsers({
            params: { ...params, "filter[search]": search, limit: 100 },
        });

        const groupedByType = result.data.reduce((acc, user) => {
            if (!acc[user.type]) {
                acc[user.type] = [];
            }
            acc[user.type].push({
                id: user.id,
                value: user.id.toString(),
                label: user.full_name,
            });
            return acc;
        }, {});

        let finalResult = initialData ? initialData : [];

        if (groupedByType["client"]) {
            finalResult.push({
                label: "Clients",
                options: groupedByType["client"],
            });
        }
        if (groupedByType["member"]) {
            finalResult.push({
                label: "Members",
                options: groupedByType["member"],
            });
        }
        if (groupedByType["advisor"]) {
            finalResult.push({
                label: "Advisors",
                options: groupedByType["advisor"],
            });
        }

        return finalResult;
    };

    return (
        <div className="w-64 flex-shrink-0">
            {label && (
                <InputLabel
                    text={label}
                    htmlFor={id || name}
                    required={required}
                />
            )}
            <Select
                id={id || name}
                name={name}
                placeholder={placeholder || "Select an option..."}
                onChange={(selection) => onChange(selection.value)}
                cacheOptions={cacheOptions}
                defaultOptions
                loadOptions={loadOptions}
                onInputChange={loadOptions}
                styles={styles}
            />
        </div>
    );
}
