import React, { useState } from "react";
import useClient from "hooks/useClient";
import Note from "views/clients/Notes/Note";
import useAuth from "hooks/useAuth";
import { useClientNotes } from "views/clients/Notes/useClientNotes";
import useClientNotesEstateAdminFetch from "hooks/useClientNotesEstateAdminFetch";
import usePostMortemState from "hooks/usePostMortemState";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";
import { titleCase } from "util/TextUtil";
import Spinner from "components/Spinner";

export default function EstateAdminNotesReport() {
    const { client } = useClient();
    const auth = useAuth();
    const [currentPage] = useState(1);
    const { person } = usePostMortemState();

    const { data, isLoading, refetch } = useClientNotesEstateAdminFetch({
        clientId: client.id,
        page: currentPage,
        person,
    });

    const assets = data ? data.data : [];

    const processing = false;
    const input = {};
    const setInput = () => {};

    const { deleteNote, updateNote } = useClientNotes({
        refetch,
        input,
        setInput,
        currentPage: 1,
        setCurrentPage: () => {},
        onlyEstateAdmin: true,
    });

    return (
        <>
            {(!assets || assets.length === 0) && !isLoading && (
                <div>No Estate Administration notes have been added yet.</div>
            )}
            {isLoading && (
                <div className="w-full h-full flex justify-center items-center">
                    <Spinner message="Loading Notes" />
                </div>
            )}

            {assets.length > 0 && (
                <>
                    <div className="mt-10 space-y-5 divide-y-2">
                        {assets.map((asset) => {
                            const notes = asset.client_notes;
                            return (
                                <div
                                    key={asset.resource_uuid}
                                    className="space-y-6 pt-6 bg-white rounded-lg p-6 border-2 border-gray-100"
                                >
                                    <div>
                                        <div
                                            className={`text-sm text-gray-500`}
                                        >
                                            {titleCase(asset.type_formatted, {
                                                pascal: true,
                                            })}
                                        </div>
                                        <div className="flex items-start">
                                            <a
                                                href={asset.resource_path}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className="hover:text-brand-500 text-gray-600"
                                            >
                                                <span className="font-bold text-xl">
                                                    {
                                                        asset.resource_display_name
                                                    }
                                                </span>
                                                &nbsp;
                                                <FontAwesomeIcon
                                                    icon={faExternalLinkAlt}
                                                    className="pb-1"
                                                />
                                            </a>
                                        </div>
                                        {asset.account_number && (
                                            <span className="text-xs text-gray-500 block mt-1">
                                                {asset.account_number}
                                            </span>
                                        )}
                                    </div>
                                    {notes.map((note) => (
                                        <Note
                                            key={note.id}
                                            client={client}
                                            note={note}
                                            auth={auth}
                                            showRelations={false}
                                            showRelatedResources={false}
                                            refetch={refetch}
                                            deleteNote={deleteNote}
                                            updateNote={updateNote}
                                            processing={processing}
                                        />
                                    ))}
                                </div>
                            );
                        })}
                    </div>
                </>
            )}
        </>
    );
}
