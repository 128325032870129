import React from "react";
import TextArea from "../../../components/TextArea";
import Fieldset from "../../../components/Fieldset";
import { DataList } from "../../../components/TextInput";

export default function Documents({ documents, locations, setDocuments }) {
    const updateValue = (e) => {
        let newItems = { ...documents };
        newItems[e.target.name] = e.target.value;
        setDocuments(newItems);
    };

    return (
        <Fieldset legend="Location Of Your Important Documents">
            <div className="mb-6">
                <DataList
                    list={locations}
                    label="Original Stock Certificates [Not held in account]"
                    name="original_stock_certificates"
                    value={documents["original_stock_certificates"]}
                    onChange={(e) => updateValue(e)}
                />
            </div>
            <div className="mb-6">
                <DataList
                    list={locations}
                    label="Original Bonds [Not held in account]"
                    name="original_bonds"
                    value={documents["original_bonds"]}
                    onChange={(e) => updateValue(e)}
                />
            </div>
            <div className="mb-6">
                <DataList
                    list={locations}
                    label="Original CDs [Not held in account]"
                    name="original_cds"
                    value={documents["original_cds"]}
                    onChange={(e) => updateValue(e)}
                />
            </div>
            <div className="mb-6">
                <DataList
                    list={locations}
                    label="Original Life Insurance Policies"
                    name="original_life_policies"
                    value={documents["original_life_policies"]}
                    onChange={(e) => updateValue(e)}
                />
            </div>
            <div className="mb-6">
                <DataList
                    list={locations}
                    label="Original Homeowners/Auto/Umbrella/Liability Insurance Policies"
                    name="original_none_life_policies"
                    value={documents["original_none_life_policies"]}
                    onChange={(e) => updateValue(e)}
                />
            </div>
            <div className="mb-6">
                <DataList
                    list={locations}
                    label="Original Disability/Long Term Care Insurance Policies"
                    name="original_disability_policies"
                    value={documents["original_disability_policies"]}
                    onChange={(e) => updateValue(e)}
                />
            </div>
            <div className="mb-6">
                <DataList
                    list={locations}
                    label="Student Loan Documents"
                    name="student_loan_documents"
                    value={documents["student_loan_documents"]}
                    onChange={(e) => updateValue(e)}
                />
            </div>

            <div className="my-10">
                <h2 className="mb-4 font-bold text-gray-500">Tax Records</h2>
                <div className="mb-6">
                    <DataList
                        list={locations}
                        label="Income Tax Returns"
                        name="income_tax_returns"
                        value={documents["income_tax_returns"]}
                        onChange={(e) => updateValue(e)}
                    />
                </div>
                <div className="mb-6">
                    <DataList
                        list={locations}
                        label="Gift Tax Returns"
                        name="gift_tax_returns"
                        value={documents["gift_tax_returns"]}
                        onChange={(e) => updateValue(e)}
                    />
                </div>
            </div>

            <div className="my-10">
                <h2 className="mb-4 font-bold text-gray-500">Real Estate</h2>
                <div className="mb-6">
                    <DataList
                        list={locations}
                        label="Title Insurance"
                        name="title_insurance"
                        value={documents["title_insurance"]}
                        onChange={(e) => updateValue(e)}
                    />
                </div>
                <div className="mb-6">
                    <DataList
                        list={locations}
                        label="Rental Property Records"
                        name="rental_property_records"
                        value={documents["rental_property_records"]}
                        onChange={(e) => updateValue(e)}
                    />
                </div>
                <div className="mb-6">
                    <DataList
                        list={locations}
                        label="Rental Property Leases"
                        name="rental_property_leases"
                        value={documents["rental_property_leases"]}
                        onChange={(e) => updateValue(e)}
                    />
                </div>
                <div className="mb-6">
                    <DataList
                        list={locations}
                        label="Notes/Loan Agreements"
                        name="notes_loan_agreements"
                        value={documents["notes_loan_agreements"]}
                        onChange={(e) => updateValue(e)}
                    />
                </div>
                <div className="mb-6">
                    <DataList
                        list={locations}
                        label="Mortgages"
                        name="mortgages"
                        value={documents["mortgages"]}
                        onChange={(e) => updateValue(e)}
                    />
                </div>
                <div className="mb-6">
                    <DataList
                        list={locations}
                        label="Warranties"
                        name="warranties"
                        value={documents["warranties"]}
                        onChange={(e) => updateValue(e)}
                    />
                </div>
                <div className="mb-6">
                    <DataList
                        list={locations}
                        label="Maintenance Agreements"
                        name="maintenance_agreements"
                        value={documents["maintenance_agreements"]}
                        onChange={(e) => updateValue(e)}
                    />
                </div>
            </div>

            <div className="my-10">
                <h2 className="mb-4 font-bold text-gray-500">
                    Personal Effects/Other Assets
                </h2>
                <div className="mb-6">
                    <DataList
                        list={locations}
                        label="Car Titles"
                        name="car_title"
                        value={documents["car_title"]}
                        onChange={(e) => updateValue(e)}
                    />
                </div>
                <div className="mb-6">
                    <DataList
                        list={locations}
                        label="Boat/Plane/Recreation Vehicle Titles"
                        name="boat_plane_title"
                        value={documents["boat_plane_title"]}
                        onChange={(e) => updateValue(e)}
                    />
                </div>
                <div className="mb-6">
                    <DataList
                        list={locations}
                        label="Marriage Certificate"
                        name="marriage_certificate"
                        value={documents["marriage_certificate"]}
                        onChange={(e) => updateValue(e)}
                    />
                </div>
                <div className="mb-6">
                    <DataList
                        list={locations}
                        label="Premarital Agreement"
                        name="premarital_certificate"
                        value={documents["premarital_certificate"]}
                        onChange={(e) => updateValue(e)}
                    />
                </div>
                <div className="mb-6">
                    <DataList
                        list={locations}
                        label="Divorce Seperation Papers"
                        name="divorce_papers"
                        value={documents["divorce_papers"]}
                        onChange={(e) => updateValue(e)}
                    />
                </div>
                <div className="mb-6">
                    <DataList
                        list={locations}
                        label="Birth Certificates"
                        name="birth_certificates"
                        value={documents["birth_certificates"]}
                        onChange={(e) => updateValue(e)}
                    />
                </div>
                <div className="mb-6">
                    <DataList
                        list={locations}
                        label="Death Certificates"
                        name="death_certificates"
                        value={documents["death_certificates"]}
                        onChange={(e) => updateValue(e)}
                    />
                </div>
                <div className="mb-6">
                    <DataList
                        list={locations}
                        label="Adoption Papers"
                        name="adoption_papers"
                        value={documents["adoption_papers"]}
                        onChange={(e) => updateValue(e)}
                    />
                </div>
                <div className="mb-6">
                    <DataList
                        list={locations}
                        label="Citizenship Papers"
                        name="citizenship_papers"
                        value={documents["citizenship_papers"]}
                        onChange={(e) => updateValue(e)}
                    />
                </div>
                <div className="mb-6">
                    <DataList
                        list={locations}
                        label="Military Papers"
                        name="military_papers"
                        value={documents["military_papers"]}
                        onChange={(e) => updateValue(e)}
                    />
                </div>
                <div className="mb-6">
                    <DataList
                        list={locations}
                        label="Social Security Cards"
                        name="soc_cards"
                        value={documents["soc_cards"]}
                        onChange={(e) => updateValue(e)}
                    />
                </div>
                <div className="mb-6">
                    <DataList
                        list={locations}
                        label="Passport"
                        name="passport"
                        value={documents["passport"]}
                        onChange={(e) => updateValue(e)}
                    />
                </div>
                <div className="mb-6">
                    <DataList
                        list={locations}
                        label="Checkbooks"
                        name="checkbooks"
                        value={documents["checkbooks"]}
                        onChange={(e) => updateValue(e)}
                    />
                </div>
                <div className="mb-6">
                    <DataList
                        list={locations}
                        label="Medical/Dental/Vision Insurance Cards"
                        name="medical_insurance_cards"
                        value={documents["medical_insurance_cards"]}
                        onChange={(e) => updateValue(e)}
                    />
                </div>
                <div className="mb-6">
                    <DataList
                        list={locations}
                        label="Credit Cards/Debit Cards/Retail Store Cards"
                        name="credit_cards"
                        value={documents["credit_cards"]}
                        onChange={(e) => updateValue(e)}
                    />
                </div>
                <div className="mb-6">
                    <DataList
                        list={locations}
                        label="Medicare/Medicaid/Medicare Supplemental Insurance Card/Veterans Administration Cards"
                        name="medicare_cards"
                        value={documents["medicare_cards"]}
                        onChange={(e) => updateValue(e)}
                    />
                </div>
                <div className="mb-6">
                    <DataList
                        list={locations}
                        label="Bills/Statements received by mail"
                        name="bills_received_by_mail"
                        value={documents["bills_received_by_mail"]}
                        onChange={(e) => updateValue(e)}
                    />
                </div>
                <div className="mb-6">
                    <TextArea
                        rows="3"
                        label="Bills/Statements received by email"
                        name="bills_received_by_email"
                        value={documents["bills_received_by_email"]}
                        onChange={(e) => updateValue(e)}
                        placeholder="List Bill/Statement Name &amp; email address it is received at."
                    />
                </div>
                <div className="mb-6">
                    <DataList
                        list={locations}
                        label="Budget/Schedule of Expenses"
                        name="budget"
                        value={documents["budget"]}
                        onChange={(e) => updateValue(e)}
                    />
                </div>
            </div>
        </Fieldset>
    );
}
