import React from "react";
import Money from "./Money";

export default function TableSummaryRow({ label = "Totals:", columns }) {
    return (
        <tr className={"border-t-2 border-brand-200 font-bold"}>
            {columns.map((c, i) => {
                if (label && i === 0)
                    return <td key={`summaryItem_${i}`}>{label}</td>;
                return (
                    <td key={`summaryItem_${i}`}>
                        {typeof c === "number" ? <Money value={c} /> : ""}
                    </td>
                );
            })}
        </tr>
    );
}
