import React from "react";
import Button from "../../../components/Button";
import useClient from "../../../hooks/useClient";

import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useIceContext from "../hooks/useIceContext";

export default function ICEMedical() {
    const { clientPath } = useClient();
    const { isSpouse } = useIceContext();
    return (
        <div className="flex flex-col space-y-6">
            <h2 className="text-lg font-bold">Medical History</h2>
            <div>
                ICE Medical information is automatically pulled from the Medical
                History in the Location List Form.
            </div>
            <div className="w-64">
                <Button
                    type="link"
                    target="_blank"
                    to={
                        clientPath +
                        `/forms/location-list/edit?spouse=${
                            isSpouse ? 1 : 0
                        }#medical`
                    }
                >
                    Open Medical Information
                    <FontAwesomeIcon
                        icon={faExternalLinkAlt}
                        className="ml-2"
                    />
                </Button>
            </div>
        </div>
    );
}
