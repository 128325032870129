import React from "react";
import PropTypes from "prop-types";

export default function Panel({
    children,
    className = "",
    allowOverflow = false,
    appearance = "default",
    ...props
}) {
    const styles = {
        default:
            "bg-white shadow-xs rounded-xl p-6 border border-transparent relative h-full",
        wrap: "bg-white shadow-xs rounded-xl p-6 border border-transparent relative",
        alert: "bg-orange-400 rounded-xl p-6 border border-transparent relative text-orange-50 font-bold",
        flat: "bg-transparent",
    };

    return (
        <div
            className={`${allowOverflow ? "" : "overflow-hidden"} ${className
                .concat(" ")
                .concat(styles[appearance])}`}
            {...props}
        >
            {props.header && <div>{props.header}</div>}
            <div className="h-full">{children}</div>
            {props.footer && (
                <div className="bg-gray-50 p-4 mt-4">{props.footer}</div>
            )}
        </div>
    );
}

export function PanelHeader({
    title = "",
    size = "lg",
    children = null,
    subtitle = "",
    titleChildren = null,
}) {
    const sizes = {
        lg: "text-xl text-gray-700 font-bold",
        sm: "text-lg text-gray-700 font-bold",
    };

    return (
        <div className="flex items-center justify-between mb-4 pb-3 border-b border-gray-200">
            <div>
                {subtitle && (
                    <div className="text-sm text-gray-500">{subtitle}</div>
                )}
                <h2 className={`${sizes[size ? size : "lg"]}`}>{title}</h2>
                {titleChildren}
            </div>
            <div className="flex items-center justify-between">{children}</div>
        </div>
    );
}

Panel.protoTypes = {
    className: PropTypes.string,
};
