import Api from "./Api";
import axios from "axios";
class Auth {
    async login(values) {
        await this.getCsrfToken();
        window.localStorage.removeItem("session-expiration");
        let response = await Api.post("login", {
            ...values,
        });

        if (
            response.data.redirect_to_subdomain &&
            window.location !== response.data.redirect_to_subdomain
        ) {
            return (window.location = response.data.redirect_to_subdomain);
        }

        const user = response.data.user;
        const organization = response.data.organization;
        this.saveUserToSession(user, organization);

        return response;
    }

    async logout() {
        try {
            let response = await Api.delete("logout");
            this.clearSession();
            return response;
        } catch (error) {
            this.clearSession();
            throw error;
        }
    }

    async forgot(values) {
        return await Api.post("login/forgot", values);
    }

    async resetPassword({ token, password, password_confirmation }) {
        return await Api.post("login/reset", {
            token,
            password,
            password_confirmation,
        });
    }

    async check() {
        try {
            const response = await Api.get("login/check");
            const user = response.data.user;
            const organization = response.data.organization;
            this.saveUserToSession(user, organization);
            window.zESettings = {
                webWidget: {
                    contactForm: {
                        fields: [
                            {
                                id: "name",
                                prefill: { "*": user.full_name },
                            },
                            {
                                id: "email",
                                prefill: { "*": user.email },
                            },
                        ],
                    },
                },
            };

            return response;
        } catch (error) {
            if (error.response.status === 404) {
                return await this.logout();
            }

            throw error;
        }
    }

    async impersonateClient(clientId, payload) {
        try {
            await Api.post(`clients/${clientId}/impersonate`, payload);
            if (window.location.origin) {
                window.location.href = window.location.origin;
            } else {
                window.location.reload();
            }
        } catch (error) {
            if (error.response.data.message) {
                alert(error.response.data.message);
            }
            console.log(error);
        }
    }

    saveUserToSession(user, organization) {
        window.sessionStorage.setItem(
            "con2a:user_profile",
            JSON.stringify({ user, organization })
        );
    }

    clearSession() {
        window.sessionStorage.removeItem("con2a:user_profile");
    }

    async getCsrfToken() {
        return await axios.get("/sanctum/csrf-cookie");
    }
}

export default new Auth();
