import useFetch from "./useFetch";
import {
    fetchContacts,
    fetchAvailableContacts,
} from "services/IceContactsService";

export function useFetchContacts(params, config = { keepPreviousData: true }) {
    return useFetch(["fetchContacts", params], fetchContacts, config);
}

export function useFetchAvailableContacts(
    params,
    config = { keepPreviousData: true }
) {
    return useFetch(
        ["fetchAvailableContacts", params],
        fetchAvailableContacts,
        config
    );
}
