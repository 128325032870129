import React, { useState } from "react";
import Api from "../../services/Api";
import Spinner from "../Spinner";
import TextInput, { InputLabel } from "../TextInput";
import ModalModelFactory from "../ModalModelFactory";
import SelectInput from "../SelectInput";

export default function Grantor({
    client,
    updateValue,
    input,
    errors,
    ...props
}) {
    const [loading, setLoading] = useState(false);
    const [needsRefreshed, setNeedsRefreshed] = useState(0);
    const [relationships, setRelationships] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const [quickAddType, setQuickAddType] = useState("");
    const [quickAddTitle, setQuickAddTitle] = useState("");

    function grantorTypes() {
        return [
            { value: "Relationship", label: "Relationship" },
            { value: "OTH", label: "Other" },
        ];
    }

    function clientOptions() {
        let options = [
            { value: "", label: "Select an option..." },
            { value: "Client", label: client.full_name },
        ];

        if (client.spouse) {
            options.splice(2, 0, {
                value: "Spouse",
                label: client.spouse.full_name + " (Spouse)",
            });
            options.splice(3, 0, {
                value: "JT",
                label: "Joint with Spouse",
            });
        }

        return options;
    }

    React.useEffect(() => {
        const fetchRelationships = async () => {
            try {
                setLoading(true);
                let response = await Api.get(
                    "clients/" + client.id + "/relationships"
                );
                let options = response.data.data.map((resource) => {
                    return {
                        value: resource.id,
                        label:
                            resource.type === "organization"
                                ? resource.organization_name
                                : resource.full_name,
                    };
                });
                setRelationships(options);
                setLoading(false);
            } catch (error) {
                setLoading(false);
            }
        };
        fetchRelationships();
    }, [client.id, needsRefreshed]);

    if (loading) {
        return <Spinner />;
    }

    return (
        <>
            <div className="flex">
                <div className="flex-1">
                    <SelectInput
                        className={`${
                            input.grantor_type === "Relationship" ||
                            input.grantor_type === "OTH"
                                ? "rounded-r-none"
                                : ""
                        }`}
                        label="Grantor"
                        name="grantor_type"
                        options={clientOptions().concat(grantorTypes())}
                        loading={false}
                        value={input.grantor_type}
                        disabled={props.disabled}
                        required={props.required}
                        error={errors.grantor_type}
                        onChange={(e) => {
                            updateValue(e);
                            let value = e.target.value;
                            if (value === "Client" || value === "JT") {
                                // Is client or Joint/w Spouse
                                return updateValue({
                                    target: {
                                        name: "grantor_id",
                                        value: client.id,
                                    },
                                });
                            }

                            if (value === "Spouse") {
                                return updateValue({
                                    target: {
                                        name: "grantor_id",
                                        value: client.spouse.id || 0,
                                    },
                                });
                            }

                            return updateValue({
                                target: {
                                    name: "grantor_id",
                                    value: 0,
                                },
                            });
                        }}
                    />
                </div>
                <>
                    {/* Show Relationship input */}
                    {input.grantor_type === "Relationship" && (
                        <div className="flex-1">
                            <div className="flex justify-between items-center w-full">
                                <InputLabel text="Select Relationship" />
                                <button
                                    onClick={() => {
                                        setQuickAddTitle("Relationship");
                                        setQuickAddType("Relationship");
                                        setIsOpen(true);
                                    }}
                                    type="button"
                                    className="mb-3 text-gray-500 text-sm hover:text-brand flex items-center space-x-1"
                                >
                                    <span>Quick Add</span>
                                </button>
                            </div>
                            <SelectInput
                                className="border-l-0 rounded-l-none"
                                name="grantor_id"
                                options={relationships}
                                loading={loading}
                                disabled={props.disabled}
                                required={props.required}
                                value={input.grantor_id}
                                error={errors.grantor_id}
                                onChange={updateValue}
                            />
                        </div>
                    )}

                    {/* Show free form input when other is selected */}
                    {["OTH"].includes(input.grantor_type) && (
                        <div className="flex-1">
                            <TextInput
                                name="grantor_description"
                                label="Other Grantor"
                                className="border-l-0 rounded-l-none"
                                value={input.grantor_description}
                                placeholder="Enter name of grantor"
                                disabled={props.disabled}
                                error={errors.grantor_description}
                                onChange={updateValue}
                            />
                        </div>
                    )}
                </>
            </div>

            <ModalModelFactory
                isOpen={isOpen}
                client={client}
                user={[]}
                title={`Add ${quickAddTitle}`}
                modelType={quickAddType}
                onClose={() => {
                    setIsOpen(false);
                    setQuickAddType("");
                }}
                onSuccess={() => {
                    setIsOpen(false);
                    setQuickAddType("");
                    setNeedsRefreshed(needsRefreshed + 1);
                }}
            />
        </>
    );
}
