import React from "react";
import ReactDOM from "react-dom";
import BasicModal from "./ModalBasic";
import AnnuityModal from "../views/annuities/AnnuityModal";
import FinancialAccountModal from "../views/financial_accounts/FinancialAccountModal";
import InsurancePolicyModal from "../views/insurance_policies/InsurancePolicyModal";
import LeaseModal from "../views/leases/LeaseModal";
import OtherInsurancePolicyModal from "../views/other_insurance_policies/OtherInsurancePolicyModal";
import PersonalPropertyModal from "../views/personal_property/PersonalPropertyModal";
import RealEstateModal from "../views/real_estate/RealEstateModal";
import TrustModal from "../views/trusts/TrustModal";
import BusinessInterestModal from "../views/business_interests/BusinessInterestModal";
import RelationshipModal from "../views/relationships/RelationshipModal";
import NonMortgageLiabilityModal from "../views/non_mortgage_liabilities/NonMortgageLiabilityModal";
import RetirementAccountModal from "../views/retirement_accounts/RetirementAccountModal";
import AdvisorModal from "../views/advisors/AdvisorModal";

const ModelTypes = {
    Annuity: "Annuity",
    FinancialAccount: "FinancialAccount",
    RetirementAccount: "RetirementAccount",
    InsurancePolicy: "InsurancePolicy",
    Lease: "Lease",
    OtherInsurance: "OtherInsurance",
    PersonalProperty: "PersonalProperty",
    RealEstate: "RealEstate",
    Trust: "Trust",
    BusinessInterest: "BusinessInterest",
    Relationship: "Relationship",
    NonMortgageLiability: "NonMortgageLiability",
    Advisor: "Advisor",
};

export { ModelTypes };

export default function ({
    isOpen,
    client,
    onClose,
    onSuccess,
    title,
    modelType,
    id = -1,
    parentResource,
    ...props
}) {
    switch (modelType) {
        case ModelTypes.Annuity:
            return ReactDOM.createPortal(
                <AnnuityModal
                    isOpen={isOpen}
                    client={client}
                    onClose={onClose}
                    onSuccess={onSuccess}
                    title={title}
                    id={id}
                />,
                document.getElementById("quickAddPortal")
            );
        case ModelTypes.FinancialAccount:
            return ReactDOM.createPortal(
                <FinancialAccountModal
                    isOpen={isOpen}
                    client={client}
                    onClose={onClose}
                    onSuccess={onSuccess}
                    title={title}
                    id={id}
                />,
                document.getElementById("quickAddPortal")
            );
        case ModelTypes.RetirementAccount:
            return ReactDOM.createPortal(
                <RetirementAccountModal
                    isOpen={isOpen}
                    client={client}
                    onClose={onClose}
                    onSuccess={onSuccess}
                    title={title}
                    id={id}
                />,
                document.getElementById("quickAddPortal")
            );
        case ModelTypes.InsurancePolicy:
            return ReactDOM.createPortal(
                <InsurancePolicyModal
                    isOpen={isOpen}
                    client={client}
                    onClose={onClose}
                    onSuccess={onSuccess}
                    title={title}
                    id={id}
                />,
                document.getElementById("quickAddPortal")
            );
        case ModelTypes.Lease:
            return ReactDOM.createPortal(
                <LeaseModal
                    isOpen={isOpen}
                    client={client}
                    onClose={onClose}
                    onSuccess={onSuccess}
                    title={title}
                    id={id}
                    {...props}
                />,
                document.getElementById("quickAddPortal")
            );
        case ModelTypes.OtherInsurance:
            return ReactDOM.createPortal(
                <OtherInsurancePolicyModal
                    isOpen={isOpen}
                    client={client}
                    onClose={onClose}
                    onSuccess={onSuccess}
                    title={title}
                    parentResource={parentResource}
                    id={id}
                />,
                document.getElementById("quickAddPortal")
            );
        case ModelTypes.PersonalProperty:
            return ReactDOM.createPortal(
                <PersonalPropertyModal
                    isOpen={isOpen}
                    client={client}
                    onClose={onClose}
                    onSuccess={onSuccess}
                    title={title}
                    id={id}
                />,
                document.getElementById("quickAddPortal")
            );
        case ModelTypes.RealEstate:
            return ReactDOM.createPortal(
                <RealEstateModal
                    isOpen={isOpen}
                    client={client}
                    onClose={onClose}
                    onSuccess={onSuccess}
                    title={title}
                    id={id}
                />,
                document.getElementById("quickAddPortal")
            );
        case ModelTypes.Trust:
            return ReactDOM.createPortal(
                <TrustModal
                    isOpen={isOpen}
                    client={client}
                    onClose={onClose}
                    onSuccess={onSuccess}
                    title={title}
                    id={id}
                />,
                document.getElementById("quickAddPortal")
            );
        case ModelTypes.BusinessInterest:
            return ReactDOM.createPortal(
                <BusinessInterestModal
                    isOpen={isOpen}
                    client={client}
                    onClose={onClose}
                    onSuccess={onSuccess}
                    title={title}
                />,
                document.getElementById("quickAddPortal")
            );
        case ModelTypes.Relationship:
            return ReactDOM.createPortal(
                <RelationshipModal
                    isOpen={isOpen}
                    client={client}
                    onClose={onClose}
                    onSuccess={onSuccess}
                    title={title}
                />,
                document.getElementById("quickAddPortal")
            );
        case ModelTypes.NonMortgageLiability:
            return ReactDOM.createPortal(
                <NonMortgageLiabilityModal
                    isOpen={isOpen}
                    client={client}
                    onClose={onClose}
                    onSuccess={onSuccess}
                    title={title}
                    parentResource={parentResource}
                />,
                document.getElementById("quickAddPortal")
            );
        case ModelTypes.Advisor:
            return ReactDOM.createPortal(
                <AdvisorModal
                    isOpen={isOpen}
                    client={client}
                    onClose={onClose}
                    onSuccess={onSuccess}
                    title={title}
                />,
                document.getElementById("quickAddPortal")
            );
        default:
            return ReactDOM.createPortal(
                <BasicModal
                    isOpen={isOpen}
                    onClose={onClose}
                    size="sm"
                    title="Not Found"
                >
                    Model not found.
                </BasicModal>,
                document.getElementById("quickAddPortal")
            );
    }
}
