// Used for displaying a single asset on the Funding Table screen.

import React, { useState, useEffect } from "react";
import Panel from "../Panel";
import { titleCase } from "../../util/TextUtil";
import Money, { parseMoney } from "../Money";
import Ownership from "./Ownership";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";
import Button from "../Button";
import Spinner from "../Spinner";
import { useToasts } from "react-toast-notifications";
import ProposeTransferButton from "./partials/ProposeTransferButton";
import { TransferButtonToolbar } from "./partials/TransferButtonToolbar";
import { OwnershipLineItemReadOnly } from "./partials/OwnershipLineItemReadOnly";
import { PendingTransferLineItemReadOnly } from "./partials/PendingTransferLineItemReadOnly";
import { Tooltip } from "react-tippy";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import useAssetValues from "views/funding_table/hooks/useAssetValues";
import usePostMortemState from "hooks/usePostMortemState";
import { useOwnershipContext } from "contexts/OwnershipContext";

export default function AssetDisplayForTransfer({
    client,
    asset,
    ownerships,
    showProposed,
    setVerifyAsset,
    setVerifyTransfer,
    setVerifyOwnership,
    date,
    onSave,
    isReadOnly,
    allowChangingOwnershipMode = true,
}) {
    const { isPostMortem } = usePostMortemState();

    const ownershipContext = useOwnershipContext();
    const { setCurrent } = ownershipContext;
    const [edit, setEdit] = useState(false);
    const [showTransferButton, setShowTransferButton] = useState(false);
    const { addToast } = useToasts();

    asset.resource_id = asset.resource_id ?? asset.id;
    asset.type = asset.type ?? asset.resource_type;

    const { asOfDate, value, gross_value, balance, valueLabel, asOfDateLabel } =
        useAssetValues({
            asset,
        });

    useEffect(() => {
        setShowTransferButton(showProposed && (asset.is_transferrable ?? true));
    }, [asset, showProposed]);

    if (!asset) {
        return <Spinner message="Loading asset..." />;
    }

    return (
        <Panel
            key={`${asset.type}_${asset.id}`}
            className={`${edit ? "border-brand-500" : ""}`}
        >
            <div className="bg-white flex flex-wrap mb-3 pb-3 border-b border-gray-200">
                <div className="flex-none mr-10">
                    <div className={`text-sm text-gray-500`}>
                        {titleCase(asset.type, {
                            pascal: true,
                        })}
                    </div>
                    <h2 className="text-sm">
                        <div className="flex items-start">
                            <a
                                href={asset.resource_path}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="hover:text-brand-500 text-gray-600"
                            >
                                <span className="font-bold text-xl">
                                    {asset.resource_display_name}
                                </span>
                                &nbsp;
                                <FontAwesomeIcon
                                    icon={faExternalLinkAlt}
                                    className="pb-1"
                                />
                            </a>
                        </div>
                        {asset.account_number && (
                            <span className="text-xs text-gray-500 block mt-1">
                                {asset.account_number}
                            </span>
                        )}
                    </h2>
                </div>

                <div className="flex-none ml-auto ">
                    <div className="flex justify-between text-right">
                        {!!asset.transfers.proposed && (
                            <div className="flex-none ml-8">
                                <div className={`text-xs text-gray-500`}>
                                    Proposed Transfers
                                </div>
                                <div className="text-sm tabular-nums">
                                    Yes{" "}
                                    <span className="text-xs">
                                        ({asset.transfers.proposed.length})
                                    </span>
                                </div>
                            </div>
                        )}
                        <div className="flex-none ml-8">
                            <div className={`text-xs text-gray-500 space-x-1`}>
                                <span>{valueLabel}</span>
                                {isPostMortem && (
                                    <Tooltip
                                        title="Value of asset shown is 100% regardless of percentage included in estate."
                                        position="top"
                                        duration={150}
                                        followCursor={false}
                                        distance={16}
                                    >
                                        <FontAwesomeIcon
                                            icon={faQuestionCircle}
                                            className="text-gray-400"
                                            fixedWidth
                                        />
                                    </Tooltip>
                                )}
                            </div>
                            <div className="text-sm tabular-nums">
                                {asset.is_liability ? (
                                    <span className="text-red-500">
                                        <Money value={value} />
                                    </span>
                                ) : (
                                    <Money value={value} />
                                )}
                                {showProposed &&
                                    parseMoney(
                                        asset.proposed_outgoing_transfers_total_amount
                                    ) > 0 && (
                                        <div className="text-red-400">
                                            -
                                            {
                                                <Money
                                                    value={
                                                        asset.proposed_outgoing_transfers_total_amount
                                                    }
                                                />
                                            }
                                        </div>
                                    )}
                                {showProposed &&
                                    parseMoney(
                                        asset.proposed_incoming_transfers_total_amount
                                    ) > 0 && (
                                        <div className="text-green-400">
                                            +
                                            {
                                                <Money
                                                    value={
                                                        asset.proposed_incoming_transfers_total_amount
                                                    }
                                                />
                                            }
                                        </div>
                                    )}
                            </div>
                        </div>
                        <div className="flex-none ml-8">
                            <div className={`text-xs text-gray-500`}>
                                {asOfDateLabel}
                            </div>
                            <div className="text-sm">{asOfDate}</div>
                        </div>

                        {date && (
                            <div className="flex-none ml-8">
                                <div className={`text-xs text-gray-500`}>
                                    {showProposed
                                        ? "Ownership Proposed"
                                        : "Ownership Verified"}
                                </div>
                                <div className="text-sm">{date}</div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            {asset.has_lien && parseInt(asset.liability_amount) > 0 && (
                <div className="border-b pb-3 mb-2 border-gray-200 text-gray-600">
                    <div className="flex items-center space-x-1 text-sm">
                        <span>Net Equity:</span>
                        <span>{<Money value={gross_value} />}</span>
                        <span>-</span>
                        <span className="text-red-500">
                            {<Money value={balance} />}
                        </span>
                        <span>=</span>
                        <span>
                            <Money value={value} />
                        </span>
                    </div>
                </div>
            )}

            {ownerships.length === 0 && !edit && (
                <>
                    <p>There are currently no ownerships.</p>
                    <div className="inline-block mt-2">
                        <Button
                            onClick={() => {
                                if (allowChangingOwnershipMode) {
                                    setCurrent(true);
                                }
                                setEdit(!edit);
                            }}
                            text="Add Ownerships"
                            appearance="light"
                            height="h-8"
                            disabled={isReadOnly}
                        />
                    </div>
                </>
            )}
            {(ownerships.length > 0 || edit) && (
                <div>
                    {edit && (
                        <>
                            <Ownership
                                asset={asset}
                                resourceType={asset.type}
                                client={client}
                                showProposed={showProposed}
                                onSave={() => {
                                    onSave();
                                    setEdit(false);
                                }}
                            />
                            <div className="ml-auto -mb-4">
                                <Button
                                    onClick={() => setEdit(!edit)}
                                    height="h-8"
                                    appearance="none"
                                    text="Cancel Edit"
                                    className="p-1 text-sm text-gray-700 hover:text-brand-500"
                                />
                            </div>
                        </>
                    )}
                    {edit === false && (
                        <>
                            <div className="space-y-4 divide-y">
                                {ownerships.map((ownership, idx) => {
                                    const showTransferButtonComputed =
                                        showTransferButton &&
                                        ownership?.proposed_percentage_owned >
                                            0;
                                    return (
                                        <div
                                            className="flex flex-col w-full space-y-3"
                                            key={`ownership_${ownership.id}`}
                                        >
                                            <div className="flex space-x-3 items-end mt-4">
                                                <div
                                                    className={`${
                                                        showTransferButtonComputed
                                                            ? "w-5/6"
                                                            : "w-full"
                                                    }`}
                                                >
                                                    <OwnershipLineItemReadOnly
                                                        client={client}
                                                        ownership={ownership}
                                                        showProposed={
                                                            showProposed
                                                        }
                                                        hideLabels={idx > 0}
                                                    />
                                                </div>
                                                {showTransferButtonComputed && (
                                                    <div className="flex-none flex items-center justify-center pb-2 w-1/6">
                                                        <ProposeTransferButton
                                                            asset={asset}
                                                            disabled={
                                                                isReadOnly
                                                            }
                                                            ownership={
                                                                ownership
                                                            }
                                                        />
                                                    </div>
                                                )}
                                            </div>
                                            {showProposed === true && (
                                                <div className="flex flex-col space-y-4">
                                                    {ownership.proposed_transfers_sent.map(
                                                        (transfer) => (
                                                            <div
                                                                className="flex items-center space-x-4"
                                                                key={
                                                                    transfer.id
                                                                }
                                                            >
                                                                <div className="w-5/6">
                                                                    <PendingTransferLineItemReadOnly
                                                                        transfer={
                                                                            transfer
                                                                        }
                                                                    />
                                                                </div>

                                                                <div className="w-1/6">
                                                                    <TransferButtonToolbar
                                                                        showProposed={
                                                                            showProposed
                                                                        }
                                                                        onVerifyTransfer={() => {
                                                                            setVerifyAsset(
                                                                                asset
                                                                            );
                                                                            setVerifyOwnership(
                                                                                ownership
                                                                            );
                                                                            setVerifyTransfer(
                                                                                transfer
                                                                            );
                                                                        }}
                                                                        transfer={
                                                                            transfer
                                                                        }
                                                                        disabled={
                                                                            isReadOnly
                                                                        }
                                                                    />
                                                                </div>
                                                            </div>
                                                        )
                                                    )}
                                                </div>
                                            )}
                                        </div>
                                    );
                                })}
                            </div>
                            {showProposed === false && !isPostMortem && (
                                <div className="ml-auto mt-2 -mb-4">
                                    {asset.proposed_owners.length > 0 ? (
                                        <Button
                                            onClick={() =>
                                                addToast(
                                                    "Pending Verification",
                                                    { type: "error" }
                                                )
                                            }
                                            height="h-8"
                                            appearance="none"
                                            text="Edit Ownerships"
                                            className="p-1 text-sm text-gray-700 hover:text-brand-500"
                                            disabled={false}
                                        />
                                    ) : (
                                        <Button
                                            onClick={() => setEdit(!edit)}
                                            height="h-8"
                                            appearance="none"
                                            text="Edit Ownerships"
                                            className="p-1 text-sm text-gray-700 hover:text-brand-500"
                                            disabled={false}
                                        />
                                    )}
                                </div>
                            )}
                        </>
                    )}
                </div>
            )}
        </Panel>
    );
}
