import React, { useContext, useState, useEffect } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import { useToasts } from "react-toast-notifications";
import Dropdown, { DropdownItem } from "../../../components/Dropdown";
import useDebounce from "../../../hooks/useDebounce";
import { formatDate } from "../../../util/TextUtil";
import SearchInput from "../../../components/SearchInput";
import { Link } from "react-router-dom";
import Api from "../../../services/Api";
import Spinner from "../../../components/Spinner";
import TableSortHeader from "../../../components/TableSortHeader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faTrash,
    faEdit,
    faEllipsisH,
} from "@fortawesome/free-solid-svg-icons";
import Forbidden from "../../../components/Forbidden";
import useFormatPercentage from "../../../hooks/useFormatPercentage";
import { useUserCan } from "hooks/useHasAnyPermissions";

export default function NonMortgageLiabilityTable({ client, clientPath }) {
    const auth = useContext(AuthContext);
    const userType = auth.user.type;
    const { addToast } = useToasts();
    const formatPercentage = useFormatPercentage();
    const canCreateWealthRecords = useUserCan("createWealthRecord");
    const [nonMortgageLiabilities, setNonMortgageLiabilities] = useState([]);
    const [loading, setLoading] = useState(true);
    const [notAuthorized, setNotAuthorized] = useState(false);
    const [needsUpdated, setNeedsUpdated] = useState(0);
    const [search, setSearch] = useState("");
    const debouncedSearch = useDebounce(search, 250);
    const [orderBy, setOrderBy] = useState("balance_as_of_date");
    const [sortDir, setSortDir] = useState("desc");

    // runs like component did mount
    useEffect(() => {
        document.title = "Non-Mortgage Liabilities";
    }, []);

    // runs when items in array are updated
    useEffect(() => {
        const fetchData = async () => {
            try {
                let response = await Api.get(
                    "clients/" + client.id + "/non-mortgage-liabilities",
                    {
                        limit: 20,
                        sort: sortDir === "desc" ? "-" + orderBy : orderBy,
                        "filter[search]": debouncedSearch,
                    }
                );
                setNonMortgageLiabilities(response.data.data);
                setLoading(false);
            } catch (error) {
                if (error.response.status === 403) {
                    setNotAuthorized(true);
                }
            }
        };
        fetchData();
    }, [client, needsUpdated, orderBy, sortDir, debouncedSearch]);

    async function deleteNonMortgageLiability(nonMortgageLiability) {
        try {
            const response = await Api.delete(
                "clients/" +
                    client.id +
                    "/non-mortgage-liabilities/" +
                    nonMortgageLiability.id
            );
            addToast(response.data.message);
            setNeedsUpdated(needsUpdated + 1);
        } catch (error) {
            addToast(error.errorMessage, { type: "error" });
        }
    }

    if (notAuthorized) {
        return <Forbidden />;
    }

    if (loading) {
        return <Spinner />;
    }

    return (
        <>
            <div className="mb-4">
                <SearchInput
                    label={false}
                    searchValue={search}
                    onSearchChange={(e) => setSearch(e.target.value)}
                />
            </div>
            {nonMortgageLiabilities.length ? (
                <>
                    <table className="w-full text-left">
                        <thead>
                            <tr>
                                <TableSortHeader
                                    field="name"
                                    sortField={orderBy}
                                    sortDir={sortDir}
                                    changeSort={(column) => {
                                        setSortDir(
                                            sortDir === "asc" ? "desc" : "asc"
                                        );
                                        setOrderBy(column);
                                    }}
                                >
                                    Description
                                </TableSortHeader>
                                <TableSortHeader
                                    disabled={true}
                                    field="owner"
                                    sortField={orderBy}
                                    sortDir={sortDir}
                                    changeSort={(column) => {
                                        setSortDir(
                                            sortDir === "asc" ? "desc" : "asc"
                                        );
                                        setOrderBy(column);
                                    }}
                                >
                                    Owner
                                </TableSortHeader>
                                <TableSortHeader
                                    disabled={true}
                                    field="percentage_owned"
                                    sortField={orderBy}
                                    sortDir={sortDir}
                                    changeSort={(column) => {
                                        setSortDir(
                                            sortDir === "asc" ? "desc" : "asc"
                                        );
                                        setOrderBy(column);
                                    }}
                                >
                                    Percentage Owned
                                </TableSortHeader>
                                <TableSortHeader
                                    field="balance"
                                    sortField={orderBy}
                                    sortDir={sortDir}
                                    changeSort={(column) => {
                                        setSortDir(
                                            sortDir === "asc" ? "desc" : "asc"
                                        );
                                        setOrderBy(column);
                                    }}
                                >
                                    Balance
                                </TableSortHeader>
                                <TableSortHeader
                                    field="balance_as_of_date"
                                    sortField={orderBy}
                                    sortDir={sortDir}
                                    changeSort={(column) => {
                                        setSortDir(
                                            sortDir === "asc" ? "desc" : "asc"
                                        );
                                        setOrderBy(column);
                                    }}
                                >
                                    As of Date
                                </TableSortHeader>
                                <th className="text-right">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {nonMortgageLiabilities.map(
                                (nonMortgageLiability, idx) => (
                                    <tr key={idx}>
                                        <td>
                                            <Link
                                                to={`${clientPath}/non-mortgage-liabilities/${nonMortgageLiability.id}/view`}
                                            >{`${nonMortgageLiability.name}`}</Link>
                                        </td>
                                        <td>
                                            {nonMortgageLiability.owner_name}
                                        </td>
                                        <td>
                                            {formatPercentage(
                                                nonMortgageLiability.percentage_owned
                                            )}
                                        </td>
                                        <td>${nonMortgageLiability.balance}</td>
                                        <td>
                                            {formatDate(
                                                nonMortgageLiability.balance_as_of_date
                                            )}
                                        </td>
                                        <td className="text-right">
                                            <span>
                                                <Dropdown
                                                    position="right"
                                                    toggleClass="px-3 py-1"
                                                    toggleContent={
                                                        <FontAwesomeIcon
                                                            icon={faEllipsisH}
                                                        />
                                                    }
                                                >
                                                    <DropdownItem
                                                        label="Edit"
                                                        icon={faEdit}
                                                        link={`${clientPath}/non-mortgage-liabilities/${nonMortgageLiability.id}/edit`}
                                                    />
                                                    {(userType === "member" ||
                                                        userType ===
                                                            "client") && (
                                                        <DropdownItem
                                                            label="Delete"
                                                            icon={faTrash}
                                                            handleClick={() =>
                                                                deleteNonMortgageLiability(
                                                                    nonMortgageLiability
                                                                )
                                                            }
                                                        />
                                                    )}
                                                </Dropdown>
                                            </span>
                                        </td>
                                    </tr>
                                )
                            )}
                        </tbody>
                    </table>
                </>
            ) : (
                <div className="text-gray-400">
                    There are no non-mortgage liabilities for this client.{" "}
                    {canCreateWealthRecords && (
                        <Link
                            to={`${clientPath}/non-mortgage-liabilities/add`}
                            className="underline text-gray-600"
                        >
                            Add Now.
                        </Link>
                    )}
                </div>
            )}
        </>
    );
}
