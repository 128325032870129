import React, { useState } from "react";
import Api from "../../services/Api";
import { useToasts } from "react-toast-notifications";
import Button from "../Button";
import OwnershipList from "./OwnershipList";
import TextInput from "../TextInput";
import { formatDate } from "../../util/TextUtil";
import { ownershipTypes } from "../Ownership/OwnershipService";
import { AddOwnershipButton } from "components/Ownership";

export default function Ownership({
    asset,
    client,
    resourceType: initialResourceType,
    showProposed = false,
    disabled = false,
    onSave,
}) {
    const resourceId = asset.resource_id;
    const resourceType = asset.resource_type || initialResourceType;
    const [ownerships, setOwnerships] = useState([]);

    React.useEffect(() => {
        const currentOwnerships = asset.current_owners;
        const proposedOwnerships = asset.proposed_owners;
        if (showProposed && proposedOwnerships.length) {
            setOwnerships(proposedOwnerships);
        } else {
            if (currentOwnerships.length) {
                setOwnerships(currentOwnerships);
            }
        }
    }, [client, showProposed, asset]);

    const { addToast } = useToasts();
    const [errors, setErrors] = useState([]);
    const [verifiedAt, setVerifiedAt] = useState(
        formatDate(new Date(), "", {
            format: "Y-MM-DD",
        })
    );
    const [saving, setSaving] = useState(false);

    function getRemainingPercentageOwned() {
        return (
            100 -
            ownerships.reduce((a, b) => {
                return a + parseFloat(b.percentage_owned) || 0;
            }, 0)
        );
    }

    function appendOwnership() {
        let newPercentageOwned = getRemainingPercentageOwned();
        if (ownerships.length === 1) {
            updatePercentageOwned(0, ownerships[0].percentage_owned / 2);
            newPercentageOwned = ownerships[0].percentage_owned / 2;
        } else {
            newPercentageOwned = 100;
        }
        let newOwnerships = [
            ...ownerships,
            {
                type: "OWNER",
                owner_id: client.id,
                owner_type: "Client",
                percentage_owned: newPercentageOwned,
                resource_id: resourceId,
                resource_type: resourceType,
            },
        ];
        setOwnerships(newOwnerships);
    }

    function updatePercentageOwned(idx, value) {
        setOwnerships((ownerships) =>
            ownerships.map((ownership, idxs) => {
                if (idxs === idx) {
                    ownership.percentage_owned = value;
                }
                return ownership;
            })
        );
    }

    function removeOwnership(idx) {
        let deletedPercentage = 0;
        let newOwnerships = ownerships.filter((owner, i) => i !== idx);
        setOwnerships(() => newOwnerships);
        setErrors([]);
        updatePercentageOwned(
            0,
            Math.min(
                parseInt(ownerships[0].percentage_owned) +
                    parseInt(deletedPercentage),
                100
            )
        );
    }

    async function save() {
        try {
            setSaving(true);

            let payload = {
                resource_id: resourceId,
                resource_type: resourceType,
                verified_at: verifiedAt,
                ownerships,
                propose: showProposed,
                is_initial: true,
            };
            let response = await Api.post(
                "clients/" + client.id + "/ownerships",
                payload
            );
            addToast(response.data.message);
            setErrors([]);
            setSaving(false);
            onSave();
        } catch (e) {
            console.error(e);
            setSaving(false);
            setErrors([]);
            if (e.response) {
                if (e.response.data.errors) {
                    setErrors(e.response.data.errors);
                } else {
                    addToast(e.response.data.message, { type: "error" });
                }
            }
        }
    }

    return (
        <>
            <div className="w-full">
                <OwnershipList
                    asset={asset}
                    client={client}
                    errors={errors}
                    setErrors={setErrors}
                    disabled={disabled}
                    ownerships={ownerships}
                    setOwnerships={setOwnerships}
                    ownershipTypes={ownershipTypes}
                    removeOwnership={removeOwnership}
                />
                <div>
                    {!disabled && (
                        <AddOwnershipButton
                            ownerships={ownerships}
                            appendOwnership={appendOwnership}
                            tabIndex={
                                getRemainingPercentageOwned() > 0 ? 0 : -1
                            }
                        />
                    )}
                    {!disabled && (
                        <div className="w-full flex items-center justify-end mt-3 space-x-3 border-t pt-6">
                            {ownerships.length > 0 && (
                                <div className="flex items-center space-x-3">
                                    <div>
                                        <div className="flex items-center space-x-3">
                                            <div>
                                                <TextInput
                                                    type="date"
                                                    name="verified_at"
                                                    value={verifiedAt}
                                                    onChange={(e) =>
                                                        setVerifiedAt(
                                                            e.target.value
                                                        )
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="w-40">
                                        <Button
                                            onClick={save}
                                            isLoading={saving}
                                            disabled={disabled || !verifiedAt}
                                            text="Save"
                                        />
                                    </div>
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </div>
        </>
    );
}
