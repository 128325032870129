import React from "react";
import Checkbox from "components/Checkbox";
import InsuredSelect from "components/InsuredSelect";
import FrequencySelect from "components/FrequencySelect";
import ManagedByAdvisor from "components/ManagedByAdvisor";
import InsurancePoliciesLayout from "./InsurancePoliciesLayout";
import useFormatCurrency from "hooks/useFormatCurrency";
import TextInput, { DataList } from "components/TextInput";
import SaveButton from "components/SaveButton";

export default function EditInsurancePolicyInfo({
    client,
    input,
    errors,
    readOnly = false,
    updateValue,
    onSubmit,
    saving,
    currentBasePath,
    title,
}) {
    const formatCurrency = useFormatCurrency();

    return (
        <InsurancePoliciesLayout
            currentBasePath={currentBasePath}
            title={title}
            buttons={
                <SaveButton
                    onClick={onSubmit}
                    isLoading={saving}
                    readOnly={readOnly}
                />
            }
        >
            <form onSubmit={onSubmit} className="max-w-3xl mx-auto">
                <div>
                    <div className="mb-6">
                        <TextInput
                            type="name"
                            name="name"
                            label="Policy Name"
                            id="name"
                            readOnly={readOnly}
                            disabled={readOnly}
                            value={input.name}
                            error={errors.name}
                            onChange={updateValue}
                            required={true}
                            autoFocus={true}
                        />
                    </div>

                    <div className="mb-6">
                        <TextInput
                            name="number"
                            label="Policy Number"
                            id="number"
                            value={input.number}
                            readOnly={readOnly}
                            disabled={readOnly}
                            error={errors.number}
                            onChange={updateValue}
                        />
                    </div>

                    <div className="mb-6">
                        <DataList
                            type="type"
                            name="type"
                            label="Policy Type"
                            id="type"
                            disabled={readOnly}
                            value={input.type}
                            error={errors.type}
                            onChange={updateValue}
                            list={[
                                "AD&D",
                                "Group Life",
                                "Split Dollar",
                                "Term",
                                "Universal Life",
                                "Variable Insurance",
                                "Whole Life",
                            ]}
                        />
                    </div>

                    <div className="mb-6">
                        <TextInput
                            name="provider_name"
                            label="Insurance Company Name"
                            id="provider_name"
                            value={input.provider_name}
                            readOnly={readOnly}
                            disabled={readOnly}
                            error={errors.provider_name}
                            onChange={updateValue}
                            required={true}
                        />
                    </div>

                    <div className="mb-6">
                        <InsuredSelect
                            client={client}
                            value={input.insured}
                            disabled={readOnly}
                            name="insured"
                            label="Who Is Insured?"
                            onChange={updateValue}
                        />
                    </div>

                    {input.insured === "other" && (
                        <div className="mb-6">
                            <TextInput
                                name="other_insured"
                                label="Other Insured"
                                id="other_insured"
                                value={input.other_insured}
                                readOnly={readOnly}
                                disabled={readOnly}
                                error={errors.other_insured}
                                onChange={updateValue}
                                autoFocus={input.insured === "other"}
                            />
                        </div>
                    )}

                    {(input.type === "Group Life" ||
                        input.type === "Universal Life" ||
                        input.type === "Whole Life" ||
                        input.type === "Term") && (
                        <fieldset className="mt-12">
                            <legend className="font-bold txt-md text-gray-500 my-6 pb-1 border-b block w-full">
                                Long Term Care Rider Policy
                            </legend>
                            <Checkbox
                                id="long_term_care_rider"
                                name="long_term_care_rider"
                                disabled={readOnly}
                                defaultChecked={input.long_term_care_rider}
                                onChange={updateValue}
                                label="Is there a Long Term Care rider policy?"
                            />
                            {parseInt(input.long_term_care_rider) === 1 && (
                                <div className="flex space-x-4 my-6">
                                    <div className="flex-1">
                                        <TextInput
                                            name="long_term_care_amount"
                                            label="What is the amount of the monthly benefit?"
                                            id="long_term_care_amount"
                                            value={input.long_term_care_amount}
                                            readOnly={readOnly}
                                            disabled={readOnly}
                                            error={errors.long_term_care_amount}
                                            onChange={updateValue}
                                            autoFocus={
                                                parseInt(
                                                    input.long_term_care_rider
                                                ) === 1
                                            }
                                        />
                                    </div>

                                    <div className="flex-1">
                                        <TextInput
                                            type="date"
                                            name="long_term_care_term"
                                            label="What is the term of the benefit?"
                                            id="long_term_care_term"
                                            readOnly={readOnly}
                                            disabled={readOnly}
                                            value={input.long_term_care_term}
                                            error={errors.long_term_care_term}
                                            onChange={updateValue}
                                        />
                                    </div>
                                </div>
                            )}
                        </fieldset>
                    )}

                    <fieldset className="mt-12">
                        <legend className="font-bold txt-md text-gray-500 my-6 pb-1 border-b block w-full">
                            Policy Amounts
                        </legend>

                        <div className="flex space-x-4 mb-6">
                            <div className="flex-1">
                                <TextInput
                                    name="value"
                                    label="Cash Value"
                                    id="value"
                                    readOnly={readOnly}
                                    disabled={readOnly}
                                    value={input.value}
                                    error={errors.value}
                                    onChange={updateValue}
                                    onBlur={(e) => {
                                        e.target.value = formatCurrency(
                                            e.target.value
                                        );
                                        updateValue(e);
                                    }}
                                    placeholder="0.00"
                                />
                            </div>

                            <div className="flex-1">
                                <TextInput
                                    type="date"
                                    name="as_of_date"
                                    label="As of Date"
                                    readOnly={readOnly}
                                    disabled={readOnly}
                                    id="as_of_date"
                                    value={input.as_of_date}
                                    error={errors.as_of_date}
                                    onChange={updateValue}
                                />
                            </div>
                        </div>

                        <div className="flex space-x-4 mb-6">
                            <div className="flex-1">
                                <TextInput
                                    name="death_benefit"
                                    label="Death Benefit"
                                    id="death_benefit"
                                    readOnly={readOnly}
                                    disabled={readOnly}
                                    value={input.death_benefit}
                                    error={errors.death_benefit}
                                    onChange={updateValue}
                                    onBlur={(e) => {
                                        e.target.value = formatCurrency(
                                            e.target.value
                                        );
                                        updateValue(e);
                                    }}
                                    placeholder="0.00"
                                />
                            </div>
                        </div>

                        <div className="flex space-x-4 mb-6">
                            <div className="flex-1">
                                <TextInput
                                    name="premium_amount"
                                    label="Premium Amount"
                                    id="premium_amount"
                                    value={input.premium_amount}
                                    readOnly={readOnly}
                                    disabled={readOnly}
                                    error={errors.premium_amount}
                                    onChange={updateValue}
                                    onBlur={(e) => {
                                        e.target.value = formatCurrency(
                                            e.target.value
                                        );
                                        updateValue(e);
                                    }}
                                    placeholder="0.00"
                                />
                            </div>
                            <div className="flex-1">
                                <FrequencySelect
                                    name="frequency_id"
                                    label="Payment Frequency"
                                    id="frequency_id"
                                    disabled={readOnly}
                                    value={input.frequency_id}
                                    error={errors.frequency_id}
                                    onChange={updateValue}
                                    defaultOption="Choose a frequency..."
                                    defaultOptionValue={0}
                                    defaultOptionDisabled={true}
                                    required={true}
                                />
                            </div>
                        </div>

                        <div className="mb-6">
                            <TextInput
                                name="loan_amount"
                                label="Loan Amount"
                                id="loan_amount"
                                value={input.loan_amount}
                                readOnly={readOnly}
                                disabled={readOnly}
                                error={errors.loan_amount}
                                onChange={updateValue}
                                onBlur={(e) => {
                                    e.target.value = formatCurrency(
                                        e.target.value
                                    );
                                    updateValue(e);
                                }}
                                placeholder="0.00"
                            />
                        </div>
                    </fieldset>

                    <fieldset className="mt-12">
                        <legend className="font-bold txt-md text-gray-500 my-6 pb-1 border-b block w-full">
                            Managed By
                        </legend>
                        <ManagedByAdvisor
                            client={client}
                            input={input}
                            disabled={readOnly}
                            errors={errors}
                            updateValue={updateValue}
                        />
                    </fieldset>
                    <input type="submit" hidden />
                </div>
            </form>
        </InsurancePoliciesLayout>
    );
}
