import React, { useState, useEffect } from "react";
import Api from "../../../services/Api";
import { useToasts } from "react-toast-notifications";
import Dropdown, { DropdownItem } from "../../../components/Dropdown";
import {
    faBan,
    faEnvelope,
    faEllipsisH,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import EmptyState from "../../../components/EmptyState";
import { formatDate } from "../../../util/TextUtil";

const PendingClientsTable = ({ title }) => {
    document.title = title || "Pending Clients";
    const { addToast } = useToasts();
    const [invitations, setInviations] = useState([]);

    useEffect(() => {
        const fetchData = async () => fetchInvitations();
        fetchData();
    }, []);

    async function fetchInvitations() {
        let invitations = await Api.get("invitations", {
            "filter[type]": "client",
            "filter[trashed]": "with",
        });
        setInviations(invitations.data.data);
    }

    async function resendInvitation(invitationEmail, invitationId) {
        try {
            const response = await Api.post("invitations", {
                invitees: [
                    { email: invitationEmail, invitation_id: invitationId },
                ],
            });
            addToast(response.data.message);
            await fetchInvitations();
        } catch (error) {
            addToast(error.response.data.message, { type: "error" });
        }
    }

    async function revokeInvitation(invitationId) {
        const response = await Api.delete("invitations/" + invitationId);
        addToast(response.data.message);
        await fetchInvitations();
    }

    if (invitations.length < 1) {
        return <EmptyState>No clients found.</EmptyState>;
    }

    return (
        <table className="w-full text-left">
            <thead>
                <tr>
                    <th>Email</th>
                    <th>Invited On</th>
                    <th>Expires On</th>
                    <th>Role</th>
                    <th>Status</th>
                    <th className="text-right"></th>
                </tr>
            </thead>
            <tbody>
                {invitations.map((invitation, idx) => (
                    <tr
                        key={idx}
                        className={invitation.deleted_at ? "text-gray-400" : ""}
                    >
                        <td>{invitation.email}</td>
                        <td>{formatDate(invitation.created_at)}</td>
                        <td>{formatDate(invitation.expires_at)}</td>
                        <td>{invitation.role_display}</td>
                        <td className="w-12">
                            {invitation.deleted_at ? (
                                <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium leading-4 bg-red-100 text-red-800">
                                    Revoked
                                </span>
                            ) : (
                                <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium leading-4 bg-green-100 text-green-800">
                                    Pending
                                </span>
                            )}
                        </td>
                        <td className="text-right">
                            <Dropdown
                                position="right"
                                toggleClass="px-3 py-1"
                                toggleContent={
                                    <FontAwesomeIcon icon={faEllipsisH} />
                                }
                            >
                                <DropdownItem
                                    label="Resend Invitation"
                                    icon={faEnvelope}
                                    handleClick={() =>
                                        resendInvitation(
                                            invitation.email,
                                            invitation.id
                                        )
                                    }
                                />
                                {!invitation.deleted_at && (
                                    <DropdownItem
                                        label="Revoke"
                                        icon={faBan}
                                        handleClick={() =>
                                            revokeInvitation(invitation.id)
                                        }
                                    />
                                )}
                            </Dropdown>
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
};

export default PendingClientsTable;
