import React from "react";
import { Link } from "react-router-dom";

const ResourceNotFound = ({ backUrl = "/" }) => {
    return (
        <div className="h-full flex items-center justify-center">
            <div className="text-center">
                <h1 className="block text-4xl font-light text-gray-600">404</h1>
                <p>The item you are looking for could not be found.</p>
                <Link to={backUrl} className="block mt-3 text-brand underline">
                    Go back
                </Link>
            </div>
        </div>
    );
};
export default ResourceNotFound;
