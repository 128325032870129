import React from "react";

export default function Percentage({ value }) {
    let dividedValue = value / 100;
    let valueFormatted = dividedValue.toLocaleString("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
        style: "percent",
    });
    return <span>{valueFormatted}</span>;
}
