import React, { useState } from "react";
import BasicModal from "components/ModalBasic";
import Button from "components/Button";
import TextInput from "components/TextInput";
import useForm from "hooks/useForm";
import Modal from "components/Modal";
import useFormatCurrency from "hooks/useFormatCurrency";
import useFormatPercentage from "hooks/useFormatPercentage";
import Api from "services/Api";
import { useToasts } from "react-toast-notifications";
import { parseMoney } from "components/Money";
import { useQueryClient } from "react-query";

const ModalMessage = ({ transferType }) => (
    <>
        <div className="pt-4 text-red-500">
            <strong>This cannot be undone.</strong>
        </div>
        <br />
        <div>
            {transferType === "ownership"
                ? "Existing ownership will be restored, but the asset value will not be restored. Please check both Asset Ownership and Asset Value after deletion."
                : "Value transfers will be restored. Please check and modify DOD Value if need."}
        </div>
    </>
);

export default function EditTransferModal({
    client,
    transfer,
    destination,
    isOpen,
    onClose,
}) {
    const { input, setInput, updateValue, resetInput } = useForm(destination);

    const queryClient = useQueryClient();

    const { addToast } = useToasts();
    const [showDelete, setShowDelete] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const [isSaving, setIsSaving] = useState(false);

    const formatCurrency = useFormatCurrency();
    const formatPercentage = useFormatPercentage();

    const patchTransfer = async () => {
        setIsSaving(true);
        try {
            let {
                transfer_amount,
                transfer_percentage,
                verified_at,
                proposed_at,
                original_asset_as_of_date,
                original_asset_value,
            } = input;

            transfer_percentage = parseMoney(transfer_percentage);
            transfer_amount = parseMoney(transfer_amount);
            original_asset_value = parseMoney(original_asset_value);
            await Api.patch(
                `clients/${client.id}/transfers/${destination.id}`,
                {
                    transfer_amount,
                    transfer_percentage,
                    verified_at,
                    proposed_at,
                    original_asset_as_of_date,
                    original_asset_value,
                }
            );
            onClose();
            addToast("Transfer Updated");
            queryClient.removeQueries({ active: true });
            queryClient.refetchQueries({ active: true });
            return true;
        } catch (err) {
            setIsSaving(false);
            if (err?.response?.data?.message) {
                addToast(err?.response?.data?.message, { type: "error" });
            }
            console.error(err);
        }
        return false;
    };

    const deleteTransfer = async () => {
        setShowDelete(false);
        setIsDeleting(true);
        try {
            await Api.delete(
                `clients/${client.id}/transfers/${destination.id}`
            );
            onClose();
            addToast("Transfer Reset");
            queryClient.removeQueries({ active: true });
            queryClient.refetchQueries({ active: true });
            return true;
        } catch (err) {
            setIsDeleting(false);
            if (err?.response?.data?.message) {
                addToast(err?.response?.data?.message, { type: "error" });
            }
            console.error(err);
        }
        return false;
    };

    React.useEffect(() => {
        resetInput();
        setIsDeleting(false);
        setIsSaving(false);
    }, [isOpen]);

    React.useEffect(() => {
        if (destination) {
            setInput({
                ...destination,
                transfer_percentage:
                    formatCurrency(destination.transfer_percentage || 0) + "%",
            });
        }
    }, [destination]);

    const errors = {};

    return (
        <>
            <BasicModal isOpen={isOpen} size="3xl" onClose={onClose}>
                <>
                    <div className="sticky top-0 z-10 mb-6 flex items-center justify-between border-b border-gray-300 py-3 bg-white">
                        <h1 className="text-2xl font-bold">Edit Transfer</h1>
                        <div className="text-right">
                            <div className="font-bold">
                                {transfer?.asset_type}
                            </div>
                            <div>{transfer?.asset_name}</div>
                            <div className="text-xs">
                                {transfer?.asset?.account_number ||
                                    transfer?.asset?.number}
                            </div>
                        </div>
                    </div>
                    <div className="">
                        <div className="flex items-end space-x-3 mb-6">
                            <div className="flex-1">
                                <TextInput
                                    type="text"
                                    label="$ Transfer Amount"
                                    name="transfer_amount"
                                    id={`transfer_amount`}
                                    value={input?.transfer_amount}
                                    required={true}
                                    placeholder="0.00"
                                    error={errors.transfer_amount}
                                    onChange={updateValue}
                                    tooltip="Once an asset is transferred, the DOD Value must also be modified separately, in addition to the value transferred."
                                    onBlur={(e) => {
                                        e.target.value = formatCurrency(
                                            e.target.value
                                        );

                                        updateValue(e);
                                    }}
                                />
                            </div>
                            {transfer?.transfer_type === "ownership" && (
                                <div className="flex-1">
                                    <TextInput
                                        name="transfer_percentage"
                                        label="Transfer Percent"
                                        id="transfer_percentage"
                                        value={input?.transfer_percentage}
                                        error={errors.transfer_percentage}
                                        onChange={updateValue}
                                        onBlur={(e) => {
                                            e.target.value = formatPercentage(
                                                e.target.value
                                            );

                                            updateValue(e);
                                        }}
                                    />
                                </div>
                            )}
                        </div>
                        <div className="flex items-end space-x-3 mb-6">
                            <div className="flex-1">
                                <TextInput
                                    name="proposed_at"
                                    label="Date Proposed"
                                    id="proposed_at"
                                    type="date"
                                    value={input?.proposed_at}
                                    error={errors.proposed_at}
                                    onChange={updateValue}
                                />
                            </div>
                            <div className="flex-1">
                                <TextInput
                                    name="verified_at"
                                    label="Date Verified"
                                    id="verified_at"
                                    type="date"
                                    value={input?.verified_at}
                                    error={errors.verified_at}
                                    onChange={updateValue}
                                />
                            </div>
                        </div>
                        <div className="flex items-end space-x-3 mb-6">
                            <div className="flex-1">
                                <TextInput
                                    type="text"
                                    label="Original Asset Value"
                                    name="original_asset_value"
                                    id={`original_asset_value`}
                                    value={input?.original_asset_value}
                                    required={true}
                                    placeholder="0.00"
                                    error={errors.original_asset_value}
                                    onChange={updateValue}
                                    tooltip="Once an asset is transferred, the DOD Value must also be modified separately, in addition to the value transferred."
                                    onBlur={(e) => {
                                        e.target.value = formatCurrency(
                                            e.target.value
                                        );

                                        updateValue(e);
                                    }}
                                />
                            </div>
                            <div className="flex-1">
                                <TextInput
                                    type="date"
                                    label="Original Asset As Of Date"
                                    name="original_asset_as_of_date"
                                    id={`original_asset_as_of_date`}
                                    value={input?.original_asset_as_of_date}
                                    required={true}
                                    error={errors.original_asset_as_of_date}
                                    onChange={updateValue}
                                    onBlur={updateValue}
                                />
                            </div>
                        </div>

                        <hr className="mb-4" />
                    </div>

                    <div className="flex items-center space-x-3 justify-between">
                        <div>
                            <Button
                                onClick={() => setShowDelete(true)}
                                appearance="danger"
                                isLoading={false}
                                disabled={false}
                                text="Reset Transfer"
                            />
                        </div>
                        <div className="flex space-x-4">
                            <div className="w-40">
                                <Button
                                    onClick={onClose}
                                    appearance="outline"
                                    isLoading={false}
                                    disabled={false}
                                    text="Cancel"
                                />
                            </div>
                            <div className="w-40">
                                <Button
                                    onClick={patchTransfer}
                                    isLoading={isSaving}
                                    disabled={isSaving}
                                    text="Save Transfer"
                                />
                            </div>
                        </div>
                    </div>
                </>
            </BasicModal>
            <ConfirmDelete
                title="Reset Transfer?"
                message={() => (
                    <ModalMessage transferType={input?.transfer_type} />
                )}
                isOpen={showDelete}
                isLoading={isDeleting}
                onClose={() => setShowDelete(false)}
                onDelete={deleteTransfer}
            />
        </>
    );
}

function ConfirmDelete({
    title = "Are you sure?",
    message = 'This cannot be undone. Type in "reset" to confirm',
    isLoading = false,
    onClose,
    onDelete,
    isOpen,
}) {
    const [modalError, setModalError] = React.useState([]);

    return (
        <Modal
            isOpen={isOpen}
            type="warning"
            title={title}
            body={message}
            inputType="text"
            isLoading={isLoading}
            inputErrors={modalError}
            inputPlaceholder="Type in 'reset' to confirm"
            doneButtonTitle="Reset"
            doneButtonAppearance="danger"
            onResult={async (result) => {
                setModalError([]);
                if (result.result !== "reset") {
                    if (typeof result.result === "string") {
                        setModalError([
                            'Please confirm deletion by typing in "reset"',
                        ]);
                        return;
                    }
                    onClose();
                    return;
                }
                if (await onDelete()) {
                    onClose();
                }
            }}
        />
    );
}
