import React, { useEffect, useState } from "react";
import PageHeader from "../../components/PageHeader";
import PageContainer from "../../components/PageContainer";
import CollaboratingAdvisorNav from "./_partials/CollaboratingAdvisorNav";
import TableSortHeader from "../../components/TableSortHeader";
import useDebounce from "../../hooks/useDebounce";
import useApi from "../../hooks/useApi";
import { Redirect } from "react-router-dom";
import { formatDate } from "../../util/TextUtil";
import Dropdown, { DropdownItem } from "../../components/Dropdown";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faEllipsisH,
    faEdit,
    faEnvelope,
} from "@fortawesome/free-solid-svg-icons";
import Pagination from "../../components/Pagination";
import SearchInput from "../../components/SearchInput";

export default function CollaboratingAdvisorClientList({ advisor, ...props }) {
    const [clients, setClients] = useState([]);
    const [meta, setMeta] = useState([]);
    const [needsUpdated, setNeedsUpdated] = useState(false);
    const [search, setSearch] = useState("");
    const debouncedSearch = useDebounce(search, 250);
    const [page, setPage] = useState(1);
    const [orderBy, setOrderBy] = useState("last_name");
    const [sortDir, setSortDir] = useState("asc");
    const [redirectTo, setRedirectTo] = useState(false);
    const { api } = useApi();

    useEffect(() => {
        document.title = "Collaborating Advisor Client List";
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            try {
                let response = await api(
                    "get",
                    `collaborating-advisors/${advisor.id}/clients`,
                    {
                        page: page,
                        limit: 20,
                        sort: sortDir === "desc" ? "-" + orderBy : orderBy,
                        "filter[search]": debouncedSearch,
                    }
                );
                setMeta(response.data.meta);
                setClients(response.data.data);
                setNeedsUpdated(false);
            } catch (error) {
                console.log(error);
            }
        };
        fetchData();
    }, [
        debouncedSearch,
        needsUpdated,
        page,
        sortDir,
        orderBy,
        api,
        advisor.id,
    ]);

    function visitLink(e, client, link) {
        e.preventDefault();
        if (client.org_subdomain) {
            return (window.location.href = `${client.org_url}${link}`);
        } else {
            setRedirectTo(link);
        }
    }

    if (redirectTo) {
        return <Redirect to={redirectTo} />;
    }

    return (
        <>
            <PageHeader
                text={advisor.full_name}
                backTo="/collaborating-advisors"
                backText="Collaborating Advisors"
            />
            <PageContainer>
                <div className="flex space-x-10">
                    <div className="flex-initial">
                        <CollaboratingAdvisorNav
                            active={props.history.location.pathname}
                            advisor={advisor}
                        />
                    </div>
                    <div className="flex-1">
                        <div className="mb-4">
                            <SearchInput
                                label={false}
                                searchValue={search}
                                onSearchChange={(e) =>
                                    setSearch(e.target.value)
                                }
                            />
                        </div>
                        {clients.length > 0 && (
                            <>
                                <table className="w-full text-left">
                                    <thead>
                                        <tr>
                                            <TableSortHeader
                                                field="full_name"
                                                sortField={orderBy}
                                                sortDir={sortDir}
                                                changeSort={(column) => {
                                                    setSortDir(
                                                        sortDir === "asc"
                                                            ? "desc"
                                                            : "asc"
                                                    );
                                                    setOrderBy(column);
                                                }}
                                            >
                                                Name
                                            </TableSortHeader>
                                            <TableSortHeader
                                                field="attorney_last_name"
                                                sortField={orderBy}
                                                sortDir={sortDir}
                                                changeSort={(column) => {
                                                    setSortDir(
                                                        sortDir === "asc"
                                                            ? "desc"
                                                            : "asc"
                                                    );
                                                    setOrderBy(column);
                                                }}
                                            >
                                                Attorney
                                            </TableSortHeader>
                                            <th>Spouse</th>
                                            <TableSortHeader
                                                field="org_user.created_at"
                                                sortField={orderBy}
                                                sortDir={sortDir}
                                                changeSort={(column) => {
                                                    setSortDir(
                                                        sortDir === "asc"
                                                            ? "desc"
                                                            : "asc"
                                                    );
                                                    setOrderBy(column);
                                                }}
                                            >
                                                Joined
                                            </TableSortHeader>
                                            <th className="text-right">
                                                Actions
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {clients.map((client, idx) => (
                                            <tr
                                                key={idx}
                                                className={
                                                    client.deleted_at ? "" : ""
                                                }
                                            >
                                                <td>
                                                    <a
                                                        href="#editClient"
                                                        onClick={(e) =>
                                                            visitLink(
                                                                e,
                                                                client,
                                                                `/clients/${client.id}/dashboard`
                                                            )
                                                        }
                                                        className="hover:text-brand-600"
                                                    >
                                                        {client.full_name}
                                                    </a>
                                                    <div className="text-sm text-gray-500">
                                                        {client.email}
                                                    </div>
                                                </td>
                                                <td>
                                                    {client.attorney_first_name}{" "}
                                                    {client.attorney_last_name}
                                                </td>
                                                <td>
                                                    {client.spouse_full_name ||
                                                        "N/A"}
                                                </td>
                                                <td>
                                                    <div>
                                                        {formatDate(
                                                            client.created_at
                                                        )}
                                                    </div>
                                                    <div className="text-sm text-gray-500">
                                                        {formatDate(
                                                            client.created_at
                                                        )}
                                                    </div>
                                                </td>
                                                <td className="text-right">
                                                    <span>
                                                        <Dropdown
                                                            position="right"
                                                            toggleClass="px-3 py-1 text-gray-500"
                                                            toggleContent={
                                                                <FontAwesomeIcon
                                                                    icon={
                                                                        faEllipsisH
                                                                    }
                                                                />
                                                            }
                                                        >
                                                            <DropdownItem
                                                                label="Edit"
                                                                icon={faEdit}
                                                                handleClick={(
                                                                    e
                                                                ) =>
                                                                    visitLink(
                                                                        e,
                                                                        client,
                                                                        `/clients/${client.id}/dashboard`
                                                                    )
                                                                }
                                                            />
                                                            <DropdownItem
                                                                label="Messages"
                                                                icon={
                                                                    faEnvelope
                                                                }
                                                                handleClick={(
                                                                    e
                                                                ) =>
                                                                    visitLink(
                                                                        e,
                                                                        client,
                                                                        `/clients/${client.id}/messages`
                                                                    )
                                                                }
                                                            />
                                                        </Dropdown>
                                                    </span>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                                <Pagination {...meta} nextPage={setPage} />
                            </>
                        )}
                    </div>
                </div>
            </PageContainer>
        </>
    );
}
