import React from "react";
import ClientPageHeader, { Heading } from "../../components/ClientPageHeader";
import PageContainer from "../../components/PageContainer";
import AdvisorProfileForm from "./_partials/AdvisorProfileForm";
import Button from "../../components/Button";
import AdvisorNav from "./_partials/AdvisorNav";
import { formatDate } from "../../util/TextUtil";
import { restoreClientAdvisor } from "../../services/AdvisorService";
import Badge from "components/Badge";
import { isBool } from "index";
import ProfilePhoto from "views/members/_partials/ProfilePhoto";
import useHasAnyPermissions from "hooks/useHasAnyPermissions";

export default function ViewAdvisor({
    client,
    clientPath = null,
    advisor,
    ...props
}) {
    document.title = advisor.full_name
        ? `View Advisor: ${advisor.full_name}`
        : "View Advisor";

    const canEditAdvisor = useHasAnyPermissions([
        "client_related_accounts_administration",
        "firm_client_related_accounts_administration",
    ]);

    return (
        <>
            <ClientPageHeader client={client}>
                <Heading
                    backText="All Advisors"
                    backTo={`${clientPath}/advisors`}
                >
                    <div className="space-x-3">
                        <span>View Profile - {advisor.full_name}</span>
                        {isBool(advisor.can_collaborate) && (
                            <Badge color="green">Collaborator</Badge>
                        )}
                    </div>
                </Heading>
                {canEditAdvisor && (
                    <div className="flex-0">
                        <Button
                            type="link"
                            to={`${clientPath}/advisors/${advisor.id}/edit`}
                            text="Edit Advisor"
                        />
                    </div>
                )}
            </ClientPageHeader>
            <PageContainer>
                {advisor.disabled_at && (
                    <div className="flex items-center bg-red-500 text-white p-6 py-3">
                        This advisor was disabled on{" "}
                        {formatDate(advisor.disabled_at)}
                        <button
                            type="button"
                            className="ml-auto px-3 py-1 undeline bg-red-400 rounded hover:bg-red-600"
                            onClick={() =>
                                restoreClientAdvisor(client.id, advisor.id)
                            }
                        >
                            Restore
                        </button>
                    </div>
                )}
                <div className="flex space-x-10">
                    {canEditAdvisor && (
                        <div className="flex-initial">
                            <AdvisorNav
                                clientPath={clientPath}
                                active={props.history.location.pathname}
                                advisor={advisor}
                            />
                        </div>
                    )}
                    <div className="flex-1">
                        <form className="mx-auto">
                            <AdvisorProfileForm
                                advisor={advisor}
                                updateValue={() => {}}
                                errors={[]}
                                readonly={true}
                            />
                        </form>
                    </div>
                    <div className="flex-initial">
                        <ProfilePhoto
                            memberId={advisor.id}
                            photo={advisor.profile_photo}
                            upload={console.log}
                            readOnly={true}
                        />
                    </div>
                </div>
            </PageContainer>
        </>
    );
}
