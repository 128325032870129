import React from "react";

const Checkbox = ({
    type = "checkbox",
    label = "",
    appearance = "inline",
    onChange,
    inputClass = "",
    labelClass = "",
    ...props
}) => {
    const styles = {
        inline: "inline-flex items-center group",
        vertical:
            "inline-flex flex-col-reverse items-center justify-end w-full group",
    };
    type = type === "radio" ? "radio" : "checkbox";
    return (
        <div className={styles[appearance]}>
            <input
                {...props}
                type={type}
                onChange={(e) => {
                    if (type === "checkbox")
                        e.target.value = e.target.checked ? 1 : 0;
                    onChange(e);
                }}
                tabIndex={props.tabindex || 1}
                className={`form-checkbox h-4 w-4 text-brand-600 transition duration-150 ease-in-out ${
                    props.disabled
                        ? "opacity-50 cursor-not-allowed"
                        : "opacity-100 cursor-pointer"
                } ${inputClass}`}
            />
            {label && (
                <label
                    htmlFor={props.id}
                    className={`ml-2 block text-sm leading-5 text-brand-900 group-hover:text-brand-800 ${
                        props.disabled
                            ? "cursor-not-allowed"
                            : "opacity-100 cursor-pointer"
                    } ${labelClass}`}
                >
                    {label}
                </label>
            )}
        </div>
    );
};

export const Radio = ({ label, ...props }) => {
    return (
        <div className="inline-flex items-center">
            <input
                type="radio"
                tabIndex={props.tabindex || 1}
                className="form-radio h-4 w-4 text-brand-600 transition duration-150 ease-in-out"
                {...props}
            />
            {label && (
                <label
                    htmlFor={props.id}
                    className="ml-2 block text-sm leading-5 text-brand-900"
                >
                    {label}
                </label>
            )}
        </div>
    );
};

export default Checkbox;
