import React from "react";
import Spinner from "./Spinner";

export default function Table(props) {
    if (props.loading) {
        return <Spinner />;
    }

    return (
        <div className="max-w-screen overflow-auto">
            <table className="table-auto w-full text-black-500 text-left">
                {props.children}
            </table>
        </div>
    );
}

export function TableHeader(props) {
    return (
        <thead>
            <tr>{props.children}</tr>
        </thead>
    );
}

export function TableBody(props) {
    return <tbody>{props.children}</tbody>;
}

export function TableRow({ className, ...props }) {
    return (
        <tr className={`border-b border-light-500 ` + className} {...props}>
            {props.children}
        </tr>
    );
}

export function TableCell(props) {
    return <td className={`px-4 py-4 ` + props.className}>{props.children}</td>;
}
