import Api from "./Api";

export const fetchRelationships = async function ({ queryKey }) {
    const [, { ...params }] = queryKey;
    return await Api.get(
        "clients/" + params.clientId + "/relationships/",
        params
    ).then((res) => res.data);
};

export const fetchRelationship = async function ({ queryKey }) {
    const [, { ...params }] = queryKey;
    return await Api.get(
        "clients/" +
            params.clientId +
            "/relationships/" +
            params.relationshipId,
        params
    ).then((res) => res.data);
};

export const createRelationship = async function (clientId, formData) {
    return await Api.post(
        "clients/" + clientId + "/relationships",
        formData
    ).then((res) => res.data);
};

export const updateRelationship = async function (
    clientId,
    relationshipId,
    formData
) {
    return await Api.put(
        "clients/" + clientId + "/relationships/" + relationshipId,
        formData
    ).then((res) => res.data);
};

export const restoreRelationship = async function (clientId, relationshipId) {
    return await Api.put(
        "clients/" + clientId + "/relationships/" + relationshipId,
        {
            restore: true,
        }
    );
};

export default {
    fetchRelationships,
    fetchRelationship,
    createRelationship,
    updateRelationship,
    restoreRelationship,
};
