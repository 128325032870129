import { downloadFile } from "util/Downloader";
import Api from "./Api";

export const fetchClientNotes = async function ({ queryKey }) {
    const [, { ...params }] = queryKey;
    return await Api.get("clients/" + params.clientId + "/notes", params);
};

export const fetchEstateAdminClientNotes = async function ({ queryKey }) {
    const [, { ...params }] = queryKey;
    return await Api.get(
        "clients/" + params.clientId + "/notes-estate-admin",
        params
    );
};

export const fetchEstateAdminClientNotesExport = async function (params) {
    const { clientId } = params;

    const response = await Api.get(
        "clients/" + clientId + "/notes-estate-admin",
        {
            ...params,
            export: true,
        },
        { responseType: "arraybuffer" }
    );
    await downloadFile(response, "estate-inventory-notes", { open: true });
    return {};
};
