import React, { Component } from "react";
import { useToasts } from "react-toast-notifications";

import * as Sentry from "@sentry/browser";
import { Link } from "react-router-dom";
import Forbidden from "components/Forbidden";
import ResourceNotFound from "components/ResourceNotFound";

class ErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = {
            eventId: null,
            hasError: false,
            errorStatus: null,
            message: props.message || null,
        };
    }

    static getDerivedStateFromError(error) {
        if (error.response && error.response.status) {
            return { hasError: true, errorStatus: error.response.status };
        }

        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        Sentry.withScope((scope) => {
            scope.setExtras(errorInfo);
            const eventId = Sentry.captureException(error);
            this.setState({ eventId });
        });
    }

    render() {
        if (this.state.hasError && this.state.errorStatus === 403) {
            return (
                <div className="h-full max-h-screen w-full flex items-center justify-center overflow-hidden">
                    <Forbidden text={this.state.message} />
                </div>
            );
        }

        if (this.state.hasError && this.state.errorStatus === 404) {
            return <ResourceNotFound />;
        }

        if (this.state.hasError) {
            return (
                <div className="h-full max-h-screen w-full flex items-center justify-center overflow-hidden">
                    <div className="text-center">
                        <h1 className="block text-4xl font-light text-gray-600">
                            Something Went Wrong :(
                        </h1>
                        <p className="text-gray-500">
                            Try refreshing the page or going back to the
                            dashboard.
                        </p>
                        <div className="flex items-center space-x-4 text-center justify-center">
                            <Link
                                to={"#"}
                                onClick={() => window.location.reload()}
                                className="block mt-3 text-brand underline"
                            >
                                Refresh Page
                            </Link>
                            <Link
                                to={"/"}
                                className="block mt-3 text-brand underline"
                            >
                                Back to Dashboard
                            </Link>
                        </div>
                    </div>
                </div>
            );
        }

        return this.props.children;
    }
}

function withToast(Component) {
    return function WrappedComponent(props) {
        const toastFuncs = useToasts();
        return <Component {...props} {...toastFuncs} />;
    };
}

export default withToast(ErrorBoundary);
