import React from "react";
import { Link } from "react-router-dom";
import Dropdown, { DropdownItem, DropdownSubmenu } from "../Dropdown";
import {
    activeClass,
    inActiveClass,
    iconClass,
    IconTray,
    MenuLink,
    MenuBarContainer,
    MenuItemsContainer,
    ClientProfileMenuContainer,
} from "../ClientPageHeader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown, faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { useActiveMenuItem } from "../ClientPageHeader";
import { listOfReports } from "../../views/reports/ReportsIndex";
import useHasPermission from "hooks/usePermissions";
import useAuth from "hooks/useAuth";

export default function ClientMenuForMembers({ client }) {
    const { isActive } = useActiveMenuItem();
    const canAccessVault = useHasPermission("firm_client_vault");
    return (
        <>
            <ClientProfileMenuContainer>
                <ClientProfileMenu client={client} />
            </ClientProfileMenuContainer>
            <MenuBarContainer>
                <MenuItemsContainer>
                    <Link
                        to={`/clients/${client.id}/dashboard`}
                        className={
                            isActive("dashboard") ? activeClass : inActiveClass
                        }
                    >
                        Dashboard
                    </Link>
                    <ClientReportsMenu client={client} />
                    <ClientPlanningMenu client={client} />
                    <ClientFinancialMenu client={client} />
                    <ClientFormsMenu client={client} />
                    {canAccessVault && (
                        <Link
                            to={`/clients/${client.id}/vault`}
                            className={
                                isActive("vault") ? activeClass : inActiveClass
                            }
                        >
                            Vault
                        </Link>
                    )}
                </MenuItemsContainer>
                <IconTray>
                    <MenuLink
                        to={`/clients/${client.id}/messages`}
                        paths="messages"
                    >
                        <FontAwesomeIcon icon={faEnvelope} />
                        <span className="ml-1 xl:block hidden">Messages</span>
                    </MenuLink>
                </IconTray>
            </MenuBarContainer>
        </>
    );
}

export function ClientProfileMenu({ client }) {
    return (
        <Dropdown
            toggleContent={
                <span className="w-full flex flex-no-wrap text-gray-900 px-3 py-2 text-center">
                    {client.full_name}
                    <FontAwesomeIcon icon={faCaretDown} className={iconClass} />
                </span>
            }
        >
            <DropdownItem
                label="Edit Profile"
                link={`/clients/${client.id}/edit/profile`}
            />
            <DropdownItem
                label="Relationships"
                link={`/clients/${client.id}/relationships`}
            />
            <DropdownItem
                label="Advisors"
                link={`/clients/${client.id}/advisors`}
            />
            <DropdownItem
                label="All Notes"
                link={`/clients/${client.id}/notes`}
            />
            <DropdownItem
                label="Notice Of Changes"
                link={`/clients/${client.id}/changes`}
            />
            <DropdownItem
                label="Appointments"
                link={`/clients/${client.id}/appointments`}
            />
        </Dropdown>
    );
}

export function ClientPlanningMenu({ client }) {
    const { isActive } = useActiveMenuItem();
    const paths = [
        "trusts",
        "funding-table",
        "beneficiaries",
        "estate-planning-roles",
        "post-mortem",
        "questionnaires",
    ];
    const { user } = useAuth();

    return (
        <Dropdown
            toggleContent={
                <span className={isActive(paths) ? activeClass : inActiveClass}>
                    Planning{" "}
                    <FontAwesomeIcon icon={faCaretDown} className={iconClass} />
                </span>
            }
        >
            <DropdownItem
                label="Trusts"
                link={`/clients/${client.id}/trusts`}
            />
            <DropdownItem
                label="Owner Changes &amp; Transfers"
                link={`/clients/${client.id}/funding-table`}
            />
            <DropdownItem
                label="Beneficiary List"
                link={`/clients/${client.id}/beneficiaries`}
            />
            <DropdownItem
                label="Estate Planning Roles"
                link={`/clients/${client.id}/estate-planning-roles`}
            />
            {user.navigation.ice && (
                <DropdownItem
                    label="In Case of Emergency"
                    link={`/clients/${client.id}/ice`}
                />
            )}
            <DropdownItem
                label="Estate Administration"
                link={`/clients/${client.id}/post-mortem`}
            />
            <DropdownItem
                label="Questionnaires"
                link={`/clients/${client.id}/questionnaires`}
            />
        </Dropdown>
    );
}

export function ClientFinancialMenu({ client }) {
    const { isActive } = useActiveMenuItem();
    const paths = [
        "non-mortgage-liabilities",
        "income-sources",
        "budget-estimates",
        "other-insurance-policies",
        "update-values",
        "annuities",
        "business-interests",
        "financial-accounts",
        "insurance-policies",
        "leases",
        "personal-properties",
        "real-estate",
        "notes-receivables",
    ];
    return (
        <Dropdown
            toggleContent={
                <span className={isActive(paths) ? activeClass : inActiveClass}>
                    Financial{" "}
                    <FontAwesomeIcon icon={faCaretDown} className={iconClass} />
                </span>
            }
        >
            <DropdownSubmenu label="Assets" link="#assets">
                <ClientAssetsMenu client={client} />
            </DropdownSubmenu>
            <DropdownItem
                label="Liabilities (Non-Mortgage)"
                link={`/clients/${client.id}/non-mortgage-liabilities`}
            />
            <DropdownItem
                label="Income Sources"
                link={`/clients/${client.id}/income-sources`}
            />
            <DropdownItem
                label="Budgets"
                link={`/clients/${client.id}/budget-estimates`}
            />
            <DropdownItem
                label="Other Insurance Policies"
                link={`/clients/${client.id}/other-insurance-policies`}
            />
            <DropdownItem
                label="Update Values"
                link={`/clients/${client.id}/update-values`}
            />
        </Dropdown>
    );
}

export function ClientAssetsMenu({ client }) {
    return (
        <>
            <DropdownItem
                label="Annuities"
                link={`/clients/${client.id}/annuities`}
            />
            <DropdownItem
                label="Business Interests"
                link={`/clients/${client.id}/business-interests`}
            />
            <DropdownItem
                label="Financial Accounts"
                link={`/clients/${client.id}/financial-accounts`}
            />
            <DropdownItem
                label="Retirement Accounts"
                link={`/clients/${client.id}/retirement-accounts`}
            />
            <DropdownItem
                label="Life Insurance Policies"
                link={`/clients/${client.id}/insurance-policies`}
            />
            <DropdownItem
                label="Personal Property"
                link={`/clients/${client.id}/personal-properties`}
            />
            <DropdownItem
                label="Real Estate"
                link={`/clients/${client.id}/real-estate`}
            />
            <DropdownItem
                label="Other Leases"
                link={`/clients/${client.id}/leases`}
            />
            <DropdownItem
                label="Notes Receivables"
                link={`/clients/${client.id}/notes-receivables`}
            />
        </>
    );
}

export function ClientFormsMenu({ client }) {
    const { isActive } = useActiveMenuItem();
    const paths = [
        "funeral-planning/edit",
        "location-list/edit",
        "personal-property-memos",
    ];
    return (
        <Dropdown
            toggleContent={
                <span className={isActive(paths) ? activeClass : inActiveClass}>
                    Next-Level Planning{" "}
                    <FontAwesomeIcon icon={faCaretDown} className={iconClass} />
                </span>
            }
        >
            <DropdownItem
                label="Funeral Planning"
                link={`/clients/${client.id}/forms/funeral-planning/edit`}
            />
            <DropdownItem
                label="Location List"
                link={`/clients/${client.id}/forms/location-list/edit`}
            />
            <DropdownItem
                label="Memorandum"
                link={`/clients/${client.id}/personal-property-memos`}
            />
        </Dropdown>
    );
}

export function ClientReportsMenu({ client }) {
    const { isActive } = useActiveMenuItem();
    const paths = [
        "reports/livewealth",
        "reports/list-of-changes",
        "reports/list-of-gifts",
        "reports/estate-inventory",
    ];

    const hasAnyReportAccess = listOfReports.filter((report) => {
        return useHasPermission(report.permission_key);
    });

    if (
        hasAnyReportAccess.length === 0 &&
        !useHasPermission("report_global_access")
    ) {
        return <></>;
    }
    return (
        <Dropdown
            toggleContent={
                <span className={isActive(paths) ? activeClass : inActiveClass}>
                    Reports{" "}
                    <FontAwesomeIcon icon={faCaretDown} className={iconClass} />
                </span>
            }
        >
            {listOfReports.map((report) => {
                if (
                    useHasPermission(report.permission_key) ||
                    useHasPermission("report_global_access")
                )
                    return (
                        <DropdownItem
                            key={`report_${report.path}`}
                            label={report.title}
                            link={`/clients/${client.id}/${report.path}`}
                        />
                    );
            })}
        </Dropdown>
    );
}
