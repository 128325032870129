import { useReducer, useState } from "react";
import { formatDateForInput } from "util/TextUtil";

export default function useDateRange() {
    let today = new Date();
    const [currentEndDate, setCurrentEndDate] = useState(
        formatDateForInput(today)
    );
    const [currentStartDate, setCurrentStartDate] = useState(
        formatDateForInput(today.setMonth(today.getMonth() - 1))
    );

    const [dates, setDates] = useReducer(
        (state, newState) => ({ ...state, ...newState }),
        {
            startDate: formatDateForInput(currentStartDate),
            endDate: formatDateForInput(currentEndDate),
        }
    );

    const updateStartDate = (event) => {
        event.preventDefault();
        setDates({ startDate: event.target.value });
    };

    const updateEndDate = (event) => {
        event.preventDefault();
        setDates({ endDate: event.target.value });
    };

    return {
        currentStartDate,
        setCurrentStartDate,
        currentEndDate,
        setCurrentEndDate,
        dates,
        setDates,
        updateStartDate,
        updateEndDate,
    };
}
