import React from "react";
import TextInput from "../../../components/TextInput";
import TextArea from "../../../components/TextArea";
import { titleCase } from "../../../util/TextUtil";

export default function MessageTemplateForm({
    input,
    errors,
    readOnly = false,
    updateValue,
    onSubmit,
}) {
    const copyToClipboard = async (variable) => {
        if (navigator.clipboard) {
            await navigator.clipboard.writeText(variable);
        }
    };

    return (
        <div className="flex space-x-4">
            <form onSubmit={onSubmit} className="max-w-2xl w-full">
                <div>
                    <div className="mb-6">
                        <TextInput
                            name="name"
                            label="Template Name"
                            id="name"
                            value={input.name}
                            readOnly={true}
                            disabled={true}
                        />
                    </div>
                    <div className="mb-6">
                        <TextInput
                            name="channel"
                            label="Channel"
                            id="channel"
                            value={input.channel}
                            readOnly={true}
                            disabled={true}
                        />
                    </div>
                    <div className="mb-6">
                        <TextArea
                            name="description"
                            label="Description"
                            id="description"
                            value={input.description}
                            readOnly={true}
                            disabled={true}
                            rows={3}
                        />
                    </div>
                    <hr className="my-4" />
                    {input.channel !== "Display Message" && (
                        <div className="mb-6">
                            <TextInput
                                name="subject"
                                label="Subject"
                                id="subject"
                                readOnly={readOnly}
                                disabled={readOnly}
                                value={input.subject}
                                error={errors.subject}
                                onChange={updateValue}
                                required={true}
                            />
                        </div>
                    )}
                    <div className="mb-6">
                        <TextArea
                            name="body"
                            label="Message Body"
                            id="body"
                            readOnly={readOnly}
                            disabled={readOnly}
                            value={input.body}
                            error={errors.body}
                            onChange={updateValue}
                            required={true}
                        />
                    </div>

                    <input type="submit" hidden />
                </div>
            </form>
            <div>
                <div className="text-center font-bold text-md mb-4 border-b border-gray-200 pb-4">
                    Template Variables
                </div>
                <dl className="space-y-3 px-3">
                    {input.variables &&
                        input.variables.map((varName) => (
                            <div key={varName}>
                                <dt
                                    onClick={() =>
                                        copyToClipboard(`%${varName}%`)
                                    }
                                    className="font-semibold"
                                >
                                    {titleCase(varName.replaceAll("_", " "), {
                                        pascal: true,
                                    })}
                                </dt>
                                <dd className="hover:text-brand-700 cursor-pointer">
                                    {"%" + varName + "%"}
                                </dd>
                            </div>
                        ))}
                </dl>
            </div>
        </div>
    );
}
