import React, { useState, useContext, useEffect } from "react";
import DashboardLayout from "../layouts/DashboardLayout";
import Modal from "../../components/Modal";
import { Redirect } from "react-router-dom";
import { AuthContext } from "../../contexts/AuthContext";
import Loading from "../../components/Loading";
import ClientDashboard from "./Clients/ClientDashboard";
import MemberDashboard from "./Members/MemberDashboard";
import AdvisorDashboard from "./Advisors/AdvisorDashboard";
import ClientContextProvider from "../../contexts/ClientContext";

function Dashboard(props) {
    const [showOnboarding, setShowOnboarding] = useState(null);
    const [redirectTo2FA, setRedirectTo2FA] = useState(false);

    const context = useContext(AuthContext);

    useEffect(() => {
        document.title = "Dashboard";
    }, []);

    const header = () => {
        if (!context.user) {
            return <Loading />;
        }

        if (context.user.type === "advisor") {
            return (
                <DashboardLayout {...props}>
                    <AdvisorDashboard />
                </DashboardLayout>
            );
        }

        if (context.user.type === "client") {
            return (
                <DashboardLayout {...props}>
                    <ClientContextProvider {...props}>
                        <ClientDashboard />
                    </ClientContextProvider>
                </DashboardLayout>
            );
        }

        if (context.user.type === "member") {
            return (
                <DashboardLayout {...props}>
                    <MemberDashboard />
                </DashboardLayout>
            );
        }

        return <div>No Access</div>;
    };

    if (
        props.location.state &&
        props.location.state.onboarding &&
        showOnboarding === null
    ) {
        setShowOnboarding(true);
    }

    if (redirectTo2FA) {
        return (
            <Redirect
                to={{
                    pathname: "/account/two-factor-auth",
                }}
            />
        );
    }

    return (
        <>
            {header()}

            <Modal
                isOpen={showOnboarding}
                title="Two-Factor Authentication"
                body={`Setting up Two-Factor authentication is the best way to keep you account as secure as possible. Upon logging on, we add an extra layer of protection by requiring a one-time passcode.`}
                doneButtonTitle="Setup"
                cancelButtonTitle="No Thanks"
                onResult={({ result }) => {
                    setShowOnboarding(false);
                    if (result) setRedirectTo2FA(true);
                }}
            />
        </>
    );
}

export default Dashboard;
