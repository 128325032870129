import React from "react";

import { useOwnershipContext } from "contexts/OwnershipContext";
import { OwnershipOptions } from "components/Ownership";
import SelectInput from "components/SelectInput";
import { ownershipTypes } from "../OwnershipService";
import { FakeInput } from "components/TextInput";
import PercentInput from "../../PercentInput";

export function OwnershipLineItemReadOnly({
    client,
    ownership,
    showProposed = false,
    showTransferDeltas = false,
    hideLabels = false,
}) {
    const ownershipContext = useOwnershipContext();
    const assetOptions = ownershipContext.getAssetOptionsForResourceType(
        ownership.resource_type
    );

    const showStrikeThrough =
        showProposed &&
        ownership.proposed_percentage_owned != ownership.percentage_owned;

    return (
        <div key={`ownership_${ownership.id}`}>
            <div className={`flex space-x-3 items-stretch`}>
                <div className="flex-shrink-0 w-56">
                    <div className="flex-1 relative">
                        <SelectInput
                            label={!hideLabels ? `Ownership Type` : ""}
                            name="type"
                            options={ownershipTypes()}
                            loading={false}
                            value={ownership.type || ""}
                            disabled={true}
                            required={false}
                            autoFocus={false}
                        />
                    </div>
                </div>
                {ownership.type && assetOptions && (
                    <div className="flex-1">
                        {!ownershipContext.loadingOptions ? (
                            <OwnershipOptions
                                label={!hideLabels ? `Owner` : ""}
                                assetOptions={assetOptions}
                                client={client}
                                type={ownership.type}
                                ownerType={ownership.owner_type}
                                description={ownership.owner_description}
                                ownerId={ownership.owner_id}
                                disabled={true}
                                updateOption={console.log()}
                                updateDescription={console.log()}
                            />
                        ) : (
                            <SelectInput
                                name="owner_id"
                                label={!hideLabels ? `Owner` : ""}
                                value={null}
                                disabled={true}
                                options={[
                                    {
                                        label: "Loading...",
                                        value: "",
                                    },
                                ]}
                                onChange={console.log()}
                            />
                        )}
                    </div>
                )}
                <div className="flex space-x-2 flex-0 w-32 mx-2">
                    <div className="flex-1 relative">
                        {!showProposed && !showTransferDeltas && (
                            <PercentInput
                                label={!hideLabels ? `Percentage` : ""}
                                name="percentage_owned"
                                id={`percentage_owned_${ownership.id}`}
                                value={ownership.percentage_owned}
                                disabled={true}
                                required={false}
                                placeholder="0 - 100"
                                onChange={console.log()}
                            />
                        )}
                        {(showProposed || showTransferDeltas) && (
                            <>
                                {showProposed && !showTransferDeltas && (
                                    <FakeInput
                                        label={!hideLabels ? `Percentage` : ""}
                                        name="percent"
                                        disabled={true}
                                        required={false}
                                    >
                                        {!showStrikeThrough && (
                                            <span>
                                                {ownership.percentage_owned +
                                                    "%"}
                                            </span>
                                        )}
                                        {showStrikeThrough && (
                                            <>
                                                {" "}
                                                <span className="line-through opacity-50">
                                                    {ownership.percentage_owned +
                                                        "%"}
                                                </span>
                                                <span>
                                                    {(ownership.proposed_percentage_owned ??
                                                        0) + "%"}
                                                </span>
                                            </>
                                        )}
                                    </FakeInput>
                                )}
                                {!showProposed && showTransferDeltas && (
                                    <FakeInput
                                        label={!hideLabels ? `Percentage` : ""}
                                        name="percent"
                                        disabled={true}
                                        required={false}
                                    >
                                        <span>
                                            {ownership.percentage_owned + "%"}
                                        </span>
                                        {showTransferDeltas &&
                                            ownership.proposed_outgoing_transfers_total_percentage >
                                                0 && (
                                                <span className="text-xs text-red-500">
                                                    {"-" +
                                                        ownership.proposed_outgoing_transfers_total_percentage +
                                                        "%"}
                                                </span>
                                            )}
                                        {showTransferDeltas &&
                                            ownership.proposed_incoming_transfers_total_percentage >
                                                0 && (
                                                <span className="text-xs text-green-500">
                                                    {"+" +
                                                        ownership.proposed_incoming_transfers_total_percentage +
                                                        "%"}
                                                </span>
                                            )}
                                    </FakeInput>
                                )}
                            </>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}
