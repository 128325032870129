import useClient from "hooks/useClient";
import { useState } from "react";
import { useToasts } from "react-toast-notifications";
import Api from "services/Api";

export function useClientNotes({
    input,
    setInput,
    refetch,
    currentPage,
    setCurrentPage,
    onlyEstateAdmin,
}) {
    const { addToast } = useToasts();
    const [processing, setProcessing] = useState(false);
    const { client } = useClient();

    async function createNote(e) {
        e.preventDefault();
        setProcessing(true);
        try {
            let response = await Api.post(
                "clients/" + client.id + "/notes",
                input
            );
            setInput({
                content: "",
                for_office_use_only: false,
                is_post_mortem: !!onlyEstateAdmin,
            });
            addToast(response.data.message);
            if (currentPage !== 1) {
                setCurrentPage(1);
            }
            refetch();
            setProcessing(false);
        } catch (e) {
            addToast(e.response.data.message, { type: "error" });
            setProcessing(false);
        }
    }

    async function deleteNote(note) {
        setProcessing(true);
        try {
            let response = await Api.delete(
                "clients/" + client.id + "/notes/" + note.id
            );
            setProcessing(false);
            addToast(response.data.message);
            refetch();
        } catch (e) {
            setProcessing(false);
            addToast(e.response.data.message, { type: "error" });
        }
    }

    async function updateNote(note, noteInput) {
        setProcessing(true);
        try {
            let response = await Api.put(
                "clients/" + client.id + "/notes/" + note.id,
                {
                    content: noteInput.content,
                    for_office_use_only: noteInput.for_office_use_only,
                    is_post_mortem: noteInput.is_post_mortem,
                }
            );
            addToast(response.data.message);
            setProcessing(false);
            refetch();
        } catch (e) {
            addToast(e.response.data.message, { type: "error" });
            setProcessing(false);
        }
    }

    return {
        createNote,
        deleteNote,
        updateNote,
        processing,
        setProcessing,
    };
}
