import React from "react";
import ClientPageHeader, { Heading } from "../../components/ClientPageHeader";
import PageContainer from "../../components/PageContainer";
import Button from "../../components/Button";
import useClient from "hooks/useClient";
import ResourceContextProvider from "contexts/ResourceContext";
import usePostMortemDownloads from "views/post_mortem/usePostMortemDownloads";
import PostMortemContextProvider from "views/post_mortem/PostMortemContext";
import useHasPermission from "hooks/usePermissions";
import EstateInventoryReport from "./_partials/EstateInventoryReport";

const pageTitle = "Estate Inventory Report";

export default function EstateInventoryReportPage({ client }) {
    document.title = pageTitle;

    return (
        <ResourceContextProvider
            refresh={() => {}}
            resourceTypes={[
                "Client",
                "Relationship",
                "Trust",
                "BusinessInterest",
            ]}
        >
            <PostMortemContextProvider>
                <ReportContent client={client} />
            </PostMortemContextProvider>
        </ResourceContextProvider>
    );
}

export const ReportContent = ({ client }) => {
    const forbidden =
        useHasPermission("report_estate_valuation_report") === false;
    const { clientPath } = useClient();

    const { isLoading: isDownloading, exportEstateInventory } =
        usePostMortemDownloads();

    async function exportRecords(e) {
        e.preventDefault();
        exportEstateInventory(e);
    }

    return (
        <>
            <ClientPageHeader client={client}>
                <Heading
                    backTo={`${clientPath}/reports`}
                    backText="All Reports"
                >
                    {pageTitle}
                </Heading>
                {!forbidden && (
                    <Button
                        text="Download"
                        appearance="outline"
                        width="w-auto"
                        isLoading={isDownloading}
                        onClick={exportRecords}
                    />
                )}
            </ClientPageHeader>
            <PageContainer forbidden={forbidden}>
                <EstateInventoryReport />
            </PageContainer>
        </>
    );
};
