import useClient from "../../hooks/useClient";
import usePostMortemState from "hooks/usePostMortemState";
import usePostMortemWealthExport from "hooks/usePostMortemWealthExport";
import useLiveWealthState from "hooks/useLiveWealthState";
import useBeneficiaryReportExport from "hooks/useBeneficiaryReportExport";
import usePostMortemTransfereesReport from "hooks/usePostMortemTransfereesReport";
import useClientNotesEstateAdminExport from "hooks/useClientNotesEstateAdminExport";

export default function usePostMortemDownloads() {
    const { client } = useClient();

    const { person, isPostMortem, showAltValues, jointSplit } =
        usePostMortemState();

    const { owner, current } = useLiveWealthState();

    const {
        onSubmit: exportEstateInventory,
        isLoading: isExportingEstateInventory,
    } = usePostMortemWealthExport({
        owner,
        current,
        client,
        isPostMortem,
        showAltValues,
        person: person || "client",
        snapshot: true,
        jointSplit,
    });

    const {
        onSubmit: exportEstateInventoryNotes,
        isLoading: isExportingEstateInventoryNotes,
    } = useClientNotesEstateAdminExport({
        clientId: client.id,
        person,
    });

    const {
        onSubmit: exportBeneficiaryReport,
        isLoading: isExportingBeneficiaryReport,
    } = useBeneficiaryReportExport({
        client,
        showClaimsForms: true,
        is_post_mortem: true,
        person,
        level: 1,
    });

    const {
        onSubmit: exportEstateTransfereesReport,
        isLoading: isExportingEstateTransfereesReport,
    } = usePostMortemTransfereesReport({
        client,
        person,
    });

    const isLoading =
        isExportingEstateInventory ||
        isExportingBeneficiaryReport ||
        isExportingEstateTransfereesReport ||
        isExportingEstateInventoryNotes;

    return {
        isLoading,
        exportEstateInventoryNotes,
        exportEstateInventory,
        exportBeneficiaryReport,
        exportEstateTransfereesReport,
    };
}
