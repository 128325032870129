import React, { useState, useEffect } from "react";
import Dropdown, { DropdownItem } from "../../../components/Dropdown";
import {
    faBan,
    faCheck,
    faEdit,
    faUser,
    faUserAlt,
    faEllipsisH,
    faEnvelope,
    faUserSecret,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Api from "../../../services/Api";
import { useToasts } from "react-toast-notifications";
import { Redirect } from "react-router-dom";
import EmptyState from "../../../components/EmptyState";
import SearchInput from "../../../components/SearchInput";
import useDebounce from "../../../hooks/useDebounce";
import Pagination from "../../../components/Pagination";
import TableSortHeader from "../../../components/TableSortHeader";
import { formatDate } from "../../../util/TextUtil";
import Spinner from "../../../components/Spinner";
import useHasPermission from "hooks/usePermissions";
import useAuth from "hooks/useAuth";
import ConfirmImpersonationModal from "components/ConfirmImpersonationModal";

const ClientsTable = ({ showDisabled, showAll, title }) => {
    const { user } = useAuth();
    const userType = user.type;
    const { addToast } = useToasts();
    const [clients, setClients] = useState([]);
    const [meta, setMeta] = useState([]);
    const [needsUpdated, setNeedsUpdated] = useState(false);
    const [search, setSearch] = useState("");
    const debouncedSearch = useDebounce(search, 250);
    const [page, setPage] = useState(1);
    const [orderBy, setOrderBy] = useState("last_name");
    const [sortDir, setSortDir] = useState("asc");
    const [redirectTo, setRedirectTo] = useState(false);
    const [loading, setLoading] = useState(true);
    const [clientToImpersonate, setClientToImpersonate] = useState(null);

    const canImpersonateClient = useHasPermission("impersonate_client");

    document.title = title || "Clients";

    useEffect(() => {
        const fetchData = async () => {
            try {
                let response = await Api.get("clients", {
                    page: page,
                    limit: 20,
                    sort: sortDir === "desc" ? "-" + orderBy : orderBy,
                    show_trashed: showDisabled ? "with" : false,
                    "filter[search]": debouncedSearch,
                    "filter[role]": "client_owner",
                    show_all: showAll ? true : false,
                });
                setMeta(response.data.meta);
                setClients(response.data.data);
                setNeedsUpdated(false);
                setLoading(false);
            } catch (error) {
                console.log(error);
                setLoading(false);
            }
        };
        fetchData();
    }, [
        showDisabled,
        showAll,
        debouncedSearch,
        needsUpdated,
        setLoading,
        page,
        sortDir,
        orderBy,
    ]);

    const hasAccountsAdministration = useHasPermission(
        "firm_client_related_accounts_administration"
    );

    const canDisableClients = useHasPermission(
        "firm_client_account_disablement"
    );

    async function removeClient(clientId) {
        try {
            const response = await Api.delete("clients/" + clientId);
            addToast(response.data.message);
            setNeedsUpdated(true);
        } catch (e) {
            addToast(e.errorMessage, { type: "error" });
        }
    }

    async function restoreClient(clientId) {
        try {
            const response = await Api.put("clients/" + clientId + "/trashed", {
                is_trashed: false,
            });
            addToast(response.data.message);
            setNeedsUpdated(true);
        } catch (e) {
            addToast(e.errorMessage, { type: "error" });
        }
    }

    const renderDisableButton = (client) => {
        if (
            client.id === user.id ||
            userType !== "member" ||
            (canDisableClients === false && user.id !== client.attorney_id)
        ) {
            return <></>;
        }

        if (client.disabled_at) {
            return (
                <DropdownItem
                    label="Enable"
                    icon={faCheck}
                    handleClick={() => restoreClient(client.id)}
                />
            );
        }

        return (
            <DropdownItem
                label="Disable"
                icon={faBan}
                handleClick={() => removeClient(client.id)}
            />
        );
    };

    function visitLink(e, client, link) {
        e.preventDefault();
        if (client.org_subdomain) {
            return (window.location.href = `${client.org_url}${link}`);
        } else {
            setRedirectTo(link);
        }
    }

    if (redirectTo) {
        return <Redirect to={redirectTo} />;
    }

    if (loading) {
        return <Spinner />;
    }

    return (
        <>
            <div className="mb-4">
                <SearchInput
                    label={false}
                    searchValue={search}
                    onSearchChange={(e) => setSearch(e.target.value)}
                />
            </div>
            {clients.length < 1 ? (
                <EmptyState>No clients found.</EmptyState>
            ) : (
                <>
                    <table className="w-full text-left">
                        <thead>
                            <tr>
                                <TableSortHeader
                                    field="first_name"
                                    sortField={orderBy}
                                    sortDir={sortDir}
                                    changeSort={(column) => {
                                        setSortDir(
                                            sortDir === "asc" ? "desc" : "asc"
                                        );
                                        setOrderBy(column);
                                    }}
                                >
                                    Name
                                </TableSortHeader>
                                <th>Organization</th>
                                <TableSortHeader
                                    field="attorney_last_name"
                                    sortField={orderBy}
                                    sortDir={sortDir}
                                    changeSort={(column) => {
                                        setSortDir(
                                            sortDir === "asc" ? "desc" : "asc"
                                        );
                                        setOrderBy(column);
                                    }}
                                >
                                    Attorney
                                </TableSortHeader>
                                <th>Spouse</th>
                                <TableSortHeader
                                    field="org_user.created_at"
                                    sortField={orderBy}
                                    sortDir={sortDir}
                                    changeSort={(column) => {
                                        setSortDir(
                                            sortDir === "asc" ? "desc" : "asc"
                                        );
                                        setOrderBy(column);
                                    }}
                                >
                                    Joined
                                </TableSortHeader>
                                {showDisabled && (
                                    <TableSortHeader
                                        field="org_user.deleted_at"
                                        sortField={orderBy}
                                        sortDir={sortDir}
                                        changeSort={(column) => {
                                            setSortDir(
                                                sortDir === "asc"
                                                    ? "desc"
                                                    : "asc"
                                            );
                                            setOrderBy(column);
                                        }}
                                    >
                                        Disabled On
                                    </TableSortHeader>
                                )}
                                <th>Storage</th>
                                <th>Has Access</th>
                                <th className="text-right">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {clients.map((client, idx) => (
                                <tr
                                    key={idx}
                                    className={client.deleted_at ? "" : ""}
                                >
                                    <td>
                                        <a
                                            href="#editClient"
                                            onClick={(e) =>
                                                visitLink(
                                                    e,
                                                    client,
                                                    `/clients/${client.id}/dashboard`
                                                )
                                            }
                                            className="hover:text-brand-600"
                                        >
                                            {client.full_name}
                                        </a>
                                        <div className="text-sm text-gray-500">
                                            {client.email}
                                        </div>
                                    </td>
                                    <td>{client.org_name}</td>
                                    <td>
                                        {client.attorney_first_name}{" "}
                                        {client.attorney_last_name}
                                    </td>
                                    <td>{client.spouse_full_name || "N/A"}</td>
                                    <td>
                                        <div>
                                            {formatDate(client.created_at)}
                                        </div>
                                    </td>
                                    {showDisabled && (
                                        <td>
                                            {formatDate(client.disabled_at)}
                                        </td>
                                    )}
                                    <td>{client.storage_used_display}</td>
                                    <td>
                                        <dl>
                                            <div className="flex space-x-2 text-sm">
                                                <dt>
                                                    <a
                                                        href={`/clients/${client.id}/advisors`}
                                                        className="hover:text-brand-600"
                                                    >
                                                        Collaborating Advisors:
                                                    </a>
                                                </dt>
                                                <dd className="text-brand">
                                                    {
                                                        client.collaborating_advisors_count
                                                    }
                                                </dd>
                                            </div>
                                            <div className="flex space-x-1 text-sm">
                                                <dt>
                                                    <a
                                                        href={`/clients/${client.id}/relationships`}
                                                        className="hover:text-brand-600"
                                                    >
                                                        Authorized Parties:
                                                    </a>
                                                </dt>
                                                <dd className="text-brand">
                                                    {
                                                        client.authorized_parties_count
                                                    }
                                                </dd>
                                            </div>
                                        </dl>
                                    </td>
                                    <td className="text-right">
                                        <span>
                                            <Dropdown
                                                position="right"
                                                toggleClass="px-3 py-1 text-gray-500"
                                                toggleContent={
                                                    <FontAwesomeIcon
                                                        icon={faEllipsisH}
                                                    />
                                                }
                                            >
                                                <DropdownItem
                                                    label="Edit"
                                                    icon={faEdit}
                                                    handleClick={(e) =>
                                                        visitLink(
                                                            e,
                                                            client,
                                                            `/clients/${client.id}/dashboard`
                                                        )
                                                    }
                                                />
                                                <DropdownItem
                                                    label="Messages"
                                                    icon={faEnvelope}
                                                    handleClick={(e) =>
                                                        visitLink(
                                                            e,
                                                            client,
                                                            `/clients/${client.id}/messages`
                                                        )
                                                    }
                                                />
                                                {canImpersonateClient && (
                                                    <>
                                                        <DropdownItem
                                                            label="Impersonate Client"
                                                            icon={faUserSecret}
                                                            handleClick={() =>
                                                                setClientToImpersonate(
                                                                    client
                                                                )
                                                            }
                                                        />
                                                    </>
                                                )}
                                                {(userType === "member" ||
                                                    userType === "client") &&
                                                    hasAccountsAdministration && (
                                                        <>
                                                            <DropdownItem
                                                                label="Add Authorized Party"
                                                                icon={faUser}
                                                                handleClick={(
                                                                    e
                                                                ) =>
                                                                    visitLink(
                                                                        e,
                                                                        client,
                                                                        `/clients/${client.id}/relationships/add?authorized_party`
                                                                    )
                                                                }
                                                            />
                                                            <DropdownItem
                                                                label="Add Collaborating Advisor"
                                                                icon={faUserAlt}
                                                                handleClick={(
                                                                    e
                                                                ) =>
                                                                    visitLink(
                                                                        e,
                                                                        client,
                                                                        `/clients/${client.id}/advisors/add?collaborating_advisor`
                                                                    )
                                                                }
                                                            />
                                                        </>
                                                    )}
                                                {renderDisableButton(client)}
                                            </Dropdown>
                                        </span>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <Pagination {...meta} nextPage={setPage} />
                    <ConfirmImpersonationModal
                        client={clientToImpersonate || {}}
                        isOpen={!!clientToImpersonate}
                        onClose={() => setClientToImpersonate(null)}
                    />
                </>
            )}
        </>
    );
};

export default ClientsTable;
