import React, { useState } from "react";
import SelectInput from "../../../components/SelectInput";
import TextInput from "../../../components/TextInput";
import Checkbox from "../../../components/Checkbox";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import Fieldset from "../../../components/Fieldset";

export default function PetCareInstructions({
    petCareInstructions,
    setPetCareInstructions,
}) {
    const veterinarian = {
        name: "",
        specialty: "",
        address: "",
        phone: "",
        email: "",
        website: "",
    };

    const pharmacy = {
        name: "",
        address: "",
        phone: "",
        email: "",
        website: "",
    };

    const boardingFacility = {
        name: "",
        address: "",
        phone: "",
        email: "",
        website: "",
    };

    const pet = {
        name: "",
        type: "",
        breed: "",
        color: "",
        dob: "",
    };

    const medication = {
        name: "",
        dosage: "",
        taken: 0,
        am: 0,
        pm: 0,
        refills: 0,
        refill_frequency: "None",
        pet,
    };

    const [veterinarians, setVeterinarians] = useState(
        petCareInstructions.veterinarians || []
    );
    const [pharmacies, setPharmacies] = useState(
        petCareInstructions.pharmacies || []
    );
    const [pets, setPets] = useState(petCareInstructions.pets || []);
    const [medications, setMedications] = useState(
        petCareInstructions.medications || []
    );
    const [boardingFacilities, setBoardingFacilities] = useState(
        petCareInstructions.boardingFacilities || []
    );

    function appendVeterinarian() {
        if (veterinarians.length < 10) {
            setVeterinarians((veterinarians) => [
                ...veterinarians,
                veterinarian,
            ]);
        }
    }

    function removeVeterinarian(idx) {
        const newVeterinarians = veterinarians.filter(
            (_veterinarian, idxs) => idxs !== idx
        );
        setVeterinarians(() => newVeterinarians);
        setPetCareInstructions({
            ...petCareInstructions,
            veterinarians: newVeterinarians,
        });
    }

    const updateVeterinarian = (event, idx) => {
        const newVeterinarians = veterinarians.map((veterinarian, sidx) => {
            if (idx !== sidx) return veterinarian;
            return { ...veterinarian, [event.target.name]: event.target.value };
        });
        setVeterinarians(newVeterinarians);
        setPetCareInstructions({
            ...petCareInstructions,
            veterinarians: newVeterinarians,
        });
    };

    function appendPharmacy() {
        if (pharmacies.length < 10) {
            setPharmacies((pharmacies) => [...pharmacies, pharmacy]);
        }
    }

    function removePharmacy(idx) {
        const newPharmacies = pharmacies.filter(
            (_pharmacy, idxs) => idxs !== idx
        );
        setPharmacies(() => newPharmacies);
        setPetCareInstructions({
            ...petCareInstructions,
            pharmacies: newPharmacies,
        });
    }

    const updatePharmacy = (event, idx) => {
        const newPharmacies = pharmacies.map((pharmacy, sidx) => {
            if (idx !== sidx) return pharmacy;
            return { ...pharmacy, [event.target.name]: event.target.value };
        });
        setPharmacies(newPharmacies);
        setPetCareInstructions({
            ...petCareInstructions,
            pharmacies: newPharmacies,
        });
    };

    function appendPet() {
        if (pets.length < 10) {
            setPets((pets) => [...pets, pet]);
        }
    }

    function removePet(idx) {
        const newPets = pets.filter((pet, idxs) => idxs !== idx);
        setPets(() => newPets);
        setPetCareInstructions({ ...petCareInstructions, pets: newPets });
    }

    const updatePet = (event, idx) => {
        const newPets = pets.map((pet, sidx) => {
            if (idx !== sidx) return pet;
            return { ...pet, [event.target.name]: event.target.value };
        });
        setPets(newPets);
        setPetCareInstructions({ ...petCareInstructions, pets: newPets });
    };

    function appendMedication() {
        if (medications.length < 10) {
            setMedications((medications) => [...medications, medication]);
        }
    }

    function removeMedication(idx) {
        const newMedications = medications.filter(
            (_medication, idxs) => idxs !== idx
        );
        setMedications(() => newMedications);
        setPetCareInstructions({
            ...petCareInstructions,
            medications: newMedications,
        });
    }

    const updateMedication = (event, idx) => {
        const newMedications = medications.map((medication, sidx) => {
            if (idx !== sidx) return medication;
            return { ...medication, [event.target.name]: event.target.value };
        });
        setMedications(newMedications);
        setPetCareInstructions({
            ...petCareInstructions,
            medications: newMedications,
        });
    };

    function appendBoardingFacility() {
        if (boardingFacilities.length < 10) {
            setBoardingFacilities((boardingFacilities) => [
                ...boardingFacilities,
                boardingFacility,
            ]);
        }
    }

    function removeBoardingFacility(idx) {
        const newBoardingFacilitys = boardingFacilities.filter(
            (_boardingFacility, idxs) => idxs !== idx
        );
        setBoardingFacilities(() => newBoardingFacilitys);
        setPetCareInstructions({
            ...petCareInstructions,
            boardingFacilities: newBoardingFacilitys,
        });
    }

    const updateBoardingFacility = (event, idx) => {
        const newBoardingFacilitys = boardingFacilities.map(
            (boardingFacility, sidx) => {
                if (idx !== sidx) return boardingFacility;
                return {
                    ...boardingFacility,
                    [event.target.name]: event.target.value,
                };
            }
        );
        setBoardingFacilities(newBoardingFacilitys);
        setPetCareInstructions({
            ...petCareInstructions,
            boardingFacilities: newBoardingFacilitys,
        });
    };

    return (
        <Fieldset legend="Pet Care Instructions">
            <div className="mb-10 border-b border-gray-100 pb-6">
                <h2 className="mb-4 font-bold text-gray-500">Pets</h2>
                {pets.map((per, idx) => (
                    <div className="mb-10 border-b border-gray-100" key={idx}>
                        <div className="mb-6">
                            <TextInput
                                label="Name"
                                name="name"
                                value={pets[idx]["name"]}
                                onChange={(e) => updatePet(e, idx)}
                                autoFocus={pets.length === idx + 1}
                            />
                        </div>
                        <div className="flex space-x-3 mb-6">
                            <div className="flex-1">
                                <TextInput
                                    label="Type"
                                    name="type"
                                    value={pets[idx]["type"]}
                                    onChange={(e) => updatePet(e, idx)}
                                />
                            </div>
                            <div className="flex-1">
                                <TextInput
                                    label="Breed"
                                    name="breed"
                                    value={pets[idx]["breed"]}
                                    onChange={(e) => updatePet(e, idx)}
                                />
                            </div>
                        </div>
                        <div className="flex space-x-3 mb-6">
                            <div className="flex-1">
                                <TextInput
                                    type="date"
                                    label="DOB"
                                    name="dob"
                                    value={pets[idx]["dob"]}
                                    onChange={(e) => updatePet(e, idx)}
                                />
                            </div>
                            <div className="flex-1">
                                <TextInput
                                    label="Color"
                                    name="color"
                                    value={pets[idx]["color"]}
                                    onChange={(e) => updatePet(e, idx)}
                                />
                            </div>
                        </div>

                        <div className="flex justify-end mb-3">
                            <button
                                type="button"
                                onClick={() => removePet(idx)}
                                className="text-gray-500 text-sm hover:text-red-500"
                            >
                                <FontAwesomeIcon
                                    icon={faTimesCircle}
                                    className="mr-1"
                                />{" "}
                                Remove Pet
                            </button>
                        </div>
                    </div>
                ))}
                <div className="flex">
                    <button
                        type="button"
                        onClick={(e) => appendPet(e)}
                        className="text-gray-500 text-sm hover:text-brand"
                    >
                        <FontAwesomeIcon icon={faPlusCircle} className="mr-1" />{" "}
                        Add Pet
                    </button>
                </div>
            </div>

            <div className="mb-10 border-b border-gray-100 pb-6">
                <h2 className="mb-4 font-bold text-gray-500">Veterinarians</h2>
                {veterinarians.map((veterinarian, idx) => (
                    <div className="mb-10 border-b border-gray-100" key={idx}>
                        <div className="mb-6">
                            <TextInput
                                label="Name"
                                name="name"
                                value={veterinarians[idx]["name"]}
                                onChange={(e) => updateVeterinarian(e, idx)}
                                autoFocus={veterinarians.length === idx + 1}
                            />
                        </div>
                        <div className="flex space-x-3 mb-6">
                            <div className="flex-1">
                                <TextInput
                                    label="Specialty"
                                    name="specialty"
                                    value={veterinarians[idx]["specialty"]}
                                    onChange={(e) => updateVeterinarian(e, idx)}
                                />
                            </div>
                            <div className="flex-1">
                                <TextInput
                                    label="Phone"
                                    name="phone"
                                    value={veterinarians[idx]["phone"]}
                                    onChange={(e) => updateVeterinarian(e, idx)}
                                />
                            </div>
                        </div>
                        <div className="mb-6">
                            <TextInput
                                label="Address"
                                name="address"
                                value={veterinarians[idx]["address"]}
                                onChange={(e) => updateVeterinarian(e, idx)}
                            />
                        </div>
                        <div className="flex space-x-3 mb-6">
                            <div className="flex-1">
                                <TextInput
                                    type="email"
                                    label="Email"
                                    name="email"
                                    value={veterinarians[idx]["email"]}
                                    onChange={(e) => updateVeterinarian(e, idx)}
                                />
                            </div>
                            <div className="flex-1">
                                <TextInput
                                    label="Website"
                                    name="website"
                                    value={veterinarians[idx]["website"]}
                                    onChange={(e) => updateVeterinarian(e, idx)}
                                />
                            </div>
                        </div>
                        <div className="flex justify-end mb-3">
                            <button
                                type="button"
                                onClick={() => removeVeterinarian(idx)}
                                className="text-gray-500 text-sm hover:text-red-500"
                            >
                                <FontAwesomeIcon
                                    icon={faTimesCircle}
                                    className="mr-1"
                                />{" "}
                                Remove Veterinarian
                            </button>
                        </div>
                    </div>
                ))}
                <div className="flex">
                    <button
                        type="button"
                        onClick={(e) => appendVeterinarian(e)}
                        className="text-gray-500 text-sm hover:text-brand"
                    >
                        <FontAwesomeIcon icon={faPlusCircle} className="mr-1" />{" "}
                        Add Veterinarian
                    </button>
                </div>
            </div>

            <div className="mb-10 border-b border-gray-100 pb-6">
                <h2 className="mt-4 mb-4 font-bold text-gray-500">
                    Current Medications
                </h2>
                <div>
                    {medications.length > 0 &&
                        medications.map((medication, idx) => (
                            <div
                                key={idx}
                                className="mb-10 border-b border-gray-200 mr-10"
                            >
                                <div className="mb-6">
                                    <SelectInput
                                        label="Medication For"
                                        options={pets.map((pet) => pet.name)}
                                        name="pet"
                                        value={medications[idx]["pet"]}
                                        onChange={(e) =>
                                            updateMedication(e, idx)
                                        }
                                    />
                                </div>
                                <div className="flex space-x-3 mb-6">
                                    <div className="flex-1">
                                        <TextInput
                                            label="Name Of Medications"
                                            name="name"
                                            value={medications[idx]["name"]}
                                            onChange={(e) =>
                                                updateMedication(e, idx)
                                            }
                                        />
                                    </div>
                                    <div className="flex-1">
                                        <TextInput
                                            label="Dosage"
                                            name="dosage"
                                            value={medications[idx]["dosage"]}
                                            onChange={(e) =>
                                                updateMedication(e, idx)
                                            }
                                        />
                                    </div>
                                </div>
                                <div className="flex items-center space-x-10 mb-6">
                                    <div>
                                        <Checkbox
                                            label="Currently Taken"
                                            name="taken"
                                            defaultChecked={
                                                parseInt(
                                                    medications[idx]["taken"]
                                                ) || 0
                                            }
                                            value={medications[idx]["taken"]}
                                            onChange={(e) =>
                                                updateMedication(e, idx)
                                            }
                                        />
                                        <div className="flex items-center space-x-3 mt-3">
                                            <Checkbox
                                                label="AM"
                                                name="am"
                                                defaultChecked={
                                                    parseInt(
                                                        medications[idx]["am"]
                                                    ) || 0
                                                }
                                                value={medications[idx]["am"]}
                                                onChange={(e) =>
                                                    updateMedication(e, idx)
                                                }
                                            />
                                            <Checkbox
                                                label="PM"
                                                name="pm"
                                                defaultChecked={
                                                    parseInt(
                                                        medications[idx]["pm"]
                                                    ) || 0
                                                }
                                                value={medications[idx]["pm"]}
                                                onChange={(e) =>
                                                    updateMedication(e, idx)
                                                }
                                            />
                                        </div>
                                    </div>
                                    <div className="flex-1">
                                        <SelectInput
                                            label="Refill Frequency"
                                            options={[
                                                "None",
                                                "Monthly",
                                                "Quarterly",
                                                "Semi-Annually",
                                                "Annually",
                                                "Other",
                                            ]}
                                            name="refill_frequency"
                                            value={
                                                medications[idx][
                                                    "refill_frequency"
                                                ]
                                            }
                                            onChange={(e) =>
                                                updateMedication(e, idx)
                                            }
                                        />
                                    </div>
                                </div>
                                <div className="flex justify-end mb-3">
                                    <button
                                        type="button"
                                        onClick={() => removeMedication(idx)}
                                        className="text-gray-500 text-sm hover:text-red-500"
                                    >
                                        <FontAwesomeIcon
                                            icon={faTimesCircle}
                                            className="mr-1"
                                        />
                                        Remove Medication
                                    </button>
                                </div>
                            </div>
                        ))}
                    <div className="flex mt-6 text-right">
                        <button
                            type="button"
                            onClick={(e) => appendMedication(e)}
                            className="text-gray-500 text-sm hover:text-brand"
                        >
                            <FontAwesomeIcon
                                icon={faPlusCircle}
                                className="mr-1"
                            />{" "}
                            Add Medication
                        </button>
                    </div>
                </div>
            </div>

            <div className="mb-10 border-b border-gray-100 pb-6">
                <h2 className="mb-6 font-bold text-gray-500">Pharmacy</h2>
                {pharmacies.map((pharmacy, idx) => (
                    <div className="mb-10 border-b border-gray-200" key={idx}>
                        <div className="flex space-x-3 mb-6">
                            <div className="flex-1">
                                <TextInput
                                    id={`pharmacy_name_${idx}`}
                                    label="Pharmacy Name"
                                    name="name"
                                    value={pharmacies[idx]["name"]}
                                    onChange={(e) => updatePharmacy(e, idx)}
                                    autoFocus={pharmacies.length === idx + 1}
                                />
                            </div>
                            <div className="flex-1">
                                <TextInput
                                    type="tel"
                                    label="Phone"
                                    name="phone"
                                    value={pharmacies[idx]["phone"]}
                                    onChange={(e) => updatePharmacy(e, idx)}
                                />
                            </div>
                        </div>
                        <div className="mb-6">
                            <TextInput
                                label="Address"
                                name="address"
                                value={pharmacies[idx]["address"]}
                                onChange={(e) => updatePharmacy(e, idx)}
                            />
                        </div>
                        <div className="flex space-x-3 mb-6">
                            <div className="flex-1">
                                <TextInput
                                    type="email"
                                    label="Email"
                                    name="email"
                                    value={pharmacies[idx]["email"]}
                                    onChange={(e) => updatePharmacy(e, idx)}
                                />
                            </div>
                            <div className="flex-1">
                                <TextInput
                                    label="Website"
                                    name="website"
                                    value={pharmacies[idx]["website"]}
                                    onChange={(e) => updatePharmacy(e, idx)}
                                />
                            </div>
                        </div>
                        <div className="flex justify-end mb-3">
                            <button
                                type="button"
                                onClick={() => removePharmacy(idx)}
                                className="text-gray-500 text-sm hover:text-red-500"
                            >
                                <FontAwesomeIcon
                                    icon={faTimesCircle}
                                    className="mr-1"
                                />{" "}
                                Remove Pharamacy
                            </button>
                        </div>
                    </div>
                ))}
                <div className="flex">
                    <button
                        type="button"
                        onClick={(e) => appendPharmacy(e)}
                        className="text-gray-500 text-sm hover:text-brand"
                    >
                        <FontAwesomeIcon icon={faPlusCircle} className="mr-1" />{" "}
                        Add Pharamacy
                    </button>
                </div>
            </div>

            <div>
                <h2 className="mb-4 font-bold text-gray-500">
                    Boarding Facility
                </h2>
                {boardingFacilities.map((boardingFacility, idx) => (
                    <div className="mb-10 border-b border-gray-100" key={idx}>
                        <div className="flex space-x-3 mb-6">
                            <div className="flex-1">
                                <TextInput
                                    label="Facility Name"
                                    name="name"
                                    value={boardingFacilities[idx]["name"]}
                                    onChange={(e) =>
                                        updateBoardingFacility(e, idx)
                                    }
                                    autoFocus={
                                        boardingFacilities.length === idx + 1
                                    }
                                />
                            </div>
                            <div className="flex-1">
                                <TextInput
                                    label="Phone"
                                    name="phone"
                                    value={boardingFacilities[idx]["phone"]}
                                    onChange={(e) =>
                                        updateBoardingFacility(e, idx)
                                    }
                                />
                            </div>
                        </div>
                        <div className="mb-6">
                            <TextInput
                                label="Address"
                                name="address"
                                value={boardingFacilities[idx]["address"]}
                                onChange={(e) => updateBoardingFacility(e, idx)}
                            />
                        </div>
                        <div className="flex space-x-3 mb-6">
                            <div className="flex-1">
                                <TextInput
                                    type="email"
                                    label="Email"
                                    name="email"
                                    value={boardingFacilities[idx]["email"]}
                                    onChange={(e) =>
                                        updateBoardingFacility(e, idx)
                                    }
                                />
                            </div>
                            <div className="flex-1">
                                <TextInput
                                    label="Website"
                                    name="website"
                                    value={boardingFacilities[idx]["website"]}
                                    onChange={(e) =>
                                        updateBoardingFacility(e, idx)
                                    }
                                />
                            </div>
                        </div>
                        <div className="flex justify-end mb-3">
                            <button
                                type="button"
                                onClick={() => removeBoardingFacility(idx)}
                                className="text-gray-500 text-sm hover:text-red-500"
                            >
                                <FontAwesomeIcon
                                    icon={faTimesCircle}
                                    className="mr-1"
                                />{" "}
                                Remove Boarding Facility
                            </button>
                        </div>
                    </div>
                ))}
                <div className="flex">
                    <button
                        type="button"
                        onClick={(e) => appendBoardingFacility(e)}
                        className="text-gray-500 text-sm hover:text-brand"
                    >
                        <FontAwesomeIcon icon={faPlusCircle} className="mr-1" />{" "}
                        Add Boarding Facility
                    </button>
                </div>
            </div>
        </Fieldset>
    );
}
