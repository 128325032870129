import React, { useState } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import PageHeader from "../../components/PageHeader";
import PageContainer from "../../components/PageContainer";
import Button from "../../components/Button";
import Tabs from "../../components/Tabs";
import MembersTable from "./_partials/MembersTable";
import PendingInvitations from "./_partials/PendingInvitations";
import Modal from "../../components/Modal";
import InviteMembers from "./InviteMembers";
import useHasPermission from "../../hooks/usePermissions";
import Forbidden from "../../components/Forbidden";
import useAuth from "hooks/useAuth";

export default function MembersIndex(props) {
    const { user } = useAuth();
    const [showInviteMemberModal, setShowInviteMemberModal] = useState(false);
    const [newInviteCount, setNewInviteCount] = useState(0);
    const unauthorized = !useHasPermission(
        "firm_member_accounts_administration"
    );

    function handleUpdatePendingMembers() {
        setShowInviteMemberModal(false);
        setNewInviteCount((newInviteCount) => newInviteCount + 1);
    }

    if (user.type === "client") {
        return <Redirect to="/" />;
    }

    if (unauthorized) {
        return <Forbidden />;
    }

    return (
        <>
            <PageHeader text="Team Members">
                <Button
                    onClick={() => setShowInviteMemberModal(true)}
                    text="Add Team Members"
                ></Button>
            </PageHeader>
            <PageContainer>
                <Tabs
                    {...props}
                    items={[
                        { label: "Active", to: "/members" },
                        { label: "Deactivated", to: "/members/disabled" },
                        { label: "Pending", to: "/members/pending" },
                    ]}
                />
                <Switch>
                    <Route
                        path="/members/pending"
                        render={(props) => (
                            <PendingInvitations
                                {...props}
                                newInviteCount={newInviteCount}
                                setShowInviteMemberModal={
                                    setShowInviteMemberModal
                                }
                            />
                        )}
                    />
                    <Route
                        path="/members/disabled"
                        render={(props) => (
                            <MembersTable
                                {...props}
                                setShowInviteMemberModal={
                                    setShowInviteMemberModal
                                }
                                showDisabled={true}
                                emptyStateMessage="No members have been deactivated."
                            />
                        )}
                    />
                    <Route
                        path="/members"
                        render={(props) => (
                            <MembersTable
                                {...props}
                                showDisabled={false}
                                setShowInviteMemberModal={
                                    setShowInviteMemberModal
                                }
                            />
                        )}
                    />
                </Switch>
            </PageContainer>
            <Modal
                noWrapper={true}
                backgroundDismiss={true}
                title="Invite Members"
                isOpen={showInviteMemberModal}
                size="2xl"
                component={InviteMembers}
                onCancel={() => setShowInviteMemberModal(false)}
                onResult={() => handleUpdatePendingMembers()}
            />
        </>
    );
}
