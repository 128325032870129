import React, { useEffect } from "react";
import AccountNav from "./_partials/AccountNav";
import PageHeader from "../../components/PageHeader";
import PageContainer from "../../components/PageContainer";
import QuestionnaireTable from "views/questionnaires/_partials/QuestionnaireTable";

const Questionnaires = ({ member, ...props }) => {
    useEffect(() => {
        document.title = "Questionnaires";
    }, []);

    return (
        <>
            <PageHeader text="Questionnaires" />
            <PageContainer>
                <div className="flex space-x-10">
                    <div className="flex-initial">
                        <AccountNav active={props.history.location.pathname} />
                    </div>

                    <div className="flex-1">
                        <QuestionnaireTable member={member} />
                    </div>
                </div>
            </PageContainer>
        </>
    );
};

export default Questionnaires;
