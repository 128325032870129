import useFetch from "./useFetch";
import { fetchAllBeneficiaries } from "services/BeneficiaryService";

export default function useAllBeneficiaries(params, config) {
    return useFetch(
        ["fetchAllBeneficiaries", params],
        fetchAllBeneficiaries,
        config
    );
}
