import React, { useEffect } from "react";
import BeneficiaryList from "./BeneficiaryList";
import Spinner from "../Spinner";
import TextInput from "../TextInput";
import Button from "../Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import useBeneficiaries from "./useBeneficiary";
import Panel from "components/Panel";
import UnsavedChanges from "../UnsavedChanges";

export const resourceTypes = [
    "Client",
    "Relationship",
    "Trust",
    "BusinessInterest",
];

export default function Beneficiary({
    showProposed = false,
    client,
    resourceType,
    resourceId,
    disabled = false,
}) {
    const {
        hasUnsavedChanges,
        removeBeneficiary,
        fetchBeneficiaries,
        beneficiaries,
        setBeneficiaries,
        loading,
        errors,
        appendBeneficiary,
        verifiedAt,
        setVerifiedAt,
        save,
        saving,
    } = useBeneficiaries(client, resourceType, resourceId, showProposed);

    useEffect(() => {
        fetchBeneficiaries({ client, resourceType, resourceId });
        return () => {
            setBeneficiaries([]);
        };
    }, [
        client,
        resourceType,
        resourceId,
        fetchBeneficiaries,
        setBeneficiaries,
    ]);

    if (loading) {
        return <Spinner message="Loading..." />;
    }

    return (
        <Panel>
            <UnsavedChanges when={hasUnsavedChanges} />
            <BeneficiaryList
                client={client}
                errors={errors}
                beneficiaries={beneficiaries}
                setBeneficiaries={setBeneficiaries}
                removeBeneficiary={removeBeneficiary}
                disabled={disabled}
                required={true}
            />
            <AppendBeneficiary
                disabled={disabled}
                beneficiaries={beneficiaries}
                appendBeneficiary={appendBeneficiary}
                hasUnsavedChanges={hasUnsavedChanges}
                saving={saving}
                verifiedAt={verifiedAt}
                setVerifiedAt={setVerifiedAt}
                save={save}
            />
        </Panel>
    );
}

export function AppendBeneficiary({
    disabled,
    beneficiaries,
    appendBeneficiary,
    hasUnsavedChanges,
    saving,
    verifiedAt,
    setVerifiedAt,
    save,
}) {
    return (
        <div className="w-full flex items-center justify-end mt-6 space-x-3">
            <button
                type="button"
                onClick={appendBeneficiary}
                className="flex items-center space-x-1 text-gray-500 text-sm hover:text-brand p-2 mr-auto"
            >
                <FontAwesomeIcon icon={faPlusCircle} />{" "}
                <span>Add Beneficiary</span>
            </button>
            {beneficiaries.length > 0 && (
                <>
                    <div>
                        <div className="flex items-center space-x-3">
                            <div>
                                <TextInput
                                    type="date"
                                    name="verified_at"
                                    value={verifiedAt}
                                    onChange={(e) =>
                                        setVerifiedAt(e.target.value)
                                    }
                                />
                            </div>
                        </div>
                    </div>
                    <div className="w-40">
                        <Button
                            isLoading={saving}
                            onClick={save}
                            disabled={disabled || saving || !hasUnsavedChanges}
                            text="Save"
                        />
                    </div>
                </>
            )}
        </div>
    );
}

// Service stuff
export function beneficiaryTypes({ showOptions = "default" } = {}) {
    let defaultOptions = [
        { value: "", label: "Choose an option...", disable: true },
        { value: "CP", label: "Community Property" },
        { value: "TBE", label: "Tenants-by-Entireties" },
        { value: "JT", label: "Joint with Spouse" },
        { value: "OWNER", label: "Owner" },
    ];

    let otherOptions = [
        { value: "", label: "Choose an option...", disable: true },
        { value: "ADD_OWNER", label: "Additional Owner" },
        // { value: "JTO", label: "Joint w/Other" },
        { value: "CJTO", label: "Client Joint w/Other" },
        { value: "SJTO", label: "Spouse Joint w/Other" },
        { value: "TCO", label: "Tenants in Common" },
        { value: "OTH", label: "Other" },
    ];

    if (showOptions === "default") {
        return defaultOptions;
    }

    if (showOptions === "other") {
        return otherOptions;
    }

    if (showOptions === "all") {
        return defaultOptions.concat(otherOptions);
    }
}
