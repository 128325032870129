import React from "react";
import Dropdown, { DropdownItem } from "./Dropdown";
import ModalModelFactory from "./ModalModelFactory";
import useQuickAdd from "../hooks/useQuickAdd";
import { faCaretDown, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "./Button";

export default function QuickAddProvider({
    client,
    children,
    onSave = () => {},
}) {
    const {
        quickAddOpen,
        quickAddType,
        setQuickAddOpen,
        setQuickAddType,
        quickAddTitle,
        setQuickAddTitle,
        quickAddAssets,
    } = useQuickAdd();

    return (
        <>
            {children({
                quickAddOpen,
                setQuickAddOpen,
                quickAddType,
                setQuickAddType,
                quickAddTitle,
                setQuickAddTitle,
                quickAddAssets,
                client,
            })}
            <ModalModelFactory
                isOpen={quickAddOpen}
                client={client}
                user={[]}
                title={`Add ${quickAddTitle}`}
                modelType={quickAddType}
                onClose={() => {
                    setQuickAddOpen(false);
                    setQuickAddType("");
                    setQuickAddTitle("");
                }}
                onSuccess={(data) => {
                    setQuickAddOpen(false);
                    setQuickAddType("");
                    setQuickAddTitle("");
                    onSave(data);
                }}
            />
        </>
    );
}

export function QuickAddButton({
    text = "Quick Add",
    position = "right",
    options = ["BusinessInterest", "Relationship", "Trust"],
    quickAddAssets = [],
    appearance = "outline",
    ...props
}) {
    if (props.singleAction) {
        return (
            <div className="w-32 inline-flex items-center space-x-3">
                <Button
                    type="button"
                    appearance="light"
                    onClick={() => {
                        props.setQuickAddType(props.singleAction);
                        props.setQuickAddTitle(props.singleAction);
                        props.setQuickAddOpen(true);
                    }}
                >
                    {text}
                    <FontAwesomeIcon
                        icon={faPlus}
                        className="text-sm ml-2 text-gray-600"
                    />
                </Button>
            </div>
        );
    }

    if (props.children) {
        return (
            <>
                <Dropdown position={position} toggleContent={props.children}>
                    {quickAddAssets
                        .filter((item) => options.includes(item.type))
                        .map((item) => (
                            <DropdownItem
                                key={item.type}
                                label={item.label}
                                handleClick={() => {
                                    props.setQuickAddType(item.type);
                                    props.setQuickAddTitle(item.label);
                                    props.setQuickAddOpen(true);
                                }}
                            />
                        ))}
                </Dropdown>
            </>
        );
    }

    return (
        <>
            <Dropdown
                toggleContent={null}
                position={position}
                toggleButton={
                    <>
                        {text}
                        <FontAwesomeIcon icon={faCaretDown} className="ml-2" />
                    </>
                }
                toggleButtonAppearance={appearance}
            >
                {quickAddAssets
                    .filter((item) => options.includes(item.type))
                    .map((item) => (
                        <DropdownItem
                            key={item.type}
                            label={item.label}
                            handleClick={() => {
                                props.setQuickAddType(item.type);
                                props.setQuickAddTitle(item.label);
                                props.setQuickAddOpen(true);
                            }}
                        />
                    ))}
            </Dropdown>
        </>
    );
}
