import React, { useState, useEffect } from "react";
import ClientPageHeader, { Heading } from "../../components/ClientPageHeader";
import PageContainer from "../../components/PageContainer";
import Api from "../../services/Api";
import { useToasts } from "react-toast-notifications";
import Spinner from "../../components/Spinner";
import { formatDate } from "../../util/TextUtil";
import Button from "../../components/Button";
import { downloadFile } from "../../util/Downloader";
import Panel from "../../components/Panel";
import Money, { parseMoney } from "../../components/Money";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";
import useClient from "hooks/useClient";

export default function Transferees({
    client,
    title,
    pathname,
    isPostMortem = false,
    forbidden,
}) {
    document.title = title;

    const { clientPath } = useClient();
    const [loadingChanges, setLoadingChanges] = useState(false);
    const [transfers, setTransfers] = useState([]);
    const [isExporting, setIsExporting] = useState(false);
    const { addToast } = useToasts();

    useEffect(() => {
        async function fetchData() {
            try {
                setLoadingChanges(true);
                let response = await Api.get(
                    "clients/" + client.id + "/reports/" + pathname
                );
                setTransfers(response.data);
                setLoadingChanges(false);
            } catch (error) {
                setLoadingChanges(false);
                console.log(error);
            }
        }
        fetchData();
    }, [client.id, pathname]);

    async function exportRecords(e) {
        e.preventDefault();
        try {
            setIsExporting(true);
            let response = await Api.get(
                "clients/" + client.id + "/reports/" + pathname,
                {
                    export: "pdf",
                },
                { responseType: "arraybuffer" }
            );
            await downloadFile(response, pathname);
            setIsExporting(false);
            addToast("Export downloaded");
        } catch (e) {
            setIsExporting(false);
            addToast(e.response.data.message, { type: "error" });
        }
    }

    return (
        <>
            <ClientPageHeader client={client}>
                <Heading
                    backTo={`${clientPath}/reports`}
                    backText="All Reports"
                >
                    {title}
                </Heading>
                {transfers.length > 0 && !forbidden && (
                    <Button
                        text="Download"
                        appearance="outline"
                        width="w-auto"
                        isLoading={isExporting}
                        onClick={exportRecords}
                    />
                )}
            </ClientPageHeader>
            <PageContainer forbidden={forbidden}>
                {loadingChanges ? (
                    <Spinner message="Loading Transfers" />
                ) : (
                    <>
                        <div className="space-y-6 mt-6">
                            {transfers.map((transfer) => {
                                return (
                                    <Panel key={transfer.title}>
                                        <div className="border-b border-gray-200 flex justify-between pb-4">
                                            <h2 className="text-sm">
                                                <span className="font-bold text-base">
                                                    {transfer.title}
                                                </span>
                                            </h2>
                                            <div className="text-sm space-x-5">
                                                <span className="text-small space-x-1">
                                                    <span className="font-normal">
                                                        Total Transferred:
                                                    </span>
                                                    <span className="font-bold">
                                                        <Money
                                                            value={
                                                                transfer.total_transferred
                                                            }
                                                        />
                                                    </span>
                                                </span>
                                                {!isPostMortem && (
                                                    <span className="text-small space-x-1">
                                                        <span className="font-normal">
                                                            Total Basic
                                                            Exclusion:
                                                        </span>
                                                        <span className="font-bold">
                                                            <Money
                                                                value={
                                                                    transfer.total_basic_exclusion
                                                                }
                                                            />
                                                        </span>
                                                    </span>
                                                )}
                                            </div>
                                        </div>
                                        <div className="space-y-10">
                                            {transfer.assets.map(
                                                (asset, idxa) => {
                                                    return (
                                                        <table
                                                            className="w-full table-fixed"
                                                            key={`asset_${asset.id}_${idxa}`}
                                                        >
                                                            {idxa === 0 && (
                                                                <TransferTableHeader
                                                                    index={idxa}
                                                                    isPostMortem={
                                                                        isPostMortem
                                                                    }
                                                                />
                                                            )}
                                                            <IndividualAsset
                                                                transfer={
                                                                    transfer
                                                                }
                                                                key={`${transfer.title}_${asset.asset_name}_${idxa}`}
                                                                isPostMortem={
                                                                    isPostMortem
                                                                }
                                                                asset={asset}
                                                            />
                                                        </table>
                                                    );
                                                }
                                            )}
                                        </div>
                                    </Panel>
                                );
                            })}
                        </div>
                    </>
                )}
                {transfers.length === 0 && !loadingChanges && (
                    <div>No transfers were found.</div>
                )}
            </PageContainer>
        </>
    );
}

export function TransferTableHeader({ isPostMortem }) {
    return (
        <thead>
            <tr className="text-gray-500 uppercase text-left">
                <th className="text-xs px-0">Transfer</th>
                <th className="text-xs text-center">Percentage</th>
                <th className="text-xs">As of Date</th>
                <th className="text-xs text-right">Value</th>
                <th className="text-xs text-center">Complete</th>
                {!isPostMortem && <th className="text-xs text-center">Gift</th>}
                {!isPostMortem && (
                    <th className="text-xs text-right">Basic Exclusion</th>
                )}
            </tr>
        </thead>
    );
}

export function IndividualAsset({ asset, isPostMortem }) {
    if (asset.transfers.length === 0) {
        return (
            <tbody>
                <tr className="font-bold text-gray-600">
                    <td
                        className="pb-3 pt-0"
                        colSpan={isPostMortem ? "5" : "7"}
                    >
                        <div className="w-32">
                            <span>{asset.title}</span>
                        </div>
                    </td>
                </tr>
                <tr
                    colSpan={isPostMortem ? "5" : "7"}
                    className="text-gray-600 text-sm pb-3"
                >
                    No transfers for this asset were found...
                </tr>
            </tbody>
        );
    }
    return (
        <tbody>
            <tr className="font-bold text-gray-600 pt-11">
                <td
                    className="pb-3 pt-0 px-0"
                    colSpan={isPostMortem ? "5" : "7"}
                >
                    <div className="w-50">
                        <a
                            href={asset.transfers[0].asset_resource_path}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <span>
                                <span className="block text-xs text-gray-400">
                                    {asset.type}
                                </span>
                                <span>
                                    {asset.title}
                                    &nbsp;
                                    <FontAwesomeIcon
                                        icon={faExternalLinkAlt}
                                        className="text-gray-500 hover:text-brand text-xs"
                                    />
                                </span>
                                {asset.account_number && (
                                    <span className="text-xs text-gray-400 block">
                                        {asset.account_number}
                                    </span>
                                )}
                            </span>
                        </a>
                    </div>
                </td>
            </tr>
            {asset.transfers.map((t, idxt) => {
                return (
                    <tr key={`${t.owner}_${idxt}`} className="text-gray-600">
                        <td className="pb-3 pt-0 pl-2 w-32">{t.transferee}</td>
                        {t.transfer_type === "ownership" && (
                            <td className="pb-3 pt-0 w-20 text-center">
                                {t.transfer_percentage
                                    ? parseFloat(t.transfer_percentage) + "%"
                                    : "n/a"}
                            </td>
                        )}
                        {t.transfer_type === "value" && (
                            <td className="pb-3 pt-0 w-20 text-center">
                                Value Transfer
                            </td>
                        )}
                        <td className="pb-3 pt-0">
                            {formatDate(
                                t.as_of_date || t.original_asset_as_of_date,
                                false
                            )}
                        </td>
                        <td className="pb-3 pt-0 text-right tabular-nums">
                            {t.transfer_value}
                        </td>
                        <td className="pb-3 pt-0 text-center">
                            {t.verified_at !== null ? (
                                formatDate(t.verified_at, false)
                            ) : (
                                <span className="text-red-500 font-bold text-xl text-center">
                                    &times;
                                </span>
                            )}
                        </td>
                        {!isPostMortem && (
                            <td className="p-1 text-center">
                                {parseInt(t.is_gift) === 0 ? "No" : "Yes"}
                            </td>
                        )}
                        {!isPostMortem && (
                            <td className="pb-3 pt-0 text-right tabular-nums">
                                <Money value={parseMoney(t.basic_exclusion)} />
                            </td>
                        )}
                    </tr>
                );
            })}
            <tr className="bg-gray-100 py-0">
                <td
                    colSpan="4"
                    className="text-right font-semibold space-x-3 py-1"
                >
                    <span className="text-gray-500 mr-3 font-normal">
                        Total Transferred from {asset.title.replace("|", ": ")}:
                    </span>
                    <span className="tabular-nums">
                        {asset.is_liability ? (
                            <span className="text-red-500">
                                <Money value={asset.total_transferred} />
                            </span>
                        ) : (
                            <Money value={asset.total_transferred} />
                        )}
                    </span>
                </td>
                {!isPostMortem && (
                    <td colSpan="3" className="text-right font-semibold py-1">
                        <span className="tabular-nums">
                            <Money value={asset.total_basic_exclusion} />
                        </span>
                    </td>
                )}
                {isPostMortem && (
                    <td colSpan="1" className="text-right font-semibold py-1">
                        <span className="tabular-nums"></span>
                    </td>
                )}
            </tr>
        </tbody>
    );
}
