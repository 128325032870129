import PageContainer from "components/PageContainer";
import React from "react";
import PageHeader from "../../components/PageHeader";
import DashboardLayout from "../../views/layouts/DashboardLayout";
import ChangeLogTable from "./_partials/ChangeLogTable";

export default function ShowAllMemberChanges({ client = null }) {
    return (
        <DashboardLayout>
            <PageHeader
                text="Notice Of Changes"
                backTo={"/"}
                backText="Dashboard"
            />
            <PageContainer>
                <ChangeLogTable limit={5} showFilters={true} client={client} />
            </PageContainer>
        </DashboardLayout>
    );
}
