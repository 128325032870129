import React from "react";
import Transition from "./Transition";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "./Button";

export default function BasicModal({
    isOpen,
    children,
    onClose,
    size = "sm",
    title = "",
    header = "",
    modalBodyClassName = "p-6",
    overFlow = "overflow-y-auto",
}) {
    return (
        <Transition
            show={!!isOpen}
            enter="transition ease-out duration-300 transform"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition ease-in duration-75 transform"
            leaveFrom="opacity-0"
            leaveTo="opacity-0"
        >
            <div
                className="fixed z-50 inset-0 flex justify-center max-h-screen"
                onClick={onClose}
            >
                <div className="z-50 relative w-full p-3 flex items-center justify-center">
                    <div
                        className={`relative max-h-full w-full h-full sm:h-auto z-50 bg-white sm:rounded-md shadow-xl ${overFlow} ${
                            size ? "max-w-" + size : ""
                        }`}
                        onClick={(e) => e.stopPropagation()}
                    >
                        {header}
                        {title && (
                            <div className="flex justify-between items-center mb-6 p-6">
                                <h3 className="text-2xl leading-6 font-medium text-gray-900">
                                    {title}
                                </h3>
                                <button
                                    className={`right-0`}
                                    type="button"
                                    onClick={onClose}
                                >
                                    <FontAwesomeIcon
                                        icon={faTimesCircle}
                                        className="text-gray-400 hover:text-brand text-lg"
                                    />
                                </button>
                            </div>
                        )}
                        <div className={modalBodyClassName}>{children}</div>
                    </div>
                </div>
                <div className="absolute z-40 inset-0 bg-gray-800 opacity-75"></div>
            </div>
        </Transition>
    );
}

export function BasicModalHeader({
    onClose,
    onSave,
    title = "",
    showSave = true,
    saveText = "Save",
    disabled = false,
}) {
    return (
        <div className="sticky top-0 z-10 flex items-center justify-between border-b border-gray-300 mb-4 px-6 py-3 bg-white">
            <h2 className="text-2xl text-gray-700">{title}</h2>
            <div className="flex items-center justify-end space-x-2 ml-auto">
                <div className="w-32">
                    <Button
                        type="button"
                        appearance="light"
                        className="bg-white"
                        text="Cancel"
                        onClick={onClose}
                    />
                </div>
                {showSave && (
                    <div className="max-w-md">
                        <Button
                            type="button"
                            appearance="default"
                            text={saveText}
                            onClick={onSave}
                            disabled={disabled}
                        />
                    </div>
                )}
            </div>
        </div>
    );
}
