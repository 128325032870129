import React from "react";
import Spinner from "../../components/Spinner";
import useAuth from "../../hooks/useAuth";
import ICEContextProvider from "./ICEGuestContext";
import ICEGuestWrapper from "./ICEGuestWrapper";

export default function ICEGuest() {
    const { isLoadingSettings } = useAuth();

    if (isLoadingSettings) {
        return <Spinner />;
    }

    return (
        <ICEContextProvider>
            <ICEGuestWrapper />
        </ICEContextProvider>
    );
}
