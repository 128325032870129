import usePost from "./usePost";
import { updateMemberRole } from "services/PermissionService";

export default function useUpdateMemberRole(memberId) {
    const { mutation, validationErrors } = usePost(
        (formData) => updateMemberRole(memberId, formData),
        {
            showToastOnError: true,
            showToastOnSuccess: true,
        }
    );
    const onSubmit = (event, formData) => {
        event.preventDefault();
        return mutation.mutate(formData);
    };

    return { onSubmit, ...mutation, validationErrors };
}
