import useFetch from "./useFetch";
import { fetchPostMortemBeneficiaries } from "../services/PostMortemService";

export default function usePostMortemBeneficiaries(params, config) {
    return useFetch(
        ["fetchPostMortemBeneficiaries", params],
        fetchPostMortemBeneficiaries,
        config
    );
}
