import React, { useState } from "react";
import Button from "../../components/Button";
import TextInput from "../../components/TextInput";
import { TableCell, TableRow } from "../../components/Table";
import { Link } from "react-router-dom";
import useFormatCurrency from "../../hooks/useFormatCurrency";
import { formatDateForInput } from "util/TextUtil";

export default function UpdateLienValue({ name, lien, onSave, errors }) {
    const [value, setValue] = useState(lien.balance);
    const [asOfDate, setAsOfDate] = useState(
        formatDateForInput(lien.balance_as_of_date)
    );
    const formatCurrency = useFormatCurrency();

    function save(e) {
        onSave(e, {
            value,
            as_of_date: asOfDate,
            asset_type: lien.resource_type,
            asset_id: lien.id,
        });
    }

    return (
        <TableRow>
            <TableCell className="align-middle">
                <div className="ml-3 flex items-start space-x-2">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-3 w-3 transform rotate-180 inline-block text-gray-500 mt-1"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                    >
                        <path
                            fillRule="evenodd"
                            d="M7.707 3.293a1 1 0 010 1.414L5.414 7H11a7 7 0 017 7v2a1 1 0 11-2 0v-2a5 5 0 00-5-5H5.414l2.293 2.293a1 1 0 11-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                            clipRule="evenodd"
                        />
                    </svg>

                    <div>
                        <h2 className="text-base font-semibold text-gray-800">
                            <Link to={`${lien.resource_path}`}>{name}</Link>
                        </h2>
                        <div className="space-y-1">
                            {lien.account_number && (
                                <span className="text-xs text-gray-500 block mt-1">
                                    {lien.account_number}
                                </span>
                            )}
                            <span className="text-sm text-gray-500">
                                {lien.resource_type_formatted}
                            </span>
                        </div>
                    </div>
                </div>
            </TableCell>
            <TableCell className="align-middle">
                <TextInput
                    id={`value_${lien.id}`}
                    type="text"
                    name="value"
                    value={value}
                    onChange={(e) => setValue(e.target.value)}
                    onBlur={(e) => {
                        e.target.value = formatCurrency(e.target.value);
                        setValue(e.target.value);
                    }}
                    placeholder="0.00"
                    error={errors.value}
                    tabIndex={1}
                />
            </TableCell>
            <TableCell className="align-middle">
                <TextInput
                    id={`as_of_date_${lien.id}`}
                    type="date"
                    name="as_of_date"
                    value={asOfDate}
                    onChange={(e) => setAsOfDate(e.target.value)}
                    onBlur={save}
                    error={errors.as_of_date}
                    tabIndex={1}
                />
            </TableCell>
            <TableCell className="align-middle">
                <Button
                    type="button"
                    text="Save"
                    tabIndex={1}
                    onClick={save}
                    appearance={"outline"}
                />
            </TableCell>
        </TableRow>
    );
}
