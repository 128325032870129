import React from "react";
import { useLocation } from "react-router";
import OrganizationNav from "./OrganizationNav";

export default function OrganizationPageLayout({ children }) {
    const location = useLocation();
    return (
        <div className="flex space-x-10">
            <div className="flex-initial">
                <OrganizationNav active={location.pathname} />
            </div>
            <div className="flex-1">{children}</div>
        </div>
    );
}
