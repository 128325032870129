import React from "react";
import ClientPageHeader, { Heading } from "../../components/ClientPageHeader";
import PageContainer from "../../components/PageContainer";
import AdvisorNav from "./_partials/AdvisorNav";
import Badge from "components/Badge";
import { isBool } from "index";
import ClientNotes from "views/clients/Notes/ClientNotes";

export default function AdvisorNotes({
    client,
    clientPath = null,
    advisor,
    ...props
}) {
    return (
        <>
            <ClientPageHeader client={client} text="Notes">
                <Heading
                    backText="All Advisors"
                    backTo={`${clientPath}/advisors`}
                >
                    <div className="space-x-3">
                        <span>Notes for {advisor.full_name}</span>
                        {isBool(advisor.can_collaborate) && (
                            <Badge color="green">Collaborator</Badge>
                        )}
                    </div>
                </Heading>
            </ClientPageHeader>
            <PageContainer>
                <div className="flex space-x-10">
                    <div className="flex-initial">
                        <AdvisorNav
                            clientPath={clientPath}
                            active={props.history.location.pathname}
                            advisor={advisor}
                        />
                    </div>
                    <div className="flex-1">
                        <ClientNotes
                            client={client}
                            resourceType="Advisor"
                            resourceId={advisor.id}
                            {...props}
                        />
                    </div>
                </div>
            </PageContainer>
        </>
    );
}
