import React from "react";
import SelectInput from "../../../components/SelectInput";
import TextInput from "../../../components/TextInput";

const allOptions = {
    "Farm Lease": [
        { value: "Cash Rent", label: "Cash Rent" },
        { value: "Share Crop", label: "Share Crop" },
    ],
};

export default function LeaseSubtypeSelect({ ...props }) {
    React.useEffect(() => {
        if (!allOptions[props.lease_type_id]) {
            if (props.lease_subtype_id) {
                props.onChange({
                    target: { name: "lease_subtype_id", value: null },
                });
            }
            if (props.lease_subtype_name) {
                props.onChange({
                    target: { name: "lease_subtype_name", value: null },
                });
            }
        } else {
            const options = allOptions[props.lease_type_id];
            if (props.lease_subtype_id === null) {
                props.onChange({
                    target: {
                        name: "lease_subtype_id",
                        value: options[0].value,
                    },
                });
            }
        }
    }, [props]);

    if (!allOptions[props.lease_type_id]) {
        return <></>;
    }

    const options = allOptions[props.lease_type_id];

    const updateType = (e) => {
        const selectedValue = e.target.value;
        let optionText = options.filter((opt) => {
            return opt.value === selectedValue;
        });
        if (optionText.value === "other") {
            optionText = "";
        }
        props.onChange({
            target: { name: "lease_subtype_id", value: selectedValue },
        });
        props.onChange({
            target: { name: "lease_subtype_name", value: optionText.label },
        });
    };

    return (
        <div className="flex space-x-3 mt-4">
            <div className="flex-1">
                <SelectInput
                    name="lease_subtype_id"
                    label={props.lease_type_id + " Type"}
                    options={options}
                    loading={false}
                    onChange={updateType}
                    {...props}
                />
            </div>
            {props.value === "Other" && (
                <div className="flex-1">
                    <TextInput
                        id="lease_subtype_name"
                        label={props.lease_subtype_id + " Name"}
                        name="lease_subtype_name"
                        value={props.lease_subtype_name}
                        onChange={props.onChange}
                        disabled={props.readOnly}
                        readOnly={props.readOnly}
                    />
                </div>
            )}
        </div>
    );
}
