import React from "react";
import { Route, Switch } from "react-router-dom";
import LeasesIndex from "./LeasesIndex";
import AddLease from "./AddLease";
import EditLease from "./EditLease";

export default function Leases(parentProps) {
    return (
        <Switch>
            <Route
                exact
                path={["/clients/:clientId/leases", "/client/leases"]}
                render={(props) => <LeasesIndex {...parentProps} {...props} />}
            />
            <Route
                exact
                path={["/clients/:clientId/leases/add", "/client/leases/add"]}
                render={(props) => <AddLease {...parentProps} {...props} />}
            />
            <Route
                path={[
                    "/clients/:clientId/leases/:leaseId/edit",
                    "/client/leases/:leaseId/edit",
                ]}
                render={(props) => <EditLease {...parentProps} {...props} />}
            />
            <Route
                path={[
                    "/clients/:clientId/leases/:leaseId/view",
                    "/client/leases/:leaseId/view",
                ]}
                render={(props) => (
                    <EditLease readOnly={true} {...parentProps} {...props} />
                )}
            />
        </Switch>
    );
}
