import React, { useState } from "react";
import { Link, Redirect } from "react-router-dom";
import TextInput from "../../components/TextInput";
import Button from "../../components/Button";
import Auth from "../../services/Auth";
import { getProp } from "../../Utility";
import LoginSplash from "./LoginSplash";

import ReCAPTCHA from "react-google-recaptcha";

export default function ForgotPassword({ ...props }) {
    const recaptchaRef = React.useRef(null);

    const [redirectToLogin, setRedirectToLogin] = useState(false);
    const [email, setEmail] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [errors, setErrors] = useState({
        email: null,
    });

    document.title = "Forgot Password";

    const forgot = async (e) => {
        e.preventDefault();
        try {
            const recaptcha = await recaptchaRef.current.executeAsync();
            recaptchaRef?.current?.reset();
            await Auth.forgot({ email, recaptcha });
            setRedirectToLogin(true);
        } catch (e) {
            const errors = getProp(e, "response.data.errors");
            if (errors) {
                setErrors(errors);
            } else {
                setErrorMessage(e.response.data.message);
                setErrors({
                    email: null,
                });
            }
        }
    };

    if (redirectToLogin === true) {
        return (
            <Redirect
                to={{
                    path: "/login",
                    state: {
                        message:
                            "An email has been sent with instructions on how to reset your password.",
                    },
                }}
            />
        );
    }

    return (
        <div className="h-auto min-h-screen flex">
            <div className="relative bg-white text-gray-700 w-full flex-initial flex items-center justify-center md:max-w-xl">
                <div className="max-w-xs w-full">
                    <h1 className="text-3xl mb-8 text-center">
                        Forgot Password
                    </h1>
                    {props.location.state && (
                        <div className="max-w-xs text-red-600 my-3 text-center">
                            {props.location.state.message}
                        </div>
                    )}
                    {errorMessage && (
                        <div className="max-w-xs text-red-600 my-3 text-center">
                            {errorMessage}
                        </div>
                    )}
                    <form onSubmit={forgot}>
                        <ReCAPTCHA
                            ref={recaptchaRef}
                            size="invisible"
                            sitekey={process.env.REACT_APP_NOCAPTCHA_SITEKEY}
                        />

                        <div className="mb-6">
                            <TextInput
                                name="email"
                                label="Email Address"
                                id="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                error={errors.email}
                                autoFocus={true}
                            />
                        </div>

                        <Button
                            type="submit"
                            text="Forgot Password"
                            className="w-64 mx-auto block bg-gray-800 hover:bg-gray-700 text-white border border-gray-800"
                        />
                    </form>

                    <div className="mt-8 text-center">
                        <Link to="/login" className="text-brand-600 text-sm">
                            Back to Login
                        </Link>
                    </div>
                </div>
            </div>
            <LoginSplash />
        </div>
    );
}
