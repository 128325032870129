import React, { useState } from "react";
import TextArea from "../../../components/TextArea";
import TextInput from "../../../components/TextInput";
import TimePicker from "../../../components/TimePicker";
import SearchableSelect from "../../../components/SearchableSelect";
import { fetchAttorneys } from "../../../services/MemberService";
import debounce from "../../../util/debounce-promise";
import useClient from "../../../hooks/useClient";

export default function AppointmentForm({
    input,
    errors,
    onSubmit,
    updateValue,
    readOnly = false,
}) {
    const getAttorneyOptions = debounce(async (e) => {
        const options = await fetchAttorneys(e);
        setLastOptions(options);
        return options;
    }, 250);
    const { client } = useClient();
    const [lastOptions, setLastOptions] = useState([]);

    return (
        <form onSubmit={onSubmit} className="space-y-6 max-w-3xl mx-auto">
            <TextInput
                type="text"
                label="Title"
                name="title"
                id="title"
                value={input.title}
                error={errors.title}
                onChange={updateValue}
                autoFocus={true}
                readOnly={readOnly}
            />
            <div className="flex space-x-2 items-end">
                <TextInput
                    type="date"
                    label="Appointment Date"
                    name="date"
                    id="date"
                    value={input.date}
                    error={errors.date}
                    onChange={updateValue}
                    readOnly={readOnly}
                />
                <TimePicker
                    hour={input.hour}
                    minute={input.minute}
                    meridiem={input.meridiem}
                    updateHour={updateValue}
                    updateMinute={updateValue}
                    updateMeridiem={updateValue}
                    readOnly={readOnly}
                />
            </div>
            <div>
                <SearchableSelect
                    label={`Which attorney will ${client.full_name} be meeting with?`}
                    id="primary_attorney_id"
                    placeholder="Select an attorney"
                    onChange={(selection) => {
                        console.log(selection);
                        updateValue({
                            target: {
                                name: "member_id",
                                value: selection.value,
                            },
                        });
                    }}
                    cacheOptions
                    loadOptions={getAttorneyOptions}
                    defaultOptions
                    value={lastOptions.find(
                        (option) =>
                            parseInt(option.value) === parseInt(input.member_id)
                    )}
                    onInputChange={getAttorneyOptions}
                />
            </div>
            <TextArea
                rows="3"
                type="text"
                label="Details"
                name="description"
                id="description"
                value={input.description}
                error={errors.description}
                onChange={updateValue}
                readOnly={readOnly}
            />
            <input type="submit" hidden />
        </form>
    );
}
