import React, { Component } from "react";
import { Route, Redirect, Switch } from "react-router-dom";
import { lazy } from "react";
import Auth from "../../services/Auth";
import DashboardLayout from "../layouts/DashboardLayout";
import Loading from "../../components/Loading";
import Questionnaires from "./Questionnaires";
import SwitchAccount from "./SwitchAccount";
const EditProfile = lazy(() => import("./EditProfile"));
const EditPassword = lazy(() => import("./EditPassword"));
const EditTwoFactorAuth = lazy(() => import("./EditTwoFactorAuth"));

class Account extends Component {
    constructor(props) {
        super(props);
        this.state = {
            member: null,
        };
    }

    async componentDidMount() {
        this.fetchMember();
    }

    async fetchMember() {
        const response = await Auth.check();
        this.setState({
            member: response.data.user,
        });
    }

    render() {
        if (!this.state.member) {
            return (
                <DashboardLayout {...this.props}>
                    <Loading />
                </DashboardLayout>
            );
        }
        return (
            <DashboardLayout {...this.props}>
                <Switch>
                    <Route
                        path="/account/profile"
                        render={(props) => (
                            <EditProfile
                                {...props}
                                member={this.state.member}
                                update={() => this.fetchMember()}
                            />
                        )}
                    />
                    <Route
                        path="/account/password"
                        render={(props) => (
                            <EditPassword
                                {...props}
                                member={this.state.member}
                                update={() => this.fetchMember()}
                            />
                        )}
                    />
                    <Route
                        path="/account/two-factor-auth"
                        render={(props) => (
                            <EditTwoFactorAuth
                                {...props}
                                member={this.state.member}
                                update={() => this.fetchMember()}
                            />
                        )}
                    />
                    <Route
                        path="/account/questionnaires"
                        render={(props) => (
                            <Questionnaires
                                user={undefined}
                                {...props}
                                member={this.state.member}
                                update={() => this.fetchMember()}
                            />
                        )}
                    />
                    <Route
                        exact
                        path="/account/switch"
                        render={(props) => (
                            <SwitchAccount
                                {...props}
                                member={this.state.member}
                                update={() => this.fetchMember()}
                            />
                        )}
                    />
                    <Route
                        exact
                        path={this.props.match.url}
                        render={() => <Redirect to="/account/profile" />}
                    />
                </Switch>
            </DashboardLayout>
        );
    }
}

export default Account;
