import React, { useEffect } from "react";
import { faCaretDown, faCaretUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useHistory } from "react-router-dom";
import useQuery from "../hooks/useQuery";

export default function TableSortHeader({
    children,
    field,
    sortField,
    sortDir,
    changeSort,
    allowSearchParams = true,
    disabled = false,
}) {
    const history = useHistory();

    const query = useQuery();

    useEffect(() => {
        if (!allowSearchParams) {
            return;
        }
        const params = query;
        if (sortField) {
            params.set(
                "orderBy",
                sortDir === "desc" ? "-" + sortField : sortField
            );
        } else {
            params.delete("orderBy");
        }
        history.replace({
            search: params.toString(),
        });
    }, [sortField, sortDir, allowSearchParams]);

    if (disabled) {
        return (
            <th className="text-sm">
                <span className={`text-gray-800`}>{children}</span>
            </th>
        );
    }

    return (
        <th
            className="cursor-pointer text-sm select-none"
            onClick={() => changeSort(field)}
        >
            <div className="flex items-center space-x-4">
                <span
                    className={`${
                        sortField === field ? "text-gray-900" : "text-gray-800"
                    } hover:underline`}
                >
                    {children}
                </span>
                <span>
                    <FontAwesomeIcon
                        className={`${
                            sortField === field
                                ? "text-gray-700 text-sm"
                                : "opacity-0"
                        }`}
                        icon={sortDir === "asc" ? faCaretDown : faCaretUp}
                    />{" "}
                </span>
            </div>
        </th>
    );
}
