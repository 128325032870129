import React, { useState } from "react";
import Panel, { PanelHeader } from "../../../components/Panel";
import Api from "../../../services/Api";
import Button from "../../../components/Button";
import UserSelect from "actions/UserSelect";
import { useToasts } from "react-toast-notifications";

export default function TransferPermissions({ fromMemberId }) {
    const [toMember, setToMember] = useState();
    const { addToast } = useToasts();

    const transferPermissions = async (e) => {
        e.preventDefault();
        try {
            let response = await Api.post(
                "members/" + toMember + "/transferred-permissions",
                {
                    from_user_id: parseInt(fromMemberId),
                    preserve_existing: true,
                }
            );
            addToast(response.data.message);
        } catch (error) {
            console.log(error.response);
            if (error?.response?.data?.message) {
                addToast(error.response.data.message, { type: "error" });
            }
        }
    };

    const handleOnChange = (value) => {
        setToMember(value);
    };

    return (
        <div className="mt-12">
            <Panel allowOverflow={true}>
                <PanelHeader title="Transfer Permissions" />
                <form onSubmit={transferPermissions}>
                    <div className="flex items-end">
                        <UserSelect
                            label="Transfer Permissions To"
                            name="transfer"
                            onChange={(e) => handleOnChange(e)}
                            params={{
                                type: "member",
                            }}
                        />
                        <div className="flex-initial ml-4">
                            <Button type="submit" text="Transfer" />
                        </div>
                    </div>
                </form>
            </Panel>
        </div>
    );
}
