import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { MemberHeader } from "../../../components/PageHeader";
import MemberNav from "../_partials/MemberNav";
import { useState } from "react";
import Spinner from "../../../components/Spinner";
import Api from "../../../services/Api";
import TableSortHeader from "../../../components/TableSortHeader";
import PageContainer from "../../../components/PageContainer";
import { formatDate } from "../../../util/TextUtil";

export default function MemberAdvisors({ member, ...props }) {
    const [advisors, setAdvisors] = useState([]);
    const [loading, setLoading] = useState(true);
    const [orderBy, setOrderBy] = useState("first_name");
    const [sortDir, setSortDir] = useState("asc");

    useEffect(() => {
        async function fetchAdvisors() {
            setLoading(true);
            let response = await Api.get(
                "members/" + member.id + "/member-advisors",
                {}
            );
            setAdvisors(response.data.data);
            setLoading(false);
        }
        fetchAdvisors();
    }, [member]);

    if (loading) {
        return (
            <div className="absolute top-0 left-0 w-full h-screen flex items-center justify-center">
                <Spinner />
            </div>
        );
    }

    return (
        <>
            <MemberHeader text={member.full_name} />
            <PageContainer>
                <div className="flex">
                    <div className="mr-10 flex-initial">
                        <MemberNav
                            active={props.history.location.pathname}
                            member={member}
                        />
                    </div>
                    <div className="flex-1">
                        <table className="w-full text-left">
                            <thead>
                                <tr>
                                    <TableSortHeader
                                        field="first_name"
                                        sortField={orderBy}
                                        sortDir={sortDir}
                                        changeSort={(column) => {
                                            setSortDir(
                                                sortDir === "asc"
                                                    ? "desc"
                                                    : "asc"
                                            );
                                            setOrderBy(column);
                                        }}
                                    >
                                        First Name
                                    </TableSortHeader>
                                    <TableSortHeader
                                        field="last_name"
                                        sortField={orderBy}
                                        sortDir={sortDir}
                                        changeSort={(column) => {
                                            setSortDir(
                                                sortDir === "asc"
                                                    ? "desc"
                                                    : "asc"
                                            );
                                            setOrderBy(column);
                                        }}
                                    >
                                        Last Name
                                    </TableSortHeader>
                                    <TableSortHeader
                                        field="email"
                                        sortField={orderBy}
                                        sortDir={sortDir}
                                        changeSort={(column) => {
                                            setSortDir(
                                                sortDir === "asc"
                                                    ? "desc"
                                                    : "asc"
                                            );
                                            setOrderBy(column);
                                        }}
                                    >
                                        Email
                                    </TableSortHeader>
                                    <TableSortHeader
                                        field="org_user.created_at"
                                        sortField={orderBy}
                                        sortDir={sortDir}
                                        changeSort={(column) => {
                                            setSortDir(
                                                sortDir === "asc"
                                                    ? "desc"
                                                    : "asc"
                                            );
                                            setOrderBy(column);
                                        }}
                                    >
                                        Date Added
                                    </TableSortHeader>
                                </tr>
                            </thead>
                            <tbody>
                                {advisors.map((advisor, idx) => (
                                    <tr key={idx}>
                                        <td>
                                            <Link
                                                to={`/clients/${advisor.client_id}/advisors/${advisor.id}/edit`}
                                            >
                                                {advisor.first_name}
                                            </Link>
                                        </td>
                                        <td>{advisor.last_name}</td>
                                        <td>{advisor.email}</td>
                                        <td>
                                            {formatDate(advisor.created_at)}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </PageContainer>
        </>
    );
}
