import { useQueryState } from "use-location-state";

export default function useLiveWealthState({
    current: currentInitial = true,
    show_dod_values = false,
    ownerInitial = "",
} = {}) {
    const [current, setCurrent] = useQueryState("current", currentInitial);
    const [owner, setOwner] = useQueryState("owner", ownerInitial);
    const [totalsColumn, setTotalsColumn] = useQueryState(
        "totalsColumn",
        "net_worth"
    );

    const [showDodValue, setShowDodValue] = useQueryState(
        "show_dod_values",
        show_dod_values
    );

    return {
        owner,
        setOwner,
        current,
        setCurrent,
        showDodValue,
        setShowDodValue,
        totalsColumn,
        setTotalsColumn,
    };
}
