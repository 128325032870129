import React from "react";
import ClientPageHeader, { Heading } from "../../components/ClientPageHeader";
import PageContainer from "../../components/PageContainer";
import { Link } from "react-router-dom";

export const listOfLinks = [
    {
        path: "annuities",
        title: "Annuities",
    },
    {
        path: "business-interests",
        title: "Business Interests",
    },
    {
        path: "financial-accounts",
        title: "Financial Accounts",
    },
    {
        path: "retirement-accounts",
        title: "Retirement Accounts",
    },
    {
        path: "insurance-policies",
        title: "Life Insurance Policies",
    },
    {
        path: "other-insurance-policies",
        title: "Other Insurance Policies",
    },
    {
        path: "leases",
        title: "Other Leases",
    },
    {
        path: "personal-properties",
        title: "Personal Property",
    },
    {
        path: "real-estate",
        title: "Real Estate",
    },
    {
        path: "notes-receivables",
        title: "Notes Receivables",
    },
    {
        path: "non-mortgage-liabilities",
        title: "Non-Mortgage Liabilities",
    },
];

export default function AssetsAndLiabilitiesIndex({ client, clientPath }) {
    return (
        <>
            <ClientPageHeader client={client}>
                <Heading>Assets & Liabilities</Heading>
            </ClientPageHeader>
            <PageContainer>
                <table className="w-full text-left">
                    <tbody>
                        {listOfLinks.map((link, idx) => (
                            <tr key={idx}>
                                <td>
                                    <Link
                                        to={`${clientPath}/${link.path}`}
                                        className="underline"
                                    >
                                        {link.title}
                                    </Link>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </PageContainer>
        </>
    );
}
