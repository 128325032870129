import React, { useCallback } from "react";
import { useToasts } from "react-toast-notifications";
import Api from "../services/Api";

export default function useApi() {
    const { addToast } = useToasts();
    const [loading, setLoading] = React.useState(false);
    const [saving, setSaving] = React.useState(false);
    const [hasError, setHasError] = React.useState(false);
    const [unauthorized, setUnauthorized] = React.useState(false);
    const [errors, setErrors] = React.useState([]);
    const [errorMessage, setErrorMessage] = React.useState("");
    const [response, setResponse] = React.useState(null);

    const api = useCallback(
        async (method, url, payload) => {
            const usedMethod = method.toLowerCase();
            if (usedMethod === "get") {
                setLoading(true);
            }
            if (usedMethod === "put" || usedMethod === "post") {
                setSaving(true);
            }
            try {
                let response = await Api[method](url, payload);
                setLoading(false);
                setSaving(false);
                setErrors([]);
                setErrorMessage("");
                setResponse(response);

                // TODO Remove this once we have react query in place.
                if (response.data && response.data.message) {
                    addToast(response.data.message, { type: "success" });
                }

                return response;
            } catch (error) {
                setLoading(false);
                setSaving(false);
                setHasError(true);

                if (error?.response?.data?.message) {
                    setErrorMessage(error.response.data.message);
                }

                if (error?.response?.status === 403) {
                    return setUnauthorized(true);
                }

                if (error?.response?.data?.errors) {
                    setErrors(error.response.data.errors);
                }

                if (error?.response?.data?.message) {
                    addToast(error.response.data.message, { type: "error" });
                }

                throw error;
            }
        },
        [addToast]
    );

    return {
        loading,
        setLoading,
        hasError,
        setHasError,
        response,
        setResponse,
        api,
        errorMessage,
        errors,
        setErrors,
        setUnauthorized,
        unauthorized,
        saving,
    };
}
