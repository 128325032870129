import { useState, useCallback } from "react";
import { useToasts } from "react-toast-notifications";
import { formatDate } from "../../util/TextUtil";
import Api from "../../services/Api";

export default function useBeneficiaries(
    client,
    resourceType,
    resourceId,
    showProposed = false
) {
    const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
    const [beneficiaries, setBeneficiaries] = useState([]);
    const [loading, setLoading] = useState(false);
    const [saving, setSaving] = useState(false);
    const [errors, setErrors] = useState([]);
    const { addToast } = useToasts();
    const [verifiedAt, setVerifiedAt] = useState(
        formatDate(new Date(), "", { format: "Y-MM-DD" })
    );

    const fetchBeneficiaries = useCallback(
        async ({ client, resourceType, resourceId }) => {
            let response = await Api.get(
                "clients/" + client.id + "/beneficiaries",
                {
                    resourceType,
                    resourceId,
                    show_proposed: showProposed,
                }
            );

            const beneficiaries = response.data.data;
            setBeneficiaries(beneficiaries);

            if (beneficiaries.length) {
                const bene = beneficiaries[0];
                if (showProposed) {
                    setVerifiedAt(bene.proposed_at);
                } else {
                    setVerifiedAt(bene.verified_at);
                }
            }
        },
        []
    );

    const appendBeneficiary = () => {
        setHasUnsavedChanges(true);
        let newBeneficiaries = [
            ...beneficiaries,
            {
                beneficiary_id: "",
                beneficiary_type: "",
                percentage_owned: 0,
                resource_id: "",
                level: 1,
                resource_type: "",
            },
        ];
        setBeneficiaries(newBeneficiaries);
    };

    const removeBeneficiary = (idx) => {
        if (beneficiaries.length === 1) {
            if (
                confirm(
                    `Are you sure you want to delete all ${
                        showProposed ? "proposed" : "current"
                    } beneficiaries for this asset?`
                )
            ) {
                save(null, []);
                setBeneficiaries([]);
                return true;
            }

            return false;
        }

        setHasUnsavedChanges(true);

        let deletedPercentage = 0;
        let newBeneficiaries = beneficiaries.filter((owner, i) => i !== idx);
        setBeneficiaries(() => newBeneficiaries);
        setErrors([]);
        updatePercentageOwned(
            0,
            Math.min(
                parseInt(beneficiaries[0].percentage_owned) +
                    parseInt(deletedPercentage),
                100
            )
        );
    };

    const updatePercentageOwned = (idx, value) => {
        setHasUnsavedChanges(true);
        setBeneficiaries((beneficiaries) =>
            beneficiaries.map((ownership, idxs) => {
                if (idxs === idx) {
                    ownership.percentage_owned = value;
                }
                return ownership;
            })
        );
    };

    async function save(event, defaultBeneficiaries) {
        event && event.preventDefault();

        try {
            setSaving(true);
            let payload = {
                resource_id: resourceId,
                resource_type: resourceType,
                beneficiaries: defaultBeneficiaries
                    ? defaultBeneficiaries
                    : beneficiaries,
                proposal_mode: showProposed ? "propose" : "verify",
            };

            if (showProposed) {
                payload["proposed_at"] = verifiedAt;
            } else {
                payload["verified_at"] = verifiedAt;
            }

            let response = await Api.post(
                "clients/" + client.id + "/beneficiaries",
                payload
            );
            addToast(response.data.message);
            setErrors([]);
            setSaving(false);
            setHasUnsavedChanges(false);
        } catch (e) {
            console.error(e);
            setSaving(false);
            setErrors([]);
            if (e.response.data.errors) {
                setErrors(e.response.data.errors);
            } else {
                addToast(e.response.data.message, { type: "error" });
            }
        }
    }

    const setBeneficiariesOverride = useCallback(
        (args) => {
            setBeneficiaries(args);
            setHasUnsavedChanges(true);
        },
        [setBeneficiaries, setHasUnsavedChanges]
    );

    const setVerifiedAtOverride = useCallback(
        (args) => {
            setVerifiedAt(args);
            setHasUnsavedChanges(true);
        },
        [setBeneficiaries, setHasUnsavedChanges]
    );

    return {
        hasUnsavedChanges,
        appendBeneficiary,
        removeBeneficiary,
        updatePercentageOwned,
        fetchBeneficiaries,
        beneficiaries,
        setBeneficiaries: setBeneficiariesOverride,
        loading,
        setLoading,
        errors,
        setErrors,
        verifiedAt,
        setVerifiedAt: setVerifiedAtOverride,
        save,
        saving,
        setSaving,
    };
}
