import React, { useState, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";
import SelectInput from "../../components/SelectInput";
import Spinner from "../../components/Spinner";
import Api from "../../services/Api";

export default function MessageList({
    client,
    clientPath,
    search,
    searchType,
    recipients,
    needsRefreshed,
    ...props
}) {
    const activeMessageId = props.match.params.messageId;
    const [messages, setMessages] = useState([]);
    const [viewed, setViewed] = useState([]);
    const [messageView, setMessageView] = useState("Inbox");
    const [isLoading, setIsLoading] = useState(true);

    function addToViewedList(message) {
        setViewed([...viewed, message.id]);
    }

    const fetchMessages = useCallback(async () => {
        try {
            setIsLoading(true);
            if (needsRefreshed >= 0) {
                let response = await Api.get(`clients/${client.id}/messages`, {
                    messageView,
                    searchType,
                    "filter[search]": search,
                    "filter[recipients]": recipients,
                });
                setMessages(response.data.data);
                setIsLoading(false);
            }
        } catch (error) {
            console.log(error);
            setIsLoading(false);
        }
    }, [
        client.id,
        search,
        recipients,
        searchType,
        messageView,
        needsRefreshed,
    ]);

    useEffect(() => {
        fetchMessages();
    }, [fetchMessages]);

    function unreadMessageCount() {
        return messages.filter((message) => message.unread).length;
    }

    return (
        <div className="max-h-screen flex-initial w-96 flex flex-col bg-white">
            <div className="flex items-center justify-between px-4 py-3 space-x-4 border-b border-gray-200">
                <SelectInput
                    className="border-none flex-1"
                    options={["Inbox", "Sent"]}
                    name="messageView"
                    value={messageView}
                    onChange={(e) => setMessageView(e.target.value)}
                />
                {messageView === "Inbox" && (
                    <span className="text-sm text-gray-500">
                        {unreadMessageCount()} unread messages
                    </span>
                )}
            </div>
            {isLoading ? (
                <div className="flex items-center justify-center h-full w-full">
                    <Spinner message="Loading Messages" />
                </div>
            ) : (
                <MessageListItems
                    messages={messages}
                    activeMessageId={activeMessageId}
                    viewed={viewed}
                    messageView={messageView}
                    clientPath={clientPath}
                    addToViewedList={addToViewedList}
                />
            )}
        </div>
    );
}

export function MessageListItems({
    messages,
    activeMessageId,
    viewed,
    messageView,
    clientPath,
    addToViewedList,
}) {
    return (
        <ul className="flex-1 overflow-auto">
            {messages.map((message) => (
                <li
                    className={`flex space-x-2 relative focus-within:ring-2 focus-within:ring-inset border-b ${
                        parseInt(activeMessageId) === parseInt(message.id)
                            ? "bg-brand-500 focus-within:ring-brand-600 border-brand-100 text-white"
                            : "hover:bg-gray-50 bg-white focus-within:ring-brand-600 border-gray-200 text-gray-900"
                    }`}
                    key={`message_${message.id}`}
                >
                    <Link
                        to={`${clientPath}/messages/${message.id}/view`}
                        className="block focus:outline-none w-full flex space-x-2 py-5 px-4"
                        onClick={() => addToViewedList(message)}
                    >
                        <div
                            className={`h-3 w-3 rounded-full mt-1 ${
                                message.unread &&
                                messageView === "Inbox" &&
                                !viewed.includes(message.id)
                                    ? "bg-brand"
                                    : "bg-transparent"
                            }`}
                        ></div>
                        <div className="flex-1">
                            <div className="flex justify-between space-x-3">
                                <div className="min-w-0 flex-1">
                                    <span
                                        className="absolute inset-0"
                                        aria-hidden="true"
                                    ></span>
                                    <p className="text-sm font-bold">
                                        <span>{message.sender}</span>
                                        {message.recipients.map((recipient) => (
                                            <span key={recipient.id}>
                                                ,{" "}
                                                {recipient.user.full_name ||
                                                    "Unknown"}
                                            </span>
                                        ))}
                                    </p>
                                    <p className="text-sm truncate">
                                        {message.subject}
                                    </p>
                                </div>
                                <time
                                    dateTime="2021-01-27T16:35"
                                    className="flex-shrink-0 whitespace-nowrap text-sm opacity-75"
                                >
                                    {message.created_at_diff}
                                </time>
                            </div>
                            <div className="mt-1">
                                <p className="line-clamp-2 text-sm opacity-50">
                                    {message.preview}
                                </p>
                            </div>
                        </div>
                    </Link>
                </li>
            ))}
        </ul>
    );
}
