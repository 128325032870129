import React from "react";

export default function InputStepper({ value, onChange }) {
    return (
        <span className="relative z-0 inline-flex shadow-sm rounded-md">
            <button
                type="button"
                className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-gray-300 focus:border-gray-300"
                onClick={() => onChange(value - 1)}
            >
                <span className="sr-only">Subtract</span>
                <span className="px-2">-</span>
            </button>
            <button
                type="button"
                disabled={true}
                className="-ml-px relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-gray-300 focus:border-gray-300"
            >
                {value}
            </button>

            <button
                type="button"
                className="-ml-px relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-gray-300 focus:border-gray-300"
                onClick={() => onChange(value + 1)}
            >
                <span className="px-2">+</span>
            </button>
        </span>
    );
}
