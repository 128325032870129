import React from "react";
import { useState } from "react";
import Api from "../../../services/Api";
import Button from "../../../components/Button";
import SaveButton from "../../../components/SaveButton";
import SelectInput from "../../../components/SelectInput";
import TextInput, { InputLabel } from "../../../components/TextInput";
import { useToasts } from "react-toast-notifications";
import Toggle from "../../../components/Toggle";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import InputErrors from "../../../components/InputErrors";

export default function EditQuestion({
    questionnaire,
    member,
    refresh,
    question,
}) {
    const { addToast } = useToasts();
    const [title, setTitle] = useState(question.title);
    const [type, setType] = useState(question.type || "boolean");
    const [options, setOptions] = useState(question.options || []);
    const [newOption, setNewOption] = useState("");
    const [status, setStatus] = useState(question.status || "published");
    const [errors, setErrors] = useState([]);

    const [rangeStart, setRangeStart] = useState(question.range_start);
    const [rangeEnd, setRangeEnd] = useState(question.range_end);

    function appendOption() {
        if (newOption.length >= 1 && options.length < 4) {
            const newOptions = options;
            newOptions.push(newOption);
            setOptions(newOptions);
            setNewOption("");
        }
    }

    function removeOption(optionToRemoveIdx) {
        const newOptions = options.filter(
            (option, idx) => optionToRemoveIdx !== idx
        );
        setOptions(newOptions);
    }

    function updateOption(value, updateIdx) {
        const updatedOptions = options.map((option, idx) =>
            updateIdx === idx ? value : option
        );
        setOptions(updatedOptions);
    }

    async function updateQuestion(e, questionId, body) {
        e.preventDefault();
        try {
            await Api.put(
                "members/" +
                    member.id +
                    "/questionnaires/" +
                    questionnaire.id +
                    "/questions/" +
                    questionId,
                body
            );
            refresh();
            setErrors([]);
        } catch (e) {
            setErrors([]);
            if (e.response.data.errors) {
                setErrors(e.response.data.errors);
            } else {
                addToast(e.response.data.message, { type: "error" });
            }
        }
    }

    return (
        <form
            onSubmit={(e) =>
                updateQuestion(e, question.id, {
                    title,
                    type,
                    options,
                    status,
                    range_start: rangeStart,
                    range_end: rangeEnd,
                })
            }
        >
            <div className="mb-6">
                <TextInput
                    name="title"
                    value={title}
                    label="Question"
                    error={errors.title}
                    onChange={(e) => setTitle(e.target.value)}
                    autoFocus={true}
                />
            </div>

            <div>
                <SelectInput
                    name="type"
                    value={type}
                    label="Question Type"
                    error={errors.type}
                    options={[
                        { value: "boolean", label: "True/False" },
                        { value: "multiple_choice", label: "Multiple Choice" },
                        { value: "range", label: "Range" },
                    ]}
                    onChange={(e) => setType(e.target.value)}
                />
            </div>

            {type === "multiple_choice" && (
                <div className="mt-6 bg-gray-100 rounded p-4">
                    {options.length > 0 && (
                        <div>
                            {options.map((option, idx) => {
                                return (
                                    <div
                                        className="mb-6 last:mb-0 flex items-end space-x-2"
                                        key={`option_${idx}`}
                                    >
                                        <div className="flex-1">
                                            <TextInput
                                                name="option"
                                                value={option}
                                                onChange={(e) =>
                                                    updateOption(
                                                        e.target.value,
                                                        idx
                                                    )
                                                }
                                            />
                                        </div>
                                        <div className="flex-0">
                                            <Button
                                                appearance="light"
                                                type="button"
                                                icon={faTimes}
                                                onClick={() =>
                                                    removeOption(idx)
                                                }
                                            />
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    )}
                    {options.length < 4 && (
                        <div>
                            {options.length > 0 && <hr className="my-3" />}
                            <InputErrors errors={errors.options} />
                            <div className="flex space-x-3 items-center">
                                <div className="flex-1">
                                    <TextInput
                                        name="options"
                                        value={newOption}
                                        placeholder="Enter a new multiple choice option..."
                                        onChange={(e) =>
                                            setNewOption(e.target.value)
                                        }
                                        onKeyDown={(e) => {
                                            if (e.keyCode === 13) {
                                                e.stopPropagation();
                                                e.preventDefault();
                                                if (newOption.length >= 1) {
                                                    appendOption(newOption);
                                                }
                                            }
                                        }}
                                    />
                                </div>
                                <div className="flex-0 w-24">
                                    <Button
                                        type="button"
                                        disabled={newOption.length < 1}
                                        onClick={() => appendOption(newOption)}
                                        text="Add"
                                    />
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            )}

            {type === "range" && (
                <div className="mt-6 flex space-x-3">
                    <div className="flex-1">
                        <TextInput
                            name="range_start"
                            label={`Range Start`}
                            value={rangeStart}
                            error={errors.range_start}
                            onChange={(e) => setRangeStart(e.target.value)}
                            autoFocus={true}
                        />
                    </div>
                    <div className="flex-1">
                        <TextInput
                            name="range_end"
                            label={`Range End`}
                            value={rangeEnd}
                            error={errors.range_end}
                            onChange={(e) => setRangeEnd(e.target.value)}
                        />
                    </div>
                </div>
            )}

            <div className="mt-6">
                <InputLabel text={`Save question as draft?`} />
                <Toggle
                    name="status"
                    onToggle={(e, toggleState) =>
                        setStatus(toggleState ? "draft" : "published")
                    }
                    isToggled={status === "draft"}
                />
            </div>

            <div className="w-48 ml-auto">
                <SaveButton type="submit" />
            </div>
        </form>
    );
}
