import React from "react";
import TextInput from "../../../components/TextInput";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import Fieldset from "../../../components/Fieldset";

export default function RewardMemberships({
    rewardMemberships,
    setRewardMemberships,
}) {
    const rewardMembership = {
        name: "",
        contact: "",
        address: "",
        phone: "",
        email: "",
        website: "",
    };

    function appendRewardMembership() {
        if (rewardMemberships.length < 10) {
            setRewardMemberships((rewardMemberships) => [
                ...rewardMemberships,
                rewardMembership,
            ]);
        }
    }

    function removeRewardMembership(idx) {
        setRewardMemberships(
            rewardMemberships.filter((rewardMembership, idxs) => idxs !== idx)
        );
    }

    const updateRewardMembership = (event, idx) => {
        setRewardMemberships(
            rewardMemberships.map((rewardMembership, sidx) => {
                return idx !== sidx
                    ? rewardMembership
                    : {
                          ...rewardMembership,
                          [event.target.name]: event.target.value,
                      };
            })
        );
    };

    return (
        <Fieldset
            legend="Reward Memberships"
            description="Frequent Flyer, Hotel, Credit Cards, Etc."
        >
            <div className="mb-10 border-b border-gray-100 pb-6">
                {rewardMemberships.map((rewardMembership, idx) => (
                    <div className="mb-10 border-b border-gray-100" key={idx}>
                        <div className="flex space-x-3 mb-6">
                            <div className="flex-1">
                                <TextInput
                                    label="Company"
                                    name="name"
                                    value={rewardMemberships[idx]["name"]}
                                    onChange={(e) =>
                                        updateRewardMembership(e, idx)
                                    }
                                    autoFocus={
                                        rewardMemberships.length === idx + 1
                                    }
                                />
                            </div>
                            <div className="flex-1">
                                <TextInput
                                    label="Contact"
                                    name="contact"
                                    value={rewardMemberships[idx]["contact"]}
                                    onChange={(e) =>
                                        updateRewardMembership(e, idx)
                                    }
                                />
                            </div>
                        </div>
                        <div className="mb-6">
                            <TextInput
                                label="Address"
                                name="address"
                                value={rewardMemberships[idx]["address"]}
                                onChange={(e) => updateRewardMembership(e, idx)}
                            />
                        </div>
                        <div className="flex space-x-3 mb-6">
                            <div className="flex-1">
                                <TextInput
                                    type="tel"
                                    label="Phone"
                                    name="phone"
                                    value={rewardMemberships[idx]["phone"]}
                                    onChange={(e) =>
                                        updateRewardMembership(e, idx)
                                    }
                                />
                            </div>
                            <div className="flex-1">
                                <TextInput
                                    type="email"
                                    label="Email"
                                    name="email"
                                    value={rewardMemberships[idx]["email"]}
                                    onChange={(e) =>
                                        updateRewardMembership(e, idx)
                                    }
                                />
                            </div>
                        </div>
                        <div className="mb-6">
                            <TextInput
                                label="Website"
                                name="website"
                                value={rewardMemberships[idx]["website"]}
                                onChange={(e) => updateRewardMembership(e, idx)}
                            />
                        </div>
                        <div className="flex justify-end mb-3">
                            <button
                                type="button"
                                onClick={() => removeRewardMembership(idx)}
                                className="text-gray-500 text-sm hover:text-red-500"
                            >
                                <FontAwesomeIcon
                                    icon={faTimesCircle}
                                    className="mr-1"
                                />{" "}
                                Remove Reward Membership
                            </button>
                        </div>
                    </div>
                ))}
                <div className="flex">
                    <button
                        type="button"
                        onClick={(e) => appendRewardMembership(e)}
                        className="text-gray-500 text-sm hover:text-brand"
                    >
                        <FontAwesomeIcon icon={faPlusCircle} className="mr-1" />{" "}
                        Add Reward Membership
                    </button>
                </div>
            </div>
        </Fieldset>
    );
}
