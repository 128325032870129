import React from "react";
import InsurancePoliciesLayout from "./InsurancePoliciesLayout";
import useClient from "hooks/useClient";
import ClientNotes from "views/clients/Notes/ClientNotes";

export default function EditInsurancePolicyNotes({
    resourceId,
    resourceType,
    currentBasePath,
    title,
}) {
    const { client } = useClient();

    return (
        <InsurancePoliciesLayout
            currentBasePath={currentBasePath}
            title={title}
        >
            <ClientNotes
                client={client}
                resourceType={resourceType}
                resourceId={resourceId}
                autoFocus={true}
            />
        </InsurancePoliciesLayout>
    );
}
