import React, { useState } from "react";
import TextInput from "../../../components/TextInput";
import useFormatCurrency from "../../../hooks/useFormatCurrency";
import { formatDateForInput } from "../../../util/TextUtil";
import LeaseTypeSelect from "./LeaseTypeSelect";
import FrequencySelect from "../../../components/FrequencySelect";
import LeaseSubtypeSelect from "./LeaseSubtypeSelect";
import AddressEntry from "../../../components/AddressEntry";
import Api from "../../../services/Api";

export default function LeasesForm({
    client,
    input,
    errors,
    readOnly = false,
    updateValue,
    onSubmit,
    ...props
}) {
    const formatCurrency = useFormatCurrency();

    const [hiddenFarmFields, setHiddenFarmFields] = useState([]);
    const assetTypes = {
        Equipment: "PersonalProperty",
        "Real Estate": "RealEstate",
        "Farm Lease": "RealEstate",
        "Wind Farm Lease": "RealEstate",
        "Cellphone Tower Lease": "RealEstate",
        "Solar Panel Farm Lease": "RealEstate",
        "Mineral Right Lease": "RealEstate",
        "Oil/Gas Lease": "RealEstate",
    };

    const [resourceInfo, setResourceInfo] = useState({});
    const resourceType = Object.prototype.hasOwnProperty.call(
        assetTypes,
        input.lease_type_id || ""
    )
        ? assetTypes[input.lease_type_id]
        : null;

    const hasResourceInfo = Object.keys(resourceInfo || {}).length > 0;

    // Reset selected resource when changing resources
    React.useEffect(() => {
        if (!resourceType) {
            if (input.lease_for_id) {
                updateValue({
                    target: { name: "lease_for_id", value: null },
                });
            }
            if (input.lease_for_type) {
                updateValue({
                    target: { name: "lease_for_type", value: null },
                });
            }
        }
    }, [resourceType, updateValue, input.lease_for_type, input.lease_for_id]);

    // Handle logic for managing logic for visibility of fields in coordination of resources
    React.useEffect(() => {
        if (resourceType) {
            if (input.lease_for_type !== resourceType) {
                updateValue({
                    target: { name: "lease_for_type", value: resourceType },
                });
                updateValue({
                    target: { name: "lease_for_id", value: "" },
                });
            }
            if (!input.lease_for_id) {
                [
                    "value",
                    "as_of_date",
                    "purchase_price",
                    "purchase_date",
                ].forEach((key) => {
                    updateValue({
                        target: { name: key, value: null },
                    });
                });
            }
        }
    }, [resourceType, updateValue, input.lease_for_id, input.lease_for_type]);

    React.useEffect(() => {
        const fetchData = async () => {
            if (!input.lease_for_id || !input.lease_for_type) {
                setResourceInfo({});
                return;
            }
            const routes = {
                PersonalProperty: "personal-properties",
                RealEstate: "real-estate",
            };
            const resource = routes[input.lease_for_type];
            const lease_for = input.lease_for_id;
            const { data } = await Api.get(
                `clients/${client.id}/${resource}/${lease_for}`
            );
            setResourceInfo(data.data);
        };
        fetchData();
    }, [input.lease_for_id, input.lease_for_type, client.id]);

    // Farm type visibility
    React.useEffect(() => {
        if (
            input.lease_subtype_id === "Share Crop" &&
            input.lease_type_id === "Farm Lease"
        ) {
            hiddenFarmFields.forEach((field) => {
                let e = {
                    target: {
                        name: field,
                        value: null,
                    },
                };
                updateValue(e);
            });
            if (hiddenFarmFields.length !== 2) {
                setHiddenFarmFields(["rent_frequency_id", "rent_amount"]);
            }
        } else {
            if (hiddenFarmFields.length !== 0) {
                setHiddenFarmFields([]);
            }
        }
    }, [
        input.lease_subtype_id,
        input.lease_type_id,
        updateValue,
        hiddenFarmFields,
    ]);

    return (
        <form onSubmit={onSubmit}>
            <div>
                <div className="mb-6">
                    <TextInput
                        name="description"
                        description="description"
                        label="Description"
                        id="description"
                        readOnly={readOnly}
                        disabled={readOnly}
                        value={input.description}
                        error={errors.description}
                        onChange={updateValue}
                        required={true}
                        autoFocus={true}
                    />
                </div>
                {!props.hideType && (
                    <div className="mb-6">
                        <LeaseTypeSelect
                            label="Lease Type"
                            id="lease_type_id"
                            value={input.lease_type_id}
                            readOnly={readOnly}
                            disabled={readOnly}
                            error={errors.lease_type_id}
                            onChange={updateValue}
                            client={client}
                            resourceType={resourceType}
                            lease_type_name={input.lease_type_name}
                            lease_for_id={input.lease_for_id}
                        />

                        <LeaseSubtypeSelect
                            label="Lease Subtype"
                            id="lease_subtype_id"
                            value={input.lease_subtype_id}
                            readOnly={readOnly}
                            disabled={readOnly}
                            error={errors.lease_subtype_id}
                            onChange={updateValue}
                            lease_type_id={input.lease_type_id}
                            lease_type_name={input.lease_type_name}
                            lease_subtype_id={input.lease_subtype_id}
                            lease_subtype_name={input.lease_subtype_name}
                        />
                    </div>
                )}
                <div className="flex space-x-3 mb-6">
                    <div className="flex-1">
                        <TextInput
                            name="value"
                            label="Value"
                            id="value"
                            value={
                                hasResourceInfo
                                    ? resourceInfo.value || resourceInfo.value
                                    : input.value
                            }
                            readOnly={
                                resourceType || hasResourceInfo || readOnly
                            }
                            disabled={
                                resourceType || hasResourceInfo || readOnly
                            }
                            error={errors.value}
                            onChange={updateValue}
                            onBlur={(e) => {
                                if (e.target.value) {
                                    e.target.value = formatCurrency(
                                        e.target.value
                                    );
                                    updateValue(e);
                                }
                            }}
                            placeholder="0.00"
                        />
                    </div>
                    <div className="flex-1">
                        <TextInput
                            type="date"
                            name="as_of_date"
                            label="As Of Date"
                            id="as_of_date"
                            value={formatDateForInput(
                                hasResourceInfo
                                    ? resourceInfo.as_of_date
                                    : input.as_of_date
                            )}
                            readOnly={
                                resourceType || hasResourceInfo || readOnly
                            }
                            disabled={
                                resourceType || hasResourceInfo || readOnly
                            }
                            error={errors.as_of_date}
                            onChange={updateValue}
                        />
                    </div>
                </div>
                <div className="flex space-x-3 mb-6">
                    <div className="flex-1">
                        <TextInput
                            name="purchase_price"
                            label="Purchase Price"
                            id="purchase_price"
                            value={
                                hasResourceInfo
                                    ? resourceInfo.purchase_price
                                    : input.purchase_price
                            }
                            readOnly={
                                resourceType || hasResourceInfo || readOnly
                            }
                            disabled={
                                resourceType || hasResourceInfo || readOnly
                            }
                            error={errors.purchase_price}
                            onChange={updateValue}
                            onBlur={(e) => {
                                e.target.value = formatCurrency(e.target.value);
                                updateValue(e);
                            }}
                            placeholder="0.00"
                        />
                    </div>
                    <div className="flex-1">
                        <TextInput
                            type="date"
                            name="purchase_date"
                            label="Purchase Date"
                            id="purchase_date"
                            value={formatDateForInput(
                                hasResourceInfo
                                    ? resourceInfo.purchase_date
                                    : input.purchase_date
                            )}
                            readOnly={
                                resourceType || hasResourceInfo || readOnly
                            }
                            disabled={
                                resourceType || hasResourceInfo || readOnly
                            }
                            error={errors.purchase_date}
                            onChange={updateValue}
                        />
                    </div>
                </div>
                <div className="flex space-x-3 mb-6">
                    <div className="flex-1">
                        <TextInput
                            type="date"
                            name="lease_commencement_date"
                            label="Lease Commencement Date"
                            id="lease_commencement_date"
                            value={formatDateForInput(
                                input.lease_commencement_date
                            )}
                            readOnly={readOnly}
                            disabled={readOnly}
                            error={errors.lease_commencement_date}
                            onChange={updateValue}
                        />
                    </div>
                    <div className="flex-1">
                        <TextInput
                            type="date"
                            name="lease_termination_date"
                            label="Lease Termination Date"
                            id="lease_termination_date"
                            value={formatDateForInput(
                                input.lease_termination_date
                            )}
                            readOnly={readOnly}
                            disabled={readOnly}
                            error={errors.lease_termination_date}
                            onChange={updateValue}
                        />
                    </div>
                </div>

                {hiddenFarmFields.indexOf("rent_amount") === -1 &&
                    hiddenFarmFields.indexOf("rent_frequency_id") === -1 && (
                        <div className="flex space-x-3">
                            <div className="flex-1">
                                <TextInput
                                    name="rent_amount"
                                    label="Rent Amount"
                                    id="rent_amount"
                                    readOnly={readOnly}
                                    disabled={readOnly}
                                    value={input.rent_amount}
                                    error={errors.rent_amount}
                                    onChange={updateValue}
                                    onBlur={(e) => {
                                        e.target.value = formatCurrency(
                                            e.target.value
                                        );
                                        updateValue(e);
                                    }}
                                    placeholder="0.00"
                                />
                            </div>
                            <div className="flex-1">
                                <FrequencySelect
                                    name="rent_frequency_id"
                                    label="Rent Frequency"
                                    id="rent_frequency_id"
                                    disabled={readOnly}
                                    value={input.rent_frequency_id}
                                    error={errors.rent_frequency_id}
                                    onChange={updateValue}
                                    defaultOption="Choose an option..."
                                    defaultValue=""
                                    defaultOptionDisabled={true}
                                />
                            </div>
                        </div>
                    )}

                <fieldset className="mt-12">
                    <legend className="font-bold txt-md text-gray-500 my-6 pb-1 border-b block w-full">
                        Lessee
                    </legend>

                    <div className="flex space-x-3 mb-6">
                        <div className="flex-1">
                            <TextInput
                                name="lessee_first_name"
                                label="Lessee First Name"
                                id="lessee_first_name"
                                disabled={readOnly}
                                readOnly={readOnly}
                                value={input.lessee_first_name}
                                error={errors.lessee_first_name}
                                onChange={updateValue}
                            />
                        </div>
                        <div className="flex-1">
                            <TextInput
                                name="lessee_last_name"
                                label="Lessee Last Name"
                                id="lessee_last_name"
                                disabled={readOnly}
                                readOnly={readOnly}
                                value={input.lessee_last_name}
                                error={errors.lessee_last_name}
                                onChange={updateValue}
                            />
                        </div>
                    </div>

                    <div className="flex space-x-3 mb-6">
                        <div className="flex-1">
                            <TextInput
                                name="lessee_email"
                                label="Lessee Email Address"
                                id="lessee_email"
                                type="email"
                                disabled={readOnly}
                                readOnly={readOnly}
                                value={input.lessee_email}
                                error={errors.lessee_email}
                                onChange={updateValue}
                            />
                        </div>
                        <div className="flex-1">
                            <TextInput
                                name="lessee_phone"
                                label="Lessee Phone"
                                id="lessee_phone"
                                type="tel"
                                disabled={readOnly}
                                readOnly={readOnly}
                                value={input.lessee_phone}
                                error={errors.lessee_phone}
                                onChange={updateValue}
                            />
                        </div>
                    </div>

                    <AddressEntry
                        fieldPrefix={"lessee"}
                        labelPrefix={"Lessee"}
                        readOnly={readOnly}
                        input={input}
                        errors={errors}
                        updateValue={updateValue}
                    />
                </fieldset>

                <fieldset className="mt-12">
                    <legend className="font-bold txt-md text-gray-500 my-6 pb-1 border-b block w-full">
                        Address
                    </legend>

                    <AddressEntry
                        readOnly={readOnly}
                        input={input}
                        errors={errors}
                        updateValue={updateValue}
                    />
                </fieldset>
                <input type="submit" hidden />
            </div>
        </form>
    );
}
