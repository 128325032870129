import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Forbidden from "components/Forbidden";
import useHasPermission from "hooks/usePermissions";
import React, { useState, useMemo } from "react";
import Spinner from "../../components/Spinner";
import useBilling from "../../hooks/useBilling";

export default function Invoices() {
    const { getInvoices, loading } = useBilling();
    const [invoices, setInvoices] = useState([]);

    useMemo(() => {
        async function fetchInvoices() {
            try {
                const response = await getInvoices();
                setInvoices(response.data);
            } catch (error) {
                console.log(error);
            }
        }
        fetchInvoices();
    }, [getInvoices]);

    if (loading) {
        return (
            <div>
                <Spinner />
            </div>
        );
    }

    if (invoices.length === 0) {
        return <div>No invoices found...</div>;
    }

    if (!useHasPermission("firm_information_access")) {
        return <Forbidden />;
    }

    return (
        <div className="">
            <ul className="space-y-2 divide-y divide-gray-100">
                {invoices.map((invoice) => (
                    <li
                        key={`invoice_${invoice.id}`}
                        className="flex items-center justify-between pt-2"
                    >
                        <div className="flex-1">{invoice.date}</div>
                        <div className="text-right tabular-nums flex-1">
                            {invoice.total}
                        </div>
                        <div className="flex-1 text-right pl-2">
                            <a
                                href={invoice.download_url}
                                download
                                className="text-brand hover:text-brand-600"
                            >
                                Download Receipt
                                <FontAwesomeIcon
                                    icon={faDownload}
                                    className="text-sm text-brand-600 ml-2"
                                />
                            </a>
                        </div>
                    </li>
                ))}
            </ul>
        </div>
    );
}
