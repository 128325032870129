import React from "react";

function Logo({ organization, message = "Sign In" }) {
    if (organization && organization.name) {
        return (
            <>
                {organization.logo_path && (
                    <div className="mx-auto mb-20 text-center">
                        <img
                            className="h-32 w-32 inline-block object-contain"
                            src={`${organization.logo_path}?w=${4 * 56}`}
                            alt={`Logo for ${organization.name}`}
                        />
                    </div>
                )}

                {!organization.logo_path && (
                    <h1 className="text-3xl mb-8 text-center font-bold">
                        {organization.name}
                    </h1>
                )}
            </>
        );
    }

    return <h1 className="text-3xl mb-8 text-center font-bold">{message}</h1>;
}

export default Logo;
