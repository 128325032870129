import React, { useState, useEffect } from "react";
import SelectInput from "../../../components/SelectInput";
import Api from "../../../services/Api";

export default function RecipientSelect({
    client,
    recipient_id,
    recipient_type,
    errors,
    updateValue,
    ...props
}) {
    const [recipientType, setRecipientType] = useState(recipient_type);
    const [recipientOptions, setRecipientOptions] = useState([]);
    const [fetchingRecipients, setFetchingRecipients] = useState(false);
    const recipientTypes = [
        { value: "", label: "None" },
        { value: "relationship", label: "Relationships" },
        { value: "trust", label: "Trusts" },
    ];

    useEffect(() => {
        const loadRecipientOptions = async () => {
            setFetchingRecipients(true);
            setRecipientOptions([]);
            if (recipientType === "relationship") {
                // relationships
                let response = await Api.get(
                    "clients/" + client.id + "/relationships",
                    {
                        "filter[trashed]": false,
                    }
                );
                let options = response.data.data.map((relationship) => {
                    return {
                        value: relationship.id,
                        label: relationship?.full_name_with_relationship,
                    };
                });
                options.splice(0, 0, { value: "", label: "None" });
                setRecipientOptions(options);
                setFetchingRecipients(false);
            } else {
                // trusts
                let response = await Api.get(
                    "clients/" + client.id + "/trusts",
                    {
                        "filter[trashed]": false,
                    }
                );
                let options = response.data.data.map((trust) => {
                    return {
                        value: trust.id,
                        label:
                            trust.name +
                            " (" +
                            trust.type_display_name +
                            ")",
                    };
                });
                options.splice(0, 0, { value: "", label: "None" });
                setRecipientOptions(options);
                setFetchingRecipients(false);
            }
        };

        if (recipientType) {
            loadRecipientOptions();
        }
    }, [recipientType, client.id]);

    function recipientTypeUpdate(e) {
        e.preventDefault();
        const event = {
            target: { value: e.target.value, name: e.target.name },
        };
        setRecipientType(e.target.value);
        updateValue(event);
        updateValue({ target: { value: "", name: "recipient_id" } });
    }

    return (
        <>
            <SelectInput
                className="mb-4"
                options={recipientTypes}
                loading={false}
                name="recipient_type"
                label="Recipient Type"
                error={errors.recipient_type}
                onChange={recipientTypeUpdate}
                value={recipient_type}
                {...props}
            />
            <SelectInput
                options={recipientOptions}
                loading={fetchingRecipients}
                name="recipient_id"
                error={errors.recipient_id}
                label="Recipient"
                onChange={updateValue}
                value={recipient_id}
                {...props}
            />
        </>
    );
}
