import React, { useEffect } from "react";
import SelectInput from "./SelectInput";
import Api from "../services/Api";
import useResourceTypeFetch from "hooks/useResourceTypeFetch";

export default function ResourceSelect({
    resourceType,
    client,
    autoFocus = false,
    excluded = false,
    emptyState = false,
    emptyStateValue = "",
    emptyStateLabel = "None Selected",
    value,
    optionValueKey = "value",
    ...props
}) {
    const [excludedOptions, setExcludedOptions] = React.useState(
        excluded || []
    );

    useEffect(() => {
        if (
            (!excluded && !excludedOptions.length) ||
            JSON.stringify(excluded) === JSON.stringify(excludedOptions)
        ) {
            return;
        }
        if (Array.isArray(excluded)) {
            setExcludedOptions(Array.isArray(excluded) ? excluded : [excluded]);
        } else {
            setExcludedOptions([]);
        }
    }, [excluded, excludedOptions]);

    const resourceTypes = Array.isArray(resourceType)
        ? resourceType
        : [resourceType];

    const { data: options, isLoading } = useResourceTypeFetch(
        {
            resourceTypes,
            client,
            excludedOptions,
            emptyStateLabel,
            emptyStateValue,
            emptyState,
        },
        {
            initialData: [
                {
                    value: "",
                    label: "Loading Options",
                },
            ],
        }
    );

    return (
        <SelectInput
            value={value}
            options={options}
            loading={isLoading}
            autoFocus={autoFocus}
            optionValueKey={optionValueKey}
            {...props}
        />
    );
}

export function EnumSelect({ enumType, ...props }) {
    const [loading, setLoading] = React.useState(true);
    const [options, setOptions] = React.useState([]);

    React.useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
                let response = await Api.get(`enums/${enumType}`);
                let options = response.data;

                setOptions([
                    { label: "None Selected", value: "", disabled: true },
                    ...options,
                ]);
                setLoading(false);
            } catch (error) {
                console.error(error);
            }
        };
        fetchData();
    }, [enumType]);

    return <SelectInput options={options} loading={loading} {...props} />;
}
