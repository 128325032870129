import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import TextInput from "../../components/TextInput";
import Button from "../../components/Button";
import Auth from "../../services/Auth";
import { getSearch } from "../../Utility";
import LoginSplash from "./LoginSplash";

class ResetPassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            redirectToLogin: false,
            password: "",
            password_confirmation: "",
            errorMessage: "",
            errors: {
                password: null,
            },
        };
    }

    updateValues = (event) => {
        const { name, value } = event.target;
        this.setState({ [name]: value });
    };

    resetPassword = async (e) => {
        e.preventDefault();

        const { token } = getSearch();
        const { password, password_confirmation } = this.state;

        try {
            await Auth.resetPassword({
                token,
                password,
                password_confirmation,
            });

            this.setState(() => ({
                redirectToLogin: true,
            }));
        } catch (e) {
            if (e.response && e.response.data.errors) {
                this.setState({ errors: e.response.data.errors });
            } else {
                this.setState({ errorMessage: e.response.data.message });
                this.setState({
                    errors: {
                        email: null,
                        password: null,
                    },
                });
            }
        }
    };

    render() {
        const { redirectToLogin } = this.state;

        if (redirectToLogin === true) {
            return (
                <Redirect
                    to={{
                        path: "/login",
                        state: {
                            message:
                                "Your password has been successfully been reset. Please login to continue.",
                        },
                    }}
                />
            );
        }

        return (
            <div className="h-screen flex">
                <div
                    className="bg-white text-gray-700 w-full flex-initial flex items-center justify-center"
                    style={{ maxWidth: "564px" }}
                >
                    <div className="max-w-xs w-full">
                        <h1 className="text-3xl mb-8 text-center">
                            Reset Password
                        </h1>
                        {this.props.location.state && (
                            <div className="max-w-xs text-red-600 my-3 text-center">
                                {this.props.location.state.message}
                            </div>
                        )}
                        {this.state.errorMessage && (
                            <div className="max-w-xs text-red-600 my-3 text-center">
                                {this.state.errorMessage}
                            </div>
                        )}
                        <form onSubmit={this.resetPassword}>
                            <div className="mb-6">
                                <TextInput
                                    name="password"
                                    label="Password"
                                    id="password"
                                    type="password"
                                    autoComplete="new-password"
                                    value={this.state.password}
                                    onChange={this.updateValues}
                                    error={this.state.errors.password}
                                />
                            </div>

                            <div className="mb-6">
                                <TextInput
                                    name="password_confirmation"
                                    label="Confirm Password"
                                    id="password_confirmation"
                                    type="password"
                                    autoComplete="confirm-new-password"
                                    value={this.state.password_confirmation}
                                    onChange={this.updateValues}
                                    error={
                                        this.state.errors.password_confirmation
                                    }
                                />
                            </div>

                            <Button
                                type="submit"
                                text="Reset Password"
                                className="w-64 mx-auto block bg-gray-800 hover:bg-gray-700 text-white border border-gray-800"
                            />
                        </form>
                    </div>
                </div>
                <LoginSplash />
            </div>
        );
    }
}

export default ResetPassword;
