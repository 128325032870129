import React from "react";
import VerticalTabs from "../../../components/VerticalTabs";

export default function TrustTabs({ basePath, ...props }) {
    let items = [
        {
            label: "Info",
            to: basePath + "",
        },
        {
            label: "Trustees",
            to: basePath + "/trustees",
        },
        {
            label: "Beneficiaries",
            to: basePath + "/beneficiaries",
        },
        {
            label: "Notes",
            to: basePath + "/notes",
        },
        {
            label: "Related Files",
            to: basePath + "/related-files",
        },
        {
            label: "Access",
            to: basePath + "/access",
        },
    ];

    return <VerticalTabs {...props} items={items} />;
}
