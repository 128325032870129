import React from "react";
import {
    faUsers,
    faChartBar,
    faUserFriends,
    faPeopleArrows,
} from "@fortawesome/free-solid-svg-icons";
import MainNavigationContainer from "../_partials/MainNavigationContainer";
import MainNavigationItem from "../_partials/MainNavigationItem";
import useHasPermission from "../../../hooks/usePermissions";

const MainNavigation = ({ ...props }) => {
    return (
        <MainNavigationContainer {...props}>
            <ul className="flex-grow">
                <MainNavigationItem
                    label="Dashboard"
                    to="/"
                    icon={faChartBar}
                    {...props}
                />
                <MainNavigationItem
                    label="Clients"
                    to="/clients"
                    icon={faUsers}
                    {...props}
                />
                {useHasPermission("firm_member_accounts_administration") && (
                    <MainNavigationItem
                        label="Team Members"
                        to="/members"
                        icon={faUserFriends}
                        {...props}
                    />
                )}
                {useHasPermission("firm_member_accounts_administration") && (
                    <MainNavigationItem
                        label="Advisors"
                        to="/collaborating-advisors"
                        icon={faPeopleArrows}
                        {...props}
                    />
                )}
            </ul>
        </MainNavigationContainer>
    );
};

export default MainNavigation;
