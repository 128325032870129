import React, { useEffect, useState } from "react";
import Api from "../../services/Api";
import debounce from "../../util/debounce-promise";
import Modal from "../../components/Modal";
import Select from "react-select/async";
import useLinkResource from "hooks/useLinkResource";

export default function ModalLinkResource({
    client,
    vaultEntry,
    show,
    setShow,
    onResult,
}) {
    const [resourceType, setResourceType] = useState(false);
    const [resourceTypeOptions, setResourceTypeOptions] = useState([]);
    const [resource, setResource] = useState(false);
    const [resourceOptions, setResourceOptions] = useState([]);

    const fetchResourceTypeOptions = async () => {
        const response = await Api.get(`vault-resources/types/${client.id}`);
        setResourceTypeOptions(response.data.data);
        return response.data.data;
    };

    const fetchResourceOptions = async () => {
        if (!resourceType.value) return [];
        const response = await Api.get(
            `vault-resources/types/${client.id}/${resourceType.value}`
        );
        setResourceOptions(response.data.data);
        return response.data.data;
    };

    const getResourceOptions = debounce(fetchResourceOptions, 250);
    const getResourceTypeOptions = debounce(fetchResourceTypeOptions, 250);

    const {
        onSubmit,
        isLoading,
        isSuccess,
        validationErrors: errors,
    } = useLinkResource();

    useEffect(() => {
        if (isSuccess) {
            reset();
            onResult();
            setShow(false);
        }
    }, [isSuccess]);

    const linkResource = async (event, resource) => {
        onSubmit(event, {
            type: resource.resource_type,
            resource_id: resource.resource_id,
            vault_id: vaultEntry.id,
        });
    };

    const reset = () => {
        setResource(false);
        setResourceType(false);
        setResourceOptions([]);
        setResourceTypeOptions([]);
    };

    return (
        <Modal
            isOpen={show}
            type=""
            title={`Link Resource`}
            body={`Select a resource below to link to this file.`}
            doneButtonTitle={"Link"}
            isLoading={isLoading}
            doneButtonDisabled={!resource}
            size="md"
            inputErrors={errors}
            onResult={async ({ result, event }) => {
                if (result !== true) {
                    setShow(false);
                    reset();
                    return;
                }
                linkResource(event, resource);
            }}
        >
            <div className="my-4" />

            <Select
                placeholder="Resource Type"
                value={resourceType ? resourceType : null}
                noOptionsMessage={() => "No Resource Type Available"}
                onChange={({ value }) => {
                    let o = resourceTypeOptions.filter(
                        (option) => option.value === value
                    );
                    setResourceType(o.length > 0 ? o[0] : null);
                }}
                cacheOptions
                loadOptions={getResourceTypeOptions}
                defaultOptions
                onInputChange={() => {
                    getResourceOptions();
                    setResource(null);
                }}
                menuPortalTarget={document.body}
                styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
            />

            <div className="my-4" />

            {resourceType && (
                <Select
                    placeholder="Resource"
                    key={JSON.stringify({ resourceType })}
                    value={resource ? resource : null}
                    noOptionsMessage={() =>
                        `No ${resourceType.plural} Available.`
                    }
                    onChange={({ value }) => {
                        let o = resourceOptions.filter(
                            (option) => option.value === value
                        );
                        setResource(o.length > 0 ? o[0] : null);
                    }}
                    loadOptions={getResourceOptions}
                    defaultOptions
                    menuPortalTarget={document.body}
                    styles={{
                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                    }}
                />
            )}
        </Modal>
    );
}
