import usePost from "./usePost";
import { revokeAuthorizedPartyInvite } from "services/AuthorizedPartyService";

export default function useRevokeAuthorizedPartyInvite(params, config = {}) {
    const { mutation, validationErrors } = usePost(
        (formData) => revokeAuthorizedPartyInvite(params, formData),
        { showToastOnError: true, showToastOnSuccess: true, ...config }
    );
    const onSubmit = (event, formData) => {
        event.preventDefault();
        return mutation.mutate(formData);
    };

    return { onSubmit, ...mutation, validationErrors };
}
