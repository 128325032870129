import React from "react";
import SelectInput from "./SelectInput";
// import Api from "../services/Api";
import useAdvisors from "hooks/useAdvisors";

export default function ClientAdvisorsSelect({ client, ...props }) {
    const [advisors, setAdvisors] = React.useState([]);

    const { data, isLoading, isRefetching } = useAdvisors({
        clientId: client.id,
        limit: 50,
    });

    React.useEffect(() => {
        let defaultOptions = [{ value: "", label: "None" }];
        if (props.prepend) {
            defaultOptions.splice(0, 0, props.prepend);
        }

        if (data) {
            let advisors = data.map((advisor) => {
                return {
                    value: `${advisor.class_type}:${advisor.id}`,
                    label: advisor.full_name,
                };
            });
            setAdvisors(defaultOptions.concat(advisors));
        }
    }, [data]);

    return (
        <SelectInput
            options={Array.isArray(advisors) ? advisors : []}
            loading={isLoading || isRefetching}
            {...props}
        />
    );
}
