import React, { useState, useRef, useMemo } from "react";
import PropTypes from "prop-types";
import InputErrors from "./InputErrors";
import useOnClickOutside from "../hooks/useOnClickOutside";
import {
    faEyeSlash,
    faQuestionCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tooltip } from "react-tippy";

const baseStyle =
    "bg-white focus:outline-none focus:outline-none border focus:border-gray-800 border-gray-200 px-3 flex w-full appearance-none leading-normal h-12";
const inlineStyle =
    "bg-transparent focus:outline-none focus:outline-none border-b text-gray-900 focus:border-gray-800 border-brand-500 px-1 py-0 inline-block w-full appearance-none leading-normal";

function getStyleType(styleType, rounded, hasError = false) {
    switch (styleType) {
        case "inline":
            return inlineStyle;
        default:
            return `${baseStyle} ${rounded ? "rounded" : ""} ${
                hasError ? "shadow-outline-red border-none" : ""
            }`;
    }
}

const TextInput = ({
    type = "text",
    id,
    name,
    label,
    onChange,
    onFocus = () => {},
    onBlur = () => {},
    placeholder = "",
    value,
    error,
    styleType = true,
    className = "",
    tabindex = 0,
    autoFocus = false,
    readOnly = false,
    disabled = false,
    required = false,
    rounded = true,
    reference = null,
    tooltip = "",
    secret = false,
    ...props
}) => {
    const [showSecret, setShowSecret] = useState(false);

    function handleBlur(event) {
        onBlur(event);
        setShowSecret(false);
    }

    function handleFocus(event) {
        onFocus(event);
        setShowSecret(true);
    }

    const mask = useMemo(
        function () {
            if (typeof value === "string" || value instanceof String) {
                return new Array(value.length).join("•");
            }

            return "";
        },
        [value]
    );

    return (
        <div>
            {label && !error && (
                <InputLabel
                    text={label}
                    htmlFor={name}
                    required={required}
                    tooltip={tooltip}
                />
            )}
            <div className="field">
                <InputErrors errors={error} />
                <div className={`relative`}>
                    {secret && !showSecret && (
                        <div
                            className={
                                styleType
                                    ? `${getStyleType(
                                          styleType,
                                          rounded,
                                          error && error.length
                                      )} ${
                                          disabled || readOnly
                                              ? "bg-gray-100 cursor-not-allowed z-10"
                                              : ""
                                      } ${className} absolute inset-0 pointer-events-none flex items-center justify-between`
                                    : `${className} absolute inset-0 pointer-events-none  flex items-center justify-between`
                            }
                        >
                            {mask}
                            {mask.length > 0 && (
                                <div className="ml-auto cursor-pointer">
                                    <FontAwesomeIcon
                                        icon={faEyeSlash}
                                        className="opacity-50"
                                    />
                                </div>
                            )}
                        </div>
                    )}
                    <input
                        max={type === "date" ? "3000-12-31" : null}
                        min={type === "date" ? "1800-01-01" : null}
                        type={type ?? "text"}
                        name={name}
                        className={
                            styleType
                                ? `${getStyleType(
                                      styleType,
                                      rounded,
                                      error && error.length
                                  )} ${
                                      disabled || readOnly
                                          ? "opacity-75 bg-gray-100 cursor-not-allowed"
                                          : ""
                                  } ${className}`
                                : `${className}`
                        }
                        placeholder={placeholder}
                        value={value || ""}
                        id={id || name}
                        onChange={onChange}
                        onFocus={handleFocus}
                        onBlur={handleBlur}
                        tabIndex={tabindex}
                        autoFocus={autoFocus}
                        readOnly={readOnly}
                        disabled={disabled}
                        required={required}
                        ref={reference}
                        {...props}
                    />
                </div>
            </div>
        </div>
    );
};

TextInput.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    placehoder: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    error: PropTypes.array,
};

export const InputLabel = ({
    text,
    htmlFor,
    required = false,
    tooltip = "",
}) => {
    return (
        <label htmlFor={htmlFor} className="mb-1 block text-sm text-gray-900">
            {text} {required && <span className="text-gray-500">*</span>}
            {tooltip && (
                <Tooltip
                    title={tooltip}
                    position="top"
                    duration={150}
                    followCursor={false}
                    distance={16}
                >
                    <FontAwesomeIcon
                        icon={faQuestionCircle}
                        className="text-sm text-gray-500 cursor-pointer"
                        fixedWidth
                    />
                </Tooltip>
            )}
        </label>
    );
};

export const DataList = ({
    type,
    id,
    name,
    label,
    onChange,
    onBlur,
    placeholder,
    value,
    error,
    styleType = true,
    className = "",
    tabindex = 0,
    autoFocus = false,
    readOnly = false,
    disabled = false,
    required = false,
    list = [],
    ...props
}) => {
    const ref = useRef();
    const textInput = useRef();
    useOnClickOutside(ref, () => setShowDataList(false));
    const [showDataList, setShowDataList] = useState(false);
    const [currentSelection, setCurrentSelection] = useState(-1);

    function chooseOption(option) {
        return onChange({ target: { name: name, value: option } });
    }

    function handleKeyDown(event) {
        if (showDataList === false) return setShowDataList(true);

        const datalistLength = list.length - 1;

        if (event.key === "Escape") {
            setCurrentSelection(-1);
            return setShowDataList(false);
        }

        if (
            event.key === "ArrowUp" ||
            (event.shiftKey && event.key === "Tab")
        ) {
            setCurrentSelection(
                currentSelection > 0 ? currentSelection - 1 : 0
            );
            if (currentSelection <= 0) {
                textInput.current.focus();
            }
            return event.preventDefault();
        }

        if (event.key === "ArrowDown" || event.key === "Tab") {
            setCurrentSelection(
                currentSelection >= datalistLength
                    ? datalistLength
                    : currentSelection + 1
            );
            return event.preventDefault();
        }

        if (event.key === "Enter") {
            event.preventDefault();
            list[currentSelection]
                ? chooseOption(list[currentSelection])
                : chooseOption(value);
            return setShowDataList(false);
        }
    }

    return (
        <div ref={ref}>
            {label && !error && (
                <InputLabel text={label} htmlFor={name} required={required} />
            )}
            <div className="field">
                <InputErrors errors={error} />
                <div
                    className={`relative ${
                        error && error.length
                            ? "shadow-outline-red rounded"
                            : ""
                    }`}
                >
                    <input
                        aria-haspopup="true"
                        type={type ?? "text"}
                        name={name}
                        className={
                            styleType
                                ? `${baseStyle} ${
                                      disabled
                                          ? "opacity-75 bg-gray-100 cursor-not-allowed"
                                          : ""
                                  }`
                                : `${className}`
                        }
                        placeholder={
                            placeholder
                                ? placeholder
                                : "Select a value or type in your own"
                        }
                        value={value}
                        id={name || id}
                        onChange={onChange}
                        onFocus={() => {
                            setCurrentSelection(-1);
                            setShowDataList(true);
                        }}
                        onBlur={onBlur}
                        tabIndex={tabindex}
                        autoFocus={autoFocus}
                        autoComplete="off"
                        readOnly={readOnly}
                        disabled={disabled}
                        required={required}
                        ref={textInput}
                        onKeyDown={(e) => handleKeyDown(e)}
                        {...props}
                    />
                    {Array.isArray(list) && list.length > 0 && showDataList && (
                        <ul
                            className="absolute z-10 w-full shadow-md bg-white"
                            role="menu"
                            onKeyDown={(e) => handleKeyDown(e)}
                            aria-expanded={showDataList ? "true" : "false"}
                        >
                            {placeholder && value && (
                                <li className="px-3 pt-3 text-gray-300 text-sm">
                                    {placeholder}
                                </li>
                            )}
                            {list.map((item, idx) => {
                                return (
                                    <li className="text-left" key={idx}>
                                        <button
                                            ref={(button) => {
                                                if (
                                                    button &&
                                                    currentSelection === idx
                                                ) {
                                                    button.focus();
                                                }
                                            }}
                                            role="menuitem"
                                            tabIndex={tabindex}
                                            type="button"
                                            onFocus={() =>
                                                setCurrentSelection(idx)
                                            }
                                            onClick={() => {
                                                chooseOption(item);
                                                setShowDataList(false);
                                            }}
                                            value={item}
                                            className={`text-left block w-full p-3 hover:bg-brand hover:text-white`}
                                        >
                                            {item}
                                        </button>
                                    </li>
                                );
                            })}
                        </ul>
                    )}
                </div>
            </div>
        </div>
    );
};

export function FakeInput({
    type,
    id,
    name,
    label,
    placeholder,
    value,
    error,
    styleType = true,
    className = "",
    readOnly = false,
    disabled = false,
    required = false,
    rounded = true,
    reference,
    children,
    ...props
}) {
    return (
        <div>
            {label && !error && (
                <InputLabel text={label} htmlFor={name} required={required} />
            )}
            <div className="field">
                <InputErrors errors={error} />
                <div className={`relative`}>
                    <div
                        type={type ?? "text"}
                        name={name}
                        className={
                            styleType
                                ? `${getStyleType(
                                      styleType,
                                      rounded,
                                      error && error.length
                                  )} ${
                                      disabled || readOnly
                                          ? "opacity-75 bg-gray-100 cursor-not-allowed"
                                          : ""
                                  } flex items-center space-x-2 ${className}`
                                : `flex items-center space-x-2 ${className}`
                        }
                        id={id || name}
                        ref={reference}
                        {...props}
                    >
                        {!!children !== false && children}
                        {!!children === false && (value || placeholder)}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default TextInput;
