import React from "react";
import { Prompt } from "react-router-dom";

export default function UnsavedChanges({ when, ...props }) {
    return (
        <Prompt
            when={when}
            message="There are unsaved changes, do you wish to discard them?"
            {...props}
        />
    );
}
