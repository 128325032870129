import usePost from "./usePost";
import { createClientAdvisor } from "services/AdvisorService";
import { useQueryClient } from "react-query";

export default function useCreateAdvisor(clientId, formData) {
    const queryClient = useQueryClient();
    const { mutation, validationErrors } = usePost(
        (formData) => createClientAdvisor(clientId, formData),
        {
            showToastOnError: true,
            showToastOnSuccess: true,
            onSuccess: () => {
                queryClient.invalidateQueries("client-advisors");
            },
        }
    );
    const onSubmit = (event) => {
        event.preventDefault();
        return mutation.mutateAsync(formData);
    };

    return { onSubmit, ...mutation, validationErrors };
}
