import React from "react";
import { Switch, Route } from "react-router-dom";
import RetirementAccountIndex from "./RetirementAccountIndex";
import AddRetirementAccount from "./AddRetirementAccount";
import EditRetirementAccount from "./EditRetirementAccount";

export default function RetirementAccounts(parentProps) {
    return (
        <Switch>
            <Route
                exact
                path={[
                    "/clients/:clientId/retirement-accounts",
                    "/clients/:clientId/retirement-accounts/disabled",
                    "/client/retirement-accounts",
                    "/client/retirement-accounts/disabled",
                ]}
                render={(props) => (
                    <RetirementAccountIndex {...parentProps} {...props} />
                )}
            />
            <Route
                exact
                path={[
                    "/clients/:clientId/retirement-accounts/add",
                    "/client/retirement-accounts/add",
                ]}
                render={(props) => (
                    <AddRetirementAccount {...parentProps} {...props} />
                )}
            />
            <Route
                path={[
                    "/clients/:clientId/retirement-accounts/:retirementAccountId/edit",
                    "/client/retirement-accounts/:retirementAccountId/edit",
                ]}
                render={(props) => (
                    <EditRetirementAccount {...parentProps} {...props} />
                )}
            />
            <Route
                path={[
                    "/clients/:clientId/retirement-accounts/:retirementAccountId/view",
                    "/client/retirement-accounts/:retirementAccountId/view",
                ]}
                render={(props) => (
                    <EditRetirementAccount
                        readOnly={true}
                        {...parentProps}
                        {...props}
                    />
                )}
            />
        </Switch>
    );
}
