import React from "react";
import PropTypes from "prop-types";
import InputErrors from "./InputErrors";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { InputLabel } from "./TextInput";

const SelectInput = ({
    options,
    id,
    name,
    label,
    onChange,
    onBlur,
    value,
    optionValueKey = "value",
    error,
    styleType = true,
    className,
    defaultOption = false,
    defaultOptionDisabled = false,
    defaultOptionValue = "",
    disabled = false,
    readOnly = false,
    autoFocus = false,
    tabindex = 0,
    required = false,
    ...props
}) => {
    const baseClass =
        "form-select focus:outline-none rounded border focus:border-gray-800 border-gray-200 h-12 px-3 pr-8 block w-full appearance-none leading-normal";
    const inlineClass =
        "bg-transparent focus:outline-none focus:outline-none border-b text-gray-900 focus:border-gray-800 border-brand-500 px-1 py-0 inline-block w-full appearance-none leading-normal";

    function getStyleType() {
        switch (styleType) {
            case "inline":
                return inlineClass;
            default:
                return baseClass;
        }
    }

    function renderOption(option) {
        if (typeof option === "object") {
            if (option.label && Array.isArray(option.options)) {
                return (
                    <optgroup label={option.label} key={option.label}>
                        {option.options.map((option, idx) =>
                            renderOption(option, idx)
                        )}
                    </optgroup>
                );
            } else {
                return (
                    <option
                        value={option[optionValueKey || "value"]}
                        key={`option_${option.value}_${Math.random(10)}`}
                        disabled={option.disabled}
                    >
                        {option.label}
                    </option>
                );
            }
        }

        return (
            <option value={option} key={option}>
                {option}
            </option>
        );
    }

    const renderOptions = () => {
        if (options.length === 0 && !defaultOption) {
            return (
                <div
                    className={`flex items-center justify-between text-gray-400 ${baseClass}`}
                >
                    No options available
                </div>
            );
        }

        const defaultOptionProps = {};
        if (defaultOptionDisabled) defaultOptionProps.disabled = true;

        return (
            <select
                name={name}
                className={`${getStyleType()} ${
                    disabled || readOnly
                        ? "opacity-75 bg-gray-100 cursor-not-allowed"
                        : ""
                } ${className}`}
                value={value === null || value === undefined ? "" : value}
                id={name || id}
                onChange={onChange}
                onBlur={onBlur}
                autoFocus={autoFocus}
                disabled={disabled || readOnly}
                tabIndex={tabindex}
                required={required}
            >
                {defaultOption && (
                    <option value={defaultOptionValue} {...defaultOptionProps}>
                        {defaultOption}
                    </option>
                )}
                {options
                    .concat(props.append ? props.append : false)
                    .filter((option) => option !== false)
                    .map((option) => renderOption(option))}
            </select>
        );
    };

    return (
        <div>
            {label && !error && (
                <InputLabel text={label} htmlFor={name} required={required} />
            )}
            <div className="field">
                <InputErrors errors={error} />
                <div
                    className={`flex items-center ${
                        error && error.length
                            ? "shadow-outline-red rounded"
                            : ""
                    }`}
                >
                    {props.loading ? (
                        <div
                            className={`flex items-center justify-between text-gray-400 ${baseClass}`}
                        >
                            Loading options...{" "}
                            <span className="animate animate-spin">
                                <FontAwesomeIcon icon={faSpinner} />
                            </span>
                        </div>
                    ) : (
                        renderOptions()
                    )}
                </div>
            </div>
        </div>
    );
};

export function StateSelect(props) {
    const states = [
        {
            label: "Alabama",
            value: "AL",
        },
        {
            label: "Alaska",
            value: "AK",
        },
        // {
        //     "label": "American Samoa",
        //     "value": "AS"
        // },
        {
            label: "Arizona",
            value: "AZ",
        },
        {
            label: "Arkansas",
            value: "AR",
        },
        {
            label: "California",
            value: "CA",
        },
        {
            label: "Colorado",
            value: "CO",
        },
        {
            label: "Connecticut",
            value: "CT",
        },
        {
            label: "Delaware",
            value: "DE",
        },
        {
            label: "District Of Columbia",
            value: "DC",
        },
        // {
        //     "label": "Federated States Of Micronesia",
        //     "value": "FM"
        // },
        {
            label: "Florida",
            value: "FL",
        },
        {
            label: "Georgia",
            value: "GA",
        },
        // {
        //     "label": "Guam Gu",
        //     "value": "GU"
        // },
        {
            label: "Hawaii",
            value: "HI",
        },
        {
            label: "Idaho",
            value: "ID",
        },
        {
            label: "Illinois",
            value: "IL",
        },
        {
            label: "Indiana",
            value: "IN",
        },
        {
            label: "Iowa",
            value: "IA",
        },
        {
            label: "Kansas",
            value: "KS",
        },
        {
            label: "Kentucky",
            value: "KY",
        },
        {
            label: "Louisiana",
            value: "LA",
        },
        {
            label: "Maine",
            value: "ME",
        },
        // {
        //     "label": "Marshall Islands",
        //     "value": "MH"
        // },
        {
            label: "Maryland",
            value: "MD",
        },
        {
            label: "Massachusetts",
            value: "MA",
        },
        {
            label: "Michigan",
            value: "MI",
        },
        {
            label: "Minnesota",
            value: "MN",
        },
        {
            label: "Mississippi",
            value: "MS",
        },
        {
            label: "Missouri",
            value: "MO",
        },
        {
            label: "Montana",
            value: "MT",
        },
        {
            label: "Nebraska",
            value: "NE",
        },
        {
            label: "Nevada",
            value: "NV",
        },
        {
            label: "New Hampshire",
            value: "NH",
        },
        {
            label: "New Jersey",
            value: "NJ",
        },
        {
            label: "New Mexico",
            value: "NM",
        },
        {
            label: "New York",
            value: "NY",
        },
        {
            label: "North Carolina",
            value: "NC",
        },
        {
            label: "North Dakota",
            value: "ND",
        },
        // {
        //     "label": "Northern Mariana Islands",
        //     "value": "MP"
        // },
        {
            label: "Ohio",
            value: "OH",
        },
        {
            label: "Oklahoma",
            value: "OK",
        },
        {
            label: "Oregon",
            value: "OR",
        },
        // {
        //     "label": "Palau",
        //     "value": "PW"
        // },
        {
            label: "Pennsylvania",
            value: "PA",
        },
        // {
        //     "label": "Puerto Rico",
        //     "value": "PR"
        // },
        {
            label: "Rhode Island",
            value: "RI",
        },
        {
            label: "South Carolina",
            value: "SC",
        },
        {
            label: "South Dakota",
            value: "SD",
        },
        {
            label: "Tennessee",
            value: "TN",
        },
        {
            label: "Texas",
            value: "TX",
        },
        {
            label: "Utah",
            value: "UT",
        },
        {
            label: "Vermont",
            value: "VT",
        },
        {
            label: "Virgin Islands",
            value: "VI",
        },
        {
            label: "Virginia",
            value: "VA",
        },
        {
            label: "Washington",
            value: "WA",
        },
        {
            label: "West Virginia",
            value: "WV",
        },
        {
            label: "Wisconsin",
            value: "WI",
        },
        {
            label: "Wyoming",
            value: "WY",
        },
    ];

    return <SelectInput {...props} options={states} />;
}

SelectInput.propTypes = {
    options: PropTypes.array.isRequired,
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    required: PropTypes.bool,
    error: PropTypes.array,
    defaultOption: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
};

export default SelectInput;
