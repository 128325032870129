import React, { useState } from "react";
import { Route } from "react-router-dom";
import PageContainer from "../../components/PageContainer";
import { MemberHeader } from "../../components/PageHeader";
import MemberNav from "../members/_partials/MemberNav";
import Button from "../../components/Button";
import QuestionnaireTable from "./_partials/QuestionnaireTable";
import Modal from "../../components/Modal";
import AddQuestionnaire from "./AddQuestionnaire";
import SectionHeader from "../../components/SectionHeader";
import useHasPermission from "hooks/usePermissions";

export default function QuestionnaireIndex({ member, ...props }) {
    const [needsUpdated, setNeedsUpdated] = useState(0);
    const [showAddQuestionnaireModal, setShowAddQuestionnaireModal] =
        useState(false);
    const canCustomizeQuestions = useHasPermission("question_customization");

    return (
        <>
            <MemberHeader text={member.full_name}>
                {!member.deleted_at && canCustomizeQuestions && (
                    <Button
                        type="button"
                        onClick={() => setShowAddQuestionnaireModal(true)}
                        text="Add Questionnaire"
                    />
                )}
            </MemberHeader>
            <PageContainer>
                <div className="flex">
                    <div className="mr-10 flex-initial">
                        <MemberNav
                            active={props.history.location.pathname}
                            member={member}
                        />
                    </div>
                    <div className="flex-1">
                        <SectionHeader>Questionnaires</SectionHeader>
                        <Route
                            exact
                            path="/members/:memberId/questionnaires"
                            render={(props) => (
                                <QuestionnaireTable
                                    member={member}
                                    needsUpdated={needsUpdated}
                                    setNeedsUpdated={setNeedsUpdated}
                                    setShowAddQuestionnaireModal={
                                        setShowAddQuestionnaireModal
                                    }
                                    {...props}
                                />
                            )}
                        />
                    </div>
                </div>
            </PageContainer>

            <Modal
                isOpen={showAddQuestionnaireModal}
                backgroundDismiss={true}
                noWrapper={true}
                title="Add Questionnaire"
                size="lg"
                onCancel={() => setShowAddQuestionnaireModal(false)}
                component={() => (
                    <AddQuestionnaire
                        member={member}
                        onSave={() => {
                            setNeedsUpdated(needsUpdated + 1);
                            setShowAddQuestionnaireModal(false);
                        }}
                        {...props}
                    />
                )}
            />
        </>
    );
}
