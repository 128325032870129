import React from "react";
import InputErrors from "../../../components/InputErrors";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import TextInput from "../../../components/TextInput";
import useUpdateArray from "../../../hooks/useUpdateArray";
import Fieldset from "../../../components/Fieldset";

export default function Locations({ locations, errors, setLocations }) {
    const updateArrayValue = useUpdateArray;

    function appendLocation() {
        if (locations.length < 10) {
            setLocations((locations) => [...locations, ""]);
        }
    }

    function removeLocation(idx) {
        if (locations.length > 1) {
            setLocations((locations) =>
                locations.filter((location, idxs) => idxs !== idx)
            );
        }
    }

    return (
        <>
            <Fieldset legend="My Locations">
                <InputErrors errors={errors["data.locations"]} />
                {locations.map((location, idx) => (
                    <div className="flex items-end spacing-3 mb-6" key={idx}>
                        <div className="flex-1">
                            <TextInput
                                label={`Location # ${idx + 1}`}
                                value={locations[idx]}
                                name={locations[idx]}
                                onChange={(e) => {
                                    setLocations(
                                        updateArrayValue(
                                            locations,
                                            idx,
                                            e.target.value
                                        )
                                    );
                                }}
                                autoFocus={locations.length === idx + 1}
                            />
                        </div>
                        {locations.length > 1 && (
                            <div className="flex-0">
                                <button
                                    type="button"
                                    onClick={() => removeLocation(idx)}
                                    className="text-base flex items-center hover:text-gray-900 flex-initial px-3 h-13"
                                >
                                    <FontAwesomeIcon
                                        icon={faTimesCircle}
                                        className="text-gray-500 hover:text-red-500"
                                    />
                                </button>
                            </div>
                        )}
                    </div>
                ))}
                <div className="flex">
                    <button
                        type="button"
                        onClick={(e) => appendLocation(e)}
                        className="text-gray-500 text-sm hover:text-brand"
                    >
                        <FontAwesomeIcon icon={faPlusCircle} className="mr-1" />{" "}
                        Add Another Location
                    </button>
                </div>
            </Fieldset>
        </>
    );
}
