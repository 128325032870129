import React, { useEffect } from "react";

import PageHeader from "../../../components/PageHeader";
import PageContainer from "../../../components/PageContainer";
import { formatDate } from "../../../util/TextUtil";
import useMessageTemplates from "./useMessageTemplates";
import { Link } from "react-router-dom";
import OrganizationPageLayout from "../_partials/OrganizationPageLayout";

export default function MessageTemplatesIndex() {
    const { defaultTemplates, userTemplates } = useMessageTemplates();

    useEffect(() => {
        document.title = "Message Templates";
    }, []);

    return (
        <>
            <PageHeader text="Message Templates"></PageHeader>
            <PageContainer>
                <OrganizationPageLayout>
                    <table className="w-full text-left">
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Channel</th>
                                <th>Last Modified</th>
                                <th>Customized</th>
                                <th className="text-right">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {defaultTemplates.map((template) => {
                                const userTemplate =
                                    userTemplates.filter(
                                        (t) =>
                                            t.message_id === template.message_id
                                    )[0] || null;
                                return (
                                    <tr key={template.message_id}>
                                        <td className="w-72">
                                            <Link
                                                to={`/organization/message-templates/${template.message_id}/edit`}
                                                className="hover:text-brand cursor-pointer focus:outline-none focus:underline"
                                            >
                                                {template.name}
                                            </Link>
                                        </td>
                                        <td>{template.channel}</td>
                                        <td>
                                            {userTemplate
                                                ? formatDate(
                                                      userTemplate.updated_at
                                                  )
                                                : "N/A"}
                                        </td>
                                        <td>
                                            <span
                                                className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium leading-4 ${
                                                    userTemplate
                                                        ? "bg-green-100 text-green-800"
                                                        : "bg-red-100 text-red-800"
                                                }`}
                                            >
                                                {userTemplate ? "Yes" : "No"}
                                            </span>
                                        </td>
                                        <td className="text-right">
                                            <Link
                                                to={`/organization/message-templates/${template.message_id}/edit`}
                                                className="text-gray-600 hover:text-gray-900 cursor-pointer focus:outline-none focus:underline"
                                            >
                                                Edit
                                            </Link>
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </OrganizationPageLayout>
            </PageContainer>
        </>
    );
}
