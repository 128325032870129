import Api from "./Api";
import UploadApi from "./UploadApi";

const buildCrumb = (breadcrumb, arr) => {
    arr.unshift(breadcrumb);
    if (breadcrumb.parent_recursive) {
        buildCrumb(breadcrumb.parent_recursive, arr);
    }
    return arr;
};

export const fetchVault = async function ({ queryKey }) {
    const [, { ...params }] = queryKey;
    const { clientId, ...qs } = params;
    let res = await Api.get("clients/" + clientId + "/vault", qs);
    res.data.breadcrumbs = res.data.breadcrumbs
        ? buildCrumb(res.data.breadcrumbs, [])
        : [];
    return { data: res.data };
};

export const uploadFileToVault = async ({
    client,
    nameWithoutExtension,
    extension,
    description,
    defaultFolderName = null,
    file,
    parentId,
    onProgress,
}) => {
    const options = {
        name: nameWithoutExtension,
        extension,
        description,
        size: file.size,
        parent_id: parentId,
    };

    if (defaultFolderName && !parentId) {
        options.defaultFolderName = defaultFolderName;
    }

    let url = `clients/${client.id}/vault?json=${JSON.stringify(options)}`;

    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = function () {
            // Since it contains the Data URI, we should remove the prefix and keep only Base64 string
            var data = reader.result.replace(/^data:.+;base64,/, "");

            UploadApi.chunk(url, file, data, onProgress, reject, resolve);
        };

        reader.readAsDataURL(file);
    });
};
