import React from "react";
import SelectInput from "../../../components/SelectInput";
import { useOtherInsurancePolicies } from "hooks/useInsurancePolicies";

export default function InsuranceSelect({ client, ...props }) {
    const { data, isLoading } = useOtherInsurancePolicies({
        clientId: client.id,
    });

    let insurancePolicies = data?.map((policy) => {
        return {
            value: policy.id,
            label: policy.type + ": " + policy.number,
        };
    });

    if (Array.isArray(insurancePolicies)) {
        insurancePolicies.splice(0, 0, {
            value: "",
            label: "None",
        });
    }

    return (
        <SelectInput
            options={Array.isArray(insurancePolicies) ? insurancePolicies : []}
            loading={isLoading}
            {...props}
        />
    );
}
