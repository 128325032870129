import React, { useState, useEffect, useReducer, useCallback } from "react";
import Api from "../../services/Api";
import { useToasts } from "react-toast-notifications";
import SaveButton from "../../components/SaveButton";
import { Redirect } from "react-router-dom";
import ClientPageHeader, { Heading } from "../../components/ClientPageHeader";
import PageContainer from "../../components/PageContainer";
import DisabilityPoliciesForm from "./_partials/DisabilityPoliciesForm";

export default function AddDisabilityPolicy({ client, clientPath, ...props }) {
    const { addToast } = useToasts();
    const [errors, setErrors] = useState([]);
    const [redirectToId, setRedirectToId] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [input, setInput] = useReducer(
        (state, newState) => ({ ...state, ...newState }),
        {
            name: "",
            number: "",
            type: "Disability",
            insured_id: client.id,
            benefit_frequency_id: 4,
            frequency_id: 1,
            managed_by_id: "",
            managed_by_address: "",
            managed_by_address_2: "",
            managed_by_city: "",
            managed_by_state: "",
            managed_by_postal_code: "",
            managed_by_country: "",
            lifetime_benefits: 0,
        }
    );

    useEffect(() => {
        document.title = "Add Disability/LTC Policy";
    }, []);

    const updateValue = useCallback((event) => {
        setInput({ [event.target.name]: event.target.value });
    }, []);

    async function store(e) {
        e.preventDefault();
        try {
            setIsSaving(true);
            const body = { ...input };
            let response = await Api.post(
                "clients/" + client.id + "/disability-policies",
                body
            );
            addToast(response.data.message);
            setErrors([]);
            setIsSaving(false);
            setRedirectToId(response.data.data.id);
        } catch (e) {
            setIsSaving(false);
            setErrors([]);
            if (e.response.data.errors) {
                setErrors(e.response.data.errors);
            } else {
                addToast(e.response.data.message, { type: "error" });
            }
        }
    }

    if (redirectToId) {
        return (
            <Redirect
                to={`${clientPath}/disability-policies/${redirectToId}/edit/related-files`}
            />
        );
    }

    return (
        <>
            <ClientPageHeader client={client}>
                <Heading
                    backTo={`${clientPath}/disability-policies`}
                    backText="All Policies"
                >
                    Add Disability/LTC Policy
                </Heading>
                <div className="flex-0">
                    <SaveButton isLoading={isSaving} onClick={store} />
                </div>
            </ClientPageHeader>
            <PageContainer>
                <div className="max-w-3xl mx-auto">
                    <DisabilityPoliciesForm
                        client={client}
                        input={input}
                        errors={errors}
                        updateValue={updateValue}
                        onSubmit={store}
                        {...props}
                    />
                </div>
            </PageContainer>
        </>
    );
}
