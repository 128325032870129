// https://goshakkk.name/array-form-inputs/
import React, { useState } from "react";
import TextInput from "../../components/TextInput";
import Api from "../../services/Api";
import { useToasts } from "react-toast-notifications";
import Button from "../../components/Button";
import { faEnvelopeOpen } from "@fortawesome/free-solid-svg-icons";

function InviteMembers(props) {
    const { addToast } = useToasts();
    const [emails, setEmails] = useState([{ email: "" }]);
    const [errors, setErrors] = useState([]);

    function updateEmail(idx, event) {
        const newEmails = emails.map((email, sidx) => {
            if (idx !== sidx) return email;
            return { ...email, email: event.target.value };
        });
        setEmails(newEmails);
    }

    function appendNewEmail() {
        if (emails.length < 10) {
            setEmails((emails) => [...emails, { email: "" }]);
        }
    }

    function removeEmail(idx) {
        if (emails.length > 1) {
            setEmails((emails) => emails.filter((s, sidx) => idx !== sidx));
        }
    }

    async function inviteMembers(e) {
        e.preventDefault();
        try {
            let response = await Api.post("invitations", {
                invitees: emails,
            });
            setEmails([{ email: "" }]);
            setErrors([{ emails: null, invitees: null }]);
            addToast(response.data.message);
            props.onResult();
        } catch (e) {
            if (e.response.data.errors) {
                setErrors(e.response.data.errors);
            } else {
                addToast(e.response.data.message, { type: "error" });
            }
        }
    }

    function renderEmailInputs() {
        return emails.map((email, idx) => (
            <div className="mb-8 flex items-center" key={idx}>
                <div className="flex-1">
                    <TextInput
                        name="emails[]"
                        value={email.email}
                        placeholder="Email"
                        type="email"
                        onChange={(event) => updateEmail(idx, event)}
                        error={errors["invitees." + idx + ".email"]}
                        autoFocus={idx === 0 ? true : false}
                    />
                </div>
                {emails.length > 1 && (
                    <button
                        type="button"
                        onClick={() => removeEmail(idx)}
                        className="text-base flex items-center hover:text-gray-900 flex-initial pl-3"
                    >
                        <svg
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            className="w-5 h-5"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
                            ></path>
                        </svg>
                    </button>
                )}
            </div>
        ));
    }

    return (
        <form onSubmit={inviteMembers}>
            <p className="mb-8">
                Enter the email address of the members you would like to invite.
            </p>
            {renderEmailInputs()}
            <div className="mt-3">
                <button
                    type="button"
                    onClick={(event) => appendNewEmail(event)}
                    className="text-base flex items-center text-gray-700 hover:text-gray-900"
                >
                    <svg
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        className="w-5 h-5 mr-1"
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                        ></path>
                    </svg>{" "}
                    Add Another Team Member
                </button>
            </div>

            <div className="flex items-center space-x-2 mt-8 justify-end">
                <div className="flex items-center space-x-2 w-64">
                    <Button
                        appearance="light"
                        onClick={props.onCancel}
                        text="Cancel"
                    />
                    <Button
                        onClick={inviteMembers}
                        icon={faEnvelopeOpen}
                        text="Invite Members"
                    />
                </div>
            </div>
        </form>
    );
}

export default InviteMembers;
