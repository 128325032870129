import React, { useState, useEffect, useRef, useReducer } from "react";
import ClientPageHeader, { Heading } from "../../components/ClientPageHeader";
import PageContainer from "../../components/PageContainer";
import Api from "../../services/Api";
import { useToasts } from "react-toast-notifications";
import Spinner from "../../components/Spinner";
import { formatDate } from "../../util/TextUtil";
import TextInput from "../../components/TextInput";
import Button from "../../components/Button";
import { downloadFile } from "../../util/Downloader";
import Panel, { PanelHeader } from "../../components/Panel";
import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";
import Money from "../../components/Money";
import useHasAnyPermissions from "hooks/useHasAnyPermissions";
import { BasicExclusionTotals } from "views/funding_table/_partials/TransfersTable";
import Percentage from "components/Percentage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useClient from "hooks/useClient";

export default function ListOfGifts({ client }) {
    document.title = "List of Gifts Report";
    const { clientPath } = useClient();
    const currentEndDate = new Date();
    const currentStartDate = new Date();
    currentStartDate.setDate(currentEndDate.getDate() - 30);
    const isForbidden = !useHasAnyPermissions([
        "report_global_access",
        "report_list_of_gifts",
    ]);
    const [loadingChanges, setLoadingChanges] = useState(false);
    const [needsUpdated, setNeedsUpdated] = useState(0);
    const [gifts, setGifts] = useState([]);
    const endDate = useRef(formatDate(currentEndDate, false));
    const startDate = useRef(formatDate(currentStartDate, false));
    const [dates, setDates] = useReducer(
        (state, newState) => ({ ...state, ...newState }),
        {
            startDate: formatDate(currentStartDate, false, {
                format: "Y-MM-DD",
            }),
            endDate: formatDate(currentEndDate, false, {
                format: "Y-MM-DD",
            }),
        }
    );

    const [isExporting, setIsExporting] = useState(false);

    const { addToast } = useToasts();
    const [totalClientExclusionUsed, setTotalClientExclusionUsed] = useState(0);
    const [totalSpouseExclusionUsed, setTotalSpouseExclusionUsed] = useState(0);

    useEffect(() => {
        async function fetchData() {
            try {
                setLoadingChanges(true);
                let response = await Api.get(
                    "clients/" + client.id + "/reports/list-of-gifts",
                    {
                        startDate: formatDate(startDate.current, false, {
                            format: "Y-MM-DD",
                        }),
                        endDate: formatDate(endDate.current, false, {
                            format: "Y-MM-DD",
                        }),
                    }
                );
                setGifts(response.data.data);
                setTotalClientExclusionUsed(
                    response.data.totalClientExclusionUsed
                );
                setTotalSpouseExclusionUsed(
                    response.data.totalSpouseExclusionUsed
                );
                setLoadingChanges(false);
            } catch (error) {
                setLoadingChanges(false);
                console.log(error);
            }
        }
        fetchData();
    }, [needsUpdated, client.id]);

    async function exportRecords(e) {
        e.preventDefault();
        try {
            setIsExporting(true);
            let response = await Api.get(
                "clients/" + client.id + "/reports/list-of-gifts",
                {
                    export: "pdf",
                    startDate: formatDate(startDate.current, false, {
                        format: "Y-MM-DD",
                    }),
                    endDate: formatDate(endDate.current, false, {
                        format: "Y-MM-DD",
                    }),
                },
                { responseType: "arraybuffer" }
            );
            await downloadFile(response, "list-of-gifts");
            setIsExporting(false);
            addToast("Export downloaded");
        } catch (e) {
            setIsExporting(false);
            addToast(e.response.data.message, { type: "error" });
        }
    }

    const dateUpdate = (e) => {
        e.preventDefault();
        if (e.target.name === "start_date") {
            startDate.current = e.target.value;
            setDates({ startDate: e.target.value });
        } else {
            endDate.current = e.target.value;
            setDates({ endDate: e.target.value });
        }
    };

    return (
        <>
            <ClientPageHeader client={client}>
                <Heading
                    backTo={`${clientPath}/reports`}
                    backText="All Reports"
                >
                    List of Gifts Report
                </Heading>
            </ClientPageHeader>
            <PageContainer forbidden={isForbidden}>
                {loadingChanges ? (
                    <Spinner />
                ) : (
                    <>
                        <div className="flex justify-between items-end">
                            <div className="flex-0 flex items-end space-x-2">
                                <TextInput
                                    label="Date Range"
                                    name="start_date"
                                    type="date"
                                    value={dates.startDate}
                                    onChange={dateUpdate}
                                />
                                <TextInput
                                    name="end_date"
                                    type="date"
                                    value={dates.endDate}
                                    onChange={dateUpdate}
                                />
                                <Button
                                    onClick={() =>
                                        setNeedsUpdated(needsUpdated + 1)
                                    }
                                    appearance="light"
                                    text="Submit"
                                />
                            </div>
                            <div className="flex-0 flex ml-auto mr-6">
                                <BasicExclusionTotals
                                    totalClientExclusionUsed={
                                        totalClientExclusionUsed
                                    }
                                    totalSpouseExclusionUsed={
                                        totalSpouseExclusionUsed
                                    }
                                />
                            </div>
                            <div className="flex-0 flex">
                                <Button
                                    text="Download"
                                    appearance="outline"
                                    width="w-auto"
                                    isLoading={isExporting}
                                    onClick={exportRecords}
                                />
                            </div>
                        </div>
                        <div className="space-y-6 mt-6">
                            {gifts.map((gift) => {
                                return (
                                    <Panel key={gift.title}>
                                        <PanelHeader title={gift.title} />
                                        <div className="space-y-6 divide-y divide-gray-100">
                                            <table className="w-full">
                                                <GiftsTableHeader />
                                                <tbody>
                                                    {gift.transfers.map(
                                                        (transfer, idxa) => {
                                                            return (
                                                                <IndividualGift
                                                                    key={`${gift.title}_${transfer.asset_name}_${idxa}`}
                                                                    asset={gift}
                                                                    transfer={
                                                                        transfer
                                                                    }
                                                                />
                                                            );
                                                        }
                                                    )}
                                                    <tr>
                                                        <td
                                                            colSpan="7"
                                                            className="pt-4 pb-0 px-3 text-right font-bold space-x-3 text-black"
                                                        >
                                                            <span className="font-normal">
                                                                Total Basic
                                                                Exclusion:
                                                            </span>
                                                            <span className="tabular-nums">
                                                                <Money
                                                                    value={
                                                                        gift.totalBasicExclusion
                                                                    }
                                                                />
                                                            </span>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </Panel>
                                );
                            })}
                        </div>
                    </>
                )}
                {gifts.length === 0 && (
                    <div>No gifts were found for the given date range.</div>
                )}
            </PageContainer>
        </>
    );
}

export function GiftsTableHeader() {
    return (
        <thead>
            <tr className="font-semibold text-gray-700 text-left">
                <th className="p-1">Recipients</th>
                <th className="py-1 px-4">&nbsp;</th>
                <th className="py-1 px-4">Value</th>
                <th className="py-1 px-4">Verified</th>
                <th className="py-1 px-4">Tax Return</th>
                <th className="py-1 px-4">Donor</th>
                <th className="py-1 px-4 text-right">Basic Exclusion</th>
            </tr>
        </thead>
    );
}

export function IndividualGift({ transfer, asset }) {
    return (
        <>
            <tr className="text-gray-600">
                <td className="p-1 pt-3 font-bold text-gray-600" colSpan={6}>
                    <div className="w-50">
                        <a
                            href={transfer.asset_resource_path}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <span>
                                <span className="block text-xs text-gray-400">
                                    {asset.asset_type_formatted}
                                </span>
                                <span>
                                    {asset.asset_name}
                                    {asset.asset_account_number && (
                                        <span className="text-xs mx-1 text-gray-400">
                                            ({asset.asset_account_number})
                                        </span>
                                    )}
                                    &nbsp;
                                    <FontAwesomeIcon
                                        icon={faExternalLinkAlt}
                                        className="text-gray-500 hover:text-brand text-xs"
                                    />
                                </span>
                            </span>
                        </a>
                    </div>
                </td>
            </tr>
            <tr className="border-b border-gray-100">
                <td className="text-gray-600 py-2 pt-0 px-1 pl-2 w-56 align-top">
                    {transfer.transferee}
                    {transfer.description && (
                        <div className="text-xs text-gray-500 mt-1 italic">
                            {transfer.description}
                        </div>
                    )}
                </td>
                <td className="py-2 pt-0 px-4 tabular-nums align-top">
                    {transfer.transfer_type === "value" ? (
                        <span>Value Transfer</span>
                    ) : (
                        <Percentage value={transfer.percentage_transferred} />
                    )}
                </td>
                <td className="py-2 pt-0 px-4 tabular-nums align-top">
                    {transfer.transfer_value}
                </td>
                <td className="py-2 pt-0 px-4 align-top whitespace-no-wrap">
                    {formatDate(transfer.verified_at, "")}
                </td>
                <td className="py-2 pt-0 px-4 align-top">
                    {transfer.gift_tax_return === 0 ? "No" : "Yes"}
                </td>
                <td className="py-2 pt-0 px-4 align-top">
                    {transfer.donor_name}
                </td>
                <td className="py-2 pt-0 px-4 tabular-nums text-right align-top">
                    {transfer.basic_exclusion}
                </td>
            </tr>
        </>
    );
}
