import React, { useState, useEffect, useReducer } from "react";
import TextInput from "../../components/TextInput";
import Api from "../../services/Api";
import { useToasts } from "react-toast-notifications";
import ClientNav from "./_partials/ClientNav";
import ClientPageHeader, { Heading } from "../../components/ClientPageHeader";
import PageContainer from "../../components/PageContainer";
import useAuth from "../../hooks/useAuth";
import Button from "../../components/Button";
import BasicModal from "components/ModalBasic";
import Panel, { PanelHeader } from "components/Panel";

export default function ClientPassword({ client, ...props }) {
    const { user } = useAuth();
    const { addToast } = useToasts();
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState([]);
    const [needsApprovalPassword, setNeedsApprovalPassword] = useState(false);
    const [approvalPassword, setApprovalPassword] = useState("");

    const [clientInput, setClientInput] = useReducer(
        (state, newState) => ({ ...state, ...newState }),
        {}
    );

    useEffect(() => {
        setClientInput(client);
        document.title = "Change Password: " + client.full_name;
    }, [client]);

    function updateValue(event) {
        setClientInput({ [event.target.name]: event.target.value });
    }

    async function updatePassword(e) {
        e.preventDefault();
        try {
            setLoading(true);
            let response = await Api.put(
                "clients/" + client.id + "/passwords",
                { ...clientInput, approval_password: approvalPassword }
            );
            addToast(response.data.message);
            setErrors([]);
            setLoading(false);
            setNeedsApprovalPassword(false);
            setApprovalPassword("");
            setClientInput({ new_password: "" });
            setClientInput({ new_password_confirmation: "" });
        } catch (e) {
            setLoading(false);
            setErrors([]);
            if (e.response.data.errors) {
                setApprovalPassword("");
                if (e.response.data.errors.approval_password) {
                    setNeedsApprovalPassword(true);
                } else {
                    setNeedsApprovalPassword(false);
                }

                setErrors(e.response.data.errors);
            } else {
                addToast(e.response.data.message, { type: "error" });
            }
        }
    }

    const sendPasswordResetEmail = async () => {
        try {
            setLoading(true);
            let response = await Api.put(
                "clients/" + client.id + "/reset-password"
            );
            addToast(response.data.message);
            setErrors([]);
            setNeedsApprovalPassword(false);
            setLoading(false);
        } catch (e) {
            setLoading(false);
            setErrors([]);
            setNeedsApprovalPassword(false);
            if (e.response.data.errors) {
                setErrors(e.response.data.errors);
            } else {
                addToast(e.response.data.message, { type: "error" });
            }
        }
    };

    const openApprovalModal = (event) => {
        event.preventDefault();
        setApprovalPassword("");
        setNeedsApprovalPassword(true);
    };

    return (
        <>
            <ClientPageHeader text="Change Password" client={clientInput}>
                <Heading>Change Password</Heading>
            </ClientPageHeader>

            <PageContainer>
                <div>
                    <div className="flex">
                        <div className="mr-10 flex-initial">
                            <ClientNav
                                active={props.history.location.pathname}
                                client={clientInput}
                            />
                        </div>

                        <div className="flex-1 space-y-12">
                            <Panel appearance="wrap">
                                <PanelHeader title="Change Password" />
                                <form onSubmit={openApprovalModal}>
                                    <div className="mb-6">
                                        <TextInput
                                            type="password"
                                            name="new_password"
                                            label="New Password"
                                            id="new_password"
                                            value={clientInput.new_password}
                                            onChange={updateValue}
                                            error={errors.new_password}
                                            autoComplete="new-password"
                                        />
                                    </div>

                                    <div className="mb-6">
                                        <TextInput
                                            type="password"
                                            name="new_password_confirmation"
                                            label="Confirm New Password"
                                            id="new_password_confirmation"
                                            value={
                                                clientInput.new_password_confirmation
                                            }
                                            onChange={updateValue}
                                            error={
                                                errors.new_password_confirmation
                                            }
                                            autoComplete="new-password"
                                        />
                                    </div>

                                    <input type="submit" hidden />
                                    <div className="w-full flex justify-end space-x-3">
                                        <Button
                                            text="Change Password"
                                            type="submit"
                                            width="auto"
                                        />
                                    </div>
                                </form>
                            </Panel>

                            {user.type === "member" && (
                                <Panel appearance="wrap">
                                    <PanelHeader title="Send Password Reset Email" />
                                    <p className="mb-6">
                                        This will send an email with password
                                        reset instructions to {client.full_name}{" "}
                                        at&nbsp;
                                        <span className="font-semibold">
                                            {client.email}
                                        </span>
                                    </p>
                                    <Button
                                        text="Send password reset email"
                                        onClick={sendPasswordResetEmail}
                                        width="auto"
                                        appearance="light"
                                        isLoading={loading}
                                    />
                                </Panel>
                            )}
                        </div>
                    </div>
                </div>
                <BasicModal
                    isOpen={needsApprovalPassword}
                    title="Confirm Password Change"
                    onClose={() => setNeedsApprovalPassword(false)}
                    size="lg"
                >
                    <form
                        onSubmit={updatePassword}
                        className="space-y-6 -mt-10"
                    >
                        <div>
                            Please enter{" "}
                            <span className="font-semibold underline">
                                your
                            </span>{" "}
                            current password to proceed.
                        </div>
                        <TextInput
                            label={"Your Password"}
                            type="password"
                            name="approval_password"
                            id="approval_password"
                            value={approvalPassword}
                            onChange={(e) =>
                                setApprovalPassword(e.target.value)
                            }
                            error={errors.approval_password}
                            required
                        />
                        <div className="text-right">
                            <Button
                                isLoading={loading}
                                text="Confirm Password Change"
                                type="submit"
                                width="auto"
                            />
                        </div>
                    </form>
                </BasicModal>
            </PageContainer>
        </>
    );
}
