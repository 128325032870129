import React, { useState, useReducer } from "react";
import { useToasts } from "react-toast-notifications";
import ClientPageHeader, { Heading } from "../../components/ClientPageHeader";
import PageContainer from "../../components/PageContainer";
import Api from "../../services/Api";
import { Redirect } from "react-router-dom";
import PersonalPropertyMemoForm from "./_partials/PersonalPropertyMemoForm";
import SaveButton from "../../components/SaveButton";

export default function AddPersonalPropertyMemo({
    client,
    clientPath,
    ...props
}) {
    const spouseSelected = props.match.params.spouse === "spouse";
    const { addToast } = useToasts();
    const [errors, setErrors] = useState([]);
    const [redirectToIndex, setRedirectToIndex] = useState(false);

    const defaultInput = {
        transfer_on: "first_death",
        is_spouse: spouseSelected ? 1 : 0,
    };

    const [input, setInput] = useReducer(
        (state, newState) => ({ ...state, ...newState }),
        defaultInput
    );

    const indexLink = spouseSelected
        ? `${clientPath}/personal-property-memos/spouse`
        : `${clientPath}/personal-property-memos`;

    document.title = "Add Memorandum of Personal Property";

    function updateValue(event) {
        setInput({ [event.target.name]: event.target.value });
    }

    async function store(e) {
        e.preventDefault();
        try {
            const body = { ...input };
            let response = await Api.post(
                "clients/" + client.id + "/memorandum-of-personal-property",
                body
            );
            console.log(body);
            console.log(response);
            addToast(response.data.message);
            setErrors([]);
            setRedirectToIndex(true);
        } catch (error) {
            setErrors([]);
            if (error.response.data && error.response.data.errors) {
                setErrors(error.response.data.errors);
            } else {
                addToast(error.response.data.message, { type: "error" });
            }
        }
    }

    if (redirectToIndex) {
        return <Redirect to={indexLink} />;
    }

    return (
        <>
            <ClientPageHeader client={client}>
                <Heading
                    backTo={indexLink}
                    backText="Memorandum of Personal Property"
                >
                    Memorandum of Personal Property
                </Heading>
                <div className="flex-0">
                    <SaveButton onClick={store} />
                </div>
            </ClientPageHeader>
            <PageContainer>
                <div className="max-w-3xl mx-auto">
                    <PersonalPropertyMemoForm
                        client={client}
                        input={input}
                        errors={errors}
                        updateValue={updateValue}
                        onSubmit={store}
                    />
                </div>
            </PageContainer>
        </>
    );
}
