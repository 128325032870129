import React, { useEffect } from "react";
import ClientPageHeader, {
    Heading,
} from "../../../components/ClientPageHeader";
import PageContainer from "../../../components/PageContainer";
import ClientNotes from "./ClientNotes";
import { useHasUserType } from "../../../hooks/usePermissions";
import ResourceNotFound from "../../../components/ResourceNotFound";

export default function ClientNotesPage({ client, ...props }) {
    const canViewNotes = useHasUserType(["member", "client", "advisor"]);

    useEffect(() => {
        document.title = "Notes for " + client.full_name;
    }, [client]);

    if (!canViewNotes) {
        return <ResourceNotFound />;
    }

    return (
        <>
            <ClientPageHeader text="Notes" client={client}>
                <Heading>Notes</Heading>
            </ClientPageHeader>
            <PageContainer>
                <ClientNotes client={client} {...props} />
            </PageContainer>
        </>
    );
}
