import React from "react";
import InsurancePoliciesLayout from "./InsurancePoliciesLayout";
import { AllOwnershipsForAsset } from "components/Ownership";

export default function EditInsurancePolicyOwners({
    client,
    input,
    currentBasePath,
    title,
    refreshData,
}) {
    return (
        <InsurancePoliciesLayout
            currentBasePath={currentBasePath}
            title={title}
            // buttons={
            //     <QuickAddProvider client={client} onSave={() => refreshData()}>
            //         {(props) => <QuickAddButton {...props} />}
            //     </QuickAddProvider>
            // }
        >
            <AllOwnershipsForAsset
                asset={input}
                client={client}
                refreshData={refreshData}
                hideQuickAdd={false}
            />
        </InsurancePoliciesLayout>
    );
}
