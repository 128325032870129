import React, { useState } from "react";
import SelectInput from "../../../components/SelectInput";
import TextInput from "../../../components/TextInput";
import FrequencySelect from "../../../components/FrequencySelect";
import ManagedByAdvisor from "../../../components/ManagedByAdvisor";
import useFormatCurrency from "../../../hooks/useFormatCurrency";
import ResourceSelect from "components/ResourceSelect";

const policyTypes = [
    { value: "Auto", label: "Auto" },
    { value: "Home", label: "Home" },
    { value: "Medical", label: "Medical" },
    { value: "Umbrella", label: "Umbrella" },
    { value: "Other", label: "Other" },
];

const getPolicyTypeLabel = (type) => {
    const policyType = policyTypes.filter((t) => type === t.value);
    if (policyType.length === 0) return "Unknown";
    return policyType[0].label;
};

export { getPolicyTypeLabel };

export default function OtherInsurancePolicyForm({
    client,
    input = {},
    errors = {},
    readOnly = false,
    onSubmit,
    updateValue,
}) {
    const formatCurrency = useFormatCurrency();
    const [insuredOptions] = useState(["Joint", "Relationship"]);
    const [resourceTypes] = useState([
        "Joint",
        "Relationship",
        "Trust",
        "BusinessInterest",
    ]);
    const [paymentFrequencyOnly] = useState([1, 2, 3, 4]);

    const updateInsured = (event) => {
        let value = event.target.value;
        const parts = value.split(":");

        if (parts[0] !== undefined && parts[1] !== undefined) {
            updateValue({
                target: { name: "insured_type", value: parts[0] },
            });
            updateValue({
                target: { name: "insured_id", value: parts[1] },
            });
        }
        updateValue(event);
    };

    const updateAdditionalInsuredOne = (event) => {
        let value = event.target.value;
        const parts =
            value === "None" || value === "other"
                ? [null, null]
                : value.split(":");

        if (parts[0] !== undefined && parts[1] !== undefined) {
            updateValue({
                target: { name: "additional_insured_1_type", value: parts[0] },
            });
            updateValue({
                target: { name: "additional_insured_1_id", value: parts[1] },
            });
        }
        updateValue({
            target: {
                name: "additional_insured_1_is_other",
                value: value === "other" ? true : false,
            },
        });
        updateValue(event);
    };

    const updateAdditionalInsuredTwo = (event) => {
        let value = event.target.value;
        const parts =
            value === "None" || value === "other"
                ? [null, null]
                : value.split(":");
        if (parts[0] !== undefined && parts[1] !== undefined) {
            updateValue({
                target: { name: "additional_insured_2_type", value: parts[0] },
            });
            updateValue({
                target: { name: "additional_insured_2_id", value: parts[1] },
            });
        }
        updateValue({
            target: {
                name: "additional_insured_2_is_other",
                value: value === "other" ? true : false,
            },
        });
        updateValue(event);
    };

    return (
        <>
            <form onSubmit={onSubmit}>
                <div>
                    <div className="mb-6">
                        <SelectInput
                            type="type"
                            name="type"
                            disabled={readOnly}
                            label="Policy Type"
                            id="type"
                            value={input.type}
                            error={errors.type}
                            onChange={updateValue}
                            autoFocus={true}
                            required={true}
                            options={policyTypes}
                        />
                    </div>

                    <div className="mb-6">
                        <TextInput
                            name="number"
                            label="Policy Number"
                            id="number"
                            readOnly={readOnly}
                            disabled={readOnly}
                            value={input.number}
                            error={errors.number}
                            onChange={updateValue}
                        />
                    </div>

                    <div className="mb-6">
                        <TextInput
                            name="provider_name"
                            label="Company Name"
                            id="provider_name"
                            readOnly={readOnly}
                            disabled={readOnly}
                            value={input.provider_name}
                            error={errors.provider_name}
                            onChange={updateValue}
                            required={true}
                        />
                    </div>

                    <div className="mb-6">
                        <ResourceSelect
                            client={client}
                            name="insured"
                            resourceType={insuredOptions}
                            disabled={readOnly}
                            label="Insured"
                            id="insured"
                            value={input.insured}
                            error={errors.insured}
                            onChange={updateInsured}
                            emptyState={true}
                            emptyStateLabel={"Choose an option"}
                            emptyStateValue={"Choose an option"}
                        />
                    </div>

                    <fieldset className="mt-12">
                        <legend className="block items-center justify-between font-bold txt-md text-gray-500 my-6 pb-1 border-b w-full">
                            <span>Additional Insured</span>
                        </legend>
                        <div className="mb-6">
                            <ResourceSelect
                                client={client}
                                name="additional_insured_1"
                                resourceType={resourceTypes}
                                disabled={readOnly}
                                label="Additional Insured 1"
                                id="additional_insured_1"
                                value={input.additional_insured_1}
                                error={errors.additional_insured_1}
                                onChange={updateAdditionalInsuredOne}
                                emptyState={true}
                                emptyStateLabel={"None"}
                                emptyStateValue={"None"}
                                appendOptions={[
                                    { value: "other", label: "Other" },
                                ]}
                            />
                        </div>

                        {input.additional_insured_1 === "other" && (
                            <div className="mb-6">
                                <TextInput
                                    name="additional_insured_1_name"
                                    label="Other Name"
                                    id="additional_insured_1_name"
                                    readOnly={readOnly}
                                    disabled={readOnly}
                                    value={input.additional_insured_1_name}
                                    error={errors.additional_insured_1_name}
                                    onChange={updateValue}
                                />
                            </div>
                        )}

                        <hr className="my-6 border-gray-200" />

                        <div className="mb-6">
                            <ResourceSelect
                                client={client}
                                name="additional_insured_2"
                                resourceType={resourceTypes}
                                disabled={readOnly}
                                label="Additional Insured 2"
                                id="additional_insured_2"
                                value={input.additional_insured_2}
                                error={errors.additional_insured_2}
                                onChange={updateAdditionalInsuredTwo}
                                emptyState={true}
                                emptyStateLabel={"None"}
                                emptyStateValue={"None"}
                                appendOptions={[
                                    { value: "other", label: "Other" },
                                ]}
                            />
                        </div>

                        {input.additional_insured_2 === "other" && (
                            <div className="mb-6">
                                <TextInput
                                    name="additional_insured_2_name"
                                    label="Other Name"
                                    id="additional_insured_2_name"
                                    readOnly={readOnly}
                                    disabled={readOnly}
                                    value={input.additional_insured_2_name}
                                    error={errors.additional_insured_2_name}
                                    onChange={updateValue}
                                />
                            </div>
                        )}
                    </fieldset>

                    <fieldset className="mt-12">
                        <legend className="font-bold txt-md text-gray-500 my-6 pb-1 border-b block w-full">
                            Policy Amounts
                        </legend>

                        <div className="flex space-x-4 mb-6">
                            <div className="flex-1">
                                <TextInput
                                    name="premium_amount"
                                    label="Premium Amount"
                                    id="premium_amount"
                                    readOnly={readOnly}
                                    disabled={readOnly}
                                    value={input.premium_amount}
                                    error={errors.premium_amount}
                                    onChange={updateValue}
                                    onBlur={(e) => {
                                        e.target.value = formatCurrency(
                                            e.target.value
                                        );
                                        updateValue(e);
                                    }}
                                    placeholder="0.00"
                                />
                            </div>
                            <div className="flex-1">
                                <FrequencySelect
                                    only={paymentFrequencyOnly}
                                    name="frequency_id"
                                    label="Payment Frequency"
                                    disabled={readOnly}
                                    id="frequency_id"
                                    value={input.frequency_id}
                                    error={errors.frequency_id}
                                    onChange={updateValue}
                                />
                            </div>
                        </div>
                    </fieldset>

                    <fieldset className="mt-12">
                        <legend className="font-bold txt-md text-gray-500 my-6 pb-1 border-b block w-full">
                            Managed By
                        </legend>
                        <ManagedByAdvisor
                            client={client}
                            input={input}
                            errors={errors}
                            disabled={readOnly}
                            updateValue={updateValue}
                        />
                    </fieldset>
                    <input type="submit" hidden />
                </div>
            </form>
        </>
    );
}
