import React, { useState, useContext } from "react";
import Api from "../../../services/Api";
import Dropdown, { DropdownItem } from "../../../components/Dropdown";
import { Link } from "react-router-dom";
import Spinner from "../../../components/Spinner";
import TableSortHeader from "../../../components/TableSortHeader";
import SearchInput from "../../../components/SearchInput";
import { AuthContext } from "../../../contexts/AuthContext";
import useDebounce from "../../../hooks/useDebounce";
import { formatDate } from "../../../util/TextUtil";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faTrash,
    faEdit,
    faEllipsisH,
} from "@fortawesome/free-solid-svg-icons";
import { useToasts } from "react-toast-notifications";
import Forbidden from "../../../components/Forbidden";
import { titleCase } from "../../../util/TextUtil";
import useHasPermission from "hooks/usePermissions";
import Badge from "components/Badge";
import useQuestionnaires from "hooks/useQuestionnaires";

function QuestionnaireTable({
    member,
    needsUpdated = null,
    setNeedsUpdated = null,
    setShowAddQuestionnaireModal,
}) {
    const auth = useContext(AuthContext);
    const userType = auth.user.type;
    const { addToast } = useToasts();
    const [orderBy, setOrderBy] = useState("created_at");
    const [sortDir, setSortDir] = useState("desc");
    const [search, setSearch] = useState("");
    const debouncedSearch = useDebounce(search, 250);
    const canCustomizeQuestions = useHasPermission("question_customization");
    const {
        data: questionnaires,
        isLoading,
        error,
    } = useQuestionnaires({
        memberId: member.id,
        sort: sortDir === "desc" ? "-" + orderBy : orderBy,
        "filter[search]": debouncedSearch,
    });

    document.title = "Questionnaires";

    async function deleteQuestionnaire(questionnaire) {
        try {
            const response = await Api.delete(
                "members/" + member.id + "/questionnaires/" + questionnaire.id
            );
            addToast(response.data.message);
            setNeedsUpdated(needsUpdated + 1);
        } catch (e) {
            addToast(e.errorMessage, { type: "error" });
        }
    }

    if (error?.status === 403) {
        return <Forbidden />;
    }

    if (isLoading) {
        return <Spinner />;
    }

    return (
        <>
            <div className="mb-4">
                <SearchInput
                    label={false}
                    searchValue={search}
                    onSearchChange={(e) => setSearch(e.target.value)}
                />
            </div>
            {questionnaires && questionnaires.data.length ? (
                <>
                    <table className="w-full text-left">
                        <thead>
                            <tr>
                                <TableSortHeader
                                    field="title"
                                    sortField={orderBy}
                                    sortDir={sortDir}
                                    changeSort={(column) => {
                                        setSortDir(
                                            sortDir === "asc" ? "desc" : "asc"
                                        );
                                        setOrderBy(column);
                                    }}
                                >
                                    Name
                                </TableSortHeader>
                                <TableSortHeader disabled={true}>
                                    Type
                                </TableSortHeader>
                                <TableSortHeader
                                    field="updated_at"
                                    sortField={orderBy}
                                    sortDir={sortDir}
                                    changeSort={(column) => {
                                        setSortDir(
                                            sortDir === "asc" ? "desc" : "asc"
                                        );
                                        setOrderBy(column);
                                    }}
                                >
                                    Last Updated
                                </TableSortHeader>
                                <TableSortHeader
                                    field="status"
                                    sortField={orderBy}
                                    sortDir={sortDir}
                                    changeSort={(column) => {
                                        setSortDir(
                                            sortDir === "asc" ? "desc" : "asc"
                                        );
                                        setOrderBy(column);
                                    }}
                                >
                                    Status
                                </TableSortHeader>
                                <th className="text-right">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {questionnaires.data.map((questionnaire, idx) => (
                                <tr
                                    key={idx}
                                    className={
                                        questionnaire.deleted_at ? "" : ""
                                    }
                                >
                                    <td>
                                        <Link
                                            to={`/members/${member.id}/questionnaires/${questionnaire.id}/view`}
                                        >
                                            <span>{questionnaire.title}</span>
                                        </Link>
                                    </td>
                                    <td>
                                        {questionnaire.is_default ? (
                                            <Badge color="gray">
                                                Organization Default
                                            </Badge>
                                        ) : (
                                            <Badge color="gray">
                                                Custom For Member
                                            </Badge>
                                        )}
                                    </td>
                                    <td>
                                        {formatDate(questionnaire.updated_at)}
                                    </td>
                                    <td>{titleCase(questionnaire.status)}</td>
                                    <td className="text-right">
                                        <span>
                                            <Dropdown
                                                position="right"
                                                toggleClass="px-3 py-1"
                                                toggleContent={
                                                    <FontAwesomeIcon
                                                        icon={faEllipsisH}
                                                    />
                                                }
                                            >
                                                <DropdownItem
                                                    label="Edit"
                                                    icon={faEdit}
                                                    link={`/members/${member.id}/questionnaires/${questionnaire.id}/edit`}
                                                />
                                                {(userType === "member" ||
                                                    userType === "member") &&
                                                    !questionnaire.is_default && (
                                                        <DropdownItem
                                                            label="Delete"
                                                            icon={faTrash}
                                                            handleClick={() =>
                                                                deleteQuestionnaire(
                                                                    questionnaire
                                                                )
                                                            }
                                                        />
                                                    )}
                                            </Dropdown>
                                        </span>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </>
            ) : (
                <div className="text-gray-400">
                    The are no questionnaires for this member.{" "}
                    {canCustomizeQuestions && (
                        <Link
                            to={"#"}
                            onClick={() => setShowAddQuestionnaireModal(true)}
                            className="underline text-gray-600"
                        >
                            Add Now.
                        </Link>
                    )}
                </div>
            )}
        </>
    );
}

export default QuestionnaireTable;
