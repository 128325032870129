import React, { useState, useContext, useEffect } from "react";
import { Route, Switch } from "react-router-dom";
import EditClient from "./EditClient";
import ClientVault from "./Vault/Vault";
import Relationships from "../relationships/Relationships";
import Api from "../../services/Api";
import Spinner from "../../components/Spinner";
import Advisors from "../advisors/Advisors";
import { formatDate } from "../../util/TextUtil";
import ViewClientProfile from "./ViewClientProfile";
import IncomeSources from "../income_sources/IncomeSources";
import InsurancePolicies from "../insurance_policies/InsurancePolicies";
import DisabilityPolicies from "../disability_policies/DisabilityPolicies";
import OtherInsurancePolices from "../other_insurance_policies/OtherInsurancePolicies";
import Annuities from "../annuities/Annuities";
import PersonalProperties from "../personal_property/PersonalProperties";
import ClientForms from "../client_forms/ClientForms";
import PersonalPropertyMemorandums from "../personal_property_memo/PersonalPropertyMemorandums";
import Leases from "../leases/Leases";
import RealEstate from "../real_estate/RealEstate";
import NotesReceivables from "../notes_receivables/NotesReceivables";
import Trusts from "../trusts/Trusts";
import NonMortgageLiabilities from "../non_mortgage_liabilities/NonMortgageLiabilities";
import ClientQuestionnaires from "../client_questionnaires/ClientQuestionnaires";
import BudgetEstimates from "../budget-estimates/BudgetEstimates";
import Trustees from "../trustees/Trustees";
import FinancialAccounts from "../financial_accounts/FinancialAccounts";
import BusinessInterest from "../business_interests/BusinessInterest";
import EstatePlanningRoles from "../estate_planning_roles/EstatePlanningRoles";
import BeneficiariesIndex from "../Beneficiaries/BeneficiariesIndex";
import FundingTable from "../funding_table/FundingTable";
import PostMortem from "../post_mortem/PostMortem";
import UpdateValues from "../update_values/UpdateValues";
import ClientNotesPage from "./Notes/ClientNotesPage";
import Messages from "../messages/Messages";
import ChangeLog from "../changelog/ChangeLog";
import LiveWealth from "../reports/LiveWealth";
import ReportsIndex from "../reports/ReportsIndex";
import ListOfChanges from "../reports/ListOfChanges";
import { ClientContext } from "../../contexts/ClientContext";
import ListOfGifts from "../reports/ListOfGifts";
import LifetimeTransferees from "../reports/LifetimeTransferees";
import ICE from "../ice/ICE";
import useAuth from "../../hooks/useAuth";
import Appointments from "../appointments/Appointments";
import { ClientPageNavigation } from "components/ClientPageHeader";
import ErrorBoundary from "util/ErrorBoundary";
import PersonalInfoReport from "views/reports/PersonalInfoReport";
import RetirementAccounts from "../retirement_accounts/RetirementAccounts";
import BeneficiaryClaimsReport from "views/reports/BeneficiaryClaimsReport";
import EstateInventoryReportPage from "views/reports/EstateInventoryReportPage";
import EstateTransfersReportPage from "views/reports/EstateTransfersReportPage";

export default function ManageClient(props) {
    const clientContext = useContext(ClientContext);
    const [client] = useState(clientContext.client);
    const [clientPath, setClientPath] = useState("");

    useEffect(() => {
        setClientPath(`/clients/${client ? client.id : 0}`);
    }, [client]);

    const context = useAuth();
    const isClient = context.isClient;

    async function restoreClient(e) {
        e.preventDefault();
        try {
            await Api.put("clients/" + client.id + "/trashed", {
                is_trashed: false,
            });
            clientContext.refreshClient();
        } catch (error) {
            console.log(error);
        }
    }

    const reloadClient = () => {
        clientContext.refreshClient();
    };

    if (!client) {
        return (
            <div className="absolute top-0 left-0 w-full h-screen flex items-center justify-center">
                <Spinner message="Client not found..." />
            </div>
        );
    }

    return (
        <>
            <ClientPageNavigation />
            {client.disabled_at && (
                <div className="flex items-center bg-red-500 text-white p-6 py-3">
                    This client was disabled on {formatDate(client.disabled_at)}
                    <a
                        href="#restore"
                        className="ml-auto px-3 py-1 underline bg-red-400 rounded hover:bg-red-600"
                        onClick={(e) => restoreClient(e)}
                    >
                        Restore
                    </a>
                </div>
            )}
            <Switch>
                <ClientRoute
                    path="/clients/:clientId/advisors"
                    render={() => (
                        <Advisors
                            user={context.user}
                            clientPath={clientPath}
                            isClient={isClient}
                            client={client}
                            reloadClient={reloadClient}
                            {...props}
                        />
                    )}
                />
                <ClientRoute
                    path="/clients/:clientId/annuities"
                    render={() => (
                        <Annuities
                            user={context.user}
                            clientPath={clientPath}
                            isClient={isClient}
                            client={client}
                            reloadClient={reloadClient}
                            {...props}
                        />
                    )}
                />
                <ClientRoute
                    path="/clients/:clientId/appointments"
                    render={() => (
                        <Appointments
                            user={context.user}
                            clientPath={clientPath}
                            isClient={isClient}
                            client={client}
                            reloadClient={reloadClient}
                            {...props}
                        />
                    )}
                />
                <ClientRoute
                    path="/clients/:clientId/beneficiaries"
                    exact
                    render={() => (
                        <BeneficiariesIndex
                            user={context.user}
                            clientPath={clientPath}
                            isClient={isClient}
                            client={client}
                            reloadClient={reloadClient}
                            {...props}
                        />
                    )}
                />
                <ClientRoute
                    exact
                    path="/clients/:clientId/budget-estimates"
                    render={() => (
                        <BudgetEstimates
                            user={context.user}
                            clientPath={clientPath}
                            isClient={isClient}
                            client={client}
                            reloadClient={reloadClient}
                            {...props}
                        />
                    )}
                />
                <ClientRoute
                    path="/clients/:clientId/business-interests"
                    render={() => (
                        <BusinessInterest
                            clientPath={clientPath}
                            isClient={isClient}
                            user={context.user}
                            client={client}
                            reloadClient={reloadClient}
                            {...props}
                        />
                    )}
                />
                <ClientRoute
                    path="/clients/:clientId/disability-policies"
                    render={() => (
                        <DisabilityPolicies
                            user={context.user}
                            clientPath={clientPath}
                            isClient={isClient}
                            client={client}
                            reloadClient={reloadClient}
                            {...props}
                        />
                    )}
                />
                <ClientRoute
                    path="/clients/:clientId/edit"
                    render={() => (
                        <EditClient
                            user={context.user}
                            clientPath={clientPath}
                            isClient={isClient}
                            client={client}
                            reloadClient={reloadClient}
                            {...props}
                        />
                    )}
                />
                <ClientRoute
                    path="/clients/:clientId/estate-planning-roles"
                    exact
                    render={() => (
                        <EstatePlanningRoles
                            user={context.user}
                            clientPath={clientPath}
                            isClient={isClient}
                            client={client}
                            reloadClient={reloadClient}
                            {...props}
                        />
                    )}
                />
                <ClientRoute
                    path="/clients/:clientId/ice"
                    render={() => (
                        <ICE
                            user={context.user}
                            clientPath={clientPath}
                            isClient={isClient}
                            client={client}
                            reloadClient={reloadClient}
                            {...props}
                        />
                    )}
                />
                <ClientRoute
                    path="/clients/:clientId/changes"
                    render={() => (
                        <ChangeLog
                            clientPath={clientPath}
                            client={client}
                            {...props}
                        />
                    )}
                />
                <ClientRoute
                    path="/clients/:clientId/financial-accounts"
                    render={() => (
                        <FinancialAccounts
                            user={context.user}
                            clientPath={clientPath}
                            isClient={isClient}
                            client={client}
                            reloadClient={reloadClient}
                            {...props}
                        />
                    )}
                />
                <ClientRoute
                    path="/clients/:clientId/retirement-accounts"
                    render={() => (
                        <RetirementAccounts
                            user={context.user}
                            clientPath={clientPath}
                            isClient={isClient}
                            client={client}
                            reloadClient={reloadClient}
                            {...props}
                        />
                    )}
                />
                <ClientRoute
                    path="/clients/:clientId/forms"
                    render={() => (
                        <ClientForms
                            user={context.user}
                            clientPath={clientPath}
                            isClient={isClient}
                            client={client}
                            reloadClient={reloadClient}
                            {...props}
                        />
                    )}
                />
                <ClientRoute
                    path="/clients/:clientId/funding-table"
                    render={() => (
                        <FundingTable
                            user={context.user}
                            clientPath={clientPath}
                            isClient={isClient}
                            client={client}
                            reloadClient={reloadClient}
                            {...props}
                        />
                    )}
                />
                <ClientRoute
                    path="/clients/:clientId/income-sources"
                    render={() => (
                        <IncomeSources
                            user={context.user}
                            clientPath={clientPath}
                            isClient={isClient}
                            client={client}
                            reloadClient={reloadClient}
                            {...props}
                        />
                    )}
                />
                <ClientRoute
                    path="/clients/:clientId/insurance-policies"
                    render={() => (
                        <InsurancePolicies
                            user={context.user}
                            clientPath={clientPath}
                            isClient={isClient}
                            client={client}
                            reloadClient={reloadClient}
                            {...props}
                        />
                    )}
                />
                <ClientRoute
                    path="/clients/:clientId/leases"
                    render={() => (
                        <Leases
                            user={context.user}
                            clientPath={clientPath}
                            isClient={isClient}
                            client={client}
                            reloadClient={reloadClient}
                            {...props}
                        />
                    )}
                />
                <ClientRoute
                    path="/clients/:clientId/messages"
                    render={() => (
                        <Messages
                            user={context.user}
                            clientPath={clientPath}
                            isClient={isClient}
                            client={client}
                            reloadClient={reloadClient}
                            {...props}
                        />
                    )}
                />
                <ClientRoute
                    path="/clients/:clientId/other-insurance-policies"
                    render={() => (
                        <OtherInsurancePolices
                            user={context.user}
                            clientPath={clientPath}
                            isClient={isClient}
                            client={client}
                            reloadClient={reloadClient}
                            {...props}
                        />
                    )}
                />
                <ClientRoute
                    path="/clients/:clientId/non-mortgage-liabilities"
                    render={() => (
                        <NonMortgageLiabilities
                            user={context.user}
                            clientPath={clientPath}
                            isClient={isClient}
                            client={client}
                            reloadClient={reloadClient}
                            {...props}
                        />
                    )}
                />
                <ClientRoute
                    path="/clients/:clientId/notes-receivables"
                    render={() => (
                        <NotesReceivables
                            user={context.user}
                            clientPath={clientPath}
                            isClient={isClient}
                            client={client}
                            reloadClient={reloadClient}
                            {...props}
                        />
                    )}
                />
                <ClientRoute
                    path="/clients/:clientId/personal-properties"
                    render={() => (
                        <PersonalProperties
                            user={context.user}
                            clientPath={clientPath}
                            isClient={isClient}
                            client={client}
                            reloadClient={reloadClient}
                            {...props}
                        />
                    )}
                />
                <ClientRoute
                    path={[
                        "/clients/:clientId/personal-property-memos",
                        "/clients/:clientId/forms/2/edit",
                    ]}
                    render={() => (
                        <PersonalPropertyMemorandums
                            user={context.user}
                            clientPath={clientPath}
                            isClient={isClient}
                            client={client}
                            reloadClient={reloadClient}
                            {...props}
                        />
                    )}
                />
                <ClientRoute
                    path={["/clients/:clientId/post-mortem"]}
                    render={() => (
                        <PostMortem
                            user={context.user}
                            clientPath={clientPath}
                            client={client}
                            {...props}
                        />
                    )}
                />
                <ClientRoute
                    path="/clients/:clientId/questionnaires"
                    render={() => (
                        <ClientQuestionnaires
                            user={context.user}
                            clientPath={clientPath}
                            isClient={isClient}
                            client={client}
                            reloadClient={reloadClient}
                            {...props}
                        />
                    )}
                />
                <ClientRoute
                    path="/clients/:clientId/real-estate"
                    render={() => (
                        <RealEstate
                            user={context.user}
                            clientPath={clientPath}
                            isClient={isClient}
                            client={client}
                            reloadClient={reloadClient}
                            {...props}
                        />
                    )}
                />
                <ClientRoute
                    path="/clients/:clientId/relationships"
                    render={() => (
                        <Relationships
                            user={context.user}
                            clientPath={clientPath}
                            isClient={isClient}
                            client={client}
                            reloadClient={reloadClient}
                            {...props}
                        />
                    )}
                />
                <ClientRoute
                    path="/clients/:clientId/trustees"
                    render={() => (
                        <Trustees
                            user={context.user}
                            clientPath={clientPath}
                            isClient={isClient}
                            client={client}
                            reloadClient={reloadClient}
                            {...props}
                        />
                    )}
                />
                <ClientRoute
                    path="/clients/:clientId/trusts"
                    render={() => (
                        <Trusts
                            user={context.user}
                            clientPath={clientPath}
                            isClient={isClient}
                            client={client}
                            reloadClient={reloadClient}
                            {...props}
                        />
                    )}
                />
                <ClientRoute
                    path="/clients/:clientId/reports"
                    render={() => {
                        return (
                            <Switch>
                                <ClientRoute
                                    path="/clients/:clientId/reports/livewealth"
                                    render={() => (
                                        <LiveWealth
                                            user={context.user}
                                            clientPath={clientPath}
                                            ownerDefault={`Client:${client.id}`}
                                            client={client}
                                            {...props}
                                        />
                                    )}
                                />
                                <ClientRoute
                                    path="/clients/:clientId/reports/estate-inventory"
                                    render={() => (
                                        <EstateInventoryReportPage
                                            client={client}
                                        />
                                    )}
                                />
                                <ClientRoute
                                    path="/clients/:clientId/reports/list-of-changes"
                                    render={() => (
                                        <ListOfChanges
                                            user={context.user}
                                            clientPath={clientPath}
                                            client={client}
                                            {...props}
                                        />
                                    )}
                                />
                                <ClientRoute
                                    path="/clients/:clientId/reports/list-of-gifts"
                                    render={() => (
                                        <ListOfGifts
                                            user={context.user}
                                            clientPath={clientPath}
                                            client={client}
                                            {...props}
                                        />
                                    )}
                                />
                                <ClientRoute
                                    path="/clients/:clientId/reports/lifetime-transferees"
                                    render={() => (
                                        <LifetimeTransferees
                                            user={context.user}
                                            clientPath={clientPath}
                                            client={client}
                                            {...props}
                                        />
                                    )}
                                />
                                <ClientRoute
                                    path="/clients/:clientId/reports/estate-transferees"
                                    render={() => (
                                        <EstateTransfersReportPage
                                            client={client}
                                        />
                                    )}
                                />
                                <ClientRoute
                                    path="/clients/:clientId/reports/personal-info-report"
                                    render={() => (
                                        <PersonalInfoReport
                                            user={context.user}
                                            clientPath={clientPath}
                                            client={client}
                                            {...props}
                                        />
                                    )}
                                />
                                <ClientRoute
                                    path="/clients/:clientId/reports/beneficiary-claims"
                                    render={() => (
                                        <BeneficiaryClaimsReport
                                            user={context.user}
                                            clientPath={clientPath}
                                            client={client}
                                            {...props}
                                        />
                                    )}
                                />
                                <ReportsIndex
                                    user={context.user}
                                    clientPath={clientPath}
                                    client={client}
                                    {...props}
                                />
                            </Switch>
                        );
                    }}
                />

                <ClientRoute
                    path={["/clients/:clientId/vault"]}
                    render={() => {
                        return (
                            <ClientVault
                                user={context.user}
                                clientPath={clientPath}
                                isClient={isClient}
                                client={client}
                                reloadClient={reloadClient}
                                {...props}
                            />
                        );
                    }}
                />
                <ClientRoute
                    path="/clients/:clientId/view"
                    exact
                    render={() => (
                        <ViewClientProfile
                            user={context.user}
                            clientPath={clientPath}
                            isClient={isClient}
                            client={client}
                            reloadClient={reloadClient}
                            {...props}
                        />
                    )}
                />
                <ClientRoute
                    path="/clients/:clientId/update-values"
                    exact
                    render={() => (
                        <UpdateValues
                            user={context.user}
                            clientPath={clientPath}
                            isClient={isClient}
                            client={client}
                            reloadClient={reloadClient}
                            {...props}
                        />
                    )}
                />
                <ClientRoute
                    path="/clients/:clientId/notes"
                    render={() => (
                        <ClientNotesPage
                            user={context.user}
                            clientPath={clientPath}
                            isClient={isClient}
                            client={client}
                            reloadClient={reloadClient}
                            {...props}
                        />
                    )}
                />
                <ClientRoute
                    path="/clients/:clientId/dashboard"
                    render={() => (
                        <LiveWealth
                            user={context.user}
                            clientPath={clientPath}
                            client={client}
                            ownerDefault={`Client:${client.id}`}
                            {...props}
                        />
                    )}
                />
            </Switch>
        </>
    );
}

export function ClientRoute({ ...props }) {
    return (
        <ErrorBoundary key={props.path}>
            <Route {...props} />
        </ErrorBoundary>
    );
}
