import React, { useState, useEffect, useContext } from "react";
import Api from "../../../services/Api";
import Dropdown, { DropdownItem } from "../../../components/Dropdown";
import { Link } from "react-router-dom";
import Spinner from "../../../components/Spinner";
import TableSortHeader from "../../../components/TableSortHeader";
import SearchInput from "../../../components/SearchInput";
import { AuthContext } from "../../../contexts/AuthContext";
import useDebounce from "../../../hooks/useDebounce";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faTrash,
    faEdit,
    faEllipsisH,
} from "@fortawesome/free-solid-svg-icons";
import { useToasts } from "react-toast-notifications";
import Forbidden from "../../../components/Forbidden";
import { useUserCan } from "hooks/useHasAnyPermissions";
import Money from "components/Money";
import { titleCase } from "util/TextUtil";

function IncomeSourcesTable({
    client,
    clientPath,
    showDisabled,
    updateRecordCount,
}) {
    const auth = useContext(AuthContext);
    const userType = auth.user.type;
    const { addToast } = useToasts();
    const [loading, setLoading] = useState(true);
    const [incomeSources, setIncomeSources] = useState([]);
    const [orderBy, setOrderBy] = useState("created_at");
    const [sortDir, setSortDir] = useState("desc");
    const [search, setSearch] = useState("");
    const debouncedSearch = useDebounce(search, 250);
    const [notAuthorized, setNotAuthorized] = useState(false);
    const [needsUpdated, setNeedsUpdated] = useState(0);
    const canCreateWealthRecords = useUserCan("createWealthRecord");

    useEffect(() => {
        document.title = "Income Sources";
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            try {
                let response = await Api.get(
                    "clients/" + client.id + "/income-sources",
                    {
                        limit: 20,
                        sort: sortDir === "desc" ? "-" + orderBy : orderBy,
                        "filter[search]": debouncedSearch,
                    }
                );
                setIncomeSources(response.data.data);
                updateRecordCount(response.data.data.length);
                setLoading(false);
            } catch (error) {
                if (error.response.status === 403) {
                    setNotAuthorized(true);
                }
            }
        };
        fetchData();
    }, [
        client,
        showDisabled,
        debouncedSearch,
        orderBy,
        sortDir,
        needsUpdated,
        updateRecordCount,
    ]);

    async function deleteIncomeSource(incomeSource) {
        try {
            const response = await Api.delete(
                "clients/" + client.id + "/income-sources/" + incomeSource.id
            );
            addToast(response.data.message);
            setNeedsUpdated(needsUpdated + 1);
        } catch (e) {
            addToast(e.errorMessage, { type: "error" });
        }
    }

    if (notAuthorized) {
        return <Forbidden />;
    }

    if (loading) {
        return <Spinner />;
    }

    return (
        <>
            <div className="mb-4">
                <SearchInput
                    label={false}
                    searchValue={search}
                    onSearchChange={(e) => setSearch(e.target.value)}
                />
            </div>
            {incomeSources.length ? (
                <>
                    <table className="w-full text-left">
                        <thead>
                            <tr>
                                <TableSortHeader
                                    field="type"
                                    sortField={orderBy}
                                    sortDir={sortDir}
                                    changeSort={(column) => {
                                        setSortDir(
                                            sortDir === "asc" ? "desc" : "asc"
                                        );
                                        setOrderBy(column);
                                    }}
                                >
                                    Source
                                </TableSortHeader>
                                <TableSortHeader
                                    field="recipient"
                                    sortField={orderBy}
                                    sortDir={sortDir}
                                    changeSort={(column) => {
                                        setSortDir(
                                            sortDir === "asc" ? "desc" : "asc"
                                        );
                                        setOrderBy(column);
                                    }}
                                >
                                    Recipient
                                </TableSortHeader>
                                <TableSortHeader
                                    field="amount"
                                    sortField={orderBy}
                                    sortDir={sortDir}
                                    changeSort={(column) => {
                                        setSortDir(
                                            sortDir === "asc" ? "desc" : "asc"
                                        );
                                        setOrderBy(column);
                                    }}
                                >
                                    Payment
                                </TableSortHeader>
                                <TableSortHeader
                                    field="frequency_id"
                                    sortField={orderBy}
                                    sortDir={sortDir}
                                    changeSort={(column) => {
                                        setSortDir(
                                            sortDir === "asc" ? "desc" : "asc"
                                        );
                                        setOrderBy(column);
                                    }}
                                >
                                    Frequency
                                </TableSortHeader>
                                <TableSortHeader
                                    field="lifetime"
                                    sortField={orderBy}
                                    sortDir={sortDir}
                                    changeSort={(column) => {
                                        setSortDir(
                                            sortDir === "asc" ? "desc" : "asc"
                                        );
                                        setOrderBy(column);
                                    }}
                                >
                                    Lifetime
                                </TableSortHeader>
                                <TableSortHeader
                                    field="taxable"
                                    sortField={orderBy}
                                    sortDir={sortDir}
                                    changeSort={(column) => {
                                        setSortDir(
                                            sortDir === "asc" ? "desc" : "asc"
                                        );
                                        setOrderBy(column);
                                    }}
                                >
                                    Taxable
                                </TableSortHeader>
                                <th className="text-right">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {incomeSources.map((incomeSource, idx) => (
                                <tr key={idx}>
                                    <td>
                                        <Link
                                            to={`${clientPath}/income-sources/${incomeSource.id}/view`}
                                        >{`${incomeSource.display_name}`}</Link>
                                    </td>
                                    <td>
                                        {incomeSource.recipient
                                            ? incomeSource.recipient.full_name
                                            : "N/A"}
                                    </td>
                                    <td>
                                        <Money value={incomeSource.amount} />
                                    </td>
                                    <td>{incomeSource.frequency}</td>
                                    <td>{titleCase(incomeSource.lifetime)}</td>
                                    <td>
                                        {incomeSource.taxable ? "Yes" : "No"}
                                    </td>
                                    <td className="text-right">
                                        <span>
                                            <Dropdown
                                                position="right"
                                                toggleClass="px-3 py-1"
                                                toggleContent={
                                                    <FontAwesomeIcon
                                                        icon={faEllipsisH}
                                                    />
                                                }
                                            >
                                                <DropdownItem
                                                    label="Edit"
                                                    icon={faEdit}
                                                    link={`${clientPath}/income-sources/${incomeSource.id}/edit`}
                                                />
                                                {(userType === "member" ||
                                                    userType === "client") && (
                                                    <DropdownItem
                                                        label="Delete"
                                                        icon={faTrash}
                                                        handleClick={() =>
                                                            deleteIncomeSource(
                                                                incomeSource
                                                            )
                                                        }
                                                    />
                                                )}
                                            </Dropdown>
                                        </span>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </>
            ) : (
                <div className="text-gray-400">
                    The are no income sources for this client.{" "}
                    {canCreateWealthRecords && (
                        <Link
                            to={`${clientPath}/income-sources/add`}
                            className="underline text-gray-600"
                        >
                            Add Now.
                        </Link>
                    )}
                </div>
            )}
        </>
    );
}

export default IncomeSourcesTable;
