import React from "react";
import Button from "../../components/Button";
import { downloadFileAsLink } from "../../util/Downloader";
import InsurancePoliciesLayout from "./InsurancePoliciesLayout";

export default function InsurancePoliciesForms({
    client,
    input,
    currentBasePath,
    title,
}) {
    document.title = "Letters of Instruction";

    return (
        <InsurancePoliciesLayout
            currentBasePath={currentBasePath}
            title={title}
        >
            <table className="w-full text-left">
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Description</th>
                        <th className="text-right">Actions</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Ownership Change Letter</td>
                        <td>
                            Merge proposed ownership information for the asset
                            to a document to provide to financial institutions.
                        </td>
                        <td className="text-right">
                            <Button
                                type="button"
                                text="Download Document"
                                height="h-10"
                                appearance="outline"
                                width="w-auto"
                                onClick={() =>
                                    downloadFileAsLink(
                                        "clients/" +
                                            client.id +
                                            "/insurance-policies/" +
                                            input.id +
                                            "/letters/ownership-change"
                                    )
                                }
                            />
                        </td>
                    </tr>
                    <tr>
                        <td>Beneficiary Change Letter</td>
                        <td>
                            Merge proposed beneficiary information for the asset
                            to a document to provide to financial institutions.
                        </td>
                        <td className="text-right">
                            <Button
                                type="button"
                                text="Download Document"
                                height="h-10"
                                width="w-auto"
                                appearance="outline"
                                onClick={() =>
                                    downloadFileAsLink(
                                        "clients/" +
                                            client.id +
                                            "/insurance-policies/" +
                                            input.id +
                                            "/letters/beneficiary-change"
                                    )
                                }
                            />
                        </td>
                    </tr>
                </tbody>
            </table>
        </InsurancePoliciesLayout>
    );
}
