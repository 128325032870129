import SelectInput from "components/SelectInput";
import TextInput from "components/TextInput";
import React from "react";

const PaymentDetails = ({ data, idx, onchange }) => {
    return (
        <>
            <div className="flex-1">
                <PaymentMethod data={data} idx={idx} onchange={onchange} />
            </div>
            <div className="flex-1">
                <PaymentFrequency data={data} idx={idx} onchange={onchange} />
            </div>
        </>
    );
};

export const PaymentMethod = ({ data, idx, onchange }) => {
    return (
        <div className="flex-1">
            <SelectInput
                options={[
                    "Auto Withdrawal",
                    "Credit Card",
                    "Check",
                    "On-line Banking",
                ]}
                label="Payment Method"
                name="payment_method"
                value={data[idx]["payment_method"]}
                onChange={(e) => onchange(e, idx)}
            />
        </div>
    );
};

export const PaymentFrequency = ({ data, idx, onchange }) => {
    return (
        <div className="flex-1">
            <SelectInput
                options={[
                    "None",
                    "Monthly",
                    "Quarterly",
                    "Semi-Annually",
                    "Annually",
                    "Other",
                ]}
                label="Payment Frequency"
                name="payment_frequency"
                value={data[idx]["payment_frequency"]}
                onChange={(e) => onchange(e, idx)}
            />
            {data[idx]["payment_frequency"] === "Other" && (
                <div className="mt-6">
                    <TextInput
                        label="Other Frequency"
                        name="payment_frequency_other"
                        value={data[idx]["payment_frequency_other"]}
                        onChange={(e) => onchange(e, idx)}
                    />
                </div>
            )}
        </div>
    );
};

export default PaymentDetails;
