import React, { useEffect } from "react";
import PageHeader from "../../components/PageHeader";
import PageContainer from "../../components/PageContainer";
import AdvisorProfileForm from "../advisors/_partials/AdvisorProfileForm";
import CollaboratingAdvisorNav from "./_partials/CollaboratingAdvisorNav";
import ProfilePhoto from "views/members/_partials/ProfilePhoto";

export default function CollaboratingAdvisorProfile({ advisor, ...props }) {
    useEffect(() => {
        document.title = "Collaborating Advisor";
    }, []);

    return (
        <>
            <PageHeader
                text={advisor.full_name}
                backTo="/collaborating-advisors"
                backText="Collaborating Advisors"
            />
            <PageContainer>
                <div className="flex space-x-10">
                    <div className="flex-initial">
                        <CollaboratingAdvisorNav
                            active={props.history.location.pathname}
                            advisor={advisor}
                        />
                    </div>
                    <div className="flex-1">
                        <form>
                            <AdvisorProfileForm
                                advisor={advisor}
                                updateValue={() => {}}
                                errors={[]}
                                readonly={true}
                            />
                        </form>
                    </div>
                    <div className="flex-initial">
                        <ProfilePhoto
                            memberId={advisor.id}
                            photo={advisor.profile_photo}
                            readOnly={true}
                            upload={() => {}}
                        />
                    </div>
                </div>
            </PageContainer>
        </>
    );
}
