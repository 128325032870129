import React, { useState, useEffect, useReducer } from "react";
import Api from "../../services/Api";
import { useToasts } from "react-toast-notifications";
import BasicModal, { BasicModalHeader } from "../../components/ModalBasic";
import LeasesForm from "./_partials/LeasesForm";
import { formatDateForInput } from "../../util/TextUtil";

export default function LeaseModal({
    isOpen,
    title,
    client,
    id,
    onClose,
    onSuccess,
    ...props
}) {
    const { addToast } = useToasts();
    const [errors, setErrors] = useState([]);
    const [notAuthorized, setNotAuthorized] = useState(false);
    const [loading, setLoading] = useState(id > 0);

    const [input, setInput] = useReducer(
        (state, newState) => ({ ...state, ...newState }),
        {
            owner: "Client:" + client.id,
            owner_type: "Client",
            owner_id: client.id,
            as_of_date: formatDateForInput(new Date()),
            percentage_owned: 100,
            managed_by_id: "",
            lease_type_id: "Oil/Gas Lease",
        }
    );

    useEffect(() => {
        const fetchData = async () => {
            try {
                if (id > 0) {
                    let response = await Api.get(
                        "clients/" + client.id + "/leases/" + id
                    );
                    setInput(response.data.data);
                    setLoading(false);
                }
            } catch (error) {
                if (error.response.status === 403) {
                    setNotAuthorized(true);
                }
            }
        };
        fetchData();
    }, [id, client]);

    function updateValue(event) {
        setInput({ [event.target.name]: event.target.value });
    }

    if (notAuthorized) {
        onClose();
    }

    async function save(e) {
        e.preventDefault();
        try {
            const type =
                props.hideType === true ? "Real Estate" : input.lease_type_id;
            const body = { ...input, lease_type_id: type };
            let response;
            if (id > 0) {
                response = await Api.put(
                    "clients/" + client.id + "/leases/" + id,
                    body
                );
            } else {
                response = await await Api.post(
                    "clients/" + client.id + "/leases",
                    body
                );
            }
            addToast(response.data.message);
            setErrors([]);
            onSuccess();
        } catch (e) {
            setErrors([]);
            if (e.response.data.errors) {
                setErrors(e.response.data.errors);
            } else {
                addToast(e.response.data.message, { type: "error" });
            }
        }
    }

    return (
        <BasicModal
            isOpen={isOpen}
            size="w-full"
            onClose={onClose}
            header={
                <BasicModalHeader
                    onClose={onClose}
                    onSave={save}
                    title={title}
                />
            }
        >
            <div className="max-w-3xl mx-auto">
                <LeasesForm
                    client={client}
                    input={input}
                    readOnly={loading}
                    errors={errors}
                    onSubmit={save}
                    updateValue={updateValue}
                    hideType={props.hideType}
                />
            </div>
        </BasicModal>
    );
}
