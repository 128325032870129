import {
    CardElement,
    Elements,
    useElements,
    useStripe,
} from "@stripe/react-stripe-js";
import React, { useState, useEffect, useCallback } from "react";
import Forbidden from "../../components/Forbidden";
import PageContainer from "../../components/PageContainer";
import PageHeader from "../../components/PageHeader";
import SaveButton from "../../components/SaveButton";
import { loadStripe } from "@stripe/stripe-js";
import Plans from "../billing/plans";
import Panel, { PanelHeader } from "../../components/Panel";
import useBilling from "../../hooks/useBilling";
import useHasPermission from "../../hooks/usePermissions";
import { useStripeCardOptions } from "../register/InitialPayment";
import Button from "../../components/Button";
import BasicModal from "../../components/ModalBasic";
import Invoices from "../billing/invoices";
import OrganizationPageLayout from "./_partials/OrganizationPageLayout";
import { titleCase } from "util/TextUtil";
import useCurrentSubscription from "hooks/useCurrentSubscription";

export const OrganizationBilling = () => {
    const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);
    const { getPaymentDetails } = useBilling();
    const [paymentDetails, setPaymentDetails] = useState(null);

    document.title = "Subscription & Payments";

    const fetchPaymentDetails = async () => {
        const response = await getPaymentDetails();
        let paymentDetails = "";

        if (response.data.pm_type && response.data.pm_last_four) {
            paymentDetails = `${titleCase(response.data.pm_type)} ${
                response.data.pm_last_four
            }`;
        }

        setPaymentDetails(paymentDetails);
    };

    useEffect(() => {
        fetchPaymentDetails();
    }, []);

    if (!useHasPermission("firm_information_access")) {
        return <Forbidden />;
    }

    return (
        <>
            <Elements stripe={stripePromise}>
                <PageHeader text="Subscription & Billing" />
                <PageContainer>
                    <OrganizationPageLayout>
                        <div className="flex items-start max-w-4xl">
                            <div className="flex-1 space-y-10">
                                <Panel>
                                    <PanelHeader title="Update Subscription" />
                                    <UpdatePlan />
                                </Panel>

                                <Panel>
                                    <PanelHeader
                                        title={`Update Credit Card: ${paymentDetails}`}
                                    />
                                    <UpdateCreditCard />
                                </Panel>

                                <Panel>
                                    <PanelHeader title="Receipts" />
                                    <Invoices />
                                </Panel>

                                <Panel>
                                    <PanelHeader title="Cancel Subscription" />
                                    <CancelPrimarySubscription />
                                </Panel>
                            </div>
                        </div>
                    </OrganizationPageLayout>
                </PageContainer>
            </Elements>
        </>
    );
};

export function UpdatePlan() {
    const { data } = useCurrentSubscription();
    const { updatePrimarySubscription, loading, processing } = useBilling();
    const [selectedPrice, setSelectedPrice] = useState();

    return (
        <div className="space-y-3">
            <Plans
                selectedPrice={selectedPrice}
                primarySubscriptionPrice={data?.stripe_price}
                onChange={setSelectedPrice}
            />
            <div className="w-40 ml-auto">
                <Button
                    text="Change Plan"
                    disabled={!selectedPrice}
                    isLoading={loading || processing}
                    onClick={() => updatePrimarySubscription(selectedPrice)}
                />
            </div>
        </div>
    );
}

export function UpdateCreditCard() {
    const { createSetupIntent, updatePaymentMethod, clientSecret, loading } =
        useBilling();
    const options = useStripeCardOptions();
    const [processing, setProcessing] = useState("");
    const [error, setError] = useState(null);
    const stripe = useStripe();
    const elements = useElements();

    const handleSubmit = async (event) => {
        event.preventDefault();

        setProcessing(true);

        if (!stripe || !elements) {
            // Stripe.js has not loaded yet. Make sure to disable
            // form submission until Stripe.js has loaded.
            return;
        }

        const result = await stripe.confirmCardSetup(clientSecret, {
            payment_method: {
                card: elements.getElement(CardElement),
            },
        });

        if (result.error) {
            setError(`Payment failed ${result.error.message}`);
            alert(`Payment failed ${result.error.message}`);
            setProcessing(false);
        } else {
            setProcessing(false);
            try {
                await updatePaymentMethod(result.setupIntent.payment_method);
                setError(null);
            } catch (error) {
                console.log(error);
            }
        }
    };

    useEffect(() => {
        createSetupIntent();
    }, [createSetupIntent]);

    return (
        <div className="space-y-3">
            <form onSubmit={handleSubmit} className="space-y-4 mx-auto">
                {error && <div>{error}</div>}
                <div className="flex items-center border rounded-md px-3 h-12 bg-white">
                    <div className="flex-1">
                        <CardElement options={options} />
                    </div>
                </div>

                <div className="flex items-center w-full">
                    <div className="ml-auto w-40">
                        <SaveButton
                            type="submit"
                            disabled={!stripe}
                            isLoading={processing || loading}
                        >
                            Save
                        </SaveButton>
                    </div>
                </div>
            </form>
        </div>
    );
}

export function CancelPrimarySubscription() {
    const { cancelPrimarySubscription, loading } = useBilling();
    const [showModal, setShowModal] = useState(false);

    const handleCancelForm = useCallback(() => {
        try {
            cancelPrimarySubscription();
            setShowModal(false);
        } catch (error) {
            setShowModal(false);
        }
    }, [cancelPrimarySubscription]);

    return (
        <>
            <div className="space-y-3">
                <div className="w-48 ml-auto">
                    <Button
                        text="Cancel Subscription"
                        isLoading={loading}
                        onClick={() => setShowModal(true)}
                    />
                </div>
            </div>
            <BasicModal
                size="lg"
                isOpen={showModal}
                onClose={() => setShowModal(false)}
                onSubmit={() => setShowModal(false)}
            >
                <div className="text-xl mb-6">
                    Are you sure you wish to cancel your Inheralink
                    subscription?
                </div>
                <div className="flex space-x-3">
                    <Button
                        appearance="light"
                        type="button"
                        text="Never mind"
                        onClick={() => setShowModal(false)}
                    />
                    <Button
                        type="button"
                        text="Yes, cancel"
                        onClick={handleCancelForm}
                    />
                </div>
            </BasicModal>
        </>
    );
}
