import usePost from "./usePost";
import { resetClientPermissions } from "services/PermissionService";

export default function useResetClientPermission(clientId) {
    const { mutation, validationErrors } = usePost(
        (permissionKey) => resetClientPermissions(clientId, permissionKey),
        {
            showToastOnError: true,
            showToastOnSuccess: true,
        }
    );
    const onSubmit = (event, permissionKey) => {
        event.preventDefault();
        return mutation.mutate(permissionKey);
    };

    return { onSubmit, ...mutation, validationErrors };
}
