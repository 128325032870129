import React from "react";

const welcomeSidebarStyles = {
    backgroundImage:
        'url("/images/login-splash.jpg"), linear-gradient(145deg, #D5D5D5 0%, #484848 100%)',
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "right center",
};

const welcomeSidebarGradient = {
    backgroundImage:
        "linear-gradient(145deg, rgba(213,213,213, 0.0) 10%, rgba(2,2,2, 0.4) 100%)",
};

export default function LoginSplash() {
    return (
        <div className="flex-grow hidden md:block relative">
            <div style={welcomeSidebarStyles} className="h-full w-full">
                <div
                    style={welcomeSidebarGradient}
                    className="h-full w-full"
                ></div>
            </div>
            <div className="absolute bottom-10 flex items-center justify-center w-full text-white">
                <div className="text-center">
                    {/* <img src="/images/inherlink-logo.png" alt="Linking Assets For Generations" /> */}
                    <svg
                        className="h-32 fill-current mx-auto mb-8"
                        viewBox="0 0 200 190"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <g transform="translate(.32 .117)">
                            <circle cx="86.64" cy="70.433" r="14.29" />
                            <path d="M51.79 159.983a16.68 16.68 0 01-5.19-1.54c-15.411-6.813-22.382-24.829-15.57-40.24l-.44 1.23 16.75-37.88c.12-.28.22-.56.32-.85l9.94-22.47-.61 1.15a30.51 30.51 0 1155.81 24.67l.44-1.23-16.75 37.89a14 14 0 1025.6 11.32l16.75-37.89c13.05-29.503-.287-64-29.79-77.05-29.503-13.05-64 .287-77.05 29.79l-16.75 37.88c-.12.28-.22.57-.32.85l-9.94 22.48c-13.022 29.495.322 63.962 29.81 77 13.51 6 28.16 6.18 43.64.9-17.98-7.66-26.65-26.01-26.65-26.01z" />
                            <path d="M164.56 5.163c-17.85-7.89-36.4-7.44-56.23 6.28 0 0 15.41 5.16 25.77 18 8.81-1.3 13.56.21 18.66 2.46 15.413 6.816 22.383 24.835 15.57 40.25l.44-1.24-14.4 32.57c-.26.43-.5.86-.72 1.32l-17.69 37.46.64-1.14c-7.242 15.159-25.376 21.61-40.565 14.433-15.19-7.177-21.718-25.284-14.605-40.503l-.47 1.22 3.65-7.37c3.29-7 .83-16.22-6.13-19.51l-.15-.05c-7-3.29-15.74.65-19 7.61l-3.65 7.33a58.41 58.41 0 10105.62 49.9l14.94-31.62a13.88 13.88 0 001.41-2.47l16.75-37.89a58.41 58.41 0 00-29.84-77.04z" />
                        </g>
                    </svg>
                    <div
                        style={{
                            fontFamily: "vag-rundschrift-d, sans-serif",
                        }}
                        className="text-4xl"
                    >
                        Linking assets for generations
                    </div>
                </div>
            </div>
        </div>
    );
}
