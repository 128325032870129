import Api from "./Api";

export const fetchPostMortem = async function ({ queryKey }) {
    const [, { ...params }] = queryKey;
    const { client } = params;
    return await Api.get("clients/" + client.id + "/post-mortem").then(
        (res) => res.data.data
    );
};

export const fetchPostMortemAssets = async function ({ queryKey }) {
    const [, { ...params }] = queryKey;
    const { clientId, ...qs } = params;
    return await Api.get(
        "clients/" + clientId + "/post-mortem/all-assets",
        qs
    ).then((res) => res.data);
};

export const fetchPostMortemAssetsForInclusion = async function ({ queryKey }) {
    const [, { ...params }] = queryKey;
    const { clientId, ...qs } = params;
    return await Api.get(
        "clients/" + clientId + "/post-mortem/inclusion",
        qs
    ).then((res) => res.data);
};

export const fetchPostMortemAssetsForValues = async function ({ queryKey }) {
    const [, { ...params }] = queryKey;
    const { clientId, ...qs } = params;
    return await Api.get(
        "clients/" + clientId + "/post-mortem/values",
        qs
    ).then((res) => res.data);
};

export const fetchPostMortemBeneficiaries = async function ({ queryKey }) {
    const [, { ...params }] = queryKey;
    const { clientId, ...qs } = params;
    return await Api.get(
        "clients/" + clientId + "/beneficiaries/all-post-mortem",
        qs
    ).then((res) => res.data);
};

export const fetchPostMortemWealth = async function ({ queryKey }) {
    const [, { ...params }] = queryKey;
    const {
        owner,
        current,
        isPostMortem,
        client,
        showAltValues,
        person,
        jointSplit,
        include_transfer_owners,
        snapshot,
    } = params;
    return await Api.get("clients/" + client.id + "/reports/estate-inventory", {
        owner,
        current,
        is_post_mortem: isPostMortem,
        show_alternate_valuation: showAltValues,
        person,
        jointSplit,
        include_transfer_owners,
        snapshot,
    }).then((res) => {
        const {
            assets,
            owner_totals,
            owner_summary,
            net_worth_total: netWorth,
            net_worth_with_death_benefit_total: netWorthWithDeathBenefit,
            estate_net_worth_total: estateNetWorth,
            asset_keys,
        } = res.data;

        res.data.assets = assets;
        res.data.ownerTotals = owner_totals;
        res.data.ownerSummary = owner_summary;
        res.data.assetKeys = asset_keys;
        res.data.totals = {
            netWorth,
            netWorthWithDeathBenefit,
            estateNetWorth,
        };
        res.data.sortKeys = Object.keys(assets);

        return res.data;
    });
};
