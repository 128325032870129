import { formatDate } from "util/TextUtil";
import usePostMortemState from "hooks/usePostMortemState";

export default function useAssetValues({ asset, allowPostMortem = true }) {
    const { person, showAltValues, isPostMortem } = usePostMortemState();

    let asOfDate = formatDate(asset.as_of_date);
    let value = asset.net_value;

    let gross_value = asset.gross_value;
    let balance = asset.balance;

    let asOfDateLabel = "As of Date";
    let valueLabel = asset.is_liability ? "Liability Balance" : "Net Value";

    if (asset.death_benefit && isPostMortem) {
        valueLabel = "Death Benefit";
    }

    let hasDodValue = asset.has_dod_value;

    if (person === "spouse") {
        hasDodValue = asset.has_dod_value_spouse;
    }

    if (isPostMortem && allowPostMortem && hasDodValue) {
        asOfDateLabel = "As of D.O.D";
        let isOriginal =
            person === "spouse"
                ? !asset.dod_value_spouse_original
                : !asset.dod_value_original;

        valueLabel = asset.is_liability
            ? "D.O.D Liability balance"
            : "D.O.D Net value";

        value =
            person === "spouse"
                ? asset.dod_net_value_spouse || asset.net_value
                : asset.dod_net_value || asset.net_value;
        gross_value =
            person === "spouse"
                ? asset.dod_gross_value_spouse || asset.gross_value
                : asset.dod_gross_value || asset.gross_value;
        balance =
            person === "spouse"
                ? asset.dod_balance_spouse || asset.balance
                : asset.dod_balance || asset.balance;

        asOfDate = formatDate(
            person === "spouse"
                ? asset.dod_as_of_date_spouse
                : asset.dod_as_of_date,
            false
        );

        if (showAltValues) {
            isOriginal =
                person === "spouse"
                    ? !asset.alt_dod_value_spouse_original
                    : !asset.alt_dod_value_original;

            valueLabel = asset.is_liability
                ? "Alternate Liability balance"
                : "Alternate value";

            value =
                person === "spouse"
                    ? asset.alt_dod_net_value_spouse || asset.net_value
                    : asset.alt_dod_net_value || asset.net_value;

            gross_value =
                person === "spouse"
                    ? asset.alt_dod_gross_value_spouse || asset.gross_value
                    : asset.alt_dod_gross_value || asset.gross_value;
            balance =
                person === "spouse"
                    ? asset.alt_dod_balance_spouse || asset.balance
                    : asset.alt_dod_balance || asset.balance;

            asOfDate = formatDate(
                asset.person === "spouse"
                    ? asset.alt_dod_as_of_date_spouse
                    : asset.alt_dod_as_of_date,
                false
            );

            asOfDateLabel = "As of Alt. Date";
        }

        if (isOriginal) {
            valueLabel = asset.is_liability
                ? "Current Liability balance"
                : "Current Net Value";

            asOfDateLabel = "As of Date";
        }
    }

    return {
        asOfDate,
        asOfDateLabel,
        value,
        valueLabel,
        gross_value,
        balance,
    };
}
