import Api from "./Api";

export const fetchAssets = async function ({ queryKey }) {
    const [, { ...params }] = queryKey;
    const { clientId, ...qs } = params;
    return await Api.get("clients/" + clientId + "/all-assets", qs).then(
        (res) => res.data
    );
};

export const fetchAsset = async function ({ queryKey }) {
    const [, { ...params }] = queryKey;
    const { clientId, assetType, assetId, ...qs } = params;
    return await Api.get(
        "clients/" + clientId + "/all-assets/" + assetType + "/" + assetId,
        qs
    ).then((res) => res.data);
};
