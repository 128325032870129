import { formatDate } from "util/TextUtil";
import usePostMortemState from "hooks/usePostMortemState";

export default function useAssetOwnerValues({ asset, allowPostMortem = true }) {
    const { person, showAltValues, isPostMortem } = usePostMortemState();

    let asOfDate = formatDate(asset.as_of_date);
    let value = asset.owner_net_value;

    let gross_value = asset.owner_gross_value;
    let balance = asset.balance;

    let asOfDateLabel = "As of Date";
    let valueLabel = asset.is_liability ? "Liability Balance" : "Net Value";

    if (asset.death_benefit && isPostMortem) {
        valueLabel = "Death Benefit";
    }

    let hasDodValue = asset.has_dod_value;

    if (person === "spouse") {
        hasDodValue = asset.has_dod_value_spouse;
    }

    if (isPostMortem && allowPostMortem && hasDodValue) {
        asOfDateLabel = "As of D.O.D";
        let isOriginal =
            person === "spouse"
                ? !asset.dod_value_spouse_original
                : !asset.dod_value_original;

        valueLabel = asset.is_liability
            ? "D.O.D Liability balance"
            : "D.O.D Net value";

        value =
            person === "spouse"
                ? asset.owner_dod_net_value_spouse || asset.owner_net_value
                : asset.owner_dod_net_value || asset.owner_net_value;
        gross_value =
            person === "spouse"
                ? asset.owner_dod_gross_value_spouse || asset.owner_gross_value
                : asset.owner_dod_gross_value || asset.owner_gross_value;
        balance =
            person === "spouse"
                ? asset.owner_dod_balance_spouse || asset.owner_balance
                : asset.owner_dod_balance || asset.owner_balance;

        asOfDate = formatDate(
            person === "spouse"
                ? asset.dod_as_of_date_spouse
                : asset.dod_as_of_date,
            false
        );

        if (showAltValues) {
            isOriginal =
                person === "spouse"
                    ? !asset.owner_alt_dod_value_spouse_original
                    : !asset.owner_alt_dod_value_original;

            valueLabel = asset.is_liability
                ? "Alternate Liability balance"
                : "Alternate value";

            value =
                person === "spouse"
                    ? asset.owner_alt_dod_net_value_spouse ||
                      asset.owner_net_value
                    : asset.owner_alt_dod_net_value || asset.owner_net_value;

            gross_value =
                person === "spouse"
                    ? asset.owner_alt_dod_gross_value_spouse ||
                      asset.owner_gross_value
                    : asset.owner_alt_dod_gross_value ||
                      asset.owner_gross_value;
            balance =
                person === "spouse"
                    ? asset.owner_alt_dod_balance_spouse || asset.owner_balance
                    : asset.owner_alt_dod_balance || asset.owner_balance;

            asOfDate = formatDate(
                asset.person === "spouse"
                    ? asset.alt_dod_as_of_date_spouse
                    : asset.alt_dod_as_of_date,
                false
            );

            asOfDateLabel = "As of Alt. Date";
        }

        if (isOriginal) {
            valueLabel = asset.is_liability
                ? "Current Liability balance"
                : "Current Net Value";

            asOfDateLabel = "As of Date";
        }
    }

    let status = "";
    if (isPostMortem && allowPostMortem && hasDodValue) {
        if (person === "spouse") {
            if (asset.has_dod_value_spouse && !showAltValues) {
                status = "D.O.D";
            }
            if (asset.alt_dod_value_spouse && showAltValues) {
                status = "ALT. D.O.D";
            }
        } else {
            if (asset.has_dod_value && asset.dod_value && !showAltValues) {
                status = "D.O.D";
            }
            if (asset.alt_dod_value && showAltValues) {
                status = "ALT. D.O.D";
            }
        }
    }

    // Handle Liens
    // TODO: this was never handled, but owner_lien_value should probably be the default here
    let lienValue = "";

    let lienStatus = "";
    let lienAsOfDate = asset.lien_as_of_date;

    if (isPostMortem && allowPostMortem) {
        lienValue =
            person === "spouse"
                ? asset.owner_dod_balance_spouse
                : asset.owner_dod_balance;
    }

    if (isPostMortem && allowPostMortem && hasDodValue) {
        lienAsOfDate =
            person === "spouse"
                ? asset.lien_dod_as_of_date_spouse
                : asset.lien_dod_as_of_date;

        if (person === "spouse") {
            if (asset.lien_has_dod_value_spouse && !showAltValues) {
                lienStatus = "D.O.D";
            }
            if (asset.lien_has_alt_dod_value_spouse && showAltValues) {
                lienStatus = "ALT. D.O.D";
            }
        } else {
            if (asset.lien_has_dod_value && !showAltValues) {
                lienStatus = "D.O.D";
            }
            if (asset.lien_has_alt_dod_value && showAltValues) {
                lienStatus = "ALT. D.O.D";
            }
        }

        if (showAltValues) {
            lienValue =
                person === "spouse"
                    ? asset.alt_owner_dod_balance_spouse
                    : asset.alt_owner_dod_balance;
        }
    }

    return {
        asOfDate,
        asOfDateLabel,
        value,
        valueLabel,
        gross_value,
        balance,
        status,
        lienValue,
        lienStatus,
        lienAsOfDate,
    };
}
