import React from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import Spinner from "./Spinner";

const baseStyle = `inline-flex items-center justify-center rounded-md border px-4 text-base leading-6 font-medium shadow-sm transition-colors duration-200 ease-in-out whitespace-no-wrap`;
const styles = {
    link: "bg-transparent border-0 border-transparent shadow-none text-brand hover:text-brand-700 rounded-sm",
    default:
        "bg-brand hover:bg-brand-600 border-brand hover:border-brand-600 text-white rounded-sm",
    danger: "bg-red-500 hover:bg-red-700 border-red-500 text-red-100 hover:text-white ",
    warning:
        "bg-orange-500 hover:bg-orange-700 border-orange-500 text-red-100 hover:text-white",
    outline:
        "shadow-none bg-transparent hover:border-gray-800 border-gray-600 text-gray-600 hover:text-gray-900 hover:border-gray-900",
    light: "shadow-none bg-transparent hover:border-gray-800 border-gray-200 text-gray-600 hover:text-gray-900 hover:border-gray-900",
    none: "",
};

export const ButtonStyles = Object.fromEntries(
    new Map(
        Object.keys(styles).map((key) => [key, `${baseStyle} ${styles[key]}`])
    )
);

const Inner = ({ children, icon, text, isLoading }) => {
    return (
        <>
            {!!children !== true && (
                <>
                    {icon && (
                        <div className={`${isLoading ? "invisible" : ""}`}>
                            <FontAwesomeIcon
                                icon={icon}
                                className={text ? "mr-2" : "mr-0"}
                            />
                        </div>
                    )}
                    {isLoading && (
                        <div className="absolute">
                            <Spinner
                                color="currentColor"
                                width={20}
                                height={20}
                            />
                        </div>
                    )}
                    <span className={`${isLoading ? "invisible" : ""}`}>
                        {text}
                    </span>
                </>
            )}
            {!!children !== false && !isLoading && children}
            {!!children !== false && isLoading && (
                <div className="absolute">
                    <Spinner color="currentColor" width={20} height={20} />
                </div>
            )}
        </>
    );
};

const Button = ({
    type,
    text,
    icon = null,
    target = "",
    to = "",
    title = "",
    width = "w-full",
    height = "h-12",
    appearance = "default",
    base = true,
    onClick = () => {},
    isLoading = false,
    disabled = false,
    children = false,
    className = "",
    tabIndex = 0,
}) => {
    let options = [];
    let baseStyles =
        base !== true
            ? `${width ? width : ""} ${height ? height : ""}`
            : `relative ${width ? width : ""} ${
                  height ? height : ""
              } ${baseStyle}`;

    if (disabled) {
        options["disabled"] = "disabled";
        baseStyles += " opacity-50 pointer-events-none cursor-not-allowed";
    } else {
        options["disabled"] = "";
    }

    if (target) {
        options["target"] = target;
    }

    if (type === "anchor") {
        return (
            <a
                href={to}
                className={getClassName()}
                {...options}
                tabIndex={tabIndex}
            >
                <Inner icon={icon} text={text} isLoading={isLoading}>
                    {children}
                </Inner>
            </a>
        );
    }
    if (type === "link") {
        return (
            <Link
                to={to}
                className={getClassName()}
                {...options}
                tabIndex={tabIndex}
            >
                <Inner icon={icon} text={text} isLoading={isLoading}>
                    {children}
                </Inner>
            </Link>
        );
    }

    function handleClick(e) {
        if (onClick && isLoading === false && disabled === false) {
            onClick(e);
        }
    }

    function getClassName() {
        if (appearance === "none") return className;
        return `${baseStyles + " " + styles[appearance]} ${
            disabled ? "cursor-not-allowed" : ""
        } ${className}`;
    }

    return (
        <button
            type={type ?? "button"}
            className={getClassName()}
            onClick={(e) => handleClick(e)}
            {...options}
            disabled={isLoading}
            title={title || text}
            tabIndex={tabIndex}
        >
            <Inner icon={icon} text={text} isLoading={isLoading}>
                {children}
            </Inner>
        </button>
    );
};

Button.propTypes = {
    text: PropTypes.string,
    type: PropTypes.string,
    disabled: PropTypes.bool,
    handleClick: PropTypes.func,
    appearance: PropTypes.string,
};

export default Button;
