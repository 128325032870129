import React from "react";
import { Route, Switch } from "react-router-dom";
import AddOtherInsurancePolicy from "./AddOtherInsurancePolicy";
import EditOtherInsurancePolicy from "./EditOtherInsurancePolicy";
import OtherInsurancePoliciesIndex from "./OtherInsurancePoliciesIndex";

export default function OtherInsurancePolices(parentProps) {
    return (
        <Switch>
            <Route
                exact
                path={[
                    "/clients/:clientId/other-insurance-policies",
                    "/client/other-insurance-policies",
                ]}
                render={(props) => (
                    <OtherInsurancePoliciesIndex {...parentProps} {...props} />
                )}
            />
            <Route
                exact
                path={[
                    "/clients/:clientId/other-insurance-policies/add",
                    "/client/other-insurance-policies/add",
                ]}
                render={(props) => (
                    <AddOtherInsurancePolicy {...parentProps} {...props} />
                )}
            />
            <Route
                path={[
                    "/clients/:clientId/other-insurance-policies/:nonLifePolicyId/edit",
                    "/client/other-insurance-policies/:nonLifePolicyId/edit",
                ]}
                render={(props) => (
                    <EditOtherInsurancePolicy {...parentProps} {...props} />
                )}
            />
            <Route
                path={[
                    "/clients/:clientId/other-insurance-policies/:nonLifePolicyId/view",
                    "/client/other-insurance-policies/:nonLifePolicyId/view",
                ]}
                render={(props) => (
                    <EditOtherInsurancePolicy
                        readOnly={true}
                        {...parentProps}
                        {...props}
                    />
                )}
            />
        </Switch>
    );
}
