import React, { useState, useReducer, useEffect } from "react";
import Api from "../../services/Api";
import BasicModal, { BasicModalHeader } from "../../components/ModalBasic";
import { useToasts } from "react-toast-notifications";
import PersonalPropertyForm from "./_partials/PersonalPropertyForm";
import { formatDateForInput } from "../../util/TextUtil";

export default function PersonalPropertyModal({
    isOpen,
    title,
    client,
    id,
    onClose,
    onSuccess,
}) {
    const { addToast } = useToasts();
    const [errors, setErrors] = useState([]);
    const [notAuthorized, setNotAuthorized] = useState(false);
    const [loading, setLoading] = useState(id > 0);

    const [input, setInput] = useReducer(
        (state, newState) => ({ ...state, ...newState }),
        {
            name: "",
            description: "",
            value: "",
            as_of_date: formatDateForInput(new Date()),
            purchase_price: "",
            purchase_date: "",
            insurance_policy_id: "",
            owner: "client:" + client.id,
            percentage_owned: "",
            other_owner: "",
            other_percentage_owned: "",
            other_owners_name: "",
            has_lien: false,
            lien_holder: "",
            loan_balance: "",
            managed_by_id: "",
        }
    );

    useEffect(() => {
        const fetchData = async () => {
            try {
                if (id > 0) {
                    let response = await Api.get(
                        "clients/" + client.id + "/personal-properties/" + id
                    );
                    setInput(response.data.data);
                    setLoading(false);
                }
            } catch (error) {
                if (error.response.status === 403) {
                    setNotAuthorized(true);
                }
            }
        };
        fetchData();
    }, [id, client]);

    function updateValue(event) {
        setInput({ [event.target.name]: event.target.value });
    }

    async function save(e) {
        e.preventDefault();
        try {
            const body = { ...input };
            let response;
            if (id > 0) {
                response = await Api.put(
                    "clients/" + client.id + "/personal-properties/" + id,
                    body
                );
            } else {
                response = await Api.post(
                    "clients/" + client.id + "/personal-properties",
                    body
                );
            }
            addToast(response.data.message);
            setErrors([]);
            onSuccess();
        } catch (e) {
            setErrors([]);
            if (e.response.data.errors) {
                setErrors(e.response.data.errors);
            } else {
                addToast(e.response.data.message, { type: "error" });
            }
        }
    }

    if (notAuthorized) {
        onClose();
    }

    return (
        <BasicModal
            isOpen={isOpen}
            size="w-full"
            onClose={onClose}
            header={
                <BasicModalHeader
                    onClose={onClose}
                    onSave={save}
                    title={title}
                />
            }
        >
            <div className="max-w-3xl mx-auto">
                <PersonalPropertyForm
                    client={client}
                    input={input}
                    readOnly={loading}
                    errors={errors}
                    onSubmit={save}
                    updateValue={updateValue}
                />
            </div>
        </BasicModal>
    );
}
