import React, { useEffect, useState } from "react";
import Api from "../../services/Api";
import { useToasts } from "react-toast-notifications";
import Button from "../Button";
import Spinner from "../Spinner";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import BeneficiaryList from "./BeneficiaryList";
import TextInput from "../TextInput";
import SelectInput from "../SelectInput";
import { formatDateForInput } from "util/TextUtil";

const dateOptions = [
    {
        value: "propose",
        label: "Date Proposed",
    },
    {
        value: "verify",
        label: "Date Verified",
    },
];

export default function AssetBeneficiary({
    proposed = false,
    client,
    asset,
    resourceType,
    resourceId,
    defaultBeneficiaries = [],
    loading = false,
    current = true,
    didSave = () => {},
}) {
    const showProposed = !current;
    const { addToast } = useToasts();
    const [errors, setErrors] = useState([]);
    const [proposedAt, setProposedAt] = useState(
        formatDateForInput(asset.beneficiaries_proposed_at)
    );
    const [verifiedAt, setVerifiedAt] = useState(
        formatDateForInput(asset.beneficiaries_verified_at)
    );
    const [saving, setSaving] = useState(false);

    const [proposalMode, setProposalMode] = useState("propose");

    const [beneficiaries, setBeneficiaries] = useState(defaultBeneficiaries);

    useEffect(() => {
        setBeneficiaries(defaultBeneficiaries);
    }, [defaultBeneficiaries]);

    function appendBeneficiary() {
        let newBeneficiaries = [
            ...beneficiaries,
            {
                beneficiary_id: "",
                beneficiary_type: "",
                percentage_owned: 0,
                resource_id: "",
                level: 1,
                resource_type: "",
                show_proposed: showProposed,
            },
        ];
        setBeneficiaries(newBeneficiaries);
    }

    function updatePercentageOwned(idx, value) {
        setBeneficiaries((beneficiaries) =>
            beneficiaries.map((ownership, idxs) => {
                if (idxs === idx) {
                    ownership.percentage_owned = value;
                }
                return ownership;
            })
        );
    }

    function removeBeneficiary(idx) {
        if (beneficiaries.length === 1) {
            if (
                confirm(
                    `Are you sure you want to delete all ${
                        showProposed ? "proposed" : "current"
                    } beneficiaries for this asset?`
                )
            ) {
                update([]);
                setBeneficiaries([]);
                return true;
            }

            return false;
        }

        let deletedPercentage = 0;
        let newBeneficiaries = beneficiaries.filter((owner, i) => i !== idx);
        setBeneficiaries(() => newBeneficiaries);
        setErrors([]);
        updatePercentageOwned(
            0,
            Math.min(
                parseInt(beneficiaries[0].percentage_owned) +
                    parseInt(deletedPercentage),
                100
            )
        );
    }

    async function update(beneficiaries) {
        try {
            setSaving(true);

            let payload = {
                show_proposed: showProposed,
                resource_id: resourceId,
                resource_type: resourceType,
                proposed_at: proposedAt,
                verified_at: verifiedAt,
                proposal_mode: proposalMode,
                beneficiaries,
            };
            let response = await Api.post(
                "clients/" + client.id + "/beneficiaries",
                payload
            );
            addToast(response.data.message);
            setErrors([]);
            setSaving(false);
            didSave();
        } catch (e) {
            console.error(e);
            setSaving(false);
            setErrors([]);
            if (e.response.data.errors) {
                setErrors(e.response.data.errors);
            } else {
                addToast(e.response.data.message, { type: "error" });
            }
        }
    }

    if (loading) {
        return <Spinner />;
    }

    return (
        <>
            <div className="mb-6 w-full">
                <div>
                    <BeneficiaryList
                        proposed={proposed}
                        client={client}
                        errors={errors}
                        disabled={!showProposed}
                        beneficiaries={beneficiaries}
                        setBeneficiaries={setBeneficiaries}
                        removeBeneficiary={removeBeneficiary}
                    />
                    {showProposed && (
                        <>
                            <div className="w-full flex items-center justify-end mt-6 space-x-3">
                                <button
                                    type="button"
                                    onClick={appendBeneficiary}
                                    className="flex items-center space-x-1 text-gray-500 text-sm hover:text-brand p-2 mr-auto"
                                >
                                    <FontAwesomeIcon icon={faPlusCircle} />{" "}
                                    <span>Add Beneficiary</span>
                                </button>
                                {beneficiaries.length > 0 && (
                                    <>
                                        <div>
                                            <div className="flex items-center space-x-3">
                                                <SelectInput
                                                    options={dateOptions}
                                                    name="proposal_mode"
                                                    value={proposalMode}
                                                    disabled={!showProposed}
                                                    onChange={(e) =>
                                                        setProposalMode(
                                                            e.target.value
                                                        )
                                                    }
                                                />
                                                <div>
                                                    {proposalMode ===
                                                        "propose" && (
                                                        <>
                                                            <TextInput
                                                                type="date"
                                                                name="proposed_at"
                                                                value={
                                                                    proposedAt
                                                                }
                                                                onChange={(e) =>
                                                                    setProposedAt(
                                                                        e.target
                                                                            .value
                                                                    )
                                                                }
                                                            />
                                                        </>
                                                    )}
                                                    {proposalMode ===
                                                        "verify" && (
                                                        <>
                                                            <TextInput
                                                                type="date"
                                                                name="verified_at"
                                                                value={
                                                                    verifiedAt
                                                                }
                                                                onChange={(e) =>
                                                                    setVerifiedAt(
                                                                        e.target
                                                                            .value
                                                                    )
                                                                }
                                                            />
                                                        </>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="w-40">
                                            <Button
                                                onClick={() =>
                                                    update(beneficiaries)
                                                }
                                                isLoading={saving}
                                                disabled={
                                                    (proposalMode ===
                                                        "propose" &&
                                                        !proposedAt) ||
                                                    (proposalMode ===
                                                        "verify" &&
                                                        !verifiedAt)
                                                }
                                                text={`${
                                                    proposalMode === "propose"
                                                        ? "Save & Propose"
                                                        : "Save & Verify"
                                                }`}
                                            />
                                        </div>
                                    </>
                                )}
                            </div>
                        </>
                    )}
                </div>
            </div>
        </>
    );
}
