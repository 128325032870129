import React, { useState } from "react";
import Button from "./Button";
import Auth from "../services/Auth";
import useSwitchAccount from "hooks/useSwitchAccount";
import { titleCase } from "util/TextUtil";
import useAccounts from "hooks/useAccounts";
import Panel from "./Panel";

export default function FatalError({ title = "Fatal Error", message, code }) {
    const [isLoading, setIsLoading] = useState(false);

    const logout = async () => {
        try {
            setIsLoading(true);
            await Auth.logout();
        } catch (e) {
            console.log(e);
        }
        window.location.reload();
    };

    if (code === 403) {
        return (
            <NoAccountAccess
                title={title}
                message={message}
                code={code}
                logout={logout}
            />
        );
    }

    return (
        <div className="h-screen flex items-center justify-center">
            <div className="flex flex-col items-center">
                <h1 className="block text-2xl">
                    {title} ({code})
                </h1>
                <p className="m-4">{message}</p>
                <Button
                    className={`m-4`}
                    onClick={() => window.location.reload()}
                    text={"Reload"}
                ></Button>
                {parseInt("" + code) === 403 && (
                    <Button
                        isLoading={isLoading}
                        onClick={() => logout()}
                        text={"Logout"}
                    ></Button>
                )}
            </div>
        </div>
    );
}

const NoAccountAccess = ({ title, message, code, isLoading, logout }) => {
    const { data: accounts } = useAccounts(
        {},
        {
            enabled: true,
            cacheTime: 1000 * 60 * 2,
            refetchOnWindowFocus: false,
        }
    );

    const { onSubmit } = useSwitchAccount();

    const handleClick = (event, account) => {
        onSubmit(event, {
            org_user_id: account.id,
        });
    };

    return (
        <div className="h-screen flex items-center justify-center">
            <div className="flex flex-col items-center max-w-2xl">
                <h1 className="block text-2xl">
                    {title} ({code})
                </h1>
                <p className="m-4">{message}</p>
                {accounts?.length > 0 && (
                    <Panel className="p-2 my-10">
                        <p>
                            Below is a list of all accounts linked to this email
                            on the Inheralink Platform. You can log into your
                            other roles through the list below.
                        </p>
                        <table className="w-full text-left">
                            <thead>
                                <tr>
                                    <th>Organization</th>
                                    <th>Role</th>
                                    <th>Client</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {accounts?.map((account) => (
                                    <tr key={account.id}>
                                        <td>
                                            <button
                                                type="button"
                                                onClick={(e) =>
                                                    handleClick(e, account)
                                                }
                                            >
                                                {account.organization_name}
                                            </button>
                                        </td>
                                        <td>
                                            {titleCase(
                                                account.role.replaceAll(
                                                    "_",
                                                    " "
                                                )
                                            )}
                                        </td>
                                        <td>
                                            {titleCase(
                                                account.type === "member"
                                                    ? "All"
                                                    : account.role ===
                                                      "client_owner"
                                                    ? account.user_full_name
                                                    : account.target_full_name
                                            )}
                                        </td>
                                        <td>
                                            <div className={"flex"}>
                                                <div className={"flex-0"}>
                                                    <button
                                                        type="button"
                                                        className="bg-transparent border-0 border-transparent shadow-none text-brand hover:text-brand-700 rounded-sm px-0"
                                                        onClick={(e) =>
                                                            handleClick(
                                                                e,
                                                                account
                                                            )
                                                        }
                                                    >
                                                        Switch
                                                    </button>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </Panel>
                )}
                <div className="w-32">
                    <Button
                        isLoading={isLoading}
                        onClick={() => logout()}
                        text={"Logout"}
                        appearance="light"
                    ></Button>
                </div>
            </div>
        </div>
    );
};
