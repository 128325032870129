import React, { useState, useEffect } from "react";
import TextInput from "../../components/TextInput";
import Button from "../../components/Button";
import Api from "../../services/Api";
import { useToasts } from "react-toast-notifications";
import InputErrors from "../../components/InputErrors";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faArrowAltCircleRight,
    faCheckCircle,
    faEnvelope,
} from "@fortawesome/free-solid-svg-icons";
import Panel from "../../components/Panel";

const InviteMembers = () => {
    const { addToast } = useToasts();
    const [emails, setEmails] = useState([
        { email: "", type: "member", role: "member_partner" },
    ]);
    const [errors, setErrors] = useState([]);
    const [isSaving, setIsSaving] = useState(false);
    const [invitationsSent, setInvitationsSent] = useState(false);

    useEffect(() => {
        document.title = "Invite Team Members";
    }, []);

    function updateEmail(idx, event) {
        const newEmails = emails.map((email, sidx) => {
            if (idx !== sidx) return email;
            return {
                ...email,
                email: event.target.value,
                type: "member",
                role: "member_partner",
            };
        });
        setEmails(newEmails);
    }

    function appendNewEmail() {
        if (emails.length < 10) {
            setEmails((emails) => [...emails, { email: "" }]);
        }
    }

    function removeEmail(idx) {
        if (emails.length > 1) {
            setEmails((emails) => emails.filter((s, sidx) => idx !== sidx));
        }
    }

    async function inviteMembers(e) {
        e.preventDefault();
        setIsSaving(true);
        try {
            await Api.post("register/invite-team-members", {
                invitees: emails,
            });
            setIsSaving(false);
            setInvitationsSent(true);
            setEmails([{ email: "", type: "member", role: "member_partner" }]);
        } catch (e) {
            setIsSaving(false);
            setInvitationsSent(false);
            if (e.response.data.errors) {
                setErrors(e.response.data.errors);
            } else {
                addToast(e.response.data.message, { type: "error" });
            }
        }
    }

    return (
        <div className="min-h-screen flex items-center justify-center">
            <Panel className="max-w-xl w-full">
                <div>
                    {invitationsSent === false && (
                        <>
                            <h1 className="text-3xl mb-8 text-center">
                                Invite Team Members
                            </h1>
                            <form onSubmit={inviteMembers}>
                                <InputErrors errors={errors.invitees} />
                                {emails.map((email, idx) => (
                                    <div
                                        className="mb-8 flex items-center"
                                        key={idx}
                                    >
                                        <div className="flex-1">
                                            <TextInput
                                                name="emails[]"
                                                value={email.email}
                                                placeholder="Email"
                                                type="email"
                                                autoComplete="off"
                                                onChange={(e) =>
                                                    updateEmail(idx, e)
                                                }
                                                error={
                                                    errors[
                                                        "invitees." +
                                                            idx +
                                                            ".email"
                                                    ]
                                                }
                                                autoFocus={
                                                    idx === 0 ? true : false
                                                }
                                            />
                                        </div>
                                        {emails.length > 1 && (
                                            <button
                                                type="button"
                                                onClick={() => removeEmail(idx)}
                                                className="text-base flex items-center hover:text-gray-900 flex-initial pl-3"
                                            >
                                                <svg
                                                    fill="none"
                                                    viewBox="0 0 24 24"
                                                    stroke="currentColor"
                                                    className="w-5 h-5"
                                                >
                                                    <path
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        strokeWidth="2"
                                                        d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
                                                    ></path>
                                                </svg>
                                            </button>
                                        )}
                                    </div>
                                ))}

                                {emails.length < 10 && (
                                    <div className="mt-3 mb-12">
                                        <button
                                            type="button"
                                            onClick={appendNewEmail}
                                            className="text-base flex items-center text-gray-700 hover:text-gray-900"
                                        >
                                            <svg
                                                fill="none"
                                                viewBox="0 0 24 24"
                                                stroke="currentColor"
                                                className="w-5 h-5 mr-1"
                                            >
                                                <path
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    strokeWidth="2"
                                                    d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                                                ></path>
                                            </svg>{" "}
                                            Add Another Team Member
                                        </button>
                                    </div>
                                )}

                                <Button
                                    type="submit"
                                    text="Send Invite"
                                    disabled={isSaving}
                                    className="w-64 mx-auto block bg-gray-800 hover:bg-gray-700 text-white border border-gray-800"
                                />
                            </form>
                            <div className="mt-8 text-center">
                                Not ready to invite anyone?{" "}
                                <Link to="/" className="underline">
                                    Skip for now
                                </Link>
                            </div>
                        </>
                    )}
                    {invitationsSent && (
                        <div>
                            <h1 className="text-3xl mb-8 text-center space-x-2">
                                <FontAwesomeIcon
                                    icon={faCheckCircle}
                                    className="text-green-400 -ml-2"
                                />
                                <span>Invitations Sent</span>
                            </h1>
                            <div className="flex space-x-6">
                                <Button
                                    type="button"
                                    onClick={() => setInvitationsSent(false)}
                                    text="Invite more team members"
                                    icon={faEnvelope}
                                    className="w-64 mx-auto block bg-gray-800 hover:bg-gray-700 text-white border border-gray-800"
                                />
                                <Button
                                    type="link"
                                    to="/"
                                    text="Go to my dashboard"
                                    icon={faArrowAltCircleRight}
                                    className="w-64 mx-auto block bg-gray-800 hover:bg-gray-700 text-white border border-gray-800"
                                />
                            </div>
                        </div>
                    )}
                </div>
            </Panel>
        </div>
    );
};

export default InviteMembers;
