import React, { useEffect, useState } from "react";
import Api from "services/Api";
import Spinner from "components/Spinner";
import { useToasts } from "react-toast-notifications";
import AssetDisplayForInclusion from "components/Ownership/AssetDisplayForInclusion";
import { isBool } from "index";
import usePostMortemState from "hooks/usePostMortemState";
import OwnershipContextProvider from "contexts/OwnershipContext";
import usePostMortemAssetsForInclusion from "hooks/usePostMortemAssetsForInclusion";
import useClient from "../../hooks/useClient";

export default function Inclusion() {
    return (
        <OwnershipContextProvider>
            <InclusionInner />
        </OwnershipContextProvider>
    );
}

const InclusionInner = () => {
    const { client } = useClient();
    const { person } = usePostMortemState();
    const {
        data,
        isLoading: loading,
        remove,
        refetch,
    } = usePostMortemAssetsForInclusion({
        clientId: client.id,
        show_death_benefit: true,
        person,
    });
    const { addToast } = useToasts();

    const refresh = () => {
        remove();
        refetch();
    };

    const [assets, setAssets] = useState([]);

    useEffect(() => {
        setAssets(data);
    }, [data]);

    async function updateInclusion(event, asset, ownership) {
        const ownershipKey = `${ownership.id}`;
        const key = `${asset.type}_${asset.id}`;
        let name = event.target.name;
        let value = isBool(event.target.value) ? true : false;
        try {
            setAssets((assets) =>
                assets.map((asset) => {
                    if (key === `${asset.type}_${asset.id}`) {
                        asset.current_owners = asset.current_owners.map(
                            (owner) => {
                                if (ownershipKey === `${owner.id}`) {
                                    owner[name] = value;
                                }
                                return owner;
                            }
                        );
                    }
                    return asset;
                })
            );
            await Api.post("clients/" + client.id + "/post-mortem/inclusion", {
                person,
                ownership_id: ownership.id,
                value,
            });
        } catch (e) {
            addToast(e.response.data.message, { type: "error" });
            refresh();
        }
    }

    if (loading) {
        return <Spinner message="Loading assets" />;
    }

    return (
        <>
            <div className="w-full">
                <div className="mb-4">
                    <p>
                        Select which assets should be{" "}
                        <span className="underline">included</span> in the
                        estate.
                    </p>
                </div>
                <div className="w-full space-y-6">
                    {assets?.map((asset) => {
                        return (
                            <AssetDisplayForInclusion
                                key={`${asset.type}_${asset.id}`}
                                client={client}
                                asset={asset}
                                ownerships={asset.current_owners}
                                updateInclusion={updateInclusion}
                                person={person}
                            />
                        );
                    })}
                </div>
            </div>
        </>
    );
};
