import React from "react";
import { Link } from "react-router-dom";

const inactiveClass =
    "text-brand focus:outline-none focus:bg-gray-100 hover:text-brand-700";
const activeClass =
    "text-gray-900 bg-brand-50 hover:text-gray-900 focus:outline-none focus:bg-gray-200";

export default function SideNav(props) {
    return <nav>{props.children}</nav>;
}

export function SideNavLink({ label, to, active = false, ...props }) {
    return (
        <div className="truncate">
            <Link
                to={to}
                className={`group flex items-center pl-6 pr-3 py-4 rounded transition ease-in-out duration-150 border-b border-gray-100 ${
                    active ? activeClass : inactiveClass
                }`}
            >
                <span className="text-base">{label || props.children}</span>
            </Link>
        </div>
    );
}
