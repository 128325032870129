import React, { useState, useCallback, useEffect } from "react";
import Api from "../services/Api";

export const MessageContext = React.createContext();

export default function MessageContextProvider({ children }) {
    const [messageCount, setMessageCount] = useState(0);

    const check = useCallback(() => {
        Api.get("messages", {
            unread: true,
        })
            .then(function name(response) {
                setMessageCount(response.data.data.length);
            })
            .catch((error) => {
                setMessageCount(0);
                console.log(error);
            });
    }, []);

    useEffect(() => {
        // check();
    }, [check]);

    return (
        <MessageContext.Provider
            value={{
                messageCount,
                setMessageCount,
                check,
            }}
        >
            {children}
        </MessageContext.Provider>
    );
}
