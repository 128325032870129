import React, { useState } from "react";

function Toggle({ onToggle, isToggled }) {
    const [toggled, setToggle] = useState(isToggled);

    const toggle = (e) => {
        if (e.key && e.key !== "Enter") return false;

        let toggleState = !toggled;
        setToggle(toggleState);
        onToggle(e, toggleState);
    };

    return (
        <div>
            <span
                onClick={(e) => toggle(e)}
                onKeyPress={(e) => toggle(e)}
                className={`${
                    toggled ? "bg-green-400" : "bg-gray-200"
                } relative block flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:shadow-outline`}
                role="checkbox"
                aria-checked={toggled}
                tabIndex="0"
            >
                <span
                    aria-hidden="true"
                    className={`${
                        toggled ? "translate-x-5" : "translate-x-0"
                    } block h-5 w-5 rounded-full bg-white shadow transform transition ease-in-out duration-200`}
                ></span>
            </span>
        </div>
    );
}

export default Toggle;
