import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Api from "../../../services/Api";
import Dropdown, { DropdownItem } from "../../../components/Dropdown";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faEllipsisH } from "@fortawesome/free-solid-svg-icons";
import Spinner from "../../../components/Spinner";
import { formatDate } from "../../../util/TextUtil";
import { useAsyncError } from "hooks/useAsyncError";

export default function ClientFormsTable({ client, clientPath }) {
    const throwError = useAsyncError();
    const [forms, setForms] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            try {
                let response = await Api.get("clients/" + client.id + "/forms");
                setForms(response.data.data);
                setLoading(false);
            } catch (error) {
                if (error.response.status === 403) {
                    throwError(error);
                }
            }
        };
        fetchData();
    }, [client]);

    useEffect(() => {
        document.title = "Client Forms";
    }, []);

    if (loading) {
        return <Spinner />;
    }

    return (
        <table className="w-full text-left">
            <thead>
                <tr>
                    <th>Form Name</th>
                    <th>Last Updated</th>
                    <th className="text-right">Actions</th>
                </tr>
            </thead>
            <tbody>
                {forms.map((form, idx) => (
                    <tr key={idx}>
                        <td>
                            <Link
                                to={
                                    form.path
                                        ? form.path
                                        : `${clientPath}/forms/${form.type_id}/edit`
                                }
                            >
                                {form.name}
                            </Link>
                        </td>
                        <td>{formatDate(form.updated_at)}</td>
                        <td className="text-right">
                            <Dropdown
                                position="right"
                                toggleClass="px-3 py-1"
                                toggleContent={
                                    <FontAwesomeIcon icon={faEllipsisH} />
                                }
                            >
                                <DropdownItem
                                    label="Edit"
                                    icon={faEdit}
                                    link={
                                        form.path
                                            ? form.path
                                            : `${clientPath}/forms/${form.type_id}/edit`
                                    }
                                />
                            </Dropdown>
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
}
