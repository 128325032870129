import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faEllipsisV,
    faTrash,
    faPlus,
} from "@fortawesome/free-solid-svg-icons";

import Api from "../../services/Api";
import EmptyTableRow from "../../components/EmptyTableRow";
import Spinner from "../../components/Spinner";
import Panel, { PanelHeader } from "../../components/Panel";
import Button from "../../components/Button";
import Dropdown, { DropdownItem } from "../../components/Dropdown";

import ModalRevokeAccess from "./ModalRevokeAccess";
import ModalAddAccess from "./ModalAddClientAccess";
import { formatDate } from "../../util/TextUtil";

export default function ResourceAccessClientList({ resourceType, resourceId }) {
    const [accessList, setAccessList] = useState([]);
    const [showDelete, setShowDelete] = useState(false);
    const [showAdd, setShowAdd] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedAccess, setSelectedAccess] = useState(null);
    const [forceUpdate, setForceUpdate] = useState(0);

    useEffect(() => {
        const fetch = async () => {
            try {
                setIsLoading(true);
                const response = await Api.get(
                    `resources/access/${resourceType}/${resourceId}`
                );
                setAccessList(response.data.data);
                setIsLoading(false);
            } catch (e) {
                setIsLoading(false);
            }
        };
        fetch();
    }, [resourceType, resourceId, forceUpdate]);

    const openDelete = (access) => {
        setShowDelete(true);
        setSelectedAccess(access);
    };

    const OptionsDropdown = ({ access }) => {
        return (
            <span className="block w-full">
                <Dropdown
                    position="right"
                    toggleContent={
                        <>
                            <div className="py-1 px-3 w-full block">
                                <FontAwesomeIcon icon={faEllipsisV} />
                            </div>
                        </>
                    }
                >
                    <DropdownItem
                        label="Revoke"
                        icon={faTrash}
                        handleClick={() => openDelete(access)}
                    />
                </Dropdown>
            </span>
        );
    };

    return (
        <>
            <Panel className="mt-8" allowOverflow={true}>
                <PanelHeader title="Assigned To Client">
                    <Button
                        height={null}
                        appearance={"link"}
                        onClick={() => setShowAdd(true)}
                    >
                        <FontAwesomeIcon
                            icon={faPlus}
                            className="text-xs mr-1"
                        />{" "}
                        <span>Add Access</span>
                    </Button>
                </PanelHeader>
                <table className="w-full text-left">
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Date Granted</th>
                            <th>Granted By</th>
                            <th className="text-right">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {accessList.map((access, idx) => (
                            <tr key={idx}>
                                <td>{access.user_full_name}</td>
                                <td>{formatDate(access.created_at)}</td>
                                <td>{access.grantor_full_name}</td>
                                <td className="text-right">
                                    <OptionsDropdown access={access} />
                                </td>
                            </tr>
                        ))}
                        {!isLoading && accessList.length === 0 && (
                            <EmptyTableRow colSpan="5">
                                No Clients Have Access
                            </EmptyTableRow>
                        )}
                    </tbody>
                </table>
                {isLoading && accessList.length === 0 && (
                    <div className="w-full h-full flex items-center justify-center">
                        <Spinner />
                    </div>
                )}
                <ModalRevokeAccess
                    access={selectedAccess}
                    show={showDelete}
                    setShow={setShowDelete}
                    onResult={() => {
                        setForceUpdate(forceUpdate + 1);
                    }}
                />
                <ModalAddAccess
                    resourceId={resourceId}
                    type={resourceType}
                    show={showAdd}
                    setShow={setShowAdd}
                    onResult={() => {
                        setForceUpdate(forceUpdate + 1);
                    }}
                />
            </Panel>
        </>
    );
}
