import React from "react";
import { Switch, Route } from "react-router-dom";
import BusinessInterestIndex from "./BusinessInterestIndex";
import AddBusinessInterest from "./AddBusinessInterest";
import EditBusinessInterest from "./EditBusinessInterest";

export default function BusinessInterest(parentProps) {
    return (
        <Switch>
            <Route
                exact
                path={[
                    "/clients/:clientId/business-interests",
                    "/client/business-interests",
                ]}
                render={(props) => (
                    <BusinessInterestIndex {...parentProps} {...props} />
                )}
            />
            <Route
                exact
                path={[
                    "/clients/:clientId/business-interests/add",
                    "/client/business-interests/add",
                ]}
                render={(props) => (
                    <AddBusinessInterest {...parentProps} {...props} />
                )}
            />
            <Route
                path={[
                    "/clients/:clientId/business-interests/:businessInterestId/edit",
                    "/client/business-interests/:businessInterestId/edit",
                ]}
                render={(props) => (
                    <EditBusinessInterest {...parentProps} {...props} />
                )}
            />
            <Route
                path={[
                    "/clients/:clientId/business-interests/:businessInterestId/view",
                    "/client/business-interests/:businessInterestId/view",
                ]}
                render={(props) => (
                    <EditBusinessInterest
                        readOnly={true}
                        {...parentProps}
                        {...props}
                    />
                )}
            />
        </Switch>
    );
}
