import React from "react";
import useClient from "hooks/useClient";
import RelationshipLayout from "./RelationshipLayout";
import VaultPhotosList from "views/resources/VaultPhotosList";

export default function RelationshipPhotos({
    relationshipId,
    relationshipInput,
    readOnly = false,
    currentBasePath,
}) {
    const { client } = useClient();

    return (
        <RelationshipLayout
            relationshipInput={relationshipInput}
            readOnly={readOnly}
            currentBasePath={currentBasePath}
        >
            <VaultPhotosList
                client={client}
                resourceType={"Relationship"}
                resourceId={relationshipId}
                panelClass=""
            />
        </RelationshipLayout>
    );
}
