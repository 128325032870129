import React, {
    useState,
    useCallback,
    useReducer,
    useContext,
    useEffect,
} from "react";
import { AuthContext } from "contexts/AuthContext";
import useClient from "../../hooks/useClient";
import Api from "../../services/Api";
import { useToasts } from "react-toast-notifications";
import useBudgetItems from "../../hooks/useBudgetItems";
import useBudgetCategories from "../../hooks/useBudgetCategories";
import useFrequency from "../../hooks/useFrequency";
import useBudget from "hooks/useBudget";

export const BudgetContext = React.createContext({});

export default function BudgetContextProvider({ children }) {
    const authContext = useContext(AuthContext);

    const { addToast } = useToasts();

    const { client } = useClient();

    let hasSpouse = false;
    let clientName = client.full_name || "Client";
    let spouseName = "Spouse";

    if (client.spouse) {
        hasSpouse = true;
        if (authContext.user.role === "client_spouse") {
            clientName = client.spouse.full_name;
            spouseName = client.full_name;
        } else {
            clientName = client.full_name;
            spouseName = client.spouse.full_name;
        }
    }

    const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [budgetClientItems, setBudgetClientItems] = useState({});
    const [showModal, setShowModal] = useState(false);
    const [currentBudgetItem, setCurrentBudgetItem] = useState({});
    const [options, setOptions] = useState([]);

    const [newItem, setNewItem] = useReducer(
        (state, newState) => ({ ...state, ...newState }),
        {
            name: "",
            client_value: "",
            spouse_value: "",
            joint_value: "",
        }
    );

    const updateValue = useCallback(
        (key, field, value) => {
            let item = {
                id: null,
                client_id: client.id,
                budget_category_item_id: key,
                name: "",
                frequency_id: "",
                client_value: "",
                spouse_value: "",
                joint_value: "",
                other_value: "",
            };
            if (key in budgetClientItems) {
                item = budgetClientItems[key];
            }
            setBudgetClientItems({
                ...budgetClientItems,
                [key]: { ...item, [field]: value },
            });
            setHasUnsavedChanges(true);
        },
        [budgetClientItems, client.id]
    );

    const getValue = useCallback(
        (key, field) => {
            let defaultVal = "";
            if (field === "frequency_id") defaultVal = "1";
            if (budgetClientItems && key in budgetClientItems) {
                return budgetClientItems[key][field];
            }
            return defaultVal;
        },
        [budgetClientItems]
    );

    const { data: budget, isLoading: isLoadingBudget } = useBudget({
        clientId: client.id,
    });

    const {
        data: remoteBudgetClientItems,
        isSuccess: remoteBudgetItemsDownloaded,
        isLoading: isLoadingBudgetItems,
    } = useBudgetItems({
        clientId: client.id,
    });

    const {
        data: budgetCategories,
        isLoading: isLoadingBudgetCategories,
        refetch: refetchCategories,
    } = useBudgetCategories({
        clientId: client.id,
    });

    const {
        data: frequencyOptions,
        isLoading: loadingOptions,
        isSuccess: hasFrequencyOptions,
    } = useFrequency();

    useEffect(() => {
        if (hasFrequencyOptions) {
            const exclude = ["Daily"];
            setOptions(
                frequencyOptions.filter(
                    (f) => exclude.includes(f.label) === false
                )
            );
        }
    }, [hasFrequencyOptions]);

    const loading =
        isLoadingBudgetItems || isLoadingBudgetCategories || isLoadingBudget;

    useEffect(() => {
        if (remoteBudgetItemsDownloaded) {
            setBudgetClientItems(remoteBudgetClientItems);
        }
    }, [remoteBudgetClientItems, remoteBudgetItemsDownloaded]);

    const isNegative = useCallback((val) => {
        const num = parseFloat(val);
        return num < 0;
    }, []);

    const update = useCallback(
        async (e) => {
            e.preventDefault();
            try {
                setIsSaving(true);
                const body = { ...budgetClientItems };
                let response = await Api.post(
                    "clients/" + client.id + "/budget-client-items",
                    body
                );
                addToast(response.data.message);
                setIsSaving(false);
                setHasUnsavedChanges(false);
            } catch (e) {
                setIsSaving(false);
                addToast(e.response.data.message, { type: "error" });
                console.log(e);
            }
        },
        [budgetClientItems, client.id, addToast]
    );

    const destroy = useCallback(
        async (id, e) => {
            e.preventDefault();
            try {
                setIsSaving(true);
                let response = await Api.delete(
                    "clients/" + client.id + "/budget-client-items/" + id
                );
                addToast(response.data.message);
                setIsSaving(false);

                refetchCategories();
            } catch (e) {
                setIsSaving(false);
                addToast(e.response.data.message, { type: "error" });
                console.log(e);
            }
        },
        [client.id, addToast, budgetClientItems, setBudgetClientItems]
    );

    return (
        <BudgetContext.Provider
            value={{
                clientName,
                hasSpouse,
                spouseName,
                updateValue,
                getValue,
                loading,
                budget,
                budgetClientItems,
                setBudgetClientItems,
                budgetCategories,
                options,
                loadingOptions,
                isSaving,
                setIsSaving,
                hasUnsavedChanges,
                setHasUnsavedChanges,
                showModal,
                setShowModal,
                newItem,
                setNewItem,
                currentBudgetItem,
                setCurrentBudgetItem,
                isNegative,
                update,
                destroy,
            }}
        >
            {children}
        </BudgetContext.Provider>
    );
}
