import Api from "./Api";

export const fetchBudget = async function ({ queryKey }) {
    const [, { ...params }] = queryKey;
    return await Api.get(
        "clients/" + params.clientId + "/budgets",
        params
    ).then((res) => res.data);
};

export const fetchBudgetItems = async function ({ queryKey }) {
    const [, { ...params }] = queryKey;
    return await Api.get(
        "clients/" + params.clientId + "/budget-client-items",
        params
    ).then((res) =>
        res.data.reduce(function (map, obj) {
            map[obj.budget_category_item_id] = obj;
            return map;
        }, {})
    );
};

export const fetchBudgetCategories = async function ({ queryKey }) {
    const [, { ...params }] = queryKey;
    return await Api.get(
        "clients/" + params.clientId + "/budget-categories",
        params
    ).then((res) => res.data);
};

export default {
    fetchBudgetItems,
    fetchBudgetCategories,
};
