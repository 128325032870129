// This component is used to dynamically display the proper ownership select menu depending on the ownership type.

import React, { useState, useEffect } from "react";
import SelectInput from "../../SelectInput";
import TextInput from "../../TextInput";

export function OwnershipOptions({
    index,
    client,
    type,
    ownerType,
    ownerId,
    description,
    assetOptions,
    updateOption,
    updateDescription,
    errors,
    ...props
}) {
    switch (type) {
        case "CP": // Community Property
        case "TBE": // Tenants-by-Entireties
        case "JT": // Joint with Spouse
            return (
                <JointWithSpouseOptions
                    index={index}
                    name="owner_id"
                    client={client}
                    onChange={updateOption}
                    ownerType={ownerType}
                    ownerId={ownerId}
                    errors={errors}
                    {...props}
                />
            );
        case "JTO": // Joint w/Other
            return (
                <JointWithOtherOptions
                    index={index}
                    name="owner_id"
                    client={client}
                    ownerType={ownerType}
                    ownerId={ownerId}
                    description={description}
                    assetOptions={assetOptions}
                    onChange={updateOption}
                    updateDescription={updateDescription}
                    errors={errors}
                    {...props}
                />
            );
        case "CJTO": // Client Joint w/Other
            return (
                <JointWithOtherOptions
                    index={index}
                    name="owner_id"
                    client={client}
                    ownerType={ownerType}
                    ownerId={ownerId}
                    description={description}
                    assetOptions={assetOptions}
                    onChange={updateOption}
                    updateDescription={updateDescription}
                    showSpouse={true}
                    errors={errors}
                    {...props}
                />
            );
        case "SJTO": // Client Joint w/Other
            return (
                <JointWithOtherOptions
                    index={index}
                    name="owner_id"
                    client={client}
                    ownerType={ownerType}
                    ownerId={ownerId}
                    description={description}
                    assetOptions={assetOptions}
                    onChange={updateOption}
                    updateDescription={updateDescription}
                    showClient={true}
                    errors={errors}
                    {...props}
                />
            );
        case "OWNER": // Owner
            return (
                <OwnerOptions
                    index={index}
                    name="owner_id"
                    client={client}
                    ownerType={ownerType}
                    ownerId={ownerId}
                    assetOptions={assetOptions}
                    onChange={updateOption}
                    errors={errors}
                    {...props}
                />
            );
        case "ADD_OWNER": // Additional Owner
        case "TCO":
            return (
                <AdditionalOwnerOptions
                    index={index}
                    name="owner_id"
                    client={client}
                    ownerType={ownerType}
                    ownerId={ownerId}
                    description={description}
                    assetOptions={assetOptions}
                    onChange={updateOption}
                    updateDescription={updateDescription}
                    errors={errors}
                    {...props}
                />
            );
        case "OTH": // Other
            return (
                <OtherOptions
                    index={index}
                    name="ownership_option"
                    client={client}
                    description={description}
                    onChange={updateDescription}
                    errors={errors}
                    {...props}
                />
            );
        default:
            return <></>;
    }
}

export function JointWithSpouseOptions({
    client,
    onChange,
    errors,
    index,
    ...props
}) {
    return (
        <SelectInput
            name="owner_id"
            label={props.label}
            value={client.id}
            disabled={props.disabled}
            options={[
                {
                    value: client.id,
                    label: `${client.full_name} & ${
                        client.spouse ? client.spouse.full_name : "Spouse"
                    }`,
                },
            ]}
            onChange={onChange}
            error={errors && errors["ownerships." + index + ".owner_id"]}
        />
    );
}

export function OwnerOptions({
    client,
    onChange,
    ownerType,
    ownerId,
    assetOptions,
    errors,
    index,
    ...props
}) {
    let clientOptions = [
        {
            value: `Client:${client.id}`,
            label: `${client.full_name} (Client)`,
        },
    ];

    if (client.spouse) {
        clientOptions.push({
            value: `Spouse:${client.spouse.id}`,
            label: `${client.spouse.full_name} (Spouse)`,
        });
    }

    return (
        <SelectInput
            name="owner_id"
            label={props.label}
            value={ownerType + ":" + ownerId}
            disabled={props.disabled}
            options={clientOptions.concat(assetOptions)}
            onChange={onChange}
            error={errors && errors["ownerships." + index + ".owner_id"]}
        />
    );
}

export function AdditionalOwnerOptions({
    client,
    onChange,
    ownerType,
    description,
    ownerId,
    assetOptions,
    updateDescription,
    errors,
    index,
    ...props
}) {
    const [clientOptions, setClientOptions] = useState([]);
    useEffect(() => {
        let clientOptions = [
            {
                value: `Client:${client.id}`,
                label: `${client.full_name} (Client)`,
            },
        ];
        if (client.spouse) {
            clientOptions.push({
                value: `Spouse:${client.spouse.id}`,
                label: `${client.spouse.full_name} (Spouse)`,
            });
        }
        setClientOptions(clientOptions);
    }, [client]);

    return (
        <div className="flex-1 flex space-x-3 items-end">
            <div className="flex-1">
                <SelectInput
                    name="owner_id"
                    label={props.label}
                    value={
                        parseInt(ownerId) >= 0 ? ownerType + ":" + ownerId : -1
                    }
                    disabled={props.disabled}
                    defaultOption={"Choose an option..."}
                    defaultOptionDisabled={true}
                    defaultOptionValue={-1}
                    options={clientOptions
                        .concat(assetOptions)
                        .concat({ value: "OTH:0", label: "Other" })}
                    onChange={onChange}
                    error={
                        errors && errors["ownerships." + index + ".owner_id"]
                    }
                />
            </div>
            {ownerType === "OTH" && (
                <div className="flex-1">
                    <TextInput
                        name="owner_description"
                        value={description}
                        disabled={props.disabled}
                        placeholder="Other owner name"
                        onChange={updateDescription}
                        error={
                            errors &&
                            errors["ownerships." + index + ".owner_description"]
                        }
                    />
                </div>
            )}
        </div>
    );
}

export function JointWithOtherOptions({
    onChange,
    ownerType,
    ownerId,
    client,
    description,
    assetOptions,
    updateDescription,
    showClient = false,
    showSpouse = false,
    index,
    errors,
    ...props
}) {
    let options = assetOptions.concat({
        value: "OTH:0",
        label: "Other",
    });

    if (showClient === true) {
        options = [
            {
                value: `Client:${client.id}`,
                label: `${client.full_name} (Client)`,
            },
        ].concat(options);
    }

    if (showSpouse === true && client.spouse) {
        options = [
            {
                value: `Spouse:${client.spouse.id}`,
                label: `${client.spouse.full_name} (Spouse)`,
            },
        ].concat(options);
    }

    return (
        <div className="flex-1 flex space-x-3">
            <div className="flex-1">
                <SelectInput
                    name="owner_id"
                    label={props.label}
                    value={
                        parseInt(ownerId) >= 0 ? ownerType + ":" + ownerId : -1
                    }
                    disabled={props.disabled}
                    defaultOption={"Choose an option..."}
                    defaultOptionDisabled={true}
                    defaultOptionValue={-1}
                    options={options}
                    onChange={onChange}
                    error={
                        errors && errors["ownerships." + index + ".owner_id"]
                    }
                />
            </div>
            {ownerType === "OTH" && (
                <div className="flex-2">
                    <TextInput
                        name="owner_description"
                        label="Ownership Description"
                        value={description}
                        disabled={props.disabled}
                        placeholder="Other owner name"
                        onChange={updateDescription}
                        error={
                            errors &&
                            errors["ownerships." + index + ".owner_description"]
                        }
                    />
                </div>
            )}
        </div>
    );
}

export function OtherOptions({
    onChange,
    description,
    errors,
    index,
    ...props
}) {
    return (
        <TextInput
            name="owner_description"
            label={props.label}
            value={description}
            disabled={props.disabled}
            placeholder="Enter other owner description"
            onChange={onChange}
            error={
                errors && errors["ownerships." + index + ".owner_description"]
            }
        />
    );
}
