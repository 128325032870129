import React, { useState } from "react";
import TextInput from "../../../components/TextInput";
import Checkbox from "../../../components/Checkbox";

export default function InlineBudgetItem({
    budgetItem,
    updateValue,
    readOnly = false,
}) {
    const [item, setItem] = useState(budgetItem);

    return (
        <div className="flex space-x-3 items-center">
            <div className="flex-1 items-center">
                <TextInput
                    name={`${item.id}_name`}
                    value={item.name}
                    disabled={readOnly}
                    onChange={(e) => {
                        setItem({ ...item, name: e.target.value });
                    }}
                    onBlur={(e) => {
                        e.preventDefault();
                        updateValue(
                            item.budget_category_id,
                            item.id,
                            "name",
                            e.target.value
                        );
                    }}
                />
            </div>
            <div>
                <Checkbox
                    name={`${item.id}_has_joint_values`}
                    id="has_joint_values"
                    value={1}
                    disabled={readOnly}
                    onChange={(e) => {
                        updateValue(
                            item.budget_category_id,
                            item.id,
                            "has_joint_values",
                            e.target.value
                        );
                    }}
                    label="Has Joint Values?"
                    defaultChecked={parseInt(item.has_joint_values) === 1}
                />
            </div>
        </div>
    );
}
