import React from "react";
import { RegistrationContext } from "../contexts/RegistrationContext";
export default function useRegistrationContext() {
    const context = React.useContext(RegistrationContext);
    if (context === undefined) {
        throw new Error(
            "useRegistrationContext must be used within a RegistrationContextProvider"
        );
    }

    return { ...context };
}
