import React, { useState, useCallback, useEffect } from "react";
import Api from "../../services/Api";
import Modal from "../../components/Modal";
import FilePicker from "../clients/Vault/_modals/FilePicker";
import EditPhoto from "../_partials/EditPhoto";
import Tabs from "../../components/Tabs";
import { useDropzone } from "react-dropzone";
import { useToasts } from "react-toast-notifications";
import InputErrors from "../../components/InputErrors";
import { uploadFileToVault } from "../../services/VaultService";
// import { isFileImage } from '../clients/Vault/helpers'
import { processFormErrors } from "../../util/FormsHelper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserCircle } from "@fortawesome/free-solid-svg-icons";
import { titleCase } from "../../util/TextUtil";

export default function ModalVaultUploadPhoto({
    client,
    primaryPhoto,
    photoType = "profile",
    photoUrl,
    restrictToUpload = false,
    isOpen,
    onResult,
    resourceType,
    resourceId,
}) {
    const [isLoading, setIsLoading] = useState(false);
    const [errors, setErrors] = useState([]);
    const [file, setFile] = useState(false);
    const { addToast } = useToasts();

    const [showPickerTab, setShowPickerTab] = useState(true);

    const onDrop = useCallback((acceptedFiles) => {
        let file = acceptedFiles[0];
        if (file) {
            setFile(file);
            setErrors([]);
        }
    }, []);

    useEffect(() => {
        setShowPickerTab(restrictToUpload ? false : true);
    }, [restrictToUpload, isOpen]);

    const { getRootProps, getInputProps } = useDropzone({ onDrop });

    const linkFile = async ({ id }) => {
        setErrors([]);
        try {
            setIsLoading(true);
            const response = await Api.post(`resources/relations`, {
                type: resourceType,
                resource_id: resourceId,
                is_primary_photo: true,
                vault_id: id,
            });
            addToast(`${response.data.message}`, { type: "success" });
            setIsLoading(false);
            reset();
            onResult(true);
            return true;
        } catch (e) {
            setIsLoading(false);
            console.error(e);
            setErrors(processFormErrors(e));
        }
    };

    const onSaveAndLinkPhoto = async (acceptedFiles) => {
        let file = acceptedFiles[0];
        console.log(file);
        if (file) {
            let pieces = file ? file.name.split(".") : [];
            let extension = pieces.length > 1 ? pieces.pop() : "";
            const nameWithoutExtension = file.name.replace("." + extension, "");

            if (file.size > Math.pow(1024, 2) * 50) {
                setErrors({ size: ["Size cannot exceed 50 MB."] });
            }

            extension = extension.toLowerCase();
            // const isImage = isFileImage({extension, type: file.type});

            // TODO:
            const fileDescription = "";
            const parentId = null;

            try {
                setErrors([]);
                setIsLoading(1);
                setIsLoading(true);
                const res = await uploadFileToVault({
                    client,
                    nameWithoutExtension,
                    extension,
                    description: fileDescription,
                    file: file,
                    parentId,
                    onProgress: (percentCompleted) => {
                        setIsLoading(percentCompleted);
                    },
                });

                await linkFile({ id: res.data.vault.id });
            } catch (e) {
                setIsLoading(false);
                console.error(e);
                setErrors(processFormErrors(e));
            }
        }
    };

    const reset = () => {
        setIsLoading(false);
        setErrors([]);
        setFile(false);
        setShowPickerTab(true);
    };

    return (
        <Modal
            isOpen={isOpen}
            title={`${titleCase(resourceType, { pascal: true })} Photo`}
            showButtons={false}
            onResult={() => {
                reset();
                onResult(false);
            }}
        >
            {!restrictToUpload && (
                <div className={"mt-4"}>
                    <Tabs
                        selectedIndex={showPickerTab ? 0 : 1}
                        items={[
                            {
                                label: "Choose from Vault",
                                onClick: () => {
                                    setShowPickerTab(true);
                                    setErrors([]);
                                },
                            },
                            {
                                label: "Upload Photo",
                                onClick: () => {
                                    setShowPickerTab(false);
                                    setErrors([]);
                                },
                            },
                        ]}
                    />
                </div>
            )}

            {errors && <InputErrors errors={errors} />}

            {/* Empty photo upload */}
            {!showPickerTab && !primaryPhoto && !file && (
                <>
                    <div
                        className={`flex items-center justify-center relative z-auto cursor-pointer}`}
                    >
                        <div
                            {...getRootProps({
                                accept: "image/jpeg, image/png",
                            })}
                            className="text-center"
                        >
                            <input {...getInputProps({ disabled: false })} />
                            {photoUrl ? (
                                <img
                                    src={`photoUrl`}
                                    className="object-cover h-48 w-48 rounded-full shadow-inner"
                                    alt="Member"
                                />
                            ) : (
                                <div
                                    className={`flex items-center justify-center text-center bg-gray-100 'hover:bg-gray-200 hover:border-gray-400  border-2 border-dashed h-56 w-56 p-2`}
                                >
                                    <div>
                                        <FontAwesomeIcon
                                            icon={faUserCircle}
                                            size="8x"
                                            className="text-gray-400"
                                        />
                                        <p className="mt-2 text-gray-400 text-sm">{`+ Add ${photoType} photo`}</p>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </>
            )}

            {!showPickerTab && (primaryPhoto || file) && (
                <EditPhoto
                    file={file}
                    url={photoUrl}
                    isParentLoading={isLoading}
                    onResult={async (result) => {
                        if (!result || !result.result) {
                            reset();
                            onResult(false);
                            return;
                        }
                        await onSaveAndLinkPhoto([result.result]);
                    }}
                />
            )}

            {showPickerTab && (
                <FilePicker
                    client={client}
                    fileType={"Photo"}
                    mimeTypeFilter={"image"}
                    isLoading={isLoading}
                    onResult={async (result) => {
                        if (typeof result.result !== "string") {
                            onResult(false);
                            return;
                        }
                        setIsLoading(true);
                        if (!(await linkFile(result.parent))) {
                            onResult(false);
                        }
                    }}
                />
            )}
        </Modal>
    );
}
