import React, { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import Api from "../../../services/Api";
import Modal from "../../../components/Modal";
import { faUserCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useToasts } from "react-toast-notifications";
import EditPhoto from "../../_partials/EditPhoto";

export default function ProfilePhoto({
    memberId,
    photo,
    readOnly = false,
    upload,
    postUrl = null,
}) {
    const { addToast } = useToasts();
    const [hover, setHover] = useState(false);
    const [file, setFile] = useState(false);
    const [showEditPhoto, setShowEditPhoto] = useState(false);

    const onDrop = useCallback((acceptedFiles) => {
        let file = acceptedFiles[0];
        if (file) {
            setFile(file);
            setShowEditPhoto(true);
            setHover(false);
        }
    }, []);

    const onSavePhoto = (acceptedFiles) => {
        let file = acceptedFiles[0];
        if (file) {
            setFile(file);
            const data = new FormData();
            data.append("file", file, file.name);
            const headers = {
                headers: { "content-type": "multipart/form-data" },
            };
            setHover(false);

            const defaultUrl = "members/" + memberId + "/photos";

            Api.post(postUrl || defaultUrl, data, headers)
                .then((response) => upload(response.data))
                .catch((error) => {
                    if (
                        error.response.data.errors &&
                        error.response.data.errors.file[0]
                    ) {
                        addToast(error.response.data.errors.file[0], {
                            type: "error",
                        });
                    }
                });
        }
    };

    const clearPhoto = () => {
        const defaultUrl = "members/" + memberId + "/photos";
        Api.delete(postUrl || defaultUrl)
            .then((response) => upload(response.data))
            .catch((error) => {
                if (error.response.data.message) {
                    addToast(error.response.data.message, {
                        type: "error",
                    });
                }
            });
        setHover(false);
    };

    const { getRootProps, getInputProps } = useDropzone({ onDrop });

    return (
        <>
            <div
                className={`relative z-auto ${
                    showEditPhoto || readOnly ? "" : "cursor-pointer"
                }`}
                onMouseOver={() => setHover(true)}
                onMouseLeave={() => setHover(false)}
            >
                <div
                    {...getRootProps({ accept: "image/jpeg, image/png" })}
                    className="text-center"
                >
                    <div className="text-sm mb-2 -mt-1">Profile Image</div>
                    {readOnly === false && <input {...getInputProps()} />}
                    {photo ? (
                        <img
                            src={`${photo}?w=${4 * 56}`}
                            className="object-cover h-48 w-48 rounded-full shadow-inner"
                            alt="Member"
                        />
                    ) : (
                        <div
                            className={`flex items-center justify-center text-center bg-gray-100 ${
                                readOnly === false
                                    ? "hover:bg-gray-200 hover:border-gray-400"
                                    : ""
                            }  border-2 border-dashed h-56 w-56 p-2`}
                        >
                            <div>
                                <FontAwesomeIcon
                                    icon={faUserCircle}
                                    size="8x"
                                    className="text-gray-400"
                                />
                                <p className="mt-2 text-gray-400 text-sm">
                                    {readOnly === false
                                        ? "+ Add profile photo"
                                        : "No Profile Photo"}
                                </p>
                            </div>
                        </div>
                    )}
                </div>
                {photo && hover && readOnly === false && (
                    <>
                        <div className="absolute top-0 right-0 bottom-0 left-0 flex flex-col space-y-4 items-center justify-center">
                            <button
                                type="button"
                                className="text-center w-32 flex-0 py-1 px-2 shadow-sm bg-brand-600 text-white hover:bg-brand-700 bg-opacity-75 rounded-sm text-sm"
                                onClick={() => setShowEditPhoto(true)}
                            >
                                Edit
                            </button>

                            <button
                                type="button"
                                className="text-center w-32 flex-0 py-1 px-2 shadow-sm bg-brand text-white hover:bg-brand-700 bg-opacity-75 rounded-sm text-sm"
                                onClick={clearPhoto}
                            >
                                Remove
                            </button>
                        </div>
                    </>
                )}

                {readOnly === false && (
                    <Modal
                        component={EditPhoto}
                        file={file}
                        title="Edit Profile Photo"
                        url={photo}
                        isOpen={showEditPhoto}
                        onResult={({ result }) => {
                            setShowEditPhoto(false);
                            setHover(false);
                            if (result) {
                                onSavePhoto([result]);
                            }
                        }}
                    />
                )}
            </div>
            {photo && readOnly === false && (
                <button
                    type="button"
                    className="text-sm mt-3 text-brand-500 block text-center w-full"
                    onClick={() => setShowEditPhoto(true)}
                >
                    Edit
                </button>
            )}
        </>
    );
}
