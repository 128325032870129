import React from "react";
import { Link } from "react-router-dom";
import useHasPermission from "hooks/usePermissions";

const inactiveClass =
    "group flex items-center px-3 py-2 text-sm leading-5 font-medium text-gray-600 rounded-md hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:bg-gray-100 transition ease-in-out duration-150";
const activeClass =
    "group flex items-center px-3 py-2 text-sm leading-5 font-medium text-gray-900 rounded-md bg-gray-200 hover:text-gray-900 hover:bg-gray-200 focus:outline-none focus:bg-gray-200 transition ease-in-out duration-150";

export default function OrganizationNav({ active }) {
    const sideNavItem = (to, label, active) => {
        return (
            <Link
                to={to}
                className={active.includes(to) ? activeClass : inactiveClass}
            >
                <span className="truncate">{label}</span>
            </Link>
        );
    };

    return (
        <nav className="w-56">
            {useHasPermission("firm_information_access") &&
                sideNavItem(
                    "/organization/details",
                    "Organization Details",
                    active
                )}

            {useHasPermission("firm_information_access") &&
                sideNavItem(
                    "/organization/billing",
                    "Subscription & Billing",
                    active
                )}
            {useHasPermission("firm_information_access") &&
                sideNavItem(
                    "/organization/message-templates",
                    "Message Templates",
                    active
                )}
            {useHasPermission("firm_information_access") &&
                sideNavItem(
                    "/organization/default-permissions",
                    "Default Permissions",
                    active
                )}
            {useHasPermission("firm_information_access") &&
                sideNavItem(
                    "/organization/export",
                    "Export Organization Data",
                    active
                )}
        </nav>
    );
}
