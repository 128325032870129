import React, { useState } from "react";
import Api from "../../services/Api";
import { useToasts } from "react-toast-notifications";
import SaveButton from "../../components/SaveButton";
import TextInput from "../../components/TextInput";
import { useHistory } from "react-router-dom";
import SelectInput from "../../components/SelectInput";

export const statusOptions = [
    { value: "public", label: "Public" },
    { value: "private", label: "Private" },
    { value: "draft", label: "Draft" },
];

export default function AddQuestionnaire({ member }) {
    const history = useHistory();
    const { addToast } = useToasts();
    const [errors, setErrors] = useState([]);
    const [title, setTitle] = useState("");
    const [status, setStatus] = useState("public");

    async function store(e) {
        e.preventDefault();
        try {
            let response = await Api.post(
                "members/" + member.id + "/questionnaires",
                {
                    title,
                    status,
                }
            );
            addToast(response.data.message);
            setErrors([]);
            if (response.data.data.id) {
                history.push(
                    `/members/${member.id}/questionnaires/${response.data.data.id}/view`
                );
            }
        } catch (e) {
            setErrors([]);
            if (e.response.data.errors) {
                setErrors(e.response.data.errors);
            } else {
                addToast(e.response.data.message, { type: "error" });
            }
        }
    }

    return (
        <form onSubmit={(e) => store(e)} className="space-y-6">
            <TextInput
                name="title"
                label="Questionnaire Title"
                id="title"
                value={title}
                error={errors.title}
                onChange={(e) => setTitle(e.target.value)}
                required={true}
                autoFocus={true}
            />
            <SelectInput
                name="status"
                label="Status"
                id="status"
                options={statusOptions}
                value={status}
                error={errors.status}
                onChange={(e) => setStatus(e.target.value)}
                required={true}
            />
            <input type="submit" hidden />
            <div className="w-48 mt-6 ml-auto">
                <SaveButton type="submit" />
            </div>
        </form>
    );
}
