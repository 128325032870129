import React from "react";
import ClientPageHeader, { Heading } from "../../components/ClientPageHeader";
import PageContainer from "../../components/PageContainer";
import { Link } from "react-router-dom";
import useHasPermission from "hooks/usePermissions";

export const listOfReports = [
    {
        path: "reports/livewealth",
        title: "Wealth Report",
        description: "Displays net worth and estate net worth",
        permission_key: "report_wealth_report",
    },
    {
        path: "reports/list-of-changes",
        title: "List of Changes Report",
        description: "Displays the changes that have occurred for a client",
        permission_key: "report_list_of_changes",
    },
    {
        path: "reports/list-of-gifts",
        title: "List of Gifts Report",
        description: "Displays the gifts that have occurred for a client",
        permission_key: "report_list_of_gifts",
    },
    {
        path: "beneficiaries",
        title: "Beneficiary Report",
        description:
            "Displays a listing of all assets and current beneficiaries",
        permission_key: "report_beneficiary_report",
    },
    {
        path: "leases",
        title: "Leases Report",
        description: "Displays the leases owned by a client",
        permission_key: "report_leases_report",
    },
    {
        path: "income-sources",
        title: "Income Sources Report",
        description: "Displays the income sources for a client",
        permission_key: "report_income_sources_report",
    },
    {
        path: "other-insurance-policies",
        title: "Other Insurance Policies Report",
        description:
            "Displays all other insurance policies policies for a client",
        permission_key: "report_other_insurance_policies_report",
    },
    {
        path: "disability-policies",
        title: "Disability/LTC Policies Report",
        description:
            "Displays the disability or long-term care policies for a client",
        permission_key: "report_disability_ltc_policies_report",
    },
    {
        path: "reports/lifetime-transferees",
        title: "Lifetime Transferees",
        description: "Displays the lifetime transferees for a client",
        permission_key: "report_lifetime_transferees",
    },
    {
        path: "reports/estate-transferees",
        title: "Estate Transferees",
        description: "Displays the estate transferees for a client",
        permission_key: "report_estate_transferees",
    },
    {
        path: "reports/estate-inventory",
        title: "Estate Inventory Report",
        description: "Displays Estate inventory report for a client",
        permission_key: "report_estate_valuation_report",
    },
    {
        path: "reports/beneficiary-claims",
        title: "Beneficiary Claims Form Report",
        description:
            "Displays the status of all Beneficiary Claims Forms for a client",
        permission_key: "report_beneficiary_claims_report",
    },
    {
        path: "relationships",
        title: "Personal Info Report",
        description:
            "Displays the the client's personal info report or blank report.",
        permission_key: "report_personal_info_report",
    },
];

export default function ReportsIndex({ client, clientPath }) {
    return (
        <>
            <ClientPageHeader client={client}>
                <Heading>Reports</Heading>
            </ClientPageHeader>
            <PageContainer>
                <table className="w-full text-left">
                    <thead>
                        <tr>
                            <th className="w-1/3">Report</th>
                            <th>Description</th>
                        </tr>
                    </thead>
                    <tbody>
                        {listOfReports
                            .filter((report) => {
                                return useHasPermission(report.permission_key);
                            })
                            .map((report, idx) => (
                                <tr key={idx}>
                                    <td>
                                        <Link
                                            to={`${clientPath}/${report.path}`}
                                            className="underline"
                                        >
                                            {report.title}
                                        </Link>
                                    </td>
                                    <td>{report.description}</td>
                                </tr>
                            ))}
                    </tbody>
                </table>
            </PageContainer>
        </>
    );
}
