import React from "react";
import { Switch, Route } from "react-router-dom";
import RealEstateIndex from "./RealEstateIndex";
import AddRealEstate from "./AddRealEstate";
import EditRealEstate from "./EditRealEstate";

export default function RealEstate(parentProps) {
    return (
        <Switch>
            <Route
                exact
                path={["/clients/:clientId/real-estate", "/client/real-estate"]}
                render={(props) => (
                    <RealEstateIndex {...parentProps} {...props} />
                )}
            />
            <Route
                exact
                path={[
                    "/clients/:clientId/real-estate/add",
                    "/client/real-estate/add",
                ]}
                render={(props) => (
                    <AddRealEstate {...parentProps} {...props} />
                )}
            />
            <Route
                path={[
                    "/clients/:clientId/real-estate/:realEstateId/edit",
                    "/client/real-estate/:realEstateId/edit",
                ]}
                render={(props) => (
                    <EditRealEstate {...parentProps} {...props} />
                )}
            />
            <Route
                path={[
                    "/clients/:clientId/real-estate/:realEstateId/view",
                    "/client/real-estate/:realEstateId/view",
                ]}
                render={(props) => (
                    <EditRealEstate
                        readOnly={true}
                        {...parentProps}
                        {...props}
                    />
                )}
            />
        </Switch>
    );
}
