import React from "react";

import ICEAccess from "./ICEGuestAccess";
import ICELogin from "./ICEGuestLogin";
import useIceGuest from "./useIceGuest";

export default function ICEGuestWrapper() {
    const { isLoggedIn } = useIceGuest();
    return <>{isLoggedIn ? <ICEAccess /> : <ICELogin />}</>;
}
