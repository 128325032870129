import React, { useState, useEffect } from "react";
import { Link, Switch, Route } from "react-router-dom";
import Api from "../../services/Api";
import TableSortHeader from "../../components/TableSortHeader";
import { formatDate } from "../../util/TextUtil";
import PageHeader from "../../components/PageHeader";
import PageContainer from "../../components/PageContainer";
import Spinner from "../../components/Spinner";
import DashboardLayout from "../layouts/DashboardLayout";
import EditCollaboratingAdvisor from "./EditCollaboratingAdvisor";

export default function CollaboratingAdvisors({ ...props }) {
    const [loading, setLoading] = useState(true);
    const [orderBy, setOrderBy] = useState("full_name");
    const [sortDir, setSortDir] = useState("asc");
    const [advisors, setAdvisors] = useState([]);

    useEffect(() => {
        document.title = "Collaborating Advisors";
    }, []);

    useEffect(() => {
        async function fetchAdvisors() {
            try {
                setLoading(true);
                let response = await Api.get("collaborating-advisors", {
                    "filter[trashed]": "with",
                });
                setAdvisors(response.data.data);
                setLoading(false);
            } catch (e) {
                if (e.response.status === 401) {
                    alert(e.response.data.message);
                }
            }
        }
        fetchAdvisors();
    }, []);

    if (loading) {
        return (
            <div className="flex h-full items-center">
                <Spinner />
            </div>
        );
    }

    return (
        <DashboardLayout>
            <Switch>
                <Route
                    path={[
                        "/collaborating-advisors/:advisorId/edit",
                        "/collaborating-advisors/:advisorId/edit-permissions",
                        "/collaborating-advisors/:advisorId/client-list",
                    ]}
                    component={EditCollaboratingAdvisor}
                />
                <Route
                    path={props.match.url}
                    render={() => (
                        <>
                            <PageHeader text="Collaborating Advisors" />
                            <PageContainer>
                                <table className="w-full text-left">
                                    <thead>
                                        <tr>
                                            <TableSortHeader
                                                field="first_name"
                                                sortField={orderBy}
                                                sortDir={sortDir}
                                                changeSort={(column) => {
                                                    setSortDir(
                                                        sortDir === "asc"
                                                            ? "desc"
                                                            : "asc"
                                                    );
                                                    setOrderBy(column);
                                                }}
                                            >
                                                Name
                                            </TableSortHeader>
                                            <TableSortHeader
                                                field="email"
                                                sortField={orderBy}
                                                sortDir={sortDir}
                                                changeSort={(column) => {
                                                    setSortDir(
                                                        sortDir === "asc"
                                                            ? "desc"
                                                            : "asc"
                                                    );
                                                    setOrderBy(column);
                                                }}
                                            >
                                                Email
                                            </TableSortHeader>
                                            <TableSortHeader
                                                field="created_at"
                                                sortField={orderBy}
                                                sortDir={sortDir}
                                                changeSort={(column) => {
                                                    setSortDir(
                                                        sortDir === "asc"
                                                            ? "desc"
                                                            : "asc"
                                                    );
                                                    setOrderBy(column);
                                                }}
                                            >
                                                Added
                                            </TableSortHeader>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {advisors.map((advisor, idx) => (
                                            <tr
                                                key={idx}
                                                className={
                                                    advisor.deleted_at ? "" : ""
                                                }
                                            >
                                                <td>
                                                    <Link
                                                        to={`/collaborating-advisors/${advisor.id}/edit`}
                                                    >{`${advisor.full_name}`}</Link>
                                                </td>
                                                <td>{advisor.email}</td>
                                                <td>
                                                    {formatDate(
                                                        advisor.first_created_at
                                                    )}
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </PageContainer>
                        </>
                    )}
                />
            </Switch>
        </DashboardLayout>
    );
}
