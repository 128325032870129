import React from "react";

import useIceGuest from "./useIceGuest";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faPhone } from "@fortawesome/free-solid-svg-icons";

export default function ICEContacts() {
    const { info } = useIceGuest();

    const contacts = info.contacts;

    return (
        <div className="bg-white overflow-hidden shadow rounded-lg divide-y divide-gray-200">
            <div className="px-4 py-5 sm:px-6">
                <div>Emergency Contacts</div>
            </div>
            <div className="px-4 py-5 sm:p-6">
                {contacts.length === 0 && (
                    <div className="py-4 flex items-center justify-center">
                        No Contact Information Available
                    </div>
                )}
                <ul className="grid grid-cols-1 gap-6 lg:grid-cols-2">
                    {contacts.map((person) => (
                        <li
                            key={person.email}
                            className="col-span-1 bg-white rounded-lg shadow divide-y divide-gray-200"
                        >
                            <div className="w-full flex items-center justify-between p-6 space-x-6">
                                <div className="flex-1 truncate">
                                    <div className="flex items-center space-x-3">
                                        <h3 className="text-gray-900 text-sm font-medium truncate">
                                            {person.resource_display_name}
                                        </h3>
                                    </div>
                                </div>
                                <div className="w-10 h-10 bg-gray-500 rounded-full flex-shrink-0 inline-flex items-center justify-center">
                                    <span className="text-xl font-medium leading-none text-white">
                                        {(person.first_name || " ").charAt("0")}
                                        {(person.last_name || " ").charAt("0")}
                                    </span>
                                </div>
                            </div>
                            <div>
                                <div className="-mt-px flex divide-x divide-gray-200">
                                    <div className="w-0 flex-1 flex">
                                        {!!person.email && (
                                            <a
                                                href={`mailto:${person.email}`}
                                                className="relative -mr-px w-0 flex-1 inline-flex items-center justify-center py-4 text-sm text-gray-700 font-medium border border-transparent rounded-bl-lg hover:text-gray-500"
                                            >
                                                <FontAwesomeIcon
                                                    icon={faEnvelope}
                                                    className="w-8 h-8 text-gray-400 flex-none text-lg"
                                                    aria-hidden="true"
                                                />
                                                <div className="flex flex-col space-y-1 ml-2 text-center w-24 break-words">
                                                    <span className="font-bold">
                                                        Email
                                                    </span>
                                                    <span className="text-xs text-wrap">
                                                        {person.email}
                                                    </span>
                                                </div>
                                            </a>
                                        )}
                                    </div>
                                    {!!person.phone && (
                                        <div className="-ml-px w-0 flex-1 flex">
                                            <a
                                                href={`tel:${person.phone}`}
                                                className="relative w-0 flex-1 inline-flex items-center justify-center py-4 text-sm text-gray-700 font-medium border border-transparent rounded-br-lg hover:text-gray-500"
                                            >
                                                <FontAwesomeIcon
                                                    icon={faPhone}
                                                    className="w-8 h-8 text-gray-400 flex-none text-lg"
                                                    aria-hidden="true"
                                                />
                                                <div className="flex flex-col space-y-1 ml-2 text-center">
                                                    <span className="font-bold">
                                                        Home
                                                    </span>
                                                    <span className="text-xs">
                                                        {person.phone}
                                                    </span>
                                                </div>
                                            </a>
                                        </div>
                                    )}
                                    {!!person.mobile_phone && (
                                        <div className="-ml-px w-0 flex-1 flex">
                                            <a
                                                href={`tel:${person.mobile_phone}`}
                                                className="relative w-0 flex-1 inline-flex items-center justify-center py-4 text-sm text-gray-700 font-medium border border-transparent rounded-br-lg hover:text-gray-500"
                                            >
                                                <FontAwesomeIcon
                                                    icon={faPhone}
                                                    className="w-8 h-8 text-gray-400 flex-none text-lg"
                                                    aria-hidden="true"
                                                />
                                                <div className="flex flex-col space-y-1 ml-2 text-center">
                                                    <span className="font-bold">
                                                        Mobile
                                                    </span>
                                                    <span className="text-xs">
                                                        {person.mobile_phone}
                                                    </span>
                                                </div>
                                            </a>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
}
