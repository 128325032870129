import React, { useEffect, useState } from "react";
import useClient from "hooks/useClient";
import RelationshipLayout from "./RelationshipLayout";
import VaultProfilePhoto from "views/resources/VaultProfilePhoto";
import SelectInput from "components/SelectInput";
import Api from "services/Api";
import FriendForm from "./_partials/FriendForm";
import OrganizationForm from "./_partials/OrganizationForm";
import SpouseForm from "./_partials/SpouseForm";
import FamilyForm from "./_partials/FamilyForm";
import { useToasts } from "react-toast-notifications";
import Button from "components/Button";
import useHasAnyPermissions from "hooks/useHasAnyPermissions";

export default function Relationship({
    relationshipId,
    relationshipInput,
    setRelationshipInput,
    clearUnsavedChanges,
    updateValue,
    readOnly = false,
    currentBasePath,
}) {
    const { addToast } = useToasts();
    const { client } = useClient();
    const [errors, setErrors] = useState([]);

    const canEditRelationship = useHasAnyPermissions([
        "firm_client_related_accounts_administration",
        "client_related_accounts_administration",
        "client_wealth_records_global_access",
        "firm_client_wealth_records_global_access",
        "client_wealth_records_editing",
        "auth_party_client_related_accounts_administration",
    ]);

    const displayForm = () => {
        switch (relationshipInput.type) {
            case "friend":
                return (
                    <FriendForm
                        relationshipInput={relationshipInput}
                        errors={errors}
                        onChange={updateValue}
                        clientId={client.id}
                        readOnly={readOnly}
                    />
                );
            case "organization":
                return (
                    <OrganizationForm
                        relationshipInput={relationshipInput}
                        errors={errors}
                        onChange={updateValue}
                        clientId={client.id}
                        readOnly={readOnly}
                    />
                );
            case "spouse":
                return (
                    <SpouseForm
                        relationshipInput={relationshipInput}
                        errors={errors}
                        onChange={updateValue}
                        clientId={client.id}
                        readOnly={readOnly}
                    />
                );
            default:
                return (
                    <FamilyForm
                        relationshipInput={relationshipInput}
                        errors={errors}
                        onChange={updateValue}
                        clientId={client.id}
                        readOnly={readOnly}
                    />
                );
        }
    };

    async function updateRelationship(e) {
        e.preventDefault();
        clearUnsavedChanges();

        relationshipInput["authorized_party"] = null;

        try {
            let response = await Api.put(
                "clients/" + client.id + "/relationships/" + relationshipId,
                relationshipInput
            );
            addToast(response.data.message);
            setRelationshipInput(response.data.data);
            setErrors([]);
        } catch (e) {
            setErrors([]);
            if (e.response.data.errors) {
                setErrors(e.response.data.errors);
            } else {
                addToast(e.response.data.message, { type: "error" });
            }
        }
    }

    useEffect(() => {
        switch (relationshipInput.type) {
            case "spouse":
                setRelationshipInput({
                    authorized_party_role: "client_spouse",
                });
                break;
            default:
                setRelationshipInput({
                    authorized_party_role: "client_successor",
                });
        }
    }, [relationshipInput.type]);

    return (
        <RelationshipLayout
            relationshipInput={relationshipInput}
            readOnly={readOnly}
            currentBasePath={currentBasePath}
            buttons={
                canEditRelationship && (
                    <div className="flex space-x-2">
                        {readOnly ? (
                            <Button type="link" to={"edit"} text="Edit" />
                        ) : (
                            <Button
                                type="button"
                                text="Save"
                                onClick={updateRelationship}
                            />
                        )}
                    </div>
                )
            }
        >
            <div className="flex items-start space-x-10 w-full flex-shrink-0">
                <form onSubmit={updateRelationship} className="flex-1">
                    <div>
                        <div className="mb-6">
                            <SelectInput
                                label="Relationship Type"
                                options={[
                                    {
                                        value: "spouse",
                                        label: "Spouse",
                                    },
                                    {
                                        value: "family",
                                        label: "Family",
                                    },
                                    {
                                        value: "friend",
                                        label: "Friend",
                                    },
                                    {
                                        value: "organization",
                                        label: "Organization",
                                    },
                                ]}
                                value={relationshipInput.type}
                                name="type"
                                onChange={updateValue}
                                readOnly={readOnly}
                            ></SelectInput>
                        </div>

                        <div className="mb-6">
                            <SelectInput
                                label="Related To"
                                options={[
                                    {
                                        value: "client",
                                        label: "Client",
                                    },
                                    {
                                        value: "spouse",
                                        label: "Spouse",
                                    },
                                    {
                                        value: "both",
                                        label: "Both",
                                    },
                                ]}
                                value={relationshipInput.spousal_relation}
                                name="spousal_relation"
                                onChange={updateValue}
                                readOnly={readOnly}
                            ></SelectInput>
                        </div>
                        <div className="w-full flex-shrink-0 flex-1">
                            {displayForm()}
                        </div>
                    </div>
                    <input type="submit" hidden />
                </form>
                <div className="flex-none">
                    <VaultProfilePhoto
                        client={client}
                        readOnly={false}
                        resourceType={"Relationship"}
                        resourceId={relationshipId}
                        upload={() => {}}
                    />
                </div>
            </div>
        </RelationshipLayout>
    );
}
