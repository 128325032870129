import React, { useState, useEffect } from "react";
import Api from "../../services/Api";
import Spinner from "../../components/Spinner";
import { Switch, Route } from "react-router";
import CollaboratingAdvisorProfile from "./CollaboratingAdvisorProfile";
import CollaboratingAdvisorClientList from "./CollaboratingAdvisorClientList";

export default function EditCollaboratingAdvisor({ ...props }) {
    const [loading, setLoading] = useState(true);
    const [advisor, setAdvisor] = useState({});

    const advisorId = props.match.params.advisorId;

    useEffect(() => {
        document.title = "Collaborating Advisor";
    }, []);

    useEffect(() => {
        async function fetchAdvisor() {
            try {
                setLoading(true);
                let response = await Api.get(
                    "collaborating-advisors/" + advisorId,
                    {
                        "filter[trashed]": "with",
                    }
                );
                setAdvisor(response.data.data);
                setLoading(false);
            } catch (e) {
                if (e.response.status === 401) {
                    alert(e.response.data.message);
                }
            }
        }
        fetchAdvisor();
    }, [advisorId]);

    if (loading) {
        return (
            <div className="h-full flex items-center">
                <Spinner />
            </div>
        );
    }

    return (
        <Switch>
            <Route
                path={"/collaborating-advisors/:advisorId/edit"}
                render={() => (
                    <CollaboratingAdvisorProfile advisor={advisor} {...props} />
                )}
            />
            <Route
                path={"/collaborating-advisors/:advisorId/client-list"}
                render={() => (
                    <CollaboratingAdvisorClientList
                        advisor={advisor}
                        {...props}
                    />
                )}
            />
        </Switch>
    );
}
