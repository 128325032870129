import React from "react";
import ChangeLogTable from "./_partials/ChangeLogTable";
import PageContainer from "../../components/PageContainer";
import ClientPageHeader, { Heading } from "../../components/ClientPageHeader";
import { Switch, Route } from "react-router-dom";
import ShowChange from "./ShowChange";

export default function ChangeLog(parentProps) {
    document.title = "Notice Of Changes";

    return (
        <Switch>
            <Route
                exact
                path={["/clients/:clientId/changes/:changeId/show"]}
                render={(props) => <ShowChange {...parentProps} {...props} />}
            />
            <Route
                exact
                path={["/clients/:clientId/changes"]}
                render={(props) => (
                    <>
                        <ClientPageHeader client={parentProps.client}>
                            <Heading>Notice of Changes</Heading>
                        </ClientPageHeader>
                        <PageContainer>
                            <ChangeLogTable
                                limit="50"
                                {...parentProps}
                                {...props}
                            />
                        </PageContainer>
                    </>
                )}
            />
        </Switch>
    );
}
