import React, { useState, useEffect } from "react";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import "../../services/Api";
import Api from "../../services/Api";
import Button from "../../components/Button";
import SaveButton from "../../components/SaveButton";
import "react-image-crop/dist/ReactCrop.css";

export default function EditPhoto({
    url,
    file,
    width = 300,
    minWidth = 300,
    minHeight = 300,
    aspect = 1 / 1,
    isParentLoading = false,
    enableCrop = true,
    onResult,
}) {
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState("");
    const [hasInit, setHasInit] = useState(false);

    const [initialImg, setInitialImg] = useState();
    const [imgHandle, setImgHandle] = useState(null);
    const [currentFileName, setCurrentFileName] = useState("result.jpeg");
    const [result, setResult] = useState();

    const [crop, setCrop] = useState({ unit: "px", width, aspect });
    const [previewUrl, setPreviewUrl] = useState();

    const onSelectFile = (e) => {
        if (e.target.files && e.target.files.length > 0) {
            readFile(e.target.files[0]);
        }
    };

    const onImgLoad = (image) => {
        if (enableCrop === false) {
            const crop = {
                unit: "px",
                aspect: null,
                width: image.width,
                height: image.height,
                x: 0,
                y: 0,
            };
            setCrop(crop);
            setImgHandle(image);
            setError("");
            return false;
        }

        // Center a square percent crop.
        const width =
            image.width > image.height
                ? (image.height / image.width) * 100
                : 100;
        const height =
            image.height > image.width
                ? (image.width / image.height) * 100
                : 100;

        const x = width === 100 ? 0 : (100 - width) / 2;
        const y = height === 100 ? 0 : (100 - height) / 2;

        const crop = {
            unit: "px",
            aspect: aspect,
            width: (width / 100) * image.width,
            height: (height / 100) * image.height,
            x: (x / 100) * image.width,
            y: (y / 100) * image.height,
        };

        setCrop(crop);
        setImgHandle(image);
        setError("");
        return false; // Return false if you set crop state in here.
    };

    const readFile = (file) => {
        setIsLoading(true);
        setHasInit(false);
        setImgHandle(false);
        const reader = new FileReader();
        reader.addEventListener("load", () => {
            setInitialImg(reader.result);
            setIsLoading(false);
        });
        reader.readAsDataURL(file);
        setCurrentFileName(file.name);
    };

    if (!file && !url && !error) {
        setError("No Image To Edit");
    }

    useEffect(() => {
        if (isLoading || initialImg || error) return;

        if (file) {
            setIsLoading(true);
            readFile(file);
        } else if (url) {
            setIsLoading(true);

            Api.get(url, null, { responseType: "blob" })
                .then(async (response) => {
                    let metadata = {
                        type: response.data.type,
                    };
                    let file = new File([response.data], "test.jpg", metadata);
                    readFile(file);
                })
                .catch((e) => {
                    if (e.response && e.response.status === 404) {
                        setError("The image could not be found.");
                    } else {
                        setError(
                            `There was an error loading the photo. (${
                                e.response ? e.response.status : "-1"
                            })`
                        );
                    }
                    setIsLoading(false);
                });
        } else {
            setError("Invalid Image");
        }
    }, [initialImg, file, url, isLoading, error]);

    const makeClientCrop = async (crop) => {
        if (imgHandle && crop.width && crop.height) {
            createCropPreview(imgHandle, crop, currentFileName);
        }
    };

    const createCropPreview = async (image, crop, fileName) => {
        const canvas = document.createElement("canvas");
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.width = crop.width;
        canvas.height = crop.height;
        const ctx = canvas.getContext("2d");

        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width,
            crop.height
        );

        return new Promise((resolve, reject) => {
            canvas.toBlob((blob) => {
                if (!blob) {
                    reject(new Error("Canvas is empty"));
                    return;
                }
                blob.name = fileName;
                window.URL.revokeObjectURL(previewUrl);
                setResult(blob);
                setPreviewUrl(window.URL.createObjectURL(blob));
            }, "image/png");
        });
    };

    if (!hasInit && imgHandle) {
        setHasInit(true);
        makeClientCrop(crop);
    }

    return (
        <div>
            <ReactCrop
                className={`w-full w-64`}
                src={initialImg}
                crop={enableCrop ? crop : null}
                minHeight={minHeight}
                minWidth={minWidth}
                onImageLoaded={onImgLoad}
                onChange={(c) => setCrop(c)}
                onComplete={makeClientCrop}
            />
            {error && <div>{error}</div>}
            {/* {previewUrl && <img alt="Crop preview" src={previewUrl} />} */}

            <div className="relative mt-4">
                <Button text="New File" />
                <input
                    className="absolute cursor-pointer left-0 top-0 w-full h-full opacity-0"
                    type="file"
                    accept="image/*"
                    onChange={onSelectFile}
                />
            </div>

            <div className="flex items-center justify-center mt-6">
                <div className="mr-3 flex-1">
                    <Button
                        onClick={() => onResult({ result: false })}
                        text="Cancel"
                        appearance="outline"
                    />
                </div>
                <div className="flex-1">
                    <SaveButton
                        isLoading={isLoading || isParentLoading}
                        onClick={() => onResult({ result: result })}
                        disabled={!!error}
                    />
                </div>
            </div>
        </div>
    );
}
