import React from "react";
import TextInput from "../../../components/TextInput";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import Fieldset from "../../../components/Fieldset";
import PaymentDetails from "../_partials/PaymentDetails";

export default function Memberships({ memberships, setMemberships }) {
    const membership = {
        name: "",
        contact: "",
        address: "",
        phone: "",
        email: "",
        website: "",
        payment_frequency: "Monthly",
    };

    function appendMembership() {
        if (memberships.length < 10) {
            setMemberships((memberships) => [...memberships, membership]);
        }
    }

    function removeMembership(idx) {
        setMemberships(memberships.filter((membership, idxs) => idxs !== idx));
    }

    const updateMembership = (event, idx) => {
        if (
            event.target.name === "payment_frequency" &&
            event.target.value !== "Other"
        ) {
            memberships[idx].payment_frequency_other = "";
            setMemberships(memberships);
        }

        setMemberships(
            memberships.map((membership, sidx) => {
                return idx !== sidx
                    ? membership
                    : {
                          ...membership,
                          [event.target.name]: event.target.value,
                      };
            })
        );
    };

    return (
        <Fieldset
            legend="Membership Clubs"
            description="Books, Fitness, Social, Etc."
        >
            <div className="mb-10 border-b border-gray-100 pb-6 mb-6">
                {memberships.map((membership, idx) => (
                    <div className="mb-10 border-b border-gray-100" key={idx}>
                        <div className="flex space-x-3 mb-6">
                            <div className="flex-1">
                                <TextInput
                                    label="Membership"
                                    name="name"
                                    value={memberships[idx]["name"]}
                                    onChange={(e) => updateMembership(e, idx)}
                                    autoFocus={memberships.length === idx + 1}
                                />
                            </div>
                            <div className="flex-1">
                                <TextInput
                                    label="Contact"
                                    name="contact"
                                    value={memberships[idx]["contact"]}
                                    onChange={(e) => updateMembership(e, idx)}
                                />
                            </div>
                        </div>
                        <div className="mb-6">
                            <TextInput
                                label="Address"
                                name="address"
                                value={memberships[idx]["address"]}
                                onChange={(e) => updateMembership(e, idx)}
                            />
                        </div>
                        <div className="flex space-x-3 mb-6">
                            <div className="flex-1">
                                <TextInput
                                    type="tel"
                                    label="Phone"
                                    name="phone"
                                    value={memberships[idx]["phone"]}
                                    onChange={(e) => updateMembership(e, idx)}
                                />
                            </div>
                            <div className="flex-1">
                                <TextInput
                                    type="email"
                                    label="Email"
                                    name="email"
                                    value={memberships[idx]["email"]}
                                    onChange={(e) => updateMembership(e, idx)}
                                />
                            </div>
                        </div>
                        <div className="mb-6">
                            <TextInput
                                label="Website"
                                name="website"
                                value={memberships[idx]["website"]}
                                onChange={(e) => updateMembership(e, idx)}
                            />
                        </div>
                        <div className="flex items-start space-x-3 mb-6">
                            <PaymentDetails
                                data={memberships}
                                idx={idx}
                                onchange={updateMembership}
                            />
                        </div>

                        <div className="flex justify-end mb-3">
                            <button
                                type="button"
                                onClick={() => removeMembership(idx)}
                                className="text-gray-500 text-sm hover:text-red-500"
                            >
                                <FontAwesomeIcon
                                    icon={faTimesCircle}
                                    className="mr-1"
                                />{" "}
                                Remove Membership
                            </button>
                        </div>
                    </div>
                ))}
                <div className="flex">
                    <button
                        type="button"
                        onClick={(e) => appendMembership(e)}
                        className="text-gray-500 text-sm hover:text-brand"
                    >
                        <FontAwesomeIcon icon={faPlusCircle} className="mr-1" />{" "}
                        Add Membership
                    </button>
                </div>
            </div>
        </Fieldset>
    );
}
