import React from "react";
import Money from "../../../components/Money";
import Spinner from "../../../components/Spinner";
import { titleCase } from "../../../util/TextUtil";
import useLiveWealthData from "hooks/useLiveWealthData";
import useLiveWealthState from "hooks/useLiveWealthState";

export function formatOwnerSummaryTitle(title) {
    if (title.toUpperCase() === "OTH") {
        return "Other".toUpperCase();
    }

    if (title.toUpperCase() === "JOINT") {
        return "Joint Ownership".toUpperCase();
    }

    return (
        "" +
        titleCase(title, {
            pascal: true,
        }).toUpperCase() +
        "S"
    );
}

export default function FundingTableOwners() {
    const { isLoading, isFetching, totals, ownerSummary } = useLiveWealthData({
        ownerDefault: "all",
        defaultCurrent: true,
        include_transfer_owners: true,
    });

    let loadingAssets = isLoading || isFetching;

    if (loadingAssets) {
        return (
            <div className="h-full flex justify-center items-center">
                <Spinner message="Loading Summary" />
            </div>
        );
    }

    return (
        <OwnerSummaryTable
            worth={totals.netWorth}
            totalValueLabel={"Total Net Worth"}
            ownerValueSumKey={"net_value_sum"}
            ownerSummary={ownerSummary}
        />
    );
}

export function OwnerSummaryTable({
    worth,
    ownerSummary,
    ownerValueSumKey,
    totalValueLabel,
}) {
    const { current, setCurrent } = useLiveWealthState();
    return (
        <div className="h-full bg-white">
            <div>
                <button
                    onClick={() => setCurrent(!current)}
                    className="font-bold text-md text-white py-2 w-full text-center bg-brand-500 focus:outline-none hover:bg-brand-400 rounded-none"
                >
                    {current ? "Current " : "Proposed "}
                </button>
            </div>
            <div className="bg-white flex text-center font-bold justify-center flex-col py-4 px-4 border-b border-gray-100">
                <span className="text-gray-400 text-sm">{totalValueLabel}</span>
                <span
                    className={`text-gray-600 ${
                        String(worth).length > 6 ? `text-2xl` : `text-3xl`
                    }`}
                >
                    {<Money value={worth} />}
                </span>
            </div>
            <div className="bg-white py-4 px-4 flex flex-col pb-24">
                <div className="space-y-4">
                    <div className="divide-y divide-gray-300 space-y-4 -mt-4">
                        {ownerSummary.length === 0 && (
                            <p className="py-4">
                                There are currently no ownerships.
                            </p>
                        )}
                        {ownerSummary?.map((ownerSummaryGroup, idx) => (
                            <div key={idx} className="pt-4">
                                {ownerSummaryGroup.title !== "Client" &&
                                    ownerSummaryGroup.title !== "Spouse" && (
                                        <div
                                            className={
                                                "text-center text-gray-500 text-sm font-bold mb-4"
                                            }
                                        >
                                            {formatOwnerSummaryTitle(
                                                ownerSummaryGroup.title
                                            )}
                                        </div>
                                    )}
                                <div className="space-y-4">
                                    {ownerSummaryGroup.items.map(
                                        (ownerSummaryGroupItem, idxg) => (
                                            <div key={idxg}>
                                                <div className="text-brand font-bold">
                                                    {
                                                        ownerSummaryGroupItem.title
                                                    }
                                                </div>
                                                <div className="flex justify-between text-gray-600 ">
                                                    <div className="text-sm">
                                                        Net Worth
                                                    </div>
                                                    <div
                                                        className={`text-sm ${
                                                            (
                                                                ownerSummaryGroupItem.value +
                                                                ""
                                                            ).length > 8
                                                                ? "text-sm"
                                                                : ""
                                                        }`}
                                                    >
                                                        <Money
                                                            value={
                                                                ownerSummaryGroupItem[
                                                                    ownerValueSumKey
                                                                ]
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    )}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
}
