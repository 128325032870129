import usePost from "./usePost";
import { deleteNonMortgageLiability } from "services/NonMortgageLiabilityService";

export default function useDeleteNonMortgageLiability(
    { clientId, liabilityId },
    config
) {
    const { mutation, validationErrors } = usePost(
        () => deleteNonMortgageLiability(clientId, liabilityId),
        {
            showToastOnError: true,
            showToastOnSuccess: true,
            ...config,
        }
    );
    const onSubmit = (event) => {
        event.preventDefault();
        return mutation.mutate();
    };

    return { onSubmit, ...mutation, validationErrors };
}
