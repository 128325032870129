import React from "react";
import PersonalPropertySelect from "../../../components/PersonalPropertySelect";
import RecipientSelect from "./RecipientSelect";
import SelectInput from "../../../components/SelectInput";

export default function PersonalPropertyMemoForm({
    client,
    input,
    errors,
    updateValue,
    onSubmit,
}) {
    return (
        <form onSubmit={onSubmit}>
            <div className="mb-6">
                <PersonalPropertySelect
                    client={client}
                    value={input.property_id}
                    name="property_id"
                    error={errors.property_id}
                    label="Personal Property"
                    onChange={updateValue}
                />
            </div>
            <div className="mb-6">
                <RecipientSelect
                    client={client}
                    recipient_id={input.recipient_id}
                    recipient_type={input.recipient_type}
                    errors={errors}
                    updateValue={updateValue}
                />
            </div>
            {client.spouse && (
                <fieldset className="mt-6">
                    <SelectInput
                        id="transfer_on"
                        name="transfer_on"
                        label="Transfer On"
                        options={[
                            {
                                label: "First Death",
                                value: "first_death",
                            },
                            {
                                label: "Second Death",
                                value: "second_death",
                            },
                        ]}
                        value={input.transfer_on}
                        onChange={updateValue}
                    />
                </fieldset>
            )}
        </form>
    );
}
