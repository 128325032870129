import useFetch from "./useFetch";
import { fetchAuthorizedPartyInvite } from "services/AuthorizedPartyService";

export default function useAuthorizedPartyInvite(params, config) {
    return useFetch(
        ["fetchAuthorizedPartyInvite", params],
        fetchAuthorizedPartyInvite,
        config
    );
}
