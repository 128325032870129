import React from "react";
import SaveButton from "../../components/SaveButton";
import { Redirect } from "react-router-dom";
import ClientPageHeader, { Heading } from "../../components/ClientPageHeader";
import PageContainer from "../../components/PageContainer";
import AdvisorProfileForm from "./_partials/AdvisorProfileForm";
import useCreateAdvisor from "hooks/useCreateAdvisor";
import useForm from "hooks/useForm";

export default function AddAdvisor({ client, clientPath }) {
    const { input, updateValue, clearUnsavedChanges } = useForm({
        type: 1,
        first_name: "",
        last_name: "",
        middle_name: "",
        nick_name: "",
        email: "",
        phone: "",
        address: "",
        address_2: "",
        city: "",
        state: "",
        postal_code: "",
        country: "US",
        organization_name: "",
        organization_website: "",
        can_collaborate: 0,
    });

    document.title = "Add Advisor";

    const {
        onSubmit,
        validationErrors: errors,
        isSuccess,
    } = useCreateAdvisor(client.id, input);

    const createAdvisor = (event) => {
        clearUnsavedChanges();
        onSubmit(event);
    };

    if (isSuccess) {
        return <Redirect to={`${clientPath}/advisors`} />;
    }

    return (
        <>
            <ClientPageHeader client={client}>
                <Heading
                    backTo={`${clientPath}/advisors`}
                    backText="All Advisors"
                >
                    Add Advisor
                </Heading>
                <div className="flex-0">
                    <SaveButton onClick={createAdvisor} />
                </div>
            </ClientPageHeader>
            <PageContainer>
                <form
                    onSubmit={createAdvisor}
                    className="max-w-3xl mx-auto"
                    autoComplete="off"
                >
                    <AdvisorProfileForm
                        advisor={input}
                        updateValue={updateValue}
                        errors={errors}
                        view="create"
                    />
                </form>
            </PageContainer>
        </>
    );
}
