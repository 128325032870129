import React from "react";
import Select from "react-select/async";
import { InputLabel } from "./TextInput";

const SearchableSelect = (props) => {
    return (
        <>
            {props.label && (
                <InputLabel text={props.label} required={props.required} />
            )}
            <Select
                {...props}
                styles={{
                    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                    control: (base) => ({
                        ...base,
                        ...{
                            minHeight: 48,
                            display: "flex",
                            border: "solid rgb(229, 231, 235) 1px",
                            borderRadius: 4,
                        },
                    }),
                }}
            />
        </>
    );
};

export default SearchableSelect;
