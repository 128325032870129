import usePost from "./usePost";
import { fetchPostMortemTransfereesExport } from "services/PostMortemDownloadsService";

export default function usePostMortemTransfereesReport(params) {
    const { mutation, validationErrors } = usePost(
        () => fetchPostMortemTransfereesExport(params),
        {
            ...params,
            showToastOnError: true,
            showToastOnSuccess: true,
        }
    );
    const onSubmit = (event) => {
        event.preventDefault();
        return mutation.mutate();
    };

    return { onSubmit, ...mutation, validationErrors };
}
