import React, { useState, useEffect } from "react";
import Api from "../../services/Api";
import Spinner from "../../components/Spinner";
import UpdateAssetValue from "./UpdateAssetValue";
import { useToasts } from "react-toast-notifications";
import PageContainer from "../../components/PageContainer";
import ClientPageHeader, { Heading } from "../../components/ClientPageHeader";
import Table, { TableBody, TableHeader } from "../../components/Table";
import UpdateLienValue from "./UpdateLienValue";

export default function UpdateValues({ client }) {
    const { addToast } = useToasts();
    const [errors, setErrors] = useState([]);
    const [assetType, setAssetType] = useState();
    const [assetId, setAssetId] = useState();
    const [isLoading, setIsLoading] = useState(true);
    const [assets, setAssets] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                setIsLoading(true);
                let response = await Api.get(
                    "clients/" + client.id + "/all-assets",
                    { liabilities: true }
                );
                setAssets(response.data.data);
                setIsLoading(false);
            } catch (error) {
                setIsLoading(false);
                console.log(error);
            }
        };
        fetchData();
    }, [client]);

    async function saveChanges(e, data) {
        setAssetType(data.asset_type);
        setAssetId(data.asset_id);
        e.preventDefault();
        try {
            let response = await Api.put(
                "clients/" + client.id + "/update-values",
                data
            );
            addToast(response.data.message);
            setErrors([]);
        } catch (e) {
            setErrors([]);
            if (e.response.data.errors) {
                setErrors(e.response.data.errors);
            } else {
                addToast(e.response.data.message, { type: "error" });
            }
        }
    }

    return (
        <>
            <ClientPageHeader client={client}>
                <Heading>Update Values</Heading>
            </ClientPageHeader>
            <PageContainer>
                {isLoading ? (
                    <Spinner />
                ) : (
                    <Table>
                        <TableHeader>
                            <th>Asset Name</th>
                            <th>Value</th>
                            <th>As Of Date</th>
                            <th></th>
                        </TableHeader>
                        <TableBody>
                            {assets.map((asset, idx) => (
                                <>
                                    <UpdateAssetValue
                                        key={idx}
                                        name={asset.name}
                                        asset={asset}
                                        errors={
                                            assetType === asset.type &&
                                            assetId === asset.id
                                                ? errors
                                                : []
                                        }
                                        onSave={(e, data) =>
                                            saveChanges(e, data)
                                        }
                                    />
                                    {asset.lien && (
                                        <UpdateLienValue
                                            key={idx}
                                            name={asset.lien.name}
                                            lien={asset.lien}
                                            errors={
                                                assetType === asset.type &&
                                                assetId === asset.id
                                                    ? errors
                                                    : []
                                            }
                                            onSave={(e, data) =>
                                                saveChanges(e, data)
                                            }
                                        />
                                    )}
                                </>
                            ))}
                        </TableBody>
                    </Table>
                )}
            </PageContainer>
        </>
    );
}
