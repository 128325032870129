import React from "react";
import InsurancePoliciesLayout from "./InsurancePoliciesLayout";
import useClient from "hooks/useClient";
import VaultResourceFileList from "views/resources/VaultResourceFileList";

export default function EditInsurancePolicyRelatedFiles({
    resourceId,
    resourceType,
    currentBasePath,
    title,
}) {
    const { client } = useClient();

    return (
        <InsurancePoliciesLayout
            currentBasePath={currentBasePath}
            title={title}
        >
            <VaultResourceFileList
                client={client}
                resourceType={resourceType}
                resourceId={resourceId}
            />
        </InsurancePoliciesLayout>
    );
}
