import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { AuthContext } from "../../../contexts/AuthContext";
import useHasPermission from "../../../hooks/usePermissions";

export default function AccountNav({ active, member }) {
    const inactiveClass =
        "group flex items-center px-3 py-2 text-sm leading-5 font-medium text-gray-600 rounded-md hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:bg-gray-100 transition ease-in-out duration-150";
    const activeClass =
        "group flex items-center px-3 py-2 text-sm leading-5 font-medium text-gray-900 rounded-md bg-gray-200 hover:text-gray-900 hover:bg-gray-200 focus:outline-none focus:bg-gray-200 transition ease-in-out duration-150";

    const auth = useContext(AuthContext);
    const canCustomizeQuestions = useHasPermission("question_customization");
    const canCustomizeBudgets = useHasPermission("budget_item_customization");

    return (
        <nav className="w-56">
            <Link
                to={`/members/${member.id}/edit/profile`}
                className={
                    active === `/members/${member.id}/edit/profile`
                        ? activeClass
                        : inactiveClass
                }
            >
                <span className="truncate">Edit Profile</span>
            </Link>
            {useHasPermission("firm_member_accounts_administration") &&
                member.id !== auth.user.id && (
                    <Link
                        to={`/members/${member.id}/edit/permissions`}
                        className={
                            active === `/members/${member.id}/edit/permissions`
                                ? activeClass
                                : inactiveClass
                        }
                    >
                        <span className="truncate">Permissions</span>
                    </Link>
                )}
            {canCustomizeQuestions && (
                <Link
                    to={`/members/${member.id}/questionnaires`}
                    className={
                        active === `/members/${member.id}/questionnaires`
                            ? activeClass
                            : inactiveClass
                    }
                >
                    <span className="truncate">Questionnaires</span>
                </Link>
            )}
            {canCustomizeBudgets && (
                <Link
                    to={`/members/${member.id}/budget-items`}
                    className={
                        active === `/members/${member.id}/budget-items`
                            ? activeClass
                            : inactiveClass
                    }
                >
                    <span className="truncate">Budget Items</span>
                </Link>
            )}
        </nav>
    );
}
