import { useState, useCallback, useEffect, useReducer } from "react";

import { useToasts } from "react-toast-notifications";

import Api from "../../../services/Api";

export default function useOneMessageTemplate(messageTemplateId) {
    const { addToast } = useToasts();
    const [isLoading, setIsLoading] = useState(false);

    const [saving, setSaving] = useState(false);
    const [resetting, setResetting] = useState(false);
    const [sending, setSending] = useState(false);

    const [errors, setErrors] = useState([]);
    const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);

    const [needsUpdate, setNeedsUpdate] = useState(0);

    const [input, setInput] = useReducer(
        (state, newState) => ({ ...state, ...newState }),
        {}
    );

    const updateValue = useCallback((event) => {
        setInput({ [event.target.name]: event.target.value });
        setHasUnsavedChanges(true);
    }, []);

    // runs when items in array are updated
    useEffect(() => {
        const fetchData = async () => {
            try {
                // API Call
                setIsLoading(true);
                let response = await Api.get(
                    "organization/message-templates/" + messageTemplateId
                );
                // Set Input with response
                setInput({
                    ...(response.data.default || {}),
                    ...(response.data.user || {}),
                });
                setHasUnsavedChanges(false);
                setIsLoading(false);
            } catch (error) {
                setIsLoading(false);
                addToast(
                    error.response && error.response.data.errors
                        ? error.response.data.errors
                        : "Error fetching personal properties",
                    { type: "error" }
                );
            }
        };
        fetchData();
    }, [messageTemplateId, addToast, needsUpdate]);

    async function update(e) {
        e.preventDefault();
        try {
            setErrors([]);
            setSaving(true);

            if (!input.subject) {
                input.subject = input.name;
            }

            const body = { ...input };
            let response = await Api.post(
                "organization/message-templates",
                body
            );
            addToast(response.data.message);
            setErrors([]);
            setHasUnsavedChanges(false);
            setSaving(false);
            setNeedsUpdate((needsUpdate) => needsUpdate + 1);
        } catch (e) {
            setSaving(false);
            setErrors([]);
            if (e.response.data.errors) {
                setErrors(e.response.data.errors);
            } else {
                addToast(e.response.data.message, { type: "error" });
            }
        }
    }

    async function reset(e) {
        e.preventDefault();
        try {
            setErrors([]);
            setResetting(true);
            let response = await Api.delete(
                "organization/message-templates/" + messageTemplateId
            );
            addToast(response.data.message);
            setErrors([]);
            setHasUnsavedChanges(false);
            setResetting(false);
            setInput({ id: undefined });
            setNeedsUpdate((needsUpdate) => needsUpdate + 1);
        } catch (e) {
            setResetting(false);
            setErrors([]);
            if (e.response.data.errors) {
                setErrors(e.response.data.errors);
            } else {
                addToast(e.response.data.message, { type: "error" });
            }
        }
    }

    async function sendTest(e) {
        e.preventDefault();
        try {
            await update(e);
            setSending(true);
            setErrors([]);
            let response = await Api.post(
                "organization/message-templates/" +
                    messageTemplateId +
                    "/send-test"
            );
            addToast(response.data.message);
            setErrors([]);
            setSending(false);
        } catch (e) {
            setSending(false);
            setErrors([]);
            if (e.response.data.errors) {
                setErrors(e.response.data.errors);
            } else {
                addToast(e.response.data.message, { type: "error" });
            }
        }
    }

    return {
        input,
        errors,
        isLoading,
        updateValue,
        hasUnsavedChanges,
        saving,
        setNeedsUpdate,
        update,
        reset,
        resetting,
        sendTest,
        sending,
    };
}
