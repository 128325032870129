import React, { useState } from "react";
import Api from "../../services/Api";
import Modal from "../../components/Modal";
import { processFormErrors } from "../../util/FormsHelper";
import { useToasts } from "react-toast-notifications";
import Select from "react-select/async";
import InputErrors from "../../components/InputErrors";
import debounce from "../../util/debounce-promise";

export default function ModalAddClientAccess({
    resourceId,
    type,
    show,
    setShow,
    onResult,
    ...props
}) {
    const [selectedLabel, setSelectedLabel] = useState(null);
    const [selectedValue, setSelectedValue] = useState(null);

    const selectedAccessLevel = "write";

    const [isLoading, setIsLoading] = useState(false);
    const [errors, setErrors] = useState([]);
    const { addToast } = useToasts();

    const fetchOptions = async (query) => {
        const response = await Api.get(
            `resources/access/${type}/${resourceId}/clients`,
            {
                "filter[search]": query,
                limit: 10,
            }
        );

        return response.data.data.map((user) => {
            return {
                value: user.id.toString(),
                label: user.full_name,
            };
        });
    };

    const getUserOptions = debounce(fetchOptions, 250);

    const addAccess = async ({ id }) => {
        setErrors([]);
        try {
            setIsLoading(true);
            const response = await Api.post(
                `resources/access/${type}/${resourceId}/${id}`,
                { access_level: selectedAccessLevel }
            );
            const access = response.data.data;
            onResult();
            addToast(
                `Access granted to ${access.user_full_name} for ${access.resource_name}.`,
                { type: "success" }
            );
            setIsLoading(false);
            return true;
        } catch (e) {
            setIsLoading(false);
            console.log(e);
            setErrors(processFormErrors(e));
        }
    };

    return (
        <Modal
            isOpen={show}
            type="warning"
            title={`Grant Access`}
            body={() => {
                return (
                    <>
                        <p className={`my-4 text-base text-gray-600`}>
                            {props.instructions ||
                                "Select a client below to grant them access."}
                        </p>

                        {errors && <InputErrors errors={errors} />}

                        <Select
                            placeholder="Select a client"
                            value={
                                selectedValue
                                    ? {
                                          label: selectedLabel,
                                          value: selectedValue,
                                      }
                                    : null
                            }
                            noOptionsMessage={() => "No clients available..."}
                            onChange={({ value, label }) => {
                                setSelectedLabel(label);
                                setSelectedValue(value);
                            }}
                            cacheOptions
                            loadOptions={getUserOptions}
                            defaultOptions
                            onInputChange={getUserOptions}
                            menuPortalTarget={document.body}
                            styles={{
                                menuPortal: (base) => ({
                                    ...base,
                                    zIndex: 9999,
                                }),
                            }}
                        />
                    </>
                );
            }}
            doneButtonTitle="Grant"
            isLoading={isLoading}
            onResult={async (result) => {
                if (result.result) {
                    if (await addAccess({ id: selectedValue })) {
                        setShow(false);
                        onResult();
                        return;
                    }
                }
                setShow(false);
            }}
        />
    );
}
