import React from "react";
import useApi from "../../../hooks/useApi";
import PageContainer from "../../../components/PageContainer";
import TableSortHeader from "../../../components/TableSortHeader";
import { Link } from "react-router-dom";
import Dropdown, { DropdownItem } from "../../../components/Dropdown";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faEllipsisH,
    faEdit,
    faTrash,
    faEye,
} from "@fortawesome/free-solid-svg-icons";
import useAuth from "../../../hooks/useAuth";
import SearchInput from "../../../components/SearchInput";
import useDebounce from "../../../hooks/useDebounce";
import PageHeader from "../../../components/PageHeader";
import DashboardLayout from "../../../views/layouts/DashboardLayout";

export default function MemberAppointmentIndex() {
    const { user } = useAuth();
    const { api } = useApi();
    const [appointments, setAppointments] = React.useState([]);
    const [orderBy, setOrderBy] = React.useState("date");
    const [sortDir, setSortDir] = React.useState("desc");
    const [search, setSearch] = React.useState("");
    const debouncedSearch = useDebounce(search, 250);

    const deleteAppointment = async (id) => {
        try {
            await api("delete", `appointments/${id}`);
            setAppointments(
                appointments.filter((appointment) => appointment.id !== id)
            );
        } catch (error) {
            console.log(error);
        }
    };

    React.useEffect(() => {
        const fetchAppointments = async () => {
            try {
                const response = await api("get", "appointments", {
                    client_id: null,
                    limit: 100,
                    sort: sortDir === "desc" ? "-" + orderBy : orderBy,
                    "filter[search]": debouncedSearch,
                });
                setAppointments(response.data.data);
            } catch (error) {
                console.log(error);
            }
        };
        fetchAppointments();
    }, [orderBy, sortDir, debouncedSearch, api]);

    return (
        <>
            <DashboardLayout>
                <PageHeader text={`Appointments`} />
                <PageContainer>
                    <div className="mb-4">
                        <SearchInput
                            searchValue={search}
                            onSearchChange={(e) => setSearch(e.target.value)}
                        />
                    </div>
                    <table className="w-full text-left">
                        <thead>
                            <tr>
                                <TableSortHeader
                                    field="title"
                                    sortField={orderBy}
                                    sortDir={sortDir}
                                    changeSort={(column) => {
                                        setSortDir(
                                            sortDir === "asc" ? "desc" : "asc"
                                        );
                                        setOrderBy(column);
                                    }}
                                >
                                    Title
                                </TableSortHeader>
                                <TableSortHeader
                                    field="date"
                                    sortField={orderBy}
                                    sortDir={sortDir}
                                    changeSort={(column) => {
                                        setSortDir(
                                            sortDir === "asc" ? "desc" : "asc"
                                        );
                                        setOrderBy(column);
                                    }}
                                >
                                    Date & Time
                                </TableSortHeader>
                                <th className="text-right">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {appointments.map((appointment) => (
                                <tr key={appointment.id}>
                                    <td>
                                        <Link
                                            to={appointment.resource_url}
                                        >{`${appointment.title}`}</Link>
                                    </td>
                                    <td>
                                        {appointment.date_formatted} at{" "}
                                        {appointment.time_formatted}
                                    </td>
                                    <td className="text-right">
                                        <span>
                                            <Dropdown
                                                position="right"
                                                toggleClass="px-3 py-1"
                                                toggleContent={
                                                    <FontAwesomeIcon
                                                        icon={faEllipsisH}
                                                    />
                                                }
                                            >
                                                {user.type !== "member" && (
                                                    <>
                                                        <DropdownItem
                                                            label="View"
                                                            icon={faEye}
                                                            link={
                                                                appointment.resource_url
                                                            }
                                                        />
                                                    </>
                                                )}
                                                {user.type === "member" && (
                                                    <>
                                                        <DropdownItem
                                                            label="Edit"
                                                            icon={faEdit}
                                                            link={
                                                                appointment.resource_url
                                                            }
                                                        />
                                                        <DropdownItem
                                                            label="Delete"
                                                            icon={faTrash}
                                                            handleClick={() =>
                                                                deleteAppointment(
                                                                    appointment.id
                                                                )
                                                            }
                                                        />
                                                    </>
                                                )}
                                            </Dropdown>
                                        </span>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </PageContainer>
            </DashboardLayout>
        </>
    );
}
