import { useState, useCallback, useEffect } from "react";

import Api from "../../../services/Api";

export default function useMessageTemplates() {
    const [userTemplates, setUserTemplates] = useState([]);
    const [defaultTemplates, setDefaultTemplates] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const refreshTemplates = useCallback(async () => {
        try {
            setIsLoading(true);
            const { data } = await Api.get("organization/message-templates");
            setUserTemplates(data.user);
            setDefaultTemplates(data.default);
        } catch (e) {
            console.error(e);
        }
        setIsLoading(false);
    }, []);

    useEffect(() => {
        refreshTemplates();
    }, [refreshTemplates]);

    return {
        userTemplates,
        defaultTemplates,
        isLoading,
    };
}
