import React from "react";
import { Route, Switch } from "react-router-dom";
import DashboardLayout from "../../views/layouts/DashboardLayout";
import ManageMember from "./ManageMember";
import MembersIndex from "./MembersIndex";

const Members = () => {
    return (
        <DashboardLayout>
            <Switch>
                <Route
                    exact
                    path={[
                        "/members",
                        "/members/all",
                        "/members/disabled",
                        "/members/pending",
                    ]}
                    component={MembersIndex}
                />
                <Route path="/members/:memberId" component={ManageMember} />
            </Switch>
        </DashboardLayout>
    );
};

export default Members;
