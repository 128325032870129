import React, { useState } from "react";
import Api from "../../services/Api";
import OtpInput from "../../components/OtpInput";
import InputErrors from "../../components/InputErrors";
import Button from "../../components/Button";
import useAuth from "../../hooks/useAuth";
import Checkbox from "../../components/Checkbox";
import useSessionStorage from "../../hooks/useSessionStorage";

export default function VerifyTwoFactorForm({
    title = "Verify Two-Factor Authentication",
    auth = null,
    authOptions = [],
    doneButtonTitle = "Verify",
    showRemember = false,
    showButtons = true,
    showCancel = true,
    onResult,
}) {
    const [isLoading, setIsLoading] = useState(false);
    const [code, setCode] = useState("");
    const [remember, setRemember] = useState(false);
    const [errors, setErrors] = useState([]);

    const [success, setSuccess] = useState(false);
    const [autoSubmitted, setAutoSubmitted] = useState(false);
    const [requestMessage, setRequestMessage] = useState("");
    const [requestMessageError, setRequestMessageError] = useState("");

    const [selectedAuth, setSelectedAuth] = useState(false);
    const [lastRequestTime, setLastRequestTime, isInitialized] =
        useSessionStorage(`last_request_${selectedAuth?.id}`, null);

    const { setNeedsRefresh } = useAuth();

    React.useEffect(() => {
        if (!selectedAuth && auth) {
            setSelectedAuth(auth);
            return;
        }
        if (!selectedAuth && authOptions && authOptions.length) {
            setSelectedAuth(
                Object.assign(authOptions[0], {
                    autoRequest: authOptions[0].auth_type === "sms",
                })
            );
            return;
        }
    }, [selectedAuth, auth, authOptions]);

    React.useEffect(() => {
        if (code.length > 5 && !autoSubmitted) {
            setAutoSubmitted(true);
            verifyTwoFA();
        }
        if (code.length < 5 && autoSubmitted) {
            setAutoSubmitted(false);
        }
    }, [code, autoSubmitted]);

    React.useEffect(() => {
        if (!selectedAuth) return;
        if (
            selectedAuth.autoRequest &&
            !lastRequestTime &&
            !success &&
            isInitialized
        ) {
            requestCode();
        }
    }, [selectedAuth, lastRequestTime, success, selectedAuth, isInitialized]);

    const cancel = () => {
        onResult({ result: false, selectedAuth });
        setLastRequestTime(null);
    };

    const next = async () => {
        await verifyTwoFA();
    };

    const requestCode = async () => {
        try {
            if (!lastRequestTime) {
                setLastRequestTime(Date.now());
            }
            setRequestMessage("");
            setRequestMessageError("");
            const response = await Api.put(
                `auth/2fa/${selectedAuth.id}/request`,
                {
                    // method,
                }
            );
            setRequestMessage(response.data.message);
            setErrors([]);
        } catch (e) {
            if (e.response.data.errors) {
                setRequestMessageError(e.response.data.errors.auth_type[0]);
                return;
            }
            if (e.response.data.message) {
                setRequestMessageError(e.response.data.message);
                return;
            }
        }
    };

    const verifyTwoFA = async () => {
        try {
            setIsLoading(true);
            setErrors([]);
            await Api.put(`auth/2fa/${selectedAuth.id}/verify`, {
                code,
                remember,
                // method,
            });

            setSuccess(true);
            setErrors([]);
            onResult({ result: true, auth: selectedAuth });
            setLastRequestTime(null);
            setIsLoading(false);
            setNeedsRefresh((refresh) => refresh + 1);
        } catch (e) {
            setIsLoading(false);
            setCode("");
            if (e.response.data.errors) {
                setErrors(e.response.data.errors);
            }
        }
    };

    return (
        <div>
            {title && <strong>{title}</strong>}

            {selectedAuth.auth_type === "sms" && (
                <>
                    <p className="my-4">
                        We just sent a code to {selectedAuth.masked_value}. Type
                        in the code below.
                    </p>
                    <div className="my-4">
                        Didn&apos;t receive it?
                        <span
                            onClick={() => {
                                requestCode();
                            }}
                            className="underline text-gray-600 hover:text-gray-900 cursor-pointer focus:outline-none focus:underline ml-2"
                        >
                            Request another.
                        </span>
                        {requestMessage && (
                            <div className="mt-2 text-green-500">
                                {requestMessage}
                            </div>
                        )}
                        {requestMessageError && (
                            <div className="mt-2">
                                <InputErrors errors={[requestMessageError]} />
                            </div>
                        )}
                    </div>
                </>
            )}
            <div>
                <div className="mt-1 mb-4">
                    <OtpInput
                        value={code}
                        onChange={(value) => setCode(value)}
                        numInputs={6}
                        shouldAutoFocus={true}
                        containerStyle={`w-full flex items-center justify-center px-1`}
                        inputContainerStyle={`p-1 text-2xl border-2 border-l-2 border-r-2 border-gray-400 first:border-l-2 first:rounded-l-md last:rounded-r-md last:rounded-r-2 -m-px`}
                        focusInputContainerStyle={`bg-gray-200`}
                        // focusInputContainerStyle={`border-orange-400 border-l-2`}
                        inputStyle={`p-3 w-full text-center focus:outline-none bg-transparent`}
                    />
                    {errors.code && (
                        <div className="mt-2">
                            <InputErrors errors={errors.code} />
                        </div>
                    )}
                </div>
            </div>

            {showRemember && (
                <div>
                    <div className="mt-1 mb-4 flex pl-2">
                        <Checkbox
                            label="Don't ask again on this computer for two weeks."
                            id="2fa-remember"
                            onChange={(e) => setRemember(e.target.value)}
                        />
                    </div>
                </div>
            )}

            {showButtons && (
                <div className="flex items-center justify-center mt-6">
                    {showCancel && (
                        <div className="mr-3 flex-1">
                            <Button
                                onClick={() => cancel()}
                                text="Cancel"
                                appearance="outline"
                            />
                        </div>
                    )}
                    <div className="flex-1">
                        <Button
                            isLoading={isLoading}
                            onClick={() => next()}
                            text={doneButtonTitle}
                        />
                    </div>
                </div>
            )}
        </div>
    );
}
