import Api from "./Api";

export const fetchAuthorizedPartyInvite = async function ({ queryKey }) {
    const [, { ...params }] = queryKey;
    return await Api.get(
        "clients/" +
            params.clientId +
            "/relationships/" +
            params.relationshipId +
            "/invite"
    ).then((res) => res.data);
};

export const createAuthorizedPartyInvite = async function (params, formData) {
    return await Api.post(
        "clients/" +
            params.clientId +
            "/relationships/" +
            params.relationshipId +
            "/invite",
        formData
    ).then((res) => res.data);
};

export const revokeAuthorizedPartyInvite = async function (params, formData) {
    return await Api.delete(
        "clients/" +
            params.clientId +
            "/relationships/" +
            params.relationshipId +
            "/invite",
        formData
    ).then((res) => res.data);
};
