// import Moment from 'moment-timezone/builds/moment-timezone-with-data'
import Moment from "moment-timezone/builds/moment-timezone-with-data";
const tz = Moment.tz.guess();
export function titleCase(str, { pascal = false } = {}) {
    if (typeof str !== "string") {
        str = new String("");
    }

    if (pascal) {
        var result = str.replace(/([A-Z])/g, " $1");
        str = result.charAt(0).toUpperCase() + result.slice(1);
        return str;
    }

    str = str.toLowerCase().split(" ");
    for (var i = 0; i < str.length; i++) {
        str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1);
    }
    return str.join(" ");
}

export function snakeToTitle(str) {
    str = str.toLowerCase().split("_");
    for (var i = 0; i < str.length; i++) {
        str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1);
    }
    return str.join(" ");
}

export function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes";

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
}

export function formatDate(
    dateString,
    emptyMessage = "N/A",
    { format, timezone } = {
        format: "MM-DD-Y",
        timezone: "UTC",
    }
) {
    if (!dateString) return emptyMessage;
    return Moment.tz(dateString, "UTC")
        .tz(timezone || "UTC")
        .format(format);
}

export function formatDateTime(dateString, emptyMessage = "N/A") {
    return formatDate(dateString, emptyMessage, {
        format: "MM-DD-Y h:mm A",
        timezone: tz,
    });
}

export function formatDateForInput(
    dateString,
    { format, timezone } = {
        format: "yyyy-MM-DD",
        timezone: "UTC",
    }
) {
    if (!dateString) return "";
    return Moment(dateString)
        .tz(timezone ? timezone : Moment.tz.guess())
        .format(format);
}
