import React, { useState } from "react";
import {
    faKey,
    faChartBar,
    faHome,
    faPiggyBank,
    faFileContract,
    faUsers,
    faEnvelope,
    faCommentAlt,
    faFileAlt,
    faCalendarAlt,
} from "@fortawesome/free-solid-svg-icons";
import SidebarDropdownList, {
    SidebarDropdownListItem,
} from "../../../components/SidebarDropdownList";
import MainNavigationContainer from "../_partials/MainNavigationContainer";
import MainNavigationItem from "../_partials/MainNavigationItem";
import { listOfReports } from "../../reports/ReportsIndex";
import useAuth from "hooks/useAuth";
import useHasPermission from "hooks/usePermissions";

export default function ClientMainNavigation(props) {
    const [activeDropdown, setActiveDropdown] = useState("");
    const [hasAnyReportAccess] = useState(
        listOfReports.filter((report) => {
            return useHasPermission(report.permission_key);
        }).length > 0
    );
    const { user } = useAuth();

    const clientId = user.role === "client_owner" ? user.id : user.client_id;

    return (
        <MainNavigationContainer {...props}>
            <ul className="flex-grow">
                <MainNavigationItem
                    onClick={() => setActiveDropdown("")}
                    label="Dashboard"
                    to="/"
                    icon={faChartBar}
                    {...props}
                />

                <MainNavigationItem
                    onClick={() => setActiveDropdown("")}
                    label="My Vault"
                    to="/client/vault"
                    icon={faKey}
                    {...props}
                />

                {user.navigation.assets && (
                    <li className={`flex-0 relative`}>
                        <SidebarDropdownList
                            title="Assets"
                            icon={faHome}
                            isOpen={activeDropdown === "assets"}
                            onClick={() =>
                                setActiveDropdown(
                                    activeDropdown === "assets" ? "" : "assets"
                                )
                            }
                            {...props}
                        >
                            {user.navigation.annuities && (
                                <SidebarDropdownListItem
                                    label="Annuities"
                                    to={`/client/annuities`}
                                />
                            )}
                            {user.navigation.businessInterests && (
                                <SidebarDropdownListItem
                                    label="Business Interests"
                                    to={`/client/business-interests`}
                                />
                            )}
                            {user.navigation.financialAccounts && (
                                <SidebarDropdownListItem
                                    label="Financial Accounts"
                                    to={`/client/financial-accounts`}
                                />
                            )}
                            {user.navigation.retirementAccounts && (
                                <SidebarDropdownListItem
                                    label="Retirement Accounts"
                                    to={`/client/retirement-accounts`}
                                />
                            )}
                            {user.navigation.insurance && (
                                <SidebarDropdownListItem
                                    label="Life Insurance Policies"
                                    to={`/client/insurance-policies`}
                                />
                            )}
                            {user.navigation.property && (
                                <SidebarDropdownListItem
                                    label="Personal Property"
                                    to={`/client/personal-properties`}
                                />
                            )}
                            {user.navigation.realEstate && (
                                <SidebarDropdownListItem
                                    label="Real Estate"
                                    to={`/client/real-estate`}
                                />
                            )}
                            {user.navigation.leases && (
                                <SidebarDropdownListItem
                                    label="Other Leases"
                                    to={`/client/leases`}
                                />
                            )}
                            {user.navigation.notesReceivable && (
                                <SidebarDropdownListItem
                                    label="Notes Receivables"
                                    to={`/client/notes-receivables`}
                                />
                            )}
                            {user.navigation.trusts && (
                                <SidebarDropdownListItem
                                    label="Trusts"
                                    to={`/client/trusts`}
                                />
                            )}
                            <SidebarDropdownListItem
                                label="Update Values"
                                to={`/client/update-values`}
                            />
                        </SidebarDropdownList>
                    </li>
                )}

                {user.navigation.financial && (
                    <li className={`flex-0 relative`}>
                        <SidebarDropdownList
                            title="Financial"
                            icon={faPiggyBank}
                            isOpen={activeDropdown === "financial"}
                            onClick={() =>
                                setActiveDropdown(
                                    activeDropdown === "financial"
                                        ? ""
                                        : "financial"
                                )
                            }
                            {...props}
                        >
                            {user.navigation.budgets && (
                                <SidebarDropdownListItem
                                    label="Budgets"
                                    to="/client/budget-estimates"
                                />
                            )}
                            {user.navigation.incomeSources && (
                                <SidebarDropdownListItem
                                    label="Income Sources"
                                    to={`/client/income-sources`}
                                />
                            )}
                            {user.navigation.otherInsurance && (
                                <SidebarDropdownListItem
                                    label="Other Insurance Policies"
                                    to={`/client/other-insurance-policies`}
                                />
                            )}
                            {user.navigation.nonMortgageLiability && (
                                <SidebarDropdownListItem
                                    label="Non-Mortgage Liabilities"
                                    to={`/client/non-mortgage-liabilities`}
                                />
                            )}
                        </SidebarDropdownList>
                    </li>
                )}

                {(user.navigation.relationships ||
                    user.navigation.advisors) && (
                    <li className={`flex-0 relative`}>
                        <SidebarDropdownList
                            title="People"
                            icon={faUsers}
                            isOpen={activeDropdown === "people"}
                            onClick={() =>
                                setActiveDropdown(
                                    activeDropdown === "people" ? "" : "people"
                                )
                            }
                            {...props}
                        >
                            {user.navigation.advisors && (
                                <SidebarDropdownListItem
                                    label="Advisors"
                                    to="/client/advisors"
                                />
                            )}

                            {user.navigation.relationships && (
                                <SidebarDropdownListItem
                                    label="Relationships"
                                    to="/client/relationships"
                                />
                            )}

                            {user.navigation.relationships && (
                                <SidebarDropdownListItem
                                    label="Estate Planning Roles"
                                    to={`/client/estate-planning-roles`}
                                />
                            )}
                            {user.navigation.ice && (
                                <SidebarDropdownListItem
                                    label="In Case of Emergency"
                                    to={`/client/ice`}
                                />
                            )}
                        </SidebarDropdownList>
                    </li>
                )}

                <li className={`flex-0 relative`}>
                    <SidebarDropdownList
                        title="Next-Level Planning"
                        icon={faFileContract}
                        isOpen={activeDropdown === "forms"}
                        onClick={() =>
                            setActiveDropdown(
                                activeDropdown === "forms" ? "" : "forms"
                            )
                        }
                        {...props}
                    >
                        {user.navigation.questionnaires && (
                            <SidebarDropdownListItem
                                label="Questionnaires"
                                to="/client/questionnaires"
                            />
                        )}
                        {user.navigation.locationList && (
                            <SidebarDropdownListItem
                                label="Location List"
                                to={`/clients/${user.id}/forms/location-list/edit`}
                            />
                        )}
                        {user.navigation.funeralDeclaration && (
                            <SidebarDropdownListItem
                                label="Funeral Planning"
                                to={`/clients/${user.id}/forms/funeral-planning/edit`}
                            />
                        )}
                        <SidebarDropdownListItem
                            label="Memorandum Of Personal Property"
                            to={`/clients/${user.id}/personal-property-memos`}
                        />
                    </SidebarDropdownList>
                </li>

                {hasAnyReportAccess && (
                    <li className="flex-0 relative">
                        <SidebarDropdownList
                            title="Reports"
                            icon={faFileAlt}
                            isOpen={activeDropdown === "reports"}
                            onClick={() =>
                                setActiveDropdown(
                                    activeDropdown === "reports"
                                        ? ""
                                        : "reports"
                                )
                            }
                            {...props}
                        >
                            {listOfReports.map((report) => {
                                if (useHasPermission(report.permission_key))
                                    return (
                                        <SidebarDropdownListItem
                                            key={`client_report_${report.path}`}
                                            label={report.title}
                                            to={`/clients/${clientId}/${report.path}`}
                                        />
                                    );
                            })}
                        </SidebarDropdownList>
                    </li>
                )}

                <MainNavigationItem
                    onClick={() => setActiveDropdown("")}
                    label="Notes"
                    to="/client/notes"
                    icon={faCommentAlt}
                    {...props}
                />

                <MainNavigationItem
                    onClick={() => setActiveDropdown("")}
                    label="Messages"
                    to="/client/messages"
                    icon={faEnvelope}
                    {...props}
                />

                <MainNavigationItem
                    onClick={() => setActiveDropdown("")}
                    label="Appointments"
                    to="/client/appointments"
                    icon={faCalendarAlt}
                    {...props}
                />
            </ul>
        </MainNavigationContainer>
    );
}
