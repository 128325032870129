import React, { useState, useEffect } from "react";
import Api from "../../services/Api";
import { Route, Switch } from "react-router-dom";
import Spinner from "../../components/Spinner";
import { useToasts } from "react-toast-notifications";
import InsurancePoliciesForms from "./EditInsurancePolicyForms";
import useForm from "hooks/useForm";
import EditInsurancePolicyInfo from "./EditInsurancePolicyInfo";
import EditInsurancePolicyOwners from "./EditInsurancePolicyOwners";
import EditInsurancePolicyBeneficiaries from "./EditInsurancePolicyBeneficiaries";
import EditInsurancePolicyContact from "./EditInsurancePolicyContact";
import EditInsurancePolicyNotes from "./EditInsurancePolicyNotes";
import EditInsurancePolicyRelatedFiles from "./EditInsurancePolicyRelatedFiles";
import EditInsurancePolicyAccess from "./EditInsurancePolicyAccess";
import EditInsurancePolicyTransfers from "./EditInsurancePolicyTransfers";
import { useAsyncError } from "hooks/useAsyncError";

export default function EditInsurancePolicy({
    client,
    readOnly = false,
    clientPath,
    ...props
}) {
    const throwError = useAsyncError();
    const [loading, setLoading] = useState(true);
    const { addToast } = useToasts();
    const [errors, setErrors] = useState([]);
    const [saving, setSaving] = useState(false);
    const [needsUpdate, setNeedsUpdate] = useState(0);
    const { input, updateValue, setInput, clearUnsavedChanges } = useForm({});

    const title =
        `${readOnly ? "View" : "Edit"} Insurance Policy` +
        (input && !loading ? ` - ${input.name}` : "") +
        (input.account_number_last_four
            ? ` (xxx${input.account_number_last_four})`
            : "");

    useEffect(() => {
        document.title = title;
    }, [title]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
                let response = await Api.get(
                    "clients/" +
                        client.id +
                        "/insurance-policies/" +
                        props.match.params.insurancePolicyId
                );
                setInput(response.data.data);
                setLoading(false);
                clearUnsavedChanges();
            } catch (error) {
                if (error.response.status === 403) {
                    throwError(error);
                }
            }
        };
        fetchData();
    }, [client, props.match.params.insurancePolicyId, needsUpdate]);

    async function update(e) {
        e.preventDefault();
        try {
            setSaving(true);
            const body = { ...input };
            let response = await Api.put(
                "clients/" + client.id + "/insurance-policies/" + input.id,
                body
            );
            addToast(response.data.message);
            setErrors([]);
            clearUnsavedChanges();
            setSaving(false);
        } catch (e) {
            setSaving(false);
            setErrors([]);
            if (e.response.data.errors) {
                setErrors(e.response.data.errors);
            } else {
                addToast(e.response.data.message, { type: "error" });
            }
        }
    }

    if (!input || loading) {
        return (
            <div className="h-screen w-full flex items-center justify-center">
                <Spinner />
            </div>
        );
    }

    const resourceType = "InsurancePolicy";
    const basePath = `${clientPath}/insurance-policies/${input.id}/`;
    const currentBasePath = basePath + (readOnly ? "view" : "edit");

    const refreshData = () => {
        setNeedsUpdate((needsUpdate) => needsUpdate + 1);
    };

    return (
        <Switch>
            <Route
                exact={true}
                path={[basePath + "edit", basePath + "view"]}
                render={() => (
                    <EditInsurancePolicyInfo
                        client={client}
                        input={input}
                        errors={errors}
                        updateValue={updateValue}
                        onSubmit={update}
                        saving={saving}
                        currentBasePath={currentBasePath}
                        title={title}
                        readOnly={readOnly}
                    />
                )}
            />
            <Route
                exact={true}
                path={[basePath + "edit/owners", basePath + "view/owners"]}
                render={() => (
                    <EditInsurancePolicyOwners
                        client={client}
                        input={input}
                        currentBasePath={currentBasePath}
                        title={title}
                        refreshData={refreshData}
                    />
                )}
            />
            <Route
                exact={true}
                path={[
                    basePath + "edit/beneficiaries",
                    basePath + "view/beneficiaries",
                ]}
                render={() => (
                    <EditInsurancePolicyBeneficiaries
                        client={client}
                        input={input}
                        currentBasePath={currentBasePath}
                        title={title}
                        refreshData={refreshData}
                    />
                )}
            />
            <Route
                exact={true}
                path={[basePath + "edit/contact", basePath + "view/contact"]}
                render={() => (
                    <EditInsurancePolicyContact
                        input={input}
                        readOnly={readOnly}
                        errors={errors}
                        onSubmit={update}
                        updateValue={updateValue}
                        currentBasePath={currentBasePath}
                        title={title}
                    />
                )}
            />
            <Route
                exact={true}
                path={[basePath + "edit/notes", basePath + "view/notes"]}
                render={() => (
                    <>
                        <EditInsurancePolicyNotes
                            resourceType={resourceType}
                            resourceId={input.id}
                            currentBasePath={currentBasePath}
                            title={title}
                        />
                    </>
                )}
            />
            <Route
                exact={true}
                path={[
                    basePath + "edit/related-files",
                    basePath + "view/related-files",
                ]}
                render={() => (
                    <EditInsurancePolicyRelatedFiles
                        resourceType={resourceType}
                        resourceId={input.id}
                        currentBasePath={currentBasePath}
                        title={title}
                    />
                )}
            />
            <Route
                exact={true}
                path={[basePath + "edit/access", basePath + "view/access"]}
                render={() => (
                    <EditInsurancePolicyAccess
                        resourceType={resourceType}
                        resourceId={input.id}
                        currentBasePath={currentBasePath}
                        title={title}
                    />
                )}
            />
            <Route
                exact={true}
                path={[basePath + "edit/forms", basePath + "view/forms"]}
                render={() => (
                    <InsurancePoliciesForms
                        client={client}
                        input={input}
                        currentBasePath={currentBasePath}
                        title={title}
                    />
                )}
            />
            <Route
                exact={true}
                path={[
                    basePath + "edit/transfers",
                    basePath + "view/transfers",
                ]}
                render={() => (
                    <EditInsurancePolicyTransfers
                        assetId={input.id}
                        client={client}
                        currentBasePath={currentBasePath}
                        title={title}
                    />
                )}
            />
        </Switch>
    );
}
