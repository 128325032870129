import React, { useState, useEffect, useReducer } from "react";
import Api from "../../services/Api";
import { Redirect } from "react-router-dom";
import Button from "../../components/Button";
import SaveButton from "../../components/SaveButton";
import Spinner from "../../components/Spinner";
import { useToasts } from "react-toast-notifications";
import PageContainer from "../../components/PageContainer";
import TrusteesForm from "./_partials/TrusteesForm";
import ClientPageHeader, { Heading } from "../../components/ClientPageHeader";
import { useAsyncError } from "hooks/useAsyncError";

export default function EditTrustee({
    client,
    readOnly = false,
    clientPath,
    ...props
}) {
    const throwError = useAsyncError();
    const [loading, setLoading] = useState(true);
    const [redirectToIndex, setRedirectToIndex] = useState(false);
    const { addToast } = useToasts();
    const [errors, setErrors] = useState([]);

    const [input, setInput] = useReducer((state, newState) => ({
        ...state,
        ...newState,
    }));

    useEffect(() => {
        const fetchData = async () => {
            try {
                let response = await Api.get(
                    "clients/" +
                        client.id +
                        "/trustees/" +
                        props.match.params.trusteeId
                );
                setInput(response.data.data);
                setLoading(false);
                document.title = `${readOnly ? "View" : "Edit"} Trustee: ${
                    response.data.data.trustee_name
                }`;
            } catch (error) {
                if (error.response.status === 403) {
                    throwError(error);
                }
            }
        };
        fetchData();
    }, [client, props.match.params.trusteeId, readOnly]);

    function updateValue(event) {
        setInput({ [event.target.name]: event.target.value });
    }

    async function update(e) {
        e.preventDefault();
        try {
            const body = { ...input };
            let response = await Api.put(
                "clients/" + client.id + "/trustees/" + input.id,
                body
            );
            addToast(response.data.message);
            setErrors([]);
            setRedirectToIndex(true);
        } catch (e) {
            setErrors([]);
            if (e.response.data.errors) {
                setErrors(e.response.data.errors);
            } else {
                addToast(e.response.data.message, { type: "error" });
            }
        }
    }

    if (redirectToIndex) {
        return <Redirect to={`/clients/${client.id}/trustees`} />;
    }

    if (input === false || loading) {
        return (
            <div className="h-screen w-full flex items-center justify-center">
                <Spinner />
            </div>
        );
    }

    return (
        <>
            <ClientPageHeader client={client}>
                <Heading
                    backTo={`${clientPath}/trustees`}
                    backText="All Trustees"
                >
                    {readOnly ? "View" : "Edit"} Trustee
                </Heading>
                {readOnly !== true && (
                    <div className="flex-0">
                        <SaveButton onClick={update} />
                    </div>
                )}
                {readOnly !== false && (
                    <div className="flex-0">
                        <Button
                            type="link"
                            to={`${clientPath}/trustees/${input.id}/edit`}
                            text="Edit"
                        />
                    </div>
                )}
            </ClientPageHeader>
            <PageContainer>
                <div className="max-w-3xl mx-auto">
                    <TrusteesForm
                        client={client}
                        input={input}
                        readOnly={readOnly}
                        errors={errors}
                        updateValue={updateValue}
                        onSubmit={update}
                    />
                </div>
            </PageContainer>
        </>
    );
}
