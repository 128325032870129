import React, { useState } from "react";
import Api from "../../services/Api";
import SaveButton from "../../components/SaveButton";
import ClientPageHeader, { Heading } from "../../components/ClientPageHeader";
import PageContainer from "../../components/PageContainer";
import PersonalPropertyForm from "./_partials/PersonalPropertyForm";
import { useToasts } from "react-toast-notifications";
import { Redirect } from "react-router-dom";
import { formatDateForInput } from "../../util/TextUtil";
import useForm from "hooks/useForm";
import { useQueryClient } from "react-query";

export default function AddPersonalProperty({ client, user, clientPath }) {
    const { addToast } = useToasts();
    const [errors, setErrors] = useState([]);
    const [isSaving, setIsSaving] = useState(false);
    const [redirectToId, setRedirectToId] = useState(false);
    const queryClient = useQueryClient();

    const { input, updateValue, clearUnsavedChanges } = useForm({
        name: "",
        description: "",
        value: "",
        as_of_date: formatDateForInput(new Date()),
        purchase_price: "",
        purchase_date: "",
        insurance_policy_id: "",
        owner: "client:" + client.id,
        percentage_owned: "",
        other_owner: "",
        other_percentage_owned: "",
        other_owners_name: "",
        has_lien: false,
        lien_holder: "",
        loan_balance: "",
        managed_by_id: "",
    });

    document.title = "Add Personal Property";

    async function store(e) {
        e.preventDefault();
        try {
            setIsSaving(true);
            const body = { ...input };
            let response = await Api.post(
                "clients/" + client.id + "/personal-properties",
                body
            );
            clearUnsavedChanges();
            addToast(response.data.message);
            setErrors([]);
            setRedirectToId(response.data.data.id);
            setIsSaving(false);
        } catch (e) {
            clearUnsavedChanges();
            setIsSaving(false);
            setErrors([]);
            if (e.response.data.errors) {
                setErrors(e.response.data.errors);
            } else {
                addToast(e.response.data.message, { type: "error" });
            }
        }
    }

    function handleOnAddPolicy(data) {
        queryClient.refetchQueries(["fetchOtherInsurancePolicies"]);
        updateValue({
            target: {
                name: "insurance_policy_id",
                value: data.id,
            },
        });
    }

    if (redirectToId) {
        return (
            <Redirect
                to={`${clientPath}/personal-properties/${redirectToId}/edit/owners`}
            />
        );
    }

    return (
        <>
            <ClientPageHeader client={client}>
                <Heading
                    backTo={`${clientPath}/personal-properties`}
                    backText="All Personal Property"
                >
                    Add Personal Property
                </Heading>
                <div className="flex-0">
                    <SaveButton isLoading={isSaving} onClick={store} />
                </div>
            </ClientPageHeader>
            <PageContainer>
                <div className="max-w-3xl mx-auto">
                    <PersonalPropertyForm
                        client={client}
                        input={input}
                        errors={errors}
                        updateValue={updateValue}
                        onSubmit={store}
                        user={user}
                        clientPath={clientPath}
                        onAddPolicy={handleOnAddPolicy}
                    />
                </div>
            </PageContainer>
        </>
    );
}
