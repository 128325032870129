import React from "react";
import Checkbox from "../../../components/Checkbox";
import TextInput from "../../../components/TextInput";
import useFormatCurrency from "../../../hooks/useFormatCurrency";
import { formatDateForInput } from "../../../util/TextUtil";
import TextArea from "../../../components/TextArea";
import { StateSelect } from "../../../components/SelectInput";
import TrustTypeSelect from "../../../components/TrustTypeSelect";
import Grantor from "../../../components/Grantor/Grantor";

export default function TrustsForm({
    client,
    input,
    errors,
    readOnly = false,
    updateValue,
    onSubmit,
}) {
    const formatCurrency = useFormatCurrency();

    return (
        <form onSubmit={onSubmit}>
            <div className="mb-6">
                <Checkbox
                    name="is_included_in_estate"
                    label="Include this Trust in the taxable estate"
                    id="is_included_in_estate"
                    checked={parseInt(input.is_included_in_estate) === 1}
                    value={1}
                    error={errors.is_included_in_estate}
                    onChange={updateValue}
                    disabled={readOnly}
                />
            </div>
            <div>
                <div className="mb-6">
                    <TextInput
                        name="name"
                        label="Short Name"
                        id="name"
                        readOnly={readOnly}
                        disabled={readOnly}
                        value={input.name}
                        error={errors.name}
                        onChange={updateValue}
                        required={true}
                        autoFocus={true}
                    />
                </div>

                <div className="mb-6">
                    <TextArea
                        name="description"
                        label="Full Trust Name"
                        id="description"
                        readOnly={readOnly}
                        disabled={readOnly}
                        value={input.description}
                        error={errors.description}
                        onChange={updateValue}
                        rows="3"
                    />
                </div>

                <div className="mb-6">
                    <TextInput
                        type="date"
                        name="sign_date"
                        label="Sign Date"
                        id="sign_date"
                        value={formatDateForInput(input.sign_date)}
                        required={true}
                        readOnly={readOnly}
                        disabled={readOnly}
                        error={errors.sign_date}
                        onChange={updateValue}
                    />
                </div>

                <div className="mb-6">
                    <Grantor
                        client={client}
                        input={input}
                        updateValue={updateValue}
                        readOnly={readOnly}
                        disabled={readOnly}
                        errors={errors}
                        required={true}
                    />
                </div>

                <div className="flex space-x-3 mb-6">
                    <div className="flex-auto">
                        <TrustTypeSelect
                            name="type_id"
                            label="Trust Type"
                            id="type_id"
                            value={input.type_id}
                            readOnly={readOnly}
                            disabled={readOnly}
                            error={errors.type_id}
                            onChange={updateValue}
                            required={true}
                        />
                    </div>
                    {parseInt(input.type_id) === 17 && (
                        <div className="flex-auto">
                            <TextInput
                                name="type_other"
                                label="Other Description"
                                id="type_other"
                                value={input.type_other}
                                readOnly={readOnly}
                                disabled={readOnly}
                                error={errors.type_other}
                                onChange={updateValue}
                            />
                        </div>
                    )}
                </div>

                <div className="mb-6">
                    <TextInput
                        name="tax_id_number"
                        label="Tax ID Number"
                        id="tax_id_number"
                        value={input.tax_id_number}
                        readOnly={readOnly}
                        disabled={readOnly}
                        required={true}
                        error={errors.tax_id_number}
                        onChange={updateValue}
                        secret={true}
                    />
                </div>

                {/* State Law */}
                <div className="flex space-x-3">
                    <div className="mb-6 flex-auto">
                        <StateSelect
                            name="state_law"
                            label="State Law"
                            id="state_law"
                            disabled={readOnly}
                            readOnly={readOnly}
                            value={input.state_law}
                            error={errors.state_law}
                            onChange={updateValue}
                            append={["Other", "None"]}
                        />
                    </div>
                    {input.state_law === "Other" && (
                        <div className="mb-6 flex-auto">
                            <TextInput
                                name="state_law_other"
                                label="State Law Other"
                                id="state_law_other"
                                disabled={readOnly}
                                readOnly={readOnly}
                                value={input.state_law_other}
                                error={errors.state_law_other}
                                onChange={updateValue}
                            />
                        </div>
                    )}
                </div>

                {/* Situs */}
                <div className="flex space-x-3">
                    <div className="mb-6 flex-auto">
                        <StateSelect
                            name="state_situs"
                            label="Situs"
                            id="state_situs"
                            disabled={readOnly}
                            readOnly={readOnly}
                            value={input.state_situs}
                            error={errors.state_situs}
                            onChange={updateValue}
                            append={["Other", "None"]}
                        />
                    </div>
                    {input.state_situs === "Other" && (
                        <div className="mb-6 flex-auto">
                            <TextInput
                                name="state_situs_other"
                                label="Situs Other"
                                id="state_situs_other"
                                disabled={readOnly}
                                readOnly={readOnly}
                                value={input.state_situs_other}
                                error={errors.state_situs_other}
                                onChange={updateValue}
                            />
                        </div>
                    )}
                </div>

                {input.assets_owned.length === 0 && (
                    <>
                        <div className="mb-6">
                            <TextInput
                                name="value"
                                label="Value"
                                id="value"
                                value={input.value}
                                readOnly={readOnly}
                                disabled={readOnly}
                                error={errors.value}
                                onChange={updateValue}
                                onBlur={(e) => {
                                    e.target.value = formatCurrency(
                                        e.target.value
                                    );
                                    updateValue(e);
                                }}
                                placeholder="0.00"
                            />
                        </div>

                        <div className="mb-6">
                            <TextInput
                                type="date"
                                name="as_of_date"
                                label="As Of Date"
                                id="as_of_date"
                                value={formatDateForInput(input.as_of_date)}
                                readOnly={readOnly}
                                disabled={readOnly}
                                error={errors.as_of_date}
                                onChange={updateValue}
                            />
                        </div>
                    </>
                )}

                {input.assets_owned.length > 0 && (
                    <>
                        <div className="mb-6">
                            <TextInput
                                name="value"
                                label="Value"
                                id="value"
                                value={input.gross_value || 0}
                                readOnly={true}
                                disabled={true}
                            />
                        </div>

                        <div className="mb-6">
                            <TextInput
                                type="text"
                                name="as_of_date"
                                label="As Of Date"
                                id="as_of_date"
                                value={formatDateForInput(input.as_of_date)}
                                readOnly={true}
                                disabled={true}
                            />
                        </div>
                    </>
                )}

                <fieldset className="mt-12">
                    <legend className="font-bold txt-md text-gray-500 my-6 pb-1 border-b block w-full">
                        Address
                    </legend>
                    <div className="mb-6">
                        <TextInput
                            name="address"
                            label="Address"
                            id="address"
                            disabled={readOnly}
                            readOnly={readOnly}
                            value={input.address}
                            error={errors.address}
                            onChange={updateValue}
                        />
                    </div>
                    <div className="flex space-x-4">
                        <div className="flex-1">
                            <TextInput
                                name="city"
                                label="City"
                                id="city"
                                disabled={readOnly}
                                readOnly={readOnly}
                                value={input.city}
                                error={errors.city}
                                onChange={updateValue}
                            />
                        </div>
                        <div className="flex-1">
                            <StateSelect
                                name="state"
                                label="State"
                                id="state"
                                disabled={readOnly}
                                readOnly={readOnly}
                                value={input.state}
                                error={errors.state}
                                onChange={updateValue}
                            />
                        </div>
                        <div className="flex-1">
                            <TextInput
                                name="postal_code"
                                label="Zip"
                                id="postal_code"
                                disabled={readOnly}
                                readOnly={readOnly}
                                value={input.postal_code}
                                error={errors.postal_code}
                                onChange={updateValue}
                            />
                        </div>
                    </div>
                </fieldset>

                <input type="submit" hidden />
            </div>
        </form>
    );
}
