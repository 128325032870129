import React from "react";
import { faChartBar, faUsers } from "@fortawesome/free-solid-svg-icons";
import MainNavigationContainer from "../_partials/MainNavigationContainer";
import MainNavigationItem from "../_partials/MainNavigationItem";

export default function AdvisorMainNavigation(props) {
    return (
        <MainNavigationContainer {...props}>
            <ul className="flex-grow">
                <MainNavigationItem
                    label="Dashboard"
                    to="/"
                    icon={faChartBar}
                    {...props}
                />
                <MainNavigationItem
                    label="My Clients"
                    to="/clients"
                    icon={faUsers}
                    {...props}
                />
            </ul>
        </MainNavigationContainer>
    );
}
