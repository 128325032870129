import React from "react";
import { Route, Switch } from "react-router-dom";
import PageContainer from "../../components/PageContainer";
import Button from "../../components/Button";
import ClientPageHeader, { Heading } from "../../components/ClientPageHeader";
import AnnuityTable from "./_partials/AnnuityTable";
import { useUserCan } from "hooks/useHasAnyPermissions";
export default function AnnuityIndex({ client, clientPath, ...props }) {
    const canCreateWealthRecords = useUserCan("createWealthRecord");

    return (
        <>
            <ClientPageHeader client={client}>
                <Heading>Annuities</Heading>
                {canCreateWealthRecords && (
                    <div className="flex-0">
                        <Button
                            type="link"
                            to={`${clientPath}/annuities/add`}
                            text="Add Annuity"
                        />
                    </div>
                )}
            </ClientPageHeader>
            <PageContainer>
                <div className="flex space-x-8">
                    <div className="flex-1">
                        <Switch>
                            <Route
                                path={clientPath + "/annuities"}
                                render={(newProps) => (
                                    <AnnuityTable
                                        client={client}
                                        clientPath={clientPath}
                                        {...props}
                                        {...newProps}
                                    />
                                )}
                            />
                        </Switch>
                    </div>
                </div>
            </PageContainer>
        </>
    );
}
