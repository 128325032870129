import { useState } from "react";
import { useQuery } from "react-query";

const defaultConfig = {
    retry: 0,
    keepPreviousData: false,
};

export default function useFetch(key, callback, config) {
    const [unauthorized, setUnauthorized] = useState(false);
    const [notFound, setNotFound] = useState(false);
    const [meta, setMeta] = useState(null);
    const useConfig = { ...defaultConfig, ...config };
    const query = useQuery(
        key,
        async (d) =>
            callback(d).then((data) => {
                if (data.meta) {
                    setMeta(data.meta);
                } else {
                    setMeta(null);
                }

                setNotFound(false);
                setUnauthorized(false);

                return data.data || data;
            }),
        {
            ...useConfig,
            cacheTime: useConfig.cacheTime || 0,
            useErrorBoundary: (error) => {
                if (error.response && error.response.status === 403) {
                    return true;
                }
            },
            onError: (error) => {
                if (error?.response?.status === 403) {
                    return setUnauthorized(true);
                }
                if (error?.response?.status === 404) {
                    return setNotFound(true);
                }
                if (typeof config?.onError === "function") {
                    config.onError(error);
                }
            },
        }
    );

    return { unauthorized, notFound, meta, ...query };
}
