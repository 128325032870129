import { downloadFile } from "../util/Downloader";
import Api from "./Api";

export const fetchPostMortemWealthExport = async function (params) {
    const {
        owner,
        current,
        isPostMortem,
        client,
        showAltValues,
        person,
        jointSplit,
        include_transfer_owners,
        snapshot,
    } = params;

    const response = await Api.get(
        "clients/" + client.id + "/reports/estate-inventory",
        {
            owner,
            current,
            is_post_mortem: isPostMortem,
            show_alternate_valuation: showAltValues,
            person,
            jointSplit,
            include_transfer_owners,
            snapshot,
            export: true,
        },
        { responseType: "arraybuffer" }
    );
    await downloadFile(response, "beneficiary-claims-form-export", {
        open: true,
    });
    return {};
};

export const fetchPostMortemTransfereesExport = async function (params) {
    const { person, client } = params;

    const response = await Api.get(
        "clients/" + client.id + "/reports/estate-transferees",
        {
            person,
            export: "pdf",
        },
        { responseType: "arraybuffer" }
    );
    await downloadFile(response, "estate-transferees", { open: true });
    return {};
};
