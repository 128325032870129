import Api from "./Api";

export const fetchClientAdvisors = async function ({ queryKey }) {
    const [, { ...params }] = queryKey;
    return await Api.get(
        "clients/" + params.clientId + "/advisors/",
        params
    ).then((res) => res.data);
};

export const fetchClientAdvisor = async function ({ queryKey }) {
    const [, { ...params }] = queryKey;
    return await Api.get(
        "clients/" + params.clientId + "/advisors/" + params.advisorId
    ).then((res) => res.data);
};

export const createClientAdvisor = async function (clientId, formData) {
    return await Api.post("clients/" + clientId + "/advisors", formData).then(
        (res) => res.data
    );
};

export const updateClientAdvisor = async function (
    clientId,
    advisorId,
    formData
) {
    return await Api.put(
        "clients/" + clientId + "/advisors/" + advisorId,
        formData
    ).then((res) => res.data);
};

export const restoreClientAdvisor = async function (clientId, advisorId) {
    return await Api.put("clients/" + clientId + "/advisors/" + advisorId, {
        restore: true,
    });
};

export default {
    fetchClientAdvisors,
    fetchClientAdvisor,
    restoreClientAdvisor,
};
