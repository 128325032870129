import React, { useState, useEffect, useCallback } from "react";
import Api from "../../services/Api";
import { formatDateTime } from "../../util/TextUtil";
import renderHTML from "react-render-html";
import Forbidden from "../../components/Forbidden";
import VaultResourceFileList from "../resources/VaultResourceFileList";
import Spinner from "../../components/Spinner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faReply, faTrash } from "@fortawesome/free-solid-svg-icons";

export default function MessageViewer({
    client,
    deleteMessage,
    reply,
    needsRefreshed,
    ...props
}) {
    const [message, setMessage] = useState();
    const [notAuthorized, setNotAuthorized] = useState(false);
    const [notFound, setNotFound] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    const fetchMessage = useCallback(async () => {
        if (props.match.params.messageId)
            try {
                setIsLoading(true);
                let response = await Api.get(
                    `clients/${client.id}/messages/${props.match.params.messageId}`
                );
                setMessage(response.data.data);
                setNotFound(false);
                setNotAuthorized(false);
                setIsLoading(false);
            } catch (error) {
                if (error.response && error.response.status === 403) {
                    setNotAuthorized(true);
                    setNotFound(false);
                    setIsLoading(false);
                }
                if (error.response && error.response.status === 404) {
                    setNotAuthorized(false);
                    setNotFound(true);
                    setIsLoading(false);
                }
                console.log(error);
            }
    }, [client.id, props.match.params.messageId]);

    useEffect(() => {
        fetchMessage();
    }, [props.match.params.messageId, fetchMessage, needsRefreshed]);

    useEffect(() => {
        document.title = message ? message.subject : "No message selected.";
    }, [message]);

    if (notAuthorized) {
        return (
            <div className="h-full w-full flex items-center justify-center">
                <Forbidden text="You do not have permission to view this message." />
            </div>
        );
    }

    if (notFound) {
        return (
            <div className="h-full w-full flex items-center justify-center">
                Message not found
            </div>
        );
    }

    if (!message) {
        return (
            <div className="h-full w-full flex items-center justify-center">
                Select a message to view...
            </div>
        );
    }

    if (isLoading) {
        return (
            <div className="flex items-center justify-center h-full w-full">
                <Spinner message="Loading Messages" />
            </div>
        );
    }

    return (
        <div className="bg-white px-6 pb-6">
            <div className="sm:flex sm:justify-between sm:items-baseline border-b py-8">
                <h3 className="text-base font-medium space-y-1">
                    <div className="text-gray-900 text-lg">
                        {message.subject}
                    </div>
                    <div className="space-x-1 text-sm">
                        <span className="text-gray-500">From:</span>
                        <span className="text-gray-800">{message.sender}</span>
                    </div>
                    <div className="space-x-1 text-sm">
                        <span className="text-gray-500">To:</span>
                        <span className="text-gray-800">
                            {message.recipients.map((recipient, i) => (
                                <span key={recipient.id}>
                                    {recipient.user
                                        ? recipient.user.full_name
                                        : "Unknown"}
                                    {i < message.recipients.length - 1
                                        ? ", "
                                        : ""}
                                </span>
                            ))}
                        </span>
                    </div>
                </h3>
                <div>
                    <div className="mt-1 text-sm text-gray-600 whitespace-pre-line">
                        <time dateTime={message.created_at}>
                            {formatDateTime(message.created_at, null, {
                                format: "Y-M-d h:mm:ss A",
                            })}
                        </time>
                    </div>
                    <div className="flex items-center space-x-1 mt-2">
                        <button
                            title={`Reply`}
                            type="button"
                            className="rounded-md px-4 py-1 text-gray-500 border border-gray-200 hover:bg-gray-100 hover:text-gray-600"
                            onClick={() => reply(message)}
                        >
                            <FontAwesomeIcon icon={faReply} />
                        </button>
                        <button
                            title={`Delete Message`}
                            type="button"
                            className="rounded-md px-4 py-1 text-gray-500 border border-gray-200 hover:bg-gray-100 hover:text-gray-600"
                            onClick={() => deleteMessage(message.id)}
                        >
                            <FontAwesomeIcon icon={faTrash} />
                        </button>
                    </div>
                </div>
            </div>
            <div className="mt-6 pb-10 space-y-6 text-base leading-relaxed text-gray-900 whitespace-pre-line">
                {renderHTML(message.body)}
            </div>
            <div>
                <VaultResourceFileList
                    title="Attachments"
                    client={{ id: message.sender_id }}
                    resourceType={"Message"}
                    resourceId={message.id}
                    viewOnly={true}
                />
            </div>
        </div>
    );
}
