import React from "react";
import { Route, Switch } from "react-router-dom";
import TrustsIndex from "./TrustsIndex";
import AddTrust from "./AddTrust";
import EditTrust from "./EditTrust";

export default function Trusts(parentProps) {
    return (
        <Switch>
            <Route
                exact
                path={["/clients/:clientId/trusts", "/client/trusts"]}
                render={(props) => <TrustsIndex {...parentProps} {...props} />}
            />
            <Route
                exact
                path={["/clients/:clientId/trusts/add", "/client/trusts/add"]}
                render={(props) => <AddTrust {...parentProps} {...props} />}
            />
            <Route
                path={[
                    "/clients/:clientId/trusts/:trustId/edit",
                    "/client/trusts/:trustId/edit",
                ]}
                render={(props) => <EditTrust {...parentProps} {...props} />}
            />
            <Route
                path={[
                    "/clients/:clientId/trusts/:trustId/view",
                    "/client/trusts/:trustId/view",
                ]}
                render={(props) => (
                    <EditTrust readOnly={true} {...parentProps} {...props} />
                )}
            />
        </Switch>
    );
}
