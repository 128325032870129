import React from "react";
import ReactDOM from "react-dom";

import { OwnerSummaryTable } from "./FundingTableOwners";
import Spinner from "components/Spinner";
import usePostMortemState from "hooks/usePostMortemState";
import useClient from "../../../hooks/useClient";
import useLiveWealthState from "hooks/useLiveWealthState";
import usePostMortemWealthFetch from "hooks/usePostMortemWealthFetch";

export function PostMortemWealthOwners() {
    const { client } = useClient();
    const { current, owner } = useLiveWealthState();

    const { person, isPostMortem, showAltValues, jointSplit } =
        usePostMortemState({
            joint_split: true,
            is_post_mortem: true,
        });

    const { isLoading, isFetching, data } = usePostMortemWealthFetch({
        owner,
        current,
        client,
        isPostMortem,
        showAltValues,
        person,
        jointSplit,
        include_transfer_owners: true,
    });

    if (isLoading || isFetching || !data) {
        return (
            <div className="h-full flex justify-center items-center">
                <Spinner message="Loading Summary" />
            </div>
        );
    }

    const { totals, ownerSummary } = data;

    return (
        <OwnerSummaryTable
            worth={totals.estateNetWorth}
            totalValueLabel={"Total Estate Net Worth"}
            ownerValueSumKey={"dod_net_value_sum"}
            ownerSummary={ownerSummary}
        />
    );
}

export function PostMortemOwnershipSidebar() {
    return ReactDOM.createPortal(
        <div className="flex-none w-72 h-full max-h-screen overflow-auto border-l border-gray-200 overscroll-contain">
            <PostMortemWealthOwners />
        </div>,
        document.getElementById("rightSideBar")
    );
}
