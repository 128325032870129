import React from "react";
import { ClientContext } from "../contexts/ClientContext";
export default function useClient() {
    const context = React.useContext(ClientContext);
    if (context === undefined) {
        throw new Error(
            "useClient must be used within a ClientContextProvider"
        );
    }

    return {
        context,
        client: context.client,
        clientPath: context.clientPath,
        refreshClient: context.refreshClient,
    };
}
