import Forbidden from "components/Forbidden";
import React, { useEffect, useState, useCallback, useContext } from "react";
import ResourceNotFound from "../components/ResourceNotFound";
import Spinner from "../components/Spinner";
import Api from "../services/Api";
import { AuthContext } from "./AuthContext";

export const ClientContext = React.createContext();

export default function ClientContextProvider({ children, ...props }) {
    const { user } = useContext(AuthContext);
    const [client, setClient] = useState({});
    const [clientNotFound, setClientNotFound] = useState(false);
    const [unauthorized, setUnauthorized] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [clientPath, setClientPath] = useState("");

    const fetchClient = useCallback(async (clientId) => {
        try {
            setIsLoading(true);
            let response = await Api.get("clients/" + clientId, {
                "filter[trashed]": "with",
            });
            let clientResponse = response.data.data;
            setClient(clientResponse);
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            if (error.response.status === 401) {
                return alert(error.response.data.message);
            }
            if (error.response.status === 403) {
                return setUnauthorized(true);
            }
            if (error.response.status === 404) {
                return setClientNotFound(true);
            }
        }
    }, []);

    const refreshClient = () => {
        if (props.match.params.clientId) {
            setClientPath(`/clients/${props.match.params.clientId}`);
            fetchClient(props.match.params.clientId);
        } else {
            if (user.type === "member" || user.type === "advisor") {
                return (window.location.href = "/clients");
            }
            if (user.role === "client_owner") {
                fetchClient(user.id);
                setClientPath(`/client`);
            }
            if (
                user.role === "client_spouse" ||
                user.role === "client_successor"
            ) {
                fetchClient(user.client_id);
                setClientPath(`/client`);
            }
        }
    };

    useEffect(() => {
        refreshClient();
    }, [props.match.params.clientId, fetchClient, user]);

    if (isLoading) {
        return (
            <div className="h-full w-full flex items-center justify-center">
                <Spinner message="Loading Client" />
            </div>
        );
    }

    if (unauthorized) {
        return (
            <div className="h-full w-full flex items-center justify-center">
                <Forbidden />
            </div>
        );
    }

    if (clientNotFound) {
        return (
            <div className="h-full w-full flex items-center justify-center">
                <ResourceNotFound />
            </div>
        );
    }
    return (
        <ClientContext.Provider
            value={{
                client,
                clientPath,
                setClient,
                fetchClient,
                refreshClient,
            }}
        >
            {children}
        </ClientContext.Provider>
    );
}
