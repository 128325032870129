import React from "react";
import { Switch, Route } from "react-router-dom";
import NotesReceivableIndex from "./NotesReceivableIndex";
import AddNotesReceivable from "./AddNotesReceivable";
import EditNotesReceivable from "./EditNotesReceivable";

export default function NotesReceivables(parentProps) {
    return (
        <Switch>
            <Route
                exact
                path={[
                    "/clients/:clientId/notes-receivables",
                    "/client/notes-receivables",
                ]}
                render={(props) => (
                    <NotesReceivableIndex {...parentProps} {...props} />
                )}
            />
            <Route
                exact
                path={[
                    "/clients/:clientId/notes-receivables/add",
                    "/client/notes-receivables/add",
                ]}
                render={(props) => (
                    <AddNotesReceivable {...parentProps} {...props} />
                )}
            />
            <Route
                path={[
                    "/clients/:clientId/notes-receivables/:notesReceivableId/edit",
                    "/client/notes-receivables/:notesReceivableId/edit",
                ]}
                render={(props) => (
                    <EditNotesReceivable {...parentProps} {...props} />
                )}
            />
            <Route
                path={[
                    "/clients/:clientId/notes-receivables/:notesReceivableId/view",
                    "/client/notes-receivables/:notesReceivableId/view",
                ]}
                render={(props) => (
                    <EditNotesReceivable
                        readOnly={true}
                        {...parentProps}
                        {...props}
                    />
                )}
            />
        </Switch>
    );
}
