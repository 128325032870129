import React from "react";
import Spinner from "../../../components/Spinner";
import EstatePlanningRoleForm from "./EstatePlanningRoleForm";
import BasicModal, { BasicModalHeader } from "../../../components/ModalBasic";

export default function EstatePlanningRoleModal({
    title,
    input,
    errors,
    isOpen,
    client,
    onClose,
    loading,
    readOnly,
    onSuccess,
    updateValue,
    relationships,
    size = "w-full",
}) {
    return (
        <BasicModal
            isOpen={isOpen}
            size={size ? size : "w-full"}
            onClose={onClose}
            header={
                <BasicModalHeader
                    onClose={onClose}
                    onSave={onSuccess}
                    title={title}
                />
            }
        >
            {loading ? (
                <Spinner message="Loading..." />
            ) : (
                <div className="max-w-3xl mx-auto">
                    <EstatePlanningRoleForm
                        client={client}
                        input={input}
                        errors={errors}
                        updateValue={updateValue}
                        onSubmit={onSuccess}
                        readOnly={readOnly}
                        relationships={relationships}
                    />
                </div>
            )}
        </BasicModal>
    );
}
