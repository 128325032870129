import React, { useState } from "react";
import Button from "../../components/Button";
import { Route, Switch } from "react-router-dom";
import RelationshipsTable from "./_partials/RelationshipsTable";
import AddRelationship from "./AddRelationship";
import PendingRelationshipsTable from "./_partials/PendingRelationshipsTable";
import Tabs from "../../components/Tabs";
import PageContainer from "../../components/PageContainer";
import ClientPageHeader, { Heading } from "../../components/ClientPageHeader";
import Spinner from "../../components/Spinner";
import ManageRelationship from "./ManageRelationship";
import useHasAnyPermissions from "hooks/useHasAnyPermissions";
import ErrorBoundary from "util/ErrorBoundary";
import { downloadFile } from "util/Downloader";
import { useToasts } from "react-toast-notifications";
import Api from "services/Api";
import { faCloudDownloadAlt } from "@fortawesome/free-solid-svg-icons";

export default function Relationships({ client, ...parentProps }) {
    const { addToast } = useToasts();
    const [isExporting, setIsExporting] = useState(false);
    const canDownloadReport = useHasAnyPermissions([
        "report_global_access",
        "report_personal_info_report",
    ]);

    async function exportPersonalInfoReport(e) {
        e.preventDefault();
        try {
            setIsExporting(true);
            let response = await Api.get(
                "clients/" + client.id + "/reports/personal-info",
                {
                    export: "pdf",
                    printBlankReport: 0,
                },
                { responseType: "arraybuffer" }
            );
            await downloadFile(response, "personal-info");
            setIsExporting(false);
            addToast("Export downloaded");
        } catch (e) {
            addToast(e.response.data.message, { type: "error" });
            setIsExporting(false);
        }
    }

    if (!client) {
        return <Spinner />;
    }

    const canManageAuthorizedParties = useHasAnyPermissions([
        "client_related_accounts_administration",
        "firm_client_related_accounts_administration",
    ]);

    return (
        <>
            <Switch>
                <Route
                    path={[
                        "/clients/:clientId/relationships/add",
                        "/client/relationships/add",
                    ]}
                    exact
                    render={(props) => (
                        <AddRelationship
                            client={client}
                            {...parentProps}
                            {...props}
                        />
                    )}
                />
                <Route
                    path={[
                        "/client/relationships/:relationshipId/edit",
                        "/clients/:clientId/relationships/:relationshipId/edit",
                        "/client/relationships/:relationshipId/edit-permissions",
                        "/clients/:clientId/relationships/:relationshipId/edit-permissions",
                    ]}
                    render={(props) => (
                        <ManageRelationship
                            client={client}
                            readOnly={false}
                            {...parentProps}
                            {...props}
                        />
                    )}
                />
                <Route
                    path={[
                        "/client/relationships/:relationshipId/view",
                        "/clients/:clientId/relationships/:relationshipId/view",
                    ]}
                    render={(props) => (
                        <ManageRelationship
                            readOnly={true}
                            {...parentProps}
                            {...props}
                        />
                    )}
                />
                <Route
                    path={[
                        "/client/relationships",
                        "/clients/:clientId/relationships",
                    ]}
                    render={() => (
                        <ErrorBoundary>
                            <ClientPageHeader client={client}>
                                <Heading>Relationships</Heading>
                                {canDownloadReport && (
                                    <div className="flex-0">
                                        <Button
                                            text="Export Personal Info Report"
                                            width="w-auto"
                                            isLoading={isExporting}
                                            appearance="light"
                                            icon={faCloudDownloadAlt}
                                            onClick={exportPersonalInfoReport}
                                        />
                                    </div>
                                )}
                                {canManageAuthorizedParties && (
                                    <div className="ml-2 flex-0">
                                        <Button
                                            type="link"
                                            to={`/clients/${client.id}/relationships/add`}
                                            text="Add Relationship"
                                        />
                                    </div>
                                )}
                            </ClientPageHeader>
                            <PageContainer>
                                <div className="flex space-x-8">
                                    <div className="flex-1">
                                        <Tabs
                                            {...parentProps}
                                            items={[
                                                {
                                                    label: "Active",
                                                    to:
                                                        "/clients/" +
                                                        client.id +
                                                        "/relationships",
                                                },
                                                {
                                                    label: "Disabled",
                                                    to:
                                                        "/clients/" +
                                                        client.id +
                                                        "/relationships/disabled",
                                                },
                                                {
                                                    label: "Invitations",
                                                    to:
                                                        "/clients/" +
                                                        client.id +
                                                        "/relationships/pending",
                                                },
                                            ]}
                                        />
                                        <Switch>
                                            <Route
                                                path={[
                                                    "/client/relationships/pending",
                                                    "/clients/:clientId/relationships/pending",
                                                ]}
                                                render={(props) => (
                                                    <PendingRelationshipsTable
                                                        {...parentProps}
                                                        {...props}
                                                        client={client}
                                                    />
                                                )}
                                            />
                                            <Route
                                                path={[
                                                    "/client/relationships/disabled",
                                                    "/clients/:clientId/relationships/disabled",
                                                ]}
                                                render={(props) => (
                                                    <RelationshipsTable
                                                        {...parentProps}
                                                        {...props}
                                                        client={client}
                                                        showDisabled={true}
                                                    />
                                                )}
                                            />
                                            <Route
                                                path={[
                                                    "/client/relationships",
                                                    "/clients/:clientId/relationships",
                                                ]}
                                                render={(props) => (
                                                    <RelationshipsTable
                                                        {...parentProps}
                                                        {...props}
                                                        client={client}
                                                        showDisabled={false}
                                                    />
                                                )}
                                            />
                                        </Switch>
                                    </div>
                                </div>
                            </PageContainer>
                        </ErrorBoundary>
                    )}
                />
            </Switch>
        </>
    );
}
