import React from "react";

import { Link } from "react-router-dom";
import Logo from "./Logo";
import TextInput from "components/TextInput";
import Button from "components/Button";

import ReCAPTCHA from "react-google-recaptcha";

export default function LoginForm({
    settings,
    message,
    errorMessage,
    login,
    updateValue,
    errors,
    loginInput,
    orgRedirect,
    isLoggingIn,
    recaptchaRef,
}) {
    return (
        <>
            <Logo organization={settings.organization} />
            {message && (
                <div className="max-w-xs mx-auto mb-3 p-3 text-center rounded-md bg-green-200 text-green-800">
                    {message}
                </div>
            )}
            {orgRedirect && (
                <div className="max-w-xs mx-auto mb-3 p-3 text-center rounded-md bg-green-200 text-green-800">
                    We redirected you to your organization. Please try logging
                    in again.
                </div>
            )}
            {errorMessage && (
                <div className="max-w-xs mx-auto mb-3 p-3 text-center rounded-md bg-gray-50 text-red-600">
                    {errorMessage}
                </div>
            )}
            <form onSubmit={login} className="max-w-xs mx-auto w-full">
                <ReCAPTCHA
                    ref={recaptchaRef}
                    size="invisible"
                    sitekey={process.env.REACT_APP_NOCAPTCHA_SITEKEY}
                />

                <div className="mb-6 relative flex space-x-2 items-center">
                    <span className="text-gray-500">
                        <svg
                            className="h-6 w-6"
                            fill="none"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                        >
                            <path d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"></path>
                        </svg>
                    </span>
                    <div className="flex-1">
                        <TextInput
                            className="border-b border-gray-400 outline-none h-10 w-full px-1"
                            name="email"
                            type="email"
                            styleType={false}
                            id="email"
                            value={loginInput.email}
                            onChange={updateValue}
                            error={errors.email}
                            autoFocus={true}
                            placeholder="Email Address"
                            autoComplete="username"
                        />
                    </div>
                </div>

                <div className="mb-6 relative flex space-x-2 items-center">
                    <span className="text-gray-500">
                        <svg
                            className="h-6 w-6"
                            fill="none"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                        >
                            <path d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z"></path>
                        </svg>
                    </span>
                    <div className="flex-1">
                        <TextInput
                            className="border-b border-gray-400 outline-none h-10 w-full px-1"
                            styleType={false}
                            name="password"
                            id="password"
                            type="password"
                            value={loginInput.password}
                            onChange={updateValue}
                            error={errors.password}
                            placeholder="Password"
                            autoComplete="current-password"
                        />
                    </div>
                </div>

                <div className="w-64 mx-auto">
                    <Button
                        type="submit"
                        text="Sign In"
                        isLoading={isLoggingIn}
                    />
                </div>
            </form>

            <div className="mt-6 text-center">
                <Link to="/login/forgot" className="text-brand-600 text-sm">
                    Forgot Password?
                </Link>
            </div>
        </>
    );
}
