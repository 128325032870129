import React from "react";
import { Route, Switch } from "react-router-dom";
import PageContainer from "../../components/PageContainer";
import Button from "../../components/Button";
import Tabs from "../../components/Tabs";
import ClientPageHeader, { Heading } from "../../components/ClientPageHeader";
import AdvisorsTable from "./_partials/AdvisorsTable";
import ErrorBoundary from "util/ErrorBoundary";

export default function AdvisorsIndex({ client, user, clientPath, ...props }) {
    const canAddAdvisors = user.role !== "client_successor";

    return (
        <>
            <ClientPageHeader client={client}>
                <Heading>Advisors</Heading>
                {canAddAdvisors && (
                    <div className="flex-0">
                        <Button
                            type="link"
                            to={`${clientPath}/advisors/add`}
                            text="Add Advisor"
                        />
                    </div>
                )}
            </ClientPageHeader>
            <PageContainer>
                <ErrorBoundary>
                    <div className="flex space-x-8">
                        <div className="flex-1">
                            <Tabs
                                {...props}
                                items={[
                                    {
                                        label: "Active",
                                        to: clientPath + "/advisors",
                                    },
                                    {
                                        label: "Disabled",
                                        to: clientPath + "/advisors/disabled",
                                    },
                                ]}
                            />
                            <Switch>
                                <Route
                                    path={clientPath + "/advisors/disabled"}
                                    render={(newProps) => (
                                        <AdvisorsTable
                                            client={client}
                                            user={user}
                                            clientPath={clientPath}
                                            {...props}
                                            {...newProps}
                                            showDisabled={true}
                                        />
                                    )}
                                />
                                <Route
                                    path={clientPath + "/advisors"}
                                    render={(newProps) => (
                                        <AdvisorsTable
                                            client={client}
                                            user={user}
                                            clientPath={clientPath}
                                            {...props}
                                            {...newProps}
                                            showDisabled={false}
                                        />
                                    )}
                                />
                            </Switch>
                        </div>
                    </div>
                </ErrorBoundary>
            </PageContainer>
        </>
    );
}
