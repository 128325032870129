import Api from "./Api";

export const fetchMembers = async function fetchAttorneyOptions(
    query,
    params = { limit: 100 }
) {
    let response = await Api.get("members", {
        "filter[search]": query,
        "filter[role]": ["member_owner", "member_partner"],
        limit: params.limit || 100,
    });

    return response.data.data;
};

export const fetchAttorneys = async function fetchAttorneyOptions(
    query,
    params = { limit: 100 }
) {
    let data = await fetchMembers(query, params);

    return data.map((attorney) => {
        return {
            id: attorney.id,
            value: attorney.id.toString(),
            label: attorney.full_name,
        };
    });
};

export default {
    fetchMembers,
    fetchAttorneys,
};
