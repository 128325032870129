import * as qs from "query-string";

function getSearch() {
    return qs.parse(window.location.search);
}

function input(param) {
    const params = qs.parse(window.location.search);
    return params[param];
}

function useQueryParam(param) {
    const params = qs.parse(window.location.search);
    return params[param];
}

function redirectToSubdomain(subdomain, providedPath) {
    let userProfile = JSON.parse(
        window.sessionStorage.getItem("con2a:user_profile")
    );
    if (userProfile && userProfile.user.role === "advisor") {
        subdomain = null;
    }

    const { protocol, host, pathname: currentPath } = window.location;
    const path =
        providedPath && providedPath.length ? providedPath : currentPath;
    let currentSubdomain = host.split(".")[0];
    if (subdomain && currentSubdomain && currentSubdomain !== subdomain) {
        window.location.href = protocol + "//" + subdomain + "." + host + path;
        return true;
    }
}

function redirectToRootDomain(path) {
    const { port } = window.location;
    const { protocol, host, pathname } = new URL(
        process.env.REACT_APP_BASE_URL
    );
    window.location.href =
        protocol +
        "//" +
        host +
        (port ? ":" + port : "") +
        pathname +
        (path || "");
}

function isRootDomain() {
    const { host } = new URL(process.env.REACT_APP_BASE_URL);
    if (window.location.hostname === host) {
        return true;
    }

    return false;
}

const getProp = (o, p) =>
    (Array.isArray(p) ? p : p.split(".")).reduce(
        (xs, x) => (xs && xs[x] ? xs[x] : null),
        o
    );

const snakeToSentenceCase = (subject) => {
    if (typeof subject === "string") {
        subject = subject.toLowerCase().split("_");
        for (var i = 0; i < subject.length; i++) {
            subject[i] =
                subject[i].charAt(0).toUpperCase() + subject[i].slice(1);
        }
        return subject.join(" ");
    }

    return subject;
};

function slugify(string) {
    // https://gist.github.com/hagemann/382adfc57adbd5af078dc93feef01fe1
    const a =
        "àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìıİłḿñńǹňôöòóœøōõőṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/_,:;";
    const b =
        "aaaaaaaaaacccddeeeeeeeegghiiiiiiiilmnnnnoooooooooprrsssssttuuuuuuuuuwxyyzzz------";
    const p = new RegExp(a.split("").join("|"), "g");

    return string
        .toString()
        .toLowerCase()
        .replace(/\s+/g, "") // Replace spaces with -
        .replace(p, (c) => b.charAt(a.indexOf(c))) // Replace special characters
        .replace(/&/g, "-and-") // Replace & with 'and'
        .replace(/[^\w]+/g, "") // Remove all non-word characters
        .replace(/--+/g, "-") // Replace multiple - with single -
        .replace(/^-+/, "") // Trim - from start of text
        .replace(/-+$/, ""); // Trim - from end of text
}

function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
}

export {
    getSearch,
    input,
    getProp,
    redirectToSubdomain,
    redirectToRootDomain,
    isRootDomain,
    snakeToSentenceCase,
    useQueryParam,
    slugify,
    classNames,
};
