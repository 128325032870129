import React from "react";
import useClient from "hooks/useClient";
import RelationshipLayout from "./RelationshipLayout";
import Button from "components/Button";
import Panel, { PanelHeader } from "components/Panel";
import TextInput from "components/TextInput";
import useAuthorizedPartyInvite from "../../hooks/useAuthorizedPartyInvite";
import { formatDate, formatDateTime } from "util/TextUtil";
import useCreateAuthorizedPartyInvite from "../../hooks/useCreateAuthorizedPartyInvite";
import useRevokeAuthorizedPartyInvite from "../../hooks/useRevokeAuthorizedPartyInvite";
import useForm from "hooks/useForm";

export default function RelationshipAccess({
    relationshipId,
    relationshipInput,
    readOnly = false,
    currentBasePath,
}) {
    const { client } = useClient();

    const { input, updateValue, clearUnsavedChanges } = useForm({
        ...relationshipInput,
    });

    const {
        data: invite,
        remove,
        isLoading,
        refetch,
    } = useAuthorizedPartyInvite({
        clientId: client.id,
        relationshipId,
    });

    const {
        onSubmit: onCreateInvitation,
        isLoading: isCreating,
        validationErrors,
    } = useCreateAuthorizedPartyInvite(
        {
            clientId: client.id,
            relationshipId,
        },
        {
            onSuccess: () => {
                remove();
                refetch();
                clearUnsavedChanges();
            },
        }
    );

    const { onSubmit: onRevokeInvitation, isLoading: isRevoking } =
        useRevokeAuthorizedPartyInvite(
            {
                clientId: client.id,
                relationshipId,
            },
            {
                onSuccess: () => {
                    remove();
                    refetch();
                    clearUnsavedChanges();
                },
            }
        );

    async function inviteRelationship(e) {
        e.preventDefault();
        onCreateInvitation(e, {
            email: input?.email,
            authorized_party_role:
                relationshipInput.type == "spouse"
                    ? "client_spouse"
                    : "client_successor",
        });
    }

    async function revokeRelationship(e) {
        e.preventDefault();
        onRevokeInvitation(e, {
            inviteId: invite.id,
        });
    }

    return (
        <RelationshipLayout
            relationshipInput={relationshipInput}
            readOnly={readOnly}
            currentBasePath={currentBasePath}
        >
            <div className="space-y-10">
                {relationshipInput.authorized_party ? (
                    <Panel appearance="flat">
                        <PanelHeader title="Authorized Party Access" />
                        {relationshipInput.full_name} is an authorized party for{" "}
                        {client.full_name}.
                    </Panel>
                ) : (
                    <Panel>
                        <PanelHeader title="Designate Authorized Party" />
                        {invite && invite.is_expired ? (
                            <>
                                <p className="text-sm leading-5 text-red-600">
                                    {`Invitation was sent on ${formatDateTime(
                                        invite?.updated_at
                                    )}. The invite expired on ${formatDate(
                                        invite?.expires_at
                                    )}.`}
                                </p>
                                <p className="text-sm leading-5 text-gray-700 mb-4">
                                    Please revoke this invitation and resend it.
                                </p>
                            </>
                        ) : (
                            <p className="text-sm leading-5 text-gray-700 mb-4">
                                {invite !== undefined
                                    ? `Invitation was sent on ${formatDateTime(
                                          invite?.updated_at
                                      )}. The invite expires on ${formatDate(
                                          invite?.expires_at
                                      )}.`
                                    : `Enter an email for ${relationshipInput.full_name} to invite them to be an authorized party for ${client.full_name}`}
                            </p>
                        )}
                        <form onSubmit={inviteRelationship}>
                            <div className="flex items-center space-x-2">
                                <div className="flex-1">
                                    <TextInput
                                        type="email"
                                        name="email"
                                        label="Email"
                                        id="email"
                                        readOnly={invite?.email}
                                        value={invite?.email || input?.email}
                                        error={validationErrors.email}
                                        onChange={updateValue}
                                    />
                                </div>
                                <div className="mt-6 w-28 ml-auto">
                                    {invite !== undefined ? (
                                        <Button
                                            text={"Revoke"}
                                            appearance={"danger"}
                                            disabled={isLoading || isRevoking}
                                            onClick={revokeRelationship}
                                        />
                                    ) : (
                                        <Button
                                            text={"Invite"}
                                            type="submit"
                                            disabled={
                                                isLoading ||
                                                isCreating ||
                                                input.email?.length === 0
                                            }
                                        />
                                    )}
                                    <input type="submit" hidden />
                                </div>
                            </div>
                        </form>
                    </Panel>
                )}
            </div>
        </RelationshipLayout>
    );
}
