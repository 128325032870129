import React, { useState, useEffect } from "react";

import { redirectToSubdomain } from "../../Utility";

import Auth from "../../services/Auth";
import useQuery from "../../hooks/useQuery";
import { useToasts } from "react-toast-notifications";
import { useQueryParam } from "../../Utility";
import useAuth from "hooks/useAuth";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router";
import useForm from "hooks/useForm";

export default function useLogin() {
    const context = useAuth();

    const recaptchaRef = React.useRef(null);

    const history = useHistory();
    const location = useLocation();

    const search = useQuery();
    const flashedEmail = useQueryParam("email");

    const { removeAllToasts } = useToasts();

    const {
        input: loginInput,
        setInput,
        updateValue,
        clearUnsavedChanges,
    } = useForm({
        email: flashedEmail || "",
        password: "",
        password_confirmation: "",
    });

    const [isLoggingIn, setIsLoggingIn] = useState(false);
    const [errors, setErrors] = useState([]);
    const [errorMessage, setErrorMessage] = useState("");

    const [authOptions, setAuthOptions] = useState([]);
    const [showVerifyOtp, setShowVerifyOtp] = useState(false);
    const [showChangePassword, setShowChangePassword] = useState(false);

    useEffect(() => {
        clearUnsavedChanges();
    }, [loginInput]);

    let { from } = (location?.from && location) || {
        from: { pathname: decodeURIComponent(search.redirect || "/") },
    };
    if (from.pathname === "/logout") {
        from.pathname = "/";
    }

    if (from.pathname !== "/") {
        window.sessionStorage.setItem("con2a:redirect", from.pathname);
    } else {
        window.sessionStorage.removeItem("con2a:redirect");
    }

    if (context.needs2fa && !showVerifyOtp) {
        setShowVerifyOtp(context.needs2fa);
        setAuthOptions(context.options2fa);
        setShowChangePassword(false);
    } else if (context.needsPasswordChange && !showChangePassword) {
        setShowVerifyOtp(false);
        setShowChangePassword(true);
    }

    const redirectToReferrer = () => {
        history.replace(from);
    };

    const redirectToRegistration = () => {
        history.replace("/register/organization-step-2");
    };

    const checkAuth = async () => {
        const { data } = await context.checkAuth();
        handleAuthSuccess({ data });
    };

    const login = async (e) => {
        e.preventDefault();
        setErrors({});
        setErrorMessage("");

        const { email, password } = loginInput;

        try {
            setIsLoggingIn(true);
            const recaptcha = await recaptchaRef?.current.executeAsync();
            recaptchaRef?.current?.reset();
            await Auth.login({ email, password, recaptcha });
            await checkAuth();
            setIsLoggingIn(false);
        } catch (error) {
            console.error(error);
            setInput({ password: "" });
            setIsLoggingIn(false);

            if (!error.response) {
                console.log(error);
                return;
            }

            if (error.response.status === 409) {
                let data = error.response.data;

                if (data.type === "2fa") {
                    setAuthOptions(error.response.data.options);
                    setShowVerifyOtp(true);
                } else if (data.type === "password") {
                    setShowChangePassword(true);
                }
                return;
            }

            if (error?.response?.status === 423) {
                setErrorMessage(error.response.data.message);
                return;
            }

            if (error?.response?.data?.ERROR_CODE === "WRONG_ORG") {
                window.location =
                    error.response.data.data.login_url +
                    `?email=${encodeURIComponent(
                        email
                    )}&organization_redirect=true`;
            }

            if (error?.response?.data?.errors) {
                setErrors(error.response.data.errors);

                if (error.response.data.errors.recaptcha) {
                    setErrorMessage(
                        "Form expired. Please reload the page and try again."
                    );
                }
                return;
            }

            setErrorMessage(error.response.data.message || "");
            setErrors({});
        }
    };

    const onVerify = async () => {
        await checkAuth();
    };

    const handleAuthSuccess = ({ data }) => {
        if (data && data.code === "NO_ORG") {
            return redirectToRegistration();
        }

        setShowVerifyOtp(false);
        setAuthOptions([]);

        if (data && data.user) {
            const user = data.user;
            const organization = data.organization;
            const orgSubdomain = data.subdomain;

            context.setUser(user);
            context.setOrganization(organization);
            context.setIsLoggedIn(true);
            removeAllToasts();

            if (organization === null) {
                return redirectToRegistration();
            }

            if (orgSubdomain) {
                if (
                    redirectToSubdomain(
                        orgSubdomain,
                        location.from && location.from.pathname
                    )
                ) {
                    return;
                }
            }
        }

        redirectToReferrer();
    };

    return {
        loginInput,
        updateValue,
        login,
        isLoggingIn,
        errors,
        authOptions,
        onVerify,
        errorMessage,
        showChangePassword,
        showVerifyOtp,
        recaptchaRef,
    };
}
