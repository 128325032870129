import useFetch from "./useFetch";
import Api from "services/Api";

const fetchResourceAccess = ({ queryKey }) => {
    const [, { resourceType, resourceId }] = queryKey;
    return Api.get(`resources/access/${resourceType}/${resourceId}`).then(
        (res) => res.data
    );
};

export default function useResourceAccess(
    resourceType,
    resourceId,
    config = { initialData: [] }
) {
    return useFetch(
        ["resource-access", { resourceType, resourceId }],
        fetchResourceAccess,
        config
    );
}
