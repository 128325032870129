import React from "react";
import Checkbox from "../../../components/Checkbox";
import InsuredSelect from "../../../components/InsuredSelect";
import TextInput from "../../../components/TextInput";
import ManagedByAdvisor from "../../../components/ManagedByAdvisor";
import useFormatCurrency from "../../../hooks/useFormatCurrency";
import SelectInput from "../../../components/SelectInput";

export default function AnnuityForm({
    client,
    input,
    errors,
    readOnly = false,
    updateValue,
    onSubmit,
}) {
    const formatCurrency = useFormatCurrency();

    return (
        <form onSubmit={onSubmit}>
            <div>
                <div className="mb-6">
                    <TextInput
                        name="provider_name"
                        label="Company Name"
                        id="provider_name"
                        readOnly={readOnly}
                        disabled={readOnly}
                        value={input.provider_name}
                        error={errors.provider_name}
                        onChange={updateValue}
                        required={true}
                        autoFocus={true}
                    />
                </div>

                <div className="mb-6">
                    <TextInput
                        type="name"
                        name="name"
                        label="Policy Name"
                        readOnly={readOnly}
                        disabled={readOnly}
                        id="name"
                        value={input.name}
                        error={errors.name}
                        onChange={updateValue}
                        required={true}
                    />
                </div>

                <div className="mb-6">
                    <TextInput
                        name="number"
                        label="Policy Number"
                        id="number"
                        readOnly={readOnly}
                        disabled={readOnly}
                        value={input.number}
                        error={errors.number}
                        onChange={updateValue}
                    />
                </div>

                <div className="mb-6">
                    <SelectInput
                        type="type"
                        disabled={readOnly}
                        name="type"
                        label="Policy Type"
                        id="type"
                        value={input.type}
                        error={errors.type}
                        onChange={updateValue}
                        options={[
                            { value: "fixed_annuity", label: "Fixed Annuity" },
                            {
                                value: "variable_annuity",
                                label: "Variable Annuity",
                            },
                        ]}
                    />
                </div>

                <div className="mb-6">
                    <SelectInput
                        type="qualified"
                        disabled={readOnly}
                        name="qualified"
                        label="Qualified/Non-qualified"
                        id="qualified"
                        value={input.qualified}
                        error={errors.qualified}
                        onChange={updateValue}
                        defaultOption={"Choose an option..."}
                        defaultOptionDisabled={true}
                        options={[
                            { value: "nonqualified", label: "Non-Qualified" },
                            { value: "qualified", label: "Qualified" },
                        ]}
                    />
                </div>

                <fieldset className="mt-12">
                    <legend className="font-bold txt-md text-gray-500 my-6 pb-1 border-b block w-full">
                        Annuitants
                    </legend>

                    <div className="flex space-x-3 mb-6">
                        <div className="flex-1">
                            <InsuredSelect
                                client={client}
                                disabled={readOnly}
                                value={input.annuitant}
                                name="annuitant"
                                label="Annuitant"
                                onChange={updateValue}
                            />
                        </div>

                        {input.annuitant === "other" && (
                            <div className="flex-1">
                                <TextInput
                                    client={client}
                                    value={input.annuitant_other}
                                    name="annuitant_other"
                                    readOnly={readOnly}
                                    disabled={readOnly}
                                    label="Other Annuitant Name"
                                    onChange={updateValue}
                                    error={errors.annuitant_other}
                                />
                            </div>
                        )}
                    </div>

                    <div className="flex space-x-3 mb-6">
                        <div className="flex-1">
                            <InsuredSelect
                                client={client}
                                disabled={readOnly}
                                value={input.annuitant_2}
                                name="annuitant_2"
                                label="Other Annuitant"
                                onChange={updateValue}
                            />
                        </div>

                        {input.annuitant_2 === "other" && (
                            <div className="flex-1">
                                <TextInput
                                    client={client}
                                    value={input.annuitant_2_other}
                                    name="annuitant_2_other"
                                    readOnly={readOnly}
                                    disabled={readOnly}
                                    label="Other Annuitant Name"
                                    onChange={updateValue}
                                    error={errors.annuitant_2_other}
                                />
                            </div>
                        )}
                    </div>
                </fieldset>

                <fieldset className="mt-12">
                    <legend className="font-bold txt-md text-gray-500 my-6 pb-1 border-b block w-full">
                        Policy Amounts
                    </legend>

                    <div className="flex space-x-4 mb-6">
                        <div className="flex-1">
                            <TextInput
                                name="value"
                                label="Cash Value"
                                id="value"
                                readOnly={readOnly}
                                disabled={readOnly}
                                value={input.value}
                                error={errors.value}
                                onChange={updateValue}
                                onBlur={(e) => {
                                    e.target.value = formatCurrency(
                                        e.target.value
                                    );
                                    updateValue(e);
                                }}
                                placeholder="0.00"
                            />
                        </div>

                        <div className="flex-1">
                            <TextInput
                                type="date"
                                name="as_of_date"
                                label="As of Date"
                                readOnly={readOnly}
                                disabled={readOnly}
                                id="as_of_date"
                                value={input.as_of_date}
                                error={errors.as_of_date}
                                onChange={updateValue}
                            />
                        </div>
                    </div>

                    <div className="flex space-x-4 mb-6">
                        <div className="flex-1">
                            <TextInput
                                name="death_benefit"
                                label="Death Benefit"
                                id="death_benefit"
                                readOnly={readOnly}
                                disabled={readOnly}
                                value={input.death_benefit}
                                error={errors.death_benefit}
                                onChange={updateValue}
                                onBlur={(e) => {
                                    e.target.value = formatCurrency(
                                        e.target.value
                                    );
                                    updateValue(e);
                                }}
                                placeholder="0.00"
                            />
                        </div>
                    </div>
                    <div className="mb-6">
                        <TextInput
                            name="loan_amount"
                            label="Loan Amount"
                            id="loan_amount"
                            readOnly={readOnly}
                            disabled={readOnly}
                            value={input.loan_amount}
                            error={errors.loan_amount}
                            onChange={updateValue}
                            onBlur={(e) => {
                                e.target.value = formatCurrency(e.target.value);
                                updateValue(e);
                            }}
                            placeholder="0.00"
                        />
                    </div>
                </fieldset>

                <Checkbox
                    name="annuitized"
                    id="annuitized"
                    value={input.annuitized}
                    disabled={readOnly}
                    readOnly={readOnly}
                    onChange={updateValue}
                    label="Annuitized"
                    defaultChecked={input.annuitized}
                />

                {parseInt(input.annuitized) === 1 && (
                    <div className="flex space-x-3 mt-6">
                        <div className="flex-1">
                            <TextInput
                                type="date"
                                name="annuitized_date"
                                label="Date Annuitized"
                                readOnly={readOnly}
                                disabled={readOnly}
                                id="annuitized_date"
                                value={input.annuitized_date}
                                error={errors.annuitized_date}
                                onChange={updateValue}
                                required={input.annuitized}
                            />
                        </div>

                        <div className="flex-1">
                            <TextInput
                                name="annuity_payment"
                                label="Annuity Payment"
                                id="annuity_payment"
                                readOnly={readOnly}
                                disabled={readOnly}
                                value={input.annuity_payment}
                                error={errors.annuity_payment}
                                onChange={updateValue}
                                required={input.annuitized}
                                onBlur={(e) => {
                                    e.target.value = formatCurrency(
                                        e.target.value
                                    );
                                    updateValue(e);
                                }}
                                placeholder="0.00"
                            />
                        </div>
                    </div>
                )}

                <fieldset className="my-12">
                    <legend className="font-bold txt-md text-gray-500 my-6 pb-1 border-b block w-full">
                        Managed By
                    </legend>
                    <ManagedByAdvisor
                        client={client}
                        input={input}
                        disabled={readOnly}
                        errors={errors}
                        updateValue={updateValue}
                    />
                </fieldset>

                <input type="submit" hidden />
            </div>
        </form>
    );
}
