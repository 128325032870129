import useFetch from "./useFetch";
import { fetchQuestionnairesForClient } from "../services/QuestionnaireService";

export default function useClientQuestionnaires(
    params,
    config = { keepPreviousData: true }
) {
    return useFetch(
        ["fetchQuestionnairesForClient", params],
        fetchQuestionnairesForClient,
        config
    );
}
