import React, { useState } from "react";
import Button from "../../../components/Button";
import TextArea from "../../../components/TextArea";
import { InputLabel } from "../../../components/TextInput";
import { faComment, faCommentDots } from "@fortawesome/free-solid-svg-icons";

export default function QuestionWrapper({
    question,
    onUpdate,
    response,
    children,
    disabled = false,
}) {
    const [comments, setComments] = useState(
        question.answer ? question.answer.comments : ""
    );
    const [showComment, setShowComment] = useState(false);

    return (
        <>
            <div>
                <div className="flex space-between items-start">
                    <div className="flex-1 mb-3">
                        <InputLabel text={question.title} />
                    </div>
                    <div className="flex-0">
                        <Button
                            className={`block ml-3 text-gray-300 hover:text-gray-400 ${
                                comments && comments.length
                                    ? "text-green-300"
                                    : ""
                            }`}
                            type="button"
                            appearance="none"
                            icon={
                                comments && comments.length
                                    ? faCommentDots
                                    : faComment
                            }
                            onClick={() => setShowComment(!showComment)}
                            title={"Show Comments"}
                        />
                    </div>
                </div>

                <div>{children}</div>

                {showComment && (
                    <div className="mt-6">
                        <TextArea
                            name="comment"
                            value={comments || ""}
                            onChange={(e) => setComments(e.target.value)}
                            onBlur={() => onUpdate({ response, comments })}
                            placeholder={
                                "Enter any additional comments to help clarify your answer."
                            }
                            autoFocus={showComment}
                            disabled={response.length === 0 || disabled}
                        />
                    </div>
                )}
            </div>
        </>
    );
}
