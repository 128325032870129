import React, { useState, useEffect } from "react";
import PageContainer from "../../components/PageContainer";
import ClientNav from "./_partials/ClientNav";
import ClientPageHeader, { Heading } from "../../components/ClientPageHeader";
import ProfilePhoto from "../members/_partials/ProfilePhoto";
import ClientProfileForm from "./_partials/ClientProfileForm";
import Tabs from "../../components/Tabs";

function ViewClientProfile({ client, ...props }) {
    const [selectedTab, setSelectedTab] = useState(0);

    // const limitedAccess = user.id !== client.id && user.type !== 'member';
    const limitedAccess = true;

    useEffect(() => {
        document.title = "View Profile: " + client.full_name;
    }, [client]);

    return (
        <>
            <ClientPageHeader client={client} {...props}>
                <Heading>View Profile</Heading>
            </ClientPageHeader>
            <PageContainer>
                {!!client.spouse !== false && (
                    <Tabs
                        {...props}
                        selectedIndex={selectedTab}
                        items={[
                            {
                                label: "Client",
                                onClick: () => {
                                    setSelectedTab(0);
                                },
                            },
                            {
                                label: "Spouse",
                                onClick: () => {
                                    setSelectedTab(1);
                                },
                            },
                        ]}
                    />
                )}

                <div className="flex space-x-10">
                    {limitedAccess === false && (
                        <div className="flex-initial">
                            <ClientNav
                                active={props.history.location.pathname}
                                client={client}
                            />
                        </div>
                    )}

                    <div className="flex-1">
                        <form
                            onSubmit={(e) => {
                                e.preventDefault();
                            }}
                        >
                            <ClientProfileForm
                                client={
                                    selectedTab === 0 ? client : client.spouse
                                }
                                readOnly={true}
                                errors={{}}
                                updateValue={() => {}}
                            />
                        </form>
                    </div>

                    <div className="flex-initial">
                        <ProfilePhoto
                            readOnly={limitedAccess}
                            memberId={client.id}
                            photo={client.profile_photo}
                            upload={() => {}}
                        />
                    </div>
                </div>
            </PageContainer>
        </>
    );
}

export default ViewClientProfile;
