import React, { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import { uploadFileToVault } from "services/VaultService";
import { faFile, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "../../../../components/Button";
import TextInput from "../../../../components/TextInput";
import { formatBytes } from "../../../../util/TextUtil";
import { iconColorForVault } from "../_partials/helpers";
import InputErrors from "../../../../components/InputErrors";
import { isFileImage } from "../helpers";
import MoveFile from "./MoveFile";
import { useToasts } from "react-toast-notifications";

export default function UploadFile({
    client,
    parentId: initialParentId,
    defaultFolderName: initialDefaultFolderName = null,
    onResult,
}) {
    const [hover, setHover] = useState(false);
    const { addToast } = useToasts();

    const [parentId, setParentId] = useState(initialParentId);
    const [defaultFolderName, setDefaultFolderName] = useState(
        initialDefaultFolderName
    );

    const [nameWithoutExtension, setNameWithoutExtension] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [file, setFile] = useState(false);
    const [errors, setErrors] = useState({});
    const [blob, setBlob] = useState(false);
    const [fileDescription, setFileDescription] = useState("");

    const extension = (file ? file.name.split(".").pop() : "").toLowerCase();
    const isImage = file ? isFileImage({ extension, type: file.type }) : false;

    const onDrop = useCallback((acceptedFiles) => {
        const main = async () => {
            let file = acceptedFiles[0];
            if (file) {
                setFile(file);
                let pieces = file ? file.name.split(".") : [];
                let extension = pieces.length > 1 ? pieces.pop() : "";
                setNameWithoutExtension(file.name.replace("." + extension, ""));

                if (file.size > Math.pow(1024, 2) * 50) {
                    setErrors({ size: ["Size cannot exceed 50 MB."] });
                }

                extension = extension.toLowerCase();
                const isImage = isFileImage({ extension, type: file.type });

                if (isImage) {
                    var reader = new FileReader();
                    reader.readAsDataURL(file);
                    reader.onload = function (e) {
                        setBlob(e.target.result);
                    };
                }
            }
        };
        main();
    }, []);

    const { getRootProps, getInputProps } = useDropzone({ onDrop });

    const processErrors = (errors) => {
        const newErrors = {};
        Object.keys(errors).forEach((key) => {
            const newKey = key.replace("json.", "");
            newErrors[newKey] = errors[key].map((error) =>
                error.replace("json.", "")
            );
        });
        setErrors(newErrors);
    };

    const uploadChunked = async () => {
        setIsLoading(1);
        return uploadFileToVault({
            client,
            nameWithoutExtension,
            extension,
            description: fileDescription,
            file: file,
            parentId,
            defaultFolderName,
            onProgress: (percentCompleted) => {
                setIsLoading(percentCompleted);
            },
        });
    };

    const onUploadFile = async () => {
        setErrors({});

        try {
            const response = await uploadChunked();
            setIsLoading(false);
            onResult({ result: response.data.vault });
        } catch (e) {
            setIsLoading(false);

            if (!e.response) {
                addToast("File Upload Error: No Response.", { type: "error" });
                console.error(e);
                return;
            }

            if (typeof e.response.data === "object") {
                if (e?.response?.data?.errors) {
                    processErrors(e?.response?.data?.errors);
                } else if (e?.response?.data?.errorMessage) {
                    setErrors({ message: [e.response.data.errorMessage] });
                } else if (e?.response?.data?.message) {
                    setErrors({ message: [e.response.data.message] });
                } else {
                    setErrors({
                        message: [`File Upload Error (${e.response.status})`],
                    });
                }
                return;
            }

            if (e.response.status === 403) {
                addToast(`File Upload Error (WAF - ${e.response.status})`, {
                    type: "error",
                });
            } else {
                addToast(`File Upload Error (${e.response.status})`, {
                    type: "error",
                });
            }
        }
    };

    const clearFile = () => {
        setFile(null);
        setBlob(null);
        setFileDescription(null);
        setNameWithoutExtension(null);
        setErrors({});
    };

    let fileErrors = [];
    ["file", "fileType", "fileMimeTypeGroup", "size", "fileSize"].forEach(
        (key) => {
            if (errors[key]) fileErrors = fileErrors.concat(errors[key]);
        }
    );

    return (
        <>
            <div className="my-4">
                {!!defaultFolderName && initialDefaultFolderName ? (
                    <div className="flex flex-wrap justify-between items-center">
                        <div>
                            Default Folder: <strong>{defaultFolderName}</strong>
                        </div>
                        <Button
                            width="w-auto"
                            appearance="link"
                            text="Select Folder Manually"
                            onClick={() => {
                                setDefaultFolderName(null);
                            }}
                        />
                    </div>
                ) : (
                    <div className="flex flex-wrap justify-between items-center">
                        Select a location:
                        {initialDefaultFolderName && (
                            <Button
                                width="w-auto"
                                appearance="link"
                                text="Use Default Folder"
                                onClick={() => {
                                    setDefaultFolderName(
                                        initialDefaultFolderName
                                    );
                                    setParentId(null);
                                }}
                            />
                        )}
                    </div>
                )}
            </div>
            <div className={"my-4"}>
                {!defaultFolderName && (
                    <MoveFile
                        client={client}
                        parentId={parentId}
                        isLoading={isLoading}
                        emptyMessage="Upload Here"
                        showButtons={false}
                        rootVaultPath={"/"}
                        onChange={(parentId) => {
                            console.log(parentId);
                            setParentId(parentId);
                        }}
                        onResult={() => {}}
                    />
                )}
            </div>

            <hr />
            <p className="my-4">Select a File:</p>
            {file ? (
                <div>
                    {isImage && (
                        <div>
                            {blob && (
                                <img
                                    src={blob}
                                    className="object-contain h-56 w-56 mx-auto mb-4"
                                    alt="Preview"
                                />
                            )}
                        </div>
                    )}
                    {!!isImage !== true && (
                        <div className="flex justify-center align-center mb-4">
                            <div className="relative">
                                <FontAwesomeIcon
                                    className={`${iconColorForVault({
                                        extension,
                                    })}`}
                                    icon={faFile}
                                    size="8x"
                                />
                                <div className="absolute inset-0 flex items-center justify-center">
                                    <div className="text-md font-bold text-white">
                                        {extension.toUpperCase()}
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}

                    <InputErrors errors={errors.message} />

                    <div className="mt-4">
                        <TextInput
                            type={"text"}
                            name={"filename"}
                            label={"File Name"}
                            value={nameWithoutExtension}
                            onChange={(e) =>
                                setNameWithoutExtension(e.target.value)
                            }
                            error={errors.name ? errors.name : errors.extension}
                        />
                    </div>

                    <div className="mt-4">
                        <TextInput
                            type={"text"}
                            name={"description"}
                            placeholder={"Description"}
                            label={"Description"}
                            // id=""
                            value={fileDescription}
                            onChange={(e) => setFileDescription(e.target.value)}
                            error={errors.description}
                        />
                    </div>

                    <div className="mt-4">
                        <TextInput
                            type={"text"}
                            name={"size"}
                            label={"Size"}
                            disabled={true}
                            readOnly={true}
                            error={fileErrors}
                            value={formatBytes(file.size)}
                        />
                    </div>

                    {isLoading !== false && (
                        <div className="mt-4 h-6 shadow w-full bg-grey-100 rounded-md flex flex-row content-center">
                            <div
                                className="rounded-md bg-blue-500 h-full transition-all ease-in-out duration-100 text-xs leading-none py-1 text-center text-white"
                                style={{ width: `${isLoading}%` }}
                            >
                                {isLoading}%
                            </div>
                        </div>
                    )}
                    {isLoading === false && (
                        <Button
                            className={"mt-4 hover:text-blue-700 text-blue-500"}
                            appearance="link"
                            onClick={() => clearFile()}
                        >
                            <FontAwesomeIcon
                                icon={faTimesCircle}
                                size="1x"
                                className="mr-2"
                            />
                            <span>Clear File</span>
                        </Button>
                    )}
                </div>
            ) : (
                <div
                    className="relative z-0 cursor-pointer flex-col justify-center items-center"
                    onMouseOver={() => setHover(true)}
                    onMouseLeave={() => setHover(false)}
                >
                    <div {...getRootProps()}>
                        <input
                            {...getInputProps({
                                multiple: false,
                            })}
                        />
                        <div className="flex items-center justify-center mx-auto text-center bg-gray-100 hover:bg-gray-200 hover:border-gray-400 border-2 border-dashed h-56 w-56 p-2">
                            <div>
                                <FontAwesomeIcon
                                    icon={faFile}
                                    size="8x"
                                    className="text-gray-400"
                                />
                                <p className="mt-2 text-gray-400 text-sm">
                                    + Add File
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {file && hover && (
                <>
                    <button
                        type="button"
                        className="absolute top-0 right-0 py-1 px-2 shadow-sm bg-white"
                        onClick={clearFile}
                    >
                        <FontAwesomeIcon
                            icon={faTimesCircle}
                            className="text-gray-500"
                        />
                    </button>
                    {/* <button
                    type="button"
                    className="absolute top-0 left-0 py-1 px-2 shadow-sm bg-white text-sm"
                    onClick={()=>setShowEditPhoto(true)}>Edit Size</button> */}
                </>
            )}
            <div className="flex items-center justify-center mt-6">
                <div className="mr-3 flex-1">
                    <Button
                        onClick={() => {
                            onResult();
                        }}
                        appearance="outline"
                        text="Cancel"
                    />
                </div>
                <div className="flex-1">
                    <Button
                        disabled={!!file === false}
                        isLoading={isLoading !== false}
                        onClick={async () => {
                            await onUploadFile();
                        }}
                        text="Upload"
                    />
                </div>
            </div>
        </>
    );
}
