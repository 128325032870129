import React from "react";
import { Link, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function VerticalTabs({ items = [], selectedIndex = -1 }) {
    const location = useLocation();
    const baseTabStyle =
        "group flex items-center px-3 py-2 text-sm leading-5 font-medium text-gray-600 rounded-md hover:text-gray-900 focus:outline-none transition ease-in-out duration-150";
    const inactiveTabStyle =
        "hover:text-gray-900 hover:bg-gray-50 focus:outline-none";
    const activeTabStyle =
        "text-brand-600 bg-brand-100 hover:text-brand-600 focus:outline-none";
    const baseIconStyle = "-ml-0.5 mr-2 h-5 w-5";
    const inactiveIconStyle =
        "text-gray-400 group-hover:text-gray-500 group-focus:text-gray-600";
    const activeIconStyle = "text-gray-500 group-focus:text-gray-700";

    const isItemSelected = (item) =>
        item.active ||
        (item.onClick
            ? selectedIndex > -1 && selectedIndex === items.indexOf(item)
            : location.pathname === item.to);

    const activeLinkClass = (item) => {
        if (isItemSelected(item)) {
            return baseTabStyle.concat(" ").concat(activeTabStyle);
        }

        return baseTabStyle.concat(" ").concat(inactiveTabStyle);
    };

    const activeIconClass = (item) => {
        if (isItemSelected(item)) {
            return baseIconStyle.concat(" ").concat(activeIconStyle);
        }

        return baseIconStyle.concat(" ").concat(inactiveIconStyle);
    };

    return (
        <nav className="w-56">
            {items.map((item) => {
                if (item.hidden) {
                    return <div className="hidden" key={item.label}></div>;
                }

                const options = {};
                if (item.onClick) {
                    options.onClick = item.onClick;
                    options.to = "#";
                } else {
                    options.to = item.to;
                }
                return (
                    <Link
                        {...options}
                        className={activeLinkClass(item)}
                        key={item.label}
                    >
                        {item.icon && (
                            <FontAwesomeIcon
                                icon={item.icon}
                                className={activeIconClass(item)}
                            />
                        )}
                        <span>{item.label}</span>
                    </Link>
                );
            })}
        </nav>
    );
}

export default VerticalTabs;
