import React, { Suspense, useEffect } from "react";
import { createBrowserHistory } from "history";
import { Router, Switch, Route, Redirect } from "react-router-dom";
import Loading from "./components/Loading";
import NoMatch from "./components/NoMatch";
import FatalError from "./components/FatalError";
import { redirectToSubdomain } from "./Utility";

import Login from "./views/login/Login";
import Logout from "./views/login/Logout";
import ForgotPassword from "./views/login/ForgotPassword";
import ResetPassword from "./views/login/ResetPassword";
import Register from "./views/register/Register";
import Dashboard from "./views/dashboard/Dashboard";
import Account from "./views/account/Account";
import Members from "./views/members/Members";
import CollaboratingAdvisors from "./views/collaborating_advisors/OrganizationAdvisors";
import RegisterMember from "./views/members/RegisterMember";
import AcceptClientInvitation from "./views/clients/AcceptClientInvitation";
import Clients from "./views/clients/Clients";
import Client from "./views/clients/Client";
import ShowMemberChange from "./views/changelog/ShowMemberChange";
import AcceptAdvisorInvitation from "./views/invitations/AcceptAdvisorInvitation";
import MessageContextProvider from "./contexts/MessageContext";
import useAuth from "./hooks/useAuth";
import Spinner from "./components/Spinner";
import ICEGuest from "./views/ice_guest/ICEGuest";
import { loadStripe } from "@stripe/stripe-js";
import InitialPayment from "../src/views/register/InitialPayment";
import InviteTeamMembers from "../src/views/register/InviteTeamMembers";
import { Elements } from "@stripe/react-stripe-js";
import MemberAppointmentIndex from "./views/members/appointments/MemberAppointmentIndex";
import { ReactQueryDevtools } from "react-query/devtools";
import Organization from "views/organization/Organization";
import ShowAllMemberChanges from "views/changelog/ShowAllMemberChanges";
import { useLocation } from "react-router-dom";

const appHistory = createBrowserHistory();
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

export const PrivateRoute = ({ component: Component, subdomain, ...rest }) => {
    const location = useLocation();
    const { isLoggedIn } = useAuth();
    redirectToSubdomain(subdomain);

    // Get the last segment of the path and preload the Zendesk widget.
    useEffect(() => {
        if (window.zE) {
            window.zE("webWidget", "helpCenter:setSuggestions", {
                url: true,
            });
        }
    }, [location.pathname]);

    return (
        <Route
            {...rest}
            render={(props) =>
                isLoggedIn ? (
                    <Component {...rest} {...props} />
                ) : (
                    <Redirect
                        to={{
                            pathname: "/login",
                            from: props.location,
                        }}
                    />
                )
            }
        />
    );
};

export const GuestRoute = ({ component: Component, ...rest }) => {
    const { isLoggedIn } = useAuth();
    const redirect = window.sessionStorage.getItem("con2a:redirect") || "";
    if (redirect || isLoggedIn) {
        window.sessionStorage.removeItem("con2a:redirect");
        return (
            <Route
                {...rest}
                render={(props) => (
                    <Redirect
                        to={{
                            pathname: redirect || "/",
                            from: props.location,
                        }}
                    />
                )}
            />
        );
    }

    return (
        <Route
            {...rest}
            render={(props) => <Component {...rest} {...props} />}
        />
    );
};

export const RegistrationRoute = ({ component: Component, ...rest }) => {
    const { isLoggedIn } = useAuth();
    if (isLoggedIn) {
        return <Redirect to="/" />;
    }
    return (
        <Route
            {...rest}
            render={(props) => <Component {...rest} {...props} />}
        />
    );
};

export const PublicRoute = ({ component: Component, ...rest }) => {
    return (
        <Route
            {...rest}
            render={(props) => <Component {...rest} {...props} />}
        />
    );
};

export default function App() {
    const { subdomain, hasCheckedLogin, errorMessage, errorTitle, errorCode } =
        useAuth();

    const allowedRoutes = ["/complete-client-registration"];

    if (errorMessage) {
        const options = {
            message: errorMessage,
            code: errorCode,
        };
        if (errorTitle) {
            options.title = errorTitle;
        }

        if (
            errorCode !== 403 ||
            !allowedRoutes.includes(window.location.pathname)
        ) {
            return <FatalError {...options} />;
        }
    }

    if (!hasCheckedLogin) {
        return (
            <div className="h-screen w-screen flex items-center justify-center">
                <Spinner message={`Loading...`} />
            </div>
        );
    }

    return (
        <>
            <ReactQueryDevtools initialIsOpen={false} />
            <MessageContextProvider>
                <Suspense fallback={<Loading />}>
                    <Router history={appHistory}>
                        <Switch>
                            <PrivateRoute
                                path="/"
                                component={Dashboard}
                                exact
                                subdomain={subdomain}
                            />
                            <GuestRoute exact path="/login" component={Login} />
                            <GuestRoute
                                path="/ice"
                                sensitive={false}
                                component={ICEGuest}
                            />
                            <GuestRoute
                                exact
                                path="/login/forgot"
                                component={ForgotPassword}
                            />
                            <GuestRoute
                                exact
                                path="/login/reset"
                                component={ResetPassword}
                            />
                            <PrivateRoute
                                path="/logout"
                                component={Logout}
                                subdomain={subdomain}
                            />
                            <RegistrationRoute
                                path="/register"
                                component={Register}
                                stripePromise={stripePromise}
                            />
                            <PrivateRoute
                                path="/account"
                                component={Account}
                                subdomain={subdomain}
                            />
                            <PrivateRoute
                                path="/organization"
                                component={Organization}
                                subdomain={subdomain}
                            />
                            <PrivateRoute
                                path="/members"
                                component={Members}
                                subdomain={subdomain}
                            />
                            <PrivateRoute
                                path="/collaborating-advisors"
                                component={CollaboratingAdvisors}
                                subdomain={subdomain}
                            />
                            <PrivateRoute
                                path="/appointments"
                                component={MemberAppointmentIndex}
                                subdomain={subdomain}
                            />
                            <PrivateRoute
                                path="/clients"
                                component={Clients}
                                subdomain={subdomain}
                            />
                            <PrivateRoute
                                path="/changes/:changeId/show"
                                component={ShowMemberChange}
                                subdomain={subdomain}
                            />
                            <PrivateRoute
                                path="/changes"
                                component={ShowAllMemberChanges}
                                subdomain={subdomain}
                            />
                            <PrivateRoute
                                path="/client"
                                component={Client}
                                subdomain={subdomain}
                            />
                            <GuestRoute
                                path="/complete-registration"
                                component={RegisterMember}
                            />
                            <PublicRoute
                                path="/complete-client-registration"
                                component={AcceptClientInvitation}
                            />
                            <GuestRoute
                                path="/accept-invitation"
                                component={AcceptAdvisorInvitation}
                            />
                            <PrivateRoute
                                path="/invite-team-members"
                                component={InviteTeamMembers}
                                subdomain={subdomain}
                            />
                            <Elements stripe={stripePromise}>
                                <PrivateRoute
                                    path="/choose-subscription"
                                    component={InitialPayment}
                                    subdomain={subdomain}
                                />
                            </Elements>
                            <Route component={NoMatch} />
                        </Switch>
                    </Router>
                </Suspense>
            </MessageContextProvider>
            <div id="quickAddPortal"></div>
        </>
    );
}
