import React, { useState, useEffect, useContext } from "react";
import Api from "../../../../services/Api";
import Spinner from "../../../../components/Spinner";
import Button from "../../../../components/Button";

import Breadcrumbs from "../../../../components/Breadcrumbs";
import TableSortHeader from "../../../../components/TableSortHeader";
import EmptyTableRow from "../../../../components/EmptyTableRow";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFolder, faFile } from "@fortawesome/free-solid-svg-icons";
import { getRootVaultPath, isFileImage } from "../helpers";
import { AuthContext } from "../../../../contexts/AuthContext";
import { iconColorForVault } from "../_partials/helpers";
import { formatDate } from "../../../../util/TextUtil";

export default function FilePicker({
    client,
    isLoading,
    fileType = "File",
    mimeTypeFilter,
    onResult,
}) {
    const context = useContext(AuthContext);

    const [selectedVault, setSelectedVault] = useState(null);
    const [vaultEntries, setVaultEntries] = useState([]);
    const [parentId, setParentId] = useState(null);
    const [parent, setParent] = useState(null);
    const [loading, setLoading] = useState(false);
    const [breadcrumbs, setBreadcrumbs] = useState([]);

    const [sortField, setSortField] = useState("name");
    const [sortDir, setSortDir] = useState("asc");

    useEffect(() => {
        const buildCrumb = (breadcrumb, arr) => {
            arr.unshift(breadcrumb);
            if (breadcrumb.parent_recursive) {
                buildCrumb(breadcrumb.parent_recursive, arr);
            }
            return arr;
        };
        async function fetchVault() {
            const params = {};
            if (parentId) {
                params.parentId = parentId;
            }
            const response = await Api.get(
                `clients/${client.id}/vault`,
                params
            );
            setVaultEntries(response.data.data);
            if (response.data.breadcrumbs) {
                setBreadcrumbs(buildCrumb(response.data.breadcrumbs, []));
                setParent(response.data.breadcrumbs);
            } else {
                setBreadcrumbs([]);
                setParent(null);
            }
        }
        setLoading(true);
        Promise.all([fetchVault()]).finally(() => {
            setLoading(false);
        });
    }, [parentId, client.id]);

    const selectVaultEntry = (vaultEntry) => {
        if (
            selectedVault &&
            selectedVault.id === vaultEntry.id &&
            selectedVault.type === "directory"
        ) {
            setParentId(vaultEntry.id);
            return;
        }
        setSelectedVault(vaultEntry);
    };

    const changeSort = (fieldName) => {
        setSortField(fieldName);
        if (sortField === fieldName) {
            setSortDir(sortDir === "asc" ? "desc" : "asc");
        }
    };

    const onNext = () => {
        const selected = selectedVault ? selectedVault : parent;
        const id = selectedVault ? selected.id + "" : "";
        onResult({ result: id, parent: selected });
    };

    const onCancel = () => {
        onResult({ result: undefined });
    };

    const selectedStyle = `override border-2 border-blue-500 bg-blue-200`;
    const unselectedStyle = ``;

    const moveToMaxLength = 10;
    const moveToName = selectedVault
        ? `${selectedVault.name.substring(0, moveToMaxLength)}${
              selectedVault.name.length > moveToMaxLength ? "..." : ""
          }`
        : null;

    const foldersOnTop = true;
    const searchFilter = "";

    const filteredEntries = vaultEntries
        .filter(
            (a) => a.name.toLowerCase().indexOf(searchFilter.toLowerCase()) > -1
        )
        .filter(
            (a) =>
                a.type !== "file" ||
                !mimeTypeFilter ||
                a.mime_type.indexOf(mimeTypeFilter) > -1
        )
        .sort((a, b) => {
            if (a.type === b.type || foldersOnTop === false) {
                return sortDir === "asc"
                    ? a[sortField].localeCompare(b[sortField])
                    : b[sortField].localeCompare(a[sortField]);
            }
            return a.type === "directory" ? -1 : 1;
        });

    const isDirectory = selectedVault
        ? selectedVault.type === "directory"
        : false;

    const isImage = selectedVault
        ? isFileImage({ extension: selectedVault.extension })
        : false;
    let src = "";
    if (selectedVault && isImage) {
        src = Api.makeUrl(
            `clients/${client.id}/vault/${selectedVault.id}/download?preview=1`
        );
    }

    return (
        <div onClick={() => setSelectedVault(null)}>
            {loading && (
                <div className="w-full h-full flex items-center justify-center">
                    <Spinner />
                </div>
            )}

            {!loading && (
                <>
                    {selectedVault && isDirectory && (
                        <div className="flex justify-center align-center mb-4 text-gray-400">
                            <FontAwesomeIcon
                                className={`${iconColorForVault(
                                    selectedVault
                                )}`}
                                size={"10x"}
                                icon={faFolder}
                            />
                        </div>
                    )}

                    {selectedVault && !isDirectory && (
                        <>
                            {isImage && (
                                <div>
                                    {src && (
                                        <img
                                            src={src}
                                            className="object-contain h-32 w-32 mx-auto mb-4 rounded border-2 border-gray-200"
                                            alt="Preview"
                                        />
                                    )}
                                </div>
                            )}
                            {!!isImage !== true && (
                                <div className="flex justify-center align-center mb-4 text-gray-400">
                                    <div className="relative">
                                        <FontAwesomeIcon
                                            className={`${iconColorForVault(
                                                selectedVault
                                            )}`}
                                            icon={faFile}
                                            size="8x"
                                        />
                                        <div className="absolute inset-0 flex items-center justify-center">
                                            <div className="text-md font-bold text-white">
                                                {selectedVault.extension.toUpperCase()}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </>
                    )}

                    <Breadcrumbs
                        defaultName={"Vault"}
                        breadcrumbs={breadcrumbs}
                        rootPath={getRootVaultPath(client, context.user)}
                        onClick={(newParent) => {
                            setParentId(newParent ? newParent.id : null);
                        }}
                    />

                    <table className="w-full text-left">
                        <thead>
                            <tr>
                                <TableSortHeader
                                    sortField={sortField}
                                    sortDir={sortDir}
                                    changeSort={changeSort}
                                    field="name"
                                >
                                    Name
                                </TableSortHeader>
                                <TableSortHeader
                                    sortField={sortField}
                                    sortDir={sortDir}
                                    changeSort={changeSort}
                                    field="created_at"
                                >
                                    Date Created
                                </TableSortHeader>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredEntries.map((ref, idx) => (
                                <tr
                                    key={idx}
                                    className={`cursor-pointer ${
                                        selectedVault &&
                                        ref.id === selectedVault.id
                                            ? selectedStyle
                                            : unselectedStyle
                                    }`}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        selectVaultEntry(ref);
                                    }}
                                >
                                    <td>
                                        <>
                                            {ref.type === "directory" && (
                                                <FontAwesomeIcon
                                                    className={`${iconColorForVault(
                                                        ref
                                                    )}`}
                                                    icon={faFolder}
                                                />
                                            )}
                                            {ref.type !== "directory" && (
                                                <FontAwesomeIcon
                                                    className={`${iconColorForVault(
                                                        ref
                                                    )}`}
                                                    icon={faFile}
                                                />
                                            )}
                                            &nbsp;&nbsp;
                                            {ref.name}
                                            {ref.ext ? "." + ref.ext : ""}
                                        </>
                                    </td>
                                    <td>{formatDate(ref.created_at)}</td>
                                </tr>
                            ))}
                            {vaultEntries.length === 0 && (
                                <EmptyTableRow colSpan="2">
                                    Empty Folder
                                </EmptyTableRow>
                            )}
                        </tbody>
                    </table>

                    <div className="flex items-center justify-center mt-6">
                        <div className="mr-3 flex-1">
                            <Button
                                onClick={() => onCancel({ background: false })}
                                text={"Cancel"}
                                appearance="outline"
                            />
                        </div>
                        <div className="flex-1">
                            <Button
                                isLoading={isLoading}
                                disabled={
                                    !selectedVault ||
                                    selectedVault.type !== "file"
                                }
                                onClick={() => onNext()}
                                text={`${
                                    moveToName && !isDirectory
                                        ? `Select "${moveToName}"`
                                        : "Select a " + fileType
                                }`}
                            />
                        </div>
                    </div>
                </>
            )}
        </div>
    );
}
