import useFetch from "./useFetch";
import { fetchPostMortemWealth } from "services/PostMortemService";

export default function usePostMortemWealthFetch(
    params,
    config = { keepPreviousData: false }
) {
    return useFetch(
        ["fetchPostMortemWealth", params],
        fetchPostMortemWealth,
        config
    );
}
