import useGlobalSetting from "hooks/useGlobalSetting";
import React, { useCallback, useState, useEffect } from "react";
import { useToasts } from "react-toast-notifications";
import useClient from "../../../hooks/useClient";
import Api from "../../../services/Api";
import { downloadFile } from "../../../util/Downloader";
import useIceContext from "../hooks/useIceContext";

export const IceCardContext = React.createContext();

export default function IceCardContextProvider({ children }) {
    const { isSpouse } = useIceContext();
    const { client } = useClient();
    const [isLoadingOrderStatus, setIsLoadingOrderStatus] = useState(false);
    const [isExporting, setIsExporting] = useState(false);
    const [isCheckingOut, setCheckingOut] = useState(false);
    const [shippingInfoErrors, setShippingInfoErrors] = useState({});
    const [orderStatus, setOrderStatus] = useState([]);
    const { addToast } = useToasts();

    const [spouseCardQty, setSpouseCardQty] = useState(0);
    const [clientCardQty, setClientCardQty] = useState(0);
    const [isCheckoutOpen, setIsCheckoutOpen] = useState(false);

    const {
        data: costData,
        isSuccess: loadedCost,
        isLoading: loadingCost,
    } = useGlobalSetting("ice_card_price");

    const cost = loadedCost ? costData.value : -1;

    const fetchOrderStatus = useCallback(async () => {
        setIsLoadingOrderStatus(true);
        try {
            const { data } = await Api.get(
                "clients/" + client.id + "/ice/card/checkout"
            );
            setOrderStatus(data.data);
        } catch (err) {
            console.error(err);
            setOrderStatus([]);
        }
        setIsLoadingOrderStatus(false);
    }, [client.id]);

    useEffect(() => {
        if (spouseCardQty < 0) {
            setSpouseCardQty(0);
        }
        if (clientCardQty < 0) {
            setClientCardQty(0);
        }
    }, [spouseCardQty, clientCardQty]);

    const [shippingInfo, setShippingInfo] = useState({});

    const updateShippingInfo = (e) => {
        e.preventDefault();
        setShippingInfo({
            ...shippingInfo,
            [e.target.name]: e.target.value,
        });
    };

    const checkout = async (e) => {
        e.preventDefault();
        setCheckingOut(true);
        try {
            await Api.post("clients/" + client.id + "/ice/card/checkout", {
                ...shippingInfo,
                clientCardQty,
                spouseCardQty,
            });
            await fetchOrderStatus();
        } catch (error) {
            console.error(error);
            setShippingInfoErrors([]);
            if (error.response.data.errors) {
                setShippingInfoErrors(error.response.data.errors);
            } else {
                addToast(error.response.data.message, { type: "error" });
            }
        }
        setCheckingOut(false);
        setIsCheckoutOpen(false);
    };

    const printCard = useCallback(
        async (id, e) => {
            e.preventDefault();
            try {
                setIsExporting(true);
                let response = await Api.get(
                    "clients/" + client.id + "/ice/card",
                    {
                        isSpouse,
                        export: true,
                    },
                    { responseType: "arraybuffer" }
                );
                await downloadFile(response, "ice-card", {
                    open: true,
                    print: true,
                });
                setIsExporting(false);
                addToast("ICE Card Ready");
            } catch (e) {
                setIsExporting(false);
                addToast(e.response.data.message, { type: "error" });
            }
        },
        [addToast, client.id, isSpouse]
    );

    useEffect(() => {
        fetchOrderStatus();
    }, [fetchOrderStatus]);

    return (
        <IceCardContext.Provider
            value={{
                isExporting,
                printCard,
                clientCardQty,
                setClientCardQty,
                spouseCardQty,
                setSpouseCardQty,
                cost,
                checkout,
                shippingInfo,
                shippingInfoErrors,
                updateShippingInfo,
                isCheckingOut,
                orderStatus,
                isLoadingOrderStatus,
                isCheckoutOpen,
                setIsCheckoutOpen,
                loadedCost,
                loadingCost,
            }}
        >
            {children}
        </IceCardContext.Provider>
    );
}
