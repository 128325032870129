import React, { useState, useEffect } from "react";
import Api from "../../services/Api";
import { useToasts } from "react-toast-notifications";
import Button from "../../components/Button";
import SaveButton, { showSave } from "../../components/SaveButton";
import ClientPageHeader, { Heading } from "../../components/ClientPageHeader";
import PageContainer from "../../components/PageContainer";
import Spinner from "../../components/Spinner";
import VaultResourceFileList from "../resources/VaultResourceFileList";
import FinancialAccountForm from "./_partials/FinancialAccountForm";
import ResourceAccessUserList from "../resources/ResourceAccessUserList";
import FinancialAccountTabs from "./_partials/FinancialAccountTabs";
import { AllOwnershipsForAsset } from "components/Ownership";
import FinancialAccountFormContact from "./_partials/FinancialAccountFormContact";
import InitialBeneficiary from "../../components/Beneficiary/InitialBeneficiary";
import ClientNotes from "../clients/Notes/ClientNotes";
import Forbidden from "../../components/Forbidden";
import { Route, Switch } from "react-router";
import { formatDate } from "../../util/TextUtil";
import TransfersTable from "../funding_table/_partials/TransfersTable";
import useForm from "hooks/useForm";
import FinancialAccountLetters from "./FinancialAccountLetters";

export default function EditFinancialAccount({
    client,
    readOnly = false,
    clientPath,
    ...props
}) {
    const [loading, setLoading] = useState(true);
    const [saving, setSaving] = useState(false);
    const [notAuthorized, setNotAuthorized] = useState(false);
    const { addToast } = useToasts();
    const [errors, setErrors] = useState([]);
    const [needsUpdate, setNeedsUpdate] = useState(0);
    const [title, setTitle] = useState("");
    const [isDeleted, setIsDeleted] = useState(false);

    const { input, updateValue, setInput, clearUnsavedChanges } = useForm({
        financial_account_type_id: 0,
    });

    const createTitle = async (input) => {
        if (input.financial_account_type_id > 0) {
            let newTitle = `${readOnly ? "View" : "Edit"} Financial Account`;
            newTitle =
                `${newTitle} - ${input.name}` +
                (input.account_number_last_four
                    ? ` (xxx${input.account_number_last_four})`
                    : "");
            setTitle(newTitle);
            document.title = newTitle;
        }
    };

    useEffect(() => {
        createTitle(input);
    }, [readOnly, input.financial_account_type_id]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
                let response = await Api.get(
                    "clients/" +
                        client.id +
                        "/financial-accounts/" +
                        props.match.params.financialAccountId
                );
                setInput(response.data.data);
                setLoading(false);
                if (response.data.data.deleted_at) {
                    setIsDeleted(true);
                }
            } catch (error) {
                if (error.response.status === 403) {
                    setNotAuthorized(true);
                }
            }
        };
        fetchData();
    }, [client, props.match.params.financialAccountId, needsUpdate, isDeleted]);

    async function update(e) {
        e.preventDefault();
        try {
            setSaving(true);
            const body = { ...input };
            let response = await Api.put(
                "clients/" + client.id + "/financial-accounts/" + input.id,
                body
            );
            addToast(response.data.message);
            setErrors([]);
            createTitle(response.data.data);
            setSaving(false);
            clearUnsavedChanges();
        } catch (e) {
            setSaving(false);
            clearUnsavedChanges();
            setErrors([]);
            if (e.response.data.errors) {
                setErrors(e.response.data.errors);
            } else {
                addToast(e.response.data.message, { type: "error" });
            }
        }
    }

    async function restoreResource(financialAccount) {
        try {
            let response = await Api.put(
                `clients/${client.id}/financial-accounts/${financialAccount.id}/restore`,
                {
                    is_trashed: false,
                }
            );
            addToast(response.data.message);
            setInput(response.data.resource);
        } catch (e) {
            addToast(e.errorMessage, { type: "error" });
        }
    }

    if (notAuthorized) {
        return <Forbidden />;
    }

    if (!input || loading) {
        return (
            <div className="h-screen w-full flex items-center justify-center">
                <Spinner />
            </div>
        );
    }

    const resourceType = "FinancialAccount";
    const basePath = `${clientPath}/financial-accounts/${input.id}/`;
    const currentBasePath = basePath + (readOnly ? "view" : "edit");

    const saveButtonVisible = showSave();

    const refreshData = () => {
        setNeedsUpdate((needsUpdate) => needsUpdate + 1);
    };

    return (
        <>
            {input.deleted_at && (
                <div className="flex items-center bg-red-500 text-white p-6 py-3">
                    This resource was deleted on {formatDate(input.deleted_at)}
                    <a
                        href="#restore"
                        className="ml-auto px-3 py-1 bg-red-400 rounded hover:bg-red-600"
                        onClick={() => restoreResource(input)}
                    >
                        Restore
                    </a>
                </div>
            )}
            <ClientPageHeader client={client}>
                <Heading
                    backTo={`${clientPath}/financial-accounts`}
                    backText="All Financial Accounts"
                >
                    {title}
                </Heading>
                {readOnly !== true && saveButtonVisible === true && (
                    <div className="flex-0">
                        <SaveButton isLoading={saving} onClick={update} />
                    </div>
                )}
                {readOnly !== false &&
                    saveButtonVisible === true &&
                    isDeleted !== true && (
                        <div className="flex-0">
                            <Button
                                type="link"
                                to={window.location.pathname.replace(
                                    "view",
                                    "edit"
                                )}
                                text="Edit"
                            />
                        </div>
                    )}
            </ClientPageHeader>
            <PageContainer>
                <div className="flex space-x-10">
                    <div className="flex-initial">
                        <FinancialAccountTabs
                            basePath={currentBasePath}
                            showBeneficiary={input.show_beneficiary}
                            resourceTypeId={input.financial_account_type_id}
                            accountType={parseInt(
                                input.financial_account_type_id
                            )}
                            {...props}
                        />
                    </div>
                    <div className="flex-1">
                        <Switch>
                            <Route
                                exact={true}
                                path={[basePath + "edit", basePath + "view"]}
                                render={(newProps) => (
                                    <div className="max-w-3xl mx-auto">
                                        <FinancialAccountForm
                                            client={client}
                                            input={input}
                                            readOnly={readOnly}
                                            errors={errors}
                                            onSubmit={update}
                                            updateValue={updateValue}
                                            {...newProps}
                                        />
                                    </div>
                                )}
                            />
                            <Route
                                exact={true}
                                path={[
                                    basePath + "edit/owners",
                                    basePath + "view/owners",
                                ]}
                                render={() => (
                                    <AllOwnershipsForAsset
                                        asset={input}
                                        client={client}
                                        refreshData={refreshData}
                                    />
                                )}
                            />
                            <Route
                                exact={true}
                                path={[
                                    basePath + "edit/contact",
                                    basePath + "view/contact",
                                ]}
                                render={(newProps) => (
                                    <FinancialAccountFormContact
                                        input={input}
                                        readOnly={readOnly}
                                        errors={errors}
                                        onSubmit={update}
                                        updateValue={updateValue}
                                        {...newProps}
                                    />
                                )}
                            />
                            <Route
                                exact={true}
                                path={[
                                    basePath + "edit/notes",
                                    basePath + "view/notes",
                                ]}
                                render={() => (
                                    <>
                                        <Heading>Notes</Heading>
                                        <ClientNotes
                                            client={client}
                                            resourceType={resourceType}
                                            resourceId={input.id}
                                            {...props}
                                        />
                                    </>
                                )}
                            />
                            <Route
                                exact={true}
                                path={[
                                    basePath + "edit/beneficiaries",
                                    basePath + "view/beneficiaries",
                                ]}
                                render={() => (
                                    <InitialBeneficiary
                                        client={client}
                                        asset={input}
                                    />
                                )}
                            />
                            <Route
                                exact={true}
                                path={[
                                    basePath + "edit/related-files",
                                    basePath + "view/related-files",
                                ]}
                                render={() => (
                                    <VaultResourceFileList
                                        client={client}
                                        resourceType={"FinancialAccount"}
                                        resourceId={input.id}
                                    />
                                )}
                            />
                            <Route
                                exact={true}
                                path={[
                                    basePath + "edit/access",
                                    basePath + "view/access",
                                ]}
                                render={() => (
                                    <ResourceAccessUserList
                                        resourceType="FinancialAccount"
                                        resourceId={input.id}
                                    />
                                )}
                            />
                            <Route
                                exact={true}
                                path={[
                                    basePath + "edit/transfers",
                                    basePath + "view/transfers",
                                ]}
                                render={() => (
                                    <TransfersTable
                                        assetId={input.id}
                                        assetType={`FinancialAccount`}
                                        client={client}
                                    />
                                )}
                            />
                            <Route
                                exact={true}
                                path={[
                                    basePath + "edit/forms",
                                    basePath + "view/forms",
                                ]}
                                render={() => (
                                    <FinancialAccountLetters
                                        clientId={client.id}
                                        accountId={input.id}
                                    />
                                )}
                            />
                        </Switch>
                    </div>
                </div>
            </PageContainer>
        </>
    );
}
