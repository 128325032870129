import Api from "./Api";

export const fetchNonMortgageLiabilities = async function ({ queryKey }) {
    const [, { ...params }] = queryKey;
    return await Api.get(
        "clients/" + params.clientId + "/non-mortgage-liabilities/",
        params
    ).then((res) => res.data);
};

export const fetchNonMortgageLiability = async function ({ queryKey }) {
    const [, { ...params }] = queryKey;
    return await Api.get(
        "clients/" +
            params.clientId +
            "/non-mortgage-liabilities/" +
            params.liabilityId
    ).then((res) => res.data);
};

export const createNonMortgageLiability = async function (clientId, formData) {
    return await Api.post(
        "clients/" + clientId + "/non-mortgage-liabilities",
        formData
    ).then((res) => res.data);
};

export const updateNonMortgageLiability = async function (
    clientId,
    liabilityId,
    formData
) {
    return await Api.put(
        "clients/" + clientId + "/non-mortgage-liabilities/" + liabilityId,
        formData
    ).then((res) => res.data);
};

export const deleteNonMortgageLiability = async function (
    clientId,
    liabilityId
) {
    return await Api.delete(
        "clients/" + clientId + "/non-mortgage-liabilities/" + liabilityId
    ).then((res) => res.data);
};

export default {
    fetchNonMortgageLiabilities,
    fetchNonMortgageLiability,
    createNonMortgageLiability,
    updateNonMortgageLiability,
    deleteNonMortgageLiability,
};
