import React from "react";
import ClientPageHeader, { Heading } from "../../components/ClientPageHeader";
import PageContainer from "../../components/PageContainer";

import VaultPhotosList from "../resources/VaultPhotosList";
import ClientNav from "./_partials/ClientNav";

export default function ClientRelatedPhotos({ client, ...props }) {
    if (!client) {
        return <></>;
    }

    return (
        <>
            <ClientPageHeader text="Edit Profile" {...props} client={client}>
                <Heading>Edit Profile</Heading>
            </ClientPageHeader>
            <PageContainer>
                <div>
                    <div className="flex">
                        <div className="mr-10 flex-initial">
                            <ClientNav
                                active={props.history.location.pathname}
                                client={client}
                            />
                        </div>

                        <div className="flex-1">
                            {client && (
                                <VaultPhotosList
                                    client={client}
                                    resourceType={"Client"}
                                    resourceId={client.id}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </PageContainer>
        </>
    );
}
