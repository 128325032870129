import { faPercent } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import TextInput from "./TextInput";

const PercentInput = ({
    type,
    id,
    name,
    label,
    onChange,
    placeholder,
    value,
    error,
    max = 100,
    styleType = true,
    className = "",
    tabindex = 0,
    autoFocus = false,
    readOnly = false,
    disabled = false,
    required = false,
    reference = null,
    ...props
}) => {
    function onLocalChange(e) {
        const newVal = e.target.value;
        const numDecimals = decimalPlaces(newVal);
        if (numDecimals < 5 && newVal <= max) {
            if (onChange !== null && onChange !== undefined) {
                onChange({ target: { name: name, value: newVal } });
            }
        }
    }

    function decimalPlaces(num) {
        return (num.split(".")[1] || []).length;
    }

    return (
        <div className="relative">
            <TextInput
                type={type}
                id={id}
                name={name}
                label={label}
                onChange={onLocalChange}
                placeholder={placeholder}
                value={value}
                error={error}
                styleType={styleType}
                className={className}
                tabindex={tabindex}
                autoFocus={autoFocus}
                readOnly={readOnly}
                disabled={disabled}
                required={required}
                reference={reference}
                {...props}
            />
            <div className="absolute text-xs text-gray-700 font-light bg-transparent z-index-10 h-12 bottom-0 pr-4 right-0 flex items-center">
                <FontAwesomeIcon size="sm" icon={faPercent} />
            </div>
        </div>
    );
};

export default PercentInput;
