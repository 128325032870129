import Api from "./Api";

export const fetchUsers = async function ({ params }) {
    return await Api.get("users/", params).then((res) => res.data);
};

export const fetchUser = async function ({ queryKey }) {
    const [, { ...params }] = queryKey;
    return await Api.get("users/" + params.userId).then((res) => res.data);
};

export default {
    fetchUsers,
    fetchUser,
};
