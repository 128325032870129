import React, { useState, useEffect, useReducer, useCallback } from "react";
import Api from "../../services/Api";
import Button from "../../components/Button";
import SaveButton, { showSave } from "../../components/SaveButton";
import { Route, Switch } from "react-router-dom";
import UnsavedChanges from "../../components/UnsavedChanges";
import Spinner from "../../components/Spinner";
import { useToasts } from "react-toast-notifications";
import PageContainer from "../../components/PageContainer";
import VaultResourceFileList from "../resources/VaultResourceFileList";
import AnnuityForm from "./_partials/AnnuityForm";
import ClientPageHeader, { Heading } from "../../components/ClientPageHeader";
import ResourceAccessUserList from "../resources/ResourceAccessUserList";
import AnnuityTabs from "./_partials/AnnuityTabs";
import { AllOwnershipsForAsset } from "components/Ownership";
import ClientNotes from "../clients/Notes/ClientNotes";
import Forbidden from "../../components/Forbidden";
import TextInput from "../../components/TextInput";
import TransfersTable from "../funding_table/_partials/TransfersTable";
import InitialBeneficiary from "../../components/Beneficiary/InitialBeneficiary";
import ContactForm from "./_partials/ContactForm";
import AnnuityLetters from "./AnnuityLetters";

export default function EditAnnuity({
    client,
    readOnly = false,
    clientPath,
    ...props
}) {
    const [loading, setLoading] = useState(true);
    const [notAuthorized, setNotAuthorized] = useState(false);
    const { addToast } = useToasts();
    const [errors, setErrors] = useState([]);
    const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
    const [saving, setSaving] = useState(false);
    const [needsUpdate, setNeedsUpdate] = useState(0);

    const [input, setInput] = useReducer(
        (state, newState) => ({ ...state, ...newState }),
        {
            other_owner_type: "none",
            managed_by_id: "",
            qualified: "nonqualified",
        }
    );

    const title =
        `${readOnly ? "View" : "Edit"} Annuity` +
        (input.name && !loading ? ` - ${input.name}` : "") +
        (input.account_number_last_four
            ? ` (xxx${input.account_number_last_four})`
            : "");

    useEffect(() => {
        let unlisten = props.history.listen(() => {
            if (hasUnsavedChanges) {
                setNeedsUpdate(needsUpdate + 1);
            }
        });
        return () => {
            unlisten();
        };
    }, [hasUnsavedChanges, needsUpdate, props.history]);

    useEffect(() => {
        document.title = title;
    }, [title]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
                let response = await Api.get(
                    "clients/" +
                        client.id +
                        "/annuities/" +
                        props.match.params.annuityId
                );
                setInput(response.data.data);
                setHasUnsavedChanges(false);
                setLoading(false);
            } catch (error) {
                if (error.response.status === 403) {
                    setNotAuthorized(true);
                }
            }
        };
        fetchData();
    }, [client, props.match.params.annuityId, needsUpdate]);

    const updateValue = useCallback((event) => {
        setInput({ [event.target.name]: event.target.value });
        setHasUnsavedChanges(true);
    }, []);

    async function update(e) {
        e.preventDefault();
        try {
            setSaving(true);
            const body = { ...input };
            let response = await Api.put(
                "clients/" + client.id + "/annuities/" + input.id,
                body
            );
            addToast(response.data.message);
            setErrors([]);
            setHasUnsavedChanges(false);
            setSaving(false);
        } catch (e) {
            setSaving(false);
            setErrors([]);
            if (e.response.data.errors) {
                setErrors(e.response.data.errors);
            } else {
                addToast(e.response.data.message, { type: "error" });
            }
        }
    }

    if (notAuthorized) {
        return <Forbidden />;
    }

    if (!input || loading) {
        return (
            <div className="h-screen w-full flex items-center justify-center">
                <Spinner />
            </div>
        );
    }

    const resourceType = "Annuity";
    const basePath = `${clientPath}/annuities/${input.id}/`;
    const currentBasePath = basePath + (readOnly ? "view" : "edit");
    const saveButtonVisible = showSave();

    const refreshData = () => {
        setNeedsUpdate((needsUpdate) => needsUpdate + 1);
    };

    return (
        <>
            <ClientPageHeader client={client}>
                <Heading
                    backTo={`${clientPath}/annuities`}
                    backText="All Policies"
                >
                    {title}
                </Heading>
                {readOnly !== true && saveButtonVisible === true && (
                    <div className="flex-0">
                        <SaveButton isLoading={saving} onClick={update} />
                    </div>
                )}
                {readOnly !== false && saveButtonVisible === true && (
                    <div className="flex-0">
                        <Button
                            type="link"
                            to={window.location.pathname.replace(
                                "view",
                                "edit"
                            )}
                            text="Edit"
                        />
                    </div>
                )}
            </ClientPageHeader>
            <PageContainer>
                <UnsavedChanges when={hasUnsavedChanges} />
                <div className="flex space-x-10">
                    <div className="flex-initial">
                        <AnnuityTabs basePath={currentBasePath} {...props} />
                    </div>
                    <div className="flex-1">
                        <Switch>
                            <Route
                                exact={true}
                                path={[basePath + "edit", basePath + "view"]}
                                render={() => (
                                    <div className="max-w-3xl mx-auto">
                                        <AnnuityForm
                                            client={client}
                                            input={input}
                                            errors={errors}
                                            readOnly={readOnly}
                                            updateValue={updateValue}
                                            onSubmit={update}
                                        />
                                    </div>
                                )}
                            />
                            <Route
                                exact={true}
                                path={[
                                    basePath + "edit/owners",
                                    basePath + "view/owners",
                                ]}
                                render={() => (
                                    <AllOwnershipsForAsset
                                        asset={input}
                                        client={client}
                                        refreshData={refreshData}
                                    />
                                )}
                            />
                            <Route
                                exact={true}
                                path={[
                                    basePath + "edit/beneficiaries",
                                    basePath + "view/beneficiaries",
                                ]}
                                render={() => (
                                    <InitialBeneficiary
                                        client={client}
                                        asset={input}
                                    />
                                )}
                            />
                            <Route
                                exact={true}
                                path={[
                                    basePath + "edit/contact",
                                    basePath + "view/contact",
                                ]}
                                render={() => (
                                    <ContactForm
                                        input={input}
                                        errors={errors}
                                        readOnly={readOnly}
                                        updateValue={updateValue}
                                        onSubmit={update}
                                    />
                                )}
                            />
                            <Route
                                exact={true}
                                path={[
                                    basePath + "edit/notes",
                                    basePath + "view/notes",
                                ]}
                                render={() => (
                                    <>
                                        <Heading>Notes</Heading>
                                        <ClientNotes
                                            client={client}
                                            resourceType={resourceType}
                                            resourceId={input.id}
                                            {...props}
                                        />
                                    </>
                                )}
                            />
                            <Route
                                exact={true}
                                path={[
                                    basePath + "edit/related-files",
                                    basePath + "view/related-files",
                                ]}
                                render={() => (
                                    <VaultResourceFileList
                                        client={client}
                                        resourceType={resourceType}
                                        resourceId={input.id}
                                    />
                                )}
                            />
                            <Route
                                exact={true}
                                path={[
                                    basePath + "edit/access",
                                    basePath + "view/access",
                                ]}
                                render={() => (
                                    <ResourceAccessUserList
                                        resourceType={resourceType}
                                        resourceId={input.id}
                                    />
                                )}
                            />
                            <Route
                                exact={true}
                                path={[
                                    basePath + "edit/post-mortem",
                                    basePath + "view/post-mortem",
                                ]}
                                render={() => (
                                    <form onSubmit={update}>
                                        <div>
                                            <div className="mb-6">
                                                <TextInput
                                                    name="dod_value"
                                                    label="Date Of Death Value"
                                                    id="dod_value"
                                                    readOnly={readOnly}
                                                    disabled={readOnly}
                                                    value={input.dod_value}
                                                    error={errors.dod_value}
                                                    onChange={updateValue}
                                                    required={true}
                                                    autoFocus={true}
                                                />
                                            </div>
                                        </div>
                                    </form>
                                )}
                            />
                            <Route
                                exact={true}
                                path={[
                                    basePath + "edit/transfers",
                                    basePath + "view/transfers",
                                ]}
                                render={() => (
                                    <TransfersTable
                                        assetId={input.id}
                                        assetType={`Annuity`}
                                        client={client}
                                    />
                                )}
                            />
                            <Route
                                exact={true}
                                path={[
                                    basePath + "edit/letters-of-instruction",
                                    basePath + "view/letters-of-instruction",
                                ]}
                                render={() => (
                                    <AnnuityLetters
                                        clientId={client.id}
                                        accountId={input.id}
                                    />
                                )}
                            />
                        </Switch>
                    </div>
                </div>
            </PageContainer>
        </>
    );
}
