import React from "react";
import TextInput from "../../../components/TextInput";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import Fieldset from "../../../components/Fieldset";

export default function VolunteerLocations({
    volunteerLocations,
    setVolunteerLocations,
}) {
    const volunteerLocation = {
        name: "",
        contact: "",
        address: "",
        phone: "",
        email: "",
        website: "",
    };

    function appendVolunteerLocation() {
        if (volunteerLocations.length < 10) {
            setVolunteerLocations((volunteerLocations) => [
                ...volunteerLocations,
                volunteerLocation,
            ]);
        }
    }

    function removeVolunteerLocation(idx) {
        setVolunteerLocations(
            volunteerLocations.filter((volunteerLocation, idxs) => idxs !== idx)
        );
    }

    const updateVolunteerLocation = (event, idx) => {
        setVolunteerLocations(
            volunteerLocations.map((volunteerLocation, sidx) => {
                return idx !== sidx
                    ? volunteerLocation
                    : {
                          ...volunteerLocation,
                          [event.target.name]: event.target.value,
                      };
            })
        );
    };

    return (
        <Fieldset legend="Volunteer Locations">
            <div className="mb-10 border-b border-gray-100 pb-6">
                {volunteerLocations.map((volunteerLocation, idx) => (
                    <div className="mb-10 border-b border-gray-100" key={idx}>
                        <div className="flex space-x-3 mb-6">
                            <div className="flex-1">
                                <TextInput
                                    label="Company"
                                    name="name"
                                    value={volunteerLocations[idx]["name"]}
                                    onChange={(e) =>
                                        updateVolunteerLocation(e, idx)
                                    }
                                    autoFocus={
                                        volunteerLocations.length === idx + 1
                                    }
                                />
                            </div>
                            <div className="flex-1">
                                <TextInput
                                    label="Contact"
                                    name="contact"
                                    value={volunteerLocations[idx]["contact"]}
                                    onChange={(e) =>
                                        updateVolunteerLocation(e, idx)
                                    }
                                />
                            </div>
                        </div>
                        <div className="mb-6">
                            <TextInput
                                label="Address"
                                name="address"
                                value={volunteerLocations[idx]["address"]}
                                onChange={(e) =>
                                    updateVolunteerLocation(e, idx)
                                }
                            />
                        </div>
                        <div className="flex space-x-3 mb-6">
                            <div className="flex-1">
                                <TextInput
                                    type="tel"
                                    label="Phone"
                                    name="phone"
                                    value={volunteerLocations[idx]["phone"]}
                                    onChange={(e) =>
                                        updateVolunteerLocation(e, idx)
                                    }
                                />
                            </div>
                            <div className="flex-1">
                                <TextInput
                                    type="email"
                                    label="Email"
                                    name="email"
                                    value={volunteerLocations[idx]["email"]}
                                    onChange={(e) =>
                                        updateVolunteerLocation(e, idx)
                                    }
                                />
                            </div>
                        </div>
                        <div className="mb-6">
                            <TextInput
                                label="Website"
                                name="website"
                                value={volunteerLocations[idx]["website"]}
                                onChange={(e) =>
                                    updateVolunteerLocation(e, idx)
                                }
                            />
                        </div>
                        <div className="flex justify-end mb-3">
                            <button
                                type="button"
                                onClick={() => removeVolunteerLocation(idx)}
                                className="text-gray-500 text-sm hover:text-red-500"
                            >
                                <FontAwesomeIcon
                                    icon={faTimesCircle}
                                    className="mr-1"
                                />{" "}
                                Remove Volunteer Location
                            </button>
                        </div>
                    </div>
                ))}
                <div className="flex">
                    <button
                        type="button"
                        onClick={(e) => appendVolunteerLocation(e)}
                        className="text-gray-500 text-sm hover:text-brand"
                    >
                        <FontAwesomeIcon icon={faPlusCircle} className="mr-1" />{" "}
                        Add Volunteer Location
                    </button>
                </div>
            </div>
        </Fieldset>
    );
}
