import React from "react";
import Checkbox from "../../../components/Checkbox";
import SelectInput from "../../../components/SelectInput";
import ResourceSelect, { EnumSelect } from "../../../components/ResourceSelect";

export default function EstatePlanningRoleForm({
    client,
    input,
    errors,
    readOnly = false,
    updateValue,
    onSubmit,
    relationships,
}) {
    const [resourceOptions] = React.useState(["Relationship", "Client"]);
    const roleOptions = [
        { value: "primary", label: "Primary" },
        { value: "secondary", label: "Secondary" },
        { value: "third", label: "Third" },
        { value: "fourth", label: "Fourth" },
    ];

    const updateOnBehalfOf = (event) => {
        let value = event.target.value;
        const parts = value.split(":");

        if (parts[0] !== undefined && parts[1] !== undefined) {
            updateValue({
                target: { name: "on_behalf_of_type", value: parts[0] },
            });
            updateValue({
                target: { name: "on_behalf_of_id", value: parts[1] },
            });
            return;
        }
        updateValue(event);
    };

    return (
        <form onSubmit={() => onSubmit(input)}>
            <div>
                <div className="mb-6">
                    <SelectInput
                        options={relationships}
                        name="relationship_id"
                        label="Person"
                        value={input.relationship_id}
                        onChange={updateValue}
                        disabled={readOnly}
                        client={client}
                        error={errors.relationship_id}
                        autoFocus={true}
                        append={{
                            value: "client",
                            label: client.full_name,
                        }}
                    />
                </div>
                <div className="w-full mb-6 flex items-center space-x-4">
                    <div className="flex-1">
                        <EnumSelect
                            enumType="estatePlanningRoles"
                            name="role_id"
                            label="Role"
                            value={input.role_id}
                            onChange={updateValue}
                            disabled={readOnly}
                            client={client}
                            error={errors.role_id}
                        />
                    </div>

                    <div className="flex-1">
                        <SelectInput
                            options={roleOptions}
                            name="role_level"
                            label="Level"
                            value={input.role_level}
                            onChange={updateValue}
                            disabled={readOnly}
                            client={client}
                            error={errors.role_level}
                        />
                    </div>
                </div>
                <div className="mb-6">
                    <ResourceSelect
                        resourceType={resourceOptions}
                        name="on_behalf_of"
                        label="For Whom"
                        value={
                            input.on_behalf_of_id
                                ? `${input.on_behalf_of_type}:${input.on_behalf_of_id}`
                                : ""
                        }
                        onChange={updateOnBehalfOf}
                        disabled={readOnly}
                        client={client}
                        error={errors.on_behalf_of_id}
                    />
                </div>
                <div className="mb-6">
                    <Checkbox
                        name="may_act_alone"
                        id="may_act_alone"
                        label="This person may act alone in this role if they are the only survivor."
                        value={input.may_act_alone}
                        defaultChecked={input.may_act_alone}
                        onChange={updateValue}
                        disabled={readOnly}
                        client={client}
                        error={errors.may_act_alone}
                    />
                </div>
                <input type="submit" hidden />
            </div>
        </form>
    );
}
