import { useEffect, useReducer, useRef, useState } from "react";
import { useHistory } from "react-router";

export default function useForm(initialData) {
    const history = useHistory();
    const initialState = useRef(initialData);
    const [input, setInput] = useReducer(
        (state, newState) => ({ ...state, ...newState }),
        initialData || null
    );
    const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
    const updateValue = (event) => {
        const initialValue = initialState.current
            ? initialState.current[event.target.name]
            : null;
        setInput({ [event.target.name]: event.target.value });
        setHasUnsavedChanges(
            initialValue !== null && initialValue !== event.target.value
        );
    };

    const resetInput = () => {
        setInput(initialData || null);
        clearUnsavedChanges();
    };

    const clearUnsavedChanges = () => {
        setHasUnsavedChanges(false);
    };

    useEffect(() => {
        let unBlock = history.block(() => {
            if (hasUnsavedChanges) {
                let result = window.confirm(
                    "There are unsaved changes, do you wish to discard them?"
                );
                if (result === true) {
                    clearUnsavedChanges();
                    resetInput();
                }
                return result;
            }
        });
        return () => {
            unBlock();
        };
    }, [hasUnsavedChanges]);

    return {
        input,
        setInput,
        updateValue,
        clearUnsavedChanges,
        hasUnsavedChanges,
        setHasUnsavedChanges,
        resetInput,
    };
}
