import usePost from "./usePost";
import { createAuthorizedPartyInvite } from "services/AuthorizedPartyService";

export default function useCreateAuthorizedPartyInvite(params, config = {}) {
    const { mutation, validationErrors } = usePost(
        (formData) => createAuthorizedPartyInvite(params, formData),
        { showToastOnError: true, showToastOnSuccess: true, ...config }
    );
    const onSubmit = (event, formData) => {
        event.preventDefault();
        return mutation.mutate(formData);
    };

    return { onSubmit, ...mutation, validationErrors };
}
