import React, {
    useState,
    useEffect,
    useCallback,
    useReducer,
    useContext,
} from "react";
import Api from "../../services/Api";
import Forbidden from "../../components/Forbidden";
import TextInput from "../../components/TextInput";
import TextArea from "../../components/TextArea";
import Button from "../../components/Button";
import SearchableSelect from "../../components/SearchableSelect";
import { useToasts } from "react-toast-notifications";
import InputErrors from "../../components/InputErrors";
import { MessageContext } from "../../contexts/MessageContext";
import VaultResourceFileList from "../resources/VaultResourceFileList";
import BasicModal, { BasicModalHeader } from "../../components/ModalBasic";
import Loading from "components/Loading";

export default function MessageComposer({
    client,
    onSend,
    showComposeModal,
    setShowComposeModal,
    fetchRecipientOptions,
    defaultRecipients,
    defaultSubject,
}) {
    const messageContext = useContext(MessageContext);

    const { addToast } = useToasts();
    const [notAuthorized, setNotAuthorized] = useState();
    const [errors, setErrors] = useState([]);
    const [sending, setSending] = useState(false);
    const [message, setMessage] = useReducer(
        (state, newState) => ({ ...state, ...newState }),
        {
            subject: "",
            body: "",
        }
    );

    const [recipients, setRecipients] = useState([]);

    useEffect(() => {
        setMessage({ subject: defaultSubject });
    }, [defaultSubject]);

    useEffect(() => {
        if (defaultRecipients.length > 0) {
            setRecipients(defaultRecipients);
        }
        return () => {
            setRecipients([]);
        };
    }, [defaultRecipients]);

    const updateValue = useCallback((event) => {
        setMessage({ [event.target.name]: event.target.value });
    }, []);

    const createDraft = async () => {
        try {
            let response = await Api.post(
                `clients/${client.id}/draft-messages`
            );
            setMessage(response.data.resource);
        } catch (error) {
            console.log(error);
        }
    };

    const sendMessage = useCallback(
        async (e) => {
            e.preventDefault();
            setSending(true);
            try {
                let response = await Api.post(`clients/${client.id}/messages`, {
                    draft_id: message.id,
                    subject: message.subject,
                    body: message.body,
                    recipients: recipients
                        .filter((recipient) => recipient)
                        .map((recipient) => recipient.value),
                });
                addToast(response.data.message);
                setMessage({
                    draft_id: null,
                    recipients: [],
                    subject: "",
                    body: "",
                });
                setRecipients([]);
                setSending(false);
                onSend(response.data.resource);
                messageContext.check();
            } catch (error) {
                setSending(false);
                setErrors([]);
                if (error.response.status === 403) {
                    setNotAuthorized(true);
                }
                if (error.response.data.errors) {
                    setErrors(error.response.data.errors);
                } else {
                    addToast(error.response.data.message, { type: "error" });
                }
            }
        },
        [client.id, message, recipients, addToast, onSend, messageContext]
    );

    const openComposeModal = async () => {
        if (showComposeModal === false) {
            return;
        }

        await createDraft();
    };

    useEffect(() => {
        if (showComposeModal === true) {
            openComposeModal();
        }
    }, [showComposeModal]);

    useEffect(() => {
        document.title = message ? message.subject : "Draft.";
    }, [message]);

    if (notAuthorized) {
        return (
            <Forbidden text="You do not have permission to compose messages." />
        );
    }

    return (
        <BasicModal
            isOpen={showComposeModal}
            size="3xl"
            onClose={() => setShowComposeModal(false)}
            header={
                <BasicModalHeader
                    title="New Message"
                    showSave={true}
                    onSave={(e) => sendMessage(e)}
                    saveText="Send Message"
                    onClose={() => setShowComposeModal(false)}
                    disabled={!recipients || !message.subject || !message.body}
                />
            }
        >
            <MessageComposerForm
                sending={sending}
                recipients={recipients}
                client={client}
                message={message}
                updateValue={updateValue}
                sendMessage={sendMessage}
                errors={errors}
                fetchRecipientOptions={fetchRecipientOptions}
                setRecipients={setRecipients}
                defaultSubject={defaultSubject}
            />
        </BasicModal>
    );
}

export function MessageComposerForm({
    sending,
    recipients,
    client,
    message,
    updateValue,
    sendMessage,
    errors,
    fetchRecipientOptions,
    setRecipients,
}) {
    if (!message?.id) {
        return <Loading />;
    }

    return (
        <form onSubmit={sendMessage}>
            <div className="mb-6">
                <InputErrors errors={errors.recipients} />
                <SearchableSelect
                    name="recipients"
                    id="recipients"
                    label="Recipients"
                    value={recipients}
                    required={true}
                    placeholder="Search for clients, members, or advisors by name"
                    loadOptions={fetchRecipientOptions}
                    isMulti
                    defaultOptions
                    autoFocus={true}
                    onChange={(selection) => {
                        setRecipients(selection);
                    }}
                />
            </div>
            <div className="mb-6">
                <TextInput
                    type="subject"
                    name="subject"
                    label="Subject"
                    value={message.subject}
                    error={errors.subject}
                    onChange={updateValue}
                    required={true}
                />
            </div>
            <div className="mb-6">
                <TextArea
                    name="body"
                    value={message.body}
                    error={errors.body}
                    onChange={updateValue}
                    required={true}
                    placeholder="Add your message here..."
                />
            </div>
            {client && client.type === "client" && (
                <div className="mb-6">
                    <VaultResourceFileList
                        title="Attach Files"
                        client={client}
                        resourceType={"Message"}
                        resourceId={message.id}
                    />
                </div>
            )}
            <div className="flex space-x-2 justify-end">
                <div className="w-40">
                    <Button
                        isLoading={sending}
                        type="submit"
                        disabled={
                            !recipients || !message.subject || !message.body
                        }
                    >
                        Send Message
                    </Button>
                </div>
            </div>
        </form>
    );
}
