import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

const Stepper = ({ step }) => {
    return (
        <ul className="flex justify-between items-center mb-12 text-center">
            <li
                className={
                    step >= 1
                        ? "text-brand-500 border-brand-500"
                        : "text-gray-500"
                }
            >
                <div>
                    <StepperIcon
                        completedStep={step > 1}
                        activeStep={step === 1}
                        label={"1"}
                    />
                    <StepperLabel
                        label="Email & Password"
                        completedStep={step > 1}
                        activeStep={step === 1}
                    />
                </div>
            </li>
            <hr className="flex-1 border-gray-200 mx-8" />
            <li
                className={
                    step >= 2
                        ? "text-brand-500 border-brand-500"
                        : "text-gray-500"
                }
            >
                <Link to={"/register/organization-step-1"}>
                    <StepperIcon
                        completedStep={step > 2}
                        activeStep={step === 2}
                        label={"2"}
                    />
                    <StepperLabel
                        label="Personal Info"
                        completedStep={step > 2}
                        activeStep={step === 2}
                    />
                </Link>
            </li>

            <hr className="flex-1 border-gray-200 mx-8" />
            <li
                className={
                    step === 3
                        ? "text-brand-500 border-brand-500"
                        : "text-gray-500"
                }
            >
                <div>
                    <StepperIcon
                        completedStep={step > 3}
                        activeStep={step === 3}
                        label={"3"}
                    />
                    <StepperLabel
                        label="Organization Info"
                        completedStep={step > 3}
                        activeStep={step === 3}
                    />
                </div>
            </li>
        </ul>
    );
};

export function StepperLabel({ completedStep, activeStep, label }) {
    if (completedStep) {
        return <div className="mt-1">{label}</div>;
    }

    if (activeStep) {
        return <div className="mt-1 font-bold">{label}</div>;
    }

    return <div className="mt-1">{label}</div>;
}

export function StepperIcon({ completedStep, activeStep, label }) {
    if (completedStep) {
        return (
            <div
                className={`flex mx-auto items-center justify-center h-7 w-7 rounded-full border-2 border-brand-500 bg-brand-500`}
            >
                <svg
                    className="text-white h-6 w-6 relative z-50 fill-current"
                    aria-hidden="true"
                    focusable="false"
                    data-prefix="fas"
                    data-icon="check-circle"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512"
                >
                    <path
                        fill="currentColor"
                        d="M504 256c0 136.967-111.033 248-248 248S8 392.967 8 256 119.033 8 256 8s248 111.033 248 248zM227.314 387.314l184-184c6.248-6.248 6.248-16.379 0-22.627l-22.627-22.627c-6.248-6.249-16.379-6.249-22.628 0L216 308.118l-70.059-70.059c-6.248-6.248-16.379-6.248-22.628 0l-22.627 22.627c-6.248 6.248-6.248 16.379 0 22.627l104 104c6.249 6.249 16.379 6.249 22.628.001z"
                    ></path>
                </svg>
            </div>
        );
    }

    if (activeStep) {
        return (
            <div
                className={`flex mx-auto items-center justify-center h-7 w-7 rounded-full border-2 border-brand-500 font-bold`}
            >
                {label}
            </div>
        );
    }

    return (
        <div
            className={`flex mx-auto items-center justify-center h-7 w-7 rounded-full border-2 border-current`}
        >
            {label}
        </div>
    );
}

Stepper.propTypes = {
    step: PropTypes.number.isRequired,
};

export default Stepper;
