import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import ClientProfile from "./ClientProfile";
import ClientPassword from "./ClientPassword";
import Relationships from "../relationships/Relationships";
import ClientPermissions from "./ClientPermissions";
import ClientProfileNotes from "./ClientProfileNotes";
import ClientRelatedFiles from "./ClientRelatedFiles";
import ClientRelatedPhotos from "./ClientRelatedPhotos";

function EditClient(props) {
    return (
        <Switch>
            <Route
                exact
                path="/clients/:clientId/edit"
                render={() => (
                    <Redirect
                        to={`/clients/${props.match.params.clientId}/edit/profile`}
                    />
                )}
            />
            <Route
                path="/clients/:clientId/edit/profile"
                render={() => <ClientProfile {...props} />}
            />
            <Route
                path="/clients/:clientId/edit/notes"
                render={() => <ClientProfileNotes {...props} />}
            />
            <Route
                path="/clients/:clientId/edit/permissions"
                render={() => <ClientPermissions {...props} />}
            />
            <Route
                path="/clients/:clientId/edit/password"
                render={() => <ClientPassword {...props} />}
            />
            <Route
                path="/clients/:clientId/edit/related-files"
                render={() => <ClientRelatedFiles {...props} />}
            />
            <Route
                path="/clients/:clientId/edit/related-photos"
                render={() => <ClientRelatedPhotos {...props} />}
            />
            <Route
                path="/clients/:clientId/relationships"
                render={() => <Relationships {...props} />}
            />
        </Switch>
    );
}

export default EditClient;
