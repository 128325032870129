import React from "react";
import SelectInput from "./SelectInput";

const hours = [
    { value: 0, label: "12" },
    { value: 1, label: "1" },
    { value: 2, label: "2" },
    { value: 3, label: "3" },
    { value: 4, label: "4" },
    { value: 5, label: "5" },
    { value: 6, label: "6" },
    { value: 7, label: "7" },
    { value: 8, label: "8" },
    { value: 9, label: "9" },
    { value: 10, label: "10" },
    { value: 11, label: "11" },
];

const minutes = () => {
    let minutes = [];
    for (let index = 0; index < 60; index++) {
        minutes.push({
            value: index,
            label: index < 10 ? `0${index}` : `${index}`,
        });
    }
    return minutes;
};

const TimePicker = ({
    updateHour,
    updateMinute,
    updateMeridiem,
    hour,
    minute,
    meridiem,
    readOnly = false,
}) => {
    return (
        <div className="flex items-center space-x-2">
            <SelectInput
                name="hour"
                options={hours}
                onChange={updateHour}
                value={hour}
                readOnly={readOnly}
            />
            <SelectInput
                name="minute"
                options={minutes()}
                onChange={updateMinute}
                value={minute}
                readOnly={readOnly}
            />
            <SelectInput
                name="meridiem"
                options={["AM", "PM"]}
                onChange={updateMeridiem}
                value={meridiem}
                readOnly={readOnly}
            />
        </div>
    );
};

export default TimePicker;
