import React from "react";
import Fieldset from "../../../components/Fieldset";
import { DataList } from "../../../components/TextInput";

export default function Access({ items, locations, setAccessItems }) {
    const updateValue = (e) => {
        let newItems = { ...items };
        newItems[e.target.name] = e.target.value;
        setAccessItems(newItems);
    };

    return (
        <Fieldset legend="Access To Your Home And Important Documents">
            <div className="mb-6">
                <DataList
                    list={locations}
                    label="Spare Key/Code to Home"
                    name="spare_home_key"
                    value={items["spare_home_key"]}
                    onChange={(e) => updateValue(e)}
                />
            </div>
            <div className="mb-6">
                <DataList
                    list={locations}
                    label="Spare Key/Code to Shed"
                    name="spare_shed_key"
                    value={items["spare_shed_key"]}
                    onChange={(e) => updateValue(e)}
                />
            </div>
            <div className="mb-6">
                <DataList
                    list={locations}
                    label="Spare Car Key"
                    name="spare_car_key"
                    value={items["spare_car_key"]}
                    onChange={(e) => updateValue(e)}
                />
            </div>
            <div className="mb-6">
                <DataList
                    list={locations}
                    label="Garage Door Code"
                    name="garage_door_code"
                    value={items["garage_door_code"]}
                    onChange={(e) => updateValue(e)}
                />
            </div>
            <div className="mb-6">
                <DataList
                    list={locations}
                    label="Garage Door Openers"
                    name="garage_door_openers"
                    value={items["garage_door_openers"]}
                    onChange={(e) => updateValue(e)}
                />
            </div>
            <div className="mb-6">
                <DataList
                    list={locations}
                    label="Alarms Codes"
                    name="alarm_codes"
                    value={items["alarm_codes"]}
                    onChange={(e) => updateValue(e)}
                />
            </div>
            <div className="mb-6">
                <DataList
                    list={locations}
                    label="Safe Deposit Box Key"
                    name="safe_deposit_box_key"
                    value={items["safe_deposit_box_key"]}
                    onChange={(e) => updateValue(e)}
                />
            </div>
            <div className="mb-6">
                <DataList
                    list={locations}
                    label="Safe Combination/Key"
                    name="safe_combination_key"
                    value={items["safe_combination_key"]}
                    onChange={(e) => updateValue(e)}
                />
            </div>
            <div className="mb-6 page-break-inside-avoid">
                <DataList
                    list={locations}
                    label="Key to Locked File Cabinet/Desk"
                    name="key_to_locked_file_cabinet_desk"
                    value={items["key_to_locked_file_cabinet_desk"]}
                    onChange={(e) => updateValue(e)}
                />
            </div>
            <div className="mb-6">
                <DataList
                    list={locations}
                    label="Storage Facility Access Information"
                    name="Storage Facility Access Information"
                    value={items["Storage Facility Access Information"]}
                    onChange={(e) => updateValue(e)}
                />
            </div>
            <div className="mb-6">
                <DataList
                    list={locations}
                    label="Water Shut-off Instructions"
                    name="water_shut_off_instructions"
                    value={items["water_shut_off_instructions"]}
                    onChange={(e) => updateValue(e)}
                />
            </div>
            <div className="mb-6">
                <DataList
                    list={locations}
                    label="Breaker Box"
                    name="breaker_box"
                    value={items["breaker_box"]}
                    onChange={(e) => updateValue(e)}
                />
            </div>
        </Fieldset>
    );
}
