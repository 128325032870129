import React from "react";
import { Route, Switch } from "react-router-dom";

import EmailPassword from "./EmailPassword";
import OrganizationStep1 from "./OrganizationStep1";
import OrganizationStep2 from "./OrganizationStep2";
// import TwoFactorAuthStep1 from "./TwoFactorAuthStep1";
// import TwoFactorAuthStep2 from "./TwoFactorAuthStep2";
// import TwoFactorAuthStep3 from "./TwoFactorAuthStep3";
import RegistrationContextProvider from "../../contexts/RegistrationContext";
import { GuestRoute } from "../../App";

export default function Register({ ...props }) {
    return (
        <>
            <Switch>
                <RegistrationContextProvider>
                    <Route
                        path="/register/email-password"
                        component={EmailPassword}
                    />
                    <Route
                        path="/register/organization-step-1"
                        component={OrganizationStep1}
                    />
                    <Route
                        path="/register/organization-step-2"
                        component={OrganizationStep2}
                    />
                    <GuestRoute
                        exact
                        path={props.match.url}
                        component={EmailPassword}
                    />
                </RegistrationContextProvider>
            </Switch>
            {/* <GuestRoute
                path="/register/invite-team-members"
                component={InviteTeamMembers}
            />
            <PrivateRoute
                path="/register/2fa-step-1"
                component={TwoFactorAuthStep1}
            />
            <PrivateRoute
                path="/register/2fa-step-2"
                component={TwoFactorAuthStep2}
            />
            <PrivateRoute
                path="/register/2fa-step-3"
                component={TwoFactorAuthStep3}
            /> */}
        </>
    );
}
