import React from "react";
import Button from "../../../components/Button";
import useClient from "../../../hooks/useClient";

import useIceContext from "../hooks/useIceContext";
import useIceVault from "../hooks/useIceVault";
import Spinner from "../../../components/Spinner";
import useAuth from "../../../hooks/useAuth";
import VaultWrapper from "../../clients/Vault/VaultWrapper";

export default function ICEVault({ clientPath, ...props }) {
    const {
        clientIceVaultId,
        spouseIceVaultId,
        initVault,
        currentName,
        isCreating,
        isLoading,
    } = useIceVault();

    const { user } = useAuth();

    const { client, isClient } = useClient();
    const { isSpouse } = useIceContext();

    const notInitialized =
        (isSpouse && !spouseIceVaultId) || (!isSpouse && !clientIceVaultId);

    return (
        <div className="flex flex-col">
            {isLoading && (
                <div className="flex-none mt-6 h-full w-full flex items-center justify-center">
                    <Spinner message="Loading ICE Vaults" />
                </div>
            )}
            {!isLoading && notInitialized && (
                <div className="flex-auto flex flex-col justify-center items-center">
                    <div className="flex-none">
                        <Button
                            text={"Setup ICE Vault For " + currentName}
                            loading={isCreating}
                            onClick={initVault}
                        />
                    </div>
                </div>
            )}

            {!isLoading && !notInitialized && (
                <VaultWrapper
                    user={user}
                    clientPath={clientPath}
                    isClient={isClient}
                    client={client}
                    vaultId={isSpouse ? spouseIceVaultId : clientIceVaultId}
                    reloadClient={() => {}}
                    {...props}
                />
            )}
        </div>
    );
}
