import React, { useState } from "react";
import Api from "../../services/Api";
import { useToasts } from "react-toast-notifications";
import SaveButton from "../../components/SaveButton";
import ClientNav from "./_partials/ClientNav";
import ClientPageHeader, { Heading } from "../../components/ClientPageHeader";
import PageContainer from "../../components/PageContainer";
import ProfilePhoto from "../members/_partials/ProfilePhoto";
import ClientProfileForm from "./_partials/ClientProfileForm";
import debounce from "../../util/debounce-promise";
import useForm from "hooks/useForm";

function ClientProfile({ client, reloadClient, ...props }) {
    const { addToast } = useToasts();
    const [errors, setErrors] = useState([]);

    const {
        input: clientInput,
        updateValue,
        clearUnsavedChanges,
    } = useForm(client);

    document.title = "Edit Profile: " + client.full_name;

    async function updateClient(e) {
        e.preventDefault();
        try {
            let response = await Api.put("clients/" + client.id, clientInput);
            addToast(response.data.message);
            setErrors([]);
            reloadClient();
            clearUnsavedChanges();
        } catch (e) {
            setErrors([]);
            if (e.response.data.errors) {
                setErrors(e.response.data.errors);
            } else {
                addToast(e.response.data.message, { type: "error" });
            }
        }
    }

    async function updateProfilePicture() {
        reloadClient();
    }

    async function fetchAttorneyOptions(query) {
        let response = await Api.get("members", {
            "filter[search]": query,
            "filter[role]": ["member_owner", "member_partner"],
            limit: 100,
        });

        return response.data.data.map((attorney) => {
            return {
                value: attorney.id.toString(),
                label: attorney.full_name,
            };
        });
    }

    const getAttorneyOptions = debounce(fetchAttorneyOptions, 250);

    return (
        <>
            <ClientPageHeader
                text="Edit Profile"
                client={clientInput}
                {...props}
            >
                <Heading>Edit Profile</Heading>
                <div className="flex flex-0 space-x-4">
                    {!client.disabled_at && (
                        <>
                            <SaveButton onClick={updateClient} />
                        </>
                    )}
                </div>
            </ClientPageHeader>
            <PageContainer>
                <div className="flex space-x-10">
                    <div className="flex-initial">
                        <ClientNav
                            active={props.history.location.pathname}
                            client={clientInput}
                        />
                        <div className="mt-6">
                            {clientInput.estate_planning_roles?.length > 0 && (
                                <div>
                                    <h2 className="border-b font-bold text-gray-700 text-sm">
                                        Estate Planning Roles
                                    </h2>
                                    <div className="flex flex-col space-y-3 divide-y divide-gray-200">
                                        {clientInput.estate_planning_roles.map(
                                            (item, idx) => (
                                                <dl
                                                    key={idx}
                                                    className="text-sm pt-3"
                                                >
                                                    <div className="flex space-x-1 items-center">
                                                        <dt className="text-gray-600">
                                                            Role:
                                                        </dt>
                                                        <dd>
                                                            {
                                                                item.role_description
                                                            }
                                                        </dd>
                                                    </div>
                                                    <div className="flex space-x-1 items-center">
                                                        <dt className="text-gray-600">
                                                            For Whom:
                                                        </dt>
                                                        <dd>
                                                            {
                                                                item.on_behalf_of_description
                                                            }
                                                        </dd>
                                                    </div>
                                                    <div className="flex space-x-1 items-center">
                                                        <dt className="text-gray-600">
                                                            Level:
                                                        </dt>
                                                        <dd>
                                                            {
                                                                item.role_level_description
                                                            }
                                                        </dd>
                                                    </div>
                                                </dl>
                                            )
                                        )}
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>

                    <div className="flex-1">
                        <form onSubmit={updateClient}>
                            <ClientProfileForm
                                client={clientInput}
                                errors={errors}
                                updateValue={updateValue}
                                getAttorneyOptions={getAttorneyOptions}
                                readOnly={false}
                            />
                        </form>
                    </div>

                    <div className="flex-initial">
                        <ProfilePhoto
                            memberId={client.id}
                            photo={client.profile_photo}
                            upload={updateProfilePicture}
                        />
                    </div>
                </div>
            </PageContainer>
        </>
    );
}

export default ClientProfile;
