import React from "react";

import { ownershipTypes } from "../../components/Ownership/OwnershipService";
import PercentInput from "../PercentInput";
import SelectInput from "../SelectInput";
import TextInput from "../TextInput";
import InputErrors from "../InputErrors";
import { OwnershipOptions } from "components/Ownership";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import useClient from "hooks/useClient";
import { FakeInput } from "components/TextInput";
import { useOwnershipContext } from "contexts/OwnershipContext";

export function TransferLineItem({
    source,
    destination,
    transfer,
    after,
    idx,
    transferUnit,
    updateDestination = () => {},
    errors = {},
    disabled = false,
}) {
    const { client } = useClient();
    const ownershipContext = useOwnershipContext();
    const assetOptions = ownershipContext.assetOptions;

    function updateOwnershipType(value) {
        let parts = value.split(":");
        let ownerType = parts[0];
        let ownerId = parts[1];

        updateDestination({
            ...destination,
            owner_type: ownerType,
            owner_id: ownerId,
            destination_type: ownerType,
            destination_id: ownerId,
            ...(ownerType === "Spouse" && client.spouse
                ? { owner_id: client.spouse.id }
                : {}),
        });
    }
    function updateDestinationDescription(value) {
        updateDestination({
            ...destination,
            destination_description: value,
        });
    }

    function updateType(value) {
        updateDestination({
            ...destination,
            type: value,
            destination_ownership_type: value,
        });
    }

    function updateUnit(value) {
        updateDestination({
            ...destination,
            unit: value,
        });
    }

    let max = 100;
    if (source) {
        if (source.proposed_ownership) {
            max = source.proposed_ownership?.percentage_owned ?? 0;
        } else {
            max = source.percentage_owned;
        }
    }

    if (transfer.source_proposed_ownership) {
        max =
            (transfer.source_proposed_ownership?.percentage_owned ?? 0) +
            transfer.transfer_percentage;
    }

    return (
        <>
            <div className="flex-1">
                <InputErrors errors={errors["destination.type"]} />
                <SelectInput
                    label={idx === 0 ? `Ownership Type` : ""}
                    name="type"
                    options={ownershipTypes()}
                    loading={false}
                    value={destination.type}
                    error={errors["destinations." + idx + ".type"]}
                    disabled={disabled}
                    required={true}
                    onChange={(e) => updateType(e.target.value, idx)}
                />
            </div>
            {destination.type && (
                <div className="flex-1">
                    <OwnershipOptions
                        label={idx === 0 ? `Ownership Option(s)` : ""}
                        assetOptions={assetOptions}
                        client={client}
                        type={destination.type}
                        ownerType={destination.owner_type}
                        ownerId={destination.owner_id}
                        description={destination.destination_description}
                        updateOption={(e) =>
                            updateOwnershipType(e.target.value, idx)
                        }
                        updateDescription={(e) =>
                            updateDestinationDescription(e.target.value, idx)
                        }
                        error={errors["destinations." + idx + ".owner_type"]}
                        disabled={disabled}
                    />
                </div>
            )}
            <div className="flex space-x-2 flex-0 w-30 mx-2">
                {!after && (
                    <div className="flex-1 relative">
                        {transferUnit === "percentage" ? (
                            <PercentInput
                                label={idx === 0 ? `Percentage` : ""}
                                name="percentage_owned"
                                id={`transfer_unit_${transfer.id}`}
                                value={destination.unit}
                                disabled={disabled}
                                required={true}
                                max={max}
                                error={
                                    errors["destinations." + idx + ".unit"] ||
                                    errors.unit
                                }
                                placeholder={`0 - ${max}`}
                                onChange={(e) =>
                                    updateUnit(e.target.value, idx)
                                }
                            />
                        ) : (
                            <TextInput
                                type="number"
                                label="$ Amount"
                                name="amount"
                                id={`transfer_unit_${transfer.id}`}
                                value={destination.unit}
                                disabled={disabled}
                                required={true}
                                placeholder="0.00"
                                error={
                                    errors["destinations." + idx + ".unit"] ||
                                    errors.unit
                                }
                                onChange={(e) =>
                                    updateUnit(e.target.value, idx)
                                }
                            />
                        )}
                    </div>
                )}
                {!!after && (
                    <>
                        {transferUnit === "percentage" ? (
                            <FakeInput
                                label={idx === 0 ? `Percentage` : ""}
                                name="percent"
                                disabled={true}
                                required={false}
                            >
                                <>
                                    {" "}
                                    <span className="line-through opacity-50">
                                        {max + "%"}
                                    </span>
                                    <span>{(after.unit ?? 0) + "%"}</span>
                                </>
                            </FakeInput>
                        ) : (
                            <FakeInput
                                label={idx === 0 ? `$ Amount` : ""}
                                name="amount"
                                disabled={true}
                                required={false}
                            >
                                <>
                                    {" "}
                                    <span className="line-through opacity-50">
                                        {"$" + destination.unit}
                                    </span>
                                    <span>{"$" + (after.unit ?? 0)}</span>
                                </>
                            </FakeInput>
                        )}
                    </>
                )}
            </div>
        </>
    );
}

export default function TransferDestination({
    errors,
    source,
    transfer,
    transferUnit,
    destinations,
    setDestinations,
}) {
    function updateDestination(newDestination) {
        setDestinations(
            destinations.map((destination) => {
                if (destination.uuid === newDestination.uuid) {
                    return newDestination;
                }

                return destination;
            })
        );
    }

    // function appendDestination() {
    //     let newOptions = [
    //         ...destinations,
    //         {
    //             uuid: destinations.length + 1,
    //             type: "",
    //             resource_id: asset.id,
    //             resource_type: asset.type,
    //             owner_id: client.id,
    //             owner_type: "Client",
    //             percentage_owned: 0,
    //             owner_description: "",
    //         },
    //     ];
    //     setDestinations(newOptions);
    // }

    function removeDestination(idx) {
        setDestinations(destinations.filter((destination, i) => i !== idx));
    }

    return (
        <div className="space-y-4">
            {destinations.map((destination, idx) => (
                <div
                    className={`flex space-x-3 items-end`}
                    key={`destination_${idx}`}
                >
                    <TransferLineItem
                        destination={destination}
                        updateDestination={updateDestination}
                        source={source}
                        transfer={transfer}
                        idx={idx}
                        transferUnit={transferUnit}
                        errors={errors}
                    />
                    {destinations.length > 1 && (
                        <button
                            tabIndex={-1}
                            type="button"
                            className="text-gray-500 text-sm hover:text-red-500 mb-4"
                            title="Remove Ownership"
                            onClick={() => removeDestination(idx)}
                        >
                            <FontAwesomeIcon icon={faTimesCircle} />
                        </button>
                    )}
                </div>
            ))}
        </div>
    );
}
