import Api from "./Api";

export const fetchAllPrices = async function ({ queryKey }) {
    const [, { ...params }] = queryKey;
    return await Api.get("billing/get-prices", params).then((res) => res.data);
};

export const fetchCurrentSubscription = async function ({ queryKey }) {
    const [, { ...params }] = queryKey;
    return await Api.get("billing/current-subscription", params).then(
        (res) => res.data
    );
};

export default {
    fetchAllPrices,
};
