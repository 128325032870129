import Api from "./Api";

const chunkSize = 1024 * 1024 * 8;

const chunkUploader = (endpoint, file, data, options) => {
    let fileType = file.type;
    file = new File([data], file.name, {});

    const start = options.chunkNumber * chunkSize;
    const end = Math.min(file.size, start + chunkSize);

    options.blockCount = Math.ceil(file.size / chunkSize);

    let currentChunkSize = chunkSize;
    if (options.chunkNumber + 1 === options.blockCount) {
        currentChunkSize = file.size - start;
    }

    const params = new FormData();
    params.append("resumableChunkNumber", options.chunkNumber + 1);
    params.append("resumableChunkSize", currentChunkSize);
    params.append("resumableCurrentChunkSize", currentChunkSize);
    params.append("resumableTotalSize", file.size);
    params.append("resumableType", fileType);
    params.append("resumableIdentifier", options.identifier);
    params.append("resumableFilename", file.name);
    params.append("resumableRelativePath", file.name);
    params.append("resumableTotalChunks", options.blockCount);

    params.append("file", file.slice(start, end), file.name);

    return Api.post(endpoint, params)
        .then((res) => {
            options.onProgress &&
                options.onProgress(parseInt((end / file.size) * 100, 10), res);
            if (end === file.size) {
                options.onSuccess && options.onSuccess(res);
            } else {
                options.chunkNumber++;
                return chunkUploader(endpoint, file, data, options);
            }
        })
        .catch((err) => {
            options.onError && options.onError(err);
        });
};

export default {
    chunk: (endpoint, file, data, onProgress, onError, onSuccess) => {
        const chunkNumber = 0;
        const identifier = `${file.size}-${file.name.replace(".", "")}`;

        return chunkUploader(endpoint, file, data, {
            identifier,
            chunkNumber,
            onProgress,
            onError,
            onSuccess,
        });
    },
};
