import React from "react";
import useFormatCurrency from "../../../hooks/useFormatCurrency";
import TextInput from "../../../components/TextInput";
import PercentInput from "../../../components/PercentInput";
import { StateSelect } from "../../../components/SelectInput";
import FrequencySelect from "../../../components/FrequencySelect";
import PersonalPropertySelect from "../../../components/PersonalPropertySelect";
import Checkbox from "../../../components/Checkbox";

export default function NonMortgageLiabilityForm({
    client,
    input = {},
    errors = {},
    readOnly = false,
    onSubmit,
    updateValue,
}) {
    const formatCurrency = useFormatCurrency();

    return (
        <>
            <form onSubmit={onSubmit}>
                <div>
                    <div className="mb-6">
                        <TextInput
                            name="name"
                            label="Description"
                            id="name"
                            autoFocus={true}
                            readOnly={readOnly}
                            disabled={readOnly}
                            value={input.name}
                            error={errors.name}
                            onChange={updateValue}
                            required={true}
                        />
                    </div>
                    <fieldset className="mt-12">
                        <legend className="font-bold txt-md text-gray-500 my-6 pb-1 border-b block w-full">
                            Lender
                        </legend>
                        <div className="mb-6">
                            <TextInput
                                name="lender_name"
                                label="Lender Name"
                                id="lender_name"
                                readOnly={readOnly}
                                disabled={readOnly}
                                value={input.lender_name}
                                error={errors.lender_name}
                                onChange={updateValue}
                                required={true}
                            />
                        </div>
                        <div className="mb-6">
                            <TextInput
                                name="names_on_loan"
                                label="Name(s) on Loan"
                                id="names_on_loan"
                                readOnly={readOnly}
                                disabled={readOnly}
                                value={input.names_on_loan}
                                error={errors.names_on_loan}
                                onChange={updateValue}
                            />
                        </div>
                        <div className="mb-6">
                            <TextInput
                                name="lender_account_number"
                                label="Loan/Account Number"
                                id="lender_account_number"
                                readOnly={readOnly}
                                disabled={readOnly}
                                value={input.lender_account_number}
                                error={errors.lender_account_number}
                                onChange={updateValue}
                            />
                        </div>
                        <div className="mb-6">
                            <TextInput
                                name="lender_address"
                                label="Address"
                                id="lender_address"
                                readOnly={readOnly}
                                disabled={readOnly}
                                value={input.lender_address}
                                error={errors.lender_address}
                                onChange={updateValue}
                            />
                        </div>
                        <div className="flex mb-6">
                            <div className="w-1/3 mr-4">
                                <TextInput
                                    name="lender_city"
                                    label="City"
                                    id="lender_city"
                                    value={input.lender_city}
                                    error={errors.lender_city}
                                    onChange={updateValue}
                                    readOnly={readOnly}
                                    disabled={readOnly}
                                />
                            </div>
                            <div className="w-1/3 mr-4">
                                <StateSelect
                                    name="lender_state"
                                    label="State"
                                    id="lender_state"
                                    disabled={readOnly}
                                    readOnly={readOnly}
                                    value={input.lender_state}
                                    error={errors.lender_state}
                                    onChange={updateValue}
                                    append={["Other"]}
                                />
                            </div>
                            <div className="w-1/3">
                                <TextInput
                                    name="lender_postal_code"
                                    label="Postal Code"
                                    id="lender_postal_code"
                                    value={input.lender_postal_code}
                                    error={errors.lender_postal_code}
                                    onChange={updateValue}
                                    readOnly={readOnly}
                                    disabled={readOnly}
                                />
                            </div>
                        </div>
                        <div className="mb-6">
                            <TextInput
                                name="lender_phone"
                                label="Phone"
                                id="lender_phone"
                                readOnly={readOnly}
                                disabled={readOnly}
                                value={input.lender_phone}
                                error={errors.lender_phone}
                                onChange={updateValue}
                            />
                        </div>
                    </fieldset>

                    <fieldset className="mt-6">
                        <legend className="font-bold txt-md text-gray-500 my-6 pb-1 border-b block w-full">
                            Loan Amounts
                        </legend>
                        <div className="flex mb-6">
                            <div className="w-1/2 mr-4">
                                <TextInput
                                    name="balance"
                                    label="Balance"
                                    id="balance"
                                    readOnly={readOnly}
                                    disabled={readOnly}
                                    value={input.balance}
                                    error={errors.balance}
                                    onChange={updateValue}
                                    onBlur={(e) => {
                                        e.target.value = formatCurrency(
                                            e.target.value
                                        );
                                        updateValue(e);
                                    }}
                                    required={true}
                                />
                            </div>
                            <div className="w-1/2">
                                <TextInput
                                    type="date"
                                    name="balance_as_of_date"
                                    label="As of Date"
                                    id="balance_as_of_date"
                                    readOnly={readOnly}
                                    disabled={readOnly}
                                    value={input.balance_as_of_date}
                                    error={errors.balance_as_of_date}
                                    onChange={updateValue}
                                    required={true}
                                />
                            </div>
                        </div>
                        <div className="flex mb-6">
                            <div className="w-1/2 mr-4">
                                <TextInput
                                    name="initial_loan_amount"
                                    label="Initial Amount"
                                    id="initial_loan_amount"
                                    readOnly={readOnly}
                                    disabled={readOnly}
                                    value={input.initial_loan_amount}
                                    error={errors.initial_loan_amount}
                                    onChange={updateValue}
                                    onBlur={(e) => {
                                        if (e.target.value) {
                                            e.target.value = formatCurrency(
                                                e.target.value
                                            );
                                            updateValue(e);
                                        }
                                    }}
                                />
                            </div>
                            <div className="w-1/2">
                                <TextInput
                                    type="date"
                                    name="loan_date"
                                    label="Loan Date"
                                    id="loan_date"
                                    readOnly={readOnly}
                                    disabled={readOnly}
                                    value={input.loan_date}
                                    error={errors.loan_date}
                                    onChange={updateValue}
                                />
                            </div>
                        </div>
                        <div className="mb-6">
                            <PercentInput
                                name="interest_rate"
                                label="Interest Rate"
                                id="interest_rate"
                                readOnly={readOnly}
                                disabled={readOnly}
                                value={input.interest_rate}
                                error={errors.interest_rate}
                                onChange={updateValue}
                            />
                        </div>
                        <div className="mb-6">
                            <Checkbox
                                id="has_payment_plan"
                                name="has_payment_plan"
                                readOnly={readOnly}
                                disabled={readOnly}
                                defaultChecked={input.has_payment_plan === "1"}
                                onChange={updateValue}
                                label="Has Payment Plan"
                            />
                        </div>
                        {input.has_payment_plan === "1" && (
                            <div className="flex mb-6">
                                <div className="w-1/2 mr-4">
                                    <TextInput
                                        name="payment"
                                        label="Payment"
                                        id="payment"
                                        readOnly={readOnly}
                                        disabled={readOnly}
                                        value={input.payment}
                                        error={errors.payment}
                                        onChange={updateValue}
                                        onBlur={(e) => {
                                            if (e.target.value) {
                                                e.target.value = formatCurrency(
                                                    e.target.value
                                                );
                                                updateValue(e);
                                            }
                                        }}
                                    />
                                </div>
                                <div className="w-1/2">
                                    <FrequencySelect
                                        name="frequency_id"
                                        label="Payment Frequency"
                                        disabled={readOnly}
                                        id="frequency_id"
                                        value={input.frequency_id}
                                        error={errors.frequency_id}
                                        onChange={updateValue}
                                    />
                                </div>
                            </div>
                        )}
                        <div className="mb-6">
                            <Checkbox
                                id="has_personal_property"
                                name="has_personal_property"
                                readOnly={readOnly}
                                disabled={readOnly}
                                defaultChecked={
                                    input.has_personal_property === 1
                                }
                                onChange={updateValue}
                                label="Is this lien attached to any personal property?"
                            />
                        </div>
                        {parseInt(input.has_personal_property) === 1 && (
                            <div>
                                <PersonalPropertySelect
                                    client={client}
                                    disabled={readOnly}
                                    value={input.personal_property_id}
                                    name="personal_property_id"
                                    error={errors.personal_property_id}
                                    label="Personal Property"
                                    onChange={updateValue}
                                />
                            </div>
                        )}
                    </fieldset>
                </div>
                <input type="submit" hidden />
            </form>
        </>
    );
}
