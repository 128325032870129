import React from "react";
import BudgetContextProvider from "./BudgetContext";
import BudgetEstimateForm from "./BudgetEstimateForm";

export default function BudgetEstimates({ ...props }) {
    return (
        <BudgetContextProvider>
            <BudgetEstimateForm {...props} />
        </BudgetContextProvider>
    );
}
