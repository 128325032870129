import React from "react";
import TransfersTable from "views/funding_table/_partials/TransfersTable";
import InsurancePoliciesLayout from "./InsurancePoliciesLayout";

export default function EditInsurancePolicyTransfers({
    client,
    assetId,
    currentBasePath,
    title,
}) {
    document.title = `Transfers ${title}`;

    return (
        <InsurancePoliciesLayout
            currentBasePath={currentBasePath}
            title={title}
        >
            <TransfersTable
                assetType={`InsurancePolicy`}
                assetId={assetId}
                client={client}
            />
        </InsurancePoliciesLayout>
    );
}
