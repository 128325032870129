import React from "react";
import ClientPageHeader, { Heading } from "../../components/ClientPageHeader";
import PageContainer from "../../components/PageContainer";
import Spinner from "../../components/Spinner";
import Button from "../../components/Button";
import useClient from "hooks/useClient";
import usePostMortemBeneficiaries from "hooks/usePostMortemBeneficiaries";
import AssetDisplayForBeneficiaryPreview from "components/Ownership/AssetDisplayForBeneficiaryPreview";
import ResourceContextProvider from "contexts/ResourceContext";
import usePostMortemDownloads from "views/post_mortem/usePostMortemDownloads";
import PostMortemContextProvider from "views/post_mortem/PostMortemContext";
import { ClientSpouseSelect } from "views/post_mortem/PostMortemIndex";
import usePostMortemState from "hooks/usePostMortemState";
import useHasPermission from "hooks/usePermissions";

const pageTitle = "Beneficiary Claims Report";

export default function BeneficiaryClaimsReport({ client }) {
    document.title = pageTitle;

    return (
        <ResourceContextProvider
            refresh={() => {}}
            resourceTypes={[
                "Client",
                "Relationship",
                "Trust",
                "BusinessInterest",
            ]}
        >
            <PostMortemContextProvider>
                <ReportContent client={client} />
            </PostMortemContextProvider>
        </ResourceContextProvider>
    );
}

export const ReportContent = ({ client }) => {
    const forbidden =
        useHasPermission("report_beneficiary_claims_report") === false;
    const { person } = usePostMortemState();
    const { clientPath } = useClient();
    const { data, isLoading } = usePostMortemBeneficiaries(
        {
            clientId: client.id,
            includeTrusts: false,
            liabilities: true,
            person,
            is_post_mortem: true,
        },
        {
            keepPreviousData: false,
        }
    );

    const { isLoading: isDownloading, exportBeneficiaryReport } =
        usePostMortemDownloads();

    async function exportRecords(e) {
        e.preventDefault();
        exportBeneficiaryReport(e);
    }

    return (
        <>
            <ClientPageHeader client={client}>
                <Heading
                    backTo={`${clientPath}/reports`}
                    backText="All Reports"
                >
                    {pageTitle}
                </Heading>
                {data?.length > 0 && !forbidden && (
                    <Button
                        text="Download"
                        appearance="outline"
                        width="w-auto"
                        isLoading={isDownloading}
                        onClick={exportRecords}
                    />
                )}
            </ClientPageHeader>
            <PageContainer forbidden={forbidden}>
                <ClientSpouseSelect
                    client={client}
                    showValuations={true}
                    currentTab={"beneficiaries"}
                />
                {isLoading ? (
                    <Spinner message="Loading Beneficiary Designations" />
                ) : (
                    <div className="w-full space-y-6">
                        {data?.map((asset) => {
                            return (
                                <AssetDisplayForBeneficiaryPreview
                                    key={`${asset.type}_${asset.id}`}
                                    client={client}
                                    asset={asset}
                                    manageTransfer={() => console.log}
                                />
                            );
                        })}
                        {data?.length === 0 && (
                            <div>
                                No Beneficiary Designations Exist. Please add
                                Beneficiary Designations, if applicable.
                            </div>
                        )}
                    </div>
                )}
            </PageContainer>
        </>
    );
};
