import React, { useState, useEffect } from "react";
import { Route, Switch } from "react-router-dom";
import Spinner from "../../components/Spinner";
import useClient from "../../hooks/useClient";
import Api from "../../services/Api";
import EstatePlanningRolesIndex from "./EstatePlanningRolesIndex";

export default function EstatePlanningRoles(parentProps) {
    const { client } = useClient();
    const [relationships, setRelationships] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            let response = await Api.get(
                "clients/" + client.id + "/relationships"
            );
            setRelationships(
                [
                    { value: "", label: "Choose an option", disabled: true },
                ].concat(
                    response.data.data.map((relationship) => {
                        return {
                            value: relationship.id,
                            label: relationship.full_name_with_relationship,
                        };
                    })
                )
            );
            setLoading(false);
        };
        fetchData();
    }, [client]);

    if (loading) {
        return <Spinner />;
    }

    return (
        <Switch>
            <Route
                exact
                path={[
                    "/clients/:clientId/estate-planning-roles",
                    "/client/estate-planning-roles",
                ]}
                render={(props) => (
                    <EstatePlanningRolesIndex
                        {...parentProps}
                        {...props}
                        relationships={relationships}
                    />
                )}
            />
        </Switch>
    );
}
