import React, { useState, useReducer, useEffect } from "react";
import TextInput from "../../components/TextInput";
import MemberNav from "./_partials/MemberNav";
import Api from "../../services/Api";
import SaveButton from "../../components/SaveButton";
import { MemberHeader } from "../../components/PageHeader";
import PageContainer from "../../components/PageContainer";
import SectionHeader from "../../components/SectionHeader";

export default function MemberProfile({ member, ...props }) {
    const [errors, setErrors] = useState([]);

    useEffect(() => {
        setMemberInput(member);
        document.title = "Edit Member: " + member.full_name;
    }, [member]);

    function updateValue(event) {
        setMemberInput({ [event.target.name]: event.target.value });
    }

    const [memberInput, setMemberInput] = useReducer(
        (state, newState) => ({ ...state, ...newState }),
        member
    );

    const updateMember = async (e) => {
        e.preventDefault();
        try {
            await Api.put("members/" + member.id, memberInput);
            setErrors([]);
            props.reloadMember();
        } catch (e) {
            if (e.response.data.errors) {
                setErrors(e.response.data.errors);
            }
        }
    };

    if (!member) {
        return <div>Loading...</div>;
    }

    return (
        <>
            <MemberHeader text={member.full_name}>
                {!member.deleted_at && (
                    <SaveButton onClick={(e) => updateMember(e)} />
                )}
            </MemberHeader>
            <PageContainer>
                <div className="flex space-x-10">
                    <div className="flex-initial">
                        <MemberNav
                            active={props.history.location.pathname}
                            member={member}
                        />
                    </div>
                    <div className="flex-1">
                        <SectionHeader>Edit Profile</SectionHeader>
                        <form onSubmit={(e) => updateMember(e)}>
                            <div>
                                <div className="flex mb-6">
                                    <div className="flex-1 mr-4">
                                        <TextInput
                                            name="first_name"
                                            label="First Name"
                                            id="first_name"
                                            value={memberInput.first_name}
                                            onChange={updateValue}
                                            error={errors.first_name}
                                            required={true}
                                            autoFocus={true}
                                        />
                                    </div>
                                    <div className="flex-1">
                                        <TextInput
                                            name="last_name"
                                            label="Last Name"
                                            id="last_name"
                                            value={memberInput.last_name}
                                            onChange={updateValue}
                                            required={true}
                                            error={errors.last_name}
                                        />
                                    </div>
                                </div>

                                <div className="mb-6">
                                    <TextInput
                                        name="email"
                                        label="Email"
                                        id="email"
                                        value={memberInput.email}
                                        onChange={updateValue}
                                        required={true}
                                        error={errors.email}
                                    />
                                </div>

                                <div className="mb-6">
                                    <TextInput
                                        name="phone"
                                        label="Phone"
                                        id="phone"
                                        value={memberInput.phone}
                                        onChange={updateValue}
                                        error={errors.phone}
                                    />
                                </div>

                                <div className="mb-6">
                                    <TextInput
                                        name="title"
                                        label="Title"
                                        id="title"
                                        value={memberInput.title}
                                        onChange={updateValue}
                                        error={errors.title}
                                    />
                                </div>
                                <input type="submit" hidden />
                            </div>
                        </form>
                    </div>
                </div>
            </PageContainer>
        </>
    );
}
