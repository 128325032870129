import useFetch from "./useFetch";
import { fetchGlobalSetting } from "services/GlobalSettingService";

export default function useGlobalSetting(
    key,
    config = { keepPreviousData: false }
) {
    return useFetch(
        ["fetchGlobalSetting", { key }],
        fetchGlobalSetting,
        config
    );
}
