import React, { useState } from "react";
import { Route, Switch } from "react-router-dom";
import PageContainer from "../../components/PageContainer";
import Button from "../../components/Button";
import ClientPageHeader, { Heading } from "../../components/ClientPageHeader";
import LeasesTable from "./_partials/LeasesTable";
import { useToasts } from "react-toast-notifications";
import Api from "../../services/Api";
import { downloadFile } from "../../util/Downloader";
import useHasAnyPermissions, { useUserCan } from "hooks/useHasAnyPermissions";

export default function LeasesIndex({ client, clientPath, ...props }) {
    const [showAddModal, setShowAddModal] = useState(false);
    const [isExporting, setIsExporting] = useState(false);
    const [recordCount, setRecordCount] = useState(0);
    const { addToast } = useToasts();
    const canCreateWealthRecords = useUserCan("createWealthRecord");
    const canDownloadReport = useHasAnyPermissions([
        "report_global_access",
        "report_leases_report",
    ]);

    async function exportRecords(e) {
        e.preventDefault();
        try {
            setIsExporting(true);
            let response = await Api.get(
                "clients/" + client.id + "/leases",
                {
                    export: true,
                },
                { responseType: "arraybuffer" }
            );
            await downloadFile(response, "other-leases");
            setIsExporting(false);
            addToast("Export downloaded");
        } catch (e) {
            setIsExporting(false);
            addToast(e.response.data.message, { type: "error" });
        }
    }

    function updateRecordCount(count) {
        setRecordCount(count);
    }

    return (
        <>
            <ClientPageHeader client={client}>
                <Heading>Other Leases</Heading>
                <div className="flex-0 flex space-x-4">
                    {canCreateWealthRecords && (
                        <Button
                            type="link"
                            to={`${clientPath}/leases/add`}
                            text="Add Other Lease"
                        />
                    )}
                    {recordCount > 0 && canDownloadReport && (
                        <Button
                            text="Download"
                            appearance="outline"
                            width="w-auto"
                            isLoading={isExporting}
                            onClick={exportRecords}
                        />
                    )}
                </div>
            </ClientPageHeader>
            <PageContainer>
                <div className="flex space-x-8">
                    <div className="flex-1">
                        <Switch>
                            <Route
                                path={clientPath + "/leases"}
                                render={(newProps) => (
                                    <LeasesTable
                                        client={client}
                                        clientPath={clientPath}
                                        showAddModal={showAddModal}
                                        setShowAddModal={setShowAddModal}
                                        updateRecordCount={updateRecordCount}
                                        showDisabled={false}
                                        {...props}
                                        {...newProps}
                                    />
                                )}
                            />
                        </Switch>
                    </div>
                </div>
            </PageContainer>
        </>
    );
}
