import React from "react";
import SelectInput from "./SelectInput";
import Api from "../services/Api";

export default function TrustTypeSelect(props) {
    const [loading, setLoading] = React.useState(true);
    const [options, setOptions] = React.useState([]);

    React.useEffect(() => {
        const fetchData = async () => {
            if (options.length < 1) {
                try {
                    let response = await Api.get("enums/trust-types");
                    let options = response.data;
                    if (props.only) {
                        options = options.filter((option) => {
                            if (props.only.includes(option.value)) {
                                return option;
                            } else {
                                return false;
                            }
                        });
                    }
                    setOptions(options);
                    setLoading(false);
                } catch (error) {
                    console.log(error);
                }
            }
        };
        fetchData();
    }, [props.only, options.length]);

    return (
        <SelectInput
            options={options}
            loading={loading}
            {...props}
            defaultOption={"Select an option..."}
            defaultOptionDisabled={true}
            defaultOptionValue={""}
        />
    );
}
