import React, { useContext, useState, useEffect } from "react";
import { AuthContext } from "../../contexts/AuthContext";
import SelectInput from "../../components/SelectInput";
import ClientPageHeader, { Heading } from "../../components/ClientPageHeader";
import PageContainer from "../../components/PageContainer";
import ClientNav from "./_partials/ClientNav";
import Button from "../../components/Button";
import { formatDate } from "../../util/TextUtil";
import ModalResetClientPermissions from "./ModalResetClientPermissions";
import Forbidden from "../../components/Forbidden";
import PermissionList from "components/Permissions/PermissionList";
import useClientPermissions from "../../hooks/useClientPermissions";
import useUpdateClientPermission from "../../hooks/useUpdateClientPermission";
import Spinner from "../../components/Spinner";
import useResetClientPermission from "../../hooks/useResetClientPermission";
import useUpdateClientRole from "../../hooks/useUpdateClientRole";
import { getPermissionTypes } from "services/PermissionService";
import useHasAnyPermissions from "hooks/useHasAnyPermissions";

export default function ClientPermissions({ client, reloadClient, ...props }) {
    let roleOptions = [
        { value: "client_owner", label: "Owner" },
        { value: "client_spouse", label: "Spouse" },
        { value: "client_successor", label: "Successor" },
    ];

    if (client.role === "client_owner") {
        roleOptions = [{ value: "client_owner", label: "Owner" }];
    } else if (client.role === "client_spouse") {
        roleOptions = [{ value: "client_spouse", label: "Spouse" }];
    }

    const [readOnly, setReadOnly] = useState(true);
    const [showReset, setShowReset] = useState(false);
    const [permissionType, setPermissionType] = useState("");

    const canEditPermissions = useHasAnyPermissions([
        "firm_client_related_accounts_administration",
        "client_related_accounts_administration",
    ]);

    const context = useContext(AuthContext);

    const { data, isLoading, refetch } = useClientPermissions({
        clientId: client.id,
    });

    const permissionTypes = getPermissionTypes(data || []);

    const { onSubmit, isSuccess } = useUpdateClientPermission(client.id);

    const { onSubmit: onReset, isSuccess: isReset } = useResetClientPermission(
        client.id
    );

    const { onSubmit: onRoleChange, isSuccess: hasRoleChanged } =
        useUpdateClientRole(client.id);

    useEffect(() => {
        if (isSuccess || isReset) {
            refetch();
        }
        if (hasRoleChanged) {
            reloadClient();
        }
    }, [isSuccess, isReset, hasRoleChanged]);

    const filteredPermissions = () => {
        if (permissionType === "general" || permissionType === "report") {
            return data.filter(
                (permission) => permission.type === permissionType
            );
        }
        return data;
    };

    const updateRole = async (event, role) => onRoleChange(event, role);

    const updatePermission = async (event, permission, toggled) => {
        onSubmit(event, {
            key: permission,
            enabled: toggled,
        });
    };

    const resetPermission = async (event, permission) => {
        onReset(event, permission);
    };

    const handleResetPermission = async (event, permissionKey) => {
        event.preventDefault();
        resetPermission(event, permissionKey);
    };
    const handleUpdatePermission = (event, permission, toggled) => {
        event.preventDefault();
        updatePermission(event, permission, toggled);
    };

    return (
        <>
            <ClientPageHeader client={client} text="Permissions">
                <Heading>Permissions</Heading>
                {canEditPermissions && (
                    <div className="flex items-end space-x-3">
                        <div className="flex-1">
                            <Button
                                text="Reset All Permissions"
                                onClick={() => setShowReset(true)}
                            />
                        </div>
                        <div className="flex-1">
                            <Button
                                text={readOnly ? "Unlock" : "Lock"}
                                onClick={() =>
                                    setReadOnly((readOnly) => !readOnly)
                                }
                            />
                        </div>
                    </div>
                )}
            </ClientPageHeader>
            <PageContainer>
                {client.deleted_at && (
                    <div className="bg-red-500 rounded-sm text-red-100 p-6 py-3 mb-8">
                        This member was disabled on{" "}
                        {formatDate(client.deleted_at)}
                    </div>
                )}

                <div className="flex">
                    <div className="mr-10 flex-initial">
                        <ClientNav
                            active={props.history.location.pathname}
                            client={client}
                            auth={context}
                        />
                    </div>

                    {isLoading && <Spinner />}
                    {!canEditPermissions && <Forbidden />}
                    {data && data.length && canEditPermissions && (
                        <form className="flex-1">
                            {roleOptions.length > 1 && (
                                <div className="flex justify-between items-center py-6 pt-0 border-b">
                                    <label>Role</label>
                                    <div className="w-64 max-w-full">
                                        <SelectInput
                                            options={roleOptions}
                                            value={client.role}
                                            name="role"
                                            onChange={(e) => {
                                                updateRole(e, e.target.value);
                                            }}
                                        ></SelectInput>
                                    </div>
                                </div>
                            )}

                            <div className="flex justify-between items-center pb-6 border-b">
                                <label>Permission Type</label>
                                <div className="w-64 max-w-full">
                                    <SelectInput
                                        options={permissionTypes}
                                        value={permissionType}
                                        name="type"
                                        readOnly={readOnly}
                                        onChange={(e) => {
                                            setPermissionType(e.target.value);
                                        }}
                                    />
                                </div>
                            </div>

                            <PermissionList
                                permissions={filteredPermissions()}
                                resetPermission={handleResetPermission}
                                updatePermission={handleUpdatePermission}
                                readOnly={readOnly}
                            />

                            <input type="submit" hidden />
                        </form>
                    )}
                </div>
            </PageContainer>

            <ModalResetClientPermissions
                client={client}
                show={showReset}
                setShow={setShowReset}
                onResult={() => {
                    reloadClient();
                    refetch();
                }}
            />
        </>
    );
}
