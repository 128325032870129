import React from "react";

export const parseMoney = (value) => {
    let valueString = "" + (value || "0");
    return parseFloat(valueString.replaceAll(",", "").replaceAll("$", ""));
};

export const formatMoney = (value, options = {}) => {
    let float = parseMoney(value);
    return float.toLocaleString("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
        style: "currency",
        currency: "USD",
        ...options,
    });
};

export default function Money({ value, formatNegative = false }) {
    let float = parseMoney(value);
    const moneyString = float.toLocaleString("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
        style: "currency",
        currency: "USD",
    });
    // TODO: Detect non US English numbers? Globalize.parseFloat('0,04'); - https://stackoverflow.com/questions/7571553/javascript-parse-float-is-ignoring-the-decimals-after-my-comma

    if (formatNegative && float < 0) {
        return (
            <span className={"text-red-700"}>
                ({moneyString.replace(formatNegative ? "-" : "", "")})
            </span>
        );
    }

    return (
        <span className={float < 0 ? "text-red-700" : ""}>{moneyString}</span>
    );
}
