import useAuth from "hooks/useAuth";
import useHasAnyPermissions from "hooks/useHasAnyPermissions";
import React from "react";
import { Link } from "react-router-dom";

const inactiveClass =
    "group flex items-center px-3 py-2 text-sm leading-5 font-medium text-gray-600 rounded-md hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:bg-gray-100 transition ease-in-out duration-150";
const activeClass =
    "group flex items-center px-3 py-2 text-sm leading-5 font-medium text-gray-900 rounded-md bg-gray-200 hover:text-gray-900 hover:bg-gray-200 focus:outline-none focus:bg-gray-200 transition ease-in-out duration-150";

export default function ClientNav({ client, active }) {
    const { user } = useAuth();

    const isMember = user.type == "member" || user.type == "advisor";

    const editUrl = "/clients/" + client.id + "/edit/profile";
    const passwordUrl = "/clients/" + client.id + "/edit/password";
    const notesUrl = "/clients/" + client.id + "/edit/notes";
    const permissionsUrl = "/clients/" + client.id + "/edit/permissions";
    const relatedFiles = "/clients/" + client.id + "/edit/related-files";
    const relatedPhotos = "/clients/" + client.id + "/edit/related-photos";

    const canEditPermissions = useHasAnyPermissions([
        "firm_client_related_accounts_administration",
        "client_related_accounts_administration",
    ]);

    return (
        <nav className="w-56">
            <Link
                to={editUrl}
                className={active === editUrl ? activeClass : inactiveClass}
            >
                <span className="truncate">Edit Profile</span>
            </Link>

            {isMember && (
                <Link
                    to={passwordUrl}
                    className={
                        active === passwordUrl ? activeClass : inactiveClass
                    }
                >
                    <span className="truncate">Change Password</span>
                </Link>
            )}

            {canEditPermissions && (
                <Link
                    to={permissionsUrl}
                    className={
                        active === permissionsUrl ? activeClass : inactiveClass
                    }
                >
                    <span className="truncate">Permissions</span>
                </Link>
            )}

            {isMember && (
                <Link
                    to={notesUrl}
                    className={
                        active === notesUrl ? activeClass : inactiveClass
                    }
                >
                    <span className="truncate">Client Notes</span>
                </Link>
            )}
            <Link
                to={relatedFiles}
                className={
                    active === relatedFiles ? activeClass : inactiveClass
                }
            >
                <span className="truncate">Related Files</span>
            </Link>
            <Link
                to={relatedPhotos}
                className={
                    active === relatedPhotos ? activeClass : inactiveClass
                }
            >
                <span className="truncate">Related Photos</span>
            </Link>
        </nav>
    );
}
