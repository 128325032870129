import useHasAnyPermissions from "hooks/useHasAnyPermissions";
import React from "react";
import VerticalTabs from "../../../components/VerticalTabs";

export default function RelationshipTabs({
    basePath,
    isAuthorizedParty,
    ...props
}) {
    const canManageAuthorizedParties = useHasAnyPermissions([
        "client_related_accounts_administration",
        "firm_client_related_accounts_administration",
        "authorized_party_account_creation",
    ]);

    return (
        <VerticalTabs
            {...props}
            items={[
                {
                    label: "Profile",
                    to: basePath + "",
                },
                {
                    label: "Notes",
                    to: basePath + "/notes",
                },
                {
                    label: "Related Files",
                    to: basePath + "/related-files",
                },
                {
                    label: "Related Photos",
                    to: basePath + "/related-photos",
                },
                {
                    label: "Authorized Party Access",
                    to: basePath + "/access",
                    hidden: !canManageAuthorizedParties,
                },
                {
                    label: "Permissions",
                    to: basePath + "/permissions",
                    hidden:
                        (isAuthorizedParty ? false : true) ||
                        !canManageAuthorizedParties,
                },
            ]}
        />
    );
}
