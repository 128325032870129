import React, { useState, useEffect } from "react";
import TransferPermissions from "./_partials/TransferPermissions";
import MemberNav from "./_partials/MemberNav";
import SelectInput from "../../components/SelectInput";
import { MemberHeader } from "../../components/PageHeader";
import PageContainer from "../../components/PageContainer";
import Button from "../../components/Button";
import { Redirect } from "react-router-dom";
import useHasPermission from "../../hooks/usePermissions";
import { formatDate } from "../../util/TextUtil";
import ModalResetMemberPermissions from "./ModalResetMemberPermissions";
import Spinner from "../../components/Spinner";
import SectionHeader from "../../components/SectionHeader";
import PermissionList from "components/Permissions/PermissionList";
import useMemberPermissions from "hooks/useMemberPermissions";
import useAuth from "hooks/useAuth";
import Forbidden from "components/Forbidden";
import useUpdateMemberPermission from "hooks/useUpdateMemberPermission";
import useUpdateMemberRole from "hooks/useUpdateMemberRole";
import useResetMemberPermission from "../../hooks/useResetMemberPermission";

export default function MemberPermissions({
    member,
    reloadMember,
    auth,
    ...props
}) {
    document.title = "Member Permissions";
    const [readOnly, setReadOnly] = useState(true);
    const [showReset, setShowReset] = useState(false);
    const { user } = useAuth();

    let roleOptions;
    switch (user.role) {
        case "member_owner":
            roleOptions = [
                { value: "member_owner", label: "Owner" },
                { value: "member_partner", label: "Associate" },
                { value: "member_associate", label: "Staff" },
            ];
            break;
        case "member_partner":
            roleOptions = [
                { value: "member_partner", label: "Associate" },
                { value: "member_associate", label: "Staff" },
            ];
            break;
        case "member_associate":
            roleOptions = [{ value: "member_associate", label: "Staff" }];
            break;
        default:
            roleOptions = [];
            break;
    }

    const { data, isLoading, refetch } = useMemberPermissions({
        memberId: member.id,
    });

    const { onSubmit, isSuccess } = useUpdateMemberPermission(member.id);
    const { onSubmit: onReset, isSuccess: isReset } = useResetMemberPermission(
        member.id
    );
    const { onSubmit: onRoleChange, isSuccess: hasRoleChanged } =
        useUpdateMemberRole(member.id);

    const updateMemberPermission = async (event, permission, toggled) => {
        onSubmit(event, {
            key: permission,
            enabled: toggled,
        });
    };

    const updateMemberRole = async (event, role) => onRoleChange(event, role);

    useEffect(() => {
        if (isSuccess) {
            refetch();
        }
        if (hasRoleChanged) {
            reloadMember();
            refetch();
        }
    }, [isSuccess, isReset, hasRoleChanged]);

    const resetMemberPermission = async (event, permission) => {
        onReset(event, permission);
    };

    if (!useHasPermission("firm_member_accounts_administration")) {
        return <Forbidden />;
    }

    if (auth.user.id === member.id) {
        return <Redirect to={"/members/" + member.id + "/edit/profile"} />;
    }

    return (
        <>
            <MemberHeader text={member.full_name}>
                <div className="flex-0">
                    <div className="flex items-end space-x-3">
                        <div className="flex-1">
                            <Button
                                text="Reset All Permissions"
                                onClick={() => setShowReset(true)}
                            />
                        </div>
                        <div className="flex-1">
                            <Button
                                text={readOnly ? "Unlock" : "Lock"}
                                onClick={() =>
                                    setReadOnly((readOnly) => !readOnly)
                                }
                            />
                        </div>
                    </div>
                </div>
            </MemberHeader>
            <PageContainer>
                {member.deleted_at && (
                    <div className="bg-red-500 rounded-sm text-red-100 p-6 py-3 mb-8">
                        This member was disabled on{" "}
                        {formatDate(member.deleted_at)}
                    </div>
                )}

                <div className="flex">
                    <div className="mr-10 flex-initial">
                        <MemberNav
                            active={props.history.location.pathname}
                            member={member}
                        />
                    </div>
                    <div className="flex-1">
                        <SectionHeader>Permissions</SectionHeader>
                        {isLoading && <Spinner />}
                        {data && data.length && (
                            <form>
                                <div className="flex justify-between items-end pb-6 pt-0 border-b ">
                                    <label>Role</label>
                                    <div className="w-64 max-w-full">
                                        <SelectInput
                                            options={roleOptions}
                                            value={member.role}
                                            name="role"
                                            readOnly={readOnly}
                                            onChange={async (e) => {
                                                updateMemberRole(
                                                    e,
                                                    e.target.value
                                                );
                                            }}
                                        ></SelectInput>
                                    </div>
                                </div>

                                <PermissionList
                                    permissions={data}
                                    readOnly={readOnly}
                                    resetPermission={resetMemberPermission}
                                    updatePermission={updateMemberPermission}
                                />

                                <input type="submit" hidden />
                            </form>
                        )}
                        {member.id && (
                            <TransferPermissions fromMemberId={member.id} />
                        )}
                    </div>
                </div>

                <ModalResetMemberPermissions
                    member={member}
                    show={showReset}
                    setShow={setShowReset}
                    onResult={() => {
                        reloadMember();
                        refetch();
                    }}
                />
            </PageContainer>
        </>
    );
}
