import React, { useContext } from "react";
import { parseMoney, formatMoney } from "../../../components/Money";
import TextInput from "../../../components/TextInput";
import { BudgetContext } from "../BudgetContext";

export default function InlineSectionTotal({
    sectionName,
    sectionLabel,
    budget,
    hasJoint,
}) {
    const {
        hasSpouse,
        getValue,
        options: frequency,
    } = useContext(BudgetContext);

    const frequencyMultipliers = {
        Annual: 1,
        "Semi Annual": 2,
        Quarterly: 4,
        Monthly: 12,
        Weekly: 52,
        Biweekly: 104,
        "One Time": 1,
        Daily: 365,
    };

    let spouseTotal = 0;
    let clientTotal = 0;
    let jointTotal = 0;

    budget.budget_category_items.forEach((item) => {
        if (!frequency.length) {
            return;
        }
        const itemName = item.id;
        const freqId = getValue(itemName, "frequency_id") || 1;
        const freqs = frequency.filter((f) => f.value == freqId);
        if (!freqs.length) return console.warn("Invalid Frequency");

        const freq = freqs[0];

        const frequencyMultiplier = frequencyMultipliers[freq.label];
        const c = parseMoney(getValue(itemName, "client_value"));

        clientTotal += c * frequencyMultiplier;

        if (hasSpouse) {
            const s = parseMoney(getValue(itemName, "spouse_value"));
            const j = parseMoney(getValue(itemName, "joint_value"));
            spouseTotal += s * frequencyMultiplier;
            jointTotal += j * frequencyMultiplier;
        }
    });

    const grandTotal =
        clientTotal +
        (hasSpouse ? spouseTotal : 0) +
        (hasSpouse ? jointTotal : 0);

    return (
        <div className="flex space-x-3 items-center border-t pt-2">
            <div className="flex items-center w-2/5">
                <div className="flex-1 px-2 font-bold">
                    {sectionLabel} Total (Annual)
                </div>
                <div className="flex-1 text-right font-bold">Grand Total</div>
            </div>
            <div className="w-1/5 flex items-center">
                <TextInput
                    name={`${sectionName}_grand_total`}
                    value={formatMoney(grandTotal)}
                    disabled={true}
                />
                <div className="ml-4">{"="}</div>
            </div>
            <div className="w-1/5">
                <TextInput
                    name={`${sectionName}_client_total`}
                    value={formatMoney(clientTotal)}
                    disabled={true}
                />
            </div>
            {hasSpouse && (
                <>
                    <div className="w-1/5 flex items-center">
                        <div className="mr-4">{"+"}</div>
                        <TextInput
                            name={`${sectionName}_spouse_total`}
                            value={formatMoney(spouseTotal)}
                            disabled={true}
                        />
                    </div>
                    <div className="w-1/5 flex items-center">
                        {hasJoint !== 0 && (
                            <>
                                <div className="mr-4">{"+"}</div>
                                <TextInput
                                    name={`${sectionName}_joint_total`}
                                    value={formatMoney(jointTotal)}
                                    disabled={true}
                                />
                            </>
                        )}
                    </div>
                </>
            )}
        </div>
    );
}
