import React, { useState } from "react";
import { Route, Switch } from "react-router-dom";
import PageContainer from "../../components/PageContainer";
import ClientPageHeader, { Heading } from "../../components/ClientPageHeader";
import ClientQuestionnaireTable from "./_partials/ClientQuestionnaireTable";
import Button from "components/Button";

export default function ClientQuestionnairesIndex({
    client,
    user,
    clientPath,
    ...props
}) {
    const [showModal, setShowModal] = useState(false);
    return (
        <>
            <ClientPageHeader client={client}>
                <div className="flex-1">
                    <Heading>Questionnaires</Heading>
                </div>
                <div className="flex-0">
                    <Button
                        type="button"
                        onClick={() => setShowModal(true)}
                        text="Add Questionnaire"
                    />
                </div>
            </ClientPageHeader>
            <PageContainer>
                <div className="flex space-x-8">
                    <div className="flex-1">
                        <Switch>
                            <Route
                                path={clientPath + "/questionnaires"}
                                render={(newProps) => (
                                    <ClientQuestionnaireTable
                                        client={client}
                                        user={user}
                                        clientPath={clientPath}
                                        showModal={showModal}
                                        setShowModal={setShowModal}
                                        {...props}
                                        {...newProps}
                                    />
                                )}
                            />
                        </Switch>
                    </div>
                </div>
            </PageContainer>
        </>
    );
}
