import { RelationshipContext } from "contexts/RelationshipContext";
import React from "react";
export default function useRelationshipContext() {
    const context = React.useContext(RelationshipContext);
    if (context === undefined) {
        throw new Error(
            "useRelationshipContext must be used within a RelationshipContextProvider"
        );
    }
    return { ...context };
}
