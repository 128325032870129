export const getRootVaultPath = (client, user) => {
    if (window.location.href.indexOf("/ice") > -1) {
        return user.type === "client"
            ? "/client/ice/vault"
            : `/clients/${client.id}/ice/vault`;
    }
    return user.type === "client"
        ? "/client/vault"
        : `/clients/${client.id}/vault`;
};

export const isFileImage = ({ extension, type }) => {
    return (
        ["png", "jpeg", "gif", "jpg", "bmp"].indexOf(extension) > -1 &&
        (typeof type !== "string" || type.startsWith("image"))
    );
};
