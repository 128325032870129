import usePost from "./usePost";
import { resetAllClientPermissions } from "../services/PermissionService";

export default function useResetAllClientPermissions(clientId, params) {
    const { mutation, validationErrors } = usePost(
        () => resetAllClientPermissions(clientId),
        {
            ...params,
            showToastOnError: true,
            showToastOnSuccess: true,
        }
    );
    const onSubmit = (event) => {
        event.preventDefault();
        return mutation.mutate();
    };

    return { onSubmit, ...mutation, validationErrors };
}
