import usePost from "./usePost";
import { verifyTransfer } from "services/TransferService";
import { useQueryClient } from "react-query";

export default function useVerifyTransfer(clientId, id) {
    const queryClient = useQueryClient();
    const { mutation, validationErrors } = usePost(
        (formData) => verifyTransfer({ clientId, id }, formData),
        {
            showToastOnError: true,
            showToastOnSuccess: true,
            onSuccess: () => {
                queryClient.refetchQueries({ active: true });
            },
        }
    );
    const onSubmit = (event, formData) => {
        event.preventDefault();
        return mutation.mutate(formData);
    };

    return { onSubmit, ...mutation, validationErrors };
}
