import React from "react";
import TextInput from "../../components/TextInput";
import Checkbox from "../../components/Checkbox";

export default function Settlement({
    client,
    onSubmit,
    input,
    errors,
    updateValue,
}) {
    return (
        <div>
            <form onSubmit={onSubmit}>
                <fieldset className="mb-8">
                    <legend className="font-bold txt-md text-gray-500 my-6 pb-1 border-b block w-full">
                        Client: {client.full_name}
                    </legend>
                    <div className="mb-6">
                        <Checkbox
                            name="is_client_deceased"
                            label="Is this client deceased?"
                            id="is_client_deceased"
                            checked={parseInt(input.is_client_deceased) === 1}
                            value={1}
                            error={errors.is_client_deceased}
                            onChange={updateValue}
                        />
                    </div>
                    {parseInt(input.is_client_deceased) === 1 && (
                        <>
                            <div className="mb-6">
                                <TextInput
                                    type="date"
                                    name="client_date_of_death"
                                    label="Date of Death"
                                    id="client_date_of_death"
                                    value={input.client_date_of_death}
                                    error={errors.client_date_of_death}
                                    onChange={updateValue}
                                    disabled={
                                        parseInt(input.is_client_dod_locked) ===
                                        1
                                    }
                                    readOnly={
                                        parseInt(input.is_client_dod_locked) ===
                                        1
                                    }
                                />
                            </div>
                            <div className="mb-6">
                                <Checkbox
                                    name="is_client_dod_locked"
                                    label="Do you want to lock in the DOD value?"
                                    id="is_client_dod_locked"
                                    checked={
                                        parseInt(input.is_client_dod_locked) ===
                                        1
                                    }
                                    value={1}
                                    error={errors.is_client_dod_locked}
                                    onChange={updateValue}
                                />
                            </div>
                        </>
                    )}
                    <div className="mb-6">
                        <Checkbox
                            name="show_client_deceased_on_account"
                            label="Do you still want to see deceased client name on the account and reports?"
                            id="show_client_deceased_on_account"
                            checked={
                                parseInt(
                                    input.show_client_deceased_on_account
                                ) === 1
                            }
                            value={1}
                            error={errors.show_client_deceased_on_account}
                            onChange={updateValue}
                        />
                    </div>
                    {/* <div className="mb-6">
                        <Checkbox
                            name="alternate_client_valuation_date"
                            label="Would you like to add an alternate valuation date?"
                            id="alternate_client_valuation_date"
                            checked={
                                parseInt(
                                    input.alternate_client_valuation_date
                                ) === 1
                            }
                            value={1}
                            error={errors.alternate_client_valuation_date}
                            onChange={updateValue}
                        />
                    </div> */}
                    {parseInt(input.alternate_client_valuation_date) === 1 && (
                        <>
                            <div className="mb-6">
                                <TextInput
                                    type="date"
                                    name="client_valuation_date"
                                    label="Alternate Valuation Date"
                                    id="client_valuation_date"
                                    value={input.client_valuation_date}
                                    error={errors.client_valuation_date}
                                    onChange={updateValue}
                                    disabled={
                                        parseInt(
                                            input.is_client_dod_valuation_locked
                                        ) === 1
                                    }
                                    readOnly={
                                        parseInt(
                                            input.is_client_dod_valuation_locked
                                        ) === 1
                                    }
                                />
                            </div>
                            <div className="mb-6">
                                <Checkbox
                                    name="is_client_dod_valuation_locked"
                                    label="Do you want to lock in the alternate DOD valuation value?"
                                    id="is_client_dod_valuation_locked"
                                    checked={
                                        parseInt(
                                            input.is_client_dod_valuation_locked
                                        ) === 1
                                    }
                                    value={1}
                                    error={
                                        errors.is_client_dod_valuation_locked
                                    }
                                    onChange={updateValue}
                                />
                            </div>
                        </>
                    )}
                </fieldset>
                {client.spouse !== null && (
                    <fieldset>
                        <legend className="font-bold txt-md text-gray-500 my-6 pb-1 border-b block w-full">
                            Spouse: {client.spouse.full_name}
                        </legend>
                        <div className="mb-6">
                            <Checkbox
                                name="is_spouse_deceased"
                                label="Is the spouse deceased?"
                                id="is_spouse_deceased"
                                checked={
                                    parseInt(input.is_spouse_deceased) === 1
                                }
                                value={1}
                                error={errors.is_spouse_deceased}
                                onChange={updateValue}
                            />
                        </div>
                        {parseInt(input.is_spouse_deceased) === 1 && (
                            <>
                                <div className="mb-6">
                                    <TextInput
                                        type="date"
                                        name="spouse_date_of_death"
                                        label="Date of Death"
                                        id="spouse_date_of_death"
                                        value={input.spouse_date_of_death}
                                        error={errors.spouse_date_of_death}
                                        onChange={updateValue}
                                        disabled={
                                            parseInt(
                                                input.is_spouse_dod_locked
                                            ) === 1
                                        }
                                        readOnly={
                                            parseInt(
                                                input.is_spouse_dod_locked
                                            ) === 1
                                        }
                                    />
                                </div>
                                <div className="mb-6">
                                    <Checkbox
                                        name="is_spouse_dod_locked"
                                        label="Do you want to lock in the DOD value?"
                                        id="is_spouse_dod_locked"
                                        checked={
                                            parseInt(
                                                input.is_spouse_dod_locked
                                            ) === 1
                                        }
                                        value={1}
                                        error={errors.is_spouse_dod_locked}
                                        onChange={updateValue}
                                    />
                                </div>
                            </>
                        )}
                        <div className="mb-6">
                            <Checkbox
                                name="show_spouse_deceased_on_account"
                                label="Do you still want to see deceased spouse name on the account and reports?"
                                id="show_spouse_deceased_on_account"
                                checked={
                                    parseInt(
                                        input.show_spouse_deceased_on_account
                                    ) === 1
                                }
                                value={1}
                                error={errors.show_spouse_deceased_on_account}
                                onChange={updateValue}
                            />
                        </div>
                        {/* <div className="mb-6">
                            <Checkbox
                                name="alternate_spouse_valuation_date"
                                label="Would you like to add an alternate valuation date?"
                                id="alternate_spouse_valuation_date"
                                checked={
                                    parseInt(
                                        input.alternate_spouse_valuation_date
                                    ) === 1
                                }
                                value={1}
                                error={errors.alternate_spouse_valuation_date}
                                onChange={updateValue}
                            />
                        </div> */}
                        {parseInt(input.alternate_spouse_valuation_date) ===
                            1 && (
                            <>
                                <div className="mb-6">
                                    <TextInput
                                        type="date"
                                        name="spouse_valuation_date"
                                        label="Alternate Valuation Date"
                                        id="spouse_valuation_date"
                                        value={input.spouse_valuation_date}
                                        error={errors.spouse_valuation_date}
                                        onChange={updateValue}
                                        disabled={
                                            parseInt(
                                                input.is_spouse_dod_valuation_locked
                                            ) === 1
                                        }
                                        readOnly={
                                            parseInt(
                                                input.is_spouse_dod_valuation_locked
                                            ) === 1
                                        }
                                    />
                                </div>
                                <div className="mb-6">
                                    <Checkbox
                                        name="is_spouse_dod_valuation_locked"
                                        label="Do you want to lock in the alternate DOD valuation value?"
                                        id="is_spouse_dod_valuation_locked"
                                        checked={
                                            parseInt(
                                                input.is_spouse_dod_valuation_locked
                                            ) === 1
                                        }
                                        value={1}
                                        error={
                                            errors.is_spouse_dod_valuation_locked
                                        }
                                        onChange={updateValue}
                                    />
                                </div>
                            </>
                        )}
                    </fieldset>
                )}
                <input type="submit" hidden />
            </form>
        </div>
    );
}
