import React, { useState } from "react";
import Button from "../../components/Button";
import Badge from "../../components/Badge";
import TextInput from "../../components/TextInput";
import { TableCell, TableRow } from "../../components/Table";
import { Link } from "react-router-dom";
import useFormatCurrency from "../../hooks/useFormatCurrency";

export default function UpdateAssetValue({ name, asset, onSave, errors }) {
    const [value, setValue] = useState(asset.gross_value);
    const [asOfDate, setAsOfDate] = useState(asset.as_of_date);
    const formatCurrency = useFormatCurrency();

    function save(e) {
        onSave(e, {
            value,
            as_of_date: asOfDate,
            asset_type: asset.type,
            asset_id: asset.id,
        });
    }

    return (
        <TableRow>
            <TableCell className="align-middle">
                <div>
                    <h2 className="text-base font-bold text-gray-800">
                        <Link to={`${asset.resource_path}`}>{name}</Link>
                    </h2>
                    <div className="space-y-1">
                        {asset.account_number && (
                            <span className="text-xs text-gray-500 block mt-1">
                                {asset.account_number}
                            </span>
                        )}
                        <span className="text-sm text-gray-500">
                            {asset.type_formatted}
                        </span>
                    </div>
                </div>
                {asset.funding_entity && (
                    <div className="my-1 -ml-1">
                        <Badge color="gray">Funding Entity</Badge>
                    </div>
                )}
            </TableCell>
            <TableCell className="align-middle">
                <TextInput
                    id={`value_${asset.id}`}
                    type="text"
                    name="value"
                    value={value}
                    onChange={(e) => setValue(e.target.value)}
                    onBlur={(e) => {
                        e.target.value = formatCurrency(e.target.value);
                        setValue(e.target.value);
                    }}
                    placeholder="0.00"
                    error={errors.value}
                    disabled={asset.funding_entity}
                    tabIndex={1}
                />
            </TableCell>
            <TableCell className="align-middle">
                <TextInput
                    id={`as_of_date_${asset.id}`}
                    type="date"
                    name="as_of_date"
                    value={asOfDate}
                    onChange={(e) => setAsOfDate(e.target.value)}
                    onBlur={save}
                    error={errors.as_of_date}
                    disabled={asset.funding_entity}
                    tabIndex={1}
                />
            </TableCell>
            <TableCell className="align-middle">
                <Button
                    type="button"
                    text="Save"
                    tabIndex={1}
                    onClick={save}
                    appearance={"outline"}
                />
            </TableCell>
        </TableRow>
    );
}
