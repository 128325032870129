import React, { useMemo } from "react";
import TextInput from "../../../components/TextInput";
import CountrySelector from "../../../components/CountrySelector";
import AdvisorTypeSelect from "./AdvisorTypeSelect";
import useAuth from "hooks/useAuth";
import Checkbox from "components/Checkbox";
import useHasAnyPermissions from "hooks/useHasAnyPermissions";

export default function AdvisorProfileForm({
    advisor,
    errors,
    updateValue,
    readonly = false,
    view = "show",
}) {
    const { planLimits, user } = useAuth();

    const canAddCollaboratingAdvisor =
        planLimits.can_add_collaborating_advisors &&
        useHasAnyPermissions([
            "client_related_accounts_administration",
            "firm_client_related_accounts_administration",
        ]);

    const disableForCollaborators =
        view === "update" && parseInt(advisor.can_collaborate);

    const showAddCollaboratingAdvisorCheckbox = useMemo(
        function () {
            return (
                canAddCollaboratingAdvisor &&
                user.type === "client" &&
                view === "create"
            );
        },
        [canAddCollaboratingAdvisor, user.type, view]
    );

    return (
        <div className="max-w-3xl mx-auto space-y-6">
            <div className="flex mb-6">
                <div className="w-1/3 mr-4">
                    <TextInput
                        name="first_name"
                        label="First Name"
                        id="first_name"
                        required={true}
                        value={advisor.first_name}
                        error={errors.first_name}
                        onChange={updateValue}
                        disabled={readonly || disableForCollaborators}
                    />
                </div>
                <div className="w-1/3 mr-4">
                    <TextInput
                        name="middle_name"
                        label="Middle Name"
                        id="middle_name"
                        value={advisor.middle_name}
                        error={errors.middle_name}
                        onChange={updateValue}
                        disabled={readonly || disableForCollaborators}
                    />
                </div>
                <div className="w-1/3">
                    <TextInput
                        name="last_name"
                        label="Last Name"
                        id="last_name"
                        required={true}
                        value={advisor.last_name}
                        error={errors.last_name}
                        onChange={updateValue}
                        disabled={readonly || disableForCollaborators}
                    />
                </div>
            </div>
            <div className="mb-6">
                <TextInput
                    type="email"
                    name="email"
                    label="Email"
                    id="email"
                    required={true}
                    value={advisor.email}
                    error={errors.email}
                    onChange={updateValue}
                    disabled={readonly || disableForCollaborators}
                />
            </div>
            {showAddCollaboratingAdvisorCheckbox && (
                <div className="mb-6">
                    <Checkbox
                        id="can_collaborate"
                        name="can_collaborate"
                        defaultChecked={advisor.can_collaborate}
                        value={advisor.can_collaborate}
                        onChange={updateValue}
                        label="Give this advisor access to collaborate on my account."
                    />
                </div>
            )}
            <div className="mb-6">
                <AdvisorTypeSelect
                    value={advisor.type}
                    name="type"
                    onChange={updateValue}
                    disabled={readonly}
                />
            </div>
            <div className="flex items-end space-x-4 mb-6">
                <div className="w-1/2">
                    <TextInput
                        name="organization_name"
                        label="Organization Name"
                        id="organization_name"
                        value={advisor.organization_name}
                        error={errors.organization_name}
                        onChange={updateValue}
                        disabled={readonly || disableForCollaborators}
                    />
                </div>
                <div className="w-1/2">
                    <TextInput
                        name="organization_website"
                        label="Organization Website"
                        id="organization_website"
                        value={advisor.organization_website}
                        error={errors.organization_website}
                        onChange={updateValue}
                        disabled={readonly || disableForCollaborators}
                    />
                </div>
            </div>

            <div className="mb-6">
                <TextInput
                    name="nick_name"
                    label="Nickname"
                    id="nick_name"
                    value={advisor.nick_name}
                    error={errors.nick_name}
                    onChange={updateValue}
                    disabled={readonly}
                />
            </div>

            <div className="mb-6">
                <TextInput
                    type="tel"
                    name="phone"
                    label="Phone"
                    id="phone"
                    value={advisor.phone}
                    error={errors.phone}
                    onChange={updateValue}
                    disabled={readonly || disableForCollaborators}
                />
            </div>

            <div className="mb-6">
                <TextInput
                    type="tel"
                    name="mobile_phone"
                    label="Mobile Phone"
                    id="mobile_phone"
                    value={advisor.mobile_phone}
                    error={errors.mobile_phone}
                    onChange={updateValue}
                    disabled={readonly || disableForCollaborators}
                />
            </div>

            <div className="mb-6">
                <TextInput
                    name="address"
                    label="Address"
                    id="address"
                    value={advisor.address}
                    error={errors.address}
                    onChange={updateValue}
                    disabled={readonly}
                />
            </div>

            <div className="flex mb-6">
                <div className="w-1/3 mr-4">
                    <TextInput
                        name="city"
                        label="City"
                        id="city"
                        value={advisor.city}
                        error={errors.city}
                        onChange={updateValue}
                        disabled={readonly}
                    />
                </div>
                <div className="w-1/3 mr-4">
                    <TextInput
                        name="state"
                        label="State"
                        id="state"
                        value={advisor.state}
                        error={errors.state}
                        onChange={updateValue}
                        disabled={readonly}
                    />
                </div>
                <div className="w-1/3">
                    <TextInput
                        name="postal_code"
                        label="Postal Code"
                        id="postal_code"
                        value={advisor.postal_code}
                        error={errors.postal_code}
                        onChange={updateValue}
                        disabled={readonly}
                    />
                </div>
            </div>

            <div className="mb-6">
                <CountrySelector
                    label="Country"
                    value={advisor.country}
                    name="country"
                    onChange={updateValue}
                    disabled={readonly}
                />
            </div>

            <input type="submit" hidden />
        </div>
    );
}
