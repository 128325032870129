import Api from "../../services/Api";

export function ownershipTypes(assetType) {
    if (assetType === "RetirementAccount") {
        return [
            { value: "", label: "Choose an option...", disabled: true },
            { value: "OWNER", label: "Owner" },
        ];
    }

    return [
        { value: "", label: "Choose an option...", disabled: true },
        { value: "JT", label: "Joint with Spouse" },
        { value: "CJTO", label: "Client Joint w/Other" },
        { value: "SJTO", label: "Spouse Joint w/Other" },
        { value: "OWNER", label: "Owner" },
        { value: "CP", label: "Community Property" },
        { value: "TBE", label: "Tenants-by-Entireties" },
        { value: "TCO", label: "Tenants in Common" },
        { value: "OTH", label: "Other" },
    ];
}

export async function fetchRelationships({ client }) {
    let response = await Api.get("clients/" + client.id + "/relationships", {
        "filter[excludeSpouse]": true,
    });
    let options = response.data.data.map((resource) => {
        return {
            value: resource.uuid,
            label:
                resource.type === "organization"
                    ? resource.organization_name
                    : resource.full_name_with_relationship,
        };
    });

    if (options.length === 0) {
        return [
            {
                value: " ",
                label: "No options available...",
                disabled: true,
            },
        ];
    }

    return options;
}

export async function fetchTrusts({ client }) {
    let response = await Api.get("clients/" + client.id + "/trusts");
    let options = response.data.data.map((resource) => {
        return {
            value: resource.uuid,
            label: resource.display_name || resource.name,
        };
    });

    if (options.length === 0) {
        return [
            {
                value: " ",
                label: "No options available...",
                disabled: true,
            },
        ];
    }

    return options;
}

export async function fetchFundingEntities({ client }) {
    let url = "clients/" + client.id + "/business-interests";
    url += "?filter[funding_entity]=1";
    let response = await Api.get(url);
    let options = response.data.data.map((resource) => {
        return {
            value: resource.uuid,
            label: resource.display_name || resource.name,
        };
    });

    if (options.length === 0) {
        return [
            {
                value: " ",
                label: "No options available...",
                disabled: true,
            },
        ];
    }

    return options;
}

export async function fetchOwnerships({
    client,
    resourceType,
    resourceId,
    showProposed,
}) {
    let response = await Api.get("clients/" + client.id + "/ownerships", {
        show_proposed: showProposed,
        resourceType,
        resourceId,
    });
    return response.data.data;
}
