import React from "react";

import useIceGuest from "./useIceGuest";
import Button from "../../components/Button";

export default function ICEFiles() {
    const { info, download, isDownloadingIds } = useIceGuest();

    const files = info.files;

    return (
        <div className="bg-white overflow-hidden shadow rounded-lg divide-y divide-gray-200">
            <div className="px-4 py-5 sm:px-6">
                <div>ICE Files</div>
            </div>
            <div className="px-4 py-5 sm:p-6">
                {files.length === 0 && (
                    <div className="p-2 flex items-center justify-center">
                        No ICE Files Available
                    </div>
                )}
                <ul className="divide-y divide-gray-300">
                    {files.map((file) => (
                        <li key={file.id} className="px-6 py-4">
                            <div className="flex justify-between">
                                <div>
                                    {file.name}.{file.extension}
                                </div>

                                <div className="flex-none">
                                    <Button
                                        text="Download"
                                        isLoading={
                                            isDownloadingIds.filter(
                                                (i) => file.id === i
                                            ).length > 0
                                        }
                                        appearance="link"
                                        onClick={(e) => download(file, e)}
                                    />
                                </div>
                            </div>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
}
