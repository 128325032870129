import React, { useState, useEffect, useReducer, useCallback } from "react";
import Api from "../../services/Api";
import { Redirect } from "react-router-dom";
import SaveButton from "../../components/SaveButton";
import { useToasts } from "react-toast-notifications";
import PageContainer from "../../components/PageContainer";
import InsurancePoliciesForm from "./_partials/InsurancePoliciesForm";
import ClientPageHeader, { Heading } from "../../components/ClientPageHeader";
import { formatDateForInput } from "../../util/TextUtil";

export default function AddInsurancePolicy({ client, clientPath }) {
    const { addToast } = useToasts();
    const [errors, setErrors] = useState([]);
    const [redirectToId, setRedirectToId] = useState(0);
    const [input, setInput] = useReducer(
        (state, newState) => ({ ...state, ...newState }),
        {
            name: "",
            number: "",
            type: "",
            type_select: "",
            description: "",
            owner: "client:" + client.id,
            percentage_owned: "",
            other_owner: "",
            other_percentage_owned: "",
            other_owners: "",
            insured_id: "",
            other_insured: "",
            value: "",
            as_of_date: formatDateForInput(new Date()),
            death_benefit: "",
            loan_amount: "",
            premium_amount: "",
            frequency_id: 0,
            provider_name: "",
            long_term_care_rider: false,
            long_term_care_amount: "",
            long_term_care_term: "",
            managed_by_id: "",
            managed_by_address: "",
            managed_by_address_2: "",
            managed_by_city: "",
            managed_by_state: "",
            managed_by_postal_code: "",
            managed_by_country: "",
        }
    );

    useEffect(() => {
        document.title = "Add Insurance Policy";
    }, []);

    const updateValue = useCallback((event) => {
        setInput({ [event.target.name]: event.target.value });
    }, []);

    async function store(e) {
        e.preventDefault();
        try {
            const body = { ...input };
            let response = await Api.post(
                "clients/" + client.id + "/insurance-policies",
                body
            );
            addToast(response.data.message);
            setErrors([]);
            setRedirectToId(response.data.data.id);
        } catch (e) {
            setErrors([]);
            if (e.response.data.errors) {
                setErrors(e.response.data.errors);
            } else {
                addToast(e.response.data.message, { type: "error" });
            }
        }
    }

    if (redirectToId) {
        return (
            <Redirect
                to={`${clientPath}/insurance-policies/${redirectToId}/edit/owners`}
            />
        );
    }

    return (
        <>
            <ClientPageHeader client={client}>
                <Heading
                    backTo={`${clientPath}/insurance-policies`}
                    backText="All Policies"
                >
                    Add Insurance Policy
                </Heading>
                <div className="flex-0">
                    <SaveButton onClick={store} />
                </div>
            </ClientPageHeader>
            <PageContainer>
                <div className="max-w-3xl mx-auto">
                    <InsurancePoliciesForm
                        client={client}
                        input={input}
                        errors={errors}
                        updateValue={updateValue}
                        onSubmit={store}
                    />
                </div>
            </PageContainer>
        </>
    );
}
