import React, { useState, useReducer, useEffect } from "react";
import TextInput from "../../components/TextInput";
import Button from "../../components/Button";
import SaveButton from "../../components/SaveButton";
import InputErrors from "../../components/InputErrors";
import CountrySelector from "../../components/CountrySelector";
import { input } from "../../Utility";
import Api from "../../services/Api";
import { Redirect } from "react-router-dom";
import Panel, { PanelHeader } from "../../components/Panel";
import Spinner from "../../components/Spinner";

const AcceptAdvisorInvitation = () => {
    const [errors, setErrors] = useState([]);
    const [code, setCode] = useState(false);
    const [codeHasExpired, setCodeHasExpired] = useState(false);
    const [invitation, setInvitation] = useState();
    const [loading, setLoading] = useState(true);
    const [redirectToDashboard, setRedirectToDashboard] = useState(false);

    const [clientInput, setClientInput] = useReducer(
        (state, newState) => ({ ...state, ...newState }),
        {
            first_name: "",
            last_name: "",
            middle_name: "",
            email: "",
            phone: "",
            address: "",
            city: "",
            state: "",
            postal_code: "",
            country: "US",
            role: "client_owner",
        }
    );

    useEffect(() => {
        let code = input("code");
        setCode(code);

        let email = input("email");
        setClientInput({ email: email });

        fetchInvitation(code);
    }, []);

    function updateValue(event) {
        setClientInput({ [event.target.name]: event.target.value });
    }

    async function fetchInvitation(code) {
        try {
            setLoading(true);
            let invitation = await Api.get("invitations/" + code);
            setInvitation(invitation.data);
            if (invitation.data.advisor) {
                setClientInput(invitation.data.advisor);
            }
            setLoading(false);
        } catch (e) {
            setLoading(false);
            if (e.response.status === 410) {
                setCodeHasExpired(true);
            }
        }
    }

    async function acceptInvitation(e) {
        e.preventDefault();
        try {
            setLoading(true);
            await Api.post("invitations/" + invitation.id + "/accept", {
                code: code,
                email: clientInput.email,
            });
            setLoading(false);
            setRedirectToDashboard(true);
        } catch (e) {
            setLoading(false);
            if (e.response.data && e.response.data.errors) {
                setErrors(e.response.data.errors);
            }
            if (e.response.data.errors) {
                setErrors(e.response.data.errors);
            }
        }
    }

    async function acceptAndRegister(e) {
        e.preventDefault();
        try {
            setLoading(true);
            await Api.post("invitations/" + invitation.id + "/accept", {
                code: code,
                email: clientInput.email,
                password: clientInput.password,
                password_confirmation: clientInput.password_confirmation,
                first_name: clientInput.first_name,
                last_name: clientInput.last_name,
                phone: clientInput.phone,
            });
            setLoading(false);
            window.location = "/";
        } catch (e) {
            setLoading(false);
            if (e.response.data && e.response.data.errors) {
                setErrors(e.response.data.errors);
            }
            if (e.response.data.errors) {
                setErrors(e.response.data.errors);
            }
        }
    }

    if (loading) {
        return <Spinner />;
    }

    if (redirectToDashboard) {
        if (invitation.client_id) {
            return (
                <Redirect
                    to={{
                        pathname: "/clients/" + invitation.client_id,
                    }}
                />
            );
        }

        return (
            <Redirect
                to={{
                    pathname: "/clients",
                }}
            />
        );
    }

    if (codeHasExpired || !code) {
        return (
            <div className="h-screen flex items-center justify-center text-center">
                <div className="mt-8 mx-auto max-w-2xl px-3">
                    <h1 className="text-xl font-bold text-gray-700 mb-1">
                        Your Invitation Has Expired
                    </h1>
                    <div className="text-gray-700 mx-auto px-6 py-4">
                        <p>
                            Please reach out to the person that invited you to
                            ask for a new invitation.
                        </p>
                    </div>
                </div>
            </div>
        );
    }

    if (invitation && invitation.user) {
        return (
            <div className="my-8">
                <div className="mx-auto max-w-2xl px-3">
                    <Panel>
                        <PanelHeader title="Accept Invitation" />
                        <div className="mx-auto max-w-2xl px-3 text-xl">
                            <p>Hello {invitation.user.first_name},</p>
                            <p>
                                You have been invited to collaborate on a mutual
                                client as a Collaborating Advisor in Inheralink.
                                To confirm the invitation or to set up an
                                account with Inheralink, please click on the
                                link below.
                            </p>
                            <div className="mt-8">
                                <Button
                                    onClick={(e) => acceptInvitation(e)}
                                    text="Accept Invitation"
                                />
                            </div>
                        </div>
                    </Panel>
                </div>
            </div>
        );
    }

    return (
        <div className="my-8">
            <div className="mx-auto max-w-2xl px-3">
                <Panel>
                    <PanelHeader title="Complete Your Account" />
                    <InputErrors errors={errors.invitees} />
                    <form onSubmit={acceptAndRegister}>
                        <input type="hidden" name="code" value={code} />
                        <input
                            type="hidden"
                            name="email"
                            value={clientInput.email}
                        />

                        <div className="flex items-end mb-6">
                            <div className="w-1/3 mr-4">
                                <TextInput
                                    name="first_name"
                                    label="First Name"
                                    id="first_name"
                                    required={true}
                                    value={clientInput.first_name}
                                    error={errors.first_name}
                                    autoFocus={true}
                                    onChange={updateValue}
                                />
                            </div>
                            <div className="w-1/3 mr-4">
                                <TextInput
                                    name="middle_name"
                                    label="Middle Name"
                                    id="middle_name"
                                    value={clientInput.middle_name}
                                    error={errors.middle_name}
                                    onChange={updateValue}
                                />
                            </div>
                            <div className="w-1/3">
                                <TextInput
                                    name="last_name"
                                    label="Last Name"
                                    id="last_name"
                                    required={true}
                                    value={clientInput.last_name}
                                    error={errors.last_name}
                                    onChange={updateValue}
                                />
                            </div>
                        </div>

                        <div className="mb-6">
                            <TextInput
                                type="email"
                                name="email"
                                label="Email"
                                id="email"
                                required={true}
                                readOnly={true}
                                value={clientInput.email}
                                error={errors.email}
                                onChange={updateValue}
                            />
                        </div>

                        <div className="mb-6">
                            <TextInput
                                name="password"
                                label="Password"
                                id="password"
                                type="password"
                                autoComplete="new-password"
                                required={true}
                                value={clientInput.password}
                                onChange={updateValue}
                                error={errors.password}
                            />
                        </div>

                        <div className="mb-6">
                            <TextInput
                                name="password_confirmation"
                                label="Confirm Password"
                                id="password_confirmation"
                                type="password"
                                autoComplete="confirm-new-password"
                                required={true}
                                value={clientInput.password_confirmation}
                                onChange={updateValue}
                                error={errors.password_confirmation}
                            />
                        </div>

                        <div className="mb-6">
                            <TextInput
                                type="tel"
                                name="phone"
                                label="Phone"
                                id="phone"
                                required={true}
                                value={clientInput.phone}
                                error={errors.phone}
                                onChange={updateValue}
                            />
                        </div>

                        <div className="mb-6">
                            <TextInput
                                name="address"
                                label="Address"
                                id="address"
                                value={clientInput.address}
                                error={errors.address}
                                onChange={updateValue}
                            />
                        </div>

                        <div className="flex mb-6">
                            <div className="w-1/3 mr-4">
                                <TextInput
                                    name="city"
                                    label="City"
                                    id="city"
                                    value={clientInput.city}
                                    error={errors.city}
                                    onChange={updateValue}
                                />
                            </div>
                            <div className="w-1/3 mr-4">
                                <TextInput
                                    name="state"
                                    label="State"
                                    id="state"
                                    value={clientInput.state}
                                    error={errors.state}
                                    onChange={updateValue}
                                />
                            </div>
                            <div className="w-1/3">
                                <TextInput
                                    name="postal_code"
                                    label="Postal Code"
                                    id="postal_code"
                                    value={clientInput.postal_code}
                                    error={errors.postal_code}
                                    onChange={updateValue}
                                />
                            </div>
                        </div>

                        <div className="mb-6">
                            <CountrySelector
                                label="Country"
                                value={clientInput.country}
                                name="country"
                                error={errors.country}
                                onChange={updateValue}
                            />
                        </div>

                        <SaveButton
                            type="submit"
                            className="w-64 mx-auto block bg-gray-800 hover:bg-gray-700 text-white border border-gray-800"
                        />
                    </form>
                </Panel>
            </div>
        </div>
    );
};

export default AcceptAdvisorInvitation;
