import Api from "./Api";

export const fetchAccounts = async function () {
    return await Api.get("login/switch-accounts").then((res) => res.data);
};

export const updateAccount = async function (formData) {
    return await Api.put("login/switch-accounts", formData).then(
        (res) => res.data
    );
};

export default {
    fetchAccounts,
    updateAccount,
};
