import EmptyState from "components/EmptyState";
import SelectInput from "components/SelectInput";
import React, { useState } from "react";

export default function PermissionList({
    permissions,
    readOnly = false,
    resetPermission,
    updatePermission,
}) {
    const handleOnChange = (event, permission) => {
        if (event.target.value === "") {
            resetPermission(event, permission.key);
        } else {
            updatePermission(event, permission.key, event.target.value === "1");
        }
    };

    const isPermissionEnabled = (key) => {
        const permission = permissions.filter((p) => p.key === key);
        if (permission.length) {
            // Is Default
            if (permission[0].is_default) {
                return permission[0].default_enabled;
            }
            if (permission[0].enabled) {
                return true;
            }
        }
        return false;
    };

    return (
        <div>
            {permissions.map((permission) => (
                <PermissionListItem
                    permission={permission}
                    key={permission.key}
                    readOnly={readOnly}
                    handleOnChange={handleOnChange}
                    isPermissionEnabled={isPermissionEnabled}
                />
            ))}
            {permissions.length === 0 && (
                <div className="mt-6">
                    <EmptyState>No permissions found.</EmptyState>
                </div>
            )}
        </div>
    );
}

export function PermissionListItem({
    permission,
    readOnly,
    handleOnChange,
    isPermissionEnabled,
}) {
    const permissionOptions = (permission) => {
        let allowLabel = permission.allow_label || "Allow";
        let denyLabel = permission.deny_label || "Deny";
        return [
            {
                label: `Default (${
                    permission.default_enabled ? allowLabel : denyLabel
                })`,
                value: "",
            },
            {
                label: allowLabel,
                value: "1",
            },
            {
                label: denyLabel,
                value: "0",
            },
        ];
    };
    const [value, setValue] = useState(
        permission.is_default ? "" : permission.enabled ? "1" : "0"
    );

    React.useEffect(() => {
        const newValue = permission.is_default
            ? ""
            : permission.enabled
            ? "1"
            : "0";

        setValue((value) => (value !== newValue ? newValue : value));
    }, [permission.is_default, permission.enabled]);

    function updateValue(event) {
        event.preventDefault();
        setValue(event.target.value);
        handleOnChange(event, permission);
    }

    let description = permission.description;
    // Handle deny description
    if (permission.deny_description) {
        if (value === "0") {
            description = permission.deny_description;
        }
        // Default permission was chosen
        if (value === "" && permission.default_enabled === false) {
            description = permission.deny_description;
        }
    }

    const parentStyle = "flex justify-between items-center py-6 border-b";
    const childStyles = "flex justify-between items-center py-6 border-b ml-6";

    if (permission.hidden) {
        return <></>;
    }
    if (
        permission.parent_permission_key &&
        isPermissionEnabled(permission.parent_permission_key)
    ) {
        return <div key={permission.key}></div>;
    }

    return (
        <div
            className={
                permission.parent_permission_key ? childStyles : parentStyle
            }
            key={permission.key}
        >
            <div>
                <label>{permission.label}</label>
                {description && (
                    <p className="text-sm text-gray-600 mt-1 mr-8">
                        {description}
                    </p>
                )}
            </div>
            <div className="w-40 flex-shrink-0">
                <SelectInput
                    name="permission"
                    readOnly={readOnly}
                    options={permissionOptions(permission)}
                    value={value}
                    key={permission.key}
                    onChange={(event) => updateValue(event)}
                />
            </div>
        </div>
    );
}
