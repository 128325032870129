import React from "react";
import SelectInput from "../SelectInput";
import InputErrors from "../InputErrors";
import PercentInput from "../PercentInput";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import BeneficiaryResourceSelect from "../BeneficiaryResourceSelect";

export function getCompoundUUID(type, id) {
    if (type && id) {
        return `${type}:${id}`;
    }
    return "";
}

export const beneficiaryLevels = [
    { value: 1, label: "Primary" },
    {
        value: 2,
        label: "Secondary",
    },
    { value: 3, label: "Third" },
    { value: 4, label: "Fourth" },
];

export default function BeneficiaryList({
    proposed,
    client,
    errors,
    beneficiaries,
    setBeneficiaries,
    removeBeneficiary,
    disabled = false,
    required = true,
}) {
    return (
        <>
            {beneficiaries.length === 0 && (
                <p>{`There are no ${
                    proposed ? "proposed" : "current"
                } beneficiaries for this asset.`}</p>
            )}

            <div className="space-y-3">
                {errors["percentage_owned"] && (
                    <div className="text-right">
                        <InputErrors errors={errors["percentage_owned"]} />
                    </div>
                )}
                {beneficiaries.map((beneficiary, idx) => {
                    return (
                        <BeneficiaryLineItem
                            proposed={proposed}
                            client={client}
                            beneficiary={beneficiary}
                            idx={idx}
                            key={idx}
                            showLabels={idx === 0}
                            disabled={disabled}
                            errors={errors}
                            required={required}
                            setBeneficiaries={setBeneficiaries}
                            removeBeneficiary={removeBeneficiary}
                        />
                    );
                })}
            </div>
        </>
    );
}

export function BeneficiaryLineItem({
    proposed,
    beneficiary,
    client,
    showLevel = true,
    showLabels = true,
    disabled = false,
    errors = {},
    required = true,
    setBeneficiaries = () => {},
    removeBeneficiary = () => {},
    idx = 0,
}) {
    function updateBeneficiaryLevel(idx, value) {
        setBeneficiaries((beneficiaries) =>
            beneficiaries.map((beneficiary, idxs) => {
                if (idxs === idx) {
                    beneficiary.level = parseInt(value);
                }
                return beneficiary;
            })
        );
    }

    function updatePercentageOwned(idx, value) {
        setBeneficiaries((beneficiaries) =>
            beneficiaries.map((beneficiary, idxs) => {
                if (idxs === idx) {
                    beneficiary.percentage_owned = value;
                }
                return beneficiary;
            })
        );
    }

    function updateBeneficiaryType(value, idx) {
        let parts = value.split(":");
        let beneficiaryType = parts[0];
        let beneficiaryId = parts[1];
        setBeneficiaries((beneficiaries) =>
            beneficiaries.map((beneficiary, idxs) => {
                if (idxs === idx) {
                    beneficiary.beneficiary_type = beneficiaryType;
                    beneficiary.beneficiary_id = beneficiaryId;
                }
                return beneficiary;
            })
        );
    }

    return (
        <div>
            <InputErrors
                errors={errors["beneficiaries." + idx + ".percentage_owned"]}
            />
            <InputErrors
                errors={errors["beneficiaries." + idx + ".beneficiary_id"]}
            />
            <InputErrors
                errors={errors["beneficiaries." + idx + ".beneficiary_type"]}
            />
            <div
                className={`flex space-x-3 items-stretch ${
                    beneficiary.should_delete ? "hidden" : "block"
                }`}
            >
                <div className="flex-1">
                    <BeneficiaryResourceSelect
                        client={client}
                        label={
                            showLabels
                                ? proposed
                                    ? "Proposed Beneficiary"
                                    : "Current Beneficiary"
                                : ""
                        }
                        name="beneficiary"
                        disabled={disabled}
                        autoFocus={false}
                        value={getCompoundUUID(
                            beneficiary.beneficiary_type,
                            beneficiary.beneficiary_id
                        )}
                        onChange={(e) => {
                            updateBeneficiaryType(e.target.value, idx);
                        }}
                    />
                </div>
                {showLevel && (
                    <div className="flex space-x-2 flex-0 w-65 mx-2">
                        <div className="flex-1">
                            <SelectInput
                                label={showLabels ? "Beneficiary Level" : ""}
                                type="level"
                                disabled={disabled}
                                required={required}
                                value={beneficiary.level}
                                name="level"
                                options={beneficiaryLevels}
                                onChange={(e) =>
                                    updateBeneficiaryLevel(idx, e.target.value)
                                }
                            />
                        </div>
                    </div>
                )}
                <div className="flex space-x-2 flex-0 w-48 mx-2">
                    <div className="flex-1">
                        <PercentInput
                            id={`percentage_owned_${idx}`}
                            label={showLabels ? "Percentage Owned" : ""}
                            name="percentage_owned"
                            value={beneficiary.percentage_owned}
                            disabled={disabled}
                            required={required}
                            placeholder="0 - 100"
                            onChange={(e) =>
                                updatePercentageOwned(idx, e.target.value)
                            }
                        />
                    </div>
                    {!disabled && (
                        <div className="flex-0 flex items-end justify-center pb-4">
                            <button
                                type="button"
                                className="text-gray-500 text-sm hover:text-red-500"
                                onClick={() => removeBeneficiary(idx)}
                            >
                                <FontAwesomeIcon icon={faTimesCircle} />
                            </button>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}
