import usePost from "./usePost";
import { updateProposedTransfer } from "services/TransferService";

export default function useUpdateProposedTransfer(clientId, transferId) {
    const { mutation, validationErrors } = usePost(
        (formData) =>
            updateProposedTransfer({ clientId, transferId }, formData),
        {
            showToastOnError: true,
            showToastOnSuccess: true,
        }
    );
    const onSubmit = (event, formData) => {
        event.preventDefault();
        return mutation.mutate(formData);
    };

    return { onSubmit, ...mutation, validationErrors };
}
