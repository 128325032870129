import React from "react";
import SelectInput from "./SelectInput";

export default function GenderSelect(props) {
    const options = [
        { value: "", label: "n/a" },
        { value: "male", label: "Male" },
        { value: "female", label: "Female" },
    ];

    return <SelectInput options={options} {...props} />;
}
