import React from "react";
import { FundingTableAssetOwnerships } from "views/funding_table/FundingTableIndex";
import OwnershipContextProvider from "contexts/OwnershipContext";
import useClient from "../../hooks/useClient";

export default function EstateInventoryTransfers() {
    const { clientPath } = useClient();
    return (
        <OwnershipContextProvider>
            <FundingTableAssetOwnerships
                clientPath={clientPath}
                currentTab="ownership"
            />
        </OwnershipContextProvider>
    );
}
