import React, { useContext, useState } from "react";
import { BudgetContext } from "../BudgetContext";
import InlineRowItem from "./InlineRowItem";
import InlineHeaderItem from "./InlineHeaderItem";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import InlineSectionTotal from "./InlineSectionTotal";
import useHasAnyPermissions from "hooks/useHasAnyPermissions";

export default function BudgetCategory({ budget }) {
    const [nextId, setNextId] = useState(0);
    const canEnterBudgetValues = useHasAnyPermissions([
        "budget_value_entry",
        "client_budget_data_provision",
    ]);
    const { setCurrentBudgetItem, setNewItem, setShowModal } =
        useContext(BudgetContext);

    return (
        <div className="mb-20" key={budget.name}>
            <InlineHeaderItem itemLabel={budget.name} />
            {budget.budget_category_items.length > 0 && (
                <>
                    {budget.budget_category_items.map((cat) => (
                        <div
                            className="odd:bg-gray-100 hover:bg-gray-200 py-2"
                            key={[`c`, budget.name, cat.id].join("_")}
                        >
                            <InlineRowItem
                                itemLabel={cat.name}
                                itemName={cat.id}
                                hasJoint={cat.has_joint_values}
                                hasOtherValue={cat.has_other_value}
                                disable={!canEnterBudgetValues}
                            />
                        </div>
                    ))}
                    {budget.can_add_items === 1 && (
                        <div>
                            <button
                                type="button"
                                onClick={() => {
                                    setNewItem({
                                        id: `UDF${nextId}`,
                                        budget_category_id: budget.id,
                                        name: "new item",
                                        sort_order:
                                            Math.max.apply(
                                                Math,
                                                budget.budget_category_items.map(
                                                    function (o) {
                                                        return o.sort_order;
                                                    }
                                                )
                                            ) + 10,
                                        has_other_value: 0,
                                        has_joint_values: 1,
                                    });
                                    setNextId(nextId + 1);
                                    setShowModal(true);
                                    setCurrentBudgetItem(budget);
                                }}
                                className="flex items-center space-x-1 text-gray-800 hover:text-brand p-1 mr-auto mt-4 text-xs"
                            >
                                <FontAwesomeIcon
                                    icon={faPlusCircle}
                                    className="text-gray-400"
                                />{" "}
                                <span>Add new {budget.name} item</span>
                            </button>
                        </div>
                    )}
                    <InlineSectionTotal
                        sectionLabel={budget.name}
                        sectionName={budget.id}
                        budget={budget}
                    />
                </>
            )}
        </div>
    );
}
