import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faArrowRight,
    faExternalLinkAlt,
} from "@fortawesome/free-solid-svg-icons";
import { snakeToSentenceCase } from "../../Utility";
import { Link } from "react-router-dom";
import PageContainer from "../../components/PageContainer";
import { formatDate } from "../../util/TextUtil";
import ClientPageHeader, { Heading } from "../../components/ClientPageHeader";
import useApi from "../../hooks/useApi";

function ShowChange({ client, ...props }) {
    const [change, setChange] = useState({});
    const { api, unauthorized, loading } = useApi();

    useEffect(() => {
        const fetchData = async () => {
            try {
                let response = await api(
                    "get",
                    "changes/" + props.match.params.changeId
                );
                setChange(response.data.data);
            } catch (error) {
                console.log(error);
            }
        };
        fetchData();
    }, [props.match.params.changeId, api]);

    useEffect(() => {
        document.title = "View Change";
    }, []);

    const displayValue = (value) => {
        if (
            value === null ||
            value === undefined ||
            value === "empty_value" ||
            value.length === 0
        ) {
            return <span className="line-through">No Value</span>;
        }

        return value;
    };

    const changes = (changes) => {
        if (changes.old && changes.new) {
            return Object.keys(changes.new).map(function (change) {
                return (
                    <tr className="border-b last:border-b-0" key={change}>
                        <td>
                            <div className="bold text-gray-600 mr-2">
                                {snakeToSentenceCase(change)}:
                            </div>
                            {displayValue(changes.old[change])}
                            <span className="text-gray-300 mx-1 text-sm">
                                <FontAwesomeIcon
                                    icon={faArrowRight}
                                    size="sm"
                                />
                            </span>{" "}
                            <span className="text-green-400">
                                {displayValue(changes.new[change])}
                            </span>
                        </td>
                    </tr>
                );
            });
        }
    };

    return (
        <>
            {client && (
                <ClientPageHeader client={client}>
                    <Heading
                        backTo={"/clients/" + client.id + "/changes"}
                        backText="Change Log"
                    >
                        {change.resource_display_name}
                    </Heading>
                </ClientPageHeader>
            )}
            <PageContainer isLoading={loading} forbidden={unauthorized}>
                <table className="w-full text-left">
                    <tbody>
                        <tr className="border-b border-gray-100">
                            <td className="font-semibold w-80">
                                What Happened
                            </td>
                            <td className="space-x-1">
                                <span>{change.resource_display_name}</span>
                            </td>
                        </tr>
                        {change.old_value && change.new_value && (
                            <tr className="border-b border-gray-100">
                                <td className="font-semibold">What Changed</td>
                                <td className="space-x-1">
                                    <span className="text-brand-600 font-semibold">
                                        {change.field_name_display}
                                    </span>{" "}
                                    <span>was changed from</span>
                                    <span className="text-brand-600 font-semibold">
                                        {change.old_value_display || "_blank_"}
                                    </span>
                                    <span>to</span>
                                    <span className="text-brand-600 font-semibold">
                                        {change.new_value_display || "_blank_"}
                                    </span>
                                </td>
                            </tr>
                        )}
                        <tr className="border-b border-gray-100">
                            <td className="font-semibold">
                                When Did It Happen
                            </td>
                            <td>{formatDate(change.created_at)}</td>
                        </tr>
                        <tr className="border-b border-gray-100">
                            <td className="font-semibold">Who Did It</td>
                            <td>
                                {change.user ? (
                                    <>
                                        <div>{change.user.full_name}</div>
                                        <div className="text-gray-600">
                                            {change.user.email}
                                        </div>
                                    </>
                                ) : (
                                    "N/A"
                                )}
                            </td>
                        </tr>

                        {change.changed_resource_display_name && (
                            <tr className="border-b border-gray-100">
                                <td className="font-semibold">Resource Name</td>
                                <td>
                                    {change.resource_name ||
                                        change.changed_resource_display_name}
                                </td>
                            </tr>
                        )}

                        {change.field_name_display && (
                            <tr className="border-b border-gray-100">
                                <td className="font-semibold">Field Changed</td>
                                <td>{change.field_name_display}</td>
                            </tr>
                        )}

                        <tr className="border-b border-gray-100">
                            <td className="font-semibold">Resource Type</td>
                            <td>{change.resource_display_type}</td>
                        </tr>

                        <tr className="border-b border-gray-100">
                            <td className="font-semibold">Resource ID</td>
                            <td>{change.resource_id}</td>
                        </tr>

                        {change.resource_url && (
                            <tr className="border-b border-gray-100">
                                <td className="font-semibold">
                                    Resource Changed
                                </td>
                                <td>
                                    <Link
                                        to={change.resource_url}
                                        className="underline flex space-x-1 items-center"
                                    >
                                        <span>View Resource</span>
                                        <FontAwesomeIcon
                                            icon={faExternalLinkAlt}
                                            className="pb-1"
                                        />
                                    </Link>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>

                {change.description && (
                    <div className="mt-12">
                        <p className="font-semibold mb-2">What Changed</p>
                        <table className="w-full">
                            <tbody>{changes(change.description)}</tbody>
                        </table>
                    </div>
                )}
            </PageContainer>
        </>
    );
}

export default ShowChange;
