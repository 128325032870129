import React, { useContext } from "react";
import SelectInput from "./SelectInput";
import Api from "../services/Api";
import { ResourceContext } from "../contexts/ResourceContext";

export default function BeneficiaryResourceSelect({
    autoFocus,
    excluded = [],
    ...props
}) {
    const resourceContext = useContext(ResourceContext);
    const options = resourceContext.options.filter(
        (option) => excluded.includes(option.value) === false
    );
    return (
        <SelectInput
            options={options}
            loading={resourceContext.loading}
            autoFocus={autoFocus}
            {...props}
        />
    );
}

export function EnumSelect({ enumType, ...props }) {
    const [loading, setLoading] = React.useState(true);
    const [options, setOptions] = React.useState([]);

    React.useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
                let response = await Api.get(`enums/${enumType}`);
                let options = response.data;
                setOptions([
                    { label: "None Selected", value: "", disabled: true },
                    ...options,
                ]);
                setLoading(false);
            } catch (error) {
                console.error(error);
            }
        };
        fetchData();
    }, [enumType]);

    return <SelectInput options={options} loading={loading} {...props} />;
}
