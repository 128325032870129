import React from "react";
import { Link, useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tooltip } from "react-tippy";

export default function MainNavigationItem({
    to,
    label: Label,
    icon,
    className,
    count = 0,
    as = "Link",
    ...props
}) {
    const activeClass = "font-normal bg-brand text-white";
    const inActiveClass = "font-normal text-brand hover:text-brand-700";

    const expandedStyles =
        "flex-0 flex justify-center relative mr-4 rounded-r-lg";
    const collapsedStyles = "flex-0 flex justify-center relative";

    const history = useHistory();

    const isActive = () => {
        const [lastUrlSegment] = history.location.pathname.split("/").slice(-1);
        const [lastLinkSegment] = to ? to.split("/").slice(-1) : "";
        return lastUrlSegment === lastLinkSegment;
    };

    if (as === "Button") {
        return (
            <li
                className={`${
                    props.isCollapsed ? collapsedStyles : expandedStyles
                } ${
                    isActive("clients") ? activeClass : inActiveClass
                } ${className}`}
            >
                <button
                    type="button"
                    className={`${
                        props.isCollapsed
                            ? "flex items-center mx-auto my-1 py-2 text-lg outline-none focus:outline-none"
                            : "flex items-center mx-auto my-1 py-2 text-lg space-x-3 w-48 outline-none focus:outline-none"
                    }`}
                    onClick={(e) => (props.onClick ? props.onClick(e) : false)}
                >
                    {icon && (
                        <Tooltip
                            disabled={!props.isCollapsed}
                            title={props.tooltip || Label}
                            position="right"
                            duration="150"
                            followCursor={false}
                            distance="-64"
                        >
                            <div className="relative">
                                <FontAwesomeIcon
                                    icon={icon}
                                    className="text-xl"
                                    fixedWidth
                                />
                                {count > 0 && (
                                    <span className="absolute bottom-0 right-0 mt-1 h-4 px-1 rounded-full bg-red-400 text-white text-xs font-bold flex items-center justify-center">
                                        {count}
                                    </span>
                                )}
                            </div>
                        </Tooltip>
                    )}
                    {!props.isCollapsed && (
                        <span className="text-base">
                            {typeof Label === "function" ? (
                                <Label isActive={isActive()} />
                            ) : (
                                Label
                            )}
                        </span>
                    )}
                </button>
            </li>
        );
    }

    return (
        <li
            className={`${
                props.isCollapsed ? collapsedStyles : expandedStyles
            } ${
                isActive("clients") ? activeClass : inActiveClass
            } ${className}`}
        >
            <Link
                to={to}
                className={`${
                    props.isCollapsed
                        ? "flex items-center mx-auto my-1 py-2 text-lg"
                        : "flex items-center mx-auto my-1 py-2 text-lg space-x-3 w-48"
                }`}
                onClick={(e) => (props.onClick ? props.onClick(e) : false)}
            >
                {icon && (
                    <Tooltip
                        disabled={!props.isCollapsed}
                        title={props.tooltip || Label}
                        position="right"
                        duration="150"
                        followCursor={false}
                        distance="-64"
                    >
                        <div className="relative">
                            <FontAwesomeIcon
                                icon={icon}
                                className="text-xl"
                                fixedWidth
                            />
                            {count > 0 && (
                                <span className="absolute bottom-0 right-0 mt-1 h-4 px-1 rounded-full bg-red-400 text-white text-xs font-bold flex items-center justify-center">
                                    {count}
                                </span>
                            )}
                        </div>
                    </Tooltip>
                )}
                {!props.isCollapsed && (
                    <span className="w-full text-base">
                        {typeof Label === "function" ? (
                            <Label isActive={isActive()} />
                        ) : (
                            Label
                        )}
                    </span>
                )}
            </Link>
        </li>
    );
}
