import usePost from "./usePost";
import { updateAccount } from "services/AccountSwitcherService";

export default function useSwitchAccount() {
    const { mutation, validationErrors } = usePost(
        (formData) => updateAccount(formData),
        {
            showToastOnError: true,
            showToastOnSuccess: true,
            onSuccess: (data) => {
                console.log(data);

                let next = new URL(window.location.href);

                let old = next.hostname;
                let hosts = next.hostname.split(".");
                hosts[0] = data.domain;
                next.hostname = hosts.join(".");

                if (old !== next.hostname) {
                    window.location.href = next + "";
                } else {
                    window.location.reload();
                }
            },
        }
    );
    const onSubmit = (event, account) => {
        event.preventDefault();
        return mutation.mutate(account);
    };

    return { onSubmit, ...mutation, validationErrors };
}
