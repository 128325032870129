import React, { useState, useEffect, useContext } from "react";
import Api from "../../services/Api";
import { Redirect } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import PageContainer from "../../components/PageContainer";
import PageHeader from "../../components/PageHeader";
import QuestionnaireQuestions from "./_partials/QuestionnaireQuestions";
import { AuthContext } from "../../contexts/AuthContext";
import Button from "../../components/Button";
import SaveButton from "../../components/SaveButton";
import Spinner from "../../components/Spinner";
import TextInput from "../../components/TextInput";
import SelectInput from "../../components/SelectInput";
import ResourceAccessClientList from "../resources/ResourceAccessClientList";
import Forbidden from "../../components/Forbidden";
import { statusOptions } from "./AddQuestionnaire";

export default function EditQuestionnaire({
    member,
    readOnly = false,
    ...props
}) {
    const [notAuthorized, setNotAuthorized] = useState(false);
    const { addToast } = useToasts();
    const [errors, setErrors] = useState([]);
    const [redirectToIndex, setRedirectToIndex] = useState(false);
    const auth = useContext(AuthContext);
    const [loading, setLoading] = useState(true);
    const [needsRefreshed, setNeedsRefreshed] = useState(0);

    const [questionnaire, setQuestionnaire] = useState(null);

    const [title, setTitle] = useState();
    const [status, setStatus] = useState("");

    useEffect(() => {
        document.title = "Edit Questionnaire";
    }, []);

    useEffect(() => {
        if (auth.user.type !== "member") {
            setNotAuthorized(true);
        }
    }, [auth.user]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                let response = await Api.get(
                    "members/" +
                        member.id +
                        "/questionnaires/" +
                        props.match.params.questionnaireId
                );
                setQuestionnaire(response.data.data);
                setTitle(response.data.data.title);
                setStatus(response.data.data.status);
                setLoading(false);
            } catch (error) {
                if (error.response.status === 403) {
                    setNotAuthorized(true);
                }
            }
        };
        fetchData();
    }, [member, props.match.params.questionnaireId, needsRefreshed]);

    async function update(e) {
        e.preventDefault();
        try {
            let response = await Api.put(
                "members/" + member.id + "/questionnaires/" + questionnaire.id,
                {
                    title,
                    status,
                }
            );
            addToast(response.data.message);
            setErrors([]);
            setRedirectToIndex(true);
            setNeedsRefreshed(needsRefreshed + 1);
        } catch (e) {
            setErrors([]);
            if (e.response.data.errors) {
                setErrors(e.response.data.errors);
            } else {
                addToast(e.response.data.message, { type: "error" });
            }
        }
    }

    if (notAuthorized) {
        return <Forbidden />;
    }

    if (redirectToIndex) {
        return <Redirect to={`/members/${member.id}/questionnaires`} />;
    }

    if (loading) {
        return <Spinner />;
    }

    return (
        <>
            <PageHeader
                text="Questionnaires"
                backTo={`/members/${member.id}/questionnaires`}
                backText="All Questionnaires"
            >
                <div className="flex flex-row">
                    {readOnly !== true && <SaveButton onClick={update} />}
                    {readOnly !== false && (
                        <Button
                            type="link"
                            to={`/members/${member.id}/questionnaires/${questionnaire.id}/edit`}
                            text="Edit"
                        />
                    )}
                </div>
            </PageHeader>
            <PageContainer>
                <form onSubmit={update} className="max-w-6xl mx-auto space-y-6">
                    <TextInput
                        name="title"
                        label="Questionnaire Title"
                        id="title"
                        readOnly={readOnly}
                        disabled={readOnly}
                        value={title}
                        error={errors.title}
                        onChange={(e) => setTitle(e.target.value)}
                        required={true}
                        autoFocus={true}
                    />
                    <SelectInput
                        name="status"
                        label="Status"
                        id="status"
                        readOnly={readOnly}
                        disabled={readOnly}
                        options={statusOptions}
                        value={status}
                        error={errors.status}
                        onChange={(e) => setStatus(e.target.value)}
                        required={true}
                    />
                    <input type="submit" hidden />
                    <hr className="mb-6" />
                </form>

                <div className="max-w-6xl mx-auto">
                    <QuestionnaireQuestions
                        member={member}
                        questionnaire={questionnaire}
                        errors={errors}
                        setErrors={setErrors}
                        refresh={() => setNeedsRefreshed(needsRefreshed + 1)}
                        onSubmit={update}
                        readOnly={readOnly}
                    />

                    {questionnaire.status === "private" && (
                        <ResourceAccessClientList
                            resourceType="Questionnaire"
                            resourceId={questionnaire.id}
                        />
                    )}
                </div>
            </PageContainer>
        </>
    );
}
