import React, { useState } from "react";
import BasicModal from "../ModalBasic";
import TextInput from "../TextInput";
import Button from "../Button";
import { formatDate } from "../../util/TextUtil";
import { GiftQuestions } from "./Transfer";
import Money, { parseMoney } from "../Money";
import ValueTransferDestination from "./ValueTransferDestination";
import { titleCase } from "../../util/TextUtil";
import SelectInput from "../SelectInput";
import { TransferLineItem } from "./TransferDestination";
import useVerifyTransfer from "hooks/useVerifyTransfer";
import useUpdateProposedTransfer from "hooks/useUpdateProposedTransfer";
import useAssetValues from "views/funding_table/hooks/useAssetValues";
import { Tooltip } from "react-tippy";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import usePostMortemState from "hooks/usePostMortemState";
import { useOwnershipContext } from "contexts/OwnershipContext";

export default function VerifyIndividualTransfer({
    client,
    asset,
    transfer,
    source,
    isOpen,
    onClose,
    ownership,
    closeModal,
}) {
    if (!isOpen || !transfer || !ownership) {
        return <></>;
    }

    const { isPostMortem } = usePostMortemState();

    const ownershipContext = useOwnershipContext();

    const [hasChanges, setHasChanges] = useState(false);
    const [input, setInput] = useState(transfer);
    const [edit, setEdit] = useState(false);

    const transferUnit =
        parseInt(transfer.is_percentage) === 1 ? "percentage" : "amount";

    const resetOnClose = () => {
        setInput(() => ({}));
        setEdit(false);
        setHasChanges(false);
        onClose();
    };

    const discard = () => {
        setHasChanges(false);
        setEdit(false);
    };

    const closeModalAndReset = () => {
        resetOnClose();
        closeModal();
    };

    let max = 100;

    if (transfer.source_proposed_ownership) {
        max =
            (transfer.source_proposed_ownership?.percentage_owned ?? 0) +
            transfer.transfer_percentage;
    }

    React.useEffect(() => {
        if (!edit && isOpen && !hasChanges) {
            setInput(() => ({
                ...transfer,
                verified_at: formatDate(new Date(), false, {
                    format: "Y-MM-DD",
                }),
                proposed_at: formatDate(transfer.proposed_at, false, {
                    format: "Y-MM-DD",
                }),
                id: transfer.id,
                unit: parseMoney(
                    transferUnit == "percentage"
                        ? transfer.transfer_percentage
                        : transfer.transfer_amount
                ),
                type: transfer.destination_ownership_type,
                owner_type: transfer.destination_type,
                owner_id: transfer.destination_id,
                description: transfer.destination_description,
            }));
        }
    }, [edit, transfer, isOpen, hasChanges]);

    const transferSourceBefore = {
        id: "sourceBefore",
        unit: parseMoney(
            transferUnit == "percentage"
                ? transfer.source_original_percentage
                : transfer.original_asset_value
        ),
        type: transfer.source_ownership_type,
        owner_type: transfer.source_type,
        owner_id: transfer.source_id,
        description: transfer.source_description,
    };
    const transferSourceAfter = {
        ...transferSourceBefore,
        id: "sourceAfter",
        unit: max - input.unit + "",
    };

    const {
        onSubmit,
        isLoading: saving,
        isSuccess: didSave,
        validationErrors: errors,
    } = useVerifyTransfer(transfer.client_id, transfer.id);

    const { onSubmit: onUpdateProposedTransfer } = useUpdateProposedTransfer(
        transfer.client_id,
        transfer.id
    );

    React.useEffect(() => {
        if (didSave) {
            closeModalAndReset();
        }
    }, [didSave]);

    async function save(e) {
        if (
            confirm(
                "Are you sure you want to verify and complete the transfer?"
            )
        ) {
            const payload = {
                ...input,
            };
            onSubmit(e, payload);
        }
    }

    const updateInput = (input) => {
        setHasChanges(true);
        setInput((old) => ({ ...old, ...input }));
    };

    const updateValue = (e) => {
        updateInput({
            ...input,
            [e.target.name]: e.target.value,
        });
    };

    async function acceptChanges(event) {
        event.preventDefault();
        onUpdateProposedTransfer(event, input);
        setEdit((edit) => !edit);
    }

    const { asOfDate, asOfDateLabel, value, valueLabel } = useAssetValues({
        asset,
    });

    let netValue = value;

    return (
        <BasicModal
            isOpen={isOpen}
            size="3xl"
            onClose={() => closeModal()}
            header={
                <div className="sticky top-0 z-10 flex items-center justify-between border-b border-gray-300 px-6 py-3 bg-white">
                    <div className="flex items-center space-x-6">
                        <div className="flex-none w-72">
                            <div className={`text-xs text-gray-500`}>
                                {titleCase(asset.type, {
                                    pascal: true,
                                })}
                            </div>
                            <h2 className="text-sm">
                                <span className="font-bold text-base">
                                    {asset.name}
                                </span>
                                {asset.account_number && (
                                    <span className="text-xs text-gray-500 block -mb-1">
                                        {asset.account_number}
                                    </span>
                                )}
                            </h2>
                        </div>
                        {!edit && (
                            <div className="flex-none text-left">
                                <div
                                    className={`text-xs text-gray-500 space-x-1`}
                                >
                                    <span>{valueLabel}</span>
                                    {isPostMortem && (
                                        <Tooltip
                                            title="Value of asset shown is 100% regardless of percentage included in estate."
                                            position="top"
                                            duration={150}
                                            followCursor={false}
                                            distance={16}
                                        >
                                            <FontAwesomeIcon
                                                icon={faQuestionCircle}
                                                className="text-gray-400"
                                                fixedWidth
                                            />
                                        </Tooltip>
                                    )}
                                </div>
                                <div className="text-sm tabular-nums">
                                    {<Money value={netValue} />}
                                </div>
                            </div>
                        )}

                        {!edit && (
                            <div className="flex-none text-left">
                                <div className={`text-xs text-gray-500`}>
                                    {asOfDateLabel}
                                </div>
                                <div className="text-sm">
                                    {formatDate(asOfDate)}
                                </div>
                            </div>
                        )}
                    </div>
                    <div className="flex items-end space-x-2 ml-auto">
                        {!edit && (
                            <Button
                                type="button"
                                appearance="light"
                                className="bg-white"
                                text="Close"
                                onClick={() => closeModalAndReset()}
                            />
                        )}
                        {edit && (
                            <Button
                                type="button"
                                appearance="danger"
                                className="bg-white"
                                text="Reset Changes"
                                onClick={() => discard()}
                            />
                        )}
                        {edit ? (
                            <Button
                                type="button"
                                appearance="default"
                                text={"Accept Changes"}
                                onClick={(event) => acceptChanges(event)}
                            />
                        ) : (
                            <Button
                                type="button"
                                appearance="default"
                                text={"Edit"}
                                onClick={() => setEdit(true)}
                            />
                        )}
                    </div>
                </div>
            }
        >
            <div>
                {transfer.transfer_type === "value" && (
                    <>
                        <div className="mb-6">
                            <h2 className="mb-4 font-bold text-lg text-gray-800">
                                Transferor
                            </h2>
                            <SelectInput
                                name="donor_type"
                                options={ownershipContext.donorOptions()}
                                value={transfer.donor_type}
                                disabled={!edit}
                            />
                        </div>
                        <div className="space-y-2 mb-10">
                            <h2 className="font-bold text-lg text-gray-800">
                                Recipient
                            </h2>
                            <ValueTransferDestination
                                client={client}
                                asset={asset}
                                errors={errors}
                                source={asset.ownership}
                                transfer={input}
                                destinations={[input]}
                                setDestinations={(destinations) => {
                                    updateInput(destinations[0]);
                                }}
                                showAdd={false}
                                readOnly={!edit}
                                disabled={!edit}
                            />
                        </div>
                    </>
                )}
                {transfer.transfer_type === "ownership" && (
                    <>
                        <div className="flex flex-col space-y-6 mb-6">
                            <div className="space-y-2">
                                <h2 className="font-bold text-lg text-gray-800">
                                    Current Source Ownership
                                </h2>
                                <div className="flex space-x-3 items-end">
                                    <TransferLineItem
                                        destination={transferSourceBefore}
                                        after={transferSourceAfter}
                                        source={source}
                                        disabled={true}
                                        transfer={transfer}
                                        transferUnit={transferUnit}
                                    />
                                </div>
                            </div>

                            <div className="mb-6">
                                <h2 className="mb-4 font-bold text-lg text-gray-800">
                                    Transferor
                                </h2>
                                <SelectInput
                                    name="donor_type"
                                    options={ownershipContext.donorOptions()}
                                    value={transfer.donor_type}
                                    disabled={true}
                                />
                            </div>

                            <div className="space-y-2 mb-10">
                                <h2 className="font-bold text-lg text-gray-800">
                                    Proposed Transfer
                                </h2>

                                <div className="flex space-x-3 items-end">
                                    <TransferLineItem
                                        destination={input}
                                        updateDestination={updateInput}
                                        source={source}
                                        disabled={!edit}
                                        transfer={transfer}
                                        transferUnit={transferUnit}
                                        errors={errors}
                                    />
                                </div>
                            </div>
                        </div>
                    </>
                )}

                {transfer && !isPostMortem && (
                    <>
                        <GiftQuestions
                            input={input}
                            errors={errors}
                            updateValue={updateValue}
                            disabled={!edit}
                        />
                    </>
                )}

                <div className="flex justify-end">
                    <div className="flex items-end space-x-3 justify-end">
                        <div>
                            <TextInput
                                type="date"
                                label="Date Proposed"
                                name="proposed_at"
                                value={input.proposed_at}
                                onChange={updateValue}
                                disabled={!edit}
                            />
                        </div>
                        {!edit && (
                            <>
                                <div>
                                    <TextInput
                                        type="date"
                                        label="Date Verified"
                                        name="verified_at"
                                        value={input.verified_at}
                                        onChange={updateValue}
                                        disabled={false}
                                    />
                                </div>
                                <div className="w-40">
                                    <Button
                                        onClick={save}
                                        isLoading={saving}
                                        disabled={edit}
                                        text={
                                            edit ? "Finish Editing" : "Verify"
                                        }
                                    />
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </BasicModal>
    );
}
