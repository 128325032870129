import useFetch from "./useFetch";
import { fetchBudgetCategories } from "../services/BudgetService";

export default function useBudgetItems(
    params,
    config = { keepPreviousData: true }
) {
    return useFetch(
        ["client-budget-categories", params],
        fetchBudgetCategories,
        config
    );
}
