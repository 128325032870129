import React, { useState } from "react";
import TextInput from "../../../components/TextInput";
import SelectInput from "../../../components/SelectInput";
import CountrySelector from "../../../components/CountrySelector";
import Spinner from "../../../components/Spinner";
import GenderSelect from "../../../components/GenderSelect";
import useRelationshipContext from "hooks/useRelationshipContext";

export default function SpouseForm({
    relationshipInput,
    readOnly = false,
    allFields = true,
    errors,
    onChange,
}) {
    const { spouseModifiers, directRelationships } = useRelationshipContext();
    const [isLoading] = useState(false);

    if (isLoading) {
        return <Spinner />;
    }

    return (
        <>
            {directRelationships.length > 0 && (
                <div className="mb-6">
                    <SelectInput
                        label="Direct Relation"
                        options={directRelationships}
                        value={relationshipInput.direct_relationship_id}
                        name="direct_relationship_id"
                        onChange={onChange}
                        error={errors.direct_relationship_id}
                        defaultOption="None"
                        disabled={readOnly}
                    ></SelectInput>
                </div>
            )}

            {spouseModifiers.length > 0 && (
                <div className="mb-6">
                    <SelectInput
                        label="Relationship Modifier"
                        options={spouseModifiers}
                        value={relationshipInput.modifier}
                        error={errors.modifier}
                        name="modifier"
                        onChange={onChange}
                        disabled={readOnly}
                    ></SelectInput>
                </div>
            )}

            <div className="mb-6">
                <TextInput
                    type="date"
                    name="marriage_date"
                    label="Marriage Date"
                    id="marriage_date"
                    value={relationshipInput.marriage_date}
                    error={errors.marriage_date}
                    readOnly={readOnly}
                    onChange={onChange}
                    required={true}
                />
            </div>

            {spouseModifiers.length > 0 && relationshipInput.modifier > 0 && (
                <div className="mb-6">
                    <TextInput
                        type="date"
                        name="marriage_end_date"
                        label="Marriage End Date"
                        id="birth_date"
                        value={relationshipInput.marriage_end_date}
                        error={errors.marriage_end_date}
                        readOnly={readOnly}
                        onChange={onChange}
                    />
                </div>
            )}

            <div className="flex items-end space-x-3 mb-6">
                <div className="w-20">
                    <TextInput
                        name="prefix"
                        label="Prefix"
                        id="prefix"
                        value={relationshipInput.prefix}
                        error={errors.prefix}
                        readOnly={readOnly}
                        onChange={onChange}
                    />
                </div>
                <div className="flex-1">
                    <TextInput
                        name="first_name"
                        label="First Name"
                        id="first_name"
                        value={relationshipInput.first_name}
                        error={errors.first_name}
                        readOnly={readOnly}
                        onChange={onChange}
                        required={true}
                    />
                </div>
                <div className="flex-1">
                    <TextInput
                        name="middle_name"
                        label="Middle Name"
                        id="middle_name"
                        value={relationshipInput.middle_name}
                        error={errors.middle_name}
                        onChange={onChange}
                        readOnly={readOnly}
                    />
                </div>
                <div className="flex-1">
                    <TextInput
                        name="last_name"
                        label="Last Name"
                        id="last_name"
                        value={relationshipInput.last_name}
                        error={errors.last_name}
                        onChange={onChange}
                        readOnly={readOnly}
                        required={true}
                    />
                </div>
                <div className="w-20">
                    <TextInput
                        name="suffix"
                        label="Suffix"
                        id="suffix"
                        value={relationshipInput.suffix}
                        error={errors.suffix}
                        readOnly={readOnly}
                        onChange={onChange}
                    />
                </div>
            </div>

            <div className="flex items-end space-x-3 mb-6">
                <div className="flex-1">
                    <TextInput
                        name="signature_name"
                        label="Signature Name"
                        id="signature_name"
                        value={relationshipInput.signature_name}
                        error={errors.signature_name}
                        onChange={onChange}
                        readOnly={readOnly}
                    />
                </div>

                <div className="flex-1">
                    <TextInput
                        name="maiden_name"
                        label="Maiden Name"
                        id="maiden_name"
                        value={relationshipInput.maiden_name}
                        error={errors.maiden_name}
                        onChange={onChange}
                        readOnly={readOnly}
                    />
                </div>

                <div className="flex-1">
                    <TextInput
                        name="nick_name"
                        label="Nickname"
                        id="nick_name"
                        value={relationshipInput.nick_name}
                        error={errors.nick_name}
                        onChange={onChange}
                        readOnly={readOnly}
                    />
                </div>
            </div>

            <div className="flex items-end space-x-3 mb-6">
                <div className="flex-1">
                    <GenderSelect
                        label="Gender"
                        value={relationshipInput.gender}
                        name="gender"
                        error={errors.gender}
                        onChange={onChange}
                        defaultOption={""}
                        disabled={readOnly}
                    />
                </div>

                <div className="flex-1">
                    <TextInput
                        type="date"
                        name="birth_date"
                        label="Birthdate"
                        id="birth_date"
                        value={relationshipInput.birth_date}
                        error={errors.birth_date}
                        readOnly={readOnly}
                        onChange={onChange}
                    />
                </div>

                <div className="flex-1">
                    <TextInput
                        type="date"
                        name="date_of_death"
                        label="DOD"
                        id="date_of_death"
                        value={relationshipInput.date_of_death}
                        error={errors.date_of_death}
                        readOnly={readOnly}
                        onChange={onChange}
                    />
                </div>
            </div>

            {allFields && (
                <>
                    <fieldset className="mt-12">
                        <legend className="font-bold txt-md text-gray-500 my-6 pb-1 border-b block w-full">
                            Contact Information
                        </legend>

                        <div className="mb-6">
                            <TextInput
                                type="email"
                                name="email"
                                label="Email"
                                autoComplete="off"
                                id="email"
                                value={relationshipInput.email}
                                error={errors.email}
                                readOnly={readOnly}
                                onChange={onChange}
                            />
                        </div>

                        <div className="mb-6">
                            <TextInput
                                type="tel"
                                name="phone"
                                label="Home Phone"
                                id="phone"
                                value={relationshipInput.phone}
                                error={errors.phone}
                                readOnly={readOnly}
                                onChange={onChange}
                            />
                        </div>

                        <div className="mb-6">
                            <TextInput
                                type="tel"
                                name="mobile_phone"
                                label="Mobile Phone"
                                id="mobile_phone"
                                value={relationshipInput.mobile_phone}
                                error={errors.mobile_phone}
                                readOnly={readOnly}
                                onChange={onChange}
                            />
                        </div>

                        <div className="mb-6">
                            <TextInput
                                name="address"
                                label="Address"
                                id="address"
                                value={relationshipInput.address}
                                error={errors.address}
                                onChange={onChange}
                                readOnly={readOnly}
                            />
                        </div>

                        <div className="mb-6">
                            <TextInput
                                name="address_2"
                                label="Address 2"
                                id="address_2"
                                value={relationshipInput.address_2}
                                error={errors.address_2}
                                onChange={onChange}
                                readOnly={readOnly}
                            />
                        </div>

                        <div className="flex mb-6">
                            <div className="w-1/3 mr-4">
                                <TextInput
                                    name="city"
                                    label="City"
                                    id="city"
                                    value={relationshipInput.city}
                                    error={errors.city}
                                    onChange={onChange}
                                    readOnly={readOnly}
                                />
                            </div>
                            <div className="w-1/3 mr-4">
                                <TextInput
                                    name="state"
                                    label="State"
                                    id="state"
                                    value={relationshipInput.state}
                                    error={errors.state}
                                    onChange={onChange}
                                    readOnly={readOnly}
                                />
                            </div>
                            <div className="w-1/3">
                                <TextInput
                                    name="postal_code"
                                    label="Postal Code"
                                    id="postal_code"
                                    value={relationshipInput.postal_code}
                                    error={errors.postal_code}
                                    onChange={onChange}
                                    readOnly={readOnly}
                                />
                            </div>
                        </div>

                        <div className="mb-6">
                            <TextInput
                                name="county"
                                label="County"
                                id="county"
                                value={relationshipInput.county}
                                error={errors.county}
                                onChange={onChange}
                                readOnly={readOnly}
                            />
                        </div>

                        <div className="mb-6">
                            <CountrySelector
                                label="Country"
                                value={relationshipInput.country}
                                name="country"
                                error={errors.country}
                                onChange={onChange}
                                disabled={readOnly}
                            />
                        </div>
                        <div className="mb-6">
                            <CountrySelector
                                label="Citizenship"
                                value={relationshipInput.citizenship}
                                disabled={readOnly}
                                name="citizenship"
                                onChange={onChange}
                            />
                        </div>
                    </fieldset>

                    <fieldset className="mt-12">
                        <legend className="font-bold txt-md text-gray-500 my-6 pb-1 border-b block w-full">
                            Employment
                        </legend>
                        <div className="mb-6">
                            <TextInput
                                name="work_name"
                                label="Company"
                                id="work_name"
                                value={relationshipInput.work_name}
                                error={errors.work_name}
                                onChange={onChange}
                                readOnly={readOnly}
                            />
                        </div>

                        <div className="mb-6">
                            <TextInput
                                type="tel"
                                name="work_phone"
                                label="Work Phone"
                                id="work_phone"
                                value={relationshipInput.work_phone}
                                error={errors.work_phone}
                                onChange={onChange}
                                readOnly={readOnly}
                            />
                        </div>

                        <div className="mb-6">
                            <TextInput
                                name="work_address"
                                label="Address"
                                id="work_address"
                                value={relationshipInput.work_address}
                                error={errors.work_address}
                                onChange={onChange}
                                readOnly={readOnly}
                            />
                        </div>

                        <div className="flex mb-6">
                            <div className="w-1/3 mr-4">
                                <TextInput
                                    name="work_city"
                                    label="City"
                                    id="work_city"
                                    value={relationshipInput.work_city}
                                    error={errors.work_city}
                                    onChange={onChange}
                                    readOnly={readOnly}
                                />
                            </div>
                            <div className="w-1/3 mr-4">
                                <TextInput
                                    name="work_state"
                                    label="State"
                                    id="work_state"
                                    value={relationshipInput.work_state}
                                    error={errors.work_state}
                                    onChange={onChange}
                                    readOnly={readOnly}
                                />
                            </div>
                            <div className="w-1/3">
                                <TextInput
                                    name="work_postal_code"
                                    label="Postal Code"
                                    id="work_postal_code"
                                    value={relationshipInput.work_postal_code}
                                    error={errors.work_postal_code}
                                    onChange={onChange}
                                    readOnly={readOnly}
                                />
                            </div>
                        </div>

                        <div className="mb-6">
                            <CountrySelector
                                label="Country"
                                value={relationshipInput.work_country}
                                name="work_country"
                                onChange={onChange}
                                error={errors.work_country}
                                disabled={readOnly}
                            />
                        </div>
                    </fieldset>

                    <fieldset className="mt-12">
                        <legend className="font-bold txt-md text-gray-500 my-6 pb-1 border-b block w-full">
                            Identifying Information
                        </legend>

                        <div className="mb-6">
                            <TextInput
                                type="text"
                                secret={true}
                                name="drivers_license_number"
                                label="Driver's License Number"
                                autoComplete="off"
                                id="drivers_license_number"
                                value={relationshipInput.drivers_license_number}
                                error={errors.drivers_license_number}
                                onChange={onChange}
                                readOnly={readOnly}
                            />
                        </div>
                        <div className="mb-6">
                            <TextInput
                                type="text"
                                name="drivers_license_state"
                                label="Driver's License State"
                                autoComplete="off"
                                id="drivers_license_state"
                                value={relationshipInput.drivers_license_state}
                                error={errors.drivers_license_state}
                                onChange={onChange}
                                readOnly={readOnly}
                            />
                        </div>
                        <div className="mb-6">
                            <TextInput
                                type="text"
                                secret={true}
                                name="passport_number"
                                label="Passport Number"
                                autoComplete="off"
                                id="passport_number"
                                value={relationshipInput.passport_number}
                                error={errors.passport_number}
                                onChange={onChange}
                                readOnly={readOnly}
                            />
                        </div>

                        <div className="mb-6">
                            <TextInput
                                type="text"
                                secret={true}
                                name="ssn"
                                label="Social Security Number"
                                autoComplete="off"
                                id="ssn"
                                value={relationshipInput.ssn}
                                error={errors.ssn}
                                onChange={onChange}
                                readOnly={readOnly}
                            />
                        </div>
                    </fieldset>
                </>
            )}
        </>
    );
}
