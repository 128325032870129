import React, { useReducer, useState, useEffect, useCallback } from "react";
import ClientPageHeader, { Heading } from "../../components/ClientPageHeader";
import SaveButton from "../../components/SaveButton";
import PageContainer from "../../components/PageContainer";
import RetirementAccountForm from "./_partials/RetirementAccountForm";
import { useToasts } from "react-toast-notifications";
import { Redirect } from "react-router-dom";
import Api from "../../services/Api";
import UnsavedChanges from "../../components/UnsavedChanges";
import { formatDateForInput } from "../../util/TextUtil";

export default function AddRetirementAccount({ client, clientPath }) {
    const { addToast } = useToasts();
    const [errors, setErrors] = useState([]);
    const [redirectToEdit, setRedirectToEdit] = useState(false);
    const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);

    const [input, setInput] = useReducer(
        (state, newState) => ({ ...state, ...newState }),
        {
            // initialize values here
            financial_account_type_id: null,
            account_number: "",
            name: "",
            value: "",
            as_of_date: formatDateForInput(new Date()),
            cost_basis: 0,
            company_name: "",
            contact_name: "",
            address: "",
            address_2: "",
            city: "",
            state: "",
            postal_code: "",
            country: "",
            phone: "",
        }
    );

    useEffect(() => {
        document.title = "Add Retirement Account";
    }, []);

    const updateValue = useCallback((event) => {
        setInput({ [event.target.name]: event.target.value });
        setHasUnsavedChanges(true);
    }, []);

    async function store(e) {
        e.preventDefault();
        try {
            const body = { ...input };
            let response = await Api.post(
                "clients/" + client.id + "/retirement-accounts",
                body
            );
            addToast(response.data.message);
            setErrors([]);
            setRedirectToEdit(response.data.data.id);
        } catch (e) {
            setErrors([]);
            if (e.response.data.errors) {
                setErrors(e.response.data.errors);
            } else {
                addToast(e.response.data.message, { type: "error" });
            }
        }
    }

    if (redirectToEdit) {
        return (
            <Redirect
                to={`${clientPath}/retirement-accounts/${redirectToEdit}/edit/owners`}
            />
        );
    }

    return (
        <>
            <ClientPageHeader client={client}>
                <Heading
                    backTo={`${clientPath}/retirement-accounts`}
                    backText="All Retirement Accounts"
                >
                    Add Retirement Account
                </Heading>
                <div className="flex-0">
                    <SaveButton onClick={store} />
                </div>
            </ClientPageHeader>
            <PageContainer>
                <UnsavedChanges when={hasUnsavedChanges} />
                <div className="max-w-3xl mx-auto">
                    <RetirementAccountForm
                        client={client}
                        input={input}
                        errors={errors}
                        onSubmit={store}
                        updateValue={updateValue}
                    />
                </div>
            </PageContainer>
        </>
    );
}
