import React, { useState, useEffect, useContext } from "react";
import Api from "../../../services/Api";
import Dropdown, { DropdownItem } from "../../../components/Dropdown";
import { Link } from "react-router-dom";
import Spinner from "../../../components/Spinner";
import TableSortHeader from "../../../components/TableSortHeader";
import SearchInput from "../../../components/SearchInput";
import { AuthContext } from "../../../contexts/AuthContext";
import useDebounce from "../../../hooks/useDebounce";
import { formatDate } from "../../../util/TextUtil";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faTrash,
    faEdit,
    faEllipsisH,
} from "@fortawesome/free-solid-svg-icons";
import { useToasts } from "react-toast-notifications";
import Pagination from "../../../components/Pagination";
import usePagination from "../../../hooks/usePagination";
import ModalModelFactory from "../../../components/ModalModelFactory";
import TableSummaryRow from "../../../components/TableSummaryRow";
import Money, { parseMoney } from "../../../components/Money";
import Forbidden from "../../../components/Forbidden";
import useFormatPercentage from "../../../hooks/useFormatPercentage";
import { useUserCan } from "hooks/useHasAnyPermissions";

function LeasesTable({
    client,
    clientPath,
    showDisabled,
    showAddModal,
    setShowAddModal,
    updateRecordCount,
}) {
    const auth = useContext(AuthContext);
    const userType = auth.user.type;
    const { addToast } = useToasts();
    const formatPercentage = useFormatPercentage();
    const [loading, setLoading] = useState(true);
    const [Leases, setLeases] = useState([]);
    const [orderBy, setOrderBy] = useState("as_of_date");
    const [sortDir, setSortDir] = useState("desc");
    const [search, setSearch] = useState("");
    const debouncedSearch = useDebounce(search, 250);
    const [notAuthorized, setNotAuthorized] = useState(false);
    const [needsUpdated, setNeedsUpdated] = useState(0);
    const [page, setPage, meta, setMeta] = usePagination();
    const canCreateWealthRecords = useUserCan("createWealthRecord");

    const [selectedId, setSelectedId] = useState(-1);

    useEffect(() => {
        document.title = "Other Leases";
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            try {
                let response = await Api.get(
                    "clients/" + client.id + "/leases",
                    {
                        limit: 20,
                        page: page,
                        sort: sortDir === "desc" ? "-" + orderBy : orderBy,
                        "filter[search]": debouncedSearch,
                    }
                );
                setMeta(response.data.meta);
                setLeases(response.data.data);
                updateRecordCount(response.data.data.length);
                setLoading(false);
            } catch (error) {
                if (error.response.status === 403) {
                    setNotAuthorized(true);
                }
            }
        };
        fetchData();
    }, [
        client,
        showDisabled,
        updateRecordCount,
        debouncedSearch,
        orderBy,
        sortDir,
        needsUpdated,
        page,
        setMeta,
    ]);

    async function deleteLease(lease) {
        try {
            const response = await Api.delete(
                "clients/" + client.id + "/leases/" + lease.id
            );
            addToast(response.data.message);
            setNeedsUpdated(needsUpdated + 1);
        } catch (e) {
            addToast(e.errorMessage, { type: "error" });
        }
    }

    if (notAuthorized) {
        return <Forbidden />;
    }

    if (loading) {
        return <Spinner />;
    }

    return (
        <>
            <div className="mb-4">
                <SearchInput
                    label={false}
                    searchValue={search}
                    onSearchChange={(e) => setSearch(e.target.value)}
                />
            </div>
            {Leases.length ? (
                <>
                    <table className="w-full text-left">
                        <thead>
                            <tr>
                                <TableSortHeader
                                    field="description"
                                    sortField={orderBy}
                                    sortDir={sortDir}
                                    changeSort={(column) => {
                                        setSortDir(
                                            sortDir === "asc" ? "desc" : "asc"
                                        );
                                        setOrderBy(column);
                                    }}
                                >
                                    Description
                                </TableSortHeader>
                                <TableSortHeader
                                    field="lease_type_id"
                                    sortField={orderBy}
                                    sortDir={sortDir}
                                    changeSort={(column) => {
                                        setSortDir(
                                            sortDir === "asc" ? "desc" : "asc"
                                        );
                                        setOrderBy(column);
                                    }}
                                >
                                    Type
                                </TableSortHeader>
                                <th className="text-gray-800">Lessee</th>
                                <th className="text-gray-800">Lessor</th>
                                <TableSortHeader
                                    disabled={true}
                                    field="percentage_owned"
                                    sortField={orderBy}
                                    sortDir={sortDir}
                                    changeSort={(column) => {
                                        setSortDir(
                                            sortDir === "asc" ? "desc" : "asc"
                                        );
                                        setOrderBy(column);
                                    }}
                                >
                                    Percentage Owned
                                </TableSortHeader>
                                <TableSortHeader
                                    field="rent_amount"
                                    sortField={orderBy}
                                    sortDir={sortDir}
                                    changeSort={(column) => {
                                        setSortDir(
                                            sortDir === "asc" ? "desc" : "asc"
                                        );
                                        setOrderBy(column);
                                    }}
                                >
                                    Rent Amount
                                </TableSortHeader>
                                <TableSortHeader field="value" disabled={true}>
                                    Frequency
                                </TableSortHeader>
                                <TableSortHeader
                                    field="as_of_date"
                                    sortField={orderBy}
                                    sortDir={sortDir}
                                    changeSort={(column) => {
                                        setSortDir(
                                            sortDir === "asc" ? "desc" : "asc"
                                        );
                                        setOrderBy(column);
                                    }}
                                >
                                    As Of Date
                                </TableSortHeader>
                                <th className="text-right">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {Leases.map((lease, idx) => (
                                <tr
                                    key={idx}
                                    className={lease.deleted_at ? "" : ""}
                                >
                                    <td>
                                        <Link
                                            to={`${clientPath}/leases/${lease.id}/view`}
                                        >{`${lease.description}`}</Link>
                                    </td>
                                    <td>
                                        {lease.lease_type_id === "Other" &&
                                        lease.lease_type_id
                                            ? lease.lease_type_name
                                            : lease.lease_type_id}
                                    </td>
                                    <td>{lease.lessee_name}</td>
                                    <td>{lease.owner_name}</td>
                                    <td>
                                        {formatPercentage(
                                            lease.percentage_owned
                                        )}
                                    </td>
                                    <td>
                                        <Money value={lease.rent_amount} />
                                    </td>
                                    <td>{lease.rent_frequency}</td>
                                    <td>{formatDate(lease.as_of_date)}</td>
                                    <td className="text-right">
                                        <span>
                                            <Dropdown
                                                position="right"
                                                toggleClass="px-3 py-1"
                                                toggleContent={
                                                    <FontAwesomeIcon
                                                        icon={faEllipsisH}
                                                    />
                                                }
                                            >
                                                <DropdownItem
                                                    label="Edit"
                                                    icon={faEdit}
                                                    link={`${clientPath}/leases/${lease.id}/edit`}
                                                />
                                                {(userType === "member" ||
                                                    userType === "client") && (
                                                    <DropdownItem
                                                        label="Delete"
                                                        icon={faTrash}
                                                        handleClick={() =>
                                                            deleteLease(lease)
                                                        }
                                                    />
                                                )}
                                            </Dropdown>
                                        </span>
                                    </td>
                                </tr>
                            ))}
                            <TableSummaryRow
                                columns={[
                                    "",
                                    "",
                                    "",
                                    "",
                                    "",
                                    Leases.map((p) =>
                                        parseMoney(p.rent_amount)
                                    ).reduce((a, c) => a + c),
                                    "",
                                    "",
                                ]}
                            />
                        </tbody>
                    </table>
                    <div className="my-10">
                        <Pagination {...meta} nextPage={setPage} />
                    </div>
                </>
            ) : (
                <div className="text-gray-400">
                    The are no leases for this client.{" "}
                    {canCreateWealthRecords && (
                        <Link
                            to={`${clientPath}/leases/add`}
                            className="underline text-gray-600"
                        >
                            Add Now.
                        </Link>
                    )}
                </div>
            )}
            <ModalModelFactory
                isOpen={showAddModal}
                onClose={() => {
                    setShowAddModal(false);
                    setSelectedId(-1);
                }}
                title="Add Lease"
                client={client}
                clientPath={clientPath}
                onSuccess={() => {
                    setNeedsUpdated(needsUpdated + 1);
                    setShowAddModal(false);
                    setSelectedId(-1);
                }}
                modelType="Lease"
                id={selectedId}
            />
        </>
    );
}

export default LeasesTable;
