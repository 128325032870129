import React, { useEffect, useState } from "react";
import { Route, Switch } from "react-router-dom";
import { lazy } from "react";
import DashboardLayout from "../layouts/DashboardLayout";
import useAuth from "hooks/useAuth";
import MessageTemplates from "./message-templates/MessageTemplates";
import { OrganizationBilling } from "./OrganizationBilling";
import ExportOrganizationData from "./ExportOrganizationData";
import Api from "services/Api";
import Spinner from "components/Spinner";
import Forbidden from "components/Forbidden";
import useHasPermission from "hooks/usePermissions";
const EditOrganization = lazy(() => import("./EditOrganization"));
const EditOrganizationDefaultPermissions = lazy(() =>
    import("./EditOrganizationDefaultPermissions")
);

export default function Organization({ ...props }) {
    const { user } = useAuth();
    const [isLoading, setIsLoading] = useState(true);
    const [organization, setOrganization] = useState(false);
    const [, setNotAuthorized] = useState(false);

    const doesNotHaveFirmInfoAccess = !useHasPermission(
        "firm_information_access"
    );

    async function fetchOrganization() {
        try {
            setIsLoading(true);
            const response = await Api.get("organizations");
            setOrganization(response.data.data);
            setNotAuthorized(false);
            setIsLoading(false);
        } catch (error) {
            if (error.response.status === 403) {
                setIsLoading(false);
                setNotAuthorized(true);
            }
        }
    }

    useEffect(() => {
        fetchOrganization();
    }, []);

    if (isLoading) {
        return <Spinner />;
    }

    if (doesNotHaveFirmInfoAccess) {
        return (
            <DashboardLayout {...props}>
                <Forbidden />
            </DashboardLayout>
        );
    }

    return (
        <DashboardLayout {...props}>
            <Switch>
                <Route
                    exact
                    path="/organization/details"
                    render={(props) => (
                        <EditOrganization
                            {...props}
                            organization={organization}
                            member={user}
                            update={() => fetchOrganization()}
                        />
                    )}
                />
                <Route
                    exact
                    path="/organization/default-permissions"
                    render={(props) => (
                        <EditOrganizationDefaultPermissions
                            {...props}
                            organization={organization}
                            member={user}
                            update={() => this.fetchMember()}
                        />
                    )}
                />
                <Route
                    path="/organization/message-templates"
                    render={(props) => (
                        <MessageTemplates
                            {...props}
                            organization={organization}
                            update={() => this.fetchMember()}
                        />
                    )}
                />
                <Route
                    exact
                    path="/organization/export"
                    render={(props) => (
                        <ExportOrganizationData
                            {...props}
                            organization={organization}
                            member={user}
                            update={() => this.fetchMember()}
                        />
                    )}
                />
                <Route
                    path="/organization/billing"
                    render={(props) => (
                        <OrganizationBilling
                            {...props}
                            organization={organization}
                            member={user}
                            update={() => this.fetchMember()}
                        />
                    )}
                />
            </Switch>
        </DashboardLayout>
    );
}
