import React, { useState, useEffect } from "react";
import BasicModal from "../ModalBasic";
import TextInput from "../TextInput";
import Button from "../Button";
import { formatDateForInput, titleCase } from "../../util/TextUtil";
import Money from "../Money";
import { formatDate } from "../../util/TextUtil";
import { BeneficiaryLineItem } from "components/Beneficiary/BeneficiaryList";
import useAssetValues from "views/funding_table/hooks/useAssetValues";
import useBeneficiaryTransferSave from "hooks/useBeneficiaryTransferSave";
import useForm from "hooks/useForm";
import usePostMortemState from "hooks/usePostMortemState";

export default function ManageBeneficiaryTransfer({
    client,
    asset = {},
    beneficiaries,
    isOpen,
    onClose,
    closeModal,
}) {
    const [title, setTitle] = useState(null);

    const { person } = usePostMortemState();

    const { input, updateValue, clearUnsavedChanges, resetInput } = useForm({
        person,
        claims_form_sent_at:
            beneficiaries?.length > 0
                ? beneficiaries[0].claims_form_sent_at
                : null,
        transfer_verified_at:
            beneficiaries?.length > 0
                ? beneficiaries[0].transfer_verified_at
                : null,
    });

    const {
        onSubmit: saveTransfer,
        isLoading: saving,
        validationErrors: errors,
    } = useBeneficiaryTransferSave(
        { clientId: client.id },
        {
            onSuccess() {
                clearUnsavedChanges();
                closeModal();
            },
        }
    );

    function resetOnClose() {
        clearUnsavedChanges();
        resetInput();
        onClose();
    }

    useEffect(() => {
        if (isOpen) {
            clearUnsavedChanges();
            resetInput();
        }
    }, [isOpen]);

    useEffect(() => {
        setTitle(asset?.name);
    }, [asset?.id, asset?.type, asset?.name, asset?.ownership, client]);

    async function save(e) {
        if (input.transfer_verified_at) {
            if (
                !confirm(
                    "Are you sure you want to verify and complete the transfer?"
                )
            ) {
                resetOnClose();
                return false;
            }
        }

        saveTransfer(e, {
            resource_type: asset.type,
            resource_id: asset.id,
            ...input,
        });
    }

    const {
        asOfDate,
        asOfDateLabel,
        value: grossValue,
        valueLabel,
    } = useAssetValues({
        asset,
    });

    if (!asset || !asset?.id || !beneficiaries || !beneficiaries?.length) {
        return <></>;
    }

    const personName =
        person === "spouse" ? client.spouse.full_name : client.full_name;

    return (
        <BasicModal
            isOpen={isOpen}
            size="3xl"
            onClose={() => {
                resetOnClose();
                onClose && onClose();
            }}
            header={
                <div className="sticky top-0 z-10 flex items-center justify-between border-b border-gray-300 px-6 py-3 bg-white">
                    <div className="flex items-center">
                        <div className="flex-none mr-10 w-72">
                            <div className={`text-xs text-gray-500`}>
                                {titleCase(asset?.type || "", {
                                    pascal: true,
                                })}
                            </div>
                            <h2 className="text-sm">
                                <span className="font-bold text-base">
                                    {title}
                                </span>
                                {asset?.account_number && (
                                    <span className="text-xs text-gray-500 block -mb-1">
                                        {asset?.account_number}
                                    </span>
                                )}
                            </h2>
                        </div>
                        <div className="flex-none text-left">
                            <div className={`text-xs text-gray-500`}>
                                {valueLabel}
                            </div>
                            <div className="text-sm tabular-nums">
                                {<Money value={grossValue} />}
                            </div>
                        </div>

                        <div className="ml-10 flex-none text-left">
                            <div className={`text-xs text-gray-500`}>
                                {asOfDateLabel}
                            </div>
                            <div className="text-sm">
                                {formatDate(asOfDate)}
                            </div>
                        </div>
                    </div>

                    <div className="flex items-center space-x-2 w-32 ml-auto">
                        <Button
                            type="button"
                            appearance="light"
                            className="bg-white"
                            text="Cancel"
                            onClick={() => closeModal()}
                        />
                    </div>
                </div>
            }
        >
            <>
                <div className="mb-6">
                    <h2 className="mb-4 font-bold text-lg text-gray-800">
                        Owner
                    </h2>
                    <TextInput
                        name="persn_name"
                        value={personName}
                        disabled={true}
                    />
                </div>
                <div className="mb-6">
                    <h2 className="mb-4 font-bold text-lg text-gray-800">
                        Beneficiaries
                    </h2>
                    <div className="space-y-2">
                        {beneficiaries.map((beneficiary, idx) => (
                            <BeneficiaryLineItem
                                key={`beneficiary_${beneficiary.id}`}
                                beneficiary={beneficiary}
                                client={client}
                                showLevel={true}
                                showLabels={idx === 0}
                                disabled={true}
                                required={false}
                            />
                        ))}
                    </div>
                </div>
            </>

            <div className="flex items-center space-x-3 justify-end">
                <div className="w-44">
                    <TextInput
                        label={"Claims Form Sent"}
                        type="date"
                        required={false}
                        error={errors.claims_form_sent_at}
                        name="claims_form_sent_at"
                        value={formatDateForInput(input.claims_form_sent_at)}
                        onChange={updateValue}
                    />
                </div>
                <div className="w-44">
                    <TextInput
                        label={"Transfer Verified"}
                        type="date"
                        required={false}
                        disabled={!input.claims_form_sent_at}
                        error={errors.transfer_verified_at}
                        name="transfer_verified_at"
                        value={formatDateForInput(input.transfer_verified_at)}
                        onChange={updateValue}
                    />
                </div>
                <div className="w-40 mt-6">
                    <Button
                        onClick={save}
                        isLoading={saving}
                        disabled={false}
                        text="Save"
                    />
                </div>
            </div>
        </BasicModal>
    );
}
