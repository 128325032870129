import React from "react";
import SelectInput from "../../../components/SelectInput";
import TextInput from "../../../components/TextInput";
import FrequencySelect from "../../../components/FrequencySelect";
import Checkbox from "../../../components/Checkbox";
import useFormatCurrency from "../../../hooks/useFormatCurrency";
import PercentInput from "../../../components/PercentInput";
import ResourceSelect from "../../../components/ResourceSelect";
import { isBool } from "index";

const resourceTypes = [
    "Client",
    "Relationship",
    "Annuity",
    "Lease",
    "BusinessInterest",
];

const recipientResourceTypes = ["Client", "Relationship"];

const lifetimeAmountOptions = [
    { value: "", label: "Choose a death amount option", disabled: true },
    { value: 0, label: "0%" },
    { value: 25, label: "25%" },
    { value: 50, label: "50%" },
    { value: 75, label: "75%" },
    { value: 100, label: "100%" },
];
export default function IncomeSourcesForm({
    client,
    input = {},
    errors = {},
    readOnly = false,
    onSubmit,
    updateValue,
}) {
    const formatCurrency = useFormatCurrency();

    function updateSource(event) {
        let value = event.target.value;
        let parts = value.split(":");
        updateValue({ target: { name: "source_type", value: parts[0] } });
        updateValue({ target: { name: "source_id", value: parts[1] || 0 } });
        updateValue({ target: { name: "source", value: value } });
    }

    function updateRecipient(event) {
        let value = event.target.value;
        let parts = value.split(":");
        updateValue({
            target: { name: "lifetime_recipient_type", value: parts[0] },
        });
        updateValue({
            target: { name: "lifetime_recipient_id", value: parts[1] || 0 },
        });
        updateValue({ target: { name: "lifetime_recipient", value: value } });
    }

    function updateOwner(event) {
        let value = event.target.value;
        let parts = value.split(":");
        updateValue({ target: { name: "owner_type", value: parts[0] } });
        updateValue({ target: { name: "owner_id", value: parts[1] || 0 } });
        updateValue({ target: { name: "owner", value: value } });
    }

    const OwnerSelectOptions = () => {
        const options = [
            { value: "", label: "Choose an owner", disabled: true },
            { value: "Client:" + client.id, label: client.full_name },
        ];
        if (client.spouse) {
            options.push({
                value: `Spouse:${client.spouse.id}`,
                label: client.spouse.full_name + " (Spouse)",
            });
        }
        return options;
    };

    const LifetimeSelectOptions = () => {
        const options = [
            { label: "No", value: "no" },
            { label: "Lifetime", value: "lifetime" },
        ];

        if (input.type.toLowerCase() === "pension" && client.spouse) {
            options.push({ label: "Joint Lifetime", value: "joint-lifetime" });
        }
        return options;
    };

    function handleUpdateTaxable(event) {
        const isChecked = event.target.checked;
        const eventPayload = {
            target: {
                name: "taxable",
                value: !isChecked,
            },
        };
        updateValue(eventPayload);
    }

    return (
        <form onSubmit={onSubmit}>
            <div>
                <div className="mb-6">
                    <SelectInput
                        options={OwnerSelectOptions()}
                        value={input.owner}
                        disabled={readOnly}
                        name="owner"
                        error={errors.owner_type}
                        label="Recipient / Owner"
                        onChange={updateOwner}
                    />
                </div>

                <div className="mb-6">
                    <SelectInput
                        type="type"
                        name="type"
                        disabled={readOnly}
                        label="Income Source"
                        id="type"
                        value={input.type}
                        error={errors.type}
                        onChange={updateValue}
                        required={true}
                        defaultOption={"Select an income source"}
                        defaultOptionDisabled={true}
                        options={[
                            "Inheritance",
                            "Royalty",
                            "Pension",
                            "Disability",
                            "Social Security",
                            "Salary Continuation",
                            { value: "OTH", label: "Other" },
                        ]}
                    />
                </div>

                {input.type === "Other" && (
                    <div className="mb-6">
                        <TextInput
                            name="other_income_description"
                            label="Other Income Source Description"
                            id="other_income_description"
                            readOnly={readOnly}
                            disabled={readOnly}
                            value={input.other_income_description}
                            error={errors.other_income_description}
                            onChange={updateValue}
                        />
                        <hr className="mt-6" />
                    </div>
                )}

                <div className="mb-6">
                    <SelectInput
                        options={LifetimeSelectOptions()}
                        disabled={readOnly}
                        id="lifetime"
                        value={input.lifetime}
                        name="lifetime"
                        label="Lifetime"
                        error={errors.lifetime}
                        onChange={updateValue}
                    />
                </div>

                {["joint-lifetime", "lifetime"].includes(input.lifetime) && (
                    <div className="mb-6 space-y-6">
                        <ResourceSelect
                            name="lifetime_recipient_id"
                            resourceType={recipientResourceTypes}
                            value={input.lifetime_recipient}
                            label="Recipient on Death"
                            client={client}
                            disabled={readOnly}
                            onChange={updateRecipient}
                            emptyState={true}
                            emptyStateLabel="Not Applicable"
                        />
                        <SelectInput
                            options={lifetimeAmountOptions}
                            disabled={readOnly}
                            id="lifetime_amount"
                            value={input.lifetime_amount}
                            name="lifetime_amount"
                            label="Amount"
                            error={errors.lifetime_amount}
                            onChange={updateValue}
                        />
                    </div>
                )}

                <div className="mb-6">
                    <ResourceSelect
                        name="source"
                        resourceType={resourceTypes}
                        value={input.source}
                        label="Where/Who is Income From?"
                        client={client}
                        disabled={readOnly}
                        onChange={updateSource}
                        emptyState={true}
                        emptyStateLabel="Not Applicable"
                    />
                </div>

                <div className="flex space-x-4 mb-6">
                    <div className="flex-1">
                        <TextInput
                            name="amount"
                            label="Payment Amount"
                            id="amount"
                            readOnly={readOnly}
                            disabled={readOnly}
                            placeholder="0.00"
                            value={input.amount}
                            error={errors.amount}
                            onChange={updateValue}
                            onBlur={(e) => {
                                e.target.value = formatCurrency(e.target.value);
                                updateValue(e);
                            }}
                            required={true}
                        />
                    </div>

                    <div className="flex-1">
                        <FrequencySelect
                            value={input.frequency_id}
                            name="frequency_id"
                            label="Payment Frequency"
                            disabled={readOnly}
                            onChange={updateValue}
                            required={true}
                        />
                    </div>

                    <div className="flex-1">
                        <PercentInput
                            readOnly={readOnly}
                            disabled={readOnly}
                            name="growth_rate"
                            label="Growth Rate %"
                            id="growth_rate"
                            placeholder="0 - 100"
                            value={input.growth_rate}
                            error={errors.growth_rate}
                            onChange={updateValue}
                        />
                    </div>
                </div>

                <div className="mb-6">
                    <TextInput
                        name="tax_basis"
                        label="Tax Basis"
                        id="tax_basis"
                        readOnly={readOnly}
                        disabled={readOnly}
                        placeholder="0.00"
                        value={input.tax_basis}
                        error={errors.tax_basis}
                        onChange={updateValue}
                        onBlur={(e) => {
                            e.target.value = formatCurrency(e.target.value);
                            updateValue(e);
                        }}
                    />
                </div>

                <div className="mb-6">
                    <TextInput
                        type="date"
                        name="start_date"
                        label="Payment Start Date"
                        readOnly={readOnly}
                        disabled={readOnly}
                        id="start_date"
                        placeholder="0.00"
                        value={input.start_date}
                        error={errors.start_date}
                        onChange={updateValue}
                    />
                </div>

                {!input.lifetime.includes("lifetime") && (
                    <div className="mb-6">
                        <TextInput
                            type="date"
                            name="end_date"
                            label="Payment End Date"
                            readOnly={readOnly}
                            disabled={readOnly}
                            id="end_date"
                            placeholder="0.00"
                            value={input.end_date}
                            error={errors.end_date}
                            onChange={updateValue}
                        />
                    </div>
                )}

                <Checkbox
                    id="taxable"
                    name="taxable"
                    defaultChecked={!isBool(input.taxable)}
                    disabled={readOnly}
                    onChange={handleUpdateTaxable}
                    label="Non-Taxable"
                />
                <input type="submit" hidden />
            </div>
        </form>
    );
}
