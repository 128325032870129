import React, { useState, useEffect } from "react";
import Api from "../../../services/Api";
import Dropdown, { DropdownItem } from "../../../components/Dropdown";
import { Link } from "react-router-dom";
import Spinner from "../../../components/Spinner";
import TableSortHeader from "../../../components/TableSortHeader";
import SearchInput from "../../../components/SearchInput";
import useDebounce from "../../../hooks/useDebounce";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Pagination from "../../../components/Pagination";
import usePagination from "../../../hooks/usePagination";
import {
    faTrash,
    faEdit,
    faEllipsisH,
} from "@fortawesome/free-solid-svg-icons";
import Forbidden from "../../../components/Forbidden";

export default function EstatePlanningRoleTable({
    user,
    client,
    addRecord,
    editRecord,
    showDisabled,
    needsUpdated,
    destroyRecord,
    orderBy,
    setOrderBy,
    sortDir,
    setSortDir,
}) {
    const [search, setSearch] = useState("");
    const [loading, setLoading] = useState(true);
    const debouncedSearch = useDebounce(search, 250);
    const [notAuthorized, setNotAuthorized] = useState(false);
    const [estatePlaningRoles, setEstatePlaningRoles] = useState([]);
    const [page, setPage, meta, setMeta] = usePagination();

    useEffect(() => {
        document.title = "Estate Planing Roles";
    }, []);

    useEffect(() => {
        const baseUrl = "clients/" + client.id + "/estate-planning-roles";
        const fetchData = async () => {
            try {
                let response = await Api.get(baseUrl, {
                    limit: 50,
                    page: page,
                    sort: sortDir === "desc" ? "-" + orderBy : orderBy,
                    "filter[search]": debouncedSearch,
                });
                setMeta(response.data.meta);
                setEstatePlaningRoles(response.data.data);
                setLoading(false);
            } catch (error) {
                if (error.response.status === 403) {
                    setNotAuthorized(true);
                }
            }
        };
        fetchData();
    }, [
        client,
        showDisabled,
        debouncedSearch,
        orderBy,
        sortDir,
        needsUpdated,
        page,
        setMeta,
    ]);

    if (notAuthorized) {
        return <Forbidden />;
    }

    if (loading) {
        return <Spinner />;
    }

    return (
        <>
            <div className="mb-4">
                <SearchInput
                    label={false}
                    searchValue={search}
                    onSearchChange={(e) => setSearch(e.target.value)}
                    placeholder="Search by name"
                />
            </div>
            {estatePlaningRoles.length ? (
                <>
                    <table className="w-full text-left">
                        <thead>
                            <tr>
                                <TableSortHeader
                                    field="first_name"
                                    sortField={orderBy}
                                    sortDir={sortDir}
                                    changeSort={(column) => {
                                        setSortDir(
                                            sortDir === "asc" ? "desc" : "asc"
                                        );
                                        setOrderBy(column);
                                    }}
                                >
                                    Name
                                </TableSortHeader>
                                <TableSortHeader
                                    field="role_id"
                                    sortField={orderBy}
                                    sortDir={sortDir}
                                    changeSort={(column) => {
                                        setSortDir(
                                            sortDir === "asc" ? "desc" : "asc"
                                        );
                                        setOrderBy(column);
                                    }}
                                >
                                    Role
                                </TableSortHeader>
                                <TableSortHeader
                                    disabled={true}
                                    field="on_behalf_of_description"
                                    sortField={orderBy}
                                    sortDir={sortDir}
                                    changeSort={(column) => {
                                        setSortDir(
                                            sortDir === "asc" ? "desc" : "asc"
                                        );
                                        setOrderBy(column);
                                    }}
                                >
                                    For Whom
                                </TableSortHeader>
                                <TableSortHeader
                                    field="role_level"
                                    sortField={orderBy}
                                    sortDir={sortDir}
                                    changeSort={(column) => {
                                        setSortDir(
                                            sortDir === "asc" ? "desc" : "asc"
                                        );
                                        setOrderBy(column);
                                    }}
                                >
                                    Order
                                </TableSortHeader>

                                <th className="text-right">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {estatePlaningRoles.map((item, idx) => (
                                <tr key={idx}>
                                    <td>
                                        <button
                                            type="button"
                                            onClick={() => editRecord(item)}
                                        >
                                            {item.full_name}
                                        </button>
                                    </td>
                                    <td>{item.role}</td>
                                    <td>{item.on_behalf_of_description}</td>
                                    <td>{item.role_level_description}</td>
                                    <td className="text-right">
                                        <span>
                                            <Dropdown
                                                position="right"
                                                toggleClass="px-3 py-1"
                                                toggleContent={
                                                    <FontAwesomeIcon
                                                        icon={faEllipsisH}
                                                    />
                                                }
                                            >
                                                <DropdownItem
                                                    label="Edit"
                                                    icon={faEdit}
                                                    handleClick={() =>
                                                        editRecord(item)
                                                    }
                                                />
                                                {(user.type === "member" ||
                                                    user.type === "client") && (
                                                    <DropdownItem
                                                        label="Delete"
                                                        icon={faTrash}
                                                        handleClick={() =>
                                                            destroyRecord(item)
                                                        }
                                                    />
                                                )}
                                            </Dropdown>
                                        </span>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <div className="my-10">
                        <Pagination {...meta} nextPage={setPage} />
                    </div>
                </>
            ) : (
                <div className="text-gray-400">
                    The are no estate planning roles found for this client.{" "}
                    <Link
                        to={"#"}
                        onClick={addRecord}
                        className="underline text-gray-600"
                    >
                        Add Now.
                    </Link>
                </div>
            )}
        </>
    );
}
