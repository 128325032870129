import useAuth from "hooks/useAuth";
import React from "react";
import { Link } from "react-router-dom";

const inactiveClass =
    "group flex items-center px-3 py-2 text-sm leading-5 font-medium text-gray-600 rounded-md hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:bg-gray-100 transition ease-in-out duration-150";
const activeClass =
    "group flex items-center px-3 py-2 text-sm leading-5 font-medium text-gray-900 rounded-md bg-gray-200 hover:text-gray-900 hover:bg-gray-200 focus:outline-none focus:bg-gray-200 transition ease-in-out duration-150";

export default function AccountNav({ active }) {
    const { accounts, user } = useAuth();
    const isClient = user.role === "client_owner";
    const sideNavItem = (to, label, active) => {
        return (
            <Link
                to={to}
                className={active.includes(to) ? activeClass : inactiveClass}
            >
                <span className="truncate">{label}</span>
            </Link>
        );
    };

    return (
        <>
            <nav className="w-56">
                {sideNavItem("/account/profile", "Profile", active)}
                {sideNavItem("/account/password", "Change Password", active)}
                {sideNavItem(
                    "/account/two-factor-auth",
                    "Two-Factor Authentication",
                    active
                )}
                {accounts && accounts.length > 1 && (
                    <>
                        {sideNavItem(
                            "/account/switch",
                            "Switch Accounts",
                            active
                        )}
                    </>
                )}
            </nav>
            {isClient && (
                <div className="mt-6">
                    {user.estate_planning_roles?.length > 0 && (
                        <div>
                            <h2 className="border-b font-bold text-gray-700 text-sm">
                                Estate Planning Roles
                            </h2>
                            <div className="flex flex-col space-y-3 divide-y divide-gray-200">
                                {user.estate_planning_roles.map((item, idx) => (
                                    <dl key={idx} className="text-sm pt-3">
                                        <div className="flex space-x-1 items-center">
                                            <dt className="text-gray-600">
                                                Role:
                                            </dt>
                                            <dd>{item.role_description}</dd>
                                        </div>
                                        <div className="flex space-x-1 items-center">
                                            <dt className="text-gray-600">
                                                For Whom:
                                            </dt>
                                            <dd>
                                                {item.on_behalf_of_description}
                                            </dd>
                                        </div>
                                        <div className="flex space-x-1 items-center">
                                            <dt className="text-gray-600">
                                                Level:
                                            </dt>
                                            <dd>
                                                {item.role_level_description}
                                            </dd>
                                        </div>
                                    </dl>
                                ))}
                            </div>
                        </div>
                    )}
                </div>
            )}
        </>
    );
}
