import Api from "../../services/Api";
import BasicModal, { BasicModalHeader } from "../../components/ModalBasic";
import { useToasts } from "react-toast-notifications";
import NonMortgageLiabilityForm from "./_partials/NonMortgageLiabilityForm";
import React, { useState } from "react";
import { formatDateForInput } from "../../util/TextUtil";
import useForm from "hooks/useForm";

export default function NonMortgageLiabilityModal({
    isOpen,
    title,
    client,
    onClose,
    onSuccess,
    parentResource,
}) {
    const { addToast } = useToasts();
    const [errors, setErrors] = useState([]);
    const { input, updateValue, resetInput, clearUnsavedChanges } = useForm({
        name: "",
        lender_name: "",
        lender_account_number: "",
        lender_address: "",
        lender_city: "",
        lender_state: "",
        lender_postal_code: "",
        lender_phone: "",
        balance: "",
        balance_as_of_date: formatDateForInput(new Date()),
        initial_loan_amount: "",
        loan_date: "",
        interest_rate: "",
        monthly_payment: "",
        owner: "client:" + client.id,
        percentage_owned: "",
        other_owner: "",
        other_percentage_owned: "",
        other_owner_description: "",
    });

    async function save(e) {
        e.preventDefault();
        try {
            setErrors([]);
            const body = { ...input, parentResource: parentResource };
            let response = await Api.post(
                "clients/" + client.id + "/non-mortgage-liabilities",
                body
            );
            resetInput();
            addToast(response.data.message);
            setErrors([]);
            clearUnsavedChanges();
            onSuccess(response.data);
        } catch (e) {
            setErrors([]);
            if (e.response.data.errors) {
                setErrors(e.response.data.errors);
            } else {
                addToast(e.response.data.message, { type: "error" });
            }
        }
    }

    return (
        <BasicModal
            isOpen={isOpen}
            size="w-full"
            onClose={onClose}
            header={
                <BasicModalHeader
                    onClose={onClose}
                    onSave={save}
                    title={title}
                />
            }
        >
            <div className="max-w-3xl mx-auto">
                <NonMortgageLiabilityForm
                    client={client}
                    input={input}
                    readOnly={false}
                    errors={errors}
                    onSubmit={save}
                    updateValue={updateValue}
                />
            </div>
        </BasicModal>
    );
}
