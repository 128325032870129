import React, { useState } from "react";
import Spinner from "components/Spinner";
import Button from "components/Button";

import Breadcrumbs from "components/Breadcrumbs";
import TableSortHeader from "components/TableSortHeader";
import EmptyTableRow from "components/EmptyTableRow";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFolder } from "@fortawesome/free-solid-svg-icons";
import { formatDateTime } from "util/TextUtil";
import useVaultFetch from "hooks/useVaultFetch";

export default function MoveFile({
    client,
    parentId: initialParentId = null,
    vaultId = null,
    showButtons = true,
    emptyMessage = "Move Here",
    onChange = () => {},
    isLoading,
    rootVaultPath,
    onResult,
}) {
    const [selectedVault, setSelectedVault] = useState(null);
    const [parentId, setParentId] = useState(initialParentId);

    const [sortField, setSortField] = useState("name");
    const [sortDir, setSortDir] = useState("asc");

    const enabled = true;

    const { data, isLoading: loading } = useVaultFetch(
        {
            clientId: client.id,
            ...(parentId ? { parentId } : {}),
        },
        {
            enabled,
            keepPreviousData: false,
            initialData: {
                vaultEntries: [],
                breadcrumbs: [],
            },
        }
    );

    const { data: vaultEntries, breadcrumbs } = data;

    const selectVaultEntry = (vaultEntry) => {
        if (selectedVault && vaultEntry && selectedVault.id === vaultEntry.id) {
            openVault(vaultEntry.id);
            return;
        }
        setSelectedVault(vaultEntry);
        if (onChange) onChange(vaultEntry ? vaultEntry.id : null);
    };

    const openVault = (vaultEntryId) => {
        setParentId(vaultEntryId);
    };

    const changeSort = React.useCallback(
        (fieldName) => {
            setSortField(fieldName);
            if (sortField === fieldName) {
                setSortDir(sortDir === "asc" ? "desc" : "asc");
            }
        },
        [sortField, sortDir]
    );

    const onNext = () => {
        const selected = selectedVault ? selectedVault : parentId;
        const id = selectedVault ? selected.id + "" : "";
        onResult({ result: id, parent: selected });
    };

    const onCancel = () => {
        onResult({ result: undefined });
    };

    const selectedStyle = `override border-2 border-blue-500 bg-blue-200`;
    const unselectedStyle = ``;

    const filteredEntries =
        vaultEntries
            ?.filter(
                (entry) =>
                    entry.type === "directory" &&
                    (!vaultId || entry.id !== vaultId)
            )
            .sort((a, b) =>
                sortDir === "asc"
                    ? a[sortField].localeCompare(b[sortField])
                    : b[sortField].localeCompare(a[sortField])
            ) ?? [];

    const moveToName = selectedVault
        ? selectedVault.name
        : parent
        ? parent.name
        : null;

    return (
        <div
            onClick={() => {
                selectVaultEntry(null);
                if (onChange) onChange(parentId);
            }}
        >
            {loading && (
                <div className="w-full h-full flex items-center justify-center">
                    <Spinner />
                </div>
            )}

            {!loading && (
                <>
                    <Breadcrumbs
                        defaultName={"Vault"}
                        breadcrumbs={breadcrumbs}
                        rootPath={rootVaultPath}
                        onClick={(newParent) => {
                            setParentId(newParent ? newParent.id : null);
                            if (onChange)
                                onChange(newParent ? newParent.id : null);
                        }}
                    />

                    <table className="w-full text-left">
                        <thead>
                            <tr>
                                <TableSortHeader
                                    field="name"
                                    sortField={sortField}
                                    sortDir={sortDir}
                                    changeSort={changeSort}
                                    allowSearchParams={false}
                                >
                                    Name
                                </TableSortHeader>
                                <TableSortHeader
                                    field="created_at"
                                    sortField={sortField}
                                    sortDir={sortDir}
                                    changeSort={changeSort}
                                    allowSearchParams={false}
                                >
                                    Date Created
                                </TableSortHeader>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredEntries.map((ref, idx) => (
                                <tr
                                    key={idx}
                                    className={`cursor-pointer ${
                                        selectedVault &&
                                        ref.id === selectedVault.id
                                            ? selectedStyle
                                            : unselectedStyle
                                    }`}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        selectVaultEntry(ref);
                                    }}
                                >
                                    <td>
                                        <>
                                            {ref.type === "directory" && (
                                                <FontAwesomeIcon
                                                    icon={faFolder}
                                                />
                                            )}
                                            &nbsp;&nbsp;
                                            {ref.name}
                                            {ref.ext ? "." + ref.ext : ""}
                                        </>
                                    </td>
                                    <td>
                                        {formatDateTime(ref.created_at, "N/A")}
                                    </td>
                                </tr>
                            ))}
                            {filteredEntries.length === 0 && (
                                <EmptyTableRow colSpan="2">
                                    {emptyMessage}
                                </EmptyTableRow>
                            )}
                        </tbody>
                    </table>

                    {showButtons && (
                        <div className="flex items-center justify-center mt-6">
                            <div className="mr-3 flex-1">
                                <Button
                                    onClick={() =>
                                        onCancel({ background: false })
                                    }
                                    text={"Cancel"}
                                    appearance="outline"
                                />
                            </div>
                            <div className="flex-1">
                                <Button
                                    isLoading={isLoading}
                                    onClick={() => onNext()}
                                    text={`${
                                        moveToName
                                            ? `Move to "${moveToName}"`
                                            : "Move Here"
                                    }`}
                                />
                            </div>
                        </div>
                    )}
                </>
            )}
        </div>
    );
}
