import Api from "../../services/Api";
import Button from "../../components/Button";
import { downloadFile } from "util/Downloader";
import { Route, Switch } from "react-router-dom";
import React, { useState, useReducer } from "react";
import { useToasts } from "react-toast-notifications";
import PageContainer from "../../components/PageContainer";
import EstatePlanningRoleModal from "./_partials/EstatePlanningRoleModal";
import EstatePlanningRoleTable from "./_partials/EstatePlanningRoleTable";
import ClientPageHeader, { Heading } from "../../components/ClientPageHeader";

export default function EstatePlanningRolesIndex({
    client,
    user,
    clientPath,
    relationships,
    ...props
}) {
    const { addToast } = useToasts();
    const [errors, setErrors] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [modalTitle, setModalTitle] = useState("");
    const [needsUpdated, setNeedsUpdated] = useState(0);
    const [isExporting, setIsExporting] = useState(false);
    const [orderBy, setOrderBy] = useState("role_level");
    const [sortDir, setSortDir] = useState("asc");

    const defaultInput = {
        id: null,
        relationship_id: "",
        is_client: false,
        role_id: 1,
        role_level: "primary",
        may_act_alone: 0,
        on_behalf_of_type: "",
        on_behalf_of_id: 0,
    };

    const [input, setInput] = useReducer(
        (state, newState) => ({ ...state, ...newState }),
        defaultInput
    );

    function updateValue(event) {
        if (
            event.target.name === "relationship_id" &&
            event.target.value === "client"
        ) {
            setInput({ is_client: true });
            setInput({ [event.target.name]: event.target.value });
        } else {
            setInput({ is_client: false });
            setInput({ [event.target.name]: event.target.value });
        }
    }

    async function editRecord(record) {
        let fetchedData = await fetch(record.id);
        setIsOpen(true);
        setModalTitle("Edit Estate Planning Role");
        document.title = "Edit Estate Planning Role";

        if (fetchedData) {
            document.title = `Edit Estate Planning Role for ${fetchedData.full_name}`;
        }
    }

    function addRecord() {
        setIsOpen(true);
        setModalTitle("Add Estate Planning Role");
        document.title = "Add Estate Planing Role";
    }

    function closeModal() {
        resetDefaultInput();
        setIsOpen(false);
        setModalTitle("");
        document.title = "Estate Planing Roles";
    }

    const baseUrl = "clients/" + client.id + "/estate-planning-roles";

    function handleError(error) {
        console.log(error);
        setErrors([]);
        if (error.response.data.errors) {
            setErrors(error.response.data.errors);
        } else {
            addToast(error.response.data.message, { type: "error" });
        }
    }

    function handleSuccess(response) {
        setNeedsUpdated(needsUpdated + 1);
        addToast(response.data.message);
    }

    async function fetch(id) {
        try {
            setLoading(true);
            let response = await Api.get(baseUrl + "/" + id);
            let newData = response.data.data;

            if (newData.is_client) {
                newData.relationship_id = "client";
            }
            setInput(newData);
            setLoading(false);
            return newData;
        } catch (error) {
            console.log(error);
        }
    }

    async function store() {
        try {
            let response = await Api.post(baseUrl, input);
            handleSuccess(response);
            closeModal();
            resetDefaultInput();
        } catch (error) {
            handleError(error);
        }
    }

    async function update(item) {
        try {
            let response = await Api.put(baseUrl + "/" + item.id, input);
            handleSuccess(response);
            closeModal();
            resetDefaultInput();
        } catch (error) {
            handleError(error);
        }
    }

    async function destroyRecord(item) {
        try {
            let response = await Api.delete(baseUrl + "/" + item.id);
            handleSuccess(response);
        } catch (error) {
            handleError(error);
        }
    }

    function handleSave() {
        return input.id ? update(input) : store();
    }

    function resetDefaultInput() {
        setInput(defaultInput);
        setErrors([]);
    }

    async function handlePrint(event) {
        event.preventDefault();
        const baseUrl = "clients/" + client.id + "/estate-planning-roles";
        try {
            setIsExporting(true);
            let response = await Api.get(
                baseUrl,
                {
                    export: true,
                    sort: sortDir === "desc" ? "-" + orderBy : orderBy,
                },
                { responseType: "arraybuffer" }
            );
            await downloadFile(response, "estate-planning-roles");
            setIsExporting(false);
            addToast("Questions downloaded");
        } catch (e) {
            setIsExporting(false);
            addToast(e.response.data.message, { type: "error" });
        }
    }

    return (
        <>
            <ClientPageHeader client={client}>
                <Heading>Estate Planning Roles</Heading>
                <div className="flex-0 flex space-x-2">
                    <Button
                        type="button"
                        text="Download"
                        appearance="light"
                        width="w-auto"
                        isLoading={isExporting}
                        onClick={(event) => handlePrint(event)}
                    />
                    <Button text="Add Role" onClick={addRecord} />
                </div>
            </ClientPageHeader>
            <PageContainer>
                <div className="flex space-x-8">
                    <div className="flex-1">
                        <Switch>
                            <Route
                                path={clientPath + "/estate-planning-roles"}
                                render={(newProps) => (
                                    <EstatePlanningRoleTable
                                        user={user}
                                        client={client}
                                        addRecord={addRecord}
                                        editRecord={editRecord}
                                        needsUpdated={needsUpdated}
                                        destroyRecord={destroyRecord}
                                        orderBy={orderBy}
                                        setOrderBy={setOrderBy}
                                        showDisabled={false}
                                        sortDir={sortDir}
                                        setSortDir={setSortDir}
                                        {...props}
                                        {...newProps}
                                    />
                                )}
                            />
                        </Switch>
                    </div>
                </div>
            </PageContainer>

            <EstatePlanningRoleModal
                size="3xl"
                input={input}
                errors={errors}
                client={client}
                isOpen={isOpen}
                readOnly={false}
                loading={loading}
                onSuccess={handleSave}
                title={modalTitle}
                onClose={closeModal}
                updateValue={updateValue}
                relationships={relationships}
            />
        </>
    );
}
