import React, { useEffect } from "react";

import { getProp, useQueryParam } from "../../Utility";
import LoginSplash from "./LoginSplash";
import useAuth from "../../hooks/useAuth";
import TwoFactorLoginForm from "./_partials/TwoFA";
import useLogin from "./useLogin";
import LoginForm from "./_partials/LoginForm";
import ChangePasswordForm from "./_partials/ChangePasswordForm";
import PoweredBy from "components/PoweredBy";

function Login({ ...props }) {
    const { settings } = useAuth();

    const {
        showVerifyOtp,
        authOptions,
        onVerify,
        errorMessage,
        loginInput,
        updateValue,
        login,
        isLoggingIn,
        errors,
        showChangePassword,
        recaptchaRef,
    } = useLogin();

    const orgRedirect = useQueryParam("organization_redirect");

    useEffect(() => {
        document.title = "Login";
    }, []);

    const message = getProp(props, "location.from.state.message");

    const showLogin = !showVerifyOtp && !showChangePassword;

    return (
        <div className="h-auto min-h-screen flex">
            <div className="relative bg-white text-gray-700 w-full flex-initial flex items-center justify-center md:max-w-xl">
                <div className="flex flex-col h-full max-w-lg w-full">
                    <div className="flex items-center justify-center flex-1">
                        {showVerifyOtp && (
                            <TwoFactorLoginForm
                                authOptions={authOptions}
                                onVerify={onVerify}
                            />
                        )}

                        {showChangePassword && (
                            <ChangePasswordForm onVerify={onVerify} />
                        )}
                        {showLogin && (
                            <div className="flex-none">
                                <LoginForm
                                    settings={settings}
                                    message={message}
                                    errorMessage={errorMessage}
                                    login={login}
                                    updateValue={updateValue}
                                    errors={errors}
                                    loginInput={loginInput}
                                    orgRedirect={orgRedirect}
                                    isLoggingIn={isLoggingIn}
                                    recaptchaRef={recaptchaRef}
                                />

                                <WelcomeMessage />
                            </div>
                        )}
                    </div>
                    {showLogin && <PoweredBy />}
                </div>
            </div>

            <LoginSplash />
        </div>
    );
}

export function WelcomeMessage() {
    const { settings } = useAuth();

    if (!settings) {
        return <></>;
    }

    if (settings?.custom_messages?.login_welcome_message.body) {
        return (
            <div
                className="text-sm mt-10 text-center max-w-sm"
                dangerouslySetInnerHTML={{
                    __html: settings?.custom_messages?.login_welcome_message
                        .body,
                }}
            ></div>
        );
    }
    if (settings.organization) {
        return (
            <div className="text-sm mt-10 text-center">
                For questions or support, please contact{" "}
                <a
                    href={settings.organization.website || "#"}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={`${
                        settings.organization.website
                            ? "text-brand hover:underline"
                            : ""
                    }`}
                >
                    {settings.organization.name}
                </a>
            </div>
        );
    }

    return <></>;
}

export default Login;
