import React from "react";
import ICEContacts from "./ICEContacts";
import ICEFiles from "./ICEFiles";
import ICEInfo from "./ICEInfo";
import ICEMedicalHistory from "./ICEMedicalHistory";
import ICESession from "./ICESession";

export default function ICEGuestAccess() {
    return (
        <div className="flex flex-col p-8 space-y-4">
            <ICESession />
            <ICEInfo />
            <ICEMedicalHistory />
            <ICEContacts />
            <ICEFiles />
        </div>
    );
}
