import React, { useEffect, useState } from "react";

import PageHeader from "../../../components/PageHeader";
import PageContainer from "../../../components/PageContainer";

import SaveButton from "../../../components/SaveButton";

import UnsavedChanges from "../../../components/UnsavedChanges";

import useOneMessageTemplate from "./useOneMessageTemplate";
import useHasPermission from "../../../hooks/usePermissions";
import MessageTemplateForm from "./MessageTemplateForm";
import { Link } from "react-router-dom";
import Button from "../../../components/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import OrganizationPageLayout from "../_partials/OrganizationPageLayout";

export default function EditMessageTemplate({ ...props }) {
    const notAuthorized = !useHasPermission("firm_information_access");

    const readOnly = false;

    const [messageId, setMessageId] = useState(
        props.match.params.messageTemplateId
    );

    const {
        isLoading,
        input,
        errors,
        updateValue,
        update,
        saving,
        reset,
        resetting,
        hasUnsavedChanges,
        sendTest,
        sending,
    } = useOneMessageTemplate(messageId);

    useEffect(() => {
        document.title = "Message Templates";
    }, []);

    const title =
        `Edit Message Templates` +
        (input && !isLoading ? ` - ${input.name}` : "");

    useEffect(() => {
        document.title = title;
    }, [title]);

    useEffect(() => {
        setMessageId(props.match.params.messageTemplateId);
    }, [props.match.params.messageTemplateId]);

    return (
        <div>
            <PageHeader text="Message Templates">
                <div className="flex-0">
                    <div className="flex space-x-4">
                        {input.id && (
                            <Button
                                onClick={reset}
                                text="Reset"
                                appearance="danger"
                                isLoading={resetting}
                            />
                        )}
                        {input.channel === "Email" && (
                            <Button
                                onClick={sendTest}
                                text="Send Test Email"
                                isLoading={sending}
                            />
                        )}
                        <SaveButton isLoading={saving} onClick={update} />
                    </div>
                </div>
            </PageHeader>
            <PageContainer forbidden={notAuthorized}>
                <UnsavedChanges when={hasUnsavedChanges} />
                <OrganizationPageLayout>
                    {!isLoading && (
                        <div className="flex flex-col flex-1">
                            <div className="mb-6">
                                <Link
                                    to={"/organization/message-templates"}
                                    className="flex items-center space-x-1 hover:text-gray-700"
                                >
                                    <span className="text-xs text-gray-300">
                                        <FontAwesomeIcon icon={faChevronLeft} />
                                    </span>
                                    <span className="text-gray-500 text-sm">
                                        All Message Templates
                                    </span>
                                </Link>
                            </div>
                            <MessageTemplateForm
                                input={input}
                                readOnly={readOnly}
                                errors={errors}
                                updateValue={updateValue}
                                onSubmit={update}
                            />
                        </div>
                    )}
                </OrganizationPageLayout>
            </PageContainer>
        </div>
    );
}
