import Api from "./Api";

export const fetchTransfers = async function ({ queryKey }) {
    const [, { ...params }] = queryKey;
    const { clientId, ...qs } = params;
    return await Api.get("clients/" + clientId + "/transfers", qs).then(
        (res) => res.data
    );
};

export const updateProposedTransfer = async function (
    { clientId, transferId },
    formData
) {
    return await Api.put(
        "clients/" + clientId + "/transfers/" + transferId,
        formData
    ).then((res) => res.data);
};

export const verifyTransfer = async function ({ clientId, id }, formData) {
    return await Api.post(
        "clients/" + clientId + "/transfers/" + id + "/verify",
        formData
    ).then((res) => res.data);
};

export const deleteTransfer = async function ({ clientId, id }, formData) {
    return await Api.delete(
        "clients/" + clientId + "/transfers/" + id,
        formData
    ).then((res) => res.data);
};
