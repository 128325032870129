import React from "react";
import { ICEGuestContext } from "./ICEGuestContext";

export default function useIceGuest() {
    const context = React.useContext(ICEGuestContext);
    if (context === undefined) {
        throw new Error(
            "useAuth must be used within a ICEGuestContextProvider"
        );
    }
    return { ...context };
}
