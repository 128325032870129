import React from "react";
import { Route, Switch } from "react-router-dom";
import AnnuityIndex from "./AnnuityIndex";
import AddAnnuity from "./AddAnnuity";
import EditAnnuity from "./EditAnnuity";

export default function Annuities(parentProps) {
    return (
        <Switch>
            <Route
                exact
                path={["/clients/:clientId/annuities", "/client/annuities"]}
                render={(props) => <AnnuityIndex {...parentProps} {...props} />}
            />
            <Route
                exact
                path={[
                    "/clients/:clientId/annuities/add",
                    "/client/annuities/add",
                ]}
                render={(props) => <AddAnnuity {...parentProps} {...props} />}
            />
            <Route
                path={[
                    "/clients/:clientId/annuities/:annuityId/edit",
                    "/client/annuities/:annuityId/edit",
                ]}
                render={(props) => <EditAnnuity {...parentProps} {...props} />}
            />
            <Route
                path={[
                    "/clients/:clientId/annuities/:annuityId/view",
                    "/client/annuities/:annuityId/view",
                ]}
                render={(props) => (
                    <EditAnnuity readOnly={true} {...parentProps} {...props} />
                )}
            />
        </Switch>
    );
}
