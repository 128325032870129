import React from "react";
import useFormatCurrency from "../../../hooks/useFormatCurrency";
import TextInput from "../../../components/TextInput";
import FrequencySelect from "../../../components/FrequencySelect";

export default function NotesReceivableForm({
    input = {},
    errors = {},
    readOnly = false,
    onSubmit,
    updateValue,
}) {
    const formatCurrency = useFormatCurrency();

    return (
        <form onSubmit={onSubmit}>
            <div>
                <div className="mb-6">
                    <TextInput
                        name="debtor_name"
                        label="Name of Debtor"
                        id="debtor_name"
                        readOnly={readOnly}
                        disabled={readOnly}
                        value={input.debtor_name}
                        error={errors.debtor_name}
                        onChange={updateValue}
                        required={true}
                    />
                </div>
                <div className="flex space-x-4 mb-6">
                    <div className="flex-1">
                        <TextInput
                            name="current_balance"
                            label="Current Balance Owed"
                            id="current_balance"
                            readOnly={readOnly}
                            disabled={readOnly}
                            value={input.current_balance}
                            error={errors.current_balance}
                            onChange={updateValue}
                            onBlur={(e) => {
                                e.target.value = formatCurrency(e.target.value);
                                updateValue(e);
                            }}
                            placeholder="0.00"
                            required={true}
                        />
                    </div>
                    <div className="flex-1">
                        <TextInput
                            type="date"
                            name="as_of_date"
                            label="As of Date"
                            id="as_of_date"
                            readOnly={readOnly}
                            disabled={readOnly}
                            value={input.as_of_date}
                            error={errors.as_of_date}
                            onChange={updateValue}
                            required={true}
                        />
                    </div>
                </div>
                <div className="flex space-x-4 mb-6">
                    <div className="flex-1">
                        <TextInput
                            name="initial_loan_amount"
                            label="Initial Loan Amount"
                            id="initial_loan_amount"
                            readOnly={readOnly}
                            disabled={readOnly}
                            value={input.initial_loan_amount}
                            error={errors.initial_loan_amount}
                            onChange={updateValue}
                            onBlur={(e) => {
                                e.target.value = formatCurrency(e.target.value);
                                updateValue(e);
                            }}
                            placeholder="0.00"
                        />
                    </div>
                    <div className="flex-1">
                        <TextInput
                            type="date"
                            name="date_of_note"
                            label="Date of Loan"
                            id="date_of_note"
                            readOnly={readOnly}
                            disabled={readOnly}
                            value={input.date_of_note}
                            error={errors.date_of_note}
                            onChange={updateValue}
                        />
                    </div>
                </div>
                <div className="flex space-x-4 mb-6">
                    <div className="flex-1">
                        <TextInput
                            name="payment_amount"
                            label="Payment Amount"
                            id="payment_amount"
                            readOnly={readOnly}
                            disabled={readOnly}
                            value={input.payment_amount}
                            error={errors.payment_amount}
                            onChange={updateValue}
                            onBlur={(e) => {
                                e.target.value = formatCurrency(e.target.value);
                                updateValue(e);
                            }}
                            placeholder="0.00"
                        />
                    </div>
                    <div className="flex-1">
                        <FrequencySelect
                            name="frequency_id"
                            label="Payment Frequency"
                            id="frequency_id"
                            disabled={readOnly}
                            value={input.frequency_id}
                            error={errors.frequency_id}
                            onChange={updateValue}
                        />
                    </div>
                </div>
                <div className="mb-6">
                    <TextInput
                        type="date"
                        name="due_date"
                        label="Due Date"
                        id="due_date"
                        readOnly={readOnly}
                        disabled={readOnly}
                        value={input.due_date}
                        error={errors.due_date}
                        onChange={updateValue}
                    />
                </div>
            </div>
        </form>
    );
}
