import React from "react";
import InsurancePoliciesLayout from "./InsurancePoliciesLayout";
import SaveButton from "components/SaveButton";
import AddressEntry from "components/AddressEntry";
import TextInput from "components/TextInput";

export default function EditInsurancePolicyContact({
    input,
    errors,
    currentBasePath,
    title,
    onSubmit,
    readOnly = false,
    updateValue,
    saving = false,
}) {
    return (
        <InsurancePoliciesLayout
            currentBasePath={currentBasePath}
            title={title}
            buttons={
                <SaveButton
                    onClick={onSubmit}
                    isLoading={saving}
                    readOnly={readOnly}
                />
            }
        >
            <form onSubmit={onSubmit}>
                <div className="mb-6">
                    <TextInput
                        name="company_name"
                        label="Company Name"
                        id="company_name"
                        disabled={readOnly}
                        readOnly={readOnly}
                        value={input.company_name}
                        error={errors.company_name}
                        onChange={updateValue}
                    />
                </div>
                <div className="mb-6">
                    <TextInput
                        name="contact_name"
                        label="Contact Name"
                        id="contact_name"
                        disabled={readOnly}
                        readOnly={readOnly}
                        value={input.contact_name}
                        error={errors.contact_name}
                        onChange={updateValue}
                    />
                </div>

                <div className="mb-6">
                    <TextInput
                        name="phone"
                        label="Phone"
                        id="phone"
                        disabled={readOnly}
                        readOnly={readOnly}
                        value={input.phone}
                        error={errors.phone}
                        onChange={updateValue}
                    />
                </div>
                <div className="mb-6">
                    <TextInput
                        name="email"
                        label="Email"
                        id="email"
                        disabled={readOnly}
                        readOnly={readOnly}
                        value={input.email}
                        error={errors.email}
                        onChange={updateValue}
                    />
                </div>

                <AddressEntry
                    input={input}
                    errors={errors}
                    updateValue={updateValue}
                    readOnly={readOnly}
                />

                <input type="submit" hidden />
            </form>
        </InsurancePoliciesLayout>
    );
}
