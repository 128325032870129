import React from "react";
import { Route, Switch } from "react-router-dom";
import ClientFormsIndex from "./ClientFormsIndex";
import LocationListForm from "./LocationListForm/locationListForm";
import FuneralPlanningForm from "./FuneralPlanningForm/FuneralPlanningForm";

export default function ClientForms(parentProps) {
    return (
        <Switch>
            <Route
                exact
                path={["/clients/:clientId/forms", "/client/forms"]}
                render={(props) => (
                    <ClientFormsIndex {...parentProps} {...props} />
                )}
            />
            <Route
                exact
                path={[
                    "/clients/:clientId/forms/location-list/edit",
                    "/client/forms/location-list/edit",
                ]}
                render={(props) => (
                    <LocationListForm {...parentProps} {...props} />
                )}
            />
            <Route
                exact
                path={[
                    "/clients/:clientId/forms/funeral-planning/edit",
                    "/clients/:clientId/forms/funeral-planning/edit/:spouse",
                    "/client/forms/funeral-planning/edit",
                    "/client/forms/funeral-planning/edit/:spouse",
                ]}
                render={(props) => (
                    <FuneralPlanningForm {...parentProps} {...props} />
                )}
            />
        </Switch>
    );
}
