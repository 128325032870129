import React from "react";
import ClientPageHeader, { Heading } from "../../components/ClientPageHeader";
import Button from "../../components/Button";
import NotesReceivableTable from "./_partials/NotesReceivableTable";
import PageContainer from "../../components/PageContainer";
import { Switch, Route } from "react-router-dom";
import { useUserCan } from "hooks/useHasAnyPermissions";

export default function NotesReceivableIndex({
    client,
    user,
    clientPath,
    ...props
}) {
    const canCreateWealthRecords = useUserCan("createWealthRecord");
    return (
        <>
            <ClientPageHeader client={client}>
                <Heading>Notes Receivable</Heading>
                {canCreateWealthRecords && (
                    <div className="flex-0">
                        <Button
                            type="link"
                            to={`${clientPath}/notes-receivables/add`}
                            text="Add Notes Receivable"
                        />
                    </div>
                )}
            </ClientPageHeader>
            <PageContainer>
                <div className="flex space-x-8">
                    <div className="flex-1">
                        <Switch>
                            <Route
                                path={clientPath + "/notes-receivables"}
                                render={(newProps) => (
                                    <NotesReceivableTable
                                        client={client}
                                        user={user}
                                        clientPath={clientPath}
                                        {...props}
                                        {...newProps}
                                    />
                                )}
                            />
                        </Switch>
                    </div>
                </div>
            </PageContainer>
        </>
    );
}
