import React from "react";
import TextInput from "./TextInput";
import { StateSelect } from "./SelectInput";
import CountrySelector from "./CountrySelector";

export default function AddressEntry({
    input,
    errors,
    updateValue,
    readOnly,
    fieldPrefix = "",
    labelPrefix = "",
}) {
    const prefixForField = React.useCallback(
        (field) => {
            if (!fieldPrefix) {
                return field;
            }
            return [fieldPrefix, field].join("_");
        },
        [fieldPrefix]
    );

    const labelForName = React.useCallback(
        (name) => {
            if (!labelPrefix) {
                return name;
            }
            return [labelPrefix, name].join(" ");
        },
        [labelPrefix]
    );

    return (
        <>
            <div className="mb-6">
                <TextInput
                    name={prefixForField("address")}
                    label={labelForName("Address")}
                    id={prefixForField("address")}
                    disabled={readOnly}
                    readOnly={readOnly}
                    value={input[prefixForField("address")]}
                    error={errors[prefixForField("address")]}
                    onChange={updateValue}
                />
            </div>
            <div className="mb-6">
                <TextInput
                    name={prefixForField("address_2")}
                    label={labelForName("Address 2")}
                    id={prefixForField("address_2")}
                    disabled={readOnly}
                    readOnly={readOnly}
                    value={input[prefixForField("address_2")]}
                    error={errors[prefixForField("address_2")]}
                    onChange={updateValue}
                />
            </div>
            <div className="flex space-x-3 mb-6">
                <div className="flex-1">
                    <TextInput
                        name={prefixForField("city")}
                        label={labelForName("City")}
                        id={prefixForField("city")}
                        disabled={readOnly}
                        readOnly={readOnly}
                        value={input[prefixForField("city")]}
                        error={errors[prefixForField("city")]}
                        onChange={updateValue}
                    />
                </div>
                <div className="flex-1">
                    <StateSelect
                        name={prefixForField("state")}
                        label={labelForName("State")}
                        id={prefixForField("state")}
                        disabled={readOnly}
                        readOnly={readOnly}
                        value={input[prefixForField("state")]}
                        error={errors[prefixForField("state")]}
                        onChange={updateValue}
                    />
                </div>
                <div className="flex-1">
                    <TextInput
                        name={prefixForField("postal_code")}
                        label={labelForName("Zip")}
                        id={prefixForField("postal_code")}
                        disabled={readOnly}
                        readOnly={readOnly}
                        value={input[prefixForField("postal_code")]}
                        error={errors[prefixForField("postal_code")]}
                        onChange={updateValue}
                    />
                </div>
            </div>
            <div>
                <CountrySelector
                    name={prefixForField("country")}
                    label="Country"
                    id={prefixForField("country")}
                    disabled={readOnly}
                    readOnly={readOnly}
                    value={input[prefixForField("country")]}
                    error={errors[prefixForField("country")]}
                    onChange={updateValue}
                />
            </div>
        </>
    );
}
