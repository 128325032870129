import React, { useState } from "react";
import { classNames } from "Utility";

export default function Image({
    src,
    alt,
    fallback = null,
    className,
    ...props
}) {
    const [showFallback, setShowFallback] = useState(true);

    const handleOnLoad = () => {
        setShowFallback(false);
    };

    const handleOnError = () => {
        setShowFallback(true);
    };

    return (
        <>
            {showFallback && fallback}
            <img
                className={classNames(
                    className,
                    showFallback ? "hidden" : "visible"
                )}
                src={src}
                alt={alt}
                onLoad={handleOnLoad}
                onError={handleOnError}
                {...props}
            />
        </>
    );
}
