import React, { useEffect, useState } from "react";
import Spinner from "../../components/Spinner";
import useClient from "../../hooks/useClient";
import Api from "../../services/Api";
import usePostMortemState from "hooks/usePostMortemState";
import usePostMortemFetch from "hooks/usePostMortemFetch";

const PostMortemContext = React.createContext();

export const POST_MORTEM_VIEWS = {
    NONE: "",
    CLIENT: "client",
    SPOUSE: "spouse",
};

export function clientDeceased(data) {
    return parseInt(data.is_client_deceased) === 1;
}

export function spouseDeceased(data) {
    return parseInt(data.is_spouse_deceased) === 1;
}

export function postMortemInActive(data) {
    return (
        parseInt(data.is_client_deceased) === 0 &&
        parseInt(data.is_spouse_deceased) === 0
    );
}

export default function PostMortemContextProvider({ children }) {
    const { client } = useClient();

    const { person, setPerson: setPersonState } = usePostMortemState();
    const [isActive, setIsActive] = useState(false);

    async function setPerson(person) {
        try {
            if (!person) {
                return;
            }
            await Api.put("clients/" + client.id + "/post-mortem", {
                active_person: person,
            });
            setPersonState(person);
        } catch (error) {
            console.log(error);
        }
    }

    const {
        data,
        isLoading: loading,
        remove,
        refetch,
        notFound,
    } = usePostMortemFetch(
        { client },
        {
            keepPreviousData: true,
            refetchOnWindowFocus: false,
            onSuccess: (data) => {
                setPersonState(data.active_person || "");
                setIsActive(!postMortemInActive(data));
            },
        }
    );

    const updatePerson = () => {
        if (notFound) {
            setPersonState("");
            return;
        }
        if (!data) {
            return;
        }
        if (data.active_person) {
            setPersonState(data.active_person);
            return;
        }

        if (spouseDeceased(data) && !clientDeceased(data)) {
            setPerson(POST_MORTEM_VIEWS.SPOUSE);
        }
        if (clientDeceased(data)) {
            setPerson(POST_MORTEM_VIEWS.CLIENT);
        }
        if (postMortemInActive(data)) {
            setPerson(POST_MORTEM_VIEWS.NONE);
        }
    };
    useEffect(() => {
        updatePerson();
    }, [data, notFound]);

    // Sometimes, the URL does not include a person param
    // when it should. This fixes that really weird issue that
    // Brian seems to continue to reproduce.
    useEffect(() => {
        if (!person) {
            updatePerson();
        }
    }, [person]);

    if (loading) {
        return (
            <div className="h-full flex items-center justify-center">
                <Spinner message={`Loading Estate Administration`} />
            </div>
        );
    }

    const reload = () => {
        remove();
        refetch();
    };

    return (
        <PostMortemContext.Provider
            value={{
                setPerson,
                POST_MORTEM_VIEWS,
                data,
                reload,
                notFound,
                isActive,
            }}
        >
            {children}
        </PostMortemContext.Provider>
    );
}

export function usePostMortemContext() {
    const context = React.useContext(PostMortemContext);
    if (context === undefined) {
        throw new Error(
            "usePostMortemContext must be used within a PostMortemContext"
        );
    }
    return { ...context };
}
