import useFetch from "./useFetch";
import { fetchEstateAdminClientNotes } from "../services/ClientNotesService";

export default function useClientNotesEstateAdminFetch(
    params,
    config = { keepPreviousData: true }
) {
    return useFetch(
        ["fetchEstateAdminClientNotes", params],
        fetchEstateAdminClientNotes,
        config
    );
}
