import useAuth from "hooks/useAuth";
import React from "react";
import VerticalTabs from "../../../components/VerticalTabs";

export default function RetirementAccountTabs({ basePath, ...props }) {
    const { user } = useAuth();

    let items = [
        {
            label: "Info",
            to: basePath + "",
        },
        {
            label: "Owners",
            to: basePath + "/owners",
        },
        {
            label: "Beneficiaries",
            to: basePath + "/beneficiaries",
        },
        {
            label: "Contact",
            to: basePath + "/contact",
        },
        {
            label: "Notes",
            to: basePath + "/notes",
        },
        {
            label: "Related Files",
            to: basePath + "/related-files",
        },
        {
            label: "Access",
            to: basePath + "/access",
        },
        {
            label: "Transfers",
            to: basePath + "/transfers",
        },
    ];

    if (user.type !== "client") {
        items.push({
            label: "Letters Of Instruction",
            to: basePath + "/forms",
        });
    }

    return <VerticalTabs {...props} items={items} />;
}
