import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faEllipsisV,
    faTrash,
    faPlus,
} from "@fortawesome/free-solid-svg-icons";

import EmptyTableRow from "../../components/EmptyTableRow";
import Spinner from "../../components/Spinner";
import Panel, { PanelHeader } from "../../components/Panel";
import Button from "../../components/Button";
import Dropdown, { DropdownItem } from "../../components/Dropdown";
import ModalRevokeAccess from "./ModalRevokeAccess";
import ModalAddAccess from "./ModalAddAccess";
import { formatDate } from "../../util/TextUtil";
import useResourceAccess from "hooks/useResourceAccess";
import ErrorBoundary from "util/ErrorBoundary";

export default function ResourceAccessUserList({ ...props }) {
    return (
        <ErrorBoundary>
            <ResourceAccessUserListWrapper
                resourceType={props.resourceType}
                resourceId={props.resourceId}
                operations={props.operations}
                viewOnly={props.viewOnly}
            />
        </ErrorBoundary>
    );
}

export function ResourceAccessUserListWrapper({
    resourceType,
    resourceId,
    operations = ["read", "write"],
    viewOnly = false,
    appearance = "flat",
    panelClass = "",
}) {
    const [showDelete, setShowDelete] = useState(false);
    const [showAdd, setShowAdd] = useState(false);
    const [selectedAccess, setSelectedAccess] = useState(null);

    const { data, isLoading, refetch } = useResourceAccess(
        resourceType,
        resourceId
    );

    const openDelete = (access) => {
        setShowDelete(true);
        setSelectedAccess(access);
    };

    const OptionsDropdown = ({ access }) => {
        return (
            <span className="block w-full">
                <Dropdown
                    position="right"
                    toggleContent={
                        <>
                            <div className="py-1 px-3 w-full block">
                                <FontAwesomeIcon icon={faEllipsisV} />
                            </div>
                        </>
                    }
                >
                    <DropdownItem
                        label="Revoke"
                        icon={faTrash}
                        handleClick={() => openDelete(access)}
                    />
                </Dropdown>
            </span>
        );
    };

    return (
        <>
            <Panel
                appearance={appearance}
                className={panelClass ?? ""}
                allowOverflow={true}
            >
                <PanelHeader title="Access List">
                    {!viewOnly && (
                        <Button
                            height={null}
                            base={false}
                            appearance={"link"}
                            onClick={() => setShowAdd(true)}
                        >
                            <FontAwesomeIcon
                                icon={faPlus}
                                className="text-sm text-brand-400"
                            />{" "}
                            Add Access
                        </Button>
                    )}
                </PanelHeader>
                <table className="w-full text-left table-fixed">
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Access Level</th>
                            <th>Date Granted</th>
                            <th>Granted By</th>
                            {!viewOnly && (
                                <th className="text-right">Actions</th>
                            )}
                        </tr>
                    </thead>
                    <tbody>
                        {data &&
                            data.map((access, idx) => (
                                <tr key={idx}>
                                    <td>{access.user_full_name}</td>
                                    <td>{access.access_level_display}</td>
                                    <td>{formatDate(access.created_at)}</td>
                                    <td>{access.grantor_full_name}</td>
                                    {!viewOnly && (
                                        <td className="text-right">
                                            <OptionsDropdown access={access} />
                                        </td>
                                    )}
                                </tr>
                            ))}
                        {!isLoading && data.length === 0 && (
                            <EmptyTableRow colSpan="5">
                                No Other Users Have Access
                            </EmptyTableRow>
                        )}
                    </tbody>
                </table>
                {isLoading && data.length === 0 && (
                    <div className="w-full h-full flex items-center justify-center">
                        <Spinner />
                    </div>
                )}

                <ModalRevokeAccess
                    access={selectedAccess}
                    show={showDelete}
                    setShow={setShowDelete}
                    onResult={() => {
                        refetch();
                    }}
                />

                <ModalAddAccess
                    resourceId={resourceId}
                    type={resourceType}
                    show={showAdd}
                    setShow={setShowAdd}
                    operations={operations}
                    onResult={() => {
                        refetch();
                    }}
                />
            </Panel>
        </>
    );
}
