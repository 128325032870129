import React, { useContext, useState, useEffect } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import SearchInput from "../../../components/SearchInput";
import { useToasts } from "react-toast-notifications";
import Spinner from "../../../components/Spinner";
import TableSortHeader from "../../../components/TableSortHeader";
import Api from "../../../services/Api";
import Dropdown, { DropdownItem } from "../../../components/Dropdown";
import useDebounce from "../../../hooks/useDebounce";
import { formatDate } from "../../../util/TextUtil";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TableSummaryRow from "../../../components/TableSummaryRow";
import { parseMoney } from "../../../components/Money";
import { Link } from "react-router-dom";
import {
    faTrash,
    faEdit,
    faEllipsisH,
} from "@fortawesome/free-solid-svg-icons";
import Forbidden from "../../../components/Forbidden";
import useFormatPercentage from "../../../hooks/useFormatPercentage";
import { useUserCan } from "hooks/useHasAnyPermissions";

export default function BusinessInterestTable({ client, clientPath }) {
    const auth = useContext(AuthContext);
    const userType = auth.user.type;
    const { addToast } = useToasts();
    const formatPercentage = useFormatPercentage();
    const canCreateWealthRecords = useUserCan("createWealthRecord");
    const [businessInterests, setBusinessInterests] = useState([]);
    const [loading, setLoading] = useState(true);
    const [notAuthorized, setNotAuthorized] = useState(false);
    const [needsUpdated, setNeedsUpdated] = useState(0);
    const [search, setSearch] = useState("");
    const debouncedSearch = useDebounce(search, 250);
    const [orderBy, setOrderBy] = useState("as_of_date");
    const [sortDir, setSortDir] = useState("desc");

    useEffect(() => {
        document.title = "Business Interests";
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            try {
                let response = await Api.get(
                    "clients/" + client.id + "/business-interests",
                    {
                        limit: 20,
                        sort: sortDir === "desc" ? "-" + orderBy : orderBy,
                        "filter[search]": debouncedSearch,
                    }
                );
                setBusinessInterests(response.data.data);
                setLoading(false);
            } catch (error) {
                if (error && error.response && error.response.status === 403) {
                    setNotAuthorized(true);
                }
                console.log(error);
                setLoading(false);
            }
        };
        fetchData();
    }, [client, needsUpdated, orderBy, sortDir, debouncedSearch]);

    async function deleteBusinessInterest(businessInterest) {
        try {
            const response = await Api.delete(
                "clients/" +
                    client.id +
                    "/business-interests/" +
                    businessInterest.id
            );
            addToast(response.data.message);
            setNeedsUpdated(needsUpdated + 1);
        } catch (error) {
            addToast(error.errorMessage, { type: "error" });
        }
    }

    if (notAuthorized) {
        return <Forbidden />;
    }

    if (loading) {
        return <Spinner />;
    }

    return (
        <>
            <div className="mb-4">
                <SearchInput
                    label={false}
                    searchValue={search}
                    onSearchChange={(e) => setSearch(e.target.value)}
                />
            </div>
            {businessInterests.length ? (
                <>
                    <table className="w-full text-left">
                        <thead>
                            <tr>
                                <TableSortHeader
                                    field="name"
                                    sortField={orderBy}
                                    sortDir={sortDir}
                                    changeSort={(column) => {
                                        setSortDir(
                                            sortDir === "asc" ? "desc" : "asc"
                                        );
                                        setOrderBy(column);
                                    }}
                                >
                                    Business/Partnership Name
                                </TableSortHeader>
                                <TableSortHeader
                                    field="type_id"
                                    sortField={orderBy}
                                    sortDir={sortDir}
                                    changeSort={(column) => {
                                        setSortDir(
                                            sortDir === "asc" ? "desc" : "asc"
                                        );
                                        setOrderBy(column);
                                    }}
                                >
                                    Business Type
                                </TableSortHeader>
                                <TableSortHeader
                                    disabled={true}
                                    field="owner_name"
                                    sortField={orderBy}
                                    sortDir={sortDir}
                                    changeSort={(column) => {
                                        setSortDir(
                                            sortDir === "asc" ? "desc" : "asc"
                                        );
                                        setOrderBy(column);
                                    }}
                                >
                                    Owner
                                </TableSortHeader>
                                <TableSortHeader
                                    disabled={true}
                                    field="percentage_owned"
                                    sortField={orderBy}
                                    sortDir={sortDir}
                                    changeSort={(column) => {
                                        setSortDir(
                                            sortDir === "asc" ? "desc" : "asc"
                                        );
                                        setOrderBy(column);
                                    }}
                                >
                                    Percentage Owned
                                </TableSortHeader>
                                <TableSortHeader
                                    field="value"
                                    sortField={orderBy}
                                    sortDir={sortDir}
                                    changeSort={(column) => {
                                        setSortDir(
                                            sortDir === "asc" ? "desc" : "asc"
                                        );
                                        setOrderBy(column);
                                    }}
                                >
                                    Value
                                </TableSortHeader>
                                <TableSortHeader
                                    field="as_of_date"
                                    sortField={orderBy}
                                    sortDir={sortDir}
                                    changeSort={(column) => {
                                        setSortDir(
                                            sortDir === "asc" ? "desc" : "asc"
                                        );
                                        setOrderBy(column);
                                    }}
                                >
                                    As of Date
                                </TableSortHeader>
                                <th className="text-right">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {businessInterests.map((businessInterest, idx) => (
                                <tr key={idx}>
                                    <td>
                                        <Link
                                            to={`${clientPath}/business-interests/${businessInterest.id}/view`}
                                        >{`${businessInterest.name}`}</Link>
                                    </td>
                                    <td>{businessInterest.type_description}</td>
                                    <td>{businessInterest.owner_name}</td>
                                    <td>
                                        {formatPercentage(
                                            businessInterest.percentage_owned
                                        )}
                                    </td>
                                    {businessInterest.funding_entity ? (
                                        <td>
                                            &#36;
                                            {`${businessInterest.gross_value}`}
                                        </td>
                                    ) : (
                                        <td>&#36;{businessInterest.value}</td>
                                    )}

                                    <td>
                                        {formatDate(
                                            businessInterest.as_of_date,
                                            "N/A"
                                        )}
                                    </td>

                                    <td className="text-right">
                                        <span>
                                            <Dropdown
                                                position="right"
                                                toggleClass="px-3 py-1"
                                                toggleContent={
                                                    <FontAwesomeIcon
                                                        icon={faEllipsisH}
                                                    />
                                                }
                                            >
                                                <DropdownItem
                                                    label="Edit"
                                                    icon={faEdit}
                                                    link={`${clientPath}/business-interests/${businessInterest.id}/edit`}
                                                />
                                                {(userType === "member" ||
                                                    userType === "client") && (
                                                    <DropdownItem
                                                        label="Delete"
                                                        icon={faTrash}
                                                        handleClick={() =>
                                                            deleteBusinessInterest(
                                                                businessInterest
                                                            )
                                                        }
                                                    />
                                                )}
                                            </Dropdown>
                                        </span>
                                    </td>
                                </tr>
                            ))}

                            <TableSummaryRow
                                columns={[
                                    "",
                                    "",
                                    "",
                                    "",
                                    businessInterests
                                        .map((b) =>
                                            b.funding_entity
                                                ? parseMoney(b.gross_value)
                                                : parseMoney(b.value)
                                        )
                                        .reduce((a, c) => a + c),
                                    "",
                                    "",
                                ]}
                            />
                        </tbody>
                    </table>
                </>
            ) : (
                <div className="text-gray-400">
                    There are no business interests for this client.{" "}
                    {canCreateWealthRecords && (
                        <Link
                            to={`${clientPath}/business-interests/add`}
                            className="underline text-gray-600"
                        >
                            Add Now.
                        </Link>
                    )}
                </div>
            )}
        </>
    );
}
