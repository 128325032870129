import React from "react";
import { Route, Switch } from "react-router-dom";
import DashboardLayout from "../layouts/DashboardLayout";
import ManageClient from "./ManageClient";
import ClientsIndex from "./ClientsIndex";
import ClientContextProvider from "../../contexts/ClientContext";

const Clients = (parentProps) => {
    return (
        <DashboardLayout>
            <Switch>
                <Route
                    exact
                    path={[
                        "/clients",
                        "/clients/all",
                        "/clients/disabled",
                        "/clients/pending",
                    ]}
                    component={ClientsIndex}
                />

                <Route
                    path="/clients/:clientId"
                    render={(props) => {
                        return (
                            <ClientContextProvider {...parentProps} {...props}>
                                <ManageClient {...parentProps} {...props} />
                            </ClientContextProvider>
                        );
                    }}
                />
            </Switch>
        </DashboardLayout>
    );
};

export default Clients;
