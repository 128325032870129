import React, { useMemo, useState, useEffect } from "react";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import Button from "../../components/Button";
import Plans from "../billing/plans";
import useBilling from "../../hooks/useBilling";
import useAuth from "../../hooks/useAuth";
import Spinner from "../../components/Spinner";
import TextInput from "../../components/TextInput";
import Forbidden from "components/Forbidden";

export const useStripeCardOptions = () => {
    const fontSize = "16px";
    const options = useMemo(
        () => ({
            style: {
                base: {
                    fontSize,
                    color: "#424770",
                    letterSpacing: "0.025em",
                    fontFamily: "Arial",
                    "::placeholder": {
                        color: "#aab7c4",
                    },
                },
                invalid: {
                    color: "#9e2146",
                },
            },
        }),
        [fontSize]
    );

    return options;
};

export default function InitialPayment() {
    const { logoutUser, user, organization, isSubscribed } = useAuth();
    const {
        createSetupIntent,
        savePaymentMethod,
        clientSecret,
        loading,
        errorMessage,
    } = useBilling();
    const [processing, setProcessing] = useState(false);
    const [error, setError] = useState(null);
    const [selectedPrice, setSelectedPrice] = useState();
    const [promotionCode, setPromotionCode] = useState();
    const stripe = useStripe();
    const elements = useElements();
    const options = useStripeCardOptions();

    if (isSubscribed) {
        window.location.href = "/organization/billing";
    }

    const viewOrgWebsite = async () => {
        window.location.href = organization.website;
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        setProcessing(true);

        if (!stripe || !elements) {
            // Stripe.js has not loaded yet. Make sure to disable
            // form submission until Stripe.js has loaded.
            return;
        }

        const result = await stripe.confirmCardSetup(clientSecret, {
            payment_method: {
                card: elements.getElement(CardElement),
            },
        });

        if (result.error) {
            setError(`Payment failed ${result.error.message}`);
            alert(`Payment failed ${result.error.message}`);
            setProcessing(false);
        } else {
            try {
                await savePaymentMethod(
                    result.setupIntent.payment_method,
                    selectedPrice,
                    promotionCode
                );
                setError(null);
                setProcessing(false);
                return (window.location.href = "/invite-team-members");
            } catch (error) {
                createSetupIntent();
                setProcessing(false);
            }
        }
    };

    useEffect(() => {
        createSetupIntent();
    }, []);

    if (!loading && errorMessage) {
        return (
            <div className="h-screen w-screen flex items-center justify-center">
                <div className="flex-col flex">
                    <Forbidden text={errorMessage} />

                    <div className="text-center mt-6 text-sm">
                        <button onClick={logoutUser}>Logout</button>
                    </div>
                </div>
            </div>
        );
    }

    if (loading || !clientSecret) {
        return (
            <div className="h-screen w-screen flex items-center justify-center">
                <Spinner message="Loading..." />
            </div>
        );
    }

    if (user.role !== "member_owner") {
        return (
            <div className="h-screen w-screen flex items-center justify-center text-center">
                <div>
                    {user.type === "client" ? (
                        <>
                            {organization.name} does not have an active
                            membership. <br />
                            Please contact them to let them know about the issue
                            with their account.
                            <div className="text-center mt-6 text-sm">
                                <button onClick={viewOrgWebsite}>
                                    Take me to their website
                                </button>
                            </div>
                            <div className="text-center mt-6 text-sm">
                                <button onClick={logoutUser}>Sign Out</button>
                            </div>
                        </>
                    ) : (
                        <>
                            This account does not have an active membership.{" "}
                            <br />
                            Please login with the owner account to add payment
                            and choose a plan.
                            <div className="text-center mt-6 text-sm">
                                <button onClick={logoutUser}>
                                    I&apos;d like to log-in to a different
                                    account...
                                </button>
                            </div>
                        </>
                    )}
                </div>
            </div>
        );
    }

    return (
        <div className="max-w-3xl mx-auto p-10">
            <div className="flex justify-center w-full">
                <InheralinkLogo width="200px" />
            </div>

            <h1 className="text-2xl mb-8 font-bold text-gray-600 text-center">
                Add your credit card and choose a plan.
            </h1>
            <form
                onSubmit={handleSubmit}
                className="space-y-4 max-w-lg mx-auto"
            >
                {error && <div>{error}</div>}
                <div className="flex items-center border rounded-md px-3 h-12 bg-white">
                    <div className="flex-1">
                        <CardElement options={options} />
                    </div>
                </div>

                <Plans
                    selectedPrice={selectedPrice}
                    onChange={setSelectedPrice}
                />

                <div>
                    <TextInput
                        name="promotion_code"
                        value={promotionCode}
                        placeholder="Coupon Code (optional)"
                        onChange={(e) => setPromotionCode(e.target.value)}
                        className="border-gray-300"
                    />
                </div>

                {stripe && (
                    <Button
                        type="submit"
                        text="Start My Membership"
                        disabled={!selectedPrice || processing}
                        isLoading={processing}
                    />
                )}
            </form>

            <div className="text-center mt-6 text-sm">
                <button onClick={logoutUser}>Logout</button>
            </div>
        </div>
    );
}

export function InheralinkLogo({
    width = "396px",
    height = "94px",
    fill = "#27AAE1",
}) {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 396 94"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g
                id="Page-1"
                stroke="none"
                strokeWidth="1"
                fill="none"
                fillRule="evenodd"
            >
                <g id="Inherlink-NoTagline" fill={fill} fillRule="nonzero">
                    <path
                        d="M384.35,21.34 C384.35,18.3300481 386.790048,15.89 389.8,15.89 C392.809952,15.89 395.25,18.3300481 395.25,21.34 C395.25,24.3499519 392.809952,26.79 389.8,26.79 C386.790048,26.79 384.35,24.3499519 384.35,21.34 L384.35,21.34 Z M394.07,21.34 C394.009622,18.9970142 392.071974,17.1406267 389.728551,17.180622 C387.385129,17.2206173 385.511957,19.1420443 385.531567,21.4857259 C385.551176,23.8294075 387.456237,25.7192222 389.8,25.72 C390.95527,25.7316538 392.065035,25.2702643 392.871521,24.4430015 C393.678008,23.6157387 394.111028,22.4946001 394.07,21.34 Z M387.91,23.68 L387.91,19 C387.914516,18.6875 388.15816,18.4308029 388.47,18.41 L390,18.41 C390.508287,18.351373 391.018278,18.5047182 391.409945,18.8339451 C391.801611,19.1631719 392.04036,19.6392023 392.07,20.15 C392.104864,21.0743527 391.402414,21.8607437 390.48,21.93 L391.7,23.3 C391.805424,23.3967341 391.872809,23.5279575 391.89,23.67 C391.898822,23.8210534 391.842661,23.9686753 391.735668,24.0756682 C391.628675,24.1826611 391.481053,24.2388222 391.33,24.23 C391.136204,24.2305671 390.955397,24.1326299 390.85,23.97 L389.22,22.04 L389.03,22.04 L389.03,23.64 C389.013871,23.9524592 388.752821,24.1958104 388.44,24.19 C388.16261,24.171976 387.938676,23.9564917 387.91,23.68 L387.91,23.68 Z M389.91,21.04 C390.57,21.04 390.91,20.71 390.91,20.26 C390.91,19.81 390.57,19.52 389.98,19.52 L389.05,19.52 L389.05,21 L389.91,21.04 Z"
                        id="Shape"
                    ></path>
                    <path
                        d="M7.4,27.5495672 C5.8511357,27.5495672 4.36570885,28.1652839 3.2704964,29.2604964 C2.17528395,30.3557088 1.55999961,31.8411357 1.55999961,33.39 L1.55999961,68.29 C1.55884346,71.5422171 4.14893926,74.2032744 7.4,74.29 C10.6633689,74.2351936 13.2689877,71.5535962 13.23,68.29 L13.23,33.39 C13.2516975,31.8363253 12.6447644,30.3397865 11.5469853,29.2401243 C10.4492061,28.1404621 8.95370965,27.5309652 7.4,27.5495672 L7.4,27.5495672 Z"
                        id="Path"
                    ></path>
                    <path
                        d="M7.4,4.93 C3.78253489,4.93 0.85,7.86253489 0.85,11.48 C0.85,15.0974651 3.78253489,18.03 7.4,18.03 C11.0174651,18.03 13.95,15.0974651 13.95,11.48 C13.95,7.86253489 11.0174651,4.93 7.4,4.93 Z"
                        id="Path"
                    ></path>
                    <path
                        d="M47.42,26.94 C41.59,26.94 36.77,29.6 33.09,34 L33.09,33.39 C33.0926604,31.8403217 32.4782308,30.3533486 31.3824411,29.2575589 C30.2866514,28.1617692 28.7996783,27.5473396 27.25,27.55 C25.6971197,27.533736 24.2032418,28.1441221 23.1060707,29.2431751 C22.0088997,30.3422281 21.4010747,31.8371499 21.42,33.39 L21.42,68.29 C21.3864987,71.5514051 23.9889789,74.2297725 27.25,74.29 C30.5010607,74.2032744 33.0911565,71.5422171 33.09,68.29 L33.09,44.24 C35.55,39.63 39.33,37.07 43.63,37.07 C48.85,37.07 51.82,40.45 51.82,45.87 L51.82,68.29 C51.8673562,71.5606734 54.4899677,74.2097759 57.76,74.29 C60.9678169,74.1455035 63.4932528,71.501068 63.4900031,68.29 L63.4900031,43.42 C63.4900031,32.77 56.12,26.94 47.42,26.94 Z"
                        id="Path"
                    ></path>
                    <path
                        d="M97.57,26.94 C91.64,26.94 86.83,29.6 83.14,34.1 L83.14,6.77 C83.1667503,5.22372204 82.5780306,3.73017213 81.5033987,2.61803125 C80.4287668,1.50589037 78.9562858,0.866295376 77.41,0.84 C74.1333299,0.84 71.4755163,3.49333451 71.47,6.77 L71.47,68.29 C71.47,71.5804178 74.1197468,74.2570958 77.41,74.29 C80.6363917,74.1867147 83.185275,71.517727 83.14,68.29 L83.14,44.29 C85.49,39.68 89.38,37.02 93.68,37.02 C98.9,37.02 101.97,40.4 101.97,45.82 L101.97,68.24 C101.881295,70.382265 102.973632,72.4008763 104.815514,73.4984379 C106.657395,74.5959996 108.952605,74.5959996 110.794486,73.4984379 C112.636368,72.4008763 113.728705,70.382265 113.64,68.24 L113.64,43.42 C113.64,32.77 106.27,26.94 97.57,26.94 Z"
                        id="Path"
                    ></path>
                    <path
                        d="M142.2,26.94 C128.59,26.94 118.86,37.07 118.86,50.79 C118.86,65.32 129.71,74.79 144.04,74.79 C148.55689,74.848533 153.030683,73.9059629 157.14,72.03 C159.137606,71.2601544 160.472645,69.3602921 160.52,67.22 C160.546902,65.9801188 160.073109,64.7816921 159.205633,63.8953992 C158.338158,63.0091063 157.150173,62.5097082 155.91,62.51 C154.658794,62.594358 153.422783,62.8328275 152.23,63.22 C149.910364,64.0675163 147.468721,64.533556 145,64.6 C137.33,64.6 131.59,60.6 130.36,53.14 L156.16,53.14 C158.82,53.14 160.56,52.83 161.69,51.5 C162.657205,50.2843407 163.131952,48.7494451 163.02,47.2 C163,36.66 155.3,26.94 142.2,26.94 Z M130.63,45.67 C131.96,39.67 136.06,36.05 141.99,36.05 C148.44,36.05 152.44,40.45 152.54,45.67 L130.63,45.67 Z"
                        id="Shape"
                    ></path>
                    <path
                        d="M198.8,32.67 C198.823096,31.1433522 198.205911,29.6767757 197.09816,28.6260267 C195.990408,27.5752778 194.493301,27.0363593 192.97,27.14 C188.26,27.14 183.65,29.5 179.87,35.94 L179.87,33.39 C179.87,30.1646571 177.255343,27.55 174.03,27.55 C172.47712,27.533736 170.983242,28.1441221 169.886071,29.2431751 C168.7889,30.3422281 168.181075,31.8371499 168.2,33.39 L168.2,68.29 C168.166499,71.5514051 170.768979,74.2297725 174.03,74.29 C177.281061,74.2032744 179.871157,71.5422171 179.87,68.29 L179.87,45.67 C183.45,40.04 188.26,38.61 192.97,38.61 C194.538876,38.6213665 196.045202,37.9955714 197.144178,36.87586 C198.243154,35.7561486 198.840687,34.2383898 198.8,32.67 L198.8,32.67 Z"
                        id="Path"
                    ></path>
                    <path
                        d="M218.45,26.94 C213.358089,26.9519778 208.329286,28.0675942 203.71,30.21 C201.858061,31.0496984 200.6607,32.8866916 200.64,34.92 C200.632955,36.2082287 201.156117,37.442638 202.086689,38.3334906 C203.017261,39.2243432 204.273303,39.6931956 205.56,39.63 C206.788469,39.481414 207.992099,39.1721015 209.14,38.71 C211.621758,37.6141721 214.307115,37.0552905 217.02,37.07 C222.45,37.07 225.21,38.91 225.21,43.42 L225.21,46.28 C219.48,46.9 212.62,47.51 207.3,49.35 C200.75,51.71 196.86,55.6 196.86,62.05 C196.86,69.11 202.38,74.84 210.57,74.84 C215.79,74.84 220.71,72.59 225.21,68.6 C225.21,71.6817489 227.708251,74.18 230.79,74.18 C233.871749,74.18 236.37,71.6817489 236.37,68.6 L236.37,42.19 C236.37,32.46 229.51,26.94 218.45,26.94 Z M225.21,61 C221.73,63.66 217.53,65.4 214.05,65.4 C210.57,65.4 208.22,63.56 208.22,60.8 C208.22,58.04 210.16,56.5 215.08,55.37 C217.94,54.66 221.63,54.14 225.21,53.63 L225.21,61 Z"
                        id="Shape"
                    ></path>
                    <path
                        d="M250.08,0.84 C246.841778,0.88923014 244.244155,3.53140917 244.24999,6.77 L244.24999,68.29 C244.249776,71.5765632 246.89366,74.2516568 250.18,74.29 C253.406392,74.1867147 255.955275,71.517727 255.91,68.29 L255.91,6.77 C255.93137,5.20813046 255.327226,3.70257996 254.232146,2.58871622 C253.137066,1.47485247 251.642007,0.845194042 250.08,0.84 Z"
                        id="Path"
                    ></path>
                    <path
                        d="M270.14,4.93 C266.536065,4.98502834 263.65379,7.94148086 263.690337,11.5456503 C263.726883,15.1498197 266.668516,18.0472177 270.272826,18.0292383 C273.877135,18.011091 276.78958,15.0843547 276.79,11.48 C276.806806,9.72027744 276.108282,8.02916975 274.854522,6.79426372 C273.600763,5.55935769 271.899266,4.88653478 270.14,4.93 L270.14,4.93 Z"
                        id="Path"
                    ></path>
                    <path
                        d="M270.14,27.5495695 C268.592054,27.5495695 267.107653,28.1656006 266.014028,29.2611015 C264.920403,30.3566023 264.307345,31.842056 264.309991,33.39 L264.309991,68.29 C264.308696,71.5384389 266.892865,74.1979612 270.14,74.29 C273.404948,74.2351981 276.013444,71.5552364 275.98,68.29 L275.98,33.39 C275.998977,31.8353957 275.389789,30.3389172 274.290436,29.239564 C273.191083,28.1402109 271.694604,27.5310228 270.14,27.5495695 L270.14,27.5495695 Z"
                        id="Path"
                    ></path>
                    <path
                        d="M381.6,64.4 L367.68,47.51 L379.56,37.38 C381.015113,36.335177 381.887348,34.6612274 381.91,32.87 C381.925993,31.4715015 381.38437,30.1242133 380.404845,29.1259229 C379.425319,28.1276325 378.08855,27.5605442 376.69,27.55 C375.134161,27.5521434 373.635617,28.1372892 372.49,29.19 L351.82,47.41 L351.82,6.77 C351.84675,5.22372204 351.258031,3.73017213 350.183399,2.61803125 C349.108767,1.50589037 347.636286,0.866295376 346.09,0.84 C342.81333,0.84 340.155516,3.49333451 340.15,6.77 L340.15,66.93 C340.094817,67.2202092 340.064706,67.5146283 340.06,67.81 L340.06,69.25 L340.06,68.71 C339.867173,75.5770666 334.244773,81.0438048 327.375,81.0438048 C320.505227,81.0438048 314.882827,75.5770666 314.69,68.71 L314.69,69.25 L314.69,51.13 C314.69,47.9157028 312.084297,45.31 308.87,45.31 C305.655703,45.31 303.05,47.9157028 303.05,51.13 L303.05,69.25 C303.22035,82.4760767 313.946054,93.1323514 327.172958,93.2173125 C340.399862,93.3012635 351.260769,82.7828229 351.6,69.56 C351.691736,69.1428563 351.738669,68.7171096 351.74,68.29 L351.74,59.29 L359.11,54.17 L372.72,71.47 C373.787762,73.2171647 375.672799,74.2991757 377.72,74.34 C379.245431,74.3589854 380.713936,73.7613892 381.792663,72.6826627 C382.871389,71.6039362 383.468985,70.1354314 383.45,68.61 C383.55,67.47 383,66.14 381.6,64.4 Z"
                        id="Path"
                    ></path>
                    <path
                        d="M309.19,39.25 C316.181271,39.3050302 321.808813,45.0086206 321.77,52 L321.77,51.46 L321.64,68.68 C321.742972,71.8004201 324.289771,74.2837128 327.411796,74.3080505 C330.533821,74.332033 333.118747,71.8884527 333.27,68.77 L333.41,51.55 C333.642159,42.766193 329.112328,34.5418309 321.564413,30.0431489 C314.016498,25.5444668 304.627442,25.4729422 297.011866,29.8561107 C289.39629,34.2392793 284.741688,42.3936755 284.84,51.18 L284.709822,68.4 C284.685147,71.6142972 287.270703,74.2401472 290.485,74.265 C293.699297,74.2898528 296.325147,71.7042972 296.35,68.49 L296.48,51.27 L296.48,51.81 C296.545924,44.8422277 302.221936,39.2332021 309.19,39.25 L309.19,39.25 Z"
                        id="Path"
                    ></path>
                </g>
            </g>
        </svg>
    );
}
