import React, { useEffect } from "react";
import Api from "../../services/Api";
import Button from "../../components/Button";
import PageHeader from "../../components/PageHeader";
import PageContainer from "../../components/PageContainer";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import OrganizationPageLayout from "./_partials/OrganizationPageLayout";
import SearchableSelect from "components/SearchableSelect";
import { InputLabel } from "components/TextInput";

export default function ExportOrganizationData() {
    useEffect(() => {
        document.title = "Export Data";
    }, []);

    return (
        <>
            <PageHeader text="Export Organization Data" />
            <PageContainer>
                <OrganizationPageLayout>
                    <div className="space-y-4">
                        <ExportAllContacts />
                        <ExportContactsClientSelect />
                    </div>
                </OrganizationPageLayout>
            </PageContainer>
        </>
    );
}

function ExportAllContacts() {
    return (
        <>
            <div className="bg-white shadow sm:rounded-lg">
                <div className="px-4 py-5 sm:p-6">
                    <h3 className="text-lg leading-6 font-medium text-gray-900">
                        Contacts Export
                    </h3>
                    <div className="mt-2 sm:flex sm:items-start sm:justify-between">
                        <div className="max-w-xl text-sm text-gray-500">
                            <p>
                                Export all contacts within your organization,
                                including all clients and their relationships to
                                a CSV format.
                            </p>
                        </div>
                        <div className="mt-5 sm:mt-0 sm:ml-6 sm:flex-shrink-0 sm:flex sm:items-center">
                            <Button
                                icon={faDownload}
                                type="anchor"
                                text="Download Contacts"
                                to={
                                    Api.baseUrl() +
                                    `organization/contacts-export`
                                }
                                target="_blank"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

function ExportContactsClientSelect() {
    const [selectedId, setSelectedId] = React.useState(0);
    return (
        <>
            <div className="bg-white shadow sm:rounded-lg">
                <div className="px-4 py-5 sm:p-6">
                    <h3 className="text-lg leading-6 font-medium text-gray-900">
                        Contacts Export - Single Client
                    </h3>
                    <div className="mt-2 sm:flex sm:items-start sm:justify-between">
                        <div className="max-w-xl text-sm text-gray-500">
                            <p>
                                Export contacts within your organization limited
                                to a single client and their relationships to a
                                CSV format.
                            </p>
                        </div>
                        <div className="space-y-4 flex items-end space-x-3">
                            <div className="w-72">
                                <InputLabel
                                    text="Search Clients"
                                    htmlFor="select_client"
                                    tooltip="Start typing in the input to search clients by full name."
                                />
                                <ClientSelect onChange={setSelectedId} />
                            </div>
                            <div className="w-40">
                                <Button
                                    icon={faDownload}
                                    type="anchor"
                                    disabled={!selectedId}
                                    text="Download"
                                    to={
                                        Api.baseUrl() +
                                        `organization/contacts-export?clientId=${selectedId}`
                                    }
                                    target="_blank"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

function ClientSelect({ onChange }) {
    const fetchClients = async (search) => {
        try {
            let response = await Api.get(`clients`, {
                limit: 100,
                "filter[search]": search,
            });
            let options = response.data.data.map((client) => {
                return {
                    id: client.id,
                    value: client.id.toString(),
                    label: client.full_name,
                };
            });

            return options;
        } catch (error) {
            console.error(error);
        }
    };

    const handleSelect = (value) => {
        onChange(value.id);
    };

    return (
        <SearchableSelect
            id="select_client"
            placeholder="Search for clients by name"
            onChange={handleSelect}
            loadOptions={fetchClients}
            defaultOptions
            onInputChange={fetchClients}
        />
    );
}
