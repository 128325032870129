import { updateNonMortgageLiability } from "services/NonMortgageLiabilityService";
import usePost from "./usePost";

export default function useUpdateNonMortgageLiability(
    { clientId, liabilityId, formData },
    config
) {
    const { mutation, validationErrors } = usePost(
        (formData) =>
            updateNonMortgageLiability(clientId, liabilityId, formData),
        {
            showToastOnError: true,
            showToastOnSuccess: true,
            ...config,
        }
    );
    const onSubmit = (event) => {
        event.preventDefault();
        return mutation.mutate(formData);
    };

    return { onSubmit, ...mutation, validationErrors };
}
