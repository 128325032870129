import React, { useEffect, useState } from "react";
import useFormatCurrency from "../../../hooks/useFormatCurrency";
import TextInput from "../../../components/TextInput";
import Checkbox from "../../../components/Checkbox";
import SelectInput, { StateSelect } from "../../../components/SelectInput";
import Api from "../../../services/Api";

export default function BusinessInterestForm({
    input,
    errors,
    readOnly = false,
    updateValue,
    onSubmit,
}) {
    const formatCurrency = useFormatCurrency();
    const [businessTypes, setBusinessTypes] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                let response = await Api.get("enums/business-types");
                setBusinessTypes(response.data);
            } catch (error) {
                console.log(error);
            }
        };
        fetchData();
    }, []);

    return (
        <form onSubmit={onSubmit}>
            <div>
                <div className="mb-6">
                    <TextInput
                        name="name"
                        label="Business/Partnership Name"
                        id="name"
                        readOnly={readOnly}
                        disabled={readOnly}
                        value={input.name}
                        error={errors.name}
                        onChange={updateValue}
                        required={true}
                        autoFocus={true}
                    />
                </div>

                <div className="flex mb-6">
                    <div className="w-1/2 mr-4">
                        <TextInput
                            name="value"
                            label="Value"
                            id="value"
                            readOnly={readOnly}
                            disabled={
                                readOnly || parseInt(input.funding_entity) === 1
                            }
                            value={
                                parseInt(input.funding_entity) === 1
                                    ? input.gross_value
                                    : input.value
                            }
                            error={errors.value}
                            onChange={updateValue}
                            onBlur={(e) => {
                                if (e.target.value) {
                                    e.target.value = formatCurrency(
                                        e.target.value
                                    );
                                    updateValue(e);
                                }
                            }}
                            placeholder="0.00"
                            required={true}
                        />
                    </div>
                    <div className="w-1/2">
                        <TextInput
                            type="date"
                            name="as_of_date"
                            label="As of Date"
                            id="as_of_date"
                            readOnly={readOnly}
                            disabled={
                                readOnly || parseInt(input.funding_entity) === 1
                            }
                            value={input.as_of_date}
                            error={errors.as_of_date}
                            onChange={updateValue}
                            required={true}
                        />
                    </div>
                </div>
                <div className="flex mb-6">
                    <div className="w-1/2 mr-4">
                        <TextInput
                            name="purchase_price"
                            label="Purchase Price/Cost Basis"
                            id="purchase_price"
                            readOnly={readOnly}
                            disabled={readOnly}
                            value={input.purchase_price}
                            error={errors.purchase_price}
                            onChange={updateValue}
                            onBlur={(e) => {
                                e.target.value = formatCurrency(e.target.value);
                                updateValue(e);
                            }}
                            placeholder="0.00"
                        />
                    </div>
                    <div className="w-1/2">
                        <TextInput
                            type="date"
                            name="purchase_date"
                            label="Purchase Date"
                            id="purchase_date"
                            readOnly={readOnly}
                            disabled={readOnly}
                            value={input.purchase_date}
                            error={errors.purchase_date}
                            onChange={updateValue}
                        />
                    </div>
                </div>

                <div className="mb-6">
                    <Checkbox
                        name="funding_entity"
                        label="Is Funding Entity"
                        id="funding_entity"
                        readOnly={readOnly}
                        disabled={readOnly}
                        defaultChecked={input.funding_entity}
                        value={input.funding_entity}
                        error={errors.funding_entity}
                        onChange={updateValue}
                    />
                </div>

                <div className="mb-6">
                    <SelectInput
                        name="type_id"
                        options={businessTypes}
                        label="Business Type"
                        id="type_id"
                        readOnly={readOnly}
                        disabled={readOnly}
                        value={input.type_id}
                        error={errors.type_id}
                        onChange={updateValue}
                        required={true}
                    />
                </div>

                {[1, 2, 7].includes(parseInt(input.type_id)) && (
                    <div className="mb-6">
                        <TextInput
                            name="number_of_shares"
                            label="Number of Shares/Units/Percentage Owned"
                            id="number_of_shares"
                            readOnly={readOnly}
                            disabled={readOnly}
                            value={input.number_of_shares}
                            error={errors.number_of_shares}
                            onChange={updateValue}
                        />
                    </div>
                )}

                {[3, 4, 6].includes(parseInt(input.type_id)) && (
                    <div className="mb-6">
                        <TextInput
                            name="number_of_units"
                            label="Number Of Units"
                            id="number_of_units"
                            readOnly={readOnly}
                            disabled={readOnly}
                            value={input.number_of_units}
                            error={errors.number_of_units}
                            onChange={updateValue}
                        />
                    </div>
                )}

                <div className="mb-6">
                    <TextInput
                        name="tax_id_number"
                        label="Tax ID Number"
                        id="tax_id_number"
                        readOnly={readOnly}
                        disabled={readOnly}
                        value={input.tax_id_number}
                        error={errors.tax_id_number}
                        onChange={updateValue}
                        secret={true}
                    />
                </div>

                <div className="mb-6">
                    <Checkbox
                        name="show_beneficiary"
                        label="Add Transfer On Death Designation?"
                        id="show_beneficiary"
                        readOnly={readOnly}
                        disabled={readOnly}
                        defaultChecked={input.show_beneficiary}
                        value={input.show_beneficiary}
                        error={errors.show_beneficiary}
                        onChange={updateValue}
                    />
                </div>

                <fieldset className="mt-12">
                    <legend className="font-bold txt-md text-gray-500 my-6 pb-1 border-b block w-full">
                        Address
                    </legend>

                    <div className="mb-6">
                        <TextInput
                            name="address"
                            label="Address"
                            id="address"
                            readOnly={readOnly}
                            disabled={readOnly}
                            value={input.address}
                            error={errors.address}
                            onChange={updateValue}
                        />
                    </div>
                    <div className="flex mb-6">
                        <div className="w-1/3 mr-4">
                            <TextInput
                                name="city"
                                label="City"
                                id="city"
                                value={input.city}
                                error={errors.city}
                                onChange={updateValue}
                                readOnly={readOnly}
                                disabled={readOnly}
                            />
                        </div>
                        <div className="w-1/3 mr-4">
                            <StateSelect
                                name="state"
                                label="State"
                                id="state"
                                value={input.state}
                                error={errors.state}
                                onChange={updateValue}
                                readOnly={readOnly}
                                disabled={readOnly}
                            />
                        </div>
                        <div className="w-1/3">
                            <TextInput
                                name="postal_code"
                                label="Postal Code"
                                id="postal_code"
                                value={input.postal_code}
                                error={errors.postal_code}
                                onChange={updateValue}
                                readOnly={readOnly}
                                disabled={readOnly}
                            />
                        </div>
                    </div>
                    <div>
                        <TextInput
                            name="country"
                            label="Country"
                            id="country"
                            value={input.country}
                            error={errors.country}
                            onChange={updateValue}
                            readOnly={readOnly}
                            disabled={readOnly}
                        />
                    </div>
                </fieldset>

                <input type="submit" hidden />
            </div>
        </form>
    );
}
