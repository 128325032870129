import React, { useState, useRef } from "react";
import Transition from "./Transition";
import TextInput from "./TextInput";
import InputErrors from "./InputErrors";

import {
    faCheckCircle,
    faExclamationCircle,
    faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "./Button";
import ReactDOM from "react-dom";

const Modal = ({
    isOpen,
    component: Component = null,
    type = "",
    title = "Are you sure?",
    body: Body = "",
    size = "sm",
    doneButtonTitle = "Yes",
    doneButtonDisabled = false,
    doneButtonAppearance = "default",
    cancelButtonTitle = "Cancel",
    showButtons = true,
    backgroundDismiss = true,
    isLoading = false,
    inputType = "",
    inputLabel = "",
    inputValue = "",
    inputPlaceholder = "",
    inputErrors = [],
    errors = [],
    onResult = () => {},
    ...props
}) => {
    const [value, setValue] = useState(inputValue);
    const inputEl = useRef(null);

    const [portalNode] = useState(document.getElementById("portal"));

    React.useEffect(() => {
        if (isOpen) {
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.overflow = "unset";
        }
    }, [isOpen]);

    React.useEffect(() => {
        if (isOpen) {
            setValue(inputValue);
        }
    }, [inputValue, isOpen]);

    React.useEffect(() => {
        if (isOpen && inputType && inputEl && inputEl.current) {
            inputEl.current.focus();
        }
    }, [isOpen, inputType]);

    if (inputType && value && !isOpen) {
        setValue("");
    }

    const getIcon = () => {
        switch (type) {
            case "error":
                return (
                    <div className="mx-auto flex-shrink-0 flex items-center justify-center h-8 w-8 rounded-full bg-red-100">
                        <FontAwesomeIcon
                            icon={faExclamationCircle}
                            className="text-red-500 text-2xl"
                        />
                    </div>
                );
            case "success":
                return (
                    <div className="mx-auto flex items-center justify-center h-8 w-8 rounded-full bg-green-100">
                        <FontAwesomeIcon
                            icon={faCheckCircle}
                            className="text-green-400 text-2xl"
                        />
                    </div>
                );
            default:
                return <></>;
        }
    };

    const getTitle = () => {
        switch (type) {
            case "error":
                return title || "Error";
            case "success":
                return title || "Success";
            default:
                return title;
        }
    };

    const onCancel = (background) => {
        onResult({ result: background ? undefined : false });
        if (props.onCancel) {
            props.onCancel(background);
        }
    };

    const onNext = (event) => {
        onResult({
            event,
            result: inputType ? value : true,
        });
    };

    const renderWrappedModal = (Component) => {
        const title = getTitle();
        return (
            <>
                <div className="z-50">
                    <div className="mb-4 relative">
                        <div className="w-full flex items-center justify-center">
                            {title && (
                                <h3 className="flex items-center space-x-2 text-2xl leading-6 font-medium text-gray-900">
                                    {getIcon()} <span>{getTitle()}</span>
                                </h3>
                            )}
                            <button
                                className="absolute right-0"
                                type="button"
                                onClick={onCancel}
                            >
                                <FontAwesomeIcon
                                    icon={faTimesCircle}
                                    className="text-gray-400 text-lg"
                                />
                            </button>
                        </div>
                        {Body && (
                            <div className="mt-2">
                                {typeof Body === "string" ? (
                                    <p className="text-base text-gray-600">
                                        {Body}
                                    </p>
                                ) : (
                                    { Body }
                                )}
                            </div>
                        )}

                        {errors && <InputErrors errors={errors} />}
                    </div>
                </div>

                <Component onResult={onResult} {...props} />

                {props.children}
            </>
        );
    };

    const renderDefaultModal = () => {
        return (
            <>
                <div className="z-50">
                    {getIcon()}
                    <div className="text-center mt-5">
                        <div className="relative w-full flex items-center justify-center">
                            {title && (
                                <h3 className="flex items-center space-x-2 text-2xl leading-6 font-medium text-gray-900">
                                    <span>{getTitle()}</span>
                                </h3>
                            )}
                            {showButtons === false && (
                                <button
                                    className={`absolute right-0`}
                                    type="button"
                                    onClick={onCancel}
                                >
                                    <FontAwesomeIcon
                                        icon={faTimesCircle}
                                        className="text-gray-400 text-lg"
                                    />
                                </button>
                            )}
                        </div>

                        {Body && (
                            <div className="mt-2">
                                {typeof Body === "string" ? (
                                    <p className="text-base text-gray-600">
                                        {Body}
                                    </p>
                                ) : (
                                    <Body />
                                )}
                            </div>
                        )}

                        <form
                            onSubmit={(e) => {
                                e.preventDefault();
                                onNext(e);
                            }}
                        >
                            {errors && <InputErrors errors={errors} />}

                            {inputType && (
                                <TextInput
                                    reference={inputEl}
                                    className="mt-2"
                                    type={inputType}
                                    name={inputLabel}
                                    label={inputLabel}
                                    placeholder={inputPlaceholder}
                                    value={value}
                                    onChange={(e) => setValue(e.target.value)}
                                    error={inputErrors}
                                />
                            )}
                        </form>

                        {props.children}
                    </div>
                </div>

                {showButtons && (
                    <div className="flex items-center justify-center mt-6">
                        {cancelButtonTitle && (
                            <div className="mr-3 flex-1">
                                <Button
                                    onClick={() =>
                                        onCancel({ background: false })
                                    }
                                    text={cancelButtonTitle}
                                    appearance="outline"
                                />
                            </div>
                        )}
                        <div className="flex-1">
                            <Button
                                isLoading={isLoading}
                                onClick={(e) => onNext(e)}
                                text={doneButtonTitle}
                                appearance={doneButtonAppearance}
                                disabled={doneButtonDisabled}
                            />
                        </div>
                    </div>
                )}
            </>
        );
    };

    if (!portalNode) {
        return <></>;
    }

    return ReactDOM.createPortal(
        <Transition
            show={isOpen}
            enter="transition ease-out duration-300 transform"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition ease-in duration-75 transform"
            leaveFrom="opacity-0"
            leaveTo="opacity-0"
        >
            <div
                className="fixed z-50 inset-0 flex justify-center max-h-screen"
                onClick={() => {
                    backgroundDismiss && onCancel({ background: true });
                }}
            >
                <div className="fixed inset-0 bg-gray-800 opacity-75 z-50"></div>
                <div className="w-full p-3 flex items-center justify-center">
                    <div
                        className={`max-h-full w-full z-50 bg-white sm:rounded-md shadow-xl ${
                            size ? "max-w-" + size : ""
                        } p-6 overflow-y-auto`}
                        onClick={(e) => e.stopPropagation()}
                    >
                        {Component ? (
                            props.noWrapper ? (
                                <Component onResult={onResult} {...props} />
                            ) : (
                                renderWrappedModal(Component)
                            )
                        ) : (
                            renderDefaultModal()
                        )}
                    </div>
                </div>
            </div>
        </Transition>,
        portalNode
    );
};

export default Modal;
