import React, { useState } from "react";

import TextInput from "../TextInput";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import ResourceSelect from "../ResourceSelect";
import InputErrors from "../InputErrors";
import useFormatCurrency from "../../hooks/useFormatCurrency";
import { AddOwnershipButton } from "components/Ownership";

export default function ValueTransferDestination({
    client,
    asset,
    errors,
    transfer,
    destinations,
    setDestinations,
    showAdd = true,
    readOnly = false,
    disabled = false,
}) {
    const formatCurrency = useFormatCurrency();
    const [excludedAsset] = useState([asset.type + ":" + asset.id]);

    function updateOwnershipType(value, idx) {
        let parts = value.split(":");
        let ownerType = parts[0];
        let ownerId = parts[1];

        setDestinations(
            destinations.map((destination, idxs) => {
                if (idxs === idx) {
                    if (ownerType === "Spouse" && client.spouse) {
                        destination.owner_id = client.spouse.id;
                    }
                    destination.owner_type = ownerType;
                    destination.owner_id = ownerId;
                    destination.destination_type = ownerType;
                    destination.destination_id = ownerId;
                }
                return destination;
            })
        );
    }

    const [resourceTypes] = useState([
        "Relationship",
        "Client",
        "FinancialAccount",
        "RetirementAccount",
        "Trust",
    ]);

    function updateAmount(value, idx) {
        setDestinations(
            destinations.map((destination, idxs) => {
                if (idxs === idx) {
                    destination.transfer_amount = value;
                }
                return destination;
            })
        );
    }

    function appendDestination() {
        let newOptions = [
            ...destinations,
            {
                resource_id: asset.id,
                resource_type: asset.type,
                owner_id: client.id,
                owner_type: "Client",
                percentage_owned: 0,
                owner_description: "",
            },
        ];
        setDestinations(newOptions);
    }

    function removeDestination(idx) {
        setDestinations(destinations.filter((destination, i) => i !== idx));
    }

    return (
        <div className="space-y-4">
            {destinations.map((destination, idx) => (
                <div
                    className={`flex space-x-3 items-end`}
                    key={`destination_${idx}`}
                >
                    <div className="flex-1">
                        <InputErrors errors={errors["destination.type"]} />
                        <InputErrors errors={errors["transfer_amount"]} />
                        <ResourceSelect
                            client={client}
                            resourceType={resourceTypes}
                            label="Recipient"
                            name="owner"
                            disabled={disabled}
                            readOnly={readOnly}
                            value={`${destination.owner_type}:${destination.owner_id}`}
                            error={errors.owner}
                            excluded={excludedAsset}
                            onChange={(e) => {
                                updateOwnershipType(e.target.value, idx);
                            }}
                        />
                    </div>

                    <div className="flex space-x-2 flex-0 w-30 mx-2">
                        <div className="flex-1 relative">
                            <TextInput
                                type="text"
                                label="$ Amount"
                                name="transfer_amount"
                                id={`transfer_amount_${transfer.id}`}
                                value={
                                    disabled
                                        ? formatCurrency(
                                              destination.transfer_amount
                                          )
                                        : destination.transfer_amount
                                }
                                disabled={disabled}
                                required={true}
                                placeholder="0.00"
                                error={
                                    errors[
                                        "destinations." +
                                            idx +
                                            ".transfer_amount"
                                    ]
                                }
                                onChange={(e) =>
                                    updateAmount(e.target.value, idx)
                                }
                                onBlur={(e) => {
                                    e.target.value = formatCurrency(
                                        e.target.value
                                    );
                                    updateAmount(e.target.value, idx);
                                }}
                            />
                        </div>
                    </div>
                    {destinations.length > 1 && showAdd && (
                        <button
                            tabIndex={-1}
                            type="button"
                            className="text-gray-500 text-sm hover:text-red-500 mb-4"
                            title="Remove Ownership"
                            onClick={() => removeDestination(idx)}
                        >
                            <FontAwesomeIcon icon={faTimesCircle} />
                        </button>
                    )}
                </div>
            ))}
            {readOnly === false && showAdd && (
                <AddOwnershipButton
                    appendOwnership={appendDestination}
                    text="Add a recipient"
                    tabIndex={1}
                />
            )}
        </div>
    );
}
