import useLiveWealthFetch from "hooks/useLiveWealthFetch";
import useLiveWealthExport from "hooks/useLiveWealthExport";
import useClient from "hooks/useClient";
import usePostMortemState from "hooks/usePostMortemState";
import useLiveWealthState from "hooks/useLiveWealthState";

const initialData = {
    assets: [],
    owner_totals: {},
    owner_summary: [],
    net_worth_total: 0,
    net_worth_with_death_benefit_total: 0,
    asset_keys: {},
    ownerTotals: {},
    ownerSummary: [],
    assetKeys: {},
    totals: {
        netWorth: 0,
        netWorthWithDeathBenefit: 0,
    },
};

export default function useLiveWealthData(
    {
        ownerInitial = "all",
        defaultCurrent = true,
        include_transfer_owners = false,
    } = {
        ownerInitial: "all",
        defaultCurrent: true,
        include_transfer_owners: false,
    }
) {
    const { client } = useClient();

    const { person, showAltValues, isPostMortem } = usePostMortemState();

    // Props
    const {
        current,
        setCurrent,
        owner,
        setOwner,
        showDodValue,
        setShowDodValue,
    } = useLiveWealthState({
        current: defaultCurrent,
        ownerInitial,
    });

    const enabled = !!owner;

    const { data, isLoading, isFetching, remove, refetch } = useLiveWealthFetch(
        {
            owner,
            current,
            client,
            include_transfer_owners,
        },
        {
            enabled,
            keepPreviousData: false,
        }
    );

    const { onSubmit: exportRecords, isLoading: isExporting } =
        useLiveWealthExport({
            owner,
            current,
            client,
            isPostMortem,
            showAltValues,
            include_transfer_owners,
            person: person || "client",
        });

    const fetchData = () => {
        remove();
        refetch();
    };

    const { assets, assetKeys, sortKeys, totals, ownerTotals, ownerSummary } =
        data || initialData;

    return {
        isLoading,
        isFetching,
        assets,
        assetKeys,
        sortKeys,
        showDodValue,
        setShowDodValue,
        totals,
        ownerTotals,
        owner,
        setOwner,
        current,
        setCurrent,
        exportRecords,
        isExporting,
        ownerSummary,
        fetchData,
    };
}
