import React from "react";

export default function Badge({ color = "orange", children }) {
    const baseStyle =
        "inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium leading-4";
    const colors = {
        brand: "bg-brand-100 text-brand-800",
        orange: "bg-orange-100 text-orange-800",
        red: "bg-red-100 text-red-800",
        green: "bg-green-100 text-green-800",
        gray: "bg-gray-200 text-gray-900",
        white: "bg-white text-brand",
    };
    return <span className={`${baseStyle} ${colors[color]}`}>{children}</span>;
}
