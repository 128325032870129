import React, { useState, useReducer, useEffect } from "react";
import Api from "../../services/Api";
import { useToasts } from "react-toast-notifications";
import TrusteeForm from "./_partials/TrusteesForm";
import BasicModal from "../../components/ModalBasic";
import Button from "../../components/Button";
import SaveButton from "../../components/SaveButton";

export default function AddTrusteeModal({
    isOpen,
    trustId = "",
    client,
    onSuccess,
    onClose,
}) {
    const { addToast } = useToasts();
    const [errors, setErrors] = useState([]);

    const [input, setInput] = useReducer(
        (state, newState) => ({ ...state, ...newState }),
        {
            trust_id: trustId,
            owner: "Client:" + client.id,
            successor_level: 1,
        }
    );

    useEffect(() => {
        setInput({ trust_id: trustId });
    }, [trustId]);

    function updateValue(event) {
        setInput({ [event.target.name]: event.target.value });
    }

    async function store(e) {
        e.preventDefault();
        try {
            const body = { ...input };
            let response = await Api.post(
                "clients/" + client.id + "/trustees",
                body
            );
            addToast(response.data.message);
            setErrors([]);
            onSuccess();
        } catch (e) {
            setErrors([]);
            if (e.response.data.errors) {
                setErrors(e.response.data.errors);
            } else {
                addToast(e.response.data.message, { type: "error" });
            }
        }
    }

    return (
        <>
            <BasicModal
                isOpen={isOpen}
                size="lg"
                title="Add Trustee"
                onClose={onClose}
                modalBodyClassName="p-6 pt-0"
            >
                <TrusteeForm
                    client={client}
                    input={input}
                    errors={errors}
                    updateValue={updateValue}
                    onSubmit={store}
                />
                <div className="flex items-center space-x-2">
                    <Button
                        type="button"
                        appearance="light"
                        text="Cancel"
                        onClick={onClose}
                    />
                    <SaveButton
                        type="button"
                        appearance="default"
                        onClick={store}
                    />
                </div>
            </BasicModal>
        </>
    );
}
