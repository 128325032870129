import React, { useEffect, useState } from "react";
import useFormatCurrency from "../../../hooks/useFormatCurrency";
import SelectInput from "../../../components/SelectInput";
import TextInput from "../../../components/TextInput";
import Checkbox from "../../../components/Checkbox";
import Api from "../../../services/Api";
import ManagedByAdvisor from "../../../components/ManagedByAdvisor";

export function showCostBasis(type) {
    const availableTypes = [1, 3, 5, 6];
    return availableTypes.includes(parseInt(type));
}

export default function FinancialAccountForm({
    client,
    input = {},
    errors = {},
    readOnly = false,
    onSubmit,
    updateValue,
}) {
    const formatCurrency = useFormatCurrency();
    const [accountTypes, setAccountTypes] = useState([]);
    const addBeneficiaryLabel =
        "Add Payable on Death or Transfer On Death Designation?";

    const handleUpdateAccountType = (event) => {
        let showBeneficiary = 0;

        updateValue({
            target: {
                name: "show_beneficiary",
                value: showBeneficiary,
            },
        });
        updateValue(event);
    };

    useEffect(() => {
        const fetchAccountTypes = async () => {
            if (accountTypes.length < 1) {
                try {
                    let response = await Api.get("enums/financialaccounttypes");
                    setAccountTypes(
                        response.data.filter(
                            (item) => item.label !== "Retirement Accounts"
                        )
                    );
                } catch (error) {
                    console.log(error);
                }
            }
        };
        fetchAccountTypes();
    }, [client, accountTypes.length]);

    const isStockOrBond = parseInt(input?.financial_account_type_id) === 5;

    return (
        <form onSubmit={onSubmit}>
            <div>
                <div className="mb-6">
                    <TextInput
                        name="name"
                        label={isStockOrBond ? "Name of Stock" : "Account Name"}
                        id="name"
                        disabled={readOnly}
                        readOnly={readOnly}
                        value={input.name}
                        error={errors.name}
                        onChange={updateValue}
                        required={true}
                    />
                </div>
                <div className="mb-6">
                    <SelectInput
                        options={accountTypes}
                        value={input.financial_account_type_id}
                        error={errors.financial_account_type_id}
                        disabled={readOnly}
                        id="financial_account_type_id"
                        name="financial_account_type_id"
                        label="Type of Account"
                        onChange={handleUpdateAccountType}
                        required={true}
                        autoFocus={true}
                        defaultOption="Select an account type..."
                        defaultOptionValue={null}
                        defaultOptionDisabled={false}
                    />
                </div>
                {!isStockOrBond ? (
                    <div className="mb-6">
                        <TextInput
                            name="account_number"
                            label="Account Number"
                            id="account_number"
                            disabled={readOnly}
                            readOnly={readOnly}
                            value={input.account_number}
                            error={errors.account_number}
                            onChange={updateValue}
                        />
                    </div>
                ) : (
                    <div className="mb-6">
                        <TextInput
                            name="shares_owned"
                            label="Shares Owned"
                            id="shares_owned"
                            disabled={readOnly}
                            readOnly={readOnly}
                            value={input.shares_owned}
                            error={errors.shares_owned}
                            onChange={updateValue}
                        />
                    </div>
                )}
                <div className="flex space-x-3 mb-6">
                    <div className="flex-1">
                        <TextInput
                            name="value"
                            label="Current Value"
                            id="value"
                            disabled={readOnly}
                            readOnly={readOnly}
                            value={input.value}
                            error={errors.value}
                            onChange={updateValue}
                            onBlur={(e) => {
                                e.target.value = formatCurrency(e.target.value);
                                updateValue(e);
                            }}
                            placeholder="0.00"
                            required={true}
                        />
                    </div>
                    <div className="flex-1">
                        <TextInput
                            type="date"
                            name="as_of_date"
                            label="As of Date"
                            id="as_of_date"
                            readOnly={readOnly}
                            disabled={readOnly}
                            value={input.as_of_date}
                            error={errors.as_of_date}
                            onChange={updateValue}
                            required={true}
                        />
                    </div>
                </div>
                {showCostBasis(input.financial_account_type_id) && (
                    <div className="mb-6">
                        <TextInput
                            name="cost_basis"
                            label="Cost Basis"
                            id="cost_basis"
                            disabled={readOnly}
                            readOnly={readOnly}
                            value={input.cost_basis}
                            error={errors.cost_basis}
                            onChange={updateValue}
                            onBlur={(e) => {
                                e.target.value = formatCurrency(e.target.value);
                                updateValue(e);
                            }}
                            placeholder="0.00"
                        />
                    </div>
                )}
                <div className="mb-6">
                    <Checkbox
                        name="show_beneficiary"
                        label={addBeneficiaryLabel}
                        id="show_beneficiary"
                        readOnly={readOnly}
                        disabled={readOnly}
                        checked={parseInt(input.show_beneficiary) === 1}
                        value={input.show_beneficiary}
                        error={errors.show_beneficiary}
                        onChange={updateValue}
                    />
                </div>
            </div>

            <fieldset className="mt-12">
                <legend className="font-bold txt-md text-gray-500 my-6 pb-1 border-b block w-full">
                    Managed By
                </legend>
                <ManagedByAdvisor
                    client={client}
                    input={input}
                    errors={errors}
                    disabled={readOnly}
                    updateValue={updateValue}
                />
            </fieldset>
            <input type="submit" hidden />
        </form>
    );
}
