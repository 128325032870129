import React, { useState, useEffect } from "react";
import SelectInput from "../../../components/SelectInput";
import Api from "../../../services/Api";
import { useToasts } from "react-toast-notifications";
import Dropdown, { DropdownItem } from "../../../components/Dropdown";
import {
    faBan,
    faEnvelope,
    faEllipsisH,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import EmptyState from "../../../components/EmptyState";
import { Link } from "react-router-dom";
import { formatDate } from "../../../util/TextUtil";

const roles = [
    { value: "member_owner", label: "Owner" },
    { value: "member_partner", label: "Associate" },
    { value: "member_associate", label: "Staff" },
];

const PendingInvitations = ({ setShowInviteMemberModal, ...props }) => {
    const { addToast } = useToasts();
    const [invitations, setInvitations] = useState([]);

    useEffect(() => {
        const fetchData = async () => fetchInvitations();
        fetchData();
    }, [props.newInviteCount]);

    async function fetchInvitations() {
        try {
            let invitations = await Api.get("invitations", {
                "filter[type]": "member",
                "filter[trashed]": "with",
            });
            setInvitations(invitations.data.data);
        } catch (e) {
            // TODO catch error.
        }
    }

    async function resendInvitation(invitationEmail, invitationId) {
        try {
            const response = await Api.post("invitations", {
                invitees: [
                    { email: invitationEmail, invitation_id: invitationId },
                ],
            });
            addToast(response.data.message);
            fetchInvitations();
        } catch (e) {
            addToast(e.response.data.message, { type: "error" });
        }
    }

    async function revokeInvitation(invitationId) {
        try {
            const response = await Api.delete("invitations/" + invitationId);
            fetchInvitations();
            addToast(response.data.message);
        } catch (e) {
            addToast(e.response.data.message, { type: "error" });
        }
    }

    async function updateInvitationRole(e, invitation, idx) {
        e.preventDefault();
        try {
            const role = e.target.value;
            setInvitations(
                invitations.map((invitation, idxs) => {
                    if (idxs === idx) {
                        invitation.role = role;
                    }
                    return invitation;
                })
            );
            const response = await Api.put("invitations/" + invitation.id, {
                role,
            });
            addToast(response.data.message);
        } catch (e) {
            addToast(e.response.data.message, { type: "error" });
        }
    }

    if (invitations.length < 1) {
        return (
            <EmptyState>
                No pending member invitations found.{" "}
                <Link
                    onClick={() => setShowInviteMemberModal(true)}
                    to="#"
                    className="underline"
                >
                    Invite some now.
                </Link>
            </EmptyState>
        );
    }

    return (
        <table className="w-full text-left">
            <thead>
                <tr>
                    <th>Email</th>
                    <th>Invited On</th>
                    <th>Expires On</th>
                    <th>Role</th>
                    <th>Status</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                {invitations.map((invitation, idx) => (
                    <tr
                        key={`invitation_${invitation.id}`}
                        className={invitation.deleted_at ? "text-gray-400" : ""}
                    >
                        <td>{invitation.email}</td>
                        <td>{formatDate(invitation.created_at)}</td>
                        <td>{formatDate(invitation.expires_at)}</td>

                        <td>
                            <SelectInput
                                options={roles}
                                name="invitation.role"
                                value={invitation.role}
                                onChange={(e) =>
                                    updateInvitationRole(e, invitation, idx)
                                }
                                disabled={invitation.deleted_at}
                            />
                        </td>
                        <td className="w-12">
                            {invitation.deleted_at ? (
                                <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium leading-4 bg-red-100 text-red-800">
                                    Revoked
                                </span>
                            ) : (
                                <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium leading-4 bg-green-100 text-green-800">
                                    Pending
                                </span>
                            )}
                        </td>
                        <td className="text-right">
                            <Dropdown
                                position="right"
                                toggleclassName="px-3 py-1"
                                toggleContent={
                                    <FontAwesomeIcon icon={faEllipsisH} />
                                }
                            >
                                {invitation.deleted_at === null && (
                                    <DropdownItem
                                        label="Revoke"
                                        icon={faBan}
                                        handleClick={() =>
                                            revokeInvitation(invitation.id)
                                        }
                                    />
                                )}
                                <DropdownItem
                                    label="Resend Invitation"
                                    icon={faEnvelope}
                                    handleClick={() =>
                                        resendInvitation(
                                            invitation.email,
                                            invitation.id
                                        )
                                    }
                                />
                            </Dropdown>
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
};

export default PendingInvitations;
