import SectionHeader from "components/SectionHeader";
import React from "react";
import ClientPageHeader, { Heading } from "../../components/ClientPageHeader";
import PageContainer from "../../components/PageContainer";
import ClientNotes from "./Notes/ClientNotes";
import ClientNav from "./_partials/ClientNav";

export default function ClientProfileNotes({ client, ...props }) {
    return (
        <>
            <ClientPageHeader text="Notes Attached to Client" client={client}>
                <Heading>Notes</Heading>
            </ClientPageHeader>

            <PageContainer>
                <div className="flex space-x-10">
                    <div className="flex-initial">
                        <ClientNav
                            active={props.history.location.pathname}
                            client={client}
                        />
                    </div>
                    <div className="flex-1">
                        <SectionHeader>Notes</SectionHeader>
                        <ClientNotes
                            client={client}
                            resourceType="Client"
                            resourceId={client.id}
                            {...props}
                        />
                    </div>
                </div>
            </PageContainer>
        </>
    );
}
