import React, { useState, useRef, useEffect, useContext } from "react";
import Api from "../../../services/Api";
import Panel from "../../../components/Panel";
import Button from "../../../components/Button";
import ButtonGroup from "../../../components/ButtonGroup";
import useOnClickOutside from "../../../hooks/useOnClickOutside";
import Modal from "../../../components/Modal";
import { Heading } from "../../../components/ClientPageHeader";
import Spinner from "../../../components/Spinner";
import SearchInput from "../../../components/SearchInput";
import useLocalStorage from "../../../hooks/useLocalStorage";
import { titleCase, formatDate } from "../../../util/TextUtil";
import Breadcrumbs from "../../../components/Breadcrumbs";
import TableSortHeader from "../../../components/TableSortHeader";
import EmptyTableRow from "../../../components/EmptyTableRow";
import { iconColorForVault } from "./_partials/helpers";

import Dropdown, { DropdownItem } from "../../../components/Dropdown";
import {
    faFolderOpen,
    faArrowsAlt,
    faSearch,
    faEllipsisV,
    faFolder,
    faFile,
    faPlus,
    faFolderPlus,
    faTrash,
    faICursor,
    faTextWidth,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useToasts } from "react-toast-notifications";
import UploadFile from "./_modals/UploadFile";
import MoveFile from "./_modals/MoveFile";
import { AuthContext } from "../../../contexts/AuthContext";
import { getRootVaultPath, isFileImage } from "./helpers";
import { useHistory } from "react-router";

export default function VaultFolderView({
    client,
    parent = null,
    vaultEntries,
    refreshVaultEntries,
    loading,
    breadcrumbs = [],
    ...props
}) {
    useEffect(() => {
        document.title = "Vault | " + (client.full_name || "Loading");
    }, [client]);

    const history = useHistory();

    const context = useContext(AuthContext);
    const [selectedVault, setSelectedVault] = useState(null);

    const [showCreateDirectory, setShowCreateDirectory] = useState(false);
    const [showDeleteVault, setShowDeleteVault] = useState(false);
    const [showRename, setShowRename] = useState(false);
    const [showDescription, setShowDescription] = useState(false);
    const [showAddFile, setShowAddFile] = useState(false);
    const [showMove, setShowMove] = useState(false);
    const [foldersOnTop] = useState(true);

    const [viewMode, setViewMode] = useLocalStorage("vault.viewMode", 0);

    const [sortField, setSortField] = useState("name");
    const [sortDir, setSortDir] = useState("asc");
    const [searchFilter, setSearchFilter] = useState("");

    const [isModalLoading, setIsModalLoading] = useState(false);
    const [modalError, setModalError] = useState([]);

    const isAModalOpen =
        showAddFile ||
        showRename ||
        showMove ||
        showDeleteVault ||
        showCreateDirectory ||
        showDescription;

    const outsideRef = useRef();
    useOnClickOutside(
        outsideRef,
        () => !isAModalOpen && setSelectedVault(null)
    );

    const { addToast } = useToasts();
    const parentId = parent ? parent.id : null;

    const selectVaultEntry = (vaultEntry) => {
        if (selectedVault && selectedVault.id === vaultEntry.id) {
            openVault(vaultEntry.id);
            return;
        }
        setSelectedVault(vaultEntry);
    };

    const openVault = (vaultEntryId) => {
        if (!vaultEntryId) {
            props.history.push(`${window.location.pathname}`);
        } else {
            history.push(`${window.location.pathname}?id=${vaultEntryId}`);
        }
    };

    const renameVaultEntry = async (vault, newName) => {
        setModalError([]);
        const params = {
            name: newName,
        };
        try {
            setIsModalLoading(true);
            await Api.put(`clients/${client.id}/vault/${vault.id}`, params);
            refreshVaultEntries();
            addToast(
                `Renamed ${
                    vault.type === "directory" ? "folder" : "file"
                } from "${vault.name}" to "${newName}"`,
                { type: "success" }
            );
            setIsModalLoading(false);
            return true;
        } catch (e) {
            setIsModalLoading(false);
            console.log(e);
            if (e.response.data.errors && e.response.data.errors.name) {
                setModalError([e.response.data.errors.name]);
            } else if (e.response.data.message) {
                setModalError([e.response.data.message]);
            }
        }
    };

    const updateDescriptionVaultEntry = async (vault, newDescription) => {
        setModalError([]);
        const params = {
            description: newDescription,
        };
        try {
            setIsModalLoading(true);
            await Api.put(`clients/${client.id}/vault/${vault.id}`, params);
            refreshVaultEntries();
            addToast(
                `Update description ${
                    vault.type === "directory" ? "folder" : "file"
                } from "${vault.description}" to "${newDescription}"`,
                { type: "success" }
            );
            setIsModalLoading(false);
            return true;
        } catch (e) {
            setIsModalLoading(false);
            console.log(e);
            if (e.response.data.message) {
                setModalError([e.response.data.message]);
            }
        }
    };

    const processErrors = (e) => {
        if (
            e.response.data.errors &&
            Object.keys(e.response.data.errors).length > 0
        ) {
            setModalError([Object.values(e.response.data.errors)]);
            return;
        }
        if (e.response.data.message) {
            setModalError([e.response.data.message]);
        }
    };

    const createDirectory = async (name) => {
        setModalError([]);
        const params = {
            name,
            client_id: client.id,
            parent_id: parentId,
        };
        try {
            setIsModalLoading(true);
            await Api.post(`clients/${client.id}/vault/directory`, params);
            refreshVaultEntries();
            addToast(`Folder ${name} created.`, { type: "success" });
            setIsModalLoading(false);
            return true;
        } catch (e) {
            setIsModalLoading(false);
            console.log(e);
            processErrors(e);
        }
    };

    const moveVaultEntry = async (vaultEntry, newParent) => {
        setModalError([]);
        const params = {
            parent_id: newParent ? newParent.id : null,
        };
        try {
            setIsModalLoading(true);
            await Api.put(
                `clients/${client.id}/vault/${vaultEntry.id}`,
                params
            );
            setIsModalLoading(false);
            refreshVaultEntries();
            const toastMessage = newParent
                ? `Moved ${
                      vaultEntry.type === "directory" ? "folder" : "file"
                  } "${vaultEntry.name}" ${
                      parent ? ' from "' + parent.name + '"' : ""
                  } to "${newParent.name}"`
                : `Moved ${
                      vaultEntry.type === "directory" ? "folder" : "file"
                  } "${vaultEntry.name}" ${
                      parent ? ' from "' + parent.name + '"' : ""
                  } to Vault`;
            addToast(toastMessage, { type: "success" });
            return true;
        } catch (e) {
            setIsModalLoading(false);
            console.log(e);
            processErrors(e);
        }
    };

    const deleteVaultEntry = async (vault) => {
        setModalError([]);
        try {
            setIsModalLoading(true);
            await Api.delete(`clients/${client.id}/vault/${vault.id}`);
            refreshVaultEntries();
            addToast(
                `Deleted ${
                    vault.type === "directory" ? "folder" : "file"
                } named "${vault.name}"`,
                { type: "success" }
            );
            setIsModalLoading(false);
            return true;
        } catch (e) {
            setIsModalLoading(false);
            console.log(e);
            processErrors(e);
        }
    };

    const openMove = (vault) => {
        setSelectedVault(vault);
        setShowMove(true);
    };

    const openRename = (vault) => {
        setSelectedVault(vault);
        setShowRename(true);
    };

    const openDescription = (vault) => {
        setSelectedVault(vault);
        setShowDescription(true);
    };

    const openDelete = (vault) => {
        setSelectedVault(vault);
        setShowDeleteVault(true);
    };

    const changeSort = (fieldName) => {
        setSortField(fieldName);
        if (sortField === fieldName) {
            setSortDir(sortDir === "asc" ? "desc" : "asc");
        }
    };

    const OptionsDropdown = ({ vaultEntry }) => {
        return (
            <span className="block w-full">
                <Dropdown
                    position="right"
                    stopPropagation={true}
                    toggleContent={
                        <>
                            <div className="py-1 px-3 w-full block">
                                <FontAwesomeIcon icon={faEllipsisV} />
                            </div>
                        </>
                    }
                >
                    <DropdownItem
                        label="View Details"
                        icon={
                            vaultEntry.type === "directory"
                                ? faFolderOpen
                                : faSearch
                        }
                        handleClick={() => openVault(vaultEntry.id)}
                    />
                    <DropdownItem
                        label="Rename"
                        icon={faICursor}
                        handleClick={() => openRename(vaultEntry)}
                    />
                    {vaultEntry.type === "file" && (
                        <DropdownItem
                            label="Change Description"
                            icon={faTextWidth}
                            handleClick={() => openDescription(vaultEntry)}
                        />
                    )}
                    <DropdownItem
                        label="Move"
                        icon={faArrowsAlt}
                        handleClick={() => openMove(vaultEntry)}
                    />
                    <DropdownItem
                        label="Delete"
                        icon={faTrash}
                        handleClick={() => openDelete(vaultEntry)}
                    />
                </Dropdown>
            </span>
        );
    };

    const selectedStyle = `override border-2 border-blue-500 bg-blue-200`;
    const unselectedStyle = ``;

    const filteredEntries = vaultEntries
        .filter(
            (a) => a.name.toLowerCase().indexOf(searchFilter.toLowerCase()) > -1
        )
        .sort((a, b) => {
            if (a.type === b.type || foldersOnTop === false) {
                return sortDir === "asc"
                    ? a[sortField].localeCompare(b[sortField])
                    : b[sortField].localeCompare(a[sortField]);
            }
            return a.type === "directory" ? -1 : 1;
        });

    let sortIndex = 0;
    if (sortField === "name") sortIndex = sortDir === "asc" ? 0 : 1;
    else if (sortField === "created_at") sortIndex = sortDir === "desc" ? 2 : 3;
    else if (sortField === "updated_at") sortIndex = sortDir === "desc" ? 4 : 5;

    let selectedSort = titleCase(sortField.replace("_", " "));
    if (selectedSort === "Created At") selectedSort = "Date Created";
    if (selectedSort === "Updated At") selectedSort = "Date Updated";

    return (
        <div
            onClick={() => !isAModalOpen && setSelectedVault(null)}
            ref={outsideRef}
        >
            <div className="flex justify-between item-center">
                <Heading>{client.full_name}&apos;s Vault</Heading>
                <div className="flex flex-row">
                    <Button
                        icon={faPlus}
                        text="Add File"
                        onClick={() => {
                            setShowAddFile(true);
                        }}
                    />
                    <div className="mx-1" />
                    <Button
                        icon={faFolderPlus}
                        text="New Folder"
                        onClick={() => {
                            setShowCreateDirectory(true);
                        }}
                    />
                </div>
            </div>
            <div className="my-4">
                <hr />
            </div>
            <div>
                {loading && (
                    <div className="absolute top-0 left-0 w-full h-screen flex items-center justify-center">
                        <Spinner />
                    </div>
                )}

                {!loading && (
                    <Panel allowOverflow={true}>
                        <div className={`mb-4`}>
                            <Breadcrumbs
                                defaultName={"Vault"}
                                rootPath={getRootVaultPath(
                                    client,
                                    context.user
                                )}
                                breadcrumbs={breadcrumbs}
                            />
                        </div>
                        <div className={`mb-4 flex flex-row justify-between`}>
                            {/* fa-sort-alpha-asc */}
                            <SearchInput
                                label={``}
                                searchValue={searchFilter}
                                onSearchChange={(e) =>
                                    setSearchFilter(e.target.value)
                                }
                                sortValue={"" + sortIndex}
                                selectedSort={selectedSort}
                                sortDir={sortDir}
                                sortOptions={[
                                    "Sort Name - A-Z",
                                    "Sort Name - Z-A",
                                    "Newest",
                                    "Oldest",
                                    "Recently Updated",
                                    "Least Recently Updated",
                                ]}
                                onSortChange={(e) => {
                                    switch (parseInt(e.target.value)) {
                                        case 0:
                                            setSortField("name");
                                            setSortDir("asc");
                                            break;
                                        case 1:
                                            setSortField("name");
                                            setSortDir("desc");
                                            break;
                                        case 2:
                                            setSortField("created_at");
                                            setSortDir("desc");
                                            break;
                                        case 3:
                                            setSortField("created_at");
                                            setSortDir("asc");
                                            break;
                                        case 4:
                                            setSortField("updated_at");
                                            setSortDir("desc");
                                            break;
                                        case 5:
                                            setSortField("updated_at");
                                            setSortDir("asc");
                                            break;
                                        default:
                                            break;
                                    }
                                }}
                            />
                            <ButtonGroup
                                options={["List View", "Grid View"]}
                                isLoading={-1}
                                value={viewMode}
                                onClick={(_option, idx) => {
                                    setViewMode(idx);
                                }}
                            />
                        </div>

                        {viewMode === 0 && (
                            <table className="w-full text-left">
                                <thead>
                                    <tr>
                                        <TableSortHeader
                                            sortField={sortField}
                                            sortDir={sortDir}
                                            changeSort={changeSort}
                                            field="name"
                                        >
                                            Name
                                        </TableSortHeader>
                                        <TableSortHeader
                                            sortField={sortField}
                                            sortDir={sortDir}
                                            changeSort={changeSort}
                                            field="created_at"
                                        >
                                            Date Created
                                        </TableSortHeader>
                                        <TableSortHeader
                                            sortField={sortField}
                                            sortDir={sortDir}
                                            changeSort={changeSort}
                                            field="updated_at"
                                        >
                                            Date Updated
                                        </TableSortHeader>
                                        <th>Uploaded By</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {filteredEntries.map((ref, idx) => (
                                        <tr
                                            key={idx}
                                            className={`cursor-pointer ${
                                                selectedVault &&
                                                ref.id === selectedVault.id
                                                    ? selectedStyle
                                                    : unselectedStyle
                                            }`}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                selectVaultEntry(ref);
                                            }}
                                        >
                                            <td>
                                                <>
                                                    <FontAwesomeIcon
                                                        className={`${iconColorForVault(
                                                            ref
                                                        )}`}
                                                        icon={
                                                            ref.type ===
                                                            "directory"
                                                                ? faFolder
                                                                : faFile
                                                        }
                                                    />
                                                    &nbsp;&nbsp;
                                                    {ref.name}
                                                    {ref.extension
                                                        ? "." + ref.extension
                                                        : ""}
                                                </>
                                            </td>
                                            <td>
                                                {formatDate(ref.created_at)}
                                            </td>
                                            <td>
                                                {formatDate(ref.updated_at)}
                                            </td>
                                            <td>{ref.uploaded_by.full_name}</td>
                                            <td className="text-right">
                                                <OptionsDropdown
                                                    vaultEntry={ref}
                                                />
                                            </td>
                                        </tr>
                                    ))}
                                    {vaultEntries.length === 0 && (
                                        <EmptyTableRow colSpan="5">
                                            Empty Folder
                                        </EmptyTableRow>
                                    )}
                                </tbody>
                            </table>
                        )}

                        {viewMode === 1 && (
                            <div
                                className={`flex flex-row mt-8 flex-wrap justify-center`}
                            >
                                {filteredEntries.map((vaultEntry, idx) => {
                                    const isDirectory =
                                        vaultEntry.type === "directory";

                                    const isImage = isFileImage({
                                        extension: vaultEntry.extension,
                                    });

                                    let src = "";
                                    if (isImage) {
                                        src = Api.makeUrl(
                                            `clients/${client.id}/vault/${vaultEntry.id}/download?preview=1`
                                        );
                                    }

                                    const selectedStyle =
                                        "border-blue-500 bg-blue-100";
                                    return (
                                        <div
                                            key={idx}
                                            className={`cursor-pointer w-1/3 lg:w-1/4 relative border border-1 border-gray-200 rounded-md mx-4 mb-4 p-4 flex flex-col justify-center text-center items-center ${
                                                selectedVault &&
                                                vaultEntry.id ===
                                                    selectedVault.id
                                                    ? selectedStyle
                                                    : ""
                                            }`}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                selectVaultEntry(vaultEntry);
                                            }}
                                        >
                                            {isDirectory && (
                                                <FontAwesomeIcon
                                                    className={`${iconColorForVault(
                                                        vaultEntry
                                                    )}`}
                                                    size={"10x"}
                                                    icon={faFolder}
                                                />
                                            )}

                                            {!isDirectory && (
                                                <>
                                                    {isImage && (
                                                        <div>
                                                            {src && (
                                                                <img
                                                                    src={src}
                                                                    className="object-contain h-32 w-32 mx-auto mb-4 rounded border-2 border-gray-200"
                                                                    alt="Preview"
                                                                />
                                                            )}
                                                        </div>
                                                    )}
                                                    {!!isImage !== true && (
                                                        <div className="flex justify-center align-center mb-4 text-gray-400">
                                                            <div className="relative">
                                                                <FontAwesomeIcon
                                                                    className={`${iconColorForVault(
                                                                        vaultEntry
                                                                    )}`}
                                                                    icon={
                                                                        faFile
                                                                    }
                                                                    size="8x"
                                                                />
                                                                <div className="absolute inset-0 flex items-center justify-center">
                                                                    <div className="text-md font-bold text-white">
                                                                        {vaultEntry.extension.toUpperCase()}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}
                                                </>
                                            )}
                                            <div className={`font-bold`}>
                                                {vaultEntry.name}
                                            </div>

                                            <div
                                                className={`text-xs text-gray-600`}
                                            >
                                                Created:{" "}
                                                {formatDate(
                                                    vaultEntry.created_at
                                                )}
                                            </div>

                                            <div
                                                className={`text-xs text-gray-600`}
                                            >
                                                Updated:{" "}
                                                {formatDate(
                                                    vaultEntry.updated_at
                                                )}
                                            </div>

                                            <div className="absolute top-2 right-1">
                                                <OptionsDropdown
                                                    vaultEntry={vaultEntry}
                                                />
                                            </div>
                                        </div>
                                    );
                                })}
                                {vaultEntries.length === 0 && (
                                    <div className="w-full text-center h-8">
                                        Empty Folder
                                    </div>
                                )}
                            </div>
                        )}
                    </Panel>
                )}
            </div>

            <Modal
                isOpen={showCreateDirectory}
                type=""
                title="Create Folder"
                body="Please type in the name of the new folder."
                doneButtonTitle="Create"
                inputType="text"
                inputErrors={modalError}
                isLoading={isModalLoading}
                onResult={async (result) => {
                    setModalError([]);
                    if (typeof result.result !== "string") {
                        setShowCreateDirectory(false);
                        return;
                    }
                    if (await createDirectory(result.result)) {
                        setShowCreateDirectory(false);
                    }
                }}
            />

            <Modal
                isOpen={showRename}
                type=""
                title={`Rename ${
                    selectedVault && selectedVault.type === "directory"
                        ? "Folder"
                        : "File"
                }`}
                body={`Please type in the new name of the ${
                    selectedVault && selectedVault.type === "directory"
                        ? "folder"
                        : "file"
                }.`}
                doneButtonTitle="Save"
                inputType="text"
                inputValue={`${selectedVault ? selectedVault.name : ""}`}
                inputErrors={modalError}
                isLoading={isModalLoading}
                onResult={async (result) => {
                    setModalError([]);
                    if (typeof result.result !== "string") {
                        setShowRename(false);
                        return;
                    }
                    if (await renameVaultEntry(selectedVault, result.result)) {
                        setShowRename(false);
                    }
                }}
            />

            <Modal
                isOpen={showDescription}
                type=""
                title={`Change Description`}
                body={`Please type in the new description of the ${
                    selectedVault && selectedVault.type === "directory"
                        ? "folder"
                        : "file"
                }.`}
                doneButtonTitle="Save"
                inputType="text"
                inputValue={`${
                    selectedVault ? selectedVault.description || "" : ""
                }`}
                inputErrors={modalError}
                isLoading={isModalLoading}
                onResult={async (result) => {
                    setModalError([]);
                    if (typeof result.result !== "string") {
                        setShowDescription(false);
                        return;
                    }
                    if (
                        await updateDescriptionVaultEntry(
                            selectedVault,
                            result.result
                        )
                    ) {
                        setShowDescription(false);
                    }
                }}
            />

            <Modal
                isOpen={showDeleteVault}
                type="warning"
                title={`Delete ${
                    selectedVault && selectedVault.type === "directory"
                        ? "Folder"
                        : "File"
                }`}
                body={`Are you sure you want to delete ${
                    selectedVault && selectedVault.type === "directory"
                        ? "folder"
                        : "file"
                } named ${
                    selectedVault && selectedVault.name
                }. Type in "delete" below to confirm.`}
                inputType="text"
                inputErrors={modalError}
                doneButtonTitle="Delete"
                isLoading={isModalLoading}
                onResult={async (result) => {
                    setModalError([]);
                    if (result.result !== "delete") {
                        if (typeof result.result === "string") {
                            setModalError([
                                'Please confirm deletion by typing in "delete"',
                            ]);
                            return;
                        }
                        setShowDeleteVault(false);
                        return;
                    }
                    if (await deleteVaultEntry(selectedVault)) {
                        setShowDeleteVault(false);
                    }
                }}
            />

            <Modal
                isOpen={showAddFile}
                type=""
                title="Add File"
                component={UploadFile}
                size={"lg"}
                client={client}
                parentId={selectedVault ? selectedVault.id : parentId}
                backgroundDismiss={false}
                onResult={async () => {
                    setShowAddFile(false);
                    refreshVaultEntries();
                    // if (await createDirectory(result.result)) {
                    //     setShowAddFile(false)
                    // }
                }}
            />

            <Modal
                isOpen={showMove}
                type=""
                title={`Move ${
                    selectedVault && selectedVault.type === "directory"
                        ? "Folder"
                        : "File"
                }`}
                body={`Select a new folder below for ${
                    selectedVault && selectedVault.type === "directory"
                        ? "folder"
                        : "file"
                } "${selectedVault && selectedVault.name} "`}
                size="md"
                errors={modalError}
                rootPath={getRootVaultPath(client, context.user)}
                onResult={() => {
                    setShowMove(false);
                }}
                component={() => (
                    <MoveFile
                        client={client}
                        parentId={
                            selectedVault ? selectedVault.parent_id : null
                        }
                        vaultId={selectedVault ? selectedVault.id : null}
                        isLoading={isModalLoading}
                        rootVaultPath={"/"}
                        onResult={async (result) => {
                            setShowMove(false);
                            setModalError([]);
                            if (typeof result.result !== "string") {
                                return;
                            }
                            if (
                                !(await moveVaultEntry(
                                    selectedVault,
                                    result.parent
                                ))
                            ) {
                                setShowMove(true);
                            }
                        }}
                    />
                )}
            />
        </div>
    );
}
