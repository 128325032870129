import React from "react";
import { Switch, Route } from "react-router-dom";
import PersonalPropertyMemoIndex from "./PersonalPropertyMemoIndex";
import AddPersonalPropertyMemo from "./AddPersonalPropertyMemo";
import EditPersonalPropertyMemo from "./EditPersonalPropertyMemo";

export default function PersonalPropertyMemorandums(parentProps) {
    return (
        <Switch>
            <Route
                exact
                path={[
                    "/clients/:clientId/personal-property-memos/add",
                    "/clients/:clientId/personal-property-memos/add/:spouse",
                    // "/clients/:clientId/personal-property-memos/add/:spouse",
                    // "/clients/:clientId/personal-property-memos/add/:client",
                    "/client/personal-property-memos/add",
                    "/client/personal-property-memos/add/:spouse",
                ]}
                render={(props) => (
                    <AddPersonalPropertyMemo {...parentProps} {...props} />
                )}
            />
            <Route
                exact
                path={[
                    "/clients/:clientId/personal-property-memos/:personalPropertyMemoId/edit",
                    "/clients/:clientId/personal-property-memos/:personalPropertyMemoId/edit/:spouse",
                    "/client/personal-property-memos/:personalPropertyMemoId/edit",
                    "/client/personal-property-memos/:personalPropertyMemoId/edit/:spouse",
                ]}
                render={(props) => (
                    <EditPersonalPropertyMemo {...parentProps} {...props} />
                )}
            />
            <Route
                exact
                path={[
                    "/clients/:clientId/personal-property-memos",
                    "/clients/:clientId/personal-property-memos/:spouse",
                    "/client/personal-property-memos",
                    "/client/personal-property-memos/:spouse",
                ]}
                render={(props) => (
                    <PersonalPropertyMemoIndex {...parentProps} {...props} />
                )}
            />
        </Switch>
    );
}
