import React, { useState, useEffect, useCallback, useMemo } from "react";
import {
    fetchFundingEntities,
    fetchRelationships,
    fetchTrusts,
} from "../components/Ownership/OwnershipService";

import useAssets from "hooks/useAssets";
import useClient from "hooks/useClient";
import usePostMortemAssets from "hooks/usePostMortemAssets";
import usePostMortemState from "hooks/usePostMortemState";
import useLiveWealthState from "hooks/useLiveWealthState";

const OwnershipContext = React.createContext();

export const VIEW = {
    CURRENT: "current",
    PROPOSED: "proposed",
};

export default function OwnershipContextProvider({
    children,
    refresh = false,
}) {
    const { person: postMortemPerson, isPostMortem } = usePostMortemState();

    const { client } = useClient();
    const [trusts, setTrusts] = useState([]);
    const [relationships, setRelationships] = useState([]);
    const [businessInterests, setBusinessInterests] = useState([]);
    const [assetOptions, setAssetOptions] = useState([]);
    const [transferAsset, setTransferAsset] = useState(false);
    const [loadingOptions, setLoadingOptions] = useState(false);

    const { current, setCurrent } = useLiveWealthState({ current: true });

    const [verifyAsset, setVerifyAsset] = useState(false);
    const [verifyOwnership, setVerifyOwnership] = useState(false);
    const [verifyTransfer, setVerifyTransfer] = useState(false);

    const getAssetOptionsForResourceType = (resourceType) => {
        const hideOnRetirement = false;
        if (resourceType === "RetirementAccount" && hideOnRetirement) {
            return [];
        }

        return assetOptions;
    };

    const donorOptions = useMemo(
        () =>
            function () {
                if (client.spouse) {
                    return [
                        { value: "client", label: client.full_name },
                        {
                            value: "spouse",
                            label: `${client.spouse.full_name} (Spouse)`,
                        },
                        { value: "joint", label: "Joint with Spouse" },
                    ];
                } else {
                    return [{ value: "client", label: client.full_name }];
                }
            },
        [client]
    );

    const fetchOptions = useCallback(async () => {
        setLoadingOptions(true);
        const trusts = await fetchTrusts({ client });
        const relationships = await fetchRelationships({ client });
        const businessInterests = await fetchFundingEntities({ client });
        setLoadingOptions(false);
        setTrusts(trusts);
        setRelationships(relationships);
        setBusinessInterests(businessInterests);
        setAssetOptions([
            {
                label: "Trusts",
                options: trusts,
            },
            {
                label: "Relationships",
                options: relationships,
            },
            {
                label: "Business Interests",
                options: businessInterests,
            },
        ]);
    }, [client]);

    useEffect(() => {
        fetchOptions();
    }, [client, fetchOptions, refresh]);

    function showTransferOwnershipModal(asset, ownership) {
        setTransferAsset({
            ...asset,
            ownership,
        });
    }

    const {
        data: livingAssets,
        isLoading: loadingLivingAssets,
        isFetching: fetchingLivingAssets,
        refetch: refetchLivingAssets,
    } = useAssets(
        {
            clientId: client.id,
            liabilities: true,
        },
        {
            enabled: !isPostMortem,
            keepPreviousData: false,
        }
    );
    const {
        data: postMortemAssets,
        isLoading: loadingPostMortemAssets,
        isFetching: fetchingPostMortemAssets,
        refetch: refetchPostMortemAssets,
    } = usePostMortemAssets(
        {
            clientId: client.id,
            person: postMortemPerson,
        },
        {
            enabled: !!(isPostMortem && postMortemPerson),
            keepPreviousData: false,
        }
    );

    const assets = isPostMortem ? postMortemAssets : livingAssets;

    const isLoading = isPostMortem
        ? loadingPostMortemAssets
        : loadingLivingAssets;

    const refetchAssets = isPostMortem
        ? refetchPostMortemAssets
        : refetchLivingAssets;

    const isFetching = isPostMortem
        ? fetchingPostMortemAssets
        : fetchingLivingAssets;

    const refreshAssets = async () => {
        refetchAssets();
    };
    const refreshData = async () => {
        refreshAssets();
    };

    return (
        <OwnershipContext.Provider
            value={{
                assets,
                isLoading,
                isFetching,
                refreshAssets,
                trusts,
                relationships,
                businessInterests,
                assetOptions,
                fetchOptions,
                showTransferOwnershipModal,
                transferAsset,
                setTransferAsset,
                donorOptions,
                refreshData,
                current,
                setCurrent,
                verifyAsset,
                setVerifyAsset,
                verifyOwnership,
                setVerifyOwnership,
                verifyTransfer,
                setVerifyTransfer,
                getAssetOptionsForResourceType,
                loadingOptions,
            }}
        >
            {children}
        </OwnershipContext.Provider>
    );
}

export function useOwnershipContext() {
    const context = React.useContext(OwnershipContext);
    if (context === undefined) {
        throw new Error(
            "useOwnershipContext must be used within a OwnershipContextProvider"
        );
    }
    return { ...context };
}
