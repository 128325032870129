import React, { useMemo } from "react";
import ClientPageHeader, { Heading } from "../../components/ClientPageHeader";
import PageContainer from "../../components/PageContainer";
import useFormatCurrency from "../../hooks/useFormatCurrency";
import useFormatPercentage from "../../hooks/useFormatPercentage";
import Spinner from "../../components/Spinner";
import SelectInput from "../../components/SelectInput";
import { formatDate } from "../../util/TextUtil";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExternalLinkAlt, faSync } from "@fortawesome/free-solid-svg-icons";
import Panel from "../../components/Panel";
import useLiveWealthData from "hooks/useLiveWealthData";
import Money, { parseMoney } from "../../components/Money";
import ResourceSelect from "../../components/ResourceSelect";
import Button from "../../components/Button";
import EmptyState from "components/EmptyState";
import useHasAnyPermissions from "hooks/useHasAnyPermissions";
import useClient from "hooks/useClient";
import useLiveWealthState from "hooks/useLiveWealthState";

const ownerOptions = ["ClientSpouse", "Trust", "FundingEntity"];

export default function LiveWealth({ client }) {
    const { clientPath } = useClient();
    document.title = "Wealth Report";

    const {
        loadingAssets,
        assets,
        assetKeys,
        sortKeys,
        totals,
        ownerTotals,
        exportRecords,
        isExporting,
    } = useLiveWealthData({
        ownerInitial: `Client:${client.id}`,
        include_transfer_owners: true,
    });

    const {
        totalsColumn,
        setTotalsColumn,
        current,
        setCurrent,
        owner,
        setOwner,
    } = useLiveWealthState({
        ownerInitial: `Client:${client.id}`,
    });

    console.log(owner);

    const ownerTotalsKey = useMemo(
        function () {
            return totalsColumn === "estate_net_worth"
                ? "net_value_with_death_benefit_sum"
                : "net_value_sum";
        },
        [totalsColumn]
    );

    const totalsHeading = useMemo(
        function () {
            return totalsColumn === "estate_net_worth"
                ? "Estate Net Worth"
                : "Net Worth";
        },
        [totalsColumn]
    );

    const isForbidden = !useHasAnyPermissions([
        "report_wealth_report",
        "report_global_access",
    ]);

    return (
        <>
            <ClientPageHeader client={client}>
                <Heading
                    backTo={`${clientPath}/reports`}
                    backText="All Reports"
                >
                    <div className="flex items-center">Wealth Report</div>
                </Heading>
                <div className="flex flex-row space-x-2">
                    <div>
                        <Button
                            text="Refresh"
                            appearance="outline"
                            icon={faSync}
                            disabled={loadingAssets}
                            onClick={() => window.location.reload()}
                        />
                    </div>
                    {sortKeys?.length > 0 && (
                        <div>
                            <Button
                                text="Download"
                                appearance="outline"
                                width="w-auto"
                                disabled={loadingAssets}
                                isLoading={isExporting}
                                onClick={exportRecords}
                            />
                        </div>
                    )}
                </div>
            </ClientPageHeader>

            <PageContainer forbidden={isForbidden}>
                <>
                    <div className="flex space-x-2">
                        <div className="flex-grow-0 w-96">
                            <ResourceSelect
                                resourceType={ownerOptions}
                                client={client}
                                name="resource"
                                value={owner}
                                emptyState={false}
                                emptyStateLabel={""}
                                onChange={(e) => {
                                    setOwner(e.target.value);
                                }}
                            />
                        </div>
                        <div className="flex-grow-0">
                            <SelectInput
                                name="netWorthToggle"
                                value={totalsColumn}
                                options={[
                                    { value: "net_worth", label: "Net Worth" },
                                    {
                                        value: "estate_net_worth",
                                        label: "Estate Net Worth",
                                    },
                                ]}
                                onChange={(e) => {
                                    setTotalsColumn(e.target.value);
                                }}
                            />
                        </div>
                        <div className="flex-grow-0">
                            <SelectInput
                                name="current"
                                value={current + ""}
                                options={[
                                    { value: "true", label: "Current" },
                                    { value: "false", label: "Proposed" },
                                ]}
                                onChange={(e) => {
                                    setCurrent(e.target.value === "true");
                                }}
                            />
                        </div>
                        <div className="flex-1 text-right">
                            {totalsColumn === "net_worth" && (
                                <div className={"text-right"}>
                                    <div className="text-gray-600">
                                        Total Net Worth
                                    </div>{" "}
                                    <span className="font-bold font-tabular">
                                        <Money value={totals.netWorth} />
                                    </span>
                                </div>
                            )}
                            {totalsColumn === "estate_net_worth" && (
                                <div className={"text-right"}>
                                    <div className="text-gray-600">
                                        Total Estate Net Worth
                                    </div>{" "}
                                    <span className="font-bold font-tabular">
                                        <Money
                                            value={
                                                totals.netWorthWithDeathBenefit
                                            }
                                        />
                                    </span>
                                </div>
                            )}
                        </div>
                    </div>

                    {loadingAssets && (
                        <div className="mt-12 h-full w-full flex items-center justify-center">
                            <Spinner message="Leading wealth report" />
                        </div>
                    )}
                    {!loadingAssets && (
                        <div>
                            {sortKeys && sortKeys.length > 0 && (
                                <>
                                    {sortKeys.map((key, ix) => {
                                        let curAssetKeys = assetKeys[key];
                                        return (
                                            <Panel
                                                key={`ownerGroup_${ix}`}
                                                className="mt-6"
                                            >
                                                <div className="border-b border-gray-200 flex justify-between pb-4">
                                                    <h2 className="text-sm">
                                                        <span className="font-bold text-base">
                                                            {key}
                                                        </span>
                                                    </h2>
                                                    <h2 className={"text-sm"}>
                                                        <span className="font-bold text-base">
                                                            <Money
                                                                value={
                                                                    ownerTotals[
                                                                        key
                                                                    ][
                                                                        ownerTotalsKey
                                                                    ]
                                                                }
                                                            />
                                                        </span>
                                                    </h2>
                                                </div>
                                                <div className="space-y-10">
                                                    {curAssetKeys.map(
                                                        (curKey, idx) => {
                                                            return (
                                                                <table
                                                                    className="text-left w-full"
                                                                    key={`assetTable_${idx}`}
                                                                >
                                                                    {idx ===
                                                                        0 && (
                                                                        <thead>
                                                                            <tr className="text-gray-500 uppercase">
                                                                                <th className="text-xs">
                                                                                    Assets
                                                                                </th>
                                                                                <th className="text-xs">
                                                                                    Act.
                                                                                    Number
                                                                                </th>
                                                                                <th className="text-xs">
                                                                                    As
                                                                                    of
                                                                                    Date
                                                                                </th>
                                                                                <th className="text-xs">
                                                                                    Ownership
                                                                                </th>
                                                                                <th className="text-right text-xs">
                                                                                    {
                                                                                        totalsHeading
                                                                                    }
                                                                                </th>
                                                                            </tr>
                                                                        </thead>
                                                                    )}
                                                                    <tbody>
                                                                        <AssetType
                                                                            idx={
                                                                                idx
                                                                            }
                                                                            assets={
                                                                                assets[
                                                                                    key
                                                                                ][
                                                                                    curKey
                                                                                ]
                                                                            }
                                                                        />
                                                                    </tbody>
                                                                </table>
                                                            );
                                                        }
                                                    )}
                                                </div>
                                            </Panel>
                                        );
                                    })}
                                </>
                            )}
                        </div>
                    )}
                    {sortKeys && sortKeys.length === 0 && !loadingAssets && (
                        <div className="mt-6">
                            <EmptyState>
                                No assets found to display for this wealth
                                report.
                            </EmptyState>
                        </div>
                    )}
                </>
            </PageContainer>
        </>
    );
}

export function AssetType({ assets, idx }) {
    const { totalsColumn } = useLiveWealthState();

    let totalsKey = useMemo(
        function () {
            return totalsColumn === "estate_net_worth"
                ? "owner_estate_net_value"
                : "owner_net_value";
        },
        [totalsColumn]
    );

    let assetEstateTotal = useMemo(
        function () {
            return assets.reduce((a, b) => a + parseMoney(b[totalsKey]), 0);
        },
        [assets, totalsKey]
    );

    const assetType = assets[0].type_formatted;
    return assets.map((asset, i) => {
        return (
            <AssetDetail
                index={i}
                totalAssets={assets.length}
                assetType={assetType}
                key={assetType + "_" + idx + "_" + i}
                asset={asset}
                assetEstateTotal={assetEstateTotal}
            />
        );
    });
}

export function AssetDetail({
    index,
    assetType,
    asset,
    totalAssets,
    assetEstateTotal,
}) {
    const formatCurrency = useFormatCurrency();
    const formatPercentage = useFormatPercentage();

    const { totalsColumn } = useLiveWealthState();

    let totalsKey = useMemo(
        function () {
            return totalsColumn === "estate_net_worth"
                ? "owner_estate_gross_value"
                : "owner_gross_value";
        },
        [totalsColumn]
    );

    let asOfDate = asset.as_of_date;

    return (
        <>
            {index === 0 && (
                <tr className="font-bold">
                    <td colSpan="6" className="py-0 pb-2">
                        {assetType}
                    </td>
                </tr>
            )}
            <tr className="text-gray-600">
                <td className="pb-3 pt-0 w-72">
                    <div>
                        <span>{asset.asset_name}</span>
                        <a
                            href={asset.resource_path}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            &nbsp;
                            <FontAwesomeIcon
                                icon={faExternalLinkAlt}
                                className="text-gray-500 hover:text-brand text-xs"
                            />
                        </a>
                    </div>
                </td>
                <td className="pb-3 pt-0 w-32">{asset.account_number}</td>
                <td className="pb-3 pt-0 w-32">
                    {formatDate(asOfDate, false)}
                </td>
                <td className="pb-3 pt-0 w-32">
                    ({formatPercentage(asset.percentage_owned)})
                </td>
                <td className={"text-right tabular-nums pb-3 pt-0 w-32"}>
                    {asset.is_liability ? (
                        <span className="text-red-500">
                            (<Money value={asset[totalsKey]} />)
                        </span>
                    ) : (
                        <Money value={asset[totalsKey]} />
                    )}
                </td>
            </tr>
            {asset.lien && (
                <tr className="text-gray-600">
                    <td className="pt-0 pb-2">
                        <div className="ml-1 flex items-center space-x-1 -mt-3">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="h-3 w-3 transform rotate-180 inline-block text-gray-500"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                            >
                                <path
                                    fillRule="evenodd"
                                    d="M7.707 3.293a1 1 0 010 1.414L5.414 7H11a7 7 0 017 7v2a1 1 0 11-2 0v-2a5 5 0 00-5-5H5.414l2.293 2.293a1 1 0 11-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                                    clipRule="evenodd"
                                />
                            </svg>
                            <div>
                                <span className="font-semibold">Lien: </span>
                                {asset.lien.name}
                            </div>
                        </div>
                    </td>
                    <td className="pt-0 pb-2"></td>
                    <td className="pt-0 pb-2">
                        {formatDate(asset.lien.balance_as_of_date)}
                    </td>
                    <td className="pt-0 pb-2"></td>
                    <td
                        className={
                            "text-right tabular-nums text-red-500 pt-0 pb-2"
                        }
                    >
                        <span>
                            ${formatCurrency("" + asset.lien.balance_owned)}
                        </span>
                    </td>
                </tr>
            )}
            {index === totalAssets - 1 && (
                <AssetTotals
                    isLiability={asset.is_liability}
                    assetType={assetType}
                    assetEstateTotal={assetEstateTotal}
                />
            )}
        </>
    );
}

export function AssetTotals({ assetType, assetEstateTotal, isLiability }) {
    return (
        <tr className="bg-gray-100">
            <td colSpan={6} className="text-right tabular-nums py-1 rounded-md">
                <span className="text-gray-500 mr-3 font-normal">
                    Total {assetType}:
                </span>
                <span className="text-black font-semibold">
                    {isLiability ? (
                        <span className="text-red-500">
                            (<Money value={assetEstateTotal} />)
                        </span>
                    ) : (
                        <Money value={assetEstateTotal} />
                    )}
                </span>
            </td>
        </tr>
    );
}
