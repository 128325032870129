import { fetchCurrentSubscription } from "services/BillingService";
import useFetch from "./useFetch";

export default function useCurrentSubscription(params, config) {
    return useFetch(
        ["fetchCurrentSubscription", params],
        fetchCurrentSubscription,
        config
    );
}
