import React from "react";
import SaveButton from "../../components/SaveButton";
import { Redirect } from "react-router-dom";
import ClientPageHeader, { Heading } from "../../components/ClientPageHeader";
import PageContainer from "../../components/PageContainer";
import AdvisorProfileForm from "./_partials/AdvisorProfileForm";
import AdvisorNav from "./_partials/AdvisorNav";
import useUpdateAdvisor from "hooks/useUpdateAdvisor";
import useForm from "hooks/useForm";
import Badge from "components/Badge";
import { isBool } from "index";
import ProfilePhoto from "views/members/_partials/ProfilePhoto";
import { useQueryClient } from "react-query";
import useHasAnyPermissions from "hooks/useHasAnyPermissions";

export default function AdvisorProfile({
    client,
    clientPath = null,
    advisor,
    ...props
}) {
    const { updateValue, input, clearUnsavedChanges } = useForm(advisor);
    const {
        onSubmit,
        validationErrors: errors,
        isSuccess,
    } = useUpdateAdvisor(client.id, advisor.id, input);

    const updateAdvisor = (event) => {
        event.preventDefault();
        clearUnsavedChanges();
        onSubmit(event);
    };

    const queryClient = useQueryClient();

    const canEditAdvisor = useHasAnyPermissions([
        "client_related_accounts_administration",
        "firm_client_related_accounts_administration",
    ]);

    React.useEffect(() => {
        document.title = input.full_name
            ? `Edit Advisor: ${input.full_name}`
            : "Edit Advisor";
    }, [input.full_name]);

    if (isSuccess) {
        return <Redirect to={`${clientPath}/advisors`} />;
    }

    return (
        <>
            <ClientPageHeader client={client}>
                <Heading
                    backText="All Advisors"
                    backTo={`${clientPath}/advisors`}
                >
                    <div className="space-x-3">
                        <span>Edit Profile - {advisor.full_name}</span>
                        {isBool(advisor.can_collaborate) && (
                            <Badge color="green">Collaborator</Badge>
                        )}
                    </div>
                </Heading>
                {canEditAdvisor && (
                    <div className="flex-0">
                        <SaveButton onClick={updateAdvisor} />
                    </div>
                )}
            </ClientPageHeader>
            <PageContainer>
                <div className="flex space-x-10">
                    <div className="flex-initial">
                        <AdvisorNav
                            clientPath={clientPath}
                            active={props.history.location.pathname}
                            advisor={advisor}
                        />
                    </div>
                    <div className="flex-1">
                        <form onSubmit={updateAdvisor}>
                            <AdvisorProfileForm
                                advisor={input}
                                updateValue={updateValue}
                                errors={errors}
                                view="update"
                            />
                        </form>
                    </div>
                    <div className="flex-initial">
                        <ProfilePhoto
                            memberId={advisor.id}
                            photo={advisor.profile_photo}
                            readOnly={isBool(advisor.can_collaborate)}
                            upload={() => {
                                queryClient.invalidateQueries("client-advisor");
                            }}
                            postUrl={`clients/${client.id}/advisors/${advisor.id}/photos`}
                        />
                    </div>
                </div>
            </PageContainer>
        </>
    );
}
