import React, { useState, useEffect } from "react";
import Dropdown, { DropdownItem } from "../../../components/Dropdown";
import {
    faBan,
    faCheck,
    faEdit,
    faKey,
    faEllipsisH,
    faUserSecret,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Api from "../../../services/Api";
import { useToasts } from "react-toast-notifications";
import { Link } from "react-router-dom";
import Spinner from "../../../components/Spinner";
import useHasAnyPermissions from "hooks/useHasAnyPermissions";
import useAuth from "hooks/useAuth";
import Badge from "components/Badge";
import ConfirmImpersonationModal from "components/ConfirmImpersonationModal";

function RelationshipsTable({ client, clientPath, showDisabled }) {
    document.title = "Relationships";

    const { user } = useAuth();

    const { addToast } = useToasts();
    const [loading, setLoading] = useState(true);
    const [needsRefreshed, setNeedsRefreshed] = useState(false);
    const [relationships, setRelationships] = useState([]);
    const [needsApprovalPassword, setNeedsApprovalPassword] = useState(false);
    const canManageAuthorizedParties = useHasAnyPermissions([
        "client_related_accounts_administration",
        "firm_client_related_accounts_administration",
    ]);

    const canImpersonateClient = useHasAnyPermissions([
        "impersonate_client",
        "client_impersonate_client",
    ]);

    const fetchData = async () => {
        try {
            let response = await Api.get(
                "clients/" + client.id + "/relationships",
                {
                    "filter[trashed]": showDisabled ? "only" : false,
                }
            );
            setRelationships(response.data.data);
            setLoading(false);
            setNeedsRefreshed(false);
        } catch (error) {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, [client, showDisabled, needsRefreshed]);

    async function removeRelationship(relationship) {
        // TODO replace with nicer confirmation modal.
        if (relationship.estate_planning_roles.length) {
            if (
                !window.confirm(
                    "This relationship has estate planning roles. Are you sure you want to disable them?"
                )
            ) {
                return false;
            }
        }

        try {
            const response = await Api.delete(
                "clients/" + client.id + "/relationships/" + relationship.id
            );
            addToast(response.data.message);
            setNeedsRefreshed(true);
        } catch (e) {
            addToast(e.errorMessage, { type: "error" });
        }
    }

    async function revokeAuthorizedPartyAccess(relationship) {
        try {
            const response = await Api.delete(
                "clients/" +
                    client.id +
                    "/relationships/" +
                    relationship.id +
                    "/authorized-party"
            );
            addToast(response.data.message);
            fetchData();
        } catch (error) {
            addToast(error?.response.data.message, { type: "error" });
        }
    }

    async function restoreAuthorizedPartyAccess(relationship) {
        try {
            const response = await Api.post(
                "clients/" +
                    client.id +
                    "/relationships/" +
                    relationship.id +
                    "/authorized-party"
            );
            addToast(response.data.message);
            fetchData();
        } catch (error) {
            addToast(error?.response.data.message, { type: "error" });
        }
    }

    async function restoreRelationship(relationshipId) {
        try {
            const response = await Api.put(
                "clients/" +
                    client.id +
                    "/relationships/" +
                    relationshipId +
                    "/restore",
                {
                    restore: true,
                }
            );
            addToast(response.data.message);
            setNeedsRefreshed(true);
        } catch (e) {
            addToast(e.errorMessage, { type: "error" });
        }
    }

    const relatedToName = (relationship) => {
        if (relationship.related_to) {
            return relationship.related_to;
        }

        if (client.spouse) {
            if (relationship.spousal_relation === "spouse") {
                return client.spouse.full_name;
            }
            if (relationship.spousal_relation === "both") {
                return client.full_name + " & " + client.spouse.full_name;
            }
        }

        return client.full_name;
    };

    const renderDisableButton = (relationship) => {
        if (relationship.authorized_party && !canManageAuthorizedParties) {
            return <></>;
        }
        if (relationship.disabled_at) {
            return (
                <DropdownItem
                    label="Enable"
                    icon={faCheck}
                    handleClick={() => restoreRelationship(relationship.id)}
                />
            );
        }

        return (
            <DropdownItem
                label="Disable"
                icon={faBan}
                handleClick={() => removeRelationship(relationship)}
            />
        );
    };

    if (loading) {
        return <Spinner />;
    }

    return (
        <>
            {relationships.length ? (
                <table className="w-full text-left">
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Relationship Type</th>
                            <th>Relationship</th>
                            <th>Related To</th>
                            <th>Authorized Party</th>
                            <th className="text-right">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {(user.role === "client_spouse" ||
                            user.role === "client_successor") && (
                            <tr>
                                <td className="space-x-2">
                                    <Link to={`${clientPath}/profile/view`}>
                                        {`${client.full_name}`}
                                    </Link>
                                    <Badge color="brand">Client</Badge>
                                </td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td className="text-right">
                                    <span>
                                        <Dropdown
                                            position="right"
                                            toggleClass="px-3 py-1"
                                            toggleContent={
                                                <FontAwesomeIcon
                                                    icon={faEllipsisH}
                                                />
                                            }
                                        >
                                            <DropdownItem
                                                label="Edit Information"
                                                icon={faEdit}
                                                link={`/clients/${client.id}/edit/profile`}
                                            />
                                            {canImpersonateClient && (
                                                <DropdownItem
                                                    label="Impersonate Client"
                                                    icon={faUserSecret}
                                                    handleClick={() =>
                                                        setNeedsApprovalPassword(
                                                            true
                                                        )
                                                    }
                                                />
                                            )}
                                        </Dropdown>
                                    </span>
                                </td>
                            </tr>
                        )}
                        {relationships.map((relationship, idx) => (
                            <tr
                                key={idx}
                                className={relationship.deleted_at ? "" : ""}
                            >
                                <td>
                                    <Link
                                        to={`${clientPath}/relationships/${relationship.id}/view`}
                                    >
                                        {relationship.type === "organization"
                                            ? relationship.organization_name
                                            : `${relationship.full_name_with_relationship}`}
                                    </Link>
                                </td>
                                <td>{relationship.type_description}</td>
                                <td>
                                    {
                                        relationship.relationship_with_modifier_description
                                    }
                                </td>
                                <td>{relatedToName(relationship)}</td>
                                <td>
                                    {relationship.authorized_party_status ===
                                        "authorized_party" &&
                                        relationship.revoked_at && (
                                            <Badge color="green">
                                                Disabled
                                            </Badge>
                                        )}
                                    {relationship.authorized_party_status ===
                                        "authorized_party" &&
                                        !relationship.revoked_at && (
                                            <Badge color="green">Yes</Badge>
                                        )}
                                    {relationship.authorized_party_status ===
                                        "invite_pending" && (
                                        <Badge color="orange">Pending</Badge>
                                    )}
                                    {relationship.authorized_party_status ===
                                        "disabled" && (
                                        <Badge color="red">Disabled</Badge>
                                    )}
                                    {relationship.authorized_party_status ===
                                        "none" && (
                                        <Badge color="gray">No</Badge>
                                    )}
                                </td>
                                <td className="text-right">
                                    <span>
                                        <Dropdown
                                            position="right"
                                            toggleClass="px-3 py-1"
                                            toggleContent={
                                                <FontAwesomeIcon
                                                    icon={faEllipsisH}
                                                />
                                            }
                                        >
                                            <DropdownItem
                                                label="Edit Information"
                                                icon={faEdit}
                                                link={`${clientPath}/relationships/${relationship.id}/edit`}
                                            />
                                            {relationship.authorized_party && (
                                                <>
                                                    <DropdownItem
                                                        label="Edit Permissions"
                                                        icon={faKey}
                                                        link={`${clientPath}/relationships/${relationship.id}/edit-permissions`}
                                                    />

                                                    <DropdownItem
                                                        label="Revoke Authorized Party Access"
                                                        icon={faBan}
                                                        handleClick={() =>
                                                            revokeAuthorizedPartyAccess(
                                                                relationship
                                                            )
                                                        }
                                                    />
                                                </>
                                            )}
                                            {relationship.revoked_at && (
                                                <DropdownItem
                                                    label="Restore Authorized Party Access"
                                                    icon={faBan}
                                                    handleClick={() =>
                                                        restoreAuthorizedPartyAccess(
                                                            relationship
                                                        )
                                                    }
                                                />
                                            )}
                                            {renderDisableButton(relationship)}
                                        </Dropdown>
                                    </span>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            ) : (
                <div className="text-gray-400">
                    The are no relationships for this client.{" "}
                    {canManageAuthorizedParties && (
                        <Link
                            to={`${clientPath}/relationships/add`}
                            className="underline text-gray-600"
                        >
                            Add Now.
                        </Link>
                    )}
                </div>
            )}

            <ConfirmImpersonationModal
                client={client}
                isOpen={needsApprovalPassword}
                onClose={() => setNeedsApprovalPassword(false)}
            />
        </>
    );
}

export default RelationshipsTable;
