// Used for displaying a single asset on the Estate Inventory screens.

import React from "react";
import Panel from "../Panel";
import { formatDate, titleCase } from "../../util/TextUtil";
import Money from "../Money";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faExternalLinkAlt,
    faQuestionCircle,
} from "@fortawesome/free-solid-svg-icons";
import Spinner from "../Spinner";
import Checkbox from "../Checkbox";
import { OwnershipLineItemReadOnly } from "./partials/OwnershipLineItemReadOnly";
import { Tooltip } from "react-tippy";
import { usePostMortemContext } from "../../views/post_mortem/PostMortemContext";
import usePostMortemState from "hooks/usePostMortemState";
import useAssetValues from "views/funding_table/hooks/useAssetValues";
import Badge from "components/Badge";

export default function AssetDisplayForInclusion({
    client,
    asset,
    ownerships,
    updateInclusion,
    person = "client",
}) {
    const { data: postMortemData } = usePostMortemContext();

    const { showAltValues } = usePostMortemState();

    if (!asset) {
        return <Spinner message="Loading assets..." />;
    }

    // Move to a hook?
    const hasLiability = parseInt(asset.liability_amount) > 0 && asset.has_lien;

    const isSpouse = person === "spouse";

    let hasOverriddenValue = isSpouse
        ? !!asset.dod_value_spouse_original
        : !!asset.dod_value_original;

    if (showAltValues) {
        hasOverriddenValue = isSpouse
            ? !!asset.alt_dod_value_spouse_original
            : !!asset.alt_dod_value_original;
    }

    const disabledForClient =
        asset.dod_value_locked ||
        parseInt(postMortemData.is_client_deceased) === 0 ||
        parseInt(postMortemData.is_client_dod_locked) === 1;

    const disabledForSpouseClient =
        asset.dod_value_spouse_locked ||
        parseInt(postMortemData.is_spouse_deceased) === 0 ||
        parseInt(postMortemData.is_spouse_dod_locked) === 1;

    const isAssetIncluded =
        ownerships?.length > 0 &&
        ownerships.filter((ownership) =>
            isSpouse
                ? ownership.is_included_in_spouse_post_mortem
                : ownership.is_included_in_post_mortem
        ).length > 0;

    if (!isAssetIncluded) {
        hasOverriddenValue = false;
    }

    if (asset.funding_entity && hasOverriddenValue) {
        if (person === "client") {
            hasOverriddenValue = asset.has_dod_value;
        } else {
            hasOverriddenValue = asset.has_dod_value_spouse;
        }
    }

    const {
        asOfDate: currentAsOfDate,
        asOfDateLabel: currentAsOfDateLabel,
        value: currentNetValue,
        valueLabel: currentNetValueLabel,
        gross_value: currentGrossValue,
        balance: currentBalance,
    } = useAssetValues({ asset, allowPostMortem: false });
    const {
        asOfDate: dodAsOfDate,
        asOfDateLabel: dodAsOfDateLabel,
        value: dodNetValue,
        valueLabel: dodValueLabel,
        gross_value: dodGrossValue,
        balance: dodBalance,
    } = useAssetValues({ asset });

    return (
        <Panel
            key={`${asset.type}_${asset.id}`}
            className={asset.has_snapshot ? "opacity-75" : "opacity-100"}
        >
            {asset.has_snapshot && (
                <div className="mb-2 -ml-1">
                    <Badge color="orange">
                        Snapshot taken on {formatDate(asset.snapshot_at)}
                    </Badge>
                </div>
            )}
            <div className="bg-white flex items-center">
                <div className="flex-none mr-10">
                    <div className={`text-xs text-gray-500`}>
                        {titleCase(asset.type, {
                            pascal: true,
                        })}
                    </div>
                    <h2 className="text-sm">
                        <div className="flex items-start">
                            <a
                                href={asset.resource_path}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="hover:text-brand-500 text-gray-600"
                            >
                                <span className="font-bold text-base">
                                    {asset.resource_display_name}
                                </span>
                                &nbsp;
                                <FontAwesomeIcon
                                    icon={faExternalLinkAlt}
                                    className="pb-1"
                                />
                            </a>
                        </div>
                        {asset.account_number && (
                            <span className="text-xs text-gray-500 block mt-1">
                                {asset.account_number}
                            </span>
                        )}
                    </h2>
                </div>
                <div className="ml-auto flex flex-0">
                    <div className="flex-none ml-8 text-right">
                        <div className={`text-xs text-gray-500 space-x-1`}>
                            <span>{currentNetValueLabel}</span>
                            <Tooltip
                                title="Value of asset shown is 100% regardless of percentage included in estate."
                                position="top"
                                duration={150}
                                followCursor={false}
                                distance={16}
                            >
                                <FontAwesomeIcon
                                    icon={faQuestionCircle}
                                    className="text-gray-400"
                                    fixedWidth
                                />
                            </Tooltip>
                        </div>
                        <div className="text-sm tabular-nums">
                            {asset.is_liability ? (
                                <span className="text-red-500">
                                    <Money value={currentNetValue} />
                                </span>
                            ) : (
                                <Money value={currentNetValue} />
                            )}
                        </div>
                    </div>
                    <div className="flex-none ml-8 text-right">
                        <div className={`text-xs text-gray-500`}>
                            {currentAsOfDateLabel}
                        </div>
                        <div className="text-sm">
                            {formatDate(currentAsOfDate)}
                        </div>
                    </div>
                    {hasOverriddenValue && (
                        <>
                            <div className="border-r-2 border-gray-200 ml-8"></div>
                            <div className="flex-none text-right ml-8">
                                <div
                                    className={`text-xs text-gray-500 flex items-center space-x-1`}
                                >
                                    <span>{dodValueLabel}</span>
                                    <Tooltip
                                        title="Value of asset shown is 100% regardless of percentage included in estate."
                                        position="top"
                                        duration={150}
                                        followCursor={false}
                                        distance={16}
                                    >
                                        <FontAwesomeIcon
                                            icon={faQuestionCircle}
                                            className="text-gray-400"
                                            fixedWidth
                                        />
                                    </Tooltip>
                                </div>
                                <div className="text-sm tabular-nums">
                                    {asset.is_liability ? (
                                        <span className="text-red-500">
                                            <Money value={dodGrossValue} />
                                        </span>
                                    ) : (
                                        <Money value={dodNetValue} />
                                    )}
                                </div>
                            </div>
                            <div className="flex-none ml-8 text-right">
                                <div className={`text-xs text-gray-500`}>
                                    {dodAsOfDateLabel}
                                </div>
                                <div className="text-sm">
                                    {formatDate(dodAsOfDate)}
                                </div>
                            </div>
                        </>
                    )}
                </div>
            </div>
            {hasLiability && (
                <>
                    <div className="border-t pt-3 mt-2 border-gray-200 text-gray-600">
                        <div className="flex items-center space-x-1 text-sm">
                            <span>Current Net Equity:</span>
                            <span>{<Money value={currentGrossValue} />}</span>
                            <span>-</span>
                            <span className="text-red-500">
                                {<Money value={currentBalance} />}
                            </span>
                            <span>=</span>
                            <span>
                                <Money value={currentNetValue} />
                            </span>
                        </div>
                    </div>
                    {hasOverriddenValue && (
                        <div className="border-t pt-3 mt-2 border-gray-200 text-gray-600">
                            <div className="flex items-center space-x-1 text-sm">
                                <span>D.O.D Net Equity:</span>
                                <span>{<Money value={dodGrossValue} />}</span>
                                <span>-</span>
                                <span className="text-red-500">
                                    {<Money value={dodBalance} />}
                                </span>
                                <span>=</span>
                                <span>
                                    <Money value={dodNetValue} />
                                </span>
                            </div>
                        </div>
                    )}
                </>
            )}
            {ownerships.length > 0 && (
                <div className="space-y-4 mt-3 pt-3 border-t border-gray-200">
                    {ownerships.map((ownership, idx) => (
                        <div
                            className="flex space-x-3"
                            key={`ownership_${ownership.id}`}
                        >
                            <div className="flex-1">
                                <OwnershipLineItemReadOnly
                                    client={client}
                                    ownership={ownership}
                                    hideLabels={idx > 0}
                                />
                            </div>

                            <div className="flex flex-0 space-x-2 w-36">
                                <div className="flex justify-center space-x-2 w-full">
                                    {person === "spouse" && (
                                        <Checkbox
                                            id={`${asset.type}_${asset.id}_checkbox_spouse`}
                                            name="is_included_in_spouse_post_mortem"
                                            label={
                                                idx === 0
                                                    ? "Include in Spouses Estate"
                                                    : ""
                                            }
                                            appearance="vertical"
                                            inputClass="mt-4"
                                            disabled={disabledForSpouseClient}
                                            checked={
                                                ownership.is_included_in_spouse_post_mortem
                                            }
                                            onChange={(e) => {
                                                updateInclusion(
                                                    e,
                                                    asset,
                                                    ownership
                                                );
                                            }}
                                        />
                                    )}
                                    {person === "client" && (
                                        <Checkbox
                                            id={`${asset.type}_${asset.id}_checkbox`}
                                            name="is_included_in_post_mortem"
                                            label={
                                                idx === 0
                                                    ? "Include in Estate"
                                                    : ""
                                            }
                                            appearance="vertical"
                                            inputClass="mt-4"
                                            disabled={disabledForClient}
                                            checked={
                                                ownership.is_included_in_post_mortem
                                            }
                                            onChange={(e) => {
                                                updateInclusion(
                                                    e,
                                                    asset,
                                                    ownership
                                                );
                                            }}
                                        />
                                    )}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            )}
        </Panel>
    );
}
