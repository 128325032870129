import React from "react";
import VerticalTabs from "../../../components/VerticalTabs";
import useClient from "hooks/useClient";
import useAuth from "hooks/useAuth";

export default function AnnuityTabs({ basePath, ...props }) {
    const { client } = useClient();
    const { user } = useAuth();
    const isDeceased =
        client.post_mortem?.is_client_deceased ||
        client.post_mortem?.is_spouse_deceased;

    return (
        <VerticalTabs
            {...props}
            items={[
                {
                    label: "Info",
                    to: basePath + "",
                },
                {
                    label: "Owners",
                    to: basePath + "/owners",
                },
                {
                    label: "Beneficiaries",
                    to: basePath + "/beneficiaries",
                },
                {
                    label: "Contact",
                    to: basePath + "/contact",
                },
                {
                    label: "Notes",
                    to: basePath + "/notes",
                },
                {
                    label: "Related Files",
                    to: basePath + "/related-files",
                },
                {
                    label: "Access",
                    to: basePath + "/access",
                },

                {
                    label: "Transfers",
                    to: basePath + "/transfers",
                },
                {
                    label: "Estate Administration",
                    to: basePath + "/post-mortem",
                    hidden: !isDeceased,
                },
                {
                    label: "Letters Of Instruction",
                    to: basePath + "/letters-of-instruction",
                    hidden: user.type === "client",
                },
            ]}
        />
    );
}
