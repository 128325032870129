import React from "react";
import useApi from "../../hooks/useApi";
import PageContainer from "../../components/PageContainer";
import ClientPageHeader, { Heading } from "../../components/ClientPageHeader";
import UnsavedChanges from "../../components/UnsavedChanges";
import { useToasts } from "react-toast-notifications";
import SaveButton from "../../components/SaveButton";
import { useHistory } from "react-router";
import AppointmentForm from "./_partials/AppointmentForm";
import { formatDateForInput } from "../../util/TextUtil";

export default function AddAppointment({ client, clientPath }) {
    const history = useHistory();
    const { api, saving, unauthorized } = useApi();
    const { addToast } = useToasts();
    const [errors, setErrors] = React.useState([]);
    const [hasUnsavedChanges, setHasUnsavedChanges] = React.useState(false);
    const [input, setInput] = React.useReducer(
        (state, newState) => ({ ...state, ...newState }),
        {
            member_id: 0,
            client_id: client.id,
            title: "",
            description: "",
            date: formatDateForInput(new Date()),
            hour: 0,
            minute: 0,
            meridiem: "AM",
        }
    );

    const updateValue = React.useCallback((event) => {
        setInput({ [event.target.name]: event.target.value });
        setHasUnsavedChanges(true);
    }, []);

    const createAppointment = async (e) => {
        e.preventDefault();
        try {
            await api("post", `appointments`, input);
            setHasUnsavedChanges(false);
            history.push(`${clientPath}/appointments`);
        } catch (error) {
            console.log(error);
            if (error.response.data.errors) {
                setErrors(error.response.data.errors);
            } else {
                addToast(error.response.data.message, { type: "error" });
            }
        }
    };

    return (
        <>
            <ClientPageHeader client={client}>
                <Heading
                    backTo={`${clientPath}/appointments`}
                    backText="All Appointments"
                >
                    Add Appointment
                </Heading>
                <div className="flex-0">
                    <SaveButton
                        isLoading={saving}
                        onClick={createAppointment}
                    />
                </div>
            </ClientPageHeader>
            <PageContainer forbidden={unauthorized}>
                <UnsavedChanges when={hasUnsavedChanges} />
                <AppointmentForm
                    input={input}
                    errors={errors}
                    updateValue={updateValue}
                    onSubmit={createAppointment}
                />
            </PageContainer>
        </>
    );
}
