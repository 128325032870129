import React from "react";
import { Redirect } from "react-router-dom";
import useClient from "../../hooks/useClient";
import ClientPageHeader, { Heading } from "../../components/ClientPageHeader";
import PageContainer from "../../components/PageContainer";
import ICETabs from "./_partials/ICETabs";
import { Switch } from "react-router-dom";
import { Route } from "react-router-dom";
import ICECards from "./_partials/ICECards";
import SelectInput from "../../components/SelectInput";
import useIceContext from "./hooks/useIceContext";
import ICEVault from "./_partials/ICEVault";
import ICEContacts from "./_partials/ICEContacts";
import IceContactsContextProvider from "./contexts/IceContactsContext";
import useHasAnyPermissions from "hooks/useHasAnyPermissions";
import useAuth from "hooks/useAuth";
import ICEMedical from "./_partials/ICEMedical";

export default function IceWrapper({ ...props }) {
    const { client, clientPath } = useClient();

    const { user } = useAuth();

    const { isSpouse, setIsSpouse } = useIceContext();

    const isForbidden = !useHasAnyPermissions([
        "client_related_accounts_administration",
        "firm_client_related_accounts_administration",
    ]);

    const basePath = `${clientPath}/ice`;
    const currentBasePath = basePath;

    if (
        !isForbidden &&
        user.type !== "member" &&
        props.location.pathname === basePath
    ) {
        return (
            <Redirect
                to={{
                    pathname: `${basePath}/contacts`,
                    from: props.location,
                }}
            />
        );
    }
    return (
        <>
            <ClientPageHeader client={client}>
                <Heading>In Case of Emergency</Heading>
            </ClientPageHeader>
            <PageContainer forbidden={isForbidden}>
                <div className="flex flex-col">
                    {client.spouse && (
                        <div className="flex-none flex justify-start mb-8">
                            <SelectInput
                                label=""
                                type="isSpouse"
                                name="isSpouse"
                                value={isSpouse ? 1 : 0}
                                options={[
                                    { value: 0, label: "Client" },
                                    {
                                        value: 1,
                                        label: "Spouse",
                                    },
                                ]}
                                onChange={(e) =>
                                    setIsSpouse(e.target.value === "1")
                                }
                            />
                        </div>
                    )}
                    <div className="flex">
                        <div className="flex-initial">
                            <ICETabs basePath={currentBasePath} {...props} />
                        </div>
                        <div className="flex-1 ml-6">
                            <Switch>
                                {user.type === "member" && (
                                    <Route
                                        exact={true}
                                        path={[basePath]}
                                        render={() => <ICECards />}
                                    />
                                )}
                                <Route
                                    exact={true}
                                    path={[basePath + "/vault"]}
                                    render={() => <ICEVault />}
                                />
                                <Route
                                    exact={true}
                                    path={[basePath + "/contacts"]}
                                    render={() => (
                                        <IceContactsContextProvider>
                                            <ICEContacts />
                                        </IceContactsContextProvider>
                                    )}
                                />
                                <Route
                                    exact={true}
                                    path={[basePath + "/medical"]}
                                    render={() => <ICEMedical />}
                                />
                            </Switch>
                        </div>
                    </div>
                </div>
            </PageContainer>
        </>
    );
}
