export const processFormErrors = (e) => {
    if (!e.response) {
        return [e.message];
    }
    if (
        e.response.data.errors &&
        Object.keys(e.response.data.errors).length > 0
    ) {
        return [Object.values(e.response.data.errors)];
    }
    if (e.response.data.message) {
        return [e.response.data.message];
    }
};
