import usePost from "./usePost";
import { fetchLiveWealthExport } from "services/LiveWealthService";

export default function useLiveWealthExport(params) {
    const { mutation, validationErrors } = usePost(
        () => fetchLiveWealthExport(params),
        {
            ...params,
            showToastOnError: true,
            showToastOnSuccess: true,
        }
    );
    const onSubmit = (event) => {
        event.preventDefault();
        return mutation.mutate();
    };

    return { onSubmit, ...mutation, validationErrors };
}
