import { faMinusCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Modal from "components/Modal";
import React, { useContext } from "react";
import FrequencySelect from "../../../components/FrequencySelect";
import TextInput from "../../../components/TextInput";
import useFormatCurrency from "../../../hooks/useFormatCurrency";
import { BudgetContext } from "../BudgetContext";

const formatCurrency = useFormatCurrency();

export default function InlineRowItem({
    itemName,
    itemLabel,
    hasJoint,
    hasOtherValue,
    disable = false,
}) {
    const {
        isNegative,
        hasSpouse,
        getValue,
        updateValue,
        options,
        isSaving,
        destroy,
    } = useContext(BudgetContext);

    const [showModal, setShowModal] = React.useState(false);

    return (
        <div className="flex space-x-3 items-center">
            <div className="flex items-center w-2/5">
                <div className="flex-1 px-2">{itemLabel}</div>
                {hasOtherValue !== 0 && (
                    <div className="flex-1">
                        <TextInput
                            name={`${itemName}_other_value`}
                            value={getValue(itemName, "other_value")}
                            disabled={isSaving || disable}
                            onChange={(e) => {
                                updateValue(
                                    itemName,
                                    "other_value",
                                    e.target.value
                                );
                            }}
                        />
                    </div>
                )}
            </div>
            <div className="w-1/5">
                <FrequencySelect
                    name={`${itemName}_frequency_id`}
                    optionItems={options}
                    value={getValue(itemName, "frequency_id")}
                    disabled={isSaving || disable}
                    onChange={(e) => {
                        updateValue(itemName, "frequency_id", e.target.value);
                    }}
                />
            </div>
            <div className="w-1/5">
                <TextInput
                    name={`${itemName}_client_value`}
                    value={getValue(itemName, "client_value")}
                    disabled={isSaving || disable}
                    onChange={(e) => {
                        if (isNegative(e.target.value)) {
                            e.target.value = 0;
                        }
                        updateValue(itemName, "client_value", e.target.value);
                    }}
                    onBlur={(e) => {
                        if (isNegative(e.target.value)) {
                            e.target.value = 0;
                        }
                        if (e.target.value !== "") {
                            e.target.value = formatCurrency(e.target.value);
                            updateValue(
                                itemName,
                                "client_value",
                                e.target.value
                            );
                        }
                    }}
                    placeholder="0.00"
                />
            </div>
            {hasSpouse && (
                <>
                    <div className="w-1/5">
                        <TextInput
                            name={`${itemName}_spouse_value`}
                            value={getValue(itemName, "spouse_value")}
                            disabled={isSaving || disable}
                            onChange={(e) => {
                                if (isNegative(e.target.value)) {
                                    e.target.value = 0;
                                }
                                updateValue(
                                    itemName,
                                    "spouse_value",
                                    e.target.value
                                );
                            }}
                            onBlur={(e) => {
                                if (e.target.value !== "") {
                                    if (isNegative(e.target.value)) {
                                        e.target.value = 0;
                                    }
                                    e.target.value = formatCurrency(
                                        e.target.value
                                    );
                                    updateValue(
                                        itemName,
                                        "spouse_value",
                                        e.target.value
                                    );
                                }
                            }}
                            placeholder="0.00"
                        />
                    </div>
                    <div className="w-1/5">
                        {hasJoint !== 0 && (
                            <TextInput
                                name={`${itemName}_joint_value`}
                                value={getValue(itemName, "joint_value")}
                                disabled={isSaving || disable}
                                onChange={(e) => {
                                    if (isNegative(e.target.value)) {
                                        e.target.value = 0;
                                    }
                                    updateValue(
                                        itemName,
                                        "joint_value",
                                        e.target.value
                                    );
                                }}
                                onBlur={(e) => {
                                    if (e.target.value !== "") {
                                        if (isNegative(e.target.value)) {
                                            e.target.value = 0;
                                        }
                                        e.target.value = formatCurrency(
                                            e.target.value
                                        );
                                        updateValue(
                                            itemName,
                                            "joint_value",
                                            e.target.value
                                        );
                                    }
                                }}
                                placeholder="0.00"
                            />
                        )}
                    </div>
                </>
            )}
            <div
                className="text-red-700 cursor-pointer"
                onClick={() => setShowModal(true)}
            >
                <FontAwesomeIcon icon={faMinusCircle} />
            </div>
            <Modal
                isOpen={showModal}
                onClose={() => setShowModal(false)}
                size="sm"
                body="Are you sure you want to delete this budget item? This cannot be undone."
                title="Remove Budget Item?"
                doneButtonAppearance="danger"
                onResult={(e) => {
                    if (e.result) {
                        destroy(itemName, e.event);
                    }

                    setShowModal(false);
                }}
            ></Modal>
        </div>
    );
}
