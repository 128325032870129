import React, { useState, useEffect } from "react";
import Api from "../../../services/Api";
import { useToasts } from "react-toast-notifications";
import ClientPageHeader, {
    Heading,
} from "../../../components/ClientPageHeader";
import Button from "../../../components/Button";
import SaveButton from "../../../components/SaveButton";
import PageContainer from "../../../components/PageContainer";
import TextInput from "../../../components/TextInput";
import Spinner from "../../../components/Spinner";
import Checkbox, { Radio } from "../../../components/Checkbox";
import TextArea from "../../../components/TextArea";
import SelectInput from "../../../components/SelectInput";
import moment from "moment";
import Tabs from "../../../components/Tabs";
import VaultResourceFileList from "../../resources/VaultResourceFileList";
import InputErrors from "../../../components/InputErrors";
import useHasAnyPermissions from "hooks/useHasAnyPermissions";
import { downloadFile } from "util/Downloader";

const InlineInput = ({ className, ...props }) => {
    return (
        <span className={`inline-block mx-2 ${className}`}>
            <TextInput styleType="inline" {...props} />
        </span>
    );
};

const InlineSelect = ({ className, ...props }) => {
    return (
        <span className={`inline-block mx-2 ${className}`}>
            <SelectInput styleType="inline" {...props} />
        </span>
    );
};

const SectionHeading = (props) => {
    return (
        <h2 className="flex items-start space-x-2 mb-6 mt-20 leading-normal font-bold text-lg">
            <div className="tabular-nums">({props.number})</div>
            <div className="tracking-wide">{props.children}</div>
        </h2>
    );
};

export default function FuneralPlanningForm({ client, ...props }) {
    const defaultData = React.useRef({
        year: new Date().getFullYear(),
        day: moment().format("D"),
        month: moment().format("MMMM"),
    });

    const spouseSelected = props.match.params.spouse === "spouse";
    const [isExporting, setIsExporting] = useState(false);
    const [errors, setErrors] = useState([]);
    const { addToast } = useToasts();
    const [loading, setLoading] = useState(true);

    const [id, setId] = useState(0);
    const [data, setData] = useState({});
    const isForbidden = !useHasAnyPermissions([
        "report_global_access",
        "report_funeral_declaration",
    ]);

    useEffect(() => {
        document.title = "Funeral Planning";
    }, []);

    useEffect(() => {
        async function fetchForm() {
            try {
                setLoading(true);
                let response = await Api.get(
                    "clients/" + client.id + "/funeral-planning-form",
                    {
                        is_spouse: spouseSelected,
                    }
                );

                if (response.data.data) {
                    response.data.data["month"] = response.data.data["month"]
                        ? response.data.data["month"]
                        : "January";
                    response.data.data["year"] = response.data.data["year"]
                        ? response.data.data["year"]
                        : new Date().getFullYear();
                    setData({
                        ...defaultData.current,
                        ...(response.data.data || {}),
                    });
                } else {
                    setData({});
                }

                setId(response.data.id);
                setLoading(false);
            } catch (e) {
                if (e.response && e.response.status === 401) {
                    alert(e.response.data.message);
                }
            }
        }
        fetchForm();
    }, [client.id, defaultData, spouseSelected]);

    const updateValue = (e) => {
        const { name, value } = e.target;
        setData((data) => {
            return {
                ...data,
                ...{ [name]: value },
            };
        });
    };

    async function update(e) {
        e.preventDefault();
        try {
            let response = await Api.put(
                "clients/" + client.id + "/funeral-planning-form",
                { data, is_spouse: spouseSelected }
            );
            addToast(response.data.message);
            setErrors([]);
        } catch (e) {
            setErrors([]);
            if (e.response.data.errors) {
                setErrors(e.response.data.errors);
            } else {
                addToast(e.response.data.message, { type: "error" });
            }
        }
    }

    function resetDayIfNeeded(e) {
        if (e.target.name !== "month" && e.target.name !== "year") return;
        let year =
            e.target.name === "year"
                ? e.target.value
                : data.year || new Date().getFullYear();
        let month =
            e.target.name === "month"
                ? moment().month(e.target.value).format("M")
                : data.month
                ? moment().month(data.month).format("M")
                : new Date().getMonth() + 1;
        let lastDayOfMonth = new Date(year, month, 0).getDate();
        if (parseInt(data.day) > parseInt(lastDayOfMonth)) {
            updateValue({ target: { name: "day", value: "" } });
        }
    }

    function getDaysOfMonth() {
        let arr = [];
        let year = data.year || new Date().getFullYear();
        let month = data.month
            ? moment().month(data.month).format("M")
            : new Date().getMonth() + 1;
        let lastDayOfMonth = new Date(year, month, 0).getDate();
        for (let i = 1; i <= lastDayOfMonth; i += 1) {
            arr.push(i);
        }
        return arr;
    }

    function getMonths() {
        return [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December",
        ];
    }

    function getYears() {
        let startingYear = new Date().getFullYear();
        let rangeYears = 200;
        let arr = [];
        for (let i = startingYear - rangeYears; i <= startingYear; i += 1) {
            arr.push(i);
        }
        return arr;
    }

    async function exportToPDF(e) {
        e.preventDefault();
        try {
            setIsExporting(true);
            let response = await Api.get(
                "clients/" + client.id + "/funeral-planning-form",
                {
                    export: "pdf",
                    is_spouse: spouseSelected,
                },
                { responseType: "arraybuffer" }
            );
            await downloadFile(response, "funeral-declaration");
            setIsExporting(false);
            addToast("Export downloaded");
        } catch (e) {
            addToast(e.response.data.message, { type: "error" });
            setIsExporting(false);
        }
    }

    return (
        <>
            <ClientPageHeader client={client}>
                <Heading
                    backTo={`${props.clientPath}/forms`}
                    backText="All Forms"
                >
                    Funeral Planning Declaration
                </Heading>
                {isForbidden === false && (
                    <div className="flex-0 flex space-x-2 w-64">
                        <Button
                            onClick={exportToPDF}
                            text="Download"
                            appearance="light"
                            isLoading={isExporting}
                        />
                        <SaveButton onClick={update} />
                    </div>
                )}
            </ClientPageHeader>
            <PageContainer forbidden={isForbidden}>
                {client.spouse && (
                    <div className="block print:hidden">
                        <Tabs
                            {...props}
                            items={[
                                {
                                    label: `${client.full_name}'s Form`,
                                    to:
                                        props.clientPath +
                                        "/forms/funeral-planning/edit",
                                    active: !spouseSelected,
                                },
                                {
                                    label: `${client.spouse.full_name}'s Form`,
                                    to:
                                        props.clientPath +
                                        "/forms/funeral-planning/edit/spouse",
                                    active: spouseSelected,
                                },
                            ]}
                        />
                    </div>
                )}
                <div>
                    {loading ? (
                        <Spinner />
                    ) : (
                        <form
                            onSubmit={update}
                            className="max-w-4xl mx-auto printableForm"
                        >
                            <div className="text-center mb-4 font-bold text-lg print:block hidden">
                                Funeral Planning Declaration
                            </div>
                            <div className="mb-6 leading-10">
                                Declaration made this{" "}
                                <InlineSelect
                                    options={getDaysOfMonth()}
                                    name="day"
                                    value={data["day"]}
                                    defaultOption={"Select Day"}
                                    defaultOptionDisabled={true}
                                    onChange={(e) => updateValue(e)}
                                    className="w-20"
                                    error={errors["data.day"]}
                                />
                                day of{" "}
                                <InlineSelect
                                    options={getMonths()}
                                    name="month"
                                    value={data["month"]}
                                    defaultOption={"Select Month"}
                                    defaultOptionDisabled={true}
                                    onChange={(e) => {
                                        updateValue(e);
                                        resetDayIfNeeded(e);
                                    }}
                                    className="w-24"
                                    error={errors["data.month"]}
                                />
                                ,{" "}
                                <InlineSelect
                                    options={getYears()}
                                    name="year"
                                    value={data["year"]}
                                    onChange={(e) => {
                                        updateValue(e);
                                        resetDayIfNeeded(e);
                                    }}
                                    className="w-20 ml-0"
                                    error={errors["data.year"]}
                                />
                                . <br />
                                I,{" "}
                                <InlineInput
                                    name="full_name"
                                    value={data["full_name"]}
                                    onChange={(e) => updateValue(e)}
                                    error={errors["data.full_name"]}
                                    className="w-96"
                                />
                                ,<br />
                                being at least eighteen (18) years of age and of
                                sound mind, willfully and voluntarily make known
                                my instructions concerning funeral services,
                                ceremonies, and the disposition of my remains
                                after my death.
                            </div>

                            <div className="mb-6 leading-10">
                                I hereby declare and direct that after my death{" "}
                                <InlineInput
                                    name="designee_name"
                                    value={data["designee_name"]}
                                    onChange={(e) => updateValue(e)}
                                    error={errors["data.designee_name"]}
                                    className="w-96"
                                />{" "}
                                (name of designee) shall, as my designee, carry
                                out the instructions that are set forth in this
                                declaration. If my designee is unwilling or
                                unable to act, I nominate{" "}
                                <InlineInput
                                    name="alt_designee_name"
                                    value={data["alt_designee_name"]}
                                    onChange={(e) => updateValue(e)}
                                    error={errors["data.alt_designee_name"]}
                                    className="w-96"
                                />
                                as an alternate designee.
                            </div>

                            <div className="mb-6 leading-10">
                                I hereby declare and direct that after my death
                                the following actions be taken (indicate your
                                choice by initialing or making your mark before
                                signing this declaration):
                            </div>

                            <div className="mb-20 leading-10 page-break-inside-avoid">
                                <SectionHeading number="1">
                                    My body shall be:
                                </SectionHeading>
                                <div className="pl-10">
                                    <ul>
                                        <li className="mb-6">
                                            <div className="flex items-start space-x-2">
                                                <div>(a)</div>
                                                <div className="flex-1">
                                                    <div className="flex items-center space-x-2">
                                                        <Radio
                                                            id="post_mortem_buried"
                                                            name="post_mortem_process"
                                                            checked={
                                                                data.post_mortem_process ===
                                                                "buried"
                                                            }
                                                            value="buried"
                                                            onChange={(e) =>
                                                                updateValue(e)
                                                            }
                                                        />
                                                        <label htmlFor="post_mortem_buried">
                                                            Buried. I direct
                                                            that my body be
                                                            buried at:
                                                        </label>
                                                    </div>
                                                    {data.post_mortem_process ===
                                                        "buried" && (
                                                        <TextArea
                                                            rows="2"
                                                            name="post_mortem_buried_directions"
                                                            value={
                                                                data[
                                                                    "post_mortem_buried_directions"
                                                                ]
                                                            }
                                                            onChange={(e) =>
                                                                updateValue(e)
                                                            }
                                                            className="flex-1"
                                                            error={
                                                                errors[
                                                                    "data.post_mortem_buried"
                                                                ]
                                                            }
                                                        />
                                                    )}
                                                </div>
                                            </div>
                                        </li>

                                        <li className="mb-6">
                                            <div className="flex items-start space-x-2">
                                                <div>(b)</div>
                                                <div className="flex-1">
                                                    <div className="flex items-center space-x-2">
                                                        <Radio
                                                            id="post_mortem_cremated"
                                                            name="post_mortem_process"
                                                            checked={
                                                                data.post_mortem_process ===
                                                                "cremated"
                                                            }
                                                            value="cremated"
                                                            onChange={(e) =>
                                                                updateValue(e)
                                                            }
                                                        />
                                                        <label htmlFor="post_mortem_cremated">
                                                            Cremated. I direct
                                                            that my cremated
                                                            remains be disposed
                                                            of as follows:
                                                        </label>
                                                    </div>
                                                    {data.post_mortem_process ===
                                                        "cremated" && (
                                                        <TextArea
                                                            rows="2"
                                                            name="post_mortem_cremated_directions"
                                                            value={
                                                                data[
                                                                    "post_mortem_cremated_directions"
                                                                ]
                                                            }
                                                            onChange={(e) =>
                                                                updateValue(e)
                                                            }
                                                            className="flex-1"
                                                            error={
                                                                errors[
                                                                    "data.post_mortem_cremated_directions"
                                                                ]
                                                            }
                                                        />
                                                    )}
                                                </div>
                                            </div>
                                        </li>

                                        <li className="mb-6">
                                            <div className="flex items-start space-x-2">
                                                <div>(c)</div>
                                                <div className="flex-1">
                                                    <div className="flex items-center space-x-2">
                                                        <Radio
                                                            id="post_mortem_entombed"
                                                            name="post_mortem_process"
                                                            checked={
                                                                data.post_mortem_process ===
                                                                "entombed"
                                                            }
                                                            value="entombed"
                                                            onChange={(e) =>
                                                                updateValue(e)
                                                            }
                                                        />
                                                        <label htmlFor="post_mortem_entombed">
                                                            Entombed. I direct
                                                            that my body be
                                                            entombed at:{" "}
                                                        </label>
                                                    </div>
                                                    {data.post_mortem_process ===
                                                        "entombed" && (
                                                        <TextArea
                                                            rows="2"
                                                            name="post_mortem_entombed_directions"
                                                            value={
                                                                data[
                                                                    "post_mortem_entombed_directions"
                                                                ]
                                                            }
                                                            onChange={(e) =>
                                                                updateValue(e)
                                                            }
                                                            className="flex-1"
                                                            error={
                                                                errors[
                                                                    "data.post_mortem_entombed_directions"
                                                                ]
                                                            }
                                                        />
                                                    )}
                                                </div>
                                            </div>
                                        </li>

                                        <li className="mb-6">
                                            <div className="flex items-start space-x-2">
                                                <div>(d)</div>
                                                <div className="flex-1">
                                                    <div className="flex items-center space-x-2">
                                                        <Radio
                                                            id="post_mortem_other"
                                                            name="post_mortem_process"
                                                            checked={
                                                                data.post_mortem_process ===
                                                                "other"
                                                            }
                                                            value="other"
                                                            onChange={(e) =>
                                                                updateValue(e)
                                                            }
                                                        />
                                                        <label htmlFor="post_mortem_other">
                                                            Other
                                                        </label>
                                                    </div>
                                                    {data.post_mortem_process ===
                                                        "other" && (
                                                        <TextArea
                                                            rows="2"
                                                            name="post_mortem_other_directions"
                                                            value={
                                                                data[
                                                                    "post_mortem_other_directions"
                                                                ]
                                                            }
                                                            onChange={(e) =>
                                                                updateValue(e)
                                                            }
                                                            className="flex-1"
                                                            error={
                                                                errors[
                                                                    "data.post_mortem_other_directions"
                                                                ]
                                                            }
                                                        />
                                                    )}
                                                </div>
                                            </div>
                                        </li>

                                        <li className="mb-6 flex items-start space-x-2">
                                            <div className="flex items-center space-x-2">
                                                <div>(e)</div>
                                                <Radio
                                                    id="post_mortem_no_preference"
                                                    name="post_mortem_process"
                                                    checked={
                                                        data.post_mortem_process ===
                                                        "no_preference"
                                                    }
                                                    value="no_preference"
                                                    onChange={(e) =>
                                                        updateValue(e)
                                                    }
                                                />
                                            </div>
                                            <label htmlFor="post_mortem_no_preference">
                                                I intentionally make no decision
                                                concerning the disposition of my
                                                body, leaving the decision to my
                                                designee (as named above).
                                            </label>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <div className="mb-10 leading-10 page-break-inside-avoid page-break-after-always">
                                <SectionHeading number="2">
                                    My arrangements shall be made as follows:
                                </SectionHeading>
                                <div className="pl-10">
                                    <ul>
                                        <li className="mb-6">
                                            <div className="flex items-start space-x-2">
                                                <div>(a)</div>
                                                <div className="flex-1">
                                                    <div className="flex items-center space-x-2">
                                                        <Checkbox
                                                            id="has_arrangements_service_location"
                                                            name="has_arrangements_service_location"
                                                            checked={parseInt(
                                                                data.has_arrangements_service_location ||
                                                                    0
                                                            )}
                                                            value="other"
                                                            onChange={(e) =>
                                                                updateValue(e)
                                                            }
                                                        />
                                                        <label htmlFor="has_arrangements_service_location">
                                                            I direct that
                                                            funeral services be
                                                            obtained from:
                                                        </label>
                                                    </div>
                                                    {parseInt(
                                                        data.has_arrangements_service_location
                                                    ) === 1 && (
                                                        <TextArea
                                                            rows="2"
                                                            name="arrangements_service_location"
                                                            value={
                                                                data[
                                                                    "arrangements_service_location"
                                                                ]
                                                            }
                                                            onChange={(e) =>
                                                                updateValue(e)
                                                            }
                                                            className="flex-1"
                                                            error={
                                                                errors[
                                                                    "data.arrangements_service_location"
                                                                ]
                                                            }
                                                        />
                                                    )}
                                                </div>
                                            </div>
                                        </li>

                                        <li className="mb-6">
                                            <div className="flex items-start space-x-2">
                                                <div>(b)</div>
                                                <div className="flex-1">
                                                    <div className="flex items-center space-x-2">
                                                        <Checkbox
                                                            id="has_arrangements_ceremonies"
                                                            name="has_arrangements_ceremonies"
                                                            checked={parseInt(
                                                                data.has_arrangements_ceremonies ||
                                                                    0
                                                            )}
                                                            value="other"
                                                            onChange={(e) =>
                                                                updateValue(e)
                                                            }
                                                        />
                                                        <label htmlFor="has_arrangements_ceremonies">
                                                            I direct that the
                                                            following ceremonial
                                                            arrangements be
                                                            made:
                                                        </label>
                                                    </div>
                                                    {parseInt(
                                                        data.has_arrangements_ceremonies
                                                    ) === 1 && (
                                                        <TextArea
                                                            rows="2"
                                                            name="arrangements_ceremonies"
                                                            value={
                                                                data[
                                                                    "arrangements_ceremonies"
                                                                ]
                                                            }
                                                            onChange={(e) =>
                                                                updateValue(e)
                                                            }
                                                            className="flex-1"
                                                            error={
                                                                errors[
                                                                    "data.arrangements_ceremonies"
                                                                ]
                                                            }
                                                        />
                                                    )}
                                                </div>
                                            </div>
                                        </li>

                                        <li className="mb-6">
                                            <div className="flex items-start space-x-2">
                                                <div>(c)</div>
                                                <div className="flex-1">
                                                    <div className="flex items-center space-x-2">
                                                        <Checkbox
                                                            id="has_arrangements_grave_memorial"
                                                            name="has_arrangements_grave_memorial"
                                                            checked={parseInt(
                                                                data.has_arrangements_grave_memorial ||
                                                                    0
                                                            )}
                                                            value="other"
                                                            onChange={(e) =>
                                                                updateValue(e)
                                                            }
                                                        />
                                                        <label htmlFor="has_arrangements_grave_memorial">
                                                            I direct the
                                                            selection of a grave
                                                            memorial that:
                                                        </label>
                                                    </div>
                                                    {parseInt(
                                                        data.has_arrangements_grave_memorial
                                                    ) === 1 && (
                                                        <TextArea
                                                            rows="2"
                                                            name="arrangements_grave_memorial"
                                                            value={
                                                                data[
                                                                    "arrangements_grave_memorial"
                                                                ]
                                                            }
                                                            onChange={(e) =>
                                                                updateValue(e)
                                                            }
                                                            className="flex-1"
                                                            error={
                                                                errors[
                                                                    "data.arrangements_grave_memorial"
                                                                ]
                                                            }
                                                        />
                                                    )}
                                                </div>
                                            </div>
                                        </li>

                                        <li className="mb-6">
                                            <div className="flex items-start space-x-2">
                                                <div className="flex items-center space-x-2">
                                                    <div>(d)</div>
                                                    <Checkbox
                                                        id="has_arrangements_property"
                                                        name="has_arrangements_property"
                                                        checked={parseInt(
                                                            data.has_arrangements_property ||
                                                                0
                                                        )}
                                                        value="other"
                                                        onChange={(e) =>
                                                            updateValue(e)
                                                        }
                                                    />
                                                </div>
                                                <div>
                                                    <label htmlFor="has_arrangements_property">
                                                        I direct that the
                                                        following merchandise
                                                        and other property be
                                                        selected for the
                                                        disposition of my
                                                        remains, my funeral or
                                                        other ceremonial
                                                        arrangements:
                                                    </label>
                                                    {parseInt(
                                                        data.has_arrangements_property
                                                    ) === 1 && (
                                                        <TextArea
                                                            rows="2"
                                                            name="arrangements_property"
                                                            value={
                                                                data[
                                                                    "arrangements_property"
                                                                ]
                                                            }
                                                            onChange={(e) =>
                                                                updateValue(e)
                                                            }
                                                            className="flex-1"
                                                            error={
                                                                errors[
                                                                    "data.arrangements_property"
                                                                ]
                                                            }
                                                        />
                                                    )}
                                                </div>
                                            </div>
                                        </li>

                                        <li className="mb-6">
                                            <InputErrors
                                                errors={
                                                    errors[
                                                        "data.has_arrangements_designee"
                                                    ]
                                                }
                                            />
                                            <div className="flex items-start space-x-2">
                                                <div className="flex items-center space-x-2">
                                                    <div>(e)</div>
                                                    <Checkbox
                                                        id="has_arrangements_designee"
                                                        name="has_arrangements_designee"
                                                        checked={parseInt(
                                                            data.has_arrangements_designee ||
                                                                0
                                                        )}
                                                        value="other"
                                                        onChange={(e) =>
                                                            updateValue(e)
                                                        }
                                                    />
                                                </div>
                                                <div>
                                                    <label htmlFor="has_arrangements_designee">
                                                        I direct that my
                                                        designee (as named
                                                        above) make all
                                                        arrangements concerning
                                                        ceremonies and other
                                                        funeral services.
                                                    </label>
                                                    {parseInt(
                                                        data.has_arrangements_designee
                                                    ) === 1 && (
                                                        <TextArea
                                                            rows="2"
                                                            name="arrangements_designee"
                                                            value={
                                                                data[
                                                                    "arrangements_designee"
                                                                ]
                                                            }
                                                            onChange={(e) =>
                                                                updateValue(e)
                                                            }
                                                            className="flex-1"
                                                            error={
                                                                errors[
                                                                    "data.arrangements_designee"
                                                                ]
                                                            }
                                                        />
                                                    )}
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <div className="mb-10 leading-10 page-break-inside-avoid">
                                <SectionHeading number="3">
                                    In addition to the instructions listed
                                    above, I request the following:
                                </SectionHeading>
                                <div className="pl-10">
                                    <TextArea
                                        rows="3"
                                        name="arrangements_other_requests"
                                        value={
                                            data["arrangements_other_requests"]
                                        }
                                        onChange={(e) => updateValue(e)}
                                        className="flex-1"
                                        error={
                                            errors[
                                                "data.arrangements_other_requests"
                                            ]
                                        }
                                    />
                                </div>
                            </div>

                            <div className="mb-10 leading-10 page-break-inside-avoid">
                                <SectionHeading number="4">
                                    If it is impossible to make arrangements
                                    specified in subdivisions 1 through 3
                                    because:
                                </SectionHeading>
                                <div className="pl-10 mb-6">
                                    <ul>
                                        <li className="flex items-start space-x-2 mb-4">
                                            <div>(a)</div>
                                            <div>
                                                a funeral home or other service
                                                provider is out of business,
                                                impossible to locate, or
                                                otherwise unable to provide the
                                                specified service; or
                                            </div>
                                        </li>
                                        <li className="flex items-start space-x-2">
                                            <div>(b)</div>
                                            <div>
                                                the specified arrangement is
                                                impossible, impractical, or
                                                illegal;
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <div className="page-break-inside-avoid mt-20">
                                <div className="mb-6">
                                    I direct my designee to make alternate
                                    arrangements to the best of the
                                    designee&apos;s ability.
                                </div>
                                <div className="mb-6">
                                    It is my intention that this declaration be
                                    honored by my family and others as the final
                                    expression of my intentions concerning my
                                    funeral and the disposition of my body after
                                    my death. I understand the full import of
                                    this declaration.
                                </div>

                                <div className="mt-20 mb-12">
                                    <div className="mb-6">
                                        Signed{" "}
                                        <InlineInput
                                            name="signature"
                                            value={data["signature"]}
                                            onChange={(e) => updateValue(e)}
                                            error={errors["data.signature"]}
                                            className="w-96"
                                        />
                                    </div>
                                    <div className="mb-6">
                                        Printed Name{" "}
                                        <InlineInput
                                            name="printed_name"
                                            value={data["printed_name"]}
                                            onChange={(e) => updateValue(e)}
                                            error={errors["data.printed_name"]}
                                            className="w-96"
                                        />
                                    </div>
                                </div>

                                <div className="leading-10">
                                    The declarant is personally known to me, and
                                    I believe the declarant to be of sound mind.
                                    I did not sign the declarant&apos;s
                                    signature above for or at the direction of
                                    the declarant. I am not a parent, spouse, or
                                    child of the declarant. I am not entitled to
                                    any part of the declarant&apos;s estate. I
                                    am competent and at least eighteen (18)
                                    years of age.
                                </div>

                                <div className="mt-12">
                                    <div className="mb-8 flex justify-between items-center">
                                        <div>
                                            Witness{" "}
                                            <InlineInput
                                                name="witness_1"
                                                value={data["witness_1"]}
                                                onChange={(e) => updateValue(e)}
                                                error={errors["data.witness_1"]}
                                                className="w-96"
                                            />
                                        </div>
                                        <div>
                                            Date{" "}
                                            <InlineInput
                                                type="date"
                                                name="witness_1_date"
                                                value={data["witness_1_date"]}
                                                onChange={(e) => updateValue(e)}
                                                error={
                                                    errors[
                                                        "data.witness_1_date"
                                                    ]
                                                }
                                            />
                                        </div>
                                    </div>
                                    <div className="mb-6 flex justify-between items-center">
                                        <div>
                                            Witness{" "}
                                            <InlineInput
                                                name="witness_2"
                                                value={data["witness_2"]}
                                                onChange={(e) => updateValue(e)}
                                                error={errors["data.witness_2"]}
                                                className="w-96"
                                            />
                                        </div>
                                        <div>
                                            Date{" "}
                                            <InlineInput
                                                type="date"
                                                name="witness_2_date"
                                                value={data["witness_2_date"]}
                                                onChange={(e) => updateValue(e)}
                                                error={
                                                    errors[
                                                        "data.witness_2_date"
                                                    ]
                                                }
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    )}
                </div>
                <br />
                <br />
                {id > 0 && (
                    <div className="print:hidden block mt-8">
                        <VaultResourceFileList
                            client={client}
                            title={"Related Documents"}
                            resourceType={"Form"}
                            resourceId={id}
                        />
                    </div>
                )}
            </PageContainer>
        </>
    );
}
