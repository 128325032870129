import React, { useEffect } from "react";
import LoginSplash from "../login/LoginSplash";
import Logo from "../login/_partials/Logo";
import TextInput from "../../components/TextInput";
import Button from "../../components/Button";
import { Link } from "react-router-dom";
import useIceGuest from "./useIceGuest";
import useAuth from "../../hooks/useAuth";
import PoweredBy from "components/PoweredBy";

export default function ICEGuestLogin() {
    const {
        accessCode,
        setAccessCode,
        memberId,
        setMemberId,
        isLoggingIn,
        errors,
        errorMessage,
        message,
        iceLogin,
    } = useIceGuest();

    const { settings } = useAuth();

    useEffect(() => {
        document.title = "ICE Login";
    }, []);

    return (
        <>
            <div className="h-auto min-h-screen flex">
                <div className="relative bg-white text-gray-700 w-full flex-initial flex items-center justify-center md:max-w-xl">
                    <div className="flex flex-col h-full max-w-lg w-full">
                        <div className="flex items-center justify-center flex-1">
                            <div className="flex-none">
                                <Logo
                                    organization={settings.organization}
                                    message="ICE Login"
                                />
                                {message && (
                                    <div className="max-w-xs mb-3 p-3 text-center rounded-md bg-green-200 text-green-800">
                                        {message}
                                    </div>
                                )}
                                {errorMessage && (
                                    <div className="max-w-xs text-red-600 my-3 text-center">
                                        {errorMessage}
                                    </div>
                                )}
                                <form
                                    onSubmit={iceLogin}
                                    className="max-w-xs mx-auto w-full"
                                >
                                    <div className="mb-6 relative flex space-x-2 items-center">
                                        <span className="text-gray-500">
                                            <svg
                                                className="h-6 w-6"
                                                fill="none"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth="2"
                                                viewBox="0 0 24 24"
                                                stroke="currentColor"
                                            >
                                                <path d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"></path>
                                            </svg>
                                        </span>
                                        <div className="flex-1">
                                            <TextInput
                                                className="border-b border-gray-400 outline-none h-10 w-full px-1"
                                                name="member_id"
                                                type="member_id"
                                                styleType={false}
                                                id="member_id"
                                                value={memberId}
                                                onChange={(e) =>
                                                    setMemberId(e.target.value)
                                                }
                                                error={errors.member_id}
                                                autoFocus={true}
                                                placeholder="Member ID"
                                            />
                                        </div>
                                    </div>

                                    <div className="mb-6 relative flex space-x-2 items-center">
                                        <span className="text-gray-500">
                                            <svg
                                                className="h-6 w-6"
                                                fill="none"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth="2"
                                                viewBox="0 0 24 24"
                                                stroke="currentColor"
                                            >
                                                <path d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z"></path>
                                            </svg>
                                        </span>
                                        <div className="flex-1">
                                            <TextInput
                                                className="border-b border-gray-400 outline-none h-10 w-full px-1"
                                                styleType={false}
                                                name="member_code"
                                                id="member_code"
                                                type="password"
                                                value={accessCode}
                                                onChange={(e) =>
                                                    setAccessCode(
                                                        e.target.value
                                                    )
                                                }
                                                error={errors.member_code}
                                                placeholder="Member Code"
                                            />
                                        </div>
                                    </div>

                                    <div className="w-64 mx-auto">
                                        <Button
                                            type="submit"
                                            text="Access ICE"
                                            isLoading={isLoggingIn}
                                        />
                                    </div>
                                </form>

                                <div className="mt-6 text-center">
                                    <Link
                                        to="/login"
                                        className="text-brand-600 text-sm"
                                    >
                                        Login
                                    </Link>
                                </div>

                                {settings.organization && (
                                    <div className="text-sm mt-10 text-center">
                                        For questions or support, please contact{" "}
                                        <a
                                            href={
                                                settings.organization.website ||
                                                "#"
                                            }
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className={`${
                                                settings.organization.website
                                                    ? "text-brand hover:underline"
                                                    : ""
                                            }`}
                                        >
                                            {settings.organization.name}
                                        </a>
                                    </div>
                                )}
                            </div>
                        </div>
                        <PoweredBy />
                    </div>
                </div>
                <LoginSplash />
            </div>
            ;
        </>
    );
}
