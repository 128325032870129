import React, { useEffect, useState, useCallback } from "react";
import { useToasts } from "react-toast-notifications";
import moment from "moment";
import Auth from "../services/Auth";
import Api from "../services/Api";
import useAccounts from "hooks/useAccounts";

const WARNING_THRESHOLD = 60 * 2;
// const REFRESH_THRESHOLD = 60 * 2;
// const WATCH_USER_ACTIVITY = false;

export const AuthContext = React.createContext();

export default function AuthContextProvider({ children }) {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [hasCheckedLogin, setHasCheckedLogin] = useState(false);
    const [isLoadingSettings, setIsLoadingSettings] = useState(true);
    const [isLoggingOut, setIsLoggingOut] = useState(false);
    const [shownExpirationWarning, setShownExpirationWarning] = useState("");
    const [organization, setOrganization] = useState({});
    const [needs2fa, setNeeds2fa] = useState(false);
    const [needsPasswordChange, setNeedsPasswordChange] = useState(false);
    const [enabled2fa, setEnabled2fa] = useState(false);
    const [options2fa, setOptions2fa] = useState(false);
    const [subdomain, setSubdomain] = useState("");
    const [user, setUser] = useState({});
    const [errorCode, setErrorCode] = useState(0);
    const [needsRefresh, setNeedsRefresh] = useState(0);
    const [errorTitle, setErrorTitle] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [primarySubscription, setPrimarySubscription] = useState("");
    const [primarySubscriptionPrice, setPrimarySubscriptionPrice] =
        useState("");
    const [isSubscribed, setIsSubscribed] = useState(false);
    const [planLimits, setPlanLimits] = useState([]);

    const [settings, setSettings] = useState([]);

    useEffect(() => {
        const fetchSettings = async () => {
            setIsLoadingSettings(true);
            const response = await Api.get("settings");
            setSettings(response.data);
            setIsLoadingSettings(false);
        };
        fetchSettings();
    }, []);

    useEffect(() => {
        setSubdomain(
            settings.organization ? settings.organization.subdomain : ""
        );
    }, [settings]);

    const handleAuthSuccess = (data) => {
        setOrganization(data.organization);
        setNeeds2fa(false);
        setSubdomain(data.subdomain);
        setUser(data.user);
        setEnabled2fa(data["2fa_enabled"]);
        setIsLoggedIn(true);
        setHasCheckedLogin(true);
        setPrimarySubscription(data.primary_subscription);
        setPrimarySubscriptionPrice(data.primary_subscription_price);
        setPlanLimits(data.plan_limits);
        setIsSubscribed(data.is_subscribed);
    };

    const { data: accounts, remove: removeAccounts } = useAccounts(
        {},
        {
            enabled: isLoggedIn,
            cacheTime: 1000 * 60 * 2,
            refetchOnWindowFocus: false,
        }
    );

    const { addToast, removeToast, removeAllToasts } = useToasts();

    const reset = () => {
        setIsLoggedIn(false);
        setIsLoggingOut(false);

        removeAllToasts();
        setShownExpirationWarning("");
        setOrganization({});
        setNeeds2fa(false);
        setSubdomain("");
        setUser({});
        setEnabled2fa(false);
        setIsLoggedIn(false);
        setPrimarySubscription("");
        setPrimarySubscriptionPrice("");
        setPlanLimits([]);
        removeAccounts();
        setNeedsRefresh((needsRefresh) => needsRefresh + 1);
    };

    // logout user
    const logoutUser = useCallback(async () => {
        try {
            await Auth.logout();
        } catch (error) {
            console.log(error);
        }
        window.sessionStorage.removeItem("con2a:user_profile");
        window.localStorage.removeItem("session-expiration");
        setIsLoggedIn(false);
        setIsLoggingOut(true);
        removeAllToasts();
        setShownExpirationWarning("");
        reset();
    }, [removeAllToasts]);

    const checkAuth = useCallback(async () => {
        try {
            const res = await Auth.check();

            if (
                res.data.redirect_to_subdomain &&
                window.location !==
                    res.data.redirect_to_subdomain + window.location.pathname
            ) {
                return (window.location =
                    res.data.redirect_to_subdomain + window.location.pathname);
            }

            if (res.data.is_subscribed === false) {
                if (window.location.pathname !== "/choose-subscription")
                    return (window.location = "/choose-subscription");
            }

            handleAuthSuccess(res.data);

            return res;
        } catch (error) {
            if (error.response && error.response.status === 409) {
                let data = error.response.data;

                if (data.type === "2fa") {
                    setNeeds2fa(true);
                    setOptions2fa(error.response.data.options);
                } else if (data.type === "password") {
                    setNeedsPasswordChange(true);
                }
            } else if (error.response && error.response.status === 401) {
                setIsLoggedIn(false);
            } else {
                if (
                    error.response &&
                    error.response.data &&
                    error.response.data.message
                ) {
                    setErrorCode(
                        (error.response && error.response.status) || -1
                    );
                    setErrorMessage(error.response.data.message);
                    setErrorTitle(
                        (error.errorMessage || error.message).split(" (")[0]
                    );
                } else {
                    setErrorCode(
                        (error.response && error.response.status) || -1
                    );
                    setErrorMessage(
                        (error.errorMessage || error.message).split(" (")[0]
                    );
                    setErrorTitle("");
                }
            }
            window.sessionStorage.removeItem("con2a:user_profile");
            setHasCheckedLogin(true);
            return false;
        }
    }, []);

    const checkSession = useCallback(async () => {
        try {
            if (!isLoggedIn) {
                return;
            }
            let sessionExpiration = parseInt(
                window.localStorage.getItem("session-expiration")
            );
            sessionExpiration = moment(sessionExpiration);

            let now = Date.now() / 1000;

            let expired = now > sessionExpiration;
            let timeLeft = sessionExpiration - now;

            if (expired) {
                removeToast(shownExpirationWarning);
                setShownExpirationWarning("");
                await logoutUser();
                addToast("Session Expired", {
                    type: "error",
                    autoDismiss: false,
                });
            } else if (
                timeLeft < WARNING_THRESHOLD &&
                !shownExpirationWarning
            ) {
                let toastId = "";
                addToast(
                    "Your Session is expiring soon",
                    {
                        title: "Session Expiring",
                        type: "warning",
                        autoDismiss: false,
                        actionTitle: "Extend Session",
                        action: async () => {
                            try {
                                removeToast(toastId);
                                await extendSession();
                                setShownExpirationWarning("");
                            } catch (e) {
                                addToast("Session Extension Failed", {
                                    type: "error",
                                });
                            }
                        },
                    },
                    (id) => {
                        toastId = id;
                        setShownExpirationWarning(id);
                    }
                );
            } else {
                // console.log(timeLeft);
            }
        } catch (error) {
            console.error(error);
        }
    }, [
        addToast,
        checkAuth,
        logoutUser,
        shownExpirationWarning,
        removeToast,
        isLoggedIn,
    ]);

    const extendSession = async () => {
        try {
            await Auth.check();
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        checkAuth();
    }, [checkAuth, needsRefresh]);

    useEffect(() => {
        if (!hasCheckedLogin) {
            return;
        }
        checkSession();
        const timer = setInterval(() => {
            checkSession();
        }, 2000);
        // clearing interval
        return () => clearInterval(timer);
    }, [checkSession, hasCheckedLogin]);

    // Refresh session on local activity after a certain time period
    // useEffect(() => {
    //     const handler = () => {
    //         if (!isLoggedIn) {
    //             return;
    //         }
    //         setLastActivity((lastActivity) => {
    //             const now = Date.now() / 1000;
    //             const timeSince = now - lastActivity;
    //             const refreshThreshold = REFRESH_THRESHOLD;

    //             if (timeSince > refreshThreshold && lastActivity > -1) {
    //                 checkAuth();
    //                 removeToast(shownExpirationWarning);
    //                 setShownExpirationWarning('');
    //             }
    //             return now;
    //         });
    //     };

    //     if (WATCH_USER_ACTIVITY) {
    //         // initiate the event handler
    //         window.addEventListener("scroll", handler);
    //         window.addEventListener("click", handler);
    //         window.addEventListener("keyup", handler);
    //     }

    //     // this will clean up the event every time the component is re-rendered
    //     return function cleanup() {
    //         window.removeEventListener("scroll", handler);
    //         window.removeEventListener("click", handler);
    //         window.removeEventListener("keyup", handler);
    //     };
    // }, [isLoggedIn, shownExpirationWarning]);

    return (
        <AuthContext.Provider
            value={{
                user,
                organization,
                subdomain,
                isLoggedIn,
                setIsLoggedIn,
                isLoggingOut,
                hasCheckedLogin,
                isLoadingSettings,
                logoutUser,
                needsPasswordChange,
                needs2fa,
                options2fa,
                enabled2fa,
                errorTitle,
                errorCode,
                errorMessage,
                setUser,
                settings,
                setOrganization,
                primarySubscription,
                primarySubscriptionPrice,
                isSubscribed,
                planLimits,
                setNeedsRefresh,
                accounts,
                checkAuth,
            }}
        >
            {children}
        </AuthContext.Provider>
    );
}
