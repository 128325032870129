import React, { useState } from "react";
import useFormatCurrency from "../../../hooks/useFormatCurrency";
import TextInput from "../../../components/TextInput";
import Checkbox from "../../../components/Checkbox";
import InsuranceSelect from "./InsuranceSelect";
import { StateSelect } from "../../../components/SelectInput";
import ModalModelFactory from "../../../components/ModalModelFactory";
import Button from "../../../components/Button";
import RealEstateLeaseSelect from "./RealEstateLeaseSelect";

const RealEstateForm = ({
    client,
    input,
    errors,
    readOnly = false,
    updateValue,
    onSubmit,
    user,
}) => {
    const formatCurrency = useFormatCurrency();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [needsUpdated, setNeedsUpdated] = useState(0);
    const [isLeaseModalOpen, setIsLeaseModalOpen] = useState(false);
    const [leaseNeedsUpdated, setLeaseNeedsUpdated] = useState(0);

    return (
        <>
            <form onSubmit={onSubmit}>
                <div>
                    <div className="mb-6">
                        <TextInput
                            name="property_name"
                            label="Property Name"
                            id="property_name"
                            readOnly={readOnly}
                            disabled={readOnly}
                            value={input.property_name}
                            error={errors.property_name}
                            onChange={updateValue}
                            required={true}
                            autoFocus={true}
                        />
                    </div>
                    <div className="flex mb-6">
                        <div className="w-1/2 mr-4">
                            <TextInput
                                name="value"
                                label="Value"
                                id="value"
                                readOnly={readOnly}
                                disabled={readOnly}
                                value={input.value}
                                error={errors.value}
                                onChange={updateValue}
                                onBlur={(e) => {
                                    if (e.target.value) {
                                        e.target.value = formatCurrency(
                                            e.target.value
                                        );
                                        updateValue(e);
                                    }
                                }}
                                placeholder="0.00"
                                required={true}
                            />
                        </div>
                        <div className="w-1/2">
                            <TextInput
                                type="date"
                                name="as_of_date"
                                label="As of Date"
                                id="as_of_date"
                                readOnly={readOnly}
                                disabled={readOnly}
                                value={input.as_of_date}
                                error={errors.as_of_date}
                                onChange={updateValue}
                                required={true}
                            />
                        </div>
                    </div>
                    <div className="flex mb-6">
                        <div className="w-1/2 mr-4">
                            <TextInput
                                name="purchase_price"
                                label="Purchase Price"
                                id="purchase_price"
                                readOnly={readOnly}
                                disabled={readOnly}
                                value={input.purchase_price}
                                error={errors.purchase_price}
                                onChange={updateValue}
                                onBlur={(e) => {
                                    e.target.value = formatCurrency(
                                        e.target.value
                                    );
                                    updateValue(e);
                                }}
                                placeholder="0.00"
                            />
                        </div>
                        <div className="w-1/2">
                            <TextInput
                                type="date"
                                name="purchase_date"
                                label="Purchase Date"
                                id="purchase_date"
                                readOnly={readOnly}
                                disabled={readOnly}
                                value={input.purchase_date}
                                error={errors.purchase_date}
                                onChange={updateValue}
                            />
                        </div>
                    </div>

                    <div className="mb-6">
                        <Checkbox
                            name="show_beneficiary"
                            label="Add Transfer On Death Designation?"
                            id="show_beneficiary"
                            readOnly={readOnly}
                            disabled={readOnly}
                            defaultChecked={input.show_beneficiary}
                            value={input.show_beneficiary}
                            error={errors.show_beneficiary}
                            onChange={updateValue}
                        />
                    </div>

                    <fieldset className="mt-12">
                        <legend className="font-bold txt-md text-gray-500 my-6 pb-1 border-b block w-full">
                            Location
                        </legend>

                        <div className="mb-6">
                            <TextInput
                                name="address"
                                label="Address"
                                id="address"
                                readOnly={readOnly}
                                disabled={readOnly}
                                value={input.address}
                                error={errors.address}
                                onChange={updateValue}
                            />
                        </div>
                        <div className="flex mb-6">
                            <div className="w-1/3 mr-4">
                                <TextInput
                                    name="city"
                                    label="City"
                                    id="city"
                                    value={input.city}
                                    error={errors.city}
                                    onChange={updateValue}
                                    readOnly={readOnly}
                                    disabled={readOnly}
                                />
                            </div>
                            <div className="w-1/3 mr-4">
                                <StateSelect
                                    name="state"
                                    label="State"
                                    id="state"
                                    value={input.state}
                                    error={errors.state}
                                    onChange={updateValue}
                                    readOnly={readOnly}
                                    disabled={readOnly}
                                />
                            </div>
                            <div className="w-1/3">
                                <TextInput
                                    name="postal_code"
                                    label="Postal Code"
                                    id="postal_code"
                                    value={input.postal_code}
                                    error={errors.postal_code}
                                    onChange={updateValue}
                                    readOnly={readOnly}
                                    disabled={readOnly}
                                />
                            </div>
                        </div>
                        <div className="flex mb-6">
                            <div className="w-1/2 mr-4">
                                <TextInput
                                    name="county"
                                    label="County"
                                    id="county"
                                    value={input.county}
                                    error={errors.county}
                                    onChange={updateValue}
                                    readOnly={readOnly}
                                    disabled={readOnly}
                                />
                            </div>
                            <div className="w-1/2">
                                <TextInput
                                    name="parcel_no"
                                    label="Parcel No."
                                    id="parcel_no"
                                    value={input.parcel_no}
                                    error={errors.parcel_no}
                                    onChange={updateValue}
                                    readOnly={readOnly}
                                    disabled={readOnly}
                                />
                            </div>
                        </div>
                    </fieldset>
                    <div className="mb-6">
                        <Checkbox
                            id="has_lease"
                            name="has_lease"
                            readOnly={readOnly}
                            disabled={readOnly}
                            defaultChecked={input.has_lease}
                            onChange={updateValue}
                            label="Is this real estate leased to a third party?"
                        />
                    </div>
                    {parseInt(input.has_lease) === 1 && (
                        <fieldset className="mt-8">
                            <div className="mb-4 flex">
                                <div className="w-2/3">
                                    <RealEstateLeaseSelect
                                        client={client}
                                        value={input.lease_id}
                                        realEstateId={input.id}
                                        disabled={readOnly}
                                        name="lease_id"
                                        label="Lease"
                                        onChange={updateValue}
                                        needsUpdated={leaseNeedsUpdated}
                                    />
                                </div>
                                <div className="w-1/3 mt-6 ml-2">
                                    <Button
                                        onClick={() =>
                                            setIsLeaseModalOpen(true)
                                        }
                                        text="Add New Lease"
                                    />
                                </div>
                            </div>
                        </fieldset>
                    )}
                    <fieldset className="mt-6">
                        <div className="mb-6 flex">
                            <div className="flex-1">
                                <InsuranceSelect
                                    client={client}
                                    value={input.insurance_policy_id}
                                    disabled={readOnly}
                                    name="insurance_policy_id"
                                    label="Insurance Policy"
                                    onChange={updateValue}
                                    needsUpdated={needsUpdated}
                                />
                            </div>
                            <div className="flex-none mt-6 ml-2">
                                <Button
                                    onClick={() => setIsModalOpen(true)}
                                    text="Add New Policy"
                                    appearance="light"
                                />
                            </div>
                        </div>

                        <div className="mb-6">
                            <Checkbox
                                id="has_lien"
                                name="has_lien"
                                readOnly={readOnly}
                                disabled={readOnly}
                                defaultChecked={input.has_lien}
                                onChange={updateValue}
                                label="Has Lien?"
                            />
                        </div>
                        {parseInt(input.has_lien) === 1 && (
                            <>
                                <div className="flex mb-6">
                                    <div className="w-1/2 mr-4">
                                        <TextInput
                                            name="initial_loan_amount"
                                            label="Inital Loan Amount"
                                            id="initial_loan_amount"
                                            value={input.initial_loan_amount}
                                            error={errors.initial_loan_amount}
                                            onChange={updateValue}
                                            readOnly={readOnly}
                                            onBlur={(e) => {
                                                if (e.target.value) {
                                                    e.target.value =
                                                        formatCurrency(
                                                            e.target.value
                                                        );
                                                    updateValue(e);
                                                }
                                            }}
                                        />
                                    </div>
                                    <div className="w-1/2">
                                        <TextInput
                                            type="date"
                                            name="date_of_mortgage"
                                            label="Date of Mortgage"
                                            id="date_of_mortgage"
                                            value={input.date_of_mortgage}
                                            error={errors.date_of_mortgage}
                                            onChange={updateValue}
                                            readOnly={readOnly}
                                        />
                                    </div>
                                </div>
                                <div className="flex mb-6">
                                    <div className="w-1/2 mr-4">
                                        <TextInput
                                            name="balance"
                                            label="Balance Due"
                                            id="balance"
                                            value={input.balance}
                                            error={errors.balance}
                                            onChange={updateValue}
                                            readOnly={readOnly}
                                            onBlur={(e) => {
                                                if (e.target.value) {
                                                    e.target.value =
                                                        formatCurrency(
                                                            e.target.value
                                                        );
                                                    updateValue(e);
                                                }
                                            }}
                                        />
                                    </div>
                                    <div className="w-1/2">
                                        <TextInput
                                            type="date"
                                            name="balance_as_of_date"
                                            label="As Of Date"
                                            id="balance_as_of_date"
                                            value={input.balance_as_of_date}
                                            error={errors.balance_as_of_date}
                                            onChange={updateValue}
                                            readOnly={readOnly}
                                            disabled={readOnly}
                                        />
                                    </div>
                                </div>
                                <div className="mb-6">
                                    <TextInput
                                        name="company_name"
                                        label="Company Name"
                                        id="company_name"
                                        value={input.company_name}
                                        error={errors.company_name}
                                        onChange={updateValue}
                                        readOnly={readOnly}
                                        disabled={readOnly}
                                    />
                                </div>
                                <div className="mb-6">
                                    <TextInput
                                        name="company_address"
                                        label="Company Address"
                                        id="company_address"
                                        value={input.company_address}
                                        error={errors.company_address}
                                        onChange={updateValue}
                                        readOnly={readOnly}
                                    />
                                </div>
                                <div className="flex mb-6">
                                    <div className="w-3/5 mr-4">
                                        <TextInput
                                            name="company_city"
                                            label="City"
                                            id="company_city"
                                            value={input.company_city}
                                            error={errors.company_city}
                                            onChange={updateValue}
                                            readOnly={readOnly}
                                        />
                                    </div>
                                    <div className="w-1/5 mr-4">
                                        <StateSelect
                                            name="company_state"
                                            label="State"
                                            id="company_state"
                                            value={input.company_state}
                                            error={errors.company_state}
                                            onChange={updateValue}
                                            readOnly={readOnly}
                                            disabled={readOnly}
                                        />
                                    </div>
                                    <div className="w-1/5">
                                        <TextInput
                                            name="company_postal_code"
                                            label="Postal Code"
                                            id="company_postal_code"
                                            value={input.company_postal_code}
                                            error={errors.company_postal_code}
                                            onChange={updateValue}
                                            readOnly={readOnly}
                                        />
                                    </div>
                                </div>
                                <div className="mb-6">
                                    <TextInput
                                        name="company_phone"
                                        label="Company Phone"
                                        id="company_phone"
                                        value={input.company_phone}
                                        error={errors.company_phone}
                                        onChange={updateValue}
                                        readOnly={readOnly}
                                    />
                                </div>
                                <div className="mb-6">
                                    <TextInput
                                        type="email"
                                        name="company_email"
                                        label="Company Email"
                                        id="company_email"
                                        value={input.company_email}
                                        error={errors.company_email}
                                        onChange={updateValue}
                                        readOnly={readOnly}
                                    />
                                </div>
                            </>
                        )}
                    </fieldset>

                    <input type="submit" hidden />
                </div>
            </form>

            <ModalModelFactory
                isOpen={isModalOpen}
                client={client}
                clientPath=""
                user={user}
                title="Add Insurance Policy"
                onClose={() => setIsModalOpen(false)}
                onSuccess={() => {
                    setIsModalOpen(false);
                    setNeedsUpdated(needsUpdated + 1);
                }}
                modelType="OtherInsurance"
            />

            <ModalModelFactory
                isOpen={isLeaseModalOpen}
                client={client}
                clientPath=""
                user={user}
                title="Add Real Estate Lease"
                hideType={true}
                onClose={() => setIsLeaseModalOpen(false)}
                onSuccess={() => {
                    setIsLeaseModalOpen(false);
                    setLeaseNeedsUpdated(leaseNeedsUpdated + 1);
                }}
                modelType="Lease"
            />
        </>
    );
};

export default RealEstateForm;
