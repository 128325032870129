import React, { useState } from "react";
import { Route, Switch } from "react-router-dom";
import PageContainer from "../../components/PageContainer";
import Button from "../../components/Button";
import DisabilityPoliciesTable from "./_partials/DisabilityPoliciesTable";
import ClientPageHeader, { Heading } from "../../components/ClientPageHeader";
import Tabs from "../../components/Tabs";
import { useToasts } from "react-toast-notifications";
import Api from "../../services/Api";
import { downloadFile } from "../../util/Downloader";
import useHasAnyPermissions, { useUserCan } from "hooks/useHasAnyPermissions";

export default function DisabilityPoliciesIndex({
    client,
    user,
    clientPath,
    ...props
}) {
    const [isExporting, setIsExporting] = useState(false);
    const [recordCount, setRecordCount] = useState(0);
    const { addToast } = useToasts();
    const canCreateWealthRecords = useUserCan("createWealthRecord");
    const canDownloadReport = useHasAnyPermissions([
        "report_global_access",
        "report_disability_ltc_policies_report",
    ]);

    async function exportRecords(e) {
        e.preventDefault();
        try {
            setIsExporting(true);
            let response = await Api.get(
                "clients/" + client.id + "/disability-policies",
                {
                    export: true,
                },
                { responseType: "arraybuffer" }
            );
            await downloadFile(response, "disability-policies");
            setIsExporting(false);
            addToast("Export downloaded");
        } catch (e) {
            setIsExporting(false);
            addToast(e.response.data.message, { type: "error" });
        }
    }

    function updateRecordCount(count) {
        setRecordCount(count);
    }

    return (
        <>
            <ClientPageHeader client={client}>
                <Heading>Disability/LTC Policies</Heading>
                <div className="flex-0 flex space-x-4">
                    {canCreateWealthRecords && (
                        <Button
                            type="link"
                            to={`${clientPath}/disability-policies/add`}
                            text="Add New Policy"
                        />
                    )}
                    {recordCount > 0 && canDownloadReport && (
                        <Button
                            text="Download"
                            appearance="outline"
                            width="w-auto"
                            isLoading={isExporting}
                            onClick={exportRecords}
                        />
                    )}
                </div>
            </ClientPageHeader>
            <PageContainer>
                <Tabs
                    {...props}
                    items={[
                        {
                            label: "Other Insurance Policies",
                            to: clientPath + "/other-insurance-policies",
                        },
                        {
                            label: "Disability/LTC Policies",
                            to: clientPath + "/disability-policies",
                        },
                    ]}
                />
                <div className="flex space-x-8">
                    <div className="flex-1">
                        <Switch>
                            <Route
                                path={clientPath + "/disability-policies"}
                                render={(newProps) => (
                                    <DisabilityPoliciesTable
                                        client={client}
                                        user={user}
                                        clientPath={clientPath}
                                        updateRecordCount={updateRecordCount}
                                        {...props}
                                        {...newProps}
                                    />
                                )}
                            />
                        </Switch>
                    </div>
                </div>
            </PageContainer>
        </>
    );
}
