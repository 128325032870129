import React from "react";
import { Route, Switch } from "react-router-dom";
import FundingTableIndex from "./FundingTableIndex";
import OwnershipContextProvider from "../../contexts/OwnershipContext";
import { combineProviders } from "util/CombineProviders";

const FundingTableProviders = combineProviders([OwnershipContextProvider]);

export default function FundingTable(parentProps) {
    return (
        <FundingTableProviders>
            <Switch>
                <Route
                    exact
                    path={[
                        "/clients/:clientId/funding-table",
                        "/client/funding-table",
                    ]}
                    render={(props) => (
                        <FundingTableIndex
                            {...parentProps}
                            {...props}
                            currentTab="ownership"
                        />
                    )}
                />
                <Route
                    exact
                    path={[
                        "/clients/:clientId/funding-table/transfers",
                        "/client/funding-table/transfers",
                    ]}
                    render={(props) => (
                        <FundingTableIndex
                            {...parentProps}
                            {...props}
                            currentTab="transfers"
                        />
                    )}
                />
            </Switch>
        </FundingTableProviders>
    );
}
