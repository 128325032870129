import React, { useEffect, useState } from "react";
import SelectInput from "../../../components/SelectInput";
import Spinner from "../../../components/Spinner";
import Api from "../../../services/Api";
import ResourceSelect from "../../../components/ResourceSelect";

const ownerOptions = ["Client", "Relationship"];

export default function TrusteeForm({
    client,
    input,
    errors,
    readOnly = false,
    updateValue,
    onSubmit,
}) {
    const [loading, setLoading] = useState(false);
    const [trusts, setTrusts] = useState([]);
    const [successorTypes, setSuccessorTypes] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                let response = await Api.get(
                    "clients/" + client.id + "/trusts"
                );
                if (response.data.data.length > 0) {
                    setTrusts(
                        response.data.data.map((trust) => {
                            return { value: trust.id, label: trust.name };
                        })
                    );
                }
                setLoading(false);
            } catch (error) {
                console.log(error);
            }
        };
        fetchData();
    }, [client.id]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                let response = await Api.get("enums/successors");
                setSuccessorTypes(response.data);
                setLoading(false);
            } catch (error) {
                console.log(error);
            }
        };
        fetchData();
    }, []);

    if (loading) {
        return <Spinner />;
    }

    return (
        <form onSubmit={onSubmit}>
            <div className="mb-6">
                <SelectInput
                    options={trusts}
                    name="trust_id"
                    label="Trust"
                    id="trust_id"
                    readOnly={readOnly}
                    disabled={readOnly}
                    value={input.trust_id}
                    error={errors.trust_id}
                    onChange={updateValue}
                    required={true}
                    autoFocus={true}
                    defaultOption="Select a trust..."
                    defaultOptionValue={""}
                    defaultOptionDisabled={true}
                />
            </div>
            <div className="mb-6">
                <ResourceSelect
                    client={client}
                    resourceType={ownerOptions}
                    name="owner"
                    label="Trustee"
                    value={input.owner}
                    error={errors.owner}
                    onChange={updateValue}
                    readOnly={readOnly}
                    disabled={readOnly}
                />
            </div>
            <div className="mb-6">
                <SelectInput
                    options={successorTypes}
                    name="successor_level"
                    label="Trustee Level"
                    id="successor_level"
                    readOnly={readOnly}
                    disabled={readOnly}
                    value={input.successor_level}
                    error={errors.successor_level}
                    onChange={updateValue}
                    required={true}
                />
            </div>
            <input type="submit" hidden />
        </form>
    );
}
