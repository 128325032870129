import React from "react";
import Button from "../../components/Button";
import { downloadFileAsLink } from "../../util/Downloader";

export default function AnnuityLetters({ clientId, accountId }) {
    document.title = "Forms - Letters of Instruction";

    const downloadBeneficiaryInstructions = () => {
        const url = `clients/${clientId}/annuities/${accountId}/letters/beneficiary-change`;
        downloadFileAsLink(url);
    };

    return (
        <table className="w-full text-left">
            <thead>
                <tr>
                    <th>Name</th>
                    <th>Description</th>
                    <th className="text-right">Actions</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>Change Beneficiary Designation</td>
                    <td>
                        Instructions for payable on death beneficiary
                        designation.
                    </td>
                    <td className="text-right">
                        <Button
                            type="button"
                            text="Download Document"
                            height="h-10"
                            appearance="outline"
                            width="w-auto"
                            onClick={downloadBeneficiaryInstructions}
                        />
                    </td>
                </tr>
            </tbody>
        </table>
    );
}
