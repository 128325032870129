import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faEllipsisV,
    faTrash,
    faPlus,
    faFile,
} from "@fortawesome/free-solid-svg-icons";

import Api from "../../services/Api";
import EmptyTableRow from "../../components/EmptyTableRow";
import Spinner from "../../components/Spinner";
import Panel, { PanelHeader } from "../../components/Panel";
import Button from "../../components/Button";
import Dropdown, { DropdownItem } from "../../components/Dropdown";

import ModalUnlinkResource from "./ModalUnlinkResource";
import { Link } from "react-router-dom";
import { formatDate } from "../../util/TextUtil";
import { iconColorForVault } from "../clients/Vault/_partials/helpers";
import { isFileImage } from "../clients/Vault/helpers";
import ModalVaultUpload from "./ModalVaultUpload";

export default function VaultResourceFileList({
    client,
    resourceType,
    resourceId,
    fileType = "File",
    title = "Related Files",
    viewType = "list",
    mimeTypeFilter = false,
    panelClass = null,
    viewOnly = false,
    appearance = "flat",
}) {
    const [resource, setResource] = useState({});
    const [relatedFiles, setRelatedFiles] = useState([]);
    const [showDelete, setShowDelete] = useState(false);
    const [showUpload, setShowUpload] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const [forceUpdate, setForceUpdate] = useState(0);

    useEffect(() => {
        const fetch = async () => {
            try {
                setIsLoading(true);
                const response = await Api.get(
                    `resources/relations/files/${resourceType}/${resourceId}`
                );
                setRelatedFiles(
                    response.data.files.filter(
                        (a) =>
                            a.type !== "file" ||
                            !mimeTypeFilter ||
                            a.mime_type.indexOf(mimeTypeFilter) > -1
                    )
                );
                setResource(response.data.resource);
                setIsLoading(false);
            } catch (e) {
                setIsLoading(false);
            }
        };
        if (resourceType !== undefined && resourceId !== undefined) {
            fetch();
        }
    }, [resourceType, resourceId, forceUpdate, mimeTypeFilter]);

    const openDelete = (access) => {
        setShowDelete(true);
        setSelectedFile(access);
    };

    const OptionsDropdown = ({ file }) => {
        return (
            <span className="block w-full">
                <Dropdown
                    position="right"
                    toggleContent={
                        <>
                            <div className="py-1 px-3 w-full block">
                                <FontAwesomeIcon icon={faEllipsisV} />
                            </div>
                        </>
                    }
                >
                    <DropdownItem
                        label={`Unlink ${fileType}`}
                        icon={faTrash}
                        handleClick={() => openDelete(file)}
                    />
                </Dropdown>
            </span>
        );
    };

    if (viewOnly && relatedFiles.length === 0) {
        return <></>;
    }

    return (
        <>
            <Panel
                appearance={appearance}
                className={panelClass ?? ""}
                allowOverflow={true}
                header={
                    <PanelHeader title={title}>
                        {!viewOnly && (
                            <Button
                                base={false}
                                height={null}
                                appearance={"link"}
                                onClick={() => setShowUpload(true)}
                            >
                                <FontAwesomeIcon icon={faPlus} /> Link{" "}
                                {fileType}
                            </Button>
                        )}
                    </PanelHeader>
                }
            >
                <div className="min-h-24">
                {viewType === "list" && (
                    <table className="w-full text-left">
                        <thead>
                            <tr>
                                <th>Filename</th>
                                <th>Size</th>
                                <th>Date Linked</th>
                                <th>Linked By</th>
                                {!viewOnly && <th>Actions</th>}
                            </tr>
                        </thead>
                        <tbody>
                            {relatedFiles.map((file, idx) => (
                                <tr key={idx}>
                                    <td>
                                        <Link
                                            to={
                                                `/clients/${file.client.id}/vault?id=` +
                                                file.id
                                            }
                                            className="text-blue-500"
                                            title="File"
                                        >
                                            {file.name}.{file.extension}
                                        </Link>
                                    </td>
                                    <td>{file.display_size}</td>
                                    <td>{formatDate(resource.linked_at)}</td>
                                    <td>{resource.creator_full_name}</td>
                                    {!viewOnly && (
                                        <td className="text-right">
                                            <OptionsDropdown file={file} />
                                        </td>
                                    )}
                                </tr>
                            ))}
                            {!isLoading && relatedFiles.length === 0 && (
                                <EmptyTableRow colSpan="5">
                                    No Related {fileType}s
                                </EmptyTableRow>
                            )}
                        </tbody>
                    </table>
                )}

                {viewType === "grid" && (
                    <>
                        {!isLoading && relatedFiles.length === 0 && (
                            <div className="text-center">
                                No Related {fileType}s
                            </div>
                        )}

                        {!isLoading && relatedFiles.length > 0 && (
                            <div
                                className={`flex flex-row mt-8 flex-wrap justify-center`}
                            >
                                {relatedFiles.map((vaultEntry, idx) => {
                                    const isImage = isFileImage({
                                        extension: vaultEntry.extension,
                                    });

                                    let src = "";
                                    if (isImage) {
                                        src = Api.makeUrl(
                                            `clients/${client.id}/vault/${vaultEntry.id}/download?preview=1`
                                        );
                                    }
                                    return (
                                        <div
                                            key={idx}
                                            className={`cursor-pointer w-1/3 lg:w-1/4 relative border border-1 border-gray-200 rounded-md mx-4 mb-4 p-4 flex flex-col justify-center text-center items-center`}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                            }}
                                        >
                                            <>
                                                {isImage && (
                                                    <div>
                                                        {src && (
                                                            <img
                                                                src={src}
                                                                className="object-contain h-32 w-32 mx-auto mb-4 rounded border-2 border-gray-200"
                                                                alt="Preview"
                                                            />
                                                        )}
                                                    </div>
                                                )}
                                                {!!isImage !== true && (
                                                    <div className="flex justify-center align-center mb-4 text-gray-400">
                                                        <div className="relative">
                                                            <FontAwesomeIcon
                                                                className={`${iconColorForVault(
                                                                    vaultEntry
                                                                )}`}
                                                                icon={faFile}
                                                                size="8x"
                                                            />
                                                            <div className="absolute inset-0 flex items-center justify-center">
                                                                <div className="text-md font-bold text-white">
                                                                    {vaultEntry.extension.toUpperCase()}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </>

                                            <div className={`font-bold`}>
                                                {vaultEntry.name}
                                            </div>

                                            <div
                                                className={`text-xs text-gray-600`}
                                            >
                                                Created:{" "}
                                                {formatDate(
                                                    vaultEntry.created_at
                                                )}
                                            </div>

                                            <div
                                                className={`text-xs text-gray-600`}
                                            >
                                                Updated:{" "}
                                                {formatDate(
                                                    vaultEntry.updated_at
                                                )}
                                            </div>

                                            <div className="absolute top-2 right-1">
                                                <OptionsDropdown
                                                    vaultEntry={vaultEntry}
                                                />
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        )}
                    </>
                )}

                {isLoading && (
                    <div className="w-full h-full flex items-center justify-center">
                        <Spinner height={44} />
                    </div>
                )}
                </div>
                <ModalUnlinkResource
                    relation={resource}
                    vaultEntry={selectedFile}
                    show={showDelete}
                    setShow={setShowDelete}
                    onResult={() => {
                        setForceUpdate(forceUpdate + 1);
                    }}
                />

                <ModalVaultUpload
                    client={client}
                    isOpen={showUpload}
                    fileType={fileType}
                    mimeTypeFilter={mimeTypeFilter}
                    resourceType={resourceType}
                    defaultFolderName={resourceType}
                    resourceId={resourceId}
                    onResult={() => {
                        setShowUpload(false);
                        setForceUpdate(forceUpdate + 1);
                    }}
                />
            </Panel>
        </>
    );
}
