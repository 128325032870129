import React, { useState, useReducer, useEffect } from "react";
import Api from "../../services/Api";
import BasicModal, { BasicModalHeader } from "../../components/ModalBasic";
import { useToasts } from "react-toast-notifications";
import OtherInsurancePolicyForm from "./_partials/OtherInsurancePolicyForm";

export default function OtherInsurancePolicyModal({
    isOpen,
    title,
    client,
    id,
    onClose,
    onSuccess,
    parentResource,
}) {
    const { addToast } = useToasts();
    const [errors, setErrors] = useState([]);
    const [notAuthorized, setNotAuthorized] = useState(false);
    const [loading, setLoading] = useState(id > 0);

    const [input, setInput] = useReducer(
        (state, newState) => ({ ...state, ...newState }),
        {
            name: "",
            number: "",
            type: "Auto",
            insured_id: client.id,
            benefit_frequency_id: 4,
            frequency_id: 1,
            managed_by_id: "",
            managed_by_address: "",
            managed_by_address_2: "",
            managed_by_city: "",
            managed_by_state: "",
            managed_by_postal_code: "",
            managed_by_country: "",
        }
    );

    useEffect(() => {
        const fetchData = async () => {
            try {
                if (id > 0) {
                    let response = await Api.get(
                        "clients/" + client.id + "/personal-properties/" + id
                    );
                    setInput(response.data.data);
                    setLoading(false);
                }
            } catch (error) {
                if (error.response.status === 403) {
                    setNotAuthorized(true);
                }
            }
        };
        fetchData();
    }, [id, client]);

    function updateValue(event) {
        setInput({ [event.target.name]: event.target.value });
    }

    async function save(e) {
        e.preventDefault();
        try {
            const body = { ...input, parentResource: parentResource };
            let response;
            if (id > 0) {
                response = await Api.put(
                    "clients/" + client.id + "/other-insurance-policies/" + id,
                    body
                );
            } else {
                response = await Api.post(
                    "clients/" + client.id + "/other-insurance-policies",
                    body
                );
            }
            addToast(response.data.message);
            setErrors([]);
            onSuccess(response.data.data);
        } catch (e) {
            setErrors([]);
            console.log(e);
            if (e?.response?.data?.errors) {
                setErrors(e.response.data.errors);
            }

            if (e?.response?.data?.message) {
                addToast(e?.response?.data?.message, { type: "error" });
            }
        }
    }

    if (notAuthorized) {
        onClose();
    }

    return (
        <BasicModal
            isOpen={isOpen}
            size="w-full"
            onClose={onClose}
            header={
                <BasicModalHeader
                    onClose={onClose}
                    onSave={save}
                    title={title}
                />
            }
        >
            <div className="max-w-3xl mx-auto">
                <OtherInsurancePolicyForm
                    client={client}
                    input={input}
                    readOnly={loading}
                    errors={errors}
                    onSubmit={save}
                    updateValue={updateValue}
                />
            </div>
        </BasicModal>
    );
}
