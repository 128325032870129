import React, { useReducer, useState, useEffect } from "react";
import BasicModal from "../ModalBasic";
import SelectInput from "../SelectInput";
import Checkbox from "../Checkbox";
import TextInput from "../TextInput";
import Api from "../../services/Api";
import { useToasts } from "react-toast-notifications";
import Button from "../Button";
import { ownershipTypes } from "../Ownership/OwnershipService";
import TransferDestination from "./TransferDestination";
import ValueTransferDestination from "./ValueTransferDestination";
import TextArea from "../TextArea";
import Spinner from "../Spinner";
import { titleCase } from "../../util/TextUtil";
import Money from "../Money";
import { formatDate } from "../../util/TextUtil";
import { OwnershipLineItemReadOnly } from "components/Ownership/partials/OwnershipLineItemReadOnly";
import useAssetValues from "views/funding_table/hooks/useAssetValues";
import usePostMortemState from "hooks/usePostMortemState";
import { useOwnershipContext } from "contexts/OwnershipContext";
import { useQueryClient } from "react-query";

const TransferTypes = {
    Ownership: "ownership",
    Value: "value",
};

export default function Transfer({
    client,
    asset,
    isOpen,
    onClose,
    canBeGift,
    closeModal,
}) {
    const { addToast } = useToasts();
    const [errors, setErrors] = useState([]);
    const [title, setTitle] = useState(null);
    const [transferType, setTransferType] = useState(TransferTypes.Ownership);
    const [saving, setSaving] = useState(false);
    const ownershipContext = useOwnershipContext();
    const { isPostMortem, person, showAltValues } = usePostMortemState();
    const [donor, setDonor] = useState("client");
    const queryClient = useQueryClient();
    const isValueTransferAllowed = asset?.type === "FinancialAccount";

    const defaultTransferValues = {
        source_ownership_id: 0,
        resource_id: asset.id,
        resource_type: asset.type,
        client_id: client.id,
        is_monetary_gift: "0",
        use_basic_exclusion: "0",
        basic_exclusion_amount: 0,
        file_gift_tax_return: "0",
        gift_purpose: "",
        is_percentage: "1",
        is_post_mortem: asset.isPostMortem,
        description: "",
    };

    const [input, setInput] = useReducer(
        (state, newState) => ({ ...state, ...newState }),
        defaultTransferValues
    );

    const defaultDestinations = [
        {
            type: "",
            resource_id: asset.id,
            resource_type: asset.type,
            owner_id: client.id,
            owner_type: "Client",
            percentage_owned: 0,
            owner_description: "",
        },
    ];

    const [destinations, setDestinations] = useState(defaultDestinations);

    useEffect(
        function () {
            if (isPostMortem) {
                setDonor(person);
            }
        },
        [person, isPostMortem]
    );

    useEffect(() => {
        setInput({
            is_percentage: transferType === TransferTypes.Ownership ? "1" : "0",
        });
    }, [transferType]);

    function resetOnClose() {
        setInput(defaultTransferValues);
        setTransferType(TransferTypes.Ownership);
        setErrors([]);
        onClose();
    }

    useEffect(() => {
        setInput({
            resource_id: asset.id,
            resource_type: asset.type,
        });
        if (asset.ownership !== undefined && asset.ownership !== null) {
            setInput({
                source_ownership_id: asset.ownership.id,
                source_id: asset.ownership.owner_id,
                source_type: asset.ownership.owner_type,
                source_description: asset.ownership.owner_description,
                source_original_percentage: asset.ownership.percentage_owned,
            });
        }
        setTitle(asset.name);
    }, [asset.id, asset.type, asset.name, asset.ownership, client]);

    async function save(e) {
        setSaving(true);
        e.preventDefault();
        try {
            const payload = {
                ...input,
                transfer_type: transferType,
                destinations,
                is_post_mortem: isPostMortem,
                person,
                show_alternate_valuation: showAltValues,
                donor_type: donor,
            };
            // send to API
            let response = await Api.post(
                "clients/" + client.id + "/transfers",
                payload
            );

            queryClient.refetchQueries({ active: true });

            addToast(response.data.message);
            setErrors([]);
            setDestinations(defaultDestinations);
            resetOnClose();
        } catch (e) {
            setErrors([]);
            console.log(e);
            if (e.response) {
                if (e.response.data.errors) {
                    setErrors(e.response.data.errors);
                } else {
                    addToast(e.response.data.message, { type: "error" });
                }
            }
        }
        setSaving(false);
    }

    function updateValue(e) {
        setInput({ [e.target.name]: e.target.value });
    }

    const { asOfDate, asOfDateLabel, value, valueLabel } = useAssetValues({
        asset,
    });

    let netValue = value;

    return (
        <BasicModal
            isOpen={isOpen}
            size="3xl"
            onClose={resetOnClose}
            header={
                <div className="sticky top-0 z-10 flex items-center justify-between border-b border-gray-300 px-6 py-3 bg-white">
                    <div className="flex items-start">
                        <div className="flex-none mr-10">
                            <div className={`text-xs text-gray-500`}>
                                {titleCase(asset.type || "", {
                                    pascal: true,
                                })}
                            </div>
                            <h2 className="text-sm">
                                <span className="font-bold text-base">
                                    {title}
                                </span>
                                {asset.account_number && (
                                    <span className="text-xs text-gray-500 block -mb-1">
                                        {asset.account_number}
                                    </span>
                                )}
                            </h2>
                        </div>
                        <div className="flex-none text-left">
                            <div className={`text-xs text-gray-500`}>
                                {valueLabel}
                            </div>
                            <div className="text-sm tabular-nums">
                                {<Money value={netValue} />}
                            </div>
                        </div>

                        <div className="ml-10 flex-none text-left">
                            <div className={`text-xs text-gray-500`}>
                                {asOfDateLabel}
                            </div>
                            <div className="text-sm">
                                {formatDate(asOfDate)}
                            </div>
                        </div>
                    </div>

                    <div className="flex items-center space-x-2 w-32 ml-auto">
                        <Button
                            type="button"
                            appearance="light"
                            className="bg-white"
                            text="Cancel"
                            onClick={() => closeModal()}
                        />
                    </div>
                </div>
            }
        >
            {isValueTransferAllowed && (
                <>
                    <div className="mb-4">
                        <h2 className="mb-4 font-bold text-lg text-gray-800">
                            Transfer Type
                        </h2>
                        <SelectInput
                            options={Object.keys(TransferTypes).map((t) => ({
                                value: TransferTypes[t],
                                label: t,
                            }))}
                            name="transfer_type"
                            value={transferType}
                            onChange={(e) => setTransferType(e.target.value)}
                        />
                    </div>

                    <hr className="mb-4" />
                </>
            )}

            {transferType === TransferTypes.Value && (
                <>
                    <div className="mb-6">
                        <h2 className="mb-4 font-bold text-lg text-gray-800">
                            Transferor
                        </h2>
                        <SelectInput
                            name="donor_type"
                            options={ownershipContext.donorOptions()}
                            value={donor}
                            onChange={(e) => setDonor(e.target.value)}
                        />
                    </div>
                    <div className="mb-6">
                        <h2 className="mb-4 font-bold text-lg text-gray-800">
                            Proposed Recipients
                        </h2>
                        <ValueTransferDestination
                            client={client}
                            asset={asset}
                            transfer={input}
                            errors={errors}
                            destinations={destinations}
                            source={asset.ownership}
                            setDestinations={setDestinations}
                            transferUnit={
                                parseInt(input.is_percentage) === 1
                                    ? "percentage"
                                    : "amount"
                            }
                        />
                    </div>
                </>
            )}
            {transferType === TransferTypes.Ownership && (
                <>
                    {asset.ownership && (
                        <div className="mb-6 border-b pb-3">
                            <h2 className="mb-4 font-bold text-lg text-gray-800">
                                Source Ownership
                            </h2>
                            <OwnershipLineItemReadOnly
                                client={client}
                                asset={asset}
                                ownership={asset.ownership}
                                ownershipTypes={ownershipTypes}
                                showProposed={true}
                                assetOptions={ownershipContext.assetOptions}
                            />
                        </div>
                    )}
                    <div className="mb-6">
                        <h2 className="mb-4 font-bold text-lg text-gray-800">
                            Transferor
                        </h2>
                        <SelectInput
                            name="donor_type"
                            options={ownershipContext.donorOptions()}
                            value={donor}
                            onChange={(e) => setDonor(e.target.value)}
                        />
                    </div>
                    <div className="mb-6">
                        <h2 className="mb-4 font-bold text-lg text-gray-800">
                            Proposed Transfer
                        </h2>
                        <TransferDestination
                            client={client}
                            asset={asset}
                            transfer={input}
                            errors={errors}
                            destinations={destinations}
                            source={asset.ownership}
                            setDestinations={setDestinations}
                            transferUnit={
                                parseInt(input.is_percentage) === 1
                                    ? "percentage"
                                    : "amount"
                            }
                        />
                    </div>
                </>
            )}

            {!isPostMortem && (
                <GiftQuestions
                    canBeGift={canBeGift}
                    input={input}
                    errors={errors || []}
                    updateValue={updateValue}
                />
            )}

            <div className="flex items-center space-x-3 justify-end">
                <div className="w-40">
                    <Button
                        onClick={save}
                        isLoading={saving}
                        disabled={false}
                        text="Save Proposal"
                    />
                </div>
            </div>
        </BasicModal>
    );
}

export function GiftQuestions({
    input,
    canBeGift = true,
    errors,
    updateValue = () => {},
    disabled = false,
}) {
    if (!input) {
        return <Spinner />;
    }

    if (canBeGift === false) {
        return <></>;
    }

    return (
        <div>
            <div className="mb-6">
                <Checkbox
                    name="is_monetary_gift"
                    id="is_monetary_gift"
                    checked={parseInt(input.is_monetary_gift)}
                    error={errors.is_monetary_gift}
                    onChange={updateValue}
                    label="Is this a gift?"
                    disabled={disabled}
                />
            </div>
            {parseInt(input.is_monetary_gift) === 1 && (
                <div>
                    <div className="mb-6">
                        <Checkbox
                            name="use_basic_exclusion"
                            id="use_basic_exclusion"
                            checked={parseInt(input.use_basic_exclusion) === 1}
                            error={errors.use_basic_exclusion}
                            onChange={updateValue}
                            label="Did you use any of your Basic Exclusion amount?"
                            disabled={disabled}
                        />
                        {parseInt(input.use_basic_exclusion) === 1 && (
                            <div className="w-full mt-1">
                                <TextInput
                                    name="basic_exclusion_amount"
                                    id="basic_exclusion_amount"
                                    value={input.basic_exclusion_amount}
                                    error={errors.basic_exclusion_amount}
                                    onChange={updateValue}
                                    placeholder="0.00"
                                    disabled={disabled}
                                />
                            </div>
                        )}
                    </div>
                    <div className="mb-6">
                        <Checkbox
                            name="file_gift_tax_return"
                            id="file_gift_tax_return"
                            checked={parseInt(input.file_gift_tax_return) === 1}
                            error={errors.file_gift_tax_return}
                            onChange={updateValue}
                            label="Did you file a Gift Tax Return?"
                            disabled={disabled}
                        />
                    </div>
                </div>
            )}
            {parseInt(input.is_monetary_gift) === 1 && (
                <div className="mb-6">
                    <TextArea
                        name="description"
                        id="description"
                        label={`Description of ${
                            parseInt(input.is_monetary_gift) === 1
                                ? "gift"
                                : "transfer"
                        }`}
                        value={input.description || ""}
                        error={errors.description}
                        onChange={updateValue}
                        rows="2"
                        disabled={disabled}
                    />
                </div>
            )}
        </div>
    );
}

export function ProposalModeSelect({
    proposalMode,
    setProposalMode,
    proposedAt,
    setProposedAt,
    verifiedAt,
    setVerifiedAt,
}) {
    return (
        <div className="flex items-center space-x-3 justify-end">
            <SelectInput
                options={[
                    {
                        value: "propose",
                        label: "Propose Transfer",
                    },
                    {
                        value: "verify",
                        label: "Verify Transfer",
                    },
                ]}
                name="proposal_mode"
                value={proposalMode}
                onChange={(e) => setProposalMode(e.target.value)}
            />
            <div>
                {proposalMode === "propose" && (
                    <>
                        <TextInput
                            type="date"
                            name="proposed_at"
                            value={proposedAt}
                            onChange={(e) => setProposedAt(e.target.value)}
                        />
                    </>
                )}
                {proposalMode === "verify" && (
                    <>
                        <TextInput
                            type="date"
                            name="verified_at"
                            value={verifiedAt}
                            onChange={(e) => setVerifiedAt(e.target.value)}
                        />
                    </>
                )}
            </div>
        </div>
    );
}
