import useFetch from "./useFetch";
import { fetchPostMortemAssetsForValues } from "services/PostMortemService";

export default function usePostMortemAssetsForValues(
    params,
    config = { keepPreviousData: false }
) {
    return useFetch(
        ["fetchPostMortemAssetsForValues", params],
        fetchPostMortemAssetsForValues,
        config
    );
}
