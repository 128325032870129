import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faSearch,
    faSortAmountDown,
    faSortAmountUp,
} from "@fortawesome/free-solid-svg-icons";
import TextInput from "./TextInput";

export default function SearchInput({
    label = "Search",
    searchValue = "",
    onSearchChange = () => {},
    placeholder = "Search",
    sortValue = -1,
    sortOptions = [],
    selectedSort = "",
    sortDir = "asc",
    onSortChange = () => {},
}) {
    const normalSelect = `-ml-px inline-flex items-center border border-0 border-gray-300 text-sm leading-5 font-medium rounded-r-md text-gray-700 bg-gray-50 hover:text-gray-500 hover:bg-white focus:outline-none focus:shadow-outline-blue focus:border-blue-300 active:bg-gray-100 active:text-gray-700 transition ease-in-out duration-150`;

    return (
        <div>
            <label
                htmlFor="email"
                className="block text-sm font-medium leading-5 text-gray-700"
            >
                {label}
            </label>
            <div className="mt-1 flex rounded-md">
                <div className="relative flex-grow focus-within:z-10">
                    <div className="absolute z-10 inset-y-0 left-0 pl-4 flex items-center pointer-events-none">
                        <FontAwesomeIcon
                            className="text-gray-300"
                            icon={faSearch}
                        />
                    </div>
                    <TextInput
                        name="search"
                        id="email"
                        rounded={false}
                        className={`form-input block w-full ${
                            sortOptions.length > 0 ? "rounded-none" : ""
                        } rounded-l pl-10 transition ease-in-out duration-150 sm:text-sm sm:leading-5`}
                        placeholder={placeholder}
                        value={searchValue}
                        onChange={onSearchChange}
                        autoComplete="off"
                    />
                </div>

                {sortOptions.length > 0 && (
                    <div
                        className={`relative -ml-px inline-flex items-center border border-gray-300 px-4 text-sm leading-5 font-medium rounded-r-md text-gray-700 bg-gray-50 hover:text-gray-500 hover:bg-white focus:outline-none focus:shadow-outline-blue focus:border-blue-300 active:bg-gray-100 active:text-gray-700 transition ease-in-out duration-150`}
                    >
                        <div
                            className={`left-0 right-0 w-full text-center flex flex-row justify-center items-center`}
                        >
                            <FontAwesomeIcon
                                size="1x"
                                icon={
                                    sortDir === "asc"
                                        ? faSortAmountDown
                                        : faSortAmountUp
                                }
                            />
                            &nbsp;
                            <div>{selectedSort}</div>
                        </div>
                        <select
                            aria-label="Sort Options"
                            className={`${normalSelect} absolute opacity-0 w-full h-full`}
                            onChange={onSortChange}
                            value={sortValue}
                        >
                            {sortOptions.map((option, idx) => {
                                const value =
                                    typeof option === "string"
                                        ? idx
                                        : option.value;
                                const text =
                                    typeof option === "string"
                                        ? option
                                        : option.text;
                                return (
                                    <option key={idx} value={value}>
                                        {text}
                                    </option>
                                );
                            })}
                        </select>
                    </div>
                )}
            </div>
        </div>
    );
}
