import React, { useState, useRef } from "react";
import Pagination from "../../../components/Pagination";
import { Link } from "react-router-dom";
import { formatDate } from "../../../util/TextUtil";
import useDateRange from "hooks/useDateRange";
import Button from "components/Button";
import useChanges from "hooks/useChangesLog";
import TextInput from "components/TextInput";
import usePagination from "hooks/usePagination";
import Spinner from "components/Spinner";
import UserSelect from "actions/UserSelect";

const ChangeLogTable = ({ limit = 50, client, showFilters = true }) => {
    const clientId = client ? client.id : 0;
    const [page, setPage] = usePagination();
    const [changedBy, setChangedBy] = useState();
    const changedByRef = useRef();

    const {
        dates,
        setDates,
        currentStartDate,
        currentEndDate,
        setCurrentStartDate,
        setCurrentEndDate,
    } = useDateRange();

    const {
        data: changes,
        isLoading,
        meta,
    } = useChanges({
        page: page,
        limit: limit,
        clientId: clientId,
        changedBy: changedBy,
        startDate: dates.startDate,
        endDate: dates.endDate,
    });

    const changeLink = (change) => {
        if (change.client_id > 0) {
            return `/clients/${change.client_id}/changes/${change.id}/show`;
        }

        return `/changes/${change.id}/show`;
    };

    function handleSubmit(event) {
        event.preventDefault();
        setChangedBy(changedByRef.current);
        setDates({
            startDate: currentStartDate,
            endDate: currentEndDate,
        });
    }

    const initialData = () => {
        if (client === null) {
            return [];
        }
        if (client.spouse) {
            return [
                {
                    label: "Changes by anyone",
                    value: "",
                },
                {
                    label: client.full_name,
                    value: client.id,
                },
                {
                    label: client.spouse.full_name,
                    value: client.spouse.authorized_party_id || -1,
                },
            ];
        } else {
            return [
                {
                    label: "Changes by anyone",
                    value: "",
                },
                {
                    label: client.full_name,
                    value: client.id,
                },
            ];
        }
    };

    return (
        <div className="space-y-6">
            {showFilters && (
                <div className="flex justify-between items-end">
                    <div className="flex-0 flex items-end space-x-2">
                        <TextInput
                            label="Date Range"
                            name="start_date"
                            type="date"
                            value={currentStartDate}
                            onChange={(event) =>
                                setCurrentStartDate(event.target.value)
                            }
                        />
                        <TextInput
                            name="end_date"
                            type="date"
                            value={currentEndDate}
                            onChange={(event) =>
                                setCurrentEndDate(event.target.value)
                            }
                        />
                        <div className="flex-shrink-0">
                            <UserSelect
                                label="Changed By"
                                initialData={initialData()}
                                params={{
                                    type: ["member", "advisor"],
                                    client: clientId,
                                }}
                                onChange={(value) => {
                                    changedByRef.current = value;
                                }}
                            />
                        </div>
                        <Button
                            onClick={handleSubmit}
                            appearance="light"
                            text="Submit"
                        />
                    </div>
                </div>
            )}
            {changes && changes.length ? (
                <>
                    {isLoading ? (
                        <Spinner />
                    ) : (
                        <table className="w-full text-left">
                            <thead>
                                <tr>
                                    <th>Change</th>
                                    <th>Resource Type</th>
                                    <th>Date/Time</th>
                                    <th>Changed By</th>
                                    <th className="text-right">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {changes.map((change, idx) => (
                                    <tr
                                        key={idx}
                                        className="border-b border-gray-100"
                                    >
                                        <td className="pr-9 w-96">
                                            <Link
                                                to={changeLink(change)}
                                                className="hover:text-brand space-x-1"
                                            >
                                                <span>
                                                    {
                                                        change.resource_display_name
                                                    }
                                                </span>
                                            </Link>
                                        </td>
                                        <td className="w-36">
                                            {change.resource_display_type}
                                        </td>
                                        <td className="w-36">
                                            {formatDate(change.created_at)}
                                        </td>
                                        <td className="w-36">
                                            {change.user ? (
                                                <>
                                                    <div className="bold">
                                                        {change.user.full_name}
                                                    </div>
                                                </>
                                            ) : (
                                                "N/A"
                                            )}
                                        </td>
                                        <td className="text-right w-36">
                                            <Link
                                                to={changeLink(change)}
                                                className="text-brand-500 hover:text-brand-700"
                                            >
                                                View
                                            </Link>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    )}

                    <div className="mt-6">
                        <Pagination {...meta} nextPage={setPage} />
                    </div>
                </>
            ) : (
                <div>The are no changes to list.</div>
            )}
        </div>
    );
};

export default ChangeLogTable;
