import React from "react";
import { useState, useEffect } from "react";
import Button from "../../../components/Button";
import Modal from "../../../components/Modal";
import Api from "../../../services/Api";
import AddQuestion from "./AddQuestion";
import EditQuestion from "./EditQuestion";
import {
    SortableContainer,
    SortableElement,
    SortableHandle,
} from "react-sortable-hoc";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faBars,
    faEllipsisH,
    faEdit,
    faTrash,
} from "@fortawesome/free-solid-svg-icons";
import arrayMove from "array-move";
import Dropdown, { DropdownItem } from "../../../components/Dropdown";
import { useToasts } from "react-toast-notifications";
import Badge from "../../../components/Badge";

export default function QuestionnaireForm({
    member,
    questionnaire,
    readOnly = false,
    refresh,
}) {
    const { addToast } = useToasts();
    const [showAddQuestionForm, setShowAddQuestionForm] = useState(false);
    const [questionToEdit, setQuestionToEdit] = useState(false);

    const [questions, setQuestions] = useState(questionnaire.questions);

    const SortableItem = SortableElement(({ value }) => (
        <li className="flex items-center justify-between py-2 border-b border-gray-200">
            {value}
        </li>
    ));
    const SortableList = SortableContainer(({ items, readOnly }) => {
        return (
            <ul
                className={`${
                    readOnly ? "opacity-75 pointer-events-none" : "opacity-100"
                }`}
            >
                {items &&
                    items.map((item, idx) => (
                        <SortableItem
                            key={`item-${idx}`}
                            index={idx}
                            value={
                                <SortableQuestionListItem
                                    setQuestionToEdit={setQuestionToEdit}
                                    deleteQuestion={deleteQuestion}
                                    question={item}
                                    useDragHandle={true}
                                    readOnly={readOnly}
                                />
                            }
                        />
                    ))}
            </ul>
        );
    });

    async function updateSort(question) {
        try {
            await Api.put(
                "members/" +
                    member.id +
                    "/questionnaires/" +
                    questionnaire.id +
                    "/questions/" +
                    question.id,
                question
            );
            refresh();
        } catch (error) {
            console.log(error);
        }
    }

    async function deleteQuestion(question) {
        try {
            let response = await Api.delete(
                "members/" +
                    member.id +
                    "/questionnaires/" +
                    questionnaire.id +
                    "/questions/" +
                    question.id
            );
            addToast(response.data.message);
            refresh();
        } catch (e) {
            addToast(e.response.data.message, { type: "error" });
        }
    }

    function onSortEnd({ oldIndex, newIndex }) {
        setQuestions(arrayMove(questions, oldIndex, newIndex));
        let question = questions[oldIndex];
        updateSort({ ...question, position: newIndex });
    }

    useEffect(() => setQuestions(questionnaire.questions), [questionnaire]);

    return (
        <div>
            <SortableList
                items={questions}
                onSortEnd={onSortEnd}
                readOnly={readOnly}
                useDragHandle
            />

            <div className="w-48 my-6 ml-auto">
                <Button
                    disabled={readOnly}
                    type="button"
                    onClick={() => setShowAddQuestionForm(true)}
                    text="Add Question"
                />
            </div>

            <Modal
                isOpen={
                    showAddQuestionForm || questionnaire.questions.length === 0
                }
                backgroundDismiss={true}
                title="Add Question"
                size="2xl"
                component={() => (
                    <AddQuestion
                        member={member}
                        questionnaire={questionnaire}
                        readOnly={readOnly}
                        refresh={() => {
                            setShowAddQuestionForm(false);
                            refresh();
                        }}
                    />
                )}
                onCancel={() => setShowAddQuestionForm(false)}
            />

            {questionToEdit !== false && (
                <Modal
                    isOpen={true}
                    backgroundDismiss={true}
                    title="Edit Question"
                    size="2xl"
                    component={() => (
                        <EditQuestion
                            member={member}
                            questionnaire={questionnaire}
                            readOnly={readOnly}
                            question={questionToEdit}
                            refresh={() => {
                                setQuestionToEdit(false);
                                refresh();
                            }}
                        />
                    )}
                    onCancel={() => setQuestionToEdit(false)}
                />
            )}
        </div>
    );
}

function SortableQuestionListItem({
    question,
    setQuestionToEdit,
    deleteQuestion,
    readOnly,
}) {
    const DragHandle = SortableHandle(() => (
        <span className="text-gray-300 mr-3 cursor-move hover:text-gray-400">
            <FontAwesomeIcon icon={faBars} />
        </span>
    ));

    function questionType(question) {
        if (question.type === "range") return "Range";
        if (question.type === "multiple_choice") return "Multiple Choice";
        if (question.type === "boolean") return "True/False";
        return "N/A";
    }

    return (
        <>
            <div
                className={`flex-0 w-1/2 text-left flex items-center space-x-2`}
            >
                <DragHandle />
                <div>
                    <a
                        href="#question"
                        onClick={() => setQuestionToEdit(question)}
                        className="leading-relaxed"
                    >
                        {question.title}
                    </a>
                </div>
                {question.status === "draft" && (
                    <Badge color="brand">Draft</Badge>
                )}
            </div>
            <div className="flex-0 1/4 text-center">
                {questionType(question)}
            </div>
            <div
                className={`flex-0 1/4 text-right ${
                    readOnly ? "opacity-0" : "opacity-100"
                }`}
            >
                <Dropdown
                    position="right"
                    toggleClass="px-3 py-1"
                    toggleContent={<FontAwesomeIcon icon={faEllipsisH} />}
                >
                    <DropdownItem
                        label="Edit"
                        icon={faEdit}
                        handleClick={() => setQuestionToEdit(question)}
                    />
                    <DropdownItem
                        label="Delete"
                        icon={faTrash}
                        handleClick={() => deleteQuestion(question)}
                    />
                </Dropdown>
            </div>
        </>
    );
}
