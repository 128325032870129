import React, { useState } from "react";

export const IceContext = React.createContext();

export default function IceContextProvider({ children }) {
    const [isSpouse, setIsSpouse] = useState(false);

    return (
        <IceContext.Provider
            value={{
                isSpouse,
                setIsSpouse,
            }}
        >
            {children}
        </IceContext.Provider>
    );
}
