// https://goshakkk.name/array-form-inputs/
import React, { useState, useEffect } from "react";
import Api from "../../../services/Api";
import useQuery from "../../../hooks/useQuery";

import VaultFolderView from "./VaultFolderView";
import VaultFileView from "./VaultFileView";
import Forbidden from "components/Forbidden";

export default function VaultWrapper({ client, vaultId = 0, ...props }) {
    const [vault, setVault] = useState(null);
    const [vaultEntries, setVaultEntries] = useState([]);

    const [breadcrumbs, setBreadcrumbs] = useState([]);
    const [loading, setLoading] = useState(false);
    const [forceUpdate, setForceUpdate] = useState(0);
    const [notAuthorized, setNotAuthorized] = useState(false);

    const query = useQuery();
    vaultId = query.get("id") || vaultId || null;

    useEffect(() => {
        const buildCrumb = (breadcrumb, arr) => {
            arr.unshift(breadcrumb);
            if (breadcrumb.parent_recursive) {
                buildCrumb(breadcrumb.parent_recursive, arr);
            }
            return arr;
        };
        async function fetchVault() {
            const params = {};
            if (vaultId) {
                params.parentId = vaultId;
            }
            try {
                const response = await Api.get(
                    `clients/${client.id}/vault`,
                    params
                );
                setVaultEntries(response.data.data);
                if (response.data.breadcrumbs) {
                    setBreadcrumbs(buildCrumb(response.data.breadcrumbs, []));
                    setVault(response.data.breadcrumbs);
                } else {
                    setVault(null);
                    setBreadcrumbs([]);
                }
            } catch (error) {
                if (error.response.status === 403) {
                    setNotAuthorized(true);
                }
            }
        }
        setLoading(true);
        Promise.all([fetchVault()]).finally(() => {
            setLoading(false);
        });
    }, [vaultId, forceUpdate, client.id]);

    const isDirectory = vault === null || vault.type === "directory";

    if (notAuthorized) {
        return <Forbidden />;
    }

    return (
        <>
            {isDirectory && (
                <VaultFolderView
                    client={client}
                    vaultEntries={vaultEntries}
                    loading={loading}
                    parent={vault}
                    breadcrumbs={breadcrumbs}
                    refreshVaultEntries={() => {
                        setForceUpdate(forceUpdate + 1);
                    }}
                    {...props}
                />
            )}
            {!isDirectory && (
                <VaultFileView
                    client={client}
                    vaultEntries={vaultEntries}
                    loading={loading}
                    parent={vault}
                    breadcrumbs={breadcrumbs}
                    refreshVaultEntries={() => {
                        setForceUpdate(forceUpdate + 1);
                    }}
                    {...props}
                />
            )}
        </>
    );
}
