import React, { useEffect } from "react";
import { Redirect, Link } from "react-router-dom";
import PageHeader from "../../../components/PageHeader";
import Panel, { PanelHeader } from "../../../components/Panel";
import { RecentMessages } from "../Members/MemberDashboard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faArrowRight,
    faCalendarAlt,
    faDollarSign,
    faEnvelopeOpenText,
} from "@fortawesome/free-solid-svg-icons";
import { TaskList } from "../../onboarding/TaskList";
import useAuth from "../../../hooks/useAuth";
import useFormatCurrency from "../../../hooks/useFormatCurrency";
import Spinner from "../../../components/Spinner";
import useApi from "../../../hooks/useApi";
import useMountEffect from "../../../hooks/useMountEffect";
import useClient from "../../../hooks/useClient";
import ErrorBoundary from "util/ErrorBoundary";
import useLiveWealthData from "hooks/useLiveWealthData";

function ClientDashboard(props) {
    const { user } = useAuth();
    const { client } = useClient();

    const redirectToWealthReport = false;

    if (redirectToWealthReport) {
        return (
            <>
                <PageHeader text="Wealth Report" />
                <div className="p-6">
                    <Redirect
                        to={{
                            pathname:
                                "/clients/" + user.id + "/reports/livewealth",
                            from: props.location,
                        }}
                    />
                </div>
            </>
        );
    }

    return (
        <>
            <div className="flex justify-between items-center w-full p-6">
                <h1 className="text-2xl font-bold text-gray-700">
                    Welcome, {user.full_name}!
                </h1>
                <div>
                    <ErrorBoundary>
                        <TaskList />
                    </ErrorBoundary>
                </div>
            </div>
            <div className="p-6 space-y-6">
                <Panel className="h-full">
                    <PanelHeader
                        title={
                            <div className="flex justify-between items-center w-full">
                                <div className="flex-1 flex items-center space-x-2">
                                    <FontAwesomeIcon
                                        icon={faDollarSign}
                                        className="text-brand-green"
                                    />
                                    <div className="space-x-1">
                                        <span>
                                            {user.role === "client_member"
                                                ? "My"
                                                : client.full_name_with_spouse +
                                                  "'s"}
                                        </span>
                                        <span>Wealth Snapshot</span>
                                    </div>
                                </div>
                            </div>
                        }
                    >
                        <Link
                            type="link"
                            to={`/clients/${user.id}/reports/livewealth`}
                            className="text-sm text-brand-500 hover:text-brand-600"
                        >
                            View Full Wealth Report{" "}
                            <FontAwesomeIcon
                                className="text-sm text-brand-300"
                                icon={faArrowRight}
                            />
                        </Link>
                    </PanelHeader>
                    <ErrorBoundary message="You do not have sufficient permissions to access the wealth report.">
                        <WealthSnapshotWidget
                            ownerDefault={`Client:${client.id}`}
                        />
                    </ErrorBoundary>
                </Panel>
                <div className="space-y-6 xl:flex xl:space-x-6 xl:space-y-0">
                    <div className="flex-1">
                        <ErrorBoundary>
                            <Panel>
                                <PanelHeader
                                    title={
                                        <div className="flex justify-between items-center w-full">
                                            <div className="flex items-center space-x-2">
                                                <FontAwesomeIcon
                                                    icon={faEnvelopeOpenText}
                                                    className="text-brand-green"
                                                />
                                                <div>Recent Messages</div>
                                            </div>
                                        </div>
                                    }
                                >
                                    <Link
                                        type="link"
                                        to={`/client/messages`}
                                        width="auto"
                                        className="text-sm text-brand-500 hover:text-brand-600"
                                    >
                                        View All{" "}
                                        <FontAwesomeIcon
                                            className="text-sm text-brand-300"
                                            icon={faArrowRight}
                                        />
                                    </Link>
                                </PanelHeader>
                                <RecentMessages />
                            </Panel>
                        </ErrorBoundary>
                    </div>
                    <div className="flex-1">
                        <ErrorBoundary>
                            <UpcomingAppointmentsWidget props={props} />
                        </ErrorBoundary>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ClientDashboard;

export function WealthSnapshotWidget() {
    const { client } = useClient();
    useEffect(() => {
        setOwner(`Client:${client.id}`);
    }, []);
    const { totals, isLoading: loadingAssets, setOwner } = useLiveWealthData();
    const formatCurrency = useFormatCurrency();

    if (loadingAssets) {
        return <Spinner message="Loading wealth snapshot" />;
    }

    return (
        <>
            {loadingAssets === true && <Spinner />}
            {loadingAssets === false && (
                <div className="h-full flex space-x-12">
                    <div>
                        <div className="text-gray-500">Current Net Worth</div>
                        <div className="text-2xl font-bold tabular-nums">
                            <span
                                className={`${
                                    totals.netWorth >= 0
                                        ? "text-gray-800"
                                        : "text-red-400"
                                }`}
                            >
                                ${formatCurrency("" + totals.netWorth)}
                            </span>
                        </div>
                    </div>
                    <div>
                        <div className="text-gray-500">Estate Net Worth </div>
                        <div className="text-2xl font-bold tabular-nums">
                            <span
                                className={`${
                                    totals.netWorthWithDeathBenefit > 0
                                        ? "text-gray-800"
                                        : "text-red-400"
                                }`}
                            >
                                $
                                {formatCurrency(
                                    "" + totals.netWorthWithDeathBenefit
                                )}
                            </span>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

export function UpcomingAppointmentsWidget() {
    const { user } = useAuth();
    const { api, loading } = useApi();
    const [appointments, setAppointments] = React.useState([]);

    const viewAllLink = () => {
        if (user.type === "client") {
            return `/client/appointments`;
        }
        return "/appointments";
    };

    const fetchAppointments = async () => {
        try {
            const response = await api("get", "appointments", {
                limit: 5,
                "filter[upcomingOnly]": true,
            });
            setAppointments(response.data.data);
        } catch (error) {
            console.log(error);
        }
    };

    useMountEffect(() => {
        fetchAppointments();
        return () => {
            setAppointments([]);
        };
    });

    return (
        <Panel>
            <PanelHeader
                title={
                    <div className="flex justify-between items-center w-full">
                        <div className="flex items-center space-x-2">
                            <FontAwesomeIcon
                                icon={faCalendarAlt}
                                className="text-brand-green"
                            />
                            <div>Upcoming Appointments</div>
                        </div>
                    </div>
                }
            >
                <Link
                    type="link"
                    to={viewAllLink()}
                    className="text-sm text-brand-500 hover:text-brand-600"
                >
                    View All{" "}
                    <FontAwesomeIcon
                        className="text-sm text-brand-300"
                        icon={faArrowRight}
                    />
                </Link>
            </PanelHeader>
            {loading === true && <Spinner message="Loading appointments" />}
            {loading === false && appointments.length === 0 && (
                <p>You have no upcoming appointments.</p>
            )}
            {loading === false && appointments.length > 0 && (
                <ul className="space-y-2 divide-y divide-gray-100">
                    {appointments.map((appointment) => (
                        <li
                            className="pt-2"
                            key={`appointment_${appointment.id}`}
                        >
                            <div className="text-sm text-gray-500 space-x-1">
                                <span>
                                    {appointment.date_formatted} at{" "}
                                    {appointment.time_formatted}
                                </span>
                                {appointment.client &&
                                    user.type !== "client" && (
                                        <span className="text-sm text-gray-500">
                                            with {appointment.client.full_name}
                                        </span>
                                    )}
                                {appointment.member &&
                                    user.type === "client" && (
                                        <span className="text-sm text-gray-500">
                                            with {appointment.member.full_name}
                                        </span>
                                    )}
                            </div>
                            <span className="font-bold">
                                <Link to={appointment.resource_url}>
                                    {appointment.title}
                                </Link>
                            </span>
                        </li>
                    ))}
                </ul>
            )}
        </Panel>
    );
}
