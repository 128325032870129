import React, { useState, useEffect } from "react";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

const Spinner = ({
    className = "w-full flex flex-col justify-center items-center",
    message = "",
    height = 100,
    width = 100,
    type = "Oval",
    color = "rgb(40, 170, 226)",
    delay = 200,
    ...props
}) => {
    const [delayed, setDelayed] = useState(true);

    useEffect(() => {
        const timeout = setTimeout(() => setDelayed(false), delay);
        return () => clearTimeout(timeout);
    }, [delay]);

    if (delayed) {
        return <></>;
    }

    return (
        <div className={className}>
            <Loader
                type={type}
                color={color}
                height={height}
                width={width}
                {...props}
            />
            {message && (
                <span className="mt-4 text-center font-bold text-md text-gray-700 sm:text-lg">
                    {message}
                </span>
            )}
        </div>
    );
};

export default Spinner;
